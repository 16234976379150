import styled from 'styled-components';

export const StopTestColumn = styled.div`
    text-align: left;

    [dir='rtl'] & {
        text-align: right;
    }
`;

export const ButtonColumn = styled.div`
    text-align: center;
`;

export const ButtonWrapper = styled.div`
    display: inline-block;
`;

export const ProgressWrapper = styled.div`
    text-align: right;

    [dir='rtl'] & {
        text-align: left;
    }
`;
