import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {Columns} from '@/componentLibrary/components/layout/Columns';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    Description,
    Wrapper
} from '@/pages/PersonalityTestIrt/pages/StartNewPersonalityTest/components/TestIntro/styled';
import {useMemo} from 'react';
import {personalityTestIntroMessages} from '../../messages';

export function TestIntro() {
    const phrases = useExtractPhraseConstants(personalityTestIntroMessages);

    const columns = useMemo(() => {
        return [
            {
                size: 3,
                grow: false,
                shrink: false,
                render: () => (
                    <Wrapper>
                        <Icon size={32} data-testid={'time-icon'} icon={Icons.SCHEDULE} />
                        <Description>{phrases.time}</Description>
                    </Wrapper>
                )
            },
            {
                size: 3,
                grow: false,
                shrink: false,
                render: () => (
                    <Wrapper>
                        <Icon
                            size={32}
                            data-testid={'questions-icon'}
                            icon={Icons.LEADERBOARD}
                        />
                        <Description>{phrases.questions}</Description>
                    </Wrapper>
                )
            },
            {
                size: 3,
                grow: false,
                shrink: false,
                render: () => (
                    <Wrapper>
                        <Icon size={32} data-testid={'secure-icon'} icon={Icons.LOCK} />
                        <Description>{phrases.secure}</Description>
                    </Wrapper>
                )
            },
            {
                size: 3,
                grow: false,
                shrink: false,
                render: () => (
                    <Wrapper>
                        <Icon size={32} data-testid={'results-icon'} icon={Icons.DRAFT} />
                        <Description>{phrases.results}</Description>
                    </Wrapper>
                )
            }
        ];
    }, [phrases]);

    return <Columns columns={columns} style={{alignItems: 'unset'}} />;
}
