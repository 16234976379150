export type SegmentedControlOption<T> = {
    value: T;
    text?: string;
    hoverTitle?: string;
    hoverText?: string;
    disabled?: boolean;
    isSelected?: boolean;
};

export enum SegmentedControlSize {
    DEFAULT = 'DEFAULT',
    SMALL = 'SMALL',
    LARGE = 'LARGE'
}

export type SegmentedControlProps<T> = {
    options: SegmentedControlOption<T>[];
    onSelect?: (value: T) => void;
    selectedOptionValue?: T | null;
    size?: SegmentedControlSize;
    isFullwidth?: boolean;
};
