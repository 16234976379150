import {useApolloClient} from '@apollo/client';
import {useCallback, useEffect} from 'react';

import {useRegisterUser} from '@/api/users/register/useRegisterUser';
import {useChangeLocaleForUser} from '@/api/users/useChangeLocaleForUser';
import {useChangeLocale} from '@/containers/LanguageProvider/useChangeLocale';
import {setUserAuthToken} from '@/services/auth/utils';
import {logger} from '@/services/logrocket';

export function useRegisterUserLogic(
    token: string,
    onSuccess: () => void,
    userId: number,
    userLanguage: string | null,
    handleError: (e: Error) => void
) {
    const client = useApolloClient();
    const {locale, changeLocale} = useChangeLocale();
    const [doChangeLocaleForUser] = useChangeLocaleForUser();
    const [doRegisterUser, {loading, error}] = useRegisterUser(token);

    useEffect(() => {
        if (userLanguage) {
            changeLocale(userLanguage);
        }
    }, [changeLocale, userLanguage]);

    const registerUser = useCallback(
        userData => {
            doRegisterUser(userData)
                .then(({data}) => {
                    const {ok, authToken, errorMessage} = data?.registerUser ?? {};
                    if (ok && authToken) {
                        setUserAuthToken(authToken);
                        client.cache.reset().then(() => {
                            doChangeLocaleForUser(userId, locale).catch(logger.error);
                            onSuccess();
                        });
                    } else {
                        throw new Error(errorMessage ?? '');
                    }
                })
                .catch(handleError);
        },
        [handleError, doRegisterUser, client, onSuccess, doChangeLocaleForUser, locale, userId]
    );

    return {loading, error, registerUser};
}
