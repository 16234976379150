import {defineMessages} from 'react-intl';

export default defineMessages({
    backButtonText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.backButtonText'
    },
    timeHeader: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.timeHeader'
    },
    timeSubheader: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.timeSubheader'
    },
    expiredTimeHeader: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.expiredTimeHeader'
    },
    timeLimitDesc: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.timeLimitDesc'
    },
    timeLimitDeadlinePassedDesc: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.timeLimitDeadlinePassedDesc'
    },
    timeEstimateDesc: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.timeEstimateDesc2'
    },
    technologiesHeader: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.technologiesHeader'
    },
    technologiesText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.technologiesText'
    },
    completionHeader: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.completionHeader'
    },
    landingPageHeader: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.landing.title'
    },
    landingPageSubheader: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.landing.subtitle'
    },
    landingPageTimeLimit: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.landing.timeLimit'
    },
    landingPageTimerInfo: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.landing.timerInfo'
    },
    landingPageCompletionText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.landing.completionText'
    },
    inProgressCompletionText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.progress.completionText'
    },
    githubSectionHeader: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.landing.githubSectionHeader'
    },
    githubSectionContent: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.landing.githubSectionContent'
    },
    progressPageHeader: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.progress.title'
    },
    githubRepository: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.progress.githubRepository'
    },
    githubSubtitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.progress.githubSubtitle'
    },
    codingTestWithoutTimeText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.codingTestWithoutTimeText'
    },
    codingTestOneTimeText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.codingTestOneTimeText'
    },
    codingTestTimeText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.codingTestTimeText2'
    },
    submitBtnTitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submitBtn.title'
    },
    startTestBtnTitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.startTestBtn.title'
    },
    submittingResult: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submittingResult'
    },
    submitResultSuccess: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submitResultSuccess'
    },
    codingTestResultsHeader: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.resultsHeader'
    },
    codingTestResultsText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.resultsText'
    },
    codingTestResultsTextLate: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.resultsTextLate'
    },
    codingTestResultsGithub: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.resultsGithub'
    },
    missingPRErrorMessageDesc: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submit.missingPRDesc'
    },
    tooManyPRFilesErrorMessageDesc: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submit.tooManyPRFilesDesc'
    },
    submissionFailedErrorMessage: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submit.errorMessage'
    },
    submissionFailedErrorMessageDesc: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submit.errorMessageDesc'
    },
    importBoilerplatesSuccessMessage: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.importBoilerplate.successMessage'
    },
    importBoilerplateFailedErrorMessage: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.importBoilerplate.errorMessage'
    },
    importBoilerplateNotSupportedErrorMessageDesc: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.importBoilerplate.notSupportedErrorMessageDesc'
    }
});

export const CODING_TEST_START_MODAL_MESSAGES = defineMessages({
    alertTitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.startModal.title'
    },
    alertSubtitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.startModal.subtitle'
    },
    alertSubtitleWitTechStack: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.startModal.withTechStack.subtitle'
    },
    alertDisclaimer: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.startModal.disclaimer'
    },
    alertStayButtonText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.startModal.cancel'
    },
    alertContinueButtonText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.startModal.start'
    }
});

export const CODING_TEST_SUBMIT_MODAL_MESSAGES = defineMessages({
    alertTitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submitModal.title'
    },
    alertSubtitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submitModal.subtitle'
    },
    alertStayButtonText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submitModal.cancel'
    },
    alertContinueButtonText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submitModal.start'
    }
});

export const TEST_SCENARIOS_MODAL_MESSAGES = defineMessages({
    title: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submitModal.title'
    },
    subtitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.testResultsModal.subtitle'
    },
    leftButton: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submitModal.cancel'
    },
    rightButton: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.submitModal.start'
    },
    testScenario: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.detailModal.testScenario'
    },
    results: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.detailModal.results'
    },
    score: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.testResultsModal.score'
    },
    resultsGithub: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.testResultsModal.resultsGithub'
    },
    fetchingResults: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.testResultsModal.fetchingResults'
    }
});
