import React from 'react';
import {useIntl} from 'react-intl';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {AdminContent} from '@/componentLibrary/blocks/testReports/LogicTest/components/AdminContent';
import {ReadMoreContent} from '@/componentLibrary/blocks/testReports/LogicTest/components/ReadMoreContent';
import {getStandardScore} from '@/componentLibrary/blocks/testReports/LogicTest/utils';
import {WithTestReportLocale} from '@/componentLibrary/blocks/testReports/WithTestReportLocale';
import {LogicTestResultsDistribution} from '@/componentLibrary/blocks/testReports/components/LogicTestResultsDistribution';
import {TestFooter} from '@/componentLibrary/blocks/testReports/components/TestFooter';
import {REPORT_TYPES} from '@/componentLibrary/blocks/testReports/constants';

import {PrintHeader} from '../../components/PrintHeader';
import {ReportHeader} from '../../components/ReportHeader';
import {useTestReportLocaleLogic} from '../../hooks/useTestReportLocaleLogic';
import messages from '../../messages';
import {ContentWrapperWithDefaultBg} from '../../styled';
import {ReportIntro} from '../components/ReportIntro';
import {ScoreOverview} from '../components/ScoreOverview';
import {Wrapper} from './styled';
import {GetLogicalAbilityEstimate_LogicalAbilityEstimate, LogicTestReportProps} from './types';

export const LogicTestReport: React.FC<LogicTestReportProps> = ({
    logicalAbilityEstimate,
    validationTestResult,
    showAdminContent = false,
    close
}) => {
    const intl = useIntl();
    const standardScore = getStandardScore(logicalAbilityEstimate.mu);
    const {reportLocale, changeReportLocale} = useTestReportLocaleLogic();

    return (
        <WithTestReportLocale locale={reportLocale}>
            <Modal
                back={close}
                size={MODAL_SIZE.FULL_PAGE}
                headerCustomRightContent={
                    <ReportHeader
                        reportType={REPORT_TYPES.LOGIC_TEST_REPORT}
                        reportUserId={logicalAbilityEstimate.userId}
                        reportLocale={reportLocale}
                        changeReportLocale={changeReportLocale}
                        printTitle={intl.formatMessage(messages.logicTestReportTitle)}
                        user={
                            logicalAbilityEstimate.user as GetLogicalAbilityEstimate_LogicalAbilityEstimate['user']
                        }
                        testId={logicalAbilityEstimate.logicTestId}
                    />
                }
            >
                <ContentWrapperWithDefaultBg>
                    <Wrapper>
                        <PrintHeader
                            user={
                                logicalAbilityEstimate.user as GetLogicalAbilityEstimate_LogicalAbilityEstimate['user']
                            }
                        />
                        <ReportIntro
                            user={
                                logicalAbilityEstimate.user as GetLogicalAbilityEstimate_LogicalAbilityEstimate['user']
                            }
                            showStandardScoreInfo={showAdminContent}
                        />
                        <ScoreOverview
                            standardScore={standardScore}
                            showAdminContent={showAdminContent}
                            showAdditionalInfo
                        />

                        {/* Data collection result does not contain a report */}
                        {logicalAbilityEstimate?.report ? (
                            <LogicTestResultsDistribution
                                logicalAbilityEstimate={logicalAbilityEstimate}
                                standardScore={standardScore}
                            />
                        ) : null}

                        {showAdminContent && (
                            <AdminContent
                                logicalAbilityEstimate={logicalAbilityEstimate}
                                validationTestResult={validationTestResult}
                            />
                        )}
                        <TestFooter>
                            <ReadMoreContent showAdminContent={showAdminContent} />
                        </TestFooter>
                    </Wrapper>
                </ContentWrapperWithDefaultBg>
            </Modal>
        </WithTestReportLocale>
    );
};
