import {useCallback, useMemo, useState} from 'react';

import {validateLinkedinProfileUrl} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation/components/SubmitResume/utils';
import {ERROR_TYPES} from '@/pages/User/components/RecommendationRelevanceForm/constants';

import {RemoveValidationError} from '../types';

export function useLinkedInProfileLogic(removeValidationError: RemoveValidationError) {
    const [linkedinProfileUrl, setLinkedinProfileUrl] = useState<string>('');

    const isLinkedinProfileUrlValid = useMemo(() => {
        return validateLinkedinProfileUrl(linkedinProfileUrl);
    }, [linkedinProfileUrl]);

    const handleLinkedinProfileUrlChange = useCallback(
        (linkedinProfile: string) => {
            if (validateLinkedinProfileUrl(linkedinProfile)) {
                removeValidationError(ERROR_TYPES.LINKEDIN_URL);
            }
            setLinkedinProfileUrl(linkedinProfile);
        },
        [removeValidationError]
    );

    return {
        setLinkedinProfileUrl,
        linkedinProfileUrl,
        handleLinkedinProfileUrlChange,
        isLinkedinProfileUrlValid
    };
}
