import {parse} from 'qs';
import {useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

export function useUserDemographicsLogic() {
    const history = useHistory();
    const location = useLocation();

    const redirect = useCallback(() => {
        const redirectUrl = parse(location.search, {ignoreQueryPrefix: true})
            .redirect as string;

        if (!redirectUrl) {
            history.push('/');
            return;
        }

        const hasAdditionalParams = redirectUrl.includes('?');

        if (hasAdditionalParams) {
            history.push(redirectUrl + '&fillInLater=true');
        } else {
            history.push(redirectUrl + '?fillInLater=true');
        }
    }, [history, location.search]);

    return {redirect};
}
