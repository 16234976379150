import {useEffect} from 'react';

import {LandingPageLayout} from '@/componentLibrary/blocks/LandingPageLayout';
import {Spinner} from '@/componentLibrary/components/spinners';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useLogoutLogic} from '@/pages/Logout/useLogoutLogic';

import messages from './messages';
import {InnerWrapper, Wrapper} from './styled';

export function LogoutPage() {
    const {doLogout} = useLogoutLogic();
    const phrases = useExtractPhraseConstants(messages);

    useEffect(() => {
        doLogout();
    }, [doLogout]);

    return (
        <LandingPageLayout>
            <Wrapper>
                <InnerWrapper>
                    <Spinner />
                    {phrases.loggingOut}
                </InnerWrapper>
            </Wrapper>
        </LandingPageLayout>
    );
}
