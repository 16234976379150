import {useJobApplication} from '@/api/assessment/jobApplications/useJobApplication';
import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {
    GetJobApplication_JobApplication,
    GetJobApplication_JobApplication_ChallengeAssignment
} from '@/api/assessment/types';
import {useHasCombinedRoleFitBetaTest} from '@/hooks/useHasCombinedRoleFitBetaTest';
import {useMemo} from 'react';

export type UseLogicReturnData = {
    challengeAssignment: GetJobApplication_JobApplication_ChallengeAssignment | null;
    jobApplication: GetJobApplication_JobApplication | null;
};

type UseLogic = {
    userId: number;
    jobPositionId: number;
    isReview?: boolean;
};

export function useLogic({userId, jobPositionId, isReview}: UseLogic) {
    const {jobPosition} = useJobPosition(jobPositionId);
    const hasCombinedRoleFitBetaTest = useHasCombinedRoleFitBetaTest(jobPosition);

    const {jobApplication, loading, error, refetch, startPolling, stopPolling} =
        useJobApplication(jobPositionId, userId, {
            withChallengeAssignment: true,
            withAutomatedScore: true,
            withJobPosition: true,
            withCandidate: true,
            withTeam: isReview,
            withHiringManager: isReview,
            withRoleFitV2: hasCombinedRoleFitBetaTest
        });

    const data = useMemo<UseLogicReturnData>(
        () => ({
            challengeAssignment: jobApplication?.challengeAssignment ?? null,
            jobApplication: jobApplication ?? null
        }),
        [jobApplication]
    );

    return {data, refetch, error, loading, startPolling, stopPolling};
}
