import {defineMessages} from 'react-intl';

export default defineMessages({
    veryLow: {
        id: 'app.assessment.jobPosition.roleFit.veryLow'
    },
    low: {
        id: 'app.assessment.jobPosition.roleFit.low'
    },
    medium: {
        id: 'app.assessment.jobPosition.roleFit.medium'
    },
    high: {
        id: 'app.assessment.jobPosition.roleFit.high'
    },
    veryHigh: {
        id: 'app.assessment.jobPosition.roleFit.veryHigh'
    },
    tooltip: {
        id: 'app.general.roleFit.description'
    }
});
