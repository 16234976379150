import {defineMessages} from 'react-intl';

export default defineMessages({
    codingTest: {
        id: 'app.general.codingTest'
    },
    logicTest: {
        id: 'app.general.logicTest'
    },
    personalityTest: {
        id: 'app.general.personalityTest'
    },
    noData: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResults.roleFitBadge.noData'
    },
    missingResults: {
        id: 'app.roleFit.information.resultsmissing'
    },
    score: {
        id: 'app.general.score'
    },
    results: {
        id: 'app.general.results'
    },
    roleFitDescription: {
        id: 'app.general.roleFit.description'
    },
    interviewTitle: {
        id: 'app.assessment.jobPosition.assessmentMethods.interview.title'
    }
});
