import styled from 'styled-components';

import {SpacingSmall, SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const Header = styled.div`
    margin-top: ${SpacingXxsmall};
    margin-bottom: ${SpacingSmall};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
