import styled from 'styled-components';

import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {SpacingSmall} from '@/componentLibrary/tokens/variables';

export const NameFormField = styled(FormField)`
    width: 50%;
`;

export const NamesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: ${SpacingSmall};
`;
