import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.rejectInvitationModal.title'
    },
    info: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.rejectInvitationModal.info'
    },
    reject: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.rejectInvitationModal.reject'
    },
    cancel: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.rejectInvitationModal.cancel'
    }
});
