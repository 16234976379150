import styled from 'styled-components';

import {
    BorderWidthBase,
    BorderWidthEmphasis,
    ColorBaseGrey600,
    SpacingMedium,
    SpacingXsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const EmailPreviewModalWrapper = styled.div`
    overflow-y: hidden;
    padding: 0;
`;

export const Wrapper = styled.div`
    margin: ${SpacingMedium};
    display: flex;
    flex-direction: column;
    height: 100%;
`;
export const EmailSubjectWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: ${SpacingXxxsmall} ${SpacingXsmall};
    border: ${BorderWidthBase} solid rgb(0 0 0 / 5%);
    border-bottom: ${BorderWidthEmphasis} solid ${ColorBaseGrey600};
`;
