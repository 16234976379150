import {FetchResult} from '@apollo/client';
import {useCallback, useState} from 'react';

import {DoCandidateForJobPositionProps} from '@/api/assessment/jobPositions/useAddCandidateForJobPosition';
import {
    AddCandidateForJobPositionMutation,
    CandidateInput,
    GetPublicJobPositionQuery
} from '@/api/types/__generated__/graphql';
import {logger} from '@/services/logrocket';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {isValidEmail} from '@/utils/validators';

import {ApplyFormData} from '../components/FormFields';
import {ERROR_CODES} from '../constants';
import {CREATED_FROM, ERROR_TYPES, FORM_STATES} from './constants';

export function isInvalidFormData(data: ApplyFormData) {
    const fieldHasEmptyValue = Object.values(data).some(value => !value);
    const email = data.email;
    const emailIsValid = isDefined(email) && isValidEmail(email);

    return fieldHasEmptyValue || !emailIsValid;
}

type Props = {
    doAddCandidate: ({
        candidateData,
        createdFrom,
        jobPositionId,
        invitationOptions
    }: DoCandidateForJobPositionProps) => Promise<
        FetchResult<AddCandidateForJobPositionMutation>
    >;
    setError: (error: keyof typeof ERROR_TYPES, value?: boolean) => void;
    clearErrors: () => void;
    triedToSubmit: boolean;
    setFormState: (formState: keyof typeof FORM_STATES, value?: boolean) => void;
    jobPosition: GetPublicJobPositionQuery['publicJobPosition'];
};

export function useApplyFormLogic({
    doAddCandidate,
    setError,
    clearErrors,
    triedToSubmit,
    setFormState,
    jobPosition
}: Props) {
    const [formData, setFormData] = useState<ApplyFormData>({
        firstName: '',
        lastName: '',
        email: ''
    });

    const fieldHasError = useCallback(
        (field: keyof ApplyFormData) => {
            return !formData[field] && triedToSubmit;
        },
        [formData, triedToSubmit]
    );

    const handleTextInputChange = useCallback(
        e => {
            setFormState(FORM_STATES.TRIED_TO_SUBMIT, false);

            const {name, value} = e.target;

            if (name === 'email') {
                setError(ERROR_TYPES.EMAIL_ERROR, false);
            }

            const newFormData = Object.assign({}, formData, {[name]: value});
            setFormData(newFormData);

            if (isInvalidFormData(newFormData)) {
                setError(ERROR_TYPES.DISPLAY_WARNING);
            }
        },
        [formData, setError, setFormState]
    );

    const handleSubmit = useCallback(() => {
        setFormState(FORM_STATES.TRIED_TO_SUBMIT);
        clearErrors();

        if (isInvalidFormData(formData)) {
            setError(ERROR_TYPES.DISPLAY_WARNING);
            return;
        }

        if (!jobPosition?.jobPositionId) {
            throw new Error('Job position id is required');
        }

        const data: CandidateInput = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email as string
        };

        doAddCandidate({
            candidateData: data,
            createdFrom: CREATED_FROM,
            jobPositionId: jobPosition?.jobPositionId
        })
            .then(({data: addCandidateData}) => {
                const {ok, errorMessage} = addCandidateData?.addCandidateForJobPosition ?? {};
                if (ok) {
                    setFormState(FORM_STATES.COMPLETED);
                } else {
                    throw new Error(errorMessage ?? '');
                }
            })
            .catch(error => {
                if (error.message === ERROR_CODES.INVALID_DATA_FOR_CANDIDATE) {
                    setError(ERROR_TYPES.EMAIL_ERROR);
                } else if (error.message === ERROR_CODES.COUNTRY_IS_BLOCKED_BY_ORGANIZATION) {
                    setError(ERROR_TYPES.COUNTRY_BLOCKED_ERROR);
                } else {
                    logger.error(error);
                }
            });
    }, [setError, clearErrors, doAddCandidate, formData, setFormState, jobPosition]);

    return {formData, fieldHasError, handleTextInputChange, handleSubmit};
}
