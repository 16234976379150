import {createGlobalStyle} from 'styled-components';

import {FontSizeSmall, SpacingSmall, SpacingXlarge} from '@/componentLibrary/tokens/variables';

const styled = {createGlobalStyle};
export const TopBannerStyle = styled.createGlobalStyle`
    body.with-banner .sidebar-container,
    body.with-banner #primary-header {
        top: 60px;
    }

    body.with-banner .edit-performance-review-sidebar {
        padding-top: 140px;
    }

    body.with-banner .secondary-navigation.is-fixed {
        top: 140px;
    }

    body.with-banner #root {
        padding-top: 60px;
    }

    .top-banner-wrapper {
        background-color: #20282f;
        top: 0;
        color: #fff;
        position: fixed;
        height: 60px;
        width: 100%;
        z-index: 101;
    }

    .top-banner-wrapper .top-banner-content {
        padding: ${SpacingSmall} 0;
        font-size: ${FontSizeSmall};
    }

    .top-banner-wrapper .top-banner-content p {
        line-height: 27px;
    }

    .top-banner-wrapper .container.is-fluid {
        margin: 0 auto;
        position: relative;
        max-width: none !important;
        padding-left: ${SpacingXlarge};
        padding-right: ${SpacingXlarge};
        width: 100%;
    }
`;
