import {GetInterviewRequestsForJobApplication_JobApplication_InterviewRequests_Employment} from '@/api/assessment/types';
import {GetCurrentEmployment_CurrentEmployment} from '@/api/organizations/types';

type CurrentEmployment = GetCurrentEmployment_CurrentEmployment;
type JobApplicationInterviewRequestEmployment =
    GetInterviewRequestsForJobApplication_JobApplication_InterviewRequests_Employment;
type Employment = CurrentEmployment | JobApplicationInterviewRequestEmployment;

type RequestInterviewModalProps = {
    jobApplicationIds: number[];
    preselectedInterviewIds?: number[];
    preselectedEmploymentIds?: number[];
    jobPositionId: number;
    onComplete?: (interviewRequestIds: number[]) => void;
    closeModal: () => void;
    skipSelectInterviews?: boolean;
    skipSelectInterviewers?: boolean;
    existingInterviewers?: Employment[];
};

enum SelectInterviewType {
    START_INTERVIEW = 'START_INTERVIEW',
    ASSIGN_INTERVIEWERS = 'ASSIGN_INTERVIEWERS'
}

export {RequestInterviewModalProps, SelectInterviewType, Employment};
