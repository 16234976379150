import {Action} from '@/services/ui/types';

import {
    SET_IS_MODAL_OPEN_VALUE,
    SET_MOBILE_NAV,
    SET_PAGE_TITLE,
    SET_UI_KEY_VALUE
} from './constants';
import {initialUi, setDocumentTitle} from './helpers';

export function ui(state = initialUi, action: Action) {
    switch (action.type) {
        case SET_UI_KEY_VALUE: {
            const {key, value} = action;

            return Object.assign({}, state, {
                [key]: value
            });
        }
        default:
            return state;
    }
}

export const initialPageVariables = {
    title: 'Home',
    backLink: null
};

export function pageVariables(state = initialPageVariables, action: Action) {
    switch (action.type) {
        case SET_PAGE_TITLE:
            setDocumentTitle(action.title);

            return Object.assign({}, state, {
                title: action.title,
                backLink: action.backLink
            });
        default:
            return state;
    }
}

export const initialMobile = {
    navOpen: false
};

export function mobile(state = initialMobile, action: Action) {
    switch (action.type) {
        case SET_MOBILE_NAV:
            return Object.assign({}, state, {
                navOpen: action.value
            });
        default:
            return state;
    }
}

export const initialModalOpen = {
    isModalOpen: false
};

export function modalOpen(state = initialModalOpen, action: Action) {
    switch (action.type) {
        case SET_IS_MODAL_OPEN_VALUE:
            return Object.assign({}, state, {
                isModalOpen: action.value
            });
        default:
            return state;
    }
}
