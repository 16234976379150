import {Underline} from '@tiptap/extension-underline';
import {
    AnyExtension,
    Content,
    Editor,
    Extensions,
    KeyboardShortcutCommand,
    useEditor
} from '@tiptap/react';
import {StarterKit} from '@tiptap/starter-kit';
import DOMPurify from 'dompurify';
import {useCallback, useEffect, useMemo, useState} from 'react';

import {getLinkWithText} from '@/componentLibrary/components/inputs/TextEditor/extensions/LinkWithText';
import {
    ExtensionsEnabledMap,
    ExtensionsOnClickMap,
    FormattingOptions,
    FormattingOptionsKey,
    LogicProps as Props
} from '@/componentLibrary/components/inputs/TextEditor/types';
import {
    getLinkTextUnderCursor,
    getSelectedText
} from '@/componentLibrary/components/inputs/TextEditor/utils';
import {isDefined} from '@/utils/typeGuards/isDefined';

export const useTextEditorLogic = ({
    content,
    showContentOnly,
    disabledFormattingOptions,
    onChange
}: Props) => {
    const [focused, setFocused] = useState(false);

    const extensionsEnabledMap = useMemo(() => {
        const toExtensionEnabledMap = (accumulator: ExtensionsEnabledMap, key: string) => {
            const enumKey = key as FormattingOptionsKey;
            return {
                ...accumulator,
                [enumKey]: !disabledFormattingOptions.includes(FormattingOptions[enumKey])
            };
        };
        return Object.keys(FormattingOptions).reduce<ExtensionsEnabledMap>(
            toExtensionEnabledMap,
            {}
        );
    }, [disabledFormattingOptions]);

    const {
        openModal: openLinkModal,
        closeModal: closeLinkModal,
        showModal: showLinkModal,
        link,
        linkText
    } = useLinkModalLogic();

    const onShortCut = ({editor}: {editor: Editor}) => {
        openLinkModal(editor);
        return true;
    };

    const LinkWithText = getLinkWithText({onShortCut: onShortCut as KeyboardShortcutCommand});

    const customExtensions = useMemo(() => {
        const isExtensionEnabled = (item: AnyExtension | false | undefined) =>
            item !== false && isDefined(item);

        return [
            extensionsEnabledMap[FormattingOptions.UNDERLINE] && Underline,
            extensionsEnabledMap[FormattingOptions.LINK] && LinkWithText
        ].filter(isExtensionEnabled) as Extensions;
    }, [extensionsEnabledMap, LinkWithText]);

    const extensions = [
        StarterKit.configure({
            bold: extensionsEnabledMap[FormattingOptions.BOLD] ? {} : false,
            italic: extensionsEnabledMap[FormattingOptions.ITALIC] ? {} : false,
            bulletList: extensionsEnabledMap[FormattingOptions.BULLET] ? {} : false,
            orderedList: extensionsEnabledMap[FormattingOptions.NUMBER] ? {} : false,
            code: extensionsEnabledMap[FormattingOptions.CODE] ? {} : false,
            codeBlock: extensionsEnabledMap[FormattingOptions.CODE_BLOCK] ? {} : false
        }),
        ...customExtensions
    ];

    const editor = useEditor({
        extensions,
        content: DOMPurify.sanitize(content),
        onUpdate: ({editor: _editor}) => onChange?.(DOMPurify.sanitize(_editor?.getHTML())),
        editable: !showContentOnly
    });

    useEffect(() => {
        if (!isDefined(editor)) {
            return;
        }
        if (editor.isDestroyed) {
            return;
        }
        if (editor.isFocused) {
            return;
        }

        const textEditorContent = DOMPurify.sanitize(content) as Content;
        editor.commands.setContent(textEditorContent);
    }, [editor, content]);

    const extensionsOnClickMap: ExtensionsOnClickMap = useMemo(
        () => ({
            [FormattingOptions.BOLD]: (event: React.MouseEvent) => {
                event.preventDefault();
                editor?.chain().focus().toggleBold().run();
            },
            [FormattingOptions.ITALIC]: (event: React.MouseEvent) => {
                event.preventDefault();
                editor?.chain().focus().toggleItalic().run();
            },
            [FormattingOptions.UNDERLINE]: (event: React.MouseEvent) => {
                event.preventDefault();
                editor?.chain().focus().toggleUnderline().run();
            },
            [FormattingOptions.LINK]: (event: React.MouseEvent) => {
                event.preventDefault();
                openLinkModal(editor);
            },
            [FormattingOptions.CODE]: (event: React.MouseEvent) => {
                event.preventDefault();
                editor?.chain().focus().toggleCode().run();
            },
            [FormattingOptions.CODE_BLOCK]: (event: React.MouseEvent) => {
                event.preventDefault();
                editor?.chain().focus().toggleCodeBlock().run();
            },
            [FormattingOptions.BULLET]: (event: React.MouseEvent) => {
                event.preventDefault();
                editor?.chain().focus().toggleBulletList().run();
            },
            [FormattingOptions.NUMBER]: (event: React.MouseEvent) => {
                event.preventDefault();
                editor?.chain().focus().toggleOrderedList().run();
            },
            [FormattingOptions.INDENT_DECREASE]: (event: React.MouseEvent) => {
                event.preventDefault();
                editor?.chain().focus().liftListItem('listItem').run();
            },
            [FormattingOptions.INDENT_INCREASE]: (event: React.MouseEvent) => {
                event.preventDefault();
                editor?.chain().focus().sinkListItem('listItem').run();
            }
        }),
        [editor, openLinkModal]
    );

    const setLinkInEditor = useCallback(
        (_link: string, _linkText: string) =>
            editor?.commands.setLinkWithText({link: _link, linkText: _linkText}),
        [editor]
    );

    const removeLinkInEditor = useCallback(
        () => editor?.chain().focus().extendMarkRange('link').unsetLink().run(),
        [editor]
    );

    return {
        editor,
        focused,
        setFocused,
        extensionsEnabledMap,
        extensionsOnClickMap,
        closeLinkModal,
        showLinkModal,
        link,
        linkText,
        setLinkInEditor,
        removeLinkInEditor
    };
};

const useLinkModalLogic = () => {
    const [showModal, setShowModal] = useState(false);
    const [link, setLink] = useState<string | undefined>(undefined);
    const [linkText, setLinkText] = useState<string | undefined>(undefined);

    const openModal = (_editor?: Editor | null) => {
        if (!isDefined(_editor)) {
            return;
        }
        const selectedText = getSelectedText(_editor);
        const linkTextUnderCursor = getLinkTextUnderCursor(_editor);
        const currentLink = _editor?.getAttributes('link').href;
        setLink(currentLink);
        setLinkText(selectedText || linkTextUnderCursor);
        setShowModal(true);
    };

    const closeModal = () => setShowModal(false);

    return {
        openModal,
        closeModal,
        showModal,
        link,
        linkText
    };
};
