import {isDefined} from '../typeGuards/isDefined';

export default function range(start = 0, stop?: number | null, step?: number | null) {
    if (typeof start !== 'number') {
        throw new Error('start must be a number');
    }
    if (isDefined(stop) && typeof stop !== 'number') {
        throw new Error('stop must be a number or null');
    }
    if (isDefined(step) && typeof step !== 'number') {
        throw new Error('step must be a number or null');
    }
    if (arguments.length > 3) {
        throw new Error('too many arguments');
    }

    if (isDefined(stop) && isDefined(step)) {
        return [...Array(stop / step).keys()].map(k => k * step);
    }

    if (!isDefined(step) && isDefined(stop)) {
        return [...Array(stop - start).keys()].map(k => k + start);
    }

    return [...Array(Math.abs(start)).keys()].map(k => (start < 0 ? -k : k));
}
