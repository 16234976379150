import {ConsentType} from '@/api/types/__generated__/graphql';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {Header} from '@/componentLibrary/components/layout/Header';
import {DisplaySmall} from '@/componentLibrary/components/typography/Display';
import {LINKS} from '@/links';
import {ProlificContentProps} from '@/pages/PersonalityDataCollection/pages/DataCollectionForm/components/ProlificContent/types';
import {AcceptConsentCheckbox} from '@/services/consent/components/AcceptConsentCheckbox';

import {useProlificFormLogic} from './useProlificFormLogic';

export function ProlificContent({createUser, creatingUser}: ProlificContentProps) {
    const {handleSubmit, acceptConsent, triedToSubmit, isConsentAccepted, phrases} =
        useProlificFormLogic(createUser);

    return (
        <form className="form" onSubmit={handleSubmit}>
            <Header>
                <DisplaySmall data-testid={'prolific-title'}>{phrases.formTitle}</DisplaySmall>
            </Header>
            <FormField>{phrases.prolificContent}</FormField>
            <FormField>
                <AcceptConsentCheckbox
                    type={ConsentType.TERMS_OF_SERVICE}
                    linkToFull={LINKS.terms}
                    onChange={acceptConsent}
                    showRequiredText={triedToSubmit && !isConsentAccepted}
                />
            </FormField>
            <FormField>
                <PrimaryButton
                    disabled={!isConsentAccepted}
                    type="submit"
                    style={{minWidth: '140px'}}
                    isLoading={creatingUser}
                    data-testid={'prolific-go-to-instructions'}
                >
                    {phrases.goToInstructions}
                </PrimaryButton>
            </FormField>
        </form>
    );
}
