import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {EmptyState} from '@/componentLibrary/blocks/EmptyState';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {Spinner} from '@/componentLibrary/components/spinners';
import {ActiveJobRecommendation} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation';
import messages from '@/pages/User/components/JobRecommendations/messages';
import {RecommendationRelevanceForm} from '@/pages/User/components/RecommendationRelevanceForm';
import {AcceptMarketplaceConsent} from '@/pages/common/marketplace/AcceptMarketplaceConsent';

import {useOrganizationInformation} from '@/api/organizations/useOrganizationInformation';
import {JobRecommendationsOverview} from '../../components/JobRecommendationsOverview';
import {OtherJobsSectionHeader} from '../../components/OtherJobsSectionHeader';
import {useOtherJobsSectionLogic} from './logic/useOtherJobsSectionLogic';

type Props = {
    organizationId: number;
};

export function OtherJobsSection({organizationId}: Props) {
    const {
        userId,
        showRelevanceForm,
        setShowRelevanceForm,
        relevanceParametersOptions,
        userRelevanceInformation,
        showUpdateUserRelevanceData,
        loading,
        error,
        pendingRecommendations,
        showOtherJobsSection,
        onSubmitForm,
        showAcceptPolicy,
        activeJobRecommendationState,
        setActiveJobRecommendationState,
        resetActiveJobRecommendation,
        onAcceptPolicy
    } = useOtherJobsSectionLogic();
    const {organization, loading: loadingOrganization} =
        useOrganizationInformation(organizationId);
    const {formatMessage} = useIntl();
    const updateJobProfileDescription = formatMessage(messages.updateJobProfileDescription);
    const updateJobProfileButton = formatMessage(messages.updateJobProfileButton);

    if (error) {
        return null;
    }

    if (!showOtherJobsSection) {
        return null;
    }

    if (loading || loadingOrganization) {
        return (
            <Wrapper>
                <OtherJobsSectionHeader />
                <Spinner isCentered data-testid="spinner" />
            </Wrapper>
        );
    }

    if (showAcceptPolicy) {
        return (
            <Wrapper>
                <OtherJobsSectionHeader />
                <AcceptMarketplaceConsent
                    organizationName={organization?.name}
                    onAccept={onAcceptPolicy}
                />
            </Wrapper>
        );
    }

    if (showRelevanceForm && userId) {
        return (
            <RecommendationRelevanceForm
                close={setShowRelevanceForm.bind(null, false)}
                userId={userId}
                relevanceParametersOptions={relevanceParametersOptions}
                userRelevanceInformation={userRelevanceInformation}
                onSubmit={onSubmitForm}
            />
        );
    }

    if (showUpdateUserRelevanceData) {
        return (
            <Wrapper>
                <OtherJobsSectionHeader />
                <EmptyState
                    titleText={''}
                    contentText={updateJobProfileDescription}
                    actionZone={
                        <PrimaryButton onClick={() => setShowRelevanceForm(true)}>
                            {updateJobProfileButton}
                        </PrimaryButton>
                    }
                ></EmptyState>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <OtherJobsSectionHeader />

            {activeJobRecommendationState && (
                <ActiveJobRecommendation
                    initialState={activeJobRecommendationState.initialState}
                    jobRecommendation={activeJobRecommendationState.jobRecommendation}
                    close={resetActiveJobRecommendation}
                />
            )}

            <JobRecommendationsOverview
                loading={loading}
                error={error}
                pendingRecommendations={pendingRecommendations}
                setActive={setActiveJobRecommendationState}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin: 20px auto;
    margin-bottom: 40px;
    max-width: 1000px;
`;
