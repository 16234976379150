import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.signup.createFreeTrialAccount.candidateInfo.title'
    },
    notice: {
        id: 'app.signup.createFreeTrialAccount.candidateInfo.description'
    },
    signIn: {
        id: 'app.signup.createFreeTrialAccount.candidateInfo.signInLink'
    },
    readMore: {
        id: 'app.signup.createFreeTrialAccount.candidateInfo.readMoreLink'
    }
});
