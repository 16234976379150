import {defineMessages} from 'react-intl';

export default defineMessages({
    mainSubjectHeader: {
        id: 'app.signup.createFreeTrialAccount.marketingContent.title'
    },
    feature1: {
        id: 'app.signup.createFreeTrialAccount.marketingContent.featureList.firstItem'
    },
    feature2: {
        id: 'app.signup.createFreeTrialAccount.marketingContent.featureList.secondItem'
    },
    feature3: {
        id: 'app.signup.createFreeTrialAccount.marketingContent.featureList.thirdItem'
    },
    feature4: {
        id: 'app.signup.createFreeTrialAccount.marketingContent.featureList.fourthItem'
    },
    feature5: {
        id: 'app.signup.createFreeTrialAccount.marketingContent.featureList.fifthItem'
    },
    feature6: {
        id: 'app.signup.createFreeTrialAccount.marketingContent.featureList.sixthItem'
    },
    feature7: {
        id: 'app.signup.createFreeTrialAccount.marketingContent.featureList.seventhItem'
    }
});
