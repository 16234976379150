import {DisplaySmall, Markdown} from '@/componentLibrary/components/typography';
import {
    ColorBaseGrey900,
    FontSizeSmall,
    LineHeightsCompact,
    SpacingLarge,
    SpacingMedium
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 68px 0 92px;

    @media (max-width: 768px) {
        padding: ${SpacingMedium};
    }
`;

export const FormWrapper = styled.div`
    max-width: 450px;
`;

export const TitleText = styled(DisplaySmall)`
    margin-bottom: ${SpacingLarge};
`;

export const DescriptionText = styled(Markdown)`
    font-size: ${FontSizeSmall};
    line-height: ${LineHeightsCompact};
    color: ${ColorBaseGrey900};
    margin-bottom: 30px;
`;
