import {useCallback} from 'react';
import {Redirect} from 'react-router-dom';

import {LandingPageLayout} from '@/componentLibrary/blocks/LandingPageLayout';
import loginPageBackground from '@/pages/Login/assets/loginPageBackground.jpg';

import {TOKEN_ERRORS} from '../../errors';
import {ExpiredToken} from './components/ExpiredToken';
import {InvalidToken} from './components/InvalidToken';
import {UnknownError} from './components/UnknownError';
import {ContentWrapper, InnerWrapper, OuterWrapper} from './styled';
import {Props} from './types';
import {getRedirectUrl} from './utils';

export const ValidateTokenError = ({errorMessage, userId, email}: Props) => {
    const renderError = useCallback(() => {
        switch (errorMessage) {
            case TOKEN_ERRORS.INVALID_TOKEN:
                return <InvalidToken />;
            case TOKEN_ERRORS.TOKEN_EXPIRED:
                return <ExpiredToken userId={userId} email={email} />;
            case TOKEN_ERRORS.USER_ALREADY_ACTIVATED:
                return <Redirect to={getRedirectUrl(email)} />;
            default:
                return <UnknownError />;
        }
    }, [email, errorMessage, userId]);

    return (
        <LandingPageLayout backgroundImage={loginPageBackground}>
            <OuterWrapper>
                <InnerWrapper>
                    <ContentWrapper>{renderError()}</ContentWrapper>
                </InnerWrapper>
            </OuterWrapper>
        </LandingPageLayout>
    );
};
