import {useQuery} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {VALID_EMAIL_ADDRESS} from '@/api/email/queries';
import {isValidEmail} from '@/utils/validators';

export function useEmailValidator(emailAddress: string) {
    const variables = {emailAddress: emailAddress};

    const {loading, error, data} = useQuery(VALID_EMAIL_ADDRESS, {
        variables,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !isValidEmail(emailAddress)
    });

    const getDomainFromEmail = useCallback(() => {
        if (!emailAddress.includes('@')) {
            return '';
        }
        return emailAddress.split('@')[1].toLowerCase().trimEnd();
    }, [emailAddress]);

    const isBlacklistedDomain = useMemo(() => {
        if (!data || !('validEmailAddress' in data)) {
            return false;
        }

        return !data.validEmailAddress;
    }, [data]);

    return {isBlacklistedDomain, getDomainFromEmail, loading, error};
}
