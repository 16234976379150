import styled, {css} from 'styled-components';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {
    BorderRadiusBaseMedium,
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

import {deviceBreakPointTokens} from '../../tokens/deviceBreakpoints';
import {Icons} from '../icons/constants';
import {P2, S2} from '../typography';
import {BannerButton} from './components/BannerButton';
import {BannerType} from './constants';
import {useBannerIconAndStyle} from './hooks/useBannerIconAndStyle';
import {
    BannerProps,
    ContentWrapperProps,
    HasRegularActionProp,
    OuterWrapperProps
} from './types';

export function Banner({
    type = BannerType.INFO,
    children,
    action,
    customAction,
    dismiss,
    customDismiss,
    noIcon,
    noFullwidth,
    title,
    customIcon,
    isPromo,
    ...rest
}: BannerProps) {
    const {bgColor, iconColor, borderColor, icon} = useBannerIconAndStyle({type});

    const noTitle = !title;
    const hasRegularAction = Boolean(action || customAction || dismiss || customDismiss);

    return (
        <BannerWrapper
            bgColor={bgColor}
            borderColor={borderColor}
            noFullwidth={noFullwidth}
            {...rest}
        >
            <InnerWrapper>
                {(!noIcon || !!customIcon) && (
                    <Icon fill={iconColor} icon={customIcon || icon} />
                )}
                <ContentActionWrapper hasRegularAction={hasRegularAction}>
                    <ContentWrapper hasRegularAction={hasRegularAction} noTitle={noTitle}>
                        <>
                            {!noTitle && <S2>{title}</S2>}
                            {children}
                        </>
                    </ContentWrapper>
                    <ActionWrapper hasRegularAction={hasRegularAction}>
                        {!!action && <BannerButton isPromo={isPromo} action={action} />}
                        {!!customAction && customAction}
                        {!!dismiss && (
                            <Icon icon={Icons.CLOSE} isHoverable isAction onClick={dismiss} />
                        )}
                        {!!customDismiss && customDismiss}
                    </ActionWrapper>
                </ContentActionWrapper>
            </InnerWrapper>
        </BannerWrapper>
    );
}

export const BannerWrapper = styled.div<OuterWrapperProps>`
    background-color: ${({bgColor}) => bgColor};
    border-radius: ${BorderRadiusBaseMedium};
    border: 1px solid ${({borderColor}) => borderColor};
    ${({noFullwidth}) => (noFullwidth ? 'display: inline;' : 'width: 100%;')}
`;

export const InnerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${SpacingSmall};
    padding: 12px;
    width: 100%;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        gap: ${SpacingXxsmall};
    }
`;

const ContentActionWrapper = styled.div<HasRegularActionProp>`
    width: 100%;
    display: flex;
    align-items: center;
    ${({hasRegularAction}) => hasRegularAction && `gap: ${SpacingLarge};`}

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        gap: ${SpacingXxsmall};
        ${({hasRegularAction}) => hasRegularAction && 'flex-direction: column;'}
    }
`;

const ContentWrapper = styled(P2).attrs({as: 'div'})<ContentWrapperProps>`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const ActionWrapper = styled.div<HasRegularActionProp>`
    height: 100%;
    display: flex;
    gap: ${SpacingMedium};
    align-items: center;

    ${({hasRegularAction}) =>
        hasRegularAction &&
        css`
            @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
                padding: 0;
                width: 100%;

                button {
                    padding: ${SpacingXxxsmall};
                    display: flex;
                    width: 100%;
                }
            }
        `}
`;
