import {CheckBox} from '@/componentLibrary/components/controls/CheckBox';
import {Caption} from '@/componentLibrary/components/typography';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useMemo} from 'react';

import {DropdownMenuItem} from '../DropdownMenuItem';
import {MenuItemWrapper} from '../styled';
import {hasCustomComponent, hasSubLabel} from './functions';
import {CheckBoxWrapper} from './styled';
import {DefaultMenuItemProps} from './types';

export const DefaultMenuItem = <T,>({
    isDisabled,
    isHighlighted,
    isSelected,
    getItemProps,
    item,
    index,
    itemToString,
    onClick,
    customComponent,
    showCheckboxes
}: DefaultMenuItemProps<T>) => {
    const menu = useMemo(() => {
        return hasCustomComponent(item) && item.useCustomComponent && customComponent ? (
            customComponent(item)
        ) : (
            <div>
                <P2>{item && itemToString ? itemToString(item) : ''}</P2>
                {hasSubLabel(item) && <Caption color={ColorFgSubtle}>{item.subLabel}</Caption>}
            </div>
        );
    }, [item, itemToString, customComponent]);

    const itemProps = getItemProps({
        item,
        index,
        onClick
    });

    return (
        <DropdownMenuItem
            {...itemProps}
            isHighlighted={isHighlighted}
            isDisabled={isDisabled}
            components={{
                Wrapper: MenuItemWrapper
            }}
            showCheckboxes={showCheckboxes}
            isSelected={isSelected}
        >
            {showCheckboxes ? (
                <CheckBoxWrapper>
                    <CheckBox value={isSelected} onChange={itemProps.onClick} />
                    {menu}
                </CheckBoxWrapper>
            ) : (
                menu
            )}
        </DropdownMenuItem>
    );
};
