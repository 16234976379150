import {Editor} from '@tiptap/react';
import {FC} from 'react';

import {Separator} from '@/componentLibrary/components/Separator';
import {Tooltip} from '@/componentLibrary/components/Tooltips/Tooltip';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {FormattingOptions} from '@/componentLibrary/components/inputs/TextEditor/types';

import {useTextEditorMenuLogic} from './logic';
import {SeparatorWrapper, Wrapper} from './styled';
import {Props} from './types';

const MenuSeparator = () => {
    return (
        <SeparatorWrapper>
            <Separator isHorizontal={false} />
        </SeparatorWrapper>
    );
};

const EditorIcon = ({
    editor,
    icon,
    onClick,
    isActive,
    tooltipContent
}: {
    editor: Editor;
    icon: Icons;
    onClick?: (event: React.MouseEvent) => void;
    isActive?: string | null;
    tooltipContent: string;
}) => (
    <Tooltip
        placement="top"
        hasArrow
        content={tooltipContent}
        trigger={
            <Icon
                size={14}
                isAction
                icon={icon}
                onClick={onClick}
                isActive={isActive ? editor.isActive(isActive) : false}
            />
        }
    />
);

export const TextEditorMenu: FC<Props> = ({
    editor,
    extensionsEnabledMap,
    extensionsOnClickMap
}) => {
    const phrases = useTextEditorMenuLogic();
    if (!editor) {
        return null;
    }

    const someListFormattingEnabled =
        extensionsEnabledMap[FormattingOptions.BULLET] ||
        extensionsEnabledMap[FormattingOptions.NUMBER];

    const someCodeFormattingEnabled =
        extensionsEnabledMap[FormattingOptions.CODE] ||
        extensionsEnabledMap[FormattingOptions.CODE_BLOCK];

    return (
        <Wrapper onMouseDown={e => e.preventDefault()}>
            {extensionsEnabledMap[FormattingOptions.BOLD] && (
                <EditorIcon
                    editor={editor}
                    icon={Icons.FORMAT_BOLD}
                    onClick={extensionsOnClickMap[FormattingOptions.BOLD]}
                    isActive="bold"
                    tooltipContent={phrases.bold}
                />
            )}
            {extensionsEnabledMap[FormattingOptions.ITALIC] && (
                <EditorIcon
                    editor={editor}
                    icon={Icons.FORMAT_ITALIC}
                    onClick={extensionsOnClickMap[FormattingOptions.ITALIC]}
                    isActive="italic"
                    tooltipContent={phrases.italic}
                />
            )}
            {extensionsEnabledMap[FormattingOptions.UNDERLINE] && (
                <EditorIcon
                    editor={editor}
                    icon={Icons.FORMAT_UNDERLINED}
                    onClick={extensionsOnClickMap[FormattingOptions.UNDERLINE]}
                    isActive="underline"
                    tooltipContent={phrases.underline}
                />
            )}

            {extensionsEnabledMap[FormattingOptions.LINK] && <MenuSeparator />}

            {extensionsEnabledMap[FormattingOptions.LINK] && (
                <EditorIcon
                    editor={editor}
                    icon={Icons.LINK}
                    onClick={extensionsOnClickMap[FormattingOptions.LINK]}
                    isActive="link"
                    tooltipContent={phrases.link}
                />
            )}

            {someCodeFormattingEnabled && <MenuSeparator />}

            {extensionsEnabledMap[FormattingOptions.CODE] && (
                <EditorIcon
                    editor={editor}
                    icon={Icons.CODE}
                    onClick={extensionsOnClickMap[FormattingOptions.CODE]}
                    isActive="code"
                    tooltipContent={phrases.code}
                />
            )}
            {extensionsEnabledMap[FormattingOptions.CODE_BLOCK] && (
                <EditorIcon
                    editor={editor}
                    icon={Icons.CODE_BLOCKS}
                    onClick={extensionsOnClickMap[FormattingOptions.CODE_BLOCK]}
                    isActive="codeBlock"
                    tooltipContent={phrases.codeBlock}
                />
            )}

            {someListFormattingEnabled && <MenuSeparator />}

            {extensionsEnabledMap[FormattingOptions.BULLET] && (
                <EditorIcon
                    editor={editor}
                    icon={Icons.FORMAT_LIST_BULLETED}
                    onClick={extensionsOnClickMap[FormattingOptions.BULLET]}
                    isActive="bulletList"
                    tooltipContent={phrases.bulletList}
                />
            )}
            {extensionsEnabledMap[FormattingOptions.NUMBER] && (
                <EditorIcon
                    editor={editor}
                    icon={Icons.FORMAT_LIST_NUMBERED}
                    onClick={extensionsOnClickMap[FormattingOptions.NUMBER]}
                    isActive="orderedList"
                    tooltipContent={phrases.orderedList}
                />
            )}

            {someListFormattingEnabled && <MenuSeparator />}

            {someListFormattingEnabled && (
                <EditorIcon
                    editor={editor}
                    icon={Icons.FORMAT_INDENT_DECREASE}
                    onClick={extensionsOnClickMap[FormattingOptions.INDENT_DECREASE]}
                    tooltipContent={phrases.decreaseIndent}
                />
            )}
            {someListFormattingEnabled && (
                <EditorIcon
                    editor={editor}
                    icon={Icons.FORMAT_INDENT_INCREASE}
                    onClick={extensionsOnClickMap[FormattingOptions.INDENT_INCREASE]}
                    tooltipContent={phrases.increaseIndent}
                />
            )}
        </Wrapper>
    );
};
