import {useIntl} from 'react-intl';

import {useJobApplication} from '@/api/assessment/jobApplications/useJobApplication';
import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {InPageHeader} from '@/componentLibrary/components/InPageHeader';
import {TITLE_SIZE} from '@/componentLibrary/components/InPageHeader/types';
import {RoleFitV2Tag} from '@/componentLibrary/components/RoleFitTag/RoleFitV2Tag';
import {Section} from '@/componentLibrary/components/layout/Section';
import {H4, P2, S2, ScorePercent} from '@/componentLibrary/components/typography';
import {isMissingAllResults} from '@/pages/common/roleFit/utils';

import {ScoreCards} from './components/ScoreCards';
import {CandidateRoleFitContext} from './context';
import messages from './messages';
import {ScoreResultsTitle, Wrapper} from './styled';
import {CandidateRoleFitV2PageProps} from './types';

export function CandidateRoleFitV2Page({userId, jobPositionId}: CandidateRoleFitV2PageProps) {
    const intl = useIntl();

    const {jobPosition, loading: loadingJobPosition} = useJobPosition(jobPositionId, {
        withPersonalityProfile: true,
        withInterview: true,
        withChallenge: true
    });

    const {jobApplication, loading: loadingJobApplication} = useJobApplication(
        jobPositionId,
        userId,
        {
            withRoleFitV2: true,
            withInterviews: true,
            withChallengeAssignment: true,
            withAutomatedScore: true,
            withInterviewScoresAndInterview: true
        }
    );

    if (loadingJobApplication || loadingJobPosition) {
        return <Section header={<H4 />} loading />;
    }

    const hasRoleFit = !!jobApplication && !!jobApplication.roleFitV2;

    if (!jobApplication || !jobPosition) {
        return null;
    }

    return (
        <CandidateRoleFitContext.Provider
            value={{
                jobPosition,
                jobApplication,
                userId
            }}
        >
            <Wrapper>
                <InPageHeader
                    title={{
                        content: intl.formatMessage(messages.title, {
                            positionName: jobPosition?.name
                        }),
                        size: TITLE_SIZE.LARGE
                    }}
                />
                <ScorePercent
                    score={jobApplication?.roleFitV2?.totalScore}
                    missingResults={isMissingAllResults(jobApplication?.roleFitV2)}
                    withMargin={false}
                >
                    <RoleFitV2Tag roleFit={jobApplication?.roleFitV2} />
                </ScorePercent>
                {hasRoleFit ? (
                    <>
                        <ScoreResultsTitle>
                            {intl.formatMessage(messages.assessmentScoresResults)}
                        </ScoreResultsTitle>
                        <ScoreCards />
                    </>
                ) : (
                    <div>
                        <S2>{intl.formatMessage(messages.noData)}</S2>
                        <P2>{intl.formatMessage(messages.noRolefitDescription)}</P2>
                    </div>
                )}
            </Wrapper>
        </CandidateRoleFitContext.Provider>
    );
}
