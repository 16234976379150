import {isDefined} from '@/utils/typeGuards/isDefined';

import {CODING_TEST_MODE} from './constants';

export const ASSESSMENT_INDEX_URL = '/o/assessment';
const JOB_POSITIONS_INDEX = `${ASSESSMENT_INDEX_URL}/job-positions`;
const TEST_PROFILES_INDEX = `${ASSESSMENT_INDEX_URL}/test-profiles`;
const INTERVIEW_TEMPLATES_INDEX = `${ASSESSMENT_INDEX_URL}/interview-templates`;
const PERSONALITY_PROFILES_INDEX = `${ASSESSMENT_INDEX_URL}/personality-profiles`;
const CODING_TESTS_INDEX = `${ASSESSMENT_INDEX_URL}/coding-tests`;

export const assessmentLinks = {
    jobPosition: (positionId: number | string) => `${JOB_POSITIONS_INDEX}/${positionId}`,
    jobPositionSettings: (positionId: number) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/settings`,
    assessmentProfile: (positionId: number) => `${JOB_POSITIONS_INDEX}/${positionId}/profile`,
    evaluateCandidates: (positionId: number) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/evaluate`,
    settings: (positionId: number) => `${JOB_POSITIONS_INDEX}/${positionId}/settings`,
    previewScorecard: (positionId: number | string) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/preview-scorecard`,
    previewInterview: (positionId?: number | string, interviewId?: number | string) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/${interviewId}/preview-interview`,
    previewInterviewTemplateWithPosition: (
        positionId: number | string,
        interviewTemplateId: string
    ) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/${interviewTemplateId}/preview-interview-template`,
    previewInterviewTemplate: (interviewTemplateId: string) =>
        `${JOB_POSITIONS_INDEX}/${interviewTemplateId}/preview-interview-template`,
    teamFit: (positionId: number) => `${JOB_POSITIONS_INDEX}/${positionId}/team`,
    teamFitDynamicsWithSettings: (positionId: number, settings: string) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/team/dynamics?settings=${settings}`,
    teamFitPersonalityWithSettings: (positionId: number, settings: string) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/team/personality?settings=${settings}`,
    teamFitDynamicsWithoutSettings: (positionId: number) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/team/dynamics`,
    teamFitPersonalityWithoutSettings: (positionId: number) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/team/personality`,
    candidatePage: (positionId: number | string, userId: number | string) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/candidate/${userId}`,
    candidatePageProfile: (
        positionId: number,
        userId: number,
        sharedResultsMetrics?: boolean
    ) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/candidate/${userId}/profile` +
        (sharedResultsMetrics ? '#shared-results-metrics' : ''),
    candidatePageOverview: (positionId: number, userId: number) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/candidate/${userId}/overview`,
    candidatePageRoleFit: (positionId: number, userId: number) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/candidate/${userId}/role-fit`,
    candidatePageInterview: (positionId: number, userId: number) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/candidate/${userId}/interview`,
    candidatePageTestResults: (positionId: number, userId: number) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/candidate/${userId}/psychometric-tests`,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    candidatePageCodingTest: (positionId: any, userId: any) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/candidate/${userId}/coding-test`,
    candidatePageLogicTest: (positionId: number, userId: number) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/candidate/${userId}/logic-test`,
    candidatePagePersonalityTest: (positionId: number, userId: number) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/candidate/${userId}/personality-test`,
    candidatePageInterviewCriteria: (positionId: number, userId: number) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/candidate/${userId}/interview-criteria`,
    allJobPositions: () => `${JOB_POSITIONS_INDEX}/all`,
    archivedJobPositions: () => `${JOB_POSITIONS_INDEX}/archive`,
    testProfiles: () => `${TEST_PROFILES_INDEX}`,
    testProfile: (testProfileId: string) => `${TEST_PROFILES_INDEX}/${testProfileId}`,
    testProfileCriteria: (testProfileId: string) =>
        `${TEST_PROFILES_INDEX}/${testProfileId}/criteria`,
    testProfileJobPositions: (testProfileId: string) =>
        `${TEST_PROFILES_INDEX}/${testProfileId}/job-positions`,
    testProfileSettings: (testProfileId: string) =>
        `${TEST_PROFILES_INDEX}/${testProfileId}/settings`,
    candidates: () => `${ASSESSMENT_INDEX_URL}/candidates`,
    activity: () => `${ASSESSMENT_INDEX_URL}/activity`,
    myInterviews: () => `${ASSESSMENT_INDEX_URL}/interviews`,
    oldMyScorecards: () => `${ASSESSMENT_INDEX_URL}/scorecards`,
    personalityProfiles: () => `${PERSONALITY_PROFILES_INDEX}`,
    personalityProfile: (personalityProfileId: string) =>
        `${PERSONALITY_PROFILES_INDEX}/${personalityProfileId}`,
    interviewTemplates: () => `${INTERVIEW_TEMPLATES_INDEX}`,
    interviewTemplate: (interviewTemplateId: string) =>
        `${INTERVIEW_TEMPLATES_INDEX}/${interviewTemplateId}`,
    interview: (interviewRequestId: string) =>
        `${ASSESSMENT_INDEX_URL}/interviews/${interviewRequestId}`,
    scorecard: (interviewRequestId?: number | string) =>
        `${ASSESSMENT_INDEX_URL}/scorecards/${interviewRequestId}`,
    interviewScoreCard: (interviewRequestId: string, redirect?: string) => {
        const redirectParam = isDefined(redirect) ? `?redirect=${redirect}` : '';
        return `${ASSESSMENT_INDEX_URL}/interviews/${interviewRequestId}/score-card${redirectParam}`;
    },
    candidateScorecard: (interviewRequestId?: number | string, redirect?: string) => {
        const redirectParam = isDefined(redirect) ? `?redirect=${redirect}` : '';
        return `${ASSESSMENT_INDEX_URL}/scorecards/${interviewRequestId}/candidate-scorecard${redirectParam}`;
    },
    codingTests: () => `${CODING_TESTS_INDEX}`,
    allCodingTests: () => `${CODING_TESTS_INDEX}/all`,
    archivedCodingTests: () => `${CODING_TESTS_INDEX}/archive`,
    codingTest: (challengeId: string) => `${CODING_TESTS_INDEX}/${challengeId}`,
    createCodingTest: () => `${CODING_TESTS_INDEX}?mode=${CODING_TEST_MODE.CREATE}`,
    duplicateCodingTest: (challengeId: string) =>
        `${CODING_TESTS_INDEX}/${challengeId}?mode=${CODING_TEST_MODE.DUPLICATE}`,
    codingTestAccess: (id: string) =>
        `${CODING_TESTS_INDEX}/(challenge|assignment)/${id}/access`,
    criteria: `${ASSESSMENT_INDEX_URL}/criteria`,
    competencies: `${ASSESSMENT_INDEX_URL}/competencies`,
    interviewContent: `${ASSESSMENT_INDEX_URL}/interview-content`,
    interviewContentLibraryItem: (interviewContentLibraryItemId: string) =>
        `${assessmentLinks.interviewContent}/${interviewContentLibraryItemId}`,
    jobAssessmentRecommender: (positionId: number | string) =>
        `${JOB_POSITIONS_INDEX}/${positionId}/recommender`
};
