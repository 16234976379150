import styled from 'styled-components';

import {P1} from '@/componentLibrary/components/typography';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingMedium} from '@/componentLibrary/tokens/variables';

export const InstructionImage = styled.img`
    min-height: 200px;
    margin: 0 auto;
    padding-bottom: 0;

    @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
        min-height: 360px;
    }
`;
export const DescriptionWrapper = styled(P1)`
    padding-top: ${SpacingMedium};
`;
