import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {SAVE_ANSWER_FOR_PERSONALITY_TEST_V2} from '@/api/personalityTests/mutations';
import {SaveAnswerForPersonalityTestV2Mutation} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';

export type SaveAnswerForPersonalityTestCallbackInput = {
    questionId: string;
    value: number;
};

export function useSaveAnswerForPersonalityTestV2(
    personalityTestId: string
): MutationHookReturnType<
    SaveAnswerForPersonalityTestV2Mutation,
    SaveAnswerForPersonalityTestCallbackInput
> {
    const [mutate, {error, loading}] = useMutation(SAVE_ANSWER_FOR_PERSONALITY_TEST_V2);

    const saveAnswerV2 = useCallback(
        data => {
            const saveAnswerForPersonalityTestInput = {
                personalityTestId,
                questionId: data.questionId,
                value: data.value
            };
            return mutate({variables: {saveAnswerForPersonalityTestInput}});
        },
        [mutate, personalityTestId]
    );

    return [saveAnswerV2, {error, loading}];
}
