import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {RECREATE_SHARED_LOGIC_TEST} from '@/api/logicTestIrt/mutations';

import {RecreateSharedLogicTestMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';

export function useRecreateSharedLogicTest(
    organizationId: number
): MutationHookReturnType<RecreateSharedLogicTestMutation> {
    const [mutate, {error, loading}] = useMutation(RECREATE_SHARED_LOGIC_TEST);
    const recreateSharedLogicTest = useCallback(() => {
        return mutate({variables: {data: {organizationId}}});
    }, [organizationId, mutate]);

    return [recreateSharedLogicTest, {error, loading}];
}
