import {gql} from '../types/__generated__';

export const GET_ORGANIZATION_QUERY = gql(`
    query getOrganizationInfo($id: Int!) {
        organization(id: $id) {
            id
            organizationId
            aggregateVersion
            name
            logo
            defaultLanguage
            externalPrivacyPolicyUrl
            securityPointOfContactEmail
            emailSenderSignature
            enableLogicTestForNewJobPositions
            enablePersonalityTestForNewJobPositions
            defaultPublicPageTitle
            defaultPublicPageInstructions
            jobRecommendationsEnabled
            enforcedAuthenticationMethod
            publicPageNameEnabled
            publicPagePrivacyNoticeEnabled
            publicPageColorHeader
            publicPageColorHeaderEnabled
            publicPageLogoEnabled
            organizationOktaConfiguration {
                domain
                clientId
            }
        }
    }
`);

export const GET_AUTHENTICATED_ORGANIZATION_QUERY = gql(`
    query getAuthenticatedOrganization {
        authenticatedOrganization {
            id
            organizationId
            created
            name
            logo
            onboardingStartedAt
            tokens {
                name
                provider
                created
            }
        }
    }
`);

export const GET_ORGANIZATION_SUBSCRIPTION_QUERY = gql(`
    query getAuthenticatedOrganizationSubscription {
        authenticatedOrganization {
            id
            name
            organizationId
            isProductLed
            isVerified
            activeJobsNumber
            subscription {
                organizationId
                externalId
                source
                plan
                maxActiveJobPositions
                includedActiveJobPositions
                activatedJobPositions
                isActive
                isFreeTrial
                startDate
                endDate
            }
        }
    }
`);

export const GET_ORGANIZATION_MARKETPLACE_SETTINGS_QUERY = gql(`
    query getOrganizationMarketplaceSettings($id: Int!) {
        organization(id: $id) {
            id
            marketplaceSettings {
                organizationId
                autoPublishJobsToMarketplace
            }
        }
    }
`);

export const GET_ORGANIZATION_DATA_RETENTIONS_QUERY = gql(`
    query getOrganizationDataRetentions {
        authenticatedOrganization {
            id
            countryDataRetentions {
                countryCode
                retentionPeriod
            }
            jobApplicationRetentionPeriod
        }
    }
`);

export const GET_ORGANIZATION_CONFIGURATION_QUERY = gql(`
    query getOrganizationConfiguration {
        authenticatedOrganization {
            id
            organizationId
            name
            recruitment
            teamModel
            hideTeamInsightContent
            hasPersonalityCompetences
            displayPersonalityTest
            displayLogicTest
            onboardingStatus
            disableSharingTestsForCandidates
            isProductLed
            recruitment
            useUnregisteredCandidateFlow
            aggregateVersion
            jobApplicationRetentionPeriod
            countryDataRetentions {
                countryCode
                retentionPeriod
            }
        }
    }
`);

export const GET_ORGANIZATION_ONBOARDING_QUERY = gql(`
    query getOrganizationOnboarding {
        authenticatedOrganization {
            id
            name
            organizationId
            aggregateVersion
            onboardingStatus
            displayPersonalityTest
            displayLogicTest
            defaultTestProfilesKeys {
                testProfileKey
                testProfileId
            }
            recruitment
        }
    }
`);
export const GET_CURRENT_EMPLOYMENT = gql(`
    query getCurrentEmployment($withTeams: Boolean = false) {
        currentEmployment {
            id
            jobTitle
            isKeyMember
            introductionHasBeenShown
            user {
                id
                firstName
                lastName
                email
            }
            organization {
                id
                name
                currentPlan
            }
            accessGroup {
                id
                name
            }
            teamMemberships @include(if: $withTeams) {
                id
                isAdministrator
                team {
                    id
                }
            }
            appJourney
        }
    }
`);
export const FETCH_EMPLOYMENTS_FOR_ORGANIZATION = gql(`
    query fetchEmployments($fetchOptions: EmploymentsFetchOptionsInput, $withTeams: Boolean!) {
        authenticatedOrganization {
            id
            employmentsConnection(fetchOptions: $fetchOptions) {
                totalResults
                cursor {
                    hasMore
                    next
                }
                items {
                    id
                    created
                    jobTitle
                    user {
                        id
                        firstName
                        lastName
                        email
                        lastSeenAt
                    }
                    organizationRole {
                        id
                        name
                    }
                    teamMemberships @include(if: $withTeams) {
                        id
                        team {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`);
export const GET_ORGANIZATION_FEATURE_FLAGS_BY_EMPLOYMENT = gql(`
    query getOrganizationFeatureFlagsByEmployment {
        currentEmployment {
            organization {
                featureFlags
            }
        }
    }
`);
export const GET_DEFAULT_ASSESSMENT_WEIGHTS = gql(`
    query getDefaultAssessmentWeights {
        authenticatedOrganization {
            id
            defaultAssessmentWeights {
                codingTestWeight
                interviewWeight
                logicTestWeight
                personalityTestWeight
            }
        }
    }
`);

export const GET_ALVA_DEFAULT_ASSESSMENT_WEIGHTS = gql(`
    query AlvaDefaultAssessmentWeights {
        alvaDefaultAssessmentWeights {
            codingTestWeight
            interviewWeight
            logicTestWeight
            personalityTestWeight
        }
    }
`);
