import {parse} from 'qs';
import {useLocation} from 'react-router-dom';

import {capValue, roundValue} from '@/utils/misc';

import {LogicTestValidationIndicator} from '../components/LogicTestValidationIndicator';
import {OverviewHeader} from '../components/OverviewHeader';
import {ScoreOverview} from '../components/ScoreOverview';
import {IndicatorWrapper, ScoreWrapper, Wrapper} from './styled';
import {LogicTestReportOverviewProps} from './types';

export function LogicTestReportOverview({
    logicalAbilityEstimate,
    jobPositionId,
    isValidationRequested = false,
    isValidationCompleted = false,
    validationResultType = null,
    showAdminContent = false
}: LogicTestReportOverviewProps) {
    const location = useLocation();
    // @TODO Cleanup this url parsing
    const fillInDemographicsFormLater = parse(location.search, {
        ignoreQueryPrefix: true
    }).fillInLater;
    const fillInLaterUrl = location.search.includes('?')
        ? '&fillInLater=true'
        : '?fillInLater=true';
    const path = location.pathname + location.search;
    const redirectLink = fillInDemographicsFormLater ? path + fillInLaterUrl : path;
    const standardScore = roundValue(capValue(logicalAbilityEstimate.mu, 1, 10), 0);

    return (
        <Wrapper>
            <OverviewHeader
                redirectLink={redirectLink}
                logicTestId={logicalAbilityEstimate.logicTestId}
                jobPositionId={jobPositionId}
            />
            <ScoreWrapper>
                <ScoreOverview
                    standardScore={standardScore}
                    showAdminContent={showAdminContent}
                />
            </ScoreWrapper>
            <IndicatorWrapper>
                <LogicTestValidationIndicator
                    isValidationRequested={isValidationRequested}
                    isValidationCompleted={isValidationCompleted}
                    validationResultType={validationResultType}
                />
            </IndicatorWrapper>
        </Wrapper>
    );
}
