import {useMemo} from 'react';

import {TrComponent, TrGroupComponent} from '../components/TrComponents';

export function useTablePropsLogic({
    onRowClick,
    tableProps,
    loadingTableProps,
    onDeselectAll
}) {
    const isRowClickEnabled = !!onRowClick;

    const defaultTableProps = useMemo(() => {
        return {
            className: 'main-theme-v2',
            filterable: false,
            resizable: true,
            sortable: true,
            showPagination: false,
            showPageSizeOptions: false,
            TrComponent: isRowClickEnabled ? TrComponent : undefined,
            TrGroupComponent: isRowClickEnabled ? TrGroupComponent : undefined
        };
    }, [isRowClickEnabled]);

    const loadingProps = useMemo(() => {
        return Object.assign({}, tableProps, defaultTableProps, loadingTableProps);
    }, [defaultTableProps, tableProps, loadingTableProps]);

    const normalTableProps = useMemo(() => {
        defaultTableProps.onPageChange = onDeselectAll;
        defaultTableProps.onSortedChange = onDeselectAll;
        defaultTableProps.onFilteredChange = onDeselectAll;
        defaultTableProps.getTrProps = (_state, row) => ({
            onClick: (e, handleOriginal) => {
                if (isRowClickEnabled) {
                    onRowClick(row.original, e.ctrlKey || e.metaKey);
                }
                if (handleOriginal) {
                    handleOriginal();
                }
            }
        });

        if (isRowClickEnabled) {
            defaultTableProps.getTableProps = () => ({
                style: {
                    overflow: 'visible'
                }
            });
            defaultTableProps.getTbodyProps = () => ({
                style: {
                    overflow: 'visible'
                }
            });
        }

        return Object.assign({}, defaultTableProps, tableProps);
    }, [defaultTableProps, isRowClickEnabled, onRowClick, tableProps, onDeselectAll]);

    return {
        loadingProps,
        normalTableProps
    };
}
