import {useCallback, useEffect} from 'react';
import {UseConsentCheckboxChangeLogicProps} from './types';

export function useConsentCheckboxChangeLogic({
    type,
    onChange
}: UseConsentCheckboxChangeLogicProps) {
    const callOnChange = useCallback(
        checked => onChange({type, accepted: checked}),
        [type, onChange]
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: once on start only
    useEffect(() => {
        callOnChange(false);
    }, []);

    const handleChange = useCallback(checked => callOnChange(checked), [callOnChange]);

    return {handleChange};
}
