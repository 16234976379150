import {isDefined} from '@/utils/typeGuards/isDefined';

import {BulletContent, BulletContentCaption, Marker} from '../styled';
import {DonutLegendProps} from '../types';

export const LegendCaptions = ({
    legendOptions
}: {
    legendOptions: DonutLegendProps['legendOptions'];
}) => {
    if (!isDefined(legendOptions)) {
        return null;
    }
    const {showValue, labelCaption, valueCaption} = legendOptions;
    const showValueCaption = isDefined(valueCaption) && isDefined(showValue);
    const showLabelCaption = isDefined(labelCaption);
    return showLabelCaption || showValueCaption ? (
        <BulletContent>
            <Marker />
            <BulletContentCaption>{labelCaption ?? ''}</BulletContentCaption>
            {showValueCaption && <BulletContentCaption>{valueCaption}</BulletContentCaption>}
        </BulletContent>
    ) : null;
};
