import {isValidUrl} from '@/utils/validators';

export const EMPTY_STRING = '';

const isEmptyString = (value?: string) => value === EMPTY_STRING;

export const isValidLink = (url: string) => {
    return !isEmptyString(url) && isValidUrl(url);
};

export const isValidLinkWithoutProtocol = (url: string) => {
    const pattern = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    return pattern.test(url);
};

export const addProtocol = (url: string) => `https://${url}`;

export const isValidLinkText = (text: string) => !isEmptyString(text);
