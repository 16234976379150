import React, {ReactNode, useMemo} from 'react';

import {PageHeader} from '@/componentLibrary/components/PageHeader';
import {Tab} from '@/componentLibrary/components/Tabs/TabBar/types';
import {isArrayWithItems} from '@/utils/typeGuards/isArrayWithItems';

import {BodyWrapper, PrimaryBannerWrapper, StickyHeaderWrapper, Wrapper} from './styled';
import {renderTabBar, renderTabs} from './utils';

interface PageLayoutProps {
    title: string | ReactNode;
    backLink?: string;
    primaryAction?: ReactNode;
    secondaryAction?: ReactNode;
    children?: ReactNode | ReactNode[];
    tabs?: Tab[];
    redirectPath?: string;
    fullWidth?: boolean;
    primaryBanner?: ReactNode;
    stickyHeader?: boolean;
    tabsEndButton?: ReactNode;
    floatingButton?: ReactNode;
    spotlightTabs?: boolean;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
    title,
    backLink,
    primaryAction,
    secondaryAction,
    children,
    tabs,
    redirectPath,
    fullWidth = true,
    primaryBanner,
    stickyHeader,
    tabsEndButton,
    floatingButton,
    spotlightTabs
}) => {
    const shouldRenderTabs = useMemo(() => {
        return isArrayWithItems(tabs);
    }, [tabs]) as boolean;

    return (
        <Wrapper $fullWidth={fullWidth}>
            <StickyHeaderWrapper $stickyHeader={stickyHeader}>
                <PageHeader
                    title={title}
                    backLink={backLink}
                    primaryAction={primaryAction}
                    secondaryAction={secondaryAction}
                />
                <PrimaryBannerWrapper>{primaryBanner}</PrimaryBannerWrapper>
                {renderTabBar(shouldRenderTabs, tabs, tabsEndButton, spotlightTabs)}
            </StickyHeaderWrapper>

            <BodyWrapper>
                {floatingButton && <div className={'float-right'}>{floatingButton}</div>}
                {renderTabs(shouldRenderTabs, tabs, redirectPath)}
                {children}
            </BodyWrapper>
        </Wrapper>
    );
};
