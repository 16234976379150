import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {
    RegisterUserMutation,
    RegisterUserMutationVariables
} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {REGISTER_USER} from './mutations';

export function useRegisterUser(
    token: string
): MutationHookReturnType<RegisterUserMutation, RegisterUserMutationVariables> {
    const [mutate, {error, loading}] = useMutation(REGISTER_USER);

    const doRegisterUser = useCallback(
        data => mutate({variables: {token, data}}),
        [token, mutate]
    );

    return [doRegisterUser, {error, loading}];
}
