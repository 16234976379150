import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {LeaveTheTestModalProps} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTesting/LeaveTheTestModal/types';

import {ConfirmAlert} from '@/componentLibrary/blocks/Modals/Alert/ConfirmAlert';
import {ModalEmpty} from '@/componentLibrary/components/modals/ModalEmpty';
import messages from './messages';

export function LeaveTheTestModal({leaveTheTest, close}: LeaveTheTestModalProps) {
    const phrases = useExtractPhraseConstants(messages);

    return (
        <ModalEmpty>
            <ConfirmAlert
                title={phrases.title}
                subtitle={phrases.description}
                leftButton={phrases.leaveTheTest}
                rightButton={phrases.continue}
                onLeftAction={leaveTheTest}
                onRightAction={close}
            />
        </ModalEmpty>
    );
}
