import {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';

import {ScoreBar} from '@/componentLibrary/components/charts/ScoreBar';
import {ColorFgMuted} from '@/componentLibrary/tokens/variables';
import {RoleFitScore} from '@/pages/Organization/pages/Assessment/components/RoleFitScore';

import messages from './messages';
import {CardWrapper, ScoreBarsWrapper, ScoreGroupWrapper, Title} from './styled';
import {InterviewsScoresProps} from './types';

export const InterviewsScores = ({
    interviews,
    interviewAggregatedScores,
    isLargeTitle
}: InterviewsScoresProps) => {
    const sortedInterviewAggregatedScores = useMemo(() => {
        if (!interviewAggregatedScores) {
            return [];
        }
        return interviewAggregatedScores;
    }, [interviewAggregatedScores]);

    const interviewScore = useMemo(() => {
        if (!interviewAggregatedScores || interviewAggregatedScores.length === 0) {
            return null;
        }
        return (
            interviewAggregatedScores.reduce((a, b) => a + (b?.score || 0), 0) /
            Math.max(interviewAggregatedScores.length, 1)
        );
    }, [interviewAggregatedScores]);
    return (
        <CardWrapper>
            <Title>
                <FormattedMessage {...messages.score} />
            </Title>
            <ScoreGroupWrapper>
                <RoleFitScore isInterviewScore customScore={interviewScore} />
                <ScoreBarsWrapper>
                    {interviews.map(interview => (
                        <div key={interview.id}>
                            <ScoreBar
                                barColor={ColorFgMuted}
                                title={interview.title}
                                score={
                                    sortedInterviewAggregatedScores.find(
                                        item => item.interview.id === interview.id
                                    )?.score
                                }
                                byFive
                                isLargeTitle={isLargeTitle}
                            />
                        </div>
                    ))}
                </ScoreBarsWrapper>
            </ScoreGroupWrapper>
        </CardWrapper>
    );
};
