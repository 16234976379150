import {Redirect} from 'react-router-dom';

import {useMyTestProgress} from '@/api/users/useMyTestProgress';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {LoadingScreen} from '@/componentLibrary/components/LoadingScreen';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {ModalEmpty} from '@/componentLibrary/components/modals/ModalEmpty';
import {H3} from '@/componentLibrary/components/typography';
import {Markdown} from '@/componentLibrary/components/typography/Markdown';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    ButtonWrapper,
    ContentWrapper,
    DescriptionWrapper,
    TextWrapper
} from '@/pages/LogicTestIrt/components/StartLogicTest/styled';
import {StartLogicTestProps} from '@/pages/LogicTestIrt/components/StartLogicTest/types';

import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {useGoBackOrGoToFallbackUrl} from '@/pages/Organization/pages/Assessment/hooks/useGoBackOrGoToFallbackUrl';
import messages from './messages';

export function StartLogicTest({
    onStartTest,
    loading,
    organizationId,
    goBackUrl
}: StartLogicTestProps) {
    const {
        user,
        loading: userTestProgressLoading,
        error,
        refetch
    } = useMyTestProgress(organizationId);
    const phrases = useExtractPhraseConstants(messages);

    const goBackOrGoToFallbackUrl = useGoBackOrGoToFallbackUrl(goBackUrl);

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    if (userTestProgressLoading) {
        return <LoadingScreen />;
    }

    if (
        user?.logicTestProgress?.sharedLogicTestId &&
        user.logicTestProgress.isSharedLogicTestCompleted === false
    ) {
        return <Redirect to={goBackUrl} />;
    }

    return (
        <ModalEmpty noCenterWrapper>
            <ContentWrapper>
                <TextWrapper>
                    <H3>{phrases.titleWarning}</H3>
                    <DescriptionWrapper>
                        <Markdown>{phrases.bulletsWarning}</Markdown>
                    </DescriptionWrapper>
                </TextWrapper>
                <ButtonWrapper>
                    <PrimaryButton onClick={onStartTest} isLoading={loading}>
                        {phrases.startTest}
                    </PrimaryButton>
                    <SecondaryButton onClick={goBackOrGoToFallbackUrl}>
                        {phrases.goBack}
                    </SecondaryButton>
                </ButtonWrapper>
            </ContentWrapper>
        </ModalEmpty>
    );
}
