import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {ModalEmpty} from '@/componentLibrary/components/modals/ModalEmpty';
import {DisplayMedium} from '@/componentLibrary/components/typography/Display';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    ContentWrapper,
    DescriptionWrapper,
    TextWrapper
} from '@/pages/LogicTestValidation/pages/LogicTestValidation/components/ContinueValidationTest/styled';
import {ContinueValidationTestProps} from '@/pages/LogicTestValidation/pages/LogicTestValidation/components/ContinueValidationTest/types';
import messages from './messages';

export const ContinueValidationTest = ({goToTest}: ContinueValidationTestProps) => {
    const phrases = useExtractPhraseConstants(messages);
    return (
        <ModalEmpty noCenterWrapper>
            <ContentWrapper>
                <TextWrapper>
                    <DisplayMedium>{phrases.title}</DisplayMedium>
                    <DescriptionWrapper>{phrases.description}</DescriptionWrapper>
                    <DescriptionWrapper>{phrases.warning}</DescriptionWrapper>
                    <DescriptionWrapper>{phrases.goodLuck}</DescriptionWrapper>
                </TextWrapper>
                <PrimaryButton onClick={goToTest}>{phrases.goToTest}</PrimaryButton>
            </ContentWrapper>
        </ModalEmpty>
    );
};
