import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    percentile: {
        id: 'app.testReports.stenScoreDescription.percentile'
    },
    percentileAndAbove: {
        id: 'app.testReports.stenScoreDescription.percentileAndAbove'
    },
    percentileAndBelow: {
        id: 'app.testReports.stenScoreDescription.percentileAndBelow'
    }
});
