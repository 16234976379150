import {Wrapper} from '@/pages/LogicTestIrt/components/Question/styled';
import {Pattern, QuestionProps} from '@/pages/LogicTestIrt/components/Question/types';
import {renderPatternsRow} from '@/pages/LogicTestIrt/components/Question/utils';

import questionMark from './assets/questionmark.svg';

export const Question = ({question, isSvg = false}: QuestionProps) => {
    const firstRow: Pattern[] = [
        {key: 'pattern1', data: question.pattern1},
        {key: 'pattern2', data: question.pattern2},
        {key: 'pattern3', data: question.pattern3}
    ];
    const secondRow: Pattern[] = [
        {key: 'pattern4', data: question.pattern4},
        {key: 'pattern5', data: question.pattern5},
        {key: 'pattern6', data: question.pattern6}
    ];
    const thirdRow: Pattern[] = [
        {key: 'pattern7', data: question.pattern7},
        {key: 'pattern8', data: question.pattern8},
        {key: 'questionMark', data: questionMark}
    ];

    return (
        <Wrapper>
            {renderPatternsRow(firstRow, isSvg)}
            {renderPatternsRow(secondRow, isSvg)}
            {renderPatternsRow(thirdRow, isSvg)}
        </Wrapper>
    );
};
