import {useState} from 'react';

import {CreateFreeTrialForm} from '@/pages/SignupV2/components/Email/CreateFreeTrialForm';
import {EmailConfirmationSent} from '@/pages/SignupV2/components/Email/EmailConfirmationSent';
import {RecaptchaTerms} from '@/pages/SignupV2/components/RecaptchaTerms';
import {WelcomeMessage} from '@/pages/SignupV2/components/WelcomeMessage';

export function CreateFreeTrial() {
    const [showEmailSent, setShowEmailSent] = useState(false);
    const [email, setEmail] = useState('');
    const [userId, setUserId] = useState<number>();

    const doShowEmailSent = (emailAddress: string, incomingUserId?: number) => {
        setEmail(emailAddress);
        setUserId(incomingUserId);
        setShowEmailSent(true);
    };

    let content;
    if (showEmailSent && userId) {
        content = <EmailConfirmationSent email={email} userId={userId} />;
    } else {
        content = (
            <>
                <WelcomeMessage />
                <CreateFreeTrialForm onEmailSent={doShowEmailSent} />
                <RecaptchaTerms />
            </>
        );
    }
    return content;
}
