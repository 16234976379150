import styled from 'styled-components';

import {ColorBgSubtle} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

export const TopWrapper = styled.div`
    position: sticky;
    top: 0;
    z-index: 1000;
`;

export const HeaderWrapper = styled.div<{cardsCompleted: boolean}>`
    height: 64px;
    background-color: #fff;
    box-shadow: 0 1px 1px rgb(0 0 0 / 8%);
    padding: 0 20px;
    margin-bottom: 1px;
    filter: ${props => (props.cardsCompleted ? null : 'blur(100px)')};
`;

export const BodyWrapper = styled.div<{cardsCompleted: boolean}>`
    flex: 1 1;
    display: flex;
    filter: ${props => (props.cardsCompleted ? null : 'blur(7px)')};

    @media only screen and (max-width: 800px) {
        flex-direction: column;
        flex: none;
        background-color: ${ColorBgSubtle};
        min-height: calc(100% - 64px);
    }
`;

const Column = styled.div`
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 800px) {
        flex: 0 1 auto;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
    }
`;

export const QuestionWrapper = styled(Column)`
    background-color: #fff;
`;

export const OptionsWrapper = styled(Column)`
    background-color: ${ColorBgSubtle};
`;

export const TimerWrapper = styled.div`
    @media only screen and (max-width: 370px) {
        margin-inline-start: 16px;
    }

    @media only screen and (min-width: 371px) and (max-width: 800px) {
        width: 344px;
        margin: 0 auto;
    }
`;

export const ToastContent = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ToastText = styled.div`
    margin: auto 0;
`;

export const ToastButton = styled.div`
    margin-inline-end: 16px;
`;

export const ButtonWrapper = styled.div`
    width: 192px;
    margin: 24px auto;

    @media only screen and (max-width: 370px) {
        width: 150px;
        margin: 24px auto 40px;
    }

    @media only screen and (min-width: 371px) and (max-width: 800px) {
        width: 175px;
        margin: 48px auto;
    }
`;
