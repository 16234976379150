import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {SendSignupEmailToUserMutation} from '../types/__generated__/graphql';
import {SEND_SIGNUP_EMAIL} from './mutations';

export function useSendSignupEmail(): MutationHookReturnType<
    SendSignupEmailToUserMutation,
    [number | undefined]
> {
    const [mutate, {error, loading}] = useMutation(SEND_SIGNUP_EMAIL);
    const doSendSignupEmail = useCallback(
        (userId?: number) => {
            if (!userId) {
                throw new Error('userId is required');
            }
            return mutate({variables: {data: {userId}}});
        },
        [mutate]
    );

    return [doSendSignupEmail, {error, loading}];
}
