import {useCallback, useMemo, useState} from 'react';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {CODING_TEST_SUBMIT_MODAL_MESSAGES} from '../messages';
import {AlertTextType} from '../types';

export const useSubmitCodingTestAlert = () => {
    const phrases = useExtractPhraseConstants(CODING_TEST_SUBMIT_MODAL_MESSAGES);
    const [showAlert, setShowAlert] = useState(false);

    const alertText: AlertTextType = useMemo(
        () => ({
            title: phrases.alertTitle,
            subtitle: phrases.alertSubtitle,
            leftButton: phrases.alertStayButtonText,
            rightButton: phrases.alertContinueButtonText
        }),
        [phrases]
    );
    const onShowAlert = useCallback(() => setShowAlert(true), []);
    const onHideAlert = useCallback(() => setShowAlert(false), []);

    return {alertText, showAlert, onShowAlert, onHideAlert};
};
