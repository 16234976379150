import {useState} from 'react';

import {Columns} from '@/componentLibrary/components/layout/Columns';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {personalityTestTakingMessages} from '@/pages/PersonalityTestIrt/messages';
import {
    ButtonColumn,
    ButtonWrapper,
    ProgressWrapper,
    StopTestColumn
} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/components/FormHeader/styled';
import {FormHeaderProps} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/components/FormHeader/types';

import {FormNavigation} from '../FormNavigation';
import {LeavingTestWarning} from './components/LeavingTestWarning';
import {PersonalityTestProgress} from './components/PersonalityTestProgress';

export const FormHeader = ({
    goBack,
    estimatedProgress,
    getPreviousQuestion = () => null, // Default value
    loadingPreviousQuestion,
    previousQuestionButtonHidden,
    isUsedInDataCollection
}: FormHeaderProps) => {
    const phrases = useExtractPhraseConstants(personalityTestTakingMessages);
    const [leaveTest, setLeaveTest] = useState(false);

    const showLeaveTestWarning = () => setLeaveTest(true);
    const hideLeaveTestWarning = () => setLeaveTest(false);
    const goToPreviousQuestion = () => getPreviousQuestion();

    const getColumns = () => {
        const percentage = estimatedProgress ? 100 * estimatedProgress : 0;

        return [
            {
                size: 3,
                grow: false,
                shrink: false,
                render: () => (
                    <StopTestColumn>
                        <ButtonWrapper>
                            <FormNavigation
                                onClick={showLeaveTestWarning}
                                isHidden={isUsedInDataCollection}
                            >
                                {phrases.stop}
                            </FormNavigation>
                        </ButtonWrapper>
                    </StopTestColumn>
                )
            },
            {
                size: 6,
                grow: false,
                shrink: false,
                render: () => (
                    <ButtonColumn>
                        <ButtonWrapper>
                            <FormNavigation
                                onClick={goToPreviousQuestion}
                                isLoading={loadingPreviousQuestion}
                                isHidden={previousQuestionButtonHidden}
                            >
                                {phrases.previousQuestion}
                            </FormNavigation>
                        </ButtonWrapper>
                    </ButtonColumn>
                )
            },
            {
                size: 3,
                grow: false,
                shrink: true,
                render: () => (
                    <ProgressWrapper>
                        <PersonalityTestProgress percentage={percentage} />
                    </ProgressWrapper>
                )
            }
        ];
    };

    return (
        <>
            <Columns columns={getColumns()} keepGridOnMobile />

            {leaveTest ? (
                <LeavingTestWarning cancel={hideLeaveTestWarning} leaveTest={goBack} />
            ) : null}
        </>
    );
};
