import {useQuery} from '@apollo/client';

import {GET_USER_WITH_RECOMMENDATIONS_SETTINGS} from '@/api/users/queries';

export function useUserWithRecommendations() {
    const {data, error, loading, refetch} = useQuery(GET_USER_WITH_RECOMMENDATIONS_SETTINGS, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });
    const user = data?.me ?? null;
    return {error, loading, user, refetch};
}
