import {DisplaySmallest} from '@/componentLibrary/components/typography/Display';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import messages from './messages';
import {TextWrapper} from './styled';

export function UnknownError() {
    const phrases = useExtractPhraseConstants(messages);

    return (
        <div>
            <DisplaySmallest>{phrases.title}</DisplaySmallest>
            <TextWrapper>{phrases.description}</TextWrapper>
        </div>
    );
}
