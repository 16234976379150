import {useMemo} from 'react';

import {useMyJobApplications} from '@/api/users/useMyJobApplications';
import {anyWithLeadershipReport} from '@/pages/PersonalityTestIrt/utils';

type Props = {
    organizationId: number;
};

export function useJobApplicationLogic({organizationId}: Props) {
    const {
        jobApplications,
        acceptedCandidateOrganizations,
        loading: loadingJobApplications,
        error,
        refetch
    } = useMyJobApplications(organizationId, {
        withRelevanceInformation: true
    });

    const latestCompletionDate = useMemo(() => {
        if (!jobApplications.length) {
            return null;
        }

        const completedApplications = jobApplications.filter(item => !!item.completedAt);
        if (completedApplications.length === 0) {
            return null;
        }

        completedApplications.sort(
            (a, b) => new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime()
        );
        return completedApplications[0].completedAt;
    }, [jobApplications]);

    const isLeadershipReportEnabled = useMemo(
        () => anyWithLeadershipReport(jobApplications),
        [jobApplications]
    );

    return {
        jobApplications,
        acceptedCandidateOrganizations,
        loadingJobApplications,
        latestCompletionDate,
        isLeadershipReportEnabled,
        error,
        refetch
    };
}
