import moment, {LocaleSpecifier} from 'moment';

const getDateObject = (date: string | Date) => (date instanceof Date ? date : new Date(date));

export function formatDate(dateString?: string | Date): string {
    if (!dateString) {
        return 'N/A';
    }

    const date = getDateObject(dateString);

    return moment(date).format('YYYY-MM-DD');
}

export function getTimeFromNow(dateString: string | Date, locale: LocaleSpecifier): string {
    if (!dateString) {
        return 'N/A';
    }

    const date = getDateObject(dateString);

    return moment(date).locale(locale).fromNow();
}
