import styled from 'styled-components';

import {SpacingLarge, SpacingSmall} from '@/componentLibrary/tokens/variables';

export const ActionWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const ModalBodyWrapper = styled.div`
    padding: ${SpacingLarge};
    display: flex;
    flex-direction: column;
    gap: ${SpacingSmall};
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
`;
