import {useQuery} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {CHECK_LOGGED_IN} from '@/api/auth/queries';
import {FetchPolicy} from '@/api/types/genericApi/fetchPolicy';

import {useSetUserLastSeen} from './useSetUserLastSeen';

type Props = {
    fetchPolicy?: FetchPolicy;
};

export function useCheckLoggedIn({fetchPolicy = FetchPolicy.NETWORK_ONLY}: Props = {}) {
    const {setUserLastSeen, cancel} = useSetUserLastSeen();
    const onCompleted = useCallback(
        data => {
            if (!data?.me) {
                // Skip if not logged in
                cancel();
                return;
            }
            // Note: It's very important to set the user last seen whenever a user is logged in
            // We use this value in data retention to decide which users to delete
            setUserLastSeen();
        },
        [setUserLastSeen, cancel]
    );
    const options = useMemo(() => ({onCompleted}), [onCompleted]);
    const {data, error, loading, refetch} = useQuery(CHECK_LOGGED_IN, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy,
        ...options
    });
    const user = useMemo(() => data?.me ?? null, [data]);
    const organizations = useMemo(() => user?.employmentOrganizations ?? [], [user]);

    return {
        isLoggedIn: !!user,
        user,
        organizations,
        loading,
        refetchLoginStatus: refetch,
        error
    };
}
