import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {P2} from '@/componentLibrary/components/typography';
import {
    LineHeightsCompact,
    SpacingMedium,
    SpacingSmall,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const OuterErrorWrapper = styled(FlexLayout)`
    z-index: 10;
`;
export const InnerErrorWrapper = styled(FlexLayout)`
    padding: ${SpacingMedium};
    width: 320px;
    text-align: center;
    gap: ${SpacingXxsmall};
`;
export const ErrorIcon = styled(Icon)`
    margin-bottom: ${SpacingXsmall};
`;
export const ErrorDescription = styled(P2)`
    line-height: ${LineHeightsCompact};
`;
export const RefreshButton = styled(SecondaryButton)`
    margin-top: ${SpacingSmall};
`;
