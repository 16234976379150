import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
import {useCallback, useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';
import styled, {createGlobalStyle} from 'styled-components';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {ColorBgBackdrop, ColorBgDefault} from '@/componentLibrary/tokens/variables';

type ModalEmptyProps = {
    close?: () => void;
    children?: React.ReactNode;
    showCloseIcon?: boolean;
    noCenterWrapper?: boolean;
    whiteBackdrop?: boolean;
};

export function ModalEmpty({
    close,
    children,
    showCloseIcon,
    whiteBackdrop = false,
    noCenterWrapper = false
}: ModalEmptyProps) {
    const modalRef = useRef(null);

    const closeModal = useCallback(() => {
        clearAllBodyScrollLocks();
        close?.();
    }, [close]);

    const handleKeyPress = useCallback(
        event => event.keyCode === 27 && closeModal(),
        [closeModal]
    );

    const addKeyBinding = useCallback(() => {
        window.addEventListener('keyup', handleKeyPress);
    }, [handleKeyPress]);

    const removeKeyBinding = useCallback(() => {
        window.removeEventListener('keyup', handleKeyPress);
    }, [handleKeyPress]);

    useEffect(() => {
        addKeyBinding();
        modalRef.current && disableBodyScroll(modalRef.current);

        return () => {
            removeKeyBinding();
            clearAllBodyScrollLocks();
        };
    });

    const domNode = document.getElementById('__modal') as HTMLElement;

    const Wrapper = noCenterWrapper ? ContentWrapperWithDefaultBg : CenteredContentWrapper;

    return createPortal(
        <Modal id="modal_element" ref={modalRef} $whiteBackdrop={whiteBackdrop}>
            <ModalGlobalStyle />
            {showCloseIcon ? (
                <CloseWrapper>
                    <Icon icon={Icons.CLOSE} isAction onClick={closeModal} />
                </CloseWrapper>
            ) : null}
            <Wrapper>{children}</Wrapper>
        </Modal>,
        domNode
    );
}

const Modal = styled.div<{$whiteBackdrop?: boolean}>`
    animation-duration: 0.15s;
    animation-fill-mode: both;
    animation-name: fade-in;
    background-color: ${p => (p.$whiteBackdrop ? ColorBgDefault : ColorBgBackdrop)};
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    overflow-y: auto;
    z-index: 500;
    height: 100vh;

    @media print {
        position: static;
        padding: 0;
        background-color: ${ColorBgDefault};
    }

    @media (max-width: 1024px) {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
`;

const CloseWrapper = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
`;

export const CenteredContentWrapper = styled.div`
    margin: 0 auto;
    max-width: 1000px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media print {
        margin: 0;
        display: block;
    }
`;

const ContentWrapperWithDefaultBg = styled.div`
    background-color: ${ColorBgDefault};
`;

const ModalGlobalStyle = createGlobalStyle`
    body {
        @media print {
            overflow: visible !important;
        }
    }

    /* stylelint-disable-next-line */
    #CookiebotWidget {
        @media print {
            display: none;
        }
    }
`;
