import {defineMessages} from 'react-intl';

export default defineMessages({
    signInWithGoogle: {
        id: 'app.components.buttons.googleButton'
    },
    signUpWithGoogle: {
        id: 'app.components.buttons.googleButton.signUp'
    },
    signInWithMicrosoft: {
        id: 'app.components.buttons.microsoftButton'
    },
    signUpWithMicrosoft: {
        id: 'app.components.buttons.microsoftButton.signUp'
    },
    signInWithOkta: {
        id: 'app.components.buttons.oktaButton'
    },
    signUpWithOkta: {
        id: 'app.components.buttons.oktaButton.signUp'
    }
});
