import styled, {css} from 'styled-components';

import {Banner} from '@/componentLibrary/components/banners/Banner';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {BorderBaseDefaultRule} from '@/componentLibrary/tokens/customVariables';
import {BodyP2, OtherCaption} from '@/componentLibrary/tokens/typography';
import {
    ColorBaseGrey600,
    ColorBaseGrey700,
    SpacingLarge,
    SpacingMedium,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const CodingTestResultsWrapper = styled(FlexColumn)`
    gap: ${SpacingLarge};
    padding: ${SpacingXlarge} 0;
`;

export const CodingTestResults = styled(FlexColumn)`
    gap: ${SpacingXlarge};
`;

export const TestScoreWrapper = styled(FlexColumn)<{isDisabled?: boolean}>`
    gap: ${SpacingXxsmall};
    flex: 1;
    padding: 0 ${SpacingLarge} ${SpacingLarge};
    border-radius: 28px;
    ${BorderBaseDefaultRule};
`;

export const CodingTestResultsHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DetailedResultBtn = styled(SecondaryButton)`
    place-self: flex-start;
`;

export const AIScoreWrapper = TestScoreWrapper;

export const TestScoreRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${SpacingMedium} 0;
`;

export const AIScoreHeader = styled(FlexColumn)`
    justify-content: space-between;
    padding: ${SpacingMedium} 0;
    gap: ${SpacingXxsmall};
`;

export const BarWrapper = styled(FlexColumn)<{disabled?: boolean}>`
    gap: ${SpacingXxsmall};

    ${({disabled}) =>
        disabled &&
        css`
            padding-bottom: 30px;
            color: ${ColorBaseGrey600};
        `}
`;

export const AIScore = styled.div`
    flex: 1;
    text-align: right;

    & > div {
        justify-content: flex-end;
        align-items: baseline;
        gap: ${SpacingXxxsmall};
    }
`;

export const AIScoreSection = styled.div<{disabled?: boolean}>`
    width: 288px;

    ${({disabled}) =>
        disabled &&
        css`
            &:hover {
                cursor: not-allowed;
            }
        `}
`;

export const AIScoreTitle = styled(FlexRow)<{disabled?: boolean}>`
    align-items: center;
    gap: 6px;
    margin-bottom: ${SpacingXxsmall};

    ${({disabled}) =>
        disabled &&
        css`
            color: ${ColorBaseGrey600};
        `}
`;

export const AIScoreCircles = styled(FlexRow)`
    flex: 1;
    gap: ${SpacingXxxsmall};
    justify-content: end;
`;

export const TestScoreTitle = styled.div`
    ${BodyP2}
    display: flex;
    gap: ${SpacingXxsmall};
    align-items: center;
`;

export const BannerWrapper = styled.div`
    padding-bottom: ${SpacingXlarge};
`;

export const AIScoreInfo = styled(FlexRow)`
    gap: ${SpacingMedium};
`;

export const ColorCircle = styled.div<{color: string; size?: number; disabled?: boolean}>`
    height: ${SpacingXsmall};
    width: ${SpacingXsmall};
    ${({color, disabled}) => `background-color: ${disabled ? ColorBaseGrey600 : color};`}
    border-radius: 50%;

    ${({size}) =>
        size &&
        css`
            height: ${size}px;
            width: ${size}px;
        `}
`;

export const InfoItem = styled(FlexRow)`
    align-items: center;
    gap: ${SpacingXxsmall};
`;

export const ScoreBase = styled.div`
    ${OtherCaption};
    color: ${ColorBaseGrey700};
`;

export const AIBanner = styled(Banner)`
    margin-bottom: ${SpacingMedium};
`;
