import {defineMessages} from 'react-intl';

export default defineMessages({
    timeToSelectAnswer: {
        id: 'app.pages.logicTestIrt.components.timer.timeToSelectAnswer'
    },
    timeIsUp: {
        id: 'app.pages.logicTestIrt.components.timer.timeIsUp'
    },
    nextQuestionIn: {
        id: 'app.pages.logicTestIrt.components.timer.nextQuestionIn'
    }
});
