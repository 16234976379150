import {EditorContent} from '@tiptap/react';
import {FC, useState} from 'react';

import {TextFieldLabel} from '@/componentLibrary/components/inputs/styled';
import {TextFieldErrorMessage} from '@/componentLibrary/components/inputs/styled';
import {v4 as uuid} from 'uuid';

import {LinkModal} from './components/LinkModal';
import {TextEditorMenu} from './components/TextEditorMenu';
import {useTextEditorLogic} from './logic';
import {ContentWrapper, EditorWrapper, Wrapper} from './styled';
import {TextEditorProps} from './types';

export const TextEditor: FC<TextEditorProps> = ({
    id,
    label,
    errorMessage,
    content = '',
    required = false,
    disabled = false,
    fullWidth = false,
    minHeight,
    resizable = false,
    showContentOnly = false,
    disabledFormattingOptions = [],
    onChange
}) => {
    const [fallbackId] = useState(id ?? uuid());
    const {
        editor,
        focused,
        setFocused,
        extensionsEnabledMap,
        extensionsOnClickMap,
        closeLinkModal,
        showLinkModal,
        link,
        linkText,
        setLinkInEditor,
        removeLinkInEditor
    } = useTextEditorLogic({
        content,
        showContentOnly,
        disabledFormattingOptions,
        onChange
    });

    if (showContentOnly) {
        return (
            <ContentWrapper id={id} showContentOnly>
                <EditorContent editor={editor} />
            </ContentWrapper>
        );
    }

    return (
        <Wrapper fullWidth={fullWidth} resizable={resizable} onBlur={() => setFocused(false)}>
            {!!label && <TextFieldLabel isRequired={required}>{label}</TextFieldLabel>}
            <EditorWrapper
                id={fallbackId}
                onClick={() => !editor?.isFocused && editor?.chain().focus('end').run()}
                disabled={disabled}
                focused={focused}
                error={!!errorMessage}
                onFocus={() => setFocused(true)}
                minHeight={minHeight}
            >
                <TextEditorMenu
                    editor={editor}
                    extensionsEnabledMap={extensionsEnabledMap}
                    extensionsOnClickMap={extensionsOnClickMap}
                />
                <ContentWrapper>
                    <EditorContent
                        id={fallbackId}
                        editor={editor}
                        data-testid={'text-editor-wrapper'}
                    />
                </ContentWrapper>
            </EditorWrapper>
            {!!errorMessage && <TextFieldErrorMessage>{errorMessage}</TextFieldErrorMessage>}
            {showLinkModal && (
                <LinkModal
                    initialLink={link}
                    initialLinkText={linkText}
                    save={setLinkInEditor}
                    remove={removeLinkInEditor}
                    close={closeLinkModal}
                />
            )}
        </Wrapper>
    );
};
