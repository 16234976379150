import {useCallback, useState} from 'react';

import {TextSkeletonCaption} from '@/componentLibrary/components/skeleton/TextSkeleton';

import {LoadingSelectionCell} from '../../tableCells/SelectionCell';

export function useColumnsLogic({
    selectAllInTableHeader,
    renderSelectionCell,
    renderSelectionHeader,
    columns,
    rowSelectionEnabled,
    loading
}) {
    const [hiddenColumns, setHiddenColumns] = useState([]);

    const hideColumn = useCallback(column => {
        if (column.columns) {
            setHiddenColumns(prevHiddenColumns => [...column.columns, ...prevHiddenColumns]);
        } else {
            setHiddenColumns(prevHiddenColumns => [column, ...prevHiddenColumns]);
        }
    }, []);

    const showColumn = useCallback(column => {
        if (column.columns) {
            const excludedColumns = [column, ...column.columns];
            excludedColumns.forEach(subColumn => {
                setHiddenColumns(prevHiddenColumns =>
                    prevHiddenColumns.filter(item => item.id !== subColumn.id)
                );
            });
        }
        setHiddenColumns(prevHiddenColumns =>
            prevHiddenColumns.filter(item => item.id !== column.id)
        );
    }, []);

    const convertToLoadingColumn = useCallback(column => {
        const overwrittenColumnProps = {
            Cell: column.LoadingCell ? column.LoadingCell : () => <TextSkeletonCaption />,
            accessor: typeof column.accessor === 'function' ? () => column.id : column.accessor
        };
        return Object.assign({}, column, overwrittenColumnProps);
    }, []);

    const selectionColumn = useCallback(() => {
        return {
            accessor: 'selection',
            style: {
                padding: 0,
                height: '100%'
            },
            Cell: ({original}) => renderSelectionCell(original),
            LoadingCell: () => <LoadingSelectionCell />,
            Header: () => (selectAllInTableHeader ? renderSelectionHeader() : null),
            width: 30,
            filterable: false,
            sortable: false,
            resizable: false
        };
    }, [selectAllInTableHeader, renderSelectionCell, renderSelectionHeader]);

    const getColumns = useCallback(() => {
        let modifiedColumns = columns;

        if (rowSelectionEnabled) {
            modifiedColumns = [selectionColumn(), ...modifiedColumns];
        }

        if (loading) {
            modifiedColumns = modifiedColumns.map(convertToLoadingColumn);
        }

        return modifiedColumns;
    }, [columns, convertToLoadingColumn, rowSelectionEnabled, loading, selectionColumn]);

    return {
        hiddenColumns,
        hideColumn,
        showColumn,
        getColumns
    };
}
