import PropTypes, {func} from 'prop-types';
import {useCallback, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';

import {useGiveConsent} from '@/api/users/useGiveConsent';
import {toast, useToasts} from '@/componentLibrary/components/Toast/toast';
import {logger} from '@/services/logrocket';

import {LINKS} from '../../links';
import {CONSENT_TYPES} from '../consent/consentTypes';
import {useConsentMessages} from '../consent/useConsentMessages';
import messages from './messages';

function NewConsentInfo({consentType, giveConsent}) {
    const consentMessages = useConsentMessages();

    const consentLinkText = <FormattedMessage {...consentMessages[consentType].title} />;
    useEffect(() => {
        return () => {
            giveConsent();
        };
    });
    return (
        <div>
            <FormattedMessage
                {...messages.newTerms}
                values={{
                    terms: (
                        <a target="_blank" rel="noopener noreferrer" href={LINKS.terms}>
                            {consentLinkText}
                        </a>
                    )
                }}
            />
        </div>
    );
}

NewConsentInfo.propTypes = {
    consentType: PropTypes.string.isRequired,
    giveConsent: func.isRequired
};

export function AcceptNewConsent({consentType, consent}) {
    const {onRemoveToast} = useToasts();
    const [doGiveConsent] = useGiveConsent();
    const giveConsent = useCallback(() => {
        doGiveConsent({consentType, version: consent?.version})
            .then(({data}) => {
                if (!data.giveConsent.ok) {
                    throw new Error(data.giveConsent.errorMessage);
                }
            })
            .catch(error => {
                logger.error('Failed to give consent', error.message);
            });
    }, [doGiveConsent, consent, consentType]);

    const toastId = 'new-consent';
    // biome-ignore lint/correctness/useExhaustiveDependencies: <>
    useEffect(() => {
        toast({
            type: 'info',
            message: <NewConsentInfo consentType={consentType} giveConsent={giveConsent} />
        });

        return () => {
            giveConsent();
            onRemoveToast(toastId);
        };
    }, []);

    return null;
}

AcceptNewConsent.propTypes = {
    consentType: PropTypes.oneOf(Object.values(CONSENT_TYPES)).isRequired,
    consent: PropTypes.shape({
        revision: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    }).isRequired
};
