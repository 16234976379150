import {LoadingScreen} from '@/componentLibrary/components/LoadingScreen';

import {ValidateTokenError} from './components/ValidateTokenError';
import {useValidateSignupTokenLogic} from './logic';
import {Props} from './types';

export function ValidateSignupToken({token, onSuccess}: Props) {
    const {loading, error, response, errorMessage} = useValidateSignupTokenLogic(token);

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return <ValidateTokenError />;
    }

    if (errorMessage) {
        const {userId, email} = response || {};

        return (
            <ValidateTokenError errorMessage={errorMessage} userId={userId} email={email} />
        );
    }

    if (!response) {
        return null;
    }

    return onSuccess(response);
}
