import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {EmptyState} from '@/componentLibrary/blocks/EmptyState';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {CardBase} from '@/componentLibrary/components/cards/CardBase';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {H3} from '@/componentLibrary/components/typography';
import {UserOrganizationSettings} from '@/pages/User/pages/CandidateSettings/components/UserOrganizationSettings';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {CandidateProfileForm} from './components/CandidateProfileForm';
import messages from './messages';
import {SettingsCardWrapper, Wrapper} from './styled';

export function CandidateSettings() {
    const phrases = useExtractPhraseConstants(messages);
    const {user, loading, error, refetch} = useLoggedInUser();

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    if (!loading && !user) {
        return (
            <FlexLayout alignItems="center" direction="column">
                <EmptyState
                    titleText={phrases.failedToLoadUserTitle}
                    contentText={phrases.failedToLoadUserText}
                />
            </FlexLayout>
        );
    }

    return (
        <Wrapper direction="column">
            <H3>{phrases.title}</H3>
            <SettingsCardWrapper>
                <CardBase isActive>
                    <CandidateProfileForm user={user} />
                </CardBase>
            </SettingsCardWrapper>
            <SettingsCardWrapper>
                <UserOrganizationSettings />
            </SettingsCardWrapper>
        </Wrapper>
    );
}
