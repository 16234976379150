import decodeJWT from 'jwt-decode';
import {parse} from 'qs';
import {useCallback, useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import {useSsoRedirectionLogic} from '@/pages/Login/hooks/useSsoRedirectionLogic';
import {SsoError, SsoOperationType, SsoProvider} from '@/services/auth/sso/constants';
import {useOktaIdToken} from '@/services/auth/sso/okta/useOktaIdToken';
import {SsoState} from '@/services/auth/sso/types';
import {
    getSsoNonceFromSessionStorage,
    getSsoStateFromSessionStorage,
    removeSsoNonceFromSessionStorage,
    setSsoStateInSessionStorage
} from '@/services/auth/sso/utils';
import {logger} from '@/services/logrocket';
import {isEmptyObject} from '@/utils/misc';

type IdToken = {
    nonce: string;
};

export function useSsoStateLogic() {
    const location = useLocation();

    const params = useMemo(() => {
        if (location.hash) {
            return parse(location.hash);
        }
        return parse(location.search, {ignoreQueryPrefix: true});
    }, [location.hash, location.search]);

    const stateString = useMemo(() => {
        return decodeURIComponent((params.state || params['#state']) as string);
    }, [params]);

    const stateFromUrl = useMemo(() => {
        return parse(stateString) as unknown as SsoState;
    }, [stateString]);

    const {status: oktaRequestStatus} = useOktaIdToken(stateFromUrl);

    const idToken = useMemo(() => {
        if (!stateFromUrl || isEmptyObject(stateFromUrl)) {
            return null;
        }
        if (stateFromUrl.ssoProvider === SsoProvider.OKTA) {
            return oktaRequestStatus.data;
        }
        return (params.id_token || params['#id_token']) as string;
    }, [oktaRequestStatus.data, params, stateFromUrl]);

    const validNonce = useMemo(() => {
        if (stateFromUrl.ssoProvider === SsoProvider.OKTA) {
            return true; // Nonce not needed in this auth flow
        }
        if (!idToken) {
            return false;
        }

        const nonce = getSsoNonceFromSessionStorage();
        if (!nonce) {
            return false;
        }
        removeSsoNonceFromSessionStorage();

        try {
            const decoded = decodeJWT(idToken as string) as IdToken;
            return decoded?.nonce === nonce;
        } catch (_) {
            logger.warn('Invalid token');
            return false;
        }
    }, [stateFromUrl.ssoProvider, idToken]);

    const ssoError = useMemo(() => {
        if (params.error) {
            return params.error;
        }
        if (oktaRequestStatus.completed && !idToken) {
            return SsoError.NO_TOKEN;
        }
        if (!validNonce) {
            return SsoError.INVALID_NONCE;
        }

        return undefined;
    }, [idToken, oktaRequestStatus.completed, params.error, validNonce]);

    const ssoState = useMemo(() => {
        if (stateFromUrl.ssoProvider === SsoProvider.OKTA) {
            return stateFromUrl;
        }
        return getSsoStateFromSessionStorage();
    }, [stateFromUrl]);

    const {redirectToLogin, redirectToSignup, redirectToSignupV2} =
        useSsoRedirectionLogic(ssoState);

    const redirectOnSsoError = useCallback(() => {
        switch (ssoState.operationType) {
            case SsoOperationType.LOGIN:
                redirectToLogin(ssoError);
                return;
            case SsoOperationType.REGISTER:
                redirectToSignup(ssoError);
                return;
            case SsoOperationType.AUTHENTICATE_AND_VALIDATE:
                redirectToSignupV2(ssoError);
                return;
            default:
                redirectToLogin(ssoError);
        }
    }, [
        redirectToLogin,
        redirectToSignup,
        redirectToSignupV2,
        ssoError,
        ssoState.operationType
    ]);

    const completeSsoState = useCallback(() => {
        const newState = {...ssoState, completed: true};
        setSsoStateInSessionStorage(newState);
    }, [ssoState]);

    return {
        idToken,
        stateString,
        ssoError,
        ssoState,
        completeSsoState,
        loading: !oktaRequestStatus.completed,
        redirectOnSsoError
    };
}
