import {ChangeEvent, forwardRef, useCallback, useEffect, useState} from 'react';

import {isDefined} from '@/utils/typeGuards/isDefined';

import {FluidInput, FluidTextInputWrapper, FluidTextPlaceholder} from './styled';
import {FluidTextFieldProps} from './types';

export const FluidTextField = forwardRef<HTMLInputElement, FluidTextFieldProps>(
    (
        {
            disabled,
            readOnly,
            value,
            defaultValue,
            onChange,
            placeholder,
            tabIndex,
            hasError = false,
            ...rest
        },
        ref
    ): JSX.Element => {
        const [innerValue, setInnerValue] = useState(defaultValue || '');

        useEffect(() => {
            if (isDefined(value) && value !== innerValue) {
                setInnerValue(value);
            }
        }, [innerValue, value]);

        const handleInputChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                setInnerValue(e.target.value);
                onChange?.(e);
            },
            [onChange]
        );

        return (
            <>
                {!innerValue && placeholder && (
                    <FluidTextPlaceholder>{placeholder}</FluidTextPlaceholder>
                )}
                <FluidTextInputWrapper data-value={innerValue || placeholder}>
                    <FluidInput
                        ref={ref}
                        disabled={disabled}
                        readOnly={readOnly}
                        tabIndex={readOnly ? -1 : tabIndex}
                        value={innerValue}
                        onChange={handleInputChange}
                        defaultValue={defaultValue}
                        className={hasError ? 'error' : ''}
                        maxLength={44}
                        {...rest}
                    />
                </FluidTextInputWrapper>
            </>
        );
    }
);
