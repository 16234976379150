import {defineMessages} from 'react-intl';

export default defineMessages({
    chartExplanation: {id: 'app.logicTestReport.resultsDistributionChart.explanation'},
    title: {id: 'app.logicTestReport.resultsDistributionChart.title'},
    taskDifficulty: {id: 'app.logicTestReport.resultsDistributionChart.taskDifficulty'},
    yourResult: {id: 'app.logicTestReport.resultsDistributionChart.yourResult'},
    correct: {id: 'app.logicTestReport.resultsDistributionChart.correct'},
    incorrect: {id: 'app.logicTestReport.resultsDistributionChart.incorrect'},
    difficulty: {id: 'app.logicTestReport.resultsDistributionChart.difficulty'}
});
