import styled, {css} from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    SpacingLarge,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const FacetResultWrapper = styled.div`
    margin-top: ${SpacingLarge};
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-top: ${SpacingXlarge};
        flex-direction: column;
    }
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-inline-end: ${SpacingLarge};
    width: 50%;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
    }
`;

export const ChartWrapper = styled.div`
    margin-top: ${SpacingXsmall};
    width: 50%;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
    }
`;

export const LabelsWrapper = styled.div`
    width: 100%;
    margin-top: ${SpacingXxsmall};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const labelWrapperStyle = css`
    width: 50%;
    max-width: 200px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        max-width: 140px;
    }
`;

export const UpperLabelWrapper = styled.div`
    ${labelWrapperStyle}
`;

export const LowerLabelWrapper = styled.div`
    ${labelWrapperStyle}
    text-align: right;
`;
