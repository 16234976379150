import {Fragment} from 'react';

import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {OrganizationAvatarSkeleton} from '@/componentLibrary/components/avatars/OrganizationAvatar';
import {DestructiveButton} from '@/componentLibrary/components/buttons/DestructiveButton';
import {DisplaySkeletonSmaller} from '@/componentLibrary/components/skeleton/DisplaySkeleton';
import {
    TextSkeleton,
    TextSkeletonCaption
} from '@/componentLibrary/components/skeleton/TextSkeleton';
import {grey} from '@/componentLibrary/tokens/legacyColors';

import {CardBase} from '@/componentLibrary/components/cards/CardBase';
import {H4} from '@/componentLibrary/components/typography';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {RevokeUserOrganizationSetting} from '../RevokeUserOrganizationSetting';
import {WithdrawUserOrganizationSetting} from '../WithdrawUserOrganizationSetting';
import {useUserOrganizationSettingsLogic} from './hooks';
import messages from './messages';
import {
    OrganizationInfoWrapper,
    OrganizationNameWrapper,
    OrganizationTitleWrapper,
    OrganizationWrapper,
    OrganizationsWrapper,
    TestWrapper,
    TestsWrapper
} from './styled';
import {OrganizationType} from './types';

export function UserOrganizationSettings() {
    const phrases = useExtractPhraseConstants(messages);
    const {userId, organizations, error, loading, refetch} =
        useUserOrganizationSettingsLogic();

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    const hasWithdrawSetting = (organization: OrganizationType) =>
        organization.unregisteredCandidateFlowEnabled;

    const LoadingState = () => {
        const NUMBER_OF_LOADING_ITEMS = 2;
        return loading ? (
            <>
                {Array.from(Array(NUMBER_OF_LOADING_ITEMS).keys()).map(index => (
                    <Fragment key={`organization-${index}`}>
                        <CardBase isActive>
                            <OrganizationWrapper>
                                <OrganizationInfoWrapper>
                                    <OrganizationTitleWrapper>
                                        <OrganizationAvatarSkeleton />
                                        <OrganizationNameWrapper>
                                            <DisplaySkeletonSmaller style={{width: 180}} />
                                            <TextSkeletonCaption />
                                        </OrganizationNameWrapper>
                                    </OrganizationTitleWrapper>
                                    <TestsWrapper>
                                        <TestWrapper>
                                            <TextSkeleton height="21px" />
                                            <TextSkeletonCaption />
                                        </TestWrapper>
                                        <TestWrapper>
                                            <TextSkeleton height="21px" />
                                            <TextSkeletonCaption />
                                        </TestWrapper>
                                    </TestsWrapper>
                                </OrganizationInfoWrapper>
                                <DestructiveButton
                                    style={{backgroundColor: grey.grey600(), width: 130}}
                                />
                            </OrganizationWrapper>
                        </CardBase>
                    </Fragment>
                ))}
            </>
        ) : null;
    };

    const getUserOrganizationSetting = (organization: OrganizationType, index: number) =>
        hasWithdrawSetting(organization) ? (
            <WithdrawUserOrganizationSetting
                key={`organization-${index}`}
                organization={organization}
                userId={userId}
            />
        ) : (
            <RevokeUserOrganizationSetting
                key={`organization-${index}`}
                organization={organization}
                userId={userId}
            />
        );

    return (
        <OrganizationsWrapper>
            <H4>{phrases.organizationSettings}</H4>
            <LoadingState />
            {!loading && organizations.length === 0 ? (
                <div>{phrases.noOrganizations}</div>
            ) : null}
            {!loading && organizations.length > 0
                ? organizations.map(getUserOrganizationSetting)
                : null}
        </OrganizationsWrapper>
    );
}
