import styled from 'styled-components';

import {H3, P1} from '@/componentLibrary/components/typography';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    SpacingLarge,
    SpacingSmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const ModalBody = styled.div`
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
        max-height: 576px;
    }
`;

export const Header = styled(H3)`
    align-self: center;
`;

export const Description = styled(P1)`
    margin-top: ${SpacingSmall};
    margin-bottom: ${SpacingSmall};
`;
export const ModifiedLabel = styled(P1)`
    margin-left: ${SpacingXxxsmall};
    align-self: center;
`;

export const HeaderWrapper = styled.div`
    margin-left: ${SpacingLarge};
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;
