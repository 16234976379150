import {TimePerQuestionChart} from '../TimePerQuestionChart';
import {TotalTimeSpentGraph} from '../TotalTimeSpentGraph';
import {ChartWrapper, Wrapper} from './styled';
import {LogicReportGraphsProps} from './types';

export function LogicReportGraphs({questions}: LogicReportGraphsProps) {
    return (
        <Wrapper>
            <ChartWrapper>
                <TimePerQuestionChart questions={questions} />
            </ChartWrapper>
            <ChartWrapper>
                <TotalTimeSpentGraph questions={questions} />
            </ChartWrapper>
        </Wrapper>
    );
}
