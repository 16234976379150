export const REDACT_KEYS = [
    'password',
    'token',
    'accessToken',
    'refreshToken',
    'username',
    'userName',
    'email',
    'firstName',
    'firstname',
    'lastName',
    'lastname'
];
