import {
    PersonAvatarLarge,
    PersonAvatarSmall
} from '@/componentLibrary/components/avatars/PersonAvatar';
import {getUserName} from '@/utils/misc';
import {useIntl} from 'react-intl';
import messages from './messages';

import {AvatarWrapper, Name, Title, UserWrapper, Wrapper} from './styled';
import {UserCellProps} from './types';

export function UserCell({
    user,
    subtext,
    shortInfo,
    fixedWidth,
    isBigName = false
}: UserCellProps) {
    const {formatMessage} = useIntl();

    return (
        <Wrapper>
            <AvatarWrapper>
                {shortInfo ? (
                    <PersonAvatarSmall user={user} />
                ) : (
                    <PersonAvatarLarge user={user} />
                )}
            </AvatarWrapper>
            <UserWrapper fixedWidth={fixedWidth}>
                <Name isBigName={isBigName} fixedWidth={fixedWidth}>
                    {getUserName(user, {
                        defaultIfEmptyUser: formatMessage(messages.formerEmployee)
                    })}
                </Name>
                {subtext ? <Title fixedWidth={fixedWidth}>{subtext}</Title> : null}
            </UserWrapper>
        </Wrapper>
    );
}
