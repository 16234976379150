import {defineMessages} from 'react-intl';

export default defineMessages({
    interviewContent: {
        id: 'app.assessment.interview.content'
    },
    results: {
        id: 'app.assessment.candidatePage.candidateRoleFit.scorecardResults.results'
    },
    noCompletedTitle: {
        id: 'app.assessment.candidatePage.candidateRoleFit.scorecardResults.noCompletedTitle'
    },
    noCompletedDescription: {
        id: 'app.assessment.candidatePage.candidateRoleFit.scorecardResults.noCompletedDescription'
    },
    rateThisCandidate: {
        id: 'app.assessment.candidatePage.rateThisCandidate'
    },
    failedUserWithdrawnApplication: {
        id: 'app.assessment.candidatePage.interview.manageInterviewRequests.failedUserWithdrawnApplication'
    },
    toastRateCandidateSaved: {
        id: 'app.assessment.jobPosition.settings.assignInterview.toastRateCandidateSaved'
    },
    toastRateCandidateError: {
        id: 'app.assessment.jobPosition.settings.assignInterview.toastRateCandidateError'
    }
});
