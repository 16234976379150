import {useStartPageLogic} from '@/pages/LogicTestIrt/pages/StartPage/logic';
import {Props} from '@/pages/LogicTestIrt/pages/StartPage/types';

import {StartLogicTest} from '../../components/StartLogicTest';

export const StartPage = ({shareWithOrganizationId, goToTest, goBack}: Props) => {
    const {startTest, loading} = useStartPageLogic({
        shareWithOrganizationId,
        goToTest
    });

    return (
        <StartLogicTest
            onStartTest={startTest}
            loading={loading}
            organizationId={shareWithOrganizationId}
            goBackUrl={goBack}
        />
    );
};
