import {useIntl} from 'react-intl';

import {useResultsDistributionLogic} from '@/componentLibrary/blocks/testReports/components/LogicTestResultsDistribution/hooks';
import {SectionWrap} from '@/componentLibrary/blocks/testReports/components/LogicTestResultsDistribution/styled';
import {ScatterPlotChart} from '@/componentLibrary/components/charts/ScatterPlotChart';
import {H4, P2} from '@/componentLibrary/components/typography';

import messages from './messages';
import {LogicTestResultsDistributionProps} from './types';

export function LogicTestResultsDistribution({
    logicalAbilityEstimate,
    standardScore
}: LogicTestResultsDistributionProps) {
    const intl = useIntl();

    const {xCorrectData, yCorrectData, yIncorrectData, xIncorrectData} =
        useResultsDistributionLogic(logicalAbilityEstimate);

    return (
        <SectionWrap>
            <H4 data-testid={'chart-title'}>{intl.formatMessage(messages.title)}</H4>
            <ScatterPlotChart
                xCorrectData={xCorrectData}
                xIncorrectData={xIncorrectData}
                yCorrectData={yCorrectData}
                yIncorrectData={yIncorrectData}
                standardScore={standardScore}
                xAxisTitle={intl.formatMessage(messages.taskDifficulty)}
                correctDataLabel={intl.formatMessage(messages.correct)}
                incorrectDataLabel={intl.formatMessage(messages.incorrect)}
                standardScoreLabel={intl.formatMessage(messages.yourResult)}
                valueLabel={intl.formatMessage(messages.taskDifficulty)}
            />
            <P2 data-testid={'chart-explanation'}>
                {intl.formatMessage(messages.chartExplanation)}
            </P2>
        </SectionWrap>
    );
}
