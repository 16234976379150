export const REVIEW_SCORES = {
    LOW: 0,
    MID: 1,
    HIGH: 2
};

export const VIEWPORT_BUFFER_TOP = 45;
export const MODAL_HEADER_HEIGHT = 76;

export const DETAILED_RESULTS_MODAL_TABS = {
    BeyondTimeLimit: 'beyondTimeLimit',
    WithinTimeLimit: 'withinTimeLimit'
};
