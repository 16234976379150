import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {P2} from '@/componentLibrary/components/typography';
import {
    Markdown,
    MarkdownSupportedBodyTypes
} from '@/componentLibrary/components/typography/Markdown';
import {User} from '@/utils/misc';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {useRevokeSharedPersonalityTestLogic} from './logic';
import {ActionWrapper, DescriptionWrapper, ModalBodyWrapper} from './styled';

type Props = {
    user?: User | null;
    organizationId?: number;
    personalityTestId?: string;
    disabled?: boolean;
};

export function RevokeSharedPersonalityTest({
    user,
    organizationId,
    personalityTestId,
    disabled
}: Props) {
    const {
        inputCandidateName,
        onInputChange,
        actionIsDisabled,
        onClickAction,
        modalIsOpen,
        openModal,
        closeModal,
        formattedMessages
    } = useRevokeSharedPersonalityTestLogic({
        user,
        organizationId,
        personalityTestId
    });

    if (isDefined(disabled)) {
        return null;
    }

    return (
        <>
            <PlainButton onClick={openModal}>{formattedMessages.buttonLabel}</PlainButton>
            {modalIsOpen && (
                <Modal
                    close={closeModal}
                    withoutBody
                    size={MODAL_SIZE.SMALL}
                    headerTitle={formattedMessages.modalTitle}
                >
                    <ModalBodyWrapper>
                        <DescriptionWrapper>
                            <P2>{formattedMessages.descriptionPart1}</P2>
                            <Markdown body={MarkdownSupportedBodyTypes.P2}>
                                {formattedMessages.descriptionPart2}
                            </Markdown>
                        </DescriptionWrapper>
                        <TextField
                            name="candidateName"
                            type="text"
                            fullWidth
                            value={inputCandidateName}
                            onChange={onInputChange}
                            label={formattedMessages.inputLabel}
                            placeholder={''}
                        />
                        <ActionWrapper>
                            <PlainButton onClick={onClickAction} disabled={actionIsDisabled}>
                                {formattedMessages.actionLabel}
                            </PlainButton>
                        </ActionWrapper>
                    </ModalBodyWrapper>
                </Modal>
            )}
        </>
    );
}
