import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    margin-top: 76px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column;
        justify-content: start;
        width: 100%;
        max-width: none;

        div:first-child {
            margin-bottom: ${SpacingXsmall};
        }
    }
`;
export const ChartWrapper = styled.div`
    width: 440px;

    @media print {
        width: 45%;

        .fusioncharts-container svg > g > g > rect:first-child {
            fill: transparent;
        }
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        width: 100%;
    }
`;
