import {FC} from 'react';

import {RoleFitTag} from '@/componentLibrary/components/RoleFitTag/RoleFitTag';
import {RoleFitTagV2Props} from '@/componentLibrary/components/RoleFitTag/types';
import {SpacingXsmall} from '@/componentLibrary/tokens/variables';
import {isMissingAllResults} from '@/pages/common/roleFit/utils';

export const RoleFitV2Tag: FC<RoleFitTagV2Props> = ({roleFit}) => {
    const score = isMissingAllResults(roleFit) ? null : roleFit;
    const indicateMissingResultsWithTooptip = isMissingAllResults(roleFit)
        ? false
        : roleFit?.missingResultsForAnyMethod;

    return (
        <RoleFitTag
            roleFit={score}
            missingResults={indicateMissingResultsWithTooptip}
            style={{marginInlineStart: SpacingXsmall}}
        />
    );
};
