import debounce from 'lodash.debounce';
import {useCallback, useEffect, useRef, useState} from 'react';

const debounceWait = 500;
const debounceOptions = {trailing: true, leading: true};

const getWindowSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight
});

export function useResizeListener() {
    const [{width, height}, updateSize] = useState(getWindowSize());

    const callback = useCallback(() => {
        updateSize(getWindowSize());
    }, []);

    const debouncedCallback = useRef(debounce(callback, debounceWait, debounceOptions));

    useEffect(() => {
        const func = debouncedCallback.current;
        window.addEventListener('resize', func);

        return () => {
            window.removeEventListener('resize', func);
        };
    }, []);

    return {
        width,
        height
    };
}
