import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.signup.createFreeTrialAccount.emailAccount.title'
    },
    orgNameLabel: {
        id: 'app.signup.createOrganization.organizationName'
    },
    phoneLabel: {
        id: 'app.signup.createOrganization.phoneNumber'
    },
    emailLabel: {
        id: 'app.signup.createFreeTrialAccount.emailAccount.workEmail'
    },
    firstNameLabel: {
        id: 'app.signup.firstName'
    },
    lastNameLabel: {
        id: 'app.signup.lastName'
    },
    referralSourceLabel: {
        id: 'app.signup.createOrganization.referralSource'
    },
    referralSourceError: {
        id: 'app.signup.createOrganization.referralSource.error'
    },
    orgNameDisclaimer: {
        id: 'app.signup.createOrganization.organizationName.description'
    },
    phoneDisclaimer: {
        id: 'app.signup.createOrganization.phoneNumber.description'
    },
    sendEmail: {
        id: 'app.signup.createFreeTrialAccount.emailAccount.sendVerificationEmail'
    },
    orgNameError: {
        id: 'app.signup.createOrganization.organizationName.error'
    },
    phoneError: {
        id: 'app.signup.createOrganization.phoneNumber.error'
    },
    emailError: {
        id: 'app.signup.createFreeTrialAccount.emailAccount.workEmail.error.empty'
    },
    emailBlacklistedError: {
        id: 'app.signup.createFreeTrialAccount.emailAccount.workEmail.error.personalEmailAddressUsed'
    },
    firstNameError: {
        id: 'app.signup.createAccountForm.firstNameError'
    },
    lastNameError: {
        id: 'app.signup.createAccountForm.lastNameError'
    },

    warning: {
        id: 'app.errorState.title'
    },
    genericError: {
        id: 'app.errorState.description'
    },
    errorRecaptcha: {
        id: 'app.signup.createOrganization.recaptcha.error'
    }
});
