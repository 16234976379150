import styled from 'styled-components';

import {SpacingMedium, SpacingSmall} from '@/componentLibrary/tokens/variables';

export const InnerWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: ${SpacingSmall};
    flex-flow: row wrap;
`;

export const InnerCardWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;

    & > * {
        margin-bottom: ${SpacingMedium};
    }

    @media (min-width: 1060px) {
        & > *:nth-child(odd) {
            margin-inline-end: ${SpacingMedium};
        }
    }
`;
