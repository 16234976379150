import styled from 'styled-components';

import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';
import {SpacingXsmall} from '@/componentLibrary/tokens/variables';

const EventItem = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: ${BorderBaseDefault};
`;

const EventDate = styled.div`
    flex: 0 0 80px;
    font-size: ${SpacingXsmall};
    margin-right: ${SpacingXsmall};
`;

const EventDescription = styled.div`
    flex: 1;
`;

export {EventItem, EventDate, EventDescription};
