import {defineMessages} from 'react-intl';

export default defineMessages({
    consentDescription: {
        id: 'app.general.acceptTermsAndPolicy'
    },
    alvaTermsOfServiceTitle: {
        id: 'consent.ALVA_LABS_TERMS_OF_SERVICE.title'
    },
    privacyPolicyTitle: {
        id: 'consent.PRIVACY_POLICY.title'
    }
});
