import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {LeadershipReportOverview} from '@/componentLibrary/blocks/testReports/PersonalityTest/LeadershipReportOverview';
import {FactorsOverview} from '@/componentLibrary/blocks/testReports/PersonalityTest/components/FactorsOverview';
import {FactorsOverviewProps} from '@/componentLibrary/blocks/testReports/PersonalityTest/components/FactorsOverview/types';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {S1} from '@/componentLibrary/components/typography';

import {useShowPreviousPersonalityTestResultLogic} from './hooks';
import {HeaderWrapper, Wrapper} from './styled';
import {ShowPreviousPersonalityTestResultProps} from './types';

export function ShowPreviousPersonalityTestResult({
    organizationId,
    personalityTestId,
    isLeadershipReportEnabled = false,
    setShowPreviousPersonalityTestResult
}: ShowPreviousPersonalityTestResultProps) {
    const {
        user,
        personalityTestResult,
        loading,
        error,
        refetch,
        sharingPersonalityTest,
        sharePersonalityTest,
        closeModal,
        link,
        roundedResult,
        showLeadershipReportOverview,
        phrases: {
            title,
            description,
            seeFullReport,
            sharePersonality,
            cancel,
            personalityTestResults
        }
    } = useShowPreviousPersonalityTestResultLogic({
        personalityTestId,
        organizationId,
        isLeadershipReportEnabled,
        setShowPreviousPersonalityTestResult
    });

    if (!user || !personalityTestResult || loading) {
        return null;
    }

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    const leadershipReportLink = `${link}?showLeadershipReport`;

    return (
        <Modal
            close={closeModal}
            headerTitle={title}
            size={MODAL_SIZE.LARGE}
            footer={{
                leftSecondaryAction: (
                    <SecondaryButton onClick={closeModal}>{cancel}</SecondaryButton>
                ),
                primaryAction: (
                    <PrimaryButton
                        isLoading={sharingPersonalityTest}
                        onClick={sharePersonalityTest}
                    >
                        {sharePersonality}
                    </PrimaryButton>
                )
            }}
        >
            <Wrapper windowHeight={window.innerHeight} direction={'column'}>
                <div>{description}</div>
                <HeaderWrapper>
                    <S1>{personalityTestResults}</S1>
                    <ExternalLink url={link}>{seeFullReport}</ExternalLink>
                </HeaderWrapper>

                <FactorsOverview
                    personalityTestResult={
                        roundedResult as FactorsOverviewProps['personalityTestResult']
                    }
                />
                {showLeadershipReportOverview && (
                    <LeadershipReportOverview
                        leadershipProfile={personalityTestResult.leadershipProfile}
                        linkToOpen={leadershipReportLink}
                    />
                )}
            </Wrapper>
        </Modal>
    );
}
