import styled from 'styled-components';
import warningLogo from './assets/warning_logo.svg';

export const WarningLogo = styled.div`
    width: 34.23px;
    height: 30px;
    background: url("${warningLogo}") center center no-repeat;
    background-size: contain;
    margin-bottom: 12px;
`;
