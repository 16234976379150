import styled from 'styled-components';

import {SpacingLarge} from '@/componentLibrary/tokens/variables';

const LeadershipOverviewWrapper = styled.div`
    max-width: 920px;
    margin-top: ${SpacingLarge};
`;

export {LeadershipOverviewWrapper};
