import {InterviewContentItem} from '@/api/assessment/interviews/useInterviewContentItemScoresForJobApplication';
import {roundValue} from '@/utils/misc';
import {isDefined} from '@/utils/typeGuards/isDefined';

export function formatScore(item: InterviewContentItem) {
    const averageScore = item.aggregatedScore?.competencyScoreAverage;
    if (isDefined(averageScore)) {
        return roundValue(averageScore, 1).toFixed(1);
    }
    return '-';
}
