import {useCheckLoggedIn} from '@/api/auth/useCheckLoggedIn';
import {useCheckShareableResultsToken} from '@/api/users/useCheckShareableResultsToken';
import {DestinationType} from '@/componentLibrary/blocks/LandingPageLayout/components/LandingPageLayoutHeader/types';
import {LINKS} from '@/links';
import {Params} from '@/pages/Impersonation/types';
import {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

export const useSharedResultsLogic = () => {
    const [shouldShowResults, setShouldShowResults] = useState(false);
    const {token}: Params = useParams();
    const {user, loading: loadingUser} = useCheckLoggedIn();

    const emailForPageReload = localStorage.getItem(`shared-results-reload-${token}`);
    const alvaLogoDestination: DestinationType = {
        url: LINKS.home
    };

    const {
        permissions,
        data,
        loading: loadingTokenCheck,
        error
    } = useCheckShareableResultsToken(token ?? '');
    const userId = data?.checkShareableResultsToken?.userId;
    const jobPositionId = data?.checkShareableResultsToken?.jobPositionId;

    const isValidToken = useMemo(() => {
        return !loadingTokenCheck && !error && data?.checkShareableResultsToken?.isValid;
    }, [data, loadingTokenCheck, error]);

    const doShowResults = () => {
        if (isValidToken && token && permissions && userId && jobPositionId) {
            setShouldShowResults(true);
        } else {
            setShouldShowResults(false);
        }
    };

    const loggedInEmail = user?.email ?? undefined;

    return {
        isValidToken,
        emailForPageReload,
        token,
        loggedInEmail,
        permissions,
        loading: loadingTokenCheck || loadingUser,
        error,
        doShowResults,
        shouldShowResults,
        userId,
        jobPositionId,
        alvaLogoDestination
    };
};
