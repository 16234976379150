import {useMutation} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {GET_MY_JOB_APPLICATIONS, GET_MY_TESTS_PROGRESS} from '@/api/users/queries';

import {SharePersonalityTestWithOrganizationMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {SHARE_PERSONALITY_TEST_WITH_ORGANIZATION} from './mutations';

export function useSharePersonalityTestWithOrganization(
    organizationId: number,
    userId: number | null,
    personalityTestId: string | null
): MutationHookReturnType<SharePersonalityTestWithOrganizationMutation> {
    const options = useMemo(
        () => ({
            refetchQueries: [
                {query: GET_MY_TESTS_PROGRESS, variables: {organizationId}},
                {query: GET_MY_JOB_APPLICATIONS}
            ]
        }),
        [organizationId]
    );

    const [mutate, {error, loading}] = useMutation(
        SHARE_PERSONALITY_TEST_WITH_ORGANIZATION,
        options
    );

    const doSharePersonalityTest = useCallback(() => {
        if (!userId) {
            return Promise.reject(new Error('User id is required'));
        }
        if (!personalityTestId) {
            return Promise.reject(new Error('Personality test id is required'));
        }
        const variables = {data: {organizationId, userId, personalityTestId}};
        return mutate({variables});
    }, [organizationId, userId, personalityTestId, mutate]);

    return [doSharePersonalityTest, {error, loading}];
}
