import {useIntl} from 'react-intl';

import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {Spinner} from '@/componentLibrary/components/spinners';

import messages from '../../messages';
import {LoadingWrapper} from './styled';
import {LoadingSpinnerProps} from './types';

export function LoadingSpinner({loading, error, refetch}: LoadingSpinnerProps) {
    const {formatMessage} = useIntl();
    return (
        <>
            {!loading && error && (
                <Banner
                    type={BannerType.ERROR}
                    action={{
                        onClick: refetch,
                        text: <>{formatMessage(messages.errorAction)}</>
                    }}
                >
                    {formatMessage(messages.error)}
                </Banner>
            )}

            {loading && (
                <LoadingWrapper>
                    <Spinner />
                </LoadingWrapper>
            )}
        </>
    );
}
