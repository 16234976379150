import styled from 'styled-components';

import {CardBase} from '@/componentLibrary/components/cards/CardBase';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    SpacingMedium,
    SpacingSmall,
    SpacingXsmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: flex;
    gap: ${SpacingMedium};
    flex-direction: column;
    margin-bottom: ${SpacingXxlarge};
`;

export const CardsWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: ${SpacingMedium};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.min}) {
        flex-direction: column;
    }
`;

export const CardWrapper = styled(CardBase)`
    display: flex;
    flex-direction: column;
    gap: ${SpacingSmall};
    min-width: 320px;
    max-width: 320px;
    justify-content: space-between;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.small}) {
        max-width: 100%;
    }
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXsmall};
`;
