import {useIntl} from 'react-intl';

import {H3} from '@/componentLibrary/components/typography/Headings';
import {ColorFgMuted} from '@/componentLibrary/tokens/variables';
import {getUserName} from '@/utils/misc';

import {PersonalityTestReportProps} from '../../PersonalityTestReport/types';
import {personalityTestReportMessages} from '../../messages';
import {DescriptionWrapper, Wrapper} from './styled';

type ReportIntroProps = {
    user: PersonalityTestReportProps['personalityTestResult']['user'];
};

export function ReportIntro({user}: ReportIntroProps) {
    const intl = useIntl();

    const titleText = intl.formatMessage(personalityTestReportMessages.personalityProfile);
    const descriptionText = intl.formatMessage(personalityTestReportMessages.testIntro);

    return (
        <Wrapper>
            <H3>
                {getUserName(user)} - {titleText}
            </H3>
            <DescriptionWrapper color={ColorFgMuted}>{descriptionText}</DescriptionWrapper>
        </Wrapper>
    );
}
