import {useMemo} from 'react';

import {Icons} from '@/componentLibrary/components/icons/constants';
import {ColorBaseGrey600, ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {DROPDOWN_FILTER_SIZES} from '../constants';

type StyleProps = {
    size?: DROPDOWN_FILTER_SIZES;
    isActive?: boolean;
};

type Style = {
    padding: string;
    iconFill: string;
    icon: Icons;
};

export function useInteractFieldStyleLogic({
    size = DROPDOWN_FILTER_SIZES.SMALL,
    isActive = false
}: StyleProps): Style {
    const padding = useMemo(() => {
        if (size === DROPDOWN_FILTER_SIZES.LARGE) {
            return '8px 8px 8px 12px';
        }
        return '2px 8px 2px 12px';
    }, [size]);

    const iconFill = useMemo(() => (isActive ? ColorFgSubtle : ColorBaseGrey600), [isActive]);

    const icon = useMemo(() => {
        return Icons[isActive ? 'KEYBOARD_ARROW_UP' : 'KEYBOARD_ARROW_DOWN'];
    }, [isActive]);

    return {padding, iconFill, icon};
}
