import {
    LinkBase,
    StyledReactRouterLink,
    UnStyledLinkBase,
    UnStyledReactRouterLink
} from './styled';
import {LinkProps} from './types';

export function Link({
    notReactRouter = false,
    noStyling = false,
    onClick,
    url = '#',
    children,
    textDecoration,
    noWrap = false,
    ...rest
}: LinkProps) {
    const $textDecoration = textDecoration || (noStyling ? 'none' : 'underline');

    const extraProps = {
        onClick,
        $textDecoration,
        $noWrap: noWrap
    };

    if (notReactRouter) {
        const LinkComponent = noStyling ? UnStyledLinkBase : LinkBase;
        return (
            <LinkComponent href={url} {...extraProps} {...rest}>
                {children}
            </LinkComponent>
        );
    }
    const LinkComponent = noStyling ? UnStyledReactRouterLink : StyledReactRouterLink;
    return (
        <LinkComponent to={url} {...extraProps} {...rest}>
            {children}
        </LinkComponent>
    );
}
