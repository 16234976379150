import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {BodyP2} from '@/componentLibrary/tokens/typography';
import {
    ColorBaseGrey200,
    ColorBgActionPrimaryDefault,
    ColorBgDefault,
    ColorBorderFocus,
    ColorFgActionTertriaryDefault,
    ColorFgDefault,
    ColorFgSubtle,
    SpacingLarge,
    SpacingSmall,
    SpacingXxlarge,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const TabsContainer = styled.div`
    margin: auto;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    background-color: ${ColorBgDefault};
    border-bottom: ${BorderBaseDefault};
    padding-inline: ${SpacingLarge};
    display: flex;
    
    & .end-button {
        margin-left: auto;
        margin-right: 0;
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        width: 100%;
        padding-inline: 0;
    }
`;

export const TabsItems = styled.ul<{compact?: boolean}>`
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    list-style-type: none;
    gap: ${props => (props.compact ? SpacingSmall : SpacingLarge)};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        margin-inline: ${props => (props.compact ? SpacingSmall : SpacingLarge)};
    }
`;

export const TabLinkActiveSeparator = styled.div`
    display: none;
    height: 2px;
    width: 100%;
    background-color: ${ColorFgActionTertriaryDefault};
    position: absolute;
    bottom: 0;
`;

export const TabLink = styled(NavLink)<{spotlight?: boolean}>`
    ${BodyP2}
    color: ${ColorFgSubtle};
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    &:hover {
        color: ${ColorFgDefault};
    }

    &.active {
        color: ${ColorBorderFocus};
        border-bottom: ${props => (!props.spotlight ? `2px solid ${ColorBorderFocus}` : '0px')};
    }
`;

export const TabItem = styled.li`
    display: flex;
    height: ${SpacingXxlarge};
    position: relative;
    align-items: center;
    
    &:last-child > a {
        margin-inline-end: ${SpacingLarge};
    }
    
    ${TabLink}.active + ${TabLinkActiveSeparator} {
        display: block;
    }

    &:last-child ${TabLink}.active + ${TabLinkActiveSeparator} {
        width: calc(100% - ${SpacingLarge});
    }
`;

export const TabSpotlightItem = styled(TabItem)`
    padding: ${SpacingLarge};
    border-radius: ${SpacingXxxsmall} ${SpacingXxxsmall} 0 0;
    color: ${ColorFgDefault};
    
    ${TabLink}.active + ${TabLinkActiveSeparator} {
        background-color: ${ColorBgActionPrimaryDefault};
    }
    
    &:last-child > a {
        margin-inline-end: 0;
    }
    
    &:last-child ${TabLink}.active + ${TabLinkActiveSeparator} {
        width: calc(100% - ${SpacingLarge});
    }
    
    &.selected {
        background-color: ${ColorBgActionPrimaryDefault};
        
        > .active {
            color: ${ColorFgDefault};
        }
    }
    
    &.unselected {
        background-color: ${ColorBaseGrey200};
    }
`;
