import {ColorBaseBlue900, ColorBaseGrey200} from '@/componentLibrary/tokens/variables';
import {
    Svg,
    Wrapper
} from '@/pages/LogicTestIrt/components/Timer/components/ProgressCircle/styled';
import {ProgressCircleProps} from '@/pages/LogicTestIrt/components/Timer/components/ProgressCircle/types';

const defaultProps = {
    color: ColorBaseBlue900,
    backgroundColor: ColorBaseGrey200
};

export const ProgressCircle = ({
    percentage,
    color = defaultProps.color,
    backgroundColor = defaultProps.backgroundColor
}: ProgressCircleProps) => {
    const strokeArray = `${percentage} 100`;

    return (
        <Wrapper>
            <Svg viewBox="0 0 36 36" className="circular-chart">
                <path
                    className="circle"
                    stroke={backgroundColor}
                    strokeWidth={3}
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path
                    className="circle"
                    stroke={color}
                    strokeWidth={3}
                    strokeDasharray={strokeArray}
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
            </Svg>
        </Wrapper>
    );
};
