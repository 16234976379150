import {convertToHoursAndMinutes} from '../../CodingTests/CodingTestsList/utils';
import {InvalidTime} from '../components/InvalidTime';
import messages from '../messages';
import {TimeContentProps} from '../types';

export const getTimeProps = ({challenge, intl}: TimeContentProps) => ({
    title: intl.formatMessage(messages.effortTitle),
    subtitle: intl.formatMessage(messages.effortSubtitle),
    isCustom: challenge?.isCustom ?? false,
    content: [
        {
            contentTitle: intl.formatMessage(messages.junior),
            contentItem: challenge?.juniorLevelEstimateDurationMinutes ? (
                convertToHoursAndMinutes(challenge?.juniorLevelEstimateDurationMinutes, intl)
            ) : (
                <InvalidTime />
            )
        },
        {
            contentTitle: intl.formatMessage(messages.midLevel),
            contentItem: challenge?.midLevelEstimateDurationMinutes ? (
                convertToHoursAndMinutes(challenge?.midLevelEstimateDurationMinutes, intl)
            ) : (
                <InvalidTime />
            )
        },
        {
            contentTitle: intl.formatMessage(messages.senior),
            contentItem: challenge?.seniorLevelEstimateDurationMinutes ? (
                convertToHoursAndMinutes(challenge?.seniorLevelEstimateDurationMinutes, intl)
            ) : (
                <InvalidTime />
            )
        }
    ]
});
