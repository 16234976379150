import {DisplaySmaller} from '@/componentLibrary/components/typography/Display';
import {SpacingLarge, SpacingMedium, SpacingXlarge} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    padding-bottom: ${SpacingMedium};
    max-width: 740px;
`;
export const FormWrapper = styled.div`
    max-width: 740px;
`;
export const Title = styled(DisplaySmaller)`
    margin-bottom: ${SpacingLarge};
`;
export const NameWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${SpacingLarge};
    flex-wrap: wrap;
`;
export const NameInputField = styled.div`
    margin-inline-end: ${SpacingMedium};
    width: 360px;

    &:last-child {
        margin-inline-end: 0;
    }

    @media only screen and (max-width: 414px) {
        margin-inline-end: 0;
        width: 100%;

        &:first-child {
            margin-bottom: ${SpacingLarge};
        }
    }

    @media only screen and (max-width: 768px) {
        margin-inline-end: 0;

        &:first-child {
            margin-bottom: ${SpacingLarge};
        }
    }
`;
export const InputField = styled.div`
    margin-bottom: ${SpacingMedium};
    width: 360px;

    @media only screen and (max-width: 414px) {
        width: 100%;
    }
`;
export const SelectLanguageWrapper = styled.div`
    margin-bottom: ${SpacingXlarge};
    width: 360px;

    @media only screen and (max-width: 414px) {
        width: 100%;
    }
`;
