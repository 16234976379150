import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {AnonymizeJobApplicationsForUserInOrganizationMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {ANONYMIZE_JOB_APPLICATIONS_FOR_USER_IN_ORGANIZATION} from './mutations';

export function useAnonymizeJobApplicationsForUserInOrganization(
    userId: number,
    organizationId: number
): MutationHookReturnType<AnonymizeJobApplicationsForUserInOrganizationMutation> {
    const [mutate, {error, loading}] = useMutation(
        ANONYMIZE_JOB_APPLICATIONS_FOR_USER_IN_ORGANIZATION
    );
    const doAnonymize = useCallback(() => {
        return mutate({variables: {userId, organizationId}});
    }, [userId, organizationId, mutate]);

    return [doAnonymize, {error, loading}];
}
