import {P2} from '@/componentLibrary/components/typography';

import {AIScore, FlexRow, ScoreBase} from '../styled';
import {AIScoreDisplayProps} from '../types';

export function AIScoreDisplay({
    score,
    'data-testId': dataTestId,
    base = 3
}: AIScoreDisplayProps) {
    return (
        <AIScore data-testid={dataTestId}>
            <FlexRow>
                <P2>{score}</P2>
                <ScoreBase>/{base}</ScoreBase>
            </FlexRow>
        </AIScore>
    );
}
