import {TestProfile} from '@/api/assessment/jobPositions/useJobPosition';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useCallback, useState} from 'react';

export function useTestProfileOverviewLogic(testProfile?: TestProfile | null) {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = useCallback(() => {
        if (isDefined(testProfile)) {
            setModalIsOpen(true);
        }
    }, [testProfile]);

    const closeModal = useCallback(() => {
        setModalIsOpen(false);
    }, []);

    return {modalIsOpen, openModal, closeModal};
}
