import {useCallback, useEffect, useMemo, useState} from 'react';

import {GetLoggedInUserQuery} from '@/api/types/__generated__/graphql';
import {useUpdateUser} from '@/api/users/useUpdateUser';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {useChangeLocale} from '@/containers/LanguageProvider/useChangeLocale';
import {useLocale} from '@/containers/LanguageProvider/useLocale';
import {AVAILABLE_LOCALES, AVAILABLE_LOCALE_NAMES} from '@/services/locale/constants';

type FormInputs = {
    firstName?: string;
    lastName?: string;
    email?: string;
    language?: {
        id: string;
        name: string;
    };
};

export function useCandidateProfileFormLogic(user: GetLoggedInUserQuery['me']) {
    const userLocale = useLocale();
    const {changeLocale} = useChangeLocale();
    const [doUpdateUser, {loading: updatingUser}] = useUpdateUser(user ? user.id : null);

    const languageItems = useMemo(() => {
        return AVAILABLE_LOCALES.sort().map(locale => {
            return {
                id: locale,
                name: AVAILABLE_LOCALE_NAMES[locale]
            };
        });
    }, []);

    const defaultFormInputs: FormInputs = useMemo(() => {
        return {
            firstName: '',
            lastName: '',
            email: '',
            language: languageItems.find(item => item.id === 'en')
        };
    }, [languageItems]);

    const [formInputs, updateFormInputs] = useState(defaultFormInputs);

    useEffect(() => {
        if (user) {
            updateFormInputs({
                firstName: user.firstName ?? undefined,
                lastName: user.lastName ?? undefined,
                email: user.email ?? undefined,
                language: languageItems.find(item => item.id === user.language)
            });
        }
    }, [user, languageItems]);

    const handleFormInputChange = useCallback(
        e => {
            e.preventDefault();
            const {name, value} = e.target;
            updateFormInputs(Object.assign({}, formInputs, {[name]: value}));
        },
        [formInputs]
    );

    const handleSelectLanguage = useCallback(
        item => {
            updateFormInputs(Object.assign({}, formInputs, {language: item}));
        },
        [formInputs]
    );

    const formInputsAreValid = useMemo(() => {
        if (!formInputs.language) {
            return false;
        }
        const namesAreValid = !!formInputs.firstName && !!formInputs.lastName;
        const languageIsValid = !!AVAILABLE_LOCALES.find(
            locale => locale === formInputs.language?.id
        );
        return namesAreValid && languageIsValid;
    }, [formInputs]);

    const updateUser = useCallback(
        e => {
            e.preventDefault();
            if (!formInputsAreValid) {
                return;
            }

            const data = {
                firstName: formInputs.firstName,
                lastName: formInputs.lastName,
                language: formInputs.language?.id
            };

            doUpdateUser(data)
                .then(({data}) => {
                    const {user, errorMessage} = data?.updateUser ?? {};
                    if (user) {
                        toast({type: 'success'});
                        if (userLocale !== user.language) {
                            changeLocale(user.language);
                        }
                    } else {
                        throw new Error(errorMessage ?? 'Error updating user.');
                    }
                })
                .catch(() => toast({type: 'error'}));
        },
        [
            formInputsAreValid,
            formInputs.firstName,
            formInputs.lastName,
            formInputs.language,
            doUpdateUser,
            userLocale,
            changeLocale
        ]
    );

    return {
        formInputs,
        handleFormInputChange,
        handleSelectLanguage,
        formInputsAreValid,
        languageItems,
        updateUser,
        updatingUser
    };
}
