import {useCallback} from 'react';

import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {LeadershipReport} from '@/componentLibrary/blocks/testReports/LeadershipReport';
import {
    LeadershipReportProps,
    UserTestResults_PersonalityIrtResult_User
} from '@/componentLibrary/blocks/testReports/LeadershipReport/types';
import {LeadershipReportOverview} from '@/componentLibrary/blocks/testReports/PersonalityTest/LeadershipReportOverview';
import {PersonalityTestReportOverview} from '@/componentLibrary/blocks/testReports/PersonalityTest/PersonalityTestReportOverview';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';

import {LogicTestResultEmptyState} from '../../components/LogicTestResultEmptyState';
import {LogicTestResultManager} from './LogicTestResultManager';
import {NewCandidateResultsLoadingState} from './components/NewCandidateResultsLoadingState';
import {PersonalityEmptyState} from './components/PersonalityEmptyState';
import {useTestResultsLogic} from './hooks/useTestResultsLogic';
import {LeadershipOverviewWrapper, PersonalityReportWrapper, Wrapper} from './styled';

type Props = {
    userId: number;
    jobPositionId: number;
};

export function CandidateResults({userId, jobPositionId}: Props) {
    const {
        modalIsOpen: leadershipReportModalIsOpen,
        openModal,
        closeModal
    } = useSimpleModalLogic();

    const {
        jobPosition,
        loading: loadingJobPosition,
        error: jobPositionError,
        refetch: refetchJobPosition
    } = useJobPosition(jobPositionId);

    const {
        loadingUserResults,
        userResultsError,
        refetchUserResults,
        logicTestResults,
        personalityTestResults
    } = useTestResultsLogic(userId);

    const getLogicTestResult = useCallback(() => {
        if (jobPosition && jobPosition.requireLogicIrtTest) {
            if (logicTestResults.logicalAbilityEstimate) {
                return (
                    <LogicTestResultManager
                        logicalAbilityEstimate={logicTestResults.logicalAbilityEstimate}
                        jobPositionId={jobPosition?.id}
                    />
                );
            }

            return <LogicTestResultEmptyState />;
        }

        return null;
    }, [jobPosition, logicTestResults.logicalAbilityEstimate]);

    const getPersonalityResult = useCallback(() => {
        const {requirePersonalityTest, leadershipReportEnabled} = jobPosition || {};

        if (!requirePersonalityTest || !personalityTestResults?.personalityIrtResult) {
            return <PersonalityEmptyState />;
        }

        if (leadershipReportEnabled && personalityTestResults?.leadershipProfile) {
            return (
                <>
                    <PersonalityTestReportOverview
                        personalityTestResult={personalityTestResults?.personalityIrtResult}
                        jobPositionId={jobPositionId}
                    />
                    <LeadershipOverviewWrapper>
                        <LeadershipReportOverview
                            leadershipProfile={personalityTestResults?.leadershipProfile}
                            onClickSeeReport={openModal}
                        />
                    </LeadershipOverviewWrapper>
                </>
            );
        }

        return (
            <PersonalityTestReportOverview
                personalityTestResult={personalityTestResults?.personalityIrtResult}
                jobPositionId={jobPositionId}
            />
        );
    }, [jobPosition, jobPositionId, openModal, personalityTestResults]);

    if (loadingJobPosition || loadingUserResults) {
        return <NewCandidateResultsLoadingState />;
    }

    if (jobPositionError) {
        return <ErrorState error={jobPositionError} refetch={refetchJobPosition} />;
    }

    if (userResultsError) {
        return <ErrorState error={userResultsError} refetch={refetchUserResults} />;
    }

    return (
        <Wrapper>
            {leadershipReportModalIsOpen && (
                <LeadershipReport
                    close={closeModal}
                    leadershipProfile={
                        personalityTestResults?.personalityIrtResult
                            ?.leadershipProfile as LeadershipReportProps['leadershipProfile']
                    }
                    user={
                        personalityTestResults?.personalityIrtResult
                            ?.user as UserTestResults_PersonalityIrtResult_User
                    }
                    personalityTestId={
                        personalityTestResults?.personalityIrtResult
                            ?.personalityTestId as string
                    }
                    jobPositionId={jobPositionId}
                />
            )}
            {jobPosition && (
                <>
                    {getLogicTestResult()}
                    <PersonalityReportWrapper>
                        {getPersonalityResult()}
                    </PersonalityReportWrapper>
                </>
            )}
        </Wrapper>
    );
}
