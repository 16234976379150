/**
 * Do not edit directly
 * Generated on Mon, 26 Feb 2024 19:59:37 GMT
 */

export const HeadingH1 = "font: 400 48px/130% 'Euclid Circular A';";
export const HeadingH2 = "font: 400 32px/130% 'Euclid Circular A';";
export const HeadingH3 = "font: 400 24px/130% 'Euclid Circular A';";
export const HeadingH4 = "font: 400 20px/145% 'Euclid Circular A';";
export const SubtitleS1 = "font: 500 16px/145% 'Euclid Circular A';";
export const SubtitleS2 = "font: 500 14px/145% 'Euclid Circular A';";
export const BodyP1 = "font: 300 16px/145% 'Euclid Circular A';";
export const BodyP2 = "font: 300 14px/145% 'Euclid Circular A';";
export const OtherCaption = "font: 400 12px/145% 'Euclid Circular A';";
export const OtherOverline =
    "font: 400 12px/130% 'Euclid Circular A';text-transform: uppercase;";
