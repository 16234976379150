import styled from 'styled-components';

import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingLarge, SpacingSmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    padding: ${SpacingSmall};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: stretch;
    flex-direction: column-reverse;
    gap: ${SpacingSmall};
    border-top: ${BorderBaseDefault};
    margin-top: auto;

    @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
        padding: ${SpacingLarge};
        flex-direction: row;
        width: auto;
        justify-content: space-between;
        gap: ${SpacingSmall};
    }
`;

export const RightActionGroupWrapper = styled.div`
    display: flex;
    gap: ${SpacingSmall};
    flex-direction: column-reverse;
    flex-grow: 1;
    flex-basis: 0;
    align-self: stretch;

    @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
        justify-content: flex-end;
        flex-direction: row;
    }
`;

export const LeftActionGroupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    align-self: stretch;

    @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
        justify-content: flex-start;
        flex-direction: row;
    }
`;
