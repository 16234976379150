import {LanguageToggle} from '@/componentLibrary/components/Dropdowns/LanguageToggle';
import {S1} from '@/componentLibrary/components/typography';
import {ColorFgInfo} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {userLinks} from '@/pages/User/links';

import {StartMenu} from './components/StartMenu';
import messages from './messages';
import {
    CenterColumn,
    LanguageToggleWrapper,
    Logo,
    MainNavLink,
    MenuColumn,
    Wrapper
} from './styled';
import {StartHeaderProps} from './types';

export function StartHeader({user, userOrganizations}: StartHeaderProps) {
    const phrases = useExtractPhraseConstants(messages);

    const showOrganizations = userOrganizations.some(({isEmployment}) => isEmployment);
    const showApplications = userOrganizations.some(({isCandidate}) => isCandidate);

    return (
        <Wrapper>
            <Logo />
            <CenterColumn>
                {showOrganizations && showApplications && (
                    <MainNavLink
                        to={userLinks.myOrganizations()}
                        activeStyle={{color: ColorFgInfo}}
                    >
                        <S1>{phrases.organizations}</S1>
                    </MainNavLink>
                )}
                {showApplications && (
                    <>
                        <MainNavLink
                            to={userLinks.myApplications()}
                            activeStyle={{color: ColorFgInfo}}
                        >
                            <S1>{phrases.applications}</S1>
                        </MainNavLink>
                        <MainNavLink
                            to={userLinks.myJobs()}
                            activeStyle={{color: ColorFgInfo}}
                        >
                            <S1>{phrases.jobs}</S1>
                        </MainNavLink>
                    </>
                )}
            </CenterColumn>
            <MenuColumn>
                <LanguageToggleWrapper>
                    <LanguageToggle userId={user?.id ?? null} fullWidth />
                </LanguageToggleWrapper>
                <StartMenu user={user} showOrganizations={showOrganizations} />
            </MenuColumn>
        </Wrapper>
    );
}
