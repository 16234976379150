import styled from 'styled-components';

import {LOW_HEIGHT} from '@/componentLibrary/components/charts/StandardTenChart/constants';
import {H4} from '@/componentLibrary/components/typography';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    SpacingLarge,
    SpacingMedium,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    width: 100%;
    margin-bottom: 68px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-bottom: ${SpacingXlarge};
    }

    @media print {
        margin-bottom: ${SpacingMedium};
    }
`;

export const HeaderWrapper = styled(H4)`
    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-bottom: ${SpacingLarge};
    }
`;

export const ResultWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        flex-direction: column;
    }
`;

export const DescriptionWrapper = styled.div`
    margin-top: ${SpacingXsmall};
    margin-inline-end: ${SpacingXlarge};
    max-width: 425px;
    display: flex;
    flex-direction: column;
    width: 50%;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
        margin: auto;
        max-width: none;
    }
`;

export const ChartWrapper = styled.div`
    margin-top: ${SpacingXxsmall};
    width: 50%;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
        margin-top: ${SpacingLarge};
    }
`;

export const LabelsWrapper = styled.div`
    width: 100%;
    margin-top: ${SpacingXxsmall};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const LowerLabelWrapper = styled.div`
    text-align: right;
`;

export const StenChartWrapper = styled.div`
    height: ${LOW_HEIGHT}px;
`;
