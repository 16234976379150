import {defineMessages} from 'react-intl';

export const FAQ_QUESTIONS = defineMessages({
    questionOne: {
        id: 'applicationFaq.1.questionTitle'
    },
    questionTwo: {
        id: 'applicationFaq.2.questionTitle'
    },
    questionThree: {
        id: 'applicationFaq.3.questionTitle'
    },
    questionFour: {
        id: 'applicationFaq.4.questionTitle'
    }
});

export const FAQ_ANSWERS = defineMessages({
    questionOne: {
        id: 'applicationFaq.1.questionAnswer'
    },
    questionTwo: {
        id: 'applicationFaq.2.questionAnswer'
    },
    questionThree: {
        id: 'applicationFaq.3.questionAnswer'
    },
    questionFour: {
        id: 'applicationFaq.4.questionAnswer'
    }
});
