import {useMutation} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {REPLACE_SHARED_PERSONALITY_TEST} from '@/api/personalityTests/mutations';

import {ReplaceSharedPersonalityTestMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {GET_MY_JOB_APPLICATIONS, GET_MY_TESTS_PROGRESS} from '../users/queries';

export function useReplaceSharedPersonalityTest(
    organizationId: number,
    personalityTestId: string | null
): MutationHookReturnType<ReplaceSharedPersonalityTestMutation> {
    const options = useMemo(
        () => ({
            refetchQueries: [
                {query: GET_MY_TESTS_PROGRESS, variables: {organizationId}},
                {query: GET_MY_JOB_APPLICATIONS}
            ]
        }),
        [organizationId]
    );
    const [mutate, {error, loading}] = useMutation(REPLACE_SHARED_PERSONALITY_TEST, options);
    const replaceSharedPersonalityTest = useCallback(() => {
        if (!personalityTestId) {
            return Promise.reject(new Error('Personality test id is required'));
        }
        const data = {personalityTestId, organizationId};
        return mutate({variables: {data}});
    }, [personalityTestId, organizationId, mutate]);

    return [replaceSharedPersonalityTest, {error, loading}];
}
