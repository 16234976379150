import {toast} from '@/componentLibrary/components/Toast/toast';
import {logger} from '@/services/logrocket';

import {useCreateOrContinueValidationLogicTest} from '@/api/logicTestIrt/useCreateOrContinueValidationLogicTest';
import {StartValidationTestPageProps} from '@/pages/LogicTestValidation/pages/LogicTestValidation/components/StartValidationTestPage/types';
import {StartValidationLogicTest} from './components/StartValidationLogicTest';

export const StartValidationTestPage = ({
    token,
    goToValidationTest
}: StartValidationTestPageProps) => {
    const [doCreateOrContinueTest, {loading}] = useCreateOrContinueValidationLogicTest(token);
    const startTest = () => {
        doCreateOrContinueTest()
            .then(({data}) => {
                const {ok, errorMessage, logicTestStatus} =
                    data?.createOrContinueValidationLogicTest || {};
                if (!ok) {
                    throw new Error(errorMessage ?? 'Unknown error');
                }

                const logicTestId = logicTestStatus?.logicTest.id;

                if (!logicTestId) {
                    throw new Error('Logic test id is missing');
                }

                goToValidationTest(logicTestId);
            })
            .catch(error => {
                if (error.message !== 'INVALID_TOKEN') {
                    logger.error(error);
                }
                toast({type: 'error'});
            });
    };

    return <StartValidationLogicTest onStartTest={startTest} loading={loading} />;
};
