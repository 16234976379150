import {
    arrow,
    autoUpdate,
    flip,
    offset,
    safePolygon,
    shift,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole
} from '@floating-ui/react';
import {createContext, useContext, useMemo, useRef, useState} from 'react';

import {ARROW_HEIGHT, GAP} from './constants';
import {ContextType, TooltipOptions} from './types';

export const TooltipContext = createContext<ContextType>(null);

export function useTooltip({
    initialOpen = false,
    placement = 'right',
    open: controlledOpen,
    hasArrow,
    onOpenChange: setControlledOpen,
    gap = GAP
}: TooltipOptions = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
    const arrowRef = useRef(null);

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(ARROW_HEIGHT + gap),
            flip({
                crossAxis: placement.includes('-'),
                fallbackAxisSideDirection: 'start',
                padding: 5
            }),
            shift({padding: 5}),
            arrow({
                element: arrowRef
            })
        ]
    });

    const context = data.context;

    const hover = useHover(context, {
        move: false,
        handleClose: safePolygon(),
        enabled: controlledOpen == null
    });
    const focus = useFocus(context, {
        enabled: controlledOpen == null
    });
    const dismiss = useDismiss(context);
    const role = useRole(context, {role: 'tooltip'});

    const interactions = useInteractions([hover, focus, dismiss, role]);

    return useMemo(
        () => ({
            open,
            setOpen,
            hasArrow,
            arrowRef,
            ...interactions,
            ...data
        }),
        [open, setOpen, hasArrow, interactions, data]
    );
}
export const useTooltipContext = () => {
    const context = useContext(TooltipContext);

    if (context == null) {
        throw new Error('Tooltip components must be wrapped in <Tooltip />');
    }

    return context;
};
