import styled from 'styled-components';

import {CardBase} from '@/componentLibrary/components/cards/CardBase';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';

export const Wrapper = styled.div`
    & > :not(:last-child) {
        margin-bottom: 16px;
    }
`;
export const InnerWrapper = styled.div<{wrap: string; justifyContent: string}>`
    display: grid;
    grid-template-columns: auto minmax(360px, 1fr);
    gap: 36px;

    /* @TODO: change breakpoint from tablet to mobile when sidebar on left is toggleable */
    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        grid-template-columns: 100%;
    }
`;
export const ScoreGroupWrapper = styled.div`
    > *:first-child {
        margin-bottom: 0;
    }
`;
export const HeaderWrapper = styled.div`
    padding-bottom: 20px;
`;
export const EmptyInterviewScoreWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const BackgroundWrapper = styled(CardBase)`
    margin-top: 24px;
`;
