import {ChangeEvent, useState} from 'react';

export function useCompareOrganizationName(organizationName: string) {
    const [organizationNameInput, setOrganizationNameInput] = useState('');
    const [hasEnteredOrganizationName, setHasEnteredOrganizationName] = useState(false);

    const handleOrganizationInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        setOrganizationNameInput(value);

        value.toLowerCase() === organizationName.toLowerCase()
            ? setHasEnteredOrganizationName(true)
            : setHasEnteredOrganizationName(false);
    };

    return {
        organizationNameInput,
        handleOrganizationInputChange,
        hasEnteredOrganizationName
    };
}
