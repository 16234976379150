import {useIntl} from 'react-intl';

import {P2} from '@/componentLibrary/components/typography/Paragraphs';

import messages from '../../../JobRecommendationItem/messages';
import {Section} from '../Section';

type Props = {
    hasLeadershipResponsibilities: boolean | null;
};

export function Leadership({hasLeadershipResponsibilities}: Props) {
    const intl = useIntl();
    const leadershipTitle = intl.formatMessage(messages.leadershipTitle);
    const leadershipText = intl.formatMessage(
        hasLeadershipResponsibilities ? messages.leadershipYes : messages.leadershipNo
    );

    return (
        <Section title={leadershipTitle}>
            <P2>{leadershipText}</P2>
        </Section>
    );
}
