import {useCallback} from 'react';

import {useCreateDataCollectionLogicTest} from '@/api/logicTestIrt/useCreateDataCollectionLogicTest';
import {UseStartDataCollectionTestLogicProps} from '@/pages/LogicTestDataCollection/pages/DataCollectionForm/components/StartDataCollectionTest/types';
import {useGoToLogicTest} from '@/pages/LogicTestIrt/hooks/useGoToLogicTest';

export function useStartDataCollectionTestLogic({
    showErrorToast,
    externalId,
    source,
    studyId
}: UseStartDataCollectionTestLogicProps) {
    const onTestCreated = useGoToLogicTest();
    const [doCreateDataCollectionLogicTest, {loading: creatingTest}] =
        useCreateDataCollectionLogicTest();

    const startLogicTest = useCallback(() => {
        doCreateDataCollectionLogicTest(externalId as string, source, studyId)
            .then(({data}) => {
                const createDataCollectionLogicTest = data?.createDataCollectionLogicTest;
                if (createDataCollectionLogicTest?.ok) {
                    onTestCreated(createDataCollectionLogicTest?.dataCollectionLogicTestId);
                } else {
                    throw new Error(
                        createDataCollectionLogicTest?.errorMessage ?? 'Unknown error'
                    );
                }
            })
            .catch(() => showErrorToast());
    }, [
        doCreateDataCollectionLogicTest,
        showErrorToast,
        externalId,
        studyId,
        source,
        onTestCreated
    ]);

    return {creatingTest, startLogicTest};
}
