import {
    ColorBaseGreen800,
    ColorBaseGrey900,
    ColorBaseRed900,
    ColorBaseYellow900
} from '@/componentLibrary/tokens/variables';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {AutomatedScore} from './types';

export function toDisplayAutomatedScore(score: number) {
    return score + 1;
}

export function toScorePercentage(score: number) {
    return (toDisplayAutomatedScore(score) / 3) * 100;
}

export function getScoreGradeColor(score: number | undefined) {
    switch (score) {
        case 0:
            return ColorBaseRed900;
        case 1:
            return ColorBaseYellow900;
        case 2:
            return ColorBaseGreen800;

        default:
            return ColorBaseGrey900;
    }
}

export function getTotalAutoScore(automatedScores: AutomatedScore) {
    let total = 0;
    const score = [
        automatedScores?.qualityScore?.score,
        automatedScores?.reviewScore?.score
    ].reduce((sum, scoreItem) => {
        if (isDefined(scoreItem)) {
            total += 3;
            return (sum || 0) + toDisplayAutomatedScore(scoreItem);
        }
        return sum || 0;
    }, 0);

    return {total, score};
}
