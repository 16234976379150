import {Editor, getAttributes, getMarkRange, getMarkType, getTextBetween} from '@tiptap/react';

import {isDefined} from '@/utils/typeGuards/isDefined';

const LINK_MARK_TYPE = 'link';
const EMPTY_STRING = '';

export const getSelectedText = (editor?: Editor | null) => {
    if (!isDefined(editor)) {
        return undefined;
    }

    const selection = editor.state.selection;
    const {from, to} = selection;
    return editor.state.doc.textBetween(from, to, ' ');
};

export const getLinkTextUnderCursor = (editor?: Editor | null) => {
    if (!isDefined(editor)) {
        return undefined;
    }

    const editorState = editor.state;
    const transaction = editorState.tr;
    const markType = LINK_MARK_TYPE;
    const attributes = getAttributes(editorState, markType);
    const type = getMarkType(markType, editorState.schema);
    const {selection} = transaction;
    const {$from} = selection;
    const range = getMarkRange($from, type, attributes);
    return range
        ? getTextBetween(transaction.doc, range, {blockSeparator: EMPTY_STRING})
        : EMPTY_STRING;
};
