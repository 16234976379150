import {gql} from '../types/__generated__';

export const GET_PERSONALITY_TEST_LOCALE_V2 = gql(`
    query personalityTestLocaleV2($localeName: String!) {
      personalityTestLocaleV2(localeName: $localeName) {
        id
        name
        isDataCollectionEnabled
        dataCollectionMode
      }
    }
`);

export const GET_PERSONALITY_TEST_V2 = gql(`
    query getPersonalityTestV2($id: String!, $withsStatus: Boolean = false) {
        personalityTestV2(personalityTestId: $id) {
            id
            created
            modified
            locale
            answeredQuestions
            averageSigma
            completed
            isUsedInDataCollection
            completed
            userId
            personalityTestStatusV2 @include(if: $withsStatus) {
                ...PersonalityTestStatusV2Fields
            }
        }
    }
`);

export const GET_PERSONALITY_DATA_COLLECTION_RESULT = gql(`
    query getPersonalityDataCollectionResult($personalityTestId: String!) {
        personalityDataCollectionResult(personalityTestId: $personalityTestId) {
            id
            userId
            user {
                id
                firstName
                lastName
                email
            }
            personalityTestId
            completionCode
            dataCollectionSource
            a
            ai
            aii
            aiii
            c
            ci
            cii
            ciii
            e
            ei
            eii
            eiii
            es
            esi
            esii
            esiii
            o
            oi
            oii
            oiii
        }
    }
`);

export const GET_PERSONALITY_TEST_RESULT = gql(`
    query getPersonalityTestResultById($id: String!, $withLeadershipProfile: Boolean = false) {
        personalityTestResult(personalityTestId: $id) {
            ...PersonalityIrtFields
            ...LeadershipProfileFields @include(if: $withLeadershipProfile)
        }
    }
`);
