import qs from 'qs';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {clearSelectedOrganization} from '@/services/auth/utils';

import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {LanguageToggle} from '@/componentLibrary/components/Dropdowns/LanguageToggle';
import {LogoWithText} from '@/componentLibrary/components/Logo/LogoWithText';
import {ErrorPage} from './components/ErrorPage';
import {LoadingSpinner} from './components/LoadingSpinner';
import {OrganizationLandingPage} from './components/OrganizationLandingPage';
import {ValidateToken} from './components/ValidateToken';
import {HeaderWrapper, OuterWrapper} from './styled';

export function AuthenticateCandidate() {
    const location = useLocation();
    const params = qs.parse(location.search, {ignoreQueryPrefix: true});
    const token =
        params.t && typeof params.t === 'string' ? window.decodeURIComponent(params.t) : null;

    useEffect(() => {
        clearSelectedOrganization();
    }, []);

    const {user, loading: loadingUser} = useLoggedInUser();

    if (loadingUser) {
        return <LoadingSpinner />;
    }

    return (
        <OuterWrapper>
            <HeaderWrapper>
                <LogoWithText />
                <LanguageToggle userId={user?.id} />
            </HeaderWrapper>
            <ValidateToken
                token={token}
                onLoading={() => <LoadingSpinner />}
                onError={errorCode => <ErrorPage errorCode={errorCode} />}
                onSuccess={organization => (
                    <OrganizationLandingPage organization={organization} />
                )}
            />
        </OuterWrapper>
    );
}
