import {defineMessages} from 'react-intl';

export default defineMessages({
    belowProfile: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentResults.testResults.belowProfile'
    },
    slightlyBelowProfile: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentResults.testResults.slightlyBelowProfile'
    },
    aboveProfile: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentResults.testResults.aboveProfile'
    },
    slightlyAboveProfile: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentResults.testResults.slightlyAboveProfile'
    },
    matchesProfile: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentResults.testResults.matchesProfile'
    },
    logicTest: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentResults.testResults.logicTest'
    },
    logicAssessment: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentResults.testResults.logicAssessment'
    },
    personalityAssessment: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentResults.testResults.personalityAssessment'
    },
    assessmentProfileRange: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentResults.testResults.assessmentProfileRange'
    },
    result: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentResults.testResults.result'
    },
    assessmentProfileRangeTooltip: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentResults.testResults.assessmentProfileRangeTooltip'
    },
    noResultsYet: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentResults.testResults.noResultsYet'
    }
});
