import {defineMessages} from 'react-intl';

export default defineMessages({
    logicTest: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.logicTest'
    },
    personalityTest: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.personalityTest'
    },
    codingTest: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.codingTest'
    },
    noTests: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.noTests'
    },
    noTestsLink: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.noTestsLink'
    }
});
