import {defineMessages} from 'react-intl';

export default defineMessages({
    email: {
        id: 'app.recoverPassword.recoverForm.email'
    },
    submit: {
        id: 'app.recoverPassword.recoverForm.submit'
    }
});
