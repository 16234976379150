import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingSmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;

    > div {
        margin-inline-end: ${SpacingSmall};

        &:last-child {
            margin-inline-end: 0;
        }
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        flex-direction: column;

        > div {
            margin: 0 0 ${SpacingSmall};
        }
    }
`;
