import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {CenteredSpinner} from '@/componentLibrary/components/spinners';
import {EmailPreview} from '@/pages/Organization/pages/Settings/pages/EmailTemplates/components/EmailPreview';
import {EMAIL_TYPES} from '@/pages/Organization/pages/Settings/pages/EmailTemplates/constants';

import {EmailPreviewModalWrapper} from '../../../Settings/pages/EmailTemplates/components/EmailPreview/styled';
import {WithDefaultEmailContent} from './WithDefaultEmailContent';
import messages from './messages';

export function PreviewEmailModal({closeModal, emailTemplate, emailType}) {
    return (
        <Modal
            close={closeModal}
            headerTitle={<FormattedMessage {...messages.emailPreviewTitle} />}
            withoutBody
            size={MODAL_SIZE.LARGE}
        >
            <EmailPreviewModalWrapper>
                {emailTemplate ? (
                    <EmailPreview emailTemplate={emailTemplate} />
                ) : (
                    <WithDefaultEmailContent emailType={emailType}>
                        {({loading: loadingDefaultContent, emailData}) => {
                            if (loadingDefaultContent) {
                                return <CenteredSpinner />;
                            }
                            return <EmailPreview emailTemplate={emailData} />;
                        }}
                    </WithDefaultEmailContent>
                )}
            </EmailPreviewModalWrapper>
        </Modal>
    );
}

PreviewEmailModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    emailTemplate: PropTypes.object,
    emailType: PropTypes.oneOf(Object.values(EMAIL_TYPES)).isRequired
};
