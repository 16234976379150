import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {
    FACET_MESSAGES,
    FACET_MESSAGES_TYPE
} from '@/componentLibrary/blocks/testReports/LeadershipReport/messages';
import {Spinner} from '@/componentLibrary/components/spinners';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {capAndRoundStenValue} from '@/utils/misc';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {useCandidateRoleFitContext} from '../../../context';
import {SCORE_TYPE} from '../constants';
import {useTestResultsLogic} from '../hooks/useTestResultsLogic';
import {List, ListWrapper} from '../styled';

export function PersonalityTestList() {
    const facetPhrases = useExtractPhraseConstants(FACET_MESSAGES);

    const {jobApplication, jobPosition, userId} = useCandidateRoleFitContext();

    const {loadingUserResults, userResultsError, refetchUserResults, personalityTestResults} =
        useTestResultsLogic(userId);

    if (loadingUserResults) {
        return <Spinner />;
    }

    if (userResultsError) {
        return <ErrorState error={userResultsError} refetch={refetchUserResults} />;
    }

    const assessmentScore = jobApplication?.roleFitV2?.assessmentScores.find(
        ({assessmentMethodType}) => assessmentMethodType === SCORE_TYPE.PERSONALITY_TEST
    );

    if (!isDefined(assessmentScore)) {
        return null;
    }

    const hasResults = !assessmentScore.missingResults;
    const personalityProfile = jobPosition?.personalityProfile;

    const listItems = personalityProfile?.facets.map((facet, index) => {
        if (!isDefined(facet)) {
            return null;
        }
        const selectedValue =
            personalityTestResults?.personalityIrtResult?.[
                facet.name.toLowerCase() as keyof (typeof personalityTestResults)['personalityIrtResult']
            ];
        if (!selectedValue) {
            return null;
        }
        return (
            <li key={index}>
                {facetPhrases[`${facet.name}.title` as FACET_MESSAGES_TYPE]}:{' '}
                {capAndRoundStenValue(selectedValue)}/10
            </li>
        );
    });

    return <ListWrapper>{hasResults && <List>{listItems}</List>}</ListWrapper>;
}
