import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_JOB_APPLICATION} from '@/api/assessment/jobApplications/queries';

import {UseJobApplicationOptions} from './types';

export function useJobApplication(
    jobPositionId: number,
    userId: number,
    options: UseJobApplicationOptions = {}
) {
    const {
        withRoleFit = false,
        withRoleFitV2 = false,
        withRoleFitDistribution = false,
        withRoleFitHistogram = false,
        withRoleFitContribution = false,
        withInterviewScore = false,
        withInterviewScoresAndInterview = false,
        withCandidate = false,
        withJobPosition = false,
        withInterviews = false,
        withCandidateReminderStatus = false,
        withCompletedAt = false,
        withChallengeAssignment = false,
        withAutomatedScore = false,
        withTeam = false,
        withHiringManager = false
    } = options;

    const {data, error, loading, refetch, startPolling, stopPolling} = useQuery(
        GET_JOB_APPLICATION,
        {
            variables: {
                jobPositionId,
                userId,
                withRoleFit,
                withRoleFitV2,
                withRoleFitDistribution,
                withRoleFitHistogram,
                withRoleFitContribution,
                withInterviewScore,
                withInterviewScoresAndInterview,
                withCandidate,
                withJobPosition,
                withInterviews,
                withCandidateReminderStatus,
                withCompletedAt,
                withChallengeAssignment,
                withAutomatedScore,
                withTeam,
                withHiringManager
            },
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first'
        }
    );

    const jobApplication = useMemo(() => data?.jobApplication ?? null, [data]);

    return {jobApplication, error, loading, refetch, startPolling, stopPolling};
}
