import styled from 'styled-components';

import {S2} from '@/componentLibrary/components/typography/SubTitles';
import {SpacingSmall, SpacingXxxsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
`;
export const ScoreWrapper = styled(S2)`
    margin-top: ${SpacingSmall};
    margin-bottom: ${SpacingXxxsmall};
`;
