export const personalityResultMessageIds = [
    'A_AVERAGE_O_AVERAGE',
    'A_AVERAGE_O_HIGH',
    'A_AVERAGE_O_LOW',
    'A_HIGH_O_AVERAGE',
    'A_HIGH_O_HIGH',
    'A_HIGH_O_LOW',
    'A_LOW_O_AVERAGE',
    'A_LOW_O_HIGH',
    'A_LOW_O_LOW',
    'C_AVERAGE_A_AVERAGE',
    'C_AVERAGE_A_HIGH',
    'C_AVERAGE_A_LOW',
    'C_AVERAGE_E_AVERAGE',
    'C_AVERAGE_E_HIGH',
    'C_AVERAGE_E_LOW',
    'C_AVERAGE_ES_AVERAGE',
    'C_AVERAGE_ES_HIGH',
    'C_AVERAGE_ES_LOW',
    'C_AVERAGE_O_AVERAGE',
    'C_AVERAGE_O_HIGH',
    'C_AVERAGE_O_LOW',
    'C_HIGH_A_AVERAGE',
    'C_HIGH_A_HIGH',
    'C_HIGH_A_LOW',
    'C_HIGH_E_AVERAGE',
    'C_HIGH_E_HIGH',
    'C_HIGH_E_LOW',
    'C_HIGH_ES_AVERAGE',
    'C_HIGH_ES_HIGH',
    'C_HIGH_ES_LOW',
    'C_HIGH_O_AVERAGE',
    'C_HIGH_O_HIGH',
    'C_HIGH_O_LOW',
    'C_LOW_A_AVERAGE',
    'C_LOW_A_HIGH',
    'C_LOW_A_LOW',
    'C_LOW_E_AVERAGE',
    'C_LOW_E_HIGH',
    'C_LOW_E_LOW',
    'C_LOW_ES_AVERAGE',
    'C_LOW_ES_HIGH',
    'C_LOW_ES_LOW',
    'C_LOW_O_AVERAGE',
    'C_LOW_O_HIGH',
    'C_LOW_O_LOW',
    'E_AVERAGE_A_AVERAGE',
    'E_AVERAGE_A_HIGH',
    'E_AVERAGE_A_LOW',
    'E_AVERAGE_O_AVERAGE',
    'E_AVERAGE_O_HIGH',
    'E_AVERAGE_O_LOW',
    'E_HIGH_A_AVERAGE',
    'E_HIGH_A_HIGH',
    'E_HIGH_A_LOW',
    'E_HIGH_O_AVERAGE',
    'E_HIGH_O_HIGH',
    'E_HIGH_O_LOW',
    'E_LOW_A_AVERAGE',
    'E_LOW_A_HIGH',
    'E_LOW_A_LOW',
    'E_LOW_O_AVERAGE',
    'E_LOW_O_HIGH',
    'E_LOW_O_LOW',
    'ES_AVERAGE_A_AVERAGE',
    'ES_AVERAGE_A_HIGH',
    'ES_AVERAGE_A_LOW',
    'ES_AVERAGE_E_AVERAGE',
    'ES_AVERAGE_E_HIGH',
    'ES_AVERAGE_E_LOW',
    'ES_AVERAGE_O_AVERAGE',
    'ES_AVERAGE_O_HIGH',
    'ES_AVERAGE_O_LOW',
    'ES_HIGH_A_AVERAGE',
    'ES_HIGH_A_HIGH',
    'ES_HIGH_A_LOW',
    'ES_HIGH_E_AVERAGE',
    'ES_HIGH_E_HIGH',
    'ES_HIGH_E_LOW',
    'ES_HIGH_O_AVERAGE',
    'ES_HIGH_O_HIGH',
    'ES_HIGH_O_LOW',
    'ES_LOW_A_AVERAGE',
    'ES_LOW_A_HIGH',
    'ES_LOW_A_LOW',
    'ES_LOW_E_AVERAGE',
    'ES_LOW_E_HIGH',
    'ES_LOW_E_LOW',
    'ES_LOW_O_AVERAGE',
    'ES_LOW_O_HIGH',
    'ES_LOW_O_LOW'
];
