import {defineMessages} from 'react-intl';

import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';

import {TEST_PROGRESS} from '../../constants';

export default defineMessages({
    seePreviousResult: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.seePreviousResult'
    },
    startNewTest: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.startNewTest'
    },
    notApplicable: {
        id: 'app.pages.user.chooseOrganization.myApplication.testProgressBox.NA'
    },
    codingTestStart: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testAction.NOT_STARTED'
    },
    codingTestContinue: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testAction.IN_PROGRESS'
    },
    codingTestCompleted: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testAction.COMPLETED'
    },
    oldTestBannerTitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.expiredTestResultsBanner.title'
    },
    oldTestBannerDescription: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.expiredTestResultsBanner.description'
    }
});

export const TEST_PROGRESS_MESSAGES = defineMessages({
    [TEST_PROGRESS.NOT_STARTED]: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testProgress.NOT_STARTED'
    },
    [TEST_PROGRESS.IN_PROGRESS]: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testProgress.IN_PROGRESS'
    },
    [TEST_PROGRESS.COMPLETED]: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testProgress.COMPLETED'
    },
    [TEST_PROGRESS.SHAREABLE]: {
        id: 'app.pages.user.chooseOrganization.myApplication.testProgressBox.NA'
    },
    [TEST_PROGRESS.EXPIRED]: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testProgress.NOT_STARTED'
    }
});

export const CODING_TEST_PROGRESS_MESSAGES = defineMessages({
    [ChallengeAssignmentStatus.NOT_STARTED]: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testProgress.NOT_STARTED'
    },
    [ChallengeAssignmentStatus.IN_PROGRESS]: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testProgress.IN_PROGRESS'
    },
    [ChallengeAssignmentStatus.SUBMITTED]: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testProgress.COMPLETED'
    },
    [ChallengeAssignmentStatus.SUPPORT_NEEDED]: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testProgress.SUPPORT_NEEDED'
    }
});

export const TEST_ACTION_MESSAGES = defineMessages({
    [TEST_PROGRESS.NOT_STARTED]: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testAction.NOT_STARTED'
    },
    [TEST_PROGRESS.IN_PROGRESS]: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testAction.IN_PROGRESS'
    },
    [TEST_PROGRESS.COMPLETED]: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testAction.COMPLETED'
    },
    [TEST_PROGRESS.SHAREABLE]: {
        id: 'app.pages.user.chooseOrganization.myApplication.testProgressBox.NA'
    },
    [TEST_PROGRESS.EXPIRED]: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.testAction.NOT_STARTED'
    }
});
