import styled from 'styled-components';

import {
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXlarge,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

import {Skeleton} from './Skeleton';

const DisplaySkeleton = styled(Skeleton).attrs({
    width: '100%'
})<{withMargin?: boolean}>`
    max-width: 400px;
    ${({withMargin}) => (withMargin ? `margin-bottom: ${SpacingSmall};` : '')};
`;

export const DisplaySkeletonMedium = styled(DisplaySkeleton).attrs({
    height: SpacingXlarge
})`
    margin-block: ${SpacingXxsmall};
`;

export const DisplaySkeletonSmall = styled(DisplaySkeleton).attrs({
    height: SpacingLarge
})`
    margin-block: 6px;
`;

export const DisplaySkeletonSmaller = styled(DisplaySkeleton).attrs({
    height: SpacingMedium
})`
    margin-block: 5px;
`;
