import {isDefined} from '@/utils/typeGuards/isDefined';

import {LandingPageLayoutHeader} from './components/LandingPageLayoutHeader';
import {Body, Footer, Wrapper} from './styled';
import {LandingPageLayoutProps} from './types';

const ALVA_WEBPAGE_LINK = 'https://www.alvalabs.io';

export function LandingPageLayout({
    children,
    backgroundImage,
    withLogoInHeader = true,
    withNameAndLogoInHeader = false
}: LandingPageLayoutProps) {
    return (
        <Wrapper $backgroundImage={backgroundImage}>
            <LandingPageLayoutHeader
                isWhite={!backgroundImage}
                withLogo={withLogoInHeader}
                withNameAndLogo={withNameAndLogoInHeader}
            />
            <Body>{children}</Body>
            {isDefined(backgroundImage) && (
                <Footer>
                    <a href={ALVA_WEBPAGE_LINK} rel="noreferrer noopener" target="_blank">
                        alvalabs.io
                    </a>
                </Footer>
            )}
        </Wrapper>
    );
}
