import {UPLOAD_FILE_ERROR, UPLOAD_FILE_INIT, UPLOAD_FILE_SUCCESS} from './constants';
import {FileHandlerState, UploadFileActionTypes} from './types';

const getInitialState = () => ({});

export function fileHandler(
    state = getInitialState(),
    action: UploadFileActionTypes
): FileHandlerState {
    switch (action.type) {
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                [action.requestId]: {
                    loading: false,
                    data: action.data,
                    error: null
                }
            };
        case UPLOAD_FILE_ERROR:
            return {
                ...state,
                [action.requestId]: {
                    loading: false,
                    data: null,
                    error: action.error
                }
            };
        case UPLOAD_FILE_INIT:
            return {
                ...state,
                [action.requestId]: {
                    loading: true,
                    data: null,
                    error: null
                }
            };
        default:
            return state;
    }
}
