import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    ColorBaseYellow800,
    ColorBgSubtle,
    SpacingLarge,
    SpacingMedium,
    SpacingXlarge,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 0;
`;

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

type HeaderWrapperProps = {
    withColor: boolean;
};

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
    padding: ${SpacingXxsmall} ${SpacingLarge};
    height: 76px;
    ${({withColor}) => (withColor ? `background-color: ${ColorBaseYellow800};` : '')}
    
    & .alva-logo-with-name {
        background-size: auto ${SpacingXlarge}; 
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        padding: ${SpacingXxsmall};
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`;

export const LandingPageLayoutFooter = styled.div`
    background-color: ${ColorBgSubtle};
    padding: ${SpacingMedium};
    text-align: left;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
`;

export const HelpCenterLinkStyle = styled.div`
    text-align: right;
    align-content: center;
`;
