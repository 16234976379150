import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'leadershipReport.title'
    },
    intro: {
        id: 'leadershipReport.testIntro'
    },
    helpCenter: {
        id: 'leadershipReport.helpCenter'
    }
});
