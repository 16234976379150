import LogRocket from 'logrocket';
import {useCallback, useEffect} from 'react';

import {AuthenticatedUser} from '@/api/types/__generated__/graphql';
import {useAuthenticatedUser} from '@/api/users/useAuthenticatedUser';
import {getConfig} from '@/config';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {REDACT_KEYS} from './constants';
import {createRedactor} from './utils';

const getLogger = () => {
    const config = getConfig();

    if (config.ENVIRONMENT === 'local') {
        return console;
    }

    return createRedactor(LogRocket, {redactKeys: REDACT_KEYS});
};

export const logger = getLogger();

export function LogRocketWrapper() {
    const {user} = useAuthenticatedUser();

    const identifyLogRocket = useCallback(() => {
        const userId = (user as AuthenticatedUser).userId.toString();
        LogRocket.identify(userId, {name: `User ${userId}`});
        logger.log('LogRocket User identified');
    }, [user]);

    useEffect(() => {
        if (isDefined(user)) {
            identifyLogRocket();
        }
    }, [user, identifyLogRocket]);

    return null;
}
