import React, {ReactElement, useCallback} from 'react';
import styled from 'styled-components';

import {Tooltip} from '@/componentLibrary/components/Tooltips/Tooltip';
import {CheckBox} from '@/componentLibrary/components/controls/CheckBox';
import {SpacingXxsmall} from '@/componentLibrary/tokens/variables';

type SelectionCellProps = {
    handleChecking: () => void;
    handleUnChecking: () => void;
    isChecked?: boolean;
    isDisabled?: boolean;
    tooltipMessage?: ReactElement | string;
};

export const SelectionCell = ({
    isChecked,
    handleChecking,
    handleUnChecking,
    isDisabled,
    tooltipMessage
}: SelectionCellProps) => {
    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }, []);

    const handleChange = useCallback(() => {
        if (!isDisabled) {
            isChecked ? handleUnChecking() : handleChecking();
        }
    }, [isDisabled, isChecked, handleChecking, handleUnChecking]);

    return (
        <Tooltip
            asChild
            shouldTrigger={!!tooltipMessage}
            hasArrow
            content={tooltipMessage as ReactElement}
            placement="bottom-start"
            trigger={
                <Wrapper onClick={handleClick}>
                    <CheckBox
                        value={isChecked}
                        isDisabled={isDisabled}
                        onChange={handleChange}
                    />
                </Wrapper>
            }
        />
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-right: ${SpacingXxsmall};
`;

export const LoadingSelectionCell = () => <Wrapper />;
