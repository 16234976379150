import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {H3} from '@/componentLibrary/components/typography/Headings';
import messages from '@/pages/User/components/JobRecommendations/messages';

export function OtherJobsSectionHeader() {
    const intl = useIntl();

    const title = intl.formatMessage(messages.otherJobsSectionTitle);

    return (
        <Wrapper>
            <H3>{title}</H3>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    & ${H3} {
        margin-bottom: 8px;
    }
`;
