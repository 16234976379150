import {LogLevel, LogMethod, LogRocketType, RedactorOptions} from './types';

export function createRedactor(
    logRocket: LogRocketType,
    options?: RedactorOptions
): LogRocketType {
    const redactInformation = (args: unknown[]): unknown[] => {
        return args.map(arg => {
            if (typeof arg === 'object' && arg !== null && !Array.isArray(arg)) {
                const argRecord: Record<string, unknown> = arg as Record<string, unknown>;
                const redacted: Record<string, unknown> = {};
                Object.keys(argRecord).forEach(key => {
                    redacted[key] = options?.redactKeys?.includes(key)
                        ? '[REDACTED]'
                        : argRecord[key];
                });
                return redacted;
            }
            return arg;
        });
    };

    const handler = (level: LogLevel): LogMethod => {
        return (...args: unknown[]): void => {
            const redactedArgs = redactInformation(args);
            logRocket[level](...redactedArgs);
        };
    };

    return {
        info: handler('info'),
        log: handler('log'),
        error: handler('error'),
        warn: handler('warn'),
        debug: handler('debug'),
        captureException: logRocket.captureException,
        captureMessage: logRocket.captureMessage,
        identify: logRocket.identify,
        getSessionURL: logRocket.getSessionURL,
        init: logRocket.init,
        track: logRocket.track,
        startNewSession: logRocket.startNewSession,
        version: logRocket.version,
        sessionURL: logRocket.sessionURL,
        reduxMiddleware: logRocket.reduxMiddleware
    };
}
