import qs from 'qs';
import {FC, useEffect} from 'react';
import {useLocation} from 'react-router';
import {useHistory} from 'react-router-dom';

import {useLogicalAbilityEstimate} from '@/api/logicTestIrt/useLogicalAbilityEstimate';
import {useValidationTestResult} from '@/api/logicTestIrt/useValidationTestResult';
import {useCurrentEmployment} from '@/api/organizations/useCurrentEmployment';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {LogicTestReport} from '@/componentLibrary/blocks/testReports/LogicTest/LogicTestReport';
import {LogicTestReportProps} from '@/componentLibrary/blocks/testReports/LogicTest/LogicTestReport/types';
import {PageCenteredSpinner} from '@/componentLibrary/components/spinners/PageCenteredSpinner';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {LogicTestReportPageProps} from './types';

export const LogicTestReportPage: FC<LogicTestReportPageProps> = ({
    logicTestId,
    goBack,
    displayDemographicsForm = false
}) => {
    const history = useHistory();
    const {user, loading: loadingUser} = useLoggedInUser();
    const {employment, loading: loadingEmployment} = useCurrentEmployment();
    const {logicalAbilityEstimate, loading} = useLogicalAbilityEstimate(logicTestId);
    const {validationTestResult, loading: loadingValidation} =
        useValidationTestResult(logicTestId);

    const location = useLocation();
    const {fillInLater} = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const showAdminContent = !!employment;

    useEffect(() => {
        if (loading || loadingValidation || loadingUser || loadingEmployment || !user) {
            return;
        }

        if (!loading && !logicalAbilityEstimate) {
            return;
        }

        function shouldDisplayDemographicsForm() {
            return (
                displayDemographicsForm &&
                !user?.hasSubmittedDemographicsForm &&
                !fillInLater &&
                user?.id === logicalAbilityEstimate?.userId
            );
        }

        if (shouldDisplayDemographicsForm()) {
            const redirect = window.encodeURIComponent(
                `${location.pathname}${location.search}`
            );
            const url = `/demographics-form?redirect=${redirect}`;
            history.push(url);
        }
    }, [
        loading,
        loadingValidation,
        loadingUser,
        loadingEmployment,
        user,
        logicalAbilityEstimate,
        displayDemographicsForm,
        fillInLater,
        location.pathname,
        location.search,
        history
    ]);

    if (loading || loadingValidation || loadingUser || loadingEmployment || !user) {
        return <PageCenteredSpinner />;
    }

    if (!loading && !isDefined(logicalAbilityEstimate)) {
        return <div>Not founds</div>;
    }

    return (
        <LogicTestReport
            logicalAbilityEstimate={
                logicalAbilityEstimate as LogicTestReportProps['logicalAbilityEstimate']
            }
            validationTestResult={validationTestResult}
            close={goBack}
            showAdminContent={showAdminContent}
        />
    );
};
