import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {CenteredSpinner} from '@/componentLibrary/components/spinners';
import {S2} from '@/componentLibrary/components/typography';
import {BorderRadiusBaseLarge, ColorBgDefault} from '@/componentLibrary/tokens/variables';

import messages from './messages';

export function SendingToPaymentProvider() {
    return (
        <Wrapper>
            <CenteredSpinner />
            <S2>
                <FormattedMessage {...messages.sendingToPaymentProvider} />
            </S2>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    border-radius: ${BorderRadiusBaseLarge};
    background-color: ${ColorBgDefault};
    padding: 20px;
    gap: 20px;
`;
