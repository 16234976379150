import styled from 'styled-components';

import {Banner} from '@/componentLibrary/components/banners/Banner';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {DisplaySmaller, P2} from '@/componentLibrary/components/typography';
import {
    BorderRadiusBaseLarge,
    BorderRadiusBaseXsmall,
    ColorBaseGrey000,
    ColorBaseGrey200,
    ColorBaseGrey700,
    SpacingMedium,
    SpacingXsmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    background: ${ColorBaseGrey000};
    border: ${BorderRadiusBaseXsmall} solid ${ColorBaseGrey200};
    border-radius: ${BorderRadiusBaseLarge};
    padding: ${SpacingMedium};
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SubTitle = styled(P2)`
    color: ${ColorBaseGrey700};
    margin-bottom: ${SpacingXxsmall};
`;

export const TimeEstimate = styled(P2)`
    color: ${ColorBaseGrey700};
`;

export const Description = styled(P2)`
    margin: ${SpacingMedium} 0;
    color: ${ColorBaseGrey700};
    max-width: 450px;
`;

export const OldTestBanner = styled(Banner)`
    margin-block-end: ${SpacingMedium};
    max-width: 450px;
`;

export const ActionWrapper = styled.div`
    text-align: left;
    margin-top: auto;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 414px) {
        flex-direction: column;
    }
`;

export const PrimaryAction = styled(PrimaryButton)`
    @media only screen and (max-width: 414px) {
        margin-bottom: ${SpacingXsmall};
    }
`;

export const TestTitle = styled(DisplaySmaller)`
    line-height: 1.2;
    margin: -2px 0 ${SpacingXxxsmall};
`;
