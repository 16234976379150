import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 96px;
    display: flex;
    flex-direction: column;
    gap: 56px;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        align-items: center;

        & > * {
            width: 100%;
        }
    }
`;
