import {useEffect, useMemo} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import styled from 'styled-components';

import {Spinner} from '@/componentLibrary/components/spinners';
import {ColorBaseGrey000} from '@/componentLibrary/tokens/variables';
import {
    useCheckAccountAccess,
    useCheckAssignmentAccountAccess
} from '@/pages/Organization/pages/Assessment/components/CodingTests/hooks/useCheckAccess';

type AccessParams = {
    id: string;
};

enum PathType {
    CHALLENGE = 'challenge',
    ASSIGNMENT = 'assignment'
}

export const ChallengeAccessPage = () => {
    const {id} = useParams<AccessParams>();
    const pathType = useMemo(() => {
        if (window.location.pathname.includes(PathType.CHALLENGE)) {
            return PathType.CHALLENGE;
        }
        if (window.location.pathname.includes(PathType.ASSIGNMENT)) {
            return PathType.ASSIGNMENT;
        }
    }, []);

    const location = useLocation();

    const params = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        return {code: searchParams.get('code') ?? '', state: searchParams.get('state') ?? ''};
    }, [location.search]);

    const checkAccountAccess = useCheckAccountAccess();
    const {checkAssignmentAccountAccess} = useCheckAssignmentAccountAccess();

    useEffect(() => {
        (async () => {
            if (pathType === PathType.CHALLENGE && params.code && params.state) {
                const repositoryUrl = await checkAccountAccess(id, {
                    accessCode: params.code,
                    state: params.state
                });

                if (repositoryUrl) {
                    window.open(repositoryUrl, '_self', 'noopener, noreferrer');
                }
            }
        })();
    }, [checkAccountAccess, id, params.code, params.state, pathType]);

    useEffect(() => {
        (async () => {
            if (pathType === PathType.ASSIGNMENT && params.code && params.state) {
                const repositoryUrl = await checkAssignmentAccountAccess(id, {
                    accessCode: params.code,
                    state: params.state
                });

                if (repositoryUrl) {
                    window.open(repositoryUrl, '_self', 'noopener, noreferrer');
                }
            }
        })();
    }, [checkAssignmentAccountAccess, params.code, params.state, pathType, id]);

    return (
        <Wrapper>
            <Spinner isCentered />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background-color: ${ColorBaseGrey000};
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    overflow: hidden;
`;
