import {useCallback, useEffect, useState} from 'react';

import {LEADERSHIP_REPORT_SECTIONS} from './constants';

export function useLeadershipProfileAnchorsLogic() {
    const [anchorTarget_INFLUENCING_OTHERS, setAnchorTarget_INFLUENCING_OTHERS] =
        useState<null | HTMLElement>(null);
    const [anchorTarget_NAVIGATING, setAnchorTarget_NAVIGATING] = useState<null | HTMLElement>(
        null
    );
    const [anchorTarget_ACHIEVING_RESULTS, setAnchorTarget_ACHIEVING_RESULTS] =
        useState<null | HTMLElement>(null);
    useEffect(() => {
        setAnchorTarget_INFLUENCING_OTHERS(
            document.getElementById(LEADERSHIP_REPORT_SECTIONS.INFLUENCING_OTHERS)
        );
        setAnchorTarget_NAVIGATING(
            document.getElementById(LEADERSHIP_REPORT_SECTIONS.NAVIGATING)
        );
        setAnchorTarget_ACHIEVING_RESULTS(
            document.getElementById(LEADERSHIP_REPORT_SECTIONS.ACHIEVING_RESULTS)
        );
    }, []);

    const handleAnchorClick = useCallback(
        (section, event) => {
            event.preventDefault();
            if (section === LEADERSHIP_REPORT_SECTIONS.INFLUENCING_OTHERS) {
                anchorTarget_INFLUENCING_OTHERS?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
            if (section === LEADERSHIP_REPORT_SECTIONS.ACHIEVING_RESULTS) {
                anchorTarget_ACHIEVING_RESULTS?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
            if (section === LEADERSHIP_REPORT_SECTIONS.NAVIGATING) {
                anchorTarget_NAVIGATING?.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        },
        [
            anchorTarget_INFLUENCING_OTHERS,
            anchorTarget_ACHIEVING_RESULTS,
            anchorTarget_NAVIGATING
        ]
    );

    return {handleAnchorClick};
}
