import {useMemo} from 'react';

import {FusionChart} from '@/componentLibrary/components/charts/FusionChart';
import {secondaryColorTokensHex} from '@/componentLibrary/tokens/legacyColors';
import {
    ColorBaseGrey600,
    ColorBaseGrey700,
    ColorBaseGrey800
} from '@/componentLibrary/tokens/variables';
import {
    darkTooltipAttributes,
    generalChartAttributes
} from '@/pages/Organization/pages/Analytics/constants/chartAttributes';
import {SkewedNormal} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateRoleFitPage/components/RoleFitOverview/components/RoleFitCharts/components/RoleFitDistributionChart/components/SkewedNormal';

import {ChartWrapper, DisabledWrapper, TextWrapper, Wrapper} from './styled';
import {RoleFitDistributionChartProps} from './types';
import {getGrid, getTrendLines} from './utils';

export function RoleFitDistributionChart({
    distributionParameters,
    xAxisTitle = '',
    yAxisTitle = '',
    roleFitScore,
    percentileBoundaries = [],
    percentileBoundaryLabelTexts = [],
    percentileBoundaryTooltipTexts = [],
    disabledText = ''
}: RoleFitDistributionChartProps) {
    const roleFitGrid = getGrid();
    let roleFitValues: {value: number; x: number; y: number}[] = [];
    let yAxisMinValue = 0;
    let yAxisMaxValue = 1;

    if (distributionParameters) {
        const {alpha, location, scale} = distributionParameters;
        const distribution = new SkewedNormal(alpha, location, scale);
        roleFitValues = roleFitGrid.map(value => ({
            value: distribution.pdf(value),
            x: value,
            y: distribution.pdf(value)
        }));
        yAxisMinValue = Math.min(...roleFitValues.map(({value}) => value)) * 0.8;
        yAxisMaxValue = Math.max(...roleFitValues.map(({value}) => value)) * 1.2;
    }

    const xAxisCategory = useMemo(() => {
        if (percentileBoundaries.length > 0) {
            return percentileBoundaries.map((percentileBoundary, i) => ({
                label:
                    percentileBoundaryLabelTexts.length > i
                        ? percentileBoundaryLabelTexts[i]
                        : '',
                x: percentileBoundary.value,
                showVerticalLine: true
            }));
        }
        return [0, 0.25, 0.5, 0.75, 1].map(value => ({
            label: `${value * 100} `,
            x: value,
            showVerticalLine: true
        }));
    }, [percentileBoundaries, percentileBoundaryLabelTexts]);

    const axisLineColor = ColorBaseGrey600;

    const boundaryAttributes =
        percentileBoundaries.length > 0 ? {labelDisplay: 'rotate', slantLabel: '1'} : {};

    const dataSource = {
        chart: {
            ...generalChartAttributes,
            chartLeftMargin: 4,
            chartRightMargin: 4,
            chartTopMargin: 12,
            chartBottomMargin: 4,
            showLegend: false,
            lineThickness: 5,
            showXAxisLine: true,
            xAxisLineThickness: 1,
            xAxisLineColor: axisLineColor,
            xAxisName: xAxisTitle,
            xAxisNameFontColor: ColorBaseGrey800,
            xAxisNamePadding: -5,
            xAxisNameFontSize: 14,
            yAxisName: yAxisTitle,
            yAxisNameFontSize: 12,
            yAxisNameFontColor: ColorBaseGrey800,
            labelFontColor: ColorBaseGrey700,
            yAxisMinValue,
            yAxisMaxValue,
            xAxisMinValue: 0,
            xAxisMaxValue: 1.0,
            showYAxisValues: true,
            showYAxisLine: true,
            yAxisLineThickness: 1,
            yAxisLineColor: axisLineColor,
            ...boundaryAttributes,
            ...darkTooltipAttributes,
            numDivLines: 0,
            numVDivLines: 0,
            animation: disabledText ? 0 : 1
        },
        categories: [
            {
                category: xAxisCategory
            }
        ],
        dataset: [
            {
                seriesName: 'Role fit',
                anchorSides: 0,
                drawLine: true,
                data: roleFitValues,
                drawAnchors: false,
                lineDashed: true,
                lineThickness: 2.5,
                lineDashLen: 25,
                lineDashGap: 10,
                lineColor: secondaryColorTokensHex.blue000
            }
        ],
        vTrendLines: [
            {
                line: getTrendLines(
                    roleFitScore,
                    percentileBoundaries,
                    percentileBoundaryTooltipTexts
                )
            }
        ]
    };

    return (
        <Wrapper>
            <ChartWrapper>
                <FusionChart
                    type={'scatter'}
                    width={'100%'}
                    height={320}
                    dataFormat={'JSON'}
                    dataSource={dataSource}
                />
            </ChartWrapper>
            {disabledText && (
                <DisabledWrapper>
                    <TextWrapper>{disabledText}</TextWrapper>
                </DisabledWrapper>
            )}
        </Wrapper>
    );
}
