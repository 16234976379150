import styled, {css} from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingLarge, SpacingMedium, SpacingSmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div<{$fullWidth?: boolean}>`
    ${({$fullWidth}) => ($fullWidth ? 'width: 100%;' : 'max-width: 1000px;')}

    display: flex;
    flex-direction: column;
    gap: ${SpacingLarge};
`;

export const StickyHeaderWrapper = styled.div<{$stickyHeader?: boolean}>`
    ${({$stickyHeader}) =>
        $stickyHeader &&
        css`
            position: sticky;
            top: 0;
            z-index: 10;
            background: white;
            border-top-left-radius: ${SpacingSmall};
        `}
`;

export const BodyWrapper = styled.div`
    padding-inline: ${SpacingLarge};
    
    > .float-right {
        position: relative;
        float: right;
        transform: translateY(-${SpacingMedium});
        
        @media (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
            position: relative;
            right: 0;
            width: fit-content;
        }
    }
`;

export const PrimaryBannerWrapper = styled.div`
    padding-inline: ${SpacingLarge};
`;
