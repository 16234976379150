import {useIntl} from 'react-intl';

import {P2} from '@/componentLibrary/components/typography/Paragraphs';

import messages from '../../../JobRecommendationItem/messages';
import {Section} from '../Section';

type Props = {
    levelOfExperience: number | null;
};

export function PriorExperience({levelOfExperience}: Props) {
    const intl = useIntl();
    const priorExperinceTitle = intl.formatMessage(messages.priorExperienceTitle);
    let minimumExperienceText = '';
    if (!levelOfExperience || levelOfExperience === 0) {
        minimumExperienceText = intl.formatMessage(messages.noMinimumExperience);
    } else {
        minimumExperienceText = intl.formatMessage(messages.minimumExperience, {
            levelOfExperience: levelOfExperience
        });
    }
    return (
        <Section title={priorExperinceTitle}>
            <P2>{minimumExperienceText}</P2>
        </Section>
    );
}
