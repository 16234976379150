import {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import {useRevokeLogicTestFromOrganization} from '@/api/logicTestIrt/useRevokeLogicTestFromOrganization';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import {logger} from '@/services/logrocket';
import {User, getUserName} from '@/utils/misc';

import messages from './messages';

interface Props {
    user?: User | null;
    organizationId?: number;
    logicTestId?: string;
}

export function useRevokeSharedLogicTestLogic({user, organizationId, logicTestId}: Props) {
    const intl = useIntl();
    const [inputCandidateName, setInputCandidateName] = useState('');
    const {modalIsOpen, openModal, closeModal} = useSimpleModalLogic();
    const [doRevokeLogicTestFromOrganization] = useRevokeLogicTestFromOrganization(user?.id);

    const candidateName = useMemo(() => {
        return getUserName(user);
    }, [user]);

    const formattedMessages = useMemo(() => {
        return {
            buttonLabel: intl.formatMessage(messages.allowRetakingTest, {candidateName}),
            modalTitle: intl.formatMessage(messages.title, {candidateName}),
            descriptionPart1: intl.formatMessage(messages.descriptionPart1, {
                candidateName
            }),
            descriptionPart2: intl.formatMessage(messages.descriptionPart2),
            inputLabel: intl.formatMessage(messages.inputLabel, {candidateName}),
            actionLabel: intl.formatMessage(messages.actionLabel)
        };
    }, [intl, candidateName]);

    const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setInputCandidateName(e.target.value);
    }, []);

    const actionIsDisabled = useMemo(() => {
        return inputCandidateName !== candidateName;
    }, [inputCandidateName, candidateName]);

    const onClickAction = useCallback(() => {
        if (!organizationId || !user || !logicTestId) {
            return;
        }
        doRevokeLogicTestFromOrganization({
            organizationId,
            logicTestId,
            userId: user.id
        })
            .catch(e => {
                logger.error(e);
            })
            .finally(closeModal);
    }, [closeModal, doRevokeLogicTestFromOrganization, organizationId, logicTestId, user]);

    return {
        inputCandidateName,
        onInputChange,
        actionIsDisabled,
        onClickAction,
        modalIsOpen,
        openModal,
        closeModal,
        formattedMessages
    };
}
