import {MouseEvent} from 'react';
import styled, {css} from 'styled-components';

import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {ColorVariables} from '@/componentLibrary/tokens/types';
import {BorderRadiusBaseSmall, ColorBaseGrey900} from '@/componentLibrary/tokens/variables';

import {P2} from '../typography';

type AvatarBaseProps = {
    colorFill?: string;
    fontColor?: ColorVariables;
    strokeColor?: string;
    size?: number;
    fontSize?: number;
    image?: string | null;
    isCircle?: boolean;
    text?: string;
    onClick?: (e: MouseEvent<HTMLDivElement>) => void;
    isHoverable?: boolean;
    children?: JSX.Element;
};

export const AvatarBase = ({
    colorFill = greyColorTokens.white100(),
    fontColor = ColorBaseGrey900,
    strokeColor = greyColorTokens.grey800(),
    size = 40,
    fontSize = 14,
    image,
    isCircle = true,
    text,
    onClick,
    isHoverable = false,
    children,
    ...rest
}: AvatarBaseProps) => (
    <Container
        colorFill={colorFill}
        strokeColor={strokeColor}
        size={size}
        isCircle={isCircle}
        onClick={onClick}
        isHoverable={isHoverable}
        {...rest}
    >
        <ContentWrapper>
            {!image && (
                <Text color={fontColor} size={fontSize}>
                    {text}
                </Text>
            )}
            {image && <Image size={size} imageUrl={image} isCircle={isCircle} />}
        </ContentWrapper>
        {children}
        <Overlay size={size} isCircle={isCircle} />
    </Container>
);

type OverlayProps = {
    size: number;
    isCircle: boolean;
};

const Overlay = styled.div<OverlayProps>`
    position: absolute;
    background-color: ${greyColorTokens.grey000(0.3)};
    display: none;
    width: ${({size}) => size}px;
    height: ${({size}) => size}px;
    top: -1px;
    left: -1px;
    border-radius: ${({isCircle}) => (isCircle ? '100%' : BorderRadiusBaseSmall)};
`;

type ContainerProps = {
    colorFill: string;
    strokeColor: string;
    isCircle: boolean;
    size: number;
    isHoverable: boolean;
};

const Container = styled.div<ContainerProps>`
    background-color: ${({colorFill}) => colorFill};
    border: 1px solid ${({strokeColor}) => strokeColor};
    box-sizing: border-box;
    border-radius: ${({isCircle}) => (isCircle ? '50%' : BorderRadiusBaseSmall)};
    width: ${({size}) => size}px;
    height: ${({size}) => size}px;
    position: relative;
    ${({onClick, isHoverable}) =>
        (onClick || isHoverable) &&
        css`
            cursor: pointer;

            &:hover {
                > ${Overlay} {
                    display: initial;
                }
            }
        `};
`;

type TextProps = {
    size: number;
    color: string;
};

const Text = styled(P2)<TextProps>`
    font-size: ${({size}) => size}px;
    color: ${({color}) => color};
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
`;

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

type ImageProps = {
    size: number;
    imageUrl: string;
    isCircle: boolean;
};

const Image = styled.div<ImageProps>`
    width: ${({size}) => size}px;
    height: ${({size}) => size}px;
    background: url("${({imageUrl}) => imageUrl}") center center no-repeat;
    background-size: contain;
    border-radius: ${({isCircle}) => (isCircle ? '50%' : BorderRadiusBaseSmall)};
`;
