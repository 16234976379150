import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography';
import {BorderBaseDefaultRule} from '@/componentLibrary/tokens/customVariables';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderBaseDefaultColor,
    BorderRadiusBaseLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${SpacingXxxsmall};
    padding: ${SpacingMedium};
    border-radius: ${BorderRadiusBaseLarge};
    ${BorderBaseDefaultRule}
`;

export const TextDisplay = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: ${SpacingMedium};

    span:last-child {
        white-space: nowrap;
    }
`;

export const ResultsTitle = styled(P2)`
    margin-block-start: ${SpacingSmall};
    margin-block-end: ${SpacingXxsmall};
`;

export const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingMedium};

    @media screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
        flex-direction: row;
    }
`;

export const ScoreResults = styled.div`
    flex: 1;
`;

export const Importance = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${SpacingXxsmall};
    width: 100%;
    text-align: center;

    @media screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
        width: 120px;
        flex-direction: column;
    }
`;

export const Divider = styled.div`
    background-color: ${BorderBaseDefaultColor};
    height: 1px;
    width: auto;
    align-self: stretch;

    @media screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
        width: 1px;
        height: auto;
    }
`;
