import styled from 'styled-components';

import {Caption} from '@/componentLibrary/components/typography/Captions';
import {
    BorderRadiusBaseSmall,
    ColorBgInset,
    FontSizeDefault
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    width: 115px;
    background: ${ColorBgInset};
    border-radius: ${BorderRadiusBaseSmall};
    text-align: center;
`;
export const Text = styled(Caption)`
    display: inline-block;
    padding: 6px 10px;
    font-size: ${FontSizeDefault};
`;
