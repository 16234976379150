import {FormattedMessage, useIntl} from 'react-intl';

import {GetUserTestResultsQuery} from '@/api/types/__generated__/graphql';
import {NColumnsGrid} from '@/componentLibrary/components/layout/NColumnsGrid';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {ColumnDescription} from './components/ColumnDescription';
import {GrowthFactorTag} from './components/GrowthFactorTag/GrowthFactorTag';
import {CULTURE_PREFERENCES_LINK, DRIVERS_LINK, GROWTH_FACTORS_LINK} from './links';
import messages from './messages';
import {CultureTagsWrapper, DriverTagsWrapper, LinkWrapper} from './styled';

type GrowthFactor = NonNullable<
    NonNullable<GetUserTestResultsQuery['user']>['personalityIrtResult']
>['topDriversDimensions'][0];

type GrowthFactorsProps = {
    topCultureDimensions: GrowthFactor[];
    topDriversDimensions: GrowthFactor[];
};

export function GrowthFactors({
    topDriversDimensions,
    topCultureDimensions
}: GrowthFactorsProps) {
    const intl = useIntl();

    if (
        !isDefined(topDriversDimensions) ||
        !isDefined(topCultureDimensions) ||
        topDriversDimensions.length === 0 ||
        topCultureDimensions.length === 0
    ) {
        return null;
    }

    return (
        <>
            <NColumnsGrid withHeader rowGap={20} columnsNumber={2} columnMinWidth="232px">
                <P1>
                    <FormattedMessage {...messages.title} />
                </P1>
                <ColumnDescription
                    title={intl.formatMessage(messages.driversTitle)}
                    description={intl.formatMessage(messages.driversDescription)}
                />
                <ColumnDescription
                    title={intl.formatMessage(messages.cultureTitle)}
                    description={intl.formatMessage(messages.cultureDescription)}
                />
                <DriverTagsWrapper>
                    {/* Dimensions are already ordered in backend */}
                    {topDriversDimensions.map(item =>
                        item ? (
                            <GrowthFactorTag
                                key={item.name}
                                dimensionName={item.name}
                                readMoreText={intl.formatMessage(messages.driversReadMore)}
                                readMoreUrl={DRIVERS_LINK}
                            />
                        ) : null
                    )}
                </DriverTagsWrapper>
                <CultureTagsWrapper>
                    {/* Dimensions are already ordered in backend */}
                    {topCultureDimensions.map(item =>
                        item ? (
                            <GrowthFactorTag
                                key={item.name}
                                dimensionName={item.name}
                                readMoreText={intl.formatMessage(messages.cultureReadMore)}
                                readMoreUrl={CULTURE_PREFERENCES_LINK}
                            />
                        ) : null
                    )}
                </CultureTagsWrapper>
            </NColumnsGrid>
            <LinkWrapper>
                <ExternalLink url={GROWTH_FACTORS_LINK}>
                    <FormattedMessage {...messages.readMore} />
                </ExternalLink>
            </LinkWrapper>
        </>
    );
}
