import {gql} from '@/api/types/__generated__';

export const GET_LIST_CHALLENGES = gql(`
    query listChallenges(
        $fetchOptions: ChallengesFetchOptionsInput!
        $withChallenges: Boolean = true
    ) {
        authenticatedOrganization {
            id
            challengesConnection(fetchOptions: $fetchOptions) {
                items @include(if: $withChallenges) {
                    ...ChallengeFields
                }
                totalCustomChallengesCount
                totalArchivedChallengesCount
            }
        }
    }
`);

export const GET_CHALLENGE = gql(`
    query GetChallenge($challengeId: ID!) {
        challenge(challengeId: $challengeId) {
            ...ChallengeFields
            parentId
        }
    }
`);

export const GET_CHALLENGE_PROFILES = gql(`
    query ChallengeProfiles($input: ChallengeProfileFilterInput!) {
        challengeProfiles(input: $input)
    }
`);

export const GET_BOILERPLATE_IMPORT_DETAILS = gql(`
    query GetBoilerplateImportDetails($jobPositionId: Int!, $userId: Int!) {
        jobApplication(jobPositionId: $jobPositionId, userId: $userId) {
            id
            challengeAssignment {
                id
                boilerplateImportDetails {
                    branch
                    framework
                    status
                }
            }
        }
    }
`);

export const GET_CHALLENGE_ASSIGNMENT_STATUS = gql(`
    query GetChallengeAssignmentStatus($jobPositionId: Int!, $userId: Int!) {
        jobApplication(jobPositionId: $jobPositionId, userId: $userId) {
            id
            challengeAssignment {
                id
                status
                testScoreEnabled
                submissionDeadline
            }
        }
    }
`);
