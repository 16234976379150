import {City, UserRelevanceInformation} from '@/api/types/__generated__/graphql';
import {Modify, NoNullFields} from '@/types/generic.types';

import {RecommendationRelevanceFormProps} from './types';

type UserRelevanceInformationNonNull = Omit<
    NoNullFields<UserRelevanceInformation>,
    '__typename' | 'preferredIndustries' | 'preferredCompanyTypes'
>;

type ClientSelectedUserRelevanceInformation = Modify<
    UserRelevanceInformationNonNull,
    {preferredCitiesWithMetaData?: City[]; preferredCities: City[] | undefined}
>;

function getClientSelectedUserRelevanceInformation(
    userRelevanceInformation: NonNullable<
        RecommendationRelevanceFormProps['userRelevanceInformation']
    >
): ClientSelectedUserRelevanceInformation {
    const preferredCities =
        userRelevanceInformation.preferredCitiesWithMetaData.map(toViewOptions);

    const interestedInLeadershipPositions =
        !!userRelevanceInformation.interestedInLeadershipPositions;

    return {
        userId: userRelevanceInformation.userId,
        preferredJobFamily: userRelevanceInformation.preferredJobFamily,
        interestedInLeadershipPositions: interestedInLeadershipPositions,
        linkedinProfileUrl: userRelevanceInformation.linkedinProfileUrl,
        preferredWorkLocations: userRelevanceInformation.preferredWorkLocations,
        preferredSpecialties: userRelevanceInformation.preferredSpecialties,
        preferredCitiesWithMetaData: preferredCities,
        spokenLanguages: userRelevanceInformation.spokenLanguages
    } as ClientSelectedUserRelevanceInformation;
}

const toViewOptions = (city: City) => ({
    id: city.id,
    name: `${city.name}, ${city.countryName}`,
    countryName: city.countryName
});

export {getClientSelectedUserRelevanceInformation};
