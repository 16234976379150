import PropTypes from 'prop-types';
import {useCallback} from 'react';

import {useUpdateUser} from '@/api/users/useUpdateUser';
import {toast} from '@/componentLibrary/components/Toast/toast';

import {CandidateForm} from './components/CandidateForm';

export function CandidateInfo({candidate}) {
    const [doUpdateUser, {loading}] = useUpdateUser(candidate.id);
    const updateUser = useCallback(
        data => {
            doUpdateUser(data)
                .then(
                    ({
                        data: {
                            updateUser: {ok, errorMessage}
                        }
                    }) => {
                        if (ok) {
                            toast({type: 'success'});
                        } else {
                            throw new Error(errorMessage);
                        }
                    }
                )
                .catch(() => toast({type: 'error'}));
        },
        [doUpdateUser]
    );

    return <CandidateForm candidate={candidate} save={updateUser} loading={loading} />;
}

CandidateInfo.propTypes = {
    candidate: PropTypes.shape({
        id: PropTypes.number.isRequired
    }).isRequired
};
