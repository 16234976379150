import {useCallback, useState} from 'react';

import {ERROR_TYPES} from './constants';

export function useApplyFormErrorStatesLogic() {
    const [hasEmailError, setHasEmailError] = useState(false);
    const [hasCountryBlockedError, setHasCountryBlockedError] = useState(false);
    const [warningIsVisible, setWarningIsVisible] = useState(false);

    const setError = useCallback((errorType, value = true) => {
        if (errorType === ERROR_TYPES.EMAIL_ERROR) {
            setHasEmailError(value);
        }

        if (errorType === ERROR_TYPES.COUNTRY_BLOCKED_ERROR) {
            setHasCountryBlockedError(value);
        }

        if (errorType === ERROR_TYPES.DISPLAY_WARNING) {
            setWarningIsVisible(value);
        }
    }, []);

    const clearErrors = useCallback(() => {
        setHasEmailError(false);
        setHasCountryBlockedError(false);
        setWarningIsVisible(false);
    }, []);

    return {hasEmailError, hasCountryBlockedError, warningIsVisible, clearErrors, setError};
}
