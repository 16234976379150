import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {SKIP_QUESTION_FOR_PERSONALITY_TEST_V2} from '@/api/personalityTests/mutations';
import {SkipQuestionForPersonalityTestV2Mutation} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';

export type SkipQuestionForPersonalityTestCallbackInput = {
    questionId: string;
    reason?: string;
};

export function useSkipQuestionForPersonalityTestV2(
    personalityTestId: string
): MutationHookReturnType<
    SkipQuestionForPersonalityTestV2Mutation,
    SkipQuestionForPersonalityTestCallbackInput
> {
    const [mutate, {error, loading}] = useMutation(SKIP_QUESTION_FOR_PERSONALITY_TEST_V2);

    const skipQuestionV2 = useCallback(
        data => {
            const skipQuestionForPersonalityTestInput = {
                personalityTestId,
                questionId: data.questionId,
                reason: data?.reason ?? ''
            };
            return mutate({variables: {skipQuestionForPersonalityTestInput}});
        },
        [mutate, personalityTestId]
    );

    return [skipQuestionV2, {error, loading}];
}
