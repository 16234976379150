import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GetBoilerplateImportDetailsQueryVariables} from '@/api/types/__generated__/graphql';

import {GET_BOILERPLATE_IMPORT_DETAILS} from './queries';

export function useBoilerplateImportDetails({
    jobPositionId,
    userId
}: {
    jobPositionId: number;
    userId: number;
}) {
    const variables: GetBoilerplateImportDetailsQueryVariables = useMemo(
        () => ({jobPositionId, userId}),
        [jobPositionId, userId]
    );

    const {data, error, loading, startPolling, stopPolling, client} = useQuery(
        GET_BOILERPLATE_IMPORT_DETAILS,
        {
            variables
        }
    );

    const importDetails = useMemo(
        () => data?.jobApplication?.challengeAssignment?.boilerplateImportDetails,
        [data]
    );

    return {
        client,
        error,
        loading,
        startPolling,
        stopPolling,
        importDetails,
        challengeAssignmentId: data?.jobApplication?.challengeAssignment?.id
    };
}
