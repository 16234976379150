import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';

import {FullPageModal, LargeModal, MediumModal, SmallModal, XsmallModal} from './styled';
import {ModalProps} from './types';

export function validateProps({
    close,
    back,
    size
}: {
    close?: () => void;
    back?: () => void;
    size: string;
}) {
    if ((!close || typeof close !== 'function') && (!back || typeof back !== 'function')) {
        throw new Error('Must provide a close or back function');
    }

    if (!Object.keys(MODAL_SIZE).includes(size)) {
        throw new Error('Size must be ' + Object.values(MODAL_SIZE).join(', '));
    }
}
export function getModalSize({size}: Partial<ModalProps>) {
    if (size === MODAL_SIZE.FULL_PAGE) {
        return FullPageModal;
    }
    if (size === MODAL_SIZE.LARGE) {
        return LargeModal;
    }
    if (size === MODAL_SIZE.MEDIUM) {
        return MediumModal;
    }
    if (size === MODAL_SIZE.XSMALL) {
        return XsmallModal;
    }

    return SmallModal;
}
