import {gql} from '@/api/types/__generated__';

export const SEND_INVITE_EMPLOYEE_EMAILS = gql(`
    mutation sendInviteEmployeeEmails($data: BatchInviteEmployeeEmails!) {
        sendBatchInviteEmployeeEmails(data: $data) {
            ok
            errorMessage
        }
    }
`);

export const SEND_SIGNUP_EMAIL = gql(`
    mutation sendSignupEmailToUser($data: SendSignupEmailInput!) {
        sendSignupEmail(data: $data) {
            ok
            errorMessage
        }
    }
`);

export const SEND_CANDIDATE_REMINDER_EMAILS = gql(`
    mutation sendBatchCandidateReminderEmails($data: BatchCandidateReminderEmailsInput!) {
        sendBatchCandidateReminderEmails(data: $data) {
            ok
            errorMessage
        }
    }
`);

export const SEND_INVITE_CANDIDATE_EMAILS = gql(`
    mutation sendBatchInviteCandidateEmails($data: BatchInviteCandidateEmails!) {
        sendBatchInviteCandidateEmails(data: $data) {
            ok
            errorMessage
        }
    }
`);
