const isValidHex = (hex: string) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

const getChunksFromString = (st: string, chunkSize: number) =>
    st.match(new RegExp(`.{${chunkSize}}`, 'g'));

const convertHexUnitTo256 = (hexStr: string) => parseInt(hexStr.repeat(2 / hexStr.length), 16);

function getAlphafloat(a: number, alpha: number) {
    if (typeof a !== 'undefined') {
        return a / 255;
    }

    if (typeof alpha !== 'number' || alpha < 0 || alpha > 1) {
        return 1;
    }

    return alpha;
}

export function hexToRGBA(hex: string, alpha: number) {
    if (!isValidHex(hex)) {
        throw new Error('Invalid HEX');
    }

    const chunkSize = Math.floor((hex.length - 1) / 3);
    const hexArr = getChunksFromString(hex.slice(1), chunkSize) as RegExpMatchArray;
    const [r, g, b, a] = hexArr.map(convertHexUnitTo256);

    return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`;
}

function colorToRGBA(color: string | CanvasGradient | CanvasPattern) {
    // Returns the color as an array of [r, g, b, a] -- all range from 0 - 255
    // color must be a valid canvas fillStyle. This will cover most anything
    // you'd want to use.
    // Examples:
    // colorToRGBA('red')  # [255, 0, 0, 255]
    // colorToRGBA('#f00') # [255, 0, 0, 255]
    const cvs = document.createElement('canvas');
    cvs.height = 1;
    cvs.width = 1;
    const ctx = cvs.getContext('2d') as CanvasRenderingContext2D;
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 1, 1);
    return ctx.getImageData(0, 0, 1, 1).data;
}

function byteToHex(num: number) {
    // Turns a number (0-255) into a 2-character hex number (00-ff)
    return ('0' + num.toString(16)).slice(-2);
}

export function colorToHex(color: string) {
    // Convert any CSS color to a hex representation(without opacity)
    // Examples:
    // colorToHex('red')            # '#ff0000'
    // colorToHex('rgb(255, 0, 0)') # '#ff0000'
    const rgba = colorToRGBA(color);
    const hex = [0, 1, 2]
        .map(function (idx) {
            return byteToHex(rgba[idx]);
        })
        .join('');
    return '#' + hex;
}

export function getColorOpacity(color: string) {
    // Returns color opacity in the scale of 0 to 1
    const rgba = colorToRGBA(color);
    return rgba[3] / 256;
}
