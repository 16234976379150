import {defineMessages} from 'react-intl';

export default defineMessages({
    organizations: {
        id: 'app.navigation.organizations'
    },
    applications: {
        id: 'app.navigation.applications'
    },
    jobs: {
        id: 'app.navigation.marketplace'
    }
});
