import {useEffect, useState} from 'react';

import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {LoadingScreen} from '@/componentLibrary/components/LoadingScreen';
import {AcceptMarketplaceConsent} from '@/pages/common/marketplace/AcceptMarketplaceConsent';

import {JobRecommendations} from '../../components/JobRecommendations';
import {useMyApplicationsLogic} from '../MyApplications/hooks';
import {Wrapper} from '../MyOrganizations/styled';

export const MyJobs = () => {
    const {
        loading,
        error,
        refetchUserOrganizations,
        userOrganizations,
        setPollingEnabledAfterAcceptingRecommendation,
        isRightAfterSignup,
        user,
        refetchUser
    } = useMyApplicationsLogic();
    const [reloadingOnSignup, setReloadingOnSignup] = useState(false);

    useEffect(() => {
        if (userOrganizations.length === 0 && isRightAfterSignup) {
            setReloadingOnSignup(true);
            setPollingEnabledAfterAcceptingRecommendation(true);
        }
    }, [
        isRightAfterSignup,
        userOrganizations.length,
        setPollingEnabledAfterAcceptingRecommendation
    ]);

    if (error) {
        return <ErrorState error={error} refetch={refetchUserOrganizations} />;
    }

    if (loading || (userOrganizations.length === 0 && reloadingOnSignup)) {
        return <LoadingScreen />;
    }

    if (!user?.hasRegisteredToCandidateServices) {
        return (
            <Wrapper>
                <AcceptMarketplaceConsent onAccept={refetchUser} />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <JobRecommendations
                setPollingEnabledAfterAcceptingRecommendation={
                    setPollingEnabledAfterAcceptingRecommendation
                }
            />
        </Wrapper>
    );
};
