import {defineMessages} from 'react-intl';

export default defineMessages({
    titleInvitation: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.titleInvitation'
    },
    sendInvitation: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.sendInvitation'
    },
    testComposition: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.testComposition'
    },
    description: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.description'
    },
    sendingInvitations: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.sendingInvitations'
    },
    invitationsSent: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.invitationsSent'
    },
    failedToSendInvitations: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.failedToSendInvitations'
    },
    previewEmail: {
        id: 'app.general.previewEmail'
    }
});
