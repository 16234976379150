import styled from 'styled-components';

import {OtherCaption} from '@/componentLibrary/tokens/typography';
import {ColorFgSubtle, FontSizeSmall, SpacingLarge} from '@/componentLibrary/tokens/variables';

import {FlexLayout} from '../../layout/FlexLayout';

export const Wrapper = styled.div`
    position: relative;
    display: inline-block;
    height: 100%;
    width: 100%;

    > svg {
        width: 100%;
        height: 100%;
    }
`;

export const LabelsWrapper = styled(FlexLayout)`
    width: 100%;
    height: ${SpacingLarge};
    align-items: center;
    justify-content: space-between;
`;

export const LabelWrapper = styled.span`
    ${OtherCaption};
    color: ${ColorFgSubtle};
    font-size: ${FontSizeSmall};
    text-align: center;
    width: 10%;
`;
