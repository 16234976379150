import {ReactNode} from 'react';

import {Empty} from '../components/Empty';
import {hasVisibleText} from './hasVisibleText';
import {parseAndWrap} from './parseAndWrap';
import {replaceNewlinesWithBr} from './replaceNewlinesWithBr';

export function getVisibleText(text?: string | null): ReactNode {
    if (!text) {
        return <Empty />;
    }
    const html = replaceNewlinesWithBr(text);
    const element = parseAndWrap(html);
    return hasVisibleText(element) ? html : <Empty />;
}
