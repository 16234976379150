import styled, {css} from 'styled-components';

import {BorderBaseDefaultRule} from '@/componentLibrary/tokens/customVariables';
import {
    BorderRadiusBaseLarge,
    ColorBgDefault,
    ColorBgInset,
    ShadowElevationLow,
    SpacingSmall
} from '@/componentLibrary/tokens/variables';

import {ContainerProps} from './types';

export const Container = styled.div<ContainerProps>`
    max-width: 1100px;
    padding: ${SpacingSmall};
    background-color: ${ColorBgDefault};
    ${BorderBaseDefaultRule}
    border-radius: ${BorderRadiusBaseLarge};
    height: ${({$height}) => $height};

    ${({$isActive}) =>
        !$isActive &&
        css`
            &:hover {
                box-shadow: ${ShadowElevationLow};
            }
        `}

    ${({$isDisabled}) =>
        $isDisabled &&
        css`
            background-color: ${ColorBgInset};
            cursor: not-allowed;
        `}

    ${({$isClickable}) =>
        $isClickable &&
        css`
            cursor: pointer;
        `}
`;
