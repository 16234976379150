import qs from 'qs';
import {useCallback} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';

import {PERSONALITY_IRT_LINKS} from '@/pages/PersonalityTestIrt/links';
import {parseNumberIdFromString} from '@/utils/urlParamsParsers';

const DEFAULT_REDIRECT = '/o/home/business-profile';

type RouteParams = {
    personalityTestId?: string;
    organizationId?: string;
    jobPositionId?: string;
};

export function usePersonalityTestIrtLogic() {
    const history = useHistory();

    const goToTestCompletedScreen = (personalityTestId: string, organizationId?: number) => {
        let url = PERSONALITY_IRT_LINKS.getTestCompletedLink({
            testId: personalityTestId,
            organizationId: organizationId?.toString()
        });
        const params = qs.parse(location.search, {ignoreQueryPrefix: true});
        if (params.redirect) {
            url += `?redirect=${params.redirect}`;
        }
        history.push(url);
    };

    const goToTestResult = (personalityTestId: string, organizationId?: number) => {
        let url = PERSONALITY_IRT_LINKS.getTestResultLink({
            testId: personalityTestId,
            organizationId: organizationId?.toString()
        });
        const params = qs.parse(location.search, {ignoreQueryPrefix: true});
        if (params.redirect) {
            url += `?redirect=${params.redirect}`;
        }

        history.push(url);
    };

    const getRedirectUrlFromSearchParam = () => {
        const params = qs.parse(location.search, {ignoreQueryPrefix: true});
        return params['redirect'] ? params['redirect'] : DEFAULT_REDIRECT;
    };

    const getOrganizationIdFromSearchParam = () => {
        const params = qs.parse(location.search, {ignoreQueryPrefix: true});
        if (!params['organization_id']) {
            history.push('/not-found');
        }
        const organizationId = params['organization_id'];
        return parseNumberIdFromString(organizationId as string);
    };

    const redirect = () => {
        const redirectFromParams = getRedirectUrlFromSearchParam();
        const redirectUrl = redirectFromParams ? redirectFromParams : DEFAULT_REDIRECT;

        history.push(redirectUrl as string);
    };

    const getUrlParams = useCallback((params: RouteComponentProps<RouteParams>) => {
        const {personalityTestId} = params.match.params;
        const {organizationId: organizationIdString} = params.match.params;
        const organizationId = parseNumberIdFromString(organizationIdString);
        const {jobPositionId: jobPositionIdString} = params.match.params;
        const jobPositionId = parseNumberIdFromString(jobPositionIdString);

        return {
            personalityTestId: personalityTestId as string,
            organizationId: organizationId ?? undefined,
            jobPositionId: jobPositionId ?? undefined
        };
    }, []);

    return {
        goToTestCompletedScreen,
        goToTestResult,
        getOrganizationIdFromSearchParam,
        getRedirectUrlFromSearchParam,
        redirect,
        getUrlParams
    };
}
