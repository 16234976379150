import PropTypes from 'prop-types';
import {useCallback} from 'react';
import styled from 'styled-components';

import {CheckBox} from '@/componentLibrary/components/controls/CheckBox';
import {CHECKBOX_VALUES} from '@/componentLibrary/components/controls/CheckBox/constants';

export function SelectionHeader({
    isChecked,
    isIndeterminate,
    handleChecking,
    handleUnchecking
}) {
    const handleChange = useCallback(() => {
        if (isChecked || isIndeterminate) {
            handleUnchecking();
        } else {
            handleChecking();
        }
    }, [isChecked, isIndeterminate, handleChecking, handleUnchecking]);

    const getValue = useCallback(() => {
        if (isChecked) {
            return CHECKBOX_VALUES.CHECKED;
        }

        if (isIndeterminate) {
            return CHECKBOX_VALUES.INDETERMINATE;
        }

        return CHECKBOX_VALUES.UNCHECKED;
    }, [isChecked, isIndeterminate]);

    return (
        <Wrapper>
            <CheckBox onChange={handleChange} value={getValue()} />
        </Wrapper>
    );
}

SelectionHeader.propTypes = {
    isIndeterminate: PropTypes.bool.isRequired,
    isChecked: PropTypes.bool.isRequired,
    handleChecking: PropTypes.func.isRequired,
    handleUnchecking: PropTypes.func.isRequired
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;
