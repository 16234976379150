import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.components.testResults.LogicTestReport.overviewCardHeader.title'
    },
    seeFullTestResult: {
        id: 'app.components.testResults.LogicTestReport.overviewCardHeader.seeFullTestResult'
    }
});
