import {useCallback} from 'react';

import {useValidateSsoToken} from '@/api/auth/useValidateSsoToken';
import {useSsoRedirectionLogic} from '@/pages/Login/hooks/useSsoRedirectionLogic';
import {SsoState} from '@/services/auth/sso/types';

export function useSsoAuthenticateLogic(ssoState: SsoState, idToken: string | null) {
    const {redirectToSignupV2} = useSsoRedirectionLogic(ssoState);

    const [doValidateSsoToken, {error, loading}] = useValidateSsoToken(
        ssoState.ssoProvider,
        idToken
    );

    const setSsoDataInSessionStorage = useCallback(
        (email, firstName, lastName, ssoToken, ssoProvider) => {
            window.sessionStorage.setItem(
                'SSO_AUTH_DATA',
                JSON.stringify({
                    email,
                    firstName,
                    lastName,
                    ssoToken,
                    ssoProvider
                })
            );
        },
        []
    );

    const authenticateWithSso = useCallback(
        stateString => {
            doValidateSsoToken()
                .then(({data}) => {
                    const {ok, errorMessage, tokenDetails} = data?.validateSsoToken ?? {};
                    if (ok && tokenDetails) {
                        setSsoDataInSessionStorage(
                            tokenDetails.email,
                            tokenDetails.givenName,
                            tokenDetails.familyName,
                            idToken,
                            ssoState.ssoProvider
                        );

                        redirectToSignupV2(
                            stateString ? decodeURIComponent(stateString) : undefined
                        );
                    } else {
                        throw new Error(errorMessage ?? '');
                    }
                })
                .catch(e => redirectToSignupV2(e.message));
        },
        [
            doValidateSsoToken,
            setSsoDataInSessionStorage,
            idToken,
            ssoState.ssoProvider,
            redirectToSignupV2
        ]
    );

    return {
        authenticateWithSso,
        error,
        loading
    };
}
