import {Box} from '@/componentLibrary/components/Box';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {ModalEmpty} from '@/componentLibrary/components/modals/ModalEmpty';
import {H3} from '@/componentLibrary/components/typography';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    ButtonsWrapper,
    ContentWrapper,
    DescriptionWrapper,
    TextWrapper
} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTesting/NextQuestionModal/styled';
import {NextQuestionModalProps} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTesting/NextQuestionModal/types';

import messages from './messages';

export function NextQuestionModal({leaveTheTest, onNextQuestion}: NextQuestionModalProps) {
    const phrases = useExtractPhraseConstants(messages);

    return (
        <ModalEmpty>
            <ContentWrapper>
                <Box>
                    <TextWrapper>
                        <H3>{phrases.title}</H3>
                        <DescriptionWrapper>{phrases.description}</DescriptionWrapper>
                    </TextWrapper>
                    <ButtonsWrapper>
                        {leaveTheTest ? (
                            <SecondaryButton onClick={leaveTheTest}>
                                {phrases.leaveTheTest}
                            </SecondaryButton>
                        ) : null}
                        <PrimaryButton onClick={onNextQuestion}>
                            {phrases.nextQuestion}
                        </PrimaryButton>
                    </ButtonsWrapper>
                </Box>
            </ContentWrapper>
        </ModalEmpty>
    );
}
