import {useEffect, useState} from 'react';

import {useValidateSignupToken} from '@/api/users/register/useValidateSignupToken';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {SignupTokenData} from './types';

export function useValidateSignupTokenLogic(token: string) {
    const [response, setResponse] = useState<SignupTokenData | null>(null);
    const [doValidateSignupToken, {loading, error}] = useValidateSignupToken();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        doValidateSignupToken(token)
            .then(({data}) => {
                const validateSignupToken = data?.validateSignupToken;

                if (
                    isDefined(validateSignupToken) &&
                    validateSignupToken.ok &&
                    validateSignupToken.userId
                ) {
                    setResponse({
                        userId: validateSignupToken.userId,
                        email: validateSignupToken.email,
                        firstName: validateSignupToken.firstName,
                        lastName: validateSignupToken.lastName,
                        language: validateSignupToken.language,
                        enforcedAuthenticationMethod:
                            validateSignupToken.enforcedAuthenticationMethod,
                        registrationType: validateSignupToken.registrationType,
                        organizationOktaConfiguration:
                            validateSignupToken.organizationOktaConfiguration
                    });
                } else {
                    setErrorMessage(validateSignupToken?.errorMessage ?? '');
                }
            })
            .catch(() => null);
    }, [token, doValidateSignupToken]);

    return {loading, error, response, errorMessage};
}
