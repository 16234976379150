import styled from 'styled-components';

import {ColorBgDefault} from '@/componentLibrary/tokens/variables';
import {DropdownMenuItemWrapper} from './DropdownMenuItem/styled';

export const NoItems = styled(DropdownMenuItemWrapper)`
    justify-content: center;

    &:hover {
        background-color: ${ColorBgDefault};
    }
`;
