import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingMedium} from '@/componentLibrary/tokens/variables';

export const BannerWrapper = styled.div`
    margin: ${SpacingMedium} 0;
`;

export const Wrapper = styled.div<{wrap: string; justifyContent: string}>`
    display: grid;
    grid-template-columns: auto minmax(360px, 1fr);
    gap: 36px;

    /* @TODO: change breakpoint from tablet to mobile when sidebar on left is toggleable */
    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        grid-template-columns: 100%;
    }
`;

export const ScoreGroupWrapper = styled.div`
    > *:first-child {
        margin-bottom: ${SpacingMedium};
    }
`;
