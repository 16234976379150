import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_ACCESS_TOKEN_DETAILS} from './queries';

export function useAccessTokenDetails() {
    const {data, error, loading, refetch} = useQuery(GET_ACCESS_TOKEN_DETAILS);
    const accessTokenDetails = useMemo(() => {
        return data ? data.accessTokenDetails : null;
    }, [data]);

    return {error, loading, accessTokenDetails, refetch};
}
