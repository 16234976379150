import {HelpCenterLink} from '@/componentLibrary/components/links/HelpCenterLink';
import {H3} from '@/componentLibrary/components/typography/Headings';
import {ColorFgMuted} from '@/componentLibrary/tokens/variables';
import {HELP_CENTER} from '@/constants/helpCenter';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {getUserName} from '@/utils/misc';

import messages from './messages';
import {ExtendedP2, Wrapper} from './styled';
import {ReportIntroProps} from './types';

export function ReportIntro({user}: ReportIntroProps) {
    const phrases = useExtractPhraseConstants(messages);

    return (
        <Wrapper>
            <H3>
                {getUserName(user)} - {phrases.title}
            </H3>
            <ExtendedP2 color={ColorFgMuted}>{phrases.intro}</ExtendedP2>
            <HelpCenterLink url={HELP_CENTER.leadershipReport} hideWhenPrinting>
                {phrases.helpCenter}
            </HelpCenterLink>
        </Wrapper>
    );
}
