import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.title'
    },
    description: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.description'
    },
    modalTitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.modalTitle'
    },
    showFAQ: {
        id: 'app.general.showFAQ'
    },
    header: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.header'
    },
    forJobs: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.forJobs'
    },
    personalityTest: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.personalityTest'
    },
    personalityTestSubtitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.personality.leadershipReportSubTitle'
    },
    personalityTestDescription: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.personalityTestDescription'
    },
    personalityTestLeadershipReportDescription: {
        id: 'app.pages.organization.home.takePersonalityTest.description.includingLeadershipReport'
    },
    personalityTimeText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.personalityTimeText'
    },
    logicTest: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.logicTest'
    },
    logicTestDescription: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.logicTestDescription'
    },
    logicTestTimeText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.logicTestTimeText'
    },
    logicTestIrt: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.logicTestIrt'
    },
    logicTestIrtDescription: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.logicTestIrtDescription'
    },
    logicTestIrtTimeText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.logicTestIrtTimeText'
    },
    bannerTitleSuccess: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.bannerTitleSuccess'
    },
    bannerDescriptionSuccessWithCompletionDate: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.bannerDescriptionSuccessWithCompletionDate'
    },
    bannerDescriptionSuccess: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.bannerDescriptionSuccess'
    },
    bannerDescriptionSuccessNoOrgName: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.bannerDescriptionSuccessNoOrgName'
    },
    bannerSharePrevious: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.bannerSharePrevious'
    },
    bannerSharePreviousDescription: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.bannerSharePreviousDescription'
    },
    registerTitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.registerTitle'
    },
    registerDescription: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.registerDescription'
    },
    registerButton: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.registerButton'
    },
    codingTestTimeText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestTimeText'
    },
    codingTestOneTimeText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestOneTimeText'
    },
    codingTestWithoutTimeText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestWithoutTimeText'
    },
    codingTestLimitText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestLimitText'
    },
    codingTestDescription: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTest.description'
    },
    codingTest: {
        id: 'app.pages.user.chooseOrganization.myApplication.codingTest'
    }
});
