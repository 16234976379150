import styled from 'styled-components';

import {InPageHeader} from '@/componentLibrary/components/InPageHeader';
import {SpacingMedium, SpacingSmall} from '@/componentLibrary/tokens/variables';

const Header = styled(InPageHeader)`
    margin-bottom: ${SpacingSmall};
`;

const Wrapper = styled.div`
    margin-bottom: ${SpacingMedium};
`;

export {Header, Wrapper};
