import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {ValidateSignupTokenMutation} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {VALIDATE_SIGNUP_TOKEN} from './mutations';

export function useValidateSignupToken(): MutationHookReturnType<
    ValidateSignupTokenMutation,
    string
> {
    const [mutate, {error, loading}] = useMutation(VALIDATE_SIGNUP_TOKEN);

    const doValidateSignupToken = useCallback(token => mutate({variables: {token}}), [mutate]);

    return [doValidateSignupToken, {error, loading}];
}
