import {parse} from 'qs';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {useAccessTokenDetails} from '@/api/auth/useAccessTokenDetails';
import {useSelectEmploymentOrganization} from '@/api/auth/useSelectEmploymentOrganization';
import {useMyJobApplications} from '@/api/users/useMyJobApplications';
import {useUserWithRecommendations} from '@/api/users/useRecommendationsUser';
import {useUserOrganizations} from '@/api/users/useUserOrganizations';
import {ALVA_SIGNUP_KEY} from '@/services/auth/constants';

import {userLinks} from '../../links';

export function useMyApplicationsLogic() {
    const history = useHistory();
    const location = useLocation();
    const [candidateOrganizationSelectedByUser, setCandidateOrganizationSelectedByUser] =
        useState<number | null>(null);
    const [isRightAfterSignup, setIsRightAfterSignup] = useState(false);
    const {
        userOrganizations,
        loading: loadingUserOrganizations,
        error,
        refetch: refetchUserOrganizations,
        startPolling: startPollingUserOrganizations,
        stopPolling: stopPollingUserOrganizations
    } = useUserOrganizations();
    const {resetOrganizationSelection} = useSelectEmploymentOrganization();
    const {loading: loadingAccessTokenDetails, accessTokenDetails} = useAccessTokenDetails();

    const currentAuthenticationMethod = useMemo(() => {
        return accessTokenDetails?.authenticatedWith;
    }, [accessTokenDetails?.authenticatedWith]);

    const {
        user,
        loading: loadingRecommendationSettings,
        refetch: refetchUser
    } = useUserWithRecommendations();
    const hasRecommendationsEnabled = user?.recommendationsSettings?.recommendationsEnabled;

    const [
        pollingEnabledAfterAcceptingRecommendation,
        setPollingEnabledAfterAcceptingRecommendation
    ] = useState(false);

    const {
        startPolling: startPollingMyJobApplications,
        stopPolling: stopPollingMyJobApplications,
        loading: loadingMyJobApplications
    } = useMyJobApplications();

    useEffect(() => {
        if (window.localStorage) {
            const signedUp = window.localStorage.getItem(ALVA_SIGNUP_KEY);
            if (signedUp) {
                window.localStorage.removeItem(ALVA_SIGNUP_KEY);
                setIsRightAfterSignup(true);
            }
        }
    }, []);

    useEffect(() => {
        if (pollingEnabledAfterAcceptingRecommendation) {
            startPollingUserOrganizations(1500);
            startPollingMyJobApplications(1500);
            setTimeout(() => {
                setPollingEnabledAfterAcceptingRecommendation(false);
                stopPollingUserOrganizations();
                stopPollingMyJobApplications();
            }, 5000);
        }
    }, [
        pollingEnabledAfterAcceptingRecommendation,
        startPollingMyJobApplications,
        startPollingUserOrganizations,
        stopPollingMyJobApplications,
        stopPollingUserOrganizations
    ]);

    useEffect(() => {
        if (userOrganizations.length === 1) {
            const organization = userOrganizations[0];

            if (
                organization.requiresAcceptance ||
                !organization.acceptedAt ||
                (organization.enforcedAuthenticationMethod &&
                    organization.enforcedAuthenticationMethod !==
                        currentAuthenticationMethod) ||
                loadingRecommendationSettings ||
                hasRecommendationsEnabled
            ) {
                return;
            }

            if (organization.isCandidate) {
                setCandidateOrganizationSelectedByUser(organization.organizationId);
            }
        }
    }, [
        userOrganizations,
        currentAuthenticationMethod,
        hasRecommendationsEnabled,
        loadingRecommendationSettings
    ]);

    useEffect(() => {
        if (candidateOrganizationSelectedByUser) {
            resetOrganizationSelection().then(() => {
                const params = parse(location.search, {ignoreQueryPrefix: true});
                const url =
                    params.redirect ??
                    userLinks.jobApplication(candidateOrganizationSelectedByUser);
                history.push(url as string);
            });
        }
    }, [candidateOrganizationSelectedByUser, resetOrganizationSelection, history, location]);

    const candidateUserOrganizationsSortFn = useCallback((organizationA, organizationB) => {
        if (organizationA.jobApplicationsWithdrawnAt === null) {
            return -1;
        }

        return (
            organizationA.jobApplicationsWithdrawnAt - organizationB.jobApplicationsWithdrawnAt
        );
    }, []);

    const myApplications = userOrganizations
        .filter(({requiresAcceptance, isCandidate}) => !requiresAcceptance && isCandidate)
        .sort(candidateUserOrganizationsSortFn);

    const pendingInvitations = userOrganizations.filter(
        userOrganization => userOrganization.requiresAcceptance
    );
    return {
        loading:
            loadingMyJobApplications ||
            loadingAccessTokenDetails ||
            loadingRecommendationSettings ||
            loadingUserOrganizations,
        error,
        refetchUserOrganizations,
        userOrganizations,
        myApplications,
        setCandidateOrganizationSelectedByUser,
        setPollingEnabledAfterAcceptingRecommendation,
        isRightAfterSignup,
        pendingInvitations,
        pollingEnabledAfterAcceptingRecommendation,
        user,
        refetchUser
    };
}
