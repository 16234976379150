import {InterviewContentItem} from '@/api/assessment/interviews/useInterviewContentItemScoresForJobApplication';
import {InterviewRequest} from '@/api/assessment/interviews/useJobApplicationInterviewRequests';
import {InterviewContentRatingType} from '@/api/types/__generated__/graphql';
import {ScorecardCriterionInfo} from '@/pages/Organization/pages/Assessment/components/ScorecardCriterionInfo';
import {formatScore} from '../../utils';
import {ScorecardResultsBreakdown} from '../ScorecardResultsBreakdown';
import {InfoWrapper, ResultsWrapper, Score} from './styled';

type Props = {
    item: InterviewContentItem;
    interviewRequests: Partial<InterviewRequest>[];
};

export function ScorecardCriterionResults({item, interviewRequests}: Props) {
    const displayedScore =
        item.ratingType !== InterviewContentRatingType.NO_RATING ? formatScore(item) : '';

    return (
        <>
            <InfoWrapper>
                <ScorecardCriterionInfo contentItem={item} />
            </InfoWrapper>
            <ResultsWrapper>
                <Score>{displayedScore}</Score>
                <ScorecardResultsBreakdown
                    averageScore={item.aggregatedScore?.competencyScoreAverage ?? 0}
                    interviewScores={item.publishedScores}
                    allInterviewRequests={interviewRequests}
                    ratingType={item.ratingType}
                />
            </ResultsWrapper>
        </>
    );
}
