import {ReactNode} from 'react-markdown';

interface ActionOverlayFunction {
    (arg1: (open?: boolean) => void): ReactNode;
}

// Type guard to check if a value conforms to the ActionOverlayFunction interface
export function isActionOverlayFunction(value: unknown): value is ActionOverlayFunction {
    return typeof value === 'function';
}
