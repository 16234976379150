import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';
import {Popover} from '@/componentLibrary/components/Tooltips/Popover';
import {OneDimensionalBar} from '@/componentLibrary/components/charts/OneDimensionalBar';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {H3, H4, P2} from '@/componentLibrary/components/typography';
import {
    ColorBaseGreen800,
    ColorBaseGrey200,
    ColorBaseGrey600,
    ColorBaseGrey800,
    ColorBaseRed900,
    ColorBaseYellow900
} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {BannerType} from '@/componentLibrary/components/banners/constants';
import {AIScoreDisplay} from './components/AIScoreDisplay';
import {DetailedResultsModal} from './components/DetailedResultsModal/DetailedResultsModal';
import {getScores} from './getScores';
import messages from './messages';
import {
    AIBanner,
    AIScoreCircles,
    AIScoreHeader,
    AIScoreInfo,
    AIScoreSection,
    AIScoreTitle,
    AIScoreWrapper,
    BarWrapper,
    CodingTestResults,
    CodingTestResultsHeader,
    CodingTestResultsWrapper,
    ColorCircle,
    DetailedResultBtn,
    InfoItem,
    TestScoreRow,
    TestScoreTitle,
    TestScoreWrapper
} from './styled';
import {AutomatedScoreV2ResultsProps, RenderAIScoreSectionProps} from './types';
import {
    getScoreGradeColor,
    getTotalAutoScore,
    toDisplayAutomatedScore,
    toScorePercentage
} from './utils';

export function AutomatedScoreV2Results({
    challengeAssignment,
    refetch,
    isAutoScoreDisabled = false,
    user
}: AutomatedScoreV2ResultsProps) {
    const phrases = useExtractPhraseConstants(messages);

    const {automatedScores, hasTestScore, hasQualityScore, hasReviewScore, hasDetailedScores} =
        getScores({challengeAssignment});

    const {modalIsOpen, openModal, closeModal} = useSimpleModalLogic();

    const renderAIScoreSection = ({
        score,
        title,
        tooltipContent
    }: RenderAIScoreSectionProps) => {
        const displayScore = isDefined(score) && toDisplayAutomatedScore(score);
        const scoreColor = getScoreGradeColor(score);

        return (
            <AIScoreSection disabled={isAutoScoreDisabled}>
                <AIScoreTitle disabled={isAutoScoreDisabled}>
                    <div>{title}</div>
                    {!isAutoScoreDisabled && (
                        <Popover
                            openOnHover
                            content={tooltipContent}
                            trigger={<Icon icon={Icons.INFO} size={16} />}
                        />
                    )}
                    <AIScoreCircles>
                        {Array.from(Array(3).keys()).map(i => (
                            <ColorCircle
                                key={i}
                                disabled={isAutoScoreDisabled}
                                color={
                                    displayScore && i < displayScore
                                        ? scoreColor
                                        : ColorBaseGrey200
                                }
                                size={8}
                            />
                        ))}
                    </AIScoreCircles>
                </AIScoreTitle>
                <BarWrapper disabled={isAutoScoreDisabled}>
                    <OneDimensionalBar
                        scoreInPercentage={
                            isAutoScoreDisabled ? 100 : toScorePercentage(score || 0)
                        }
                        color={
                            isAutoScoreDisabled && scoreColor ? ColorBaseGrey600 : scoreColor
                        }
                    />
                    {!isAutoScoreDisabled && displayScore && (
                        <AIScoreDisplay data-testId="aiScoreDisplay" score={displayScore} />
                    )}
                </BarWrapper>
            </AIScoreSection>
        );
    };

    if (
        !automatedScores ||
        challengeAssignment?.status !== ChallengeAssignmentStatus.SUBMITTED ||
        (!hasTestScore && !hasQualityScore && !hasReviewScore)
    ) {
        return null;
    }

    const totalAutoScore = getTotalAutoScore(automatedScores);

    return (
        <>
            <CodingTestResultsWrapper>
                <CodingTestResultsHeader>
                    <H3>{phrases.resultsHeader}</H3>
                    {hasDetailedScores && (
                        <DetailedResultBtn small onClick={openModal}>
                            {phrases.resultsDetailBtn}
                        </DetailedResultBtn>
                    )}
                </CodingTestResultsHeader>
                <CodingTestResults>
                    {(isAutoScoreDisabled || hasQualityScore || hasReviewScore) && (
                        <AIScoreWrapper>
                            <AIScoreHeader>
                                <TestScoreTitle>
                                    <H4>{phrases.autoscoreByAI}</H4>
                                    {!isAutoScoreDisabled &&
                                        isDefined(totalAutoScore.score) && (
                                            <AIScoreDisplay
                                                data-testId="overallAiScoreDisplay"
                                                score={totalAutoScore.score}
                                                base={totalAutoScore.total}
                                            />
                                        )}
                                </TestScoreTitle>
                                <AIScoreInfo>
                                    <InfoItem>
                                        <ColorCircle color={ColorBaseRed900} />
                                        <Popover
                                            openOnHover
                                            content={phrases.scoreGradeLowTooltipContent}
                                            trigger={phrases.scoreGradeLow}
                                        />
                                    </InfoItem>
                                    <InfoItem>
                                        <ColorCircle color={ColorBaseYellow900} />
                                        <Popover
                                            openOnHover
                                            content={phrases.scoreGradeMidTooltipContent}
                                            trigger={phrases.scoreGradeMid}
                                        />
                                    </InfoItem>
                                    <InfoItem>
                                        <ColorCircle color={ColorBaseGreen800} />
                                        <Popover
                                            openOnHover
                                            content={phrases.scoreGradeHighTooltipContent}
                                            trigger={phrases.scoreGradeHigh}
                                        />
                                    </InfoItem>
                                </AIScoreInfo>
                            </AIScoreHeader>
                            <AIBanner type={BannerType.ATTENTION}>
                                {phrases.aiDisclaimer}
                            </AIBanner>
                            {(isAutoScoreDisabled || hasQualityScore) &&
                                renderAIScoreSection({
                                    score: automatedScores?.qualityScore?.score,
                                    title: phrases.qualityScore,
                                    tooltipContent: phrases.qualityScoreTooltip
                                })}

                            {(isAutoScoreDisabled || hasReviewScore) &&
                                renderAIScoreSection({
                                    score: automatedScores?.reviewScore?.score,
                                    title: phrases.reviewScore,
                                    tooltipContent: phrases.reviewScoreTooltip
                                })}
                        </AIScoreWrapper>
                    )}
                    {hasTestScore && (
                        <TestScoreWrapper>
                            <TestScoreRow>
                                <TestScoreTitle>
                                    <H4>{phrases.automatedTestScore}</H4>
                                    <Popover
                                        openOnHover
                                        content={phrases.automatedTestScoreTooltip}
                                        trigger={<Icon icon={Icons.INFO} size={16} />}
                                    />
                                </TestScoreTitle>
                                <P2>{automatedScores?.testScore?.score || 0}%</P2>
                            </TestScoreRow>
                            <OneDimensionalBar
                                scoreInPercentage={automatedScores?.testScore?.score || 0}
                                color={ColorBaseGrey800}
                            />
                        </TestScoreWrapper>
                    )}
                </CodingTestResults>
            </CodingTestResultsWrapper>
            {modalIsOpen && user && (
                <DetailedResultsModal
                    onClose={closeModal}
                    user={user}
                    refetch={refetch}
                    challengeAssignment={challengeAssignment}
                />
            )}
        </>
    );
}
