import {defineMessages} from 'react-intl';

export default defineMessages({
    personalityTest: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentProfileOverview.assessmentProfileDetails.personalityTest'
    },
    logicTest: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentProfileOverview.assessmentProfileDetails.logicTest'
    },
    scorecardCriteria: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentProfileOverview.assessmentProfileDetails.scorecardCriteria'
    },
    logicalAbility: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentProfileOverview.assessmentProfileDetails.logicalAbility'
    }
});
