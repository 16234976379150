import {getAssignmentScore} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateCodingTestPage/utils';
import {useCandidateRoleFitContext} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateRoleFitV2Page/context';
import {useMemo} from 'react';

export const useCodingTestListLogic = () => {
    const {jobApplication} = useCandidateRoleFitContext();

    const automatedScore = useMemo(
        () => getAssignmentScore(jobApplication.challengeAssignment ?? null),
        [jobApplication.challengeAssignment]
    );

    return {automatedScore};
};
