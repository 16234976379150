import styled, {css} from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingMedium, SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div<{fadeOut: boolean}>`
    padding: ${SpacingMedium} 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        display: block;
    }

    ${p =>
        p.fadeOut &&
        css`
            animation-duration: 0.5s;
            animation-fill-mode: both;
            animation-name: fade-out;
        `}
`;

export const Column = styled.div`
    margin-inline-end: ${SpacingXsmall};

    &:last-child {
        margin-inline-end: 0;
    }

    @media (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-bottom: ${SpacingMedium};
    }
`;
