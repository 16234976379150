import {useCallback, useMemo} from 'react';

import {useSyncChallengeScorecard} from '@/api/assessment/codingTests/useSyncChallengeScorecard';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import {getSyncAutoScorePhrase} from '@/pages/Organization/pages/Assessment/pages/JobPosition/messages';

import {SCORECARD_ACTION_TYPE} from '../../../../JobPosition/constants';
import {UseSyncScorecardLogicProps} from '../types';
import {isSyncInProgress as getIsSyncInProgress, getScorecardActionType} from '../utils';

export function useSyncScorecardLogic({
    refetchJobApplication,
    challengeAssignment
}: UseSyncScorecardLogicProps) {
    const {
        openModal: showAlert,
        closeModal: closeAlert,
        modalIsOpen: alertIsOpen
    } = useSimpleModalLogic();

    const [doUpdate] = useSyncChallengeScorecard();

    const scorecardActionType = useMemo(
        () => getScorecardActionType(challengeAssignment),
        [challengeAssignment]
    );

    const phrases = useExtractPhraseConstants(
        getSyncAutoScorePhrase(scorecardActionType || SCORECARD_ACTION_TYPE.UPDATED)
    );

    const alertTexts = useMemo(
        () => ({
            title: phrases.syncConfirmTitle,
            subtitle: phrases.syncConfirmSubtitle,
            leftButton: phrases.syncConfirmBtnCancel,
            rightButton: phrases.syncConfirmBtnConfirm
        }),
        [
            phrases.syncConfirmBtnCancel,
            phrases.syncConfirmBtnConfirm,
            phrases.syncConfirmSubtitle,
            phrases.syncConfirmTitle
        ]
    );

    const isSyncInProgress = useMemo(
        () => !!getIsSyncInProgress(challengeAssignment),
        [challengeAssignment]
    );

    const bannerTexts = useMemo(
        () => ({
            title: phrases.syncTitle,
            subtitle: phrases.syncSubtitle,
            btnTitle: isSyncInProgress ? phrases.syncInProgressBtnTitle : phrases.syncBtnTitle
        }),
        [
            phrases.syncBtnTitle,
            phrases.syncSubtitle,
            phrases.syncTitle,
            phrases.syncInProgressBtnTitle,
            isSyncInProgress
        ]
    );

    const handleClick = useCallback(() => {
        showAlert();
    }, [showAlert]);

    const onConfirm = useCallback(async () => {
        if (!challengeAssignment?.id) {
            return;
        }

        closeAlert();
        const loadingToastId = toast({
            type: 'loading',
            message: phrases.syncStarted,
            removeAfterMs: 3000
        });
        try {
            await doUpdate({assignmentId: challengeAssignment?.id});
            if (refetchJobApplication) {
                await refetchJobApplication();
            }
        } catch (_e) {
            toast({
                type: 'error',
                id: loadingToastId,
                removeAfterMs: 5000,
                message: phrases.syncError
            });
        }
    }, [
        challengeAssignment?.id,
        closeAlert,
        doUpdate,
        phrases.syncError,
        phrases.syncStarted,
        refetchJobApplication
    ]);

    return {
        bannerTexts,
        showAlert,
        closeAlert,
        alertIsOpen,
        handleClick,
        onConfirm,
        alertTexts,
        isSyncInProgress
    };
}
