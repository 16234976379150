import {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import {PhraseConstants, useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import messages from '@/pages/User/pages/JobApplication/components/TestsForJobPosition/components/JobPositions/messages';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {
    GetMyJobApplications_JobApplication,
    JobPositionProps,
    JobPositionsProps
} from './types';

const getConfiguredAssessmentMethodName = (
    jobApplication: GetMyJobApplications_JobApplication | null,
    phrases: PhraseConstants
) => {
    if (!isDefined(jobApplication)) {
        return [];
    }

    const configuredAssessmentMethods = {
        logic_test: jobApplication.jobPosition?.requireLogicIrtTest ?? false,
        personality_test: jobApplication.jobPosition?.requirePersonalityTest ?? false,
        coding_test: isDefined(jobApplication?.challengeAssignment)
    };

    const assessmentMethodsMessages = {
        logic_test: phrases.logic_test,
        personality_test: phrases.personality_test,
        coding_test: phrases.coding_test
    };

    const isConfigured = ([, value]: [string, boolean]): boolean => value;
    const toMessage = ([key]: [string, boolean]): string =>
        assessmentMethodsMessages[key as keyof typeof assessmentMethodsMessages];

    return Object.entries(configuredAssessmentMethods).filter(isConfigured).map(toMessage);
};

export const useJobPositionsLogic = ({jobApplications}: JobPositionsProps) => {
    const [jobApplication] = jobApplications;
    const intl = useIntl();
    const companyName = jobApplication?.jobPosition?.organization.name ?? '';
    const title = intl.formatMessage(messages.title, {companyName});

    return {title};
};

export const useJobPositionLogic = ({jobApplication}: JobPositionProps) => {
    const [showModal, setShowModal] = useState(false);
    const jobRelevanceInformation = useMemo(() => {
        if (!jobApplication.jobPosition) {
            return null;
        }
        return jobApplication.jobPosition.jobRelevanceInformation;
    }, [jobApplication]);

    const {jobPosition} = jobApplication;
    const toggleModal = () => setShowModal(!showModal);
    const phrases = useExtractPhraseConstants(messages);
    const isMarketplaceJob = isDefined(jobApplication.fromJobRecommendationId);
    const assessmentMethodsNames = getConfiguredAssessmentMethodName(jobApplication, phrases);

    return {
        jobPosition,
        jobRelevanceInformation,
        assessmentMethodsNames,
        phrases,
        isMarketplaceJob,
        showModal,
        toggleModal
    };
};
