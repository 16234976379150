import {useCallback, useState} from 'react';
import {UseRadioButtonLogicProps} from './types';
import {validateProps} from './utils';

export function useRadioButtonLogic<T>({
    isDefaultSelected,
    value,
    onSelect,
    isSelected
}: UseRadioButtonLogicProps<T>) {
    const [isValueControlled] = useState(value !== undefined);
    const [uncontrolledValue, setUncontrolledValue] = useState(isDefaultSelected);

    validateProps<T>({defaultValue: isDefaultSelected, value, isValueControlled});

    const handleChange = useCallback(
        e => {
            if (!isValueControlled) {
                setUncontrolledValue(e.target.checked);
            }
            onSelect?.(value as T);
        },
        [value, isValueControlled, onSelect]
    );

    const derivedValue = isValueControlled ? value : uncontrolledValue;
    const radioButtonIsChecked = isSelected ? isSelected : derivedValue === true;

    return {radioButtonIsChecked, handleChange};
}
