import {defineMessages} from 'react-intl';

export const logicTestDataCollectionMessages = defineMessages({
    completionPageProlificContent: {
        id: 'logicTestDataCollection.completionPageProlificContent'
    },
    completionPageTitle: {
        id: 'logicTestDataCollection.completionPageTitle'
    },
    formTitle: {
        id: 'logicTestDataCollection.formTitle'
    },
    generalContent: {
        id: 'logicTestDataCollection.generalContent'
    },
    goToInstructions: {
        id: 'logicTestDataCollection.goToInstructions'
    },
    instructionsTitle: {
        id: 'logicTestDataCollection.instructionsTitle'
    },
    instructionDescription: {
        id: 'logicTestDataCollection.instructionDescription'
    },
    prolificContent: {
        id: 'logicTestDataCollection.prolificContent'
    },
    startTestButton: {
        id: 'logicTestDataCollection.startTestButton'
    },
    testInstructionsContent: {
        id: 'logicTestDataCollection.testInstructionsContent'
    },
    testInstructionsTitle: {
        id: 'logicTestDataCollection.testInstructionsTitle'
    }
});
