import {TextEditor} from '@/componentLibrary/components/inputs/TextEditor';
import {useIntl} from 'react-intl';

import messages from '../../messages';
import {useReadMoreLogic} from './logic';
import {TertiaryButtonWrapper, Wrapper} from './styled';
import {ReadMoreProps} from './types';

export const ReadMore = ({text, limit}: ReadMoreProps) => {
    const intl = useIntl();
    const {isExpanded, onClick, textLimit} = useReadMoreLogic(text, limit);

    if (!text) {
        return null;
    }
    if (!limit) {
        return <TextEditor fullWidth showContentOnly content={text} />;
    }

    return (
        <Wrapper>
            <TextEditor fullWidth showContentOnly content={textLimit} />
            {text.length > limit && (
                <TertiaryButtonWrapper onClick={onClick}>
                    {intl.formatMessage(messages[isExpanded ? 'readLess' : 'readMore'])}
                </TertiaryButtonWrapper>
            )}
        </Wrapper>
    );
};
