import styled from 'styled-components';

import {H4} from '@/componentLibrary/components/typography/Headings';
import {SpacingLarge, SpacingXlarge} from '@/componentLibrary/tokens/variables';

export const Title = styled(H4)`
    margin-bottom: ${SpacingLarge};
`;
export const Value = styled(H4)`
    margin-bottom: ${SpacingXlarge};
`;
