import {Fragment} from 'react';

import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {Header} from '@/componentLibrary/components/layout/Header';
import {DisplaySmall} from '@/componentLibrary/components/typography/Display';
import {StartDataCollectionTestProps} from '@/pages/PersonalityDataCollection/pages/DataCollectionForm/components/StartDataCollectionTest/types';

import {useStartDataCollectionTestLogic} from './logic';

export function StartDataCollectionTest({
    externalId,
    showErrorToast,
    locale
}: StartDataCollectionTestProps) {
    const {creatingTest, startPersonalityTest, phrases} = useStartDataCollectionTestLogic({
        showErrorToast,
        externalId,
        locale
    });

    return (
        <Fragment>
            <Header>
                <DisplaySmall data-testid={'start-data-collection-test-title'}>
                    {phrases.testInstructionsTitle}
                </DisplaySmall>
            </Header>
            <FormField>{phrases.testInstructionsContent}</FormField>

            <FormField>
                <PrimaryButton
                    isLoading={creatingTest}
                    onClick={startPersonalityTest}
                    data-testid={'start-data-collection-test-button'}
                >
                    {phrases.startTestButton}
                </PrimaryButton>
            </FormField>
        </Fragment>
    );
}
