import PropTypes from 'prop-types';
import {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {TextField} from '@/componentLibrary/components/inputs/TextField';

import messages from './messages';

export class CandidateForm extends Component {
    static propTypes = {
        candidate: PropTypes.shape({
            id: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            email: PropTypes.string
        }),
        save: PropTypes.func,
        loading: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            data: this.getInitialStateFromProps(props.candidate)
        };
    }

    getInitialStateFromProps = candidate => {
        return {
            firstName: {
                value: candidate.firstName ? candidate.firstName : '',
                error: null
            },
            lastName: {
                value: candidate.lastName ? candidate.lastName : ''
            }
        };
    };

    handleSubmit = e => {
        e.preventDefault();

        const data = Object.keys(this.state.data).reduce(
            (acc, item) => Object.assign({}, acc, {[item]: this.state.data[item].value}),
            {}
        );

        this.props.save(data);
    };

    handleChange = e => {
        const {name, value} = e.target;

        this.setState(prevState =>
            Object.assign({}, prevState, {
                data: Object.assign({}, prevState.data, {
                    [name]: Object.assign({}, prevState.data[name], {value: value})
                })
            })
        );
    };

    hasChanges = () => {
        const {firstName, lastName} = this.props.candidate;

        return (
            firstName !== this.state.data.firstName.value ||
            lastName !== this.state.data.lastName.value
        );
    };

    render() {
        const {data} = this.state;

        return (
            <Wrapper>
                <form onSubmit={this.handleSubmit}>
                    <FormField>
                        <TextField
                            type={'text'}
                            value={this.props.candidate.email}
                            fullWidth
                            disabled
                            label={<FormattedMessage {...messages.email} />}
                        />
                    </FormField>

                    <FormField>
                        <TextField
                            type={'text'}
                            value={data.firstName.value}
                            fullWidth
                            name={'firstName'}
                            onChange={this.handleChange}
                            label={<FormattedMessage {...messages.firstName} />}
                        />
                    </FormField>

                    <FormField>
                        <TextField
                            type={'text'}
                            value={data.lastName.value}
                            fullWidth
                            name={'lastName'}
                            onChange={this.handleChange}
                            label={<FormattedMessage {...messages.lastName} />}
                        />
                    </FormField>

                    <PrimaryButton
                        isLoading={this.props.loading}
                        onClick={this.handleSubmit}
                        disabled={!this.hasChanges()}
                    >
                        <FormattedMessage {...messages.save} />
                    </PrimaryButton>
                </form>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    margin: 4px 0;
    max-width: 500px;
`;
