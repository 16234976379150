import styled, {css} from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderBaseDefaultColor,
    BorderWidthBase,
    ColorBaseBlue600,
    ColorBaseBlue700,
    ColorBaseBlue900,
    ColorBaseGrey700,
    ColorBgDefault,
    FontSizeDefault,
    FontSizeSmall,
    LineHeightsCompact,
    LineHeightsDefault,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';
import {
    DescriptionLabelProps,
    OptionProps
} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/components/QuestionOptions/types';

export const OptionsRow = styled.div`
    display: flex;
`;

export const OptionWrapper = styled.div`
    flex: 0 0 20%;
    height: 60px;
`;

export const Option = styled.div<OptionProps>`
    background: ${ColorBaseBlue600};
    border: ${BorderWidthBase} solid ${BorderBaseDefaultColor};
    box-sizing: border-box;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
    width: 100%;
    transition: background-color 0.5s, color 0.5s;

    &:hover {
        background: ${ColorBaseBlue900};
        color: ${ColorBgDefault};
    }

    ${({isSelected}) =>
        isSelected &&
        css`
            background: ${ColorBaseBlue900};
            color: ${ColorBgDefault};
        `};
`;

export const OptionLabel = styled.span`
    font-weight: bold;
    line-height: ${LineHeightsDefault};
    font-size: ${FontSizeDefault};
`;

export const AnswerDescription = styled.div`
    line-height: ${LineHeightsCompact};
    font-size: ${FontSizeSmall};
    color: ${ColorBaseGrey700};
    margin-bottom: ${SpacingXsmall};
`;

export const DescriptionColumns = styled.div`
    color: ${ColorBaseBlue700};
    margin-top: ${SpacingXsmall};
`;

export const DescriptionLabel = styled.div<DescriptionLabelProps>`
    text-align: ${({textAlign}) => textAlign};
    font-size: ${FontSizeSmall};
    display: flex;
    justify-content: ${({justify}) => justify};
`;

export const DescriptionText = styled.span`
    display: block;

    @media (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        max-width: 80px;
    }
`;
