import styled from 'styled-components';

import {P1} from '@/componentLibrary/components/typography';
import {SpacingLarge, SpacingSmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    margin: 0 auto;
    width: 35%;
    max-width: 640px;
    min-width: 420px;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: flex-start;
    flex-direction: column;

    @media only screen and (max-width: 760px) {
        width: 100%;
        padding: 36px ${SpacingSmall};
        min-width: 0;
    }

    @media only screen and (min-width: 761px) and (max-width: 800px) {
        width: 100%;
        padding: 36px 80px;
        min-width: 0;
    }
`;
export const BodyWrapper = styled(P1).attrs({as: 'div'})`
    margin-top: ${SpacingLarge};

    & p {
        margin-bottom: ${SpacingSmall};
    }
`;
