import {defineMessages} from 'react-intl';

export default defineMessages({
    heroTitle: {
        id: 'app.pages.user.candidateRelevanceModal.heroTitle'
    },
    heroDescription: {
        id: 'app.pages.user.candidateRelevanceModal.heroDescription'
    },
    submit: {
        id: 'app.pages.user.candidateRelevanceModal.submit'
    },
    successModalTitle: {
        id: 'app.pages.user.candidateRelevanceModal.successModalTitle'
    },
    successModalBody: {
        id: 'app.pages.user.candidateRelevanceModal.successModalBody'
    },
    successModalButton: {
        id: 'app.pages.user.candidateRelevanceModal.successModalButton'
    }
});
