import styled from 'styled-components';

import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {
    BorderRadiusBaseXsmall,
    FontSizeSmall,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

import {MarkerProps} from './types';

export const BulletItem = styled(FlexLayout)`
    margin-bottom: ${SpacingXsmall};
    text-align: left;
`;

export const BulletMarkerColumn = styled.div`
    flex-grow: 0;
    margin-right: ${SpacingXxsmall};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Marker = styled.div<MarkerProps>`
    background-color: ${props => props.markerColor};
    border-radius: ${BorderRadiusBaseXsmall};
    width: 10px;
    height: 10px;
    align-self: flex-start;
    margin: 5px 0;
`;

export const BulletLabelColumn = styled.div`
    flex-grow: 1;
    font-size: ${FontSizeSmall};
`;
