import {Element} from 'html-react-parser';
import {ReactNode, isValidElement} from 'react';

export function hasVisibleText(element: Element | string | ReactNode): boolean {
    if (typeof element === 'string') {
        return element.trim().length > 0;
    }

    if (isValidElement(element)) {
        const {type, props} = element;

        // Ignore certain element types
        if (
            typeof type === 'string' &&
            ['head', 'meta', 'title', 'style', 'script'].includes(type)
        ) {
            return false;
        }

        const children = props.children;
        if (children) {
            if (Array.isArray(children)) {
                return children.some(hasVisibleText);
            }
            return hasVisibleText(children);
        }
    }

    return false;
}
