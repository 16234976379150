import {gql} from '@/api/types/__generated__';

export const UPDATE_JOB_POSITION = gql(`
    mutation updateJobPosition($jobPositionId: Int!, $data: JobPositionUpdateInput!) {
        updateJobPosition(jobPositionId: $jobPositionId, data: $data) {
            ok
            errorMessage
            jobPosition {
                id
                name
                selectedProfileId
                team {
                    id
                    name
                }
                organizationRole {
                    id
                    name
                }
                requirePersonalityTest
                requireLogicTest
                requireLogicIrtTest
                requireInterviews
                testsWeight
                interviewsWeight
                allowsPublicApplications
                publicPageTitle
                publicPageInstructions
                challenge {
                    id
                }
                countryCode
                isInternal
                leadershipReportEnabled
                publicPageOrganizationNameEnabled
                publicPagePrivacyNoticeEnabled
                publicPageColorHeaderEnabled
                publicPageLogoEnabled
                publicPageColorHeader
                publicPageOrganizationName
            }
        }
    }
`);

export const ADD_CANDIDATE_FOR_JOB_POSITION = gql(`
    mutation addCandidateForJobPosition(
        $jobPositionId: Int!
        $candidate: CandidateInput!
        $notifyCandidate: Boolean!
        $notifyCandidateEvenIfExisting: Boolean
        $createdFrom: String
        $invitationOptions: CandidateInvitationOptionsInput
    ) {
        addCandidateForJobPosition(
            jobPositionId: $jobPositionId
            candidate: $candidate
            notifyCandidate: $notifyCandidate
            notifyCandidateEvenIfExisting: $notifyCandidateEvenIfExisting
            createdFrom: $createdFrom
            invitationOptions: $invitationOptions
        ) {
            ok
            errorMessage
            jobApplication {
                id
                created
                modified
                acceptedByCandidateAt
                currentStage
                createdFrom
                user {
                    id
                    firstName
                    lastName
                    email
                }
                withdrawnAt
                fromJobRecommendationId
            }
        }
    }
`);

export const REMOVE_CANDIDATES_FROM_JOB_POSITION = gql(`
    mutation removeCandidatesFromJobPosition($jobPositionId: Int!, $userIds: [Int]!) {
        removeCandidatesFromJobPosition(jobPositionId: $jobPositionId, userIds: $userIds) {
            ok
            errorMessage
            jobPositionId
            userIds
        }
    }
`);

export const CREATE_JOB_POSITION = gql(`
    mutation createJobPosition($organizationId: Int!, $data: CreateJobPositionInput!) {
        createJobPosition(organizationId: $organizationId, data: $data) {
            ok
            errorMessage
            jobPosition {
                id
                enabledBetaFeatures
            }
        }
    }
`);

export const DELETE_JOB_POSITION = gql(`
    mutation deleteJobPosition($jobPositionId: Int!, $deletedFrom: String!) {
        deleteJobPosition(jobPositionId: $jobPositionId, deletedFrom: $deletedFrom) {
            ok
            errorMessage
            jobPositionId
        }
    }
`);

export const SET_HIRING_MANAGER_FOR_JOB_POSITION = gql(`
    mutation setHiringManagerForJobPosition($data: HiringMemberInput!) {
        setHiringManagerForJobPosition(data: $data) {
            ok
            errorMessage
            jobPosition {
                id
                hiringManager {
                    id
                    user {
                        id
                        firstName
                        lastName
                        email
                    }
                }
            }
        }
    }
`);

export const ADD_MEMBER_TO_HIRING_TEAM = gql(`
    mutation addMemberToHiringTeam($data: HiringMemberInput!) {
        addMemberToHiringTeam(data: $data) {
            ok
            errorMessage
            jobPosition {
                id
                hiringTeam {
                    id
                    user {
                        id
                        firstName
                        lastName
                        email
                    }
                }
            }
        }
    }
`);

export const REMOVE_MEMBER_FROM_HIRING_TEAM = gql(`
    mutation removeMemberFromHiringTeam($data: HiringMemberInput!) {
        removeMemberFromHiringTeam(data: $data) {
            ok
            errorMessage
            jobPosition {
                id
                hiringTeam {
                    id
                    user {
                        id
                        firstName
                        lastName
                        email
                    }
                }
            }
        }
    }
`);

export const CHANGE_JOB_POSITION_CONFIDENTIALITY = gql(`
    mutation changeJobPositionConfidentiality(
        $isConfidential: Boolean!
        $jobPositionId: Int!
    ) {
        changeJobPositionConfidentiality(
            isConfidential: $isConfidential
            jobPositionId: $jobPositionId
        ) {
            ok
            errorMessage
        }
    }
`);

export const CHANGE_JOB_POSITION_STATUS = gql(`
    mutation changeJobPositionStatus(
        $status: JobPositionStatus!
        $jobPositionId: Int!
        $changedFrom: String!
    ) {
        changeJobPositionStatus(
            status: $status
            jobPositionId: $jobPositionId
            changedFrom: $changedFrom
        ) {
            ok
            errorMessage
            jobPosition {
                id
                currentStatus
            }
        }
    }
`);

export const CHANGE_JOB_POSITIONS_STATUSES = gql(`
    mutation changeJobPositionsStatuses(
        $status: JobPositionStatus!
        $jobPositionIds: [Int]!
        $changedFrom: String!
    ) {
        changeJobPositionsStatuses(
            status: $status
            jobPositionIds: $jobPositionIds
            changedFrom: $changedFrom
        ) {
            ok
            errorMessage
            jobPositions {
                id
                currentStatus
            }
        }
    }
`);

export const SET_TEST_PROFILE_FOR_JOB_POSITION = gql(`
    mutation setTestProfileForJobPosition(
        $jobPositionId: Int!
        $testProfileId: String
        $changedFrom: String!
    ) {
        setTestProfileForJobPosition(
            jobPositionId: $jobPositionId
            testProfileId: $testProfileId
            changedFrom: $changedFrom
        ) {
            ok
            errorMessage
            jobPosition {
                id
                selectedProfileId
                selectedTestProfile {
                    ...TestProfileFields
                }
            }
        }
    }
`);

export const SET_INVITE_CANDIDATE_EMAIL_TEMPLATE_FOR_JOB_POSITION = gql(`
    mutation SetInviteCandidateEmailTemplateForJobPosition(
        $data: JobPositionEmailTemplateInput!
    ) {
        setInviteCandidateEmailTemplateForJobPosition(data: $data) {
            ok
            errorMessage
            jobPosition {
                id
                inviteCandidateEmailTemplate {
                    id
                    name
                    subject
                    title
                    body
                    linkText
                }
            }
        }
    }
`);

export const SET_CANDIDATE_REMINDER_EMAIL_TEMPLATE_FOR_JOB_POSITION = gql(`
    mutation SetCandidateReminderEmailTemplateForJobPosition(
        $data: JobPositionEmailTemplateInput!
    ) {
        setCandidateReminderEmailTemplateForJobPosition(data: $data) {
            ok
            errorMessage
            jobPosition {
                id
                candidateReminderEmailTemplate {
                    id
                    name
                    subject
                    title
                    body
                    linkText
                }
            }
        }
    }
`);

export const SET_AUTOMATED_REMINDERS_FOR_JOB_POSITION = gql(`
    mutation setAutoRemindersForJobPosition($jobPositionId: Int!, $enabled: Boolean!) {
        setAutoRemindersForJobPosition(jobPositionId: $jobPositionId, enabled: $enabled) {
            ok
            errorMessage
            jobPosition {
                id
                automatedRemindersEnabled
            }
        }
    }
`);

export const SET_AUTOMATED_REMINDERS_INTERVAL_FREQUENCY_FOR_JOB_POSITION = gql(`
    mutation setAutoRemindersIntervalFrequencyForJobPosition(
        $jobPositionId: Int!
        $intervalFrequency: Int!
    ) {
        setAutoRemindersIntervalFrequencyForJobPosition(
            jobPositionId: $jobPositionId
            intervalFrequency: $intervalFrequency
        ) {
            ok
            errorMessage
            jobPosition {
                id
                automatedRemindersIntervalFrequency
            }
        }
    }
`);

export const SYNC_TEAMTAILOR_JOBS_FOR_ORGANIZATION = gql(`
    mutation syncTeamtailorJobsForOrganization($organizationId: Int!) {
        syncTeamtailorJobsForOrganization(organizationId: $organizationId) {
            ok
            errorMessage
        }
    }
`);

export const SYNC_JOB_POSITION_ATS_CONFIGURATION = gql(`
    mutation syncJobPositionAtsConfiguration($jobPositionId: Int!) {
        syncJobPositionAtsConfiguration(jobPositionId: $jobPositionId) {
            ok
            errorMessage
            jobPosition {
                ...JobPositionAssessmentMethodsFields
            }
        }
    }
`);

export const UPDATE_JOB_POSITION_ASSESSMENT_STAGES = gql(`
    mutation updateJobAssessmentStages(
        $jobPositionId: Int!
        $assessmentStages: AssessmentStagesInput!
    ) {
        updateJobAssessmentStages(
            jobPositionId: $jobPositionId
            assessmentStages: $assessmentStages
        ) {
            ok
        }
    }
`);

export const UPDATE_JOB_POSITION_ASSESSMENT_WEIGHTS = gql(`
    mutation updateAssessmentWeights($data: UpdateAssessmentWeightsInput!) {
        updateAssessmentWeights(data: $data) {
            ok
            errorMessage
            assessmentConfigurations {
                assessmentMethodKey
                assessmentMethodType
                assessmentMethodId
                weight
                weightPercentage
            }
        }
    }
`);
