import PropTypes from 'prop-types';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

import './detailsTable.css';
import './mainTheme.css';
import './resetTable.css';
import './rowHover.css';
import './themeWhite.css';

const splitFilterMethod = (filter, row) => {
    const id = filter.pivotId || filter.id;

    // Make sure both filter and row value are lowercase when comparing
    const rowValue = typeof row[id] === 'string' ? row[id].toLowerCase() : row[id];
    const filterValue =
        typeof filter.value === 'string' ? filter.value.trim().toLowerCase() : filter.value;

    if (rowValue === undefined) {
        return true;
    }

    if (typeof rowValue !== 'string') {
        return String(rowValue).startsWith(filterValue);
    }

    const matches = rowValue.split(' ').filter(item => String(item).startsWith(filterValue));

    return String(rowValue).startsWith(filterValue) || matches.length > 0;
};

const defaultTableProps = pageSize => ({
    className: 'theme_white',
    defaultFilterMethod: splitFilterMethod,
    minRows: 0,
    filterable: true,
    resizable: true,
    showPagination: false,
    pageSize
});

export const TableWrapper = props => {
    const {data, columns, children} = props;
    const defaultSize = data ? data.length : 0;
    const tableProps = Object.assign({}, defaultTableProps(defaultSize), props.tableProps);

    return (
        <ReactTable data={data} columns={columns} {...tableProps}>
            {children}
        </ReactTable>
    );
};

TableWrapper.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    tableProps: PropTypes.object,
    children: PropTypes.func
};
