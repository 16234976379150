import {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {useCreateInterviewRequest} from '@/api/assessment/interviews/useCreateInterviewRequest';
import {InterviewRequestInput} from '@/api/types/__generated__/graphql';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import {useGoToInterviewScoreCard} from '@/pages/Organization/pages/Assessment/hooks/useGoToInterviewScoreCard';
import {logger} from '@/services/logrocket';

export function useRateCandidate(
    jobApplicationId: number | null,
    interviewIds: number[],
    employmentId?: number
) {
    const {modalIsOpen, openModal, closeModal} = useSimpleModalLogic();
    const [createInterviewRequest, {error: mutationError, loading}] =
        useCreateInterviewRequest();
    const [error, setError] = useState<string | null>(null);
    const location = useLocation();
    const redirectTo = useMemo(() => location.pathname, [location.pathname]);
    const goToInterviewScoreCard = useGoToInterviewScoreCard(redirectTo);

    const rateCandidate = useCallback(() => {
        if (interviewIds.length > 1) {
            openModal();
            return;
        }

        if (!jobApplicationId || !employmentId) {
            throw new Error('Missing jobApplicationId or employmentId');
        }

        const data: InterviewRequestInput = {
            jobApplicationId: jobApplicationId,
            employmentId: employmentId,
            interviewId: interviewIds[0]
        };
        createInterviewRequest(data)
            .then(response => {
                goToInterviewScoreCard(
                    response?.data?.createInterviewRequest?.interviewRequest?.id
                );
            })
            .catch(e => {
                logger.warn(e);
                setError(e);
            });
    }, [
        createInterviewRequest,
        employmentId,
        goToInterviewScoreCard,
        interviewIds,
        jobApplicationId,
        openModal
    ]);

    return {
        showRequestInterviewModal: modalIsOpen,
        openRequestInterviewModal: openModal,
        closeRequestInterviewModal: closeModal,
        rateCandidate,
        rateCandidateError: mutationError || error,
        rateCandidateLoading: loading
    };
}
