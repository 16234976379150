import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {BodyP2} from '@/componentLibrary/tokens/typography';
import {ColorBaseBlue800, ColorBaseGrey700} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const TertiaryButtonWrapper = styled(PlainButton)`
    ${BodyP2};
    color: ${ColorBaseBlue800};
    padding: 0;
`;

export const Wrapper = styled.div`
    color: ${ColorBaseGrey700};
`;
