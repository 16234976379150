import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import messages from '../../messages';
import {SetActiveJobRecommendation} from '../../types';
import {JobRecommendationItem} from '../JobRecommendationItem';
import {MAX_DISPLAYABLE_JOBS} from './constants';
import {JobRecommendationsOverviewProps} from './types';

export function useJobRecommendationsOverviewLogic(
    pendingRecommendations: JobRecommendationsOverviewProps['pendingRecommendations'],
    setActive: SetActiveJobRecommendation
) {
    const intl = useIntl();
    const hiddenJobs = pendingRecommendations.length - MAX_DISPLAYABLE_JOBS;

    const getDescription = useCallback(() => {
        if (hiddenJobs > 0) {
            return intl.formatMessage(messages.goToJobOverviewDescriptionMoreJobs, {
                moreJobs: hiddenJobs
            });
        }
        return intl.formatMessage(messages.goToJobOverviewDescriptionDefault);
    }, [hiddenJobs, intl]);

    const getRecommendationsList = useCallback(
        () =>
            pendingRecommendations
                .slice(0, 2)
                .map(item => (
                    <JobRecommendationItem
                        key={item.id}
                        jobRecommendation={item}
                        setActive={setActive}
                    />
                )),
        [pendingRecommendations, setActive]
    );

    return {getRecommendationsList, getDescription};
}
