import {ChangeEvent, useState} from 'react';

import {Tooltip} from '@/componentLibrary/components/Tooltips/Tooltip';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {personalityTestTakingMessages} from '@/pages/PersonalityTestIrt/messages';
import {
    Column,
    Wrapper
} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/components/FormFooter/styled';
import {FormFooterProps} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/components/FormFooter/types';

import {FormNavigation} from '../FormNavigation';

export const FormFooter = ({
    skipQuestion,
    loadingSkippingQuestion,
    skipButtonIsDisabled,
    currentAnswerValue
}: FormFooterProps) => {
    const phrases = useExtractPhraseConstants(personalityTestTakingMessages);
    const [reason, setReason] = useState<string>('');
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isSkipped, setIsSkipped] = useState<boolean>(false);

    const handleSkipQuestion = () => {
        setIsSkipped(true);
        skipQuestion(reason);
    };

    const handleExpandForm = () => {
        setIsExpanded(true);
    };

    const handleCancel = () => {
        setIsExpanded(false);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setReason(e.target.value);
    };

    const renderTextInput = () => (
        <Column>
            <TextField
                value={reason}
                onChange={handleChange}
                placeholder={phrases.tellUsWhy}
                fullWidth
            />
        </Column>
    );

    const renderCancel = () => (
        <Column>
            <FormNavigation onClick={handleCancel}>{phrases.cancel}</FormNavigation>
        </Column>
    );

    const getTooltipText = () => {
        if (skipButtonIsDisabled) {
            return <P2>{phrases.tooManySkipped}</P2>;
        }
        return <P2>{phrases.alreadyAnswered}</P2>;
    };

    const isSkippingDisabled = () => skipButtonIsDisabled || !!currentAnswerValue;

    return (
        <Wrapper fadeOut={isSkipped}>
            {isExpanded ? renderTextInput() : null}

            <Column>
                <Tooltip
                    hasArrow
                    content={getTooltipText()}
                    placement="top"
                    shouldTrigger={isSkippingDisabled()}
                    trigger={
                        <FormNavigation
                            onClick={isExpanded ? handleSkipQuestion : handleExpandForm}
                            isLoading={loadingSkippingQuestion}
                            isDisabled={isSkippingDisabled()}
                        >
                            {phrases.skipQuestion}
                        </FormNavigation>
                    }
                />
            </Column>

            {isExpanded ? renderCancel() : null}
        </Wrapper>
    );
};
