import {ScoreCardDisplay} from './components/ScoreCardDisplay';
import {useScoreCardsLogic} from './hooks';
import {Wrapper} from './styled';

export function ScoreCards() {
    const scores = useScoreCardsLogic();

    return (
        <Wrapper>
            {scores.map((score, index) => (
                <ScoreCardDisplay key={index} {...score} />
            ))}
        </Wrapper>
    );
}
