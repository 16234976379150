import {FormattedMessage, useIntl} from 'react-intl';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import {AboutYou} from '@/pages/User/components/RecommendationRelevanceForm/components/AboutYou';
import {Form} from '@/pages/User/components/RecommendationRelevanceForm/components/Form';
import {InformationSection} from '@/pages/User/components/RecommendationRelevanceForm/components/InformationSection';
import {PreferencesSection} from '@/pages/User/components/RecommendationRelevanceForm/components/PreferencesSection';
import {useCandidateRelevanceFormLogic} from '@/pages/User/components/RecommendationRelevanceForm/logic';

import {DiscardAlert} from '@/componentLibrary/blocks/Modals/Alert/DiscardAlert';
import messages from './messages';
import {RecommendationRelevanceFormProps} from './types';

export function RecommendationRelevanceForm({
    close,
    userId,
    relevanceParametersOptions,
    userRelevanceInformation,
    onSubmit
}: RecommendationRelevanceFormProps) {
    const {
        closeInitialProfileSubmitSucessModal,
        workLocations,
        selectedWorkLocations,
        handleWorkLocationSelection,
        showCitiesSelection,
        interestedInLeadershipPositions,
        setInterestedInLeadershipPositions,
        jobFamily,
        jobFamilySpecialties,
        handleJobFamilySelection,
        handleJobFamilySpecialitySelection,
        handleSubmit,
        jobFamilies,
        selectableJobFamilySpecialities,
        languages,
        selectedLanguages,
        cities,
        selectedCities,
        handleLanguagesSelection,
        handleCitiesSelection,
        loading,
        formValidationErrors,
        getErrorText,
        isFormSubmittedSuccessfully,
        showConfirmLeaveRelevanceModal,
        setShowConfirmLeaveRelevanceModal,
        handleCandidateRelevanceModalLeave,
        citiesQueryResults,
        citiesQueryLoading,
        setCitiesSearchKey,
        linkedinProfileUrl,
        handleLinkedinProfileUrlChange
    } = useCandidateRelevanceFormLogic({
        close,
        userId,
        relevanceParametersOptions,
        userRelevanceInformation,
        onSubmit
    });
    const intl = useIntl();
    const title = intl.formatMessage(messages.heroTitle);
    const description = intl.formatMessage(messages.heroDescription);
    const submitTest = intl.formatMessage(messages.submit);

    if (isFormSubmittedSuccessfully) {
        return (
            <Modal
                headerTitle={<FormattedMessage {...messages.successModalTitle} />}
                footer={{
                    primaryAction: (
                        <PrimaryButton onClick={closeInitialProfileSubmitSucessModal}>
                            <FormattedMessage {...messages.successModalButton} />
                        </PrimaryButton>
                    )
                }}
                close={closeInitialProfileSubmitSucessModal}
            >
                <P1>
                    <FormattedMessage
                        values={{linebreak: <br />}}
                        {...messages.successModalBody}
                    />
                </P1>
            </Modal>
        );
    }

    return (
        <Form
            title={title}
            description={description}
            loading={loading}
            close={handleCandidateRelevanceModalLeave.bind(null, close)}
            submit={handleSubmit}
            submitText={submitTest}
        >
            {showConfirmLeaveRelevanceModal && (
                <DiscardAlert
                    onRightAction={close}
                    onLeftAction={setShowConfirmLeaveRelevanceModal.bind(null, false)}
                />
            )}
            <AboutYou
                linkedinProfileUrl={linkedinProfileUrl}
                handleLinkedinProfileUrlChange={handleLinkedinProfileUrlChange}
                getErrorText={getErrorText}
                formValidationErrors={formValidationErrors}
            />

            <PreferencesSection
                interestedInLeadershipPositions={interestedInLeadershipPositions}
                setInterestedInLeadershipPositions={setInterestedInLeadershipPositions}
                jobFamily={jobFamily}
                jobFamilies={jobFamilies}
                jobFamilySpecialties={jobFamilySpecialties}
                handleJobFamilySelection={handleJobFamilySelection}
                handleJobFamilySpecialitySelection={handleJobFamilySpecialitySelection}
                selectableJobFamilySpecialities={selectableJobFamilySpecialities}
                formValidationErrors={formValidationErrors}
                getErrorText={getErrorText}
            />
            <InformationSection
                workLocations={workLocations}
                selectedWorkLocations={selectedWorkLocations}
                handleWorkLocationSelection={handleWorkLocationSelection}
                showCitiesSelection={showCitiesSelection}
                languages={languages}
                selectedLanguages={selectedLanguages}
                handleLanguagesSelection={handleLanguagesSelection}
                cities={cities}
                selectedCities={selectedCities}
                handleCitiesSelection={handleCitiesSelection}
                formValidationErrors={formValidationErrors}
                getErrorText={getErrorText}
                citiesQueryResults={citiesQueryResults}
                citiesQueryLoading={citiesQueryLoading}
                setCitiesSearchKey={setCitiesSearchKey}
            />
        </Form>
    );
}
