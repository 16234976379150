import {P2} from '@/componentLibrary/components/typography';
import {SpacingXsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

export const Description = styled(P2)`
  padding: 0 ${SpacingXsmall};
  text-align: center;
`;
