import {defineMessages} from 'react-intl';

export default defineMessages({
    newInvitation: {
        id: 'app.pages.user.chooseOrganization.pendingOrganization.newInvitation'
    },
    assessmentRequestedDate: {
        id: 'app.pages.user.chooseOrganization.pendingOrganization.assessmentRequestedDate'
    }
});
