import {Layout, PieData} from 'plotly.js';
import {useMemo} from 'react';

import {
    ColorBgDefault,
    ColorBorderDefault,
    ColorFgDefault
} from '@/componentLibrary/tokens/variables';

import {PieHoleRelativeSize, PieSize, donutChartPaletteColors} from './constants';
import {ChartData} from './types';

export const useDonutChartLogic = (
    data: ChartData[],
    layoutOptions?: Partial<Layout>,
    tracesOptions?: Partial<PieData>
) => {
    const traces = useMemo((): Partial<PieData>[] => {
        const values: number[] = data.map(item => item.value);
        const labels: string[] = data.map(item => item.label);

        return [
            {
                values: values,
                labels: labels,
                marker: {
                    colors: donutChartPaletteColors
                },
                hole: PieHoleRelativeSize,
                sort: false,
                type: 'pie',
                hoverlabel: {
                    bgcolor: ColorBgDefault,
                    bordercolor: ColorBorderDefault,
                    font: {
                        family: 'Euclid Circular A',
                        size: 14,
                        color: ColorFgDefault
                    }
                },
                direction: 'clockwise',
                hovertext: labels,
                textposition: 'none',
                hoverinfo: 'text+value',
                ...tracesOptions
            }
        ];
    }, [data, tracesOptions]);

    const layout = useMemo((): Partial<Layout> => {
        return {
            margin: {
                l: 0,
                r: 0,
                t: 0,
                b: 0
            },
            height: PieSize,
            width: PieSize,
            paper_bgcolor: 'transparent',
            plot_bgcolor: 'transparent',
            hidesources: true,
            showlegend: false,
            hovermode: 'closest',
            ...layoutOptions
        };
    }, [layoutOptions]);

    return {traces, layout};
};
