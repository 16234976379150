export const SubmitChallengeAssignmentError = {
    ASSIGNMENT_SUBMISSION_MISSING_PULL_REQUEST: 'ASSIGNMENT_SUBMISSION_MISSING_PULL_REQUEST',
    ASSIGNMENT_SUBMISSION_FAILED: 'ASSIGNMENT_SUBMISSION_FAILED',
    PULL_REQUEST_HAS_TOO_MANY_FILES_ERROR: 'PULL_REQUEST_HAS_TOO_MANY_FILES_ERROR'
};

export const BoilerplateImportError = {
    BOILERPLATE_IMPORT_NOT_SUPPORTED: 'BOILERPLATE_IMPORT_NOT_SUPPORTED'
};

export const IMPORT_BOILERPLATE_STATUS = {
    IN_PROGRESS: 'in_progress',
    SUCCESS: 'success',
    FAILURE: 'failure'
};

export const MANUAL_TECH_STACK_OPTION = {
    name: 'Setup later / Manual',
    id: 'MANUAL'
};
