import {defineMessages} from 'react-intl';

export default defineMessages({
    preview: {
        id: 'app.assessment.jobPosition.settings.assignInterview.preview'
    },
    descriptionInterview: {
        id: 'app.assessment.jobPosition.settings.assignInterview.descriptionInterview'
    },
    startInterview: {
        id: 'app.assessment.jobPosition.settings.assignInterview.startInterview'
    }
});
