import {HTMLAttributes, forwardRef} from 'react';

import {JobPositionStatus} from '@/api/types/__generated__/graphql';
import {Tag} from '@/componentLibrary/components/Tag';

import {getJobPositionStatusVariant} from '../../../../pages/JobPositions/constants';
import {getTextFromStatus} from './util';

type JobStatusBadgeProps = {
    status: JobPositionStatus | string;
    onClick?: () => void;
} & HTMLAttributes<HTMLSpanElement>;

export const JobStatusBadge = forwardRef<HTMLSpanElement, JobStatusBadgeProps>(
    ({status, onClick, ...rest}, ref) => (
        <Tag
            $variant={getJobPositionStatusVariant(status)}
            onClick={onClick}
            $selectable={!!onClick}
            {...rest}
            ref={ref}
        >
            {getTextFromStatus(status)}
        </Tag>
    )
);
