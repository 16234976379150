import {FormattedMessage} from 'react-intl';

import {TITLE_SIZE} from '@/componentLibrary/components/InPageHeader/types';
import {PersonAvatarGroup} from '@/componentLibrary/components/avatars/PersonAvatarGroup';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {RequestInterviewModal} from '@/pages/Organization/pages/Assessment/components/RequestInterviewModal';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {SharedResultsContext} from '@/pages/SharedResults/components/UserResults/hooks/SharedResultsContext';
import {useContext} from 'react';
import {InterviewScore} from './components/InterviewScore';
import {useInterviewDetailsLogic} from './logic';
import messages from './messages';
import {Header, Wrapper} from './styled';
import {InterviewDetailsProps} from './types';

export function InterviewDetails({
    jobPositionId,
    jobApplication,
    interviewRequests,
    interview,
    onChange
}: InterviewDetailsProps) {
    const isSharedResultsView = useContext(SharedResultsContext);
    const {
        modalIsOpen,
        openModal,
        closeModal,
        onInterviewRequestsCreated,
        getInterviewers,
        getSelectedInterviewers
    } = useInterviewDetailsLogic(interviewRequests, onChange);

    return (
        <Wrapper>
            <Header
                title={{content: interview.title, size: TITLE_SIZE.LARGE}}
                primaryAction={
                    !isSharedResultsView && (
                        <PrimaryButton onClick={openModal}>
                            <FormattedMessage {...messages.interviewers} />
                        </PrimaryButton>
                    )
                }
                secondaryAction={<PersonAvatarGroup maxUsers={3} users={getInterviewers()} />}
            />
            <InterviewScore
                interview={interview}
                jobApplication={jobApplication}
                jobPositionId={jobPositionId}
            />

            {modalIsOpen && (
                <RequestInterviewModal
                    jobApplicationIds={isDefined(jobApplication.id) ? [jobApplication.id] : []}
                    jobPositionId={jobPositionId}
                    onComplete={onInterviewRequestsCreated}
                    closeModal={closeModal}
                    preselectedInterviewIds={[interview.id]}
                    existingInterviewers={getSelectedInterviewers()}
                    skipSelectInterviews
                />
            )}
        </Wrapper>
    );
}
