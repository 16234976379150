import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {DisplaySmall} from '@/componentLibrary/components/typography/Display';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {personalityTestTakingMessages} from '@/pages/PersonalityTestIrt/messages';

export function TestNotFound() {
    return (
        <Wrapper>
            <DisplaySmall>
                <FormattedMessage {...personalityTestTakingMessages.testNotFoundTitle} />
            </DisplaySmall>

            <P2>
                <FormattedMessage {...personalityTestTakingMessages.testNotFoundDescription} />
            </P2>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding: 24px;
`;
