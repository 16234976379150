import {isDefined} from '@/utils/typeGuards/isDefined';

import {isTriggerFunction} from '../ActionMenu/utils';
import {ActionOverlayClose} from './components/ActionOverlayClose';
import {ActionOverlayContent} from './components/ActionOverlayContent';
import {ActionOverlayTrigger} from './components/ActionOverlayTrigger';
import {ActionOverlayContext, useActionOverlay} from './hooks';
import {ActionOverlayOptions, ActionOverlayProps} from './types';
import {isActionOverlayFunction} from './utils';

export function ActionOverlay({
    content,
    trigger,
    showClose = false,
    asChild = false,
    asDiv = false,
    shouldTrigger = true,
    noPadding = false,
    noFocusTrap = false,
    returnFocus = true,
    style,
    ...restOptions
}: ActionOverlayProps) {
    // This can accept any props as options, e.g. `placement`,
    // or other positioning options.
    const defaultOptions: ActionOverlayOptions = {
        placement: 'right',
        hasArrow: false,
        shouldFocus: false
    };
    const actionOverlay = useActionOverlay({...defaultOptions, ...restOptions});
    const getContent = () => {
        if (!isDefined(content)) {
            return null;
        }
        return isActionOverlayFunction(content)
            ? content((open?: boolean) => actionOverlay.setOpen(!!open))
            : content;
    };
    const getTrigger = () => {
        if (!isDefined(trigger)) {
            return null;
        }
        return isTriggerFunction(trigger) ? trigger(actionOverlay.context.open) : trigger;
    };
    return (
        <ActionOverlayContext.Provider value={actionOverlay}>
            <ActionOverlayTrigger
                asChild={asChild}
                asDiv={asDiv}
                shouldTrigger={shouldTrigger}
            >
                {getTrigger() || trigger}
            </ActionOverlayTrigger>
            <ActionOverlayContent
                style={style}
                noPadding={noPadding}
                noFocusTrap={noFocusTrap}
                returnFocus={returnFocus}
            >
                {getContent() || content}
                {showClose && <ActionOverlayClose />}
            </ActionOverlayContent>
        </ActionOverlayContext.Provider>
    );
}
