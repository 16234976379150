import {useIntl} from 'react-intl';

import {ModalEmpty} from '@/componentLibrary/components/modals/ModalEmpty';
import {DisplayMedium} from '@/componentLibrary/components/typography/Display';
import {
    ContentWrapper,
    DescriptionWrapper,
    TextWrapper
} from '../ContinueValidationTest/styled';

import {LogicTestValidationErrorProps} from '@/pages/LogicTestValidation/pages/LogicTestValidation/components/LogicTestValidationError/types';
import {LOGIC_TEST_VALIDATION_ERRORS} from './constants';
import messages from './messages';

export const LogicTestValidationError = ({error}: LogicTestValidationErrorProps) => {
    const {formatMessage} = useIntl();

    const renderMessage = (error: string) => {
        switch (error) {
            case LOGIC_TEST_VALIDATION_ERRORS.TOKEN_EXPIRED:
                return formatMessage(messages.expiredToken);
            default:
                return formatMessage(messages.invalidToken, {
                    email: <a href={'mailto:support@alvalabs.io'}>support@alvalabs.io</a>
                });
        }
    };

    return (
        <ModalEmpty noCenterWrapper>
            <ContentWrapper>
                <TextWrapper>
                    <DisplayMedium>{formatMessage(messages.title)}</DisplayMedium>
                    <DescriptionWrapper>{renderMessage(error)}</DescriptionWrapper>
                </TextWrapper>
            </ContentWrapper>
        </ModalEmpty>
    );
};
