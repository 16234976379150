import {useCallback, useState} from 'react';

export function useWithdrawJobApplicationsFromOrganizationModalLogic() {
    const [withdrawFromOrganization, setWithdrawFromOrganization] = useState<number | null>(
        null
    );
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = useCallback(organizationId => {
        setWithdrawFromOrganization(organizationId);
        setModalIsOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setWithdrawFromOrganization(null);
        setModalIsOpen(false);
    }, []);

    return {withdrawFromOrganization, modalIsOpen, openModal, closeModal};
}
