import styled from 'styled-components';

import {SpacingSmall, SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const SectionTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: ${SpacingSmall};
    align-items: center;
    gap: ${SpacingXxsmall};
`;
