import {useMemo} from 'react';

import {useTestReportLocaleLogic} from '../hooks/useTestReportLocaleLogic';
import {LEADERSHIP_DEVELOPMENT_PLAN_URL} from './constants';
import {useLeadershipProfileAnchorsLogic} from './hooks';

const EN = 'en';
const SV = 'sv';
const EN_GB = 'en-gb';

export function useLeadershipReportLogic() {
    const {handleAnchorClick} = useLeadershipProfileAnchorsLogic();
    const {reportLocale, changeReportLocale} = useTestReportLocaleLogic();

    const shouldShowTranslationsMissingBanner = useMemo(
        () => ![EN, SV, EN_GB].includes(reportLocale),
        [reportLocale]
    );

    const leadershipDevelopmentPlanUrl = useMemo(() => {
        return reportLocale === SV
            ? LEADERSHIP_DEVELOPMENT_PLAN_URL.SV
            : LEADERSHIP_DEVELOPMENT_PLAN_URL.EN;
    }, [reportLocale]);

    return {
        handleAnchorClick,
        reportLocale,
        changeReportLocale,
        shouldShowTranslationsMissingBanner,
        leadershipDevelopmentPlanUrl
    };
}
