import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {ColorFgInfo, SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const Email = styled(P2)`
    color: ${ColorFgInfo};
`;
export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${SpacingXxsmall};
`;
