import {useQuery} from '@apollo/client';

import {CHECK_SHAREABLE_RESULTS_TOKEN} from '@/api/users/queries';
import {isNotNull} from '@/utils/misc';

export function useCheckShareableResultsToken(token: string) {
    const variables = {token};
    const {data, error, loading, refetch} = useQuery(CHECK_SHAREABLE_RESULTS_TOKEN, {
        variables,
        skip: !token
    });
    const permissions = data?.checkShareableResultsToken?.permissions?.filter(isNotNull) ?? [];
    return {error, loading, data, permissions, refetch};
}
