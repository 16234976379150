import {City} from '@/api/types/__generated__/graphql';
import {Separator} from '@/componentLibrary/components/Separator';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import messages from './messages';
import {CityAndWorkLocationWrapper, LocationWrapper, SeparatorWrapper} from './styled';
import {LocationProps} from './types';

export function Location({preferredCities, preferredWorkLocations}: LocationProps) {
    const phrases = useExtractPhraseConstants(messages);
    const getCityText = (city: City) => `${city.name}, ${city.countryName}`;
    const hasPreferredCities = preferredCities?.length > 0;

    return (
        <LocationWrapper>
            <Icon icon={Icons.LOCATION_ON} />
            <CityAndWorkLocationWrapper>
                {preferredCities &&
                    preferredCities.length > 0 &&
                    preferredCities?.map((city, index) => {
                        return city && <span key={index}>{getCityText(city)}</span>;
                    })}
                {hasPreferredCities && (
                    <SeparatorWrapper>
                        <Separator />
                    </SeparatorWrapper>
                )}
                {preferredWorkLocations?.length > 0 &&
                    preferredWorkLocations.map((workLocation, index) => {
                        const workLocationKey =
                            `workLocations_${workLocation}` as keyof typeof phrases;
                        return <span key={index}>{phrases[workLocationKey]}</span>;
                    })}
            </CityAndWorkLocationWrapper>
        </LocationWrapper>
    );
}
