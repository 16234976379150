import gaussian from 'gaussian';
import linspace from 'linspace';

export class SkewedNormal {
    private alpha: number;
    private location: number;
    private scale: number;
    private gaussianDistribution: gaussian.Gaussian;

    constructor(alpha: number, location: number, scale: number) {
        if (!scale || scale === 0) {
            throw new Error('INVALID_SCALE');
        }

        this.alpha = alpha;
        this.location = location;
        this.scale = scale;
        this.gaussianDistribution = gaussian(0, 1);
    }

    pdf = (value: number): number => {
        const coefficient = 2 / (this.scale * Math.sqrt(2 * Math.PI));
        const exponentTerm = Math.exp(
            -Math.pow(value - this.location, 2) / (2 * Math.pow(this.scale, 2))
        );
        const normalCdfEnd = this.alpha * ((value - this.location) / this.scale);
        const integral = this.gaussianDistribution.cdf(normalCdfEnd);

        return coefficient * exponentTerm * integral;
    };

    cdf = (value: number): number => {
        const transformedValue = (value - this.location) / this.scale;

        return (
            this.gaussianDistribution.cdf(transformedValue) -
            2 * this.ovens(transformedValue, this.alpha)
        );
    };

    private ovens = (h: number, a: number): number => {
        const innerFunction = (x: number) =>
            Math.exp(-0.5 * Math.pow(h, 2) * (1 + Math.pow(x, 2))) / (1 + Math.pow(x, 2));

        const steps = Math.pow(10, 4);
        const deltaX = (a - 0) / steps;
        const xValues = linspace(0, a, steps);

        return (
            (1 / (2 * Math.PI)) *
            xValues.reduce(
                (acc: number, value: number) => acc + innerFunction(value) * deltaX,
                0
            )
        );
    };

    invCdf = (rank: number): number => {
        if (rank < 0 || rank > 1) {
            throw new Error('Invalid rank value');
        }

        const xStart = 0.5;
        const func = (x: number) => this.cdf(x) - rank;
        const funcPrim = (x: number) => this.pdf(x);

        return this.newtonRhapson(xStart, func, funcPrim);
    };

    private newtonRhapson = (
        xStart: number,
        func: (x: number) => number,
        funcPrim: (x: number) => number,
        maxIterations = 100
    ): number => {
        const nextValue = (x: number) => x - func(x) / funcPrim(x);

        let prevX = xStart;
        let currentX = nextValue(prevX);

        let iterations = 0;
        while (Math.abs(currentX - prevX) > 0.00001) {
            prevX = currentX;
            currentX = nextValue(prevX);
            iterations++;

            if (iterations > maxIterations) {
                console.warn('Maximum iterations reached!');
            }
        }

        return currentX;
    };
}
