import {useCallback, useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {LogicTestIrtQuestion} from '@/api/types/__generated__/graphql';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {Header} from '@/componentLibrary/components/layout/Header';
import {CenteredSpinner} from '@/componentLibrary/components/spinners';
import {DisplayMedium} from '@/componentLibrary/components/typography/Display';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {FormHeader} from '@/pages/LogicTestIrt/components/FormHeader';
import {Options} from '@/pages/LogicTestIrt/components/Options';
import {Question} from '@/pages/LogicTestIrt/components/Question';
import {SelectAnswer} from '@/pages/LogicTestIrt/components/SelectAnswer';
import {Timer} from '@/pages/LogicTestIrt/components/Timer';
import {
    BlockedWrapper,
    BodyWrapper,
    ButtonsWrapper,
    EmptyWrapper,
    HeaderWrapper,
    OptionsWrapper,
    QuestionWrapper,
    TimerWrapper,
    Wrapper
} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTesting/styled';
import {LogicTestingProps} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTesting/types';

import {LeaveTheTestModal} from './LeaveTheTestModal';
import {NextQuestionModal} from './NextQuestionModal';
import warningSvg from './assets/warning.svg';
import messages from './messages';

export function LogicTesting({
    loading,
    logicTestStatus,
    currentQuestion,
    saveAnswer,
    savingAnswer,
    fetchNewQuestion,
    goBack
}: LogicTestingProps) {
    const countdownLimit = 10;
    const phrases = useExtractPhraseConstants(messages);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [timeIsUp, setTimeIsUp] = useState<boolean>(false);
    const [extratimeIsUp, setExtratimeIsUp] = useState<boolean>(false);
    const [displayNextQuestionModal, setDisplayNextQuestionModal] = useState<boolean>(false);
    const [displayLeaveTheTestModal, setDisplayLeaveTheTestModal] = useState<boolean>(false);
    const [warningDisplayed, setWarningDisplayed] = useState<boolean>(false);
    const previousQuestionId = useRef<string | null>(null);

    const timeIsUpRef = useRef(timeIsUp);
    const extratimeIsUpRef = useRef(extratimeIsUp);
    const currentQuestionIdRef = useRef(currentQuestion?.id);
    const selectedOptionRef = useRef(selectedOption);
    useEffect(() => {
        timeIsUpRef.current = timeIsUp;
        extratimeIsUpRef.current = extratimeIsUp;
        currentQuestionIdRef.current = currentQuestion?.id;
        selectedOptionRef.current = selectedOption;
    }, [currentQuestion, extratimeIsUp, selectedOption, timeIsUp]);

    const handleOnUnload = useCallback(e => {
        const confirmationMessage = 'If you leave the logic test your progress will be lost.';
        (e || window.event).returnValue = confirmationMessage; // Gecko + IE
        return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
    }, []);

    const displaySkippedQuestionWarningOnlyOnce = useCallback(() => {
        if (loading || !logicTestStatus) {
            return;
        }

        const {skippedQuestions, maxSkippedQuestions} = logicTestStatus;

        if (
            !warningDisplayed &&
            skippedQuestions >= maxSkippedQuestions - 2 &&
            skippedQuestions < maxSkippedQuestions
        ) {
            setWarningDisplayed(true);
            toast({
                type: 'warning',
                message: (
                    <FormattedMessage
                        {...messages.skipWarning}
                        values={{
                            skippedQuestions
                        }}
                    />
                )
            });
        }
    }, [loading, logicTestStatus, warningDisplayed]);

    useEffect(() => {
        window.addEventListener('beforeunload', handleOnUnload, false);
        displaySkippedQuestionWarningOnlyOnce();

        return () => {
            window.removeEventListener('beforeunload', handleOnUnload, false);
        };
    }, [displaySkippedQuestionWarningOnlyOnce, handleOnUnload]);

    const onNewQuestionLoaded = useCallback(() => {
        setSelectedOption(null);
        setTimeIsUp(false);
        setExtratimeIsUp(false);
        setDisplayNextQuestionModal(false);
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (currentQuestion && currentQuestion.id !== previousQuestionId.current) {
            previousQuestionId.current = currentQuestion.id;
            onNewQuestionLoaded();
        }
    }, [currentQuestion, onNewQuestionLoaded]);

    useEffect(() => {
        if (extratimeIsUp === true && !displayNextQuestionModal) {
            setDisplayNextQuestionModal(true);
        }
    }, [extratimeIsUp, displayNextQuestionModal]);

    const onTimeIsUp = useCallback(() => {
        if (timeIsUpRef.current) {
            return null;
        }
        setTimeIsUp(true);
    }, []);

    const selectOption = (optionValue: string) => {
        setSelectedOption(optionValue);
    };

    const openLeaveTheTestModal = () => {
        setDisplayLeaveTheTestModal(true);
    };

    const closeLeaveTheTestModal = () => {
        setDisplayLeaveTheTestModal(false);
    };

    const saveAnswerWrapper = useCallback(
        (requestNewQuestion = true) => {
            const answer = selectedOptionRef.current;
            const questionId = currentQuestionIdRef.current;

            if ((!answer && !timeIsUpRef.current) || !questionId) {
                return;
            }

            saveAnswer(questionId, answer, requestNewQuestion);
        },
        [saveAnswer]
    );

    const onExtratimeIsUp = useCallback(() => {
        if (extratimeIsUpRef.current) {
            return null;
        }
        setExtratimeIsUp(true);
        saveAnswerWrapper(false);
    }, [saveAnswerWrapper]);

    const renderQuestion = (question?: LogicTestIrtQuestion | null) => (
        <>
            <QuestionWrapper>
                {timeIsUp ? (
                    <SelectAnswer isOptionSelected={!!selectedOption} />
                ) : (
                    question && <Question question={question} />
                )}
            </QuestionWrapper>

            <OptionsWrapper>
                <TimerWrapper>
                    {question && (
                        <Timer
                            key={question.id}
                            limit={currentQuestion?.timeLimit ?? 'PT2M'}
                            countdownLimit={countdownLimit}
                            onTimeIsUp={onTimeIsUp}
                            onExtraTimeIsUp={onExtratimeIsUp}
                        />
                    )}
                </TimerWrapper>

                {question && (
                    <Options
                        key={`options-${question.id}`}
                        question={question}
                        selectedOption={selectedOption}
                        selectOption={selectOption}
                    />
                )}

                <ButtonsWrapper>
                    <PrimaryButton
                        onClick={() => saveAnswerWrapper()}
                        disabled={!selectedOption}
                        isLoading={savingAnswer}
                        isFullWidth
                    >
                        {phrases.submit}
                    </PrimaryButton>

                    {timeIsUp && !selectedOption ? (
                        <SecondaryButton onClick={() => saveAnswerWrapper()} isFullWidth>
                            {phrases.nextQuestion}
                        </SecondaryButton>
                    ) : (
                        <EmptyWrapper />
                    )}
                </ButtonsWrapper>
            </OptionsWrapper>
        </>
    );

    const renderBody = () => {
        if (loading || !logicTestStatus) {
            return <CenteredSpinner />;
        }

        if (logicTestStatus.skippedQuestions >= logicTestStatus.maxSkippedQuestions) {
            return (
                <BlockedWrapper>
                    <Header>
                        <img src={warningSvg} alt="warning" />
                    </Header>
                    <Header>
                        <DisplayMedium>{phrases.blocked}</DisplayMedium>
                    </Header>
                    <P2>
                        <FormattedMessage
                            {...messages.blockedDescription}
                            values={{
                                emailLink: <a href="mailto:support@alvalabs.io">Alva Labs</a>
                            }}
                        />
                    </P2>
                </BlockedWrapper>
            );
        }
        return renderQuestion(currentQuestion);
    };

    const blurBackground = displayNextQuestionModal || displayLeaveTheTestModal;

    return (
        <Wrapper>
            <HeaderWrapper blurBackground={blurBackground}>
                <FormHeader
                    logicTestStatus={logicTestStatus}
                    goBack={goBack ? openLeaveTheTestModal : null}
                />
            </HeaderWrapper>
            <BodyWrapper blurBackground={blurBackground}>
                {renderBody()}
                {displayNextQuestionModal && !displayLeaveTheTestModal ? (
                    <NextQuestionModal
                        leaveTheTest={goBack}
                        onNextQuestion={fetchNewQuestion}
                    />
                ) : null}

                {displayLeaveTheTestModal && goBack ? (
                    <LeaveTheTestModal leaveTheTest={goBack} close={closeLeaveTheTestModal} />
                ) : null}
            </BodyWrapper>
        </Wrapper>
    );
}
