import {defineMessages} from 'react-intl';

export default defineMessages({
    candidateProgressHeader: {
        id: 'app.assessment.codingTest.candidateCodingTest.candidateProgress.header'
    },
    candidateProgressStatus: {
        id: 'app.assessment.codingTest.candidateCodingTest.candidateProgress.status'
    },
    candidateProgressRepository: {
        id: 'app.assessment.codingTest.candidateCodingTest.candidateProgress.repository'
    },
    candidateProgressCodingTest: {
        id: 'app.assessment.codingTest.candidateCodingTest.candidateProgress.codingTest'
    },
    manualReviewText: {
        id: 'app.assessment.codingTest.candidateCodingTest.candidateProgress.repository.manualReviewText'
    },
    manualReviewTextNoScorecards: {
        id: 'app.assessment.codingTest.candidateCodingTest.candidateProgress.repository.manualReviewText.noScorecards'
    },
    codingTestLink: {
        id: 'app.assessment.codingTest.candidateCodingTest.candidateProgress.codingTestLink'
    }
});
