import {useState} from 'react';

import {useLocale} from '@/containers/LanguageProvider/useLocale';

export function useTestReportLocaleLogic() {
    const userLocale = useLocale();
    const [reportLocale, setReportLocale] = useState(userLocale);

    return {reportLocale, changeReportLocale: setReportLocale};
}
