import {InterviewContentItem} from '@/api/assessment/jobPositions/useJobPosition';
import {P2} from '@/componentLibrary/components/typography';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {useScorecardCriterionInfoLogic} from './logic';
import {Name, Wrapper} from './styled';

type Props = {
    interviewTitle?: string;
    contentItem: InterviewContentItem;
};

export function ScorecardCriterionInfo({interviewTitle, contentItem}: Props) {
    const {translatedContentItem} = useScorecardCriterionInfoLogic(contentItem);
    const displayedName = isDefined(interviewTitle)
        ? `${interviewTitle}: ${translatedContentItem.name}`
        : translatedContentItem.name;

    return (
        <Wrapper>
            <Name>{displayedName}</Name>
            <P2>{translatedContentItem.description}</P2>
        </Wrapper>
    );
}
