import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {ImportChallengeAssignmentBoilerplateMutation} from '../../types/__generated__/graphql';
import {MutationHookReturnType} from '../../types/genericApi/types';
import {IMPORT_CHALLENGE_ASSIGNMENT_BOILERPLATE} from './mutations';

export function useImportChallengeAssignmentBoilerplate({
    challengeAssignmentId
}: {challengeAssignmentId: string}): MutationHookReturnType<
    ImportChallengeAssignmentBoilerplateMutation,
    string
> {
    const [mutate, {error, loading}] = useMutation(IMPORT_CHALLENGE_ASSIGNMENT_BOILERPLATE);

    const doImportBoilerplate = useCallback(
        (framework: string) => {
            const variables = {input: {framework, challengeAssignmentId}};
            return mutate({variables});
        },
        [challengeAssignmentId, mutate]
    );

    return [doImportBoilerplate, {error, loading}];
}
