import {useIntl} from 'react-intl';

import {messages} from './messages';
import {getLowerPercentile, getUpperPercentile} from './percentileCalculations';

export function usePercentileText(standardScore: number): string {
    const intl = useIntl();
    const lowerPercentile = getLowerPercentile(standardScore);
    const upperPercentile = getUpperPercentile(standardScore);
    if (lowerPercentile === null) {
        return intl.formatMessage(messages.percentileAndBelow, {upper: upperPercentile});
    }
    if (upperPercentile === null) {
        return intl.formatMessage(messages.percentileAndAbove, {lower: lowerPercentile});
    }
    return intl.formatMessage(messages.percentile, {
        lower: lowerPercentile,
        upper: upperPercentile
    });
}
