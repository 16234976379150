import styled from 'styled-components';

import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {
    ColorBgActionSecondaryActive,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

export const LinkWrapper = styled(P2)`
    margin-top: ${SpacingXsmall};

    @media print {
        display: none;
    }
`;

export const TriggerButton = styled(SecondaryButton)`
    &[data-state='open'] {
        background-color: ${ColorBgActionSecondaryActive};
    }
`;
