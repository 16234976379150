import {
    BorderRadiusBaseRounded,
    ColorBgInset,
    SpacingLarge,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import styled, {keyframes} from 'styled-components';

export const Wrapper = styled.div`
    width: ${SpacingLarge};
    height: ${SpacingLarge};
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
`;
const bouncedelay = () => keyframes`
  0%, 80%, 100% { transform: scale(0) }
  40% { transform: scale(1.0) }
`;
export const LoadingDot = styled.div`
    width: ${SpacingXxxsmall};
    height: ${SpacingXxxsmall};
    background-color: ${ColorBgInset};
    border-radius: ${BorderRadiusBaseRounded};
    display: inline-block;
    animation: ${bouncedelay()} 1.4s infinite ease-in-out both;
`;
export const FirstLoadingDot = styled(LoadingDot)`
    animation-delay: -0.32s;
`;
export const SecondLoadingDot = styled(LoadingDot)`
    animation-delay: -0.16s;
`;
