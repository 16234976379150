import {isValidElement} from 'react';

import {DestructiveButton} from '@/componentLibrary/components/buttons/DestructiveButton';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';

import {FocusLock} from '@/componentLibrary/misc/FocusLock';
import {MODAL_SIZE} from '../Modal/constants';
import {ButtonWrapper, MediumModal, ModalBase, Overlay, P2Wrapper, S2Wrapper} from './styled';
import {AlertProps} from './types';

export const Alert = ({
    onLeftAction,
    onRightAction,
    title,
    subtitle,
    asChild,
    children,
    size,
    leftButton,
    rightButton,
    isLoading = false,
    confirm = false,
    textAlignment = 'center'
}: AlertProps) => {
    const Modal = size === MODAL_SIZE.MEDIUM ? MediumModal : ModalBase;
    return (
        <FocusLock>
            <Overlay onClick={e => e.stopPropagation()}>
                <Modal textAlignment={textAlignment}>
                    <S2Wrapper>{title}</S2Wrapper>
                    {asChild && isValidElement(children)
                        ? children
                        : !!subtitle && <P2Wrapper>{subtitle}</P2Wrapper>}
                    <ButtonWrapper>
                        {onLeftAction && leftButton && (
                            <SecondaryButton
                                isFullWidth
                                onClick={e => {
                                    e.stopPropagation();
                                    onLeftAction();
                                }}
                            >
                                {leftButton}
                            </SecondaryButton>
                        )}
                        {confirm ? (
                            <PrimaryButton
                                isFullWidth
                                onClick={e => {
                                    e.stopPropagation();
                                    onRightAction();
                                }}
                                isLoading={isLoading}
                            >
                                {rightButton}
                            </PrimaryButton>
                        ) : (
                            <DestructiveButton
                                isFullWidth
                                onClick={e => {
                                    e.stopPropagation();
                                    onRightAction();
                                }}
                                isLoading={isLoading}
                            >
                                {rightButton}
                            </DestructiveButton>
                        )}
                    </ButtonWrapper>
                </Modal>
            </Overlay>
        </FocusLock>
    );
};
