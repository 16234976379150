import {defineMessages} from 'react-intl';

export default defineMessages({
    revokedAccess: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.revokeAccessFromOrganizationModal.revokedAccess'
    },
    modalTitle: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.revokeAccessFromOrganizationModal.modalTitle'
    },
    description: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.revokeAccessFromOrganizationModal.description'
    },
    cancel: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.revokeAccessFromOrganizationModal.cancel'
    },
    revokeAccess: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.revokeAccessFromOrganizationModal.revokeAccess'
    },
    confirmationInputLabel: {
        id: 'app.user.candidateSettings.userOrganizationSettings.withdrawAndRevokeDataFromOrganizationModal.confirmationInputLabel'
    }
});
