import {ReactNode} from 'react-markdown';

import {ActionOverlayProps} from '../ActionOverlay/types';

type TriggerFunction = {
    (arg1: boolean): ReactNode;
};

// Type guard to check if a value conforms to the TriggerFunction interface
export function isTriggerFunction(
    value: ActionOverlayProps['trigger']
): value is TriggerFunction {
    return typeof value === 'function';
}
