import {defineMessages} from 'react-intl';

export default defineMessages({
    testAdministration: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.testAdministration'
    },
    logicTestNotCompleted: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.logicTestNotCompleted'
    },
    waitingForResults: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.waitingForResults'
    },
    validationTestDescription: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.validationTestDescription'
    },
    readMore: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.readMore'
    },
    sendInvitation: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.sendInvitation'
    },
    disabledMessage: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.disabledMessage'
    },
    cancel: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.cancel'
    },
    confirmationModalHeader: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.confirmationModalHeader'
    },
    confirmationModalDescription: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.confirmationModalDescription'
    },
    confirmationModalWarning: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.confirmationModalWarning'
    },
    validationRequested: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.validationRequested'
    },
    failedToRequestValidation: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.failedToRequestValidation'
    },
    validationTestCompleted: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.validationTestCompleted'
    },
    sendReminder: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.sendReminder'
    },
    reminderSent: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.reminderSent'
    },
    failedToSendReminder: {
        id: 'app.assessment.candidatePage.candidateSettings.testAdministration.failedToSendReminder'
    }
});
