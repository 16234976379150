import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.jobPositions.title'
    },
    applied_via_marketplace: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.jobPositions.applied_via_marketplace'
    },
    applied_via_invitation: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.jobPositions.applied_via_invitation'
    },
    non_marketplace_job_description: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.jobPositions.non_marketplace_job_description'
    },
    action: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.jobPositions.action'
    },
    logic_test: {
        id: 'app.general.logicTest'
    },
    personality_test: {
        id: 'app.general.personalityTest'
    },
    coding_test: {
        id: 'app.general.codingTest'
    },
    assessments: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.title'
    }
});
