import {ColorFgMuted} from '@/componentLibrary/tokens/variables';

import {Title, Value} from './styled';
import {ChartHeaderProps} from './types';

export function ChartHeader({title, valueText}: ChartHeaderProps) {
    return (
        <>
            <Title>{title}</Title>
            <Value color={ColorFgMuted}>{valueText}</Value>
        </>
    );
}
