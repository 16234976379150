import {useIntl} from 'react-intl';
import ReactMarkdown from 'react-markdown';

import {PlainLinkButton} from '@/componentLibrary/components/buttons/PlainButton';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {Section} from '@/componentLibrary/components/layout/Section';
import messages from '@/pages/User/components/JobRecommendations/messages';
import {userLinks} from '@/pages/User/links';

import {useJobRecommendationsOverviewLogic} from './hooks';
import {DescriptionText, RecommendationListWrapper, SectionWrapper} from './styled';
import {JobRecommendationsOverviewProps} from './types';

export function JobRecommendationsOverview({
    loading,
    error,
    pendingRecommendations,
    setActive
}: JobRecommendationsOverviewProps) {
    const intl = useIntl();
    const {getDescription, getRecommendationsList} = useJobRecommendationsOverviewLogic(
        pendingRecommendations,
        setActive
    );

    if (error) {
        return null;
    }

    if (loading) {
        <Section loading />;
    }

    return (
        <RecommendationListWrapper>
            {!loading && !error && (
                <>
                    {getRecommendationsList()}
                    <SectionWrapper>
                        <DescriptionText>
                            <ReactMarkdown>{getDescription()}</ReactMarkdown>
                        </DescriptionText>
                        <PlainLinkButton
                            to={userLinks.myJobs()}
                            icon={Icons.ARROW_FORWARD}
                            iconSize={14}
                        >
                            {intl.formatMessage(messages.goToJobOverview)}
                        </PlainLinkButton>
                    </SectionWrapper>
                </>
            )}
        </RecommendationListWrapper>
    );
}
