import {defineMessages} from 'react-intl';

export default defineMessages({
    fiveFactorModelTitle: {
        id: 'personalityTestReport.fiveFactorModel'
    },
    technicalManualTitle: {
        id: 'personalityTestReport.technicalManual'
    },
    validityTitle: {
        id: 'personalityTestReport.validity'
    },
    stabilityOfTraitsTitle: {
        id: 'personalityTestReport.stabilityOfTraits'
    }
});
