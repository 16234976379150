import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {gql} from '@/api/types/__generated__';
import {GET_LOGGED_IN_USER} from '@/api/users/queries';

import {
    CreateUserDemographicsInput,
    CreateUserDemographicsMutation
} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';

export const CREATE_USER_DEMOGRAPHICS = gql(`
    mutation createUserDemographics($data: CreateUserDemographicsInput!) {
        createUserDemographics(data: $data) {
            ok
            errorMessage
            user {
                id
                hasSubmittedDemographicsForm
            }
        }
    }
`);

export function useCreateUserDemographics(
    data: CreateUserDemographicsInput
): MutationHookReturnType<CreateUserDemographicsMutation> {
    const options = {refetchQueries: [{query: GET_LOGGED_IN_USER}]};
    const [mutate, {loading}] = useMutation(CREATE_USER_DEMOGRAPHICS, options);
    const createUserDemographics = useCallback(
        () => mutate({variables: {data}}),
        [mutate, data]
    );
    return [createUserDemographics, {loading}];
}
