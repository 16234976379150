import {defineMessages} from 'react-intl';

export default defineMessages({
    validationTestRequestedTitle: {
        id: 'app.logicTestReport.logicTestValidation.validationTestRequestedTitle'
    },
    validationTestRequestedDescription: {
        id: 'app.logicTestReport.logicTestValidation.validationTestRequestedDescription'
    },
    testIsValidatedTitle: {
        id: 'app.logicTestReport.logicTestValidation.testIsValidatedTitle'
    },
    testIsValidatedDescription: {
        id: 'app.logicTestReport.logicTestValidation.testIsValidatedDescription'
    },
    testIsInvalidTitle: {
        id: 'app.logicTestReport.logicTestValidation.testIsInvalidTitle'
    },
    testIsInvalidDescription: {
        id: 'app.logicTestReport.logicTestValidation.testIsInvalidDescription'
    },
    testIsInvalidReasons: {
        id: 'app.logicTestReport.logicTestValidation.testIsInvalidReasons'
    }
});

export const validationResultTypeMessages = defineMessages({
    LOWER: {
        id: 'app.logicTestReport.validationResultType.LOWER'
    },
    HIGHER: {
        id: 'app.logicTestReport.validationResultType.HIGHER'
    },
    VALIDATED: {
        id: 'app.logicTestReport.validationResultType.VALIDATED'
    }
});
