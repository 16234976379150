import {gql} from '@/api/types/__generated__';

export const GET_CANDIDATE_ACTIVITY_EVENTS = gql(`
    query getCandidateActivityEvents($jobPositionId: Int!, $userId: Int!) {
        jobApplication(jobPositionId: $jobPositionId, userId: $userId) {
            id
            candidateActivityEvents {
                eventDate
                eventType
                jobPositionId
                jobPositionName
                byUserId
                byUserFirstName
                byUserLastName
                byUserEmail
                user {
                    id
                    firstName
                    lastName
                    email
                }
            }
        }
    }
`);

export const GET_ASSESSMENT_ACTIVITY = gql(`
    query getAssessmentActivity($fetchOptions: AssessmentActivityFetchOptionsInput) {
        authenticatedOrganization {
            id
            assessmentActivityConnection(fetchOptions: $fetchOptions) {
                totalResults
                cursor {
                    hasMore
                    next
                }
                items {
                    id
                    eventDate
                    eventType
                    jobPositionId
                    jobPositionName
                    userId
                    userEmail
                    userFirstName
                    userLastName
                }
            }
        }
    }
`);
