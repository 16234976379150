import {useEffect, useState} from 'react';

import {ActionMenu} from '@/componentLibrary/components/ActionMenu';
import {CardTypes} from '@/componentLibrary/components/cards/AssessmentCard/constants';
import {UseAssessmentCardLogicProps} from '@/componentLibrary/components/cards/AssessmentCard/types';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {Link} from '@/componentLibrary/components/links/Link';
import {ColorFgInfo, ColorFgSuccess} from '@/componentLibrary/tokens/variables';

export const useAssessmentCardLogic = (props: UseAssessmentCardLogicProps) => {
    const {actionMenuItems, link, cardType, isSelected, customActionButton} = props;
    const [color, setColor] = useState(ColorFgInfo);
    const [icon, setIcon] = useState(Icons.DRAG_INDICATOR);
    const [hasHoverableIcon, setHasHoverableIcon] = useState(
        cardType === CardTypes.SELECTABLE
    );

    useEffect(() => {
        if (cardType === CardTypes.DRAGGABLE) {
            setColor(ColorFgInfo);
            setIcon(Icons.DRAG_INDICATOR);
            setHasHoverableIcon(false);
        } else if (cardType === CardTypes.SELECTABLE && !isSelected) {
            setColor(ColorFgInfo);
            setIcon(Icons.ADD_CIRCLE);
            setHasHoverableIcon(true);
        } else if (cardType === CardTypes.SELECTABLE && isSelected) {
            setColor(ColorFgSuccess);
            setIcon(Icons.CHECK_CIRCLE);
            setHasHoverableIcon(true);
        }
    }, [isSelected, cardType]);

    let actionArea = null;
    if (customActionButton) {
        actionArea = customActionButton;
    } else if (actionMenuItems) {
        actionArea = <ActionMenu asChild items={actionMenuItems} placement="left-start" />;
    } else if (link) {
        actionArea = link.external ? (
            <ExternalLink onClick={link.hook} noWrap>
                {link.title}
            </ExternalLink>
        ) : (
            <Link onClick={link.hook} url={link.url}>
                {link.title}
            </Link>
        );
    }

    return {
        color,
        actionArea,
        hasHoverableIcon,
        icon
    };
};
