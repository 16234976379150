import {ErrorTypes} from '@/pages/common/RelevanceForm/types';

export const JOB_FAMILIES_KEY = 'app.pages.user.candidateRelevanceModal.jobFamilies';
export const COMPANY_TYPES_KEY = 'app.pages.user.candidateRelevanceModal.companyTypes';
export const LANGUAGES_KEY = 'app.pages.user.candidateRelevanceModal.languages';
export const CANDIDATE_ERRORS_KEY = 'app.pages.user.candidateRelevanceModal.errors';
export const JOB_ERRORS_KEY = 'app.assessment.jobPosition.settings.jobRelevanceModal.errors';
export const WORK_LOCATIONS_KEY = 'app.pages.user.candidateRelevanceModal.workLocations';
export const LINKEDIN_URL_KEY =
    'app.jobRecommendations.handleResume.input.linkedIn.error.noURL';

export enum FormType {
    CANDIDATE,
    JOB
}

export const ERROR_TYPES: ErrorTypes = {
    JOB_FAMILY: 'JOB_FAMILY',
    LANGUAGES: 'LANGUAGES',
    CITIES: 'CITIES',
    EXPERIENCE: 'EXPERIENCE',
    YEARS_OF_EXPERIENCE: 'YEARS_OF_EXPERIENCE',
    WORK_LOCATIONS: 'WORK_LOCATIONS',
    ROLE_DESCRIPTION: 'ROLE_DESCRIPTION',
    FAMILIARITY_DESCRIPTION: 'FAMILIARITY_DESCRIPTION',
    LINKEDIN_URL: 'LINKEDIN_URL'
};
