import {useMemo, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {ConfirmAlert} from '@/componentLibrary/blocks/Modals/Alert/ConfirmAlert';
import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {ModalBody} from '@/componentLibrary/blocks/Modals/Modal/styled';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {H3} from '@/componentLibrary/components/typography';
import {ColorBaseBlue800} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useIsOutOfViewport} from '@/hooks/useIsOutOfViewport';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import {AutoscoreDisabledBanner} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateCodingTestPage/components/AutoscoreDisabledBanner';

import {useSyncScorecardLogic} from '../../../../hooks/useSyncScorecardLogic';
import {getScorecardActionType} from '../../../../utils';
import {ScorecardView} from '../../../ScorecardView';
import {getScores} from '../../getScores';
import {DetailedModalContext} from './Context';
import {AutomatedTestScoreSection} from './components/AutomatedTestScoreSection';
import {OverduescoreTabSection} from './components/OverduescoreTabSection/OverduescoreTabSection';
import {QualityScoreSection} from './components/QualityScoreSection';
import {ReviewScoreSection} from './components/ReviewScoreSection';
import {VIEWPORT_BUFFER_TOP} from './constants';
import messages from './messages';
import {CandidateNameHeader, ModalHeader, Wrapper} from './styled';
import {DetailedResultModalProps} from './types';

export function DetailedResultsModal({
    onClose,
    challengeAssignment,
    refetch,
    user
}: DetailedResultModalProps) {
    const phrases = useExtractPhraseConstants(messages);

    const {
        targetRef,
        parentRef,
        isOutOfViewport: showCandidateNameInHeader
    } = useIsOutOfViewport({bufferTopPx: VIEWPORT_BUFFER_TOP});

    const {
        modalIsOpen: scorecardsPreviewModalIsOpen,
        openModal: openScorecardsPreviewModal,
        closeModal: closeScorecardsPreviewModal
    } = useSimpleModalLogic();

    const {
        alertIsOpen: syncCodeReviewAlert,
        closeAlert: closeSyncCodeReviewAlert,
        alertTexts: syncCodeReviewAlertTexts,
        handleClick: showSyncCodeReviewAlert,
        onConfirm: syncCodeReview,
        bannerTexts: syncCodeReviewBannerTexts
    } = useSyncScorecardLogic({
        refetchJobApplication: refetch,
        challengeAssignment: challengeAssignment ?? null
    });

    const isScorecardUpdated = useMemo(
        () => getScorecardActionType(challengeAssignment ?? null),
        [challengeAssignment]
    );

    const tabRef = useRef<HTMLDivElement>(null);
    const [useOverdueScore, setUseOverdueScore] = useState(false);

    const {isAutoScoreDisabled, automatedScores} = getScores({
        challengeAssignment: challengeAssignment ?? null,
        useOverdueScore
    });

    const handleTabClick = (useOverdueScore: boolean) => {
        setUseOverdueScore(useOverdueScore);
    };

    return (
        <Modal withoutBody close={onClose} hideClose size={MODAL_SIZE.FULL_PAGE}>
            <ModalHeader>
                <PlainButton onClick={onClose} data-testid="back-btn">
                    <Icon size={16} icon={Icons.ARROW_BACK} fill={ColorBaseBlue800} />{' '}
                    {phrases.backButtonText}
                </PlainButton>
                <CandidateNameHeader show={showCandidateNameInHeader}>
                    <FormattedMessage
                        {...messages.title}
                        values={{
                            candidateName: `${user.firstName} ${user.lastName}`
                        }}
                    />
                </CandidateNameHeader>
            </ModalHeader>
            <ModalBody ref={parentRef}>
                <DetailedModalContext.Provider value={{parentRef, tabRef}}>
                    <Wrapper>
                        <H3 ref={targetRef}>
                            <FormattedMessage
                                {...messages.title}
                                values={{
                                    candidateName: `${user.firstName} ${user.lastName}`
                                }}
                            />
                        </H3>
                        <div>
                            {challengeAssignment.overdueAutomatedScore && (
                                <>
                                    <OverduescoreTabSection
                                        tabRef={tabRef}
                                        onClick={handleTabClick}
                                    />
                                </>
                            )}
                            <AutoscoreDisabledBanner
                                profile={challengeAssignment.challenge.profile}
                            />
                            {automatedScores?.testScore && (
                                <AutomatedTestScoreSection score={automatedScores.testScore} />
                            )}
                        </div>
                        {!isAutoScoreDisabled && (
                            <>
                                {automatedScores?.qualityScore && (
                                    <>
                                        <Banner type={BannerType.ATTENTION}>
                                            {phrases.aiDisclaimer}
                                        </Banner>
                                        <QualityScoreSection
                                            score={automatedScores.qualityScore}
                                        />
                                    </>
                                )}
                                {automatedScores?.reviewScore && (
                                    <>
                                        {!automatedScores?.qualityScore && (
                                            <>
                                                <Banner type={BannerType.ATTENTION}>
                                                    {phrases.aiDisclaimer}
                                                </Banner>
                                            </>
                                        )}
                                        {isScorecardUpdated && (
                                            <Banner
                                                title={syncCodeReviewBannerTexts.title}
                                                type={BannerType.ATTENTION}
                                                action={{
                                                    onClick: showSyncCodeReviewAlert,
                                                    text: syncCodeReviewBannerTexts.btnTitle
                                                }}
                                            >
                                                {syncCodeReviewBannerTexts.subtitle}
                                            </Banner>
                                        )}
                                        <ReviewScoreSection
                                            score={automatedScores?.reviewScore}
                                            openScorecardsPreviewModal={
                                                openScorecardsPreviewModal
                                            }
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </Wrapper>
                </DetailedModalContext.Provider>
                {syncCodeReviewAlert && (
                    <ConfirmAlert
                        onLeftAction={closeSyncCodeReviewAlert}
                        onRightAction={syncCodeReview}
                        {...syncCodeReviewAlertTexts}
                    />
                )}
                {scorecardsPreviewModalIsOpen && (
                    <ScorecardView
                        challenge={challengeAssignment?.challenge ?? null}
                        close={closeScorecardsPreviewModal}
                    />
                )}
            </ModalBody>
        </Modal>
    );
}
