import React, {useCallback, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {useOrganizationOktaConfiguration} from '@/api/auth/useOrganizationOktaConfiguration';
import {
    GetOrganizationOktaConfigurationQuery,
    OrganizationOktaConfiguration
} from '@/api/types/__generated__/graphql';
import {LINKS} from '@/links';
import {SsoOperationType, SsoProvider} from '@/services/auth/sso/constants';
import {useRedirectToOkta} from '@/services/auth/sso/okta/useRedirectToOkta';
import {parseRedirectPathFromQueryParams} from '@/utils/urlParamsParsers';

export function useFormSubmissionLogic(
    email: string,
    trimEmailInputValue: () => void,
    invalidFormInput: boolean,
    triedToSubmit: boolean,
    setTriedToSubmit: (value: boolean) => void
) {
    const location = useLocation();
    const redirectToOkta = useRedirectToOkta();

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [missingOktaConfiguration, setMissingOktaConfiguration] = useState<boolean>(false);

    const getState = useCallback(
        (oktaConfig: OrganizationOktaConfiguration) => {
            return {
                ssoProvider: SsoProvider.OKTA,
                operationType: SsoOperationType.LOGIN,
                redirectPath: parseRedirectPathFromQueryParams(location.search),
                errorRedirectPath: LINKS.oktaLogin,
                oktaConfig,
                redirectToLoginOnError: true,
                token: '',
                tosRevision: ''
            };
        },
        [location.search]
    );

    const onCompleted = useCallback(
        (data?: GetOrganizationOktaConfigurationQuery) => {
            if (!data?.organizationOktaConfiguration) {
                setMissingOktaConfiguration(true);
                setSubmitting(false);
                trimEmailInputValue();
                return;
            }

            setTriedToSubmit(false);
            const oktaConfig = data.organizationOktaConfiguration;
            redirectToOkta(getState(oktaConfig), oktaConfig);
        },
        [setTriedToSubmit, redirectToOkta, getState, trimEmailInputValue]
    );

    useOrganizationOktaConfiguration(
        email.trim(),
        !submitting || invalidFormInput,
        onCompleted
    );

    const handleSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setTriedToSubmit(true);
            if (invalidFormInput) {
                trimEmailInputValue();
                return;
            }

            setSubmitting(true);
        },
        [invalidFormInput, trimEmailInputValue, setTriedToSubmit]
    );

    return {triedToSubmit, submitting, handleSubmit, missingOktaConfiguration};
}
