/**
 * Warning: Do not export anything from this file,
 * because that will cause errors with Jest testing-framework
 * https://stackoverflow.com/questions/39986178/testing-react-target-container-is-not-a-dom-element
 */
import {ApolloProvider} from '@apollo/client';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import {CookiebotBanner} from '@/services/cookiebot';

import {App} from './App';
import {client} from './apolloClient';
import {ErrorBoundary} from './componentLibrary/components/ErrorBoundary';
import {ToastsList} from './componentLibrary/components/Toast/components/ToastsList';
import {LanguageProvider} from './containers/LanguageProvider';
import {GlobalStyle} from './globalStyled';
import {translationMessages} from './i18n';
import './print.css';
import {ImpersonationBanner} from './services/ImpersonationBanner';
import {IncompatibleBrowserBanner} from './services/IncompatibleBrowserBanner';
import {Intercom} from './services/intercom';
import {LogRocketWrapper} from './services/logrocket';
import './services/logrocket/setup';
import {Satismeter} from './services/satismeter';
import store from './store';

render(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <LanguageProvider messages={translationMessages}>
                <BrowserRouter>
                    <GlobalStyle />
                    <App />
                    <CookiebotBanner />
                    <ErrorBoundary wrapperStyle={{display: 'none'}}>
                        <Intercom />
                    </ErrorBoundary>
                    <ErrorBoundary wrapperStyle={{display: 'none'}}>
                        <Satismeter />
                    </ErrorBoundary>
                    <LogRocketWrapper />
                    <ImpersonationBanner />
                </BrowserRouter>
            </LanguageProvider>
        </ApolloProvider>
    </Provider>,
    document.getElementById('root')
);

render(
    <Provider store={store}>
        <LanguageProvider messages={translationMessages}>
            <ToastsList />
        </LanguageProvider>
    </Provider>,
    document.getElementById('toasts')
);

render(
    <Provider store={store}>
        <LanguageProvider messages={translationMessages}>
            <IncompatibleBrowserBanner />
        </LanguageProvider>
    </Provider>,
    document.getElementById('browser-prompt')
);
