import {FC, forwardRef} from 'react';

import {getUserName} from '@/utils/misc';

import {AvatarContent, AvatarWrapper} from './styled';
import {PersonAvatarProps} from './types';
import {getInitials} from './utils';

const PersonAvatar = forwardRef<HTMLDivElement, PersonAvatarProps>(
    (
        {
            user,
            size = 32,
            fontSize = 14,
            onClick,
            borderColor,
            backgroundColor,
            fontColor,
            ...rest
        },
        ref
    ) => {
        return (
            <AvatarWrapper
                data-testid="person-avatar"
                size={size}
                title={getUserName(user)}
                onClick={onClick}
                ref={ref}
                $backgroundColor={backgroundColor}
                $borderColor={borderColor}
                $fontColor={fontColor}
                {...rest}
            >
                <AvatarContent fontSize={fontSize} size={size}>
                    {getInitials(user)}
                </AvatarContent>
            </AvatarWrapper>
        );
    }
);

export const PersonAvatarSmall: FC<PersonAvatarProps> = ({
    size = 24,
    fontSize = 12,
    ...rest
}) => <PersonAvatar size={size} fontSize={fontSize} {...rest} />;

export const PersonAvatarMedium: FC<PersonAvatarProps> = forwardRef<
    HTMLDivElement,
    PersonAvatarProps
>(({size = 32, fontSize = 14, ...rest}, ref) => (
    <PersonAvatar size={size} fontSize={fontSize} {...rest} ref={ref} />
));

export const PersonAvatarLarge: FC<PersonAvatarProps> = ({
    size = 40,
    fontSize = 16,
    ...rest
}) => <PersonAvatar size={size} fontSize={fontSize} {...rest} />;

export const PersonAvatarLargest: FC<PersonAvatarProps> = ({
    size = 128,
    fontSize = 48,
    ...rest
}) => <PersonAvatar size={size} fontSize={fontSize} {...rest} />;
