import {AssessmentsRecommendationAssessmentType} from '@/api/types/__generated__/graphql';
import {PhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {ARCHITECT_MODE} from '@/pages/Organization/pages/Assessment/pages/JobPosition/pages/Settings/pages/Architect/constants';

export const getRecommendationCardInfo = (
    type: AssessmentsRecommendationAssessmentType,
    phrases: PhraseConstants
) => {
    if (type === AssessmentsRecommendationAssessmentType.CODING_CHALLENGE) {
        return {
            button: phrases.buttonEditRoleExperienceSkills,
            architectMode: ARCHITECT_MODE.CODING_CHALLENGE
        };
    }
    return {
        button: phrases.buttonEditRole,
        architectMode: ARCHITECT_MODE.EDIT_ROLE
    };
};
