import {isValidEmail} from '@/utils/validators';
import {ChangeEvent, useCallback, useState} from 'react';

export function useFormInputLogic(setTriedToSubmit: (value: boolean) => void) {
    const [email, setEmail] = useState<string>('');
    const [invalidFormInput, setInvalidFormInput] = useState<boolean>(!isValidEmail(email));

    const validateEmailInput = useCallback((value: string) => {
        const isValid = isValidEmail(value);
        setInvalidFormInput(!isValid);
    }, []);

    const updateEmailInputValue = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setTriedToSubmit(false);
            const value = e.target.value;
            setEmail(value);
            validateEmailInput(value);
        },
        [setTriedToSubmit, validateEmailInput]
    );

    const trimEmailInputValue = useCallback(() => {
        setEmail(email.trim());
    }, [email]);

    return {
        email,
        trimEmailInputValue,
        invalidFormInput,
        updateEmailInputValue
    };
}
