import {GetApplicationsForJobPosition_JobApplication} from '@/api/assessment/types';
import {AssessmentConfiguration, AssessmentScore} from '@/api/types/__generated__/graphql';
import {JobApplication} from '@/pages/Organization/pages/Assessment/pages/JobPosition/pages/EvaluationPage/types';
import {AssessmentMethodType} from '@/pages/common/roleFit/types';
import {isArrayWithItems} from '@/utils/typeGuards/isArrayWithItems';
import {isDefined} from '@/utils/typeGuards/isDefined';

function toInterviewKey(interviewId: number) {
    return `${AssessmentMethodType.INTERVIEW}_${interviewId}`;
}

function getAssessmentScore(
    key: string,
    assessmentScores?: Partial<AssessmentScore>[]
): Partial<AssessmentScore> | null {
    if (!assessmentScores) {
        return null;
    }
    return assessmentScores.find(item => item.assessmentMethodKey === key) || null;
}

const isMissingAllResults = (
    roleFit?:
        | JobApplication['roleFitV2']
        | GetApplicationsForJobPosition_JobApplication['roleFitV2']
        | null
) => {
    if (!isDefined(roleFit)) {
        return true;
    }

    const isMissingResults = ({missingResults}: Partial<AssessmentScore>) => missingResults;
    return roleFit.assessmentScores.every(isMissingResults);
};

function getConfiguredAssessments(assessmentConfigurations?: AssessmentConfiguration[]) {
    const hasAssessmentConfiguration = (assessmentMethodType: AssessmentMethodType) =>
        !!assessmentConfigurations?.some(
            configuration => configuration.assessmentMethodType === assessmentMethodType
        );

    const assessmentMethodTypes = Object.values(AssessmentMethodType);
    return assessmentMethodTypes.reduce(
        (acc, methodType) => {
            acc[methodType] = hasAssessmentConfiguration(methodType);
            return acc;
        },
        {} as Record<AssessmentMethodType, boolean>
    );
}

function sortAssessmentConfigurations(
    assessmentConfigurations: AssessmentConfiguration[],
    interviewIds?: number[]
) {
    const customOrder = ['PERSONALITY_TEST', 'LOGIC_TEST', 'CODING_TEST', 'INTERVIEW'];
    const sorter1 = (a: AssessmentConfiguration, b: AssessmentConfiguration) =>
        customOrder.indexOf(a.assessmentMethodType) -
        customOrder.indexOf(b.assessmentMethodType);

    const initialSorted = [...assessmentConfigurations].sort(sorter1);
    if (!isArrayWithItems(interviewIds)) {
        return initialSorted;
    }

    const sorter2 = (a: AssessmentConfiguration, b: AssessmentConfiguration) => {
        if (a.assessmentMethodType !== b.assessmentMethodType) {
            return 0;
        }
        if (a.assessmentMethodType !== 'INTERVIEW') {
            return 0;
        }

        const aIndex = interviewIds.indexOf(parseInt(a.assessmentMethodId, 10));
        const bIndex = interviewIds.indexOf(parseInt(b.assessmentMethodId, 10));

        if (aIndex !== undefined && bIndex !== undefined) {
            return aIndex - bIndex;
        }

        return 0;
    };

    return initialSorted.sort(sorter2);
}

export {
    toInterviewKey,
    getAssessmentScore,
    isMissingAllResults,
    getConfiguredAssessments,
    sortAssessmentConfigurations
};
