import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.assessment.codingTest.candidateCodingTest.title'
    },
    codingTestScoreTitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.codingTestScoreTitle'
    },
    withinTimeLimit: {
        id: 'app.assessment.codingTest.candidateCodingTest.title.withinTimeLimit'
    },
    beyondTimeLimit: {
        id: 'app.assessment.codingTest.candidateCodingTest.title.beyondTimeLimit'
    },
    setMainScoreBtnTitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.setMainScore.btn.title'
    },
    overdueScoreDesc: {
        id: 'app.assessment.codingTest.candidateCodingTest.overdueScoreDesc'
    },
    usedOverdueDescription: {
        id: 'app.assessment.codingTest.candidateCodingTest.usedOverdue.description'
    }
});
