import {FormattedMessage} from 'react-intl';

import {GetJobPosition_JobPosition} from '@/api/assessment/types';
import {GetCurrentEmployment_CurrentEmployment} from '@/api/organizations/types';
import {FetchEmploymentsQuery} from '@/api/types/__generated__/graphql';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {Dropdown} from '@/componentLibrary/components/Dropdowns/Dropdown';
import {UserItem} from '@/componentLibrary/components/Dropdowns/Dropdown/components/UserItem';
import {TextFieldProps} from '@/componentLibrary/components/inputs/TextField/types';

import {useEmploymentDropdownLogic} from './hooks/useEmploymentDropdownLogic';
import {useSortEmploymentsLogic} from './hooks/useSortEmploymentsLogic';
import messages from './messages';
import {itemToString} from './utils';

export type Employment =
    | NonNullable<
          FetchEmploymentsQuery['authenticatedOrganization']
      >['employmentsConnection']['items'][0]
    | GetCurrentEmployment_CurrentEmployment
    | NonNullable<GetJobPosition_JobPosition['hiringManager']>;

type EmploymentDropdownProps = {
    selectedItem?: Employment | null;
    onSelect: (employment: Employment | null) => void;
    isDisabled?: boolean;
    fullWidth?: boolean;
    isItemDisabled?: (employment: Employment) => boolean;
    isClearable?: boolean;
    hasError?: boolean;
    defaultValue?: Employment;
    onBlur?: () => void;
    placeholder?: TextFieldProps['placeholder'];
    placeLoggedInUserFirst?: boolean;
    label?: string;
    isRequired?: boolean;
    noFocusTrap?: boolean;
    zIndex?: number;
};

export function EmploymentDropdown({
    placeLoggedInUserFirst,
    isDisabled,
    ...props
}: EmploymentDropdownProps) {
    const {
        searchString,
        setSearchString,
        loadingEmployments,
        loadingMore,
        error,
        employments,
        loadMore
    } = useEmploymentDropdownLogic();
    const {loadingCurrentEmployment, sortedEmployments} = useSortEmploymentsLogic({
        placeLoggedInUserFirst,
        employments,
        loadingEmployments
    });

    if (error) {
        return <ErrorState error={error} />;
    }

    return (
        <Dropdown<Employment>
            items={sortedEmployments}
            menuIsLoading={loadingEmployments || loadingMore || loadingCurrentEmployment}
            onMenuScrollToBottom={loadMore}
            searchString={searchString}
            onSearchStringChange={setSearchString}
            itemToString={itemToString}
            noItemsMessage={<FormattedMessage {...messages.noResults} />}
            disableDefaultFilter
            components={{Item: UserItem}}
            disabled={isDisabled}
            {...props}
        />
    );
}
