import {defineMessages} from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.showPreviousResults.cancel'
    },
    sharePersonality: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.showPreviousResults.sharePersonality'
    },
    title: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.showPreviousResults.title'
    },
    description: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.showPreviousResults.description'
    },
    seeFullReport: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.showPreviousResults.seeFullReport'
    },
    personalityTestResults: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.showPreviousResults.personalityTestResults'
    }
});
