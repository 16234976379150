import {SpacingXlarge, SpacingXxsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXxsmall};
`;

export const Wrapper = styled.div`
    margin-bottom: ${SpacingXlarge};

    @media only screen and (max-width: 414px) {
        width: 100%;
    }
`;
