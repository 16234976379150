import {defineMessages} from 'react-intl';

export default defineMessages({
    skipped: {
        id: 'app.assessment.scorecardCriteriaRating.skipped'
    },
    noScore: {
        id: 'app.assessment.scorecardCriteriaRating.noScore'
    },
    readMore: {
        id: 'app.assessment.scorecardCriteriaRating.readMore'
    },
    readLess: {
        id: 'app.assessment.scorecardCriteriaRating.readLess'
    },
    noComment: {
        id: 'app.assessment.scorecardCriteriaRating.noComment'
    }
});
