import {GetJobApplication_JobApplication_ChallengeAssignment} from '@/api/assessment/types';
import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';
import {AUTOSCORING_DISABLED_PROFILES} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateCodingTestPage/constants';
import {isDefined} from '@/utils/typeGuards/isDefined';

export const getScores = ({
    challengeAssignment,
    useOverdueScore
}: {
    challengeAssignment: GetJobApplication_JobApplication_ChallengeAssignment | null;
    useOverdueScore?: boolean;
}) => {
    const automatedScores =
        useOverdueScore || challengeAssignment?.useOverdueScore
            ? challengeAssignment?.overdueAutomatedScore
            : challengeAssignment?.automatedScore;

    const hasTestScore = isDefined(automatedScores?.testScore?.score);
    const hasQualityScore = isDefined(automatedScores?.qualityScore?.score);
    const hasReviewScore = isDefined(automatedScores?.reviewScore?.score);

    const hasDetailedScores = !!(
        automatedScores?.testScore?.results?.length ||
        automatedScores?.qualityScore?.requirementScores?.length ||
        automatedScores?.reviewScore?.requirementScores?.length
    );

    const noScores =
        challengeAssignment?.status !== ChallengeAssignmentStatus.SUBMITTED ||
        (!hasTestScore && !hasQualityScore && !hasReviewScore);

    const isAutoScoreDisabled = !!(
        challengeAssignment?.challenge?.profile &&
        AUTOSCORING_DISABLED_PROFILES.includes(challengeAssignment?.challenge?.profile)
    );

    return {
        automatedScores,
        hasTestScore,
        hasDetailedScores,
        hasQualityScore,
        hasReviewScore,
        noScores,
        isAutoScoreDisabled
    };
};
