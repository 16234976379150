import {defineMessages} from 'react-intl';

export const PRIVACY_POLICY_RECRUITMENT_SERVICES = defineMessages({
    title: {
        id: 'consent.PRIVACY_POLICY_RECRUITMENT_SERVICES.title'
    },
    shortDescription: {
        id: 'consent.PRIVACY_POLICY_RECRUITMENT_SERVICES.newShortDescription'
    }
});

export const PRIVACY_POLICY_CANDIDATE_SERVICES = defineMessages({
    title: {
        id: 'consent.PRIVACY_POLICY_CANDIDATE_SERVICES.title'
    },
    shortDescription: {
        id: 'consent.PRIVACY_POLICY_CANDIDATE_SERVICES.newShortDescription'
    }
});

export const TERMS_OF_SERVICE = defineMessages({
    title: {
        id: 'consent.TERMS_OF_SERVICE.title'
    },
    shortDescription: {
        id: 'consent.TERMS_OF_SERVICE.shortDescription'
    }
});

export const SUB_PROCESSORS = defineMessages({
    title: {
        id: 'consent.SUB_PROCESSORS.title'
    },
    shortDescription: {
        id: 'consent.SUB_PROCESSORS.shortDescription'
    }
});
