import {useCallback, useEffect, useRef} from 'react';

import {coral, grey} from '@/componentLibrary/tokens/legacyColors';
import {ColorBaseGrey900} from '@/componentLibrary/tokens/variables';
import {StyledCanvas} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/components/FormHeader/components/PersonalityTestProgress/styled';
import {PersonalityTestProgressProps} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/components/FormHeader/components/PersonalityTestProgress/types';

export const PersonalityTestProgress = ({percentage}: PersonalityTestProgressProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const clearCanvas = useCallback((canvas: HTMLCanvasElement) => {
        const context = canvas.getContext('2d');
        if (context) {
            context.clearRect(0, 0, canvas.width, canvas.height);
        }
    }, []);

    const drawCircleOnCanvas = useCallback(
        (canvas: HTMLCanvasElement, stopRadians: number, color: string) => {
            const context = canvas.getContext('2d');
            if (!context) {
                return;
            }

            const {width, height} = canvas;
            const radius = width / 2 - 2;

            context.strokeStyle = color;
            context.lineWidth = 2;

            context.beginPath();
            const startRadian = -0.5 * Math.PI;
            const endRadian = stopRadians - 0.5 * Math.PI;

            context.arc(width / 2, height / 2, radius, startRadian, endRadian);
            context.stroke();
        },
        []
    );

    const drawPercentageInCanvas = useCallback(
        (canvas: HTMLCanvasElement, drawPercentage: number) => {
            const text = `${drawPercentage.toFixed(0)}%`;
            const context = canvas.getContext('2d');
            if (!context) {
                return;
            }

            context.font = '13px Euclid Circular A';
            context.fillStyle = ColorBaseGrey900;
            context.textAlign = 'center';

            context.fillText(text, canvas.width / 2, 30);
        },
        []
    );

    const updateCanvas = useCallback(() => {
        if (!canvasRef.current) {
            return;
        }

        clearCanvas(canvasRef.current);
        const roundedPercentage = Math.round(percentage / 5) * 5;
        const safePercentage = Math.min(roundedPercentage, 99);

        drawCircleOnCanvas(canvasRef.current, 2 * Math.PI, grey.grey600());
        if (safePercentage > 0) {
            drawCircleOnCanvas(
                canvasRef.current,
                2 * Math.PI * (safePercentage / 100),
                coral.regular()
            );
        }
        drawPercentageInCanvas(canvasRef.current, safePercentage);
    }, [percentage, clearCanvas, drawCircleOnCanvas, drawPercentageInCanvas]);

    useEffect(() => {
        updateCanvas();
    }, [updateCanvas]);

    return <StyledCanvas height={50} width={50} ref={canvasRef} />;
};
