import {ApolloCache, FetchResult, useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';
import {JOB_RECOMMENDATION_STATUS} from '@/pages/User/components/JobRecommendations/constants';
import {logger} from '@/services/logrocket';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {AcceptJobRecommendationMutation} from '../types/__generated__/graphql';
import {ACCEPT_JOB_RECOMMENDATION} from './mutations';

function updateCache(
    cache: ApolloCache<AcceptJobRecommendationMutation>,
    response: FetchResult<AcceptJobRecommendationMutation>
) {
    const {data} = response;
    if (!data?.acceptJobRecommendation?.ok) {
        return;
    }

    const {jobRecommendationId} = data.acceptJobRecommendation;

    try {
        const newStatus = JOB_RECOMMENDATION_STATUS.ACCEPTED;

        cache.modify({
            id: cache.identify({id: jobRecommendationId, __typename: 'JobRecommendation'}),
            fields: {
                status() {
                    return newStatus;
                }
            }
        });
    } catch (error) {
        logger.error(error, ' when updating cache.');
    }
}

export function useAcceptJobRecommendation(
    jobRecommendationId: string,
    userId?: number
): MutationHookReturnType<AcceptJobRecommendationMutation> {
    const [mutate, {error, loading}] = useMutation(ACCEPT_JOB_RECOMMENDATION);
    const doMutate = useCallback(() => {
        const options = {
            variables: {data: {userId: userId as number, jobRecommendationId}},
            skip: !isDefined(userId),
            update: updateCache
        };
        return mutate(options);
    }, [mutate, jobRecommendationId, userId]);

    return [doMutate, {error, loading}];
}
