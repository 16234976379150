import {ReactElement} from 'react';
import {Body, Header, Logo, Wrapper} from './styled';

type Props = {
    children: ReactElement;
};

export const ConsentTemplate = ({children}: Props) => (
    <Wrapper>
        <Header>
            <Logo />
        </Header>
        <Body>{children}</Body>
    </Wrapper>
);
