import styled from 'styled-components';

import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {
    SpacingLarge,
    SpacingXsmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled(FlexLayout)`
    flex-direction: column;

    @media print {
        display: none;
    }
`;

export const HeaderWrapper = styled.div`
    padding: ${SpacingLarge};
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: ${SpacingXxxsmall};
    justify-items: center;
    align-items: center;

    & > button:first-child {
        margin-inline-end: auto;
        transform: translateX(-${SpacingXxsmall});
    }

    & > *:last-child {
        margin-inline-start: auto;
        transform: translateX(${SpacingXxsmall});
    }
`;

export const TitleWrapper = styled.div`
    grid-column-start: 2;
    text-align: center;
    gap: ${SpacingXsmall};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StepperWrapper = styled.div`
    width: 100%;
    padding: 0 ${SpacingLarge};
`;
