import {useIntl} from 'react-intl';

import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {LeadershipReport} from '@/componentLibrary/blocks/testReports/LeadershipReport';
import {LeadershipReportOverview} from '@/componentLibrary/blocks/testReports/PersonalityTest/LeadershipReportOverview';
import {PersonalityTestReportOverview} from '@/componentLibrary/blocks/testReports/PersonalityTest/PersonalityTestReportOverview';
import {TestReportOverviewLoading} from '@/componentLibrary/blocks/testReports/components/TestReportOverviewLoading';
import {TestReportType} from '@/componentLibrary/blocks/testReports/components/TestReportOverviewLoading/TestReportOverviewLoading';
import {InPageHeader} from '@/componentLibrary/components/InPageHeader';
import {TITLE_SIZE} from '@/componentLibrary/components/InPageHeader/types';
import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {S2, ScorePercent} from '@/componentLibrary/components/typography';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {PersonalityProfileDetailsModal} from '../../../../components/AssessmentSelector/components/PersonalityProfileDetailsModal';
import {HeaderWrapper} from '../CandidateLogicTestPage/styled';
import {FacetCharts} from './components/FacetCharts';
import {PersonalityEmptyState} from './components/PersonalityEmptyState';
import {useTestResultsLogic} from './hooks';
import messages from './messages';
import {LeadershipOverviewWrapper} from './styled';
import {Props} from './types';

export function CandidatePersonalityTestPage({userId, jobPositionId}: Props) {
    const intl = useIntl();

    const {
        modalIsOpen: leadershipReportModalIsOpen,
        openModal,
        closeModal
    } = useSimpleModalLogic();

    const {
        modalIsOpen: personalityProfileDetailsModalIsOpen,
        openModal: openPersonalityProfileDetailsModal,
        closeModal: closePersonalityProfileDetailsModal
    } = useSimpleModalLogic();

    const {
        jobPosition,
        loading: loadingJobPosition,
        error: jobPositionError,
        refetch: refetchJobPosition
    } = useJobPosition(jobPositionId, {withPersonalityProfile: true});

    const {
        loadingUserResults,
        userResultsError,
        refetchUserResults,
        personalityTestResults,
        jobApplication,
        loadingJobApplication
    } = useTestResultsLogic(userId, jobPositionId);

    if (loadingJobPosition || loadingUserResults || loadingJobApplication) {
        return <TestReportOverviewLoading type={TestReportType.PERSONALITY_TEST} />;
    }

    if (jobPositionError) {
        return <ErrorState error={jobPositionError} refetch={refetchJobPosition} />;
    }

    if (userResultsError) {
        return <ErrorState error={userResultsError} refetch={refetchUserResults} />;
    }

    const personalityTestScore = jobApplication?.roleFitV2?.assessmentScores?.find(
        ({assessmentMethodType}) => assessmentMethodType === 'PERSONALITY_TEST'
    );

    const hasPersonalityProfile =
        isDefined(jobPosition) && isDefined(jobPosition.personalityProfile);

    function hasPersonalityIrtResult(
        testResults: typeof personalityTestResults
    ): testResults is {
        personalityIrtResult: NonNullable<typeof personalityTestResults.personalityIrtResult>;
        leadershipProfile: NonNullable<typeof personalityTestResults.leadershipProfile>;
    } {
        return !!testResults && !!testResults.personalityIrtResult;
    }

    function getPersonalityResult() {
        const {requirePersonalityTest, leadershipReportEnabled} = jobPosition || {};
        const {personalityIrtResult, leadershipProfile} = personalityTestResults;

        if (
            !isDefined(jobPosition) ||
            !requirePersonalityTest ||
            !personalityIrtResult ||
            !isDefined(jobPosition.personalityProfile)
        ) {
            return <PersonalityEmptyState />;
        }

        return (
            <>
                <HeaderWrapper>
                    <InPageHeader
                        title={{
                            content: intl.formatMessage(messages.personalityScore, {
                                personalityProfile: jobPosition.personalityProfile.name
                            }),
                            size: TITLE_SIZE.LARGE
                        }}
                    />
                    <ScorePercent
                        score={personalityTestScore?.score}
                        missingResults={personalityTestScore?.missingResults ?? true}
                    />
                    <S2>{intl.formatMessage(messages.facetTitle)}</S2>
                    <FacetCharts
                        facets={jobPosition.personalityProfile.facets}
                        personalityTestResults={personalityTestResults}
                    />
                    {hasPersonalityProfile && (
                        <PlainButton narrow onClick={openPersonalityProfileDetailsModal}>
                            {intl.formatMessage(messages.facetLink)}
                        </PlainButton>
                    )}
                </HeaderWrapper>
                <PersonalityTestReportOverview
                    personalityTestResult={personalityIrtResult}
                    jobPositionId={jobPositionId}
                />
                {leadershipReportEnabled && leadershipProfile && (
                    <LeadershipOverviewWrapper>
                        <LeadershipReportOverview
                            leadershipProfile={personalityIrtResult.leadershipProfile}
                            onClickSeeReport={openModal}
                        />
                    </LeadershipOverviewWrapper>
                )}
            </>
        );
    }

    return (
        <div>
            {personalityProfileDetailsModalIsOpen && hasPersonalityProfile && (
                <PersonalityProfileDetailsModal
                    closeModal={closePersonalityProfileDetailsModal}
                    personalityProfile={jobPosition.personalityProfile ?? null}
                />
            )}

            {leadershipReportModalIsOpen &&
                hasPersonalityIrtResult(personalityTestResults) && (
                    <LeadershipReport
                        close={closeModal}
                        leadershipProfile={
                            personalityTestResults.personalityIrtResult.leadershipProfile
                        }
                        user={personalityTestResults.personalityIrtResult.user}
                        personalityTestId={
                            personalityTestResults.personalityIrtResult.personalityTestId
                        }
                        jobPositionId={jobPositionId}
                    />
                )}
            {isDefined(jobPosition) && getPersonalityResult()}
        </div>
    );
}
