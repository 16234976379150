import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {
    AuthorizationResultInput,
    RequestChallengeAssignmentAccountAccessMutation
} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {REQUEST_CHALLENGE_ASSIGNMENT_ACCOUNT_ACCESS} from './mutations';

export function useRequestChallengeAssignmentAccountAccess(): MutationHookReturnType<
    RequestChallengeAssignmentAccountAccessMutation,
    [string, AuthorizationResultInput?]
> {
    const [mutate, {error, loading}] = useMutation(
        REQUEST_CHALLENGE_ASSIGNMENT_ACCOUNT_ACCESS
    );

    const doChallengeAssignmentAccountAccess = useCallback(
        (assignmentId: string, input?: AuthorizationResultInput) => {
            const variables = {assignmentId, input};
            return mutate({variables});
        },
        [mutate]
    );

    return [doChallengeAssignmentAccountAccess, {error, loading}];
}
