import {useIntl} from 'react-intl';

import {Separator} from '@/componentLibrary/components/Separator';
import {P2} from '@/componentLibrary/components/typography';

import messages from './messages';
import {Wrapper} from './styled';

export function CreateAccountFormSeparator() {
    const intl = useIntl();

    return (
        <Wrapper>
            <Separator>
                <P2>{intl.formatMessage(messages.or)}</P2>
            </Separator>
        </Wrapper>
    );
}
