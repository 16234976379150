import {useIntl} from 'react-intl';

import {Popover} from '@/componentLibrary/components/Tooltips/Popover';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {S2} from '@/componentLibrary/components/typography/SubTitles';

import {growthFactorDescriptionsMessages, growthFactorNamesMessages} from '../../messages';
import {LinkWrapper, TriggerButton} from './styled';
import {GrowthFactorTagProps} from './types';

export function GrowthFactorTag({
    dimensionName,
    readMoreText,
    readMoreUrl
}: GrowthFactorTagProps) {
    const intl = useIntl();
    const name = intl.formatMessage(
        growthFactorNamesMessages[dimensionName as keyof typeof growthFactorNamesMessages]
    );
    const description = intl.formatMessage(
        growthFactorDescriptionsMessages[
            dimensionName as keyof typeof growthFactorDescriptionsMessages
        ]
    );

    return (
        <Popover
            asChild
            placement="bottom"
            content={
                <>
                    <S2>{name}</S2>
                    <P2>{description}</P2>
                    <LinkWrapper>
                        <ExternalLink url={readMoreUrl}>{readMoreText}</ExternalLink>
                    </LinkWrapper>
                </>
            }
            trigger={<TriggerButton>{name}</TriggerButton>}
        />
    );
}
