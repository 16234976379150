import {Children, ReactElement, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {IntlProvider} from 'react-intl';

import {useLocale} from '@/containers/LanguageProvider/useLocale';
import {LocaleMessages} from '@/i18n';
import {RIGHT_TO_LEFT_LANGUAGES} from '@/services/locale/constants';

type LanguageProviderProps = {
    customLocale?: string | null;
    messages: LocaleMessages;
    children: ReactElement;
};

function setFontFamily(locale: string): void {
    const isArabic = locale === 'ar';
    isArabic
        ? document.body.setAttribute(
              'style',
              'font-family:IBM Plex Sans Arabic, sans-serif !important'
          )
        : document.body.setAttribute(
              'style',
              'font-family:Euclid Circular A, sans-serif !important'
          );
}

function setAppDirectionIfRightToLeftLanguage(locale: string): void {
    if (RIGHT_TO_LEFT_LANGUAGES.includes(locale)) {
        document.dir = 'rtl';
    } else {
        document.dir = 'ltr';
    }
}

export function LanguageProvider({
    customLocale = null,
    messages,
    children
}: LanguageProviderProps) {
    let locale = useLocale();
    if (customLocale) {
        locale = customLocale;
    }
    const translatedMessages = messages[locale] || messages.en;

    useEffect(() => {
        setFontFamily(locale);
    }, [locale]);

    useEffect(() => {
        setAppDirectionIfRightToLeftLanguage(locale);
    }, [locale]);

    return (
        // @ts-ignore
        <IntlProvider locale={locale} messages={translatedMessages}>
            <>
                <Helmet htmlAttributes={{lang: locale}} />
                {Children.only(children)}
            </>
        </IntlProvider>
    );
}
