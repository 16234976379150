import DOMPurify from 'dompurify';
import {FormattedMessage, MessageDescriptor} from 'react-intl';

// If your message needs to replace values inside message, do not use this component
// In that case instead of this, use FormattedMessage with the format in the link:
// https://formatjs.io/docs/react-intl/components/#formattedmessage
export function FormattedHTMLMessage(message: MessageDescriptor) {
    return (
        <FormattedMessage {...message}>
            {p => (
                <span
                    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(p as unknown as string)
                    }}
                />
            )}
        </FormattedMessage>
    );
}
