import styled from 'styled-components';

import {
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXlarge
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    margin-top: ${SpacingLarge};
    margin-bottom: ${SpacingSmall};
    max-width: 920px;

    @media only screen and (max-width: 1032px) {
        padding: ${SpacingMedium};
    }
`;

export const FactorsOverviewWrapper = styled.div`
    margin-top: ${SpacingMedium};
    width: 100%;
`;

export const StrengthsAndChallengesWrapper = styled.div`
    margin-top: ${SpacingLarge};
    width: 100%;
`;

export const GrowthFactorsWrapper = styled.div`
    margin-top: ${SpacingXlarge};
    width: 100%;
`;
