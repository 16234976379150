import {Banner} from '@/componentLibrary/components/banners/Banner';

import {BannerWrapper} from './styled';
import {Props} from './types';

export const AuthenticationFormBanner = ({type, text}: Props) => (
    <BannerWrapper>
        <Banner type={type} noIcon>
            {text}
        </Banner>
    </BannerWrapper>
);
