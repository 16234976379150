import {defineMessages} from 'react-intl';

export default defineMessages({
    DECISION_MAKING: {
        id: 'leadershipReport.facet.decisionMaking.title'
    },
    DECISION_MAKING_DEFINITION_TITLE: {
        id: 'leadershipReport.facet.decisionMaking.definition.title'
    },
    DECISION_MAKING_DEFINITION_DESCRIPTION: {
        id: 'leadershipReport.facet.decisionMaking.definition.description'
    },
    DEFINING_THE_DIRECTION: {
        id: 'leadershipReport.facet.definingTheDirection.title'
    },
    DEFINING_THE_DIRECTION_DEFINITION_TITLE: {
        id: 'leadershipReport.facet.definingTheDirection.definition.title'
    },
    DEFINING_THE_DIRECTION_DEFINITION_DESCRIPTION: {
        id: 'leadershipReport.facet.definingTheDirection.definition.description'
    },
    DRIVING_TOWARDS_RESULTS: {
        id: 'leadershipReport.facet.drivingTowardsResults.title'
    },
    DRIVING_TOWARDS_RESULTS_DEFINITION_TITLE: {
        id: 'leadershipReport.facet.drivingTowardsResults.definition.title'
    },
    DRIVING_TOWARDS_RESULTS_DEFINITION_DESCRIPTION: {
        id: 'leadershipReport.facet.drivingTowardsResults.definition.description'
    },
    GETTING_OTHERS_ONBOARD: {
        id: 'leadershipReport.facet.gettingOthersOnboard.title'
    },
    GETTING_OTHERS_ONBOARD_DEFINITION_TITLE: {
        id: 'leadershipReport.facet.gettingOthersOnboard.definition.title'
    },
    GETTING_OTHERS_ONBOARD_DEFINITION_DESCRIPTION: {
        id: 'leadershipReport.facet.gettingOthersOnboard.definition.description'
    },
    HELPING_OTHERS_GROW: {
        id: 'leadershipReport.facet.helpingOthersGrow.title'
    },
    HELPING_OTHERS_GROW_DEFINITION_TITLE: {
        id: 'leadershipReport.facet.helpingOthersGrow.definition.title'
    },
    HELPING_OTHERS_GROW_DEFINITION_DESCRIPTION: {
        id: 'leadershipReport.facet.helpingOthersGrow.definition.description'
    },
    INNER_COMPASS: {
        id: 'leadershipReport.facet.innerCompass.title'
    },
    INNER_COMPASS_DEFINITION_TITLE: {
        id: 'leadershipReport.facet.innerCompass.definition.title'
    },
    INNER_COMPASS_DEFINITION_DESCRIPTION: {
        id: 'leadershipReport.facet.innerCompass.definition.description'
    },
    MANAGING_CHANGE: {
        id: 'leadershipReport.facet.managingChange.title'
    },
    MANAGING_CHANGE_DEFINITION_TITLE: {
        id: 'leadershipReport.facet.managingChange.definition.title'
    },
    MANAGING_CHANGE_DEFINITION_DESCRIPTION: {
        id: 'leadershipReport.facet.managingChange.definition.description'
    },
    RELATIONSHIP_BUILDING: {
        id: 'leadershipReport.facet.relationshipBuilding.title'
    },
    RELATIONSHIP_BUILDING_DEFINITION_TITLE: {
        id: 'leadershipReport.facet.relationshipBuilding.definition.title'
    },
    RELATIONSHIP_BUILDING_DEFINITION_DESCRIPTION: {
        id: 'leadershipReport.facet.relationshipBuilding.definition.description'
    },
    RESILIENCE: {
        id: 'leadershipReport.facet.resilience.title'
    },
    RESILIENCE_DEFINITION_TITLE: {
        id: 'leadershipReport.facet.resilience.definition.title'
    },
    RESILIENCE_DEFINITION_DESCRIPTION: {
        id: 'leadershipReport.facet.resilience.definition.description'
    },
    CHALLENGE: {
        id: 'leadershipReport.result.challenge'
    },
    STRENGTH: {
        id: 'leadershipReport.result.strength'
    },
    SUFFICIENT: {
        id: 'leadershipReport.result.sufficient'
    }
});
