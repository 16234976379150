import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_TEST_PROFILE_FOR_JOB_POSITION} from './queries';

export function useTestProfileForJobPosition(jobPositionId: number) {
    const {loading, error, data, refetch} = useQuery(GET_TEST_PROFILE_FOR_JOB_POSITION, {
        variables: {id: jobPositionId},
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    const testProfile = useMemo(() => data?.jobPosition?.selectedTestProfile ?? null, [data]);

    return {loading, error, testProfile, refetch};
}
