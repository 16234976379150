import {useMemo} from 'react';

import {useCurrentEmployment} from '@/api/organizations/useCurrentEmployment';

import {Employment} from '../EmploymentDropdown';

type UseSortEmploymentsLogicProps = {
    placeLoggedInUserFirst?: boolean;
    employments: Employment[];
    loadingEmployments: boolean;
};

export function useSortEmploymentsLogic({
    placeLoggedInUserFirst,
    employments,
    loadingEmployments
}: UseSortEmploymentsLogicProps) {
    const {loading: loadingCurrentEmployment, employment: currentEmployment} =
        useCurrentEmployment();

    const sortedEmployments: Employment[] = useMemo(() => {
        if (
            loadingEmployments ||
            loadingCurrentEmployment ||
            !employments ||
            !Array.isArray(employments)
        ) {
            return [] as Employment[];
        }

        if (!placeLoggedInUserFirst || !currentEmployment) {
            return employments;
        }

        const currentEmploymentInEmploymentsArray = employments.find(employment => {
            return employment.id === currentEmployment.id;
        });

        const filteredEmployments = employments.filter(
            employment => employment.id !== currentEmployment.id
        );

        if (!currentEmploymentInEmploymentsArray) {
            return [currentEmployment, ...filteredEmployments] as Employment[];
        }

        return [currentEmploymentInEmploymentsArray, ...filteredEmployments] as Employment[];
    }, [
        currentEmployment,
        employments,
        loadingCurrentEmployment,
        placeLoggedInUserFirst,
        loadingEmployments
    ]);

    return {
        loadingCurrentEmployment,
        sortedEmployments
    };
}
