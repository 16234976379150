import {gql} from '@/api/types/__generated__';

export const VERIFY_IMPERSONATION_TOKEN = gql(`
    mutation verifyImpersonationToken($token: String!) {
        verifyImpersonationToken(token: $token) {
            ok
            errorMessage
        }
    }
`);
