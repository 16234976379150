import {FormattedMessage} from 'react-intl';
import {ReactSVG} from 'react-svg';
import styled from 'styled-components';

import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {H3, P1, S1} from '@/componentLibrary/components/typography';
import {useResendConfirmationEmail} from '@/pages/SignupV2/components/Email/EmailConfirmationSent/hooks/useResendConfirmationEmail';
import {EmailConfirmationSentProps} from '@/pages/SignupV2/components/Email/EmailConfirmationSent/types';
import {WhiteBox} from '@/pages/SignupV2/styled';

import mailUnreadIcon from './assets/mark-email-unread.svg';
import messages from './messages';

export function EmailConfirmationSent(props: EmailConfirmationSentProps) {
    const {resendConfirmationEmail, error, success} = useResendConfirmationEmail(props.userId);
    const doResend = () => {
        resendConfirmationEmail();
    };

    return (
        <CenteredWhiteBox>
            {/* @TODO Use proper icon component */}
            <ReactSVG src={mailUnreadIcon} wrapper="span" />
            <CenteredH3>
                <FormattedMessage {...messages.checkYourInbox} />
            </CenteredH3>
            <CenteredP1>
                <FormattedMessage
                    {...messages.confirmationNeeded}
                    values={{
                        email: <S1 data-testid={'success-email-address'}>{props.email}</S1>
                    }}
                />
            </CenteredP1>
            {props.userId && (
                <div>
                    <CenteredP1>
                        <FormattedMessage {...messages.resendQuestion} />
                    </CenteredP1>
                    <PlainButton onClick={doResend}>
                        <CenteredP1>
                            <FormattedMessage {...messages.resendLinkText} />
                        </CenteredP1>
                    </PlainButton>
                </div>
            )}

            {(success || error) && (
                <Banner type={success ? BannerType.SUCCESS : BannerType.ERROR}>
                    <FormattedMessage
                        {...(success ? messages.resendSuccess : messages.resendError)}
                    />
                </Banner>
            )}
        </CenteredWhiteBox>
    );
}

const AlignCenter = styled.div`
    text-align: center;
`;

const CenteredWhiteBox = styled(WhiteBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 16px;
    padding-bottom: 16px;
`;

const CenteredH3 = H3.withComponent(AlignCenter);
const CenteredP1 = P1.withComponent(AlignCenter);
