import {
    ColorBaseBlue700,
    ColorBaseBlue800,
    ColorBaseGrey200,
    ColorBaseGrey600,
    ColorBaseGrey900,
    ColorBgSubtle,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div<{withMargin: boolean}>`
    display: flex;
    align-items: center;
    margin-bottom: ${({withMargin}) => (withMargin ? SpacingXxsmall : '0')};
`;
export const RadioButtonInput = styled.input<{hasLabel: boolean}>`
    flex-shrink: 0;
    position: relative;
    appearance: none;
    width: ${SpacingXsmall};
    height: ${SpacingXsmall};
    box-sizing: border-box;
    background-color: ${ColorBaseGrey200};
    border: 1px solid ${ColorBaseGrey600};
    border-radius: 100%;
    cursor: pointer;

    :focus:not(:focus-visible) {
        /* Remove focus indication when a mouse is used */
        outline: none;
    }

    :focus-visible {
        outline: 0.25rem solid ${ColorBaseBlue800};
        outline-offset: 0.125rem;
    }

    &::after {
        content: '';
        position: absolute;
        width: ${SpacingXxsmall};
        height: ${SpacingXxsmall};
        left: 1px;
        top: 1px;
        display: none;
        border-radius: 100%;
    }

    :checked {
        &::after {
            display: block;
        }
    }

    :disabled {
        cursor: not-allowed;
    }

    /* Selected disabled */
    :checked:disabled {
        background: ${ColorBgSubtle};
        border: 1px solid ${ColorBaseBlue700};

        &::after {
            background: ${ColorBaseBlue700};
        }
    }

    /* Selected not disabled */
    :checked:not(:disabled) {
        background: ${ColorBgSubtle};
        border: 1px solid ${ColorBaseBlue800};

        &::after {
            background: ${ColorBaseBlue800};
        }
    }

    /* Not selected and disabled */
    :not(:checked):disabled {
        background: ${ColorBgSubtle};
        border: 1px solid ${ColorBgSubtle};
    }

    ${({hasLabel}) => (hasLabel ? `margin-inline-end: ${SpacingXsmall};` : '')};
`;
export const Label = styled.label<{isDisabled: boolean}>`
    display: flex;
    align-items: center;
    cursor: ${({isDisabled}) => (isDisabled ? 'not-allowed' : 'pointer')};
    color: ${({isDisabled}) => (isDisabled ? ColorBaseGrey600 : ColorBaseGrey900)};
`;
