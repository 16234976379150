import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {S1} from '@/componentLibrary/components/typography/SubTitles';
import {
    ColorFgSuccess,
    SpacingXsmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import messages from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation/messages';

type Props = {
    companyName: string;
    close: () => void;
};

export function SentApplicationConfirmation({companyName, close}: Props) {
    const intl = useIntl();
    const successText = intl.formatMessage(messages.success, {companyName});

    return (
        <Modal close={close} closeOnClickOutside={false}>
            <IconWrapper>
                <Icon size={40} fill={ColorFgSuccess} icon={Icons.CHECK_CIRCLE} />
                <S1>{successText}</S1>
            </IconWrapper>
        </Modal>
    );
}

const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${SpacingXxlarge};

    div {
        margin: ${SpacingXsmall};
    }
`;
