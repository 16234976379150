export const ORGANIZATION_BASE_URL = '/o';

export const organizationLinks = {
    home: () => `${ORGANIZATION_BASE_URL}/home`,
    account: () => `${ORGANIZATION_BASE_URL}/account`,
    settings: () => `${ORGANIZATION_BASE_URL}/settings`,
    assessment: () => `${ORGANIZATION_BASE_URL}/assessment`,
    teamInsights: () => `${ORGANIZATION_BASE_URL}/team-insights`,
    analytics: () => `${ORGANIZATION_BASE_URL}/analytics`
};
