import styled from 'styled-components';

import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {S1} from '@/componentLibrary/components/typography/SubTitles';

export const InputField = styled(FormField)`
    margin-bottom: 40px;
`;

export const InputText = styled(S1)`
    font-weight: 500;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;
