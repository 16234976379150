import {useCallback} from 'react';

import {ColorVariables} from '@/componentLibrary/tokens/types';

import {
    LABEL_COLOR,
    LABEL_COLOR_DISABLED,
    MARKER_GREEN,
    MARKER_GREEN_DISABLED,
    MARKER_GREY,
    MARKER_GREY_DISABLED
} from './constants';

type UseToggleStyleLogicProps = {
    isToggled?: boolean;
    isDisabled?: boolean;
    showLabelToTheLeft?: boolean;
};

type GetterProps = {
    toggled?: boolean;
    disabled?: boolean;
};

export function useToggleStyleLogic({isToggled, isDisabled}: UseToggleStyleLogicProps) {
    const getColors = useCallback((): {
        markerColor: ColorVariables;
        labelColor: ColorVariables;
    } => {
        const getMarkerColor = ({toggled, disabled}: GetterProps) => {
            const greenColor = disabled ? MARKER_GREEN_DISABLED : MARKER_GREEN;
            const greyColor = disabled ? MARKER_GREY_DISABLED : MARKER_GREY;
            return toggled ? greenColor : greyColor;
        };

        const getLabelColor = ({disabled}: GetterProps) =>
            disabled ? LABEL_COLOR_DISABLED : LABEL_COLOR;

        return {
            markerColor: getMarkerColor({toggled: isToggled, disabled: isDisabled}),
            labelColor: getLabelColor({toggled: isToggled, disabled: isDisabled})
        };
    }, [isToggled, isDisabled]);

    return {getColors};
}
