import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.logicTestIrt.pages.logicTest.components.testForm.nextQuestionModal.title'
    },
    description: {
        id: 'app.pages.logicTestIrt.pages.logicTest.components.testForm.nextQuestionModal.description'
    },
    nextQuestion: {
        id: 'app.pages.logicTestIrt.pages.logicTest.components.testForm.nextQuestionModal.nextQuestion'
    },
    leaveTheTest: {
        id: 'app.pages.logicTestIrt.pages.logicTest.components.testForm.nextQuestionModal.leaveTheTest'
    }
});
