import {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';

import {LogoWithText} from '@/componentLibrary/components/Logo/LogoWithText/LogoWithText';
import {AlvaLogoWrapper, MainWrapper, MessageWrapper, SubWrapper} from '../../styled';
import messages from './messages';
import {AnimatedSuccess} from './styled';

type StripeSuccessProps = {
    redirectUrl: string;
};

export function StripeSuccess({redirectUrl}: StripeSuccessProps) {
    useEffect(() => {
        const timer = setTimeout(() => window.location.replace(redirectUrl), 3000);
        return () => clearTimeout(timer);
    }, [redirectUrl]);

    return (
        <>
            <MainWrapper>
                <div />
                <SubWrapper>
                    <AnimatedSuccess />
                    <MessageWrapper $width={396} $height={48}>
                        <FormattedMessage
                            {...messages.stripeCheckoutSuccessMessage}
                            values={{value: <br />}}
                        />
                    </MessageWrapper>
                </SubWrapper>
                <AlvaLogoWrapper>
                    <LogoWithText />
                </AlvaLogoWrapper>
            </MainWrapper>
        </>
    );
}
