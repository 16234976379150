import {StandardTenChartSvg} from '@/componentLibrary/components/charts/StandardTenChart/components/StandardTenChartSvg';
import {useStandardTenChartLogic} from '@/componentLibrary/components/charts/StandardTenChart/logic';
import {
    LabelWrapper,
    LabelsWrapper,
    Wrapper
} from '@/componentLibrary/components/charts/StandardTenChart/styled';
import {StandardTenChartProps} from '@/componentLibrary/components/charts/StandardTenChart/types';
import {Badge} from '@/componentLibrary/components/misc/Badge';
import {
    ColorBaseBlue800,
    ColorBaseGreen900,
    ColorBaseYellow900
} from '@/componentLibrary/tokens/variables';

import {BadgeWrapper, ScoresWrapper} from '../StandardTenChartRange/styled';
import {POSSIBLE_RESULT_VALUES} from './constants';

export function StandardTenChart({
    lowerBound,
    upperBound,
    selectedValue,
    showBadge = false,
    hideLabels = false,
    rangeColor = ColorBaseYellow900,
    valueColor = !lowerBound || !upperBound ? ColorBaseGreen900 : ColorBaseBlue800
}: StandardTenChartProps) {
    const {maskId, formattedBlocks} = useStandardTenChartLogic({
        lowerBound,
        upperBound,
        rangeColor,
        selectedValue,
        valueColor
    });

    return (
        <Wrapper>
            {showBadge && (
                <ScoresWrapper>
                    {selectedValue &&
                        POSSIBLE_RESULT_VALUES.map(i => {
                            return (
                                <BadgeWrapper key={i}>
                                    {i === selectedValue ? (
                                        <Badge value={selectedValue} />
                                    ) : null}
                                </BadgeWrapper>
                            );
                        })}
                </ScoresWrapper>
            )}
            <StandardTenChartSvg maskId={maskId} formattedBlocks={formattedBlocks} />
            {!showBadge ||
                (hideLabels && (
                    <LabelsWrapper>
                        {POSSIBLE_RESULT_VALUES.map(result => (
                            <LabelWrapper key={result}>{result}</LabelWrapper>
                        ))}
                    </LabelsWrapper>
                ))}
        </Wrapper>
    );
}
