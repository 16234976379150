import styled from 'styled-components';

import {SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const ContentWrapper = styled.div`
    width: 217px;
`;

export const TextContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;

    > *:nth-child(even) {
        padding-left: ${SpacingXxsmall};
    }
`;
