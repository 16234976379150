import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {personalityDataCollectionLinks} from '../links';

export function useGoToCompletedDataCollectionTest(personalityTestId: string) {
    const history = useHistory();

    return useCallback(() => {
        const url = personalityDataCollectionLinks.completed(personalityTestId);
        history.push(url);
    }, [personalityTestId, history]);
}
