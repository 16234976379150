import {defineMessages} from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.showPreviousResults.cancel'
    },
    shareLogic: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.showPreviousResults.shareLogic'
    },
    title: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.showPreviousResults.title'
    },
    description: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.showPreviousResults.description'
    },
    seeFullReport: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.showPreviousResults.seeFullReport'
    },
    logicTestResults: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.showPreviousResults.logicTestResults'
    }
});
