import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';

import messages from '../../messages';

type Props = {
    isValidationTestRequested: boolean;
    isValidationTestCompleted: boolean;
};

export const TestAdministrationHeader = ({
    isValidationTestRequested,
    isValidationTestCompleted
}: Props) => (
    <HeaderWrapper>
        <FormattedMessage {...messages.testAdministration} />
        <InfoWrapper>
            {isValidationTestRequested && !isValidationTestCompleted ? (
                <StatusWrapper>
                    <FormattedMessage {...messages.waitingForResults} />
                </StatusWrapper>
            ) : null}
        </InfoWrapper>
    </HeaderWrapper>
);

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    line-height: 32px;
`;

const InfoWrapper = styled.div`
    display: flex;
`;

const DescriptionWrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 24px;
    white-space: pre-line;
    color: ${greyColorTokens.grey000()};
`;

const StatusWrapper = styled(DescriptionWrapper)`
    margin: 0 24px 0 0;
    color: ${greyColorTokens.grey400()};
`;
