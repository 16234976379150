import {ReactNode} from 'react';
import styled from 'styled-components';

import {S2} from '@/componentLibrary/components/typography/SubTitles';

type Props = {
    title: string;
    children?: ReactNode;
};

export function Section({title, children}: Props) {
    return (
        <SectionWrapper>
            <S2>{title}</S2>
            {children}
        </SectionWrapper>
    );
}

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
`;
