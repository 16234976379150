import {useApolloClient} from '@apollo/client';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {ValidateResetPasswordToken} from '@/api/types/__generated__/graphql';
import {useValidateResetPasswordToken} from '@/api/users/useValidateResetPasswordToken';
import {useChangeLocale} from '@/containers/LanguageProvider/useChangeLocale';
import {ALVA_TOKEN_KEY} from '@/services/auth/constants';
import {logger} from '@/services/logrocket';

import messages from './messages';

const TOKEN_ERRORS = {
    INVALID_TOKEN: 'INVALID_TOKEN',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR'
};

export function useResetPasswordLogic() {
    const client = useApolloClient();
    const location = useLocation();
    const {locale, changeLocale} = useChangeLocale();
    const [token, setToken] = useState<string | null>(null);
    const [tokenValidated, setTokenValidated] = useState(false);
    const [error, setError] = useState<string>();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const urlObj = new URL(window.location.href);
        const tokenFromUrlParams = urlObj.searchParams.get('t');
        if (!tokenFromUrlParams) {
            setError(TOKEN_ERRORS.INVALID_TOKEN);
            return;
        }
        setToken(tokenFromUrlParams);
    }, [location.search]);

    const onValidToken = (responseData: ValidateResetPasswordToken) => {
        if (responseData.authToken) {
            localStorage.setItem(ALVA_TOKEN_KEY, responseData.authToken);
        }
        client.cache
            .reset()
            .then(() => {
                if (responseData.language !== locale) {
                    changeLocale(responseData.language);
                }
                setTokenValidated(true);
            })
            .catch(logger.error);
    };

    const onError = useCallback(
        (e: string) => {
            if (error !== e) {
                setError(e);
            }
        },
        [error]
    );

    const errorMessage = useMemo(() => {
        if (!error) {
            return null;
        }

        if ([TOKEN_ERRORS.INVALID_TOKEN, TOKEN_ERRORS.TOKEN_EXPIRED].includes(error)) {
            return messages.invalidTokenDescription;
        }

        return messages.unknownErrorDescription;
    }, [error]);

    return {token, tokenValidated, onValidToken, onError, error, errorMessage};
}

export function useValidateResetPasswordTokenLogic(
    onValidToken: (responseToken: ValidateResetPasswordToken) => void,
    onTokenError: (error: string) => void
) {
    const [doValidateToken, {error, loading}] = useValidateResetPasswordToken();

    if (error) {
        onTokenError('INVALID_TOKEN');
    }

    const validateToken = useCallback(
        (token: string) => {
            doValidateToken(token)
                .then(({data}) => {
                    const validateResetPasswordToken = data?.validateResetPasswordToken;
                    if (validateResetPasswordToken?.ok) {
                        onValidToken(validateResetPasswordToken);
                    } else {
                        throw new Error(validateResetPasswordToken?.errorMessage ?? '');
                    }
                })
                .catch(e => {
                    logger.warn(e.message);
                    onTokenError('INVALID_TOKEN');
                });
        },
        [onValidToken, onTokenError, doValidateToken]
    );

    return {validateToken, loading};
}
