import {ColorFgActionTertriaryDefault} from '@/componentLibrary/tokens/variables';

import {Icon} from '../icons/Icon';
import {Icons} from '../icons/constants';
import {ExternalLinkBase} from './styled';
import {ExternalLinkProps} from './types';

export function ExternalLink({
    onClick,
    url,
    noWrap = false,
    children,
    ...rest
}: ExternalLinkProps): JSX.Element {
    return (
        <ExternalLinkBase
            onClick={onClick}
            href={url}
            target="_blank"
            rel="noreferrer noopener"
            $noWrap={noWrap}
            {...rest}
        >
            {children}
            <Icon icon={Icons.OPEN_IN_NEW} size={16} fill={ColorFgActionTertriaryDefault} />
        </ExternalLinkBase>
    );
}
