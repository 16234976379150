import styled from 'styled-components';

import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {Caption} from '@/componentLibrary/components/typography/Captions';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    ColorFgSubtle,
    SpacingSmall,
    SpacingXlarge,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const CustomCaption = styled(Caption)`
    padding-bottom: ${SpacingXxsmall};
    color: ${ColorFgSubtle};
`;

export const Layout = styled(FlexLayout)`
    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column;
        justify-content: center;
    }
`;

export const WideTableCell = styled.div`
    padding-right: ${SpacingXlarge};
    padding-bottom: ${SpacingSmall};
    width: 312px;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        padding: 0 0 ${SpacingSmall};
    }
`;

export const ItalicP2 = styled(P2)`
    font-style: italic;
`;
