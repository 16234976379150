import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.user.jobRecommendations.title'
    },
    otherJobsSectionTitle: {
        id: 'app.jobRecommendations.excerpt.title'
    },
    updateJobProfileDescription: {
        id: 'app.jobRecommendations.excerpt.updateJobProfile.description'
    },
    updateJobProfileButton: {
        id: 'app.jobRecommendations.excerpt.updateJobProfile.button'
    },
    description: {
        id: 'app.pages.user.jobRecommendations.description'
    },
    goToJobOverview: {
        id: 'app.jobRecommendations.excerpt.goToOverview.button'
    },
    goToJobOverviewDescriptionDefault: {
        id: 'app.jobRecommendations.excerpt.goToOverview.description.default'
    },
    goToJobOverviewDescriptionMoreJobs: {
        id: 'app.jobRecommendations.excerpt.goToOverview.description.moreJobs'
    },
    emptyDescription: {
        id: 'app.pages.user.jobRecommendations.emptyDescription'
    },
    error: {
        id: 'app.pages.user.jobRecommendations.error'
    },
    errorAction: {
        id: 'app.pages.user.jobRecommendations.errorAction'
    },
    recommendationSharedTitle: {
        id: 'app.pages.user.jobRecommendations.recommendationSharedTitle'
    },
    recommendationSharedDescription: {
        id: 'app.pages.user.jobRecommendations.recommendationSharedDescription'
    },
    editProfile: {
        id: 'app.pages.user.jobRecommendations.editProfile'
    },
    acceptPolicyTitle: {
        id: 'app.jobRecommendations.excerpt.acceptPolicy.title'
    },
    acceptPolicyDescription: {
        id: 'app.jobRecommendations.excerpt.acceptPolicy.description'
    },
    acceptPolicyButton: {
        id: 'app.jobRecommendations.excerpt.acceptPolicy.button'
    },
    privacyPolicyTitle: {
        id: 'consent.PRIVACY_POLICY.title'
    }
});
