import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    facetCaption: {
        id: 'app.assessment.personalityProfile.facets'
    },
    weightCaption: {
        id: 'app.assessment.personalityProfile.weight.caption'
    }
});
