import {useMutation} from '@apollo/client';
import {useCallback} from 'react';
import {CreateOrContinueValidationLogicTestMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {CREATE_OR_CONTINUE_VALIDATION_LOGIC_TEST_MUTATION} from './mutations';

export function useCreateOrContinueValidationLogicTest(
    token: string
): MutationHookReturnType<CreateOrContinueValidationLogicTestMutation> {
    const [mutate, {error, loading}] = useMutation(
        CREATE_OR_CONTINUE_VALIDATION_LOGIC_TEST_MUTATION
    );

    const createOrContinueTest = useCallback(() => {
        return mutate({variables: {token}});
    }, [mutate, token]);

    return [createOrContinueTest, {error, loading}];
}
