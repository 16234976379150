import {Icon} from '../../../icons/Icon';
import {Icons} from '../../../icons/constants';
import {useToasts} from '../../toast';
import {Toast} from '../../types';
import {CloseButtonWrapper, Content, IconWrapper, ToastWrapper, Wrapper} from './styled';
import {useToastComposition} from './utils';

export const SingleToast = (toast: Toast) => {
    const {cancelToastTimeout, restartToastTimeout, onRemoveToast} = useToasts();
    const ToastComposition = useToastComposition(toast);
    return (
        <ToastWrapper
            key={toast.id}
            onMouseEnter={() => cancelToastTimeout(toast.id)}
            onMouseLeave={() => restartToastTimeout(toast.id)}
        >
            <Wrapper insetColor={ToastComposition.color}>
                <IconWrapper>{ToastComposition.icon}</IconWrapper>
                <Content>{ToastComposition.content}</Content>
                {!toast.hideCloseButton && (
                    <CloseButtonWrapper alignItems="center" justifyContent="center">
                        <Icon
                            icon={Icons.CLOSE}
                            onClick={() => onRemoveToast(toast.id)}
                            isHoverable
                            isAction
                            size={20}
                        />
                    </CloseButtonWrapper>
                )}
            </Wrapper>
        </ToastWrapper>
    );
};
