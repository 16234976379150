import {useCallback} from 'react';

import {RadioButton} from '@/componentLibrary/components/controls/RadioButton';
import {userDemographicsMessages} from '@/pages/UserDemographics/messages';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {LEVELS_OF_EDUCATION} from '@/pages/UserDemographics/components/UserDemographicsForm/components/SelectLevelOfEducation/constants';
import {SelectLevelOfEducationProps} from '@/pages/UserDemographics/components/UserDemographicsForm/components/SelectLevelOfEducation/types';
import {InputField, InputText} from '../styled';

export function SelectLevelOfEducation({
    levelOfEducation,
    setLevelOfEducation
}: SelectLevelOfEducationProps) {
    const phrases = useExtractPhraseConstants(userDemographicsMessages);
    const onLevelOfEducationChange = useCallback(
        value => {
            setLevelOfEducation(value);
        },
        [setLevelOfEducation]
    );

    return (
        <InputField>
            <InputText>{phrases.levelOfEducation}</InputText>
            <RadioButton
                value={LEVELS_OF_EDUCATION.PRIMARY_AND_LOWER_SECONDARY_EDUCATION}
                isSelected={
                    levelOfEducation ===
                    LEVELS_OF_EDUCATION.PRIMARY_AND_LOWER_SECONDARY_EDUCATION
                }
                onSelect={onLevelOfEducationChange}
                label={phrases.primaryAndLowerSecondaryEducation}
                withMargin
            />
            <RadioButton
                value={LEVELS_OF_EDUCATION.UPPER_SECONDARY_EDUCATION_AND_HIGH_SCHOOL}
                isSelected={
                    levelOfEducation ===
                    LEVELS_OF_EDUCATION.UPPER_SECONDARY_EDUCATION_AND_HIGH_SCHOOL
                }
                onSelect={onLevelOfEducationChange}
                label={phrases.upperSecondaryEducationAndHighSchool}
                withMargin
            />
            <RadioButton
                value={LEVELS_OF_EDUCATION.POST_SECONDARY_EDUCATION_LESS_THAN_THREE_YEARS}
                isSelected={
                    levelOfEducation ===
                    LEVELS_OF_EDUCATION.POST_SECONDARY_EDUCATION_LESS_THAN_THREE_YEARS
                }
                onSelect={onLevelOfEducationChange}
                label={phrases.postSecondaryEducationLessThanThreeYears}
                withMargin
            />
            <RadioButton
                value={LEVELS_OF_EDUCATION.POST_SECONDARY_EDUCATION_THREE_YEARS_AND_MORE}
                isSelected={
                    levelOfEducation ===
                    LEVELS_OF_EDUCATION.POST_SECONDARY_EDUCATION_THREE_YEARS_AND_MORE
                }
                onSelect={onLevelOfEducationChange}
                label={phrases.postSecondaryEducationThreeYearsAndMore}
                withMargin
            />
            <RadioButton
                value={LEVELS_OF_EDUCATION.OTHER}
                isSelected={levelOfEducation === LEVELS_OF_EDUCATION.OTHER}
                onSelect={onLevelOfEducationChange}
                label={phrases.otherEducation}
                withMargin
            />
            <RadioButton
                value={LEVELS_OF_EDUCATION.RATHER_NOT_SAY}
                isSelected={levelOfEducation === LEVELS_OF_EDUCATION.RATHER_NOT_SAY}
                onSelect={onLevelOfEducationChange}
                label={phrases.ratherNotSay}
                withMargin
            />
        </InputField>
    );
}
