import {useMemo} from 'react';

import {useOrganization} from '@/api/organizations/useOrganization';
import {S2} from '@/componentLibrary/components/typography';
import {Iframe} from '@/pages/Organization/pages/Settings/pages/EmailTemplates/components/EmailPreview/components/Iframe';
import {emailCss} from '@/pages/Organization/pages/Settings/pages/EmailTemplates/components/EmailPreview/css';

import {EmailSubjectWrapper, Wrapper} from './styled';
import {EmailPreviewProps} from './types';

export function EmailPreview({emailTemplate, preHeader}: EmailPreviewProps) {
    const {organization, loading} = useOrganization();
    const organizationLogoUrl = organization ? organization.logo : undefined;
    const coreOrganizationName = organization ? organization.name : undefined;

    const subject = emailTemplate ? emailTemplate.subject : undefined;
    const title = emailTemplate ? emailTemplate.title : undefined;
    const body = emailTemplate ? emailTemplate.body : undefined;
    const linkText = emailTemplate ? emailTemplate.linkText : undefined;
    const useLogo = emailTemplate ? emailTemplate.useLogo : false;
    const customOrganizationName = emailTemplate ? emailTemplate.organizationName : undefined;
    const showCustomOrganizationName = emailTemplate
        ? emailTemplate.showOrganizationName
        : false;

    const organizationName = useMemo(() => {
        if (showCustomOrganizationName && customOrganizationName) {
            return customOrganizationName;
        }
        return coreOrganizationName;
    }, [showCustomOrganizationName, customOrganizationName, coreOrganizationName]);

    const buildCompanyLogoRow = () => {
        if (useLogo && organizationLogoUrl && !loading) {
            return (
                <tr>
                    <td align="left" className="company-header-logo">
                        <img src={organizationLogoUrl} alt={organizationName + ' logo'} />
                        {showCustomOrganizationName && (
                            <span className={'company-name'}>{organizationName}</span>
                        )}
                    </td>
                </tr>
            );
        }
        return null;
    };

    return (
        <Wrapper>
            <EmailSubjectWrapper>
                <S2>{subject ? subject : '[SUBJECT]'}</S2>
            </EmailSubjectWrapper>

            <Iframe cssStyle={emailCss} style={{width: '100%', height: '760px'}}>
                <div>
                    <span className="preheader">{preHeader ? preHeader : 'PRE HEADER'}</span>
                    <table
                        className="email-wrapper content-table"
                        width="100%"
                        cellPadding="0"
                        cellSpacing="0"
                    >
                        <tbody>
                            <tr className={'alva-header'}>
                                <td align="center">
                                    <table
                                        className="email-header content-table"
                                        width="100%"
                                        cellPadding="0"
                                        cellSpacing="0"
                                    >
                                        <tbody>
                                            {buildCompanyLogoRow()}
                                            <tr>
                                                <td
                                                    align="center"
                                                    className={'job-name-title'}
                                                >
                                                    {title ? title : '[TITLE]'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    align="center"
                                                    className="alva-header-logo"
                                                >
                                                    <span className="email-header-logo">
                                                        <img
                                                            src="https://production.static-app.alvalabs.io/assets/alva_brand_signature_black2.png"
                                                            alt="Alva Logo"
                                                        />
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td align="center">
                                    <table
                                        className="email-content content-table"
                                        width="100%"
                                        cellPadding="0"
                                        cellSpacing="0"
                                    >
                                        <tbody>
                                            <tr>
                                                <td className="email-body">
                                                    <table
                                                        className="email-body_inner content-table"
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                        style={{margin: '0 auto'}}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td className="content-cell">
                                                                    <p
                                                                        className="main-paragraph"
                                                                        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: body
                                                                                ? body
                                                                                : '[BODY]'
                                                                        }}
                                                                    ></p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td
                                                                    align="center"
                                                                    valign="top"
                                                                >
                                                                    <table
                                                                        cellPadding="0"
                                                                        cellSpacing="0"
                                                                        className="container"
                                                                        width="620"
                                                                        style={{
                                                                            margin: '0 0 4px 0',
                                                                            border: 0
                                                                        }}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="share-tests-cell"></td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="button">
                                                                        {linkText
                                                                            ? linkText
                                                                            : '[LINK TEXT]'}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr className={'alva-company-details'}>
                                                <td align="center">
                                                    <table
                                                        className="email-boilerplate content-table"
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                        style={{margin: '0 auto'}}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td align="left">
                                                                    <p>&copy; Alva Labs AB.</p>
                                                                    <p>
                                                                        Luntmakargatan 66, 113
                                                                        51, Stockholm
                                                                    </p>
                                                                    <p>Org. nr. 559103-8178</p>
                                                                    <div>
                                                                        <p>
                                                                            You are receiving
                                                                            this email since
                                                                            you have been
                                                                            invited to Alva
                                                                            Labs.
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center">
                                                    <table
                                                        className="email-footer content-table"
                                                        width="100%"
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td
                                                                    align="center"
                                                                    className="email-footer-logo"
                                                                >
                                                                    <img
                                                                        src="https://production.static-app.alvalabs.io/assets/alva_brand_signature_black2.png"
                                                                        alt="Alva Logo"
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Iframe>
        </Wrapper>
    );
}
