import {gql} from '@/api/types/__generated__';

export const GET_EMAIL_TEMPLATES_FOR_ORGANIZATION = gql(`
    query getEmailTemplatesForOrganization {
        authenticatedOrganization {
            id
            emailTemplates {
                ...EmailTemplateFields
            }
        }
    }
`);

export const GET_EMAIL_TEMPLATE = gql(`
    query getEmailTemplate($id: Int!) {
        emailTemplate(id: $id) {
            ...EmailTemplateFields
        }
    }
`);

export const GET_DEFAULT_EMAIL_TEMPLATES_FOR_ORGANIZATION = gql(`
    query getDefaultEmailTemplatesForOrganization {
        authenticatedOrganization {
            id
            logo
            aggregateVersion
            defaultInviteCandidateEmailTemplate {
                ...EmailTemplateFields
            }
            defaultEnableAutomatedCandidateReminders
            defaultAutomatedRemindersIntervalFrequency
            defaultCandidateReminderEmailTemplate {
                ...EmailTemplateFields
            }
        }
    }
`);

export const GET_DEFAULT_EMAIL_TEMPLATE = gql(`
    query getDefaultEmailTemplate($locale: String!, $emailType: String!) {
        defaultEmailTemplate(locale: $locale, emailType: $emailType) {
            id
            emailType
            title
            subject
            body
            linkText
            locale
        }
    }
`);
