import {DisplaySkeletonSmaller} from '@/componentLibrary/components/skeleton/DisplaySkeleton';
import {ColorBaseGrey200} from '@/componentLibrary/tokens/variables';

import {ActionWrapper, Description, HeaderWrapper, TimeEstimate, Wrapper} from '../styled';

export const LoadingState = () => (
    <Wrapper>
        <HeaderWrapper>
            <DisplaySkeletonSmaller style={{width: 200}} />
        </HeaderWrapper>
        <TimeEstimate style={{height: 20, width: 150, backgroundColor: ColorBaseGrey200}} />
        <Description style={{height: 40, backgroundColor: ColorBaseGrey200}} />
        <ActionWrapper>
            <div style={{height: 40, width: 100, backgroundColor: ColorBaseGrey200}} />
        </ActionWrapper>
    </Wrapper>
);
