import {gql} from '@/api/types/__generated__';

export const REGISTER_USER = gql(`
    mutation registerUser($data: RegisterUserInput!, $token: String!) {
        registerUser(data: $data, token: $token) {
            ok
            errorMessage
            authToken
        }
    }
`);

export const REGISTER_USER_AND_GIVE_CONSENTS = gql(`
    mutation registerUserAndGiveConsents(
        $data: RegisterUserAndGiveConsentsInput!
        $token: String!
    ) {
        registerUserAndGiveConsents(data: $data, token: $token) {
            ok
            errorMessage
            authToken
        }
    }
`);

export const VALIDATE_SIGNUP_TOKEN = gql(`
    mutation validateSignupToken($token: String!) {
        validateSignupToken(token: $token) {
            ok
            errorMessage
            userId
            email
            language
            firstName
            lastName
            enforcedAuthenticationMethod
            registrationType
            organizationOktaConfiguration {
                domain
                clientId
            }
        }
    }
`);
