import {useMutation} from '@apollo/client';

import {
    SyncChallengeScorecardMutation,
    SyncChallengeScorecardMutationVariables
} from '@/api/types/__generated__/graphql';

import {MutationHookReturnType} from '../../types/genericApi/types';
import {SYNC_CHALLENGE_SCORECARD} from './mutations';

export function useSyncChallengeScorecard(): MutationHookReturnType<
    SyncChallengeScorecardMutation,
    [SyncChallengeScorecardMutationVariables]
> {
    const [mutate, {error, loading}] = useMutation(SYNC_CHALLENGE_SCORECARD);

    const doUpdate = ({assignmentId}: SyncChallengeScorecardMutationVariables) => {
        const variables = {assignmentId};
        return mutate({variables});
    };

    return [doUpdate, {error, loading}];
}
