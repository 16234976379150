import {useCallback} from 'react';

import {useInitializeChallengeAssignment} from '@/api/assessment/codingTests/useInitializeChallengeAssignment';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {ApolloError} from '@apollo/client';
import {UseInitChallengeAssignmentProps} from '../types';

export const useInitChallengeAssignment = ({
    assignmentId
}: UseInitChallengeAssignmentProps) => {
    const [doInitializeChallengeAssignment, {loading}] =
        useInitializeChallengeAssignment(assignmentId);

    const initChallengeAssignment = useCallback(
        async ({enableManualSubmission}: {enableManualSubmission?: boolean}) => {
            try {
                const {data} = await doInitializeChallengeAssignment({enableManualSubmission});
                if (!data?.initializeChallengeAssignment?.ok) {
                    throw new Error(
                        data?.initializeChallengeAssignment?.errorMessage || 'Error'
                    );
                }
            } catch (e) {
                const errorMessage = String((e as ApolloError).message);
                toast({type: 'error', message: errorMessage});
                return {isError: true};
            }
        },
        [doInitializeChallengeAssignment]
    );
    return {initChallengeAssignment, loading};
};
