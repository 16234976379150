import {defineMessages} from 'react-intl';

export default defineMessages({
    withdrawApplications: {
        id: 'app.user.candidateSettings.userOrganizationSettings.withdrawApplications'
    },
    withdrawnAt: {
        id: 'app.user.candidateSettings.userOrganizationSettings.withdrawnAt'
    },
    acceptedAt: {
        id: 'app.user.candidateSettings.userOrganizationSettings.acceptedAt'
    }
});
