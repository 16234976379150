import {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import {City} from '@/api/types/__generated__/graphql';
import {
    COMPANY_TYPES_KEY,
    JOB_FAMILIES_KEY,
    LANGUAGES_KEY,
    WORK_LOCATIONS_KEY
} from '@/pages/User/components/RecommendationRelevanceForm/constants';
import {RecommendationRelevanceFormProps} from '@/pages/User/components/RecommendationRelevanceForm/types';

import {GetSelectableJobFamilySpecialities} from '../types';
import {sortOptionsByName} from '../utils';

export function useFormOptions(
    relevanceParametersOptions: RecommendationRelevanceFormProps['relevanceParametersOptions']
) {
    const intl = useIntl();
    const companyTypes = useMemo(() => {
        if (!relevanceParametersOptions || !relevanceParametersOptions.companyTypes) {
            return [];
        }

        const _companyTypes = relevanceParametersOptions.companyTypes as string[];
        return _companyTypes.map((companyType: string) => ({
            id: companyType,
            name: intl.formatMessage({id: `${COMPANY_TYPES_KEY}.${companyType}`})
        }));
    }, [relevanceParametersOptions, intl]);

    const languages = useMemo(() => {
        if (!relevanceParametersOptions || !relevanceParametersOptions.languages) {
            return [];
        }

        const _languages = relevanceParametersOptions.languages as string[];
        return _languages.map((language: string) => ({
            id: language,
            name: intl.formatMessage({id: `${LANGUAGES_KEY}.${language}`})
        }));
    }, [relevanceParametersOptions, intl]);

    const cities: City[] = useMemo(() => {
        if (!relevanceParametersOptions || !relevanceParametersOptions.newCities) {
            return [];
        }
        const _newCities = relevanceParametersOptions.newCities;
        return _newCities.map(city => ({
            __typename: 'City',
            id: city.id,
            name: `${city.name}, ${city.countryName}`,
            countryName: city.countryName
        }));
    }, [relevanceParametersOptions]);

    const jobFamilies = useMemo(() => {
        if (!relevanceParametersOptions || !relevanceParametersOptions.jobFamilies) {
            return [];
        }
        const _jobFamilies = relevanceParametersOptions.jobFamilies;
        const _jobFamiliesOptions = _jobFamilies.map(family => ({
            id: family.id,
            name: intl.formatMessage({id: `${JOB_FAMILIES_KEY}.${family.id}`})
        }));
        return _jobFamiliesOptions.sort(sortOptionsByName);
    }, [relevanceParametersOptions, intl]);

    const getSelectableJobFamilySpecialities: GetSelectableJobFamilySpecialities = useCallback(
        (family: string) => {
            if (
                !family ||
                !relevanceParametersOptions ||
                !relevanceParametersOptions.jobFamilies
            ) {
                return [];
            }

            const _jobFamilies = relevanceParametersOptions.jobFamilies;

            const jobFamily = _jobFamilies.find(jf => jf.id === family);
            const _specialities = jobFamily?.specialities ?? [];
            const _specialitiesOptions = _specialities
                .filter(
                    (speciality: string | null): speciality is string => speciality !== null
                )
                .map((speciality: string) => ({
                    id: speciality,
                    name: intl.formatMessage({
                        id: `${JOB_FAMILIES_KEY}.${family}.${speciality}`
                    })
                }));
            return _specialitiesOptions.sort(sortOptionsByName);
        },
        [relevanceParametersOptions, intl]
    );

    const workLocations = useMemo(() => {
        if (!relevanceParametersOptions || !relevanceParametersOptions.workLocations) {
            return [];
        }

        const _workLocations = relevanceParametersOptions.workLocations as string[];
        return _workLocations.map((workLocation: string) => ({
            id: workLocation,
            name: intl.formatMessage({id: `${WORK_LOCATIONS_KEY}.${workLocation}`})
        }));
    }, [relevanceParametersOptions, intl]);

    return {
        companyTypes,
        languages,
        cities,
        jobFamilies,
        getSelectableJobFamilySpecialities,
        workLocations
    };
}
