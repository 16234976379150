import {ChangeEvent, useState} from 'react';
import {useIntl} from 'react-intl';

import {useActiveJobRecommendationState} from '../../context';
import messages from './messages';
import {validateLinkedinProfileUrl} from './utils';

export function useSubmitResumeLogic(onRightAction: () => void) {
    const phrases = useSubmitResumePhrases();
    const {linkedinUrl, setLinkedinUrl} = useActiveJobRecommendationState();
    const [invalidLinkedinUrl, setInValidLinkedinUrl] = useState<boolean | null>(null);
    const hasError = !!invalidLinkedinUrl;

    const handleContinue = () => {
        if (!validateLinkedinProfileUrl(linkedinUrl)) {
            setInValidLinkedinUrl(true);
            return;
        }
        onRightAction();
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setLinkedinUrl(value);
        if (validateLinkedinProfileUrl(value)) {
            setInValidLinkedinUrl(false);
        }
    };

    return {
        ...phrases,
        hasError,
        handleChange,
        handleContinue,
        linkedinUrl,
        invalidLinkedinUrl
    };
}

function useSubmitResumePhrases() {
    const intl = useIntl();
    const titleText = intl.formatMessage(messages.submitResumeTitle);
    const continueText = intl.formatMessage(messages.continue);
    const label = intl.formatMessage(messages.label);
    const linkLabel = intl.formatMessage(messages.linkLabel);
    const errorNoUrl = intl.formatMessage(messages.errorNoUrl);
    const errorInvalidUrl = intl.formatMessage(messages.errorInvalidUrl);
    const giveFeedback = intl.formatMessage(messages.giveFeedback);

    return {
        titleText,
        continueText,
        label,
        linkLabel,
        giveFeedback,
        errorNoUrl,
        errorInvalidUrl
    };
}
