import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    ColorBaseBlue600,
    ColorBaseGreen600,
    ColorBaseRed600,
    ColorBaseYellow700,
    ColorBgAttention,
    ColorBgDanger,
    ColorBgInfo,
    ColorBgInset,
    ColorBgSubtle,
    ColorBgSuccess,
    SpacingMedium,
    SpacingSmall,
    SpacingXlarge
} from '@/componentLibrary/tokens/variables';

import {BannerType} from '../constants';
import {BannerProps} from '../types';

const COLOR_MAP = {
    [BannerType.PLAIN]: {background: ColorBgSubtle, bannerColor: ColorBgInset},
    [BannerType.ATTENTION]: {background: ColorBgAttention, bannerColor: ColorBaseYellow700},
    [BannerType.SUCCESS]: {background: ColorBgSuccess, bannerColor: ColorBaseGreen600},
    [BannerType.INFO]: {background: ColorBgInfo, bannerColor: ColorBaseBlue600},
    [BannerType.ERROR]: {background: ColorBgDanger, bannerColor: ColorBaseRed600}
};

const getBannerBgColor = (type?: BannerType) => {
    return type ? COLOR_MAP[type].background : COLOR_MAP[BannerType.INFO].background;
};

const getProminentBannerColor = (type?: BannerType) => {
    return type ? COLOR_MAP[type].bannerColor : COLOR_MAP[BannerType.INFO].bannerColor;
};

type PromoBannerProps = BannerProps & {
    shouldAlignContentInCenter?: boolean;
};

export const Wrapper = styled.div<PromoBannerProps>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 213px;
    background: ${({type}) => getBannerBgColor(type)};

    ${({shouldAlignContentInCenter}) =>
        shouldAlignContentInCenter ? 'align-items: center;' : ''}

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        background: radial-gradient(
            circle at right,
            ${({type}) => getProminentBannerColor(type)} 20%,
            ${({type}) => getBannerBgColor(type)} 32%,
            ${({type}) => getBannerBgColor(type)} 10%
        );
    }
`;

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingMedium};
    justify-content: space-between;
    padding: ${SpacingXlarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        padding: ${SpacingSmall};
        flex-grow: 1;
    }
`;

export const MessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    gap: ${SpacingMedium};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        display: flex;
        flex-direction: column;

        * {
            width: 100%;
        }
    }
`;

export const ProminentBannerWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        display: none;
    }
`;

export const BannerBlurSquare = styled.div<BannerProps>`
    position: absolute;
    width: 213px;
    height: 100%;
    backdrop-filter: blur(25px);
`;

export const ProminentBannerCircle = styled.div<BannerProps>`
    width: 181px;
    height: 181px;
    right: 125px;
    border-radius: 50%;
    margin: ${SpacingSmall} 125px ${SpacingSmall} 0;
    background: ${({type}) => getProminentBannerColor(type)};
`;
