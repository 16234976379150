import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {VerifyImpersonationTokenMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {VERIFY_IMPERSONATION_TOKEN} from './queries';

export function useVerifyImpersonationToken(): MutationHookReturnType<
    VerifyImpersonationTokenMutation,
    string
> {
    const [mutate, {loading, error}] = useMutation(VERIFY_IMPERSONATION_TOKEN);

    const verifyImpersonationToken = useCallback(
        (token: string) => mutate({variables: {token}}),
        [mutate]
    );
    return [verifyImpersonationToken, {loading, error}];
}
