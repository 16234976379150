import {SpacingXsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';
import {AssessmentProfileOverview} from './components/AssessmentProfileOverview';
import {AssessmentResults} from './components/AssessmentResults';
import {RoleFitContribution} from './components/RoleFitContribution';
import {RoleFitOverview} from './components/RoleFitOverview';

type Props = {
    userId: number;
    jobPositionId: number;
};

export function CandidateRoleFitPage({userId, jobPositionId}: Props) {
    return (
        <Wrapper>
            <RoleFitOverview userId={userId} jobPositionId={jobPositionId} />
            <AssessmentProfileOverview jobPositionId={jobPositionId} />
            <AssessmentResults userId={userId} jobPositionId={jobPositionId} />
            <RoleFitContribution userId={userId} jobPositionId={jobPositionId} />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-top: ${SpacingXsmall};
`;
