import {gql} from '../types/__generated__';

export const UPDATE_ORGANIZATION_MUTATION = gql(`
    mutation updateOrganization(
        $id: Int!
        $expectedVersion: Int!
        $data: OrganizationUpdateInput!
    ) {
        updateOrganization(
            organizationId: $id
            expectedVersion: $expectedVersion
            organizationData: $data
        ) {
            ok
            errorMessage
            organization {
                id
                aggregateVersion
                name
                logo
                defaultLanguage
                externalPrivacyPolicyUrl
                securityPointOfContactEmail
                emailSenderSignature
            }
        }
    }
`);

export const SET_DEFAULT_TEST_SETTINGS = gql(`
    mutation setDefaultTestSettings(
        $organizationId: Int!
        $enableLogicTest: Boolean!
        $enablePersonalityTest: Boolean!
        $expectedVersion: Int!
    ) {
        setDefaultTestSettings(
            organizationId: $organizationId
            enableLogicTest: $enableLogicTest
            enablePersonalityTest: $enablePersonalityTest
            expectedVersion: $expectedVersion
        ) {
            ok
            errorMessage
            organization {
                id
                aggregateVersion
                enableLogicTestForNewJobPositions
                enablePersonalityTestForNewJobPositions
            }
        }
    }
`);

export const SET_DEFAULT_PUBLIC_PAGE_CONTENT = gql(`
    mutation setDefaultPublicPageContent(
        $publicPageTitle: String
        $publicPageInstructions: String
        $expectedVersion: Int!
    ) {
        setDefaultPublicPageContent(
            publicPageTitle: $publicPageTitle
            publicPageInstructions: $publicPageInstructions
            expectedVersion: $expectedVersion
        ) {
            ok
            errorMessage
            organization {
                id
                aggregateVersion
                defaultPublicPageTitle
                defaultPublicPageInstructions
            }
        }
    }
`);

export const SET_ORGANIZATION_AUTHENTICATION_SETTINGS = gql(`
    mutation setOrganizationAuthenticationSettings(
        $enforcedAuthenticationMethod: AuthenticationMethod
        $expectedVersion: Int!
    ) {
        setOrganizationAuthenticationSettings(
            enforcedAuthenticationMethod: $enforcedAuthenticationMethod
            expectedVersion: $expectedVersion
        ) {
            ok
            errorMessage
            organization {
                id
                enforcedAuthenticationMethod
                aggregateVersion
            }
        }
    }
`);

export const SET_ONBOARDING_STATUS = gql(`
    mutation updateOrganizationOnboardingStatus(
        $organizationId: String!
        $onboardingStatus: String!
        $expectedVersion: Int!
    ) {
        updateOrganizationOnboardingStatus(
            organizationId: $organizationId
            onboardingStatus: $onboardingStatus
            expectedVersion: $expectedVersion
        ) {
            ok
            errorMessage
            organization {
                id
                aggregateVersion
                onboardingStatus
            }
        }
    }
`);

export const GENERATE_CUSTOMER_PORTAL_LINK_FOR_ORGANIZATION = gql(`
    mutation generateCustomerPortalLinkForOrganization(
        $organizationId: String!
        $stripeCustomerId: String
    ) {
        generateCustomerPortalLinkForOrganization(
            organizationId: $organizationId
            stripeCustomerId: $stripeCustomerId
        ) {
            ok
            errorMessage
            stripePortalUrl
        }
    }
`);

export const GENERATE_CHECKOUT_SESSION_LINK_FOR_ORGANIZATION = gql(`
    mutation generateCheckoutSessionLinkForOrganization(
        $organizationId: String!
        $name: String
        $successUrl: String!
        $cancelUrl: String!
        $stripeCustomerId: String
        $upgradePlan: String
    ) {
        generateCheckoutSessionLinkForOrganization(
            organizationId: $organizationId
            name: $name
            successUrl: $successUrl
            cancelUrl: $cancelUrl
            stripeCustomerId: $stripeCustomerId
            upgradePlan: $upgradePlan
        ) {
            ok
            errorMessage
            stripeSessionUrl
        }
    }
`);

export const REQUEST_SUBSCRIPTION_UPGRADE_FOR_ORGANIZATION = gql(`
    mutation requestSubscriptionUpgradeForOrganization(
        $organizationId: String!
        $newPlan: String
        $includedActiveJobPositions: Int!
    ) {
        requestSubscriptionUpgradeForOrganization(
            organizationId: $organizationId
            newPlan: $newPlan
            includedActiveJobPositions: $includedActiveJobPositions
        ) {
            ok
            errorMessage
        }
    }
`);

export const UPDATE_COUNTRY_RETENTION_PERIOD_FOR_ORGANIZATION = gql(`
    mutation UpdateCountryRetentionPeriodForOrganization(
        $updateCountryRetentionPeriodForOrganizationInput: UpdateCountryRetentionPeriodForOrganizationInput!
    ) {
        updateCountryRetentionPeriodForOrganization(
            updateCountryRetentionPeriodForOrganizationInput: $updateCountryRetentionPeriodForOrganizationInput
        ) {
            ok
            errorMessage
            organizationId
        }
    }
`);

export const UPDATE_ORGANIZATION_CONFIGURATION_MUTATION = gql(`
    mutation UpdateOrganizationConfiguration(
        $organizationId: Int!
        $expectedVersion: Int!
        $data: OrganizationUpdateConfigurationInput!
    ) {
        updateOrganizationConfiguration(
            organizationId: $organizationId
            expectedVersion: $expectedVersion
            organizationData: $data
        ) {
            ok
            errorMessage
            organization {
                id
                aggregateVersion
                name
            }
        }
    }
`);

export const UPDATE_DEFAULT_PUBLIC_PAGE_SETTINGS = gql(`
    mutation UpdateDefaultPublicPageSettings(
        $updateDefaultPublicPageSettingsInput: UpdateDefaultPublicPageSettingsInput!
    ) {
        updateDefaultPublicPageSettings(
            updateDefaultPublicPageSettingsInput: $updateDefaultPublicPageSettingsInput
        ) {
            ok
            errorMessage
            organizationId
            defaultPublicPageTitle
            defaultPublicPageInstructions
            publicPageNameEnabled
            publicPagePrivacyNoticeEnabled
            publicPageColorHeader
            publicPageColorHeaderEnabled
            publicPageLogoEnabled
            name
        }
    }
`);

export const UPDATE_DEFAULT_ASSESSMENT_WEIGHTS = gql(`
    mutation UpdateDefaultAssessmentWeights($data: UpdateDefaultAssessmentWeightsInput!) {
        updateDefaultAssessmentWeights(data: $data) {
            errorMessage
            ok
        }
    }
`);
