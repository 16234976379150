import {ApolloError} from '@apollo/client';
import styled from 'styled-components';

import {JobRecommendationItem} from '@/pages/User/components/JobRecommendations/components/JobRecommendationItem';

import {SpacingMedium} from '@/componentLibrary/tokens/variables';
import {SetActiveJobRecommendation} from '../../types';
import {JobRecommendationsOverviewProps} from '../JobRecommendationsOverview/types';

type Props = {
    loading: boolean;
    error: ApolloError | undefined;
    pendingRecommendations: JobRecommendationsOverviewProps['pendingRecommendations'];
    setActive: SetActiveJobRecommendation;
};

export function JobRecommendationList({
    loading,
    error,
    pendingRecommendations,
    setActive
}: Props) {
    return (
        <RecommendationListWrapper>
            {!loading &&
                !error &&
                pendingRecommendations.map(item => (
                    <JobRecommendationItem
                        key={item.id}
                        jobRecommendation={item}
                        setActive={setActive}
                    />
                ))}
        </RecommendationListWrapper>
    );
}

const RecommendationListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${SpacingMedium};
`;
