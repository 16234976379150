import {OneDimensionalBar} from '@/componentLibrary/components/charts/OneDimensionalBar';
import {Caption} from '@/componentLibrary/components/typography';
import {ColorBaseGrey700} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {scoreBarMessages} from '../messages';
import {getScoreInPercentage, renderScore, renderText, scoreExists} from './functions';
import {InfoWrapper, ScoreWrapper} from './styled';
import {ScoreBarProps} from './types';

export const ScoreBar = ({
    score,
    title,
    barColor,
    isLargeTitle = false,
    byFive = false
}: ScoreBarProps) => {
    const phrases = useExtractPhraseConstants(scoreBarMessages);

    return (
        <>
            <InfoWrapper>
                {renderText(title, isLargeTitle)}
                <ScoreWrapper>
                    {scoreExists(score) ? (
                        renderScore(score as number, byFive, isLargeTitle)
                    ) : (
                        <Caption color={ColorBaseGrey700}>{phrases.noResult}</Caption>
                    )}
                </ScoreWrapper>
            </InfoWrapper>
            {scoreExists(score) && (
                <OneDimensionalBar
                    scoreInPercentage={getScoreInPercentage(score as number)}
                    color={barColor}
                />
            )}
        </>
    );
};
