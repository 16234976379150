import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.login.loginForm.title'
    },
    email: {
        id: 'app.login.loginForm.email'
    },
    emailPlaceholder: {
        id: 'app.login.loginForm.emailPlaceholder'
    },
    password: {
        id: 'app.login.loginForm.password'
    },
    login: {
        id: 'app.login.loginForm.login'
    },
    recover: {
        id: 'app.login.loginForm.recover'
    },
    missingRequiredFields: {
        id: 'app.login.loginForm.missingRequiredFields'
    },
    success: {
        id: 'app.login.loginForm.success'
    },
    or: {
        id: 'app.signup.createAccountForm.or'
    }
});

export const errorMessages = defineMessages({
    UNKNOWN: {
        id: 'app.login.loginForm.UNKNOWN'
    },
    INVALID_PASSWORD: {
        id: 'app.login.loginForm.INVALID_PASSWORD'
    },
    INVALID_EMAIL_FORMAT: {
        id: 'app.login.loginForm.INVALID_EMAIL_FORMAT'
    },
    INVALID_CREDENTIALS: {
        id: 'app.login.loginForm.INVALID_CREDENTIALS'
    },
    NO_PASSWORD: {
        id: 'app.login.loginForm.NO_PASSWORD'
    },
    NO_EMAIL: {
        id: 'app.login.loginForm.NO_EMAIL'
    },
    NO_SUCH_USER: {
        id: 'app.login.loginForm.NO_SUCH_USER'
    },
    INVALID_ID_TOKEN: {
        id: 'app.login.loginForm.INVALID_ID_TOKEN'
    },
    USER_ALREADY_ACTIVATED: {
        id: 'app.login.loginForm.USER_ALREADY_ACTIVATED'
    },
    USER_IS_NOT_ACTIVATED: {
        id: 'app.login.loginForm.USER_NOT_ACTIVATED'
    },
    havingTroubleLoggingIn: {
        id: 'app.login.loginForm.havingTroubleLoggingIn'
    },
    clickToResetPassword: {
        id: 'app.login.loginForm.clickToResetPassword'
    },
    somethingWentWrong: {
        id: 'app.login.loginForm.somethingWentWrong'
    }
});
