import {IntlShape} from 'react-intl';

import {FacetName, Weight} from '@/api/types/__generated__/graphql';
import {
    MessageStructure,
    PERSONALITY_FACET_MESSAGES
} from '@/componentLibrary/blocks/testReports/PersonalityTest/components/FactorFacets/constants';
import {PERSONALITY_IRT_FACET_COLORS} from '@/componentLibrary/blocks/testReports/PersonalityTest/constants';
import {ColorBaseGrey200} from '@/componentLibrary/tokens/variables';
import {WEIGHT_MESSAGES} from '@/pages/Organization/pages/Assessment/components/AssessmentSelector/components/PersonalityProfileDetailsModal/messages';

export const getFacetFullName = (intl: IntlShape, facet?: FacetName | null) => {
    const facetMessages = facet
        ? (PERSONALITY_FACET_MESSAGES as {[facet: string]: MessageStructure})[facet]
        : null;

    if (!facetMessages) {
        return '';
    }

    return `${intl.formatMessage(facetMessages.factor)}: ${intl.formatMessage(
        facetMessages.title
    )}`;
};

export const getFacetWeightMessage = (intl: IntlShape, weight?: Weight | null) => {
    if (!weight) {
        return '';
    }
    const messageMap = {
        [Weight.VERY_LOW]: WEIGHT_MESSAGES.veryLow,
        [Weight.LOW]: WEIGHT_MESSAGES.low,
        [Weight.MEDIUM]: WEIGHT_MESSAGES.medium,
        [Weight.HIGH]: WEIGHT_MESSAGES.high,
        [Weight.VERY_HIGH]: WEIGHT_MESSAGES.veryHigh
    };

    const message = messageMap[weight];
    return intl.formatMessage(message);
};

export const getFacetColor = (facet?: FacetName | null) => {
    const defaultColor = ColorBaseGrey200;
    const facetColor = facet
        ? (PERSONALITY_IRT_FACET_COLORS as {[facet: string]: string})[facet]
        : null;
    return facetColor ? facetColor : defaultColor;
};

export const convertFacetWeightToValue = (weight?: string | null) => {
    switch (weight) {
        case Weight.VERY_LOW:
            return 1;
        case Weight.LOW:
            return 2;
        case Weight.MEDIUM:
            return 3;
        case Weight.HIGH:
            return 4;
        case Weight.VERY_HIGH:
            return 5;
        default:
            return 0;
    }
};
