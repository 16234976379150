import {rejectReasonMessages} from './components/RejectJobRecommendationBody/messages';

type MessageKeys = keyof typeof rejectReasonMessages;

export const REJECT_REASON_OPTIONS: {[key in MessageKeys]: MessageKeys} = {
    DO_NOT_WANT_TO_WORK_AT_THIS_COMPANY: 'DO_NOT_WANT_TO_WORK_AT_THIS_COMPANY',
    DO_NOT_HAVE_THE_EXPERIENCE_NEEDED_FOR_THIS_ROLE:
        'DO_NOT_HAVE_THE_EXPERIENCE_NEEDED_FOR_THIS_ROLE',
    DO_NOT_HAVE_THE_EDUCATION_NEEDED_FOR_THIS_ROLE:
        'DO_NOT_HAVE_THE_EDUCATION_NEEDED_FOR_THIS_ROLE',
    DO_NOT_WANT_TO_WORK_IN_THIS_ROLE: 'DO_NOT_WANT_TO_WORK_IN_THIS_ROLE',
    OTHER_REASON: 'OTHER_REASON'
};
