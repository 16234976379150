type OptionalFormattingOptions = Exclude<
    FormattingOptions,
    [FormattingOptions.INDENT_DECREASE, FormattingOptions.INDENT_DECREASE]
>;

export type TextEditorProps = {
    id?: string;
    label?: string;
    content?: string;
    errorMessage?: string;
    required?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    minHeight?: number;
    resizable?: boolean;
    showContentOnly?: boolean;
    disabledFormattingOptions?: OptionalFormattingOptions[];
    onChange?: (value: string) => void;
};

export enum FormattingOptions {
    BOLD = 'BOLD',
    ITALIC = 'ITALIC',
    UNDERLINE = 'UNDERLINE',
    CODE = 'CODE',
    CODE_BLOCK = 'CODE_BLOCK',
    BULLET = 'BULLET',
    NUMBER = 'NUMBER',
    LINK = 'LINK',
    INDENT_DECREASE = 'INDENT_DECREASE',
    INDENT_INCREASE = 'INDENT_INCREASE'
}

export type LogicProps = {
    content: string;
    disabledFormattingOptions: FormattingOptions[];
    showContentOnly: boolean;
    onChange?: (value: string) => void;
};

export type FormattingOptionsKey = keyof typeof FormattingOptions;
export type ExtensionsEnabledMap = {[K in OptionalFormattingOptions]?: boolean};
export type ExtensionsOnClickMap = {
    [K in FormattingOptionsKey]?: (event: React.MouseEvent) => void;
};
