import {useCallback} from 'react';

import {Tag} from '@/componentLibrary/components/Tag';
import {TagVariant} from '@/componentLibrary/components/Tag/constants';
import {Tooltip} from '@/componentLibrary/components/Tooltips/Tooltip';
import {PlainAnchorButton} from '@/componentLibrary/components/buttons/PlainButton';
import {CardTypes} from '@/componentLibrary/components/cards/AssessmentCard/constants';
import {useAssessmentCardLogic} from '@/componentLibrary/components/cards/AssessmentCard/logic';
import {
    ActionWrapper,
    AdditionalTitleText,
    Attribution,
    BorderStripe,
    Container,
    ContentWrapper,
    Details,
    DetailsAndAction,
    DetailsWrapper,
    IconPlaceholder,
    Label,
    LeftWrapper,
    MainTitle,
    NoGap,
    Recommendation,
    TextWrapper,
    Titles
} from '@/componentLibrary/components/cards/AssessmentCard/styled';
import {AssessmentCardProps} from '@/componentLibrary/components/cards/AssessmentCard/types';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Caption, P2, S1, S2} from '@/componentLibrary/components/typography';
import {ConditionalWrapper} from '@/componentLibrary/misc/ConditionalWrapper';
import {ColorFgMuted, ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {AssessmentsRecommendationAssessmentType} from '@/api/types/__generated__/graphql';
import {SecondaryButton} from '../../buttons/SecondaryButton';
import {AssessmentWeight} from './components/AssessmentWeight';
import {RecommendedCard} from './components/RecommendedCard';
import {getRecommendationCardInfo} from './components/getRecommendationCardInfo';
import {PlainButtonEdit} from './components/styled';
import messages from './messages';

export const AssessmentCard = (props: AssessmentCardProps) => {
    const {
        title,
        additionalTitleText,
        description,
        caption,
        tagText,
        attribution,
        assessmentLabel,
        categoryLabel,
        iconTooltip,
        onSelect,
        isSelected,
        cardType,
        link,
        actionMenuItems,
        customActionButton,
        footer,
        footerActions,
        stageSelector,
        customLeftContent,
        recommendation
    } = props;
    const {color, actionArea, hasHoverableIcon, icon} = useAssessmentCardLogic({
        isSelected,
        cardType,
        link,
        actionMenuItems,
        customActionButton
    });

    const phrases = useExtractPhraseConstants(messages);

    const getLeftContent = useCallback(() => {
        if (customLeftContent) {
            return customLeftContent;
        }
        if (cardType === CardTypes.NO_ICON) {
            return <IconPlaceholder />;
        }

        return (
            <ConditionalWrapper
                wrapper={children => (
                    <Tooltip
                        content={<div>{iconTooltip}</div>}
                        placement="left"
                        trigger={children}
                    />
                )}
                condition={hasHoverableIcon && !!iconTooltip}
            >
                <Icon
                    icon={icon}
                    fill={color}
                    hoverFill={color}
                    onClick={onSelect}
                    isHoverable
                />
            </ConditionalWrapper>
        );
    }, [cardType, color, hasHoverableIcon, icon, iconTooltip, customLeftContent, onSelect]);

    const {button, architectMode} = getRecommendationCardInfo(
        recommendation?.type ?? AssessmentsRecommendationAssessmentType.CODING_CHALLENGE,
        phrases
    );

    return (
        <Container
            className={recommendation ? 'recommendation-item' : ''}
            $withBackground={!recommendation}
            $withBorder={!recommendation?.isRecommendation}
        >
            <LeftWrapper>
                <BorderStripe color={color} />
                {getLeftContent()}
            </LeftWrapper>
            <ContentWrapper>
                <DetailsAndAction isCentered={!recommendation}>
                    <DetailsWrapper>
                        <Details>
                            <NoGap>
                                <Titles>
                                    <Label>
                                        {!!categoryLabel && (
                                            <Caption color={ColorFgSubtle}>
                                                {categoryLabel}
                                            </Caption>
                                        )}
                                    </Label>
                                    <MainTitle>
                                        <S1>{title}</S1>
                                        <AdditionalTitleText>
                                            {additionalTitleText}
                                        </AdditionalTitleText>
                                        {!!tagText && (
                                            <Tag $variant={TagVariant?.info}>{tagText}</Tag>
                                        )}
                                    </MainTitle>
                                    {!!caption && <Attribution>{caption}</Attribution>}
                                </Titles>
                            </NoGap>
                            {isDefined(description) && description !== '' && (
                                <Caption color={ColorFgSubtle}>{description}</Caption>
                            )}
                            {!!assessmentLabel && <S2>{assessmentLabel}</S2>}
                            {!!attribution && <Attribution>{attribution}</Attribution>}
                            {!!recommendation && !recommendation?.isRecommendation && (
                                <RecommendedCard {...recommendation} />
                            )}
                            {!!recommendation && recommendation?.isRecommendation && (
                                <Recommendation>
                                    <TextWrapper>
                                        <span>
                                            <P2 forwardedAs="span" color={ColorFgMuted}>
                                                {phrases.basedOn}
                                            </P2>
                                            <S2>{recommendation?.role?.name ?? ''}</S2>
                                            <P2 forwardedAs="span" color={ColorFgMuted}>
                                                {phrases.role}
                                            </P2>
                                            {recommendation.type ===
                                                AssessmentsRecommendationAssessmentType.CODING_CHALLENGE && (
                                                <P2 forwardedAs="span">{phrases.expected}</P2>
                                            )}
                                        </span>
                                        <PlainButtonEdit
                                            narrow
                                            onClick={() =>
                                                recommendation?.openArchitect?.(architectMode)
                                            }
                                        >
                                            {button}
                                        </PlainButtonEdit>
                                    </TextWrapper>
                                </Recommendation>
                            )}
                            {footer}
                            {footerActions}
                        </Details>
                        {isDefined(props.assessmentWeightContext) && (
                            <AssessmentWeight context={props.assessmentWeightContext} />
                        )}
                        {!!recommendation && recommendation?.isRecommendation && (
                            <ActionWrapper>
                                <PlainAnchorButton
                                    target="_blank"
                                    onClick={recommendation.detailsProfileOpen}
                                    narrow
                                >
                                    {phrases?.viewDetails}
                                </PlainAnchorButton>
                                <SecondaryButton onClick={recommendation?.applyRecommendation}>
                                    {phrases.applyRecommendation}
                                </SecondaryButton>
                            </ActionWrapper>
                        )}
                    </DetailsWrapper>
                    {actionArea}
                </DetailsAndAction>
                {isDefined(stageSelector) && stageSelector}
            </ContentWrapper>
        </Container>
    );
};
