import {usePersonalityTestFormV2Logic} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/logic';
import {
    Footer,
    Header,
    QuestionInnerWrapper,
    QuestionWrapper,
    Wrapper
} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/styled';
import {PersonalityTestFormV2Props} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/types';

import {FormFooter} from './components/FormFooter';
import {FormHeader} from './components/FormHeader';
import {QuestionOptions} from './components/QuestionOptions';
import {QuestionTitle} from './components/QuestionTitle';

export function PersonalityTestFormV2({
    goBack,
    onTestCompleted,
    initialStatus,
    saveAnswer,
    savingAnswer,
    skipQuestion,
    skippingQuestion,
    getPreviousQuestion,
    loadingPreviousQuestion,
    isUsedInDataCollection
}: PersonalityTestFormV2Props) {
    const {
        currentQuestion,
        estimatedProgress,
        getPreviousQuestionWrapper,
        isLoading,
        isAnimating,
        disablePreviousQuestion,
        hideQuestionWhileSavingAnswer,
        animationName,
        saveAnswerWrapper,
        currentAnswerValue,
        skipQuestionWrapper,
        disableSkippingQuestion
    } = usePersonalityTestFormV2Logic({
        onTestCompleted,
        initialStatus,
        saveAnswer,
        savingAnswer,
        skipQuestion,
        skippingQuestion,
        getPreviousQuestion,
        loadingPreviousQuestion
    });

    if (!currentQuestion) {
        return null;
    }

    return (
        <Wrapper className="notranslate">
            <Header>
                <FormHeader
                    goBack={goBack}
                    estimatedProgress={estimatedProgress}
                    getPreviousQuestion={() => getPreviousQuestionWrapper(currentQuestion.id)}
                    loadingPreviousQuestion={isLoading || isAnimating}
                    previousQuestionButtonHidden={disablePreviousQuestion}
                    isUsedInDataCollection={isUsedInDataCollection}
                />
            </Header>
            <QuestionWrapper>
                {hideQuestionWhileSavingAnswer ? null : (
                    <QuestionInnerWrapper animationName={animationName}>
                        <QuestionTitle>{currentQuestion.title}</QuestionTitle>
                        <QuestionOptions
                            key={currentQuestion.id}
                            saveAnswer={value => saveAnswerWrapper(currentQuestion.id, value)}
                            answerValue={currentAnswerValue}
                        />
                    </QuestionInnerWrapper>
                )}
            </QuestionWrapper>

            {!isUsedInDataCollection ? (
                <Footer>
                    <FormFooter
                        key={currentQuestion.id}
                        skipQuestion={reason =>
                            skipQuestionWrapper(currentQuestion.id, reason)
                        }
                        loadingSkippingQuestion={isLoading || isAnimating}
                        skipButtonIsDisabled={disableSkippingQuestion}
                        currentAnswerValue={currentAnswerValue}
                    />
                </Footer>
            ) : null}
        </Wrapper>
    );
}
