import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_USER_ORGANIZATIONS} from './queries';

export function useUserOrganizations() {
    const {data, loading, error, refetch, startPolling, stopPolling} =
        useQuery(GET_USER_ORGANIZATIONS);

    const userAndOrganizations = useMemo(() => {
        if (!data?.me || !Array.isArray(data?.me?.userOrganizations)) {
            return {userOrganizations: [], user: null};
        }
        const {userOrganizations: orgs, ...user} = data.me;

        return {
            userOrganizations: [...orgs].sort((a, b) => {
                if (a.acceptedAt && !b.acceptedAt) {
                    return 1;
                }
                if (!a.acceptedAt && b.acceptedAt) {
                    return -1;
                }
                return a.organizationName.localeCompare(b.organizationName);
            }),
            user
        };
    }, [data]);

    return {
        userOrganizations: userAndOrganizations.userOrganizations,
        user: userAndOrganizations.user,
        loading,
        error,
        refetch,
        startPolling,
        stopPolling
    };
}

export function useNumberOfUserOrganizations() {
    const {userOrganizations, loading, error, refetch} = useUserOrganizations();
    const numberOfUserOrganizations = userOrganizations ? userOrganizations.length : 0;
    return {loading, numberOfUserOrganizations, error, refetch};
}
