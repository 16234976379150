import {defineMessages} from 'react-intl';

export default defineMessages({
    personalityScore: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.personalityScore'
    },
    logicScore: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.logicScore'
    },
    scorecardScores: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.scorecardScores'
    }
});
