import {Container} from './styled';
import {BoxProps} from './types';

export function Box({children, ...rest}: BoxProps) {
    return (
        <Container data-testid="box" {...rest}>
            {children}
        </Container>
    );
}
