import styled from 'styled-components';

import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    ColorFgSubtle,
    FontSizeSmall,
    SpacingMedium
} from '@/componentLibrary/tokens/variables';

export const RecommendationListWrapper = styled(FlexLayout)`
    display: flex;
    flex-flow: row wrap;
    gap: ${SpacingMedium};
    flex-direction: row;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.min}) {
        flex-direction: column;
    }
`;
export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    flex-grow: 6;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.min}) {
        width: 100%;
    }
`;
export const DescriptionText = styled(P1).attrs({
    as: 'div'
})`
    color: ${ColorFgSubtle};
    font-size: ${FontSizeSmall};
    line-height: 140%;
    text-align: center;
`;
