import {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';

import {StandardTenChart} from '@/componentLibrary/components/charts/StandardTenChart';
import {Caption, P2} from '@/componentLibrary/components/typography';

import {PersonalityFactorKey} from '../../../constants';
import {
    ChartWrapper,
    FactorResult,
    LowerBandWrapper,
    StyledFactorRow,
    UpperBandWrapper
} from '../styled';
import {FactorsOverviewProps, PersonalityTestResultKey} from '../types';
import {getFactorMessages} from '../utils';

type FactorRowProps = {
    factor: PersonalityFactorKey;
} & FactorsOverviewProps;

export const FactorRow = ({personalityTestResult, factor}: FactorRowProps) => {
    const getResultValueForFactor = useCallback(
        toBeLowercaseFactor => {
            return personalityTestResult[
                toBeLowercaseFactor.toLowerCase() as PersonalityTestResultKey
            ];
        },
        [personalityTestResult]
    );

    return (
        <StyledFactorRow>
            <P2>
                <FormattedMessage {...getFactorMessages(factor).name} />
            </P2>
            <FactorResult>
                <LowerBandWrapper>
                    <Caption>
                        <FormattedMessage {...getFactorMessages(factor).labelLower} />
                    </Caption>
                </LowerBandWrapper>
                <ChartWrapper>
                    <StandardTenChart
                        showBadge
                        selectedValue={getResultValueForFactor(factor)}
                    />
                </ChartWrapper>
                <UpperBandWrapper>
                    <Caption>
                        <FormattedMessage {...getFactorMessages(factor).labelUpper} />
                    </Caption>
                </UpperBandWrapper>
            </FactorResult>
        </StyledFactorRow>
    );
};
