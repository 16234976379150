import {defineMessages} from 'react-intl';

export default defineMessages({
    myEmploymentsTitle: {
        id: 'app.pages.user.chooseOrganization.myOrganizationsTitle'
    },
    noOrganizationsTitle: {
        id: 'app.pages.user.chooseOrganization.noOrganizationsTitle'
    },
    noOrganizationsDescription: {
        id: 'app.pages.user.chooseOrganization.noOrganizationsDescription'
    }
});
