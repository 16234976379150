import {Link} from 'react-router-dom';
import styled from 'styled-components';

import alvaLogo from './assets/blackAlvaLogo.svg';

export function Logo() {
    return (
        <Wrapper>
            <Link to="/o/home">
                <img src={alvaLogo} alt="" />
            </Link>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 32px;
            width: 32px;
        }
    }
`;
