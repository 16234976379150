import {useMemo} from 'react';

import {CHECKBOX_VALUES} from '@/componentLibrary/components/controls/CheckBox/constants';
import {Icons} from '@/componentLibrary/components/icons/constants';

import {getBackgroundColor, getBorderColor} from '../utils';

type Arguments = {
    value?: boolean | string;
    isDisabled: boolean;
};

export function useCheckBoxStyleLogic({value, isDisabled}: Arguments) {
    const backgroundColor = getBackgroundColor({value, isDisabled});
    const borderColor = getBorderColor({value, isDisabled});

    const icon = useMemo(() => {
        if (value === CHECKBOX_VALUES.INDETERMINATE) {
            return Icons.REMOVE;
        }

        if (value === true || value === CHECKBOX_VALUES.CHECKED) {
            return Icons.CHECK;
        }

        return null;
    }, [value]);

    return {backgroundColor, borderColor, icon};
}
