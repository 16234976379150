import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {PERSONALITY_IRT_LINKS} from '@/pages/PersonalityTestIrt/links';

export function useGoToPersonalityTest(goBackUrl?: string) {
    const history = useHistory();
    return useCallback(
        (personalityTestId, organizationId) => {
            const testUrl = PERSONALITY_IRT_LINKS.getLinkToTest({
                testId: personalityTestId,
                organizationId: organizationId?.toString()
            });
            const url = goBackUrl ? `${testUrl}?redirect=${goBackUrl}` : testUrl;
            history.push(url);
        },
        [history, goBackUrl]
    );
}
