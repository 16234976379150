import {Caption} from '@/componentLibrary/components/typography/Captions';
import {
    ColorFgError,
    SpacingSmall,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    & ul {
        list-style-type: disc;
        margin-left: ${SpacingXsmall};
    }

    & ul li {
        margin-bottom: ${SpacingXxxsmall};
    }
`;
export const Question = styled.div`
    padding-bottom: ${SpacingSmall};
`;
export const QuestionWrapper = styled.div`
    padding-top: ${SpacingXlarge};
`;
export const ErrorDisplay = styled(Caption)`
    color: ${ColorFgError};
`;
