import {useEffect, useMemo, useState} from 'react';

import {useCurrentEmployment} from '@/api/organizations/useCurrentEmployment';
import {useOrganization} from '@/api/organizations/useOrganization';
import {useAuthenticatedUser} from '@/api/users/useAuthenticatedUser';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {getConfig} from '@/config';
import {logger} from '@/services/logrocket';
import {getUserName} from '@/utils/misc';
import {SatismeterOptions} from './types';

type Props = {
    loggedInFlow?: boolean;
};

export const Satismeter = ({loggedInFlow = true}: Props) => {
    const {loading, enabled, options} = useSatismeterOptions(loggedInFlow);
    const isInitialized = useWindowSatismeter();

    useEffect(() => {
        if (window.TestingMode || !isInitialized || !enabled || loading) {
            return;
        }
        logger.log('Loading satismeter');
        if (options) {
            window.satismeter(options);
        } else {
            logger.error('Satismeter options are not defined');
        }
    }, [isInitialized, loading, enabled, options]);

    return null;
};

function useSatismeterOptions(loggedInFlow: boolean) {
    const writeKey = getConfig().SATISMETER_WRITE_KEY;

    const {user: authenticatedUser, loading: loadingAuthenticatedUser} =
        useAuthenticatedUser();
    const {user, loading: loadingUser} = useLoggedInUser();
    const {employment, loading: loadingEmployment} = useCurrentEmployment();
    const {organization, loading: loadingOrg} = useOrganization();
    const loading = loadingUser || loadingEmployment || loadingAuthenticatedUser || loadingOrg;
    return useMemo(() => {
        if (!loggedInFlow) {
            return {
                loading: false,
                enabled: true,
                options: {
                    writeKey: writeKey,
                    traits: {
                        loggedInFlow: false
                    }
                }
            };
        }
        if (!writeKey) {
            return {
                loading: false,
                enabled: false,
                options: null
            };
        }
        if (loading) {
            return {
                loading: true,
                enabled: true,
                options: null
            };
        }
        const isLoggedIn = !!authenticatedUser && !!user;
        if (!isLoggedIn) {
            return {
                loading: false,
                enabled: false,
                options: null
            };
        }
        const isImpersonating = user.id !== authenticatedUser.userId;
        if (isImpersonating) {
            return {loading: false, enabled: false, options: null};
        }
        const options: SatismeterOptions = {
            writeKey: writeKey,
            userId: authenticatedUser.userId,
            traits: {
                name: getUserName(authenticatedUser),
                email: authenticatedUser.email,
                createdAt: authenticatedUser.registeredAt,
                registeredAt: authenticatedUser.registeredAt
            }
        };

        if (employment && employment.organization) {
            const accessGroupName =
                employment && employment.accessGroup ? employment.accessGroup.name : '';
            options.traits.access_groups = accessGroupName;
            options.traits.access_group = accessGroupName;
            options.traits.isAdministrator = accessGroupName === 'ADMINISTRATOR';
            options.traits.keyMember = !!employment.isKeyMember;
            options.traits.organizationId = employment.organization.id;
            options.traits.organizationName = employment.organization.name;
            options.traits.organizationCurrentPlan = employment.organization.currentPlan;
        }

        options.traits.organizationCreatedAt = organization?.created;

        let integrationEnabled = false;
        if (organization && organization.tokens?.length > 0) {
            const newestTokenWithProvider = organization.tokens.reduce((prev, current) => {
                if (current?.provider) {
                    return new Date(current.created) > new Date(prev?.created)
                        ? current
                        : prev;
                }
                return prev;
            });
            if (newestTokenWithProvider) {
                options.traits.integrationProvider =
                    newestTokenWithProvider.provider || undefined;
                integrationEnabled = !!newestTokenWithProvider.name;
            }
        }
        options.traits.integrationEnabled = integrationEnabled;

        return {
            loading: false,
            enabled: true,
            options
        };
    }, [authenticatedUser, employment, loading, writeKey, user, organization, loggedInFlow]);
}

function useWindowSatismeter() {
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        (function () {
            window.satismeter =
                window.satismeter ||
                function (...args: [SatismeterOptions]) {
                    (window.satismeter.q = window.satismeter.q || []).push(args);
                };
            window.satismeter.l = Date.now();
            const script = document.createElement('script');
            const parent = document.getElementsByTagName('script')[0].parentNode;
            script.async = true;
            script.src = 'https://app.satismeter.com/satismeter.js';
            parent?.appendChild(script);
            setInitialized(true);
        })();
    }, []);
    return initialized;
}
