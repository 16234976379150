import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {SetNewPasswordMutation} from '../types/__generated__/graphql';
import {SET_NEW_PASSWORD} from './mutations';

export function useSetNewPassword(): MutationHookReturnType<SetNewPasswordMutation, string> {
    const [mutate, {error, loading}] = useMutation(SET_NEW_PASSWORD);

    const setNewPassword = useCallback(
        newPassword => mutate({variables: {newPassword}}),
        [mutate]
    );

    return [setNewPassword, {error, loading}];
}
