import {defineMessages} from 'react-intl';

export default defineMessages({
    resultsHeader: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.header'
    },
    automatedTestScore: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.automatedTestScore'
    },
    autoscoreByAI: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.autoscoreByAI'
    },
    scoreGradeLow: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.scoreGrade.low'
    },
    scoreGradeMid: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.scoreGrade.mid'
    },
    scoreGradeHigh: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.scoreGrade.high'
    },
    scoreGradeLowTooltipContent: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.scoreGrade.low.tooltip'
    },
    scoreGradeMidTooltipContent: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.scoreGrade.mid.tooltip'
    },
    scoreGradeHighTooltipContent: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.scoreGrade.high.tooltip'
    },
    automatedTestScoreTooltip: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.automatedTestScore.tooltip'
    },
    qualityScore: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.qualityScore'
    },
    qualityScoreTooltip: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.qualityScore.tooltip'
    },
    reviewScore: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.reviewScore'
    },
    reviewScoreTooltip: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.reviewScore.tooltip'
    },
    resultsDetailBtn: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.detailBtn'
    },
    aiDisclaimer: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.aiDisclaimer'
    }
});
