import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GetOrganizationOktaConfigurationQuery} from '../types/__generated__/graphql';
import {GET_ORGANIZATION_OKTA_CONFIGURATION} from './queries';

export function useOrganizationOktaConfiguration(
    email: string,
    skip = false,
    onCompleted: (data: GetOrganizationOktaConfigurationQuery) => void
) {
    const variables = useMemo(() => {
        return {email};
    }, [email]);

    const {data, error, loading, refetch} = useQuery(GET_ORGANIZATION_OKTA_CONFIGURATION, {
        variables: variables,
        skip,
        onCompleted
    });

    const organizationOktaConfiguration = useMemo(() => {
        if (!data || !data.organizationOktaConfiguration) {
            return null;
        }
        return data.organizationOktaConfiguration;
    }, [data]);

    return {
        loading,
        error,
        organizationOktaConfiguration,
        refetch
    };
}
