import {RecaptchaTerms} from '@/pages/SignupV2/components/RecaptchaTerms';
import {CreateOrganizationForm} from '@/pages/SignupV2/components/SSO/CreateOrganizationForm';
import {WelcomeMessage} from '@/pages/SignupV2/components/WelcomeMessage';
import {SsoAuthData} from '@/pages/SignupV2/types';

type CreateOrganizationProps = {
    ssoData: SsoAuthData;
};
export function CreateOrganization(props: CreateOrganizationProps) {
    return (
        <>
            <WelcomeMessage />
            <CreateOrganizationForm ssoData={props.ssoData} />
            <RecaptchaTerms />
        </>
    );
}
