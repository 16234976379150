import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {
    CreateInterviewRequestMutation,
    InterviewRequestInput
} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {CREATE_INTERVIEW_REQUEST} from './mutations';

export function useCreateInterviewRequest(): MutationHookReturnType<
    CreateInterviewRequestMutation,
    InterviewRequestInput
> {
    const [mutate, {error, loading}] = useMutation(CREATE_INTERVIEW_REQUEST);
    const doCreateInterviewRequest = useCallback(
        (interviewRequestData: InterviewRequestInput) => {
            const variables = {
                data: {
                    jobApplicationId: interviewRequestData.jobApplicationId,
                    employmentId: interviewRequestData.employmentId,
                    interviewId: interviewRequestData.interviewId
                },
                notifyInterviewer: false
            };
            return mutate({variables});
        },
        [mutate]
    );

    return [doCreateInterviewRequest, {error, loading}];
}
