import FileSaver from 'file-saver';
import {v4 as uuid} from 'uuid';

import {getBaseUrl} from '@/config';
import {
    ALVA_EXPERIMENTAL_FEATURES_KEY,
    ALVA_IMPERSONATION_TOKEN_KEY,
    ALVA_SELECTED_ORGANIZATION_KEY,
    ALVA_TOKEN_KEY
} from '@/services/auth/constants';
import {clearAuthTokens} from '@/services/auth/utils';
import {logger} from '@/services/logrocket';

import {WHITE_LISTED_PATHS} from './constants';
import {isDefined} from './typeGuards/isDefined';

const LOGOUT = '/logout';

const buildUrl = (slugOrUrl: string): RequestInfo => {
    // If only slug is given, add full url.
    if (slugOrUrl.indexOf('http://') === 0 || slugOrUrl.indexOf('https://') === 0) {
        return slugOrUrl;
    }

    // Not protocol, interpreted as slug
    return getBaseUrl() + slugOrUrl;
};

export const setImpersonationToken = (token: string) => {
    window.localStorage.setItem(ALVA_IMPERSONATION_TOKEN_KEY, token);
    window.dispatchEvent(new Event('impersonationTokenUpdatedEvent'));
};

export const getImpersonationToken = () => {
    return window.localStorage.getItem(ALVA_IMPERSONATION_TOKEN_KEY);
};

const getFeatureFlagsAsString = () => {
    const featureFlags: string[] = [];

    try {
        const featureFlagsObj = JSON.parse(
            window.localStorage.getItem(ALVA_EXPERIMENTAL_FEATURES_KEY) || '{}'
        );

        if (!featureFlagsObj) {
            return;
        }

        Object.entries(featureFlagsObj).forEach(([key, value]) => {
            if (value) {
                featureFlags.push(key);
            }
        });
    } catch (e) {
        logger.info('Failed to parse feature flags', e);
    }

    return featureFlags.join(',');
};

export const isImpersonating = () => {
    return !!getImpersonationToken();
};

export const clearImpersonationToken = () => {
    window.localStorage.removeItem(ALVA_IMPERSONATION_TOKEN_KEY);
    window.localStorage.removeItem(ALVA_SELECTED_ORGANIZATION_KEY);
};

export const getToken = () => {
    return window.localStorage.getItem(ALVA_TOKEN_KEY);
};

/**
 * Build GET request query string from a params object, or empty string if no params.
 * @param {*} paramsMap Query string params, in the form {key: value, key2: value2, ...}
 * @returns A query string, in the form ?key=value&key2=value2&...
 */
export const serializeQueryString = (
    paramsMap: Record<string, string | number | boolean | null> = {}
) => {
    const queryParams = [];
    if (Object.keys(paramsMap).length === 0) {
        return '';
    }

    for (const p in paramsMap) {
        if (Object.prototype.hasOwnProperty.call(paramsMap, p)) {
            let paramValue = paramsMap[p];
            if (!isDefined(paramValue)) {
                paramValue = '';
            }
            queryParams.push(encodeURIComponent(p) + '=' + encodeURIComponent(paramValue));
        }
    }
    return '?' + queryParams.join('&');
};

function getSharedTokenFromURL(): string | null {
    const pathname = window.location.pathname.split('/');
    if (pathname[1] === 'shared-results') {
        if (pathname.length >= 4) {
            return pathname[2];
        }
    }
    return null;
}

export const buildRequestHeaders = (contentType: string | null): HeadersInit => {
    const headers: HeadersInit = {};

    if (contentType) {
        headers['Content-Type'] = contentType;
    }
    const token = getToken();

    headers['Alva-Correlation-Id'] = uuid();
    headers['Authorization'] = `Token:${token}`;

    const orgKey = localStorage.getItem(ALVA_SELECTED_ORGANIZATION_KEY);
    if (isDefined(orgKey)) {
        headers['X-Selected-Organization-Id'] = orgKey;
    }

    const impersonationToken = getImpersonationToken();
    if (isDefined(impersonationToken)) {
        headers['X-Impersonation-Token'] = impersonationToken;
    }

    const featureFlags = getFeatureFlagsAsString();
    if (isDefined(featureFlags)) {
        headers['featureFlags'] = featureFlags;
    }

    const shared_results_token = getSharedTokenFromURL();
    if (isDefined(shared_results_token)) {
        headers['x-shared-results-token'] = shared_results_token;
    }

    return headers;
};

class AjaxError extends Error {
    response: {status: number; statusText: string | undefined};

    constructor(message: string, response: {status: number; statusText: string | undefined}) {
        super(message);
        this.response = response;
    }
}

const checkStatus = (response: Response) => {
    // Check status on response. Raise error if not success
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    throw new AjaxError(response.statusText || 'Request failed', response);
};

const getRedirectUrl = () => {
    return `${LOGOUT}?redirect=${window.location.pathname}`;
};

const checkAuthentication = (response: Response) => {
    if (response.status === 401) {
        const path = window.location.pathname;

        if (!WHITE_LISTED_PATHS.includes(path)) {
            clearAuthTokens();

            const redirectUrl = getRedirectUrl();

            window.location.replace(redirectUrl);
        }
    }
    return response;
};

const parseResponse = (response: Response) => {
    return response.json();
};

/**
 * Ajax request that handles authentification
 *
 * @param params
 * @returns {Promise.<TResult>}
 */
type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface RequestParams {
    method: HttpMethod;
    data?: string | FormData;
    url: string;
    contentType?: string;
    json?: Record<string, unknown>;
}

async function request(params: RequestParams) {
    // function request(params: {method: any; data: any; url: any; contentType: any; json?: any}) {
    // Options object
    const options: RequestInit = {};

    // Build URL
    const url = buildUrl(params.url);

    // Get method. Defaults to 'GET'
    options.method = params.method ? params.method.toUpperCase() : 'GET';

    // Get data. Data might be sent as text ('data') or json ('json')
    let data = params.data ? params.data : null;

    if (params.json !== null && typeof params.json === 'object') {
        data = JSON.stringify(params.json);
    }

    // Specify content type and data
    const contentType = params.contentType ? params.contentType : null;

    // Body should only have data if post or put request
    if ((options.method === 'POST' || options.method === 'PUT') && data) {
        options.body = data;
    }

    // Set headers (including token)
    options.headers = buildRequestHeaders(contentType);

    // Make request!
    return fetch(url, options).then(checkAuthentication).then(checkStatus).then(parseResponse);
}

export const ajax = {
    uploadFile: (url: string, file: string | Blob, data: {[x: string]: string | Blob}) => {
        const formData = new FormData();
        formData.append('file', file);

        // Add additional data. Obs!! JSON must be stringified
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                if (typeof data[key] === 'object') {
                    formData.append(key, JSON.stringify(data[key]));
                } else {
                    formData.append(key, data[key]);
                }
            }
        }

        const params: RequestParams = {
            method: 'POST',
            data: formData,
            url: url
        };

        return request(params);
    },
    downloadFile: (
        url: string,
        filename: string,
        contentType: string,
        onCompleted?: () => void,
        onError?: (error: Error) => void
    ) => {
        const params = {
            method: 'GET',
            headers: buildRequestHeaders(contentType)
        };

        return fetch(buildUrl(url), params)
            .then(checkAuthentication)
            .then(checkStatus)
            .then(res => res.blob())
            .then(blob => {
                FileSaver.saveAs(blob, filename);
            })
            .then(onCompleted)
            .catch(err => {
                onError?.(err);
                throw err;
            });
    }
};
