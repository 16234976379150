import styled from 'styled-components';

import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXsmall} from '@/componentLibrary/tokens/variables';

type WrapperProps = {
    windowHeight: number;
};

export const Wrapper = styled(FlexLayout)<WrapperProps>`
    gap: ${SpacingXsmall};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        max-height: calc(
            ${({windowHeight}) => windowHeight}px - 206px
        ); /* 206 px = header + footer */
    }

    & > :last-child {
        margin-top: ${SpacingXsmall};
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 99%;
`;
