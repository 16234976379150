import styled from 'styled-components';

import {SpacingLarge, SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    padding-top: ${SpacingXxsmall};
`;

export const Svg = styled.svg`
    width: ${SpacingLarge};
    height: ${SpacingLarge};

    & path {
        fill: none;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
    }
`;
