import {useMemo} from 'react';

import {DisplayMedium, DisplaySmall} from '@/componentLibrary/components/typography/Display';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {PROLIFIC_COMPLETION_BASE_URL} from '@/pages/LogicTestDataCollection/pages/CompletedDataCollectionTest/components/ProlificCompletedContent/constants';
import {
    CodeWrapper,
    DescriptionWrapper,
    TitleWrapper
} from '@/pages/LogicTestDataCollection/pages/CompletedDataCollectionTest/components/ProlificCompletedContent/styled';
import {ProlificCompletedContentProps} from '@/pages/LogicTestDataCollection/pages/CompletedDataCollectionTest/components/ProlificCompletedContent/types';
import {logicTestDataCollectionMessages} from '../../../../messages';

export function ProlificCompletedContent({completionCode}: ProlificCompletedContentProps) {
    const completionUrl = useMemo(() => {
        return PROLIFIC_COMPLETION_BASE_URL + `?cc=${completionCode}`;
    }, [completionCode]);
    const phrases = useExtractPhraseConstants(logicTestDataCollectionMessages);

    return (
        <>
            <TitleWrapper data-testid={'prolific-completed-title'}>
                <DisplayMedium>{phrases.completionPageTitle}</DisplayMedium>
            </TitleWrapper>
            <DescriptionWrapper data-testid={'prolific-completed-description'}>
                <DisplaySmall>{phrases.completionPageProlificContent}</DisplaySmall>
            </DescriptionWrapper>
            <CodeWrapper>
                <DisplaySmall>
                    <a href={completionUrl} target="_blank" rel="noopener noreferrer">
                        Link for completion
                    </a>
                </DisplaySmall>
            </CodeWrapper>
        </>
    );
}
