import {useAddCandidateForJobPosition} from '@/api/assessment/jobPositions/useAddCandidateForJobPosition';
import {usePublicJobPosition} from '@/api/assessment/publicJobPosition/usePublicJobPosition';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {PublicJobPositionNotFound} from '@/pages/PublicJobPosition/pages/ApplyForJobPosition/components/PublicJobPositionNotFound';

import {ApplyForm} from './components/ApplyForm';
import {ApplyInstructions} from './components/ApplyInstructions';
import {InstructionLayout} from './components/InstructionLayout';

export function ApplyForJobPosition(props: {jobPositionId: number}) {
    const {publicJobPosition, error, loading, refetch} = usePublicJobPosition(
        props.jobPositionId
    );
    const [doAddCandidate, {loading: loadingAddMutation}] = useAddCandidateForJobPosition(
        true,
        true
    );

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    if (!loading && !publicJobPosition) {
        return (
            <InstructionLayout instruction={<ApplyInstructions />}>
                <PublicJobPositionNotFound />
            </InstructionLayout>
        );
    }

    return (
        <InstructionLayout
            instruction={<ApplyInstructions />}
            loading={loading}
            colorHeaderEnabled={publicJobPosition?.publicPageColorHeaderEnabled}
            colorHeader={publicJobPosition?.publicPageColorHeader}
        >
            <ApplyForm
                loading={loadingAddMutation}
                jobPosition={publicJobPosition}
                doAddCandidate={doAddCandidate}
            />
        </InstructionLayout>
    );
}
