import {routerReducer} from 'react-router-redux';
import {combineReducers} from 'redux';

import {LOGOUT_USER_REQUEST} from '@/services/auth/constants';
import {clearAuthTokens} from '@/services/auth/utils';
import {fileHandler} from '@/services/uploadFile/reducers';

import * as languageReducers from './containers/LanguageProvider/reducers';
import * as uiReducers from './services/ui/reducers';

const appReducer = combineReducers({
    ...languageReducers,
    ...uiReducers,
    fileHandler,
    routing: routerReducer
});

export const rootReducer = (state, action) => {
    if (action.type === LOGOUT_USER_REQUEST) {
        clearAuthTokens();
        state = undefined;
    }

    return appReducer(state, action);
};
