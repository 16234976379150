import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.recoverPassword.title'
    },
    description: {
        id: 'app.recoverPassword.description'
    },
    emailMaybeSent: {
        id: 'app.recoverPassword.emailMaybeSent'
    },
    somethingWentWrong: {
        id: 'app.recoverPassword.somethingWentWrong'
    }
});

export const errorMessages = defineMessages({
    SERVER_ERROR: {
        id: 'app.recoverPassword.errorServer'
    },
    FAILED_TO_SEND_EMAIL: {
        id: 'app.recoverPassword.errorInvalid'
    },
    NON_EXISTING_EMAIL: {
        id: 'app.recoverPassword.errorNotFound'
    }
});
