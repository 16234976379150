export const LEVELS_OF_EDUCATION = {
    PRIMARY_AND_LOWER_SECONDARY_EDUCATION: 'PRIMARY_AND_LOWER_SECONDARY_EDUCATION',
    UPPER_SECONDARY_EDUCATION_AND_HIGH_SCHOOL: 'UPPER_SECONDARY_EDUCATION_AND_HIGH_SCHOOL',
    POST_SECONDARY_EDUCATION_LESS_THAN_THREE_YEARS:
        'POST_SECONDARY_EDUCATION_LESS_THAN_THREE_YEARS',
    POST_SECONDARY_EDUCATION_THREE_YEARS_AND_MORE:
        'POST_SECONDARY_EDUCATION_THREE_YEARS_AND_MORE',
    OTHER: 'OTHER',
    RATHER_NOT_SAY: 'RATHER_NOT_SAY'
};
