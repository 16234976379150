import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_USER} from '@/api/users/queries';

type UseUserOptions = {
    withEmployment: boolean;
};

export function useUser(userId?: number, options?: UseUserOptions) {
    const variables = {id: userId as number, withEmployment: options?.withEmployment ?? false};
    const {data, error, loading, refetch} = useQuery(GET_USER, {variables, skip: !userId});
    const user = useMemo(() => data?.user ?? null, [data]);

    return {error, loading, user, refetch};
}
