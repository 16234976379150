import {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import {P1} from '@/componentLibrary/components/typography';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {personalityTestReportMessages} from '../../messages';
import {BulletList} from '../BulletList';
import {personalityResultMessageIds} from './constants';
import {
    personalityResultChallengeMessages,
    personalityResultStrengthMessages
} from './messages';
import {Column, Content, Wrapper} from './styled';
import {PersonalityResultStrengthMessageKeys, StrengthsAndChallengesProps} from './types';
import {
    getPersonalityFactorsFromTestResult,
    sortPersonalityFactorResultBasedOnDeviation
} from './utils';

export function StrengthsAndChallenges({personalityTestResult}: StrengthsAndChallengesProps) {
    const intl = useIntl();

    const sortedFactorResult = useMemo(() => {
        const factorResult = getPersonalityFactorsFromTestResult(personalityTestResult);

        return sortPersonalityFactorResultBasedOnDeviation(factorResult);
    }, [personalityTestResult]);

    const getMessageId = useCallback(
        (
            factor1,
            factor1value,
            factor2,
            factor2value
        ): PersonalityResultStrengthMessageKeys | null => {
            const firstMessageId =
                factor1 + '_' + factor1value + '_' + factor2 + '_' + factor2value;
            const secondMessageId =
                factor2 + '_' + factor2value + '_' + factor1 + '_' + factor1value;

            if (personalityResultMessageIds.find(messageId => messageId === firstMessageId)) {
                return firstMessageId as PersonalityResultStrengthMessageKeys;
            }
            if (personalityResultMessageIds.find(messageId => messageId === secondMessageId)) {
                return secondMessageId as PersonalityResultStrengthMessageKeys;
            }

            return null;
        },
        []
    );

    const messageIds = useMemo(() => {
        const messageIdsForFactorResult: PersonalityResultStrengthMessageKeys[] = [];
        const indexCombinations = [
            [0, 1],
            [0, 2],
            [1, 2]
        ];

        indexCombinations.forEach(indexCombination => {
            const firstFactor = sortedFactorResult[indexCombination[0]];
            const secondFactor = sortedFactorResult[indexCombination[1]];
            const messageId = getMessageId(
                firstFactor.factorName,
                firstFactor.label,
                secondFactor.factorName,
                secondFactor.label
            );
            isDefined(messageId) && messageIdsForFactorResult.push(messageId);
        });

        return messageIdsForFactorResult;
    }, [sortedFactorResult, getMessageId]);

    const strengthItems = useMemo(() => {
        return messageIds.map(messageId =>
            intl.formatMessage(personalityResultStrengthMessages[messageId])
        );
    }, [intl, messageIds]);

    const challengeItems = useMemo(() => {
        return messageIds.map(messageId =>
            intl.formatMessage(personalityResultChallengeMessages[messageId])
        );
    }, [intl, messageIds]);

    return (
        <Wrapper>
            <Column>
                <Content>
                    <P1>
                        {intl.formatMessage(personalityTestReportMessages.possibleStrengths)}
                    </P1>
                    <BulletList items={strengthItems} />
                </Content>
            </Column>
            <Column>
                <Content>
                    <P1>
                        {intl.formatMessage(personalityTestReportMessages.possibleChallenges)}
                    </P1>
                    <BulletList items={challengeItems} />
                </Content>
            </Column>
        </Wrapper>
    );
}
