import {FC} from 'react';

import {H3, H4, P1} from '../typography';
import {ActionsWrapper, TitleWrapper, Wrapper} from './styled';
import {InPageHeaderProps, TITLE_SIZE} from './types';

export const InPageHeader: FC<InPageHeaderProps> = ({
    title,
    tag,
    primaryAction,
    secondaryAction,
    ...rest
}) => {
    let Title = H4;
    if (title.size === TITLE_SIZE.LARGE) {
        Title = H3;
    }
    if (title.size === TITLE_SIZE.SMALL) {
        Title = P1;
    }
    return (
        <Wrapper {...rest}>
            <TitleWrapper>
                <Title>{title.content}</Title>
                {tag}
            </TitleWrapper>
            {(!!primaryAction || !!secondaryAction) && (
                <ActionsWrapper>
                    {secondaryAction}
                    {primaryAction}
                </ActionsWrapper>
            )}
        </Wrapper>
    );
};
