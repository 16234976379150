import styled from 'styled-components';

import {SpacingLarge, SpacingMedium} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.section`
    max-width: 1100px;
    flex: 1;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: ${SpacingMedium};

    & + & {
        margin-top: ${SpacingLarge};
    }
`;

export const StateWrapper = styled.div`
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
`;
