import {useSetUseOverdueScore} from '@/api/assessment/codingTests/useSetUseOverdueScore';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';

import messages from '../messages';

export function useSetMainScoreLogic({assignmentId}: {assignmentId: string}) {
    const {modalIsOpen, openModal, closeModal} = useSimpleModalLogic();
    const phrases = useExtractPhraseConstants(messages);

    const [setUseOverdueScore, {loading}] = useSetUseOverdueScore();

    const handleSubmit = async () => {
        try {
            await setUseOverdueScore({assignmentId, useOverdueScore: true});
            toast({
                type: 'success',
                message: phrases.setMainScoreSuccessMessage
            });
        } catch (_e) {
            toast({
                type: 'error',
                message: phrases.setMainScoreErrorMessage
            });
        } finally {
            closeModal();
        }
    };

    return {modalIsOpen, openModal, closeModal, handleSubmit, loading};
}
