import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {InfoBell} from '@/componentLibrary/components/Bells/InfoBell';
import {UserInfoBell} from '@/componentLibrary/components/Bells/UserInfoBell/UserInfoBell';
import {CandidateReminderStatus} from '@/componentLibrary/components/Bells/UserInfoBell/types';
import {
    ColorFgDanger,
    FontSizeSmaller,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import {useIsMobileDevice} from '@/utils/useIsMobileDevice';

import messages from './messages';

type EmailStatusProps = {
    emailDidBounce: boolean;
    emailDidExclude: boolean;
    candidateReminderStatus: CandidateReminderStatus | null;
};

export const EmailStatus = ({
    emailDidBounce,
    emailDidExclude,
    candidateReminderStatus
}: EmailStatusProps) => {
    const intl = useIntl();
    const isMobileDevice = useIsMobileDevice();
    if (emailDidExclude) {
        return (
            <Wrapper>
                {!isMobileDevice && (
                    <BouncedText>{intl.formatMessage(messages.emailExcluded)}</BouncedText>
                )}
                <InfoBell tooltip={intl.formatMessage(messages.emailExcludedText)} isDanger />
            </Wrapper>
        );
    }
    if (emailDidBounce) {
        return (
            <Wrapper>
                {!isMobileDevice && (
                    <BouncedText>{intl.formatMessage(messages.emailBounced)}</BouncedText>
                )}
                <UserInfoBell candidateReminderStatus={candidateReminderStatus} />
            </Wrapper>
        );
    }
    return null;
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const BouncedText = styled.div`
    color: ${ColorFgDanger};
    font-size: ${FontSizeSmaller};
    margin-right: ${SpacingXxxsmall};
`;
