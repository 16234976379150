import {defineMessages} from 'react-intl';

export default defineMessages({
    inProgress: {
        id: 'app.assessment.codingTest.candidateCodingTest.status.inProgress'
    },
    submitted: {
        id: 'app.assessment.codingTest.candidateCodingTest.status.submitted'
    },
    needsSupport: {
        id: 'app.assessment.codingTest.candidateCodingTest.status.needsSupport'
    },
    notStarted: {
        id: 'app.assessment.codingTest.candidateCodingTest.status.notStarted'
    },
    passed: {
        id: 'app.assessment.codingTest.candidateCodingTest.status.passed'
    },
    failed: {
        id: 'app.assessment.codingTest.candidateCodingTest.status.failed'
    }
});
