import {useCallback, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {PersonalityTestStatusV2} from '@/api/types/__generated__/graphql';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {personalityTestTakingMessages} from '@/pages/PersonalityTestIrt/messages';
import {
    PersonalityTestQuestion,
    UsePersonalityTestFormV2LogicProps
} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/types';
import {getValueFromKeyCode} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/utils';

export function usePersonalityTestFormV2Logic({
    onTestCompleted,
    initialStatus,
    saveAnswer,
    savingAnswer,
    skipQuestion,
    skippingQuestion,
    getPreviousQuestion,
    loadingPreviousQuestion
}: UsePersonalityTestFormV2LogicProps) {
    const [estimatedProgress, setEstimatedProgress] = useState<number | null>(null);
    const [currentQuestion, setCurrentQuestion] = useState<PersonalityTestQuestion | null>(
        null
    );
    const [currentAnswerValue, setCurrentAnswerValue] = useState<number | null | undefined>(
        null
    );
    const [disableSkippingQuestion, setDisableSkippingQuestion] = useState(false);
    const [disablePreviousQuestion, setDisablePreviousQuestion] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isSavingAnswer, setIsSavingAnswer] = useState(false);
    const [hideQuestionWhileSavingAnswer, setHideQuestionWhileSavingAnswer] = useState(false);
    const isLoading = savingAnswer || skippingQuestion || loadingPreviousQuestion;

    const handleAnimationStart = useCallback(() => {
        setIsAnimating(true);
    }, []);

    const handleAnimationEnd = useCallback(() => {
        setIsAnimating(false);
        setHideQuestionWhileSavingAnswer(isSavingAnswer);
    }, [isSavingAnswer]);

    const handleNewPersonalityTestStatus = useCallback(
        (personalityTestStatus: PersonalityTestStatusV2) => {
            if (personalityTestStatus.isCompleted) {
                return onTestCompleted();
            }
            setEstimatedProgress(personalityTestStatus.estimatedProgress);
            setCurrentQuestion(personalityTestStatus.nextQuestion);
            setCurrentAnswerValue(personalityTestStatus.nextQuestionAnswer?.value);
            setDisableSkippingQuestion(personalityTestStatus.disableSkippingQuestion);
            setDisablePreviousQuestion(personalityTestStatus.disablePreviousQuestion);
        },
        [onTestCompleted]
    );

    const saveAnswerWrapper = useCallback(
        (questionId: string, value: number) => {
            if (savingAnswer) {
                return;
            }

            setIsSavingAnswer(true);
            saveAnswer({questionId, value})
                .then(({data}) => {
                    const saveAnswerResult = data?.saveAnswerForPersonalityTestV2;
                    if (saveAnswerResult?.ok) {
                        setIsSavingAnswer(false);
                        setHideQuestionWhileSavingAnswer(false);
                        handleNewPersonalityTestStatus(
                            saveAnswerResult.personalityTestStatus as PersonalityTestStatusV2
                        );
                    } else {
                        throw new Error(saveAnswerResult?.errorMessage ?? 'Unknown error');
                    }
                })
                .catch(() => {
                    setIsSavingAnswer(false);
                    setHideQuestionWhileSavingAnswer(false);
                    handleError(personalityTestTakingMessages.errorSavingAnswer);
                });
        },
        [handleNewPersonalityTestStatus, saveAnswer, savingAnswer]
    );

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (isAnimating || isLoading || !currentQuestion) {
                // Ignore all key-events when loading or animating
                return;
            }

            const value = getValueFromKeyCode(e.keyCode);

            if (!value || !currentQuestion) {
                return;
            }
            // Set state as animating.
            // Given the timeout until the question is saved, there is a small time window where you can press to keys again.
            // The decision to override the 'isAnimating' state was with argument that the state is actually just waiting until the animation will start.
            setIsAnimating(true); // Directly set isAnimating to true
            setCurrentAnswerValue(value);
            setTimeout(() => {
                saveAnswerWrapper(currentQuestion.id, value);
            }, 250);
        },
        [currentQuestion, isAnimating, isLoading, saveAnswerWrapper]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown, false);
        window.addEventListener('animationstart', handleAnimationStart);
        window.addEventListener('animationend', handleAnimationEnd);

        return () => {
            window.removeEventListener('keydown', handleKeyDown, false);
            window.removeEventListener('animationstart', handleAnimationStart);
            window.removeEventListener('animationend', handleAnimationEnd);
        };
    }, [handleAnimationEnd, handleAnimationStart, handleKeyDown]);

    useEffect(() => {
        handleNewPersonalityTestStatus(initialStatus as PersonalityTestStatusV2);
    }, [handleNewPersonalityTestStatus, initialStatus]);

    const skipQuestionWrapper = useCallback(
        (questionId: string, reason: string) => {
            skipQuestion({questionId, reason})
                .then(({data}) => {
                    const skipQuestionResult = data?.skipQuestionForPersonalityTestV2;
                    if (skipQuestionResult?.ok) {
                        handleNewPersonalityTestStatus(
                            skipQuestionResult.personalityTestStatus as PersonalityTestStatusV2
                        );
                    } else {
                        throw new Error(skipQuestionResult?.errorMessage ?? 'Unknown error');
                    }
                })
                .catch(() => handleError(personalityTestTakingMessages.errorSkippingQuestion));
        },
        [handleNewPersonalityTestStatus, skipQuestion]
    );

    const getPreviousQuestionWrapper = useCallback(
        (questionId: string) => {
            getPreviousQuestion(questionId)
                .then(({data}) => {
                    const getPreviousQuestionResult =
                        data?.getPreviousQuestionForPersonalityTestV2;
                    if (getPreviousQuestionResult?.ok) {
                        handleNewPersonalityTestStatus(
                            getPreviousQuestionResult.personalityTestStatus as PersonalityTestStatusV2
                        );
                    } else {
                        throw new Error(
                            getPreviousQuestionResult?.errorMessage ?? 'Unknown error'
                        );
                    }
                })
                .catch(() =>
                    handleError(personalityTestTakingMessages.errorLoadingPreviousQuestion)
                );
        },
        [getPreviousQuestion, handleNewPersonalityTestStatus]
    );

    const handleError = (message: {id: string; defaultMessage?: string}) =>
        toast({type: 'error', message: <FormattedMessage {...message} />});

    const animationName = isLoading ? 'fade-out-down' : 'fade-in-down';
    return {
        currentQuestion,
        estimatedProgress,
        getPreviousQuestionWrapper,
        isLoading,
        isAnimating,
        disablePreviousQuestion,
        hideQuestionWhileSavingAnswer,
        animationName,
        saveAnswerWrapper,
        currentAnswerValue,
        skipQuestionWrapper,
        disableSkippingQuestion
    };
}
