export function isDefined<T>(value: T | undefined | null): value is T {
    return value !== undefined && value !== null;
}

export function hasDefinedField<T, K extends keyof T>(
    obj: T,
    key: K
): obj is T & {[P in K]-?: NonNullable<T[P]>} {
    return isDefined(obj[key]);
}
