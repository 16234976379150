import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {CreateDataCollectionPersonalityTestV2Mutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {CREATE_DATA_COLLECTION_PERSONALITY_TEST_V2} from './mutations';

export function useCreateDataCollectionPersonalityTestV2(
    locale: string,
    dataCollectionSource: string | null = null
): MutationHookReturnType<CreateDataCollectionPersonalityTestV2Mutation, [string | null]> {
    const [mutate, {error, loading}] = useMutation(CREATE_DATA_COLLECTION_PERSONALITY_TEST_V2);

    const doCreateDataCollectionPersonalityTestV2 = useCallback(
        (externalId: string | null = null) => {
            const createDataCollectionPersonalityTestInput = {
                locale,
                dataCollectionSource,
                externalId
            };
            return mutate({variables: {createDataCollectionPersonalityTestInput}});
        },
        [locale, dataCollectionSource, mutate]
    );

    return [doCreateDataCollectionPersonalityTestV2, {error, loading}];
}
