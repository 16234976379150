import {useIntl} from 'react-intl';

import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {S2} from '@/componentLibrary/components/typography/SubTitles';

import {QUESTION_MESSAGE_IDS} from './constants';
import {FAQ_ANSWERS, FAQ_QUESTIONS} from './messages';
import {QuestionWrapper} from './styled';

export function ApplicationFAQ() {
    const {formatMessage} = useIntl();
    return (
        <>
            {QUESTION_MESSAGE_IDS.map(questionId => (
                <QuestionWrapper key={questionId} data-testid={questionId}>
                    <S2>{formatMessage(FAQ_QUESTIONS[questionId])}</S2>
                    <P2>{formatMessage(FAQ_ANSWERS[questionId])}</P2>
                </QuestionWrapper>
            ))}
        </>
    );
}
