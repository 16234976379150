import {FirstLoadingDot, LoadingDot, SecondLoadingDot, Wrapper} from './styled';

export function LoadingState() {
    return (
        <Wrapper data-testid="dropdown-loading-state">
            <FirstLoadingDot />
            <SecondLoadingDot />
            <LoadingDot />
        </Wrapper>
    );
}
