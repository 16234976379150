import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitContribution.title'
    },
    emptyStateTitle: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitContribution.emptyStateTitle'
    },
    emptyStateDescription: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitContribution.emptyStateDescription'
    }
});
