import {FC, useState} from 'react';

import {Popover} from '@/componentLibrary/components/Tooltips/Popover';
import {WarningTriangle} from '@/componentLibrary/components/WarningTriangle';
import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {getUserName} from '@/utils/misc';

import {PersonAvatarMedium, PersonAvatarSmall} from '../PersonAvatar';
import {DEFAULT_MAX_NUM_USERS} from './constants';
import messages from './messages';
import {
    AvatarWrapper,
    DetailsWrapper,
    Filler,
    Row,
    ShowMoreIcon,
    ShowMoreText,
    UserName,
    UserWrapper,
    Wrapper
} from './styled';
import {PersonAvatarGroupProps} from './types';

export const PersonAvatarGroup: FC<PersonAvatarGroupProps> = ({
    users,
    isSmall = false,
    maxUsers = DEFAULT_MAX_NUM_USERS,
    displayDetails = false
}) => {
    const phrases = useExtractPhraseConstants(messages);
    const [showAllUsersForDetails, setShowAllUsersForDetails] = useState(users.length <= 4);
    const shouldShowAllUsers = users.length <= maxUsers;
    const numberOfDisplayedCircles = shouldShowAllUsers ? users.length : maxUsers;
    const usersToShow = shouldShowAllUsers ? users : users.slice(0, maxUsers - 1);
    const AvatarComponent = isSmall ? PersonAvatarSmall : PersonAvatarMedium;
    const size = isSmall ? 24 : 32;
    const textSize = isSmall ? 12 : 14;

    const renderPersonAvatars = () => (
        <>
            {usersToShow.map((user, index) => (
                <AvatarWrapper index={index} key={user.email}>
                    <AvatarComponent user={user} />
                </AvatarWrapper>
            ))}
            {!shouldShowAllUsers && (
                <AvatarWrapper index={maxUsers - 1} size={size}>
                    <ShowMoreIcon size={size}>
                        <ShowMoreText size={size} textSize={textSize}>
                            +{users.length - maxUsers + 1}
                        </ShowMoreText>
                    </ShowMoreIcon>
                </AvatarWrapper>
            )}
        </>
    );

    const renderDetails = () => {
        const usersForDetails = showAllUsersForDetails ? users : users.slice(0, 4);
        return (
            <DetailsWrapper>
                {usersForDetails.map(user => (
                    <Row key={user.email}>
                        <UserWrapper>
                            <PersonAvatarSmall key={user.email} user={user} />
                            <UserName>{getUserName(user)}</UserName>
                        </UserWrapper>
                        {user.hasNoPersonalityResult && (
                            <WarningTriangle text={phrases.hasNoPersonalityResult} />
                        )}
                    </Row>
                ))}
                {!showAllUsersForDetails && (
                    <PlainButton narrow small onClick={() => setShowAllUsersForDetails(true)}>
                        {phrases.showAll}
                    </PlainButton>
                )}
            </DetailsWrapper>
        );
    };

    if (users.length === 0) {
        return <Filler size={size} />;
    }

    return (
        <Popover
            asChild
            shouldTrigger={displayDetails}
            content={renderDetails()}
            placement="bottom-start"
            openOnHover
            trigger={
                <Wrapper
                    numberOfCircles={numberOfDisplayedCircles}
                    avatarSize={size}
                    displayDetails={displayDetails}
                >
                    {renderPersonAvatars()}
                </Wrapper>
            }
        />
    );
};
