import {useCallback, useContext} from 'react';
import {useIntl} from 'react-intl';

import {LanguageToggle} from '@/componentLibrary/components/Dropdowns/LanguageToggle';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {EVENTS} from '@/services/segment/constants';
import {useTrackEvent} from '@/services/segment/trackEvent';

import {SharedResultsContext} from '@/pages/SharedResults/components/UserResults/hooks/SharedResultsContext';
import {useTestReportDownloadLogic} from '../../hooks/useTestReportDownloadLogic';
import messages from './messages';
import {HeaderWrapper, LanguageDropdownWrapper} from './styled';
import {ReportHeaderProps} from './types';

export function ReportHeader({
    reportType,
    reportUserId,
    reportLocale,
    changeReportLocale,
    printTitle,
    user,
    testId,
    jobPositionId,
    organizationId
}: ReportHeaderProps) {
    const intl = useIntl();
    const trackEvent = useTrackEvent();
    const reportTitle = `${printTitle} - ${user?.firstName} ${user?.lastName}`;
    const buttonText = intl.formatMessage(messages.download);
    const {download, loading} = useTestReportDownloadLogic({
        testId,
        type: reportType,
        userId: user?.id,
        reportTitle,
        reportLocale,
        jobPositionId,
        organizationId
    });
    const downloadReport = useCallback(() => {
        trackEvent(EVENTS.TEST_REPORT_DOWNLOADED, {
            reportType,
            reportUserId
        });
        download();
    }, [download, trackEvent, reportType, reportUserId]);
    const isSharedResultsView = useContext(SharedResultsContext);

    return (
        <HeaderWrapper>
            <LanguageDropdownWrapper>
                <LanguageToggle
                    onLocalSelect={changeReportLocale}
                    selectedLocale={reportLocale}
                    fullWidth
                />
            </LanguageDropdownWrapper>
            {!isSharedResultsView && (
                <PrimaryButton onClick={downloadReport} isLoading={loading}>
                    {buttonText}
                </PrimaryButton>
            )}
        </HeaderWrapper>
    );
}
