import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GetChallengeAssignmentStatusQueryVariables} from '@/api/types/__generated__/graphql';
import {GET_CHALLENGE_ASSIGNMENT_STATUS} from './queries';

export function useChallengeAssignmentStatus({
    jobPositionId,
    userId,
    skip
}: {
    jobPositionId: number;
    userId: number;
    skip?: boolean;
}) {
    const variables: GetChallengeAssignmentStatusQueryVariables = useMemo(
        () => ({jobPositionId, userId}),
        [jobPositionId, userId]
    );

    const {data, error, loading, startPolling, stopPolling, client} = useQuery(
        GET_CHALLENGE_ASSIGNMENT_STATUS,
        {
            variables,
            skip
        }
    );

    const assignmentStatus = useMemo(() => data?.jobApplication?.challengeAssignment, [data]);

    return {
        error,
        loading,
        client,
        startPolling,
        stopPolling,
        assignmentStatus
    };
}
