import styled from 'styled-components';

import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {
    BorderRadiusBaseRounded,
    BorderWidthEmphasis,
    ColorBaseGrey900,
    SpacingMedium,
    SpacingSmall,
    SpacingXlarge,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export interface SpinnerProps {
    isCentered?: boolean;
    size?: string;
}

const spinnerBorder = (size?: string) => {
    const borderMargin = size === 'small' ? BorderWidthEmphasis : SpacingXxxsmall;
    return `${borderMargin} solid ${ColorBaseGrey900}`;
};

export const Spinner = styled.div<SpinnerProps>`
    animation: spin-around 0.5s infinite linear;
    border: ${({size}) => spinnerBorder(size)};
    border-radius: ${BorderRadiusBaseRounded};
    border-top-color: transparent;
    content: '';
    display: block;
    height: ${({size}) => (size === 'small' ? SpacingSmall : SpacingXlarge)};
    width: ${({size}) => (size === 'small' ? SpacingSmall : SpacingXlarge)};
    ${({isCentered}) => isCentered && `margin: ${SpacingMedium} auto;`}
`;

export const FlexSpinner = () => (
    <FlexLayout direction="column" alignItems="center">
        <Spinner />
    </FlexLayout>
);

export const CenteredSpinner = styled(Spinner)`
    margin: auto;
`;
