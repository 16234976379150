import {AuthenticationFormBanner} from 'pages/common/authentication/AuthenticationFormBanner';

import {BannerType} from '@/componentLibrary/components/banners/constants';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import messages from '../../../../messages';

export function ErrorBanner() {
    const phrases = useExtractPhraseConstants(messages);
    return <AuthenticationFormBanner type={BannerType.ERROR} text={phrases.genericError} />;
}
