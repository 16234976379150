import {CHANGE_LOCALE} from './constants';
import {getLocaleState} from './helpers';

export const initialState = getLocaleState();

export function languageProvider(
    state = initialState,
    action: {type: string; locale: string}
) {
    switch (action.type) {
        case CHANGE_LOCALE:
            return Object.assign({}, state, {
                locale: action.locale
            });
        default:
            return state;
    }
}
