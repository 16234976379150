import {defineMessages} from 'react-intl';

export default defineMessages({
    submit: {
        id: 'app.pages.logicTestIrt.pages.logicTesting.submit'
    },
    nextQuestion: {
        id: 'app.pages.logicTestIrt.pages.logicTesting.nextQuestion'
    },
    skipWarning: {
        id: 'app.pages.logicTestIrt.pages.logicTesting.skipWarning'
    },
    blocked: {
        id: 'app.pages.logicTestIrt.pages.logicTesting.blocked'
    },
    blockedDescription: {
        id: 'app.pages.logicTestIrt.pages.logicTesting.blockedDescription'
    }
});
