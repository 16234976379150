import {useCallback} from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';

import {OrganizationAvatar} from '@/componentLibrary/components/avatars/OrganizationAvatar';
import {DestructiveButton} from '@/componentLibrary/components/buttons/DestructiveButton';
import {CardBase} from '@/componentLibrary/components/cards/CardBase';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {Caption} from '@/componentLibrary/components/typography/Captions';
import {DisplaySmaller} from '@/componentLibrary/components/typography/Display';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {RevokeAccessFromOrganizationModal} from './components/RevokeAccessFromOrganizationModal/index';
import {useRevokeAccessFromOrganizationModalLogic} from './hooks';
import messages from './messages';
import {
    OrganizationInfoWrapper,
    OrganizationNameWrapper,
    OrganizationTitleWrapper,
    TestWrapper,
    TestsWrapper
} from './styled';
import {RevokeUserOrganizationSettingProps} from './types';

export function RevokeUserOrganizationSetting({
    userId,
    organization: {
        name,
        id,
        isCandidate,
        isEmployment,
        logo,
        personalityTestCompletedAt,
        logicTestCompletedAt
    }
}: RevokeUserOrganizationSettingProps) {
    const {formatMessage, formatDate} = useIntl();
    const {revokeFromOrganization, modalIsOpen, openModal, closeModal} =
        useRevokeAccessFromOrganizationModalLogic();

    const getCompletedAtMessage = useCallback(
        completedAt => {
            return completedAt
                ? formatMessage(messages.completedAt, {completedAt: formatDate(completedAt)})
                : formatMessage(messages.notCompleted);
        },
        [formatMessage, formatDate]
    );

    const getCandidateOrEmployeeMessage = useCallback(
        (isCandidate, isEmployment) => {
            let messageKey: MessageDescriptor | null = null;
            if (isCandidate && isEmployment) {
                messageKey = messages.candidateAndEmployee;
            }
            if (isCandidate) {
                messageKey = messages.candidate;
            }
            if (isEmployment) {
                messageKey = messages.employee;
            }

            return messageKey ? (
                <Caption color={ColorFgSubtle}>{formatMessage(messageKey)}</Caption>
            ) : null;
        },
        [formatMessage]
    );

    return (
        <>
            <CardBase isActive>
                <FlexLayout direction="row" justifyContent="space-between">
                    <OrganizationInfoWrapper>
                        <OrganizationTitleWrapper>
                            <OrganizationAvatar name={name} image={logo} />
                            <OrganizationNameWrapper>
                                <DisplaySmaller>{name}</DisplaySmaller>
                                {getCandidateOrEmployeeMessage(isCandidate, isEmployment)}
                            </OrganizationNameWrapper>
                        </OrganizationTitleWrapper>
                        <TestsWrapper>
                            <TestWrapper>
                                <div>{formatMessage(messages.personalityTest)}</div>
                                <Caption color={ColorFgSubtle}>
                                    {getCompletedAtMessage(personalityTestCompletedAt)}
                                </Caption>
                            </TestWrapper>
                            <TestWrapper>
                                <div>{formatMessage(messages.logicTest)}</div>
                                <Caption color={ColorFgSubtle}>
                                    {getCompletedAtMessage(logicTestCompletedAt)}
                                </Caption>
                            </TestWrapper>
                        </TestsWrapper>
                    </OrganizationInfoWrapper>
                    <div>
                        <DestructiveButton onClick={openModal.bind(null, id)}>
                            {formatMessage(messages.revokeAccess)}
                        </DestructiveButton>
                    </div>
                </FlexLayout>
            </CardBase>
            {modalIsOpen && userId ? (
                <RevokeAccessFromOrganizationModal
                    userId={userId}
                    organizationId={revokeFromOrganization as number}
                    organizationName={name}
                    closeModal={closeModal}
                />
            ) : null}
        </>
    );
}
