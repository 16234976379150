import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {
    SpacingMedium,
    SpacingSmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const ReasonsWrapper = styled(P2)`
    & p {
        margin-top: ${SpacingSmall};
    }

    & ul {
        margin-top: ${SpacingSmall};
        margin-inline-start: ${SpacingMedium};
    }

    & ul li {
        margin-top: ${SpacingXxsmall};
        list-style-type: disc;
    }
`;
