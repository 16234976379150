export enum CANDIDATES_SORT_KEYS {
    USER = 'user_email',
    FIRST_NAME = 'first_name',
    JOB_POSITION = 'job_position_name',
    APPLICATION_CREATED = 'job_application_created',
    ROLE_FIT = 'role_fit',
    ROLE_FIT_V2 = 'role_fit_v2',
    INTERVIEW_SCORE = 'interview_score',
    TEST_PROFILE_SCORE = 'test_profile_score',
    AI = 'ai',
    AII = 'aii',
    AIII = 'aiii',
    CI = 'ci',
    CII = 'cii',
    CIII = 'ciii',
    EI = 'ei',
    EII = 'eii',
    EIII = 'eiii',
    ESI = 'esi',
    ESII = 'esii',
    ESIII = 'esiii',
    OI = 'oi',
    OII = 'oii',
    OIII = 'oiii',
    CHALLENGE_ASSIGNMENT_AUTO_SCORE_PERCENTAGE = 'challenge_assignment_auto_score_percentage',
    CHALLENGE_ASSIGNMENT_TOTAL_DECISION_COUNT = 'challenge_assignment_total_decision_count',
    CHALLENGE_ASSIGNMENT_PUBLISHED_REVIEW_COUNT = 'challenge_assignment_published_review_count',
    CHALLENGE_ASSIGNMENT_STATUS = 'challenge_assignment_status'
}

export const CANDIDATE_STATUS_FILTER_TYPE = {
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    NOT_ACCEPTED: 'NOT_ACCEPTED'
};

export const CODING_TEST_MODE = {
    CREATE: 'CREATE',
    DUPLICATE: 'DUPLICATE'
} as const;
