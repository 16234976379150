import {AssessmentCriterionTooltip} from '../../AssessmentCriterionTooltip';
import {Overlay} from '../../Overlay';
import {ContributionBar} from './ContributionBar';
import {Wrapper} from './styled';
import {ItemWithTooltipProps} from './types';

export const ItemWithTooltip = ({
    contributionType,
    item,
    barIsBlurred
}: ItemWithTooltipProps) => (
    <AssessmentCriterionTooltip
        contributionTypeId={contributionType.id}
        contributionItem={item}
    >
        <Wrapper>
            <Overlay />
            <ContributionBar
                contributionType={contributionType}
                item={item}
                isBlurred={barIsBlurred}
            />
        </Wrapper>
    </AssessmentCriterionTooltip>
);
