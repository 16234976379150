import qs from 'qs';
import {FC} from 'react';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';

import {ErrorBoundary} from '@/componentLibrary/components/ErrorBoundary';
import {TrackPageView} from '@/services/segment';
import {PAGE_CATEGORIES} from '@/services/segment/constants';

import {LOGIC_TEST_IRT_LINKS} from './links';
import {LogicTest} from './pages/LogicTest';
import {LogicTestReportPage} from './pages/LogicTestReportPage';
import {LogicTestTutorial} from './pages/LogicTestTutorial';
import {StartPage} from './pages/StartPage';

const DEFAULT_REDIRECT = '/o/home';

export const LogicTestIrt: FC = () => {
    const history = useHistory();
    const location = useLocation();

    const goToStartLogicTest = () => {
        let url = `${LOGIC_TEST_IRT_LINKS.startTest()}?redirect=${getRedirectUrlFromSearchParam()}`;
        const organizationId = getOrganizationIdFromSearchParam();
        if (organizationId) {
            url += `&organization_id=${organizationId}`;
        }
        history.push(url);
    };

    const goToTest = (testId: string) => {
        const url = LOGIC_TEST_IRT_LINKS.test(testId);
        history.push(`${url}?redirect=${getRedirectUrlFromSearchParam()}`);
    };

    const getRedirectUrlFromSearchParam = (): string => {
        const params = qs.parse(location.search, {ignoreQueryPrefix: true});
        return params.redirect ? (params.redirect as string) : DEFAULT_REDIRECT;
    };

    const getOrganizationIdFromSearchParam = () => {
        const params = qs.parse(location.search, {ignoreQueryPrefix: true});
        if (!params.organization_id) {
            history.push('/not-found');
        }
        return parseInt(params.organization_id as string, 10);
    };

    const redirect = () => {
        const redirectUrl = getRedirectUrlFromSearchParam();
        history.push(redirectUrl);
    };

    return (
        <Switch>
            <Route
                exact
                path={LOGIC_TEST_IRT_LINKS.tutorial()}
                render={() => (
                    <ErrorBoundary>
                        <TrackPageView
                            name={'Logic test tutorial'}
                            category={PAGE_CATEGORIES.LOGIC_TEST}
                        >
                            <LogicTestTutorial
                                goToStartLogicTest={goToStartLogicTest}
                                goBack={redirect}
                            />
                        </TrackPageView>
                    </ErrorBoundary>
                )}
            />
            <Route
                exact
                path={LOGIC_TEST_IRT_LINKS.startTest()}
                render={() => (
                    <ErrorBoundary>
                        <TrackPageView
                            name="Logic test start"
                            category={PAGE_CATEGORIES.LOGIC_TEST}
                        >
                            <StartPage
                                goToTest={goToTest}
                                shareWithOrganizationId={getOrganizationIdFromSearchParam()}
                                goBack={getRedirectUrlFromSearchParam()}
                            />
                        </TrackPageView>
                    </ErrorBoundary>
                )}
            />
            <Route
                exact
                path={LOGIC_TEST_IRT_LINKS.test(':logicTestId')}
                render={p => (
                    <ErrorBoundary>
                        <TrackPageView name="Logic test" category={PAGE_CATEGORIES.LOGIC_TEST}>
                            <LogicTest
                                logicTestId={p.match.params.logicTestId as string}
                                goBack={redirect}
                                isValidationTest={false}
                            />
                        </TrackPageView>
                    </ErrorBoundary>
                )}
            />
            <Route
                exact
                path={LOGIC_TEST_IRT_LINKS.report(':logicTestId')}
                render={p => (
                    <ErrorBoundary>
                        <TrackPageView
                            name="Logic test report"
                            category={PAGE_CATEGORIES.LOGIC_TEST}
                        >
                            <LogicTestReportPage
                                logicTestId={p.match.params.logicTestId as string}
                                goBack={redirect}
                                displayDemographicsForm
                            />
                        </TrackPageView>
                    </ErrorBoundary>
                )}
            />
            <Route
                exact
                path={LOGIC_TEST_IRT_LINKS.fullReport(':logicTestId')}
                render={p => (
                    <Redirect to={LOGIC_TEST_IRT_LINKS.report(p.match.params.logicTestId)} />
                )}
            />
            <Route path="*" render={() => <Redirect to={LOGIC_TEST_IRT_LINKS.tutorial()} />} />
        </Switch>
    );
};
