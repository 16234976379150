import {useCallback} from 'react';

import {OrganizationAvatar} from '@/componentLibrary/components/avatars/OrganizationAvatar';
import {DestructiveButton} from '@/componentLibrary/components/buttons/DestructiveButton';
import {CardBase} from '@/componentLibrary/components/cards/CardBase';
import {Caption} from '@/componentLibrary/components/typography/Captions';
import {DisplaySmaller} from '@/componentLibrary/components/typography/Display';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {formatDate} from '@/utils/formatDates';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {OrganizationType} from '../UserOrganizationSettings/types';
import {WithdrawApplicationsFromOrganizationModal} from './components/WithdrawApplicationFromOrganizationModal';
import {useWithdrawJobApplicationsFromOrganizationModalLogic} from './hooks';
import messages from './messages';
import {
    OrganizationInfoWrapper,
    OrganizationNameWrapper,
    OrganizationTitleWrapper,
    OrganizationWrapper,
    Overlay
} from './styled';
import {WithdrawUserOrganizationSettingProps} from './types';

export function WithdrawUserOrganizationSetting({
    userId,
    organization
}: WithdrawUserOrganizationSettingProps) {
    const phrases = useExtractPhraseConstants(messages);
    const {withdrawFromOrganization, modalIsOpen, openModal, closeModal} =
        useWithdrawJobApplicationsFromOrganizationModalLogic();

    const getTimeStamp = useCallback(
        ({jobApplicationsWithdrawnAt, acceptedAt}: OrganizationType) => {
            if (jobApplicationsWithdrawnAt) {
                return (
                    <Caption color={ColorFgSubtle}>
                        {phrases.withdrawnAt} {formatDate(jobApplicationsWithdrawnAt)}
                    </Caption>
                );
            }
            return (
                <Caption color={ColorFgSubtle}>
                    {phrases.acceptedAt} {acceptedAt ? formatDate(acceptedAt) : 'N/A'}
                </Caption>
            );
        },
        [phrases]
    );

    const isAcceptedByCandidate = organization.isCandidate && !!organization.acceptedAt;
    if (!isAcceptedByCandidate) {
        return null;
    }

    return (
        <>
            <Overlay isVisible={!!organization.jobApplicationsWithdrawnAt}>
                <CardBase isActive>
                    <OrganizationWrapper>
                        <OrganizationInfoWrapper>
                            <OrganizationTitleWrapper>
                                <OrganizationAvatar
                                    name={organization.name}
                                    image={organization.logo}
                                />
                                <OrganizationNameWrapper>
                                    <DisplaySmaller>{organization.name}</DisplaySmaller>
                                    {getTimeStamp(organization)}
                                </OrganizationNameWrapper>
                            </OrganizationTitleWrapper>
                        </OrganizationInfoWrapper>
                        {organization.jobApplicationsWithdrawnAt ? null : (
                            <div>
                                <DestructiveButton
                                    onClick={openModal.bind(null, organization.id)}
                                >
                                    {phrases.withdrawApplications}
                                </DestructiveButton>
                            </div>
                        )}
                    </OrganizationWrapper>
                </CardBase>
            </Overlay>
            {modalIsOpen && userId ? (
                <WithdrawApplicationsFromOrganizationModal
                    userId={userId}
                    organizationId={withdrawFromOrganization}
                    organizationName={organization.name}
                    closeModal={closeModal}
                />
            ) : null}
        </>
    );
}
