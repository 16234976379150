import {
    ALVA_IMPERSONATION_TOKEN_KEY,
    ALVA_SELECTED_ORGANIZATION_KEY,
    ALVA_TOKEN_KEY
} from './constants';

export const clearAuthTokens = () => {
    if (window.localStorage) {
        window.localStorage.removeItem(ALVA_TOKEN_KEY);
        window.localStorage.removeItem(ALVA_IMPERSONATION_TOKEN_KEY);
        window.localStorage.removeItem(ALVA_SELECTED_ORGANIZATION_KEY);
    }
};

export const setUserAuthToken = (token: string) => {
    if (window.localStorage) {
        window.localStorage.setItem(ALVA_TOKEN_KEY, token);
    }
};

export const clearSelectedOrganization = () => {
    if (window.localStorage) {
        window.localStorage.removeItem(ALVA_SELECTED_ORGANIZATION_KEY);
    }
};
