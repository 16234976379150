import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.assessment.codingTest.scorecardView.title'
    },
    scorecard: {
        id: 'app.assessment.codingTest.scorecardView.scorecard'
    },
    requirementsLabel: {
        id: 'app.assessment.codingTest.requirements.label'
    },
    scoringCriteria: {
        id: 'app.assessment.codingTest.scoringCriteria'
    },
    copied: {
        id: 'app.copyToClipboard.copied'
    },
    copy: {
        id: 'app.copyToClipboard.copy'
    },
    copiedToClipboardError: {
        id: 'app.copyToClipboard.error'
    }
});
