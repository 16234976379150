import {useMemo} from 'react';

import {ConsentType} from '@/api/types/__generated__/graphql';
import {useAuthenticatedUser} from '@/api/users/useAuthenticatedUser';
import {logger} from '@/services/logrocket';
import {isImpersonating} from '@/utils/ajax';

import {CONSENT_TYPES} from '../consent/consentTypes';
import {useStaticLegalContent} from '../staticLegalContent/useStaticLegalContent';
import {AcceptNewConsent} from './AcceptNewConsent';

const REQUIRED_ACCEPTED_TERMS_VERSION = 29;

export function VerifyAcceptedTerms() {
    const options = {withConsents: true};
    const {user, loading: loadingUser, error: userError} = useAuthenticatedUser(options);
    const {
        data: consent,
        loading: loadingConsent,
        error: consentError
    } = useStaticLegalContent({type: CONSENT_TYPES.OLD_TERMS_OF_SERVICE});

    const shouldPromptForNewConsent = useMemo(() => {
        if (!consent || !user || isImpersonating()) {
            return false;
        }
        if (!Array.isArray(user.consents)) {
            // Failed to load consents
            return false;
        }

        if (!user.signedUpAt) {
            // Don't prompt users that has not signed up
            return false;
        }
        try {
            const hasAcceptedRequiredVersion = user.consents.find(item => {
                return (
                    item.isApproved &&
                    window.parseInt(item.version, 10) >= REQUIRED_ACCEPTED_TERMS_VERSION
                );
            });
            return !hasAcceptedRequiredVersion;
        } catch (e) {
            // Error converting the item versions
            logger.error(e);
            return false;
        }
    }, [user, consent]);

    if (loadingUser || loadingConsent || !shouldPromptForNewConsent) {
        return null;
    }

    if (userError) {
        logger.error('Error loading user: ', userError);
        return null;
    }

    if (consentError) {
        logger.error('Error loading consent', consentError);
        return null;
    }

    return <AcceptNewConsent consentType={ConsentType.TERMS_OF_SERVICE} consent={consent} />;
}
