import {defineMessages} from 'react-intl';

export default defineMessages({
    addToJob: {
        id: 'app.assessment.candidatePage.candidateSettings.candidateJobs.addCandidateToJobPosition.jobPositionsList.addToJob'
    },
    added: {
        id: 'app.assessment.candidatePage.candidateSettings.candidateJobs.addCandidateToJobPosition.jobPositionsList.added'
    }
});
