import {S2} from '@/componentLibrary/components/typography';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXsmall, SpacingXxsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        max-width: none;
        margin-top: ${SpacingXsmall};
    }
`;

export const Name = styled(S2)`
    margin-bottom: ${SpacingXxsmall};
`;
