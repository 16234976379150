import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {P2} from '@/componentLibrary/components/typography';

import {CreateCustomItemWrapper} from '../styled';
import {DropdownMenuItem} from './DropdownMenuItem';
import {CreateCustomItemProps} from './types';

export const CreateCustomItem = ({
    isHighlighted,
    getItemProps,
    searchString,
    index,
    onClick
}: CreateCustomItemProps) => (
    <DropdownMenuItem
        {...getItemProps({
            index,
            onClick,
            item: {createCustomItemComponent: true}
        })}
        isHighlighted={isHighlighted}
        components={{
            Wrapper: CreateCustomItemWrapper
        }}
    >
        <Icon icon={Icons.ADD} />
        <P2>
            Create {'"'}
            {searchString}
            {'"'} tag
        </P2>
    </DropdownMenuItem>
);
