import {Redirect, Route, Switch} from 'react-router-dom';

import {ErrorBoundary} from '@/componentLibrary/components/ErrorBoundary';
import {usePersonalityTestIrtLogic} from '@/pages/PersonalityTestIrt/logic';
import {TrackPageView} from '@/services/segment';
import {PAGE_CATEGORIES} from '@/services/segment/constants';

import {PERSONALITY_IRT_LINKS} from './links';
import {PersonalityTestV2} from './pages/PersonalityTest';
import {PersonalityTestCompletedV2} from './pages/PersonalityTestCompleted';
import {PersonalityTestReportPage} from './pages/PersonalityTestReportPage';
import {StartNewPersonalityTest} from './pages/StartNewPersonalityTest';

export function PersonalityTestIrt() {
    const {
        goToTestCompletedScreen,
        goToTestResult,
        getOrganizationIdFromSearchParam,
        getRedirectUrlFromSearchParam,
        redirect,
        getUrlParams
    } = usePersonalityTestIrtLogic();

    return (
        <Switch>
            <Route
                exact
                path={PERSONALITY_IRT_LINKS.getStartTestLink()}
                render={() => (
                    <ErrorBoundary>
                        <TrackPageView
                            name={'Personality test start'}
                            category={PAGE_CATEGORIES.PERSONALITY_TEST}
                        >
                            <StartNewPersonalityTest
                                goBack={getRedirectUrlFromSearchParam() as string}
                                shareWithOrganizationId={
                                    getOrganizationIdFromSearchParam() as number
                                }
                            />
                        </TrackPageView>
                    </ErrorBoundary>
                )}
            />
            <Route
                exact
                path={[
                    PERSONALITY_IRT_LINKS.getLinkToTest({testId: ':personalityTestId'}),
                    PERSONALITY_IRT_LINKS.getLinkToTest({
                        testId: ':personalityTestId',
                        organizationId: ':organizationId'
                    })
                ]}
                render={props => (
                    <ErrorBoundary>
                        <TrackPageView
                            name={'Personality test'}
                            category={PAGE_CATEGORIES.PERSONALITY_TEST}
                        >
                            <PersonalityTestV2
                                goBack={redirect}
                                goToTestCompleted={goToTestCompletedScreen}
                                personalityTestId={getUrlParams(props).personalityTestId}
                                organizationId={getUrlParams(props).organizationId}
                            />
                        </TrackPageView>
                    </ErrorBoundary>
                )}
            />
            <Route
                exact
                path={[
                    PERSONALITY_IRT_LINKS.getTestCompletedLink({
                        testId: ':personalityTestId'
                    }),
                    PERSONALITY_IRT_LINKS.getTestCompletedLink({
                        testId: ':personalityTestId',
                        organizationId: ':organizationId'
                    })
                ]}
                render={props => (
                    <ErrorBoundary>
                        <TrackPageView
                            name={'Personality test completed'}
                            category={PAGE_CATEGORIES.PERSONALITY_TEST}
                        >
                            <PersonalityTestCompletedV2
                                goToTestResult={goToTestResult}
                                personalityTestId={getUrlParams(props).personalityTestId}
                                organizationId={getUrlParams(props).organizationId}
                            />
                        </TrackPageView>
                    </ErrorBoundary>
                )}
            />
            <Route
                exact
                path={[
                    PERSONALITY_IRT_LINKS.getTestResultLink({testId: ':personalityTestId'}),
                    PERSONALITY_IRT_LINKS.getTestResultLink({
                        testId: ':personalityTestId',
                        organizationId: ':organizationId'
                    }),
                    PERSONALITY_IRT_LINKS.getTestResultLink({
                        testId: ':personalityTestId',
                        jobPositionId: ':jobPositionId'
                    })
                ]}
                render={props => (
                    <ErrorBoundary>
                        <TrackPageView
                            name={'Personality test report'}
                            category={PAGE_CATEGORIES.PERSONALITY_TEST}
                        >
                            <PersonalityTestReportPage
                                personalityTestId={getUrlParams(props).personalityTestId}
                                organizationId={getUrlParams(props).organizationId}
                                jobPositionId={getUrlParams(props).jobPositionId}
                                closeLink={getRedirectUrlFromSearchParam() as string}
                                displayDemographicsForm
                            />
                        </TrackPageView>
                    </ErrorBoundary>
                )}
            />
            <Route
                path={'*'}
                render={() => <Redirect to={PERSONALITY_IRT_LINKS.getStartTestLink()} />}
            />
        </Switch>
    );
}
