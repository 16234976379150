import {useIntl} from 'react-intl';

import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {Spinner} from '@/componentLibrary/components/spinners';
import {capValue, roundValue} from '@/utils/misc';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {useCandidateRoleFitContext} from '../../../context';
import {SCORE_TYPE} from '../constants';
import {useTestResultsLogic} from '../hooks/useTestResultsLogic';
import messages from '../messages';
import {List, ListWrapper} from '../styled';

export function LogicTestList() {
    const {formatMessage} = useIntl();
    const {jobApplication, userId} = useCandidateRoleFitContext();

    const {loadingUserResults, userResultsError, refetchUserResults, logicTestResults} =
        useTestResultsLogic(userId);

    if (loadingUserResults) {
        return <Spinner />;
    }

    if (userResultsError) {
        return <ErrorState error={userResultsError} refetch={refetchUserResults} />;
    }

    const assessmentScore = jobApplication?.roleFitV2?.assessmentScores.find(
        ({assessmentMethodType}) => assessmentMethodType === SCORE_TYPE.LOGIC_TEST
    );

    if (!isDefined(assessmentScore) || !isDefined(logicTestResults?.logicalAbilityEstimate)) {
        return null;
    }

    const hasResults = !assessmentScore.missingResults;
    const standardScore = roundValue(
        capValue(logicTestResults.logicalAbilityEstimate?.mu, 1, 10),
        0
    );

    return (
        <ListWrapper>
            {hasResults && (
                <List>
                    <li>
                        {formatMessage(messages.logicalAbility)}: {standardScore}/10
                    </li>
                </List>
            )}
        </ListWrapper>
    );
}
