import {LanguageToggle} from '../../Dropdowns/LanguageToggle';
import {LimitedWidthColumns} from '../Columns';
import {
    BackgroundLayout,
    ContentWrapper,
    LanguageToggleWrapper,
    LeftHalfWrapper,
    Logo,
    LogoWrapper,
    RightHalfWrapper,
    Wrapper,
    columnsCss,
    formColumnCss,
    instructionColumnCss
} from './styled';
import {InstructionLayoutProps} from './types';

export const InstructionLayout: React.FC<InstructionLayoutProps> = ({
    children,
    instruction,
    loading = false,
    withLanguageToggle = true
}) => {
    const renderForm = () => children;

    const renderDescription = () => (
        <div>
            {instruction}
            <LogoWrapper>
                <Logo />
            </LogoWrapper>
        </div>
    );

    return (
        <Wrapper>
            <BackgroundLayout>
                <LeftHalfWrapper />
                <RightHalfWrapper />
            </BackgroundLayout>
            <ContentWrapper>
                {!loading && (
                    <LimitedWidthColumns
                        noMargin
                        columnsCss={columnsCss}
                        columns={[
                            {
                                size: 4,
                                render: renderForm,
                                offset: 0,
                                offsetRight: 1,
                                css: formColumnCss
                            },
                            {
                                size: 4,
                                render: renderDescription,
                                offset: 1,
                                offsetRight: 0,
                                css: instructionColumnCss
                            }
                        ]}
                    />
                )}
            </ContentWrapper>
            {withLanguageToggle && (
                <LanguageToggleWrapper>
                    <LanguageToggle fullWidth />
                </LanguageToggleWrapper>
            )}
        </Wrapper>
    );
};
