import {
    AIII_MESSAGES,
    AII_MESSAGES,
    AI_MESSAGES,
    CIII_MESSAGES,
    CII_MESSAGES,
    CI_MESSAGES,
    EIII_MESSAGES,
    EII_MESSAGES,
    EI_MESSAGES,
    ESIII_MESSAGES,
    ESII_MESSAGES,
    ESI_MESSAGES,
    OIII_MESSAGES,
    OII_MESSAGES,
    OI_MESSAGES
} from './messages';

export type PersonalityFacetKey =
    | 'AI'
    | 'AII'
    | 'AIII'
    | 'CI'
    | 'CII'
    | 'CIII'
    | 'EI'
    | 'EII'
    | 'EIII'
    | 'ESI'
    | 'ESII'
    | 'ESIII'
    | 'OI'
    | 'OII'
    | 'OIII';

export type MessageStructure = {
    bulletsAverage: {id: string};
    bulletsHigh: {id: string};
    bulletsLow: {id: string};
    bulletsVeryHigh: {id: string};
    bulletsVeryLow: {id: string};
    descriptionLower: {id: string};
    descriptionUpper: {id: string};
    factor: {id: string};
    labelLower: {id: string};
    labelUpper: {id: string};
    title: {id: string};
};

type PersonalityFacetMessages = {
    [key in PersonalityFacetKey]: MessageStructure; // assuming each factor has an array of strings as messages, modify as needed
};

export const PERSONALITY_FACET_MESSAGES: PersonalityFacetMessages = {
    AI: AI_MESSAGES,
    AII: AII_MESSAGES,
    AIII: AIII_MESSAGES,
    CI: CI_MESSAGES,
    CII: CII_MESSAGES,
    CIII: CIII_MESSAGES,
    EI: EI_MESSAGES,
    EII: EII_MESSAGES,
    EIII: EIII_MESSAGES,
    ESI: ESI_MESSAGES,
    ESII: ESII_MESSAGES,
    ESIII: ESIII_MESSAGES,
    OI: OI_MESSAGES,
    OII: OII_MESSAGES,
    OIII: OIII_MESSAGES
};
