import {useCallback, useState} from 'react';

import {ERROR_TYPES} from '@/pages/User/components/RecommendationRelevanceForm/constants';
import {RemoveValidationError} from '@/pages/common/RelevanceForm/types';

type Option = {
    id: string;
    name: string;
};

export function useInformationLogic(removeValidationError: RemoveValidationError) {
    const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
    const [selectedCities, setSelectedCities] = useState<Option[]>([]);
    const [selectedWorkLocations, setSelectedWorkLocations] = useState<string[]>([]);

    const handleLanguagesSelection = useCallback(
        options => {
            if (setSelectedLanguages.length > 0) {
                removeValidationError(ERROR_TYPES.LANGUAGES);
            }
            setSelectedLanguages(options.map((option: Option) => option.id));
        },
        [removeValidationError]
    );

    const handleCitiesSelection = useCallback(
        newSelectedCities => {
            if (newSelectedCities?.length > 0) {
                removeValidationError(ERROR_TYPES.CITIES);
            }
            setSelectedCities(newSelectedCities);
        },
        [removeValidationError]
    );

    const handleWorkLocationSelection = useCallback(
        (option: string) => {
            const existingWorkLocations = [...selectedWorkLocations];
            const index = existingWorkLocations.indexOf(option);
            if (index > -1) {
                existingWorkLocations.splice(index, 1);
            } else {
                existingWorkLocations.push(option);
                removeValidationError(ERROR_TYPES.WORK_LOCATIONS);
            }

            setSelectedWorkLocations(existingWorkLocations);
        },
        [selectedWorkLocations, removeValidationError]
    );

    return {
        selectedWorkLocations,
        handleWorkLocationSelection,
        selectedLanguages,
        setSelectedLanguages,
        handleLanguagesSelection,
        selectedCities,
        setSelectedCities,
        handleCitiesSelection
    };
}
