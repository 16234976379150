import {defineMessages} from 'react-intl';

export default defineMessages({
    emailBounced: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.emailBounced'
    },
    emailExcluded: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.emailExcluded'
    },
    emailExcludedText: {
        id: 'app.assessment.jobPosition.sendInvitationsModal.emailExcludedText'
    }
});
