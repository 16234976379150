import {useMemo} from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';

export type PhraseConstants = {[K in keyof Record<string, MessageDescriptor>]: string};

export function useExtractPhraseConstants<T extends Record<string, MessageDescriptor>>(
    messages: T
) {
    const intl = useIntl();
    const constants: {[K in keyof T]: string} = useMemo(() => {
        return Object.keys(messages).reduce(
            (acc, key) => {
                acc[key as keyof T] = intl.formatMessage(messages[key]);
                return acc;
            },
            {} as {[K in keyof T]: string}
        );
    }, [intl, messages]);

    return constants;
}
