import styled, {css} from 'styled-components';

import {ColorFgDefault} from '@/componentLibrary/tokens/variables';

import alvaLogo from './assets/whiteAlvaBrand.svg';

export const columnsCss = css`
    align-items: stretch;

    @media only screen and (max-width: 768px) {
        min-height: 100%;
    }
`;

export const formColumnCss = css`
    overflow-y: auto;

    @media only screen and (max-width: 768px) {
        padding: 0 24px;
        margin-bottom: 24px;
        background: white;
    }
`;

export const instructionColumnCss = css`
    overflow-y: auto;

    @media only screen and (max-width: 768px) {
        padding: 0 24px;
        background: ${ColorFgDefault};
    }
`;

export const Wrapper = styled.div`
    position: relative;
    min-height: 100vh;
`;

export const LanguageToggleWrapper = styled.div`
    top: 12px;
    left: 24px;
    position: absolute;
    width: 220px;

    @media only screen and (max-width: 768px) {
        top: 24px;
        left: initial;
        right: 24px;
    }
`;

export const BackgroundLayout = styled.div`
    min-height: 100%;
    position: absolute;
    inset: 0;
    display: flex;
    flex: 1 1;

    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

export const LeftHalfWrapper = styled.div`
    flex: 0 0 50%;
    background: white;
`;

export const RightHalfWrapper = styled.div`
    flex: 0 0 50%;
    background: #282828;
`;

export const ContentWrapper = styled.div`
    position: relative;
`;

export const LogoWrapper = styled.div`
    margin: 48px 0 24px;
    display: flex;
    justify-content: flex-end;
`;

export const Logo = styled.div`
    height: 35px;
    width: 128px;
    background: center no-repeat url("${alvaLogo}");
    background-size: contain;
`;
