import {Section} from '@/componentLibrary/components/layout/Section';
import {H4} from '@/componentLibrary/components/typography';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import messages from '../../messages';

type Props = {
    refetch: () => void;
};

export function ErrorPage({refetch}: Props) {
    const phrases = useExtractPhraseConstants(messages);

    return <Section header={<H4>{phrases.title}</H4>} error={{reload: refetch}} />;
}
