import {P2} from '../../typography/Paragraphs';
import {useRadioButtonLogic} from './hooks';
import {Label, RadioButtonInput, Wrapper} from './styled';
import {RadioButtonProps} from './types';

export const RadioButton = <T extends string>({
    value,
    isDisabled = false,
    isSelected = false,
    isDefaultSelected,
    label,
    name,
    onSelect = () => null,
    withMargin = false
}: RadioButtonProps<T>) => {
    const {radioButtonIsChecked, handleChange} = useRadioButtonLogic<T>({
        isDefaultSelected,
        value,
        onSelect,
        isSelected
    });

    const input = (
        <RadioButtonInput
            type="radio"
            value={value}
            hasLabel={!!label}
            name={name}
            onChange={handleChange}
            checked={radioButtonIsChecked}
            disabled={isDisabled}
        />
    );
    const stringLabel = <P2>{label}</P2>;

    return (
        <Wrapper withMargin={withMargin}>
            {label ? (
                <Label isDisabled={isDisabled}>
                    {input}
                    {typeof label === 'string' ? stringLabel : label}
                </Label>
            ) : (
                input
            )}
        </Wrapper>
    );
};
