import {useCallback, useMemo} from 'react';
import {FormattedDate, FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router-dom';

import {useOrganizationInformation} from '@/api/organizations/useOrganizationInformation';
import {GetMyTestsProgressQuery, User} from '@/api/types/__generated__/graphql';
import {useUpdateUserRecommendationSettings} from '@/api/users/useUpdateUserRecommendationSettings';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {LOGIC_TEST_IRT_LINKS} from '@/pages/LogicTestIrt/links';
import {PERSONALITY_IRT_LINKS} from '@/pages/PersonalityTestIrt/links';
import {userLinks} from '@/pages/User/links';
import {logger} from '@/services/logrocket';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {TEST_PROGRESS} from '../constants';
import messages from '../messages';

export function useTestsForJobPositionsLogic(
    user: GetMyTestsProgressQuery['me'],
    loadingUser: boolean,
    organizationId: number,
    isPersonalityTestRequired: boolean,
    isLogicTestIrtRequired: boolean,
    jobApplicationCompletedAt: string | null,
    codingTestsAllCompleted: boolean,
    isLeadershipEnabled: boolean
) {
    const history = useHistory();
    const {organization} = useOrganizationInformation(organizationId);
    const [updateUserRecommendationSettings] = useUpdateUserRecommendationSettings(true);

    const jobApplicationUrl = useMemo(() => {
        return userLinks.jobApplication(organizationId);
    }, [organizationId]);

    const isLogicTestSharable = useMemo(() => {
        const logicTestProgress = user?.logicTestProgress;

        if (!isDefined(logicTestProgress)) {
            return false;
        }

        const hasNoSharedTestAndHasValidCompletedTest =
            !logicTestProgress.sharedLogicTestId && logicTestProgress.latestCompletedTestId;

        const hasExpiredSharedTestAndValidCompletedTest =
            logicTestProgress.isSharedLogicTestExpired &&
            logicTestProgress.latestCompletedTestId;

        return (
            hasNoSharedTestAndHasValidCompletedTest ||
            hasExpiredSharedTestAndValidCompletedTest
        );
    }, [user]);

    const logicTestIrtStatus = useMemo(() => {
        const logicTestIrt = user ? user.logicTestProgress : null;

        if (isLogicTestSharable) {
            return {status: TEST_PROGRESS.SHAREABLE};
        }

        if (logicTestIrt?.isSharedLogicTestExpired) {
            return {
                status: TEST_PROGRESS.EXPIRED,
                link: `${LOGIC_TEST_IRT_LINKS.tutorial()}?redirect=${jobApplicationUrl}&organization_id=${organizationId}`
            };
        }

        if (logicTestIrt && logicTestIrt.sharedLogicTestId) {
            const sharedTestId = logicTestIrt.sharedLogicTestId;
            return logicTestIrt.isSharedLogicTestCompleted
                ? {
                      status: TEST_PROGRESS.COMPLETED,
                      link: `${LOGIC_TEST_IRT_LINKS.report(
                          sharedTestId
                      )}?redirect=${jobApplicationUrl}`
                  }
                : {
                      status: TEST_PROGRESS.IN_PROGRESS,
                      link: `${LOGIC_TEST_IRT_LINKS.test(
                          sharedTestId
                      )}?redirect=${jobApplicationUrl}`
                  };
        }

        return {
            status: TEST_PROGRESS.NOT_STARTED,
            link: `${LOGIC_TEST_IRT_LINKS.tutorial()}?redirect=${jobApplicationUrl}&organization_id=${organizationId}`
        };
    }, [jobApplicationUrl, organizationId, user, isLogicTestSharable]);

    const startNewLogicTestIrt = useCallback(() => {
        const url = `${LOGIC_TEST_IRT_LINKS.tutorial()}?redirect=${jobApplicationUrl}&organization_id=${organizationId}`;
        history.push(url);
    }, [history, jobApplicationUrl, organizationId]);

    const isPersonalityTestSharable = useMemo(() => {
        const personalityTestProgress = user?.personalityTestProgress;

        if (!isDefined(personalityTestProgress)) {
            return false;
        }

        const hasNoSharedTestAndHasValidCompletedTest =
            !personalityTestProgress.sharedPersonalityTestId &&
            personalityTestProgress.latestCompletedTestId;

        const hasExpiredSharedTestAndHasValidCompletedTest =
            personalityTestProgress.isSharedPersonalityTestExpired &&
            personalityTestProgress.latestCompletedTestId;

        return (
            hasNoSharedTestAndHasValidCompletedTest ||
            hasExpiredSharedTestAndHasValidCompletedTest
        );
    }, [user]);

    const personalityTestStatus = useMemo(() => {
        const personalityTest = user ? user.personalityTestProgress : null;

        if (isPersonalityTestSharable) {
            return {status: TEST_PROGRESS.SHAREABLE};
        }

        if (personalityTest?.isSharedPersonalityTestExpired) {
            return {
                status: TEST_PROGRESS.EXPIRED,
                link: `${PERSONALITY_IRT_LINKS.getStartTestLink()}?redirect=${jobApplicationUrl}&organization_id=${organizationId}`
            };
        }

        if (personalityTest && personalityTest.sharedPersonalityTestId) {
            const sharedTestId = personalityTest.sharedPersonalityTestId;
            return personalityTest.isSharedPersonalityTestCompleted
                ? {
                      status: TEST_PROGRESS.COMPLETED,
                      link: `${PERSONALITY_IRT_LINKS.getTestResultLink({
                          testId: sharedTestId,
                          organizationId: organizationId.toString()
                      })}?redirect=${jobApplicationUrl}`
                  }
                : {
                      status: TEST_PROGRESS.IN_PROGRESS,
                      link: `${PERSONALITY_IRT_LINKS.getLinkToTest({
                          testId: sharedTestId,
                          organizationId: organizationId.toString()
                      })}?redirect=${jobApplicationUrl}`
                  };
        }

        return {
            status: TEST_PROGRESS.NOT_STARTED,
            link: `${PERSONALITY_IRT_LINKS.getStartTestLink()}?redirect=${jobApplicationUrl}&organization_id=${organizationId}`
        };
    }, [isPersonalityTestSharable, jobApplicationUrl, organizationId, user]);

    const startNewPersonalityTest = useCallback(() => {
        const url = `${PERSONALITY_IRT_LINKS.getStartTestLink()}?redirect=${jobApplicationUrl}&organization_id=${organizationId}`;
        history.push(url);
    }, [history, jobApplicationUrl, organizationId]);

    const testIsShareable = useMemo(() => {
        const personalityTestIsShareable =
            personalityTestStatus.status === TEST_PROGRESS.SHAREABLE;
        const logicTestIsShareable = logicTestIrtStatus.status === TEST_PROGRESS.SHAREABLE;
        return personalityTestIsShareable || logicTestIsShareable;
    }, [logicTestIrtStatus, personalityTestStatus]);

    const allNeededTestsAreCompleted = useMemo(() => {
        const personalityTestIsCompleted = isPersonalityTestRequired
            ? personalityTestStatus.status === TEST_PROGRESS.COMPLETED
            : true;
        const logicTestIrtIsCompleted = isLogicTestIrtRequired
            ? logicTestIrtStatus.status === TEST_PROGRESS.COMPLETED
            : true;
        return (
            personalityTestIsCompleted && logicTestIrtIsCompleted && codingTestsAllCompleted
        );
    }, [
        logicTestIrtStatus,
        personalityTestStatus,
        isLogicTestIrtRequired,
        isPersonalityTestRequired,
        codingTestsAllCompleted
    ]);

    const showBanner = useMemo(() => {
        return testIsShareable || allNeededTestsAreCompleted;
    }, [allNeededTestsAreCompleted, testIsShareable]);

    const bannerTitle = useMemo(() => {
        return allNeededTestsAreCompleted ? (
            <FormattedMessage {...messages.bannerTitleSuccess} />
        ) : null;
    }, [allNeededTestsAreCompleted]);

    const bannerDescription = useMemo(() => {
        if (!organization || !organization.name) {
            return allNeededTestsAreCompleted ? (
                <FormattedMessage {...messages.bannerDescriptionSuccessNoOrgName} />
            ) : (
                <FormattedMessage {...messages.bannerSharePreviousDescription} />
            );
        }

        if (allNeededTestsAreCompleted) {
            if (jobApplicationCompletedAt) {
                return (
                    <FormattedDate value={Date.parse(jobApplicationCompletedAt)}>
                        {(date: string) => (
                            <FormattedMessage
                                values={{
                                    organizationName: organization.name,
                                    completedAt: date
                                }}
                                {...messages.bannerDescriptionSuccessWithCompletionDate}
                            />
                        )}
                    </FormattedDate>
                );
            }
            return (
                <FormattedMessage
                    {...messages.bannerDescriptionSuccess}
                    values={{organizationName: organization.name}}
                />
            );
        }

        return <FormattedMessage {...messages.bannerSharePreviousDescription} />;
    }, [organization, allNeededTestsAreCompleted, jobApplicationCompletedAt]);

    const allowedToTakeNewLogicTest = useMemo(() => {
        if (!user || !user.logicTestProgress) {
            return false;
        }

        return !user.logicTestProgress.notAllowedToTakeNewTest;
    }, [user]);

    const allowedToTakeNewPersonalityTest = useMemo(() => {
        if (!user || !user.personalityTestProgress) {
            return false;
        }

        return !user.personalityTestProgress.notAllowedToTakeNewTest;
    }, [user]);

    const enableJobRecommendationForUser = useCallback(() => {
        updateUserRecommendationSettings({
            userId: (user as User).id,
            recommendationsEnabled: true
        })
            .then(({data}) => {
                if (!data?.updateUserRecommendationSettings?.ok) {
                    throw new Error(
                        data?.updateUserRecommendationSettings?.errorMessage ?? ''
                    );
                }
            })
            .catch(error => {
                logger.error('Failed to update user recommendation settings', error.message);
            });
    }, [updateUserRecommendationSettings, user]);

    const phrases = useExtractPhraseConstants(messages);

    const personalityTestSubtitle = useMemo(
        () => (isLeadershipEnabled ? phrases.personalityTestSubtitle : undefined),
        [phrases, isLeadershipEnabled]
    );

    const personalityTestDescription = useMemo(
        () =>
            isLeadershipEnabled
                ? phrases.personalityTestLeadershipReportDescription
                : phrases.personalityTestDescription,
        [phrases, isLeadershipEnabled]
    );

    const displayCandidateServicesConsentBanner = useMemo(() => {
        return !loadingUser && !user?.registeredAt;
    }, [loadingUser, user]);

    return {
        personalityTestStatus,
        logicTestIrtStatus,
        allNeededTestsAreCompleted,
        showBanner,
        bannerTitle,
        bannerDescription,
        startNewLogicTestIrt,
        startNewPersonalityTest,
        allowedToTakeNewLogicTest,
        allowedToTakeNewPersonalityTest,
        enableJobRecommendationForUser,
        personalityTestSubtitle,
        personalityTestDescription,
        phrases,
        organizationName: organization?.name,
        displayCandidateServicesConsentBanner
    };
}
