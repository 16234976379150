import {EmptyState} from '@/componentLibrary/blocks/EmptyState';
import {OverviewHeader} from '@/componentLibrary/blocks/testReports/PersonalityTest/components/OverviewHeader';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import messages from './messages';

export function PersonalityEmptyState() {
    const phrases = useExtractPhraseConstants(messages);

    return (
        <>
            <OverviewHeader disabled />
            <EmptyState titleText={phrases.title} contentText={phrases.description} />
        </>
    );
}
