import {useApolloClient} from '@apollo/client';
import {useCallback, useState} from 'react';

import {useCheckLoggedIn} from '@/api/auth/useCheckLoggedIn';
import {useLoginUserWithSso} from '@/api/auth/useLoginUserWithSso';
import {useSsoRedirectionLogic} from '@/pages/Login/hooks/useSsoRedirectionLogic';
import {SsoToken} from '@/pages/SignupV2/components/types';
import {useRequestCreateFreeTrial} from '@/pages/SignupV2/hooks/useRequestCreateFreeTrial';
import {ALVA_SIGNUP_KEY} from '@/services/auth/constants';
import {setUserAuthToken} from '@/services/auth/utils';

export type CreateFreeTrialData = {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    organizationName: string;
    referralSource: string;
    trackingInfo?: string;
    termsOfServiceVersion?: string;
};

export enum CreationResult {
    UNSENT = 0,
    LOADING,
    OK = 2,
    USER_ALREADY_ADMIN_IN_ORGANIZATION,
    RECAPTCHA_TOKEN_ERROR,
    ERROR = 5
}

export function useCreateFreeTrialLogic(
    creationData: CreateFreeTrialData,
    ssoToken?: SsoToken
) {
    const [result, setResult] = useState<CreationResult>(CreationResult.UNSENT);
    const client = useApolloClient();
    const {redirectToUrl, redirectToLogin} = useSsoRedirectionLogic();

    const {refetchLoginStatus} = useCheckLoggedIn();
    const [loginUserWithSso] = useLoginUserWithSso(
        ssoToken?.ssoToken ?? null,
        ssoToken?.ssoProvider
    );
    const [requestCreateFreeTrial, {loading}] = useRequestCreateFreeTrial(
        creationData,
        ssoToken
    );
    const [userId, setUserId] = useState<number>();

    const setAccountSignup = useCallback(() => {
        if (window.localStorage) {
            window.localStorage.setItem(ALVA_SIGNUP_KEY, Date.now().toString());
        }
    }, []);

    const clearSsoDataInSessionStorage = useCallback(() => {
        window.sessionStorage.removeItem('SSO_AUTH_DATA');
    }, []);

    const doSsoSignin = useCallback(() => {
        loginUserWithSso()
            .then(({data}) => {
                const {ok, token, errorMessage} = data?.loginUserWithSso ?? {};
                if (ok && token) {
                    setUserAuthToken(token);
                    refetchLoginStatus()
                        .then(() => {
                            client.resetStore().then(() => {
                                setResult(CreationResult.OK);
                                setAccountSignup();
                                redirectToUrl();
                            });
                        })
                        .catch(e => {
                            throw new Error(e.message);
                        });
                } else {
                    throw new Error(errorMessage ?? '');
                }
            })
            .catch(e => redirectToLogin(e.message));
    }, [
        loginUserWithSso,
        refetchLoginStatus,
        client,
        redirectToUrl,
        redirectToLogin,
        setAccountSignup
    ]);

    const createFreeTrial = useCallback(
        (token: string) => {
            setResult(CreationResult.LOADING);
            requestCreateFreeTrial(token)
                .then(({data}) => {
                    const {
                        ok,
                        userId: responseUserId,
                        errorMessage
                    } = data?.requestFreeTrial ?? {};
                    if (ok) {
                        if (ssoToken) {
                            doSsoSignin();
                        } else if (responseUserId) {
                            clearSsoDataInSessionStorage();
                            setUserId(responseUserId);
                            setResult(CreationResult.OK);
                        }
                    } else if (
                        errorMessage === 'USER_ALREADY_ADMIN_IN_ORGANIZATION' &&
                        responseUserId
                    ) {
                        setUserId(responseUserId);
                        setResult(CreationResult.USER_ALREADY_ADMIN_IN_ORGANIZATION);
                    } else if (errorMessage === 'RECAPTCHA_TOKEN_ERROR') {
                        setResult(CreationResult.RECAPTCHA_TOKEN_ERROR);
                    } else {
                        setResult(CreationResult.UNSENT);
                    }
                })
                .catch(e => {
                    console.error(e);
                    setResult(CreationResult.ERROR);
                });
        },
        [doSsoSignin, requestCreateFreeTrial, ssoToken, clearSsoDataInSessionStorage]
    );

    return {createFreeTrial, result, loading, userId, doSsoSignin};
}
