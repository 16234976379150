// These constants are crucial for correct ratio when generating SVG
// Make sure to update mask values if you change these
import {
    greyColorTokensHex,
    secondaryColorTokens
} from '@/componentLibrary/tokens/legacyColors';

export const DEFAULT_HEIGHT = 68;
export const DEFAULT_WIDTH = 414;
export const DEFAULT_BLOCK_WIDTH = 39.4;
export const AVERAGE_WIDTH = 335;
export const MINIMUM_WIDTH = 280;
export const DEFAULT_BLOCK_COLOR = greyColorTokensHex.grey800;
export const getDefaultRangeColor = (opacity: number) =>
    secondaryColorTokens.brown000(opacity);

export const BLOCKS = [
    {
        score: 1,
        width: DEFAULT_BLOCK_WIDTH,
        height: DEFAULT_HEIGHT,
        transform: `matrix(1 0 0 -1 0 ${DEFAULT_HEIGHT})`
    },
    {
        score: 2,
        width: DEFAULT_BLOCK_WIDTH,
        height: DEFAULT_HEIGHT,
        transform: `matrix(1 0 0 -1 ${DEFAULT_WIDTH / 10} ${DEFAULT_HEIGHT})`
    },
    {
        score: 3,
        width: DEFAULT_BLOCK_WIDTH,
        height: DEFAULT_HEIGHT,
        transform: `matrix(1 0 0 -1 ${(DEFAULT_WIDTH * 2) / 10} ${DEFAULT_HEIGHT})`
    },
    {
        score: 4,
        width: DEFAULT_BLOCK_WIDTH,
        height: DEFAULT_HEIGHT,
        transform: `matrix(1 0 0 -1 ${(DEFAULT_WIDTH * 3) / 10} ${DEFAULT_HEIGHT})`
    },
    {
        score: 5,
        width: DEFAULT_BLOCK_WIDTH,
        height: DEFAULT_HEIGHT,
        transform: `matrix(1 0 0 -1 ${(DEFAULT_WIDTH * 4) / 10} ${DEFAULT_HEIGHT})`
    },
    {
        score: 6,
        width: DEFAULT_BLOCK_WIDTH,
        height: DEFAULT_HEIGHT,
        transform: `matrix(1 0 0 -1 ${(DEFAULT_WIDTH * 5) / 10} ${DEFAULT_HEIGHT})`
    },
    {
        score: 7,
        width: DEFAULT_BLOCK_WIDTH,
        height: DEFAULT_HEIGHT,
        transform: `matrix(1 0 0 -1 ${(DEFAULT_WIDTH * 6) / 10} ${DEFAULT_HEIGHT})`
    },
    {
        score: 8,
        width: DEFAULT_BLOCK_WIDTH,
        height: DEFAULT_HEIGHT,
        transform: `matrix(1 0 0 -1 ${(DEFAULT_WIDTH * 7) / 10} ${DEFAULT_HEIGHT})`
    },
    {
        score: 9,
        width: DEFAULT_BLOCK_WIDTH,
        height: DEFAULT_HEIGHT,
        transform: `matrix(1 0 0 -1 ${(DEFAULT_WIDTH * 8) / 10} ${DEFAULT_HEIGHT})`
    },
    {
        score: 10,
        width: DEFAULT_BLOCK_WIDTH,
        height: DEFAULT_HEIGHT,
        transform: `matrix(1 0 0 -1 ${(DEFAULT_WIDTH * 9) / 10} ${DEFAULT_HEIGHT})`
    }
];

export const POSSIBLE_RESULT_VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const MINIMUM_SCORE = 0;
export const MAXIMUM_SCORE = 1;
export const INTERPOLATION_SLOPE = 0.25;
