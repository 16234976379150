import {REJECT_REASON_OPTIONS} from 'pages/User/components/JobRecommendations/components/ActiveJobRecommendation/components/RejectJobRecommendation/constants';
import messages from 'pages/User/components/JobRecommendations/components/ActiveJobRecommendation/components/RejectJobRecommendation/messages';
import {useCallback, useState} from 'react';

import {
    RejectReason,
    useRejectJobRecommendation
} from '@/api/jobRecommendations/useRejectJobRecommendation';
import {Modal} from '@/componentLibrary/blocks/Modals/Modal/index';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {logger} from '@/services/logrocket';
import {RejectJobRecommendationBody} from './components/RejectJobRecommendationBody';
import {RejectJobRecommendationProps} from './types';

export function RejectJobRecommendation({
    jobRecommendationId,
    jobName,
    companyName,
    close
}: RejectJobRecommendationProps) {
    const phrases = useExtractPhraseConstants(messages);
    const [rejectReason, setRejectReason] = useState({} as RejectReason);
    const [freeText, setFreeText] = useState('');
    const [rejectReasonInputErrors, setRejectReasonErrors] = useState({
        rejectReasonNotValid: false,
        freeTextNotValid: false
    });

    const handleSubmit = () => {
        if (Object.keys(rejectReason).length === 0) {
            setRejectReasonErrors({
                rejectReasonNotValid: true,
                freeTextNotValid: false
            });
            return;
        }

        if (
            rejectReason?.id === REJECT_REASON_OPTIONS.OTHER_REASON &&
            freeText?.length === 0
        ) {
            setRejectReasonErrors({
                rejectReasonNotValid: false,
                freeTextNotValid: true
            });
            return;
        }

        doRejectJobRecommendation();
    };

    const [rejectJobRecommendation, {loading}] = useRejectJobRecommendation(
        jobRecommendationId,
        rejectReason,
        freeText
    );
    const doRejectJobRecommendation = useCallback(() => {
        rejectJobRecommendation()
            .then(({data}) => {
                const {ok, errorMessage: error} = data?.rejectJobRecommendation ?? {};
                if (ok) {
                    close();
                } else {
                    throw new Error(error ?? 'Unknown error in RejectJobRecommendation');
                }
            })
            .catch(logger.error);
    }, [rejectJobRecommendation, close]);

    return (
        <Modal
            close={close}
            headerTitle={phrases.title}
            footer={{
                leftSecondaryAction: (
                    <SecondaryButton onClick={close}>{phrases.cancel}</SecondaryButton>
                ),
                primaryAction: (
                    <PrimaryButton onClick={handleSubmit} isLoading={loading}>
                        {phrases.dismissRecommendation}
                    </PrimaryButton>
                )
            }}
        >
            <RejectJobRecommendationBody
                jobName={jobName}
                companyName={companyName}
                setRejectReason={setRejectReason}
                rejectReason={rejectReason}
                setFreeText={setFreeText}
                rejectReasonInputErrors={rejectReasonInputErrors}
                setRejectReasonErrors={setRejectReasonErrors}
            />
        </Modal>
    );
}
