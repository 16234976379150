import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_PERSONALITY_TEST_V2} from './queries';

export function usePersonalityTestV2(personalityTestId: string, withStatus: boolean = false) {
    const {data, error, loading, refetch} = useQuery(GET_PERSONALITY_TEST_V2, {
        variables: {id: personalityTestId, withsStatus: withStatus},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    const personalityTestV2 = useMemo(() => data?.personalityTestV2 ?? null, [data]);
    return {error, loading, personalityTestV2, refetch};
}
