import {useCallback, useEffect, useState} from 'react';

import {OrganizationOktaConfiguration} from '@/api/types/__generated__/graphql';
import {AuthenticationMethod} from '@/services/auth/constants';
import {SsoProvider, SsoProviderType} from '@/services/auth/sso/constants';

export function useCommonLogic(
    enforcedAuthenticationMethod: string | null,
    organizationOktaConfiguration: OrganizationOktaConfiguration | null
) {
    const [signupMethod, setSignupMethod] = useState<string | null>(null);
    const [displayedAuthenticationMethods, setDisplayedAuthenticationMethods] = useState<{
        [key: string]: boolean;
    }>({
        [AuthenticationMethod.EMAIL_PASSWORD]: true,
        [AuthenticationMethod.GOOGLE]: true,
        [AuthenticationMethod.MICROSOFT]: true,
        [AuthenticationMethod.OKTA]: true
    });

    const shouldShowSsoRegistrationMethod = useCallback(
        (ssoProvider: SsoProviderType) => {
            if (ssoProvider === SsoProvider.OKTA && !organizationOktaConfiguration) {
                return false;
            }
            if (!enforcedAuthenticationMethod) {
                return true;
            }
            return enforcedAuthenticationMethod === ssoProvider;
        },
        [enforcedAuthenticationMethod, organizationOktaConfiguration]
    );

    useEffect(() => {
        setDisplayedAuthenticationMethods({
            [AuthenticationMethod.EMAIL_PASSWORD]: !enforcedAuthenticationMethod,
            [AuthenticationMethod.GOOGLE]: shouldShowSsoRegistrationMethod(SsoProvider.GOOGLE),
            [AuthenticationMethod.MICROSOFT]: shouldShowSsoRegistrationMethod(
                SsoProvider.MICROSOFT
            ),
            [AuthenticationMethod.OKTA]: shouldShowSsoRegistrationMethod(SsoProvider.OKTA)
        });
    }, [enforcedAuthenticationMethod, shouldShowSsoRegistrationMethod]);

    return {
        signupMethod,
        setSignupMethod,
        displayedAuthenticationMethods
    };
}
