import {useCheckLoggedIn} from '@/api/auth/useCheckLoggedIn';
import {useOrganization} from '@/api/organizations/useOrganization';
import {FetchPolicy} from '@/api/types/genericApi/fetchPolicy';
import {graphqlError} from '@/componentLibrary/blocks/ErrorState';
import {RequireScopeProps} from '@/services/auth/types';

export function RequireScope({
    children,
    fallback = () => null,
    loading = () => null,
    onError = graphqlError,
    permissions,
    requireSuperUser,
    scope,
    sneakPeekForOrganizations = []
}: RequireScopeProps) {
    const {organization, loading: loadingOrganization} = useOrganization();
    const {
        isLoggedIn,
        user,
        loading: checkLoggedInLoading,
        error
    } = useCheckLoggedIn({
        fetchPolicy: FetchPolicy.CACHE_FIRST
    });

    function toArray(arrayOrSingleElement: string | string[] | undefined) {
        if (!arrayOrSingleElement) {
            return [];
        }

        if (Array.isArray(arrayOrSingleElement)) {
            return arrayOrSingleElement;
        }
        return [arrayOrSingleElement];
    }

    function isRestricted() {
        return toArray(scope).length > 0 || toArray(permissions).length > 0;
    }

    function hasOneOfPermissions() {
        const requiredPermissions = toArray(permissions);
        return user?.permissions.filter(value => requiredPermissions.includes(value)).length;
    }

    function isSuperUser() {
        return user?.isSuperUser;
    }

    function sneakPeekAllowedForOrganization() {
        return organization?.id && sneakPeekForOrganizations?.includes(organization.id);
    }

    if (checkLoggedInLoading || loadingOrganization) {
        return loading();
    }

    if (error) {
        return onError(error);
    }

    if (!isLoggedIn || !user) {
        return fallback();
    }

    if (requireSuperUser && !isSuperUser() && !sneakPeekAllowedForOrganization()) {
        return fallback();
    }

    if (!isRestricted()) {
        return children();
    }

    if (hasOneOfPermissions()) {
        return children();
    }

    return fallback();
}
