import {useCallback, useEffect, useRef} from 'react';

import {WITH_BANNER_CLASSNAME} from './constants';
import {TopBannerStyle} from './styled';
import {TopBannerProps} from './types';

// Define a module-level set to manage visibility across instances
const visibleBanners = new Set<HTMLDivElement>();

export function TopBanner({visible, children}: TopBannerProps) {
    const ref = useRef<HTMLDivElement>(null);
    const updateBodyClass = useCallback((isVisible: boolean) => {
        if (ref.current) {
            if (isVisible) {
                visibleBanners.add(ref.current);
            } else {
                visibleBanners.delete(ref.current);
            }
        }

        if (visibleBanners.size > 0) {
            addClassToBody();
        } else {
            removeClassFromBody();
        }
    }, []);

    useEffect(() => {
        const currentRef = ref.current;
        // On mount and visible changes, update the class list
        updateBodyClass(visible);

        // On unmount, clean up the set and potentially the body class
        return () => {
            if (currentRef) {
                visibleBanners.delete(currentRef);
            }
            if (visibleBanners.size === 0) {
                removeClassFromBody();
            }
        };
    }, [updateBodyClass, visible]);

    const removeClassFromBody = () => {
        document.body.classList.remove(WITH_BANNER_CLASSNAME);
    };

    const addClassToBody = () => {
        document.body.classList.add(WITH_BANNER_CLASSNAME);
    };

    if (!visible) {
        return null;
    }

    return (
        <div className="top-banner-wrapper" data-testid="top-banner-wrapper" ref={ref}>
            <TopBannerStyle />
            <div className="container is-fluid">
                <div className="top-banner-content">{children}</div>
            </div>
        </div>
    );
}
