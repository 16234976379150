import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingMedium, SpacingXxlarge} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    margin-bottom: ${SpacingXxlarge};

    @media (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-bottom: ${SpacingMedium};
    }
`;
