import {defineMessages} from 'react-intl';

export default defineMessages({
    email: {
        id: 'app.assessment.jobPosition.candidateInfo.candidateForm.email'
    },
    firstName: {
        id: 'app.assessment.jobPosition.candidateInfo.candidateForm.firstName'
    },
    lastName: {
        id: 'app.assessment.jobPosition.candidateInfo.candidateForm.lastName'
    },
    save: {
        id: 'app.assessment.jobPosition.candidateInfo.candidateForm.save'
    }
});
