import Cookies from 'js-cookie';
import {useCallback, useState} from 'react';

import {AuthenticationMethod} from '@/api/types/__generated__/graphql';
import {redirectToGoogle, redirectToMicrosoft} from '@/pages/Login/loginUtils';
import {RegistrationChannel} from '@/pages/SignupV2/components/types';
import {SsoOperationType, SsoProviderType} from '@/services/auth/sso/constants';
import {SsoState} from '@/services/auth/sso/types';
import {setSsoStateInSessionStorage} from '@/services/auth/sso/utils';

import {SsoAuthData} from '../../types';

export function useCreateAccountLogic() {
    const [loadingGoogle, setLoadingGoogle] = useState(false);
    const [loadingMicrosoft, setLoadingMicrosoft] = useState(false);

    const getSsoDataInSessionStorage = (): SsoAuthData | null => {
        const data = window.sessionStorage.getItem('SSO_AUTH_DATA');
        if (data) {
            return JSON.parse(data);
        }
        return null;
    };

    const updateSsoState = useCallback((ssoProvider: SsoProviderType) => {
        const state: SsoState = {
            ssoProvider,
            operationType: SsoOperationType.AUTHENTICATE_AND_VALIDATE,
            token: '',
            tosRevision: ''
        };
        setSsoStateInSessionStorage(state);
    }, []);

    const parseAndStoreHubspotParams = (
        registrationChannel: RegistrationChannel = RegistrationChannel.SSO
    ) => {
        const urlParams = new URLSearchParams(window.location.search);
        let params: {[key: string]: unknown};
        const paramCount = Array.from(urlParams.keys()).length;
        if (paramCount === 0) {
            params = getParamsFromCookie();
        } else {
            params = {};
            for (const [key, value] of urlParams) {
                params[key] = value;
            }
        }

        if (!('hutk' in params)) {
            const hutk = Cookies.get('hubspotutk');
            if (hutk) {
                params['hutk'] = hutk;
            }
        }

        params['registration_channel'] = registrationChannel;

        window.sessionStorage.setItem('HUBSPOT_PARAMS', JSON.stringify(params));
    };

    const getParamsFromCookie = () => {
        const params: {[key: string]: unknown} = {};
        const cookie = Cookies.get('utmParams');
        if (cookie) {
            try {
                const parsed = JSON.parse(cookie);
                for (const [key, value] of Object.entries(parsed)) {
                    if (key && value) {
                        params[key] = value;
                    }
                }
            } catch (e) {
                console.info('Error parsing utmCookie %s', e);
            }
        }
        return params;
    };

    const clearSsoDataInSessionStorage = () => {
        window.sessionStorage.removeItem('SSO_AUTH_DATA');
    };

    const getHubspotParamsInJson = () => {
        const hubspotTrackingJson = window.sessionStorage.getItem('HUBSPOT_PARAMS');

        return hubspotTrackingJson ? hubspotTrackingJson : '{}';
    };

    const appendReferralInfoAndEncode = (
        trackingInfoJson: string,
        referralSource: string
    ): string => {
        const json = JSON.parse(trackingInfoJson);
        json['alva_referral_source'] = referralSource;
        const jsonString = JSON.stringify(json);
        return window.btoa(jsonString);
    };

    const handleRegisterWithGoogle = useCallback(() => {
        setLoadingGoogle(true);
        updateSsoState(AuthenticationMethod.GOOGLE);
        redirectToGoogle(location.search, '', 'profile email', true);
    }, [updateSsoState]);

    const handleRegisterWithMicrosoft = useCallback(() => {
        setLoadingMicrosoft(true);
        updateSsoState(AuthenticationMethod.MICROSOFT);
        redirectToMicrosoft(location.search, true);
    }, [updateSsoState]);

    return {
        handleRegisterWithGoogle,
        handleRegisterWithMicrosoft,
        loadingGoogle,
        loadingMicrosoft,
        clearSsoDataInSessionStorage,
        getSsoDataInSessionStorage,
        parseAndStoreHubspotParams,
        getHubspotParamsInJson,
        appendReferralInfoAndEncode
    };
}
