import styled from 'styled-components';

import {BannerWrapper} from '@/componentLibrary/components/banners/Banner';
import {SpacingSmall, SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: none;
    justify-content: space-between;
    align-items: center;

    @media print {
        display: flex;
        margin-top: ${SpacingXsmall};
        margin-bottom: ${SpacingSmall};

        :not(:first-child) {
            page-break-before: always;
            break-before: page;
            page-break-inside: avoid;
            break-inside: avoid;
        }

        ${BannerWrapper} + & {
            page-break-before: auto;
            break-before: auto;
            page-break-inside: auto;
            break-inside: auto;
        }
    }
`;

export const Logo = styled.img`
    width: 41px;
    height: 31px;
`;
