import {Fragment, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';

import {LogoWithText} from '@/componentLibrary/components/Logo/LogoWithText/LogoWithText';
import {AlvaLogoWrapper, MainWrapper, MessageWrapper, SubWrapper} from '../../styled';
import messages from './messages';
import {WarningLogo} from './styled';

type StripeCheckoutErrorProps = {
    redirectUrl: string;
};

export function StripeCheckoutError({redirectUrl}: StripeCheckoutErrorProps) {
    useEffect(() => {
        const timer = setTimeout(() => window.location.replace(redirectUrl), 3000);
        return () => clearTimeout(timer);
    }, [redirectUrl]);

    return (
        <Fragment>
            <MainWrapper>
                <div />
                <SubWrapper>
                    <WarningLogo />
                    <MessageWrapper $width={455} $height={48}>
                        <FormattedMessage
                            {...messages.stripeCheckoutCancelMessage}
                            values={{value: <br />}}
                        />
                    </MessageWrapper>
                </SubWrapper>
                <AlvaLogoWrapper>
                    <LogoWithText />
                </AlvaLogoWrapper>
            </MainWrapper>
        </Fragment>
    );
}
