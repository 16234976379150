import React, {CSSProperties, Component, ReactNode} from 'react';

import {SpacingXxsmall} from '@/componentLibrary/tokens/variables';
import {logger} from '@/services/logrocket';

import {DetailsBox} from '../Box';
import {FlexLayout} from '../layout/FlexLayout';
import {P2} from '../typography';

type ErrorBoundaryProps = {
    children: ReactNode;
    fallback?: ReactNode;
    wrapperStyle?: CSSProperties;
};

type ErrorBoundaryState = {
    hasError: boolean;
};

const defaultWrapperStyle: CSSProperties = {
    margin: '20px',
    maxWidth: 'max-content'
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    static defaultProps = {
        wrapperStyle: defaultWrapperStyle
    };

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error: Error, info: React.ErrorInfo): void {
        // Display fallback UI
        this.setState({hasError: true});

        // Log the error to an error reporting service
        logger.error(error, info);
    }

    render(): ReactNode {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                this.props.fallback || (
                    <DetailsBox
                        style={this.props.wrapperStyle || defaultWrapperStyle}
                        title="Something went wrong"
                        description={
                            <FlexLayout $gap={SpacingXxsmall} direction="column">
                                <P2>
                                    An unexpected error occurred and we have been notified
                                    about the problem.
                                </P2>
                                <P2>
                                    You can try to refresh the page. If the problem persists,
                                    please contact us.
                                </P2>
                            </FlexLayout>
                        }
                    />
                )
            );
        }

        return this.props.children;
    }
}
