import {gql} from '../types/__generated__';

export const CREATE_DATA_COLLECTION_PERSONALITY_TEST_V2 = gql(`
mutation createDataCollectionPersonalityTestV2($createDataCollectionPersonalityTestInput: CreateDataCollectionPersonalityTestInput!) {
      createDataCollectionPersonalityTestV2(createDataCollectionPersonalityTestInput: $createDataCollectionPersonalityTestInput) {
            ok
            errorMessage
            dataCollectionPersonalityTestId
        }
    }
`);

export const SHARE_PERSONALITY_TEST_WITH_ORGANIZATION = gql(`
    mutation sharePersonalityTestWithOrganization(
        $data: SharePersonalityTestWithOrganizationInput!
    ) {
        sharePersonalityTestWithOrganization(data: $data) {
            ok
            errorMessage
        }
    }
`);

export const CREATE_PERSONALITY_TEST = gql(`
    mutation createPersonalityTest($data: CreatePersonalityTestInput!) {
        createPersonalityTest(data: $data) {
            ok
            errorMessage
            personalityTest {
                id
                created
                modified
                userId
            }
        }
    }
`);

export const REVOKE_PERSONALITY_TEST_FROM_ORGANIZATION = gql(`
    mutation RevokePersonalityTestFromOrganization(
        $data: RevokePersonalityTestFromOrganizationInput!
    ) {
        revokePersonalityTestFromOrganization(data: $data) {
            ok
            errorMessage
        }
    }
`);

export const RECREATE_SHARED_PERSONALITY_TEST = gql(`
    mutation recreateSharedPersonalityTest($data: RecreateSharedPersonalityTestInput!) {
        recreateSharedPersonalityTest(data: $data) {
            ok
            errorMessage
            personalityTest {
                id
                created
                modified
                userId
            }
        }
    }
`);

export const REPLACE_SHARED_PERSONALITY_TEST = gql(`
    mutation replaceSharedPersonalityTest($data: ReplaceSharedPersonalityTestInput!) {
        replaceSharedPersonalityTest(data: $data) {
            ok
            errorMessage
        }
    }
`);

export const SAVE_ANSWER_FOR_PERSONALITY_TEST_V2 = gql(`
    mutation saveAnswerForPersonalityTestV2($saveAnswerForPersonalityTestInput: SaveAnswerForPersonalityTestInput!) {
          saveAnswerForPersonalityTestV2(saveAnswerForPersonalityTestInput: $saveAnswerForPersonalityTestInput) {
                ok
                errorMessage
                personalityTestStatus {
                    ...PersonalityTestStatusV2Fields
                }
            }
        }
`);

export const SKIP_QUESTION_FOR_PERSONALITY_TEST_V2 = gql(`
    mutation skipQuestionForPersonalityTestV2($skipQuestionForPersonalityTestInput: SkipQuestionForPersonalityTestInput!) {
        skipQuestionForPersonalityTestV2(skipQuestionForPersonalityTestInput: $skipQuestionForPersonalityTestInput) {
            ok
            errorMessage
            personalityTestStatus {
                ...PersonalityTestStatusV2Fields
            }
        }
    }
`);

export const GET_PREVIOUS_QUESTION_FOR_PERSONALITY_TEST_V2 = gql(`
mutation getPreviousQuestionForPersonalityTestV2($getPreviousQuestionForPersonalityTestInput: GetPreviousQuestionForPersonalityTestInput!) {
      getPreviousQuestionForPersonalityTestV2(getPreviousQuestionForPersonalityTestInput: $getPreviousQuestionForPersonalityTestInput) {
            ok
            errorMessage
            personalityTestStatus {
                ...PersonalityTestStatusV2Fields
            }
        }
    }
`);
