import {createGlobalStyle} from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';

// Hack to make Prettier format `createGlobalStyle` correctly
const styled = {createGlobalStyle};

export const CookiebotBadgeGlobalStyle = styled.createGlobalStyle<{
    intercomEnabled: boolean;
    showCookiebotBadge: boolean;
}>`
    /* stylelint-disable-next-line selector-id-pattern */
    #CookiebotWidget {
        right: ${props => (props.intercomEnabled ? '80px' : '20px')} !important;
        display: ${props => (props.showCookiebotBadge ? 'block' : 'none')} !important;

        @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
            right: ${props => (props.intercomEnabled ? '80px' : '10px')} !important;
            bottom: ${props => (props.intercomEnabled ? '20px' : '10px')} !important;
        }

        @media only print {
            display: none !important;
        }
    }
`;
