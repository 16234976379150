import styled from 'styled-components';

import {SpacingSmall, SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    max-width: 448px;
`;

export const Header = styled.div`
    display: flex;
`;

export const ContentColumn = styled.div`
    margin-inline-start: ${SpacingSmall};
    display: flex;
    flex-direction: column;
    gap: ${SpacingXxsmall};
`;

export const CaptionAndTagWrapper = styled.div`
    display: flex;
    gap: ${SpacingXxsmall};
    align-items: center;
`;
