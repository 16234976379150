import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderRadiusBaseSmall,
    ColorBgSubtle,
    SpacingLarge,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const AnchorsWrapper = styled.div`
    margin-top: ${SpacingXlarge};
    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        display: none;
    }

    @media print {
        display: none;
    }
`;

export const Anchor = styled.div`
    border-radius: ${BorderRadiusBaseSmall};
    background-color: ${ColorBgSubtle};
    margin-inline-end: ${SpacingXsmall};
    cursor: pointer;

    :hover {
        filter: drop-shadow(0 2px 3px rgb(83 83 83 / 30%));
    }
`;

export const AnchorHelperText = styled(P2)`
    margin-inline-end: ${SpacingLarge};
`;

export const AnchorText = styled(P2)`
    margin: ${SpacingXxxsmall} ${SpacingXxsmall};
`;
