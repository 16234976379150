import {useCallback, useEffect, useMemo, useState} from 'react';

const POLLING_INTERVAL = 500;
const POLLING_TIMEOUT = 5000;

export type Arguments = {
    start: (interval: number) => void;
    stop: () => void;
    dataToChange: unknown;
    interval?: number;
    timeout?: number;
};

export function usePollUntilChange({start, stop, dataToChange, interval, timeout}: Arguments) {
    const _interval = interval ?? POLLING_INTERVAL;
    const _timeout = timeout ?? POLLING_TIMEOUT;
    const timesOutAt = useMemo(() => new Date().getTime() + _timeout, [_timeout]);
    const [isPolling, setIsPolling] = useState(false);

    const startPollingUntilChange = useCallback(() => {
        start(_interval);
        setIsPolling(true);
        setTimeout(() => {
            stop();
            setIsPolling(false);
        }, _timeout);
    }, [start, stop, _interval, _timeout]);

    useEffect(() => {
        const currentTime = new Date().getTime();
        const timeOutIsReached = currentTime > timesOutAt;
        const dataToChangeIsArray = Array.isArray(dataToChange);
        if (
            timeOutIsReached ||
            !dataToChangeIsArray ||
            (dataToChangeIsArray && dataToChange.length > 0)
        ) {
            stop();
            setIsPolling(false);
        }
    }, [dataToChange, stop, timesOutAt]);

    return {startPollingUntilChange, isPolling};
}
