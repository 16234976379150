import {defineMessages} from 'react-intl';

export default defineMessages({
    email: {
        id: 'app.signup.createAccountForm.email'
    },
    firstName: {
        id: 'app.signup.createAccountForm.firstName'
    },
    lastName: {
        id: 'app.signup.createAccountForm.lastName'
    },
    password: {
        id: 'app.signup.createAccountForm.password'
    },
    createAccount: {
        id: 'app.signup.createAccountForm.createAccount'
    }
});

export const errorMessages = defineMessages({
    lastNameError: {
        id: 'app.signup.createAccountForm.lastNameError'
    },
    firstNameError: {
        id: 'app.signup.createAccountForm.firstNameError'
    }
});
