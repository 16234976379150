import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'personalityTestReport.growthFactors.title'
    },
    cultureTitle: {
        id: 'personalityTestReport.growthFactors.culture.title'
    },
    cultureDescription: {
        id: 'personalityTestReport.growthFactors.culture.description'
    },
    cultureReadMore: {
        id: 'personalityTestReport.growthFactors.culture.readMore'
    },
    driversTitle: {
        id: 'personalityTestReport.growthFactors.drivers.title'
    },
    driversDescription: {
        id: 'personalityTestReport.growthFactors.drivers.description'
    },
    driversReadMore: {
        id: 'personalityTestReport.growthFactors.drivers.readMore'
    },
    readMore: {
        id: 'personalityTestReport.growthFactors.readMore'
    }
});

export const growthFactorNamesMessages = defineMessages({
    ANALYTICAL: {
        id: 'personalityTestReport.growthFactors.culture.ANALYTICAL.name'
    },
    DECISIVE: {
        id: 'personalityTestReport.growthFactors.culture.DECISIVE.name'
    },
    ENERGETIC: {
        id: 'personalityTestReport.growthFactors.culture.ENERGETIC.name'
    },
    INNOVATIVE: {
        id: 'personalityTestReport.growthFactors.culture.INNOVATIVE.name'
    },
    SUPPORTIVE: {
        id: 'personalityTestReport.growthFactors.culture.SUPPORTIVE.name'
    },
    TASK_ORIENTED: {
        id: 'personalityTestReport.growthFactors.culture.TASK_ORIENTED.name'
    },
    TEAM_ORIENTED: {
        id: 'personalityTestReport.growthFactors.culture.TEAM_ORIENTED.name'
    },

    ACHIEVEMENT: {
        id: 'personalityTestReport.growthFactors.drivers.ACHIEVEMENT.name'
    },
    AUTONOMY: {
        id: 'personalityTestReport.growthFactors.drivers.AUTONOMY.name'
    },
    COMMUNITY: {
        id: 'personalityTestReport.growthFactors.drivers.COMMUNITY.name'
    },
    CREATIVITY: {
        id: 'personalityTestReport.growthFactors.drivers.CREATIVITY.name'
    },
    INFLUENCE: {
        id: 'personalityTestReport.growthFactors.drivers.INFLUENCE.name'
    },
    RELATIONSHIPS: {
        id: 'personalityTestReport.growthFactors.drivers.RELATIONSHIPS.name'
    },
    STABILITY: {
        id: 'personalityTestReport.growthFactors.drivers.STABILITY.name'
    },
    STIMULATION: {
        id: 'personalityTestReport.growthFactors.drivers.STIMULATION.name'
    },
    STRUCTURE: {
        id: 'personalityTestReport.growthFactors.drivers.STRUCTURE.name'
    }
});

export const growthFactorDescriptionsMessages = defineMessages({
    ANALYTICAL: {
        id: 'personalityTestReport.growthFactors.culture.ANALYTICAL.description'
    },
    DECISIVE: {
        id: 'personalityTestReport.growthFactors.culture.DECISIVE.description'
    },
    ENERGETIC: {
        id: 'personalityTestReport.growthFactors.culture.ENERGETIC.description'
    },
    INNOVATIVE: {
        id: 'personalityTestReport.growthFactors.culture.INNOVATIVE.description'
    },
    SUPPORTIVE: {
        id: 'personalityTestReport.growthFactors.culture.SUPPORTIVE.description'
    },
    TASK_ORIENTED: {
        id: 'personalityTestReport.growthFactors.culture.TASK_ORIENTED.description'
    },
    TEAM_ORIENTED: {
        id: 'personalityTestReport.growthFactors.culture.TEAM_ORIENTED.description'
    },

    ACHIEVEMENT: {
        id: 'personalityTestReport.growthFactors.drivers.ACHIEVEMENT.description'
    },
    AUTONOMY: {
        id: 'personalityTestReport.growthFactors.drivers.AUTONOMY.description'
    },
    COMMUNITY: {
        id: 'personalityTestReport.growthFactors.drivers.COMMUNITY.description'
    },
    CREATIVITY: {
        id: 'personalityTestReport.growthFactors.drivers.CREATIVITY.description'
    },
    INFLUENCE: {
        id: 'personalityTestReport.growthFactors.drivers.INFLUENCE.description'
    },
    RELATIONSHIPS: {
        id: 'personalityTestReport.growthFactors.drivers.RELATIONSHIPS.description'
    },
    STABILITY: {
        id: 'personalityTestReport.growthFactors.drivers.STABILITY.description'
    },
    STIMULATION: {
        id: 'personalityTestReport.growthFactors.drivers.STIMULATION.description'
    },
    STRUCTURE: {
        id: 'personalityTestReport.growthFactors.drivers.STRUCTURE.description'
    }
});
