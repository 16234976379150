import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';

import messages from './messages';

type Props = {
    email: string;
};
export function UserExistsBanner(props: Props) {
    const intl = useIntl();

    return (
        <BannerWrap>
            <Banner
                type={BannerType.ATTENTION}
                noIcon
                action={{
                    text: intl.formatMessage(messages.signIn),
                    onClick: () => {
                        window.location.href = '/login';
                    }
                }}
                title={
                    <FormattedMessage
                        {...messages.accountExists}
                        values={{email: props.email}}
                    />
                }
            >
                <FormattedMessage {...messages.accountExistsAction} />
            </Banner>
        </BannerWrap>
    );
}

const BannerWrap = styled.div`
    margin-bottom: 16px;
`;
