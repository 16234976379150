import {defineMessages} from 'react-intl';

export default defineMessages({
    signOut: {
        id: 'app.pages.user.startHeader.startMenu.signOut'
    },
    organizations: {
        id: 'app.pages.user.startHeader.startMenu.organizations'
    },
    accountSettings: {
        id: 'app.pages.user.startHeader.startMenu.accountSettings'
    }
});
