import {defineMessages} from 'react-intl';

export default defineMessages({
    bold: {
        id: 'app.textFormatting.bold'
    },
    italic: {
        id: 'app.textFormatting.italic'
    },
    underline: {
        id: 'app.textFormatting.underline'
    },
    link: {
        id: 'app.textFormatting.link'
    },
    code: {
        id: 'app.textFormatting.code'
    },
    codeBlock: {
        id: 'app.textFormatting.codeBlock'
    },
    orderedList: {
        id: 'app.textFormatting.orderedList'
    },
    bulletList: {
        id: 'app.textFormatting.bulletList'
    },
    increaseIndent: {
        id: 'app.textFormatting.increaseIndent'
    },
    decreaseIndent: {
        id: 'app.textFormatting.decreaseIndent'
    }
});

export const MacShortcutMessages = defineMessages({
    bold: {
        id: 'app.textFormatting.shortcuts.mac.bold'
    },
    italic: {
        id: 'app.textFormatting.shortcuts.mac.italic'
    },
    underline: {
        id: 'app.textFormatting.shortcuts.mac.underline'
    },
    link: {
        id: 'app.textFormatting.shortcuts.mac.link'
    },
    code: {
        id: 'app.textFormatting.shortcuts.mac.code'
    },
    codeBlock: {
        id: 'app.textFormatting.shortcuts.mac.codeBlock'
    },
    orderedList: {
        id: 'app.textFormatting.shortcuts.mac.orderedList'
    },
    bulletList: {
        id: 'app.textFormatting.shortcuts.mac.bulletList'
    },
    increaseIndent: {
        id: 'app.textFormatting.shortcuts.mac.increaseIndent'
    },
    decreaseIndent: {
        id: 'app.textFormatting.shortcuts.mac.decreaseIndent'
    }
});

export const WindowsShortcutMessages = defineMessages({
    bold: {
        id: 'app.textFormatting.shortcuts.windows.bold'
    },
    italic: {
        id: 'app.textFormatting.shortcuts.windows.italic'
    },
    underline: {
        id: 'app.textFormatting.shortcuts.windows.underline'
    },
    link: {
        id: 'app.textFormatting.shortcuts.windows.link'
    },
    code: {
        id: 'app.textFormatting.shortcuts.windows.code'
    },
    codeBlock: {
        id: 'app.textFormatting.shortcuts.windows.codeBlock'
    },
    orderedList: {
        id: 'app.textFormatting.shortcuts.windows.orderedList'
    },
    bulletList: {
        id: 'app.textFormatting.shortcuts.windows.bulletList'
    },
    increaseIndent: {
        id: 'app.textFormatting.shortcuts.windows.increaseIndent'
    },
    decreaseIndent: {
        id: 'app.textFormatting.shortcuts.windows.decreaseIndent'
    }
});
