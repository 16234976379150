import {usePersonalityTestResult} from '@/api/personalityTests/usePersonalityTestResult';
import {PersonalityIrtResult} from '@/api/types/__generated__/graphql';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {PersonalityTestReport} from '@/componentLibrary/blocks/testReports/PersonalityTest/PersonalityTestReport';
import {PageCenteredSpinner} from '@/componentLibrary/components/spinners/PageCenteredSpinner';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import messages from './messages';

export type SharedLogicTestReportPageProps = {
    closeLink: string;
    personalityTestId: string;
};

export function SharedPersonalityTestReportPage({
    closeLink,
    personalityTestId
}: SharedLogicTestReportPageProps) {
    const history = useHistory();
    const phrases = useExtractPhraseConstants(messages);

    const {
        loading,
        error,
        refetch,
        personalityTestResult: NullablePersonalityTestResult
    } = usePersonalityTestResult({personalityTestId});

    const personalityTestResult = NullablePersonalityTestResult as PersonalityIrtResult;

    const redirectUrl = useMemo(() => {
        return closeLink ? closeLink : '/';
    }, [closeLink]);

    const goBack = useCallback(() => {
        history.push(redirectUrl);
    }, [history, redirectUrl]);

    if (loading) {
        return <PageCenteredSpinner />;
    }

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    if (!loading && !personalityTestResult) {
        return <>{phrases.testResultNotFound}</>;
    }

    return (
        <PersonalityTestReport personalityTestResult={personalityTestResult} close={goBack} />
    );
}
