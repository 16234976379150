import {TwoDimensionalBar} from '@/componentLibrary/components/charts/TwoDimensionalBar';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {ColorBaseGrey000, ColorBaseGrey600} from '@/componentLibrary/tokens/variables';

import {useFormatCriterionName} from '../../../../hooks/useFormatCriterionName';
import {ContributionType} from '../../../../utils/constants';
import {ContributionItem} from '../../types';
import {ContributionValue} from './components/ContributionValue';
import {Item, OuterContainer} from './styled';

type Props = {
    contributionType: ContributionType;
    item: ContributionItem;
    isBlurred: boolean;
};

export function ContributionBar({contributionType, item, isBlurred}: Props) {
    const barTextColor = isBlurred ? ColorBaseGrey600 : ColorBaseGrey000;
    const formatCriterionName = useFormatCriterionName({
        contributionTypeId: contributionType.id
    });

    return (
        <Item>
            <OuterContainer>
                <TwoDimensionalBar
                    barColor={contributionType.color}
                    backgroundColor={greyColorTokens.grey800()}
                    percentageValue={item.percentageMatch}
                    height={item.barHeight}
                />
                <ContributionValue
                    absoluteContribution={item.absoluteContribution}
                    percentageMatch={item.percentageMatch}
                    textColor={barTextColor}
                    name={formatCriterionName(item)}
                />
            </OuterContainer>
        </Item>
    );
}
