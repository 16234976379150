import {urlSanitizer} from '@/services/sanitizers';

import {IRequest, IResponse} from './types';

export function responseSanitizer(response: IResponse) {
    delete response.body;
    return response;
}

export function requestSanitizer(request: IRequest) {
    delete request.headers['x-auth-token'];
    delete request.headers['Authorization'];
    delete request.headers['X-Impersonation-Token'];
    delete request.body;
    request.url = urlSanitizer(request.url);
    return request;
}
