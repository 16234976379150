import {UIEvent, useCallback, useEffect} from 'react';

import {ScrollLogicProps} from './types';

export function useScrollLogic({
    onScrollToBottom,
    highlightedIndex,
    setHighlightedIndex,
    itemsCount
}: ScrollLogicProps) {
    const onScroll = useCallback(
        (e: UIEvent<HTMLDivElement>) => {
            const bottom =
                e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
                e.currentTarget.clientHeight;
            if (bottom) {
                setHighlightedIndex?.(itemsCount - 1);
            }
        },
        [setHighlightedIndex, itemsCount]
    );

    useEffect(() => {
        if (highlightedIndex === itemsCount - 1 && onScrollToBottom) {
            onScrollToBottom();
        }
    }, [highlightedIndex, itemsCount, onScrollToBottom]);

    return {onScroll};
}
