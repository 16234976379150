import moment from 'moment';
import {FormattedMessage} from 'react-intl';

import {Tag} from '@/componentLibrary/components/Tag';
import {TagVariant} from '@/componentLibrary/components/Tag/constants';
import {OrganizationAvatar} from '@/componentLibrary/components/avatars/OrganizationAvatar';
import {CardBase} from '@/componentLibrary/components/cards/CardBase';
import {H4} from '@/componentLibrary/components/typography';
import {Caption} from '@/componentLibrary/components/typography/Captions';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {AcceptInvitationInfo} from '../../../MyOrganizations/components/AcceptInvitationInfo';
import messages from './messages';
import {CaptionAndTagWrapper, ContentColumn, Header, Wrapper} from './styled';
import {PendingOrganizationProps} from './types';

export function PendingOrganization({userOrganization}: PendingOrganizationProps) {
    return (
        <CardBase isActive>
            <Wrapper>
                <Header>
                    <OrganizationAvatar
                        name={userOrganization.organizationName}
                        image={userOrganization.organizationLogo}
                    />
                    <ContentColumn>
                        <H4>{userOrganization.organizationName}</H4>
                        <CaptionAndTagWrapper>
                            <Tag $variant={TagVariant.warning}>
                                {<FormattedMessage {...messages.newInvitation} />}
                            </Tag>
                            <Caption color={ColorFgSubtle}>
                                <FormattedMessage
                                    {...messages.assessmentRequestedDate}
                                    values={{
                                        date: moment(userOrganization.created).format(
                                            'YYYY-MM-DD'
                                        )
                                    }}
                                />
                            </Caption>
                        </CaptionAndTagWrapper>
                    </ContentColumn>
                </Header>
                <AcceptInvitationInfo userOrganization={userOrganization} />
            </Wrapper>
        </CardBase>
    );
}
