import {FontSizeLarge, SpacingXsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 30vh;
    min-height: 100%;
    max-width: 900px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 768px) {
        margin-top: 48px;
    }
`;

export const DescriptionTitle = styled.div`
    padding-bottom: ${SpacingXsmall};
    font-size: ${FontSizeLarge};
`;
