import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {
    PERSONALITY_FACET_MESSAGES,
    PersonalityFacetKey
} from '@/componentLibrary/blocks/testReports/PersonalityTest/components/FactorFacets/constants';

import messages from '../messages';
import {ROLE_FIT_CONTRIBUTION_TYPES, RoleFitContributionTypes} from '../utils/constants';

export function useFormatCriterionName({
    contributionTypeId
}: {
    contributionTypeId: keyof RoleFitContributionTypes;
}) {
    const intl = useIntl();

    return useCallback(
        criterion => {
            if (contributionTypeId === ROLE_FIT_CONTRIBUTION_TYPES.PERSONALITY_TEST.id) {
                const message =
                    PERSONALITY_FACET_MESSAGES[criterion.facet as PersonalityFacetKey].title;
                return intl.formatMessage(message);
            }

            return intl.formatMessage(messages.logicTest);
        },
        [intl, contributionTypeId]
    );
}
