import {Caption} from '@/componentLibrary/components/typography';
import {ColorVariables} from '@/componentLibrary/tokens/types';

import {CriterionName, InnerContainer} from './styled';

type Props = {
    absoluteContribution: number | null;
    percentageMatch: number;
    textColor: ColorVariables;
    name: string;
};

export function ContributionValue({
    absoluteContribution,
    percentageMatch,
    textColor,
    name
}: Props) {
    let text = 'N/A';
    if (absoluteContribution !== null) {
        text = `${percentageMatch * 100}%`;
    }

    const width = percentageMatch === 0 ? 100 : percentageMatch * 100;

    return (
        <InnerContainer width={width}>
            <CriterionName color={textColor}>{name}</CriterionName>
            <Caption color={textColor}>{text}</Caption>
        </InnerContainer>
    );
}
