import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.user.candidateSettings.title'
    },
    failedToLoadUserTitle: {
        id: 'app.pages.user.candidateSettings.failedToLoadUserTitle'
    },
    failedToLoadUserText: {
        id: 'app.pages.user.candidateSettings.failedToLoadUserText'
    }
});
