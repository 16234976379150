export const HELP_CENTER = {
    root: 'https://help.alvalabs.io',
    howToSetGoals: 'https://help.alvalabs.io/performance-development/how-to-set-great-goals',
    howToHoldDevelopmentTalk:
        'https://help.alvalabs.io/performance-development/how-to-hold-a-great-development-talk',
    validateLogicTest: 'http://help.alvalabs.io/en/articles/3135586-validate-logic-test',
    marketplace: 'https://help.alvalabs.io/en/collections/4062487-alva-marketplace',
    alvaForCandidates: 'https://help.alvalabs.io/en/collections/1831924-alva-for-candidates',
    alvasLogicTest: 'https://help.alvalabs.io/alva-s-logic-test',
    alvasDefaultTestProfiles:
        'https://help.alvalabs.io/en/articles/2672814-alva-s-default-test-profiles',
    ssoHelp:
        'https://help.alvalabs.io/en/articles/5701470-authentication-settings-and-single-sign-on-sso',
    codingTests: 'https://help.alvalabs.io/en/collections/3917916-coding-tests',
    leadershipReport: 'https://help.alvalabs.io/en/articles/8403945-alva-s-leadership-report',
    selectFacets:
        'https://help.alvalabs.io/en/articles/8866802-choosing-facets-for-your-personality-profile',
    selectRightPersonalityProfile:
        'https://help.alvalabs.io/en/articles/8867127-how-to-choose-the-right-personality-profile',
    provideBestAICodeReview:
        'https://help.alvalabs.io/en/articles/9353371-how-to-provide-the-best-ai-code-review',
    setupCodebaseForCodingTest:
        'https://help.alvalabs.io/en/articles/9028914-how-to-set-up-the-codebase-for-your-coding-test',
    codingTestViaGithub:
        'https://help.alvalabs.io/en/articles/9216372-alva-s-coding-tests-via-github'
};
