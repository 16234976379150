import {defineMessages} from 'react-intl';

export default defineMessages({
    notFound: {
        id: 'app.publicJobPosition.applyForJobPosition.publicJobPositionNotFound.notFound'
    },
    description: {
        id: 'app.publicJobPosition.applyForJobPosition.publicJobPositionNotFound.description'
    },
    bullet1: {
        id: 'app.publicJobPosition.applyForJobPosition.publicJobPositionNotFound.bullet1'
    },
    bullet2: {
        id: 'app.publicJobPosition.applyForJobPosition.publicJobPositionNotFound.bullet2'
    },
    bullet3: {
        id: 'app.publicJobPosition.applyForJobPosition.publicJobPositionNotFound.bullet3'
    }
});
