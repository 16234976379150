import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {S1} from '@/componentLibrary/components/typography/SubTitles';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {Wrapper} from './styled';
import {Props} from './types';

export const Question = ({id, title, subtitle, content}: Props) => (
    <Wrapper id={id} hasSubtitle={!!subtitle}>
        <S1>{title}</S1>
        {subtitle && <P2 color={ColorFgSubtle}>{subtitle}</P2>}
        <div>{content}</div>
    </Wrapper>
);
