import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {Section} from '@/componentLibrary/components/layout/Section';
import {H2, P1} from '@/componentLibrary/components/typography';
import {MarkdownWrapper} from '@/componentLibrary/components/typography/Markdown';
import {SpacingMedium} from '@/componentLibrary/tokens/variables';

export const InvalidLink = () => {
    const style = {maxWidth: '520px', marginTop: '20px'};

    return (
        <Section
            className="alva-bg"
            style={{padding: SpacingMedium, minHeight: '100vh', display: 'flex'}}
            header={undefined}
            loading={undefined}
            error={undefined}
            emptyState={undefined}
        >
            <FlexLayout alignItems="center">
                <MarkdownWrapper>
                    <H2>An error occurred</H2>
                    <div style={style}>
                        <P1>This is most likely because of one of two things:</P1>
                        <ol>
                            <li>
                                <P1>You clicked on an invalid link.</P1>
                            </li>
                            <li>
                                <P1>You requested a view that you do not have access to.</P1>
                            </li>
                        </ol>

                        <P1>
                            Please write to us and tell us what happened. The easiest way is to
                            use the chat down in the right corner.
                        </P1>

                        <P1>
                            Go back to <a href={'/o'}>home</a>.
                        </P1>
                    </div>
                </MarkdownWrapper>
            </FlexLayout>
        </Section>
    );
};
