import {useCallback, useState} from 'react';

export function useCompletedDataCollectionTestLogic() {
    const [reportModalIsOpen, setReportModalIsOpen] = useState(false);
    const [demographicsModalIsOpen, setDemographicsModalIsOpen] = useState(true);

    const openReportModal = useCallback(() => {
        setReportModalIsOpen(true);
    }, []);

    const closeReportModal = useCallback(() => {
        setReportModalIsOpen(false);
    }, []);

    const closeDemographicsModal = useCallback(() => {
        setDemographicsModalIsOpen(false);
    }, []);

    return {
        reportModalIsOpen,
        openReportModal,
        closeReportModal,
        demographicsModalIsOpen,
        closeDemographicsModal
    };
}
