import {ReactNode} from 'react';

import {ConsentCheckbox} from './components/ConsentCheckbox';

type Props = {
    type: string;
    linkToFull?: string;
    onChange: (data?: unknown) => void;
    showRequiredText?: boolean;
    labelDescription?: ReactNode | string;
    customLabel?: ReactNode | string;
};

export function AcceptConsentCheckbox({
    type,
    linkToFull,
    onChange,
    showRequiredText,
    customLabel,
    labelDescription
}: Props) {
    return (
        <ConsentCheckbox
            type={type}
            onChange={onChange}
            linkToFull={linkToFull}
            customLabel={customLabel}
            labelDescription={labelDescription}
            showRequiredText={showRequiredText}
        />
    );
}
