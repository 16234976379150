import {Base} from '@/componentLibrary/components/typography/Base';
import {ColorVariables} from '@/componentLibrary/tokens/types';
import {ColorFgSubtle, SpacingXsmall} from '@/componentLibrary/tokens/variables';

import {StyledList} from './styled';

type Props = {
    items: string[];
    gap?: string;
    color?: ColorVariables;
};

export function BulletList({items, gap = SpacingXsmall, color = ColorFgSubtle}: Props) {
    return (
        <StyledList gap={gap} color={color}>
            {items.map((item, index) => (
                <li key={index}>
                    <Base color={color}>{item}</Base>
                </li>
            ))}
        </StyledList>
    );
}
