import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';
import {SpacingSmall, SpacingXsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const QuestionWrapper = styled.div`
    border-bottom: ${BorderBaseDefault};
    padding: ${SpacingSmall} 0;
    display: grid;
    grid-gap: ${SpacingXsmall};

    &:last-child {
        border-bottom: none;
    }
`;
