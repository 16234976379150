import styled from 'styled-components';

import {OtherCaption} from '@/componentLibrary/tokens/typography';

import {Base} from './Base';

export const Caption = styled(Base)`
    display: inline-block;
    ${OtherCaption};
`;
