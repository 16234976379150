import {H3} from '@/componentLibrary/components/typography/Headings';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    DescriptionWrapper,
    TitleWrapper,
    Wrapper
} from '@/pages/PublicJobPosition/pages/ApplyForJobPosition/components/ApplyForm/components/ThankYouPage/styled';
import messages from '../../messages';

export const ThankYouPage = () => {
    const phrases = useExtractPhraseConstants(messages);
    return (
        <Wrapper>
            <TitleWrapper>
                <H3>{phrases.completedTitle}</H3>
            </TitleWrapper>
            <DescriptionWrapper>{phrases.completedDescription}</DescriptionWrapper>
        </Wrapper>
    );
};
