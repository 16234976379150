import PropTypes from 'prop-types';
import styled from 'styled-components';

import {grey} from '@/componentLibrary/tokens/legacyColors';

export const SelectableTableRow = styled.div`
    height: 56px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${grey.grey600()};
`;

const SelectableRowGroup = styled.div`
    cursor: pointer;

    &:hover {
        background-color: ${grey.grey900()};
    }
`;

export const TrComponent = ({children, onClick}) => (
    <SelectableTableRow onClick={onClick}>{children}</SelectableTableRow>
);

TrComponent.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func
};

export const TrGroupComponent = ({children, onClick}) => (
    <SelectableRowGroup onClick={onClick}>{children}</SelectableRowGroup>
);

TrGroupComponent.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func
};
