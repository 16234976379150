import styled from 'styled-components';

import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingMedium, SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingMedium};
`;

export const LeadershipSectionsWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    margin-block-start: ${SpacingXsmall};

    & > div {
        padding: 0 ${SpacingMedium};
        border-right: ${BorderBaseDefault};
    }

    & > div:last-child {
        padding-right: 0;
        border-right: none;
    }

    & > div:first-child {
        padding-left: 0;
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        grid-auto-flow: row;

        & > div {
            padding: 0;
            border-right: none;
            margin-bottom: ${SpacingMedium};
        }

        & > div:not(:first-child) {
            border-top: ${BorderBaseDefault};
        }
    }
`;

export const LeadershipSection = styled.div`
    & > span {
        margin-bottom: ${SpacingXsmall};
        padding-inline-start: ${SpacingMedium};
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        & > span {
            margin: ${SpacingMedium} 0;
        }
    }
`;
