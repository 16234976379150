import {useMutation} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';
import {GET_USER_ORGANIZATIONS} from '@/api/users/queries';

import {AcceptSharingCandidateDataWithOrganizationMutation} from '../types/__generated__/graphql';
import {ACCEPT_SHARING_CANDIDATE_DATA_WITH_ORGANIZATION} from './mutations';

export function useAcceptSharingCandidateDataWithOrganization(): MutationHookReturnType<
    AcceptSharingCandidateDataWithOrganizationMutation,
    [number | null | undefined]
> {
    const options = useMemo(() => {
        return {refetchQueries: [{query: GET_USER_ORGANIZATIONS}]};
    }, []);
    const [mutate, {error, loading}] = useMutation(
        ACCEPT_SHARING_CANDIDATE_DATA_WITH_ORGANIZATION,
        options
    );
    const doAcceptSharingCandidateData = useCallback(
        organizationId => {
            if (!organizationId) {
                return Promise.reject(new Error('Organization id is required'));
            }
            return mutate({variables: {organizationId}});
        },
        [mutate]
    );

    return [doAcceptSharingCandidateData, {error, loading}];
}
