import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {
    PERSONALITY_FACTORS,
    PERSONALITY_FACTOR_MESSAGES,
    PersonalityFactorKey
} from '../../constants';
import messages from './messages';
import {Anchor, AnchorHelperText, AnchorText, AnchorsWrapper} from './styled';

type AnchorsProps = {
    onClick: (factor: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export function Anchors({onClick}: AnchorsProps) {
    const intl = useIntl();

    const getFactorName = useCallback(
        (factor: PersonalityFactorKey) => {
            return intl.formatMessage(PERSONALITY_FACTOR_MESSAGES[factor].name);
        },
        [intl]
    );

    return (
        <AnchorsWrapper>
            <AnchorHelperText color={ColorFgSubtle}>
                {intl.formatMessage(messages.helperText)}
            </AnchorHelperText>
            {PERSONALITY_FACTORS.map((factor, index) => (
                <Anchor key={index} onClick={event => onClick(factor, event)}>
                    <AnchorText>{getFactorName(factor)}</AnchorText>
                </Anchor>
            ))}
        </AnchorsWrapper>
    );
}
