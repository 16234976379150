import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {Caption} from '@/componentLibrary/components/typography/Captions';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {
    ColorBaseGrey600,
    ColorBaseGrey700,
    ColorBaseRed700,
    ColorBaseYellow800,
    ColorFgSuccess
} from '@/componentLibrary/tokens/variables';

import {TEST_STATUSES, TEST_TYPES} from '../../constants';
import messages from './messages';

type TestStatus = ChallengeAssignmentStatus | TEST_STATUSES;

type TestProgressBoxProps = {
    status?: TestStatus;
    testType?: TEST_TYPES;
    name?: string;
};

const TestStatusIcon = ({status}: {status?: TestStatus}) => {
    if (!status) {
        return null;
    }
    if ([TEST_STATUSES.NOT_STARTED, ChallengeAssignmentStatus.NOT_STARTED].includes(status)) {
        return <Icon icon={Icons.CIRCLE} fill={ColorBaseGrey600} />;
    }
    if ([TEST_STATUSES.IN_PROGRESS, ChallengeAssignmentStatus.IN_PROGRESS].includes(status)) {
        return <Icon icon={Icons.CLOCK_LOADER_40} fill={ColorBaseYellow800} />;
    }
    if (status === ChallengeAssignmentStatus.SUPPORT_NEEDED) {
        return <Icon icon={Icons.SCHEDULE} fill={ColorBaseRed700} />;
    }
    return <Icon icon={Icons.CHECK_CIRCLE} fill={ColorFgSuccess} />;
};

export function TestProgressBox({status, name, testType}: TestProgressBoxProps) {
    return (
        <Wrapper>
            <TestStatusIcon status={status} />
            <TextWrapper>
                <P2>
                    {!!testType && <FormattedMessage {...messages[testType]} />}
                    {name}
                </P2>
                {!!status && (
                    <Caption color={ColorBaseGrey700}>
                        <FormattedMessage {...messages[status]} />
                    </Caption>
                )}
            </TextWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const TextWrapper = styled.div`
    margin-inline-start: 12px;
    display: flex;
    flex-direction: column;
`;
