import {hexToRGBA} from '@/utils/hexToRGBA';

export const greyColorTokens = {
    white000: (opacity = 1) => hexToRGBA('#FFFFFF', opacity),
    white100: (opacity = 1) => hexToRGBA('#F4F4F4', opacity),
    grey000: (opacity = 1) => hexToRGBA('#282828', opacity),
    grey100: (opacity = 1) => hexToRGBA('#535353', opacity),
    grey200: (opacity = 1) => hexToRGBA('#737373', opacity),
    grey300: (opacity = 1) => hexToRGBA('#898989', opacity),
    grey400: (opacity = 1) => hexToRGBA('#9E9E9E', opacity),
    grey500: (opacity = 1) => hexToRGBA('#B4B4B4', opacity),
    grey600: (opacity = 1) => hexToRGBA('#C4C4C4', opacity),
    grey700: (opacity = 1) => hexToRGBA('#C9C9C9', opacity),
    grey800: (opacity = 1) => hexToRGBA('#DFDFDF', opacity),
    grey900: (opacity = 1) => hexToRGBA('#F0F0F0', opacity)
};

export const greyColorTokensHex = {
    white000: '#FFFFFF',
    white100: '#F4F4F4',
    grey000: '#282828',
    grey100: '#535353',
    grey200: '#737373',
    grey300: '#898989',
    grey400: '#9E9E9E',
    grey500: '#B4B4B4',
    grey600: '#C4C4C4',
    grey700: '#C9C9C9',
    grey800: '#DFDFDF',
    grey900: '#F0F0F0'
};

export const secondaryColorTokensHex = {
    brown000: '#8F5D4D',
    blue000: '#3B4157',
    purple000: '#554F5B',
    warmGrey000: '#7F7470',
    green000: '#506966'
};

export const secondaryColorTokens = {
    brown000: (opacity = 1) => hexToRGBA(secondaryColorTokensHex.brown000, opacity),
    blue000: (opacity = 1) => hexToRGBA(secondaryColorTokensHex.blue000, opacity),
    green000: (opacity = 1) => hexToRGBA(secondaryColorTokensHex.green000, opacity),
    purple000: (opacity = 1) => hexToRGBA(secondaryColorTokensHex.purple000, opacity)
};

export const tertiaryColorTokens = {
    yellow000: (opacity = 1) => hexToRGBA('#FFF7E2', opacity),
    yellow100: (opacity = 1) => hexToRGBA('#FFEFC5', opacity),
    yellow200: (opacity = 1) => hexToRGBA('#FFD972', opacity),
    yellow300: (opacity = 1) => hexToRGBA('#FCC440', opacity),
    yellow400: (opacity = 1) => hexToRGBA('#EE892B', opacity),
    blue000: (opacity = 1) => hexToRGBA('#F5FAFF', opacity),
    blue100: (opacity = 1) => hexToRGBA('#ECF6FF', opacity),
    blue200: (opacity = 1) => hexToRGBA('#B0C7FF', opacity),
    blue300: (opacity = 1) => hexToRGBA('#4330FC', opacity),
    blue400: (opacity = 1) => hexToRGBA('#190D7B', opacity),
    red000: (opacity = 1) => hexToRGBA('#FCEBE6', opacity),
    red100: (opacity = 1) => hexToRGBA('#F9D6CE', opacity),
    red200: (opacity = 1) => hexToRGBA('#FFA073', opacity),
    red300: (opacity = 1) => hexToRGBA('#EE5432', opacity),
    red400: (opacity = 1) => hexToRGBA('#C10D30', opacity),
    green000: (opacity = 1) => hexToRGBA('#DEF5E5', opacity),
    green100: (opacity = 1) => hexToRGBA('#BDEBCC', opacity),
    green200: (opacity = 1) => hexToRGBA('#2ED196', opacity),
    green300: (opacity = 1) => hexToRGBA('#2AA779', opacity),
    green400: (opacity = 1) => hexToRGBA('#286E55', opacity)
};

export const supportColors = {
    crimson: (opacity = 1) => hexToRGBA('#d0354c', opacity),
    coral: (opacity = 1) => hexToRGBA('#ef865c', opacity),
    aqua: (opacity = 1) => hexToRGBA('#a2d6e0', opacity),
    indigo: (opacity = 1) => hexToRGBA('#6e6ab5', opacity),
    teal: (opacity = 1) => hexToRGBA('#16bd71', opacity),
    gold: (opacity = 1) => hexToRGBA('#ecc22a', opacity)
};

export const grey = {
    black: (opacity = 1) => hexToRGBA('#000000', opacity),
    grey000: (opacity = 1) => hexToRGBA('#212121', opacity),
    grey100: (opacity = 1) => hexToRGBA('#303030', opacity),
    grey200: (opacity = 1) => hexToRGBA('#424242', opacity),
    grey300: (opacity = 1) => hexToRGBA('#666666', opacity),
    grey400: (opacity = 1) => hexToRGBA('#9e9e9e', opacity),
    grey500: (opacity = 1) => hexToRGBA('#c4c4c4', opacity),
    grey600: (opacity = 1) => hexToRGBA('#e0e0e0', opacity),
    grey700: (opacity = 1) => hexToRGBA('#e8e8e8', opacity),
    grey800: (opacity = 1) => hexToRGBA('#f5f5f5', opacity),
    grey900: (opacity = 1) => hexToRGBA('#fafafa', opacity),
    white: (opacity = 1) => hexToRGBA('#ffffff', opacity)
};

export const coral = {
    lightest: (opacity = 1) => hexToRGBA('#c8c8ff', opacity),
    lighter: (opacity = 1) => hexToRGBA('#9595fb', opacity),
    light: (opacity = 1) => hexToRGBA('#6060e9', opacity),
    regular: (opacity = 1) => hexToRGBA('#3939cd', opacity),
    dark: (opacity = 1) => hexToRGBA('#1e1e97', opacity),
    darker: (opacity = 1) => hexToRGBA('#10103d', opacity)
};
