import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {Caption} from '@/componentLibrary/components/typography/Captions';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {
    ColorBaseGreen700,
    ColorBaseGrey700,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

import messages from './messages';

export function AssessmentsCompleted() {
    return (
        <Wrapper>
            <Icon icon={Icons.CHECK_CIRCLE} fill={ColorBaseGreen700} />
            <TextWrapper>
                <P2>
                    <FormattedMessage {...messages.completedAndPublished} />
                </P2>
                <Caption color={ColorBaseGrey700}>
                    <FormattedMessage {...messages.complete} />
                </Caption>
            </TextWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-inline-start: ${SpacingXsmall};
`;
