import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.logicTestIrt.pages.logicTest.components.testForm.leaveTheTestModal.title'
    },
    description: {
        id: 'app.pages.logicTestIrt.pages.logicTest.components.testForm.leaveTheTestModal.description'
    },
    continue: {
        id: 'app.pages.logicTestIrt.pages.logicTest.components.testForm.leaveTheTestModal.continue'
    },
    leaveTheTest: {
        id: 'app.pages.logicTestIrt.pages.logicTest.components.testForm.leaveTheTestModal.leaveTheTest'
    }
});
