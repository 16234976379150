import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {User} from '@/api/users/useUserValidationTest';
import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {FormattedHTMLMessage} from '@/componentLibrary/components/FormattedHTMLMessage';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {getUserName} from '@/utils/misc';

import messages from '../../messages';

type Props = {
    close: () => void;
    user: User;
    requestValidationTest: () => void;
    requestingValidation: boolean;
};

export const TestValidationModal = ({
    close,
    user,
    requestValidationTest,
    requestingValidation
}: Props) => (
    <Modal
        close={close}
        headerTitle={<FormattedMessage {...messages.confirmationModalHeader} />}
    >
        <DescriptionWrapper>
            <DescriptionTextWrapper>
                <FormattedMessage
                    {...messages.confirmationModalDescription}
                    values={{
                        candidateName: getUserName(user),
                        b: (chunks: string) => <b>{chunks}</b>
                    }}
                />
            </DescriptionTextWrapper>
            <Banner type={BannerType.ATTENTION}>
                <FormattedHTMLMessage {...messages.confirmationModalWarning} />
            </Banner>
        </DescriptionWrapper>
        <ButtonsWrapper>
            <SecondaryButton onClick={close}>
                <FormattedMessage {...messages.cancel} />
            </SecondaryButton>
            <PrimaryButton onClick={requestValidationTest} isLoading={requestingValidation}>
                <FormattedMessage {...messages.sendInvitation} />
            </PrimaryButton>
        </ButtonsWrapper>
    </Modal>
);

const DescriptionWrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 24px;
    white-space: pre-line;
    color: ${greyColorTokens.grey000()};
`;

const DescriptionTextWrapper = styled.div`
    margin-bottom: 24px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
