export const emailCss = `
*:not(br):not(tr):not(html) {
  font-family: "Euclid Circular A", "Open Sans", Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}
table {
    border-collapse: separate;
    border-spacing: 0;
    height: 100%;
}

tr.alva-header {
    height: 150px;
}

a, a:link, a:visited {
    text-decoration: none;
    color: #00788a;
}

a:hover {
    text-decoration: underline;
}

h2, h2 a, h2 a:visited, h3, h3 a, h3 a:visited, h4, h5, h6, .t_cht {
    color: #000 !important;
}

.ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td {
    line-height: 100%;
}

.ExternalClass {
    width: 100%;
}

*:not(br):not(tr):not(html) {
    font-family: 'Euclid Circular A', sans-serif;
    box-sizing: border-box;
}

body {
    font-family: 'Euclid Circular A', sans-serif;
    width: 100% !important;
    height: 100%;
    margin: 0;
    line-height: 1.4;
    -webkit-text-size-adjust: none;
}

p,
ul,
ol,
blockquote {
    line-height: 1.4;
    text-align: left;
}

a {
    color: #4330FC;
}

a img {
    border: none;
}

td {
    word-break: break-word;
    padding: 0;
}

pre {
    font-size: inherit;
    color: inherit;
    border: initial;
    padding: initial;
    font-family: inherit;
    margin: 0;
}

.email-wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
    -premailer-width: 100%;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
}

.email-header {
    width: 100%;
    margin: 0;
    padding: 0;
    -premailer-width: 100%;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
}

.email-content {
    background-color: #F5F5F5;
    width: 100%;
    margin: 0;
    padding: 0;
    -premailer-width: 100%;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
}

.email-footer {
    margin: 0;
    width: 100%;
    -premailer-width: 100%;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
}

.alva-company-details {
    padding-top: 20px;
}

.email-header-cell {
    padding: 25px 0;
    text-align: center;
}

.email-header-logo {
    width: 140px;
}

.email-header-logo img {
    width: 140px;
}

.email-body {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    -premailer-width: 100%;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
}

.email-body_inner {
    width: 620px;
    padding: 20px;
    max-width: 620px;
    -premailer-width: 620px;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
    background-color: #FFFFFF;
    color: #282828;
}

.content-cell {
    text-align: left;
    padding: 20px 0;
}

.preheader {
    display: none !important;
    mso-hide: all;
    font-size: 1px;
    line-height: 1px;
    max-width: 0;
    opacity: 0;
    overflow: hidden;
}

.test-box {
    padding: 16px;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    height: 151px;
}

span.test-title {
    font-size: 16px;
    line-height: 28px;
    padding: 0 0 8px 0;
    color: #282828;
    padding: 0 1px 0 0;
}

span.test-description {
    font-size: 12px;
    line-height: 15px;
    color: #737373;
}

.email-boilerplate {
    margin: 60px 0;
    width: 620px;
}

.email-boilerplate p {
    font-size: 12px;
    color: #535353;
    margin: 0 0 4px 0;
}

.email-footer .email-footer-logo {
    padding: 25px 0;
    width: 100px;
    text-align: center;
}

.email-footer .email-footer-logo img {
    width: 100px;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

@media only screen and (max-width: 600px) {
    table.content-table {
        width: 100% !important;
    }

    .email-body,
    .email-footer-links-wrapper {
        padding: 0 20px;
    }

    .email-boilerplate {
        padding: 0 20px;
    }

}

@media only screen and (max-width: 500px) {
    .button {
        width: 100% !important;
    }

}

.button {
    background-color: #F6C243;
    display: block;
    color: #282828 !important;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.16);
    -webkit-text-size-adjust: none;
    text-align: center;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    padding: 0 10px;
    border: none;
    border-radius: 40px;
}

.button:hover {
    background-color: #EE892B;
    text-decoration: none;
}

h1 {
    margin: 0 auto 40px;
    font-size: 24px;
    font-weight: normal;
    text-align: left;
    color: #282828;
    text-align: center;
    max-width: 520px;
}

p {
    margin: -16px 0 0 0;
    font-size: 16px;
    line-height: 1.5em;
    text-align: left;
}

p.sub {
    font-size: 12px;
}

p.center {
    text-align: center;
}

@media (min-width: 20px) {
    table[class="container"] {
        width: 100%;
    }

    table[class="table-left"] {
        width: 100%;
    }

    table[class="table-right"] {
        width: 100%;
    }
}

@media (min-width: 480px) {
    table[class="table-left"] {
        width: 50%;
    }

    table[class="table-right"] {
        width: 50%;
    }
}

@media (min-width: 580px) {
    table[class="container"] {
        width: 580px;
    }
}

@media (max-width: 480px) {
    table[class="table-left"] {
        padding: 0 !important;
    }

    table[class="table-right"] {
        padding: 0 !important;
    }

    table.table-left {
        padding: 0 !important;
    }

    table.table-right {
        padding: 0 !important;
    }

    table.questions-and-time {
        width: 100% !important;
    }

    td.questions {
        text-align: left !important;
        float: left;
    }

    td.time {
        text-align: right !important;
        float: right;
    }

    table.title-and-badge {
        height: 28px !important;
        width: 100% !important;
    }

    td.title {
        text-align: left !important;
        float: left;
    }

    td.badge {
        text-align: right !important;
        float: right;
    }
}

span.completed-badge {
    flex: 0 0 auto;
    height: 28px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #BDEBCC;
    background-color: #BDEBCC;
    color: #286E55;
    display: inline-block;
    font-size: 12px;
    line-height: normal;
    padding: 6px 10px;
    margin: 0;
    max-width: 280px;
}

td.share-tests-cell {
    padding: 0 0 20px 0;
}

p.main-paragraph {
    white-space: pre-wrap;
}

p.main-paragraph * {
    line-height: 2.5;
}

.alva-header-logo {
    background-color: #F5F5F5;
    padding: 25px 0;
}

.job-name-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    height: 90px;
}

.company-header-logo {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 20px;
    color: #414141;
}

.company-header-logo img {
    width: 100px;
    object-fit: contain;
    margin-right: 20px;
}


`;
