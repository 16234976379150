import styled, {css} from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderBaseDefaultColor,
    BorderWidthBase,
    BorderWidthEmphasis,
    FontSizeSmall,
    SpacingLarge,
    SpacingSmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import {WrapperProps} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/components/FormNavigation/types';

export const Wrapper = styled.span<WrapperProps>`
    background: linear-gradient(
            106.7deg,
            rgb(255 255 255 / 33%) 0%,
            rgb(255 255 255 / 0%) 100%
        ),
        #fff;
    border: ${BorderWidthEmphasis} solid ${BorderBaseDefaultColor};
    border-radius: 48px;
    cursor: pointer;

    &:hover {
        box-shadow: 0 ${SpacingXxsmall} ${SpacingLarge} rgb(0 0 0 / 15%),
            0 ${BorderWidthBase} ${BorderWidthBase} rgb(0 0 0 / 8%),
            0 ${SpacingXxxsmall} ${SpacingXxxsmall} rgb(0 0 0 / 8%);
    }

    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${FontSizeSmall} ${SpacingLarge};
    font-size: ${FontSizeSmall};
    color: #000;

    @media (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        padding: ${SpacingXxsmall} ${SpacingSmall};
    }

    ${props =>
        props.isLoading &&
        css`
            cursor: wait;
        `}
    ${props =>
        props.isDisabled &&
        css`
            cursor: not-allowed;
        `}
    ${props =>
        props.isHidden &&
        css`
            display: none;
        `}
`;
