import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    width: 100%;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        display: flex;
        flex-direction: column;
    }
`;

export const DescriptionWrapper = styled(P2)`
    margin-top: ${SpacingXsmall};
    max-width: 425px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        max-width: none;
    }
`;
