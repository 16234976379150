/**
* DO NOT EDIT, THIS FILE IS GENERATED FROM A TEMPLATE
* See localeConstants.ts.jinja and run "yarn sync-locales"
*/
export const AVAILABLE_LOCALE_NAMES: Record<string, string> = {
    'en': 'English (United States)',
    'sv': 'Svenska',
    'fi': 'Suomeksi',
    'nb': 'Norsk',
    'da': 'Dansk',
    'de': 'Deutsch',
    'lv': 'Latviešu',
    'lt': 'Lietuvių',
    'et': 'Eesti keel',
    'nl': 'Nederlands',
    'es': 'Español',
    'en-gb': 'English (United Kingdom)',
    'fr-fr': 'Français',
    'pt-br': 'Português',
    'zh-cn': 'Chinese',
    'ar': 'Arabic',
    'pl': 'Polski',
    'it': 'Italiano',
    'cs': 'Czech',
    'sk': 'Slovenčina',
    'ja': 'Japanese'
};

export const AVAILABLE_LOCALES = Object.keys(AVAILABLE_LOCALE_NAMES);

export const BASIC_LOCALES = ['en', 'sv', 'fi', 'nb'];

export const RIGHT_TO_LEFT_LANGUAGES = ['ar'];
