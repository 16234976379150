import {Overline} from '@/componentLibrary/components/typography';
import {
    ColorBaseGrey700,
    SpacingMedium,
    SpacingXlarge,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const DetailsWrapper = styled.div`
    margin-bottom: ${SpacingMedium};

    &:last-child {
        margin-bottom: 0;
    }

    display: flex;
    flex-direction: column;
`;

export const ItemTitle = styled(Overline)`
    color: ${ColorBaseGrey700};
    margin-bottom: ${SpacingXxsmall};
`;

export const List = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ListItem = styled.div`
    display: flex;
    align-items: center;
    margin-right: ${SpacingXlarge};
`;

export const Bullet = styled.div<{backgroundColor: string}>`
    width: 6px;
    height: 6px;
    background-color: ${({backgroundColor}) => backgroundColor};
    border-radius: 50%/50%;
    margin-right: 10px;
    display: inline-block;
`;
