import {useMemo} from 'react';

import {useJobApplication} from '@/api/assessment/jobApplications/useJobApplication';
import {GetJobApplication_JobApplication_JobPosition_Interviews} from '@/api/assessment/types';
import {useHasCombinedRoleFitBetaTestByJobPositionId} from '@/hooks/useHasCombinedRoleFitBetaTest';
import {isArrayWithItems} from '@/utils/typeGuards/isArrayWithItems';

export function useCandidateInterviewPageLogic(jobPositionId: number, userId: number) {
    const hasCombinedRoleFitBetaTest =
        useHasCombinedRoleFitBetaTestByJobPositionId(jobPositionId);
    const {jobApplication, loading, error, refetch} = useJobApplication(
        jobPositionId,
        userId,
        {
            withCandidate: true,
            withRoleFit: true,
            withRoleFitV2: hasCombinedRoleFitBetaTest,
            withRoleFitDistribution: true,
            withRoleFitHistogram: true,
            withInterviewScoresAndInterview: true,
            withJobPosition: true,
            withInterviews: true
        }
    );
    const interviewAggregatedScores = useMemo(() => {
        if (!isArrayWithItems(jobApplication?.interviewAggregatedScores)) {
            return [];
        }

        return jobApplication?.interviewAggregatedScores ?? [];
    }, [jobApplication]);

    const interviews: GetJobApplication_JobApplication_JobPosition_Interviews = useMemo(() => {
        if (!isArrayWithItems(jobApplication?.jobPosition?.interviews)) {
            return [];
        }

        return jobApplication?.jobPosition?.interviews ?? [];
    }, [jobApplication]);

    return {
        loading,
        error,
        refetch,
        jobApplication,
        interviewAggregatedScores,
        interviews,
        hasCombinedRoleFitBetaTest
    };
}
