import {gql} from '@/api/types/__generated__';

export const CREATE_INTERVIEW_REQUEST = gql(`
    mutation CreateInterviewRequest(
        $data: InterviewRequestInput!
        $notifyInterviewer: Boolean
    ) {
        createInterviewRequest(data: $data, notifyInterviewer: $notifyInterviewer) {
            ok
            errorMessage
            jobApplication {
                id
                interviewRequests {
                    id
                    jobApplicationId
                    employmentId
                }
            }
            interviewRequest {
                id
                jobApplicationId
                employmentId
            }
        }
    }
`);

export const CREATE_INTERVIEW_REQUESTS_FOR_JOB_APPLICATIONS = gql(`
    mutation CreateInterviewRequestsForJobApplications(
        $data: InterviewRequestsForJobApplicationsInput!
        $notifyInterviewers: Boolean
    ) {
        createInterviewRequestsForJobApplications(
            data: $data
            notifyInterviewers: $notifyInterviewers
        ) {
            ok
            errorMessage
            interviewRequests {
                id
            }
        }
    }
`);

export const DELETE_INTERVIEW_REQUESTS = gql(`
    mutation DeleteInterviewRequests($data: InterviewRequestsInput!) {
        deleteInterviewRequests(data: $data) {
            ok
            errorMessage
        }
    }
`);

export const SAVE_INTERVIEW_COMPETENCY_SCORE = gql(`
    mutation saveInterviewCompetencyScore($data: InterviewCompetencyScoreInput!) {
        saveInterviewCompetencyScore(data: $data) {
            ok
            errorMessage
            interviewRequest {
                id
                competencyScores {
                    id
                    created
                    modified
                    score
                    comment
                    isSkipped
                }
            }
        }
    }
`);

export const SKIP_INTERVIEW_COMPETENCY_SCORE = gql(`
    mutation skipInterviewCompetencyScore($data: SkipInterviewCompetencyScoreInput!) {
        skipInterviewCompetencyScore(data: $data) {
            ok
            errorMessage
            interviewRequest {
                id
                competencyScores {
                    id
                    created
                    modified
                    score
                    comment
                    isSkipped
                }
            }
        }
    }
`);

export const SUBMIT_INTERVIEW_SCORECARD = gql(`
    mutation submitInterviewScorecard($id: Int!) {
        submitInterviewScorecard(interviewRequestId: $id) {
            ok
            errorMessage
        }
    }
`);

export const DELETE_INTERVIEW_REQUEST = gql(`
    mutation deleteInterviewRequest($id: Int!) {
        deleteInterviewRequest(interviewRequestId: $id) {
            ok
            errorMessage
            interviewRequestId
        }
    }
`);

export const UNPUBLISH_INTERVIEW_SCORECARD = gql(`
    mutation unpublishInterviewScorecard($id: Int!) {
        unpublishInterviewScorecard(interviewRequestId: $id) {
            ok
            errorMessage
        }
    }
`);

export const CREATE_INTERVIEW = gql(`
    mutation CreateInterview($data: InterviewInput!) {
        createInterview(data: $data) {
            ok
            errorMessage
            interview {
                id
                title
                created
                modified
                description
                jobPositionId
                order
            }
        }
    }
`);

export const CREATE_INTERVIEW_FROM_TEMPLATE = gql(`
    mutation createInterviewFromTemplate($data: CreateInterviewFromTemplateInput!) {
        createInterviewFromTemplate(data: $data) {
            ok
            errorMessage
            interview {
                id
                jobPositionId
                created
                modified
                title
                description
                order
            }
        }
    }
`);

export const UPDATE_INTERVIEW = gql(`
    mutation UpdateInterview($id: Int!, $data: UpdateInterviewInput!) {
        updateInterview(interviewId: $id, data: $data) {
            ok
            errorMessage
            interview {
                id
                title
                created
                modified
                description
                jobPositionId
                order
            }
        }
    }
`);

export const DELETE_INTERVIEW = gql(`
    mutation DeleteInterview($id: Int!) {
        deleteInterview(interviewId: $id) {
            ok
            errorMessage
            interviewId
        }
    }
`);

export const CREATE_INTERVIEW_TEMPLATE = gql(`
    mutation CreateInterviewTemplate($data: InterviewTemplateInput!) {
        createInterviewTemplate(data: $data) {
            ok
            errorMessage
            interviewTemplate {
                id
                title
                description
                organizationId
                favorite
                createdByAlva
            }
        }
    }
`);

export const UPDATE_INTERVIEW_TEMPLATE = gql(`
    mutation UpdateInterviewTemplate($id: String!, $data: InterviewTemplateInput!) {
        updateInterviewTemplate(interviewTemplateId: $id, data: $data) {
            ok
            errorMessage
            interviewTemplate {
                id
                title
                description
                organizationId
                favorite
                createdByAlva
            }
        }
    }
`);

export const DELETE_INTERVIEW_TEMPLATE = gql(`
    mutation DeleteInterviewTemplate($id: String!) {
        deleteInterviewTemplate(interviewTemplateId: $id) {
            ok
            errorMessage
            interviewTemplateId
        }
    }
`);

export const CREATE_ORGANIZATION_INTERVIEW_TAG = gql(`
    mutation CreateOrganizationInterviewTag($data: CreateOrganizationInterviewTagInput!) {
        createOrganizationInterviewTag(data: $data) {
            ok
            errorMessage
            interviewTag {
                id
                name
            }
        }
    }
`);

export const UPDATE_ORGANIZATION_INTERVIEW_TAG = gql(`
    mutation updateOrganizationInterviewTag($data: UpdateOrganizationInterviewTagInput!) {
        updateOrganizationInterviewTag(data: $data) {
            ok
            errorMessage
        }
    }
`);

export const DELETE_ORGANIZATION_INTERVIEW_TAG = gql(`
    mutation deleteOrganizationInterviewTag($data: DeleteOrganizationInterviewTagInput!) {
        deleteOrganizationInterviewTag(data: $data) {
            ok
            errorMessage
        }
    }
`);

export const CREATE_ORGANIZATION_INTERVIEW_CONTENT_LIBRARY_ITEM = gql(`
    mutation createContentLibraryItem($data: CreateInterviewContentLibraryItemInput!) {
        createInterviewContentLibraryItem(data: $data) {
            ok
            errorMessage
            interviewContentLibraryItem {
                ...ContentLibraryItemsFields
            }
        }
    }
`);

export const DELETE_ORGANIZATION_INTERVIEW_CONTENT_LIBRARY_ITEMS = gql(`
    mutation deleteOrganizationContentLibraryItems($data: DeleteInterviewContentLibraryItemsInput!) {
        deleteInterviewContentLibraryItems(data: $data) {
            ok
            errorMessage
            deletedInterviewContentLibraryItemIds
        }
    }
`);

export const UPDATE_ORGANIZATION_INTERVIEW_CONTENT_LIBRARY_ITEM = gql(`
    mutation updateOrganizationInterviewContentLibraryItem($data: UpdateInterviewContentLibraryItemInput!) {
      updateInterviewContentLibraryItem(data: $data) {
        ok
        errorMessage
        interviewContentLibraryItem {
            ...ContentLibraryItemsFields
        }
      }
    }
`);

export const SET_CONTENT_LIBRARY_ITEMS_ON_INTERVIEW_TEMPLATE = gql(`
    mutation setContentLibraryItemsOnInterviewTemplate($data: SetContentLibraryItemsOnInterviewTemplateInput!) {
      setContentLibraryItemsOnInterviewTemplate(data: $data) {
        ok
        errorMessage
        contentLibraryItems {
            id
        }
      }
    }
`);

export const SET_CONTENT_ITEMS_ON_INTERVIEW = gql(`
    mutation setContentItemsOnInterview($data: SetContentItemsOnInterviewInput!) {
      setContentItemsOnInterview(data: $data) {
        ok
        errorMessage
        contentItems {
            id
        }
      }
    }
`);
