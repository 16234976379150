import {useMemo} from 'react';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import messages from '../../messages';
import {LeadershipReportSectionValue} from '../../types';
import {DimensionRow} from './components/DimensionRow';
import {SectionTitle, Wrapper} from './styled';
import {FactorsOverviewProps} from './types';

export function SectionOverview({section}: FactorsOverviewProps) {
    const phrases = useExtractPhraseConstants(messages);
    const dimensionRows = useMemo(() => {
        return section.dimensions.map(dimension => (
            <DimensionRow key={dimension.name} dimension={dimension} />
        ));
    }, [section.dimensions]);

    return (
        <Wrapper>
            <SectionTitle>
                {phrases[section.name as LeadershipReportSectionValue]}
            </SectionTitle>
            {dimensionRows}
        </Wrapper>
    );
}
