import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderRadiusBaseXsmall,
    ColorBgDefault,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';

export const OuterWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const InnerWrapper = styled.div`
    @media only screen and (min-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        max-width: 964px;
    }

    display: flex;
    justify-content: center;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
    }
`;

export const ContentWrapper = styled.div`
    background-color: ${ColorBgDefault};
    max-width: 440px;
    height: 100%;
    padding: ${SpacingXxlarge};
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
    }

    @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
        width: 440px;
    }

    border-radius: ${BorderRadiusBaseXsmall};
`;
