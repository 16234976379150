import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography';
import {grey} from '@/componentLibrary/tokens/legacyColors';

import {OVERLAP} from './constants';

export const Wrapper = styled.div<{
    numberOfCircles: number;
    avatarSize: number;
    displayDetails: boolean;
}>`
    display: flex;
    width: ${({avatarSize, numberOfCircles}) =>
        avatarSize * numberOfCircles - (numberOfCircles - 1) * OVERLAP}px;
    ${({displayDetails}) => (displayDetails ? 'cursor: pointer' : '')};
`;

export const DetailsWrapper = styled.div`
    width: 280px;
`;

export const Row = styled.div`
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const UserWrapper = styled.div`
    display: flex;
`;

export const UserName = styled(P2)`
    margin-left: 12px;
`;

export const Filler = styled.div<{size: number}>`
    border-radius: 50%;
    height: ${({size}) => size}px;
    width: ${({size}) => size}px;
    visibility: hidden;
`;

export const AvatarWrapper = styled.div<{index: number; size?: number}>`
    position: relative;
    left: ${({index}) => -OVERLAP - index * OVERLAP}px;
    width: 24px;
    ${({size}) => size && `height: ${size}px;`}
`;

export const ShowMoreIcon = styled.div<{size: number}>`
    border-radius: 50%;
    height: ${({size}) => size}px;
    width: ${({size}) => size}px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 8%);
    position: relative;
    overflow: hidden;
    background: ${grey.grey900()};
    border: 1px solid ${grey.grey500()};
    text-align: center;
`;

export const ShowMoreText = styled.span<{size: number; textSize: number}>`
    display: block;
    line-height: ${({size}) => size}px;
    text-align: center;
    font-size: ${({textSize}) => textSize}px;
`;
