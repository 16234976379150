import {useCallback, useState} from 'react';

import {useUserWithRecommendations} from '@/api/users/useRecommendationsUser';

import {useMyJobRecommendations} from '../jobRecommendations/useMyJobRecommendations';
import {REAL_TIME_EVENTS} from './constants';
import {useRealTimeUpdates} from './useRealTimeUpdates';

export function useWaitForJobRecommendationsCreatedForUser() {
    const {user, refetch: refetchUserRecommendationSettings} = useUserWithRecommendations();
    const {refetch: refetchUserJobRecommendations} = useMyJobRecommendations();

    const [isWaitingForJobRecommendations, setIsWaitingForJobRecommendations] =
        useState(false);

    const hideLoaderAndRefetch = useCallback(async () => {
        await refetchUserRecommendationSettings();
        await refetchUserJobRecommendations();
        setIsWaitingForJobRecommendations(false);
    }, [refetchUserRecommendationSettings, refetchUserJobRecommendations]);

    useRealTimeUpdates({
        userId: user?.id,
        callback: hideLoaderAndRefetch,
        eventName: REAL_TIME_EVENTS.JOB_RECOMMENDATIONS_CREATED_FOR_USER
    });

    const showJobRecommendationsLoader = useCallback(
        () => setIsWaitingForJobRecommendations(true),
        []
    );

    return {
        isWaitingForJobRecommendations,
        showJobRecommendationsLoader
    };
}
