import styled from 'styled-components';

import {SpacingMedium} from '@/componentLibrary/tokens/variables';
import alvaLogo from './assets/logo.svg';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

export const Header = styled.div`
    flex: 0 0 60px;
    padding: 0 ${SpacingMedium};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Logo = styled.div`
    background: transparent url("${alvaLogo}") center center no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
`;

export const Body = styled.div`
    flex: 1;
    padding: 0 ${SpacingMedium};
`;
