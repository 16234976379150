import {defineMessages} from 'react-intl';

export const personalityTestIntroMessages = defineMessages({
    danish: {
        id: 'personalityTestIntro.danish'
    },
    english: {
        id: 'personalityTestIntro.english'
    },
    errorCreatingTest: {
        id: 'personalityTestIntro.errorCreatingTest'
    },
    finnish: {
        id: 'personalityTestIntro.finnish'
    },
    norwegian: {
        id: 'personalityTestIntro.norwegian'
    },
    questions: {
        id: 'personalityTestIntro.questions'
    },
    results: {
        id: 'personalityTestIntro.results'
    },
    secure: {
        id: 'personalityTestIntro.secure'
    },
    selectLanguage: {
        id: 'personalityTestIntro.selectLanguage'
    },
    startPersonalityTestTitle: {
        id: 'personalityTestIntro.startPersonalityTestTitle'
    },
    startTest: {
        id: 'personalityTestIntro.startTest'
    },
    swedish: {
        id: 'personalityTestIntro.swedish'
    },
    testGuidelinesFirst: {
        id: 'personalityTestIntro.testGuidelinesFirst'
    },
    testGuidelinesSecond: {
        id: 'personalityTestIntro.testGuidelinesSecond'
    },
    testGuidelinesThird: {
        id: 'personalityTestIntro.testGuidelinesThird'
    },
    testInfo: {
        id: 'personalityTestIntro.testInfo'
    },
    time: {
        id: 'personalityTestIntro.time'
    },
    goBack: {
        id: 'app.backLink.goBack'
    }
});
