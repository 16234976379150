import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_DEFAULT_EMAIL_TEMPLATE} from '@/api/organizations/emailTemplates/queries';
import {useLocale} from '@/containers/LanguageProvider/useLocale';

export function useDefaultEmailTemplate(emailType: string) {
    const locale = useLocale();

    const variables = {locale, emailType};
    const {data, loading, error, refetch} = useQuery(GET_DEFAULT_EMAIL_TEMPLATE, {
        variables
    });

    const defaultEmailTemplate = useMemo(() => data?.defaultEmailTemplate ?? null, [data]);

    return {loading, error, refetch, defaultEmailTemplate};
}
