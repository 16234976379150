import {useToasts} from '../../toast';
import {SingleToast} from '../SingleToast';
import {ToastsWrapper} from './styled';

export const ToastsList = () => {
    const {toasts} = useToasts();

    return (
        <ToastsWrapper>
            {toasts.map(toast => (
                <SingleToast key={toast.id} {...toast} />
            ))}
        </ToastsWrapper>
    );
};
