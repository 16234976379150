import {IntlShape} from 'react-intl';

import {EmptyState} from '@/pages/Organization/pages/Assessment/components/CodingTests/EmptyState';

import messages from '../../CodingTest/messages';

export function convertToHoursAndMinutes(
    totalMinutes: number,
    intl: IntlShape,
    isShortFormat?: boolean,
    notParagraph = false
) {
    if (!totalMinutes) {
        return <EmptyState notParagraph={notParagraph} />;
    }
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    if (isShortFormat && hours > 0 && minutes === 0) {
        return hours;
    }
    return `${hours > 0 ? `${hours} ${intl.formatMessage(messages.effortHour)}` : ''} ${
        minutes > 0 ? `${minutes} ${intl.formatMessage(messages.effortMin)}` : ''
    }`.trim();
}

export function notEmpty<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
}

export function getUniqueArrayItems(arr: string[]) {
    const uniqueItems = new Set<string>();
    const resultArray: string[] = [];
    arr.forEach(item => {
        const lowerCasedItem = item.toLowerCase();
        if (!uniqueItems.has(lowerCasedItem)) {
            uniqueItems.add(lowerCasedItem);
            resultArray.push(item);
        }
    });
    return resultArray;
}
