import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXxlarge} from '@/componentLibrary/tokens/variables';

const AuthenticationFormWrapper = styled.div`
    padding: ${SpacingXxlarge};
    display: flex;
    flex-direction: column;
    width: 100%;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        padding: 0;
    }
`;

const AuthenticationErrorPlaceholder = styled.div`
    height: 0;
    width: 100%;
`;

export {AuthenticationFormWrapper, AuthenticationErrorPlaceholder};
