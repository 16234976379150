import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingLarge, SpacingMedium, SpacingXlarge} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    margin: ${SpacingMedium} 0;
    padding: 0 ${SpacingMedium};
    width: 100%;
    max-width: 992px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        max-width: 500px;
        margin: 84px auto 68px;
    }

    @media print {
        position: static;
        padding: 0;
        max-width: none;
        zoom: 90%;
    }

    @page {
        size: a4; /* auto is the initial value */
        margin: ${SpacingMedium}; /* this affects the margin in the printer settings */
    }
`;

export const BannerWrapper = styled.div`
    margin: ${SpacingMedium} 0;
`;

export const FactorsOverviewWrapper = styled.div`
    margin-top: ${SpacingXlarge};
    width: 100%;
`;

export const StrengthsAndChallengesWrapper = styled.div`
    margin-top: ${SpacingXlarge};
`;

export const GrowthFactorsWrapper = styled.div`
    margin-top: ${SpacingLarge};
`;

export const LeadershipOverviewWrapper = styled.div`
    margin-top: ${SpacingLarge};

    @media print {
        overflow-y: auto !important;
        page-break-before: always;
        break-before: page;
        page-break-inside: avoid;
        break-inside: avoid;
    }
`;

export const LeadershipDevelopmentPlanBannerWrapper = styled.div`
    @media print {
        display: none;
    }
`;
