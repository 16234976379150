import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_LOGIC_TEST_DATA_COLLECTION_RESULT} from './queries';

export function useLogicTestDataCollectionResult(logicTestId: string) {
    const variables = {logicTestId};
    const {data, error, loading, refetch, stopPolling} = useQuery(
        GET_LOGIC_TEST_DATA_COLLECTION_RESULT,
        {variables, notifyOnNetworkStatusChange: true, pollInterval: 1000}
    );

    const logicTestDataCollectionResult = useMemo(
        () => data?.logicalAbilityEstimate ?? null,
        [data]
    );
    return {error, loading, logicTestDataCollectionResult, refetch, stopPolling};
}
