import {ApolloCache, FetchResult, useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';
import {JOB_RECOMMENDATION_STATUS} from '@/pages/User/components/JobRecommendations/constants';
import {logger} from '@/services/logrocket';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {ApplyToJobRecommendationMutation} from '../types/__generated__/graphql';
import {APPLY_TO_JOB_RECOMMENDATION} from './mutations';

function updateCache(
    cache: ApolloCache<ApplyToJobRecommendationMutation>,
    response: FetchResult<ApplyToJobRecommendationMutation>
) {
    const {data} = response;
    if (!data?.applyToJobRecommendation?.ok) {
        return;
    }

    const {jobRecommendationId} = data.applyToJobRecommendation;

    try {
        const newStatus = JOB_RECOMMENDATION_STATUS.ACCEPTED;

        cache.modify({
            id: cache.identify({id: jobRecommendationId, __typename: 'JobRecommendation'}),
            fields: {
                status() {
                    return newStatus;
                }
            }
        });
    } catch (error) {
        logger.error(error, ' when updating cache.');
    }
}

export function useApplyToJobRecommendation(
    jobRecommendationId: string,
    linkedinProfileUrl = '',
    userId?: number
): MutationHookReturnType<ApplyToJobRecommendationMutation> {
    const [mutate, {error, loading}] = useMutation(APPLY_TO_JOB_RECOMMENDATION);
    const doMutate = useCallback(() => {
        const options = {
            variables: {
                data: {userId: userId as number, jobRecommendationId, linkedinProfileUrl}
            },
            skip: !isDefined(userId),
            update: updateCache
        };
        return mutate(options);
    }, [userId, jobRecommendationId, linkedinProfileUrl, mutate]);

    return [doMutate, {error, loading}];
}
