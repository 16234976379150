import {useQuery} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {GET_LOGICAL_ABILITY_ESTIMATE_WITH_REPORT} from '@/api/logicTestIrt/queries';

export function useLogicalAbilityEstimate(logicTestId?: string, pollInterval = 0) {
    const query = GET_LOGICAL_ABILITY_ESTIMATE_WITH_REPORT;
    const variables = {id: logicTestId as string};
    const {data, error, loading, stopPolling, startPolling} = useQuery(query, {
        variables,
        pollInterval,
        skip: !logicTestId
    });

    const refetch = useCallback(() => {
        startPolling(pollInterval);
    }, [startPolling, pollInterval]);

    const logicalAbilityEstimate = useMemo(() => data?.logicalAbilityEstimate ?? null, [data]);
    return {logicalAbilityEstimate, loading, error, refetch, startPolling, stopPolling};
}
