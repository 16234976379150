import {useIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import styled from 'styled-components';

import {useMyTestProgress} from '@/api/users/useMyTestProgress';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {LoadingScreen} from '@/componentLibrary/components/LoadingScreen';
import {Base} from '@/componentLibrary/components/typography/Base';
import {DisplayMedium} from '@/componentLibrary/components/typography/Display';
import {
    ColorFgSubtle,
    SpacingLarge,
    SpacingMedium,
    SpacingXlarge
} from '@/componentLibrary/tokens/variables';
import {useGoToPersonalityTest} from '@/pages/PersonalityTestIrt/hooks/useGoToPersonalityTest';

import {StartTestForm} from './components/StartTestForm';
import {TestIntro} from './components/TestIntro';
import {personalityTestIntroMessages as messages} from './messages';

type Props = {
    goBack: string;
    shareWithOrganizationId: number;
};

export function StartNewPersonalityTest({goBack, shareWithOrganizationId}: Props) {
    const {formatMessage} = useIntl();
    const goToPersonalityTest = useGoToPersonalityTest(goBack);
    const {
        user,
        loading: userTestProgressLoading,
        error,
        refetch
    } = useMyTestProgress(shareWithOrganizationId);

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    if (userTestProgressLoading) {
        return <LoadingScreen />;
    }

    if (
        user &&
        user.personalityTestProgress &&
        user.personalityTestProgress.sharedPersonalityTestId &&
        user.personalityTestProgress.isSharedPersonalityTestCompleted === false
    ) {
        return <Redirect to={goBack} />;
    }

    return (
        <Wrapper className="notranslate">
            <InnerWrapper>
                <HeaderWrapper data-testid="personality-test-intro-title">
                    <DisplayMedium>
                        {formatMessage(messages.startPersonalityTestTitle)}
                    </DisplayMedium>
                </HeaderWrapper>

                <Description data-testid="personality-test-intro-guidelines">
                    <p>{formatMessage(messages.testGuidelinesFirst)}</p>
                    <p>{formatMessage(messages.testGuidelinesSecond)}</p>
                    <p>{formatMessage(messages.testGuidelinesThird)}</p>
                </Description>
                <TestBulletsWrapper>
                    <TestIntro />
                </TestBulletsWrapper>

                <Description color={ColorFgSubtle}>
                    {formatMessage(messages.testInfo)}
                </Description>

                <FormWrapper>
                    <StartTestForm
                        onTestCreated={goToPersonalityTest}
                        shareWithOrganizationId={shareWithOrganizationId}
                        user={user}
                    />
                </FormWrapper>
            </InnerWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-color: #fff;
    padding: ${SpacingMedium};
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-height: 768px) {
        display: block;
    }
`;

const InnerWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
`;

const HeaderWrapper = styled.div`
    margin-bottom: ${SpacingMedium};
`;

const Description = styled(Base)`
    max-width: 720px;
`;

const TestBulletsWrapper = styled.div`
    max-width: 600px;
    margin: ${SpacingLarge} 0;
`;

const FormWrapper = styled.div`
    margin-top: 60px;
    max-width: 260px;

    @media (max-width: 768px) {
        margin-top: ${SpacingXlarge};
        max-width: none;
    }
`;
