import {Fragment, useState} from 'react';
import {useIntl} from 'react-intl';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {LeadershipReportOverview} from '@/componentLibrary/blocks/testReports/PersonalityTest/LeadershipReportOverview';
import {
    hasGrowthFactors,
    hasLeadershipProfile
} from '@/componentLibrary/blocks/testReports/PersonalityTest/PersonalityTestReport/utils';
import {WithTestReportLocale} from '@/componentLibrary/blocks/testReports/WithTestReportLocale';
import {TestFooter} from '@/componentLibrary/blocks/testReports/components/TestFooter';
import {TestDivider} from '@/componentLibrary/blocks/testReports/components/TestFooter/styled';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {S2} from '@/componentLibrary/components/typography/SubTitles';
import {useQuery} from '@/hooks/useQuery';

import {LeadershipReport} from '../../LeadershipReport';
import {PrintHeader} from '../../components/PrintHeader';
import {ReportHeader} from '../../components/ReportHeader';
import {REPORT_TYPES} from '../../constants';
import {useTestReportLocaleLogic} from '../../hooks/useTestReportLocaleLogic';
import messages from '../../messages';
import {ContentWrapperWithDefaultBg} from '../../styled';
import {Anchors} from '../components/Anchors';
import {DetailedFactorResult} from '../components/DetailedFactorResult';
import {FactorFacets} from '../components/FactorFacets';
import {FactorsOverview} from '../components/FactorsOverview';
import {GrowthFactors} from '../components/GrowthFactors';
import {ReadMoreContent} from '../components/ReadMoreContent';
import {ReportIntro} from '../components/ReportIntro';
import {StrengthsAndChallenges} from '../components/StrengthsAndChallenges';
import {PERSONALITY_FACTORS} from '../constants';
import {TransformedValues} from '../types';
import {roundTestResult} from '../utils';
import {PersonalityTestModalType} from './constants';
import {usePersonalityTestReportAnchorsLogic} from './hooks';
import {
    BannerWrapper,
    FactorsOverviewWrapper,
    GrowthFactorsWrapper,
    LeadershipOverviewWrapper,
    StrengthsAndChallengesWrapper,
    Wrapper
} from './styled';
import {PersonalityTestReportProps} from './types';

export function PersonalityTestReport({
    personalityTestResult,
    close,
    isDataCollection = false,
    jobPositionId,
    organizationId
}: PersonalityTestReportProps) {
    const intl = useIntl();
    const roundedResult = roundTestResult(personalityTestResult) as TransformedValues;
    const {reportLocale, changeReportLocale} = useTestReportLocaleLogic();
    const queryParams = useQuery();

    const [activeModalType, setActiveModalType] = useState<PersonalityTestModalType>(
        queryParams.get('showLeadershipReport')
            ? PersonalityTestModalType.LEADERSHIP_PROFILE
            : PersonalityTestModalType.PERSONALITY_TEST
    );
    const {handleAnchorClick} = usePersonalityTestReportAnchorsLogic({
        activeModalType
    });

    const personalityTestReportModal = activeModalType ===
        PersonalityTestModalType.PERSONALITY_TEST && (
        <WithTestReportLocale locale={reportLocale}>
            <Modal
                back={close}
                size={MODAL_SIZE.FULL_PAGE}
                headerCustomRightContent={
                    <ReportHeader
                        reportType={REPORT_TYPES.PERSONALITY_TEST_REPORT}
                        reportUserId={personalityTestResult.user.id}
                        reportLocale={reportLocale}
                        changeReportLocale={changeReportLocale}
                        printTitle={intl.formatMessage(messages.personalityTestReportTitle)}
                        user={personalityTestResult.user}
                        testId={personalityTestResult.personalityTestId}
                        jobPositionId={jobPositionId}
                    />
                }
            >
                <ContentWrapperWithDefaultBg>
                    <Wrapper>
                        {isDataCollection ? (
                            <BannerWrapper>
                                <Banner>
                                    <S2>
                                        This is a preliminary report. We do not share this with
                                        anyone and the result is not tied to any personal
                                        information. Once you close the page, you will not be
                                        able to see the report again.
                                    </S2>
                                </Banner>
                            </BannerWrapper>
                        ) : null}
                        <PrintHeader user={personalityTestResult.user} />
                        <ReportIntro user={personalityTestResult.user} />
                        <Anchors onClick={handleAnchorClick} />
                        <FactorsOverviewWrapper>
                            <FactorsOverview personalityTestResult={roundedResult} />
                        </FactorsOverviewWrapper>
                        <StrengthsAndChallengesWrapper>
                            <StrengthsAndChallenges personalityTestResult={roundedResult} />
                        </StrengthsAndChallengesWrapper>
                        {hasGrowthFactors(personalityTestResult) && (
                            <GrowthFactorsWrapper>
                                <GrowthFactors
                                    topDriversDimensions={
                                        personalityTestResult.topDriversDimensions
                                    }
                                    topCultureDimensions={
                                        personalityTestResult.topCultureDimensions
                                    }
                                />
                            </GrowthFactorsWrapper>
                        )}

                        {hasLeadershipProfile(personalityTestResult) && (
                            <LeadershipOverviewWrapper>
                                <LeadershipReportOverview
                                    leadershipProfile={personalityTestResult.leadershipProfile}
                                    onClickSeeReport={() =>
                                        setActiveModalType(
                                            PersonalityTestModalType.LEADERSHIP_PROFILE
                                        )
                                    }
                                />
                            </LeadershipOverviewWrapper>
                        )}
                        {PERSONALITY_FACTORS.map((factor, index) => (
                            <Fragment key={index}>
                                <PrintHeader user={personalityTestResult.user} />
                                <TestDivider id={factor} />
                                <DetailedFactorResult
                                    personalityTestResult={roundedResult}
                                    factor={factor}
                                />
                                <FactorFacets
                                    personalityTestResult={roundedResult}
                                    factor={factor}
                                />
                            </Fragment>
                        ))}
                        <TestFooter>
                            <ReadMoreContent />
                        </TestFooter>
                    </Wrapper>
                </ContentWrapperWithDefaultBg>
            </Modal>
        </WithTestReportLocale>
    );

    const leadershipProfileReportModal = hasLeadershipProfile(personalityTestResult) &&
        personalityTestResult.leadershipProfile &&
        personalityTestResult.user && (
            <LeadershipReport
                close={() => setActiveModalType(PersonalityTestModalType.PERSONALITY_TEST)}
                leadershipProfile={personalityTestResult.leadershipProfile}
                user={personalityTestResult.user}
                personalityTestId={personalityTestResult.personalityTestId}
                jobPositionId={jobPositionId}
                organizationId={organizationId}
            />
        );

    return (
        <>
            {activeModalType === PersonalityTestModalType.PERSONALITY_TEST &&
                personalityTestReportModal}
            {activeModalType === PersonalityTestModalType.LEADERSHIP_PROFILE &&
                leadershipProfileReportModal}
        </>
    );
}
