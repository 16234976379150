import {useCallback, useMemo} from 'react';

import {GetJobApplication_JobApplication as JobApplication} from '@/api/assessment/types';
import {getAssessmentScore, toInterviewKey} from '@/pages/common/roleFit/utils';
import {onBaseFive} from '@/utils/misc';
import {isArrayWithItems} from '@/utils/typeGuards/isArrayWithItems';

export function useInterviewScoreLogic(jobApplication: JobApplication, interviewId: number) {
    const interviewAggregatedScores = useMemo(() => {
        if (!isArrayWithItems(jobApplication?.interviewAggregatedScores)) {
            return [];
        }

        return jobApplication?.interviewAggregatedScores;
    }, [jobApplication]);

    const calculateRoleFitV1InterviewScore = useCallback(() => {
        const selected = interviewAggregatedScores.find(
            item => item.interview.id === interviewId
        );
        return selected?.score !== undefined && selected?.score != null
            ? onBaseFive(selected.score)
            : undefined;
    }, [interviewAggregatedScores, interviewId]);

    const roleFitV1InterviewScore = useMemo(() => {
        return calculateRoleFitV1InterviewScore();
    }, [calculateRoleFitV1InterviewScore]);

    const roleFitV2InterviewScore = useMemo(() => {
        const key = toInterviewKey(interviewId);
        return getAssessmentScore(key, jobApplication.roleFitV2?.assessmentScores);
    }, [interviewId, jobApplication.roleFitV2?.assessmentScores]);

    return {
        roleFitV1InterviewScore,
        roleFitV2InterviewScore
    };
}
