import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {P2} from '@/componentLibrary/components/typography';

import {Section} from '../Section';
import {useOriginalJobAdLogic} from './logic';
import {Note, Wrapper} from './styled';

type Props = {
    url?: string | null;
};

export function OriginalJobAd({url}: Props) {
    const {urlWithTrackingParameters, phrases} = useOriginalJobAdLogic(url);

    return (
        <Section title={phrases.title}>
            <Wrapper>
                <P2>{phrases.description}</P2>
                <ExternalLink url={urlWithTrackingParameters}>{phrases.link}</ExternalLink>
                <Note>
                    <Icon icon={Icons.INFO} size={20} />
                    {phrases.applicationInfo}
                </Note>
            </Wrapper>
        </Section>
    );
}
