import {useIntl} from 'react-intl';

import {RejectReason} from '@/api/jobRecommendations/useRejectJobRecommendation';
import {Dropdown} from '@/componentLibrary/components/Dropdowns/Dropdown/index';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import {REJECT_REASON_OPTIONS} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation/components/RejectJobRecommendation/constants';
import messages, {rejectReasonMessages} from './messages';
import {ErrorDisplay, Question, QuestionWrapper, Wrapper} from './styled';
import {RejectJobRecommendationBodyProps} from './types';

export function RejectJobRecommendationBody({
    jobName,
    companyName,
    setRejectReason,
    rejectReason,
    setFreeText,
    rejectReasonInputErrors,
    setRejectReasonErrors
}: RejectJobRecommendationBodyProps) {
    const {formatMessage} = useIntl();

    return (
        <Wrapper>
            <P1>{formatMessage(messages.information, {jobName, companyName})}</P1>

            <QuestionWrapper>
                <Question>
                    <Dropdown
                        items={Object.values(REJECT_REASON_OPTIONS).map(option => ({
                            id: option,
                            name: formatMessage(rejectReasonMessages[option])
                        }))}
                        fullWidth
                        onSelect={option => {
                            setRejectReasonErrors({
                                rejectReasonNotValid: false,
                                freeTextNotValid: false
                            });
                            setRejectReason(option ?? ({} as RejectReason));
                        }}
                        label={<P1>{formatMessage(messages.reasonsForDismissal)}</P1>}
                    />
                    {rejectReasonInputErrors?.rejectReasonNotValid && (
                        <ErrorDisplay>
                            {formatMessage(messages.rejectReasonInputNotValid)}
                        </ErrorDisplay>
                    )}
                </Question>
                {rejectReason.id === REJECT_REASON_OPTIONS.OTHER_REASON && (
                    <Question>
                        <TextField
                            type="text"
                            name="Free text"
                            fullWidth
                            onChange={e => {
                                const value = e.target.value;
                                setFreeText(value);
                                setRejectReasonErrors({
                                    rejectReasonNotValid: false,
                                    freeTextNotValid: value.length === 0
                                });
                            }}
                            label={
                                <P1>{formatMessage(messages.reasonsForDismissalFreeText)}</P1>
                            }
                        />
                        {rejectReasonInputErrors?.freeTextNotValid && (
                            <ErrorDisplay>
                                {formatMessage(messages.rejectReasonInputNotValid)}
                            </ErrorDisplay>
                        )}
                    </Question>
                )}
            </QuestionWrapper>
        </Wrapper>
    );
}
