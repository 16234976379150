import {defineMessages} from 'react-intl';

export default defineMessages({
    LOGIC_TEST: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitContribution.criteriaBulletsWithTitle.LOGIC_TEST'
    },
    PERSONALITY_TEST: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitContribution.criteriaBulletsWithTitle.PERSONALITY_TEST'
    }
});
