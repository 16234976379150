import {FormattedMessage} from 'react-intl';

import {OrganizationAvatar} from '@/componentLibrary/components/avatars/OrganizationAvatar';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {CardBase} from '@/componentLibrary/components/cards/CardBase';
import {DisplaySmallest} from '@/componentLibrary/components/typography/Display';
import {SsoProviderType} from '@/services/auth/sso/constants';
import {capitalizeFirstLetter} from '@/utils/misc';

import {OrganizationSelector} from './components/OrganizationSelector';
import {useMyEmploymentLogic} from './hooks';
import messages from './messages';
import {
    BannerWrapper,
    ContentWrapper,
    OrganizationNameWrapper,
    TitleWrapper,
    Wrapper
} from './styled';
import {Props} from './types';

export function MyEmployment({
    userOrganization,
    selectOrganization,
    message,
    accessTokenDetails,
    currentAuthenticationMethod
}: Props) {
    const {organizationIsUnlockedByOktaLogin, handleLoginWithSso, loading} =
        useMyEmploymentLogic(userOrganization, accessTokenDetails);

    const goToOrganization = () => {
        selectOrganization(userOrganization.organizationId);
    };

    return (
        <Wrapper>
            <CardBase isActive>
                {!!message && (
                    <BannerWrapper>
                        <Banner type={BannerType.ERROR} noIcon>
                            <FormattedMessage
                                {...messages.ssoFailed}
                                values={{
                                    provider: userOrganization.enforcedAuthenticationMethod
                                        ? capitalizeFirstLetter(
                                              userOrganization.enforcedAuthenticationMethod
                                          )
                                        : '',
                                    organization: userOrganization.organizationName
                                }}
                            />
                        </Banner>
                    </BannerWrapper>
                )}
                <ContentWrapper>
                    <TitleWrapper>
                        <OrganizationAvatar
                            name={userOrganization.organizationName}
                            image={userOrganization.organizationLogo}
                        />
                        <OrganizationNameWrapper>
                            <DisplaySmallest>
                                {userOrganization.organizationName}
                            </DisplaySmallest>
                        </OrganizationNameWrapper>
                    </TitleWrapper>
                    <OrganizationSelector
                        loading={loading}
                        enforcedAuthenticationMethod={
                            userOrganization.enforcedAuthenticationMethod as SsoProviderType
                        }
                        organizationName={userOrganization.organizationName}
                        handleLoginWithSso={handleLoginWithSso}
                        goToOrganization={goToOrganization}
                        currentAuthenticationMethod={currentAuthenticationMethod}
                        isUnlockedByOktaLogin={organizationIsUnlockedByOktaLogin(
                            userOrganization
                        )}
                    />
                </ContentWrapper>
            </CardBase>
        </Wrapper>
    );
}
