import styled from 'styled-components';

import {
    ColorBaseGrey000,
    ColorFgDefault,
    SpacingLarge,
    SpacingSmall
} from '@/componentLibrary/tokens/variables';

import alvaLogo from './assets/whiteAlvaBrand.svg';

export const DescriptionWrapper = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 106px;

    @media only screen and (max-width: 768px) {
        width: auto;
        height: auto;
        margin: 0 ${SpacingLarge};
    }
`;

export const LanguageToggleWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 34px 18px 0 0;
    z-index: 1;

    @media only screen and (max-width: 768px) {
        position: absolute;
        top: ${SpacingLarge};
        left: initial;
        right: ${SpacingSmall};
        margin: 0;
    }
`;

export const InstructionWrapper = styled.div`
    margin-right: 106px;
`;

export const LeftHalfWrapper = styled.div`
    background: ${ColorBaseGrey000};
    width: 158%;

    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: ${SpacingLarge};
    }
`;

export const RightHalfWrapper = styled.div`
    background: ${ColorFgDefault};
    width: 100%;
    z-index: 1;
`;

export const ContentWrapper = styled.div`
    display: flex;
    min-height: 100vh;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

export const FormWrapper = styled.div`
    position: relative;
    margin: 196px 160px 0 140px;
    z-index: 1;

    @media only screen and (max-width: 768px) {
        width: auto;
        margin: 196px ${SpacingLarge} 0 ${SpacingLarge};
    }
`;

export const LogoWrapper = styled.div`
    margin-bottom: 36px;
    display: flex;
    justify-content: flex-start;

    @media only screen and (max-width: 768px) {
        margin: 48px 0 36px;
    }
`;

export const Logo = styled.div`
    height: 35px;
    width: 128px;
    background: center no-repeat url("${alvaLogo}");
    background-size: contain;
`;

type ColorHeaderProps = {
    color?: string;
};

export const ColorHeader = styled.div<ColorHeaderProps>`
    height: 220px;
    width: 62%;
    position: absolute;
    z-index: 0;
    background: ${props => props.color};
`;
