import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const LanguageDropdownWrapper = styled.div`
    margin-inline-end: ${SpacingXsmall};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        display: none;
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`;
