import {ConsentType} from '@/api/types/__generated__/graphql';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {Header} from '@/componentLibrary/components/layout/Header';
import {DisplaySmall} from '@/componentLibrary/components/typography/Display';
import {LINKS} from '@/links';
import {FigureEightContentProps} from '@/pages/PersonalityDataCollection/pages/DataCollectionForm/components/FigureEightContent/types';
import {AcceptConsentCheckbox} from '@/services/consent/components/AcceptConsentCheckbox';

import {useFigureEightFormLogic} from './useFigureEightFormLogic';

export function FigureEightContent({createUser, creatingUser}: FigureEightContentProps) {
    const {
        triedToSubmit,
        contributorId,
        onInputChange,
        isConsentAccepted,
        acceptConsent,
        handleSubmit,
        phrases
    } = useFigureEightFormLogic(createUser);

    return (
        <form className="form" onSubmit={handleSubmit}>
            <Header>
                <DisplaySmall>{phrases.formTitle}</DisplaySmall>
            </Header>
            <FormField>{phrases.figureEightContent}</FormField>
            <FormField>
                <TextField
                    fullWidth
                    isRequired
                    placeholder="Enter your ID"
                    value={contributorId}
                    onChange={onInputChange}
                    label="Figure Eight contributor ID"
                    errorMessage="Contributor ID is required to continue"
                    hasError={triedToSubmit && !contributorId}
                />
            </FormField>
            <FormField>
                <AcceptConsentCheckbox
                    type={ConsentType.TERMS_OF_SERVICE}
                    linkToFull={LINKS.terms}
                    onChange={acceptConsent}
                    showRequiredText={triedToSubmit && !isConsentAccepted}
                />
            </FormField>
            <FormField>
                <PrimaryButton
                    disabled={!isConsentAccepted || !contributorId}
                    type="submit"
                    style={{minWidth: '140px'}}
                    isLoading={creatingUser}
                >
                    {phrases.goToInstructions}
                </PrimaryButton>
            </FormField>
        </form>
    );
}
