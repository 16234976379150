import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {AuthenticationMethod, ValidateSsoTokenMutation} from '../types/__generated__/graphql';
import {VALIDATE_SSO_TOKEN} from './mutations';

export function useValidateSsoToken(
    ssoProvider: AuthenticationMethod,
    idToken: string | null
): MutationHookReturnType<ValidateSsoTokenMutation> {
    const [mutate, {error, loading}] = useMutation(VALIDATE_SSO_TOKEN);
    const doValidateSsoToken = useCallback(() => {
        if (!idToken) {
            throw new Error('No idToken provided');
        }
        return mutate({variables: {idToken, ssoProvider}});
    }, [idToken, ssoProvider, mutate]);

    return [doValidateSsoToken, {error, loading}];
}
