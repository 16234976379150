import {useState} from 'react';
import {useDebounce} from 'use-debounce';

import {useEmploymentLoader} from '@/api/organizations/useEmploymentLoader';

export function useEmploymentDropdownLogic() {
    const [searchString, setSearchString] = useState('');
    const [debouncedSearchString] = useDebounce(searchString, 200, {leading: false});

    const orderBy = debouncedSearchString ? undefined : 'user_email';

    const {
        loading: loadingEmployments,
        employments,
        loadMore,
        loadingMore,
        error
    } = useEmploymentLoader({
        searchString: debouncedSearchString,
        orderBy,
        withTeams: false
    });

    return {
        searchString,
        setSearchString,
        loadingEmployments,
        loadingMore,
        employments,
        loadMore,
        error
    };
}
