import {Popover} from '@/componentLibrary/components/Tooltips/Popover';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {Overline, P2} from '@/componentLibrary/components/typography';
import {ColorBaseGrey700} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {StatusDot} from '../../../../CandidateProgressTable/styled';
import scorePageMessages from '../../../messages';
import {useStatus} from '../hooks/useLogic';
import {useStickyScrollLogic} from '../hooks/useStickyScrollLogic';
import messages from '../messages';
import {
    Column,
    DetailedResultTable,
    Results,
    ResultsWrapper,
    Row,
    Score,
    ScoreName,
    ScorePercent,
    ScorePercentSymbol
} from '../styled';
import {AutomatedTestScoreSectionProps} from '../types';

export function AutomatedTestScoreSection({score}: AutomatedTestScoreSectionProps) {
    const phrases = useExtractPhraseConstants(messages);
    const scorePagePhrases = useExtractPhraseConstants(scorePageMessages);

    const statusType = useStatus();

    const {sectionWrapperRef, sectionFooterRef, scoreRef, headerRowRef, tableRef, isSticky} =
        useStickyScrollLogic();

    if (!isDefined(score?.score) || score?.results.length === 0) {
        return null;
    }

    return (
        <>
            <ResultsWrapper ref={sectionWrapperRef}>
                <Score isSticky={isSticky} ref={scoreRef}>
                    <ScoreName>
                        <div>{scorePagePhrases.automatedTestScore}</div>
                        <Popover
                            openOnHover
                            content={scorePagePhrases.automatedTestScoreTooltip}
                            trigger={<Icon icon={Icons.INFO} size={20} />}
                        />
                    </ScoreName>
                    <ScorePercent>
                        {score?.score}
                        <ScorePercentSymbol>%</ScorePercentSymbol>
                    </ScorePercent>
                </Score>
                <DetailedResultTable ref={tableRef} isSticky={isSticky}>
                    <Row isSticky={isSticky} ref={headerRowRef}>
                        <Column>
                            <Overline color={ColorBaseGrey700}>
                                {phrases.testScenario}
                            </Overline>
                        </Column>
                        <Column>
                            <Overline color={ColorBaseGrey700}>{phrases.results}</Overline>
                        </Column>
                    </Row>
                    {score?.results.map((result, k) => (
                        <Row key={k} data-testid="test-score-row">
                            <Column>
                                <P2>{result.test}</P2>
                            </Column>
                            <Column vcentered>
                                <Results>
                                    <StatusDot color={statusType[result.result].color} />
                                    <P2>{statusType[result.result].text}</P2>
                                </Results>
                            </Column>
                        </Row>
                    ))}
                </DetailedResultTable>
            </ResultsWrapper>
            <div ref={sectionFooterRef} />
        </>
    );
}
