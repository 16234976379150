import Pusher, {Options} from 'pusher-js';

import {getConfig} from '@/config';
import {getImpersonationToken, getToken, isImpersonating} from '@/utils/ajax';

export const initPusher = () => {
    const config = getConfig();
    const baseUrl = config.BACKEND;

    const endpoint = `${baseUrl}/pusher/auth`;
    const params: {token?: string | null; impersonation_token?: string | null} = {
        token: getToken()
    };

    if (isImpersonating()) {
        params.impersonation_token = getImpersonationToken();
    }

    const options: Options = {
        cluster: 'eu',
        authTransport: 'ajax',
        authEndpoint: endpoint,
        auth: {
            params
        }
    };

    return new Pusher(config.PUSHER_KEY, options);
};
