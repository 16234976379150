import {useOrganizationInformation} from '@/api/organizations/useOrganizationInformation';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {Tag} from '@/componentLibrary/components/Tag';
import {TagVariant} from '@/componentLibrary/components/Tag/constants';
import {TeamAvatar} from '@/componentLibrary/components/avatars/TeamAvatar/TeamAvatar';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {DisplaySmaller} from '@/componentLibrary/components/typography/Display';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {LoadingState} from './components/LoadingState/LoadingState';
import messages from './messages';
import {TitleWrapper} from './styled';
import {JobApplicationHeaderProps} from './types';

export function JobApplicationHeader({
    organizationId,
    isJobApplicationWithdrawn
}: JobApplicationHeaderProps) {
    const {withdrawn} = useExtractPhraseConstants(messages);
    const {organization, loading, error, refetch} = useOrganizationInformation(organizationId);

    if (loading) {
        return <LoadingState />;
    }

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    if (!organization) {
        return null;
    }

    return (
        <FlexLayout alignItems="center">
            <TeamAvatar team={organization} image={organization.logo} />

            <TitleWrapper>
                <DisplaySmaller>{organization.name} </DisplaySmaller>
            </TitleWrapper>
            {isJobApplicationWithdrawn && (
                <Tag $variant={TagVariant.defaultInverted}>{withdrawn}</Tag>
            )}
        </FlexLayout>
    );
}
