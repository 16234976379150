import {useMemo} from 'react';
import {Redirect} from 'react-router-dom';
import styled from 'styled-components';

import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {CenteredSpinner} from '@/componentLibrary/components/spinners';
import {OtherJobsSection} from '@/pages/User/components/JobRecommendations/components/OtherJobsSection';

import {PageTemplateWithSections} from '../../components/PageTemplate';
import {START_INDEX_URL} from '../../links';
import {JobApplicationHeader} from './components/JobApplicationHeader';
import {TestsForJobPosition} from './components/TestsForJobPosition';
import {useJobApplicationLogic} from './logic';

type Props = {
    organizationId: number;
};

export function JobApplication({organizationId}: Props) {
    const {
        jobApplications,
        acceptedCandidateOrganizations,
        loadingJobApplications,
        error,
        refetch,
        latestCompletionDate,
        isLeadershipReportEnabled
    } = useJobApplicationLogic({organizationId});

    const isCodingTestRequired = useMemo(
        () =>
            !!jobApplications.find(jobApplication => jobApplication?.challengeAssignment?.id),
        [jobApplications]
    );

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    if (loadingJobApplications) {
        return <CenteredSpinner />;
    }

    if (
        jobApplications.length === 0 ||
        !acceptedCandidateOrganizations.includes(organizationId)
    ) {
        return <Redirect to={START_INDEX_URL} />;
    }

    return (
        <PageTemplateWithSections
            primarySection={
                <Wrapper>
                    <JobApplicationHeader
                        organizationId={organizationId}
                        isJobApplicationWithdrawn={jobApplications.some(
                            ({withdrawnAt}) => withdrawnAt
                        )}
                    />
                    <TestsForJobPosition
                        organizationId={organizationId}
                        isLeadershipReportEnabled={isLeadershipReportEnabled}
                        isPersonalityTestRequired={jobApplications.some(
                            a => a.jobPosition?.requirePersonalityTest === true
                        )}
                        isLogicTestRequired={jobApplications.some(
                            a => a.jobPosition?.requireLogicTest === true
                        )}
                        isLogicTestIrtRequired={jobApplications.some(
                            a => a.jobPosition?.requireLogicIrtTest === true
                        )}
                        isCodingTestRequired={isCodingTestRequired}
                        jobApplicationCompletedAt={latestCompletionDate}
                        jobApplications={jobApplications}
                    />
                </Wrapper>
            }
            secondarySection={<OtherJobsSection organizationId={organizationId} />}
        />
    );
}

const Wrapper = styled.div`
    margin: 20px auto;
    max-width: 1000px;
`;
