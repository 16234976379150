import {InterviewContentRatingType} from '@/api/types/__generated__/graphql';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {
    ColorBaseGrey700,
    SpacingMedium,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';
import {getUserName} from '@/utils/misc';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {InterviewScoreFigure} from './components/InterviewScoreFigure';
import {ReadMore} from './components/ReadMore';
import messages from './messages';
import {ScorecardCriterionRatingProps} from './types';

export function ScorecardCriterionRating({
    score,
    comment,
    isSkipped,
    user,
    limit = 120,
    ratingType
}: ScorecardCriterionRatingProps) {
    const intl = useIntl();
    const userName = useMemo(() => getUserName(user ?? null), [user]);

    const scoreText = useMemo(() => {
        if (isSkipped) {
            return (
                <ScoreText color={ColorBaseGrey700}>
                    {intl.formatMessage(messages.skipped)}
                </ScoreText>
            );
        }

        if (ratingType === InterviewContentRatingType.NO_RATING && !comment) {
            return (
                <ScoreText color={ColorBaseGrey700}>
                    {intl.formatMessage(messages.noComment)}
                </ScoreText>
            );
        }

        if (ratingType === InterviewContentRatingType.NO_RATING) {
            return null;
        }

        if (!isDefined(score)) {
            return (
                <ScoreText color={ColorBaseGrey700}>
                    {intl.formatMessage(messages.noScore)}
                </ScoreText>
            );
        }

        return <InterviewScoreFigure score={score} />;
    }, [isSkipped, score, intl, comment, ratingType]);

    return (
        <Wrapper>
            <MainInfoWrapper>
                {userName && <P2>{userName}</P2>}
                {scoreText}
            </MainInfoWrapper>
            {comment && <ReadMore text={comment} limit={limit} />}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${SpacingXsmall};
`;

const MainInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${SpacingXxsmall};
    height: ${SpacingMedium};
`;

const ScoreText = styled(P2)`
    margin: 0;
`;
