import {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router-dom';

import {useAnonymizeJobApplicationsForUserInOrganization} from '@/api/users/useAnonymizeJobApplicationsForUserInOrganization';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {logger} from '@/services/logrocket';

import messages from './messages';

export function useAnonymizeJobApplicationsForUserInOrganizationModalLogic(
    userId,
    organizationId,
    closeModal,
    backUrl
) {
    const [doAnonymize, {loading}] = useAnonymizeJobApplicationsForUserInOrganization(
        userId,
        organizationId
    );
    const history = useHistory();

    const goBack = useCallback(() => {
        history.push(backUrl);
    }, [backUrl, history]);

    const anonymizeUser = useCallback(() => {
        if (!userId || !organizationId) {
            return;
        }
        doAnonymize()
            .then(
                ({
                    data: {
                        anonymizeJobApplicationsForUserInOrganization: {ok, errorMessage}
                    }
                }) => {
                    if (ok) {
                        toast({
                            type: 'success',
                            message: <FormattedMessage {...messages.userAnonymized} />
                        });
                        closeModal();
                        goBack();
                    } else {
                        throw new Error(errorMessage);
                    }
                }
            )
            .catch(e => {
                logger.error(e);
                toast({type: 'error'});
            });
    }, [userId, organizationId, doAnonymize, closeModal, goBack]);

    return {
        loading,
        anonymizeUser
    };
}
