import {useCallback, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';

import {IframeProps} from './types';

export function Iframe({cssStyle = '', style, children}: IframeProps) {
    const node = useRef<HTMLIFrameElement>(null);
    const head = useRef<HTMLHeadElement | null>(null);
    const body = useRef<HTMLElement | null>(null);
    const styleElement = useRef<HTMLStyleElement | null>(null);
    const cssNode = useRef<Text | null>(null);

    const updateCss = useCallback((css: string) => {
        if (head.current && !styleElement.current) {
            const element = document.createElement('style');
            head.current.appendChild(element);
            styleElement.current = element;
        }

        const _cssNode = document.createTextNode(css);

        if (cssNode.current && styleElement.current) {
            styleElement.current.removeChild(cssNode.current);
        }

        if (styleElement.current) {
            styleElement.current.appendChild(_cssNode);
            cssNode.current = _cssNode;
        }
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!node.current) {
            return;
        }
        const frame = node.current;

        if (!frame.contentDocument) {
            return;
        }

        const root = document.createElement('div');

        root.setAttribute('id', 'root');

        head.current = frame.contentDocument.head;
        body.current = frame.contentDocument.body;
        body.current.appendChild(root);
        return () => {
            const element =
                frame.contentDocument && frame.contentDocument.getElementById('root');
            if (element) {
                ReactDOM.unmountComponentAtNode(element);
            }
        };
    }, [updateCss]);

    useEffect(() => {
        updateCss(cssStyle);
    }, [cssStyle, updateCss]);

    useEffect(() => {
        if (!node.current) {
            return;
        }

        const frame = node.current;
        const element = frame.contentDocument && frame.contentDocument.getElementById('root');
        if (element && children) {
            ReactDOM.render(children, element);
        }
    });

    return <iframe ref={node} style={style} />;
}
