import {useCallback} from 'react';
import styled from 'styled-components';

import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {BADGE_THEMES} from '@/componentLibrary/components/misc/Badge/constants';
import {Theme} from '@/componentLibrary/components/misc/Badge/types';
import {Caption} from '@/componentLibrary/components/typography/Captions';
import {ColorVariables} from '@/componentLibrary/tokens/types';
import {
    BoxShadowBaseRegular,
    ColorBaseGrey000,
    ColorBaseGrey600,
    ColorBaseGrey900,
    FontWeightsMedium
} from '@/componentLibrary/tokens/variables';

type Props = {
    theme?: Theme;
    value: number;
    withShadow?: boolean;
    backgroundFill?: string;
    valueFill?: string;
    borderFill?: string;
};

function validateProps({value, theme, backgroundFill, valueFill}: Props) {
    if (value === null || value === undefined) {
        throw new Error('Must provide a value');
    }

    if (theme === BADGE_THEMES.CUSTOM) {
        if (!backgroundFill || !valueFill) {
            throw new Error(
                'Props backgroundFill and valueFill are required for CUSTOM theme'
            );
        }
    }
}

const DEFAULT_THEME = BADGE_THEMES.LIGHT as Theme;

export function Badge({
    theme = DEFAULT_THEME,
    value,
    withShadow = false,
    backgroundFill,
    valueFill,
    borderFill
}: Props) {
    validateProps({value, theme, backgroundFill, valueFill});

    const getColors = useCallback(() => {
        if (theme === BADGE_THEMES.LIGHT) {
            return {
                backgroundFill: ColorBaseGrey000,
                valueFill: ColorBaseGrey900,
                borderFill: ColorBaseGrey600
            };
        }

        if (theme === BADGE_THEMES.DARK) {
            return {
                backgroundFill: ColorBaseGrey900,
                valueFill: ColorBaseGrey000,
                borderFill: 'transparent'
            };
        }

        return {backgroundFill, valueFill, borderFill};
    }, [theme, backgroundFill, valueFill, borderFill]);

    const colors = getColors();
    const valueFillColor = colors.valueFill as ColorVariables;

    return (
        <Wrapper
            backgroundFill={colors.backgroundFill}
            borderFill={colors.borderFill}
            withShadow={withShadow}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Value color={valueFillColor}>{value}</Value>
        </Wrapper>
    );
}

const Wrapper = styled(FlexLayout)<{
    backgroundFill?: string;
    borderFill?: string;
    withShadow?: boolean;
}>`
    width: 23px;
    height: 19px;
    border-radius: 16px;
    padding: 2px 8px;
    background-color: ${({backgroundFill}) => backgroundFill};
    box-shadow: ${({withShadow}) => (withShadow ? BoxShadowBaseRegular : 'none')};
    border: ${({borderFill}) => (borderFill ? `0.5px solid ${borderFill}` : 'none')};

    @media print {
        box-shadow: none;
    }
`;

const Value = styled(Caption)`
    font-weight: ${FontWeightsMedium};
`;
