import {OverduescoreTab, TabWrapper} from '../../styled';
import {TabBar} from './Tabbar';
import {useLogic, useTabs} from './logic';
import {OverduescoreTabSectionProps} from './types';

export function OverduescoreTabSection({onClick, tabRef}: OverduescoreTabSectionProps) {
    const {tabs} = useTabs({onClick});

    const {isSticky} = useLogic({tabRef});

    return (
        <>
            <TabWrapper ref={tabRef}>
                <OverduescoreTab isSticky={isSticky}>
                    <TabBar tabs={tabs} />
                </OverduescoreTab>
            </TabWrapper>
        </>
    );
}
