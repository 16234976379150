import {FormattedMessage} from 'react-intl';

import {EMAIL_TYPES} from '@/pages/Organization/pages/Settings/pages/EmailTemplates/constants';
import {formatDate, getTimeFromNow} from '@/utils/formatDates';

import messages from './messages';
import {CandidateReminderStatus} from './types';

type LatestBouncedEmailInfoProps = {
    candidateReminderStatus: CandidateReminderStatus | null;
    locale: string;
};

export function getBellInfo({candidateReminderStatus, locale}: LatestBouncedEmailInfoProps) {
    if (!candidateReminderStatus) {
        return null;
    }
    const {
        numberOfRemindersSent,
        latestReminderSentAt,
        latestDeliveredInvitationTimestamp,
        latestBouncedInvitationTimestamp,
        latestDeliveredReminderTimestamp,
        latestBouncedReminderTimestamp
    } = candidateReminderStatus || {};

    const yesterdayDate = new Date().getTime() - 24 * 60 * 60 * 1000;

    const {latestEmailBouncedAt, latestBouncedEmailType} =
        getLatestBouncedEmailInfo(
            latestDeliveredInvitationTimestamp,
            latestBouncedInvitationTimestamp,
            latestDeliveredReminderTimestamp,
            latestBouncedReminderTimestamp
        ) || {};

    const tooltipText = getTooltipText(
        locale,
        yesterdayDate,
        latestReminderSentAt,
        numberOfRemindersSent,
        latestEmailBouncedAt,
        latestBouncedEmailType
    );

    if (!tooltipText) {
        return null;
    }

    const isInfoBellWarning = !!(latestReminderSentAt && Number(numberOfRemindersSent) > 2);

    return {
        tooltipText,
        isInfoBellWarning,
        latestEmailBouncedAt
    };
}

function getLatestBouncedEmailInfo(
    latestDeliveredInvitationTimestamp: string,
    latestBouncedInvitationTimestamp: string,
    latestDeliveredReminderTimestamp: string,
    latestBouncedReminderTimestamp: string
) {
    if (!latestBouncedInvitationTimestamp && !latestBouncedReminderTimestamp) {
        return null;
    }

    const invitationDeliveredAt = new Date(latestDeliveredInvitationTimestamp).getTime();
    const invitationBouncedAt = new Date(latestBouncedInvitationTimestamp).getTime();
    const reminderDeliveredAt = new Date(latestDeliveredReminderTimestamp).getTime();
    const reminderBouncedAt = new Date(latestBouncedReminderTimestamp).getTime();

    const invitationDidBounce = invitationBouncedAt > invitationDeliveredAt;
    const reminderDidBounce = reminderBouncedAt > reminderDeliveredAt;

    if (reminderDeliveredAt && !reminderDidBounce) {
        return null;
    }

    if (!invitationDidBounce && !reminderDidBounce) {
        return null;
    }

    return {
        latestEmailBouncedAt: new Date(Math.max(invitationBouncedAt, reminderBouncedAt)),
        latestBouncedEmailType:
            invitationBouncedAt > reminderBouncedAt
                ? EMAIL_TYPES.INVITE_CANDIDATE
                : EMAIL_TYPES.CANDIDATE_REMINDER
    };
}

function getTooltipText(
    locale: string,
    yesterdayDate: number,
    latestReminderSentAt: Date,
    numberOfRemindersSent: number,
    latestEmailBouncedAt?: Date,
    latestBouncedEmailType?: string
) {
    if (latestEmailBouncedAt) {
        const sentWithinLastDay = latestEmailBouncedAt.getTime() > yesterdayDate;
        const timeAgo = getTimeFromNow(latestEmailBouncedAt, locale);
        const lastDate = sentWithinLastDay ? '' : ` (${formatDate(latestEmailBouncedAt)})`;
        return (
            <FormattedMessage
                {...(latestBouncedEmailType === EMAIL_TYPES.INVITE_CANDIDATE
                    ? messages.latestInvitationEmailBounced
                    : messages.latestReminderEmailBounced)}
                values={{
                    timeAgo,
                    lastDate
                }}
            />
        );
    }

    if (latestReminderSentAt) {
        if (numberOfRemindersSent > 2) {
            const timeAgo = getTimeFromNow(latestReminderSentAt, locale);
            const lastDate = formatDate(latestReminderSentAt);
            return (
                <FormattedMessage
                    {...messages.tooManyRemindersSent}
                    values={{
                        number: numberOfRemindersSent,
                        timeAgo,
                        lastDate
                    }}
                />
            );
        }

        if (new Date(latestReminderSentAt).getTime() > yesterdayDate) {
            return <FormattedMessage {...messages.reminderSentLastDay} />;
        }
    }

    return null;
}
