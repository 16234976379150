import {ApolloError, useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {clearAuthTokens, setUserAuthToken} from '../../services/auth/utils';
import {useCheckLoggedIn} from '../auth/useCheckLoggedIn';
import {CreateAnonymousUserMutation} from '../types/__generated__/graphql';
import {CREATE_ANONYMOUS_USER} from './mutations';

type CreateAnonymousUserResult = {
    ok: boolean;
    userId: number | null;
};

export function useCreateAnonymousUser(): [
    () => Promise<CreateAnonymousUserResult>,
    {error?: ApolloError; loading: boolean}
] {
    const {refetchLoginStatus} = useCheckLoggedIn();
    const [mutate, {loading, error}] =
        useMutation<CreateAnonymousUserMutation>(CREATE_ANONYMOUS_USER);

    const doCreateAnonymousUser = useCallback(async (): Promise<CreateAnonymousUserResult> => {
        const {data} = await mutate();
        const createAnonymousUser = data?.createAnonymousUser;
        const ok = createAnonymousUser?.ok ?? false;
        const userId = createAnonymousUser?.userId ?? null;

        clearAuthTokens();
        if (ok && createAnonymousUser?.token) {
            setUserAuthToken(createAnonymousUser.token);
            refetchLoginStatus();
        }

        return {ok, userId};
    }, [mutate, refetchLoginStatus]);

    return [doCreateAnonymousUser, {error, loading}];
}
