import {CreateCustomItemComponent} from './types';

export function isCreateCustomItemComponent<T>(
    item: T | CreateCustomItemComponent
): item is CreateCustomItemComponent {
    return typeof item === 'object' && item !== null && 'createCustomItemComponent' in item;
}

export function hasSelectedValue<T>(selectedValue: T[] | T | null) {
    if (Array.isArray(selectedValue)) {
        return selectedValue.length > 0;
    }

    return selectedValue;
}

export function getSelectedValueDisplay<T>(
    selectedValue: T[] | T | null | undefined,
    itemToString: (item: T) => string,
    displayValue?: string
) {
    if (!selectedValue) {
        return '';
    }

    if (displayValue) {
        return displayValue;
    }

    if (Array.isArray(selectedValue)) {
        return selectedValue.map(itemToString).join(', ');
    }

    return itemToString(selectedValue);
}
