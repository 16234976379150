import {ReactNode} from 'react';

import {TooltipContent} from './components/TooltipContent';
import {TooltipTrigger} from './components/TooltipTrigger';
import {GAP} from './constants';
import {TooltipContext, useTooltip} from './hooks';
import {TooltipOptions} from './types';

export function Tooltip({
    trigger,
    content,
    shouldTrigger = true,
    asChild = false,
    asDiv = false,
    style,
    ...options
}: {
    content: ReactNode;
    trigger: ReactNode;
    asChild?: boolean;
    asDiv?: boolean;
    shouldTrigger?: boolean;
    style?: React.CSSProperties;
} & TooltipOptions) {
    // This can accept any props as options, e.g. `placement`,
    // or other positioning options.
    const defaultOptions: TooltipOptions = {placement: 'right', hasArrow: false, gap: GAP};
    const tooltip = useTooltip({...defaultOptions, ...options});
    return (
        <TooltipContext.Provider value={tooltip}>
            <TooltipTrigger shouldTrigger={shouldTrigger} asChild={asChild} asDiv={asDiv}>
                {trigger}
            </TooltipTrigger>
            <TooltipContent style={style}>{content}</TooltipContent>
        </TooltipContext.Provider>
    );
}
