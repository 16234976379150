import debounce from 'lodash.debounce';
import {useCallback, useEffect, useRef, useState} from 'react';

import {TextField} from '../TextField';
import {debounceOptions} from './constants';
import {DebouncedTextFieldProps} from './types';

export const DebouncedTextField = ({
    defaultValue = '',
    debounceTimeout = 200,
    onChange,
    onClear,
    ...props
}: DebouncedTextFieldProps) => {
    const debouncedUpdateSearchKey = useRef<(value: string) => void>(() => undefined);

    const [value, setValue] = useState(defaultValue);
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        debouncedUpdateSearchKey.current(e.target.value);
    }, []);

    const handleChange = useCallback((text: string) => onChange?.(text), [onChange]);

    const handleClear = useCallback(() => {
        onClear?.();
        setValue('');
    }, [onClear]);

    useEffect(() => {
        debouncedUpdateSearchKey.current = debounce(
            handleChange,
            debounceTimeout,
            debounceOptions
        );
    }, [handleChange, debounceTimeout]);

    return (
        <TextField
            value={value}
            onChange={handleInputChange}
            onClear={handleClear}
            {...props}
        />
    );
};
