import {PageLayout} from '@/componentLibrary/blocks/PageLayout';
import {HelpCenterLink} from '@/componentLibrary/components/links';
import {P2} from '@/componentLibrary/components/typography';
import {ColorBaseGrey800} from '@/componentLibrary/tokens/variables';
import {HELP_CENTER} from '@/constants/helpCenter';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {Logo} from '@/pages/Organization/components/Sidebar/components/TopLevelNavigation/components/Logo';
import {UserResultsProps} from '@/pages/SharedResults/components/UserResults/types';
import {HelpCenterLinkStyle, LandingPageLayoutFooter} from '../SharedResultsPage/styled';
import {SharedResultsContext} from './hooks/SharedResultsContext';
import {useSharedUserResultsLogic} from './hooks/useSharedUserResultsLogic';
import messages from './messages';
import {Wrapper} from './styled';

export const UserResults = ({token, permissions, userId, jobPositionId}: UserResultsProps) => {
    const phrases = useExtractPhraseConstants(messages);
    const {candidateName, redirectPath, navigationTabs} = useSharedUserResultsLogic(
        token,
        permissions,
        userId,
        jobPositionId
    );

    return (
        <SharedResultsContext.Provider value={token}>
            <Wrapper data-testid={'user-results'}>
                <PageLayout
                    fullWidth
                    title={candidateName}
                    tabs={navigationTabs}
                    spotlightTabs
                    redirectPath={redirectPath}
                ></PageLayout>
            </Wrapper>
            <LandingPageLayoutFooter>
                <div>
                    <Logo />
                    alvalabs.io
                </div>
                <HelpCenterLinkStyle>
                    <P2 color={ColorBaseGrey800}>{phrases.helpCenterDescription}</P2>
                    <HelpCenterLink url={HELP_CENTER.root} hideWhenPrinting>
                        {phrases.helpCenter}
                    </HelpCenterLink>
                </HelpCenterLinkStyle>
            </LandingPageLayoutFooter>
        </SharedResultsContext.Provider>
    );
};
