import {gql} from '@/api/types/__generated__';

export const GENERATE_EXTERNAL_RESULTS_TOKEN = gql(`
    mutation GenerateExternalResultToken($expiresAt: DateTime!, $jobApplicationId: Int!, $permissions: [ExternalResultsPermissionInput]!) {
      generateExternalResultsToken(
        expiresAt: $expiresAt
        jobApplicationId: $jobApplicationId
        permissions: $permissions
      ) {
        errorMessage
        ok
        token
      }
    }
`);

export const REVOKE_EXTERNAL_RESULTS_TOKENS = gql(`
    mutation revokeExternalResultsTokens(
        $job_application_id: Int!
    ) {
        revokeExternalResultsTokens(
            jobApplicationId: $job_application_id
        ) {
            ok
            errorMessage
        }
    }
`);
