import {useRelevanceParametersOptions} from '@/api/jobRecommendations/useRelevanceParametersOptions';
import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';
import {useGetRelevanceInformation} from '@/api/users/useGetRelevanceInformation';
import {useMyTestProgress} from '@/api/users/useMyTestProgress';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {InPageHeader} from '@/componentLibrary/components/InPageHeader';
import {TITLE_SIZE} from '@/componentLibrary/components/InPageHeader/types';
import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {H1} from '@/componentLibrary/components/typography';
import {SpacingSmall} from '@/componentLibrary/tokens/variables';
import {convertToHoursAndMinutes} from '@/pages/Organization/pages/Assessment/pages/CodingTests/CodingTestsList/utils';
import {RecommendationRelevanceForm} from '@/pages/User/components/RecommendationRelevanceForm';
import {ApplicationFAQ} from '@/pages/User/pages/JobApplication/components/ApplicationFAQ';
import {CandidateServicesConsentBanner} from '@/pages/User/pages/JobApplication/components/CandidateServicesConsentBanner';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {JobPositions} from 'pages/User/pages/JobApplication/components/TestsForJobPosition/components/JobPositions';
import {useCallback, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {excerpt} from '@/utils/misc';
import {GetMyJobApplications_JobApplication} from './components/JobPositions/types';
import {LocalBanner} from './components/LocalBanner';
import {ShowPreviousLogicTestResult} from './components/ShowPreviousResults/components/ShowPreviousLogicTestResult';
import {ShowPreviousPersonalityTestResult} from './components/ShowPreviousResults/components/ShowPreviousPersonalityTestResult';
import {TestItem} from './components/TestItem';
import {StartNewTest} from './components/TestItem/components/StartNewTest';
import JobPositionContext from './context';
import {getChallengeDurations} from './functions/getChallengeDurations';
import {useTestsForJobPositionsLogic} from './hooks/useTestsForJobPositionsLogic';
import messages from './messages';
import {Description, TestItemWrapper, TestItemsWrapper, Wrapper} from './styled';
import {TestsForJobPositionProps} from './types';

export function TestsForJobPosition(props: TestsForJobPositionProps) {
    const {
        isPersonalityTestRequired,
        isLogicTestIrtRequired,
        isLeadershipReportEnabled,
        organizationId,
        jobApplicationCompletedAt,
        isCodingTestRequired,
        jobApplications
    } = props;
    const intl = useIntl();
    const {
        error,
        refetch,
        user,
        loading: loadingMyTestProgress
    } = useMyTestProgress(organizationId);
    const [showRelevanceModal, setShowRelevanceModal] = useState(false);
    const [showStartNewLogicTest, setShowStartNewLogicTest] = useState(false);
    const [showStartNewPersonalityTest, setShowStartNewPersonalityTest] = useState(false);
    const [showPreviousLogicTestResult, setShowPreviousLogicTestResult] = useState(false);
    const [showPreviousPersonalityTestResult, setShowPreviousPersonalityTestResult] =
        useState(false);
    const {relevanceParametersOptions} = useRelevanceParametersOptions();
    const {userRelevanceInformation} = useGetRelevanceInformation(user?.id);
    const [showModal, setShowModal] = useState(false);

    const codingTestsAllCompleted = useMemo(() => {
        if (isCodingTestRequired) {
            return jobApplications
                .filter(jobApplication => jobApplication?.challengeAssignment)
                .every(
                    jobApplication =>
                        jobApplication?.challengeAssignment?.status ===
                        ChallengeAssignmentStatus.SUBMITTED
                );
        }
        return true;
    }, [isCodingTestRequired, jobApplications]);

    const {
        personalityTestStatus,
        logicTestIrtStatus,
        allNeededTestsAreCompleted,
        showBanner,
        bannerTitle,
        bannerDescription,
        startNewLogicTestIrt,
        startNewPersonalityTest,
        allowedToTakeNewLogicTest,
        allowedToTakeNewPersonalityTest,
        enableJobRecommendationForUser,
        personalityTestSubtitle,
        personalityTestDescription,
        phrases,
        organizationName,
        displayCandidateServicesConsentBanner
    } = useTestsForJobPositionsLogic(
        user,
        loadingMyTestProgress,
        organizationId,
        isPersonalityTestRequired,
        isLogicTestIrtRequired,
        jobApplicationCompletedAt,
        codingTestsAllCompleted,
        isLeadershipReportEnabled
    );

    const assessmentsHeader = useMemo(() => {
        return (
            <InPageHeader
                style={{marginBlockEnd: SpacingSmall}}
                title={{content: phrases.title, size: TITLE_SIZE.LARGE}}
                primaryAction={
                    <PlainButton
                        data-testid={'show-faq-button'}
                        onClick={() => setShowModal(true)}
                    >
                        {phrases.showFAQ}
                    </PlainButton>
                }
            />
        );
    }, [phrases]);

    const jobApplicationsWithChallengeAssignment = useMemo(() => {
        return jobApplications
            .filter(jobApplication => jobApplication?.challengeAssignment)
            .reverse();
    }, [jobApplications]);

    const displayTimeDetails = useCallback(
        (jobApplication: GetMyJobApplications_JobApplication) => {
            if (jobApplication.challengeAssignment?.timeLimitMinutes) {
                return (
                    <FormattedMessage
                        values={{
                            limit: convertToHoursAndMinutes(
                                jobApplication.challengeAssignment?.timeLimitMinutes,
                                intl
                            )
                        }}
                        {...messages.codingTestLimitText}
                    />
                );
            }

            const duration = getChallengeDurations(
                jobApplication.challengeAssignment?.challenge ?? null,
                intl
            );

            if (!duration) {
                return phrases.codingTestWithoutTimeText;
            }

            return (
                <FormattedMessage
                    values={{from: duration.from, to: duration.to}}
                    {...(duration.to
                        ? messages.codingTestTimeText
                        : messages.codingTestOneTimeText)}
                />
            );
        },
        [intl, phrases.codingTestWithoutTimeText]
    );

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    return (
        <Wrapper>
            <H1 style={{marginBlockEnd: SpacingSmall}}>{phrases.header}</H1>
            <Description withMargin={!displayCandidateServicesConsentBanner}>
                {phrases.description}
            </Description>
            {displayCandidateServicesConsentBanner && (
                <CandidateServicesConsentBanner organizationName={organizationName} />
            )}
            {assessmentsHeader}
            {showBanner && (
                <LocalBanner
                    isSuccess={allNeededTestsAreCompleted}
                    title={bannerTitle}
                    description={bannerDescription}
                />
            )}
            {showRelevanceModal && user && isDefined(userRelevanceInformation) && (
                <RecommendationRelevanceForm
                    close={setShowRelevanceModal.bind(null, false)}
                    userId={user.id}
                    relevanceParametersOptions={relevanceParametersOptions}
                    userRelevanceInformation={userRelevanceInformation}
                    onSubmit={enableJobRecommendationForUser}
                />
            )}
            <TestItemsWrapper>
                {isPersonalityTestRequired && (
                    <TestItemWrapper>
                        <TestItem
                            title={phrases.personalityTest}
                            subTitle={personalityTestSubtitle}
                            description={personalityTestDescription}
                            timeText={phrases.personalityTimeText}
                            testProgress={personalityTestStatus.status}
                            link={personalityTestStatus.link}
                            setShowStartNewTest={setShowStartNewPersonalityTest}
                            setShowSeePreviousResult={setShowPreviousPersonalityTestResult}
                            loading={loadingMyTestProgress}
                            allowedToTakeNewTest={allowedToTakeNewPersonalityTest}
                            userIsRegistered={!!user?.registeredAt}
                        />
                    </TestItemWrapper>
                )}

                {isLogicTestIrtRequired && (
                    <TestItemWrapper>
                        <TestItem
                            title={phrases.logicTestIrt}
                            description={phrases.logicTestIrtDescription}
                            timeText={phrases.logicTestIrtTimeText}
                            testProgress={logicTestIrtStatus.status}
                            link={logicTestIrtStatus?.link}
                            setShowStartNewTest={setShowStartNewLogicTest}
                            setShowSeePreviousResult={setShowPreviousLogicTestResult}
                            loading={loadingMyTestProgress}
                            allowedToTakeNewTest={allowedToTakeNewLogicTest}
                            userIsRegistered={!!user?.registeredAt}
                        />
                    </TestItemWrapper>
                )}

                {isCodingTestRequired &&
                    jobApplicationsWithChallengeAssignment.map(jobApplication => (
                        <JobPositionContext.Provider
                            key={jobApplication.id}
                            value={{
                                jobPosition: jobApplication.jobPosition ?? null,
                                challengeAssignment:
                                    jobApplication.challengeAssignment ?? null,
                                organizationId
                            }}
                        >
                            <TestItemWrapper>
                                <TestItem
                                    title={`${intl.formatMessage(messages.codingTest)} for ${excerpt(jobApplication?.jobPosition?.name || '', 60)}`}
                                    description={intl.formatMessage(
                                        messages.codingTestDescription
                                    )}
                                    timeText={displayTimeDetails(jobApplication)}
                                    codingTestProgress={
                                        jobApplication?.challengeAssignment
                                            ?.isOpenForLateSubmission
                                            ? ChallengeAssignmentStatus.IN_PROGRESS
                                            : jobApplication?.challengeAssignment?.status
                                    }
                                    codingTestAssignmentId={
                                        jobApplication?.challengeAssignment?.id
                                    }
                                    link={
                                        jobApplication?.challengeAssignment?.repositoryUrl ??
                                        ''
                                    }
                                    loading={loadingMyTestProgress}
                                />
                            </TestItemWrapper>
                        </JobPositionContext.Provider>
                    ))}
            </TestItemsWrapper>
            {showStartNewLogicTest && (
                <StartNewTest
                    setShowStartNewTest={setShowStartNewLogicTest}
                    doStartNewTest={startNewLogicTestIrt}
                    isLogicTest
                />
            )}
            {showStartNewPersonalityTest && (
                <StartNewTest
                    setShowStartNewTest={setShowStartNewPersonalityTest}
                    doStartNewTest={startNewPersonalityTest}
                    isPersonalityTest
                />
            )}
            {showPreviousLogicTestResult &&
                user &&
                user.logicTestProgress &&
                user.logicTestProgress.latestCompletedTestId && (
                    <ShowPreviousLogicTestResult
                        setShowPreviousLogicTestResult={setShowPreviousLogicTestResult}
                        logicTestId={user.logicTestProgress.latestCompletedTestId}
                        organizationId={organizationId}
                    />
                )}
            {showPreviousPersonalityTestResult &&
                user &&
                isDefined(user.personalityTestProgress?.latestCompletedTestId) && (
                    <ShowPreviousPersonalityTestResult
                        setShowPreviousPersonalityTestResult={
                            setShowPreviousPersonalityTestResult
                        }
                        personalityTestId={user.personalityTestProgress.latestCompletedTestId}
                        organizationId={organizationId}
                        isLeadershipReportEnabled={isLeadershipReportEnabled}
                    />
                )}
            <JobPositions jobApplications={jobApplications} />
            {showModal && (
                <Modal
                    headerTitle={phrases.modalTitle}
                    close={() => setShowModal(false)}
                    isVerticallyCentered
                >
                    <ApplicationFAQ />
                </Modal>
            )}
        </Wrapper>
    );
}
