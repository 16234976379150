export const PAGE_CATEGORIES = {
    HOME: 'HOME',
    ASSESSMENT: 'ASSESSMENT',
    TEAM_INSIGHTS: 'TEAM_INSIGHTS',
    ANALYTICS: 'ANALYTICS',
    SETTINGS: 'SETTINGS',
    ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
    LOGIC_TEST: 'LOGIC_TEST',
    PERSONALITY_TEST: 'PERSONALITY_TEST',
    BACKGROUND_INFORMATION: 'BACKGROUND_INFORMATION',
    UPGRADE_PLAN: 'UPGRADE_PLAN',
    SHARED_RESULTS: 'SHARED_RESULTS'
};

export const EVENTS = {
    CANDIDATE_ADDED: 'CANDIDATE_ADDED',
    ANALYTICS_EXPORT_DOWNLOADED: 'ANALYTICS_EXPORT_DOWNLOADED',
    TEST_REPORT_DOWNLOADED: 'TEST_REPORT_DOWNLOADED',
    RECOMMENDATION_APPLICATION_FEEDBACK: 'RECOMMENDATION_APPLICATION_FEEDBACK'
};

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#anonymizing-ip
export const options = {context: {ip: '0.0.0.0'}};

export const ANALYTICS_STUB_METHODS = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on'
];
