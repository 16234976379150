import {
    InterviewContentItem,
    useInterviewContentItemScoresForJobApplication
} from '@/api/assessment/interviews/useInterviewContentItemScoresForJobApplication';
import {ItemType} from '@/api/types/__generated__/graphql';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {CenteredSpinner} from '@/componentLibrary/components/spinners';
import {Overline} from '@/componentLibrary/components/typography/Overline';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {ScorecardCriterionInfo} from '@/pages/Organization/pages/Assessment/components/ScorecardCriterionInfo';
import {useCallback} from 'react';
import {ScorecardResultsEmptyState} from './components/ScoreCardResultsEmptyState';
import {ScorecardCriterionResults} from './components/ScorecardCriterionResults';
import {ScorecardInstruction} from './components/ScorecardInstruction';
import messages from './messages';
import {
    ContentItemListWrapper,
    ContentItemsGrid,
    EmptyStateContentItemsGrid,
    ScorecardsHeader,
    Wrapper
} from './styled';
import {ScorecardResultsProps} from './types';

export function ScorecardResults({
    jobApplicationId,
    interviewId,
    interviewRequests,
    jobApplicationIsWithdrawn
}: ScorecardResultsProps) {
    const phrases = useExtractPhraseConstants(messages);

    const {
        interviewContentItems,
        noScorecardsCompleted,
        error: errorLoadingScores,
        loading: loadingScores,
        refetch: refetchScores
    } = useInterviewContentItemScoresForJobApplication(interviewId, jobApplicationId);

    const displayContentItemEmptyState = useCallback((item: InterviewContentItem) => {
        if (item.type === ItemType.INSTRUCTION) {
            return <ScorecardInstruction key={item.id} item={item} />;
        }

        return <ScorecardCriterionInfo key={item.id} contentItem={item} />;
    }, []);

    const displayContentItem = useCallback(
        (item: InterviewContentItem) => {
            if (item.type === ItemType.INSTRUCTION) {
                return <ScorecardInstruction key={item.id} item={item} />;
            }

            return (
                <ScorecardCriterionResults
                    key={item.id}
                    item={item}
                    interviewRequests={interviewRequests}
                />
            );
        },
        [interviewRequests]
    );

    if (loadingScores) {
        return (
            <Wrapper>
                <CenteredSpinner />
            </Wrapper>
        );
    }

    if (errorLoadingScores) {
        return <ErrorState error={errorLoadingScores} refetch={refetchScores} />;
    }

    return (
        <Wrapper>
            <ScorecardsHeader>
                <Overline color={ColorFgSubtle}>{phrases.interviewContent}</Overline>
                <Overline color={ColorFgSubtle}>{phrases.results}</Overline>
            </ScorecardsHeader>
            {noScorecardsCompleted && jobApplicationId ? (
                <EmptyStateContentItemsGrid>
                    <ContentItemListWrapper>
                        {interviewContentItems.map(displayContentItemEmptyState)}
                    </ContentItemListWrapper>
                    <ScorecardResultsEmptyState
                        jobApplicationId={jobApplicationId}
                        jobApplicationIsWithdrawn={jobApplicationIsWithdrawn ?? false}
                        interviewId={interviewId}
                    />
                </EmptyStateContentItemsGrid>
            ) : (
                <ContentItemsGrid>
                    {interviewContentItems.map(displayContentItem)}
                </ContentItemsGrid>
            )}
        </Wrapper>
    );
}
