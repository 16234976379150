import {useMemo} from 'react';

import {
    AVERAGE_WIDTH,
    BLOCKS,
    DEFAULT_BLOCK_COLOR,
    DEFAULT_BLOCK_WIDTH,
    DEFAULT_WIDTH,
    MINIMUM_WIDTH
} from '@/componentLibrary/components/charts/StandardTenChartRange/constants';
import {
    GroupedRange,
    StandardTenChartRangeLogicProps
} from '@/componentLibrary/components/charts/StandardTenChartRange/types';
import {
    getColorPerResultValue,
    getGroupedRanges
} from '@/componentLibrary/components/charts/StandardTenChartRange/utils';
import {useWindowDimensions} from '@/utils/useWindowDimensions';

export const useStandardTenChartRangeLogic = ({
    width,
    lowerBound,
    upperBound,
    rangeColor,
    barColors
}: StandardTenChartRangeLogicProps) => {
    const {width: windowWidth} = useWindowDimensions();
    const blockWidth = (width * DEFAULT_BLOCK_WIDTH) / DEFAULT_WIDTH;
    const blockPadding = (width - blockWidth * 10) / 9;
    const currentWidth = () => {
        if (windowWidth <= AVERAGE_WIDTH + 40) {
            return MINIMUM_WIDTH;
        }
        if (windowWidth <= width + 40) {
            return AVERAGE_WIDTH;
        }
        return width;
    };

    const colors = barColors ?? getColorPerResultValue({lowerBound, upperBound, rangeColor});
    const formattedBlocks = useMemo(() => {
        return BLOCKS.map((block, index) => {
            return <rect key={index} {...block} fill={colors[index] || DEFAULT_BLOCK_COLOR} />;
        });
    }, [colors]);

    const groupedRanges: GroupedRange[] = getGroupedRanges({lowerBound, upperBound});

    // the randomness here is needed for safari
    const maskId = 'prefix__a__' + Math.random().toString(36).substring(7);

    return {currentWidth, maskId, formattedBlocks, groupedRanges, blockWidth, blockPadding};
};
