import styled from 'styled-components';

import {
    ColorBaseGrey100,
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import {
    BodyWrapperProps,
    HeaderWrapperProps
} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTesting/types';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
    height: 64px;
    background-color: #fff;
    box-shadow: 0 1px 1px rgb(0 0 0 / 8%);
    padding: 0 ${SpacingMedium};
    margin-bottom: 1px;
    filter: ${props => (props.blurBackground ? 'blur(10px)' : null)};
    position: sticky;
    top: 0;
    z-index: 1000;
`;

export const BodyWrapper = styled.div<BodyWrapperProps>`
    flex: 1 1;
    display: flex;
    filter: ${props => (props.blurBackground ? 'blur(10px)' : null)};

    @media only screen and (max-width: 800px) {
        flex-direction: column;
        flex: none;
        background-color: ${ColorBaseGrey100};
        min-height: calc(100% - 64px);
    }
`;

const Column = styled.div`
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 800px) {
        flex: 0 1 auto;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
    }
`;

export const QuestionWrapper = styled(Column)`
    background-color: #fff;
`;

export const OptionsWrapper = styled(Column)`
    background-color: ${ColorBaseGrey100};
`;

export const TimerWrapper = styled.div`
    @media only screen and (max-width: 370px) {
        margin-inline-start: ${SpacingSmall};
    }

    @media only screen and (min-width: 371px) and (max-width: 800px) {
        width: 344px;
        margin: 0 auto;
    }
`;

export const ButtonsWrapper = styled.div`
    padding-top: ${SpacingLarge};
    width: 192px;

    > button:first-child {
        margin-bottom: ${SpacingSmall};
    }

    @media only screen and (max-width: 370px) {
        width: 168px;
        margin: ${SpacingLarge} auto ${SpacingXxlarge};
    }

    @media only screen and (min-width: 371px) and (max-width: 800px) {
        width: 175px;
        margin: ${SpacingLarge} auto 48px;
    }
`;

export const EmptyWrapper = styled.div`
    height: 34px;
`;

export const BlockedWrapper = styled.div`
    margin: 0 auto;
    max-width: 440px;
    padding: ${SpacingLarge};
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    line-height: normal;
`;
