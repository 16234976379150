import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_ORGANIZATION_SUBSCRIPTION_QUERY} from '@/api/organizations/queries';
import {FeaturesLockType} from '@/pages/Organization/FeaturesLockedContext';

export function useOrganizationSubscription(pollInterval = 0) {
    const {data, error, loading, refetch} = useQuery(GET_ORGANIZATION_SUBSCRIPTION_QUERY, {
        pollInterval: pollInterval,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });
    const organization = useMemo(() => data?.authenticatedOrganization ?? null, [data]);

    const featuresLockType = useMemo(() => {
        if (!organization?.isProductLed) {
            return FeaturesLockType.NONE;
        }

        const isStripeSubscriptionActive = organization?.subscription?.isActive;

        if (isStripeSubscriptionActive && !organization?.isVerified) {
            return FeaturesLockType.VERIFICATION_REQUIRED;
        } else if (!isStripeSubscriptionActive) {
            return FeaturesLockType.PAYMENT_REQUIRED;
        }
        return FeaturesLockType.NONE;
    }, [organization]);

    return {
        loading,
        error,
        refetch,
        organization,
        featuresLockType
    };
}
