import {defineMessages} from 'react-intl';

export const personalityTestReportMessages = defineMessages({
    average: {
        id: 'personalityTestReport.average'
    },
    continuousImprovements: {
        id: 'personalityTestReport.continuousImprovements'
    },
    factor: {
        id: 'personalityTestReport.factor'
    },
    fiveFactorModel: {
        id: 'personalityTestReport.fiveFactorModel'
    },
    highDeviation: {
        id: 'personalityTestReport.highDeviation'
    },
    inBetween: {
        id: 'personalityTestReport.inBetween'
    },
    lowerBand: {
        id: 'personalityTestReport.lowerBand'
    },
    percentileTitle: {
        id: 'personalityTestReport.percentileTitle'
    },
    personalityProfile: {
        id: 'personalityTestReport.personalityProfile'
    },
    possibleChallenges: {
        id: 'personalityTestReport.possibleChallenges'
    },
    possibleStrengths: {
        id: 'personalityTestReport.possibleStrengths'
    },
    reliability: {
        id: 'personalityTestReport.reliability'
    },
    result: {
        id: 'personalityTestReport.result'
    },
    resultTitle: {
        id: 'personalityTestReport.resultTitle'
    },
    seeFullTestResult: {
        id: 'personalityTestReport.seeFullTestResult'
    },
    tableOfContent: {
        id: 'personalityTestReport.tableOfContent'
    },
    testDescription: {
        id: 'personalityTestReport.testDescription'
    },
    testIntro: {
        id: 'personalityTestReport.testIntro'
    },
    upperBand: {
        id: 'personalityTestReport.upperBand'
    },
    validity: {
        id: 'personalityTestReport.validity'
    }
});

export const A_MESSAGES = defineMessages({
    description: {
        id: 'personalityFactor.A.description'
    },
    descriptionLower: {
        id: 'personalityFactor.A.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityFactor.A.descriptionUpper'
    },
    labelLower: {
        id: 'personalityFactor.A.labelLower'
    },
    labelUpper: {
        id: 'personalityFactor.A.labelUpper'
    },
    name: {
        id: 'personalityFactor.A.name'
    },
    resultAverage: {
        id: 'personalityFactor.A.resultAverage'
    },
    resultHigh: {
        id: 'personalityFactor.A.resultHigh'
    },
    resultLow: {
        id: 'personalityFactor.A.resultLow'
    },
    resultVeryHigh: {
        id: 'personalityFactor.A.resultVeryHigh'
    },
    resultVeryLow: {
        id: 'personalityFactor.A.resultVeryLow'
    }
});

export const C_MESSAGES = defineMessages({
    description: {
        id: 'personalityFactor.C.description'
    },
    descriptionLower: {
        id: 'personalityFactor.C.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityFactor.C.descriptionUpper'
    },
    labelLower: {
        id: 'personalityFactor.C.labelLower'
    },
    labelUpper: {
        id: 'personalityFactor.C.labelUpper'
    },
    name: {
        id: 'personalityFactor.C.name'
    },
    resultAverage: {
        id: 'personalityFactor.C.resultAverage'
    },
    resultHigh: {
        id: 'personalityFactor.C.resultHigh'
    },
    resultLow: {
        id: 'personalityFactor.C.resultLow'
    },
    resultVeryHigh: {
        id: 'personalityFactor.C.resultVeryHigh'
    },
    resultVeryLow: {
        id: 'personalityFactor.C.resultVeryLow'
    }
});

export const E_MESSAGES = defineMessages({
    description: {
        id: 'personalityFactor.E.description'
    },
    descriptionLower: {
        id: 'personalityFactor.E.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityFactor.E.descriptionUpper'
    },
    labelLower: {
        id: 'personalityFactor.E.labelLower'
    },
    labelUpper: {
        id: 'personalityFactor.E.labelUpper'
    },
    name: {
        id: 'personalityFactor.E.name'
    },
    resultAverage: {
        id: 'personalityFactor.E.resultAverage'
    },
    resultHigh: {
        id: 'personalityFactor.E.resultHigh'
    },
    resultLow: {
        id: 'personalityFactor.E.resultLow'
    },
    resultVeryHigh: {
        id: 'personalityFactor.E.resultVeryHigh'
    },
    resultVeryLow: {
        id: 'personalityFactor.E.resultVeryLow'
    }
});

export const ES_MESSAGES = defineMessages({
    description: {
        id: 'personalityFactor.ES.description'
    },
    descriptionLower: {
        id: 'personalityFactor.ES.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityFactor.ES.descriptionUpper'
    },
    labelLower: {
        id: 'personalityFactor.ES.labelLower'
    },
    labelUpper: {
        id: 'personalityFactor.ES.labelUpper'
    },
    name: {
        id: 'personalityFactor.ES.name'
    },
    resultAverage: {
        id: 'personalityFactor.ES.resultAverage'
    },
    resultHigh: {
        id: 'personalityFactor.ES.resultHigh'
    },
    resultLow: {
        id: 'personalityFactor.ES.resultLow'
    },
    resultVeryHigh: {
        id: 'personalityFactor.ES.resultVeryHigh'
    },
    resultVeryLow: {
        id: 'personalityFactor.ES.resultVeryLow'
    }
});

export const O_MESSAGES = defineMessages({
    description: {
        id: 'personalityFactor.O.description'
    },
    descriptionLower: {
        id: 'personalityFactor.O.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityFactor.O.descriptionUpper'
    },
    labelLower: {
        id: 'personalityFactor.O.labelLower'
    },
    labelUpper: {
        id: 'personalityFactor.O.labelUpper'
    },
    name: {
        id: 'personalityFactor.O.name'
    },
    resultAverage: {
        id: 'personalityFactor.O.resultAverage'
    },
    resultHigh: {
        id: 'personalityFactor.O.resultHigh'
    },
    resultLow: {
        id: 'personalityFactor.O.resultLow'
    },
    resultVeryHigh: {
        id: 'personalityFactor.O.resultVeryHigh'
    },
    resultVeryLow: {
        id: 'personalityFactor.O.resultVeryLow'
    }
});
