import PropTypes from 'prop-types';
import {useCallback, useState} from 'react';

import {useAddCandidateForJobPosition} from '@/api/assessment/jobPositions/useAddCandidateForJobPosition';
import {useJobPositions} from '@/api/assessment/jobPositions/useJobPositions';
import {useUser} from '@/api/users/useUser';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {DebouncedTextField} from '@/componentLibrary/components/inputs/DebouncedTextField';
import {Spinner} from '@/componentLibrary/components/spinners';
import {logger} from '@/services/logrocket';

import {JobPositionsList} from './components/JobPositionsList';

export function AddCandidateToJobForm({
    userId,
    organizationId,
    existingJobApplications,
    refetchExistingJobApplications
}) {
    const [searchString, setSearchString] = useState(null);
    const [doAddCandidate] = useAddCandidateForJobPosition(false);
    const {user, loading: loadingUser} = useUser(userId);
    const {jobPositions, loading: loadingJobPositions} = useJobPositions(organizationId, {
        searchString,
        limit: 10
    });

    const hasApplicationInPosition = useCallback(
        jobPositionId => {
            return existingJobApplications.find(application => {
                return (
                    application &&
                    application.jobPosition &&
                    application.jobPosition.id === jobPositionId
                );
            });
        },
        [existingJobApplications]
    );

    const addCandidateToJobPosition = useCallback(
        jobPositionId => {
            if (!user) {
                return;
            }
            const toastId = 'addCandidateToJobPosition';
            toast({type: 'loading', id: toastId});
            const candidateData = {email: user.email};
            const createdFrom = 'ALVA';
            doAddCandidate({candidateData, createdFrom, jobPositionId})
                .then(
                    ({
                        data: {
                            addCandidateForJobPosition: {ok, errorMessage}
                        }
                    }) => {
                        if (ok) {
                            toast({type: 'success', id: toastId});
                            refetchExistingJobApplications();
                        } else {
                            throw new Error(errorMessage);
                        }
                    }
                )
                .catch(error => {
                    logger.error(error);
                    toast({type: 'error', id: toastId});
                });
        },
        [user, doAddCandidate, refetchExistingJobApplications]
    );

    return (
        <div>
            <div>
                <DebouncedTextField
                    onChange={setSearchString}
                    debounceTimeout={300}
                    autoFocus
                    fullWidth
                />
            </div>
            <div>
                {loadingJobPositions || loadingUser ? (
                    <Spinner isCentered />
                ) : (
                    <JobPositionsList
                        jobPositions={jobPositions}
                        addCandidateToJobPosition={addCandidateToJobPosition}
                        hasApplicationInPosition={hasApplicationInPosition}
                    />
                )}
            </div>
        </div>
    );
}

AddCandidateToJobForm.propTypes = {
    userId: PropTypes.number.isRequired,
    organizationId: PropTypes.number.isRequired,
    existingJobApplications: PropTypes.arrayOf(
        PropTypes.shape({
            jobPosition: PropTypes.shape({
                id: PropTypes.number
            })
        })
    ),
    refetchExistingJobApplications: PropTypes.func
};
