import {useIntl} from 'react-intl';

import {Overline} from '@/componentLibrary/components/typography';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import messages from '../../../messages';
import {ListWrapper} from '../../../styled';

export function NoDataList(): JSX.Element {
    const {formatMessage} = useIntl();

    return (
        <ListWrapper>
            <Overline color={ColorFgSubtle}>{formatMessage(messages.noData)}</Overline>
        </ListWrapper>
    );
}
