import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_PUBLIC_JOB_POSITION} from '@/api/assessment/publicJobPosition/queries';

export function usePublicJobPosition(jobPositionId: number) {
    const variables = {jobPositionId};
    const {data, error, loading, refetch} = useQuery(GET_PUBLIC_JOB_POSITION, {variables});
    const publicJobPosition = useMemo(() => data?.publicJobPosition ?? null, [data]);
    return {publicJobPosition, error, loading, refetch};
}
