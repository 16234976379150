import styled from 'styled-components';

export const Wrapper = styled.div`
    display: grid;
`;

export const DisabledWrapper = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(255 255 255 / 70%);
    grid-area: 1 / 1;
    z-index: 1;
    margin: auto;
    text-align: center;
`;

export const ChartWrapper = styled.div`
    grid-area: 1 / 1;
    z-index: 1;
`;

export const TextWrapper = styled.div`
    max-width: 354px;
    margin: auto;
    padding-top: 90px;
`;
