import {useCallback, useMemo, useState} from 'react';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {CODING_TEST_START_MODAL_MESSAGES} from '../messages';
import {StartCodingTestAlertTextType} from '../types';

export const useStartCodingTestAlert = ({withTechStack}: {withTechStack?: boolean} = {}) => {
    const phrases = useExtractPhraseConstants(CODING_TEST_START_MODAL_MESSAGES);
    const [showAlert, setShowAlert] = useState(false);

    const alertText: StartCodingTestAlertTextType = useMemo(
        () => ({
            title: phrases.alertTitle,
            subtitle: withTechStack
                ? phrases.alertSubtitleWitTechStack
                : phrases.alertSubtitle,
            disclaimer: phrases.alertDisclaimer,
            leftButton: phrases.alertStayButtonText,
            rightButton: phrases.alertContinueButtonText
        }),
        [phrases, withTechStack]
    );

    const onShowAlert = useCallback(() => setShowAlert(true), []);
    const onHideAlert = useCallback(() => setShowAlert(false), []);

    return {alertText, showAlert, onShowAlert, onHideAlert};
};
