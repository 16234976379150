import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';
import {
    ColorFgDefault,
    ColorFgInfo,
    FontSizeSmall,
    LineHeightsCompact,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const MenuItem = styled(NavLink)`
    color: ${ColorFgDefault};
    cursor: pointer;
    display: block;
    font-size: ${FontSizeSmall};
    line-height: ${LineHeightsCompact};
    padding: ${SpacingXxsmall} ${SpacingXsmall};

    :hover {
        text-decoration: underline;
    }

    &:last-child {
        border-top: ${BorderBaseDefault};
    }

    &.active {
        color: ${ColorFgInfo};
        cursor: default;
        pointer-events: none;

        :hover {
            text-decoration: none;
        }
    }
`;
