import {ColorBaseGrey100} from '@/componentLibrary/tokens/variables';
import {InnerBar, OuterBar} from './styled';

type TwoDimensionalBarProps = {
    barColor?: string;
    backgroundColor?: string;
    percentageValue?: number | null;
    height?: number;
};

export function TwoDimensionalBar({
    barColor,
    backgroundColor = ColorBaseGrey100,
    percentageValue = 0,
    height = 6
}: TwoDimensionalBarProps) {
    if (!barColor) {
        throw new Error('Bar color is required');
    }

    if (percentageValue === null || percentageValue < 0 || percentageValue > 1) {
        throw new Error('percentageValue must be between 0 and 1');
    }

    return (
        <OuterBar height={height} backgroundColor={backgroundColor}>
            <InnerBar height={height} percentageValue={percentageValue} barColor={barColor} />
        </OuterBar>
    );
}
