import styled from 'styled-components';

import {Spinner} from '@/componentLibrary/components/spinners';
import {SpacingMedium, SpacingSmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    margin-bottom: ${SpacingSmall};
    max-width: 920px;

    @media only screen and (max-width: 1032px) {
        padding: ${SpacingMedium};
    }
`;

export const SpinnerWrapper = styled.div`
    height: 199px;
    width: 100%;
    display: flex;
`;

export const CenteredSpinner = styled(Spinner)`
    margin: auto;
`;
