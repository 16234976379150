import {useMemo} from 'react';

import {Tooltip} from '@/componentLibrary/components/Tooltips/Tooltip';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {S2} from '@/componentLibrary/components/typography/SubTitles';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {useFormatCriterionName} from '../../hooks/useFormatCriterionName';
import messages from './messages';
import {ContentWrapper, TextContentWrapper} from './styled';
import {AssessmentCriterionTooltipProps} from './types';

export function AssessmentCriterionTooltip({
    children,
    contributionTypeId,
    contributionItem
}: AssessmentCriterionTooltipProps) {
    const phrases = useExtractPhraseConstants(messages);
    const getTitleMessage = useFormatCriterionName({contributionTypeId});

    const content = useMemo(() => {
        return (
            <ContentWrapper>
                <S2>{getTitleMessage(contributionItem)}</S2>
                <TextContentWrapper>
                    <P2 color={ColorFgSubtle}>{phrases.weightInRoleFit}</P2>
                    <P2>{contributionItem.maxAbsoluteContribution}%</P2>
                    <P2 color={ColorFgSubtle}>{phrases.contributionToRoleFit}</P2>
                    <P2>
                        {contributionItem.absoluteContribution !== null
                            ? contributionItem.absoluteContribution
                            : 'N/A'}
                    </P2>
                </TextContentWrapper>
            </ContentWrapper>
        );
    }, [
        getTitleMessage,
        contributionItem,
        phrases.weightInRoleFit,
        phrases.contributionToRoleFit
    ]);

    return <Tooltip asChild content={content} placement="bottom" trigger={children} />;
}
