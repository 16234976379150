import {FetchResult} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {useCreateLogicTest} from '@/api/logicTestIrt/useCreateLogicTest';
import {useRecreateSharedLogicTest} from '@/api/logicTestIrt/useRecreateSharedLogicTest';
import {
    CreateLogicTestMutation,
    RecreateSharedLogicTestMutation
} from '@/api/types/__generated__/graphql';
import {useMyTestProgress} from '@/api/users/useMyTestProgress';
import {toast} from '@/componentLibrary/components/Toast/toast';

import {LogicProps as Props} from './types';

export const useStartPageLogic = ({shareWithOrganizationId, goToTest}: Props) => {
    const displayErrorToast = useCallback(() => toast({type: 'error'}), []);
    const {user} = useMyTestProgress(shareWithOrganizationId);
    const [createLogicTest, {loading: loadingCreateTest}] =
        useCreateLogicTest(shareWithOrganizationId);
    const [recreateSharedLogicTest, {loading: loadingRecreateTest}] =
        useRecreateSharedLogicTest(shareWithOrganizationId);

    const sharedTestExpired = useMemo(
        () => user?.logicTestProgress.isSharedLogicTestExpired,
        [user]
    );

    const createAndGoToLogicTest = useCallback(() => {
        const onResponse = (response: FetchResult<CreateLogicTestMutation>) => {
            const responseData = response.data;
            const mutationResponse = responseData?.createLogicTest;
            const {ok, logicTestStatus} = mutationResponse || {};
            if (!ok || !logicTestStatus?.logicTest) {
                displayErrorToast();
                return;
            }
            goToTest(logicTestStatus.logicTest.id);
        };
        createLogicTest().then(onResponse).catch(displayErrorToast);
    }, [createLogicTest, displayErrorToast, goToTest]);

    const recreateAndGoToLogicTest = useCallback(() => {
        const onResponse = (response: FetchResult<RecreateSharedLogicTestMutation>) => {
            const responseData = response.data;
            const mutationResponse = responseData?.recreateSharedLogicTest;
            const {ok, logicTestStatus} = mutationResponse || {};
            if (!ok || !logicTestStatus?.logicTest) {
                displayErrorToast();
                return;
            }
            goToTest(logicTestStatus.logicTest.id);
        };
        recreateSharedLogicTest().then(onResponse).catch(displayErrorToast);
    }, [recreateSharedLogicTest, displayErrorToast, goToTest]);

    const startTest = useMemo(
        () => (sharedTestExpired ? recreateAndGoToLogicTest : createAndGoToLogicTest),
        [sharedTestExpired, recreateAndGoToLogicTest, createAndGoToLogicTest]
    );

    return {
        startTest,
        loading: loadingCreateTest || loadingRecreateTest
    };
};
