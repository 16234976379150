import {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {Banner} from '@/componentLibrary/components/banners/Banner';
import {SsoButton} from '@/componentLibrary/components/buttons/SsoButton';
import {SsoProvider} from '@/services/auth/sso/constants';
import {isImpersonating} from '@/utils/ajax';
import {capitalizeFirstLetter} from '@/utils/misc';

import messages from './messages';
import {GoToOrganizationButton, Wrapper} from './styled';
import {Props} from './types';

export function OrganizationSelector({
    loading,
    enforcedAuthenticationMethod,
    organizationName,
    handleLoginWithSso,
    goToOrganization,
    currentAuthenticationMethod,
    isUnlockedByOktaLogin
}: Props) {
    const intl = useIntl();
    const isLocked = useMemo(() => {
        if (isImpersonating()) {
            return false;
        }
        if (!enforcedAuthenticationMethod) {
            return false;
        }
        if (currentAuthenticationMethod !== enforcedAuthenticationMethod) {
            return true;
        }
        if (enforcedAuthenticationMethod === SsoProvider.OKTA) {
            return !isUnlockedByOktaLogin;
        }
        return false;
    }, [currentAuthenticationMethod, enforcedAuthenticationMethod, isUnlockedByOktaLogin]);

    if (isLocked) {
        return (
            <Wrapper>
                <Banner
                    noIcon
                    customAction={
                        <SsoButton
                            isSignup={false}
                            isFullWidth={false}
                            isLoading={loading}
                            ssoProvider={enforcedAuthenticationMethod}
                            onClick={handleLoginWithSso.bind(
                                null,
                                enforcedAuthenticationMethod
                            )}
                        />
                    }
                >
                    {intl.formatMessage(messages.loginWithSso, {
                        provider: capitalizeFirstLetter(enforcedAuthenticationMethod)
                    })}
                </Banner>
            </Wrapper>
        );
    }

    return (
        <GoToOrganizationButton onClick={goToOrganization}>
            {intl.formatMessage(messages.goToOrganization, {name: organizationName})}
        </GoToOrganizationButton>
    );
}
