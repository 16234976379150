import styled from 'styled-components';

import {BorderStripeProps} from '@/componentLibrary/components/cards/AssessmentCard/types';
import {Caption} from '@/componentLibrary/components/typography';
import {BorderBaseDefaultRule} from '@/componentLibrary/tokens/customVariables';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderBaseDefaultStyle,
    BorderBaseDefaultWidth,
    BorderBaseFocusColor,
    BorderRadiusBaseSmall,
    BorderWidthEmphasis,
    ColorBgDefault,
    ColorBorderInfo,
    ColorBrandSecondaryLightToneB,
    ColorFgSubtle,
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const RecommendationBorder = styled.div`
    background: linear-gradient(white, white) padding-box,
                linear-gradient(to right, ${BorderBaseFocusColor} 0%, ${ColorBrandSecondaryLightToneB} 50%, ${ColorBorderInfo} 100%) border-box;
    border-radius: ${SpacingXxxsmall};
    border: ${BorderBaseDefaultWidth} ${BorderBaseDefaultStyle} transparent;
`;

export const RecommendationBackgroundCodingTest = styled.div`
    background-image: 
        radial-gradient(circle at 20% 50%, #E7EDFF 0, transparent 100px),
        radial-gradient(circle at 75% 50%, #F6F8FF 0, transparent 100px),
        radial-gradient(circle at 85% 50%, #EAEAEA 0, transparent 100px);
`;

export const RecommendationBackground = styled.div`
    background-image: 
        radial-gradient(circle at 50% 50%, #F9F9F9 0, transparent 100px),
        radial-gradient(circle at 80% 50%, #EAF0FF 0, transparent 100px),
        radial-gradient(circle at 85% 50%, #DEE4F4 0, transparent 100px);
`;

export const Container = styled.div<{$withBackground?: boolean; $withBorder?: boolean}>`
    display: flex;
    ${({$withBackground = true}) => $withBackground && `background: ${ColorBgDefault};`}
    padding-top: ${SpacingSmall};
    padding-bottom: ${SpacingSmall};
    padding-right: ${SpacingMedium};
    ${({$withBorder = true}) => $withBorder && `${BorderBaseDefaultRule}`}
    border-radius: ${BorderRadiusBaseSmall};
`;

export const LeftWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    align-items: center;
    margin-right: ${SpacingSmall};
`;

export const BorderStripe = styled.div<BorderStripeProps>`
    width: ${BorderWidthEmphasis};
    height: 100%;
    border-radius: 0 ${BorderRadiusBaseSmall} ${BorderRadiusBaseSmall} 0;
    background: ${({color}) => color};
    margin-right: ${SpacingXxxsmall};
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    justify-content: center;
`;

export const Attribution = styled(Caption)`
    color: ${ColorFgSubtle};
`;

export const IconPlaceholder = styled.div`
    width: ${SpacingLarge};
    height: ${SpacingLarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        display: none;
    }
`;

export const DetailsAndAction = styled.div<{isCentered?: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: ${({isCentered = true}) => (isCentered ? 'center' : 'flex-start')};
`;

export const DetailsWrapper = styled.div`
    display: flex;
    gap: ${SpacingSmall};
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-right: ${SpacingSmall};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
    }
`;

export const Details = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXxsmall};
    justify-content: space-between;
    width: 100%;
`;

export const NoGap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
`;

export const MainTitle = styled.div`
    display: flex;
    align-items: center;
    gap: ${SpacingXxsmall};
`;
export const AdditionalTitleText = styled(Caption)`
    align-self: center;
    color: ${ColorFgSubtle};
`;

export const Titles = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Label = styled.div`
    display: flex;
    align-items: center;
`;

export const ActionWrapper = styled.div`
    display: flex;
    gap: ${SpacingSmall};
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

export const Recommendation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -${SpacingXxsmall};
`;
