import {useMutation} from '@apollo/client';

import {
    SetUseOverdueScoreForChallengeAssignmentMutation,
    SetUseOverdueScoreForChallengeAssignmentMutationVariables
} from '../../types/__generated__/graphql';
import {MutationHookReturnType} from '../../types/genericApi/types';
import {SET_USE_OVERDUE_SCORE_FOR_CHALLENGE_ASSIGNMENT} from './mutations';

export function useSetUseOverdueScore(): MutationHookReturnType<
    SetUseOverdueScoreForChallengeAssignmentMutation,
    [SetUseOverdueScoreForChallengeAssignmentMutationVariables]
> {
    const [mutate, {error, loading}] = useMutation(
        SET_USE_OVERDUE_SCORE_FOR_CHALLENGE_ASSIGNMENT
    );

    const doUpdate = ({
        assignmentId,
        useOverdueScore
    }: SetUseOverdueScoreForChallengeAssignmentMutationVariables) => {
        const variables = {assignmentId, useOverdueScore};
        return mutate({variables});
    };

    return [doUpdate, {error, loading}];
}
