import {SpacingMedium} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const CardWrapper = styled.div`
    margin: ${SpacingMedium} 0;
`;

export const ActivityCardHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PersonalInfoTitle = styled.div`
    display: flex;
    align-items: center;
`;
