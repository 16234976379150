import qs from 'qs';
import {v4 as uuid} from 'uuid';

import {getConfig} from '@/config';
import {userLinks} from '@/pages/User/links';
import {setSsoNonceInSessionStorage} from '@/services/auth/sso/utils';

export function redirectToGoogle(
    searchString = '',
    domain?: string,
    scope = 'email profile',
    includeAllParamsInState = false
) {
    const nonce = uuid();
    setSsoNonceInSessionStorage(nonce);
    const encoded_scope = window.encodeURIComponent(scope);
    const config = getConfig();
    const redirectPath = getRedirectPath(searchString);
    let state;
    if (includeAllParamsInState) {
        state = window.encodeURIComponent(qs.stringify({...qs.parse(searchString)}));
    } else {
        state = window.encodeURIComponent(qs.stringify({redirectPath}));
    }
    const clientId = window.encodeURIComponent(config.GOOGLE_CLIENT_ID);
    const redirectUrlPath = 'verify-oidc-authentication';
    const redirectUrl = window.encodeURIComponent(`${config.APP}/${redirectUrlPath}`);
    const hd = domain ? `&hd=${domain}` : '';
    const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=${encoded_scope}&include_granted_scopes&client_id=${clientId}&response_type=id_token&redirect_uri=${redirectUrl}&nonce=${nonce}&state=${state}&prompt=select_account${hd}`;

    window.location.href = googleLoginUrl;
}

export function redirectToMicrosoft(searchString = '', includeAllParamsInState = false) {
    const nonce = uuid();
    setSsoNonceInSessionStorage(nonce);
    // With tenant `common` users with both a personal Microsoft account and a work or school account
    // from Azure AD can sign in to the application.
    const tenant = 'common';
    const scope = window.encodeURIComponent('openid email profile');
    const config = getConfig();
    const redirectPath = getRedirectPath(searchString);
    let state;
    if (includeAllParamsInState) {
        state = window.encodeURIComponent(qs.stringify({...qs.parse(searchString)}));
    } else {
        state = window.encodeURIComponent(qs.stringify({redirectPath}));
    }
    const clientId = window.encodeURIComponent(config.MICROSOFT_CLIENT_ID);
    const redirectUrlPath = 'verify-oidc-authentication';
    const redirectUrl = window.encodeURIComponent(`${config.APP}/${redirectUrlPath}`);
    const microsoftLoginUrl = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=id_token&redirect_uri=${redirectUrl}&response_mode=fragment&scope=${scope}&state=${state}&nonce=${nonce}&prompt=select_account`;

    window.location.href = microsoftLoginUrl;
}

export function getRedirectUrl(searchString: string) {
    const redirectPath = getRedirectPath(searchString);
    if (redirectPath) {
        return userLinks.myWorkspacesWithRedirect(redirectPath);
    }
    return userLinks.myWorkspaces();
}

function getRedirectPath(searchString: string) {
    const params = qs.parse(searchString, {ignoreQueryPrefix: true});
    return params.redirect?.toString();
}
