import {useCallback, useEffect, useState} from 'react';

import {AuthenticationMethod} from '@/api/types/__generated__/graphql';
import {useOktaClient} from '@/services/auth/sso/okta/useOktaClient';
import {SsoState} from '@/services/auth/sso/types';
import {useIsComponentMounted} from '@/utils/useIsComponentMounted';

type OktaIdTokenResponse = {
    data: string | null;
    error: string | null;
    completed: boolean;
};

export function useOktaIdToken(state: SsoState) {
    const client = useOktaClient(state.oktaConfig);
    const isMounted = useIsComponentMounted();
    const [status, setStatus] = useState<OktaIdTokenResponse>({
        data: null,
        error: null,
        completed: false
    });

    const fetchIdToken = useCallback(() => {
        client.token
            .parseFromUrl()
            .then(res => {
                const idToken = res.tokens.idToken?.idToken || null;
                setStatus({
                    data: idToken,
                    error: null,
                    completed: true
                });
            })
            .catch(e => {
                setStatus({
                    data: null,
                    error: e.message,
                    completed: true
                });
            });
    }, [client.token]);

    useEffect(() => {
        if (!isMounted || status.completed) {
            return;
        }
        if (state.ssoProvider !== AuthenticationMethod.OKTA) {
            setStatus({
                data: null,
                error: null,
                completed: true
            });
            return;
        }

        fetchIdToken();
    }, [fetchIdToken, isMounted, state.ssoProvider, status.completed]);

    return {status};
}
