import styled from 'styled-components';

import {
    SpacingSmall,
    SpacingXxlarge,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXxlarge};
`;

export const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXxsmall};
`;

export const List = styled.ul`
    list-style-type: disc;
    margin-left: ${SpacingSmall};

    li {
        margin-bottom: ${SpacingXxxsmall};
    }
`;
