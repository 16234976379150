import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {P2} from '@/componentLibrary/components/typography';
import {LINKS} from '@/links';

import messages from './messages';

export function Links() {
    const intl = useIntl();
    const getText = useCallback(() => {
        return intl.formatMessage(messages.consentDescription, {
            termsOfServiceLink: (
                <ExternalLink url={LINKS.termsOfService}>
                    {intl.formatMessage(messages.alvaTermsOfServiceTitle)}
                </ExternalLink>
            ),
            privacyPolicyLink: (
                <ExternalLink url={LINKS.privacyPolicy}>
                    {intl.formatMessage(messages.privacyPolicyTitle)}
                </ExternalLink>
            )
        });
    }, [intl]);

    return <P2>{getText()}</P2>;
}
