import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {
    LINKEDIN_PLACEHOLDER_URL,
    LINKEDIN_URL
} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation/components/SubmitResume/constants';
import {Section} from '@/pages/User/components/RecommendationRelevanceForm/components/Section';
import messages from '@/pages/User/components/RecommendationRelevanceForm/components/messages';
import {
    ERROR_TYPES,
    LINKEDIN_URL_KEY
} from '@/pages/User/components/RecommendationRelevanceForm/constants';
import {Question} from '@/pages/common/RelevanceForm/components/Question';
import {ErrorDisplay} from '@/pages/common/RelevanceForm/styled';
import {useIntl} from 'react-intl';

import {LinkWrapper, TextFieldWrapper} from './styled';
import {Props} from './types';

export function AboutYou(props: Props) {
    const intl = useIntl();
    const {linkedinProfileUrl, handleLinkedinProfileUrlChange, formValidationErrors} = props;

    return (
        <Section title={intl.formatMessage(messages.aboutYouTitle)}>
            <Question
                id={ERROR_TYPES.LINKEDIN_URL}
                title={intl.formatMessage(messages.linkedinProfileUrl)}
                content={
                    <TextFieldWrapper>
                        <TextField
                            name="linkedinProfileUrl"
                            type="url"
                            fullWidth
                            placeholder={LINKEDIN_PLACEHOLDER_URL}
                            value={linkedinProfileUrl}
                            onChange={e => handleLinkedinProfileUrlChange(e.target.value)}
                        />
                    </TextFieldWrapper>
                }
            />
            {formValidationErrors.includes(ERROR_TYPES.LINKEDIN_URL) ? (
                <ErrorDisplay>{intl.formatMessage({id: LINKEDIN_URL_KEY})}</ErrorDisplay>
            ) : null}
            <LinkWrapper>
                <ExternalLink url={LINKEDIN_URL}>
                    {intl.formatMessage(messages.getLinkedInURL)}
                </ExternalLink>
            </LinkWrapper>
        </Section>
    );
}
