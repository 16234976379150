import {toast} from '@/componentLibrary/components/Toast/toast';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {InstructionLayout} from '@/componentLibrary/components/layout/InstructionLayout';
import {Wrapper} from '@/pages/LogicTestDataCollection/pages/DataCollectionForm/styled';
import {NotFound} from '@/pages/NotFound';
import {DataCollectionInstructions} from './components/DataCollectionInstructions';
import {ProlificContent} from './components/ProlificContent';
import {StartDataCollectionTest} from './components/StartDataCollectionTest';
import {useDataCollectionLogic} from './logic';
import messages from './messages';

export function DataCollectionForm() {
    const phrases = useExtractPhraseConstants(messages);
    const showErrorToast = () => toast({type: 'error', message: phrases.error});
    const {creatingUser, createUser, externalId, isUserCreated, source, studyId} =
        useDataCollectionLogic(showErrorToast);

    if (!source || !studyId) {
        return <NotFound />;
    }
    if (isUserCreated) {
        return (
            <StartDataCollectionTest
                externalId={externalId}
                studyId={studyId}
                showErrorToast={showErrorToast}
                source={source as string}
            />
        );
    }

    return (
        <InstructionLayout
            instruction={<DataCollectionInstructions />}
            withLanguageToggle={false}
        >
            <Wrapper>
                <ProlificContent createUser={createUser} creatingUser={creatingUser} />
            </Wrapper>
        </InstructionLayout>
    );
}
