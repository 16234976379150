import styled from 'styled-components';

import {
    BorderWidthBase,
    ColorBaseGrey000,
    ColorBaseGrey200,
    SpacingMedium,
    SpacingSmall,
    SpacingXxlarge,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';
import {PatternItemProps} from '@/pages/LogicTestIrt/components/Question/types';

export const Wrapper = styled.div`
    flex: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: ${SpacingMedium};

    @media only screen and (max-width: 760px) {
        padding: ${SpacingSmall} 0;
    }

    @media only screen and (min-width: 761px) and (max-width: 800px) {
        padding: ${SpacingMedium} 0 ${SpacingXxlarge};
    }
`;

export const PatternsRow = styled.div`
    display: flex;
    flex: none;
    justify-content: center;

    @media only screen and (max-width: 800px) {
        margin: 0;
    }
`;

export const PatternItemWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${SpacingMedium};
    margin: 0;
    background-color: ${ColorBaseGrey000};
    box-shadow: 0 0;
    border-radius: ${BorderWidthBase};
    border-color: ${ColorBaseGrey200};
    border-style: solid;
    border-width: ${BorderWidthBase};

    @media only screen and (max-width: 370px) {
        padding: ${SpacingXxsmall};
    }
`;

export const PatternItem = styled.img<PatternItemProps>`
    height: 80px;
    width: 80px;
`;
