import {useMemo} from 'react';

import {useGetRelevanceInformation} from '@/api/users/useGetRelevanceInformation';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {StepperProps} from '@/componentLibrary/components/Stepper/types';
import {useModalLogic} from '@/hooks/useModalLogic';
import {
    InitialActiveRecommendationState,
    MODAL_KEYS,
    showReadMoreState,
    showRejectState
} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation/constants';

type ModalActions = {
    [key: string]: {[key: string]: () => void};
};

type Props = {
    initialState?: InitialActiveRecommendationState;
    onApply?: () => void;
    close: () => void;
};

export function useActiveRecommendationLogic({initialState, onApply, close}: Props) {
    const {user: loggedInUser} = useLoggedInUser();
    const {userRelevanceInformation} = useGetRelevanceInformation(loggedInUser?.id);
    const hasSubmittedLinkedinProfile = userRelevanceInformation?.linkedinProfileUrl;
    const defaultState =
        initialState === InitialActiveRecommendationState.REJECT
            ? showRejectState
            : showReadMoreState;
    const {
        state: modalState,
        open: openModal,
        currentKey: modalCurrentKey
    } = useModalLogic(MODAL_KEYS, defaultState);

    const modalActions: ModalActions = {
        REJECT: {
            leftAction: () => close()
        },
        READ_MORE: {
            leftAction: () => openModal(MODAL_KEYS.REJECT),
            rightAction: () =>
                !hasSubmittedLinkedinProfile
                    ? openModal(MODAL_KEYS.SUBMIT_RESUME)
                    : openModal(MODAL_KEYS.SHARE_CONFIRMATION)
        },
        SUBMIT_RESUME: {
            rightAction: () => openModal(MODAL_KEYS.SHARE_CONFIRMATION),
            giveFeedback: () => openModal(MODAL_KEYS.LINKEDIN_FEEDBACK)
        },
        LINKEDIN_FEEDBACK: {
            leftAction: () => openModal(MODAL_KEYS.SUBMIT_RESUME)
        },
        SHARE_CONFIRMATION: {
            leftAction: !hasSubmittedLinkedinProfile
                ? () => openModal(MODAL_KEYS.SUBMIT_RESUME)
                : () => openModal(MODAL_KEYS.READ_MORE),
            rightAction: () => {
                onApply?.();
                openModal(MODAL_KEYS.SENT_CONFIRMATION);
            }
        },
        SENT_CONFIRMATION: {}
    };

    const modalSteppers: {[key: string]: StepperProps | undefined} = useMemo(() => {
        const submitResumeStepper = {noOfSteps: 2, activeStep: 1};
        const shareConfirmationStepper = !hasSubmittedLinkedinProfile
            ? {noOfSteps: 2, activeStep: 2}
            : undefined;
        return {
            REJECT: undefined,
            READ_MORE: undefined,
            SUBMIT_RESUME: submitResumeStepper,
            SHARE_CONFIRMATION: shareConfirmationStepper,
            SENT_CONFIRMATION: undefined
        };
    }, [hasSubmittedLinkedinProfile]);

    return {
        modalState,
        modalActions,
        modalSteppers,
        modalCurrentKey
    };
}
