import styled from 'styled-components';

import {SubtitleS1, SubtitleS2} from '@/componentLibrary/tokens/typography';

import {Base} from './Base';

export const S1 = styled(Base)`
    display: inline-block;
    ${SubtitleS1};
`;

export const S2 = styled(Base)`
    display: inline-block;
    ${SubtitleS2};
`;
