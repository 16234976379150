import {FormattedMessage} from 'react-intl';

import {JobPositionStatus} from '@/api/types/__generated__/graphql';

import {jobPositionStatusMessages} from '../../../../pages/JobPositions/messages';

export function getTextFromStatus(status: JobPositionStatus | string) {
    const message = jobPositionStatusMessages[status as JobPositionStatus];
    return message ? <FormattedMessage {...message} /> : status;
}
