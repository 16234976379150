import styled from 'styled-components';

import {H4} from '@/componentLibrary/components/typography';
import {
    ColorBaseGrey000,
    ColorBaseGrey600,
    SpacingLarge,
    SpacingSmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const InstructionsWrapper = styled.div`
    color: ${ColorBaseGrey000};
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 768px) {
        margin-top: 48px;
    }
`;

export const ParagraphWrapper = styled.div`
    padding-bottom: ${SpacingLarge};
`;

export const DescriptionTitle = styled(H4)`
    padding-bottom: ${SpacingSmall};
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const SubParagraphWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: ${SpacingXxxsmall};

    > *:first-child {
        margin-top: 2px;
    }
`;

export const AbstractWrapper = styled.span`
    color: ${ColorBaseGrey000};
`;

export const DetailsWrapper = styled.span`
    color: ${ColorBaseGrey600};
`;
