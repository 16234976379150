import {useLocale} from '@/containers/LanguageProvider/useLocale';
import {
    toTranslatedCriterion,
    toTranslatedInstruction
} from '@/pages/Organization/hooks/useTranslatedInterviewContent/mappers';
import {isCriterion} from '@/pages/Organization/utils';
import {useCallback, useMemo} from 'react';
import {UnTranslatedItem} from './types';
import {getTranslationsInPriorityOrder} from './utils';

const toTranslatedItem = <T extends UnTranslatedItem>(item: T, locale: string) => {
    const translations = getTranslationsInPriorityOrder(item.translations, locale);

    if (isCriterion(item)) {
        return toTranslatedCriterion(item, locale, translations);
    }

    return toTranslatedInstruction(item, locale, translations);
};

export const useTranslateInterviewContentItem = <T extends UnTranslatedItem>() => {
    const locale = useLocale();
    return useCallback((item: T) => toTranslatedItem(item, locale), [locale]);
};

export const useTranslatedInterviewContent = <T extends UnTranslatedItem>(
    items?: T[],
    priorityLocale?: string
) => {
    const appLocale = useLocale();
    const locale = priorityLocale ?? appLocale;

    return useMemo(() => {
        if (!items) {
            return [];
        }
        return items.map(item => toTranslatedItem(item, locale));
    }, [items, locale]);
};
