import styled from 'styled-components';

import animatedLogo from './assets/alva_animation.gif';

const Wrapper = styled.div`
    inset: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const LogoWrapper = styled.div`
    width: 100px;
    height: 100px;
    background: url("${animatedLogo}") center center;
    background-size: contain;
`;

export {Wrapper, LogoWrapper};
