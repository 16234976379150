import {GetJobRelevanceInformationQuery} from '@/api/types/__generated__/graphql';
import {WORK_LOCATIONS_THAT_REQUIRE_CITIES} from '@/pages/common/RelevanceForm/constants';
import {arrayIsEmpty} from '@/utils/typeGuards/arrayIsEmpty';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {Option} from './types';

const isCompleteRelevanceInformation = (
    jobRelevanceInformation?: NonNullable<
        GetJobRelevanceInformationQuery['jobPosition']
    >['jobRelevanceInformation']
) => {
    if (!isDefined(jobRelevanceInformation)) {
        return false;
    }
    if (!isDefined(jobRelevanceInformation?.jobFamily)) {
        return false;
    }

    const workLocations = jobRelevanceInformation?.workLocations;
    const workLocationsArePresent = !arrayIsEmpty(workLocations);

    const shouldHaveCities = !workLocationsArePresent
        ? false
        : workLocations?.some(workLocation =>
              WORK_LOCATIONS_THAT_REQUIRE_CITIES.includes(workLocation)
          );
    const citiesConditionIsMet = shouldHaveCities
        ? !arrayIsEmpty(jobRelevanceInformation?.citiesWithMetaData)
        : true;

    const requiredValuesArePresent = [
        isDefined(jobRelevanceInformation?.hasLeadershipResponsibilities),
        !arrayIsEmpty(jobRelevanceInformation?.workLocations),
        citiesConditionIsMet,
        !arrayIsEmpty(jobRelevanceInformation?.requiredLanguages)
    ];

    return requiredValuesArePresent.every(value => !!value);
};

const sortOptionsByName = (a: Option, b: Option) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
};

export {isCompleteRelevanceInformation, sortOptionsByName};
