import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography/Paragraphs';

export const Wrapper = styled(P2).attrs({as: 'div'})`
    margin: 20px 0;
    height: 200px;
    width: 260px;
`;

export const WaitingForResult = styled.p`
    font-style: italic;
`;
