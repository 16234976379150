import {Icons} from '@/componentLibrary/components/icons/constants';
import {ColorFgDanger} from '@/componentLibrary/tokens/variables';
import {CustomIcon, HorizontalLine, Indicator, Wrapper} from './styled';

export function ScoreIndicator({score}: {score: number}) {
    return (
        <Wrapper>
            <Indicator score={score}>
                <CustomIcon icon={Icons.ARROW_DROP_DOWN} fill={ColorFgDanger} size={24} />
                <HorizontalLine />
            </Indicator>
        </Wrapper>
    );
}
