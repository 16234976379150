import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {ColorFgDanger} from '@/componentLibrary/tokens/variables';
import {
    IconWrapper,
    WarningTextWrapper,
    WarningWrapper
} from '@/pages/PublicJobPosition/pages/ApplyForJobPosition/components/ApplyForm/components/FormWarning/components/styled';
import {WarningProps} from '@/pages/PublicJobPosition/pages/ApplyForJobPosition/components/ApplyForm/components/FormWarning/components/types';

export function Warning({children, close, visible}: WarningProps) {
    if (!visible) {
        return <WarningWrapper visible={false} />;
    }

    return (
        <WarningWrapper visible={visible}>
            <WarningTextWrapper>{children}</WarningTextWrapper>
            <IconWrapper onClick={close}>
                <Icon size={20} icon={Icons.CLOSE} fill={ColorFgDanger} />
            </IconWrapper>
        </WarningWrapper>
    );
}
