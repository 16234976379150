import {defineMessages} from 'react-intl';

export const personalityTestTakingMessages = defineMessages({
    alreadyAnswered: {
        id: 'personalityTestTaking.alreadyAnswered'
    },
    answerCenter: {
        id: 'personalityTestTaking.answerCenter'
    },
    answerHigh: {
        id: 'personalityTestTaking.answerHigh'
    },
    answerLow: {
        id: 'personalityTestTaking.answerLow'
    },
    cancel: {
        id: 'personalityTestTaking.cancel'
    },
    continueTest: {
        id: 'personalityTestTaking.continueTest'
    },
    errorLoadingPreviousQuestion: {
        id: 'personalityTestTaking.errorLoadingPreviousQuestion'
    },
    errorLoadingTest: {
        id: 'personalityTestTaking.errorLoadingTest'
    },
    errorSavingAnswer: {
        id: 'personalityTestTaking.errorSavingAnswer'
    },
    errorSkippingQuestion: {
        id: 'personalityTestTaking.errorSkippingQuestion'
    },
    leaveTest: {
        id: 'personalityTestTaking.leaveTest'
    },
    leaveTestDescription: {
        id: 'personalityTestTaking.leaveTestDescription'
    },
    leaveTestTitle: {
        id: 'personalityTestTaking.leaveTestTitle'
    },
    previousQuestion: {
        id: 'personalityTestTaking.previousQuestion'
    },
    questionOptionsDescription: {
        id: 'personalityTestTaking.questionOptionsDescription'
    },
    seeResult: {
        id: 'personalityTestTaking.seeResult'
    },
    skipQuestion: {
        id: 'personalityTestTaking.skipQuestion'
    },
    stop: {
        id: 'personalityTestTaking.stop'
    },
    tellUsWhy: {
        id: 'personalityTestTaking.tellUsWhy'
    },
    testIsCompleted: {
        id: 'personalityTestTaking.testIsCompleted'
    },
    testNotFoundDescription: {
        id: 'personalityTestTaking.testNotFoundDescription'
    },
    testNotFoundTitle: {
        id: 'personalityTestTaking.testNotFoundTitle'
    },
    tooManySkipped: {
        id: 'personalityTestTaking.tooManySkipped'
    },
    waitingForResult: {
        id: 'personalityTestTaking.waitingForResult'
    },
    confirm: {
        id: 'app.general.confirm'
    }
});
