import {AvatarContent, AvatarWrapper} from './styled';
import {TeamAvatarProps} from './types';

export const TeamAvatar = ({team, size = 40, fontSize = 16, image}: TeamAvatarProps) => {
    const getInitials = () => {
        let initials = '';

        if (!team) {
            return initials;
        }

        const text = team.name.replace(/[&._-]/g, '').trim();
        const nameParts = text.split(/\s+/);

        if (nameParts.length === 1) {
            initials = nameParts[0][0];
        } else if (nameParts.length > 1) {
            initials = `${nameParts[0][0]}${nameParts[1][0]}`;
        }

        return initials.toUpperCase();
    };

    return (
        <AvatarWrapper size={size} title={team?.name ?? ''} image={image}>
            {!image && (
                <AvatarContent fontSize={fontSize} size={size}>
                    {getInitials()}
                </AvatarContent>
            )}
        </AvatarWrapper>
    );
};
