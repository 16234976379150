import {useCallback, useState} from 'react';

export function useSimpleModalLogic(defaultOpen?: boolean) {
    const [isOpen, setIsOpen] = useState(defaultOpen ?? false);

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const toggleModal = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const setModalIsOpen = useCallback((isModalOpen: boolean) => {
        setIsOpen(isModalOpen);
    }, []);

    return {modalIsOpen: isOpen, openModal, closeModal, toggleModal, setModalIsOpen};
}
