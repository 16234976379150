import {useCallback, useMemo, useState} from 'react';

import {useCitySearchResults} from '@/api/jobRecommendations/useCitySearchResults';
import {City} from '@/api/types/__generated__/graphql';
import {ERROR_TYPES} from '@/pages/User/components/RecommendationRelevanceForm/constants';

import {RemoveValidationError} from '../types';

const OFFICE_KEY = 'OFFICE';
const HYBRID_KEY = 'HYBRID';

export function useLocationLogic(removeValidationError: RemoveValidationError) {
    const [selectedCities, setSelectedCities] = useState<City[]>([]);
    const [selectedWorkLocations, setSelectedWorkLocations] = useState<string[]>([]);

    const [searchKey, setSearchKey] = useState<string>('');
    const {citySearchResults, loading} = useCitySearchResults(searchKey);

    const handleCitiesSelection = useCallback(
        (newSelectedCities: City[]) => {
            if (newSelectedCities?.length > 0) {
                removeValidationError(ERROR_TYPES.CITIES);
            }
            setSelectedCities(newSelectedCities);
        },
        [removeValidationError]
    );

    const handleWorkLocationSelection = useCallback(
        (option: string) => {
            const existingWorkLocations = [...selectedWorkLocations];
            const index = existingWorkLocations.indexOf(option);
            if (index > -1) {
                existingWorkLocations.splice(index, 1);
            } else {
                existingWorkLocations.push(option);
                removeValidationError(ERROR_TYPES.WORK_LOCATIONS);
            }

            setSelectedWorkLocations(existingWorkLocations);
        },
        [selectedWorkLocations, removeValidationError]
    );

    const isOfficeOrHybrid = useCallback(
        (location: string) => [OFFICE_KEY, HYBRID_KEY].includes(location),
        []
    );

    const isCitySelectionsValid = useMemo(() => {
        const needsCitySelection =
            selectedWorkLocations.includes(HYBRID_KEY) ||
            selectedWorkLocations.includes(OFFICE_KEY);

        if (!needsCitySelection) {
            return true;
        }

        return selectedCities?.length !== 0;
    }, [selectedWorkLocations, selectedCities]);

    const showCitiesSelection = useMemo(() => {
        return selectedWorkLocations.some(isOfficeOrHybrid);
    }, [selectedWorkLocations, isOfficeOrHybrid]);

    return {
        selectedWorkLocations,
        setSelectedWorkLocations,
        handleWorkLocationSelection,
        selectedCities,
        setSelectedCities,
        handleCitiesSelection,
        showCitiesSelection,
        isCitySelectionsValid,
        setCitiesSearchKey: setSearchKey,
        citiesQueryResults: citySearchResults,
        citiesQueryLoading: loading
    };
}
