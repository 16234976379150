import styled from 'styled-components';

import {DEFAULT_HEIGHT} from '@/componentLibrary/components/charts/StandardTenChart/constants';
import {Caption, P2} from '@/componentLibrary/components/typography';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingSmall, SpacingXlarge, SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div<{$showAdditionalInfo: boolean}>`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    column-gap: ${({$showAdditionalInfo}) => ($showAdditionalInfo ? '68px' : 'inherit')};
    justify-content: space-between;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column;
        justify-content: start;
    }

    & > * {
        max-width: 425px;
    }

    @media only print {
        & > * {
            max-width: 350px;
        }
    }
`;
export const ChartWrapper = styled.div`
    margin-top: ${SpacingSmall};
    height: ${DEFAULT_HEIGHT}px;
`;
export const LeftColumnWrapper = styled.div`
    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        width: 100%;
        margin-inline-end: auto;
        max-width: none;
    }
`;
export const DescriptionWrapper = styled(P2)`
    margin-top: ${SpacingSmall};
`;
export const AdditionalInfoWrapper = styled(Caption)`
    margin-top: ${SpacingXsmall};
`;
export const RightColumnWrapper = styled.div`
    margin-top: ${SpacingXlarge};
    height: ${DEFAULT_HEIGHT}px;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        width: 100%;
        max-width: none;
    }
`;
