import {Markdown} from '@/componentLibrary/components/typography/Markdown';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {personalityDataCollectionMessages} from '@/pages/PersonalityDataCollection/messages';
import {
    DescriptionTitle,
    Wrapper
} from '@/pages/PersonalityDataCollection/pages/DataCollectionForm/components/styled';

export function DataCollectionInstructions() {
    const phrases = useExtractPhraseConstants(personalityDataCollectionMessages);

    return (
        <Wrapper>
            <DescriptionTitle>{phrases.instructionsTitle}</DescriptionTitle>
            <Markdown>{phrases.instructionDescription}</Markdown>
        </Wrapper>
    );
}
