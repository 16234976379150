import {useMemo} from 'react';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {isMac} from '@/utils/getOperatingSystem';
import {isDefined} from '@/utils/typeGuards/isDefined';

import messages, {MacShortcutMessages, WindowsShortcutMessages} from './messages';

export function useTextEditorMenuLogic() {
    const formattingPhrases = useExtractPhraseConstants(messages);
    type PhraseKey = keyof typeof formattingPhrases;
    type ShortcutKey = keyof typeof MacShortcutMessages | keyof typeof WindowsShortcutMessages;
    const shortcutMessages = isMac() ? MacShortcutMessages : WindowsShortcutMessages;
    const shortcutPhrases = useExtractPhraseConstants(shortcutMessages);

    const phrases = useMemo(() => {
        const toShortCutPhrase = (
            textEditorMenuPhrases: {[key: string]: string},
            key: string
        ) => {
            const phrase = formattingPhrases[key as PhraseKey];
            const shortcutPhrase = shortcutPhrases[key as ShortcutKey];
            const formattedShortCutPhrase = isDefined(shortcutPhrase)
                ? `(${shortcutPhrase})`
                : '';

            textEditorMenuPhrases[key as PhraseKey] = `${phrase} ${formattedShortCutPhrase}`;
            return textEditorMenuPhrases;
        };

        return Object.keys(formattingPhrases).reduce(toShortCutPhrase, {});
    }, [formattingPhrases, shortcutPhrases]);

    return phrases;
}
