import styled from 'styled-components';

import {Separator} from '@/componentLibrary/components/Separator';
import {SpacingLarge, SpacingMedium} from '@/componentLibrary/tokens/variables';

const Wrapper = styled.div`
    padding: ${SpacingMedium};
    margin-bottom: ${SpacingLarge};

    &:last-child {
        margin-bottom: 0;
    }
`;

const SectionSeparator = styled(Separator)`
    margin-top: 60px;
    margin-bottom: ${SpacingMedium};
`;

export {Wrapper, SectionSeparator};
