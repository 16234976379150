import styled from 'styled-components';

import {SpacingSmall, SpacingXxlarge} from '@/componentLibrary/tokens/variables';

export const Content = styled.div<{useFixedHeight?: boolean}>`
    overflow-y: ${props => (props.useFixedHeight ? 'hidden' : 'auto')};
    height: calc(100% - 72px);
`;

export const Header = styled.div<{
    disabled?: boolean;
    isClickable?: boolean;
    isExpanded?: boolean;
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: initial;
    margin-bottom: ${({isExpanded}) => (isExpanded ? SpacingSmall : 0)};
    height: ${SpacingXxlarge};
    cursor: ${({disabled, isClickable}) => {
        if (disabled) {
            return 'not-allowed';
        }
        return isClickable ? 'pointer' : 'default';
    }};
`;
