import {TableMain} from '@/LEGACY_COMPONENT_LIBRARY/TableMain';
import {StandardTenChartRange} from '@/componentLibrary/components/charts/StandardTenChartRange';
import {Caption} from '@/componentLibrary/components/typography/Captions';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {greyColorTokens, secondaryColorTokens} from '@/componentLibrary/tokens/legacyColors';
import messages from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateRoleFitPage/components/AssessmentResults/components/TestResults/messages';
import {capAndRoundStenValue} from '@/utils/misc';
import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {HeaderText, ResultWrapper, StenChartWrapper} from '../commonUiComponents';

export function LogicTestResults({
    testProfile,
    jobApplication,
    calculateProfileLabel,
    loading,
    oldLogicTest
}) {
    const logicTestTableColumns = useMemo(() => {
        return [
            {
                accessor: 'name',
                filterable: false,
                sortable: true,
                Cell: () => (
                    <P2>
                        <FormattedMessage {...messages.logicTest} />
                    </P2>
                ),
                Header: (
                    <HeaderText>
                        <FormattedMessage {...messages.logicAssessment} />
                    </HeaderText>
                )
            },
            {
                accessor: 'name',
                filterable: false,
                sortable: false,
                Cell: ({original}) => (
                    <StenChartWrapper>
                        <StandardTenChartRange
                            height={28}
                            width={220}
                            lowerBound={original.rangeStart}
                            upperBound={original.rangeEnd}
                            selectedValue={original.result}
                            rangeColor={opacity => secondaryColorTokens.brown000(opacity)}
                        />
                    </StenChartWrapper>
                ),
                Header: (
                    <HeaderText>
                        <FormattedMessage {...messages.assessmentProfileRange} />
                    </HeaderText>
                )
            },
            {
                accessor: 'result',
                filterable: false,
                sortable: true,
                Cell: ({original}) => (
                    <ResultWrapper>
                        {original.result ? (
                            <>
                                <P2 color={greyColorTokens.grey000()}>{original.result}</P2>
                                <Caption color={greyColorTokens.grey100()}>
                                    <FormattedMessage
                                        {...calculateProfileLabel(
                                            original.rangeStart,
                                            original.rangeEnd,
                                            original.result
                                        )}
                                    />
                                </Caption>
                            </>
                        ) : (
                            <P2 color={greyColorTokens.grey500()}>
                                <FormattedMessage {...messages.noResultsYet} />
                            </P2>
                        )}
                    </ResultWrapper>
                ),
                Header: (
                    <HeaderText>
                        <FormattedMessage {...messages.result} />
                    </HeaderText>
                )
            }
        ];
    }, [calculateProfileLabel]);

    const logicTestData = useMemo(() => {
        if (loading) {
            return [];
        }

        const hasCompletedTest =
            (oldLogicTest && jobApplication.user.latestLogicTest) ||
            (!oldLogicTest && jobApplication.user.logicalAbilityEstimate);
        const rangeStart =
            testProfile && testProfile.logicCriterion
                ? capAndRoundStenValue(testProfile.logicCriterion.lower)
                : null;
        const rangeEnd =
            testProfile && testProfile.logicCriterion
                ? capAndRoundStenValue(testProfile.logicCriterion.upper)
                : null;

        if (!hasCompletedTest) {
            return [
                {
                    result: null,
                    rangeStart,
                    rangeEnd
                }
            ];
        }

        if (oldLogicTest) {
            return [
                {
                    result: capAndRoundStenValue(
                        jobApplication.user.latestLogicTest.standardScore
                    ),
                    rangeStart,
                    rangeEnd
                }
            ];
        }

        const standardScore = capAndRoundStenValue(
            jobApplication.user.logicalAbilityEstimate.mu
        );
        return [
            {
                result: standardScore,
                rangeStart,
                rangeEnd
            }
        ];
    }, [jobApplication, loading, oldLogicTest, testProfile]);

    const tableProps = {
        resizable: false
    };

    return (
        <TableMain
            loading={loading}
            data={logicTestData}
            columns={logicTestTableColumns}
            tableProps={tableProps}
            rowHeight={56}
        />
    );
}

LogicTestResults.propTypes = {
    calculateProfileLabel: PropTypes.func.isRequired,
    oldLogicTest: PropTypes.bool,
    testProfile: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        facetCriteria: PropTypes.array,
        logicCriterion: PropTypes.object,
        personalityWeight: PropTypes.number,
        logicWeight: PropTypes.number
    }),
    jobApplication: PropTypes.shape({
        user: PropTypes.shape({
            id: PropTypes.number,
            personalityIrtResult: PropTypes.object,
            latestLogicTest: PropTypes.object,
            logicalAbilityEstimate: PropTypes.object
        }),
        roleFit: PropTypes.shape({
            score: PropTypes.number,
            interviewScore: PropTypes.number,
            testProfileScore: PropTypes.number,
            testProfilePersonalityScore: PropTypes.number,
            testProfileLogicScore: PropTypes.number,
            personalityDetails: PropTypes.arrayOf(
                PropTypes.shape({
                    facetName: PropTypes.string,
                    score: PropTypes.number,
                    label: PropTypes.string
                })
            ),
            logicDetails: PropTypes.object
        })
    }),
    loading: PropTypes.bool
};
