import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_INTERVIEW_WITH_CONTENT_ITEM_SCORES_FOR_JOB_APPLICATION} from '@/api/assessment/interviews/queries';
import {GetInterviewWithContentItemScoresForJobApplicationQuery} from '@/api/types/__generated__/graphql';
import {DeepPick} from '@/types/generic.types';
import {isArrayWithItems} from '@/utils/typeGuards/isArrayWithItems';
import {isDefined} from '@/utils/typeGuards/isDefined';

export type InterviewContentItem = DeepPick<
    GetInterviewWithContentItemScoresForJobApplicationQuery,
    'interview.contentItems'
>[0];

export type PublishedCompetencyScore = DeepPick<InterviewContentItem, 'publishedScores'>[0];

export function useInterviewContentItemScoresForJobApplication(
    interviewId: number,
    jobApplicationId: number
) {
    const variables = useMemo(() => {
        return {interviewId, jobApplicationId};
    }, [interviewId, jobApplicationId]);

    const {data, loading, error, refetch} = useQuery(
        GET_INTERVIEW_WITH_CONTENT_ITEM_SCORES_FOR_JOB_APPLICATION,
        {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first'
        }
    );

    const interviewContentItems = useMemo(() => {
        if (isArrayWithItems(data?.interview?.contentItems)) {
            return data.interview.contentItems;
        }

        return [];
    }, [data]);

    const noScorecardsCompleted = interviewContentItems.every(item => {
        return !isDefined(item.aggregatedScore?.competencyScoreAverage);
    });

    return {
        interviewContentItems,
        noScorecardsCompleted,
        loading,
        error,
        refetch
    };
}
