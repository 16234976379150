import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography';
import {SpacingXlarge} from '@/componentLibrary/tokens/variables';

const Wrapper = styled(P2).attrs({as: 'div'})`
    margin: 20px 0;
`;

const PersonalityReportWrapper = styled(P2).attrs({as: 'div'})`
    margin-top: 40px;
`;

const LeadershipOverviewWrapper = styled.div`
    max-width: 920px;
    margin-top: ${SpacingXlarge};
`;

export {Wrapper, PersonalityReportWrapper, LeadershipOverviewWrapper};
