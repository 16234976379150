import {useIntl} from 'react-intl';

import {H4} from '@/componentLibrary/components/typography/Headings';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';

import messages from './messages';
import {DescriptionWrapper} from './styled';

export function InvalidToken() {
    const intl = useIntl();
    const title = intl.formatMessage(messages.title);
    const description = intl.formatMessage(messages.description);

    return (
        <div>
            <H4>{title}</H4>
            <DescriptionWrapper>
                <P1>{description}</P1>
            </DescriptionWrapper>
        </div>
    );
}
