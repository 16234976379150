import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {FacetName} from '@/api/types/__generated__/graphql';
import {
    MessageStructure,
    PERSONALITY_FACET_MESSAGES
} from '@/componentLibrary/blocks/testReports/PersonalityTest/components/FactorFacets/constants';
import {PERSONALITY_IRT_FACET_COLORS} from '@/componentLibrary/blocks/testReports/PersonalityTest/constants';
import {BulletList} from '@/componentLibrary/components/BulletList';
import {Overline} from '@/componentLibrary/components/typography/Overline';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {
    ColorBaseGrey200,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';
import {TestProfileLegendProps} from '@/pages/Organization/pages/Assessment/components/TestProfileGraph/types';
import {roundValue} from '@/utils/misc';

import {WEIGHTS, convertWeightToValue} from '../../../../utils/weights';
import {TEST_PROFILE_COLORS} from '../../constants';
import messages from '../../messages';

export function TestProfileLegend({
    includePersonalityTest,
    includeLogicTest,
    testProfile
}: TestProfileLegendProps) {
    const intl = useIntl();

    const calculateLogicWeight = () => {
        if (!includeLogicTest) {
            return 0;
        }

        if (!includePersonalityTest) {
            return 1;
        }

        return testProfile?.logicWeight || 0;
    };

    const calculatePersonalityWeight = () => {
        if (!includeLogicTest) {
            return 1;
        }

        if (!includePersonalityTest) {
            return 0;
        }

        return testProfile?.personalityWeight || 0;
    };

    function renderPercentage(value: number) {
        return `${roundValue(value * 100, 1)} %`;
    }

    function getFacetName(facet?: FacetName | null) {
        const facetMessages = facet
            ? (PERSONALITY_FACET_MESSAGES as {[facet: string]: MessageStructure})[facet]
            : null;

        if (!facetMessages) {
            return '';
        }

        return `${intl.formatMessage(facetMessages.factor)}: ${intl.formatMessage(
            facetMessages.title
        )}`;
    }

    function getFacetColor(facet?: FacetName | null) {
        const defaultColor = ColorBaseGrey200;
        const facetColor = facet
            ? (PERSONALITY_IRT_FACET_COLORS as {[facet: string]: string})[facet]
            : null;
        return facetColor ? facetColor : defaultColor;
    }

    function getWeightPercentage(weight: string, sumOfWeights?: number) {
        const weightValue = convertWeightToValue(weight as keyof typeof WEIGHTS);

        return sumOfWeights ? weightValue / sumOfWeights : weightValue;
    }

    function renderPersonalityFacets() {
        const sumOfWeights = testProfile?.facetCriteria?.reduce(
            (acc, item) => acc + convertWeightToValue(item.weight as keyof typeof WEIGHTS),
            0
        );

        return (
            <BulletListWrapper>
                {testProfile?.facetCriteria?.map(facetCriterion => (
                    <BulletList
                        key={facetCriterion?.facet}
                        markerColor={getFacetColor(facetCriterion.facet as FacetName)}
                    >
                        <BulletContent>
                            <BulletContentText>
                                {getFacetName(facetCriterion.facet as FacetName)}
                            </BulletContentText>
                            <BulletContentPercentage>
                                {renderPercentage(
                                    calculatePersonalityWeight() *
                                        getWeightPercentage(
                                            facetCriterion.weight,
                                            sumOfWeights
                                        )
                                )}
                            </BulletContentPercentage>
                        </BulletContent>
                    </BulletList>
                ))}
            </BulletListWrapper>
        );
    }

    return (
        <Wrapper>
            {includeLogicTest ? (
                <div>
                    <BulletListTitleWrapper>
                        <FormattedMessage {...messages.logicTest} />
                    </BulletListTitleWrapper>

                    <BulletListWrapper>
                        <BulletList markerColor={TEST_PROFILE_COLORS.LOGIC_TEST}>
                            <BulletContent>
                                <BulletContentText>
                                    <FormattedMessage {...messages.logicalAbility} />
                                </BulletContentText>
                                <BulletContentPercentage>
                                    {renderPercentage(calculateLogicWeight())}
                                </BulletContentPercentage>
                            </BulletContent>
                        </BulletList>
                    </BulletListWrapper>
                </div>
            ) : null}
            {includePersonalityTest ? (
                <div>
                    <BulletListTitleWrapper>
                        <FormattedMessage {...messages.personalityTest} />
                    </BulletListTitleWrapper>
                    {renderPersonalityFacets()}
                </div>
            ) : null}
        </Wrapper>
    );
}

const Wrapper = styled(P2).attrs({as: 'div'})`
    flex-grow: 1;
    flex-basis: 0;
`;

const BulletContent = styled.div`
    display: flex;
    align-items: center;
`;

const BulletContentText = styled.span`
    flex: 1 1;
`;

const BulletContentPercentage = styled.span`
    flex: 0 0 60px;
    font-weight: bold;
    text-align: right;
`;

const BulletListWrapper = styled.div`
    margin: ${SpacingXxsmall} 0;
`;

const BulletListTitleWrapper = styled(Overline)`
    margin-top: ${SpacingXsmall};
`;
