import styled from 'styled-components';

import {H4} from '@/componentLibrary/components/typography';
import {SpacingSmall, SpacingXxlarge} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingSmall};
    margin-block-end: ${SpacingXxlarge};
    margin-inline: auto;
    max-width: 800px;
`;

export const ScoreResultsTitle = styled(H4)`
    margin-block-start: ${SpacingXxlarge};
`;
