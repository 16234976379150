import {Section} from '@/componentLibrary/components/layout/Section';
import {ScorecardResults} from '@/pages/Organization/pages/Assessment/components/ScorecardResults';

import {InterviewDetails} from '../InterviewDetails';
import {useInterviewRatingsLogic} from './logic';
import {SectionSeparator, Wrapper} from './styled';
import {Interview, InterviewRatingsProps} from './types';

export const InterviewRatings = ({
    userId,
    jobPositionId,
    jobApplication
}: InterviewRatingsProps) => {
    const {
        jobPositionError,
        refetchJobPosition,
        interviews,
        interviewRequestsError,
        refetchInterviewRequests,
        findInterviewRequestsByInterviewId,
        display,
        jobApplicationIsWithdrawn
    } = useInterviewRatingsLogic(jobPositionId, userId);

    if (jobPositionError) {
        return <Section error={{reload: refetchJobPosition}} />;
    }

    if (interviewRequestsError) {
        return <Section error={{reload: refetchInterviewRequests}} />;
    }

    return display && jobApplication ? (
        <>
            {interviews.map((interview: Interview) => (
                <Wrapper key={`interview-${interview.id}-ratings`}>
                    <InterviewDetails
                        jobPositionId={jobPositionId}
                        jobApplication={jobApplication}
                        interviewRequests={findInterviewRequestsByInterviewId(interview.id)}
                        interview={interview}
                        onChange={refetchInterviewRequests}
                    />
                    <ScorecardResults
                        jobApplicationId={jobApplication.id}
                        interviewId={interview.id}
                        interviewRequests={findInterviewRequestsByInterviewId(interview.id)}
                        jobApplicationIsWithdrawn={jobApplicationIsWithdrawn}
                    />
                    <SectionSeparator />
                </Wrapper>
            ))}
        </>
    ) : null;
};
