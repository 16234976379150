import {TabLinkActiveSeparator} from '@/componentLibrary/components/Tabs/TabBar/styled';
import {Link} from '@/componentLibrary/components/links';
import {BodyP2} from '@/componentLibrary/tokens/typography';
import {
    ColorBorderFocus,
    ColorFgDefault,
    ColorFgSubtle,
    SpacingLarge,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const TabLink = styled(Link)`
    ${BodyP2}
    color: ${ColorFgSubtle};
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    &:hover {
        color: ${ColorFgDefault};
        text-decoration: none;
    }

    &.active {
        color: ${ColorBorderFocus};
    }
`;

export const TabItem = styled.li`
    display: flex;
    height: ${SpacingXxlarge};
    position: relative;
    align-items: center;

    &:last-child > a {
        margin-inline-end: ${SpacingLarge};
    }

    ${TabLink}.active + ${TabLinkActiveSeparator} {
        display: block;
    }

    &:last-child ${TabLink}.active + ${TabLinkActiveSeparator} {
        width: calc(100% - ${SpacingLarge});
    }
`;
