import {useCallback, useEffect, useMemo} from 'react';

import {useCurrentEmployment} from '@/api/organizations/useCurrentEmployment';
import {useOrganization} from '@/api/organizations/useOrganization';
import {useAuthenticatedUser} from '@/api/users/useAuthenticatedUser';
import {initClientSideSegmentAnalytics} from '@/services/segment/utils';

import {logger} from '../logrocket';
import {options} from './constants';

export function useTrackEvent() {
    const {organization} = useOrganization();
    const {user} = useAuthenticatedUser();
    const {employment} = useCurrentEmployment();
    const userId = useMemo(() => {
        if (!user || !user.userId) {
            return '';
        }
        return user.userId.toString();
    }, [user]);

    const organizationId = useMemo(() => {
        if (!organization || !organization.id) {
            return '';
        }
        return organization.id.toString();
    }, [organization]);

    useEffect(() => {
        initClientSideSegmentAnalytics();
    }, []);

    const isImpersonating = useMemo(() => {
        if (!userId || !employment || !employment.user) {
            return false;
        }

        return employment.user.id.toString() !== userId.toString();
    }, [userId, employment]);

    return useCallback(
        (eventName, customProperties = {}) => {
            if (!userId) {
                logger.info(`Skip track event ${eventName} since no userId`);
                return;
            }

            if (isImpersonating) {
                logger.info(`Skip track event ${eventName} since impersonating`);
                return;
            }

            // https://segment.com/docs/spec/page/#properties
            const properties = {
                userId,
                organizationId,
                accountId: organizationId,
                ...customProperties
            };

            window.analytics.track(eventName, properties, options);
        },
        [userId, organizationId, isImpersonating]
    );
}
