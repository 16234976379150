import {defineMessages} from 'react-intl';

export default defineMessages({
    formHeader: {
        id: 'app.components.buttons.oktaButton'
    },
    emailInputFieldLabel: {
        id: 'app.oktaLoginPage.emailInputFieldLabel'
    },
    emailInputFieldError: {
        id: 'app.oktaLoginPage.emailInputFieldError'
    },
    genericError: {
        id: 'app.oktaLoginPage.genericError'
    }
});
