import {useCallback, useState} from 'react';

export function useRevokeAccessFromOrganizationModalLogic() {
    const [revokeFromOrganization, setRevokeFromOrganization] = useState<number | null>(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = useCallback(organizationId => {
        setRevokeFromOrganization(organizationId);
        setModalIsOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setRevokeFromOrganization(null);
        setModalIsOpen(false);
    }, []);

    return {revokeFromOrganization, modalIsOpen, openModal, closeModal};
}
