import {defineMessages} from 'react-intl';

export default defineMessages({
    originalTest: {
        id: 'app.logicTestReport.logicTestValidation.logicTestComparision.originalTest'
    },
    validationTest: {
        id: 'app.logicTestReport.logicTestValidation.logicTestComparision.validationTest'
    }
});
