import {useEffect, useState} from 'react';

import {getConfig} from '@/config';
import {logger} from '@/services/logrocket';

export const Recaptcha = () => {
    const isInitialized = useRecaptcha();

    useEffect(() => {
        if (!isInitialized) {
            return;
        }
        logger.log('Loaded recaptcha ok ', getConfig().RECAPTCHA_SITE_KEY);
    }, [isInitialized]);

    return null;
};

function useRecaptcha() {
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        (function () {
            const parent = document.getElementsByTagName('script')[0].parentNode;
            if (parent === null) {
                return;
            }

            const script = document.createElement('script');

            script.async = true;
            script.src = `https://www.google.com/recaptcha/api.js?render=${
                getConfig().RECAPTCHA_SITE_KEY
            }`;
            script.onload = () => {
                setInitialized(true);
            };
            script.onerror = () => {
                logger.error('Error loading recaptcha');
            };
            parent.appendChild(script);
        })();
    }, []);
    return initialized;
}
