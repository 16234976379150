import {TransformedValues} from '../../types';
import {personalityResultStrengthMessages} from './messages';

export type TestResult = {
    a: number;
    c: number;
    e: number;
    es: number;
    o: number;
};

export type StrengthsAndChallengesProps = {
    personalityTestResult: Partial<TransformedValues>;
};

export type PersonalityResultStrengthMessageKeys =
    keyof typeof personalityResultStrengthMessages;

export function getLabelFromResultValue(value: number) {
    if (value >= 7) {
        return 'HIGH';
    }
    if (value <= 4) {
        return 'LOW';
    }

    return 'AVERAGE';
}
