import {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import messages from '../../messages';
import {Warning} from './components/Warning';

type FormWarningProps = {
    triedToSubmit: boolean;
    warningIsVisible: boolean;
    hasEmailError: boolean;
    hasCountryBlockedError: boolean;
    onClose: () => void;
};

export function FormWarning({
    triedToSubmit,
    warningIsVisible,
    hasEmailError,
    hasCountryBlockedError,
    onClose
}: FormWarningProps) {
    const shouldDisplayWarning = useMemo(() => {
        if (!triedToSubmit) {
            // Require one submit attempt before showing any warnings.
            return false;
        }

        return warningIsVisible || hasEmailError || hasCountryBlockedError;
    }, [triedToSubmit, warningIsVisible, hasEmailError, hasCountryBlockedError]);

    const intl = useIntl();
    const getWarningMessage = useCallback(() => {
        if (hasEmailError) {
            return intl.formatMessage(messages.warningInvalidEmail);
        }

        if (hasCountryBlockedError) {
            return intl.formatMessage(messages.warningBlockedCountry);
        }
        if (warningIsVisible) {
            return intl.formatMessage(messages.warning);
        }

        return null;
    }, [intl, hasEmailError, hasCountryBlockedError, warningIsVisible]);

    return (
        <Warning visible={shouldDisplayWarning} close={onClose}>
            {getWarningMessage()}
        </Warning>
    );
}
