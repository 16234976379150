import PropTypes from 'prop-types';
import {Fragment} from 'react';
import {FormattedMessage} from 'react-intl';

import {DestructiveButton} from '@/componentLibrary/components/buttons/DestructiveButton';

import {AnonymizeJobApplicationsForUserInOrganizationModal} from './components/AnonymizeUserModal';
import {useAnonymizeUserModalLogic} from './logic';
import messages from './messages';

export function AnonymizeUser({userId, organizationId, backUrl}) {
    const {modalIsOpen, openModal, closeModal} = useAnonymizeUserModalLogic();

    return (
        <Fragment>
            <DestructiveButton onClick={openModal}>
                <FormattedMessage {...messages.anonymizeUser} />
            </DestructiveButton>

            {modalIsOpen ? (
                <AnonymizeJobApplicationsForUserInOrganizationModal
                    userId={userId}
                    organizationId={organizationId}
                    closeModal={closeModal}
                    backUrl={backUrl}
                />
            ) : null}
        </Fragment>
    );
}

AnonymizeUser.propTypes = {
    userId: PropTypes.number.isRequired,
    organizationId: PropTypes.number.isRequired,
    backUrl: PropTypes.string.isRequired
};
