import qs from 'qs';

export function extractJobPositionIdFromParams(params: {
    jobPositionId?: string | null;
}) {
    const jobPositionId = parseNumberIdFromString(params.jobPositionId);
    if (jobPositionId) {
        return {jobPositionId};
    }
    return null;
}

export function extractInterviewTemplateIdFromParams(params: {
    interviewTemplateId?: string | null;
}) {
    const interviewTemplateId = trimString(params.interviewTemplateId);
    if (interviewTemplateId) {
        return {interviewTemplateId};
    }
    return null;
}

export function extractUserIdFromParams(params: {userId?: string | null}) {
    const userId = parseNumberIdFromString(params.userId);
    if (userId) {
        return {userId};
    }
    return null;
}

export function extractInterviewIdFromParams(params: {
    interviewId?: string | null;
}) {
    const interviewId = parseNumberIdFromString(params.interviewId);
    if (interviewId) {
        return {interviewId};
    }
    return null;
}

export function extractInterviewRequestIdFromParams(params: {
    interviewRequestId?: string | null;
}) {
    const interviewRequestId = parseNumberIdFromString(params.interviewRequestId);
    if (interviewRequestId) {
        return {interviewRequestId};
    }
    return null;
}

export function extractTestProfileIdFromParams(params: {
    testProfileId?: string | null;
}) {
    const testProfileId = trimString(params.testProfileId);
    if (testProfileId) {
        return {testProfileId};
    }
    return null;
}

export function extractChallengeIdFromParams(params: {
    challengeId?: string | null;
}) {
    const challengeId = trimString(params.challengeId);
    if (challengeId) {
        return {challengeId};
    }
    return null;
}

export function parseNumberIdFromString(value_string?: string | null): number | null {
    if (value_string === undefined || value_string === null) {
        return null;
    }
    const converted_value = parseInt(value_string, 10);

    if (isNaN(converted_value)) {
        return null;
    }

    return converted_value;
}

function trimString(value_string?: string | null): string | null {
    if (value_string === undefined || value_string === null) {
        return null;
    }

    return value_string.trim();
}

export function parseRedirectPathFromQueryParams(search: string) {
    const params = qs.parse(search, {ignoreQueryPrefix: true});
    return params.redirect as string;
}
