import {useIntl} from 'react-intl';

import {JobApplicationStage} from '@/api/types/__generated__/graphql';
import {Tag} from '@/componentLibrary/components/Tag';
import {JOB_APPLICATION_STAGE_VARIANTS} from '@/pages/Organization/pages/Assessment/pages/JobPosition/constants';
import {candidateStageMessages} from '@/pages/Organization/pages/Assessment/pages/JobPosition/messages';

export function CurrentStageBadge({currentStage}: {currentStage: JobApplicationStage}) {
    const intl = useIntl();

    return (
        <Tag $variant={JOB_APPLICATION_STAGE_VARIANTS[currentStage]} $selectable>
            {intl.formatMessage(candidateStageMessages[currentStage])}
        </Tag>
    );
}
