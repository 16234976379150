import {InterviewContentRatingType, ItemType} from '@/api/types/__generated__/graphql';
import {mean} from 'mathjs';
import {useCallback, useContext} from 'react';
import {useIntl} from 'react-intl';

import {
    InterviewContentItem,
    useTranslateInterviewContentItem
} from '@/pages/Organization/hooks/useTranslatedInterviewContent';
import {toInterviewKey} from '@/pages/common/roleFit/utils';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {CandidateRoleFitContext} from '../../../../context';
import {roundPercentageValue} from '../../../ScoreCards/utils';
import messages from '../../messages';
import {CombinedInterviewData} from './types';

export function useCombineInterviewData(interviewId = '-1'): CombinedInterviewData | null {
    const intl = useIntl();
    const {jobApplication, jobPosition} = useContext(CandidateRoleFitContext);
    const interviewIdAsInt = parseInt(interviewId, 10);
    const getAssessmentScoreForInterview = useCallback(() => {
        const key = toInterviewKey(interviewIdAsInt);
        return jobApplication?.roleFitV2?.assessmentScores?.find(
            ({assessmentMethodKey}) => assessmentMethodKey === key
        );
    }, [interviewIdAsInt, jobApplication?.roleFitV2?.assessmentScores]);

    const interviewContentItems: {[key: number]: InterviewContentItem[]} = {};
    const translateContentItem = useTranslateInterviewContentItem<InterviewContentItem>();
    jobPosition.interviews?.forEach(interview => {
        interviewContentItems[interview.id] = interview.contentItems
            .filter(
                contentItem =>
                    contentItem.type === ItemType.CRITERION &&
                    contentItem.ratingType !== InterviewContentRatingType.NO_RATING
            )
            .map(translateContentItem);
    });

    const findJobPositionInterview = useCallback(() => {
        return jobPosition.interviews?.find(({id}) => id === interviewIdAsInt);
    }, [interviewIdAsInt, jobPosition.interviews]);

    const findInterviewRequestsByInterviewId = useCallback(() => {
        return jobApplication?.interviewRequests?.filter(
            ({interviewId: _interviewId}) => _interviewId === interviewIdAsInt
        );
    }, [interviewIdAsInt, jobApplication?.interviewRequests]);

    const getContentItemScoreToDisplay = useCallback(
        (contentItemId: string) => {
            const interviewRequests = findInterviewRequestsByInterviewId();
            const foundPublishedScores = interviewRequests?.map(
                ({publishedCompetencyScores}) => {
                    return publishedCompetencyScores.find(
                        ({interviewContentItemId}) => interviewContentItemId === contentItemId
                    );
                }
            );
            const definedPublishedScores = foundPublishedScores?.filter(isDefined) ?? [];
            const scoresWithRating = definedPublishedScores.filter(
                ({score, isSkipped}) => score !== null && !isSkipped
            );
            const noRatings = scoresWithRating.length === 0;
            if (noRatings) {
                return null;
            }

            return mean(scoresWithRating.map(({score}) => score ?? 0));
        },
        [findInterviewRequestsByInterviewId]
    );

    function combineContentItemData(
        hasResults: boolean,
        contentItems: InterviewContentItem[]
    ) {
        if (!hasResults) {
            return [];
        }

        return contentItems.map(({id, name}) => {
            return {id, name, score: getContentItemScoreToDisplay(id)};
        });
    }
    const filteredInterviewContentItems: InterviewContentItem[] =
        interviewContentItems[interviewIdAsInt];
    if (!filteredInterviewContentItems) {
        return null;
    }
    const jobPositionInterview = findJobPositionInterview();
    const assessmentScore = getAssessmentScoreForInterview();
    const hasScore = isDefined(assessmentScore?.score);
    const hasResults = isDefined(assessmentScore) && !assessmentScore.missingResults;
    const score =
        hasScore && hasResults
            ? `${roundPercentageValue(assessmentScore?.score)}%`
            : intl.formatMessage(messages.noData);
    return {
        id: interviewIdAsInt,
        title: jobPositionInterview?.title ?? '',
        score,
        contentItems: combineContentItemData(hasResults, filteredInterviewContentItems)
    };
}
