import {BulletList} from '@/componentLibrary/blocks/testReports/PersonalityTest/components/BulletList';
import {Tag} from '@/componentLibrary/components/Tag';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {Caption, H3, H4, P2} from '@/componentLibrary/components/typography';
import {ColorFgMuted, ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {JobInformationModal} from '@/pages/User/components/JobRecommendations/components/JobInformationModal';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {useJobPositionLogic, useJobPositionsLogic} from './hooks';
import {CardContent, CardWrapper, CardsWrapper, Wrapper} from './styled';
import {GetMyJobApplications_JobApplication, Props} from './types';

export function JobPositions({jobApplications}: Props) {
    const {title} = useJobPositionsLogic({jobApplications});
    const toJobPosition = (jobApplication: GetMyJobApplications_JobApplication) => (
        <JobPosition key={jobApplication.id} jobApplication={jobApplication} />
    );
    return (
        <Wrapper>
            <H3>{title}</H3>
            <CardsWrapper>{jobApplications.map(toJobPosition)}</CardsWrapper>
        </Wrapper>
    );
}

function JobPosition({jobApplication}: {jobApplication: GetMyJobApplications_JobApplication}) {
    const {
        jobPosition,
        jobRelevanceInformation,
        phrases,
        isMarketplaceJob,
        showModal,
        toggleModal,
        assessmentMethodsNames
    } = useJobPositionLogic({jobApplication});
    const TagToShow = (
        <Tag>
            {isMarketplaceJob
                ? phrases.applied_via_marketplace
                : phrases.applied_via_invitation}
        </Tag>
    );
    const Action =
        isMarketplaceJob && isDefined(jobRelevanceInformation) ? (
            <SecondaryButton onClick={toggleModal}>{phrases.action}</SecondaryButton>
        ) : (
            <Caption color={ColorFgSubtle}>{phrases.non_marketplace_job_description}</Caption>
        );

    return (
        <>
            <CardWrapper isActive>
                <CardContent>
                    <H4>{jobApplication.jobPosition?.name}</H4>
                    <div>
                        <P2 color={ColorFgMuted}>{phrases.assessments}</P2>
                        <BulletList
                            color={ColorFgMuted}
                            items={assessmentMethodsNames}
                            gap="0px"
                        />
                    </div>
                    <div>{TagToShow}</div>
                </CardContent>
                {Action}
            </CardWrapper>
            {showModal && isDefined(jobRelevanceInformation) && (
                <JobInformationModal
                    jobRelevanceInformation={jobRelevanceInformation}
                    close={toggleModal}
                    jobPositionName={jobPosition?.name ?? ''}
                    organizationName={jobPosition?.organization.name ?? ''}
                    organizationLogo={jobPosition?.organization.logo ?? ''}
                />
            )}
        </>
    );
}
