import {logger} from '@/services/logrocket';

export function getAppJourneyObject(appJourney: string | null) {
    let appJourneyObject = null;
    try {
        if (typeof appJourney === 'string') {
            appJourneyObject = JSON.parse(appJourney);
        }
    } catch (_e) {
        logger.info('App Journey is not a valid JSON, skipped parsing');
    }
    return appJourneyObject;
}

export function getAppJourneyString(appJourney: Record<string, unknown> | null) {
    let appJourneyString = null;
    try {
        appJourneyString = JSON.stringify(appJourney);
    } catch (e) {
        logger.info("Couldn't stringify appJourney, make sure you pass an object.", e);
    }
    return appJourneyString;
}
