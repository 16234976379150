import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {H3, P2} from '@/componentLibrary/components/typography';
import {SpacingLarge, SpacingSmall} from '@/componentLibrary/tokens/variables';
import messages from '@/pages/UpgradePlan/components/UpgradePlan/messages';

type UpgradeHeaderProps = {
    email: string;
};
export function UpgradeHeader(props: UpgradeHeaderProps) {
    return (
        <Header>
            <H3 style={{paddingBottom: SpacingSmall}}>
                <FormattedMessage {...messages.upgradePlanHeader} />
            </H3>
            <P2>
                <FormattedMessage {...messages.salesReachOut} values={{email: props.email}} />
            </P2>
        </Header>
    );
}

const Header = styled.div`
    width: 100%;
    padding: ${SpacingLarge};
`;
