import {Wrapper} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/components/FormNavigation/styled';
import {FormNavigationProps} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/components/FormNavigation/types';

export const FormNavigation = ({
    children,
    onClick,
    isLoading = false,
    isHidden = false,
    isDisabled = false
}: FormNavigationProps) => (
    <Wrapper
        isHidden={isHidden}
        onClick={isLoading || isDisabled ? undefined : onClick}
        isLoading={isLoading}
        isDisabled={isDisabled}
        data-testid={'form-navigation'}
    >
        {children}
    </Wrapper>
);
