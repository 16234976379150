import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingMedium} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    margin: 84px 0 240px;
    padding: 0 ${SpacingMedium};
    width: 100%;
    max-width: 992px;

    @media only screen and (max-width: 1074px) {
        padding: ${SpacingMedium};
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        max-width: 488px;
        margin: 84px auto 240px;
    }

    @media print {
        position: static;
        padding: 0;
        max-width: none;
        zoom: 90%;
    }

    @page {
        size: a4;
        margin: ${SpacingMedium};
    }
`;
