import {useState} from 'react';

export function useInterestedInLeadershipPositionsLogic() {
    const [interestedInLeadershipPositions, setInterestedInLeadershipPositions] =
        useState<boolean>(false);

    return {
        interestedInLeadershipPositions,
        setInterestedInLeadershipPositions
    };
}
