import styled from 'styled-components';

import {SpacingXxxsmall} from '@/componentLibrary/tokens/variables';

const Wrapper = styled.div<{height: number}>`
    display: flex;
    gap: ${SpacingXxxsmall};
    height: ${({height}) => `${height}px`};
    width: 100%;
`;

export {Wrapper};
