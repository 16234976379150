import {defineMessages} from 'react-intl';

export default defineMessages({
    gitHubLink: {
        id: 'app.assessment.codingTest.gitHubLink'
    },
    authoredBy: {
        id: 'app.assessment.codingTest.authoredBy'
    },
    custom: {
        id: 'app.assessment.codingTests.codingTestsTable.custom'
    },
    edit: {
        id: 'app.assessment.codingTest.edit'
    },
    save: {
        id: 'app.assessment.codingTest.save'
    },
    cancel: {
        id: 'app.assessment.codingTest.cancel'
    },
    profile: {
        id: 'app.assessment.codingTest.profile'
    },
    testUpdated: {
        id: 'app.assessment.codingTest.testUpdated'
    },
    testCreated: {
        id: 'app.assessment.codingTest.testCreated'
    },
    testDuplicated: {
        id: 'app.assessment.codingTest.testDuplicated'
    },
    testUpdatedError: {
        id: 'app.assessment.codingTest.testUpdatedError'
    },
    testCreatedError: {
        id: 'app.assessment.codingTest.testCreatedError'
    },
    testDuplicatedError: {
        id: 'app.assessment.codingTest.testDuplicatedError'
    },
    basicInfoNamePlaceholder: {
        id: 'app.assessment.codingTest.basicInfo.namePlaceholder'
    },
    basicInfoTitle: {
        id: 'app.assessment.codingTest.basicInfo.title'
    },
    basicInfoSubtitle: {
        id: 'app.assessment.codingTest.basicInfo.subtitle'
    },
    basicInfoNameLabel: {
        id: 'app.assessment.codingTest.basicInfo.nameLabel'
    },
    basicInfoNameErrorEmpty: {
        id: 'app.assessment.codingTest.basicInfo.name.error.empty'
    },
    basicInfoNameErrorLetter: {
        id: 'app.assessment.codingTest.basicInfo.name.error.letter'
    },
    basicInfoProfilePlaceholder: {
        id: 'app.assessment.codingTest.basicInfo.basicInfoProfilePlaceholder'
    },
    basicInfoProfileLabel: {
        id: 'app.assessment.codingTest.basicInfo.basicInfoProfileLabel'
    },
    basicInfoProfileErrorMessage: {
        id: 'app.assessment.codingTest.basicInfo.profileErrorMessage'
    },
    labelsTitle: {
        id: 'app.assessment.codingTest.labels.title'
    },
    labelsSubtitle: {
        id: 'app.assessment.codingTest.labels.subtitle'
    },
    labelsPlaceholder: {
        id: 'app.assessment.codingTest.labels.placeholder'
    },
    labelsHelpText: {
        id: 'app.assessment.codingTest.labels.helpText'
    },
    labelsAddButton: {
        id: 'app.assessment.codingTest.labels.addButton'
    },
    scorecardTitle: {
        id: 'app.assessment.codingTest.scorecard.title'
    },
    scorecardSubtitle: {
        id: 'app.assessment.codingTest.scorecard.subtitle'
    },
    scorecardSubtitleLink: {
        id: 'app.assessment.codingTest.scorecard.subtitle.link'
    },
    scoreCardPlaceholder: {
        id: 'app.assessment.codingTest.scorecard.placeholder'
    },
    requirementsLabel: {
        id: 'app.assessment.codingTest.requirements.label'
    },
    requirementsErrorMessage: {
        id: 'app.assessment.codingTest.requirements.errorMessage'
    },
    scoringCriteriaErrorMessage: {
        id: 'app.assessment.codingTest.scoringCriteria.errorMessage'
    },
    requirementsAlertTitle: {
        id: 'app.assessment.codingTest.requirements.alert.title'
    },
    requirementAddButton: {
        id: 'app.assessment.codingTest.requirements.addButton'
    },
    emptyRequirementAdd: {
        id: 'app.assessment.codingTest.requirements.emptyRequirement.add'
    },
    emptyRequirementClick: {
        id: 'app.assessment.codingTest.requirements.emptyRequirement.click'
    },
    emptyRequirementDescriptionCustom: {
        id: 'app.assessment.codingTest.requirements.emptyRequirement.description.custom'
    },
    emptyRequirementDescriptionDefault: {
        id: 'app.assessment.codingTest.requirements.emptyRequirement.description.default'
    },
    deleteActionSubtitleUndone: {
        id: 'app.assessment.codingTest.deleteAction.subtitle.undone'
    },
    deleteActionConfirmationText: {
        id: 'app.assessment.codingTest.deleteAction.confirmationText'
    },
    deleteActionCancelText: {
        id: 'app.assessment.codingTest.deleteAction.cancelText'
    },
    effortTitle: {
        id: 'app.assessment.codingTest.effort.title'
    },
    effortSubtitle: {
        id: 'app.assessment.codingTest.effort.subtitle'
    },
    effortMin: {
        id: 'app.assessment.codingTest.codingTestTable.minute'
    },
    effortHour: {
        id: 'app.assessment.codingTest.codingTestTable.hour'
    },
    gitHubTemplateRepoTitle: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTemplateRepoTitle'
    },
    gitHubTemplateRepoSubtitle: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTemplateRepoSubtitle'
    },
    gitHubTemplateBannerTitle: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTemplateBannerTitle'
    },
    gitHubTemplateBannerText: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTemplateBannerText'
    },
    gitHubTextAreaTitle: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTextAreaTitle'
    },
    gitHubTextAreaErrorMessage: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTextAreaErrorMessage'
    },
    gitHubTextAreaPlaceholder: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTextAreaPlaceholder'
    },
    whatsIncludedTitle: {
        id: 'app.assessment.codingTest.basicInfo.whatsIncludedTitle'
    },
    createCodingTest: {
        id: 'app.assessment.codingTest.createCodingTest'
    },
    createCopyCodingTest: {
        id: 'app.assessment.codingTest.createCopyCodingTest'
    },
    duplicateCodingTest: {
        id: 'app.assessment.codingTest.duplicateCodingTest'
    },
    discardTestTitle: {
        id: 'app.assessment.codingTest.discardTest.title'
    },
    discardTestSubtitle: {
        id: 'app.assessment.codingTest.discardTest.subtitle'
    },
    discardButtonText: {
        id: 'app.assessment.codingTest.discardTest.discardButtonText'
    },
    cancelButtonText: {
        id: 'app.assessment.codingTest.discardTest.cancelButtonText'
    },
    duplicateTestSubtitle: {
        id: 'app.assessment.codingTest.duplicateTest.subtitle'
    },
    creatingCodingTest: {
        id: 'app.assessment.codingTest.creatingCodingTest'
    },
    junior: {
        id: 'app.assessment.codingTest.codingTestTable.junior'
    },
    midLevel: {
        id: 'app.assessment.codingTest.codingTestTable.mid'
    },
    senior: {
        id: 'app.assessment.codingTest.codingTestTable.senior'
    },
    scoringCriteria: {
        id: 'app.assessment.codingTest.scoringCriteria'
    },
    alertDefaultTitle: {
        id: 'app.assessment.codingTest.alert.default.title'
    },
    alertDefaultSubtitle: {
        id: 'app.assessment.codingTest.alert.default.subtitle'
    },
    gitHubTemplateDuplicateBannerTitle: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTemplateDuplicateBannerTitle'
    },
    gitHubTemplateEditBannerTitle: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTemplateEditBannerTitle'
    },
    gitHubTemplateEditBannerText: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTemplateEditBannerText'
    },
    gitHubTemplateDuplicateBannerText: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTemplateDuplicateBannerText'
    },
    duplicate: {
        id: 'app.assessment.codingTest.duplicate'
    },
    duplicatePrefix: {
        id: 'app.assessment.codingTest.duplicate.prefix'
    },
    notFoundTitle: {
        id: 'app.assessment.codingTest.notFoundTitle'
    },
    notFoundDescription: {
        id: 'app.assessment.codingTest.notFoundDescription'
    },
    automatedFunctionalTests: {
        id: 'app.assessment.codingTest.basicInfo.whatsIncluded.automatedFunctionalTests'
    },
    detailedCodeReviewScorecard: {
        id: 'app.assessment.codingTest.basicInfo.whatsIncluded.detailedCodeReviewScorecard'
    },
    projectBoilerplate: {
        id: 'app.assessment.codingTest.basicInfo.whatsIncluded.projectBoilerplate'
    },
    empty: {
        id: 'app.assessment.codingTest.empty'
    },
    assignedLabels: {
        id: 'app.assessment.codingTest.labels.assignedLabels'
    },
    textAreaHelpText: {
        id: 'app.assessment.codingTest.textAreaHelpText'
    },
    archiveCodingTest: {
        id: 'app.assessment.codingTests.archiveCodingTest'
    },
    archivedCodingTest: {
        id: 'app.assessment.codingTests.archivedCodingTest'
    },
    unarchiveCodingTest: {
        id: 'app.assessment.codingTests.unarchiveCodingTest'
    },
    archiveCodingTestSuccess: {
        id: 'app.assessment.codingTests.archiveCodingTest.success'
    },
    unarchiveCodingTestSuccess: {
        id: 'app.assessment.codingTests.unarchiveCodingTest.success'
    },
    changeStatusCodingTestWaiting: {
        id: 'app.assessment.codingTests.changeStatusCodingTest.waiting'
    },
    changeStatusCodingTestFailed: {
        id: 'app.assessment.codingTests.changeStatusCodingTest.failed'
    },
    moreCodingTest: {
        id: 'app.assessment.codingTests.moreCodingTest'
    }
});
