import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingLarge, SpacingMedium} from '@/componentLibrary/tokens/variables';

export const ChartWrapper = styled.div`
    margin: ${SpacingMedium} ${SpacingLarge};
    display: flex;
    align-items: center;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        flex-direction: column;
    }
`;
