import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {v4 as uuid} from 'uuid';

import {Tag} from '@/componentLibrary/components/Tag';
import {OrganizationAvatar} from '@/componentLibrary/components/avatars/OrganizationAvatar';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {CardBase} from '@/componentLibrary/components/cards/CardBase';
import {H4} from '@/componentLibrary/components/typography/Headings';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXxsmall} from '@/componentLibrary/tokens/variables';
import {InitialActiveRecommendationState} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation/constants';

import {SetActiveJobRecommendation} from '../../types';
import {JobRecommendationsOverviewProps} from '../JobRecommendationsOverview/types';
import messages from './messages';

const NUMBER_OF_TAGS_TO_SHOW = 3;

type Props = {
    jobRecommendation: JobRecommendationsOverviewProps['pendingRecommendations'][0];
    setActive: SetActiveJobRecommendation;
};

export function JobRecommendationItem({jobRecommendation, setActive}: Props) {
    const intl = useIntl();
    const {publicJobPosition} = jobRecommendation;

    const jobFamily = jobRecommendation?.jobRelevanceInformation?.jobFamily;
    const jobFamilySpecialties =
        jobRecommendation?.jobRelevanceInformation?.jobFamilySpecialties;
    const jobTitle = intl.formatMessage(messages.title, {
        jobName: publicJobPosition?.name,
        companyName: publicJobPosition?.organizationName
    });
    const jobFamilyKey = (speciality: string) =>
        `jobFamilies_${jobFamily}_${speciality}` as keyof typeof messages;

    const dismiss = () => {
        const recommendationState = {
            initialState: InitialActiveRecommendationState.REJECT,
            jobRecommendation: jobRecommendation
        };
        setActive(recommendationState);
    };

    const readMore = () => {
        const recommendationState = {
            initialState: InitialActiveRecommendationState.READ_MORE,
            jobRecommendation: jobRecommendation
        };
        setActive(recommendationState);
    };

    return (
        <CardWrapper isActive>
            <OrganizationAvatar
                name={publicJobPosition?.organizationName || ''}
                image={publicJobPosition?.organizationLogo || undefined}
            />
            <JobTitleWrapper>
                <H4>{jobTitle}</H4>
            </JobTitleWrapper>
            <JobSpecialityTagsWrapper>
                <JobSpecialityTags>
                    {jobFamilySpecialties?.slice(0, NUMBER_OF_TAGS_TO_SHOW).map(speciality => (
                        <Tag key={uuid()}>
                            {intl.formatMessage(messages[jobFamilyKey(speciality)])}
                        </Tag>
                    ))}
                    {jobFamilySpecialties?.length > NUMBER_OF_TAGS_TO_SHOW && (
                        <Tag key={uuid()}>
                            {
                                <FormattedMessage
                                    {...messages.moreTags}
                                    values={{
                                        moreTags:
                                            jobFamilySpecialties.length -
                                            NUMBER_OF_TAGS_TO_SHOW
                                    }}
                                />
                            }
                        </Tag>
                    )}
                </JobSpecialityTags>
            </JobSpecialityTagsWrapper>
            <RecommendationActionsWrapper>
                <SecondaryButton isFullWidth onClick={dismiss}>
                    <FormattedMessage {...messages.dismiss} />
                </SecondaryButton>

                <PrimaryButton isFullWidth onClick={readMore}>
                    <FormattedMessage {...messages.readMore} />
                </PrimaryButton>
            </RecommendationActionsWrapper>
        </CardWrapper>
    );
}

const CardWrapper = styled(CardBase)`
    display: flex;
    flex-direction: column;
    width: 320px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.small}) {
        width: 100%;
    }
`;

const JobSpecialityTags = styled.div`
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    max-height: 100px;
    gap: ${SpacingXxsmall};
`;

const JobSpecialityTagsWrapper = styled.div`
    margin: 16px 0;
`;

const RecommendationActionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.small}) {
        flex-direction: column;
    }

    margin-top: auto;
`;

const JobTitleWrapper = styled.div`
    margin-top: 16px;
`;
