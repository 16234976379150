import {useIntl} from 'react-intl';

import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {TextField} from '@/componentLibrary/components/inputs/TextField';

import {PasswordInput} from './components/PasswordInput';
import {useEmailPasswordFormLogic} from './logic';
import messages from './messages';
import {NameFormField, NamesWrapper} from './styled';
import {Props} from './types';

export function EmailPasswordForm(props: Props) {
    const intl = useIntl();
    const {defaultFirstName, defaultLastName, email, onSubmit, loading} = props;

    const {
        firstName,
        lastName,
        password,
        firstNameError,
        lastNameError,
        passwordError,
        handleChangeFirstName,
        handleChangeLastName,
        handleChangePassword,
        handlePasswordStrengthChanged,
        submit
    } = useEmailPasswordFormLogic(defaultFirstName, defaultLastName, onSubmit);

    return (
        <form className="form" onSubmit={submit}>
            <FormField>
                <TextField
                    type="text"
                    fullWidth
                    defaultValue={email}
                    disabled
                    label={intl.formatMessage(messages.email)}
                />
            </FormField>
            <NamesWrapper>
                <NameFormField>
                    <TextField
                        type="text"
                        name="firstName"
                        hasError={!!firstNameError}
                        fullWidth
                        placeholder=" "
                        value={firstName}
                        onChange={handleChangeFirstName}
                        label={intl.formatMessage(messages.firstName)}
                        errorMessage={
                            firstNameError ? intl.formatMessage(firstNameError) : undefined
                        }
                    />
                </NameFormField>
                <NameFormField>
                    <TextField
                        type="text"
                        name="lastName"
                        hasError={!!lastNameError}
                        fullWidth
                        placeholder=" "
                        value={lastName}
                        onChange={handleChangeLastName}
                        label={intl.formatMessage(messages.lastName)}
                        errorMessage={
                            lastNameError ? intl.formatMessage(lastNameError) : undefined
                        }
                    />
                </NameFormField>
            </NamesWrapper>
            <FormField>
                <PasswordInput
                    onPasswordStrengthChanged={handlePasswordStrengthChanged}
                    value={password}
                    placeholder=" "
                    onChange={handleChangePassword}
                    label={intl.formatMessage(messages.password)}
                    hasError={passwordError}
                />
            </FormField>
            <FormField>
                <PrimaryButton
                    disabled={loading}
                    type="submit"
                    isLoading={loading}
                    isFullWidth
                >
                    {intl.formatMessage(messages.createAccount)}
                </PrimaryButton>
            </FormField>
        </form>
    );
}
