import qs from 'qs';
import {FC} from 'react';
import {Helmet} from 'react-helmet';
import {Route, Switch, useHistory} from 'react-router-dom';
import SharedLogicTestReportPage from './components/SharedLogicTestReportPage';
import SharedPersonalityTestReportPage from './components/SharedPersonalityTestReportPage';
import SharedResultsPage from './components/SharedResultsPage';
import {sharedResultsLinks} from './components/SharedResultsPage/links';
import {SharedResultsContext} from './components/UserResults/hooks/SharedResultsContext';

export const SharedResults: FC = () => {
    const history = useHistory();

    const getRedirectUrlFromSearchParam = (): string => {
        const params = qs.parse(location.search, {ignoreQueryPrefix: true});
        return params.redirect ? (params.redirect as string) : '';
    };

    const redirect = () => {
        const redirectUrl = getRedirectUrlFromSearchParam();
        history.push(redirectUrl);
    };

    return (
        <Switch>
            <Route
                exact
                path={sharedResultsLinks.logicTestReport(':token', ':logicTestId')}
                render={p => (
                    <SharedResultsContext.Provider value={p.match.params.token as string}>
                        <Helmet>
                            <meta name="robots" content="noindex" />
                        </Helmet>
                        <SharedLogicTestReportPage
                            logicTestId={p.match.params.logicTestId as string}
                            goBack={redirect}
                        />
                    </SharedResultsContext.Provider>
                )}
            ></Route>
            <Route
                exact
                path={sharedResultsLinks.personalityTestReport(':token', ':personalityTestId')}
                render={p => (
                    <SharedResultsContext.Provider value={p.match.params.token as string}>
                        <Helmet>
                            <meta name="robots" content="noindex" />
                        </Helmet>
                        <SharedPersonalityTestReportPage
                            personalityTestId={p.match.params.personalityTestId as string}
                            closeLink={getRedirectUrlFromSearchParam() as string}
                        />
                    </SharedResultsContext.Provider>
                )}
            ></Route>
            <Route
                path={sharedResultsLinks.main(':token')}
                render={() => (
                    <>
                        <Helmet>
                            <meta name="robots" content="noindex" />
                        </Helmet>
                        <SharedResultsPage />
                    </>
                )}
            ></Route>
        </Switch>
    );
};
