import styled from 'styled-components';

import {SpacingSmall} from '@/componentLibrary/tokens/variables';

export const ToastsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingSmall};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto ${SpacingSmall} auto;
    width: 100%;
    max-width: 720px;
    z-index: 200000;
`;
