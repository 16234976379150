import {MutationResult, useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {clearAuthTokens, setUserAuthToken} from '@/services/auth/utils';

import {LoginMutation} from '../types/__generated__/graphql';
import {LOGIN} from './mutations';
import {useCheckLoggedIn} from './useCheckLoggedIn';

type ReturnType = [
    (email: string | null, password: string | null) => void,
    MutationResult<LoginMutation>
];

export function useLogin(): ReturnType {
    const {refetchLoginStatus} = useCheckLoggedIn();
    const [doLogin, loginResult] = useMutation(LOGIN);
    const login = useCallback(
        (email, password) => {
            const variables = {
                email,
                password
            };

            return doLogin({variables})
                .then(({data}) => {
                    if (!data || !data.login) {
                        throw new Error('Something went wrong');
                    }

                    const {ok, errorMessage, token} = data.login;
                    if (ok && token) {
                        setUserAuthToken(token);
                        refetchLoginStatus();
                    } else {
                        throw new Error(errorMessage || 'Something went wrong');
                    }
                })
                .catch(() => {
                    clearAuthTokens();
                });
        },
        [doLogin, refetchLoginStatus]
    );

    return [login, loginResult];
}
