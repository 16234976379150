import {useQuery} from '@apollo/client';

import {GET_USER_FEATURE_FLAGS} from '@/api/users/queries';
import {
    PrivateExperimentalFeatureKeys,
    PrivateExperimentalFeatures,
    PublicExperimentalFeatureKeys,
    PublicExperimentalFeatures
} from '@/services/experimentalFeatures/features';

export function useUserFeatureFlags() {
    const {data, error, loading, refetch} = useQuery(GET_USER_FEATURE_FLAGS, {
        fetchPolicy: 'cache-and-network'
    });
    const flags = data?.me?.featureFlags ?? [];
    const getPublicUserFeatureFlags: () => PublicExperimentalFeatures = () => {
        return Object.assign(
            {},
            ...Object.keys(PublicExperimentalFeatureKeys).map(flagName => {
                return {[flagName]: flags.includes(flagName)};
            })
        ) as PublicExperimentalFeatures;
    };

    const getPrivateUserFeatureFlags = () => {
        return Object.assign(
            {},
            ...Object.keys(PrivateExperimentalFeatureKeys).map(flagName => {
                return {[flagName]: flags.includes(flagName)};
            })
        ) as PrivateExperimentalFeatures;
    };
    return {
        error,
        loading,
        refetch,
        publicUserFeatureFlags: getPublicUserFeatureFlags(),
        privateUserFeatureFlags: getPrivateUserFeatureFlags()
    };
}
