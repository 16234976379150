import {useMemo} from 'react';

import {EmptyState} from '@/componentLibrary/blocks/EmptyState';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {Spinner} from '@/componentLibrary/components/spinners';

import {StateWrapper, Wrapper} from './styled';
import {SectionProps} from './types';

export function Section({
    header,
    loading,
    error,
    emptyState,
    children,
    ...props
}: SectionProps) {
    const state = useMemo(() => {
        if (loading) {
            return <Spinner />;
        }

        if (error) {
            return <ErrorState refetch={error.reload} />;
        }

        if (emptyState) {
            return (
                <EmptyState
                    titleText={emptyState.title}
                    contentText={emptyState.description}
                />
            );
        }
    }, [loading, error, emptyState]);

    return (
        <Wrapper {...props}>
            {header ? <div>{header}</div> : null}
            {state ? <StateWrapper>{state}</StateWrapper> : children}
        </Wrapper>
    );
}
