import {LandingPageLayoutHeader} from '@/componentLibrary/blocks/LandingPageLayout/components/LandingPageLayoutHeader';
import {ErrorBoundary} from '@/componentLibrary/components/ErrorBoundary';
import {CenteredSpinner} from '@/componentLibrary/components/spinners';
import {SpacingXxxsmall} from '@/componentLibrary/tokens/variables';
import InvalidResultsToken from '@/pages/SharedResults/components/InvalidResultsToken';
import UserResults from '@/pages/SharedResults/components/UserResults';
import {useSharedResultsLogic} from '@/pages/SharedResults/hooks/useSharedResultsLogic';
import {GoogleTagManager} from '@/services/gtm';
import {HubspotTracking} from '@/services/hubspot';
import {Satismeter} from '@/services/satismeter';
import {useMemo} from 'react';
import SharedResultsEmailPrompt from '../SharedResultsEmailPrompt';
import {ContentWrapper, HeaderWrapper, Page, Wrapper} from './styled';

export function SharedResultsPage() {
    const {
        isValidToken,
        token,
        emailForPageReload,
        permissions,
        loading,
        loggedInEmail,
        error,
        doShowResults,
        shouldShowResults,
        userId,
        jobPositionId,
        alvaLogoDestination
    } = useSharedResultsLogic();

    const content = useMemo(() => {
        if (loading) {
            return <CenteredSpinner />;
        }
        if (error || !isValidToken || !token || !permissions || !userId || !jobPositionId) {
            return <InvalidResultsToken />;
        }
        if (shouldShowResults || emailForPageReload) {
            return (
                <Page>
                    <HeaderWrapper withColor>
                        <LandingPageLayoutHeader
                            isWhite
                            marginBottom={SpacingXxxsmall}
                            withNameAndLogo
                            showLanguageDropdown
                            recruiterLocalesOnly
                            destination={alvaLogoDestination}
                        />
                    </HeaderWrapper>
                    <ErrorBoundary wrapperStyle={{display: 'none'}}>
                        <Satismeter loggedInFlow={false} />
                    </ErrorBoundary>
                    <UserResults
                        token={token}
                        permissions={permissions}
                        userId={userId}
                        jobPositionId={jobPositionId}
                    />
                </Page>
            );
        }
        return (
            <Page>
                <HeaderWrapper withColor={false}>
                    <LandingPageLayoutHeader
                        isWhite
                        marginBottom={0}
                        withNameAndLogo
                        showLanguageDropdown
                        recruiterLocalesOnly
                        destination={alvaLogoDestination}
                    />
                </HeaderWrapper>
                <SharedResultsEmailPrompt
                    token={token}
                    loggedInEmail={loggedInEmail}
                    onShowResults={doShowResults}
                />
            </Page>
        );
    }, [
        isValidToken,
        token,
        emailForPageReload,
        loggedInEmail,
        loading,
        error,
        doShowResults,
        permissions,
        shouldShowResults,
        userId,
        jobPositionId,
        alvaLogoDestination
    ]);
    return (
        <Wrapper>
            <ErrorBoundary wrapperStyle={{display: 'none'}}>
                <HubspotTracking />
                <GoogleTagManager />
            </ErrorBoundary>
            <ContentWrapper>{content}</ContentWrapper>
        </Wrapper>
    );
}
