import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';

import {toast} from '@/componentLibrary/components/Toast/toast';
import {LINKS} from '@/links';
import messages from '@/pages/Signup/pages/RegisterUser/messages';
import {logger} from '@/services/logrocket';

const ERRORS = {
    USER_ALREADY_ACTIVATED: 'USER_ALREADY_ACTIVATED',
    INVALID_PASSWORD: 'INVALID_PASSWORD'
};

export function useErrorLogic() {
    const history = useHistory();
    const intl = useIntl();

    const showErrorToast = useCallback((message: string) => {
        toast({type: 'error', message});
    }, []);

    const handleError = useCallback(
        (e: Error) => {
            if (e.message === ERRORS.USER_ALREADY_ACTIVATED) {
                showErrorToast(intl.formatMessage(messages.userAlreadyActivated));
                setTimeout(() => {
                    const url = `${LINKS.login}?message=${e.message}`;
                    history.push(url);
                }, 2000);
                return;
            }

            if (e.message === ERRORS.INVALID_PASSWORD) {
                showErrorToast(intl.formatMessage(messages.passwordTooWeak));
                return;
            }

            logger.warn(e);
            showErrorToast(intl.formatMessage(messages.failedToRegisterUser));
        },
        [showErrorToast, intl, history]
    );

    return {handleError};
}
