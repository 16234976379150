import camelCase from 'lodash.camelcase';
import {
    TabItem,
    TabLink,
    TabLinkActiveSeparator,
    TabSpotlightItem,
    TabsContainer,
    TabsItems
} from './styled';
import {TabBarProps} from './types';

const TabBar = ({tabs, endButton, spotlight = false}: TabBarProps) => {
    const tabsList = tabs
        .filter(tab => !tab.hideFromNavigation)
        .map((aTab, idx) => {
            const {id, title, path, isExact} = aTab;
            const isCurrentTab = window.location.pathname === path;
            const TabComponent = spotlight ? TabSpotlightItem : TabItem;
            return (
                <TabLink
                    key={idx}
                    to={Array.isArray(path) ? path[0] : path}
                    exact={isExact}
                    spotlight={spotlight}
                >
                    <TabComponent
                        id={camelCase(id)}
                        className={isCurrentTab ? 'selected' : 'unselected'}
                    >
                        {title}
                    </TabComponent>
                    {!spotlight && <TabLinkActiveSeparator />}
                </TabLink>
            );
        });

    const buildWrappedEndButton = () => {
        if (!endButton) {
            return null;
        }
        return <span className="end-button">{endButton}</span>;
    };

    return (
        <TabsContainer>
            <TabsItems compact={!!spotlight}>{tabsList}</TabsItems>
            {buildWrappedEndButton()}
        </TabsContainer>
    );
};

export {TabBar};
