import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

export function useGoBackOrGoToFallbackUrl(fallbackUrl: string) {
    const history = useHistory();
    return useCallback(() => {
        if (history.length >= 2) {
            history.goBack();
        } else {
            history.push(fallbackUrl);
        }
    }, [history, fallbackUrl]);
}
