const getIntercomApps = () => {
    const intercomLightweightApps = document.getElementsByClassName(
        'intercom-lightweight-app'
    );
    const intercomApps = document.getElementsByClassName('intercom-app');
    return {
        intercomLightweightApp: intercomLightweightApps[0],
        intercomApp: intercomApps[0]
    };
};

export const hideIntercom = () => {
    const {intercomLightweightApp, intercomApp} = getIntercomApps();
    if (intercomLightweightApp) {
        // @ts-ignore: Property 'style' does not exist on type 'Element'.
        intercomLightweightApp.style.display = 'none';
    } else if (intercomApp) {
        // @ts-ignore: Property 'style' does not exist on type 'Element'.
        intercomApp.style.display = 'none';
    }
};

export const displayIntercom = () => {
    const {intercomLightweightApp, intercomApp} = getIntercomApps();
    if (intercomLightweightApp) {
        // @ts-ignore: Property 'style' does not exist on type 'Element'.
        intercomLightweightApp.style.display = 'initial';
    } else if (intercomApp) {
        // @ts-ignore: Property 'style' does not exist on type 'Element'.
        intercomApp.style.display = 'initial';
    }
};
