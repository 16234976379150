import {AuthenticationFormHeader} from 'pages/common/authentication/AuthenticationFormHeader';

import {SsoButton} from '@/componentLibrary/components/buttons/SsoButton';
import ssoButtonMessages from '@/componentLibrary/components/buttons/SsoButton/messages';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import messages from '@/pages/OktaLogin/messages';
import {AuthenticationFormWrapper} from '@/pages/common/authentication/styled';
import {SsoProvider} from '@/services/auth/sso/constants';

import {ErrorBanner} from './components/ErrorBanner';
import {useCommonStateLogic} from './logic/useCommonStateLogic';
import {useErrorLogic} from './logic/useErrorLogic';
import {useFormInputLogic} from './logic/useFormInputLogic';
import {useFormSubmissionLogic} from './logic/useFormSubmissionLogic';
import {ErrorEnum} from './types';

export function Form() {
    const ssoButtonPhrases = useExtractPhraseConstants(ssoButtonMessages);
    const phrases = useExtractPhraseConstants(messages);

    const {triedToSubmit, setTriedToSubmit} = useCommonStateLogic();
    const {email, trimEmailInputValue, invalidFormInput, updateEmailInputValue} =
        useFormInputLogic(setTriedToSubmit);
    const {submitting, handleSubmit, missingOktaConfiguration} = useFormSubmissionLogic(
        email,
        trimEmailInputValue,
        invalidFormInput,
        triedToSubmit,
        setTriedToSubmit
    );
    const {error} = useErrorLogic(triedToSubmit, invalidFormInput, missingOktaConfiguration);

    return (
        <AuthenticationFormWrapper>
            <AuthenticationFormHeader title={ssoButtonPhrases.signInWithOkta} />
            <form onSubmit={handleSubmit}>
                <FormField>
                    <TextField
                        name="email"
                        label={phrases.emailInputFieldLabel}
                        errorMessage={phrases.emailInputFieldError}
                        fullWidth
                        hasError={error === ErrorEnum.INVALID_FORM_INPUT}
                        onChange={updateEmailInputValue}
                        value={email}
                    />
                </FormField>
                {error === ErrorEnum.GENERIC_ERROR && <ErrorBanner />}
                <SsoButton
                    type="submit"
                    ssoProvider={SsoProvider.OKTA}
                    isLoading={submitting}
                />
            </form>
        </AuthenticationFormWrapper>
    );
}
