import {defineMessages} from 'react-intl';

export default defineMessages({
    legend: {
        id: 'app.logicTestReport.resultCharts.legend'
    },
    averageTimeTitle: {
        id: 'app.logicTestReport.resultCharts.timePerQuestion'
    },
    averageTimePerQuestion: {
        id: 'app.logicTestReport.resultCharts.averageTimePerQuestion'
    }
});
