import qs from 'qs';
import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Redirect, useHistory} from 'react-router-dom';

import {ConsentType} from '@/api/types/__generated__/graphql';
import {useRegisterUserAndGiveConsents} from '@/api/users/register/useRegisterUserAndGiveConsents';
import {useAcceptSharingCandidateDataWithOrganization} from '@/api/users/useAcceptSharingCandidateDataWithOrganization';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import {LINKS} from '@/links';
import {useRedirectToLogin} from '@/services/auth/hooks/useRedirectToLogin';
import {setUserAuthToken} from '@/services/auth/utils';
import {AcceptConsentCheckbox} from '@/services/consent/components/AcceptConsentCheckbox';
import {useTermsOfServiceVersion} from '@/services/consent/useTermsOfServiceVersion';
import {logger} from '@/services/logrocket';

import {OrganizationAvatar} from '@/componentLibrary/components/avatars/OrganizationAvatar';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {Caption} from '@/componentLibrary/components/typography';
import {useStaticLegalContent} from '@/services/staticLegalContent/useStaticLegalContent';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {LoadingSpinner} from '../LoadingSpinner';
import messages from './messages';
import {
    CheckboxWrapper,
    LeftColumn,
    LeftColumnBody,
    RightColumn,
    TitleWrapper,
    Wrapper
} from './styled';
import {Props} from './types';
import {displayErrorMessage, getJobApplicationLink} from './utils';

export function OrganizationLandingPage({
    organization: {id, name, logo, acceptedByCandidate}
}: Props) {
    const {formatMessage} = useIntl();
    const history = useHistory();
    const redirectToLogin = useRedirectToLogin();
    const [refetchCount, setRefetchCount] = useState(0);

    const {user, loading: loadingUser, refetch} = useLoggedInUser(true);

    const {loadingTermsOfServiceVersion, termsOfServiceVersion} = useTermsOfServiceVersion();
    const {loading: loadingRecruitmentServices, data: recruitmentServices} =
        useStaticLegalContent({type: ConsentType.PRIVACY_POLICY_RECRUITMENT_SERVICES});
    const {loading: loadingCandidateServices, data: candidateServices} = useStaticLegalContent(
        {type: ConsentType.PRIVACY_POLICY_CANDIDATE_SERVICES}
    );

    const [doAcceptCandidateInvitation, {loading: acceptingInvitation}] =
        useAcceptSharingCandidateDataWithOrganization();
    const params = qs.parse(location.search, {ignoreQueryPrefix: true});
    const token = window.decodeURIComponent(params.t + '');

    const [doRegisterUserAndGiveConsents, {loading: registerUserAndGiveConsentsLoading}] =
        useRegisterUserAndGiveConsents(token);

    const goToAssessmentPage = useCallback(() => {
        if (!id) {
            return;
        }
        const link = getJobApplicationLink(id);
        history.push(link);
    }, [id, history]);

    useEffect(() => {
        // Simple polling. This is required since it might be some delay until the auth-token is defined in localStorage...
        // @TODO: NOT GOOD ENOUGH SOLUTION FOR PRODUCTION! This will retry forever if user not found
        if (!user && !loadingUser) {
            const retryDelay = Math.pow(2, refetchCount) * 350;
            setTimeout(() => {
                refetch();
                setRefetchCount(1 + refetchCount);
            }, retryDelay);
        }
        if (user && user.hasRegisteredToCandidateServices) {
            doAcceptCandidateInvitation(id)
                .then(({data}) => {
                    const {ok, errorMessage} =
                        data?.acceptSharingCandidateDataWithOrganization ?? {};
                    if (ok) {
                        goToAssessmentPage();
                    } else {
                        throw new Error(errorMessage ?? '');
                    }
                })
                .catch(error => {
                    logger.error(error);
                    displayErrorMessage();
                });
        }
    }, [
        doAcceptCandidateInvitation,
        goToAssessmentPage,
        id,
        user,
        loadingUser,
        refetch,
        refetchCount
    ]);

    const [triedToContinue, setTriedToContinue] = useState(false);
    const [isConsentAccepted, setIsConsentAccepted] = useState(false);
    const [isOptionalConsentAccepted, setOptionalConsent] = useState(false);

    const acceptConsent = useCallback(({accepted}) => {
        setIsConsentAccepted(accepted);
    }, []);

    const acceptOptionalConsent = useCallback(({accepted}) => {
        setOptionalConsent(accepted);
    }, []);

    const handleSubmit = () => {
        setTriedToContinue(true);
        if (
            !isConsentAccepted ||
            !isDefined(termsOfServiceVersion) ||
            !isDefined(candidateServices) ||
            !isDefined(recruitmentServices)
        ) {
            return;
        }

        doRegisterUserAndGiveConsents({
            organizationId: id,
            termsOfServiceVersion,
            recruitmentServicesConsentVersion: recruitmentServices.version,
            isCandidateServicesConsentGiven: isOptionalConsentAccepted,
            candidateServicesConsentVersion: candidateServices.version
        })
            .then(({data}) => {
                const {ok, authToken, errorMessage} = data?.registerUserAndGiveConsents ?? {};
                if (ok && authToken) {
                    setUserAuthToken(authToken);
                    logger.info(
                        `Invitation is accepted, user is registered and consents: terms of service, recruitment services ${
                            isOptionalConsentAccepted ? 'and candidate services' : ''
                        } are given.`
                    );
                    goToAssessmentPage();
                } else {
                    throw new Error(errorMessage ?? '');
                }
            })
            .catch(error => {
                logger.log(error.message);
                if (error.message === 'USER_ALREADY_ACTIVATED') {
                    redirectToLogin(error.message);
                }
                logger.error(error);
            });
    };

    if (acceptedByCandidate && id) {
        return <Redirect to={getJobApplicationLink(id)} />;
    }
    if (
        !user ||
        loadingUser ||
        loadingTermsOfServiceVersion ||
        loadingRecruitmentServices ||
        loadingCandidateServices
    ) {
        return <LoadingSpinner />;
    }

    return (
        <Wrapper>
            <LeftColumn>
                <LeftColumnBody>
                    <OrganizationAvatar image={logo} name={name ?? ''} />
                    <TitleWrapper>
                        <P1>
                            {formatMessage(messages.title, {name: <strong>{name}</strong>})}
                            &nbsp;
                            {formatMessage(messages.tos, {alva: <strong>Alva</strong>})}
                        </P1>
                    </TitleWrapper>

                    <FlexLayout direction="column">
                        <CheckboxWrapper>
                            <AcceptConsentCheckbox
                                type={ConsentType.TERMS_OF_SERVICE}
                                onChange={acceptConsent}
                                customLabel={formatMessage(
                                    messages.mandatoryConsentCheckboxCustomLabel,
                                    {
                                        termsOfService: (
                                            <ExternalLink url={LINKS.termsOfService}>
                                                {formatMessage(
                                                    messages.alvaTermsOfServiceTitle
                                                )}
                                            </ExternalLink>
                                        ),
                                        privacyPolicy: (
                                            <ExternalLink url={LINKS.privacyPolicy}>
                                                {formatMessage(messages.privacyPolicyTitle)}
                                            </ExternalLink>
                                        )
                                    }
                                )}
                                showRequiredText={triedToContinue && !isConsentAccepted}
                            />
                        </CheckboxWrapper>
                        <AcceptConsentCheckbox
                            type={ConsentType.PRIVACY_POLICY_CANDIDATE_SERVICES}
                            linkToFull={LINKS.candidateServices}
                            onChange={acceptOptionalConsent}
                            labelDescription={formatMessage(
                                messages.candidateServicesLabelDescription,
                                {companyName: name}
                            )}
                        />
                    </FlexLayout>

                    <div>
                        <PrimaryButton
                            onClick={handleSubmit}
                            isLoading={
                                acceptingInvitation || registerUserAndGiveConsentsLoading
                            }
                        >
                            {formatMessage(messages.continue)}
                        </PrimaryButton>
                    </div>
                    <Caption>
                        {formatMessage(messages.disclaimer, {
                            email: <strong>{user.email}</strong>
                        })}
                    </Caption>
                </LeftColumnBody>
            </LeftColumn>
            <RightColumn />
        </Wrapper>
    );
}
