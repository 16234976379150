import styled from 'styled-components';

import {GRID_LAYOUT} from './constants';
import {Column, Wrapper} from './styled';
import {ColumnsProps} from './types';

export const Columns = ({
    columns,
    style = {},
    keepGridOnMobile = false,
    className,
    noMargin,
    columnsCss
}: ColumnsProps) => {
    return (
        <Wrapper
            style={style}
            keepGridOnMobile={keepGridOnMobile}
            className={className}
            css={columnsCss}
        >
            {columns.map(({size, render, grow, shrink, offset, offsetRight, css}, index) => (
                <Column
                    key={index}
                    percentage={size ? size / GRID_LAYOUT : columns.length / GRID_LAYOUT}
                    grow={grow}
                    shrink={shrink}
                    customCss={css}
                    keepGrid={keepGridOnMobile}
                    offsetPercentage={offset ? offset / GRID_LAYOUT : 0}
                    offsetRightPercentage={offsetRight ? offsetRight / GRID_LAYOUT : 0}
                    noMargin={noMargin}
                >
                    {render()}
                </Column>
            ))}
        </Wrapper>
    );
};

export const LimitedWidthColumns = styled(Columns)`
    max-width: 1080px;
    margin: 0 auto;
`;
