import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingSmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Column = styled.div`
    margin-top: ${SpacingSmall};
    width: 50%;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
    }
`;

export const Content = styled.div`
    max-width: 336px;
    display: flex;
    flex-direction: column;
    gap: ${SpacingSmall};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
        max-width: none;
    }
`;
