import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {assessmentLinks} from '@/pages/Organization/pages/Assessment/links';

export function useGoToInterviewScoreCard(redirectTo?: string) {
    const history = useHistory();
    return useCallback(
        interviewRequestId => {
            const url = assessmentLinks.interviewScoreCard(interviewRequestId, redirectTo);
            history.push(url);
        },
        [history, redirectTo]
    );
}
