import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import {Section} from '@/componentLibrary/components/layout/Section';
import {H4} from '@/componentLibrary/components/typography/Headings';

import {RoleFitContributionChart} from './components/RoleFitContributionChart';
import {useRoleFitContributionLogic} from './logic';
import messages from './messages';

export function RoleFitContribution({userId, jobPositionId}) {
    const intl = useIntl();

    const {
        loading,
        error,
        noData,
        logicTestContribution,
        personalityFacetsContribution,
        roleFitScore
    } = useRoleFitContributionLogic({userId, jobPositionId});

    return (
        <Section
            loading={loading}
            error={error}
            emptyState={
                noData
                    ? {
                          title: intl.formatMessage(messages.emptyStateTitle),
                          description: intl.formatMessage(messages.emptyStateDescription)
                      }
                    : null
            }
            header={<H4>{intl.formatMessage(messages.title)}</H4>}
        >
            <RoleFitContributionChart
                logicTestContribution={logicTestContribution}
                personalityFacetsContribution={personalityFacetsContribution}
                roleFitScore={roleFitScore}
            />
        </Section>
    );
}

RoleFitContribution.propTypes = {
    userId: PropTypes.number,
    jobPositionId: PropTypes.number
};
