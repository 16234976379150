import styled from 'styled-components';

import {P1} from '@/componentLibrary/components/typography';
import {
    ColorFgMuted,
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    padding-top: ${SpacingLarge};
`;

export const TestItemsWrapper = styled.div`
    margin-bottom: ${SpacingXxlarge};
    display: flex;
    gap: ${SpacingMedium};

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }

    @media only screen and (min-width: 1280px) {
        width: 1000px;
        flex-wrap: wrap;
    }
`;

export const TestItemWrapper = styled.div`
    width: 100%;
    display: flex;

    @media only screen and (max-width: 1024px) {
        margin-right: 0;
    }

    @media only screen and (min-width: 1280px) {
        width: 490px;
    }

    &:last-child {
        margin-right: 0;
    }
`;

export const BannerWrapper = styled.div`
    margin-bottom: ${SpacingMedium};
`;

export const Description = styled(P1)<{withMargin: boolean}>`
    margin-bottom: ${({withMargin}) => (withMargin ? SpacingSmall : 0)};
    color: ${ColorFgMuted};
`;
