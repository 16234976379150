import {defineMessages} from 'react-intl';

export default defineMessages({
    personalityTest: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.personalityTest'
    },
    logicTest: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.logicTest'
    },
    notCompleted: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.notCompleted'
    },
    completedAt: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.completedAt'
    },
    candidateAndEmployee: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.candidateAndEmployee'
    },
    candidate: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.candidate'
    },
    employee: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.employee'
    },
    revokeAccess: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.revokeAccess'
    }
});
