import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXxlarge} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    width: 100%;
`;
export const ContentWrapper = styled.div`
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    width: 100%;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column;
        justify-content: start;
        margin-top: 0;
    }

    @media print {
        margin-top: 48px;
        flex-direction: row;
    }
`;
export const ColumnWrapper = styled.div`
    padding-right: 72px;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        padding-right: 0;
        margin-top: ${SpacingXxlarge};
    }

    @media print {
        margin-top: 0;
        padding-right: 36px;
    }
`;
export const ChartWrapper = styled.div`
    margin-top: 42px;
    width: 424px;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        width: 100%;
    }
`;
