import {SpacingLarge, SpacingSmall, SpacingXsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const OrganizationInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 414px) {
        margin-bottom: ${SpacingLarge};
    }
`;
export const OrganizationTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${SpacingXsmall};
`;
export const OrganizationNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-inline-start: ${SpacingSmall};
`;
export const TestsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SpacingSmall};
    margin-inline-start: 56px;
`;
export const TestWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
