// The following language data is gathered from iso-locales library.
// https://www.npmjs.com/package/iso-locales
// import {getLocales} from 'iso-locales';
//
// const all_locales = getLocales();
// const locale = Object.values(all_locales).map(item => ({
//     name: `${item.language_local}${item.tag.split('-')[1]?'-'+item.tag.split('-')[1]:''}`,
//     internationalName: item.language,
//     id: item.tag
// }));

export const languageItems = [
    {
        name: 'Afaraf',
        internationalName: 'Afar',
        id: 'aa'
    },
    {
        name: 'Afaraf (Djibouti)',
        internationalName: 'Afar',
        id: 'aa-DJ'
    },
    {
        name: 'Afaraf (Eritrea)',
        internationalName: 'Afar',
        id: 'aa-ER'
    },
    {
        name: 'Afaraf (Ethiopia)',
        internationalName: 'Afar',
        id: 'aa-ET'
    },
    {
        name: 'Afrikaans',
        internationalName: 'Afrikaans',
        id: 'af'
    },
    {
        name: 'Afrikaans (Namibia)',
        internationalName: 'Afrikaans',
        id: 'af-NA'
    },
    {
        name: 'Afrikaans (South Africa)',
        internationalName: 'Afrikaans',
        id: 'af-ZA'
    },
    {
        name: 'Aghem',
        internationalName: 'Aghem',
        id: 'agq'
    },
    {
        name: 'Aghem (Cameroon)',
        internationalName: 'Aghem',
        id: 'agq-CM'
    },
    {
        name: 'Akan',
        internationalName: 'Akan',
        id: 'ak'
    },
    {
        name: 'Akan (Ghana)',
        internationalName: 'Akan',
        id: 'ak-GH'
    },
    {
        name: 'Shqip',
        internationalName: 'Albanian',
        id: 'sq'
    },
    {
        name: 'Shqip (Albania)',
        internationalName: 'Albanian',
        id: 'sq-AL'
    },
    {
        name: 'Shqip (North Macedonia)',
        internationalName: 'Albanian',
        id: 'sq-MK'
    },
    {
        name: 'Alsatian',
        internationalName: 'Alsatian',
        id: 'gsw'
    },
    {
        name: 'Alsatian (France)',
        internationalName: 'Alsatian',
        id: 'gsw-FR'
    },
    {
        name: 'Alsatian (Liechtenstein)',
        internationalName: 'Alsatian',
        id: 'gsw-LI'
    },
    {
        name: 'Alsatian (Switzerland)',
        internationalName: 'Alsatian',
        id: 'gsw-CH'
    },
    {
        name: 'አማርኛ',
        internationalName: 'Amharic',
        id: 'am'
    },
    {
        name: 'አማርኛ (Ethiopia)',
        internationalName: 'Amharic',
        id: 'am-ET'
    },
    {
        name: 'العربية',
        internationalName: 'Arabic',
        id: 'ar'
    },
    {
        name: 'العربية (Algeria)',
        internationalName: 'Arabic',
        id: 'ar-DZ'
    },
    {
        name: 'العربية (Bahrain)',
        internationalName: 'Arabic',
        id: 'ar-BH'
    },
    {
        name: 'العربية (Chad)',
        internationalName: 'Arabic',
        id: 'ar-TD'
    },
    {
        name: 'العربية (Comoros)',
        internationalName: 'Arabic',
        id: 'ar-KM'
    },
    {
        name: 'العربية (Djibouti)',
        internationalName: 'Arabic',
        id: 'ar-DJ'
    },
    {
        name: 'العربية (Egypt)',
        internationalName: 'Arabic',
        id: 'ar-EG'
    },
    {
        name: 'العربية (Eritrea)',
        internationalName: 'Arabic',
        id: 'ar-ER'
    },
    {
        name: 'العربية (Iraq)',
        internationalName: 'Arabic',
        id: 'ar-IQ'
    },
    {
        name: 'العربية (Israel)',
        internationalName: 'Arabic',
        id: 'ar-IL'
    },
    {
        name: 'العربية (Jordan)',
        internationalName: 'Arabic',
        id: 'ar-JO'
    },
    {
        name: 'العربية (Kuwait)',
        internationalName: 'Arabic',
        id: 'ar-KW'
    },
    {
        name: 'العربية (Lebanon)',
        internationalName: 'Arabic',
        id: 'ar-LB'
    },
    {
        name: 'العربية (Libya)',
        internationalName: 'Arabic',
        id: 'ar-LY'
    },
    {
        name: 'العربية (Mauritania)',
        internationalName: 'Arabic',
        id: 'ar-MR'
    },
    {
        name: 'العربية (Morocco)',
        internationalName: 'Arabic',
        id: 'ar-MA'
    },
    {
        name: 'العربية (Oman)',
        internationalName: 'Arabic',
        id: 'ar-OM'
    },
    {
        name: 'العربية (Palestine)',
        internationalName: 'Arabic',
        id: 'ar-PS'
    },
    {
        name: 'العربية (Qatar)',
        internationalName: 'Arabic',
        id: 'ar-QA'
    },
    {
        name: 'العربية (Saudi Arabia)',
        internationalName: 'Arabic',
        id: 'ar-SA'
    },
    {
        name: 'العربية (Somalia)',
        internationalName: 'Arabic',
        id: 'ar-SO'
    },
    {
        name: 'العربية (South Sudan)',
        internationalName: 'Arabic',
        id: 'ar-SS'
    },
    {
        name: 'العربية (Sudan)',
        internationalName: 'Arabic',
        id: 'ar-SD'
    },
    {
        name: 'العربية (Syria)',
        internationalName: 'Arabic',
        id: 'ar-SY'
    },
    {
        name: 'العربية (Tunisia)',
        internationalName: 'Arabic',
        id: 'ar-TN'
    },
    {
        name: 'العربية (United Arab Emirates)',
        internationalName: 'Arabic',
        id: 'ar-AE'
    },
    {
        name: 'العربية (001)',
        internationalName: 'Arabic',
        id: 'ar-001'
    },
    {
        name: 'العربية (Yemen)',
        internationalName: 'Arabic',
        id: 'ar-YE'
    },
    {
        name: 'Հայերեն',
        internationalName: 'Armenian',
        id: 'hy'
    },
    {
        name: 'Հայերեն (Armenia)',
        internationalName: 'Armenian',
        id: 'hy-AM'
    },
    {
        name: 'অসমীয়া',
        internationalName: 'Assamese',
        id: 'as'
    },
    {
        name: 'অসমীয়া (India)',
        internationalName: 'Assamese',
        id: 'as-IN'
    },
    {
        name: 'Asturian',
        internationalName: 'Asturian',
        id: 'ast'
    },
    {
        name: 'Asturian (Spain)',
        internationalName: 'Asturian',
        id: 'ast-ES'
    },
    {
        name: 'Asu',
        internationalName: 'Asu',
        id: 'asa'
    },
    {
        name: 'Asu (Tanzania)',
        internationalName: 'Asu',
        id: 'asa-TZ'
    },
    {
        name: 'Azərbaycanca',
        internationalName: 'Azerbaijani (Cyrillic)',
        id: 'az-Cyrl'
    },
    {
        name: 'Azərbaycanca (Azerbaijan)',
        internationalName: 'Azerbaijani (Cyrillic)',
        id: 'az-Cyrl-AZ'
    },
    {
        name: 'Azərbaycanca',
        internationalName: 'Azerbaijani (Latin)',
        id: 'az'
    },
    {
        name: 'Azərbaycanca',
        internationalName: 'Azerbaijani (Latin)',
        id: 'az-Latn'
    },
    {
        name: 'Azərbaycanca (Azerbaijan)',
        internationalName: 'Azerbaijani (Latin)',
        id: 'az-Latn-AZ'
    },
    {
        name: 'Bafia',
        internationalName: 'Bafia',
        id: 'ksf'
    },
    {
        name: 'Bafia (Cameroon)',
        internationalName: 'Bafia',
        id: 'ksf-CM'
    },
    {
        name: 'Bamanankan',
        internationalName: 'Bamanankan',
        id: 'bm'
    },
    {
        name: 'Bamanankan (Mali)',
        internationalName: 'Bamanankan (Latin)',
        id: 'bm-Latn-ML'
    },
    {
        name: 'বাংলা',
        internationalName: 'Bangla',
        id: 'bn'
    },
    {
        name: 'বাংলা (Bangladesh)',
        internationalName: 'Bangla',
        id: 'bn-BD'
    },
    {
        name: 'বাংলা (India)',
        internationalName: 'Bangla',
        id: 'bn-IN'
    },
    {
        name: 'Basaa',
        internationalName: 'Basaa',
        id: 'bas'
    },
    {
        name: 'Basaa (Cameroon)',
        internationalName: 'Basaa',
        id: 'bas-CM'
    },
    {
        name: 'Башҡортса',
        internationalName: 'Bashkir',
        id: 'ba'
    },
    {
        name: 'Башҡортса (Russia)',
        internationalName: 'Bashkir',
        id: 'ba-RU'
    },
    {
        name: 'Euskara',
        internationalName: 'Basque',
        id: 'eu'
    },
    {
        name: 'Euskara (Spain)',
        internationalName: 'Basque',
        id: 'eu-ES'
    },
    {
        name: 'Беларуская',
        internationalName: 'Belarusian',
        id: 'be'
    },
    {
        name: 'Беларуская (Belarus)',
        internationalName: 'Belarusian',
        id: 'be-BY'
    },
    {
        name: 'Bemba',
        internationalName: 'Bemba',
        id: 'bem'
    },
    {
        name: 'Bemba (Zambia)',
        internationalName: 'Bemba',
        id: 'bem-ZM'
    },
    {
        name: 'Bena',
        internationalName: 'Bena',
        id: 'bez'
    },
    {
        name: 'Bena (Tanzania)',
        internationalName: 'Bena',
        id: 'bez-TZ'
    },
    {
        name: 'Blin',
        internationalName: 'Blin',
        id: 'byn'
    },
    {
        name: 'Blin (Eritrea)',
        internationalName: 'Blin',
        id: 'byn-ER'
    },
    {
        name: 'Bodo',
        internationalName: 'Bodo',
        id: 'brx'
    },
    {
        name: 'Bodo (India)',
        internationalName: 'Bodo',
        id: 'brx-IN'
    },
    {
        name: 'Bosanski',
        internationalName: 'Bosnian (Cyrillic)',
        id: 'bs-Cyrl'
    },
    {
        name: 'Bosanski (Bosnia & Herzegovina)',
        internationalName: 'Bosnian (Cyrillic)',
        id: 'bs-Cyrl-BA'
    },
    {
        name: 'Bosanski',
        internationalName: 'Bosnian (Latin)',
        id: 'bs-Latn'
    },
    {
        name: 'Bosanski',
        internationalName: 'Bosnian (Latin)',
        id: 'bs'
    },
    {
        name: 'Bosanski (Bosnia & Herzegovina)',
        internationalName: 'Bosnian (Latin)',
        id: 'bs-Latn-BA'
    },
    {
        name: 'Brezhoneg',
        internationalName: 'Breton',
        id: 'br'
    },
    {
        name: 'Brezhoneg (France)',
        internationalName: 'Breton',
        id: 'br-FR'
    },
    {
        name: 'Български',
        internationalName: 'Bulgarian',
        id: 'bg'
    },
    {
        name: 'Български (Bulgaria)',
        internationalName: 'Bulgarian',
        id: 'bg-BG'
    },
    {
        name: 'မြန်မာဘာသာ',
        internationalName: 'Burmese',
        id: 'my'
    },
    {
        name: 'မြန်မာဘာသာ (Myanmar (Burma))',
        internationalName: 'Burmese',
        id: 'my-MM'
    },
    {
        name: 'Català',
        internationalName: 'Catalan',
        id: 'ca'
    },
    {
        name: 'Català (Andorra)',
        internationalName: 'Catalan',
        id: 'ca-AD'
    },
    {
        name: 'Català (France)',
        internationalName: 'Catalan',
        id: 'ca-FR'
    },
    {
        name: 'Català (Italy)',
        internationalName: 'Catalan',
        id: 'ca-IT'
    },
    {
        name: 'Català (Spain)',
        internationalName: 'Catalan',
        id: 'ca-ES'
    },
    {
        name: 'Cebuano',
        internationalName: 'Cebuano',
        id: 'ceb'
    },
    {
        name: 'Cebuan (Latin)',
        internationalName: 'Cebuan (Latin)',
        id: 'ceb-Latn'
    },
    {
        name: 'Cebuan (Latin) (Philippines)',
        internationalName: 'Cebuan (Latin)',
        id: 'ceb-Latn-PH'
    },
    {
        name: 'Central Atlas Tamazight (Latin) ()',
        internationalName: 'Central Atlas Tamazight (Latin)',
        id: 'tzm-Latn-'
    },
    {
        name: 'Kurdî',
        internationalName: 'Central Kurdish',
        id: 'ku'
    },
    {
        name: 'Kurdî',
        internationalName: 'Central Kurdish',
        id: 'ku-Arab'
    },
    {
        name: 'Kurdî (Iraq)',
        internationalName: 'Central Kurdish',
        id: 'ku-Arab-IQ'
    },
    {
        name: 'Chakma',
        internationalName: 'Chakma',
        id: 'ccp'
    },
    {
        name: 'Chakma',
        internationalName: 'Chakma',
        id: 'ccp-Cakm'
    },
    {
        name: 'Chakma ()',
        internationalName: 'Chakma',
        id: 'ccp-Cakm-'
    },
    {
        name: 'Chechen (Russia)',
        internationalName: 'Chechen',
        id: 'cd-RU'
    },
    {
        name: 'Cherokee',
        internationalName: 'Cherokee',
        id: 'chr'
    },
    {
        name: 'Cherokee',
        internationalName: 'Cherokee',
        id: 'chr-Cher'
    },
    {
        name: 'Cherokee (United States)',
        internationalName: 'Cherokee',
        id: 'chr-Cher-US'
    },
    {
        name: 'Chiga',
        internationalName: 'Chiga',
        id: 'cgg'
    },
    {
        name: 'Chiga (Uganda)',
        internationalName: 'Chiga',
        id: 'cgg-UG'
    },
    {
        name: '中文',
        internationalName: 'Chinese (Simplified)',
        id: 'zh-Hans'
    },
    {
        name: '中文',
        internationalName: 'Chinese (Simplified)',
        id: 'zh'
    },
    {
        name: '中文 (China)',
        internationalName: 'Chinese (Simplified)',
        id: 'zh-CN'
    },
    {
        name: '中文 (Singapore)',
        internationalName: 'Chinese (Simplified)',
        id: 'zh-SG'
    },
    {
        name: '中文',
        internationalName: 'Chinese (Traditional)',
        id: 'zh-Hant'
    },
    {
        name: '中文 (Hong Kong)',
        internationalName: 'Chinese (Traditional)',
        id: 'zh-HK'
    },
    {
        name: '中文 (Macao)',
        internationalName: 'Chinese (Traditional)',
        id: 'zh-MO'
    },
    {
        name: '中文 (Taiwan)',
        internationalName: 'Chinese (Traditional)',
        id: 'zh-TW'
    },
    {
        name: 'Словѣ́ньскъ (Russia)',
        internationalName: 'Church Slavic',
        id: 'cu-RU'
    },
    {
        name: 'Congo Swahili',
        internationalName: 'Congo Swahili',
        id: 'swc'
    },
    {
        name: 'Congo Swahili (Congo - Kinshasa)',
        internationalName: 'Congo Swahili',
        id: 'swc-CD'
    },
    {
        name: 'Kernewek',
        internationalName: 'Cornish',
        id: 'kw'
    },
    {
        name: 'Kernewek (United Kingdom)',
        internationalName: 'Cornish',
        id: 'kw-GB'
    },
    {
        name: 'Corsu',
        internationalName: 'Corsican',
        id: 'co'
    },
    {
        name: 'Corsu (France)',
        internationalName: 'Corsican',
        id: 'co-FR'
    },
    {
        name: 'Hrvatski',
        internationalName: 'Croatian',
        id: 'hr,'
    },
    {
        name: 'Hrvatski (Croatia)',
        internationalName: 'Croatian',
        id: 'hr-HR'
    },
    {
        name: 'Hrvatski (Bosnia & Herzegovina)',
        internationalName: 'Croatian (Latin)',
        id: 'hr-BA'
    },
    {
        name: 'Čeština',
        internationalName: 'Czech',
        id: 'cs'
    },
    {
        name: 'Čeština (Czechia)',
        internationalName: 'Czech',
        id: 'cs-CZ'
    },
    {
        name: 'Dansk',
        internationalName: 'Danish',
        id: 'da'
    },
    {
        name: 'Dansk (Denmark)',
        internationalName: 'Danish',
        id: 'da-DK'
    },
    {
        name: 'Dansk (Greenland)',
        internationalName: 'Danish',
        id: 'da-GL'
    },
    {
        name: 'Dari',
        internationalName: 'Dari',
        id: 'prs'
    },
    {
        name: 'Dari (Afghanistan)',
        internationalName: 'Dari',
        id: 'prs-AF'
    },
    {
        name: 'Divehi',
        internationalName: 'Divehi',
        id: 'dv'
    },
    {
        name: 'Divehi (Maldives)',
        internationalName: 'Divehi',
        id: 'dv-MV'
    },
    {
        name: 'Duala',
        internationalName: 'Duala',
        id: 'dua'
    },
    {
        name: 'Duala (Cameroon)',
        internationalName: 'Duala',
        id: 'dua-CM'
    },
    {
        name: 'Nederlands',
        internationalName: 'Dutch',
        id: 'nl'
    },
    {
        name: 'Nederlands (Aruba)',
        internationalName: 'Dutch',
        id: 'nl-AW'
    },
    {
        name: 'Nederlands (Belgium)',
        internationalName: 'Dutch',
        id: 'nl-BE'
    },
    {
        name: 'Nederlands (Caribbean Netherlands)',
        internationalName: 'Dutch',
        id: 'nl-BQ'
    },
    {
        name: 'Nederlands (Curaçao)',
        internationalName: 'Dutch',
        id: 'nl-CW'
    },
    {
        name: 'Nederlands (Netherlands)',
        internationalName: 'Dutch',
        id: 'nl-NL'
    },
    {
        name: 'Nederlands (Sint Maarten)',
        internationalName: 'Dutch',
        id: 'nl-SX'
    },
    {
        name: 'Nederlands (Suriname)',
        internationalName: 'Dutch',
        id: 'nl-SR'
    },
    {
        name: 'རྫོང་ཁ',
        internationalName: 'Dzongkha',
        id: 'dz'
    },
    {
        name: 'རྫོང་ཁ (Bhutan)',
        internationalName: 'Dzongkha',
        id: 'dz-BT'
    },
    {
        name: 'Embu',
        internationalName: 'Embu',
        id: 'ebu'
    },
    {
        name: 'Embu (Kenya)',
        internationalName: 'Embu',
        id: 'ebu-KE'
    },
    {
        name: 'English',
        internationalName: 'English',
        id: 'en'
    },
    {
        name: 'English (American Samoa)',
        internationalName: 'English',
        id: 'en-AS'
    },
    {
        name: 'English (Anguilla)',
        internationalName: 'English',
        id: 'en-AI'
    },
    {
        name: 'English (Antigua & Barbuda)',
        internationalName: 'English',
        id: 'en-AG'
    },
    {
        name: 'English (Australia)',
        internationalName: 'English',
        id: 'en-AU'
    },
    {
        name: 'English (Austria)',
        internationalName: 'English',
        id: 'en-AT'
    },
    {
        name: 'English (Bahamas)',
        internationalName: 'English',
        id: 'en-BS'
    },
    {
        name: 'English (Barbados)',
        internationalName: 'English',
        id: 'en-BB'
    },
    {
        name: 'English (Belgium)',
        internationalName: 'English',
        id: 'en-BE'
    },
    {
        name: 'English (Belize)',
        internationalName: 'English',
        id: 'en-BZ'
    },
    {
        name: 'English (Bermuda)',
        internationalName: 'English',
        id: 'en-BM'
    },
    {
        name: 'English (Botswana)',
        internationalName: 'English',
        id: 'en-BW'
    },
    {
        name: 'English (British Indian Ocean Territory)',
        internationalName: 'English',
        id: 'en-IO'
    },
    {
        name: 'English (British Virgin Islands)',
        internationalName: 'English',
        id: 'en-VG'
    },
    {
        name: 'English (Burundi)',
        internationalName: 'English',
        id: 'en-BI'
    },
    {
        name: 'English (Cameroon)',
        internationalName: 'English',
        id: 'en-CM'
    },
    {
        name: 'English (Canada)',
        internationalName: 'English',
        id: 'en-CA'
    },
    {
        name: 'English (029)',
        internationalName: 'English',
        id: 'en-029'
    },
    {
        name: 'English (Cayman Islands)',
        internationalName: 'English',
        id: 'en-KY'
    },
    {
        name: 'English (Christmas Island)',
        internationalName: 'English',
        id: 'en-CX'
    },
    {
        name: 'English (Cocos (Keeling) Islands)',
        internationalName: 'English',
        id: 'en-CC'
    },
    {
        name: 'English (Cook Islands)',
        internationalName: 'English',
        id: 'en-CK'
    },
    {
        name: 'English (Cyprus)',
        internationalName: 'English',
        id: 'en-CY'
    },
    {
        name: 'English (Denmark)',
        internationalName: 'English',
        id: 'en-DK'
    },
    {
        name: 'English (Dominica)',
        internationalName: 'English',
        id: 'en-DM'
    },
    {
        name: 'English (Eritrea)',
        internationalName: 'English',
        id: 'en-ER'
    },
    {
        name: 'English (150)',
        internationalName: 'English',
        id: 'en-150'
    },
    {
        name: 'English (Falkland Islands (Islas Malvinas))',
        internationalName: 'English',
        id: 'en-FK'
    },
    {
        name: 'English (Finland)',
        internationalName: 'English',
        id: 'en-FI'
    },
    {
        name: 'English (Fiji)',
        internationalName: 'English',
        id: 'en-FJ'
    },
    {
        name: 'English (Gambia)',
        internationalName: 'English',
        id: 'en-GM'
    },
    {
        name: 'English (Germany)',
        internationalName: 'English',
        id: 'en-DE'
    },
    {
        name: 'English (Ghana)',
        internationalName: 'English',
        id: 'en-GH'
    },
    {
        name: 'English (Gibraltar)',
        internationalName: 'English',
        id: 'en-GI'
    },
    {
        name: 'English (Grenada)',
        internationalName: 'English',
        id: 'en-GD'
    },
    {
        name: 'English (Guam)',
        internationalName: 'English',
        id: 'en-GU'
    },
    {
        name: 'English (Guernsey)',
        internationalName: 'English',
        id: 'en-GG'
    },
    {
        name: 'English (Guyana)',
        internationalName: 'English',
        id: 'en-GY'
    },
    {
        name: 'English (Hong Kong)',
        internationalName: 'English',
        id: 'en-HK'
    },
    {
        name: 'English (India)',
        internationalName: 'English',
        id: 'en-IN'
    },
    {
        name: 'English (Ireland)',
        internationalName: 'English',
        id: 'en-IE'
    },
    {
        name: 'English (Isle of Man)',
        internationalName: 'English',
        id: 'en-IM'
    },
    {
        name: 'English (Israel)',
        internationalName: 'English',
        id: 'en-IL'
    },
    {
        name: 'English (Jamaica)',
        internationalName: 'English',
        id: 'en-JM'
    },
    {
        name: 'English (Jersey)',
        internationalName: 'English',
        id: 'en-JE'
    },
    {
        name: 'English (Kenya)',
        internationalName: 'English',
        id: 'en-KE'
    },
    {
        name: 'English (Kiribati)',
        internationalName: 'English',
        id: 'en-KI'
    },
    {
        name: 'English (Lesotho)',
        internationalName: 'English',
        id: 'en-LS'
    },
    {
        name: 'English (Liberia)',
        internationalName: 'English',
        id: 'en-LR'
    },
    {
        name: 'English (Macao)',
        internationalName: 'English',
        id: 'en-MO'
    },
    {
        name: 'English (Madagascar)',
        internationalName: 'English',
        id: 'en-MG'
    },
    {
        name: 'English (Malawi)',
        internationalName: 'English',
        id: 'en-MW'
    },
    {
        name: 'English (Malaysia)',
        internationalName: 'English',
        id: 'en-MY'
    },
    {
        name: 'English (Malta)',
        internationalName: 'English',
        id: 'en-MT'
    },
    {
        name: 'English (Marshall Islands)',
        internationalName: 'English',
        id: 'en-MH'
    },
    {
        name: 'English (Mauritius)',
        internationalName: 'English',
        id: 'en-MU'
    },
    {
        name: 'English (Micronesia)',
        internationalName: 'English',
        id: 'en-FM'
    },
    {
        name: 'English (Montserrat)',
        internationalName: 'English',
        id: 'en-MS'
    },
    {
        name: 'English (Namibia)',
        internationalName: 'English',
        id: 'en-NA'
    },
    {
        name: 'English (Nauru)',
        internationalName: 'English',
        id: 'en-NR'
    },
    {
        name: 'English (Netherlands)',
        internationalName: 'English',
        id: 'en-NL'
    },
    {
        name: 'English (New Zealand)',
        internationalName: 'English',
        id: 'en-NZ'
    },
    {
        name: 'English (Nigeria)',
        internationalName: 'English',
        id: 'en-NG'
    },
    {
        name: 'English (Niue)',
        internationalName: 'English',
        id: 'en-NU'
    },
    {
        name: 'English (Norfolk Island)',
        internationalName: 'English',
        id: 'en-NF'
    },
    {
        name: 'English (Northern Mariana Islands)',
        internationalName: 'English',
        id: 'en-MP'
    },
    {
        name: 'English (Pakistan)',
        internationalName: 'English',
        id: 'en-PK'
    },
    {
        name: 'English (Palau)',
        internationalName: 'English',
        id: 'en-PW'
    },
    {
        name: 'English (Papua New Guinea)',
        internationalName: 'English',
        id: 'en-PG'
    },
    {
        name: 'English (Pitcairn Islands)',
        internationalName: 'English',
        id: 'en-PN'
    },
    {
        name: 'English (Puerto Rico)',
        internationalName: 'English',
        id: 'en-PR'
    },
    {
        name: 'English (Philippines)',
        internationalName: 'English',
        id: 'en-PH'
    },
    {
        name: 'English (Rwanda)',
        internationalName: 'English',
        id: 'en-RW'
    },
    {
        name: 'English (St. Kitts & Nevis)',
        internationalName: 'English',
        id: 'en-KN'
    },
    {
        name: 'English (St. Lucia)',
        internationalName: 'English',
        id: 'en-LC'
    },
    {
        name: 'English (St. Vincent & Grenadines)',
        internationalName: 'English',
        id: 'en-VC'
    },
    {
        name: 'English (Samoa)',
        internationalName: 'English',
        id: 'en-WS'
    },
    {
        name: 'English (Seychelles)',
        internationalName: 'English',
        id: 'en-SC'
    },
    {
        name: 'English (Sierra Leone)',
        internationalName: 'English',
        id: 'en-SL'
    },
    {
        name: 'English (Singapore)',
        internationalName: 'English',
        id: 'en-SG'
    },
    {
        name: 'English (Sint Maarten)',
        internationalName: 'English',
        id: 'en-SX'
    },
    {
        name: 'English (Slovenia)',
        internationalName: 'English',
        id: 'en-SI'
    },
    {
        name: 'English (Solomon Islands)',
        internationalName: 'English',
        id: 'en-SB'
    },
    {
        name: 'English (South Africa)',
        internationalName: 'English',
        id: 'en-ZA'
    },
    {
        name: 'English (South Sudan)',
        internationalName: 'English',
        id: 'en-SS'
    },
    {
        name: 'English (St. Helena)',
        internationalName: 'English',
        id: 'en-SH'
    },
    {
        name: 'English (Sudan)',
        internationalName: 'English',
        id: 'en-SD'
    },
    {
        name: 'English (Eswatini)',
        internationalName: 'English',
        id: 'en-SZ'
    },
    {
        name: 'English (Sweden)',
        internationalName: 'English',
        id: 'en-SE'
    },
    {
        name: 'English (Switzerland)',
        internationalName: 'English',
        id: 'en-CH'
    },
    {
        name: 'English (Tanzania)',
        internationalName: 'English',
        id: 'en-TZ'
    },
    {
        name: 'English (Tokelau)',
        internationalName: 'English',
        id: 'en-TK'
    },
    {
        name: 'English (Tonga)',
        internationalName: 'English',
        id: 'en-TO'
    },
    {
        name: 'English (Trinidad & Tobago)',
        internationalName: 'English',
        id: 'en-TT'
    },
    {
        name: 'English (Turks & Caicos Islands)',
        internationalName: 'English',
        id: 'en-TC'
    },
    {
        name: 'English (Tuvalu)',
        internationalName: 'English',
        id: 'en-TV'
    },
    {
        name: 'English (Uganda)',
        internationalName: 'English',
        id: 'en-UG'
    },
    {
        name: 'English (United Arab Emirates)',
        internationalName: 'English',
        id: 'en-AE'
    },
    {
        name: 'English (United Kingdom)',
        internationalName: 'English',
        id: 'en-GB'
    },
    {
        name: 'English (United States)',
        internationalName: 'English',
        id: 'en-US'
    },
    {
        name: 'English (U.S. Outlying Islands)',
        internationalName: 'English',
        id: 'en-UM'
    },
    {
        name: 'English (U.S. Virgin Islands)',
        internationalName: 'English',
        id: 'en-VI'
    },
    {
        name: 'English (Vanuatu)',
        internationalName: 'English',
        id: 'en-VU'
    },
    {
        name: 'English (001)',
        internationalName: 'English',
        id: 'en-001'
    },
    {
        name: 'English (Zambia)',
        internationalName: 'English',
        id: 'en-ZM'
    },
    {
        name: 'English (Zimbabwe)',
        internationalName: 'English',
        id: 'en-ZW'
    },
    {
        name: 'Esperanto',
        internationalName: 'Esperanto',
        id: 'eo'
    },
    {
        name: 'Esperanto (001)',
        internationalName: 'Esperanto',
        id: 'eo-001'
    },
    {
        name: 'Eesti',
        internationalName: 'Estonian',
        id: 'et'
    },
    {
        name: 'Eesti (Estonia)',
        internationalName: 'Estonian',
        id: 'et-EE'
    },
    {
        name: 'Eʋegbe',
        internationalName: 'Ewe',
        id: 'ee'
    },
    {
        name: 'Eʋegbe (Ghana)',
        internationalName: 'Ewe',
        id: 'ee-GH'
    },
    {
        name: 'Eʋegbe (Togo)',
        internationalName: 'Ewe',
        id: 'ee-TG'
    },
    {
        name: 'Ewondo',
        internationalName: 'Ewondo',
        id: 'ewo'
    },
    {
        name: 'Ewondo (Cameroon)',
        internationalName: 'Ewondo',
        id: 'ewo-CM'
    },
    {
        name: 'Føroyskt',
        internationalName: 'Faroese',
        id: 'fo'
    },
    {
        name: 'Føroyskt (Denmark)',
        internationalName: 'Faroese',
        id: 'fo-DK'
    },
    {
        name: 'Føroyskt (Faroe Islands)',
        internationalName: 'Faroese',
        id: 'fo-FO'
    },
    {
        name: 'Filipino',
        internationalName: 'Filipino',
        id: 'fil'
    },
    {
        name: 'Filipino (Philippines)',
        internationalName: 'Filipino',
        id: 'fil-PH'
    },
    {
        name: 'Suomi',
        internationalName: 'Finnish',
        id: 'fi'
    },
    {
        name: 'Suomi (Finland)',
        internationalName: 'Finnish',
        id: 'fi-FI'
    },
    {
        name: 'Français',
        internationalName: 'French',
        id: 'fr'
    },
    {
        name: 'Français (Algeria)',
        internationalName: 'French',
        id: 'fr-DZ'
    },
    {
        name: 'Français (Belgium)',
        internationalName: 'French',
        id: 'fr-BE'
    },
    {
        name: 'Français (Benin)',
        internationalName: 'French',
        id: 'fr-BJ'
    },
    {
        name: 'Français (Burkina Faso)',
        internationalName: 'French',
        id: 'fr-BF'
    },
    {
        name: 'Français (Burundi)',
        internationalName: 'French',
        id: 'fr-BI'
    },
    {
        name: 'Français (Cameroon)',
        internationalName: 'French',
        id: 'fr-CM'
    },
    {
        name: 'Français (Canada)',
        internationalName: 'French',
        id: 'fr-CA'
    },
    {
        name: 'Français (Central African Republic)',
        internationalName: 'French',
        id: 'fr-CF'
    },
    {
        name: 'Français (Chad)',
        internationalName: 'French',
        id: 'fr-TD'
    },
    {
        name: 'Français (Comoros)',
        internationalName: 'French',
        id: 'fr-KM'
    },
    {
        name: 'Français (Congo - Brazzaville)',
        internationalName: 'French',
        id: 'fr-CG'
    },
    {
        name: 'Français (Congo - Kinshasa)',
        internationalName: 'French',
        id: 'fr-CD'
    },
    {
        name: 'Français (Côte d’Ivoire)',
        internationalName: 'French',
        id: 'fr-CI'
    },
    {
        name: 'Français (Djibouti)',
        internationalName: 'French',
        id: 'fr-DJ'
    },
    {
        name: 'Français (Equatorial Guinea)',
        internationalName: 'French',
        id: 'fr-GQ'
    },
    {
        name: 'Français (France)',
        internationalName: 'French',
        id: 'fr-FR'
    },
    {
        name: 'Français (French Guiana)',
        internationalName: 'French',
        id: 'fr-GF'
    },
    {
        name: 'Français (French Polynesia)',
        internationalName: 'French',
        id: 'fr-PF'
    },
    {
        name: 'Français (Gabon)',
        internationalName: 'French',
        id: 'fr-GA'
    },
    {
        name: 'Français (Guadeloupe)',
        internationalName: 'French',
        id: 'fr-GP'
    },
    {
        name: 'Français (Guinea)',
        internationalName: 'French',
        id: 'fr-GN'
    },
    {
        name: 'Français (Haiti)',
        internationalName: 'French',
        id: 'fr-HT'
    },
    {
        name: 'Français (Luxembourg)',
        internationalName: 'French',
        id: 'fr-LU'
    },
    {
        name: 'Français (Madagascar)',
        internationalName: 'French',
        id: 'fr-MG'
    },
    {
        name: 'Français (Mali)',
        internationalName: 'French',
        id: 'fr-ML'
    },
    {
        name: 'Français (Martinique)',
        internationalName: 'French',
        id: 'fr-MQ'
    },
    {
        name: 'Français (Mauritania)',
        internationalName: 'French',
        id: 'fr-MR'
    },
    {
        name: 'Français (Mauritius)',
        internationalName: 'French',
        id: 'fr-MU'
    },
    {
        name: 'Français (Mayotte)',
        internationalName: 'French',
        id: 'fr-YT'
    },
    {
        name: 'Français (Morocco)',
        internationalName: 'French',
        id: 'fr-MA'
    },
    {
        name: 'Français (New Caledonia)',
        internationalName: 'French',
        id: 'fr-NC'
    },
    {
        name: 'Français (Niger)',
        internationalName: 'French',
        id: 'fr-NE'
    },
    {
        name: 'Français (Monaco)',
        internationalName: 'French',
        id: 'fr-MC'
    },
    {
        name: 'Français (Réunion)',
        internationalName: 'French',
        id: 'fr-RE'
    },
    {
        name: 'Français (Rwanda)',
        internationalName: 'French',
        id: 'fr-RW'
    },
    {
        name: 'Français (St. Barthélemy)',
        internationalName: 'French',
        id: 'fr-BL'
    },
    {
        name: 'Français (St. Martin)',
        internationalName: 'French',
        id: 'fr-MF'
    },
    {
        name: 'Français (St. Pierre & Miquelon)',
        internationalName: 'French',
        id: 'fr-PM'
    },
    {
        name: 'Français (Senegal)',
        internationalName: 'French',
        id: 'fr-SN'
    },
    {
        name: 'Français (Seychelles)',
        internationalName: 'French',
        id: 'fr-SC'
    },
    {
        name: 'Français (Switzerland)',
        internationalName: 'French',
        id: 'fr-CH'
    },
    {
        name: 'Français (Syria)',
        internationalName: 'French',
        id: 'fr-SY'
    },
    {
        name: 'Français (Togo)',
        internationalName: 'French',
        id: 'fr-TG'
    },
    {
        name: 'Français (Tunisia)',
        internationalName: 'French',
        id: 'fr-TN'
    },
    {
        name: 'Français (Vanuatu)',
        internationalName: 'French',
        id: 'fr-VU'
    },
    {
        name: 'Français (Wallis & Futuna)',
        internationalName: 'French',
        id: 'fr-WF'
    },
    {
        name: 'Frysk',
        internationalName: 'Frisian',
        id: 'fy'
    },
    {
        name: 'Frysk (Netherlands)',
        internationalName: 'Frisian',
        id: 'fy-NL'
    },
    {
        name: 'Friulian',
        internationalName: 'Friulian',
        id: 'fur'
    },
    {
        name: 'Friulian (Italy)',
        internationalName: 'Friulian',
        id: 'fur-IT'
    },
    {
        name: 'Fulfulde',
        internationalName: 'Fulah',
        id: 'ff'
    },
    {
        name: 'Fulfulde',
        internationalName: 'Fulah (Latin)',
        id: 'ff-Latn'
    },
    {
        name: 'Fulfulde (Burkina Faso)',
        internationalName: 'Fulah (Latin)',
        id: 'ff-Latn-BF'
    },
    {
        name: 'Fulfulde (Cameroon)',
        internationalName: 'Fulah',
        id: 'ff-CM'
    },
    {
        name: 'Fulfulde (Cameroon)',
        internationalName: 'Fulah (Latin)',
        id: 'ff-Latn-CM'
    },
    {
        name: 'Fulfulde (Gambia)',
        internationalName: 'Fulah (Latin)',
        id: 'ff-Latn-GM'
    },
    {
        name: 'Fulfulde (Ghana)',
        internationalName: 'Fulah (Latin)',
        id: 'ff-Latn-GH'
    },
    {
        name: 'Fulfulde (Guinea)',
        internationalName: 'Fulah',
        id: 'ff-GN'
    },
    {
        name: 'Fulfulde (Guinea)',
        internationalName: 'Fulah (Latin)',
        id: 'ff-Latn-GN'
    },
    {
        name: 'Fulfulde (Guinea-Bissau)',
        internationalName: 'Fulah (Latin)',
        id: 'ff-Latn-GW'
    },
    {
        name: 'Fulfulde (Liberia)',
        internationalName: 'Fulah (Latin)',
        id: 'ff-Latn-LR'
    },
    {
        name: 'Fulfulde (Mauritania)',
        internationalName: 'Fulah',
        id: 'ff-MR'
    },
    {
        name: 'Fulfulde (Mauritania)',
        internationalName: 'Fulah (Latin)',
        id: 'ff-Latn-MR'
    },
    {
        name: 'Fulfulde (Niger)',
        internationalName: 'Fulah (Latin)',
        id: 'ff-Latn-NE'
    },
    {
        name: 'Fulfulde (Nigeria)',
        internationalName: 'Fulah',
        id: 'ff-NG'
    },
    {
        name: 'Fulfulde (Nigeria)',
        internationalName: 'Fulah (Latin)',
        id: 'ff-Latn-NG'
    },
    {
        name: 'Fulfulde (Senegal)',
        internationalName: 'Fulah',
        id: 'ff-Latn-SN'
    },
    {
        name: 'Fulfulde (Sierra Leone)',
        internationalName: 'Fulah (Latin)',
        id: 'ff-Latn-SL'
    },
    {
        name: 'Galego',
        internationalName: 'Galician',
        id: 'gl'
    },
    {
        name: 'Galego (Spain)',
        internationalName: 'Galician',
        id: 'gl-ES'
    },
    {
        name: 'Luganda',
        internationalName: 'Ganda',
        id: 'lg'
    },
    {
        name: 'Luganda (Uganda)',
        internationalName: 'Ganda',
        id: 'lg-UG'
    },
    {
        name: 'ქართული',
        internationalName: 'Georgian',
        id: 'ka'
    },
    {
        name: 'ქართული (Georgia)',
        internationalName: 'Georgian',
        id: 'ka-GE'
    },
    {
        name: 'Deutsch',
        internationalName: 'German',
        id: 'de'
    },
    {
        name: 'Deutsch (Austria)',
        internationalName: 'German',
        id: 'de-AT'
    },
    {
        name: 'Deutsch (Belgium)',
        internationalName: 'German',
        id: 'de-BE'
    },
    {
        name: 'Deutsch (Germany)',
        internationalName: 'German',
        id: 'de-DE'
    },
    {
        name: 'Deutsch (Italy)',
        internationalName: 'German',
        id: 'de-IT'
    },
    {
        name: 'Deutsch (Liechtenstein)',
        internationalName: 'German',
        id: 'de-LI'
    },
    {
        name: 'Deutsch (Luxembourg)',
        internationalName: 'German',
        id: 'de-LU'
    },
    {
        name: 'Deutsch (Switzerland)',
        internationalName: 'German',
        id: 'de-CH'
    },
    {
        name: 'Ελληνικά',
        internationalName: 'Greek',
        id: 'el'
    },
    {
        name: 'Ελληνικά (Cyprus)',
        internationalName: 'Greek',
        id: 'el-CY'
    },
    {
        name: 'Ελληνικά (Greece)',
        internationalName: 'Greek',
        id: 'el-GR'
    },
    {
        name: 'Kalaallisut',
        internationalName: 'Greenlandic',
        id: 'kl'
    },
    {
        name: 'Kalaallisut (Greenland)',
        internationalName: 'Greenlandic',
        id: 'kl-GL'
    },
    {
        name: "Avañe'ẽ",
        internationalName: 'Guarani',
        id: 'gn'
    },
    {
        name: "Avañe'ẽ (Paraguay)",
        internationalName: 'Guarani',
        id: 'gn-PY'
    },
    {
        name: 'ગુજરાતી',
        internationalName: 'Gujarati',
        id: 'gu'
    },
    {
        name: 'ગુજરાતી (India)',
        internationalName: 'Gujarati',
        id: 'gu-IN'
    },
    {
        name: 'Gusii',
        internationalName: 'Gusii',
        id: 'guz'
    },
    {
        name: 'Gusii (Kenya)',
        internationalName: 'Gusii',
        id: 'guz-KE'
    },
    {
        name: 'هَوُسَ',
        internationalName: 'Hausa (Latin)',
        id: 'ha'
    },
    {
        name: 'هَوُسَ',
        internationalName: 'Hausa (Latin)',
        id: 'ha-Latn'
    },
    {
        name: 'هَوُسَ (Ghana)',
        internationalName: 'Hausa (Latin)',
        id: 'ha-Latn-GH'
    },
    {
        name: 'هَوُسَ (Niger)',
        internationalName: 'Hausa (Latin)',
        id: 'ha-Latn-NE'
    },
    {
        name: 'هَوُسَ (Nigeria)',
        internationalName: 'Hausa (Latin)',
        id: 'ha-Latn-NG'
    },
    {
        name: 'Hawaiian',
        internationalName: 'Hawaiian',
        id: 'haw'
    },
    {
        name: 'Hawaiian (United States)',
        internationalName: 'Hawaiian',
        id: 'haw-US'
    },
    {
        name: 'עברית',
        internationalName: 'Hebrew',
        id: 'he'
    },
    {
        name: 'עברית (Israel)',
        internationalName: 'Hebrew',
        id: 'he-IL'
    },
    {
        name: 'हिन्दी',
        internationalName: 'Hindi',
        id: 'hi'
    },
    {
        name: 'हिन्दी (India)',
        internationalName: 'Hindi',
        id: 'hi-IN'
    },
    {
        name: 'Magyar',
        internationalName: 'Hungarian',
        id: 'hu'
    },
    {
        name: 'Magyar (Hungary)',
        internationalName: 'Hungarian',
        id: 'hu-HU'
    },
    {
        name: 'Íslenska',
        internationalName: 'Icelandic',
        id: 'is'
    },
    {
        name: 'Íslenska (Iceland)',
        internationalName: 'Icelandic',
        id: 'is-IS'
    },
    {
        name: 'Igbo',
        internationalName: 'Igbo',
        id: 'ig'
    },
    {
        name: 'Igbo (Nigeria)',
        internationalName: 'Igbo',
        id: 'ig-NG'
    },
    {
        name: 'Bahasa Indonesia',
        internationalName: 'Indonesian',
        id: 'id'
    },
    {
        name: 'Bahasa Indonesia (Indonesia)',
        internationalName: 'Indonesian',
        id: 'id-ID'
    },
    {
        name: 'Interlingua',
        internationalName: 'Interlingua',
        id: 'ia'
    },
    {
        name: 'Interlingua (France)',
        internationalName: 'Interlingua',
        id: 'ia-FR'
    },
    {
        name: 'Interlingua (001)',
        internationalName: 'Interlingua',
        id: 'ia-001'
    },
    {
        name: 'ᐃᓄᒃᑎᑐᑦ',
        internationalName: 'Inuktitut (Latin)',
        id: 'iu'
    },
    {
        name: 'ᐃᓄᒃᑎᑐᑦ',
        internationalName: 'Inuktitut (Latin)',
        id: 'iu-Latn'
    },
    {
        name: 'ᐃᓄᒃᑎᑐᑦ (Canada)',
        internationalName: 'Inuktitut (Latin)',
        id: 'iu-Latn-CA'
    },
    {
        name: 'ᐃᓄᒃᑎᑐᑦ',
        internationalName: 'Inuktitut (Syllabics)',
        id: 'iu-Cans'
    },
    {
        name: 'ᐃᓄᒃᑎᑐᑦ (Canada)',
        internationalName: 'Inuktitut (Syllabics)',
        id: 'iu-Cans-CA'
    },
    {
        name: 'Gaeilge',
        internationalName: 'Irish',
        id: 'ga'
    },
    {
        name: 'Gaeilge (Ireland)',
        internationalName: 'Irish',
        id: 'ga-IE'
    },
    {
        name: 'Italiano',
        internationalName: 'Italian',
        id: 'it'
    },
    {
        name: 'Italiano (Italy)',
        internationalName: 'Italian',
        id: 'it-IT'
    },
    {
        name: 'Italiano (San Marino)',
        internationalName: 'Italian',
        id: 'it-SM'
    },
    {
        name: 'Italiano (Switzerland)',
        internationalName: 'Italian',
        id: 'it-CH'
    },
    {
        name: 'Italiano (Vatican City)',
        internationalName: 'Italian',
        id: 'it-VA'
    },
    {
        name: '日本語',
        internationalName: 'Japanese',
        id: 'ja'
    },
    {
        name: '日本語 (Japan)',
        internationalName: 'Japanese',
        id: 'ja-JP'
    },
    {
        name: 'Basa Jawa',
        internationalName: 'Javanese',
        id: 'jv'
    },
    {
        name: 'Basa Jawa',
        internationalName: 'Javanese',
        id: 'jv-Latn'
    },
    {
        name: 'Basa Jawa (Indonesia)',
        internationalName: 'Javanese',
        id: 'jv-Latn-ID'
    },
    {
        name: 'Jola-Fonyi',
        internationalName: 'Jola-Fonyi',
        id: 'dyo'
    },
    {
        name: 'Jola-Fonyi (Senegal)',
        internationalName: 'Jola-Fonyi',
        id: 'dyo-SN'
    },
    {
        name: 'Kabuverdianu',
        internationalName: 'Kabuverdianu',
        id: 'kea'
    },
    {
        name: 'Kabuverdianu (Cape Verde)',
        internationalName: 'Kabuverdianu',
        id: 'kea-CV'
    },
    {
        name: 'Kabyle',
        internationalName: 'Kabyle',
        id: 'kab'
    },
    {
        name: 'Kabyle (Algeria)',
        internationalName: 'Kabyle',
        id: 'kab-DZ'
    },
    {
        name: 'Kako',
        internationalName: 'Kako',
        id: 'kkj'
    },
    {
        name: 'Kako (Cameroon)',
        internationalName: 'Kako',
        id: 'kkj-CM'
    },
    {
        name: 'Kalenjin',
        internationalName: 'Kalenjin',
        id: 'kln'
    },
    {
        name: 'Kalenjin (Kenya)',
        internationalName: 'Kalenjin',
        id: 'kln-KE'
    },
    {
        name: 'Kamba',
        internationalName: 'Kamba',
        id: 'kam'
    },
    {
        name: 'Kamba (Kenya)',
        internationalName: 'Kamba',
        id: 'kam-KE'
    },
    {
        name: 'ಕನ್ನಡ',
        internationalName: 'Kannada',
        id: 'kn'
    },
    {
        name: 'ಕನ್ನಡ (India)',
        internationalName: 'Kannada',
        id: 'kn-IN'
    },
    {
        name: 'كشميري',
        internationalName: 'Kashmiri',
        id: 'ks'
    },
    {
        name: 'كشميري',
        internationalName: 'Kashmiri',
        id: 'ks-Arab'
    },
    {
        name: 'كشميري (India)',
        internationalName: 'Kashmiri',
        id: 'ks-Arab-IN'
    },
    {
        name: 'Қазақша',
        internationalName: 'Kazakh',
        id: 'kk'
    },
    {
        name: 'Қазақша (Kazakhstan)',
        internationalName: 'Kazakh',
        id: 'kk-KZ'
    },
    {
        name: 'ភាសាខ្មែរ',
        internationalName: 'Khmer',
        id: 'km'
    },
    {
        name: 'ភាសាខ្មែរ (Cambodia)',
        internationalName: 'Khmer',
        id: 'km-KH'
    },
    {
        name: "K'iche",
        internationalName: "K'iche",
        id: 'quc'
    },
    {
        name: "K'iche (Guatemala)",
        internationalName: "K'iche",
        id: 'quc-Latn-GT'
    },
    {
        name: 'Gĩkũyũ',
        internationalName: 'Kikuyu',
        id: 'ki'
    },
    {
        name: 'Gĩkũyũ (Kenya)',
        internationalName: 'Kikuyu',
        id: 'ki-KE'
    },
    {
        name: 'Kinyarwanda',
        internationalName: 'Kinyarwanda',
        id: 'rw'
    },
    {
        name: 'Kinyarwanda (Rwanda)',
        internationalName: 'Kinyarwanda',
        id: 'rw-RW'
    },
    {
        name: 'Kiswahili',
        internationalName: 'Kiswahili',
        id: 'sw'
    },
    {
        name: 'Kiswahili (Kenya)',
        internationalName: 'Kiswahili',
        id: 'sw-KE'
    },
    {
        name: 'Kiswahili (Tanzania)',
        internationalName: 'Kiswahili',
        id: 'sw-TZ'
    },
    {
        name: 'Kiswahili (Uganda)',
        internationalName: 'Kiswahili',
        id: 'sw-UG'
    },
    {
        name: 'Konkani',
        internationalName: 'Konkani',
        id: 'kok'
    },
    {
        name: 'Konkani (India)',
        internationalName: 'Konkani',
        id: 'kok-IN'
    },
    {
        name: '한국어',
        internationalName: 'Korean',
        id: 'ko'
    },
    {
        name: '한국어 (South Korea)',
        internationalName: 'Korean',
        id: 'ko-KR'
    },
    {
        name: '한국어 (North Korea)',
        internationalName: 'Korean',
        id: 'ko-KP'
    },
    {
        name: 'Koyra Chiini',
        internationalName: 'Koyra Chiini',
        id: 'khq'
    },
    {
        name: 'Koyra Chiini (Mali)',
        internationalName: 'Koyra Chiini',
        id: 'khq-ML'
    },
    {
        name: 'Koyraboro Senni',
        internationalName: 'Koyraboro Senni',
        id: 'ses'
    },
    {
        name: 'Koyraboro Senni (Mali)',
        internationalName: 'Koyraboro Senni',
        id: 'ses-ML'
    },
    {
        name: 'Kwasio',
        internationalName: 'Kwasio',
        id: 'nmg'
    },
    {
        name: 'Kwasio (Cameroon)',
        internationalName: 'Kwasio',
        id: 'nmg-CM'
    },
    {
        name: 'Кыргызча',
        internationalName: 'Kyrgyz',
        id: 'ky'
    },
    {
        name: 'Кыргызча (Kyrgyzstan)',
        internationalName: 'Kyrgyz',
        id: 'ky-KG'
    },
    {
        name: 'Kurdî (Iran)',
        internationalName: 'Kurdish',
        id: 'ku-Arab-IR'
    },
    {
        name: 'Lakota',
        internationalName: 'Lakota',
        id: 'lkt'
    },
    {
        name: 'Lakota (United States)',
        internationalName: 'Lakota',
        id: 'lkt-US'
    },
    {
        name: 'Langi',
        internationalName: 'Langi',
        id: 'lag'
    },
    {
        name: 'Langi (Tanzania)',
        internationalName: 'Langi',
        id: 'lag-TZ'
    },
    {
        name: 'ພາສາລາວ',
        internationalName: 'Lao',
        id: 'lo'
    },
    {
        name: 'ພາສາລາວ (Laos)',
        internationalName: 'Lao',
        id: 'lo-LA'
    },
    {
        name: 'Latviešu',
        internationalName: 'Latvian',
        id: 'lv'
    },
    {
        name: 'Latviešu (Latvia)',
        internationalName: 'Latvian',
        id: 'lv-LV'
    },
    {
        name: 'Lingála',
        internationalName: 'Lingala',
        id: 'ln'
    },
    {
        name: 'Lingála (Angola)',
        internationalName: 'Lingala',
        id: 'ln-AO'
    },
    {
        name: 'Lingála (Central African Republic)',
        internationalName: 'Lingala',
        id: 'ln-CF'
    },
    {
        name: 'Lingála (Congo - Brazzaville)',
        internationalName: 'Lingala',
        id: 'ln-CG'
    },
    {
        name: 'Lingála (Congo - Kinshasa)',
        internationalName: 'Lingala',
        id: 'ln-CD'
    },
    {
        name: 'Lietuvių',
        internationalName: 'Lithuanian',
        id: 'lt'
    },
    {
        name: 'Lietuvių (Lithuania)',
        internationalName: 'Lithuanian',
        id: 'lt-LT'
    },
    {
        name: 'Low German',
        internationalName: 'Low German',
        id: 'nds'
    },
    {
        name: 'Low German (Germany)',
        internationalName: 'Low German',
        id: 'nds-DE'
    },
    {
        name: 'Low German (Netherlands)',
        internationalName: 'Low German',
        id: 'nds-NL'
    },
    {
        name: 'Lower Sorbian',
        internationalName: 'Lower Sorbian',
        id: 'dsb'
    },
    {
        name: 'Lower Sorbian (Germany)',
        internationalName: 'Lower Sorbian',
        id: 'dsb-DE'
    },
    {
        name: 'Tshiluba',
        internationalName: 'Luba-Katanga',
        id: 'lu'
    },
    {
        name: 'Tshiluba (Congo - Kinshasa)',
        internationalName: 'Luba-Katanga',
        id: 'lu-CD'
    },
    {
        name: 'Luo',
        internationalName: 'Luo',
        id: 'luo'
    },
    {
        name: 'Luo (Kenya)',
        internationalName: 'Luo',
        id: 'luo-KE'
    },
    {
        name: 'Lëtzebuergesch',
        internationalName: 'Luxembourgish',
        id: 'lb'
    },
    {
        name: 'Lëtzebuergesch (Luxembourg)',
        internationalName: 'Luxembourgish',
        id: 'lb-LU'
    },
    {
        name: 'Luyia',
        internationalName: 'Luyia',
        id: 'luy'
    },
    {
        name: 'Luyia (Kenya)',
        internationalName: 'Luyia',
        id: 'luy-KE'
    },
    {
        name: 'Македонски',
        internationalName: 'Macedonian',
        id: 'mk'
    },
    {
        name: 'Македонски (North Macedonia)',
        internationalName: 'Macedonian',
        id: 'mk-MK'
    },
    {
        name: 'Machame',
        internationalName: 'Machame',
        id: 'jmc'
    },
    {
        name: 'Machame (Tanzania)',
        internationalName: 'Machame',
        id: 'jmc-TZ'
    },
    {
        name: 'Makhuwa-Meetto',
        internationalName: 'Makhuwa-Meetto',
        id: 'mgh'
    },
    {
        name: 'Makhuwa-Meetto (Mozambique)',
        internationalName: 'Makhuwa-Meetto',
        id: 'mgh-MZ'
    },
    {
        name: 'Makonde',
        internationalName: 'Makonde',
        id: 'kde'
    },
    {
        name: 'Makonde (Tanzania)',
        internationalName: 'Makonde',
        id: 'kde-TZ'
    },
    {
        name: 'Malagasy',
        internationalName: 'Malagasy',
        id: 'mg'
    },
    {
        name: 'Malagasy (Madagascar)',
        internationalName: 'Malagasy',
        id: 'mg-MG'
    },
    {
        name: 'Bahasa Melayu',
        internationalName: 'Malay',
        id: 'ms'
    },
    {
        name: 'Bahasa Melayu (Brunei)',
        internationalName: 'Malay',
        id: 'ms-BN'
    },
    {
        name: 'Bahasa Melayu (Malaysia)',
        internationalName: 'Malay',
        id: 'ms-MY'
    },
    {
        name: 'മലയാളം',
        internationalName: 'Malayalam',
        id: 'ml'
    },
    {
        name: 'മലയാളം (India)',
        internationalName: 'Malayalam',
        id: 'ml-IN'
    },
    {
        name: 'Malti',
        internationalName: 'Maltese',
        id: 'mt'
    },
    {
        name: 'Malti (Malta)',
        internationalName: 'Maltese',
        id: 'mt-MT'
    },
    {
        name: 'Gaelg',
        internationalName: 'Manx',
        id: 'gv'
    },
    {
        name: 'Gaelg (Isle of Man)',
        internationalName: 'Manx',
        id: 'gv-IM'
    },
    {
        name: 'Māori',
        internationalName: 'Maori',
        id: 'mi'
    },
    {
        name: 'Māori (New Zealand)',
        internationalName: 'Maori',
        id: 'mi-NZ'
    },
    {
        name: 'Mapudungun',
        internationalName: 'Mapudungun',
        id: 'arn'
    },
    {
        name: 'Mapudungun (Chile)',
        internationalName: 'Mapudungun',
        id: 'arn-CL'
    },
    {
        name: 'मराठी',
        internationalName: 'Marathi',
        id: 'mr'
    },
    {
        name: 'मराठी (India)',
        internationalName: 'Marathi',
        id: 'mr-IN'
    },
    {
        name: 'Masai',
        internationalName: 'Masai',
        id: 'mas'
    },
    {
        name: 'Masai (Kenya)',
        internationalName: 'Masai',
        id: 'mas-KE'
    },
    {
        name: 'Masai (Tanzania)',
        internationalName: 'Masai',
        id: 'mas-TZ'
    },
    {
        name: 'Mazanderani (Iran)',
        internationalName: 'Mazanderani',
        id: 'mzn-IR'
    },
    {
        name: 'Meru',
        internationalName: 'Meru',
        id: 'mer'
    },
    {
        name: 'Meru (Kenya)',
        internationalName: 'Meru',
        id: 'mer-KE'
    },
    {
        name: "Meta'",
        internationalName: "Meta'",
        id: 'mgo'
    },
    {
        name: "Meta' (Cameroon)",
        internationalName: "Meta'",
        id: 'mgo-CM'
    },
    {
        name: 'Mohawk',
        internationalName: 'Mohawk',
        id: 'moh'
    },
    {
        name: 'Mohawk (Canada)',
        internationalName: 'Mohawk',
        id: 'moh-CA'
    },
    {
        name: 'Монгол',
        internationalName: 'Mongolian (Cyrillic)',
        id: 'mn'
    },
    {
        name: 'Монгол',
        internationalName: 'Mongolian (Cyrillic)',
        id: 'mn-Cyrl'
    },
    {
        name: 'Монгол (Mongolia)',
        internationalName: 'Mongolian (Cyrillic)',
        id: 'mn-MN'
    },
    {
        name: 'Монгол',
        internationalName: 'Mongolian (Traditional Mongolian)',
        id: 'mn-Mong'
    },
    {
        name: 'Монгол (China)',
        internationalName: 'Mongolian (Traditional Mongolian)',
        id: 'mn-Mong-CN'
    },
    {
        name: 'Монгол (Mongolia)',
        internationalName: 'Mongolian (Traditional Mongolian)',
        id: 'mn-Mong-MN'
    },
    {
        name: 'Morisyen',
        internationalName: 'Morisyen',
        id: 'mfe'
    },
    {
        name: 'Morisyen (Mauritius)',
        internationalName: 'Morisyen',
        id: 'mfe-MU'
    },
    {
        name: 'Mundang',
        internationalName: 'Mundang',
        id: 'mua'
    },
    {
        name: 'Mundang (Cameroon)',
        internationalName: 'Mundang',
        id: 'mua-CM'
    },
    {
        name: "N'ko",
        internationalName: "N'ko",
        id: 'nqo'
    },
    {
        name: "N'ko (Guinea)",
        internationalName: "N'ko",
        id: 'nqo-GN'
    },
    {
        name: 'Nama',
        internationalName: 'Nama',
        id: 'naq'
    },
    {
        name: 'Nama (Namibia)',
        internationalName: 'Nama',
        id: 'naq-NA'
    },
    {
        name: 'नेपाली',
        internationalName: 'Nepali',
        id: 'ne'
    },
    {
        name: 'नेपाली (India)',
        internationalName: 'Nepali',
        id: 'ne-IN'
    },
    {
        name: 'नेपाली (Nepal)',
        internationalName: 'Nepali',
        id: 'ne-NP'
    },
    {
        name: 'Ngiemboon',
        internationalName: 'Ngiemboon',
        id: 'nnh'
    },
    {
        name: 'Ngiemboon (Cameroon)',
        internationalName: 'Ngiemboon',
        id: 'nnh-CM'
    },
    {
        name: 'Ngomba',
        internationalName: 'Ngomba',
        id: 'jgo'
    },
    {
        name: 'Ngomba (Cameroon)',
        internationalName: 'Ngomba',
        id: 'jgo-CM'
    },
    {
        name: 'Northern Luri (Iraq)',
        internationalName: 'Northern Luri',
        id: 'lrc-IQ'
    },
    {
        name: 'Northern Luri (Iran)',
        internationalName: 'Northern Luri',
        id: 'lrc-IR'
    },
    {
        name: 'isiNdebele',
        internationalName: 'North Ndebele',
        id: 'nd'
    },
    {
        name: 'isiNdebele (Zimbabwe)',
        internationalName: 'North Ndebele',
        id: 'nd-ZW'
    },
    {
        name: 'Norsk',
        internationalName: 'Norwegian (Bokmal)',
        id: 'no'
    },
    {
        name: 'Norsk (Bokmål)',
        internationalName: 'Norwegian (Bokmal)',
        id: 'nb'
    },
    {
        name: 'Norsk (Bokmål) (Norway)',
        internationalName: 'Norwegian (Bokmal)',
        id: 'nb-NO'
    },
    {
        name: 'Norsk (Nynorsk)',
        internationalName: 'Norwegian (Nynorsk)',
        id: 'nn'
    },
    {
        name: 'Norsk (Nynorsk) (Norway)',
        internationalName: 'Norwegian (Nynorsk)',
        id: 'nn-NO'
    },
    {
        name: 'Norsk (Bokmål) (Svalbard & Jan Mayen)',
        internationalName: 'Norwegian Bokmål',
        id: 'nb-SJ'
    },
    {
        name: 'Nuer',
        internationalName: 'Nuer',
        id: 'nus'
    },
    {
        name: 'Nuer (Sudan)',
        internationalName: 'Nuer',
        id: 'nus-SD'
    },
    {
        name: 'Nuer (South Sudan)',
        internationalName: 'Nuer',
        id: 'nus-SS'
    },
    {
        name: 'Nyankole',
        internationalName: 'Nyankole',
        id: 'nyn'
    },
    {
        name: 'Nyankole (Uganda)',
        internationalName: 'Nyankole',
        id: 'nyn-UG'
    },
    {
        name: 'Occitan',
        internationalName: 'Occitan',
        id: 'oc'
    },
    {
        name: 'Occitan (France)',
        internationalName: 'Occitan',
        id: 'oc-FR'
    },
    {
        name: 'ଓଡି଼ଆ',
        internationalName: 'Odia',
        id: 'or'
    },
    {
        name: 'ଓଡି଼ଆ (India)',
        internationalName: 'Odia',
        id: 'or-IN'
    },
    {
        name: 'Afaan Oromoo',
        internationalName: 'Oromo',
        id: 'om'
    },
    {
        name: 'Afaan Oromoo (Ethiopia)',
        internationalName: 'Oromo',
        id: 'om-ET'
    },
    {
        name: 'Afaan Oromoo (Kenya)',
        internationalName: 'Oromo',
        id: 'om-KE'
    },
    {
        name: 'Ирон æвзаг',
        internationalName: 'Ossetian',
        id: 'os'
    },
    {
        name: 'Ирон æвзаг (Georgia)',
        internationalName: 'Ossetian',
        id: 'os-GE'
    },
    {
        name: 'Ирон æвзаг (Russia)',
        internationalName: 'Ossetian',
        id: 'os-RU'
    },
    {
        name: 'پښتو',
        internationalName: 'Pashto',
        id: 'ps'
    },
    {
        name: 'پښتو (Afghanistan)',
        internationalName: 'Pashto',
        id: 'ps-AF'
    },
    {
        name: 'پښتو (Pakistan)',
        internationalName: 'Pashto',
        id: 'ps-PK'
    },
    {
        name: 'فارسی',
        internationalName: 'Persian',
        id: 'fa'
    },
    {
        name: 'فارسی (Afghanistan)',
        internationalName: 'Persian',
        id: 'fa-AF'
    },
    {
        name: 'فارسی (Iran)',
        internationalName: 'Persian',
        id: 'fa-IR'
    },
    {
        name: 'Polski',
        internationalName: 'Polish',
        id: 'pl'
    },
    {
        name: 'Polski (Poland)',
        internationalName: 'Polish',
        id: 'pl-PL'
    },
    {
        name: 'Português',
        internationalName: 'Portuguese',
        id: 'pt'
    },
    {
        name: 'Português (Angola)',
        internationalName: 'Portuguese',
        id: 'pt-AO'
    },
    {
        name: 'Português (Brazil)',
        internationalName: 'Portuguese',
        id: 'pt-BR'
    },
    {
        name: 'Português (Cape Verde)',
        internationalName: 'Portuguese',
        id: 'pt-CV'
    },
    {
        name: 'Português (Equatorial Guinea)',
        internationalName: 'Portuguese',
        id: 'pt-GQ'
    },
    {
        name: 'Português (Guinea-Bissau)',
        internationalName: 'Portuguese',
        id: 'pt-GW'
    },
    {
        name: 'Português (Luxembourg)',
        internationalName: 'Portuguese',
        id: 'pt-LU'
    },
    {
        name: 'Português (Macao)',
        internationalName: 'Portuguese',
        id: 'pt-MO'
    },
    {
        name: 'Português (Mozambique)',
        internationalName: 'Portuguese',
        id: 'pt-MZ'
    },
    {
        name: 'Português (Portugal)',
        internationalName: 'Portuguese',
        id: 'pt-PT'
    },
    {
        name: 'Português (São Tomé & Príncipe)',
        internationalName: 'Portuguese',
        id: 'pt-ST'
    },
    {
        name: 'Português (Switzerland)',
        internationalName: 'Portuguese',
        id: 'pt-CH'
    },
    {
        name: 'Português (Timor-Leste)',
        internationalName: 'Portuguese',
        id: 'pt-TL'
    },
    {
        name: 'Prussian (001)',
        internationalName: 'Prussian',
        id: 'prg-001'
    },
    {
        name: 'Pseudo Language',
        internationalName: 'Pseudo Language',
        id: 'qps-ploca'
    },
    {
        name: 'Pseudo Language',
        internationalName: 'Pseudo Language',
        id: 'qps-ploc'
    },
    {
        name: 'Pseudo Language',
        internationalName: 'Pseudo Language',
        id: 'qps-plocm'
    },
    {
        name: 'ਪੰਜਾਬੀ',
        internationalName: 'Punjabi',
        id: 'pa'
    },
    {
        name: 'ਪੰਜਾਬੀ',
        internationalName: 'Punjabi',
        id: 'pa-Arab'
    },
    {
        name: 'ਪੰਜਾਬੀ (India)',
        internationalName: 'Punjabi',
        id: 'pa-IN'
    },
    {
        name: 'ਪੰਜਾਬੀ (Pakistan)',
        internationalName: 'Punjabi',
        id: 'pa-Arab-PK'
    },
    {
        name: 'Quechua',
        internationalName: 'Quechua',
        id: 'quz'
    },
    {
        name: 'Quechua (Bolivia)',
        internationalName: 'Quechua',
        id: 'quz-BO'
    },
    {
        name: 'Quechua (Ecuador)',
        internationalName: 'Quechua',
        id: 'quz-EC'
    },
    {
        name: 'Quechua (Peru)',
        internationalName: 'Quechua',
        id: 'quz-PE'
    },
    {
        name: 'Ripuarian',
        internationalName: 'Ripuarian',
        id: 'ksh'
    },
    {
        name: 'Ripuarian (Germany)',
        internationalName: 'Ripuarian',
        id: 'ksh-DE'
    },
    {
        name: 'Română',
        internationalName: 'Romanian',
        id: 'ro'
    },
    {
        name: 'Română (Moldova)',
        internationalName: 'Romanian',
        id: 'ro-MD'
    },
    {
        name: 'Română (Romania)',
        internationalName: 'Romanian',
        id: 'ro-RO'
    },
    {
        name: 'Rumantsch',
        internationalName: 'Romansh',
        id: 'rm'
    },
    {
        name: 'Rumantsch (Switzerland)',
        internationalName: 'Romansh',
        id: 'rm-CH'
    },
    {
        name: 'Rombo',
        internationalName: 'Rombo',
        id: 'rof'
    },
    {
        name: 'Rombo (Tanzania)',
        internationalName: 'Rombo',
        id: 'rof-TZ'
    },
    {
        name: 'Kirundi',
        internationalName: 'Rundi',
        id: 'rn'
    },
    {
        name: 'Kirundi (Burundi)',
        internationalName: 'Rundi',
        id: 'rn-BI'
    },
    {
        name: 'Русский',
        internationalName: 'Russian',
        id: 'ru'
    },
    {
        name: 'Русский (Belarus)',
        internationalName: 'Russian',
        id: 'ru-BY'
    },
    {
        name: 'Русский (Kazakhstan)',
        internationalName: 'Russian',
        id: 'ru-KZ'
    },
    {
        name: 'Русский (Kyrgyzstan)',
        internationalName: 'Russian',
        id: 'ru-KG'
    },
    {
        name: 'Русский (Moldova)',
        internationalName: 'Russian',
        id: 'ru-MD'
    },
    {
        name: 'Русский (Russia)',
        internationalName: 'Russian',
        id: 'ru-RU'
    },
    {
        name: 'Русский (Ukraine)',
        internationalName: 'Russian',
        id: 'ru-UA'
    },
    {
        name: 'Rwa',
        internationalName: 'Rwa',
        id: 'rwk'
    },
    {
        name: 'Rwa (Tanzania)',
        internationalName: 'Rwa',
        id: 'rwk-TZ'
    },
    {
        name: 'Saho',
        internationalName: 'Saho',
        id: 'ssy'
    },
    {
        name: 'Saho (Eritrea)',
        internationalName: 'Saho',
        id: 'ssy-ER'
    },
    {
        name: 'Sakha',
        internationalName: 'Sakha',
        id: 'sah'
    },
    {
        name: 'Sakha (Russia)',
        internationalName: 'Sakha',
        id: 'sah-RU'
    },
    {
        name: 'Samburu',
        internationalName: 'Samburu',
        id: 'saq'
    },
    {
        name: 'Samburu (Kenya)',
        internationalName: 'Samburu',
        id: 'saq-KE'
    },
    {
        name: 'Sami (Inari)',
        internationalName: 'Sami (Inari)',
        id: 'smn'
    },
    {
        name: 'Sami (Inari) (Finland)',
        internationalName: 'Sami (Inari)',
        id: 'smn-FI'
    },
    {
        name: 'Sami (Lule)',
        internationalName: 'Sami (Lule)',
        id: 'smj'
    },
    {
        name: 'Sami (Lule) (Norway)',
        internationalName: 'Sami (Lule)',
        id: 'smj-NO'
    },
    {
        name: 'Sami (Lule) (Sweden)',
        internationalName: 'Sami (Lule)',
        id: 'smj-SE'
    },
    {
        name: 'Sámegiella',
        internationalName: 'Sami (Northern)',
        id: 'se'
    },
    {
        name: 'Sámegiella (Finland)',
        internationalName: 'Sami (Northern)',
        id: 'se-FI'
    },
    {
        name: 'Sámegiella (Norway)',
        internationalName: 'Sami (Northern)',
        id: 'se-NO'
    },
    {
        name: 'Sámegiella (Sweden)',
        internationalName: 'Sami (Northern)',
        id: 'se-SE'
    },
    {
        name: 'Sami (Skolt)',
        internationalName: 'Sami (Skolt)',
        id: 'sms'
    },
    {
        name: 'Sami (Skolt) (Finland)',
        internationalName: 'Sami (Skolt)',
        id: 'sms-FI'
    },
    {
        name: 'Sami (Southern)',
        internationalName: 'Sami (Southern)',
        id: 'sma'
    },
    {
        name: 'Sami (Southern) (Norway)',
        internationalName: 'Sami (Southern)',
        id: 'sma-NO'
    },
    {
        name: 'Sami (Southern) (Sweden)',
        internationalName: 'Sami (Southern)',
        id: 'sma-SE'
    },
    {
        name: 'Sängö',
        internationalName: 'Sango',
        id: 'sg'
    },
    {
        name: 'Sängö (Central African Republic)',
        internationalName: 'Sango',
        id: 'sg-CF'
    },
    {
        name: 'Sangu',
        internationalName: 'Sangu',
        id: 'sbp'
    },
    {
        name: 'Sangu (Tanzania)',
        internationalName: 'Sangu',
        id: 'sbp-TZ'
    },
    {
        name: 'संस्कृतम्',
        internationalName: 'Sanskrit',
        id: 'sa'
    },
    {
        name: 'संस्कृतम् (India)',
        internationalName: 'Sanskrit',
        id: 'sa-IN'
    },
    {
        name: 'Gàidhlig',
        internationalName: 'Scottish Gaelic',
        id: 'gd'
    },
    {
        name: 'Gàidhlig (United Kingdom)',
        internationalName: 'Scottish Gaelic',
        id: 'gd-GB'
    },
    {
        name: 'Sena',
        internationalName: 'Sena',
        id: 'seh'
    },
    {
        name: 'Sena (Mozambique)',
        internationalName: 'Sena',
        id: 'seh-MZ'
    },
    {
        name: 'Српски',
        internationalName: 'Serbian (Cyrillic)',
        id: 'sr-Cyrl'
    },
    {
        name: 'Српски (Bosnia & Herzegovina)',
        internationalName: 'Serbian (Cyrillic)',
        id: 'sr-Cyrl-BA'
    },
    {
        name: 'Српски (Montenegro)',
        internationalName: 'Serbian (Cyrillic)',
        id: 'sr-Cyrl-ME'
    },
    {
        name: 'Српски (Serbia)',
        internationalName: 'Serbian (Cyrillic)',
        id: 'sr-Cyrl-RS'
    },
    {
        name: 'Српски (Serbia)',
        internationalName: 'Serbian (Cyrillic)',
        id: 'sr-Cyrl-CS'
    },
    {
        name: 'Српски',
        internationalName: 'Serbian (Latin)',
        id: 'sr-Latn'
    },
    {
        name: 'Српски',
        internationalName: 'Serbian (Latin)',
        id: 'sr'
    },
    {
        name: 'Српски (Bosnia & Herzegovina)',
        internationalName: 'Serbian (Latin)',
        id: 'sr-Latn-BA'
    },
    {
        name: 'Српски (Montenegro)',
        internationalName: 'Serbian (Latin)',
        id: 'sr-Latn-ME'
    },
    {
        name: 'Српски (Serbia)',
        internationalName: 'Serbian (Latin)',
        id: 'sr-Latn-RS'
    },
    {
        name: 'Српски (Serbia)',
        internationalName: 'Serbian (Latin)',
        id: 'sr-Latn-CS'
    },
    {
        name: 'Sesotho sa Leboa',
        internationalName: 'Sesotho sa Leboa',
        id: 'nso'
    },
    {
        name: 'Sesotho sa Leboa (South Africa)',
        internationalName: 'Sesotho sa Leboa',
        id: 'nso-ZA'
    },
    {
        name: 'Setswana',
        internationalName: 'Setswana',
        id: 'tn'
    },
    {
        name: 'Setswana (Botswana)',
        internationalName: 'Setswana',
        id: 'tn-BW'
    },
    {
        name: 'Setswana (South Africa)',
        internationalName: 'Setswana',
        id: 'tn-ZA'
    },
    {
        name: 'Shambala',
        internationalName: 'Shambala',
        id: 'ksb'
    },
    {
        name: 'Shambala (Tanzania)',
        internationalName: 'Shambala',
        id: 'ksb-TZ'
    },
    {
        name: 'ChiShona',
        internationalName: 'Shona',
        id: 'sn'
    },
    {
        name: 'ChiShona',
        internationalName: 'Shona',
        id: 'sn-Latn'
    },
    {
        name: 'ChiShona (Zimbabwe)',
        internationalName: 'Shona',
        id: 'sn-Latn-ZW'
    },
    {
        name: 'سنڌي‎',
        internationalName: 'Sindhi',
        id: 'sd'
    },
    {
        name: 'سنڌي‎',
        internationalName: 'Sindhi',
        id: 'sd-Arab'
    },
    {
        name: 'سنڌي‎ (Pakistan)',
        internationalName: 'Sindhi',
        id: 'sd-Arab-PK'
    },
    {
        name: 'සිංහල',
        internationalName: 'Sinhala',
        id: 'si'
    },
    {
        name: 'සිංහල (Sri Lanka)',
        internationalName: 'Sinhala',
        id: 'si-LK'
    },
    {
        name: 'Slovenčina',
        internationalName: 'Slovak',
        id: 'sk'
    },
    {
        name: 'Slovenčina (Slovakia)',
        internationalName: 'Slovak',
        id: 'sk-SK'
    },
    {
        name: 'Slovenščina',
        internationalName: 'Slovenian',
        id: 'sl'
    },
    {
        name: 'Slovenščina (Slovenia)',
        internationalName: 'Slovenian',
        id: 'sl-SI'
    },
    {
        name: 'Soga',
        internationalName: 'Soga',
        id: 'xog'
    },
    {
        name: 'Soga (Uganda)',
        internationalName: 'Soga',
        id: 'xog-UG'
    },
    {
        name: 'Soomaaliga',
        internationalName: 'Somali',
        id: 'so'
    },
    {
        name: 'Soomaaliga (Djibouti)',
        internationalName: 'Somali',
        id: 'so-DJ'
    },
    {
        name: 'Soomaaliga (Ethiopia)',
        internationalName: 'Somali',
        id: 'so-ET'
    },
    {
        name: 'Soomaaliga (Kenya)',
        internationalName: 'Somali',
        id: 'so-KE'
    },
    {
        name: 'Soomaaliga (Somalia)',
        internationalName: 'Somali',
        id: 'so-SO'
    },
    {
        name: 'Sesotho',
        internationalName: 'Sotho',
        id: 'st'
    },
    {
        name: 'Sesotho (South Africa)',
        internationalName: 'Sotho',
        id: 'st-ZA'
    },
    {
        name: 'isiNdebele',
        internationalName: 'South Ndebele',
        id: 'nr'
    },
    {
        name: 'isiNdebele (South Africa)',
        internationalName: 'South Ndebele',
        id: 'nr-ZA'
    },
    {
        name: 'Sesotho (Lesotho)',
        internationalName: 'Southern Sotho',
        id: 'st-LS'
    },
    {
        name: 'Español',
        internationalName: 'Spanish',
        id: 'es'
    },
    {
        name: 'Español (Argentina)',
        internationalName: 'Spanish',
        id: 'es-AR'
    },
    {
        name: 'Español (Belize)',
        internationalName: 'Spanish',
        id: 'es-BZ'
    },
    {
        name: 'Español (Venezuela)',
        internationalName: 'Spanish',
        id: 'es-VE'
    },
    {
        name: 'Español (Bolivia)',
        internationalName: 'Spanish',
        id: 'es-BO'
    },
    {
        name: 'Español (Brazil)',
        internationalName: 'Spanish',
        id: 'es-BR'
    },
    {
        name: 'Español (Chile)',
        internationalName: 'Spanish',
        id: 'es-CL'
    },
    {
        name: 'Español (Colombia)',
        internationalName: 'Spanish',
        id: 'es-CO'
    },
    {
        name: 'Español (Costa Rica)',
        internationalName: 'Spanish',
        id: 'es-CR'
    },
    {
        name: 'Español (Cuba)',
        internationalName: 'Spanish',
        id: 'es-CU'
    },
    {
        name: 'Español (Dominican Republic)',
        internationalName: 'Spanish',
        id: 'es-DO'
    },
    {
        name: 'Español (Ecuador)',
        internationalName: 'Spanish',
        id: 'es-EC'
    },
    {
        name: 'Español (El Salvador)',
        internationalName: 'Spanish',
        id: 'es-SV'
    },
    {
        name: 'Español (Equatorial Guinea)',
        internationalName: 'Spanish',
        id: 'es-GQ'
    },
    {
        name: 'Español (Guatemala)',
        internationalName: 'Spanish',
        id: 'es-GT'
    },
    {
        name: 'Español (Honduras)',
        internationalName: 'Spanish',
        id: 'es-HN'
    },
    {
        name: 'Español (Latin America)',
        internationalName: 'Spanish',
        id: 'es-419'
    },
    {
        name: 'Español (Mexico)',
        internationalName: 'Spanish',
        id: 'es-MX'
    },
    {
        name: 'Español (Nicaragua)',
        internationalName: 'Spanish',
        id: 'es-NI'
    },
    {
        name: 'Español (Panama)',
        internationalName: 'Spanish',
        id: 'es-PA'
    },
    {
        name: 'Español (Paraguay)',
        internationalName: 'Spanish',
        id: 'es-PY'
    },
    {
        name: 'Español (Peru)',
        internationalName: 'Spanish',
        id: 'es-PE'
    },
    {
        name: 'Español (Philippines)',
        internationalName: 'Spanish',
        id: 'es-PH'
    },
    {
        name: 'Español (Puerto Rico)',
        internationalName: 'Spanish',
        id: 'es-PR'
    },
    {
        name: 'Español',
        internationalName: 'Spanish',
        id: 'es-ES_tradnl'
    },
    {
        name: 'Español (Spain)',
        internationalName: 'Spanish',
        id: 'es-ES'
    },
    {
        name: 'Español (United States)',
        internationalName: 'Spanish',
        id: 'es-US'
    },
    {
        name: 'Español (Uruguay)',
        internationalName: 'Spanish',
        id: 'es-UY'
    },
    {
        name: 'Standard Moroccan Tamazight',
        internationalName: 'Standard Moroccan Tamazight',
        id: 'zgh'
    },
    {
        name: 'Standard Moroccan Tamazight (Morocco)',
        internationalName: 'Standard Moroccan Tamazight',
        id: 'zgh-Tfng-MA'
    },
    {
        name: 'Standard Moroccan Tamazight',
        internationalName: 'Standard Moroccan Tamazight',
        id: 'zgh-Tfng'
    },
    {
        name: 'SiSwati',
        internationalName: 'Swati',
        id: 'ss'
    },
    {
        name: 'SiSwati (South Africa)',
        internationalName: 'Swati',
        id: 'ss-ZA'
    },
    {
        name: 'SiSwati (Eswatini)',
        internationalName: 'Swati',
        id: 'ss-SZ'
    },
    {
        name: 'Svenska',
        internationalName: 'Swedish',
        id: 'sv'
    },
    {
        name: 'Svenska (Åland Islands)',
        internationalName: 'Swedish',
        id: 'sv-AX'
    },
    {
        name: 'Svenska (Finland)',
        internationalName: 'Swedish',
        id: 'sv-FI'
    },
    {
        name: 'Svenska (Sweden)',
        internationalName: 'Swedish',
        id: 'sv-SE'
    },
    {
        name: 'Syriac',
        internationalName: 'Syriac',
        id: 'syr'
    },
    {
        name: 'Syriac (Syria)',
        internationalName: 'Syriac',
        id: 'syr-SY'
    },
    {
        name: 'Tachelhit',
        internationalName: 'Tachelhit',
        id: 'shi'
    },
    {
        name: 'Tachelhit',
        internationalName: 'Tachelhit',
        id: 'shi-Tfng'
    },
    {
        name: 'Tachelhit (Morocco)',
        internationalName: 'Tachelhit',
        id: 'shi-Tfng-MA'
    },
    {
        name: 'Tachelhit (Latin)',
        internationalName: 'Tachelhit (Latin)',
        id: 'shi-Latn'
    },
    {
        name: 'Tachelhit (Latin) (Morocco)',
        internationalName: 'Tachelhit (Latin)',
        id: 'shi-Latn-MA'
    },
    {
        name: 'Taita',
        internationalName: 'Taita',
        id: 'dav'
    },
    {
        name: 'Taita (Kenya)',
        internationalName: 'Taita',
        id: 'dav-KE'
    },
    {
        name: 'Тоҷикӣ',
        internationalName: 'Tajik (Cyrillic)',
        id: 'tg'
    },
    {
        name: 'Тоҷикӣ',
        internationalName: 'Tajik (Cyrillic)',
        id: 'tg-Cyrl'
    },
    {
        name: 'Тоҷикӣ (Tajikistan)',
        internationalName: 'Tajik (Cyrillic)',
        id: 'tg-Cyrl-TJ'
    },
    {
        name: 'Tamazight (Latin)',
        internationalName: 'Tamazight (Latin)',
        id: 'tzm'
    },
    {
        name: 'Tamazight (Latin)',
        internationalName: 'Tamazight (Latin)',
        id: 'tzm-Latn'
    },
    {
        name: 'Tamazight (Latin) (Algeria)',
        internationalName: 'Tamazight (Latin)',
        id: 'tzm-Latn-DZ'
    },
    {
        name: 'தமிழ்',
        internationalName: 'Tamil',
        id: 'ta'
    },
    {
        name: 'தமிழ் (India)',
        internationalName: 'Tamil',
        id: 'ta-IN'
    },
    {
        name: 'தமிழ் (Malaysia)',
        internationalName: 'Tamil',
        id: 'ta-MY'
    },
    {
        name: 'தமிழ் (Singapore)',
        internationalName: 'Tamil',
        id: 'ta-SG'
    },
    {
        name: 'தமிழ் (Sri Lanka)',
        internationalName: 'Tamil',
        id: 'ta-LK'
    },
    {
        name: 'Tasawaq',
        internationalName: 'Tasawaq',
        id: 'twq'
    },
    {
        name: 'Tasawaq (Niger)',
        internationalName: 'Tasawaq',
        id: 'twq-NE'
    },
    {
        name: 'Татарча',
        internationalName: 'Tatar',
        id: 'tt'
    },
    {
        name: 'Татарча (Russia)',
        internationalName: 'Tatar',
        id: 'tt-RU'
    },
    {
        name: 'తెలుగు',
        internationalName: 'Telugu',
        id: 'te'
    },
    {
        name: 'తెలుగు (India)',
        internationalName: 'Telugu',
        id: 'te-IN'
    },
    {
        name: 'Teso',
        internationalName: 'Teso',
        id: 'teo'
    },
    {
        name: 'Teso (Kenya)',
        internationalName: 'Teso',
        id: 'teo-KE'
    },
    {
        name: 'Teso (Uganda)',
        internationalName: 'Teso',
        id: 'teo-UG'
    },
    {
        name: 'ภาษาไทย',
        internationalName: 'Thai',
        id: 'th'
    },
    {
        name: 'ภาษาไทย (Thailand)',
        internationalName: 'Thai',
        id: 'th-TH'
    },
    {
        name: 'བོད་ཡིག',
        internationalName: 'Tibetan',
        id: 'bo'
    },
    {
        name: 'བོད་ཡིག (India)',
        internationalName: 'Tibetan',
        id: 'bo-IN'
    },
    {
        name: 'བོད་ཡིག (China)',
        internationalName: 'Tibetan',
        id: 'bo-CN'
    },
    {
        name: 'Tigre',
        internationalName: 'Tigre',
        id: 'tig'
    },
    {
        name: 'Tigre (Eritrea)',
        internationalName: 'Tigre',
        id: 'tig-ER'
    },
    {
        name: 'ትግርኛ',
        internationalName: 'Tigrinya',
        id: 'ti'
    },
    {
        name: 'ትግርኛ (Eritrea)',
        internationalName: 'Tigrinya',
        id: 'ti-ER'
    },
    {
        name: 'ትግርኛ (Ethiopia)',
        internationalName: 'Tigrinya',
        id: 'ti-ET'
    },
    {
        name: 'faka Tonga',
        internationalName: 'Tongan',
        id: 'to'
    },
    {
        name: 'faka Tonga (Tonga)',
        internationalName: 'Tongan',
        id: 'to-TO'
    },
    {
        name: 'Xitsonga',
        internationalName: 'Tsonga',
        id: 'ts'
    },
    {
        name: 'Xitsonga (South Africa)',
        internationalName: 'Tsonga',
        id: 'ts-ZA'
    },
    {
        name: 'Türkçe',
        internationalName: 'Turkish',
        id: 'tr'
    },
    {
        name: 'Türkçe (Cyprus)',
        internationalName: 'Turkish',
        id: 'tr-CY'
    },
    {
        name: 'Türkçe (Turkey)',
        internationalName: 'Turkish',
        id: 'tr-TR'
    },
    {
        name: 'Türkmençe',
        internationalName: 'Turkmen',
        id: 'tk'
    },
    {
        name: 'Türkmençe (Turkmenistan)',
        internationalName: 'Turkmen',
        id: 'tk-TM'
    },
    {
        name: 'Українська',
        internationalName: 'Ukrainian',
        id: 'uk'
    },
    {
        name: 'Українська (Ukraine)',
        internationalName: 'Ukrainian',
        id: 'uk-UA'
    },
    {
        name: 'Upper Sorbian',
        internationalName: 'Upper Sorbian',
        id: 'hsb'
    },
    {
        name: 'Upper Sorbian (Germany)',
        internationalName: 'Upper Sorbian',
        id: 'hsb-DE'
    },
    {
        name: 'اردو',
        internationalName: 'Urdu',
        id: 'ur'
    },
    {
        name: 'اردو (India)',
        internationalName: 'Urdu',
        id: 'ur-IN'
    },
    {
        name: 'اردو (Pakistan)',
        internationalName: 'Urdu',
        id: 'ur-PK'
    },
    {
        name: 'ئۇيغۇرچه',
        internationalName: 'Uyghur',
        id: 'ug'
    },
    {
        name: 'ئۇيغۇرچه (China)',
        internationalName: 'Uyghur',
        id: 'ug-CN'
    },
    {
        name: 'O‘zbek',
        internationalName: 'Uzbek',
        id: 'uz-Arab'
    },
    {
        name: 'O‘zbek (Afghanistan)',
        internationalName: 'Uzbek',
        id: 'uz-Arab-AF'
    },
    {
        name: 'O‘zbek',
        internationalName: 'Uzbek (Cyrillic)',
        id: 'uz-Cyrl'
    },
    {
        name: 'O‘zbek (Uzbekistan)',
        internationalName: 'Uzbek (Cyrillic)',
        id: 'uz-Cyrl-UZ'
    },
    {
        name: 'O‘zbek',
        internationalName: 'Uzbek (Latin)',
        id: 'uz'
    },
    {
        name: 'O‘zbek',
        internationalName: 'Uzbek (Latin)',
        id: 'uz-Latn'
    },
    {
        name: 'O‘zbek (Uzbekistan)',
        internationalName: 'Uzbek (Latin)',
        id: 'uz-Latn-UZ'
    },
    {
        name: 'Vai',
        internationalName: 'Vai',
        id: 'vai'
    },
    {
        name: 'Vai',
        internationalName: 'Vai',
        id: 'vai-Vaii'
    },
    {
        name: 'Vai (Liberia)',
        internationalName: 'Vai',
        id: 'vai-Vaii-LR'
    },
    {
        name: 'Vai (Latin) (Liberia)',
        internationalName: 'Vai (Latin)',
        id: 'vai-Latn-LR'
    },
    {
        name: 'Vai (Latin)',
        internationalName: 'Vai (Latin)',
        id: 'vai-Latn'
    },
    {
        name: 'Català ()',
        internationalName: 'Valencian',
        id: 'ca-ES-'
    },
    {
        name: 'Tshivenḓa',
        internationalName: 'Venda',
        id: 've'
    },
    {
        name: 'Tshivenḓa (South Africa)',
        internationalName: 'Venda',
        id: 've-ZA'
    },
    {
        name: 'Tiếng Việt',
        internationalName: 'Vietnamese',
        id: 'vi'
    },
    {
        name: 'Tiếng Việt (Vietnam)',
        internationalName: 'Vietnamese',
        id: 'vi-VN'
    },
    {
        name: 'Volapük',
        internationalName: 'Volapük',
        id: 'vo'
    },
    {
        name: 'Volapük (001)',
        internationalName: 'Volapük',
        id: 'vo-001'
    },
    {
        name: 'Vunjo',
        internationalName: 'Vunjo',
        id: 'vun'
    },
    {
        name: 'Vunjo (Tanzania)',
        internationalName: 'Vunjo',
        id: 'vun-TZ'
    },
    {
        name: 'Walser',
        internationalName: 'Walser',
        id: 'wae'
    },
    {
        name: 'Walser (Switzerland)',
        internationalName: 'Walser',
        id: 'wae-CH'
    },
    {
        name: 'Cymraeg',
        internationalName: 'Welsh',
        id: 'cy'
    },
    {
        name: 'Cymraeg (United Kingdom)',
        internationalName: 'Welsh',
        id: 'cy-GB'
    },
    {
        name: 'Wolaytta',
        internationalName: 'Wolaytta',
        id: 'wal'
    },
    {
        name: 'Wolaytta (Ethiopia)',
        internationalName: 'Wolaytta',
        id: 'wal-ET'
    },
    {
        name: 'Wolof',
        internationalName: 'Wolof',
        id: 'wo'
    },
    {
        name: 'Wolof (Senegal)',
        internationalName: 'Wolof',
        id: 'wo-SN'
    },
    {
        name: 'isiXhosa',
        internationalName: 'Xhosa',
        id: 'xh'
    },
    {
        name: 'isiXhosa (South Africa)',
        internationalName: 'Xhosa',
        id: 'xh-ZA'
    },
    {
        name: 'Yangben',
        internationalName: 'Yangben',
        id: 'yav'
    },
    {
        name: 'Yangben (Cameroon)',
        internationalName: 'Yangben',
        id: 'yav-CM'
    },
    {
        name: 'ꆈꌠ꒿ Nuosuhxop',
        internationalName: 'Yi',
        id: 'ii'
    },
    {
        name: 'ꆈꌠ꒿ Nuosuhxop (China)',
        internationalName: 'Yi',
        id: 'ii-CN'
    },
    {
        name: 'Yorùbá',
        internationalName: 'Yoruba',
        id: 'yo'
    },
    {
        name: 'Yorùbá (Benin)',
        internationalName: 'Yoruba',
        id: 'yo-BJ'
    },
    {
        name: 'Yorùbá (Nigeria)',
        internationalName: 'Yoruba',
        id: 'yo-NG'
    },
    {
        name: 'Zarma',
        internationalName: 'Zarma',
        id: 'dje'
    },
    {
        name: 'Zarma (Niger)',
        internationalName: 'Zarma',
        id: 'dje-NE'
    },
    {
        name: 'isiZulu',
        internationalName: 'Zulu',
        id: 'zu'
    },
    {
        name: 'isiZulu (South Africa)',
        internationalName: 'Zulu',
        id: 'zu-ZA'
    }
];
