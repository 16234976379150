import {useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {ErrorEnum} from '../types';

export function useErrorLogic(
    triedToSubmit: boolean,
    invalidFormInput: boolean,
    missingOktaConfiguration: boolean
) {
    const history = useHistory();
    const location = useLocation();

    const [error, setError] = useState<ErrorEnum | undefined>(undefined);

    const queryParams = useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (queryParams.has('message')) {
            if (queryParams.get('message') !== ErrorEnum.USER_ALREADY_ACTIVATED) {
                setError(ErrorEnum.GENERIC_ERROR);
            }
            queryParams.delete('message');
            history.replace({search: queryParams.toString()});
        }

        if (!triedToSubmit) {
            return;
        }
        if (invalidFormInput) {
            setError(ErrorEnum.INVALID_FORM_INPUT);
            return;
        }

        if (missingOktaConfiguration) {
            setError(ErrorEnum.GENERIC_ERROR);
        }
    }, [
        triedToSubmit,
        invalidFormInput,
        missingOktaConfiguration,
        queryParams,
        history,
        location.search
    ]);

    return {error};
}
