import {useQuery} from '@apollo/client';

import {City} from '../types/__generated__/graphql';
import {GET_CITY_SEARCH_RESULTS} from './queries';

export function useCitySearchResults(search_key: string) {
    const {data, loading, error, refetch} = useQuery(GET_CITY_SEARCH_RESULTS, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'network-only',
        variables: {search_key},
        skip: search_key === ''
    });

    const citySearchResults: City[] =
        data?.citySearchResults.map(city => {
            return {
                __typename: 'City',
                id: city.id,
                name: `${city.name}, ${city.countryName}`,
                countryName: city.countryName
            } as City;
        }) || [];

    return {
        citySearchResults: citySearchResults || [],
        loading,
        error,
        refetch
    };
}
