import {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';

import {
    CANDIDATE_ERRORS_KEY,
    ERROR_TYPES,
    FormType,
    JOB_ERRORS_KEY
} from '@/pages/User/components/RecommendationRelevanceForm/constants';
import {RemoveValidationError} from '@/pages/common/RelevanceForm/types';

export function useFormValidationErrors(type: FormType) {
    const [formValidationErrors, setFormValidationErrors] = useState<string[]>([]);
    const intl = useIntl();

    const ERRORS_KEY = type === FormType.CANDIDATE ? CANDIDATE_ERRORS_KEY : JOB_ERRORS_KEY;

    const removeValidationError: RemoveValidationError = useCallback(
        (error: string) => {
            const existingFormValidationErrors = [...formValidationErrors];
            const index = existingFormValidationErrors.indexOf(error);
            if (index > -1) {
                existingFormValidationErrors.splice(index, 1);
            }

            setFormValidationErrors(existingFormValidationErrors);
        },
        [formValidationErrors]
    );

    const getErrorText = useCallback(
        (error: string) => {
            return intl.formatMessage({id: `${ERRORS_KEY}.${ERROR_TYPES[error]}`});
        },
        [intl, ERRORS_KEY]
    );

    return {
        formValidationErrors,
        setFormValidationErrors,
        removeValidationError,
        getErrorText
    };
}
