import {useCallback, useState} from 'react';

import {ERROR_TYPES} from '@/pages/User/components/RecommendationRelevanceForm/constants';
import {
    GetSelectableJobFamilySpecialities,
    Option,
    RemoveValidationError
} from '@/pages/common/RelevanceForm/types';

export function useJobFamilyLogic(
    getSelectableJobFamilySpecialities: GetSelectableJobFamilySpecialities,
    removeValidationError: RemoveValidationError
) {
    const [jobFamily, setJobFamily] = useState<string>('');
    const [selectableJobFamilySpecialities, setSelectableJobFamilySpecialities] = useState<
        Option[]
    >([]);
    const [jobFamilySpecialties, setJobFamilySpecialities] = useState<string[]>([]);

    const handleJobFamilySelection = useCallback(
        (option: Option | null) => {
            if (option) {
                setJobFamily(option.id);
                if (jobFamily !== option.id) {
                    setSelectableJobFamilySpecialities(
                        getSelectableJobFamilySpecialities(option.id)
                    );
                    setJobFamilySpecialities([]);
                }
            }
        },
        [jobFamily, getSelectableJobFamilySpecialities]
    );

    const handleJobFamilySpecialitySelection = useCallback(
        (option: string) => {
            const existingJobFamilySpecialities = [...jobFamilySpecialties];
            const index = existingJobFamilySpecialities.indexOf(option);
            if (index > -1) {
                existingJobFamilySpecialities.splice(index, 1);
            } else {
                existingJobFamilySpecialities.push(option);
                removeValidationError(ERROR_TYPES.JOB_FAMILY);
            }

            setJobFamilySpecialities(existingJobFamilySpecialities);
        },
        [jobFamilySpecialties, removeValidationError]
    );

    return {
        jobFamily,
        setJobFamily,
        handleJobFamilySelection,
        jobFamilySpecialties,
        selectableJobFamilySpecialities,
        setSelectableJobFamilySpecialities,
        setJobFamilySpecialities,
        handleJobFamilySpecialitySelection
    };
}
