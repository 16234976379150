import {defineMessages} from 'react-intl';

export default defineMessages({
    addLink: {
        id: 'app.textFormatting.addLink'
    },
    editLink: {
        id: 'app.textFormatting.editLink'
    },
    cancel: {
        id: 'app.general.cancel'
    },
    save: {
        id: 'app.general.save'
    },
    text: {
        id: 'app.general.text'
    },
    link: {
        id: 'app.textFormatting.link'
    },
    removeLink: {
        id: 'app.textFormatting.removeLink'
    },
    invalidText: {
        id: 'app.formatting.text'
    },
    invalidLink: {
        id: 'app.textFormatting.editLink.link.error'
    }
});
