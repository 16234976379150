import {secondaryColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {ColorVariables} from '@/componentLibrary/tokens/types';

export type ContributionType = {
    id: keyof RoleFitContributionTypes;
    color: ColorVariables;
};

export type RoleFitContributionTypes = {
    [key: string]: ContributionType;
    LOGIC_TEST: ContributionType;
    PERSONALITY_TEST: ContributionType;
};

export const ROLE_FIT_CONTRIBUTION_TYPES: RoleFitContributionTypes = {
    LOGIC_TEST: {
        id: 'LOGIC_TEST',
        color: secondaryColorTokens.brown000() as ColorVariables
    },
    PERSONALITY_TEST: {
        id: 'PERSONALITY_TEST',
        color: secondaryColorTokens.blue000() as ColorVariables
    }
};
