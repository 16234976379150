import {useEffect, useState} from 'react';

import {getConfig} from '@/config';
import {logger} from '@/services/logrocket';

export const HubspotTracking = () => {
    const isInitialized = useHubspotTracking();

    useEffect(() => {
        if (!isInitialized) {
            return;
        }
        logger.log('Loaded hubspot tracking for portal id=%o', getConfig().HUBSPOT_PORTAL_ID);
    }, [isInitialized]);

    return null;
};
function useHubspotTracking() {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (window.TestingMode) {
            console.log('Playwright detected, skipping Hubspot');
            return;
        }
        (function () {
            const parent = document.getElementsByTagName('script')[0].parentNode;
            if (parent === null) {
                return;
            }

            const script = document.createElement('script');

            script.async = true;
            script.defer = true;
            script.id = 'hs-script-loader';
            script.type = 'text/javascript';
            script.src = `https://js.hs-scripts.com/${getConfig().HUBSPOT_PORTAL_ID}.js`;
            script.onload = () => {
                setInitialized(true);
            };
            script.onerror = () => {
                logger.error('Error loading hubspot tracking');
            };
            parent.appendChild(script);
        })();
    }, []);
    return initialized;
}
