import React, {Dispatch, MutableRefObject, SetStateAction} from 'react';

import {Toast, UseToastsOptsInternal} from './types';

const updateToasts = (
    toast: Toast,
    setToasts: Dispatch<SetStateAction<Toast[]>>,
    options: UseToastsOptsInternal
) => {
    let existingToast = false;

    setToasts(prev => {
        if (toast) {
            const existingToastIndex = prev.findIndex(item => item.id === toast.id);
            existingToast = existingToastIndex !== -1;

            if (existingToast) {
                const newToast = {...toast};
                options.onToastAdded?.(newToast);
                prev[existingToastIndex] = newToast;
                return [...prev];
            }

            options.onToastAdded?.(toast);
            return [...prev, {...toast}];
        }
        return prev;
    });

    return existingToast;
};

export const onToastAdded = ({
    toast,
    setToasts,
    options,
    timeouts,
    removeToast
}: {
    toast: Toast;
    setToasts: Dispatch<React.SetStateAction<Toast[]>>;
    options: UseToastsOptsInternal;
    timeouts: MutableRefObject<{[key: string]: NodeJS.Timeout}>;
    removeToast: (id: string) => void;
}) => {
    const existingToast = updateToasts(toast, setToasts, options);

    if (toast.removeAfterMs || options.removeToastsAfterMs) {
        if (existingToast && timeouts.current[toast.id]) {
            clearTimeout(timeouts.current[toast.id]);
        }
        if (!toast.persist) {
            timeouts.current[toast.id] = setTimeout(() => {
                removeToast(toast.id);
            }, toast.removeAfterMs ?? options.removeToastsAfterMs);
        }
    }
};
