import styled from 'styled-components';

import {BorderBaseDefaultRule} from '@/componentLibrary/tokens/customVariables';
import {BorderRadiusBaseSmall, SpacingXxlarge} from '@/componentLibrary/tokens/variables';

import {Skeleton} from '../../skeleton/Skeleton';
import {SkeletonProps} from '../../skeleton/types';

export const OrganizationAvatarSkeleton = styled(Skeleton).attrs<SkeletonProps>(
    ({height, width, borderRadius}) => ({
        height: height || SpacingXxlarge,
        width: width || SpacingXxlarge,
        borderRadius: borderRadius || BorderRadiusBaseSmall
    })
)`
    ${BorderBaseDefaultRule}
    display: inline-block;
    position: relative;
`;
