export const REAL_TIME_EVENTS = {
    ROLE_FIT_STORED: 'ROLE_FIT_STORED',
    NEW_LOGIC_RESULT: 'NEW_LOGIC_RESULT',
    JOB_POSITION_CREATED: 'JOB_POSITION_CREATED',
    CANDIDATE_REMINDER_SENT: 'CANDIDATE_REMINDER_SENT',
    JOB_RECOMMENDATIONS_CREATED_FOR_USER: 'JOB_RECOMMENDATIONS_CREATED_FOR_USER'
};

export const DEBOUNCE_WAIT = 2000;
export const DEBOUNCE_MAX_WAIT = 10000;
