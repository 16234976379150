import {useCallback} from 'react';

import {SegmentedControlSize} from '@/componentLibrary/components/SegmentedControl/types';

export function useSegmentedControlLogic(size: SegmentedControlSize) {
    return useCallback(() => {
        switch (size) {
            case SegmentedControlSize.LARGE:
                return 68;
            case SegmentedControlSize.SMALL:
                return 28;
            default:
                return 44;
        }
    }, [size]);
}
