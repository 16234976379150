import {useCallback, useMemo} from 'react';

import {GetUserOrganizationsWithSharedTests_Me_UserOrganization} from '@/api/users/types';
import {useMyUserOrganizationsWithSharedTests} from '@/api/users/useMyUserOrganizationsWithSharedTests';
import {isDefined} from '@/utils/typeGuards/isDefined';

export function useUserOrganizationSettingsLogic() {
    const {
        loading,
        error,
        refetch,
        userId,
        userOrganizations,
        sharedLogicTests,
        sharedPersonalityTests
    } = useMyUserOrganizationsWithSharedTests();

    const getTestCompletedAt = useCallback((result, sharedAt) => {
        if (!result) {
            return null;
        }

        const latestAnswerAtTimestamp = Date.parse(result.latestAnswerAt);
        const sharedAtTimestamp = Date.parse(sharedAt);
        return sharedAtTimestamp > latestAnswerAtTimestamp
            ? sharedAtTimestamp
            : latestAnswerAtTimestamp;
    }, []);

    const buildOrganization = useCallback(
        (userOrganization: GetUserOrganizationsWithSharedTests_Me_UserOrganization) => {
            const {
                organizationId,
                organizationName,
                organizationLogo,
                organizationUnregisteredCandidateFlowEnabled,
                acceptedAt,
                jobApplicationsWithdrawnAt,
                isCandidate,
                isEmployment
            } = userOrganization;

            const sharedLogicTest = sharedLogicTests.find(
                item => item.organizationId === organizationId
            );
            const sharedPersonalityTest = sharedPersonalityTests.find(
                item => item.organizationId === organizationId
            );
            const logicTestCompletedAt = sharedLogicTest
                ? getTestCompletedAt(
                      sharedLogicTest.logicalAbilityEstimate,
                      sharedLogicTest.sharedAt
                  )
                : null;
            const personalityTestCompletedAt = sharedPersonalityTest
                ? getTestCompletedAt(
                      sharedPersonalityTest.personalityIrtResult,
                      sharedPersonalityTest.sharedAt
                  )
                : null;

            return {
                id: organizationId,
                name: organizationName,
                logo: organizationLogo,
                acceptedAt,
                jobApplicationsWithdrawnAt,
                unregisteredCandidateFlowEnabled: organizationUnregisteredCandidateFlowEnabled,
                isCandidate,
                isEmployment,
                logicTestCompletedAt,
                personalityTestCompletedAt
            };
        },
        [sharedLogicTests, sharedPersonalityTests, getTestCompletedAt]
    );

    const organizations = useMemo(() => {
        if (!userOrganizations.length) {
            return [];
        }

        const isUniqueUserOrganization = (
            set => (organization: GetUserOrganizationsWithSharedTests_Me_UserOrganization) =>
                !set.has(organization.organizationId) && set.add(organization.organizationId)
        )(new Set());
        const candidateOrganizations = userOrganizations
            .filter(isUniqueUserOrganization)
            .map(buildOrganization);

        return candidateOrganizations.sort(
            (
                {jobApplicationsWithdrawnAt: withdrawnA},
                {jobApplicationsWithdrawnAt: withdrawnB}
            ) => {
                if (!isDefined(withdrawnA)) {
                    return -1;
                }
                if (!isDefined(withdrawnB)) {
                    return 1;
                }
                return new Date(withdrawnA).getTime() - new Date(withdrawnB).getTime();
            }
        );
    }, [userOrganizations, buildOrganization]);

    return {userId, organizations, loading, error, refetch};
}
