import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {SubmitChallengeAssignmentMutation} from '@/api/types/__generated__/graphql';
import {GET_MY_JOB_APPLICATIONS} from '@/api/users/queries';

import {MutationHookReturnType} from '../../types/genericApi/types';
import {SUBMIT_CHALLENGE_ASSIGNMENT} from './mutations';

export function useSubmitChallengeAssignment(
    assignmentId?: string
): MutationHookReturnType<SubmitChallengeAssignmentMutation> {
    const [mutate, {error, loading}] = useMutation<SubmitChallengeAssignmentMutation>(
        SUBMIT_CHALLENGE_ASSIGNMENT,
        {
            refetchQueries: [
                {
                    query: GET_MY_JOB_APPLICATIONS
                }
            ]
        }
    );

    const submitChallengeAssignment = useCallback(() => {
        const variables = {assignmentId};
        return mutate({variables});
    }, [assignmentId, mutate]);

    return [submitChallengeAssignment, {error, loading}];
}
