import {FC} from 'react';

import {Alert} from '@/componentLibrary/blocks/Modals/Alert/Alert';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {useRejectSharingCandidateDataWithOrganization} from '@/api/users/useRejectSharingCandidateDataWithOrganization';
import messages from './messages';
import {RejectInvitationModalProps} from './types';

export const RejectInvitationModal: FC<RejectInvitationModalProps> = ({
    userOrganization,
    close,
    onRejected
}) => {
    const phrases = useExtractPhraseConstants(messages);

    const {rejectInvitation, loading: rejectingCandidateData} =
        useRejectSharingCandidateDataWithOrganization(onRejected, () =>
            toast({type: 'error'})
        );

    const reject = () => {
        if (userOrganization.isCandidate) {
            rejectInvitation(userOrganization.organizationId);
        }
    };

    return (
        <Alert
            onLeftAction={close}
            onRightAction={reject}
            title={phrases.title}
            subtitle={phrases.info}
            leftButton={phrases.cancel}
            rightButton={phrases.reject}
            isLoading={rejectingCandidateData}
        />
    );
};
