export const START_INDEX_URL = '/start';

export const userLinks = {
    myWorkspaces: () => `${START_INDEX_URL}`,
    myWorkspacesWithRedirect: (redirect: string) => `${START_INDEX_URL}?redirect=${redirect}`,
    myOrganizations: () => `${START_INDEX_URL}/organizations`,
    myApplications: () => `${START_INDEX_URL}/applications`,
    myJobs: () => `${START_INDEX_URL}/jobs`,
    jobApplication: (organizationId: string | number) =>
        `${START_INDEX_URL}/apply/${organizationId}`,
    accountSettings: () => `${START_INDEX_URL}/settings`,
    codingTestAssignmentAccess: (id: string) => `${START_INDEX_URL}/assignment/${id}/access`
};
