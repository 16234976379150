import {defineMessages} from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'app.assessment.candidatePage.candidateSettings.candidateJobs.anonymizeUser.anonymizeUserModal.cancel'
    },
    anonymizeUser: {
        id: 'app.assessment.candidatePage.candidateSettings.candidateJobs.anonymizeUser.anonymizeUserModal.anonymizeUser'
    },
    description: {
        id: 'app.assessment.candidatePage.candidateSettings.candidateJobs.anonymizeUser.anonymizeUserModal.description'
    },
    modalTitle: {
        id: 'app.assessment.candidatePage.candidateSettings.candidateJobs.anonymizeUser.anonymizeUserModal.modalTitle'
    },
    userAnonymized: {
        id: 'app.assessment.candidatePage.candidateSettings.candidateJobs.anonymizeUser.anonymizeUserModal.userAnonymized'
    }
});
