import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Dropdown} from '@/componentLibrary/components/Dropdowns/Dropdown';
import {RadioButton} from '@/componentLibrary/components/controls/RadioButton';
import {userDemographicsMessages} from '@/pages/UserDemographics/messages';

import {InputField, InputText} from '../styled';
import {RATHER_NOT_SAY} from './constants';
import {useSelectLanguageLogic} from './logic';
import {SelectLanguageProps} from './types';

export function SelectLanguage({language, setLanguage}: SelectLanguageProps) {
    const {formatMessage} = useIntl();
    const {onLanguageChange, onRatherNotSay, languageItems, selectedLanguage} =
        useSelectLanguageLogic(setLanguage, language);

    return (
        <InputField>
            <SelectLanguageWrapper>
                <InputText>{formatMessage(userDemographicsMessages.nativeLanguage)}</InputText>
                <Dropdown
                    name="demographics-form-select-language"
                    items={languageItems}
                    placeholder={formatMessage(userDemographicsMessages.selectNativeLanguage)}
                    onSelect={onLanguageChange}
                    noFocusTrap
                    selectedItem={language !== RATHER_NOT_SAY ? selectedLanguage : null}
                    data-testid="select-language"
                />
            </SelectLanguageWrapper>
            <RadioButton
                value={RATHER_NOT_SAY}
                isSelected={language === RATHER_NOT_SAY}
                onSelect={onRatherNotSay}
                label={<FormattedMessage {...userDemographicsMessages.ratherNotSay} />}
            />
        </InputField>
    );
}

const SelectLanguageWrapper = styled.div`
    margin-bottom: 20px;
`;
