import {Link} from '@tiptap/extension-link';
import {Transaction} from '@tiptap/pm/state';
import {ChainedCommands, KeyboardShortcutCommand} from '@tiptap/react';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        customLink: {
            setLink: (attributes: {
                href: string;
                target?: string | null;
                rel?: string | null;
                class?: string | null;
            }) => ReturnType;
            toggleLink: (attributes: {
                href: string;
                target?: string | null;
                rel?: string | null;
                class?: string | null;
            }) => ReturnType;
            unsetLink: () => ReturnType;
            setLinkWithText: (attributes: {link: string; linkText: string}) => ReturnType;
        };
    }
}

export const getLinkWithText = ({onShortCut}: {onShortCut: KeyboardShortcutCommand}) => {
    return Link.extend({
        addKeyboardShortcuts() {
            return {
                'Mod-k': onShortCut
            };
        },
        addCommands() {
            return {
                ...this.parent?.(),
                setLinkWithText
            };
        }
    });
};

const setLinkWithText =
    (options: {link: string; linkText: string}) =>
    ({chain}: {chain: () => ChainedCommands}) => {
        const {link, linkText} = options;
        if (!link || !linkText) {
            return false;
        }

        const insertText = ({tr}: {tr: Transaction}) => {
            tr.insertText(linkText);
            return true;
        };

        return chain()
            .focus()
            .extendMarkRange('link')
            .setLink({href: link})
            .command(insertText)
            .run();
    };
