import {gql} from '@/api/types/__generated__';

export const GET_TEST_PROFILE = gql(`
    query getTestProfile($id: String!) {
        testProfile(testProfileId: $id) {
            ...TestProfileFields
        }
    }
`);

export const GET_TEST_PROFILES_FOR_ORGANIZATION = gql(`
    query getTestProfilesForOrganization($id: Int!, $withJobPositions: Boolean!) {
        organization(id: $id) {
            id
            testProfiles {
                ...TestProfileFields
                jobPositions @include(if: $withJobPositions) {
                    id
                    currentStatus
                }
            }
        }
    }
`);

export const GET_TEST_PROFILES_SUMMARY_FOR_ORGANIZATION = gql(`
    query getTestProfilesSummaryForOrganization($id: Int!) {
        organization(id: $id) {
            id
            testProfiles {
                id
                created
                modified
                name
                isAlvaProfile
                numberOfOpenJobPositions
                numberOfArchivedJobPositions
                numberOfUnlistedJobPositions
            }
        }
    }
`);

export const GET_DEFAULT_TEST_PROFILE_FOR_ORGANIZATION = gql(`
    query getDefaultTestProfileForOrganization($id: Int!) {
        organization(id: $id) {
            id
            aggregateVersion
            defaultTestProfile {
                ...TestProfileFields
            }
        }
    }
`);

export const GET_TEST_PROFILE_FOR_JOB_POSITION = gql(`
    query getTestProfileForJobPosition($id: Int!) {
        jobPosition(id: $id) {
            id
            requirePersonalityTest
            requireLogicTest
            requireLogicIrtTest
            requireInterviews
            selectedTestProfile {
                ...TestProfileFields
            }
        }
    }
`);
