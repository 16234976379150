import {useIntl} from 'react-intl';

import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';

import {excerpt} from '@/utils/misc';
import {TEST_STATUSES, TEST_TYPES} from '../constants';
import messages from '../messages';
import {TestsProgressWrapper} from '../styled';
import {RenderAssessmentProgressProps} from '../types';
import {AssessmentsCompleted} from './AssessmentsCompleted';
import {TestProgressBox} from './TestProgressBox';

export const RenderAssessmentProgress = ({
    assessmentStatus,
    personalityTestStatus,
    logicTestStatus,
    codingTestAllStatuses
}: RenderAssessmentProgressProps) => {
    const intl = useIntl();

    if (assessmentStatus === TEST_STATUSES.COMPLETED) {
        return <AssessmentsCompleted />;
    }

    return (
        <TestsProgressWrapper>
            {personalityTestStatus && (
                <TestProgressBox
                    status={personalityTestStatus}
                    testType={TEST_TYPES.PERSONALITY_TEST}
                />
            )}
            {logicTestStatus && (
                <TestProgressBox status={logicTestStatus} testType={TEST_TYPES.LOGIC_TEST} />
            )}
            {codingTestAllStatuses?.map(
                ({id, status, jobPositionName, isOpenForLateSubmission}) => (
                    <TestProgressBox
                        key={id}
                        status={
                            isOpenForLateSubmission
                                ? ChallengeAssignmentStatus.IN_PROGRESS
                                : status
                        }
                        name={`${intl.formatMessage(messages.codingTest)} for ${excerpt(jobPositionName || '', 100)}`}
                    />
                )
            ) ?? null}
        </TestsProgressWrapper>
    );
};
