import {useCallback, useState} from 'react';

import {useAcceptSharingCandidateDataWithOrganization} from '@/api/users/useAcceptSharingCandidateDataWithOrganization';

import {PendingOrganizationProps} from '../../../MyApplications/components/PendingOrganization/types';

export function useAcceptInvitationLogic(
    userOrganization: PendingOrganizationProps['userOrganization'],
    displaySuccessToast: () => void,
    displayErrorToast: () => void
) {
    const [doAcceptSharingCandidateData, {loading: loadingCandidateAccepting}] =
        useAcceptSharingCandidateDataWithOrganization();

    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [isRejected, setIsRejected] = useState(false);

    const showRejectInvitationModal = useCallback(() => {
        setIsRejectModalOpen(true);
    }, []);

    const closeRejectInvitationModal = useCallback(() => {
        setIsRejectModalOpen(false);
    }, []);

    const onInvitationRejected = useCallback(() => {
        setIsRejectModalOpen(false);
        setIsRejected(true);
    }, []);

    const handleAccept = useCallback(() => {
        doAcceptSharingCandidateData(userOrganization.organizationId)
            .then(({data}) => {
                if (data?.acceptSharingCandidateDataWithOrganization?.ok) {
                    displaySuccessToast();
                } else {
                    throw new Error(
                        data?.acceptSharingCandidateDataWithOrganization?.errorMessage ??
                            'Unknown error'
                    );
                }
            })
            .catch(() => {
                displayErrorToast();
            });
    }, [
        doAcceptSharingCandidateData,
        displaySuccessToast,
        displayErrorToast,
        userOrganization
    ]);

    return {
        loading: loadingCandidateAccepting,
        isRejectModalOpen,
        isRejected,
        showRejectInvitationModal,
        closeRejectInvitationModal,
        onInvitationRejected,
        handleAccept
    };
}
