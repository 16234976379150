import styled from 'styled-components';

export type FlexLayoutProps = {
    direction?: string;
    wrap?: string;
    alignItems?: string;
    justifyContent?: string;
    $gap?: string;
    $fullWidth?: boolean;
};

export const FlexLayout = styled.div<FlexLayoutProps>`
    display: flex;
    flex-flow: ${({direction}) => direction ?? 'row'} ${({wrap}) => wrap ?? 'nowrap'};
    align-items: ${({alignItems}) => (alignItems ? alignItems : 'normal')};
    justify-content: ${({justifyContent}) => (justifyContent ? justifyContent : 'normal')};
    ${({$gap}) => $gap && `gap: ${$gap};`}
    ${({$fullWidth}) => $fullWidth && `width: 100%;`}
`;
