import {InterviewContentLibraryItemTranslation} from '@/api/assessment/interviews/useOrganizationInterviewContentLibraryItem';
import {CriterionTranslation, ItemType} from '@/api/types/__generated__/graphql';
import {Icons} from '@/componentLibrary/components/icons/constants';

const HTML_REGEX = /<\/?[a-z][\s\S]*>/i;

const convertTextToHtmlList = (text: string) => {
    const lines = text.split('\n\n');
    const htmlList = lines.map(line => `<li>${line.trim()}</li>`).join('');
    return `<ul>${htmlList}</ul>`;
};

export const toHtml = (content?: string | null) => {
    if (!content || content === '<p></p>') {
        return '';
    }

    if (HTML_REGEX.test(content)) {
        return content;
    }

    return convertTextToHtmlList(content);
};

export const isCriterionTranslation = (
    translation: InterviewContentLibraryItemTranslation
): translation is CriterionTranslation => {
    return translation.__typename === 'CriterionTranslation';
};

export const isCriterion = ({type}: {type: ItemType}) => type === ItemType.CRITERION;

export const isInstruction = ({type}: {type: ItemType}) => type === ItemType.INSTRUCTION;

export const getContentLibraryItemIcon = ({type}: {type: ItemType}) =>
    type === ItemType.CRITERION ? Icons.HELP_CENTER : Icons.ARTICLE;
