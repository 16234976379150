import {useCallback, useEffect, useMemo, useState} from 'react';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {
    ChallengeAssignmentAutoScoreResult,
    ChallengeAssignmentAutoScoreResultState
} from '@/api/types/__generated__/graphql';
import {useMyJobApplications} from '@/api/users/useMyJobApplications';
import {TEST_SCENARIOS_MODAL_MESSAGES} from '../messages';

export const useTestResultsModal = (organizationId?: number) => {
    const phrases = useExtractPhraseConstants(TEST_SCENARIOS_MODAL_MESSAGES);

    const [showTestResults, setShowTestResults] = useState(false);

    const {
        jobApplications,
        refetch,
        startPolling: startPollingJobApplications,
        stopPolling: stopPollingJobApplications
    } = useMyJobApplications(organizationId, {
        withAutomatedScore: true
    });

    const updatedChallengeAssignment = useMemo(
        () =>
            jobApplications?.find(jobApplication => jobApplication?.challengeAssignment?.id)
                ?.challengeAssignment ?? null,
        [jobApplications]
    );

    const isEmptyDetailsTestResults = useMemo(
        () =>
            updatedChallengeAssignment?.testScoreEnabled &&
            updatedChallengeAssignment?.automatedScore === null,
        [
            updatedChallengeAssignment?.automatedScore,
            updatedChallengeAssignment?.testScoreEnabled
        ]
    );

    useEffect(() => {
        if (showTestResults && isEmptyDetailsTestResults) {
            startPollingJobApplications(5000);
        }
        if (showTestResults && !isEmptyDetailsTestResults) {
            stopPollingJobApplications();
        }
        return () => {
            stopPollingJobApplications();
        };
    }, [
        showTestResults,
        isEmptyDetailsTestResults,
        startPollingJobApplications,
        stopPollingJobApplications
    ]);

    const countResult = (results: ChallengeAssignmentAutoScoreResult[] | null) => {
        if (!results) {
            return '';
        }
        const resultCount = results.filter(
            result => result.result === ChallengeAssignmentAutoScoreResultState.PASSED
        ).length;
        if (!results.length) {
            return `0 ${textTestResults.score}`;
        }
        return `${resultCount}/${results.length} ${textTestResults.score}`;
    };

    const textTestResults = useMemo(
        () => ({
            title: phrases.title,
            subtitle: phrases.subtitle,
            leftButton: phrases.leftButton,
            rightButton: phrases.rightButton,
            testScenario: phrases.testScenario,
            results: phrases.results,
            score: phrases.score,
            resultsGithub: phrases.resultsGithub,
            fetchingResults: phrases.fetchingResults
        }),
        [phrases]
    );
    const onShowTestResults = useCallback(() => {
        setShowTestResults(true);
        refetch();
    }, [refetch]);

    const onHideTestResults = useCallback(() => setShowTestResults(false), []);

    return {
        textTestResults,
        showTestResults,
        onShowTestResults,
        onHideTestResults,
        countResult,
        updatedChallengeAssignment,
        isEmptyDetailsTestResults
    };
};
