import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {AuthenticationPageLayout} from '@/pages/common/authentication/AuthenticationPageLayout';

import {CreateAccountForm} from './components/CreateAccountForm';
import {useErrorLogic} from './hooks/useErrorLogic';
import {useRegisterUserLogic} from './hooks/useRegisterUserLogic';
import {RegisterUserProps} from './types';

export function RegisterUser(props: RegisterUserProps) {
    const {token, onSuccess, signupTokenData} = props;

    const {
        userId,
        email,
        firstName,
        lastName,
        language,
        enforcedAuthenticationMethod,
        registrationType,
        organizationOktaConfiguration
    } = signupTokenData;

    const {handleError} = useErrorLogic();
    const {error, loading, registerUser} = useRegisterUserLogic(
        token,
        onSuccess,
        userId,
        language,
        handleError
    );

    if (error) {
        return <ErrorState error={error} />;
    }

    return (
        <AuthenticationPageLayout>
            <CreateAccountForm
                token={token}
                save={registerUser}
                defaultFirstName={firstName || ''}
                defaultLastName={lastName || ''}
                language={language}
                enforcedAuthenticationMethod={enforcedAuthenticationMethod}
                registrationType={registrationType}
                email={email || ''}
                emailPasswordLoading={loading}
                organizationOktaConfiguration={organizationOktaConfiguration}
            />
        </AuthenticationPageLayout>
    );
}
