import {FunctionComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {graphqlError} from '@/componentLibrary/blocks/ErrorState';
import {LoadingScreen} from '@/componentLibrary/components/LoadingScreen';
import {
    RequireAuthenticationOptions,
    RequireAuthenticationWithoutRouterProps
} from '@/services/auth/types';

import {RequireScope} from './RequireScope';
import {logout} from './actions';
import {NotAuthorized} from './components/NotAuthorized';

export const RequireAuthenticationWithoutRouter: FunctionComponent<
    RequireAuthenticationWithoutRouterProps
> = ({
    children,
    onError = graphqlError,
    scope,
    permissions,
    requireSuperUser,
    location,
    logoutAndRedirectIfNotLoggedIn = true
}) => {
    const renderLoading = () => <LoadingScreen />;

    const renderFallback = () => {
        if (logoutAndRedirectIfNotLoggedIn) {
            return <NotAuthorized redirectPath={`/logout?redirect=${location.pathname}`} />;
        }

        return null;
    };

    return (
        <RequireScope
            loading={renderLoading}
            fallback={renderFallback}
            scope={scope}
            permissions={permissions}
            requireSuperUser={requireSuperUser}
            onError={onError}
        >
            {() => children()}
        </RequireScope>
    );
};

function mapStateToProps() {
    return {};
}

const mapDispatchToProps = {logout};

export const RequireAuthentication = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RequireAuthenticationWithoutRouter)
);

export function requireAuthentication<T>(
    WrappedComponent: FunctionComponent<T>,
    options: RequireAuthenticationOptions = {}
) {
    const RequireAuthenticationHoc: FunctionComponent<T> = props => (
        <RequireAuthentication
            scope={options.scope}
            permissions={options.permissions}
            requireSuperUser={options.requireSuperUser}
        >
            {() => <WrappedComponent {...props} />}
        </RequireAuthentication>
    );
    return RequireAuthenticationHoc;
}
