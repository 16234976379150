import {defineMessages} from 'react-intl';

export default defineMessages({
    logicTest: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitContribution.roleFitContributionChart.logicTest'
    },
    missingTestResults: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitContribution.roleFitContributionChart.missingTestResults'
    }
});
