import {useMemo} from 'react';

import {useJobApplication} from '@/api/assessment/jobApplications/useJobApplication';
import {GetJobApplication_JobApplication} from '@/api/assessment/types';
import {useUserResults} from '@/api/users/useUserResults';

export function useTestResultsLogic(userId: number, jobPositionId: number) {
    const {
        user,
        loading: loadingUserResults,
        error: userResultsError,
        refetch: refetchUserResults
    } = useUserResults(userId, true);

    const {
        jobApplication,
        loading: loadingJobApplication
    }: {jobApplication: GetJobApplication_JobApplication | null; loading: boolean} =
        useJobApplication(jobPositionId, userId, {
            withRoleFitV2: true
        });

    const personalityTestResults = useMemo(() => {
        if (!user) {
            return {};
        }

        const personalityIrtResult = user.personalityIrtResult ?? undefined;
        return {
            personalityIrtResult,
            leadershipProfile: personalityIrtResult?.leadershipProfile
        };
    }, [user]);

    return {
        loadingUserResults,
        userResultsError,
        refetchUserResults,
        personalityTestResults,
        jobApplication,
        loadingJobApplication
    };
}
