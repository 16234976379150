export const getValueFromKeyCode = (keyCode: number) => {
    switch (keyCode) {
        case 49:
            return 1;
        case 50:
            return 2;
        case 51:
            return 3;
        case 52:
            return 4;
        case 53:
            return 5;
        default:
            return null;
    }
};
