import styled, {css} from 'styled-components';

import {ButtonBase} from '@/componentLibrary/components/buttons/ButtonBase';
import {
    ColorBaseGrey900,
    ColorBgActionPrimaryActive,
    ColorBgActionPrimaryDefault,
    ColorBgActionPrimaryDisabled,
    ColorBgActionPrimaryHover,
    ColorFgActionPrimarySecondaryActive,
    ColorFgActionPrimarySecondaryDefault,
    ColorFgActionPrimarySecondaryDisabled,
    ColorFgActionPrimarySecondaryHover
} from '@/componentLibrary/tokens/variables';

import {ElementType, forwardRef} from 'react';
import {BUTTON_VARIANTS} from './constants';
import {
    AnchorButtonProps,
    ButtonBaseProps,
    ButtonProps,
    LinkButtonProps,
    NavLinkButtonProps
} from './types';

const primaryButtonStyles = css<ButtonBaseProps>`
    background-color: ${ColorBgActionPrimaryDefault};
    color: ${ColorFgActionPrimarySecondaryDefault};

    ${({isLoading}) =>
        isLoading &&
        css`
            &::after {
                border-color: ${ColorBaseGrey900};
                border-radius: 290486px;
                border-right-color: transparent;
                border-top-color: transparent;
            }
        `}

    &:hover {
        background-color: ${ColorBgActionPrimaryHover};
        color: ${ColorFgActionPrimarySecondaryHover};
    }

    &.active,
    &:active {
        background-color: ${ColorBgActionPrimaryActive};
        color: ${ColorFgActionPrimarySecondaryActive};
    }

    ${({isSelected}) =>
        isSelected &&
        css`
            &,
            &:hover,
            &:active {
                background-color: ${ColorBgActionPrimaryActive};
                color: ${ColorFgActionPrimarySecondaryActive};
            }
        `};

    &:disabled {
        color: ${ColorFgActionPrimarySecondaryDisabled};
        background-color: ${ColorBgActionPrimaryDisabled};
    }

    :focus:not(:focus-visible) {
        /* Remove focus indication when a mouse is used */
        outline: none;
    }

    :focus-visible {
        outline: 0.25rem solid ${ColorBgActionPrimaryDefault};
        outline-offset: 0.125rem;
    }
`;

const StyledPrimaryButton = styled(ButtonBase)<ButtonBaseProps>`
    ${primaryButtonStyles}
`;

const {NAVLINK, LINK, ANCHOR} = BUTTON_VARIANTS;

export const PrimaryButton = forwardRef<ElementType, ButtonProps>((props, ref) => (
    <StyledPrimaryButton {...props} ref={ref} />
));

export const PrimaryNavLinkButton = forwardRef<ElementType, NavLinkButtonProps>(
    (props, ref) => <StyledPrimaryButton {...props} ref={ref} variant={NAVLINK} />
);

export const PrimaryLinkButton = forwardRef<ElementType, LinkButtonProps>((props, ref) => (
    <StyledPrimaryButton {...props} ref={ref} variant={LINK} />
));

export const PrimaryAnchorButton = forwardRef<ElementType, AnchorButtonProps>((props, ref) => (
    <StyledPrimaryButton {...props} ref={ref} variant={ANCHOR} />
));

export const SmallPrimaryButton = forwardRef<ElementType, ButtonProps>((props, ref) => (
    <StyledPrimaryButton {...props} ref={ref} small />
));

export const SmallPrimaryNavLinkButton = forwardRef<ElementType, NavLinkButtonProps>(
    (props, ref) => <StyledPrimaryButton {...props} ref={ref} small variant={NAVLINK} />
);

export const SmallPrimaryLinkButton = forwardRef<ElementType, LinkButtonProps>(
    (props, ref) => <StyledPrimaryButton {...props} ref={ref} small variant={LINK} />
);

export const SmallPrimaryAnchorButton = forwardRef<ElementType, AnchorButtonProps>(
    (props, ref) => <StyledPrimaryButton {...props} ref={ref} small variant={ANCHOR} />
);
