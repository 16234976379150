// https://plot.ly/javascript/reference/
import Plotly from 'plotly.js/dist/plotly-finance';
import BarChart from 'plotly.js/lib/bar';
import Histogram from 'plotly.js/lib/histogram';
import PieChart from 'plotly.js/lib/pie';
import Scatter from 'plotly.js/lib/scatter';
import createPlotlyComponent from 'react-plotly.js/factory';

Plotly.register([PieChart, BarChart, Histogram, Scatter]);

export const Plot = createPlotlyComponent(Plotly);
