import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {H4} from '@/componentLibrary/components/typography';
import {ColorFgDanger, SpacingMedium} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import styled from 'styled-components';
import messages from './messages';

export const InvalidResultsToken = () => {
    const phrases = useExtractPhraseConstants(messages);
    return (
        <Wrapper>
            <Icon icon={Icons.WARNING} fill={ColorFgDanger} />
            <H4 data-testid={'invalid-token-message'}>{phrases.invalidLink}</H4>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${SpacingMedium};
    margin: 0 auto;
`;
