import {Section} from '@/componentLibrary/components/layout/Section';

import {InterviewsScores} from '../../../../components/InterviewsScores';
import {InterviewRatings} from './components/InterviewRatings';
import {useCandidateInterviewPageLogic} from './logic';
import {Wrapper} from './styled';
import {CandidateInterviewPageProps} from './types';

export function CandidateInterviewPage({userId, jobPositionId}: CandidateInterviewPageProps) {
    const {
        loading,
        error,
        refetch,
        jobApplication,
        interviewAggregatedScores,
        interviews,
        hasCombinedRoleFitBetaTest
    } = useCandidateInterviewPageLogic(jobPositionId, userId);

    if (loading) {
        return <Section loading />;
    }

    if (error) {
        return <Section error={{reload: refetch}} />;
    }

    return (
        <Wrapper>
            {!hasCombinedRoleFitBetaTest && (
                <InterviewsScores
                    interviews={interviews}
                    interviewAggregatedScores={interviewAggregatedScores}
                    isLargeTitle={false}
                />
            )}
            <InterviewRatings
                jobPositionId={jobPositionId}
                jobApplication={jobApplication}
                userId={userId}
            />
        </Wrapper>
    );
}
