import {useCallback} from 'react';

import {LeadershipDimension} from '@/api/types/__generated__/graphql';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {isDefined} from '@/utils/typeGuards/isDefined';

import messages from './messages';

type PhraseKey = keyof typeof messages;

export function useLeadershipDimensionsLogic() {
    const phrases = useExtractPhraseConstants(messages);

    const getPhrase = useCallback(
        (keySegment: string, suffix?: string) => {
            const phraseKey = isDefined(suffix) ? `${keySegment}_${suffix}` : keySegment;
            return phrases[phraseKey as PhraseKey];
        },
        [phrases]
    );

    const getTitle = useCallback(
        (dimension: LeadershipDimension) => getPhrase(dimension.name),
        [getPhrase]
    );

    const getTooltipTitle = useCallback(
        (dimension: LeadershipDimension) => getPhrase(dimension.name, 'DEFINITION_TITLE'),
        [getPhrase]
    );

    const getTooltipDescription = useCallback(
        (dimension: LeadershipDimension) =>
            getPhrase(dimension.name, 'DEFINITION_DESCRIPTION'),
        [getPhrase]
    );

    const getLabel = useCallback(
        (dimension: LeadershipDimension) => getPhrase(dimension.label),
        [getPhrase]
    );

    return {getTitle, getTooltipTitle, getTooltipDescription, getLabel};
}
