import {DeepPick} from '@/types/generic.types';

import {
    FetchEmploymentsQuery,
    GetAccessGroupQuery,
    GetAccessGroupsForOrganizationQuery,
    GetAuthenticatedOrganizationSubscriptionQuery,
    GetCurrentEmploymentQuery,
    GetEmailTemplatesForOrganizationQuery,
    GetEmploymentQuery,
    GetOrganizationConfigurationQuery,
    GetOrganizationInfoQuery,
    GetOrganizationOnboardingQuery,
    GetTeamInsightsQuery,
    GetTeamsForAuthenticatedOrganizationQuery
} from '../types/__generated__/graphql';

export type UseCurrentEmploymentOptions = {
    withTeams?: boolean;
};

export type UseEmploymentLoaderOptions = {
    searchString?: string;
    orderBy?: string;
    isDescending?: boolean;
    limit?: number;
    withTeams?: boolean;
};
export enum PlanKey {
    PAYGOv2 = 'PAY_AS_YOU_GO_V2',
    GROWv2 = 'GROW_V2',
    SCALEv2 = 'SCALE_V2',
    ENTERPRISEv2 = 'ENTERPRISE1_V2'
}

export type CheckoutLinkParams = {
    organizationId: string;
    name?: string;
    successUrl: string;
    cancelUrl: string;
    subscriptionExternalId: string;
    upgradePlan?: PlanKey;
};

export type GetOrganizationOnboarding_AuthenticatedOrganization = DeepPick<
    GetOrganizationOnboardingQuery,
    'authenticatedOrganization'
>;

export type GetAuthenticatedOrganizationSubscription_AuthenticatedOrganization = DeepPick<
    GetAuthenticatedOrganizationSubscriptionQuery,
    'authenticatedOrganization'
>;

export type GetOrganizationAccessGroups_AuthenticatedOrganization_AccessGroup = DeepPick<
    GetAccessGroupsForOrganizationQuery,
    'authenticatedOrganization.accessGroups'
>[0];

export type GetEmailTemplatesForOrganization_AuthenticatedOrganization_EmailTemplate =
    DeepPick<
        GetEmailTemplatesForOrganizationQuery,
        'authenticatedOrganization.emailTemplates'
    >[0];

export type GetEmployment_Employment = DeepPick<GetEmploymentQuery, 'employment'>;

export type GetCurrentEmployment_CurrentEmployment = DeepPick<
    GetCurrentEmploymentQuery,
    'currentEmployment'
>;

export type GetAccessGroup_AccessGroup_Permission = DeepPick<
    GetAccessGroupQuery,
    'accessGroup.permissions'
>[0];

export type GetTeamInsights_Team = DeepPick<GetTeamInsightsQuery, 'team'>;

export type GetTeamInsights_Team_TeamInsights_Employment = DeepPick<
    GetTeamInsightsQuery,
    'team.teamInsights.employments'
>[0];

export type GetOrganizationTeams_OrganizationTeam = DeepPick<
    GetTeamsForAuthenticatedOrganizationQuery,
    'authenticatedOrganization.teams'
>[0];

export type GetOrganizationConfiguration_AuthenticatedOrganization = DeepPick<
    GetOrganizationConfigurationQuery,
    'authenticatedOrganization'
>;

export type GetOrganizationInfo_Organization = Exclude<
    GetOrganizationInfoQuery['organization'],
    null
>;

export type OrganizationEmployment = DeepPick<
    FetchEmploymentsQuery,
    'authenticatedOrganization.employmentsConnection.items'
>[0];
