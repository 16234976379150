import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {AuthenticationFormHeader} from '@/pages/common/authentication/AuthenticationFormHeader';

import messages from './messages';

export function FormHeader() {
    const phrases = useExtractPhraseConstants(messages);

    return <AuthenticationFormHeader title={phrases.title} />;
}
