import {CalculateCountdown} from '../types';
import {formatTimeUnit} from './formatTimeUnit';

export const calculateCountdown = ({deadline, timeLimitMinutes}: CalculateCountdown) => {
    if (!deadline) {
        const hours = Math.floor(timeLimitMinutes / 60);
        const minutes = timeLimitMinutes % 60;
        return `${formatTimeUnit(hours)}:${formatTimeUnit(minutes)}:00`;
    }

    const now = new Date();
    const deadlineDate = new Date(deadline);
    if (now >= deadlineDate) {
        return '00:00:00';
    }

    const totalSeconds = Math.ceil((deadlineDate.getTime() - now.getTime()) / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${formatTimeUnit(hours)}:${formatTimeUnit(minutes)}:${formatTimeUnit(seconds)}`;
};
