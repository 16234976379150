import {
    FontSizeMedium,
    SpacingLarge,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: calc(30vh + 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 768px) {
        margin-top: 48px;
    }
`;

export const DescriptionTitle = styled.div`
    padding-bottom: ${SpacingXsmall};
    font-size: ${FontSizeMedium};
`;

export const ParagraphWrapper = styled.div`
    padding-bottom: ${SpacingLarge};
`;

export const DetailsWrapper = styled.div`
    color: inherit;
    opacity: 0.76;
`;

export const List = styled.ul`
    text-align: left;
    margin-left: ${SpacingLarge};

    li {
        list-style: disc outside;
    }
`;
