import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {ModalBody} from '@/componentLibrary/blocks/Modals/Modal/styled';
import {ModalHeader} from '@/componentLibrary/components/Modal/ModalHeader';
import {ExternalLink} from '@/componentLibrary/components/links';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {getRepositoryUrlWithBranch} from '@/utils/misc';
import {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {Loader} from './components';
import {useLogic} from './hooks';
import messages from './messages';
import {BranchName, FrameworkName, Header, Wrapper} from './styled';
import {ImportBoilerplateProgressModalProps} from './types';

export function ImportBoilerplateProgressModal({
    onComplete,
    jobPositionId,
    userId
}: ImportBoilerplateProgressModalProps) {
    const phrases = useExtractPhraseConstants(messages);
    const {importDetails, repositoryUrl, inProgress} = useLogic({
        jobPositionId,
        userId,
        onComplete
    });

    const handleClose = () => undefined;

    const repositoryUrlWithBranch = useMemo(() => {
        if (!repositoryUrl) {
            return;
        }

        if (!importDetails?.branch) {
            return repositoryUrl;
        }

        return getRepositoryUrlWithBranch(repositoryUrl, importDetails?.branch);
    }, [repositoryUrl, importDetails?.branch]);

    return (
        <Modal withoutBody close={handleClose} hideClose>
            <ModalHeader title={phrases.title} hideClose />
            <ModalBody>
                <Wrapper>
                    <Header>{phrases.modalHeader}</Header>
                    <div>{inProgress && <Loader />}</div>
                    {importDetails?.framework && importDetails?.branch && (
                        <div>
                            <FormattedMessage
                                values={{
                                    framework: (
                                        <FrameworkName>
                                            {importDetails?.framework}
                                        </FrameworkName>
                                    ),
                                    branch: <BranchName>{importDetails?.branch}</BranchName>
                                }}
                                {...messages.details}
                            />
                        </div>
                    )}
                    <div>{phrases.redirectInfo}</div>
                    {!inProgress && (
                        <span>
                            <span>{phrases.readyInfo}</span>
                            {repositoryUrlWithBranch && (
                                <FormattedMessage
                                    values={{
                                        a: chunks => (
                                            <ExternalLink
                                                url={repositoryUrlWithBranch}
                                                data-testid="redirect-link"
                                            >
                                                {chunks}
                                            </ExternalLink>
                                        )
                                    }}
                                    {...messages.manualRedirectInfo}
                                />
                            )}
                        </span>
                    )}
                </Wrapper>
            </ModalBody>
        </Modal>
    );
}
