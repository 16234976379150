import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import messages from './messages';

const MORGAN = {
    author: 'Morgan Pihl',
    authorDetails: 'Lead Psychometrician'
};

export function useContent() {
    const phrases = useExtractPhraseConstants(messages);

    const CANDIDATE_CONTENT = [
        {
            url: 'https://help.alvalabs.io/en/articles/2473924-what-does-alva-s-logic-test-measure',
            title: phrases.whatMeasureTitle,
            ...MORGAN
        },
        {
            url: 'https://help.alvalabs.io/en/articles/2474000-how-do-i-interpret-my-logic-test-result',
            title: phrases.howToInterpretTitle,
            ...MORGAN
        },
        {
            url: 'https://help.alvalabs.io/en/articles/4510873-technical-manual',
            title: phrases.technicalManualTitle,
            ...MORGAN
        }
    ];

    const EMPLOYEE_CONTENT = [
        {
            url: 'https://help.alvalabs.io/en/articles/3004060-logical-ability',
            title: phrases.logicalAbilityTitle,
            ...MORGAN
        },
        {
            url: 'https://help.alvalabs.io/en/articles/4510873-technical-manual',
            title: phrases.technicalManualTitle,
            ...MORGAN
        },
        {
            url: 'https://help.alvalabs.io/en/articles/2473954-validity-of-alva-s-logic-test',
            title: phrases.validityTitle,
            ...MORGAN
        }
    ];

    return {
        CANDIDATE_CONTENT,
        EMPLOYEE_CONTENT
    };
}
