import {useCallback} from 'react';

export function useScrollToFormError() {
    const getAnchorTarget = useCallback(
        (errorType: string) => document.getElementById(errorType),
        []
    );

    const scrollToError = useCallback(
        (error: string) => {
            const anchorTarget = getAnchorTarget(error);
            if (!anchorTarget) {
                return;
            }
            anchorTarget.scrollIntoView({behavior: 'smooth', block: 'start'});
        },
        [getAnchorTarget]
    );

    return {scrollToError};
}
