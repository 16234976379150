import styled from 'styled-components';

import {SkeletonBaseColor} from './constants';
import {SkeletonProps} from './types';

export const Skeleton = styled.div<SkeletonProps>`
    position: relative;
    background: ${({color}) => color || SkeletonBaseColor};
    width: ${({width}) => width || '150px'};
    height: ${({height}) => height || '20px'};
    border-radius: ${({borderRadius}) => borderRadius};

    &::after {
        inset: 0;
        content: '';
        position: absolute;
        animation: wave 1.5s linear 0.5s infinite;
        background-image: linear-gradient(
            90deg,
            ${({color}) => color || SkeletonBaseColor},
            rgb(255 255 255 / 40%),
            ${({color}) => color || SkeletonBaseColor}
        );
        background-size: 300px;
        background-repeat: no-repeat;
        border-radius: ${({borderRadius}) => borderRadius};
    }

    @keyframes wave {
        0% {
            background-position: -300px;
        }

        100% {
            background-position: calc(100% + 300px);
        }
    }
`;
