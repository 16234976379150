import Pusher from 'pusher-js';
import {useCallback, useEffect} from 'react';

import {getConfig} from '@/config';
import {getToken} from '@/utils/ajax';

import {logger} from '../logrocket';
import {initPusher} from './utils';

let pusherClient: Pusher | undefined;

export const usePusherClient = () => {
    const environment = getConfig().BACKEND_ENVIRONMENT;
    const token = getToken();

    const getUserChannelName = useCallback(
        (userId: number) => `private-${environment}-user-${userId}`,
        [environment]
    );

    const getOrganizationChannelName = useCallback(
        (organizationId: number) => `private-${environment}-org-${organizationId}`,
        [environment]
    );

    const subscribeToUserChannel = useCallback(
        (userId: number) => {
            if (pusherClient) {
                const channelName = getUserChannelName(userId);
                return pusherClient.subscribe(channelName);
            }
        },
        [getUserChannelName]
    );

    const unsubscribeFromUserChannel = useCallback(
        (userId: number) => {
            if (pusherClient) {
                const channelName = getUserChannelName(userId);
                pusherClient.unsubscribe(channelName);
            }
        },
        [getUserChannelName]
    );

    const subscribeToOrganizationChannel = useCallback(
        (organizationId: number) => {
            if (pusherClient) {
                const channelName = getOrganizationChannelName(organizationId);
                return pusherClient.subscribe(channelName);
            }
        },
        [getOrganizationChannelName]
    );

    const unsubscribeFromOrganizationChannel = useCallback(
        (organizationId: number) => {
            if (pusherClient) {
                const channelName = getOrganizationChannelName(organizationId);
                pusherClient.unsubscribe(channelName);
            }
        },
        [getOrganizationChannelName]
    );

    useEffect(() => {
        if (token && !pusherClient) {
            pusherClient = initPusher();
            logger.debug('Started pusher client');
        }
        return resetPusherClient;
    }, [token]);

    const resetPusherClient = () => {
        if (pusherClient) {
            pusherClient.unbind_all();
            pusherClient.disconnect();
            pusherClient = undefined;
            logger.debug('Closed pusher client');
        }
    };

    return {
        subscribeToUserChannel,
        unsubscribeFromUserChannel,
        subscribeToOrganizationChannel,
        unsubscribeFromOrganizationChannel,
        pusherClient: pusherClient,
        resetPusherClient
    };
};
