import {useCallback} from 'react';

import {RadioButton} from '@/componentLibrary/components/controls/RadioButton';
import {userDemographicsMessages} from '@/pages/UserDemographics/messages';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {EMPLOYMENT_STATUSES} from '@/pages/UserDemographics/components/UserDemographicsForm/components/SelectEmploymentStatus/constants';
import {SelectEmploymentStatusProps} from '@/pages/UserDemographics/components/UserDemographicsForm/components/SelectEmploymentStatus/types';
import {InputField, InputText} from '../styled';

export function SelectEmploymentStatus({
    employmentStatus,
    setEmploymentStatus
}: SelectEmploymentStatusProps) {
    const phrases = useExtractPhraseConstants(userDemographicsMessages);
    const onEmploymentStatusChange = useCallback(
        value => {
            setEmploymentStatus(value);
        },
        [setEmploymentStatus]
    );

    return (
        <InputField>
            <InputText>{phrases.employmentStatus}</InputText>
            <RadioButton
                value={EMPLOYMENT_STATUSES.EMPLOYED}
                isSelected={employmentStatus === EMPLOYMENT_STATUSES.EMPLOYED}
                onSelect={onEmploymentStatusChange}
                label={phrases.employed}
                withMargin
            />
            <RadioButton
                value={EMPLOYMENT_STATUSES.SELF_EMPLOYED}
                isSelected={employmentStatus === EMPLOYMENT_STATUSES.SELF_EMPLOYED}
                onSelect={onEmploymentStatusChange}
                label={phrases.selfEmployed}
                withMargin
            />
            <RadioButton
                value={EMPLOYMENT_STATUSES.UNEMPLOYED}
                isSelected={employmentStatus === EMPLOYMENT_STATUSES.UNEMPLOYED}
                onSelect={onEmploymentStatusChange}
                label={phrases.unEmployed}
                withMargin
            />
            <RadioButton
                value={EMPLOYMENT_STATUSES.STUDENT}
                isSelected={employmentStatus === EMPLOYMENT_STATUSES.STUDENT}
                onSelect={onEmploymentStatusChange}
                label={phrases.student}
                withMargin
            />
            <RadioButton
                value={EMPLOYMENT_STATUSES.OTHER}
                isSelected={employmentStatus === EMPLOYMENT_STATUSES.OTHER}
                onSelect={onEmploymentStatusChange}
                label={phrases.otherEmploymentStatus}
                withMargin
            />
            <RadioButton
                value={EMPLOYMENT_STATUSES.RATHER_NOT_SAY}
                isSelected={employmentStatus === EMPLOYMENT_STATUSES.RATHER_NOT_SAY}
                onSelect={onEmploymentStatusChange}
                label={phrases.ratherNotSay}
                withMargin
            />
        </InputField>
    );
}
