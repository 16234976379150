import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {
    DeleteInterviewRequestsMutation,
    InterviewRequestsInput
} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {DELETE_INTERVIEW_REQUESTS} from './mutations';

export function useDeleteInterviewRequestByEmployee(): MutationHookReturnType<
    DeleteInterviewRequestsMutation,
    InterviewRequestsInput
> {
    const [mutate, {error, loading}] = useMutation(DELETE_INTERVIEW_REQUESTS);
    const doDeleteInterviewRequest = useCallback(
        (interviewRequestData: InterviewRequestsInput) => {
            const variables = {
                data: {
                    jobApplicationId: interviewRequestData.jobApplicationId,
                    employmentIds: interviewRequestData.employmentIds,
                    interviewId: interviewRequestData.interviewId
                }
            };
            return mutate({variables});
        },
        [mutate]
    );

    return [doDeleteInterviewRequest, {error, loading}];
}
