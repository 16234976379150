import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';

import messages from '../../messages';

type Props = {
    open: () => void;
    isLogicTestCompleted: boolean;
    requestingValidation: boolean;
};

export const SendInvitationMessage = ({
    open,
    isLogicTestCompleted,
    requestingValidation
}: Props) => (
    <ButtonWrapper>
        <PrimaryButton
            onClick={open}
            isLoading={requestingValidation}
            disabled={!isLogicTestCompleted}
        >
            <FormattedMessage {...messages.sendInvitation} />
        </PrimaryButton>

        {!isLogicTestCompleted ? (
            <WarningMessageWrapper>
                <FormattedMessage {...messages.logicTestNotCompleted} />
            </WarningMessageWrapper>
        ) : null}
    </ButtonWrapper>
);

const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 20px;
    margin-bottom: 4px;
    line-height: 34px;
`;

const DescriptionWrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 24px;
    white-space: pre-line;
    color: ${greyColorTokens.grey000()};
`;

const WarningMessageWrapper = styled(DescriptionWrapper)`
    margin: 0 0 0 20px;
`;
