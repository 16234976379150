import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {Fragment} from 'react';
import {useIntl} from 'react-intl';

import {useAssessmentProfileDetailsLogic} from './logic';
import {Bullet, DetailsWrapper, ItemTitle, List, ListItem} from './styled';
import {AssessmentProfileDetailsProps, DetailsItem} from './types';

export function AssessmentProfileDetails({jobPosition}: AssessmentProfileDetailsProps) {
    const {detailItems} = useAssessmentProfileDetailsLogic(jobPosition);
    const intl = useIntl();

    return (
        <Fragment>
            {detailItems.map((detailItem: DetailsItem, i) => (
                <DetailsWrapper key={`assessment-profile-details-detail-item-${i}`}>
                    <ItemTitle>{intl.formatMessage(detailItem.titleMessage)}</ItemTitle>
                    <List>
                        {detailItem.listItems.map((listItem, j) => (
                            <ListItem key={`assessment-profile-details-list-item-${i}-${j}`}>
                                <Bullet backgroundColor={detailItem.bulletColor} />
                                <P2>
                                    {detailItem.formatMessage
                                        ? intl.formatMessage(listItem)
                                        : listItem}
                                </P2>
                            </ListItem>
                        ))}
                    </List>
                </DetailsWrapper>
            ))}
        </Fragment>
    );
}
