import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {useJobApplication} from '@/api/assessment/jobApplications/useJobApplication';
import {GET_VALIDATION_TEST_RESULT} from '@/api/logicTestIrt/queries';
import {useUserResults} from '@/api/users/useUserResults';

function useValidationTestResult(logicTestId?: number | null, withReport = true) {
    const variables = {
        id: (logicTestId ?? 0).toString(),
        withReport
    };

    const {data, error, loading, refetch} = useQuery(GET_VALIDATION_TEST_RESULT, {
        variables,
        skip: !logicTestId
    });

    const validationTestResult = useMemo(
        () => data?.logicalAbilityEstimate?.validationTestResult ?? null,
        [data]
    );

    return {validationTestResult, loading, error, refetch};
}

export function useCandidateLogicTestResults(userId: number, jobPositionId: number) {
    const {
        user,
        loading: loadingUserResults,
        error: userResultsError,
        refetch: refetchUserResults
    } = useUserResults(userId);

    const {jobApplication, loading: loadingJobApplication} = useJobApplication(
        jobPositionId,
        userId,
        {withRoleFitV2: true}
    );

    const {validationTestResult, loading: loadingValidationTestResult} =
        useValidationTestResult(user?.latestLogicTest?.id, true);

    const logicTestResults = useMemo(() => {
        if (!user) {
            return {};
        }

        return {
            latestLogicTest: user.latestLogicTest,
            logicalAbilityEstimate: user.logicalAbilityEstimate
        };
    }, [user]);

    return {
        loadingUserResults,
        userResultsError,
        refetchUserResults,
        logicTestResults,
        jobApplication,
        loadingJobApplication,
        validationTestResult,
        loadingValidationTestResult
    };
}
