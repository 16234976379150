import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {SendValidateLogicTestInvitationMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {SEND_VALIDATE_LOGIC_TEST_INVITATION} from './mutations';

export function useSendValidateLogicTestEmail(
    organizationId: number,
    userId?: number
): MutationHookReturnType<SendValidateLogicTestInvitationMutation> {
    const [mutate, {error, loading, data}] = useMutation(SEND_VALIDATE_LOGIC_TEST_INVITATION);

    const sendValidateLogicTestEmail = useCallback(() => {
        if (!userId) {
            throw new Error('userId is required');
        }
        const variables = {userId, organizationId};
        return mutate({variables});
    }, [mutate, userId, organizationId]);

    return [sendValidateLogicTestEmail, {error, loading, data}];
}
