import {defineMessages} from 'react-intl';

export default defineMessages({
    interviewers: {
        id: 'app.assessment.interviewScoreCard.interview.interviewers.button'
    },
    title: {
        id: 'app.assessment.interviewScoreCard.scoreCard.scorecardIntro.title'
    },
    description: {
        id: 'app.assessment.interviewScoreCard.scoreCard.scorecardIntro.description'
    },
    toastSaved: {
        id: 'app.assessment.jobPosition.settings.assignInterview.toastSaved'
    },
    toastError: {
        id: 'app.assessment.jobPosition.settings.assignInterview.toastError'
    },
    interviewDeleted: {
        id: 'app.assessment.interviews.interviewsTable.InterviewsTableActions.interviewDeleted'
    },
    failedToDeleteInterview: {
        id: 'app.assessment.interviews.interviewsTable.InterviewsTableActions.failedToDeleteInterview'
    }
});
