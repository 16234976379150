import {useCallback, useEffect, useMemo, useState} from 'react';

import {useMyJobRecommendations} from '@/api/jobRecommendations/useMyJobRecommendations';
import {useRelevanceParametersOptions} from '@/api/jobRecommendations/useRelevanceParametersOptions';
import {useWaitForJobRecommendationsCreatedForUser} from '@/api/realTimeUpdates/useWaitForJobRecommendationsCreatedForUser';
import {useGetRelevanceInformation} from '@/api/users/useGetRelevanceInformation';
import {useUserWithRecommendations} from '@/api/users/useRecommendationsUser';
import {usePollUntilChange} from '@/hooks/usePollUntilChange';

import {ActiveJobRecommendationState} from './types';

export function useJobRecommendationsLogic() {
    const {
        pendingRecommendations,
        loading: loadingRecommendations,
        error,
        refetch
    } = useMyJobRecommendations();
    const {user} = useUserWithRecommendations();
    const {relevanceParametersOptions} = useRelevanceParametersOptions();
    const {
        userRelevanceInformation,
        startPolling: startPollingUserRelevanceInformation,
        stopPolling: stopPollingUserRelevanceInformation,
        refetch: refetchUserRelevanceInformation,
        loading: loadingUserRelevanceInformation
    } = useGetRelevanceInformation(user?.id);

    const {startPollingUntilChange} = usePollUntilChange({
        start: startPollingUserRelevanceInformation,
        stop: stopPollingUserRelevanceInformation,
        timeout: 10000,
        dataToChange: userRelevanceInformation
    });

    useEffect(() => {
        if (!loadingUserRelevanceInformation && !userRelevanceInformation) {
            startPollingUntilChange();
        }
    }, [loadingUserRelevanceInformation, startPollingUntilChange, userRelevanceInformation]);

    const [showRelevanceForm, setShowRelevanceForm] = useState(false);
    const recommendationsSettingsIsDisabled =
        user?.recommendationsSettings?.recommendationsEnabled !== true;
    const hasRecommendations = pendingRecommendations.length !== 0;

    const {isWaitingForJobRecommendations, showJobRecommendationsLoader} =
        useWaitForJobRecommendationsCreatedForUser();

    const onSubmissionOfRelevanceForm = useCallback(async () => {
        await refetchUserRelevanceInformation();
        showJobRecommendationsLoader();
    }, [refetchUserRelevanceInformation, showJobRecommendationsLoader]);

    const loading = useMemo(
        () =>
            loadingRecommendations ||
            isWaitingForJobRecommendations ||
            loadingUserRelevanceInformation,
        [
            loadingRecommendations,
            isWaitingForJobRecommendations,
            loadingUserRelevanceInformation
        ]
    );

    const [activeJobRecommendationState, setActiveJobRecommendationState] =
        useState<ActiveJobRecommendationState>();

    const resetActiveJobRecommendation = () => setActiveJobRecommendationState(undefined);

    return {
        recommendationsSettingsIsDisabled,
        relevanceParametersOptions,
        showRelevanceForm,
        setShowRelevanceForm,
        user,
        userRelevanceInformation,
        pendingRecommendations,
        hasRecommendations,
        loading,
        error,
        refetch,
        onSubmissionOfRelevanceForm,
        activeJobRecommendationState,
        setActiveJobRecommendationState,
        resetActiveJobRecommendation
    };
}
