import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {REQUEST_CREATE_FREE_TRIAL} from '@/api/auth/mutations';
import {
    RequestFreeTrialInput,
    RequestFreeTrialMutation
} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';
import {useCreateAccountLogic} from '@/pages/SignupV2/components/CreateAccountSelector/logic';
import {SsoToken} from '@/pages/SignupV2/components/types';
import {CreateFreeTrialData} from '@/pages/SignupV2/hooks/useCreateFreeTrialLogic';

export function useRequestCreateFreeTrial(
    data: CreateFreeTrialData,
    ssoState?: SsoToken
): MutationHookReturnType<RequestFreeTrialMutation, string> {
    const [mutate, {error, loading}] = useMutation(REQUEST_CREATE_FREE_TRIAL);
    const {getHubspotParamsInJson, appendReferralInfoAndEncode} = useCreateAccountLogic();
    const trackingInfoJson = getHubspotParamsInJson();

    const requestCreateFreeTrial = useCallback(
        (token: string) => {
            const {referralSource, ...restData} = data;
            const trackingAndReferralInfo = appendReferralInfoAndEncode(
                trackingInfoJson,
                referralSource
            );
            const newInput: RequestFreeTrialInput = {
                ...restData,
                ...{...(ssoState ?? {})},
                trackingInfo: trackingAndReferralInfo
            };

            const variables = {
                data: newInput,
                token: token
            };
            return mutate({variables});
        },
        [appendReferralInfoAndEncode, trackingInfoJson, data, ssoState, mutate]
    );

    return [requestCreateFreeTrial, {error, loading}];
}
