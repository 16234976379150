import {defineMessages} from 'react-intl';

export default defineMessages({
    processing: {
        id: 'app.logicTestIrt.logicTest.logicTestCompleted.logicTestResultPlaceholder.processing'
    },
    processingDescription: {
        id: 'app.logicTestIrt.logicTest.logicTestCompleted.logicTestResultPlaceholder.processingDescription'
    },
    completed: {
        id: 'app.logicTestIrt.logicTest.logicTestCompleted.logicTestResultPlaceholder.completed'
    },
    completedDescription: {
        id: 'app.logicTestIrt.logicTest.logicTestCompleted.logicTestResultPlaceholder.completedDescription'
    },
    learnMore: {
        id: 'app.logicTestIrt.logicTest.logicTestCompleted.logicTestResultPlaceholder.learnMore'
    },
    linkText: {
        id: 'app.logicTestIrt.logicTest.logicTestCompleted.logicTestResultPlaceholder.linkText'
    },
    goBack: {
        id: 'app.logicTestIrt.logicTest.logicTestCompleted.logicTestResultPlaceholder.goBack'
    },
    seeResults: {
        id: 'app.logicTestIrt.logicTest.logicTestCompleted.logicTestResultPlaceholder.seeResults'
    }
});
