import {useCallback, useEffect, useState} from 'react';

import {FieldState} from '@/pages/SignupV2/components/types';

export function useOrgFormValidator(
    organizationName: string,
    phoneNumber: string,
    referralSource: string
) {
    const [fieldStates, setFieldStates] = useState<Record<string, FieldState>>({
        organizationName: FieldState.UNTOUCHED,
        phoneNumber: FieldState.UNTOUCHED,
        referralSource: FieldState.UNTOUCHED
    });

    const [stopFormSubmit, setStopFormSubmit] = useState(true);

    const isValidValue = (value: string) => {
        return value !== '';
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const calculateState = useCallback(
        (name: string, value: string, forceTouched: boolean): FieldState => {
            if (value === '' && fieldStates[name] === FieldState.UNTOUCHED) {
                if (!forceTouched) {
                    return FieldState.UNTOUCHED;
                }
            } else if (isValidValue(value)) {
                return FieldState.VALID;
            }
            return FieldState.INVALID;
        },
        [fieldStates]
    );

    const validateForm = useCallback(
        (forceTouched = false) => {
            const organizationNameState = calculateState(
                'organizationName',
                organizationName,
                forceTouched
            );
            const phoneNumberState = calculateState('phoneNumber', phoneNumber, forceTouched);
            const referralSourceState = calculateState(
                'referralSource',
                referralSource,
                forceTouched
            );
            setFieldStates(prevState => {
                if (
                    organizationNameState === prevState.organizationName &&
                    referralSourceState === prevState.referralSource &&
                    phoneNumberState === prevState.phoneNumber
                ) {
                    return prevState;
                }
                return {
                    organizationName: organizationNameState,
                    phoneNumber: phoneNumberState,
                    referralSource: referralSourceState
                };
            });
            const stopSubmit =
                organizationNameState !== FieldState.VALID ||
                referralSourceState !== FieldState.VALID ||
                phoneNumberState !== FieldState.VALID;
            setStopFormSubmit(stopSubmit);
        },
        [calculateState, organizationName, phoneNumber, referralSource]
    );

    const handleInvalidSubmit = useCallback(() => {
        validateForm(true);
    }, [validateForm]);

    useEffect(() => {
        validateForm();
    }, [validateForm]);

    return {stopFormSubmit, fieldStates, handleInvalidSubmit};
}
