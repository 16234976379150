import styled from 'styled-components';

import {Caption} from '@/componentLibrary/components/typography/Captions';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {SpacingSmall, SpacingXxxsmall} from '@/componentLibrary/tokens/variables';

export const PercentileWrapper = styled(P2)`
    margin: ${SpacingXxxsmall} 0 ${SpacingSmall};
`;
export const PercentileWrapperSmall = styled(Caption)`
    margin-bottom: ${SpacingSmall};
`;
