import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    SpacingXsmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

const TagsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
    gap: ${SpacingXxsmall};

    @media print {
        button {
            padding-block: ${SpacingXxxsmall};
        }
    }
`;

export const DriverTagsWrapper = styled(TagsWrapper)`
    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        grid-row: 3;
    }
`;

export const CultureTagsWrapper = styled(TagsWrapper)`
    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        grid-row: 5;
    }
`;

export const LinkWrapper = styled(P2)`
    margin-top: ${SpacingXsmall};

    @media print {
        display: none;
    }
`;
