import {
    SpacingXsmall,
    SpacingXxlarge,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const LocationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: ${SpacingXsmall};
`;
export const SeparatorWrapper = styled.div`
    width: ${SpacingXxlarge};
    margin: ${SpacingXxxsmall} 0;
`;
export const CityAndWorkLocationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${SpacingXxxsmall};
`;
