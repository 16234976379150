import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_PERSONALITY_TEST_LOCALE_V2} from './queries';

export function usePersonalityTestLocaleV2(localeName: string) {
    const {data, error, loading, refetch} = useQuery(GET_PERSONALITY_TEST_LOCALE_V2, {
        variables: {localeName: localeName}
    });
    const personalityTestLocaleV2 = useMemo(
        () => data?.personalityTestLocaleV2 ?? null,
        [data]
    );
    return {error, loading, personalityTestLocaleV2, refetch};
}
