import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography';
import {
    ColorFgSubtle,
    FontSizeSmall,
    SpacingMedium,
    SpacingSmall,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const SearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const TagsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: -${SpacingXsmall} -${SpacingMedium} ${SpacingXsmall};
    position: relative;
    overflow-x: auto;
    padding-left: ${SpacingMedium};
    gap: ${SpacingXxsmall};
`;

export const CheckBoxWrapper = styled.div`
    padding-right: ${SpacingXxsmall};
    padding-left: 10px;
`;

export const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: ${SpacingMedium};
`;

export const SelectedTags = styled.div`
    display: flex;
    align-items: center;
    overflow-x: auto;
    width: auto;
    margin-bottom: ${SpacingMedium};
    gap: ${SpacingXxsmall};
`;

export const BodyWrapper = styled.div`
    height: calc(80vh - 40px);
`;

export const NoMatch = styled(P2)`
    text-align: center;
    padding-top: 14px;
`;

export const SpinnerWrapper = styled.div`
    height: 198px;
    margin: auto;
`;

export const SearchInfoTextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${ColorFgSubtle};
    font-size: ${FontSizeSmall};
    padding: 10px 0;
`;

export const SubTitle = styled.div`
    padding-bottom: ${SpacingSmall};
`;
