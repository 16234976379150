import styled from 'styled-components';

import {UserCell} from '@/componentLibrary/components/Table/components/UserCell';
import {Toggle} from '@/componentLibrary/components/controls/Toggle';
import {coral} from '@/componentLibrary/tokens/legacyColors';
import {
    FontSizeSmall,
    FontSizeSmaller,
    FontWeightsLight,
    FontWeightsRegular,
    LineHeightsCompact,
    SpacingMedium,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';
import {EmploymentDropdown} from '@/containers/EmploymentDropdown';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {Employment} from '@/pages/Organization/pages/Assessment/components/RequestInterviewModal/types';
import {isDefined} from '@/utils/typeGuards/isDefined';

import messages from './messages';

type SelectInterviewersProps = {
    handleSelectInterviewer: (employment: Employment) => void;
    isEmployeeAlreadySelectedAsInterviewer: (employment: Employment) => boolean;
    sortedSelectedInterviewers: Employment[];
    removeEmployment: (employment: Employment) => void;
    notifyInterviewers: boolean;
    toggleNotifyInterviewers: () => void;
};

export const SelectInterviewers = ({
    handleSelectInterviewer,
    isEmployeeAlreadySelectedAsInterviewer,
    sortedSelectedInterviewers,
    removeEmployment,
    notifyInterviewers,
    toggleNotifyInterviewers
}: SelectInterviewersProps) => {
    const phrases = useExtractPhraseConstants(messages);
    return (
        <>
            <DescriptionWrapper>{phrases.descriptionInterviewers}</DescriptionWrapper>
            <TeamTitle>{phrases.involvedTeamMembers}</TeamTitle>
            <EmploymentDropdown
                onSelect={handleSelectInterviewer}
                fullWidth
                isItemDisabled={employment =>
                    isEmployeeAlreadySelectedAsInterviewer(employment)
                }
            />
            <br />
            {sortedSelectedInterviewers
                .filter(isDefined)
                .map((employment: NonNullable<Employment>) => (
                    <UserItem key={`employee-${employment.id}`}>
                        <UserCell user={employment.user} />
                        <RemoveLink onClick={removeEmployment.bind(null, employment)}>
                            {phrases.remove}
                        </RemoveLink>
                    </UserItem>
                ))}
            <NotifyInterviewersWrapper key={'notify-interviews-toggle'}>
                <Toggle isToggled={notifyInterviewers} onChange={toggleNotifyInterviewers} />
                <NotifyInterviewers onClick={toggleNotifyInterviewers}>
                    {phrases.sendEmails}
                </NotifyInterviewers>
            </NotifyInterviewersWrapper>
        </>
    );
};

const DescriptionWrapper = styled.div`
    text-align: left;
    font-style: normal;
    font-weight: ${FontWeightsRegular};
    font-size: ${FontSizeSmall};
    margin-bottom: ${SpacingMedium};
`;

const TeamTitle = styled.p`
    font-style: normal;
    font-weight: ${FontWeightsLight};
    font-size: ${FontSizeSmall};
    line-height: ${LineHeightsCompact};
    display: flex;
    align-items: flex-end;
    margin-bottom: ${SpacingXxsmall};
`;

const UserItem = styled.div`
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const RemoveLink = styled.span`
    font-size: ${FontSizeSmaller};
    color: ${coral.regular()};
    cursor: pointer;
`;

const NotifyInterviewersWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-top: ${SpacingMedium};
`;

const NotifyInterviewers = styled.span`
    margin-left: ${SpacingXsmall};
    font-size: ${FontSizeSmall};
    font-weight: ${FontWeightsLight};
`;
