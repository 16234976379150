import {AssessmentCard} from '@/componentLibrary/components/cards/AssessmentCard';
import {CardTypes} from '@/componentLibrary/components/cards/AssessmentCard/constants';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {usePreviewInterview} from '@/pages/Organization/pages/Assessment/hooks/usePreviewScorecard';

import {SelectInterviewType} from '../../types';
import messages from '../messages';
import {StartInterview, Wrapper} from './styled';
import {SelectInterviewProps} from './types';

export function SelectableInterviewCard({
    interview,
    isSelected,
    onSelect,
    selectionType
}: SelectInterviewProps) {
    const phrases = useExtractPhraseConstants(messages);
    const externalLink = {
        title: phrases.preview,
        hook: usePreviewInterview(interview?.jobPositionId, interview?.id),
        external: true
    };

    const handleSelect = () => {
        onSelect(interview);
    };

    return (
        <Wrapper>
            <AssessmentCard
                cardType={CardTypes.SELECTABLE}
                title={interview.title}
                description={interview.description}
                isSelected={isSelected}
                onSelect={handleSelect}
                link={externalLink}
                customLeftContent={
                    selectionType === SelectInterviewType.START_INTERVIEW ? (
                        <StartInterview onClick={handleSelect}>
                            {phrases.startInterview}
                        </StartInterview>
                    ) : undefined
                }
            />
        </Wrapper>
    );
}
