import {Fragment} from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {TextField} from '@/componentLibrary/components/inputs/TextField';

import messages from '../../messages';

export type ApplyFormData = {
    firstName: string;
    lastName: string;
    email: string;
};

type Props = {
    formData: ApplyFormData;
    fieldHasError: (fieldName: keyof ApplyFormData) => boolean;
    handleTextInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    hasEmailError?: boolean;
};

export const FormFields = ({
    formData,
    fieldHasError,
    handleTextInputChange,
    hasEmailError
}: Props) => {
    return (
        <Fragment>
            <RowWrapper>
                <RowFieldWrapper>
                    <TextField
                        type="text"
                        name="firstName"
                        hasError={fieldHasError('firstName')}
                        fullWidth
                        value={formData.firstName}
                        onChange={handleTextInputChange}
                        label={<FormattedMessage {...messages.firstName} />}
                        isRequired
                    />
                </RowFieldWrapper>

                <RowFieldWrapper>
                    <TextField
                        type="text"
                        name="lastName"
                        hasError={fieldHasError('lastName')}
                        fullWidth
                        value={formData.lastName}
                        onChange={handleTextInputChange}
                        label={<FormattedMessage {...messages.lastName} />}
                        isRequired
                    />
                </RowFieldWrapper>
            </RowWrapper>

            <FormField>
                <TextField
                    type="email"
                    name="email"
                    hasError={fieldHasError('email') || hasEmailError}
                    fullWidth
                    value={formData.email}
                    onChange={handleTextInputChange}
                    label={<FormattedMessage {...messages.email} />}
                    isRequired
                />
            </FormField>
        </Fragment>
    );
};

const RowWrapper = styled.div`
    display: flex;
    margin-bottom: 26px;

    > div:first-child {
        margin-right: 16px;
    }
`;

const RowFieldWrapper = styled.div`
    width: 100%;
`;

const FormField = styled.div`
    margin-bottom: 26px;
`;
