import {ApolloCache, FetchResult, MutationOptions, useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {
    AddCandidateForJobPositionMutation,
    AddCandidateForJobPositionMutationVariables,
    CandidateInput,
    CandidateInvitationOptionsInput,
    JobPositionActivationState
} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';
import {logger} from '@/services/logrocket';
import {EVENTS} from '@/services/segment/constants';
import {useTrackEvent} from '@/services/segment/trackEvent';

import {ADD_CANDIDATE_FOR_JOB_POSITION} from './mutations';
import {GET_JOB_POSITION} from './queries';

export type DoCandidateForJobPositionProps = {
    candidateData: CandidateInput;
    createdFrom: string;
    jobPositionId: number;
    invitationOptions?: CandidateInvitationOptionsInput;
};

const updateCache = (
    cache: ApolloCache<AddCandidateForJobPositionMutation>,
    response: FetchResult<AddCandidateForJobPositionMutation>,
    jobPositionId: number
) => {
    const {data} = response;
    if (!data?.addCandidateForJobPosition?.ok) {
        return;
    }

    try {
        const cachedData = cache.readQuery({
            query: GET_JOB_POSITION,
            variables: {id: jobPositionId}
        });

        if (cachedData) {
            if (
                cachedData.jobPosition?.activationState === JobPositionActivationState.INACTIVE
            ) {
                const newActivationState = JobPositionActivationState.ACTIVE;
                cache.modify({
                    id: cache.identify({
                        id: jobPositionId,
                        __typename: 'JobPosition'
                    }),
                    fields: {
                        activationState() {
                            return newActivationState;
                        }
                    }
                });
            }
        }
    } catch (error) {
        logger.error(error, ' when updating cache.');
    }
};

export function useAddCandidateForJobPosition(
    notifyCandidate = false,
    notifyCandidateEvenIfExisting = false,
    doUpdateCacheForActivationState = false
): MutationHookReturnType<AddCandidateForJobPositionMutation, DoCandidateForJobPositionProps> {
    const trackEvent = useTrackEvent();
    const [mutate, {error, loading}] = useMutation(ADD_CANDIDATE_FOR_JOB_POSITION, {
        onCompleted: () => trackEvent(EVENTS.CANDIDATE_ADDED)
    });
    const doCandidateForJobPosition = useCallback(
        ({
            candidateData,
            createdFrom,
            jobPositionId,
            invitationOptions
        }: DoCandidateForJobPositionProps) => {
            const variables = {
                jobPositionId: jobPositionId,
                candidate: candidateData,
                notifyCandidate: notifyCandidate,
                notifyCandidateEvenIfExisting: notifyCandidateEvenIfExisting,
                createdFrom: createdFrom,
                ...(invitationOptions ? {invitationOptions} : {})
            };
            let update: MutationOptions<
                AddCandidateForJobPositionMutation,
                AddCandidateForJobPositionMutationVariables
            >['update'];
            if (doUpdateCacheForActivationState) {
                update = (cache, response) => {
                    updateCache(cache, response, jobPositionId);
                };
            }

            return mutate({
                variables,
                update
            });
        },
        [
            mutate,
            notifyCandidate,
            notifyCandidateEvenIfExisting,
            doUpdateCacheForActivationState
        ]
    );

    return [doCandidateForJobPosition, {error, loading}];
}
