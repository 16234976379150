import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';

import {getNumberOfRows} from './utils';

export const CheckBoxGroupWrapper = styled.div`
    display: grid;
    grid-template-rows: repeat(${getNumberOfRows}, 1fr);
    grid-auto-flow: column;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        grid-template-rows: repeat(${({count}) => count}, 1fr);
    }
`;

export const CheckBoxWrapper = styled.div`
    margin: 10px 0;
`;
