import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {InterviewContent} from '@/pages/Organization/hooks/useTranslatedInterviewContent';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useMemo, useState} from 'react';
import messages from './messages';

const OVERFLOW_CHARACTER_LENGTH = 100;

export const useExpandableInterviewInstructionLogic = (item: InterviewContent) => {
    const phrases = useExtractPhraseConstants(messages);
    const [showMore, setShowMore] = useState(false);
    const toggleShowMore = () => setShowMore(!showMore);
    const buttonText = showMore ? phrases.showLess : phrases.showMore;
    const hasOverflowText =
        isDefined(item?.description) && item.description.length > OVERFLOW_CHARACTER_LENGTH;

    const description = useMemo(() => {
        if (!isDefined(item?.description)) {
            return '';
        }

        if (showMore) {
            return item.description;
        }

        return truncateText(item.description);
    }, [item, showMore]);

    return {
        showMore,
        hasOverflowText,
        description,
        toggleShowMore,
        buttonText
    };
};

const truncateText = (str: string) => {
    if (str.length <= OVERFLOW_CHARACTER_LENGTH) {
        return str;
    }
    return str.slice(0, OVERFLOW_CHARACTER_LENGTH) + '...';
};
