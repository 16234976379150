import {useCallback} from 'react';

import {ConsentType} from '@/api/types/__generated__/graphql';
import {useGiveConsent} from '@/api/users/useGiveConsent';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {useStaticLegalContent} from '@/services/staticLegalContent/useStaticLegalContent';
import {isDefined} from '@/utils/typeGuards/isDefined';

export function useAcceptMarketplaceConsentLogic(onAccept?: () => void) {
    const {refetch: refetchLoggedInUser} = useLoggedInUser(true);
    const [doGiveConsent, {loading: acceptingPolicy}] = useGiveConsent();
    const {loading: loadingConsent, data: consent} = useStaticLegalContent({
        type: ConsentType.PRIVACY_POLICY_CANDIDATE_SERVICES
    });
    const acceptPolicy = useCallback(async () => {
        if (!isDefined(consent)) {
            return;
        }
        const {data} = await doGiveConsent({
            consentType: ConsentType.PRIVACY_POLICY_CANDIDATE_SERVICES,
            version: consent.version
        });
        const {ok, errorMessage} = data?.giveConsent || {};
        if (ok) {
            await refetchLoggedInUser();
            onAccept?.();
        } else {
            throw new Error(errorMessage ?? '');
        }
    }, [consent, doGiveConsent, onAccept, refetchLoggedInUser]);

    return {
        loadingConsent,
        acceptingPolicy,
        acceptPolicy
    };
}
