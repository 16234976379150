import {LOWER_HEIGHT} from '@/componentLibrary/components/charts/StandardTenChart/constants';
import {P2} from '@/componentLibrary/components/typography';
import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingSmall, SpacingXlarge, SpacingXsmall} from '@/componentLibrary/tokens/variables';
import styled, {css} from 'styled-components';

const flexBaseStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Wrapper = styled.div`
    max-width: 920px;
    width: 100%;
    margin: ${SpacingXsmall} 0;

     & > div:not(:last-child) {
        border-bottom: ${BorderBaseDefault};
    }
`;

export const FacetWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${SpacingSmall};
    height: 68px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-top: ${SpacingXsmall};
        flex-wrap: wrap;
        height: auto;
        gap: 0;
        margin-bottom: ${SpacingXlarge};
        align-items: start;
        border-bottom: none;
    }
`;

export const FacetTitle = styled(P2)`
    width: 400px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: auto
    }
`;

export const Result = styled.div`
    width: 100%;
    ${flexBaseStyle}

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-top: ${SpacingXsmall};
        flex-wrap: wrap;
        align-items: start;
    }
`;

export const StenChartWrapper = styled.div`
    height: ${LOWER_HEIGHT}px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
        max-width: none;
    }
`;
