import {FC, forwardRef} from 'react';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';

import {TagWrapper} from './styled';
import {TagProps} from './types';

export const Tag: FC<TagProps> = forwardRef<HTMLSpanElement, TagProps>(
    ({children, $clearable, $selectable, onClear, ...rest}, ref) => {
        const withIcon = !!($clearable || $selectable);
        return (
            <TagWrapper
                role="status"
                $clickable={$clearable || $selectable}
                {...rest}
                ref={ref}
            >
                <span>{children}</span>
                {withIcon && (
                    <Icon
                        fill="currentColor"
                        size={14}
                        icon={$clearable ? Icons.CLOSE : Icons.KEYBOARD_ARROW_DOWN}
                        {...($clearable && onClear ? {onClick: onClear} : null)}
                    />
                )}
            </TagWrapper>
        );
    }
);
