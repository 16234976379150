import {
    GetInterviewRequestsForJobApplicationQuery,
    GetInterviewRequestsForJobApplicationQueryVariables
} from '@/api/types/__generated__/graphql';
import {DeepPick} from '@/types/generic.types';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {QueryHookOptions, useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_INTERVIEW_REQUESTS_FROM_JOB_APPLICATION} from './queries';

export type InterviewRequest = DeepPick<
    GetInterviewRequestsForJobApplicationQuery,
    'jobApplication.interviewRequests'
>[0];

export function useJobApplicationInterviewRequests(
    jobPositionId: number | null,
    userId?: number,
    interviewId?: number
) {
    const options = useMemo<
        QueryHookOptions<
            GetInterviewRequestsForJobApplicationQuery,
            GetInterviewRequestsForJobApplicationQueryVariables
        >
    >(() => {
        return {
            variables: {
                userId: userId ?? -1,
                jobPositionId: jobPositionId ?? -1,
                interviewId: interviewId ?? null
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
            skip: !isDefined(jobPositionId) || !isDefined(userId)
        };
    }, [userId, jobPositionId, interviewId]);

    const {data, loading, error, refetch} = useQuery(
        GET_INTERVIEW_REQUESTS_FROM_JOB_APPLICATION,
        options
    );

    const interviewRequests = useMemo(
        () => data?.jobApplication?.interviewRequests ?? [],
        [data]
    );

    const jobApplicationId = useMemo(
        () => data?.jobApplication?.id ?? null,
        [data?.jobApplication?.id]
    );

    const jobApplicationIsWithdrawn = useMemo(() => {
        return Boolean(data?.jobApplication?.withdrawnAt);
    }, [data]);

    return {
        interviewRequests,
        jobApplicationId,
        jobApplicationIsWithdrawn,
        loading,
        error,
        refetch
    };
}
