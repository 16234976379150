import {SpacingXsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';
import {greyColorTokens} from '../../../tokens/legacyColors';
import {P2} from '../../typography/Paragraphs';

export const Border = styled.div`
    background: ${greyColorTokens.grey500()};
`;
export const YAxisScoreWrapper = styled.div<{height: number; isFirst: boolean}>`
    position: absolute;
    bottom: calc(${({height, isFirst}) => `${height}% - ${isFirst ? 1 : 0}px`});
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 4px;

    > *:last-child {
        margin-top: auto;
        height: 1px;
    }
`;
export const YAxisScore = styled(P2)`
    align-self: flex-end;
    line-height: 8px;
    text-align: right;
    padding-right: 2px;
`;
export const XAxisScore = styled(P2)`
    margin-left: -5px;
    margin-top: 4px;
`;

export const Wrapper = styled.div<{
    spacingTop: number;
    spacingBottom: number;
    spacingLeft: number;
    spacingRight: number;
}>`
    min-height: calc(
        196px + ${({spacingTop}) => spacingTop}px + ${({spacingBottom}) => spacingBottom}px
    );
    min-width: calc(
        64px + ${({spacingLeft}) => spacingLeft}px + ${({spacingRight}) => spacingRight}px
    );
    display: grid;
    grid-template-columns: ${({spacingLeft}) => spacingLeft}px 1fr ${({spacingRight}) =>
        spacingRight}px;
    grid-template-rows: ${({spacingTop}) => spacingTop}px 1fr auto;
    grid-template-areas:
        '. y-top-space .'
        'y-label chart y-right-space'
        'x-label x-label x-label';
`;
export const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: 1px 1fr;
    grid-template-rows: 1fr 1px;
    grid-area: chart;
`;
export const YAxisLabelsWrapper = styled.div`
    grid-area: y-label;
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: ${SpacingXsmall};
`;
export const YAxisTitleWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const YAxisTitle = styled(P2)<{spacingTop: number}>`
    transform: rotate(-90deg);
    margin-top: ${({spacingTop}) => spacingTop}px;
    white-space: nowrap;
`;
export const XAxisTitle = styled(P2)<{spacingLeft: number; spacingRight: number}>`
    text-align: center;
    margin-left: ${({spacingLeft}) => spacingLeft}px;
    margin-right: ${({spacingRight}) => spacingRight}px;
    margin-top: auto;
`;
export const YAxisLabelWrapper = styled.div<{tickCount: number}>`
    display: grid;
    grid-template-rows: repeat(${({tickCount}) => tickCount} 1fr);
    position: relative;
`;
export const XAxisLabelsWrapper = styled.div<{spacingBottom: number}>`
    grid-area: x-label;
    display: grid;
    grid-template-rows: auto 1fr;
    height: ${({spacingBottom}) => spacingBottom}px;
`;
export const YAxisTopSpacing = styled.div`
    grid-area: y-top-space;
    display: grid;
    grid-template-columns: 1px;
    grid-template-rows: 1fr 4px;
`;
export const YAxisRightSpacing = styled.div`
    grid-area: y-right-space;
    display: grid;
    grid-template-rows: 1fr 1px;

    > :last-child {
        width: 4px;
    }
`;
export const XAxisLabelWrapper = styled.div<{
    tickCount: number;
    spacingLeft: number;
    spacingRight: number;
}>`
    display: grid;
    grid-template-rows: 4px 1fr;
    grid-template-columns:
        ${({spacingLeft}) => spacingLeft}px repeat(${({tickCount}) => tickCount}, 1px 1fr)
        1px ${({spacingRight}) => spacingRight}px;
`;
export const ContentWrapper = styled.div`
    position: relative;
`;
