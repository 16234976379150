import {OrganizationAvatarSkeleton} from '@/componentLibrary/components/avatars/OrganizationAvatar';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {DisplaySkeletonSmaller} from '@/componentLibrary/components/skeleton/DisplaySkeleton';
import {TitleWrapper} from '../../styled';

export const LoadingState = () => (
    <FlexLayout alignItems="center">
        <OrganizationAvatarSkeleton />
        <TitleWrapper>
            <DisplaySkeletonSmaller />
        </TitleWrapper>
    </FlexLayout>
);
