import {gql} from '@/api/types/__generated__';

export const AUTHENTICATE_CANDIDATE_MUTATION = gql(`
    mutation AuthenticateCandidate($token: String!) {
        authenticateCandidate(token: $token) {
            ok
            errorMessage
            authToken
            organizationId
            organizationName
            organizationLogo
            acceptedByCandidate
        }
    }
`);

export const SEND_RESET_PASSWORD_EMAIL = gql(`
    mutation sendResetPasswordEmail($data: SendResetPasswordEmailInput!) {
        sendResetPasswordEmail(data: $data) {
            ok
            errorMessage
            sentEmailType
        }
    }
`);

export const ACCEPT_SHARING_CANDIDATE_DATA_WITH_ORGANIZATION = gql(`
    mutation acceptSharingCandidateDataWithOrganization($organizationId: Int!) {
        acceptSharingCandidateDataWithOrganization(organizationId: $organizationId) {
            ok
            errorMessage
            user {
                id
                acceptedCandidateOrganizations
            }
        }
    }
`);

export const WITHDRAW_JOB_APPLICATIONS_FROM_ORGANIZATION = gql(`
    mutation withdrawJobApplicationsFromOrganization($userId: Int!, $organizationId: Int!) {
        withdrawJobApplicationsFromOrganization(
            userId: $userId
            organizationId: $organizationId
        ) {
            ok
            errorMessage
        }
    }
`);

export const REJECT_SHARING_CANDIDATE_DATA_WITH_ORGANIZATION = gql(`
    mutation rejectSharingCandidateDataWithOrganization($organizationId: Int!) {
        rejectSharingCandidateDataWithOrganization(organizationId: $organizationId) {
            ok
            errorMessage
            user {
                id
                acceptedCandidateOrganizations
            }
        }
    }
`);

export const UPDATE_USER_RECOMMENDATION_SETTINGS = gql(`
    mutation updateUserRecommendationSettings($data: UpdateUserRecommendationSettingsInput!) {
        updateUserRecommendationSettings(data: $data) {
            ok
            errorMessage
        }
    }
`);

export const CREATE_ANONYMOUS_USER = gql(`
    mutation createAnonymousUser {
        createAnonymousUser {
            ok
            errorMessage
            userId
            token
        }
    }
`);

export const UPDATE_USER = gql(`
    mutation updateUser($userId: Int!, $data: UserUpdateInput!) {
        updateUser(userId: $userId, userData: $data) {
            ok
            errorMessage
            user {
                id
                firstName
                lastName
                email
                emailConfirmed
                isActive
                gender
                language
            }
        }
    }
`);

export const ANONYMIZE_JOB_APPLICATIONS_FOR_USER_IN_ORGANIZATION = gql(`
    mutation anonymizeJobApplicationsForUserInOrganization(
        $userId: Int!
        $organizationId: Int!
    ) {
        anonymizeJobApplicationsForUserInOrganization(
            userId: $userId
            organizationId: $organizationId
        ) {
            ok
            errorMessage
        }
    }
`);

export const STORE_USER_FEATURE_FLAG = gql(`
    mutation storeUserFeatureFlag($user_id: Int!, $feature_flag: String!) {
        storeUserFeatureFlag(userId: $user_id, featureFlag: $feature_flag) {
            ok
            errorMessage
        }
    }
`);

export const REMOVE_USER_FEATURE_FLAG = gql(`
    mutation removeUserFeatureFlag($user_id: Int!, $feature_flag: String!) {
        removeUserFeatureFlag(userId: $user_id, featureFlag: $feature_flag) {
            ok
            errorMessage
        }
    }
`);

export const CHANGE_LOCALE_FOR_USER = gql(`
    mutation changeLanguage($userId: Int!, $language: String!) {
        changeLanguage(userId: $userId, language: $language) {
            user {
                id
                created
                firstName
                lastName
                language
                email
                emailConfirmed
                born
                gender
            }
            ok
            errorMessage
        }
    }
`);

export const GIVE_CONSENT = gql(`
    mutation giveConsent($data: GiveConsentInput!) {
        giveConsent(data: $data) {
            ok
            errorMessage
            user {
                id
                registeredAt
                hasRegisteredToCandidateServices
                consents {
                    consentType
                    version
                    isApproved
                    decisionAt
                }
            }
        }
    }
`);

export const REVOKE_ACCESS_FROM_ORGANIZATION = gql(`
    mutation revokeAccessFromOrganization($userId: Int!, $organizationId: Int!) {
        revokeAccessFromOrganization(userId: $userId, organizationId: $organizationId) {
            ok
            errorMessage
        }
    }
`);

export const SET_NEW_PASSWORD = gql(`
    mutation setNewPassword($newPassword: String!) {
        setNewPassword(newPassword: $newPassword) {
            ok
            errorMessage
        }
    }
`);

export const VALIDATE_RESET_PASSWORD_TOKEN = gql(`
    mutation validateResetPasswordToken($token: String!) {
        validateResetPasswordToken(token: $token) {
            ok
            errorMessage
            language
            authToken
        }
    }
`);

export const RECORD_EXTERNAL_RESULTS_VISIT = gql(`
    mutation recordExternalResultsVisit($email: String!, $token: String!) {
      recordExternalResultsVisit(email: $email, token: $token) {
        ok
        errorMessage
      }
    }
`);
