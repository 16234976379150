import {v4 as uuid} from 'uuid';

import {getConfig} from '@/config';
import {ANALYTICS_STUB_METHODS} from '@/services/segment/constants';
import {sanitizeUrlPlugin} from '@/services/segment/plugins';
import {Segment, SegmentOptions} from '@/services/segment/types';

export const initClientSideSegmentAnalytics = () => {
    if (window.analytics) {
        return window.analytics;
    }
    const config = getConfig();
    const analytics: Segment = (window.analytics = window.analytics || []);
    stubAnalyticsMethods(analytics);
    analytics.load = loadAnalytics(analytics);
    analytics.SNIPPET_VERSION = '4.15.3';
    analytics.load(config.SEGMENT_CLIENT_SIDE_WRITE_KEY);
    analytics.ready(() => window.analytics.register(sanitizeUrlPlugin));
    return analytics;
};

const stubAnalyticsMethods = (analytics: Segment) => {
    analytics.methods = ANALYTICS_STUB_METHODS;

    analytics.factory = (method: string) => {
        return (...args: string[]) => {
            args.unshift(method);
            analytics.push(args);
            return analytics;
        };
    };

    analytics.methods.forEach((method: string) => {
        analytics[method] = analytics.factory(method);
    });
};

const loadAnalytics = (analytics: Segment) => (key: string, options?: SegmentOptions) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript?.parentNode?.insertBefore(script, firstScript);
    analytics._loadOptions = options;
};

export const getSegmentSessionId = () => {
    const SESSION_ID_KEY = 'segment_session_id';

    if (!window.sessionStorage) {
        return null;
    }

    if (window.sessionStorage.getItem(SESSION_ID_KEY)) {
        return window.sessionStorage.getItem(SESSION_ID_KEY);
    }

    const sessionId = uuid();
    window.sessionStorage.setItem(SESSION_ID_KEY, sessionId);
    return sessionId;
};
