import {FormattedMessage, useIntl} from 'react-intl';

import {H3, ScorePercent} from '@/componentLibrary/components/typography';
import {ColorBaseBlue600} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {convertToHoursAndMinutes} from '../../../../../CodingTests/CodingTestsList/utils';
import messages from './messages';
import {
    OverdueDesc,
    ScoreItemWrapper,
    ScorePercentageWrapper,
    ScorePercentagesWrapper,
    SetOverdueScoreBtn,
    WithButton
} from './styled';
import {ScorePercentagesPropType} from './types';
import {getOverdueByMinutes} from './utils';

export function ScorePercentages({
    challengeAssignment,
    showSetMainScoreConfirm
}: ScorePercentagesPropType) {
    const phrases = useExtractPhraseConstants(messages);
    const intl = useIntl();

    const autoScoreWithinTimeLimit =
        challengeAssignment?.automatedScore?.totalScore ?? undefined;
    const autoScoreBeyondTimeLimit =
        challengeAssignment?.overdueAutomatedScore?.totalScore ?? undefined;

    const useOverdueScore = challengeAssignment?.useOverdueScore;

    const limitInMinutes =
        challengeAssignment?.timeLimitMinutes &&
        convertToHoursAndMinutes(challengeAssignment.timeLimitMinutes, intl);

    const overdueInMinutes = getOverdueByMinutes({
        submissionDeadline: challengeAssignment?.submissionDeadline,
        submittedAt: challengeAssignment?.submittedAt
    });

    return (
        <ScorePercentagesWrapper>
            {!useOverdueScore && (
                <ScoreItemWrapper>
                    <H3>
                        {challengeAssignment?.timeLimitMinutes
                            ? phrases.withinTimeLimit
                            : phrases.codingTestScoreTitle}
                    </H3>
                    <ScorePercentageWrapper>
                        <ScorePercent
                            score={autoScoreWithinTimeLimit}
                            isPercentage
                            missingResults={false}
                            withMargin={false}
                            withGap
                        />
                    </ScorePercentageWrapper>
                </ScoreItemWrapper>
            )}
            {challengeAssignment?.overdueAutomatedScore && (
                <ScoreItemWrapper color={useOverdueScore ? '' : ColorBaseBlue600}>
                    <H3>
                        {useOverdueScore ? phrases.withinTimeLimit : phrases.beyondTimeLimit}
                    </H3>
                    <WithButton>
                        <ScorePercentageWrapper>
                            <ScorePercent
                                isPercentage
                                score={autoScoreBeyondTimeLimit}
                                missingResults={false}
                                withMargin={false}
                                withGap
                            />
                        </ScorePercentageWrapper>
                        {!useOverdueScore && (
                            <>
                                {limitInMinutes && overdueInMinutes > 0 && (
                                    <OverdueDesc>
                                        <FormattedMessage
                                            {...messages.overdueScoreDesc}
                                            values={{
                                                limit: limitInMinutes,
                                                overdue: convertToHoursAndMinutes(
                                                    overdueInMinutes,
                                                    intl
                                                )
                                            }}
                                        />
                                    </OverdueDesc>
                                )}
                                <SetOverdueScoreBtn onClick={showSetMainScoreConfirm}>
                                    {phrases.setMainScoreBtnTitle}
                                </SetOverdueScoreBtn>
                            </>
                        )}
                        {useOverdueScore && limitInMinutes && overdueInMinutes > 0 && (
                            <OverdueDesc data-testid="used-overdue-desc">
                                <FormattedMessage
                                    {...messages.usedOverdueDescription}
                                    values={{
                                        limit: limitInMinutes,
                                        overdue: convertToHoursAndMinutes(
                                            overdueInMinutes,
                                            intl
                                        )
                                    }}
                                />
                            </OverdueDesc>
                        )}
                    </WithButton>
                </ScoreItemWrapper>
            )}
        </ScorePercentagesWrapper>
    );
}
