import {useIntl} from 'react-intl';

import {useJobApplication} from '@/api/assessment/jobApplications/useJobApplication';
import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {Section} from '@/componentLibrary/components/layout/Section';
import {H4} from '@/componentLibrary/components/typography/Headings';
import {RoleFitScore} from '@/pages/Organization/pages/Assessment/components/RoleFitScore';
import {ScoreBars} from '@/pages/Organization/pages/Assessment/components/ScoreBars';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useIsMobileDevice} from '@/utils/useIsMobileDevice';

import {ErrorPage} from './components/ErrorPage';
import {RoleFitCharts} from './components/RoleFitCharts';
import messages from './messages';
import {BannerWrapper, ScoreGroupWrapper, Wrapper} from './styled';

type Props = {
    userId: number;
    jobPositionId: number;
};

export function RoleFitOverview({userId, jobPositionId}: Props) {
    const intl = useIntl();
    const bannerTitle = intl.formatMessage(messages.interviewExcludedTitle);
    const bannerContent = intl.formatMessage(messages.interviewExcludedDescription);

    const {
        loading: loadingJobPosition,
        error,
        refetch,
        jobPosition
    } = useJobPosition(jobPositionId, {
        withTestProfile: true
    });

    const {jobApplication, loading: loadingJobApplication} = useJobApplication(
        jobPositionId,
        userId,
        {
            withCandidate: true,
            withRoleFit: true,
            withRoleFitDistribution: true,
            withRoleFitHistogram: true
        }
    );
    const isMobileDevice = useIsMobileDevice();

    if (error) {
        return <ErrorPage refetch={refetch} />;
    }

    const loading = loadingJobPosition || loadingJobApplication;
    if (loading) {
        return <Section header={<H4 />} loading />;
    }
    if (!jobPosition) {
        return null;
    }

    return (
        <FlexLayout wrap="wrap" justifyContent="space-between">
            {jobPosition?.requireInterviews && (
                <BannerWrapper>
                    <Banner title={bannerTitle}>{bannerContent}</Banner>
                </BannerWrapper>
            )}
            <Section
                header={
                    <H4>
                        {intl.formatMessage(messages.title, {positionName: jobPosition.name})}
                    </H4>
                }
            >
                <Wrapper wrap="wrap" justifyContent="space-between">
                    <ScoreGroupWrapper>
                        {isDefined(jobApplication?.roleFit) ? (
                            <RoleFitScore roleFit={jobApplication?.roleFit} />
                        ) : (
                            <RoleFitScore isInterviewScore={false} customScore={null} />
                        )}
                        <ScoreBars
                            jobPosition={jobPosition}
                            roleFit={jobApplication?.roleFit}
                            width={!isMobileDevice ? 260 : undefined}
                            verticalMargin={24}
                        />
                    </ScoreGroupWrapper>
                    <RoleFitCharts
                        roleFitDistribution={jobApplication?.roleFitDistribution}
                        roleFitHistogram={jobApplication?.roleFitHistogram}
                    />
                </Wrapper>
            </Section>
        </FlexLayout>
    );
}
