import {AssessmentConfiguration, GetJobPositionQuery} from '@/api/types/__generated__/graphql';
import {AssessmentMethodType} from '@/pages/common/roleFit/types';

type AssConfigs = Record<keyof typeof AssessmentMethodType, AssessmentConfiguration[]>;

export const useEnabledAssessmentConfigurations = (
    jobPosition: GetJobPositionQuery['jobPosition']
) => {
    return (jobPosition?.assessmentConfigurations?.reduce((configurations, configuration) => {
        const type = configuration.assessmentMethodType as keyof typeof AssessmentMethodType;
        if (type) {
            if (Array.isArray(configurations[type])) {
                configurations[type].push(configuration);
            } else {
                configurations[type] = [configuration];
            }
        }
        return configurations;
    }, {} as AssConfigs) || {}) as AssConfigs;
};
