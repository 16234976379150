import {defineMessages} from 'react-intl';

export default defineMessages({
    assessmentRequestedDate: {
        id: 'app.pages.user.chooseOrganization.myApplication.assessmentRequestedDate'
    },
    goToApplication: {
        id: 'app.pages.user.chooseOrganization.myApplication.goToApplication'
    },
    NOT_STARTED: {
        id: 'app.pages.user.chooseOrganization.myApplication.NOT_STARTED'
    },
    COMPLETED: {
        id: 'app.pages.user.chooseOrganization.myApplication.COMPLETED'
    },
    IN_PROGRESS: {
        id: 'app.pages.user.chooseOrganization.myApplication.IN_PROGRESS'
    },
    withdrawn: {
        id: 'app.pages.user.chooseOrganization.myApplication.withdrawn'
    },
    withdrawnAt: {
        id: 'app.user.candidateSettings.candidateOrganizationSettings.withdrawnAt'
    },
    codingTest: {
        id: 'app.pages.user.chooseOrganization.myApplication.codingTest'
    },
    jobPositionsText: {
        id: 'app.pages.user.chooseOrganization.myApplication.jobPositionsText'
    }
});
