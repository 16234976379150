import styled from 'styled-components';

import {SpacingLarge} from '@/componentLibrary/tokens/variables';

const RecoverPasswordWrapper = styled.div`
    margin-bottom: ${SpacingLarge};
    display: flex;
    justify-content: center;
`;

const SeparatorWrapper = styled.div`
    margin-bottom: ${SpacingLarge};
`;

export {RecoverPasswordWrapper, SeparatorWrapper};
