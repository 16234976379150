import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {GenerateCheckoutSessionLinkForOrganizationMutation} from '../types/__generated__/graphql';
import {GENERATE_CHECKOUT_SESSION_LINK_FOR_ORGANIZATION} from './mutations';
import {CheckoutLinkParams} from './types';

export function useGenerateOrganizationCheckoutSessionLink(
    props: CheckoutLinkParams
): MutationHookReturnType<GenerateCheckoutSessionLinkForOrganizationMutation> {
    const {organizationId, name, successUrl, cancelUrl, subscriptionExternalId, upgradePlan} =
        props;
    const [generateSessionLink, {error, loading}] = useMutation(
        GENERATE_CHECKOUT_SESSION_LINK_FOR_ORGANIZATION
    );
    const doGenerateOrganizationCheckoutSessionLink = useCallback(() => {
        if (!organizationId) {
            throw new Error('Organization id is required');
        }
        const variables = {
            organizationId: organizationId,
            name: name,
            successUrl: successUrl,
            cancelUrl: cancelUrl,
            stripeCustomerId: subscriptionExternalId,
            upgradePlan: upgradePlan
        };
        return generateSessionLink({variables});
    }, [
        organizationId,
        name,
        successUrl,
        cancelUrl,
        subscriptionExternalId,
        upgradePlan,
        generateSessionLink
    ]);

    return [doGenerateOrganizationCheckoutSessionLink, {error, loading}];
}
