import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {Section} from '@/componentLibrary/components/layout/Section';
import {H4} from '@/componentLibrary/components/typography/Headings';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {AssessmentProfileDetails} from './components/AssessmentProfileDetails';
import {AssessmentProfileWeights} from './components/AssessmentProfileWeights';
import {TestProfileOverview} from './components/TestProfileOverview';
import messages from './messages';

type Props = {
    jobPositionId: number;
};

export function AssessmentProfileOverview({jobPositionId}: Props) {
    const phrases = useExtractPhraseConstants(messages);
    const {loading, error, refetch, jobPosition} = useJobPosition(jobPositionId, {
        withTestProfile: true
    });

    return (
        <Section
            header={<H4>{phrases.title}</H4>}
            error={error ? {reload: refetch} : null}
            loading={loading}
        >
            {!isDefined(jobPosition) ? null : (
                <>
                    <TestProfileOverview
                        includeLogicTest={
                            jobPosition.requireLogicTest || jobPosition.requireLogicIrtTest
                        }
                        includePersonalityTest={jobPosition.requirePersonalityTest}
                        testProfile={jobPosition.selectedTestProfile}
                    />
                    <AssessmentProfileWeights jobPosition={jobPosition} />
                    <AssessmentProfileDetails jobPosition={jobPosition} />
                </>
            )}
        </Section>
    );
}
