import {defineMessages} from 'react-intl';

export default defineMessages({
    modalHeader: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.importBoilerplateModal.header'
    },
    title: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.importBoilerplateModal.title'
    },
    initializingText: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.importBoilerplateModal.initializingText'
    },
    details: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.importBoilerplateModal.details'
    },
    redirectInfo: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.importBoilerplateModal.redirectInfo'
    },
    readyInfo: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.importBoilerplateModal.readyInfo'
    },
    manualRedirectInfo: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.importBoilerplateModal.manualRedirectInfo'
    }
});
