import styled, {css} from 'styled-components';

import {P2} from '@/componentLibrary/components/typography';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {ColorFgOnEmphasis} from '@/componentLibrary/tokens/variables';

import {WrapperProps} from './types';

export const Wrapper = styled.div<WrapperProps>`
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    scroll-behavior: smooth;

    ${({$backgroundImage}) =>
        $backgroundImage &&
        css`
            background: url("${$backgroundImage}") center center no-repeat;
            background-size: cover;
        `}

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        background: none;
    }
`;
export const Body = styled.div`
    flex: 1 1;
`;
export const Footer = styled(P2)`
    flex: 0 0;

    &:hover {
        text-decoration: underline;
    }

    &,
    a {
        color: ${ColorFgOnEmphasis};
    }
`;
