import {useMergeRefs} from '@floating-ui/react';
import {HTMLProps, cloneElement, forwardRef, isValidElement} from 'react';

import {useTooltipContext} from '../hooks';
import {StyledTriggerButton} from './styled';

export const TooltipTrigger = forwardRef<
    HTMLElement,
    HTMLProps<HTMLElement> & {asChild?: boolean; asDiv?: boolean; shouldTrigger: boolean}
>(function TooltipTrigger(
    {children, asChild = false, asDiv = false, shouldTrigger, ...props},
    propRef
) {
    const context = useTooltipContext();
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    if (!shouldTrigger) {
        return <>{children}</>;
    }

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
        return cloneElement(
            children,
            context.getReferenceProps({
                ref,
                ...props,
                ...children.props,
                'data-state': context.open ? 'open' : 'closed'
            })
        );
    }

    // `asDiv` uses a simple div instead of a button
    if (asDiv) {
        return (
            <div
                ref={ref}
                // The user can style the trigger based on the state
                data-state={context.open ? 'open' : 'closed'}
                {...context.getReferenceProps(props)}
            >
                {children}
            </div>
        );
    }

    return (
        <StyledTriggerButton
            ref={ref}
            // The user can style the trigger based on the state
            data-state={context.open ? 'open' : 'closed'}
            {...context.getReferenceProps(props)}
        >
            {children}
        </StyledTriggerButton>
    );
});
