import {useCallback, useState} from 'react';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {personalityDataCollectionMessages} from '@/pages/PersonalityDataCollection/messages';
import {CreateUser} from '@/pages/PersonalityDataCollection/pages/DataCollectionForm/components/FigureEightContent/types';

export function useFigureEightFormLogic(createUser: CreateUser) {
    const [triedToSubmit, setTriedToSubmit] = useState(false);
    const [isConsentAccepted, setIsConsentAccepted] = useState(false);
    const [contributorId, setContributorId] = useState('');
    const phrases = useExtractPhraseConstants(personalityDataCollectionMessages);

    const onInputChange = useCallback(e => {
        const {value} = e.target;
        setContributorId(value);
    }, []);

    const acceptConsent = useCallback(({accepted}) => {
        setIsConsentAccepted(accepted);
    }, []);

    const handleSubmit = useCallback(
        e => {
            e.preventDefault();
            setTriedToSubmit(true);
            if (isConsentAccepted && contributorId) {
                createUser(contributorId);
            }
        },
        [isConsentAccepted, contributorId, createUser]
    );

    return {
        triedToSubmit,
        contributorId,
        onInputChange,
        isConsentAccepted,
        acceptConsent,
        handleSubmit,
        phrases
    };
}
