import {useCallback, useEffect} from 'react';

import {useCurrentEmployment} from '@/api/organizations/useCurrentEmployment';
import {useOrganization} from '@/api/organizations/useOrganization';
import {AuthenticatedUser} from '@/api/types/__generated__/graphql';
import {useAuthenticatedUser} from '@/api/users/useAuthenticatedUser';
import {getSegmentSessionId, initClientSideSegmentAnalytics} from '@/services/segment/utils';
import {formatDate} from '@/utils/formatDates';

import {logger} from '../logrocket';
import {options} from './constants';
import {SegmentPageLoadProps, TrackPageViewProps} from './types';

const SegmentPageLoad = ({
    user,
    organization,
    employment,
    name,
    category,
    children
}: SegmentPageLoadProps) => {
    const isImpersonating = useCallback(() => {
        if (!employment?.user) {
            return false;
        }

        return employment.user.id !== user?.userId;
    }, [employment, user]);

    const getAccessGroupName = useCallback(() => {
        return employment?.accessGroup?.name ?? '';
    }, [employment]);

    const checkIfAdministrator = useCallback(() => {
        return getAccessGroupName() === 'ADMINISTRATOR';
    }, [getAccessGroupName]);

    const getUserId = useCallback(() => {
        return (user as AuthenticatedUser).userId.toString();
    }, [user]);

    const getOrganizationId = useCallback(() => {
        return organization?.id.toString() ?? '';
    }, [organization]);

    const identifyUser = useCallback(() => {
        if (!user) {
            return;
        }

        if (isImpersonating()) {
            logger.info('Is impersonating, skipping Segment identify');
            return;
        }
        const lastSeenAt = user.lastSeenAt ? formatDate(user.lastSeenAt) : null;
        const signedUpAt = user.signedUpAt ? formatDate(user.signedUpAt) : null;
        const accessGroupName = getAccessGroupName();
        const isAdministrator = checkIfAdministrator();
        const isKeyMember = employment ? employment.isKeyMember : null;
        const onboardingStartedAt = organization
            ? formatDate(organization.onboardingStartedAt)
            : null;

        window.analytics.identify(
            getUserId(),
            {
                Intercom: {user_hash: user.intercomVerificationToken},
                userId: user.userId,
                accessGroups: accessGroupName,
                accessGroup: accessGroupName,
                lastSeenAt: lastSeenAt,
                signedUpAt: signedUpAt,
                isAdministrator: isAdministrator,
                isKeyMember: isKeyMember,
                onboardingStartedAt: onboardingStartedAt
            },
            options
        );
    }, [
        checkIfAdministrator,
        employment,
        getAccessGroupName,
        getUserId,
        isImpersonating,
        organization,
        user
    ]);

    const getProperties = useCallback(() => {
        // From docs: https://segment.com/docs/spec/page/#properties
        const organizationId = getOrganizationId();
        return {
            path: window.location.pathname,
            url: window.location.href,
            search: window.location.search,
            title: window.document.title,
            referrer: window.document.referrer,
            sessionId: getSegmentSessionId(),
            organizationId: organizationId,
            accountId: organizationId,
            accessGroup: getAccessGroupName(),
            isAdministrator: checkIfAdministrator()
        };
    }, [checkIfAdministrator, getAccessGroupName, getOrganizationId]);

    const trackPageView = useCallback(() => {
        if (!user) {
            return;
        }

        if (isImpersonating()) {
            logger.info('Is impersonating, skipping Segment page view');
            return;
        }

        window.analytics.page(category, name, getProperties(), options);
    }, [category, getProperties, isImpersonating, name, user]);

    useEffect(() => {
        initClientSideSegmentAnalytics();
        identifyUser();
    }, [identifyUser]);

    useEffect(() => {
        trackPageView();
    }, [trackPageView]);

    return children;
};

export function TrackPageView(props: TrackPageViewProps) {
    const {organization, loading: loadingOrganization} = useOrganization();
    const {user, loading: loadingUser} = useAuthenticatedUser();
    const {employment, loading: loadingEmployment} = useCurrentEmployment();

    if (loadingOrganization || loadingUser || loadingEmployment) {
        return null;
    }

    return (
        <SegmentPageLoad
            user={user}
            organization={organization}
            employment={employment}
            name={props.name}
            category={props.category}
        >
            {props.children}
        </SegmentPageLoad>
    );
}
