import {useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {LINKS} from '@/links';

export function useGoToOktaLoginPage() {
    const history = useHistory();
    const location = useLocation();

    return useCallback(() => {
        history.replace({
            pathname: LINKS.oktaLogin,
            search: location.search
        });
    }, [location.search, history]);
}
