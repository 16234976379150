import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {Alert} from './Alert';
import {discardAlertMessages} from './messages';
import {AlertVariationProps} from './types';

export const DiscardAlert = ({
    title,
    subtitle,
    leftButton,
    rightButton,
    ...rest
}: AlertVariationProps) => {
    const phrases = useExtractPhraseConstants(discardAlertMessages);
    return (
        <Alert
            title={title || phrases.title}
            subtitle={subtitle || phrases.description}
            leftButton={leftButton || phrases.safeAction}
            rightButton={rightButton || phrases.destructiveAction}
            {...rest}
        ></Alert>
    );
};
