import {mean} from 'mathjs';
import {useIntl} from 'react-intl';

import {FusionChart} from '@/componentLibrary/components/charts/FusionChart';
import {ColorBaseBrown100} from '@/componentLibrary/tokens/customVariables';
import {roundValue} from '@/utils/misc';

import {ChartHeader} from '../ChartHeader';
import {useCategory} from '../hooks';
import {Wrapper} from '../styled';
import {LogicTestReportChartProps} from '../types';
import {copyAndSortQuestions} from '../utils';
import {chartProps} from './constants';
import messages from './messages';

export function TimePerQuestionChart({
    questions,
    graphHeight = 340
}: LogicTestReportChartProps) {
    const intl = useIntl();
    const legend = intl.formatMessage(messages.legend);
    const averageTimeTitleText = intl.formatMessage(messages.averageTimeTitle);

    const averageSecondsPerQuestion = questions.length
        ? roundValue(mean(questions.map(item => item.secondsSpentOnQuestion)), 1)
        : 0;
    const secondsText = intl.formatMessage(messages.averageTimePerQuestion, {
        averageTimeInSeconds: averageSecondsPerQuestion
    });

    const copyOfQuestions = copyAndSortQuestions(questions);

    const category = useCategory(copyOfQuestions);

    const data = copyOfQuestions.map(question => {
        return {
            value: question.secondsSpentOnQuestion
        };
    }, []);

    const dataSource = {
        chart: chartProps,
        categories: [{category: category}],
        dataset: [
            {
                seriesname: legend,
                color: ColorBaseBrown100,
                data
            }
        ]
    };

    return (
        <Wrapper>
            <ChartHeader title={averageTimeTitleText} valueText={secondsText} />
            <FusionChart
                type="stackedcolumn2d"
                width="100%"
                height={graphHeight}
                dataFormat="JSON"
                dataSource={dataSource}
            />
        </Wrapper>
    );
}
