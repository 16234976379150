export function getInputCursor(isDisabled: boolean, isFocused: boolean) {
    if (isDisabled) {
        return 'not-allowed';
    }
    if (isFocused) {
        return 'text';
    }
    return 'pointer';
}

// Typeguard
export function isObject<T>(value: T | T[] | null): value is T[] | null {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
}

// Typeguard
export function isObjectOrNull<T>(value: T | T[] | null): value is T | null {
    return value === null || (typeof value === 'object' && !Array.isArray(value));
}

export function emptyFunction() {
    return undefined;
}
