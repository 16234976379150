import styled from 'styled-components';

const Body = styled.div`
    flex: 1 1;
    overflow-y: auto;
    position: relative;
    top: 0;
    left: 0;
`;

export {Body};
