import {ParsedQs, parse} from 'qs';
import {useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {useAccessTokenDetails} from '@/api/auth/useAccessTokenDetails';
import {useSelectEmploymentOrganization} from '@/api/auth/useSelectEmploymentOrganization';
import {useMyJobApplications} from '@/api/users/useMyJobApplications';
import {useUserWithRecommendations} from '@/api/users/useRecommendationsUser';
import {useUserOrganizations} from '@/api/users/useUserOrganizations';
import {organizationLinks} from '@/pages/Organization/links';
import {ALVA_SIGNUP_KEY} from '@/services/auth/constants';
import {
    getSsoStateFromSessionStorage,
    removeSsoStateFromSessionStorage
} from '@/services/auth/sso/utils';

import {userLinks} from '../../links';

export function useMyOrganizationsLogic() {
    const history = useHistory();
    const location = useLocation();
    const [employmentOrganizationSelectedByUser, setEmploymentOrganizationSelectedByUser] =
        useState<number | null>(null);
    const [candidateOrganizationSelectedByUser, setCandidateOrganizationSelectedByUser] =
        useState<number | null>(null);
    const [message, setMessage] = useState<
        string | string[] | ParsedQs | ParsedQs[] | undefined
    >([]);
    const [organizationId, setOrganizationId] = useState<number | null>(null);
    const [isRightAfterSignup, setIsRightAfterSignup] = useState(false);
    const {
        userOrganizations,
        loading,
        error,
        refetch,
        startPolling: startPollingUserOrganizations,
        stopPolling: stopPollingUserOrganizations
    } = useUserOrganizations();
    const {selectOrganization, resetOrganizationSelection} = useSelectEmploymentOrganization();
    const {loading: loadingAccessTokenDetails, accessTokenDetails} = useAccessTokenDetails();

    const currentAuthenticationMethod = useMemo(() => {
        return accessTokenDetails?.authenticatedWith;
    }, [accessTokenDetails?.authenticatedWith]);

    const {user, loading: loadingRecommendationSettings} = useUserWithRecommendations();
    const hasRecommendationsEnabled = user?.recommendationsSettings?.recommendationsEnabled;
    const [
        pollingEnabledAfterAcceptingRecommendation,
        setPollingEnabledAfterAcceptingRecommendation
    ] = useState(false);

    const {
        startPolling: startPollingMyJobApplications,
        stopPolling: stopPollingMyJobApplications
    } = useMyJobApplications();

    useEffect(() => {
        if (window.localStorage) {
            const signedUp = window.localStorage.getItem(ALVA_SIGNUP_KEY);
            if (signedUp) {
                window.localStorage.removeItem(ALVA_SIGNUP_KEY);
                setIsRightAfterSignup(true);
            }
        }
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (pollingEnabledAfterAcceptingRecommendation) {
            startPollingUserOrganizations(1500);
            startPollingMyJobApplications(1500);
            setTimeout(() => {
                setPollingEnabledAfterAcceptingRecommendation(false);
                stopPollingUserOrganizations();
                stopPollingMyJobApplications();
            }, 5000);
        }
    }, [pollingEnabledAfterAcceptingRecommendation]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (userOrganizations.length === 1) {
            const organization = userOrganizations[0];

            if (
                organization.requiresAcceptance ||
                !organization.acceptedAt ||
                (organization.enforcedAuthenticationMethod &&
                    organization.enforcedAuthenticationMethod !==
                        currentAuthenticationMethod) ||
                loadingRecommendationSettings ||
                hasRecommendationsEnabled
            ) {
                return;
            }

            if (organization.isCandidate) {
                setCandidateOrganizationSelectedByUser(organization.organizationId);
            } else {
                setEmploymentOrganizationSelectedByUser(organization.organizationId);
            }
        }
    }, [
        userOrganizations,
        currentAuthenticationMethod,
        user,
        hasRecommendationsEnabled,
        loadingRecommendationSettings
    ]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const params = parse(location.search, {ignoreQueryPrefix: true});
        setMessage(params.message);

        const ssoState = getSsoStateFromSessionStorage();
        const storedOrganizationId = ssoState.organizationId || null;
        setOrganizationId(storedOrganizationId);

        if (ssoState.completed) {
            removeSsoStateFromSessionStorage();
            if (!params.message) {
                setEmploymentOrganizationSelectedByUser(storedOrganizationId);
            }
        }
    }, [location, setEmploymentOrganizationSelectedByUser]);

    useEffect(() => {
        if (employmentOrganizationSelectedByUser) {
            selectOrganization(employmentOrganizationSelectedByUser).then(() => {
                const params = parse(location.search, {ignoreQueryPrefix: true});
                const url = params.redirect ? params.redirect : organizationLinks.home();
                history.push(url as string);
            });
        }
    }, [history, location, employmentOrganizationSelectedByUser, selectOrganization]);

    useEffect(() => {
        if (candidateOrganizationSelectedByUser) {
            resetOrganizationSelection().then(() => {
                const params = parse(location.search, {ignoreQueryPrefix: true});
                const url = params.redirect
                    ? params.redirect
                    : userLinks.jobApplication(candidateOrganizationSelectedByUser);
                history.push(url as string);
            });
        }
    }, [candidateOrganizationSelectedByUser, resetOrganizationSelection, history, location]);

    const pendingInvitations = userOrganizations.filter(
        userOrganization => userOrganization.requiresAcceptance
    );
    const myEmployments = userOrganizations.filter(
        userOrganization => userOrganization.isEmployment
    );

    return {
        loading:
            employmentOrganizationSelectedByUser ||
            candidateOrganizationSelectedByUser ||
            loadingAccessTokenDetails ||
            loadingRecommendationSettings ||
            loading,
        error,
        refetch,
        userOrganizations,
        pendingInvitations,
        myEmployments,
        currentAuthenticationMethod,
        message,
        organizationId,
        setEmploymentOrganizationSelectedByUser,
        pollingEnabledAfterAcceptingRecommendation,
        setPollingEnabledAfterAcceptingRecommendation,
        isRightAfterSignup,
        accessTokenDetails
    };
}
