import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    detailsPersonalityProfile: {
        id: 'app.assessment.personalityProfile.details'
    },
    edited: {
        id: 'app.assessment.personalityProfile.edited'
    }
});

export const WEIGHT_MESSAGES = defineMessages({
    veryLow: {
        id: 'app.assessment.personalityProfile.weight.veryLow'
    },
    low: {
        id: 'app.assessment.personalityProfile.weight.low'
    },
    medium: {
        id: 'app.assessment.personalityProfile.weight.medium'
    },
    high: {
        id: 'app.assessment.personalityProfile.weight.high'
    },
    veryHigh: {
        id: 'app.assessment.personalityProfile.weight.veryHigh'
    }
});
