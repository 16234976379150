import {
    INTERPOLATION_SLOPE,
    MAXIMUM_SCORE,
    MINIMUM_SCORE,
    POSSIBLE_RESULT_VALUES,
    getDefaultRangeColor
} from '@/componentLibrary/components/charts/StandardTenChartRange/constants';
import {
    CalculateScoreParams,
    ColorPerResultValueParams,
    RangeBoundParams
} from '@/componentLibrary/components/charts/StandardTenChartRange/types';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';

export function getGroupedRanges({lowerBound, upperBound}: RangeBoundParams) {
    const groupedRanges = [];
    let prev: number | null = null;
    let curr: number | null = null;
    let count = 0;

    POSSIBLE_RESULT_VALUES.forEach(result => {
        curr = calculateScore({result, lowerBound, upperBound});

        if (prev !== null && curr !== prev) {
            groupedRanges.push({
                score: prev,
                count
            });

            count = 0;
        }

        count += 1;
        prev = curr;
    });

    if (curr !== null) {
        groupedRanges.push({
            score: curr as number,
            count
        });
    }

    return groupedRanges;
}

function calculateScore({result, lowerBound, upperBound}: CalculateScoreParams) {
    let distanceFromRange = 0;
    if (result < lowerBound) {
        distanceFromRange = Math.abs(result - lowerBound);
    }

    if (result > upperBound) {
        distanceFromRange = Math.abs(result - upperBound);
    }

    const score = MAXIMUM_SCORE - INTERPOLATION_SLOPE * distanceFromRange;
    let formattedScore = score;
    if (score < MINIMUM_SCORE) {
        formattedScore = MINIMUM_SCORE;
    }

    return formattedScore;
}

export function getColorPerResultValue({
    lowerBound,
    upperBound,
    rangeColor
}: ColorPerResultValueParams) {
    return POSSIBLE_RESULT_VALUES.map(result => {
        const score = calculateScore({result, lowerBound, upperBound});

        let color = greyColorTokens.grey800();
        if (score > 0) {
            color = rangeColor ? rangeColor(score) : getDefaultRangeColor(score);
        }

        return color;
    });
}
