import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_VALIDATION_TEST_RESULT} from '@/api/logicTestIrt/queries';

export function useValidationTestResult(logicTestId: string, withReport = true) {
    const variables = {id: logicTestId, withReport};

    const {data, error, loading, refetch} = useQuery(GET_VALIDATION_TEST_RESULT, {variables});

    const validationTestResult = useMemo(
        () => data?.logicalAbilityEstimate?.validationTestResult ?? null,
        [data]
    );

    return {validationTestResult, loading, error, refetch};
}
