import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    DescriptionTitle,
    Wrapper
} from '@/pages/LogicTestDataCollection/pages/DataCollectionForm/components/DataCollectionInstructions/styled';
import {logicTestDataCollectionMessages} from '../../../../messages';

export function DataCollectionInstructions() {
    const phrases = useExtractPhraseConstants(logicTestDataCollectionMessages);
    return (
        <Wrapper>
            <DescriptionTitle>{phrases.instructionsTitle}</DescriptionTitle>
        </Wrapper>
    );
}
