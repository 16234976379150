import {defineMessages} from 'react-intl';

import {SCORECARD_ACTION_TYPE} from './constants';

export default defineMessages({
    manageCandidates: {
        id: 'app.assessment.jobPosition.manageCandidates'
    },
    evaluateCandidates: {
        id: 'app.assessment.jobPosition.evaluateCandidates'
    },
    assessmentProfile: {
        id: 'app.assessment.jobPosition.assessmentProfile'
    },
    settings: {
        id: 'app.assessment.jobPosition.settings'
    },
    teamFit: {
        id: 'app.assessment.jobPosition.teamFit'
    },
    reports: {
        id: 'app.assessment.jobPosition.reports'
    },
    addCandidate: {
        id: 'app.assessment.jobPosition.addCandidate'
    },
    shortlist: {
        id: 'app.assessment.jobPosition.shortlist'
    },
    archived: {
        id: 'app.assessment.jobPositions.ARCHIVED'
    },
    expiresIn: {
        id: 'app.assessment.jobPositions.jobPositionsTable.expiresIn'
    }
});

export const candidateStageMessages = defineMessages({
    IN_PROCESS: {
        id: 'app.assessment.candidateStage.inProcess'
    },
    SHORTLISTED: {
        id: 'app.assessment.candidateStage.shortlisted'
    },
    REJECTED: {
        id: 'app.assessment.candidateStage.rejected'
    },
    HIRED: {
        id: 'app.assessment.candidateStage.hired'
    },
    WITHDRAWN: {
        id: 'app.assessment.candidateStage.withdrawn'
    }
});

export const candidateStageDescriptionMessages = defineMessages({
    IN_PROCESS: {
        id: 'app.assessment.candidateStage.inProcess.description'
    },
    SHORTLISTED: {
        id: 'app.assessment.candidateStage.shortlisted.description'
    },
    REJECTED: {
        id: 'app.assessment.candidateStage.rejected.description'
    },
    HIRED: {
        id: 'app.assessment.candidateStage.hired.description'
    },
    WITHDRAWN: {
        id: 'app.assessment.candidateStage.withdrawn.description'
    }
});

export const getSyncAutoScorePhrase = (type: string = SCORECARD_ACTION_TYPE.UPDATED) => {
    return defineMessages({
        syncTitle: {
            id: `app.assessment.codingTest.candidateCodingTest.results.${type}.sync.title`
        },
        syncSubtitle: {
            id: `app.assessment.codingTest.candidateCodingTest.results.${type}.sync.subtitle`
        },
        syncBtnTitle: {
            id: `app.assessment.codingTest.candidateCodingTest.results.${type}.sync.btnTitle`
        },
        syncInProgressBtnTitle: {
            id: `app.assessment.codingTest.candidateCodingTest.results.sync.inProgress.btnTitle`
        },
        syncConfirmTitle: {
            id: `app.assessment.codingTest.candidateCodingTest.results.${type}.sync.confirm.title`
        },
        syncConfirmSubtitle: {
            id: `app.assessment.codingTest.candidateCodingTest.results.${type}.sync.confirm.subtitle`
        },
        syncConfirmBtnConfirm: {
            id: `app.assessment.codingTest.candidateCodingTest.results.${type}.sync.confirm.btnTitle`
        },
        syncConfirmBtnCancel: {
            id: 'app.components.confirmDialog.cancel'
        },
        syncStarted: {
            id: `app.assessment.codingTest.candidateCodingTest.results.sync.started`
        },
        syncLoading: {
            id: `app.assessment.codingTest.candidateCodingTest.results.${type}.sync.loading`
        },
        syncSuccess: {
            id: `app.assessment.codingTest.candidateCodingTest.results.${type}.sync.success`
        },
        syncError: {
            id: `app.assessment.codingTest.candidateCodingTest.results.${type}.sync.error`
        }
    });
};
