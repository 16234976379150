import {FlattenSimpleInterpolation, css} from 'styled-components';

import {
    ColorBaseGreen500,
    ColorBaseGreen600,
    ColorBaseGreen650,
    ColorBaseGreen900,
    ColorBaseRed500,
    ColorBaseRed900,
    ColorBaseYellow600,
    ColorBaseYellow950
} from '@/componentLibrary/tokens/variables';
import {ROLE_FIT_LABELS} from '@/constants/assessment';

export const RoleFitTagLabel = {
    ...ROLE_FIT_LABELS,
    NO_DATA: 'NO_DATA'
};

export const LabelColors: {[key in keyof typeof RoleFitTagLabel]: FlattenSimpleInterpolation} =
    {
        [RoleFitTagLabel.VERY_HIGH]: css`
            background-color: ${ColorBaseGreen650};
            border-color: ${ColorBaseGreen650};
            color: ${ColorBaseGreen900};
        `,
        [RoleFitTagLabel.HIGH]: css`
            background-color: ${ColorBaseGreen600};
            border-color: ${ColorBaseGreen600};
            color: ${ColorBaseGreen900};
        `,
        [RoleFitTagLabel.MEDIUM]: css`
            background-color: ${ColorBaseGreen500};
            border-color: ${ColorBaseGreen500};
            color: ${ColorBaseGreen900};
        `,
        [RoleFitTagLabel.LOW]: css`
            background-color: ${ColorBaseYellow600};
            border-color: ${ColorBaseYellow600};
            color: ${ColorBaseYellow950};
        `,
        [RoleFitTagLabel.VERY_LOW]: css`
            background-color: ${ColorBaseRed500};
            border-color: ${ColorBaseRed500};
            color: ${ColorBaseRed900};
        `
    } as {[key in keyof typeof RoleFitTagLabel]: FlattenSimpleInterpolation};
