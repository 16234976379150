import styled from 'styled-components';

import {
    FontSizeSmall,
    FontWeightsRegular,
    SpacingMedium
} from '@/componentLibrary/tokens/variables';

const DescriptionWrapper = styled.div`
    text-align: left;
    font-style: normal;
    font-weight: ${FontWeightsRegular};
    font-size: ${FontSizeSmall};
    margin-bottom: ${SpacingMedium};
`;

export {DescriptionWrapper};
