import {Fragment, useEffect} from 'react';

import {usePersonalityDataCollectionResult} from '@/api/personalityTests/usePersonalityDataCollectionResult';
import {usePersonalityTestV2} from '@/api/personalityTests/usePersonalityTestV2';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {PersonalityTestReport} from '@/componentLibrary/blocks/testReports/PersonalityTest/PersonalityTestReport';
import {PersonalityDataCollectionResultWithFactors} from '@/componentLibrary/blocks/testReports/PersonalityTest/PersonalityTestReport/types';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {ModalEmpty} from '@/componentLibrary/components/modals/ModalEmpty';
import {Spinner} from '@/componentLibrary/components/spinners';
import {
    ModalContent,
    WaitingForResult,
    Wrapper
} from '@/pages/PersonalityDataCollection/pages/CompletedDataCollectionTest/styled';
import {UserDemographics} from '@/pages/UserDemographics';

import {CONTENT_TYPES} from '../constants';
import {FigureEightCompletedContent} from './components/FigureEightCompletedContent';
import {GeneralCompletedContent} from './components/GeneralCompletedContent';
import {ProlificCompletedContent} from './components/ProlificCompletedContent';
import {useCompletedDataCollectionTestLogic} from './logic';

export function CompletedDataCollectionTest({personalityTestId}: {personalityTestId: string}) {
    const {
        loading: loadingTest,
        error: testError,
        refetch: refetchTest,
        personalityTestV2
    } = usePersonalityTestV2(personalityTestId);

    const {
        loading: loadingResult,
        error: resultError,
        refetch: refetchResult,
        personalityDataCollectionResult,
        stopPolling
    } = usePersonalityDataCollectionResult(personalityTestId);

    const {
        loading: loadingUser,
        error: userError,
        refetch: refetchUser,
        user
    } = useLoggedInUser();

    const {
        reportModalIsOpen,
        openReportModal,
        closeReportModal,
        demographicsModalIsOpen,
        closeDemographicsModal
    } = useCompletedDataCollectionTestLogic();

    useEffect(() => {
        if (personalityDataCollectionResult) {
            stopPolling();
        }
    }, [personalityDataCollectionResult, stopPolling]);

    function getCompletionContent() {
        switch (personalityDataCollectionResult?.dataCollectionSource) {
            case CONTENT_TYPES.PROLIFIC:
                return (
                    <ProlificCompletedContent
                        completionCode={personalityDataCollectionResult.completionCode}
                    />
                );
            case CONTENT_TYPES.FIGURE_EIGHT:
                return (
                    <FigureEightCompletedContent
                        completionCode={personalityDataCollectionResult.completionCode}
                    />
                );
            case CONTENT_TYPES.GENERAL:
                return <GeneralCompletedContent />;
            default:
                return <GeneralCompletedContent />;
        }
    }

    if (loadingTest || loadingUser) {
        return (
            <Wrapper>
                <Spinner />
            </Wrapper>
        );
    }

    if (testError) {
        return <ErrorState error={testError} refetch={refetchTest} />;
    }

    if (resultError) {
        return <ErrorState error={resultError} refetch={refetchResult} />;
    }

    if (userError) {
        return <ErrorState error={userError} refetch={refetchUser} />;
    }

    if (!user) {
        return null;
    }

    if (!personalityTestV2) {
        return <Wrapper>Test was not found.</Wrapper>;
    }

    if (!user.hasSubmittedDemographicsForm && demographicsModalIsOpen) {
        return (
            <ModalEmpty noCenterWrapper showCloseIcon={false}>
                <ModalContent>
                    <UserDemographics isMandatory onSubmitSuccess={closeDemographicsModal} />
                </ModalContent>
            </ModalEmpty>
        );
    }

    return (
        <Wrapper>
            {!loadingResult && personalityDataCollectionResult ? (
                <Fragment>
                    {getCompletionContent()}
                    <PrimaryButton onClick={openReportModal}>See result</PrimaryButton>
                </Fragment>
            ) : (
                <WaitingForResult>Waiting for result...</WaitingForResult>
            )}

            {reportModalIsOpen && personalityDataCollectionResult ? (
                <PersonalityTestReport
                    close={closeReportModal}
                    personalityTestResult={
                        personalityDataCollectionResult as PersonalityDataCollectionResultWithFactors
                    }
                    isDataCollection
                />
            ) : null}
        </Wrapper>
    );
}
