import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    SkipSampleTestButton,
    TextWrapper,
    Wrapper
} from '@/pages/LogicTestIrt/pages/LogicTestTutorial/components/SampleTestTopBanner/styled';
import {SampleTestTopBannerProps} from '@/pages/LogicTestIrt/pages/LogicTestTutorial/components/SampleTestTopBanner/types';

import messages from './messages';

export function SampleTestTopBanner({onSkip, cardsCompleted}: SampleTestTopBannerProps) {
    const phrases = useExtractPhraseConstants(messages);
    if (!cardsCompleted) {
        return null;
    }

    return (
        <Wrapper>
            <TextWrapper>{phrases.info}</TextWrapper>
            <SkipSampleTestButton onClick={onSkip}>{phrases.skipSample}</SkipSampleTestButton>
        </Wrapper>
    );
}
