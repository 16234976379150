import {useRecordExternalResultsVisit} from '@/api/users/useRecordExternalResultsVisit';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import messages from '@/pages/SharedResults/components/SharedResultsEmailPrompt/messages';
import {logger} from '@/services/logrocket';
import {useCallback, useMemo, useState} from 'react';

export const useEmailPromptLogic = (
    token: string,
    loggedInEmail: string | undefined,
    onShowResults: () => void
) => {
    const phrases = useExtractPhraseConstants(messages);
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    const [saveVisit, {loading: saving}] = useRecordExternalResultsVisit(token);
    const [typedEmail, setTypedEmail] = useState<string>();
    const readEmailFromStorage = useCallback(() => {
        return localStorage.getItem('shared-results-prompted-email') ?? undefined;
    }, []);
    const storePrefilledEmail = (email: string) => {
        localStorage.setItem('shared-results-prompted-email', email);
    };

    const email = useMemo(() => {
        if (typedEmail !== undefined) {
            return typedEmail;
        }
        const localStorageEmail = readEmailFromStorage();
        if (loggedInEmail) {
            return loggedInEmail;
        }

        return localStorageEmail;
    }, [loggedInEmail, readEmailFromStorage, typedEmail]);

    const updateEmail = (email: string) => {
        setTypedEmail(email);
        if (emailPattern.test(email)) {
            storePrefilledEmail(email);
        }
    };

    const storeDataForPageReload = (token: string, email: string) => {
        localStorage.setItem(`shared-results-reload-${token}`, email);
    };

    const doViewResults = () => {
        if (!email) {
            return;
        }
        saveVisit(email)
            .then(result => {
                if (result.data?.recordExternalResultsVisit?.ok) {
                    storeDataForPageReload(token, email);
                    onShowResults();
                    return;
                }
                throw new Error(
                    result.data?.recordExternalResultsVisit?.errorMessage ??
                        'Failed to save results visit'
                );
            })
            .catch(e => {
                logger.error('Failed to save results visit', e);
                toast({
                    type: 'error',
                    message: phrases.operationFailed
                });
            });
    };

    const validEmail = !!email && emailPattern.test(email);

    return {
        email,
        validEmail,
        saving,
        updateEmail,
        doViewResults
    };
};
