import {InterviewContentItem} from '@/pages/Organization/hooks/useTranslatedInterviewContent/index';
import {BASIC_LOCALES} from '@/services/locale/constants';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {InterviewContentLibraryItem, Translation, UnTranslatedItem} from './types';
import {getOrFallback} from './utils';

export const toTranslatedCriterion = <T extends UnTranslatedItem>(
    item: T,
    locale: string,
    translations: Translation[]
) => {
    const {value: name, locale: nameLocale} = getOrFallback(translations, 'name');

    const {value: description, locale: descriptionLocale} = getOrFallback(
        translations,
        'description'
    );

    const {value: interviewQuestions, locale: interviewQuestionsLocale} = getOrFallback(
        translations,
        'interviewQuestions'
    );

    const {value: scoringGuidelines, locale: scoringGuidelinesLocale} = getOrFallback(
        translations,
        'scoringGuidelines'
    );

    const usingSomeFallbackLocale = usesAnyFallbackLocales(
        [nameLocale, descriptionLocale, interviewQuestionsLocale, scoringGuidelinesLocale],
        locale
    );

    return {
        ...item,
        name,
        description,
        interviewQuestions,
        scoringGuidelines,
        usingSomeFallbackLocale
    };
};

export const toTranslatedInstruction = <T extends UnTranslatedItem>(
    item: T,
    locale: string,
    translations: Translation[]
) => {
    const {value: name, locale: nameLocale} = getOrFallback(translations, 'name');

    const {value: description, locale: descriptionLocale} = getOrFallback(
        translations,
        'description'
    );

    const usingSomeFallbackLocale = usesAnyFallbackLocales(
        [nameLocale, descriptionLocale],
        locale
    );

    return {
        ...item,
        name,
        description,
        usingSomeFallbackLocale
    };
};

const usesAnyFallbackLocales = (usedLocales: (string | undefined)[], userLocale: string) => {
    if (!BASIC_LOCALES.includes(userLocale)) {
        return false;
    }

    const isNotUserLocale = (usedLocale: string) => usedLocale !== userLocale;
    return usedLocales.filter(isDefined).filter(isNotUserLocale).length > 0;
};

export const contentLibraryItemToId = (item: InterviewContentLibraryItem) => item.id;

export const contentItemToCreatedFromContentLibraryItemId = (item: InterviewContentItem) =>
    item.createdFromContentLibraryItemId;
export const notUpdated = (item: InterviewContentItem) => !isDefined(item.updatedAt);
