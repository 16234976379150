import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.title'
    },
    interviewExcludedTitle: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.interviewsExcluded.title'
    },
    interviewExcludedDescription: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.interviewsExcluded.description'
    },
    rankedOutOf: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.roleFitRankingChart.rankedOutOf'
    },
    tooltip: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.roleFitDistributionChart.tooltip'
    },
    label: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.roleFitDistributionChart.label'
    },
    chartTitle: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.roleFitDistributionChart.title'
    },
    chartYAxisTitle: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.roleFitDistributionChart.chartYAxisTitle'
    },
    roleFit: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.roleFitRankingChart.roleFit'
    },
    topPercentage: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.topPercentage'
    },
    bottomPercentage: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.bottomPercentage'
    },
    histogram: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.roleFitCharts.histogram'
    },
    distribution: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.roleFitCharts.distribution'
    },
    candidates: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.roleFitCharts.candidates'
    },
    barChartTooltip: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.roleFitRankingChart.tooltip'
    },
    distributionChartDisabledText: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.roleFitCharts.distributionChartDisabledText'
    }
});
