import styled from 'styled-components';

import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {SpacingSmall, SpacingXsmall} from '@/componentLibrary/tokens/variables';

const Wrapper = styled.div`
    margin-bottom: ${SpacingSmall};
`;

const StartInterview = styled(PlainButton)`
    width: 94px;
    padding: ${SpacingXsmall} ${SpacingSmall};
    text-align: center;
    white-space: initial;
`;

export {Wrapper, StartInterview};
