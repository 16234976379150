import styled from 'styled-components';

import {OtherOverline} from '@/componentLibrary/tokens/typography';

import {Base} from './Base';

export const Overline = styled(Base)`
    display: inline-block;
    ${OtherOverline};
`;
