import linspace from 'linspace';

import {
    ColorBaseGreen700,
    ColorBaseGreen800,
    ColorBaseGrey200,
    ColorBaseRed800,
    ColorBaseRed900,
    ColorBaseYellow800
} from '@/componentLibrary/tokens/variables';
import {roundValue} from '@/utils/misc';

import {TrendLine} from './types';

export function getGrid(): number[] {
    return linspace(0, 1, 100).map((value: number) => roundValue(value, 2));
}

export function getTrendLines(
    roleFitScore: number | null | undefined,
    percentileBoundaries: {value: number}[],
    percentileBoundaryTooltipTexts: string[]
): TrendLine[] {
    const colors: string[] = [
        ColorBaseRed900,
        ColorBaseRed800,
        ColorBaseYellow800,
        ColorBaseGreen700,
        ColorBaseGreen800
    ];

    let roleFitLines: TrendLine[] = [
        [0, 0.25],
        [0.25, 0.5],
        [0.5, 0.75],
        [0.75, 1],
        [1, 1]
    ].map((item, i) => ({
        startValue: item[0],
        endValue: item[1],
        isTrendZone: true,
        color: colors[i],
        alpha: 0
    }));

    if (roleFitScore === null || roleFitScore === undefined) {
        return roleFitLines;
    }

    if (percentileBoundaries.length > 0) {
        roleFitLines = percentileBoundaries.map((percentileBoundary, i) => ({
            showLabel: false,
            startValue: percentileBoundary.value,
            endValue: percentileBoundary.value,
            isTrendZone: false,
            displayValue: '',
            toolText:
                percentileBoundaryTooltipTexts.length > i
                    ? percentileBoundaryTooltipTexts[i]
                    : '',
            color: ColorBaseGrey200
        }));
    }

    return [
        ...roleFitLines,
        {
            startValue: roleFitScore,
            endValue: roleFitScore,
            isTrendZone: false,
            color: ColorBaseRed800,
            showOnTop: false,
            dashed: '1',
            dashGap: 2,
            dashLen: 10,
            thickness: 1,
            toolText: `Role fit: ${Math.round(roleFitScore * 100)}`,
            valueOnRight: '1'
        }
    ];
}
