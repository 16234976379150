import {Scorecards} from './Scorecards';
import {BodyWrapper} from './styled';
import {ScorecardsProps} from './types';

export const Body = ({challenge}: ScorecardsProps) => {
    return (
        <BodyWrapper>
            <Scorecards challenge={challenge} />
        </BodyWrapper>
    );
};
