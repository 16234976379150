import {GetUserTestResults_User_PersonalityIrtResult} from '@/api/users/types';
import {PersonalityTestReportProps} from '@/componentLibrary/blocks/testReports/PersonalityTest/PersonalityTestReport/types';

export function hasLeadershipProfile(
    result: PersonalityTestReportProps['personalityTestResult']
): result is GetUserTestResults_User_PersonalityIrtResult {
    return (
        (result as GetUserTestResults_User_PersonalityIrtResult).leadershipProfile !==
        undefined
    );
}

export function hasGrowthFactors(
    result: PersonalityTestReportProps['personalityTestResult']
): result is GetUserTestResults_User_PersonalityIrtResult {
    return 'topDriversDimensions' in result && 'topCultureDimensions' in result;
}
