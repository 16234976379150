import {useIntl} from 'react-intl';

import {capitalizeFirstLetter} from '@/utils/misc';

import messages from './messages';
import {Text} from './styled';
import {Props} from './types';

export function EnforcedSsoInfo({enforcedAuthenticationMethod}: Props) {
    const intl = useIntl();

    return (
        <Text>
            {intl.formatMessage(messages.requireSso, {
                provider: capitalizeFirstLetter(enforcedAuthenticationMethod)
            })}
        </Text>
    );
}
