import {defineMessages} from 'react-intl';

export default defineMessages({
    startNewTest: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.startNewTest.startNewTest'
    },
    description: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.startNewTest.description'
    },
    bannerTitle: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.startNewTest.bannerTitle'
    },
    bannerDescription: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.startNewTest.bannerDescription'
    },
    cancel: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.startNewTest.cancel'
    },
    startNewPersonalityTest: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.startNewTest.startNewPersonalityTest'
    },
    startNewLogicTest: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.startNewTest.startNewLogicTest'
    }
});
