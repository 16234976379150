import {useIntl} from 'react-intl';

import messages from '../../../JobRecommendationItem/messages';
import {Section} from '../Section';

export function Languages({
    spokenLanguages
}: {
    spokenLanguages: (string | null)[];
}) {
    const intl = useIntl();
    const languageRequirementsText = intl.formatMessage(messages.languageRequirementsTitle);

    return (
        <Section title={languageRequirementsText}>
            <div>
                {spokenLanguages.map((language, index) => {
                    const languageKey = `languages_${language}` as keyof typeof messages;
                    const isLastItem = index === spokenLanguages.length - 1;
                    return (
                        <span key={index}>
                            {intl.formatMessage(messages[languageKey])}
                            {!isLastItem && <>,&nbsp;</>}
                        </span>
                    );
                })}
            </div>
        </Section>
    );
}
