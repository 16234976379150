import {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {LOGIC_SCORE_BOUNDARIES} from '../../constants';
import {
    AVERAGE_MESSAGES,
    HIGH_MESSAGES,
    LOW_MESSAGES,
    VERY_HIGH_MESSAGES,
    VERY_LOW_MESSAGES,
    messages
} from './messages';
import {UsePhrasesProps} from './types';

export function usePhrases({standardScore, showAdminContent}: UsePhrasesProps) {
    const intl = useIntl();

    const contentByScore = useMemo(() => {
        if (standardScore < LOGIC_SCORE_BOUNDARIES.VERY_LOW_LEVEL_UPPER) {
            return VERY_LOW_MESSAGES;
        }
        if (standardScore < LOGIC_SCORE_BOUNDARIES.LOW_LEVEL_UPPER) {
            return LOW_MESSAGES;
        }
        if (standardScore < LOGIC_SCORE_BOUNDARIES.AVERAGE_LEVEL_UPPER) {
            return AVERAGE_MESSAGES;
        }
        if (standardScore < LOGIC_SCORE_BOUNDARIES.HIGH_LEVEL_UPPER) {
            return HIGH_MESSAGES;
        }
        return VERY_HIGH_MESSAGES;
    }, [standardScore]);

    const title = intl.formatMessage(contentByScore.title);
    const scoreDescription = intl.formatMessage(contentByScore.scoreDescription);

    const descriptionMessageId = showAdminContent
        ? contentByScore.adminContent
        : contentByScore.userContent;
    const description = intl.formatMessage(descriptionMessageId, {
        irtScore: standardScore
    });
    const disclaimer = intl.formatMessage(messages.disclaimer);
    const commonScore = intl.formatMessage(messages.commonScore);

    return {
        title,
        scoreDescription,
        description,
        disclaimer,
        commonScore
    };
}
