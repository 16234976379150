import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {getAppJourneyObject} from './employments/utils';
import {GET_CURRENT_EMPLOYMENT} from './queries';
import {UseCurrentEmploymentOptions} from './types';

export function useCurrentEmployment(
    {withTeams}: UseCurrentEmploymentOptions = {withTeams: false}
) {
    const {data, error, loading, refetch} = useQuery(GET_CURRENT_EMPLOYMENT, {
        variables: {withTeams}
    });
    const employment = useMemo(() => {
        if (!data?.currentEmployment) {
            return null;
        }

        return {
            ...data.currentEmployment,
            appJourney: getAppJourneyObject(data?.currentEmployment?.appJourney)
        };
    }, [data]);
    return {error, loading, employment, refetch};
}
