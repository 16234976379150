import {useMemo} from 'react';

import {useUserResults} from '@/api/users/useUserResults';

export function useTestResultsLogic(userId: number) {
    const {
        user,
        loading: loadingUserResults,
        error: userResultsError,
        refetch: refetchUserResults
    } = useUserResults(userId, true);

    const logicTestResults = useMemo(() => {
        if (!user) {
            return {};
        }
        return {logicalAbilityEstimate: user.logicalAbilityEstimate};
    }, [user]);

    const personalityTestResults = useMemo(() => {
        if (!user) {
            return {};
        }
        return {personalityIrtResult: user.personalityIrtResult};
    }, [user]);

    return {
        loadingUserResults,
        userResultsError,
        refetchUserResults,
        logicTestResults,
        personalityTestResults
    };
}
