import {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import messages from './messages';

export function useRoleFitContributionChartLogic({
    logicTestContribution,
    personalityFacetsContribution,
    roleFitScore
}) {
    const countContribution = useCallback((accumulator, item) => {
        return accumulator + (item.contribution !== null) ? 1 : 0;
    }, []);

    const testScoresAreMissing = useMemo(() => {
        const logicExists = !!logicTestContribution;
        const personalityExists = !!personalityFacetsContribution;

        const totalLogicScore = logicExists
            ? logicTestContribution.reduce(countContribution, 0)
            : -1;
        const totalPersonalityScore = personalityExists
            ? personalityFacetsContribution.reduce(countContribution, 0)
            : -1;

        if (logicExists && personalityExists) {
            return totalLogicScore === 0 && totalPersonalityScore === 0;
        }

        if (logicExists) {
            return totalLogicScore === 0;
        }

        if (personalityExists) {
            return totalPersonalityScore === 0;
        }

        return false;
    }, [logicTestContribution, personalityFacetsContribution, countContribution]);

    const intl = useIntl();
    const missingScoresText = useMemo(() => {
        if ((roleFitScore !== 0 && !roleFitScore) || testScoresAreMissing) {
            return '';
        }

        if (testScoresAreMissing) {
            return intl.formatMessage(messages.missingTestResults);
        }

        return '';
    }, [intl, testScoresAreMissing, roleFitScore]);

    return {
        testScoresAreMissing,
        missingScoresText
    };
}
