import {useDeleteInterviewRequestByEmployee} from 'api/assessment/interviews/UseDeleteInterviewRequestByEmployee';
import {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import {useCreateInterviewRequestsForJobApplications} from '@/api/assessment/interviews/useCreateInterviewRequestsForJobApplications';
import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {Interview} from '@/api/types/__generated__/graphql';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {useGoToInterviewScoreCard} from '@/pages/Organization/pages/Assessment/hooks/useGoToInterviewScoreCard';
import {logger} from '@/services/logrocket';
import {getUserName} from '@/utils/misc';

import {INTERVIEWER_SELECTION_STEP, INTERVIEW_SELECTION_STEP} from './constants';
import messages from './messages';
import {Employment} from './types';

type RequestInterviewModalLogicProps = {
    jobApplicationIds: number[];
    interviewIds: number[];
    employmentIds: number[];
    removedEmploymentIds: number[];
    notifyInterviewers: boolean;
    onComplete?: (interviewRequestIds: number[]) => void;
    closeModal: () => void;
    skipSelectInterviews?: boolean;
};

export function useRequestInterviewModalLogic({
    jobApplicationIds,
    interviewIds,
    employmentIds,
    removedEmploymentIds,
    notifyInterviewers,
    skipSelectInterviews,
    onComplete,
    closeModal
}: RequestInterviewModalLogicProps) {
    const {formatMessage} = useIntl();
    const [doCreateInterviewRequests, {loading: loadingCreate}] =
        useCreateInterviewRequestsForJobApplications();
    const [doDeleteInterviewRequest, {loading: loadingDelete}] =
        useDeleteInterviewRequestByEmployee();
    const goToInterviewScoreCard = useGoToInterviewScoreCard();

    const [modalStep, setModalStep] = useState(
        skipSelectInterviews ? INTERVIEWER_SELECTION_STEP : INTERVIEW_SELECTION_STEP
    );
    const goToNextStep = useCallback(() => {
        setModalStep(modalStep + 1);
    }, [modalStep]);
    const goToPreviousStep = useCallback(() => {
        setModalStep(modalStep - 1);
    }, [modalStep]);

    const createOrRemoveInterviewRequests = useCallback(() => {
        if (removedEmploymentIds.length > 0) {
            doDeleteInterviewRequest({
                interviewId: interviewIds[0],
                employmentIds: removedEmploymentIds,
                jobApplicationId: jobApplicationIds[0]
            })
                .then(({data}) => {
                    if (data?.deleteInterviewRequests?.ok) {
                        if (employmentIds.length === 0 || interviewIds.length === 0) {
                            onComplete?.([]);
                            toast({
                                type: 'success',
                                message: formatMessage(messages.successToastMessage)
                            });
                            closeModal();
                        }
                    } else {
                        throw new Error(
                            data?.deleteInterviewRequests?.errorMessage ?? 'Unknown error'
                        );
                    }
                })
                .catch(error => {
                    logger.error(error);
                    toast({type: 'error'});
                });
        }
        if (employmentIds.length > 0 && interviewIds.length > 0) {
            doCreateInterviewRequests(
                {
                    jobApplicationIds,
                    interviewIds,
                    employmentIds
                },
                notifyInterviewers
            )
                .then(({data}) => {
                    if (data?.createInterviewRequestsForJobApplications?.ok) {
                        toast({type: 'success'});
                        onComplete?.(
                            (
                                data?.createInterviewRequestsForJobApplications
                                    ?.interviewRequests ?? []
                            ).map(({id}) => id)
                        );
                        closeModal();
                    } else {
                        throw new Error(
                            data?.createInterviewRequestsForJobApplications?.errorMessage ??
                                'Unknown error'
                        );
                    }
                })
                .catch(error => {
                    logger.error(error);
                    toast({type: 'error'});
                });
        }
    }, [
        removedEmploymentIds,
        employmentIds,
        interviewIds,
        doDeleteInterviewRequest,
        jobApplicationIds,
        onComplete,
        formatMessage,
        closeModal,
        doCreateInterviewRequests,
        notifyInterviewers
    ]);

    const startInterview = useCallback(
        interview => {
            doCreateInterviewRequests(
                {
                    jobApplicationIds,
                    interviewIds: [interview.id],
                    employmentIds
                },
                false
            )
                .then(({data}) => {
                    if (data?.createInterviewRequestsForJobApplications?.ok) {
                        goToInterviewScoreCard(
                            (data?.createInterviewRequestsForJobApplications
                                ?.interviewRequests ?? [])[0].id
                        );
                        closeModal();
                    } else {
                        throw new Error(
                            data?.createInterviewRequestsForJobApplications?.errorMessage ??
                                'Unknown error'
                        );
                    }
                })
                .catch(error => {
                    logger.error(error);
                    toast({type: 'error'});
                });
        },
        [
            goToInterviewScoreCard,
            doCreateInterviewRequests,
            jobApplicationIds,
            employmentIds,
            closeModal
        ]
    );

    return {
        loading: loadingCreate || loadingDelete,
        createOrRemoveInterviewRequests,
        startInterview,
        modalStep,
        goToNextStep,
        goToPreviousStep
    };
}

export function useSelectInterviewsLogic(jobPositionId: number) {
    const {jobPosition} = useJobPosition(jobPositionId, {
        withInterview: true
    });
    const jobPositionInterviews = jobPosition?.interviews ?? ([] as Interview[]);
    const [selectedInterviewIds, setSelectedInterviewIds] = useState<number[]>([]);

    const selectInterview = useCallback(
        interview => {
            if (selectedInterviewIds.includes(interview.id)) {
                setSelectedInterviewIds(
                    selectedInterviewIds.filter(id => id !== interview.id)
                );
            } else {
                setSelectedInterviewIds([interview.id, ...selectedInterviewIds]);
            }
        },
        [selectedInterviewIds]
    );

    const isInterviewSelected = useCallback(
        interview => {
            return selectedInterviewIds.some(id => id === interview.id);
        },
        [selectedInterviewIds]
    );

    return {
        jobPositionInterviews,
        selectInterview,
        selectedInterviewIds,
        isInterviewSelected
    };
}

export function useSelectInterviewersLogic(existingInterviewers?: Employment[]) {
    const [notifyInterviewers, setNotifyInterviewers] = useState(true);
    const [selectedInterviewers, setSelectedInterviewers] = useState(
        existingInterviewers ?? ([] as Employment[])
    );
    const [newInterviewerIds, setNewInterviewerIds] = useState<number[]>([]);
    const [removedInterviewerIds, setRemovedInterviewerIds] = useState<number[]>([]);

    const toggleNotifyInterviewers = useCallback(() => {
        setNotifyInterviewers(!notifyInterviewers);
    }, [notifyInterviewers]);

    const handleSelectInterviewer = useCallback(
        employment => {
            if (employment) {
                setSelectedInterviewers([employment, ...selectedInterviewers]);
                if (!existingInterviewers?.some(e => e?.id === employment.id)) {
                    setNewInterviewerIds([employment.id, ...newInterviewerIds]);
                }
            }
        },
        [selectedInterviewers, existingInterviewers, newInterviewerIds]
    );

    const isEmployeeAlreadySelectedAsInterviewer = useCallback(
        employment => {
            return selectedInterviewers.some(
                selectedEmployment => selectedEmployment?.id === employment.id
            );
        },
        [selectedInterviewers]
    );

    const sortedSelectedInterviewers = useMemo(() => {
        if (!Array.isArray(selectedInterviewers)) {
            return [];
        }

        return selectedInterviewers
            .slice(0)
            .sort((a, b) =>
                getUserName(a?.user ?? null).localeCompare(getUserName(b?.user ?? null))
            ) as Employment[];
    }, [selectedInterviewers]);

    const removeEmployment = useCallback(
        employment => {
            if (existingInterviewers?.some(e => e?.id === employment.id)) {
                setRemovedInterviewerIds([employment.id, ...removedInterviewerIds]);
            }
            setSelectedInterviewers(
                selectedInterviewers.filter(item => item?.id !== employment.id)
            );
            setNewInterviewerIds(newInterviewerIds.filter(item => item !== employment.id));
        },
        [existingInterviewers, removedInterviewerIds, selectedInterviewers, newInterviewerIds]
    );

    const isAnyInterviewerSelected = useCallback(() => {
        return selectedInterviewers.length > 0;
    }, [selectedInterviewers]);

    return {
        handleSelectInterviewer,
        isEmployeeAlreadySelectedAsInterviewer,
        sortedSelectedInterviewers,
        newInterviewerIds,
        removeEmployment,
        notifyInterviewers,
        toggleNotifyInterviewers,
        isAnyInterviewerSelected,
        removedInterviewerIds
    };
}
