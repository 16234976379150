import {useCallback} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {FormattedHTMLMessage} from '@/componentLibrary/components/FormattedHTMLMessage';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';

import messages from './messages';

type BaseProps =
    | {isPersonalityTest: boolean; isLogicTest?: never}
    | {isPersonalityTest?: never; isLogicTest: boolean};

type Props = {
    setShowStartNewTest: (status: boolean) => void;
    doStartNewTest: () => void;
} & BaseProps;

export function StartNewTest({setShowStartNewTest, doStartNewTest, isLogicTest}: Props) {
    const closeModal = useCallback(() => {
        setShowStartNewTest(false);
    }, [setShowStartNewTest]);
    const intl = useIntl();
    const startTest = intl.formatMessage(
        isLogicTest ? messages.startNewLogicTest : messages.startNewPersonalityTest
    );

    return (
        <Modal
            close={closeModal}
            headerTitle={intl.formatMessage(messages.startNewTest)}
            isVerticallyCentered
            closeOnClickOutside={false}
            footer={{
                leftSecondaryAction: (
                    <SecondaryButton onClick={closeModal}>
                        {intl.formatMessage(messages.cancel)}
                    </SecondaryButton>
                ),
                primaryAction: (
                    <PrimaryButton onClick={doStartNewTest}>{startTest}</PrimaryButton>
                )
            }}
        >
            <TextWrapper>
                <FormattedHTMLMessage {...messages.description} />
            </TextWrapper>
            <Banner
                type={BannerType.ATTENTION}
                title={<FormattedMessage {...messages.bannerTitle} />}
            >
                {intl.formatMessage(messages.bannerDescription)}
            </Banner>
        </Modal>
    );
}

const TextWrapper = styled(P2)`
    margin-bottom: 20px;
`;
