import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {
    BorderRadiusBaseLarge,
    SpacingLarge,
    SpacingSmall,
    SpacingXxlarge,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

import {SizeNarrow, SizeWide} from '../../constants';

const CreateAccountWrapper = styled.div`
    background-color: ${greyColorTokens.white000()};
    max-width: ${SizeWide};
    width: ${SizeWide};
    padding: ${SpacingLarge};
    border-radius: ${BorderRadiusBaseLarge};
    height: 100%;
    margin-left: ${SpacingXxlarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        width: ${SizeNarrow};
        margin-left: 0;
    }
`;

const Stack = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: ${SpacingSmall};
`;

const SignUpWithEmail = styled.div`
    margin: ${SpacingXxsmall} 0;
    display: flex;
    justify-content: center;
`;

const ConsentSection = styled.div`
    margin-top: ${SpacingSmall};
`;

export {ConsentSection, CreateAccountWrapper, SignUpWithEmail, Stack};
