/**
 * Do not edit directly
 * Generated on Mon, 26 Feb 2024 19:59:37 GMT
 */

export const BorderRadiusBaseXsmall = '2px';
export const BorderRadiusBaseSmall = '4px';
export const BorderRadiusBaseMedium = '16px';
export const BorderRadiusBaseLarge = '28px';
export const BorderRadiusBaseRounded = '999px';
export const SpacingXxxsmall = '4px';
export const SpacingXxsmall = '8px';
export const SpacingXsmall = '12px';
export const SpacingSmall = '16px';
export const SpacingMedium = '20px';
export const SpacingLarge = '24px';
export const SpacingXlarge = '32px';
export const SpacingXxlarge = '40px';
export const SpacingXxxlarge = '48px';
export const BoxShadowBaseRegular = '0px 2px 3px #5353534d';
export const BoxShadowBaseHover = '0px 2px 4px #53535366';
export const BoxShadowBaseDrag = '0px 4px 6px #53535340';
export const BoxShadowBaseContent = '-2px 0px 3px #53535314';
export const BoxShadowSeparatorTable = 'inset 0px -1px 0px #e0e0e0';
export const BorderWidthBase = '1px';
export const BorderWidthEmphasis = '2px';
export const BorderBaseDefaultColor = '#eaeaea';
export const BorderBaseDefaultWidth = '1px';
export const BorderBaseDefaultStyle = 'solid';
export const BorderBaseMutedColor = '#f9f9f9';
export const BorderBaseMutedWidth = '1px';
export const BorderBaseMutedStyle = 'solid';
export const BorderBaseFocusColor = '#2256E4';
export const BorderBaseFocusWidth = '1px';
export const BorderBaseFocusStyle = 'solid';
export const BorderBaseErrorColor = '#c10d30';
export const BorderBaseErrorWidth = '1px';
export const BorderBaseErrorStyle = 'solid';
export const BorderBaseInfoColor = '#88A7FF';
export const BorderBaseInfoWidth = '1px';
export const BorderBaseInfoStyle = 'solid';
export const BorderBaseSuccessColor = '#2ed196';
export const BorderBaseSuccessWidth = '1px';
export const BorderBaseSuccessStyle = 'solid';
export const BorderBaseAttentionColor = '#FFE6A3';
export const BorderBaseAttentionWidth = '1px';
export const BorderBaseAttentionStyle = 'solid';
export const BorderBaseDangerColor = '#ffa073';
export const BorderBaseDangerWidth = '1px';
export const BorderBaseDangerStyle = 'solid';
export const BorderActionSecondaryDefaultColor = '#eaeaea';
export const BorderActionSecondaryDefaultWidth = '1px';
export const BorderActionSecondaryDefaultStyle = 'solid';
export const BorderActionSecondaryHoverColor = '#b4b4b4';
export const BorderActionSecondaryHoverWidth = '1px';
export const BorderActionSecondaryHoverStyle = 'solid';
export const BorderActionSecondaryActiveColor = '#b4b4b4';
export const BorderActionSecondaryActiveWidth = '1px';
export const BorderActionSecondaryActiveStyle = 'solid';
export const BorderActionSecondaryDisabledColor = '#f9f9f9';
export const BorderActionSecondaryDisabledWidth = '1px';
export const BorderActionSecondaryDisabledStyle = 'solid';
export const BorderActionDestructiveDefaultColor = '#f9d6ce';
export const BorderActionDestructiveDefaultWidth = '1px';
export const BorderActionDestructiveDefaultStyle = 'solid';
export const BorderActionDestructiveHoverColor = '#c10d30';
export const BorderActionDestructiveHoverWidth = '1px';
export const BorderActionDestructiveHoverStyle = 'solid';
export const BorderActionDestructiveActiveColor = '#c10d30';
export const BorderActionDestructiveActiveWidth = '1px';
export const BorderActionDestructiveActiveStyle = 'solid';
export const BorderActionDestructiveDisabledColor = '#f9f9f9';
export const BorderActionDestructiveDisabledWidth = '1px';
export const BorderActionDestructiveDisabledStyle = 'solid';
export const BorderDropAreaDefaultColor = '#eaeaea';
export const BorderDropAreaDefaultWidth = '2px';
export const BorderDropAreaDefaultStyle = 'dashed';
export const BorderDropAreaHoverColor = '#b4b4b4';
export const BorderDropAreaHoverWidth = '2px';
export const BorderDropAreaHoverStyle = 'dashed';
export const BorderDropAreaDragColor = '#88A7FF';
export const BorderDropAreaDragWidth = '2px';
export const BorderDropAreaDragStyle = 'dashed';
export const ColorBaseGrey100 = '#f9f9f9';
export const ColorBaseGrey200 = '#eaeaea';
export const ColorBaseGrey600 = '#b4b4b4';
export const ColorBaseGrey700 = '#6f6f6f';
export const ColorBaseGrey800 = '#414141';
export const ColorBaseGrey900 = '#282828';
export const ColorBaseGrey000 = '#ffffff';
export const ColorBaseBlue500 = '#f5faff';
export const ColorBaseBlue600 = '#cddaff';
export const ColorBaseBlue700 = '#88a7ff';
export const ColorBaseBlue800 = '#2256e4';
export const ColorBaseBlue900 = '#0e39b0';
export const ColorBaseGreen500 = '#def5e5';
export const ColorBaseGreen600 = '#bdebcc';
export const ColorBaseGreen650 = '#a7dcb0';
export const ColorBaseGreen700 = '#2ed196';
export const ColorBaseGreen800 = '#2aa779';
export const ColorBaseGreen900 = '#286e55';
export const ColorBaseRed500 = '#fcebe6';
export const ColorBaseRed600 = '#f9d6ce';
export const ColorBaseRed700 = '#ffa073';
export const ColorBaseRed800 = '#ee5432';
export const ColorBaseRed900 = '#c10d30';
export const ColorBaseYellow600 = '#fff2cf';
export const ColorBaseYellow700 = '#ffe6a3';
export const ColorBaseYellow800 = '#ffd465';
export const ColorBaseYellow900 = '#ffc000';
export const ColorBaseYellow950 = '#8a6c00';
export const ColorBrandPrimaryStrong = '#ffc000';
export const ColorBrandPrimaryModerate = '#282828';
export const ColorBrandPrimaryWeak = '#ffffff';
export const ColorBrandSecondaryStrongToneA = '#ffc000';
export const ColorBrandSecondaryModerateToneA = '#ffd465';
export const ColorBrandSecondaryNormalToneA = '#ffe6a3';
export const ColorBrandSecondaryLightToneA = '#fff2cf';
export const ColorBrandSecondaryStrongToneB = '#0e39b0';
export const ColorBrandSecondaryModerateToneB = '#2256e4';
export const ColorBrandSecondaryNormalToneB = '#88a7ff';
export const ColorBrandSecondaryLightToneB = '#cddaff';
export const ColorFgDefault = '#282828';
export const ColorFgMuted = '#414141';
export const ColorFgSubtle = '#6f6f6f';
export const ColorFgOnEmphasis = '#ffffff';
export const ColorFgInfo = '#2256e4';
export const ColorFgSuccess = '#2aa779';
export const ColorFgAttention = '#ffd465';
export const ColorFgDanger = '#ee5432';
export const ColorFgError = '#c10d30';
export const ColorFgActionPrimarySecondaryDefault = '#282828';
export const ColorFgActionPrimarySecondaryHover = '#282828';
export const ColorFgActionPrimarySecondaryActive = '#282828';
export const ColorFgActionPrimarySecondaryDisabled = '#6f6f6f';
export const ColorFgActionTertriaryDefault = '#2256e4';
export const ColorFgActionTertriaryHover = '#2256e4';
export const ColorFgActionTertriaryActive = '#0e39b0';
export const ColorFgActionTertriaryDisabled = '#88a7ff';
export const ColorFgActionDestructiveDefault = '#c10d30';
export const ColorFgActionDestructiveHover = '#c10d30';
export const ColorFgActionDestructiveActive = '#c10d30';
export const ColorFgActionDestructiveDisabled = '#ffa073';
export const ColorBgDefault = '#ffffff';
export const ColorBgSubtle = '#f9f9f9';
export const ColorBgInset = '#eaeaea';
export const ColorBgEmphasis = '#282828';
export const ColorBgBackdrop = 'rgba(40, 40, 40, 0.5)';
export const ColorBgInfo = '#f5faff';
export const ColorBgSuccess = '#def5e5';
export const ColorBgAttention = '#fff2cf';
export const ColorBgDanger = '#fcebe6';
export const ColorBgActionPrimaryDefault = '#ffc000';
export const ColorBgActionPrimaryHover = '#ffd465';
export const ColorBgActionPrimaryActive = '#ffe6a3';
export const ColorBgActionPrimaryDisabled = '#fff2cf';
export const ColorBgActionSecondaryDefault = '#ffffff';
export const ColorBgActionSecondaryHover = '#ffffff';
export const ColorBgActionSecondaryActive = '#f9f9f9';
export const ColorBgActionSecondaryDisabled = '#f9f9f9';
export const ColorBgActionDestructiveDefault = '#ffffff';
export const ColorBgActionDestructiveHover = '#ffffff';
export const ColorBgActionDestructiveActive = '#fcebe6';
export const ColorBgActionDestructiveDisabled = '#f9f9f9';
export const ColorBorderDefault = '#eaeaea';
export const ColorBorderMuted = '#f9f9f9';
export const ColorBorderFocus = '#2256e4';
export const ColorBorderError = '#c10d30';
export const ColorBorderInfo = '#88a7ff';
export const ColorBorderSuccess = '#2ed196';
export const ColorBorderAttention = '#ffe6a3';
export const ColorBorderDanger = '#ffa073';
export const ColorBorderActionSecondaryDefault = '#eaeaea';
export const ColorBorderActionSecondaryHover = '#b4b4b4';
export const ColorBorderActionSecondaryActive = '#b4b4b4';
export const ColorBorderActionSecondaryDisabled = '#f9f9f9';
export const ColorBorderActionDestructiveDefault = '#f9d6ce';
export const ColorBorderActionDestructiveHover = '#c10d30';
export const ColorBorderActionDestructiveActive = '#c10d30';
export const ColorBorderActionDestructiveDisabled = '#f9f9f9';
export const FontFamiliesEuclid = 'Euclid Circular A';
export const LineHeightsCompact = '130%';
export const LineHeightsDefault = '145%';
export const FontWeightsRegular = 400;
export const FontWeightsMedium = 500;
export const FontWeightsLight = 300;
export const FontSizeSmaller = '12px';
export const FontSizeSmall = '14px';
export const FontSizeDefault = '16px';
export const FontSizeMedium = '20px';
export const FontSizeLarge = '24px';
export const FontSizeLarger = '32px';
export const FontSizeLargest = '48px';
export const LetterSpacingDefault = '0px';
export const ParagraphSpacingDefault = '0px';
export const TextCaseDefault = 'none';
export const TextCaseUppercase = 'uppercase';
export const TextDecorationBaseDefault = 'none';
export const TextDecorationBaseUnderline = 'underline';
export const TextDecorationLinkDefault = 'none';
export const TextDecorationLinkHover = 'underline';
export const TextDecorationLinkActive = 'underline';
export const SizingBase = '16px';
export const ShadowElevationLow = '0px 3px 8px #0000001a, 0px 1px 4px #00000014';
export const ShadowElevationMedium =
    '0px 4px 8px #0000001a, 0px 3px 8px #0000001a, 0px 1px 4px #00000014';
export const ShadowElevationHigh =
    '0px 6px 16px #0000001f, 0px 3px 8px #0000001a, 0px 1px 4px #00000014';
export const TokenSetOrder0 = 'global';
