import {PhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {SsoProvider, SsoProviderType} from '@/services/auth/sso/constants';

import {Icons} from '../../icons/constants';

const INVALID_SSO_PROVIDER = 'Invalid SSO provider';

export const getMessage = (
    ssoProvider: SsoProviderType,
    isSignup: boolean,
    phrases: PhraseConstants
) => {
    switch (ssoProvider) {
        case SsoProvider.GOOGLE:
            return isSignup ? phrases.signUpWithGoogle : phrases.signInWithGoogle;
        case SsoProvider.MICROSOFT:
            return isSignup ? phrases.signUpWithMicrosoft : phrases.signInWithMicrosoft;
        case SsoProvider.OKTA:
            return isSignup ? phrases.signUpWithOkta : phrases.signInWithOkta;
        default:
            throw new Error(INVALID_SSO_PROVIDER);
    }
};

export const getIcon = (ssoProvider: SsoProviderType) => {
    switch (ssoProvider) {
        case SsoProvider.GOOGLE:
            return Icons.GOOGLE_LOGO;
        case SsoProvider.MICROSOFT:
            return Icons.MICROSOFT_LOGO;
        case SsoProvider.OKTA:
            return Icons.OKTA_LOGO;
        default:
            throw new Error(INVALID_SSO_PROVIDER);
    }
};
