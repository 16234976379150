import {defineMessages} from 'react-intl';

export default defineMessages({
    shareDataText: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.shareDataText'
    },
    externalPrivacyPolicy: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.externalPrivacyPolicy'
    },
    privacyPolicy: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.privacyPolicy'
    },
    accept: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.accept'
    },
    deny: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.deny'
    },
    invitationAccepted: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.invitationAccepted'
    },
    errorAcceptingInvitation: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.errorAcceptingInvitation'
    },
    invitationRejected: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.invitationRejected'
    },
    employee: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.employee'
    },
    candidate: {
        id: 'app.pages.user.chooseOrganization.acceptInvitationInfo.candidate'
    }
});
