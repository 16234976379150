import {CHECKBOX_VALUES} from '@/componentLibrary/components/controls/CheckBox/constants';
import {greyColorTokens, tertiaryColorTokens} from '@/componentLibrary/tokens/legacyColors';

type Arguments = {
    value?: boolean | string;
    isDisabled: boolean;
};

export function getBackgroundColor({value, isDisabled}: Arguments) {
    const lookChecked =
        value === true ||
        value === CHECKBOX_VALUES.CHECKED ||
        value === CHECKBOX_VALUES.INDETERMINATE;

    if (isDisabled) {
        if (lookChecked) {
            return tertiaryColorTokens.blue200();
        }

        return greyColorTokens.white100();
    }

    if (lookChecked) {
        return tertiaryColorTokens.blue300();
    }

    return greyColorTokens.white000();
}

export function getBorderColor({value, isDisabled}: Arguments) {
    const lookChecked =
        value === true ||
        value === CHECKBOX_VALUES.CHECKED ||
        value === CHECKBOX_VALUES.INDETERMINATE;

    if (isDisabled) {
        if (lookChecked) {
            return tertiaryColorTokens.blue200();
        }

        return greyColorTokens.grey900();
    }

    if (lookChecked) {
        return tertiaryColorTokens.blue300();
    }
    return greyColorTokens.grey800();
}
