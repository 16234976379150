export const MODAL_WIDTH = {
    XSMALL: '416px',
    SMALL: '560px',
    MEDIUM: '720px',
    LARGE: '960px'
};

export const MODAL_HEIGHT = {
    XSMALL: '25vh',
    SMALL: '37.5vh',
    MEDIUM: '50vh',
    LARGE: '62.5vh',
    XLARGE: '75vh'
} as const;

export const MODAL_SIZE = {
    XSMALL: 'XSMALL',
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    LARGE: 'LARGE',
    FULL_PAGE: 'FULL_PAGE'
} as const;
