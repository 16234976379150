import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography';
import {
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXsmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    margin: ${SpacingXxsmall} 0 ${SpacingSmall};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const LeftComponent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${SpacingLarge};
`;

export const RightComponent = styled.div`
    display: flex;
    flex-direction: row;

    button {
        border-right-width: 0;

        &:disabled:hover,
        &:disabled,
        &:hover:focus,
        &.selected:focus,
        &.selected,
        &:hover,
        &:focus,
        &:active:focus:hover,
        &:active {
            border-right-width: 0;
        }

        &:last-child {
            border-right-width: 1px;

            &:disabled:hover,
            &:disabled,
            &:hover:focus,
            &.selected:focus,
            &.selected,
            &:hover,
            &:focus,
            &:active:focus:hover,
            &:active {
                border-right-width: 1px;
            }
        }
    }
`;

export const SelectionHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${SpacingMedium};
    margin-right: ${SpacingXsmall};
`;

export const SelectionTextWrapper = styled(P2)`
    margin-left: ${SpacingXxxsmall};
`;
