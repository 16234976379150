import {PersonAvatarMedium} from '@/componentLibrary/components/avatars/PersonAvatar';
import {Caption} from '@/componentLibrary/components/typography/Captions';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {ColorBaseGrey600} from '@/componentLibrary/tokens/variables';
import {DropdownMenuItem} from '../DropdownMenuItem';
import {MenuItemWrapper} from '../styled';
import {AvatarWrapper, TextWrapper, UserWrapper} from './styled';
import {Item, UserItemProps} from './types';

export const UserItem = <T extends Item>({
    isDisabled,
    isHighlighted,
    isSelected,
    getItemProps,
    item,
    index,
    itemToString
}: UserItemProps<T>) => (
    <DropdownMenuItem
        {...getItemProps?.({
            item,
            index,
            disabled: isDisabled
        })}
        isSelected={isSelected}
        isHighlighted={isHighlighted}
        isDisabled={isDisabled}
        components={{
            Wrapper: MenuItemWrapper
        }}
    >
        <UserWrapper>
            <AvatarWrapper>
                <PersonAvatarMedium user={item?.user ?? ''} />
            </AvatarWrapper>
            <TextWrapper>
                <P2>{itemToString?.(item)}</P2>
                <Caption color={ColorBaseGrey600}>{item?.user?.email ?? ''}</Caption>
            </TextWrapper>
        </UserWrapper>
    </DropdownMenuItem>
);
