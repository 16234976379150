import {useMemo} from 'react';
import {v4 as uuid} from 'uuid';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {ColorFgOnEmphasis} from '@/componentLibrary/tokens/variables';

import {CheckBoxLabel} from './components/CheckBoxLabel';
import {useCheckBoxStyleLogic} from './hooks/useCheckBoxStyleLogic';
import {useCheckBoxValueLogic} from './hooks/useCheckBoxValueLogic';
import {CheckBoxInput, CheckBoxWrapper, IconWrapper} from './styled';
import {CheckBoxProps} from './types';

export function CheckBox({
    checkboxId,
    name,
    label,
    defaultValue,
    value,
    isDisabled = false,
    onChange,
    alignItems = 'flex-start',
    withMargin = true,
    'data-testid': dataTestId
}: CheckBoxProps) {
    const {derivedValue, htmlCheckboxIsChecked, handleChange, stopEventPropagation} =
        useCheckBoxValueLogic({
            defaultValue,
            value,
            onChange
        });
    const {backgroundColor, borderColor, icon} = useCheckBoxStyleLogic({
        value: derivedValue,
        isDisabled
    });
    const id = useMemo(() => `checkbox-${checkboxId || uuid()}`, [checkboxId]);

    return (
        <FlexLayout alignItems={alignItems}>
            <CheckBoxWrapper
                hasLabel={!!label}
                withMargin={withMargin}
                onClick={stopEventPropagation}
            >
                <CheckBoxInput
                    id={id}
                    name={name}
                    type="checkbox"
                    isDisabled={isDisabled}
                    disabled={isDisabled}
                    backgroundColor={backgroundColor}
                    borderColor={borderColor}
                    onChange={handleChange}
                    checked={htmlCheckboxIsChecked}
                    data-testid={dataTestId}
                />
                {!!icon && (
                    <IconWrapper>
                        <Icon
                            icon={icon}
                            size={12}
                            fill={ColorFgOnEmphasis}
                            style={{display: 'flex'}}
                        />
                    </IconWrapper>
                )}
            </CheckBoxWrapper>
            {!!label && (
                <CheckBoxLabel
                    htmlFor={id}
                    color={isDisabled ? greyColorTokens.grey500() : greyColorTokens.grey000()}
                    disabled={isDisabled}
                >
                    {label}
                </CheckBoxLabel>
            )}
        </FlexLayout>
    );
}
