import {Layout, PieData} from 'plotly.js';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {
    MessageStructure,
    PERSONALITY_FACET_MESSAGES,
    PersonalityFacetKey
} from '@/componentLibrary/blocks/testReports/PersonalityTest/components/FactorFacets/constants';
import {PERSONALITY_IRT_FACET_COLORS} from '@/componentLibrary/blocks/testReports/PersonalityTest/constants';
import {Plot} from '@/componentLibrary/components/charts/plotlyBuild';
import {ColorBaseGrey200, ColorBgDefault} from '@/componentLibrary/tokens/variables';
import {TestProfilePieChartProps} from '@/pages/Organization/pages/Assessment/components/TestProfileGraph/types';
import {convertWeightToValue} from '@/pages/Organization/pages/Assessment/utils/weights';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {TEST_PROFILE_COLORS} from '../../constants';

const MARGIN = 20;
const HEIGHT = 360;

export function TestProfilePieChart({
    testProfile,
    includePersonalityTest,
    includeLogicTest
}: TestProfilePieChartProps) {
    const intl = useIntl();

    const traces = useMemo((): Partial<PieData>[] => {
        const values: Array<number | string> = [];
        const labels = [];
        const colors = [];

        function getFacetName(facet?: string | null) {
            const facetMessages = facet
                ? PERSONALITY_FACET_MESSAGES[facet as PersonalityFacetKey]
                : null;

            return `${intl.formatMessage(
                facetMessages?.factor as MessageStructure['factor']
            )}: ${intl.formatMessage(facetMessages?.title as MessageStructure['title'])}`;
        }

        function getFacetColor(facet?: string | null) {
            const defaultColor = ColorBaseGrey200;
            const facetColor = facet
                ? PERSONALITY_IRT_FACET_COLORS[facet as PersonalityFacetKey]
                : null;
            return facetColor ? facetColor : defaultColor;
        }

        function getWeightPercentage(sumOfWeights?: number, weight?: string | null) {
            const weightValue = convertWeightToValue(weight);

            return sumOfWeights ? weightValue / sumOfWeights : weightValue;
        }

        if (includeLogicTest && isDefined(testProfile)) {
            values.push(testProfile?.logicWeight);
            labels.push('Logic');
            colors.push(TEST_PROFILE_COLORS.LOGIC_TEST);
        }

        if (includePersonalityTest) {
            const sumOfPersonalityWeights = testProfile?.facetCriteria?.reduce(
                (acc, item) => acc + convertWeightToValue(item?.weight),
                0
            );

            testProfile?.facetCriteria?.forEach(facetCriterion => {
                labels.push(getFacetName(facetCriterion?.facet));
                colors.push(getFacetColor(facetCriterion?.facet));
                const weightPercentage = getWeightPercentage(
                    sumOfPersonalityWeights,
                    facetCriterion?.weight
                );
                if (testProfile?.personalityWeight) {
                    values.push(weightPercentage * testProfile.personalityWeight);
                }
            });
        }

        return [
            {
                values: values,
                labels: labels,
                marker: {
                    colors: colors
                },
                hole: 0.25,
                sort: false,
                type: 'pie',
                textfont: {
                    family: 'Euclid Circular A',
                    size: 14,
                    color: ColorBgDefault
                },
                hoverinfo: 'none'
            }
        ];
    }, [intl, testProfile, includeLogicTest, includePersonalityTest]);

    const layout = useMemo((): Partial<Layout> => {
        return {
            margin: {
                l: MARGIN,
                r: MARGIN,
                t: MARGIN,
                b: MARGIN
            },
            height: HEIGHT,
            paper_bgcolor: 'transparent',
            plot_bgcolor: 'transparent',
            hidesources: true,
            showlegend: false,
            hovermode: 'closest'
        };
    }, []);

    return (
        <ChartWrapper>
            <Plot
                style={{width: '360px'}}
                data={traces}
                layout={layout}
                config={{displayModeBar: false}}
                useResizeHandler
            />
        </ChartWrapper>
    );
}

const ChartWrapper = styled.div`
    flex-grow: 1;
    flex-basis: 0;
`;
