import styled from 'styled-components';

import {OtherCaption} from '@/componentLibrary/tokens/typography';
import {
    BorderRadiusBaseRounded,
    BoxShadowBaseDrag,
    ColorBgDefault,
    ColorFgDefault,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const Content = styled.div`
    ${OtherCaption};
    background-color: ${ColorBgDefault};
    color: ${ColorFgDefault};
    box-shadow: ${BoxShadowBaseDrag};
    padding: ${SpacingXxsmall} ${SpacingXsmall};
    border-radius: ${BorderRadiusBaseRounded};
    max-height: 33px;
    max-width: 356px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 100000;
`;

export const StyledTriggerButton = styled.button`
    display: inline-flex;
    cursor: help;
`;

export const ContentWrapper = styled.div`
    position: relative;
    z-index: 100000;
`;
