import {HTMLAttributes} from 'react';
import styled from 'styled-components';

import {
    HeadingH1,
    HeadingH2,
    HeadingH3,
    HeadingH4
} from '@/componentLibrary/tokens/typography';

import {Base} from './Base';

type HeadingProps = {
    forwardedAs?: keyof JSX.IntrinsicElements;
} & HTMLAttributes<HTMLSpanElement>;

export const H1 = styled(Base).attrs((props: HeadingProps) => ({
    as: props.forwardedAs || 'h1'
}))`
    ${HeadingH1}
`;

export const H2 = styled(Base).attrs((props: HeadingProps) => ({
    as: props.forwardedAs || 'h2'
}))`
    ${HeadingH2}
`;

export const H3 = styled(Base).attrs((props: HeadingProps) => ({
    as: props.forwardedAs || 'h3'
}))`
    ${HeadingH3}
`;

export const H4 = styled(Base).attrs((props: HeadingProps) => ({
    as: props.forwardedAs || 'h4'
}))`
    ${HeadingH4}
`;
