import {useCallback, useState} from 'react';

import {useSendSignupEmail} from '@/api/email/useSendSignupEmail';
import {logger} from '@/services/logrocket';

export function useResendConfirmationEmail(userId: number) {
    const [doSendSignupEmail, {error, loading}] = useSendSignupEmail();
    const [success, setSuccess] = useState<boolean>(false);
    const resendConfirmationEmail = useCallback(() => {
        doSendSignupEmail(userId)
            .then(({data}) => {
                const {ok, errorMessage} = data?.sendSignupEmail ?? {};
                if (ok) {
                    setSuccess(true);
                } else {
                    throw new Error(errorMessage ?? '');
                }
            })
            .catch(err => {
                logger.error(err);
                setSuccess(false);
            });
    }, [userId, doSendSignupEmail]);

    return {resendConfirmationEmail, success, loading, error};
}
