import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.sharedPage.emailPage.title'
    },
    yourEmail: {
        id: 'app.sharedPage.emailPage.label'
    },
    viewResults: {
        id: 'app.sharedPage.emailPage.button'
    },
    disclaimer: {
        id: 'app.sharedPage.emailPage.emailPolicy'
    },
    operationFailed: {
        id: 'app.errorState.title'
    }
});
