import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.user.jobRecommendations.RejectJobRecommendation.title'
    },
    cancel: {
        id: 'app.pages.user.jobRecommendations.RejectJobRecommendation.cancel'
    },
    dismissRecommendation: {
        id: 'app.pages.user.jobRecommendations.RejectJobRecommendation.dismissRecommendation'
    }
});
