import {Popover} from '@/componentLibrary/components/Tooltips/Popover';
import {P2, S2} from '@/componentLibrary/components/typography';
import {isMobileOrTablet} from '@/utils/guessBrowser';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {useOptionLogic} from './logic';
import {ButtonWrapper, HoverWrapper, Text} from './styled';
import {OptionProps} from './types';

export function Button<T>({option, onSelect, isSelected}: OptionProps<T>) {
    const onClick = useOptionLogic<T>(option, onSelect);

    const content = (
        <HoverWrapper>
            {isDefined(option.hoverTitle) && <S2>{option.hoverTitle}</S2>}
            <P2>{option.hoverText}</P2>
        </HoverWrapper>
    );

    return (
        <Popover
            placement="bottom"
            shouldTrigger={
                !isMobileOrTablet() && isDefined(option.hoverText) && !option.disabled
            }
            openOnHover
            asChild
            hasArrow
            noFocusTrap
            returnFocus={false}
            trigger={
                <ButtonWrapper
                    data-testid={`segmented-control-option-${option.value}`}
                    isSelected={isSelected}
                    isDisabled={option.disabled}
                    onClick={onClick}
                >
                    <Text>{option.text || option.value}</Text>
                </ButtonWrapper>
            }
            content={content}
        />
    );
}
