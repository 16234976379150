import styled from 'styled-components';

const BaseBar = styled.div<{height: number}>`
    height: ${({height}) => height}px;
`;

export const OuterBar = styled(BaseBar)<{height: number; backgroundColor?: string}>`
    background-color: ${({backgroundColor}) => backgroundColor};
`;

export const InnerBar = styled(BaseBar)<{
    height: number;
    percentageValue: number;
    barColor: string;
}>`
    width: ${({percentageValue}) => percentageValue * 100}%;
    background-color: ${({barColor}) => barColor};
`;
