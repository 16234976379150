import {gql} from '@/api/types/__generated__';

export const GET_INTERVIEW_REQUESTS_FROM_JOB_APPLICATION = gql(`
    query getInterviewRequestsForJobApplication(
        $jobPositionId: Int!
        $userId: Int!
        $interviewId: Int
    ) {
        jobApplication(jobPositionId: $jobPositionId, userId: $userId) {
            id
            withdrawnAt
            interviewRequests(interviewId: $interviewId) {
                id
                created
                modified
                jobApplicationId
                interviewId
                employment {
                    id
                    user {
                        id
                        firstName
                        lastName
                        email
                    }
                }
                publishedAt
                competencyScores {
                    id
                }
            }
        }
    }
`);

export const GET_INTERVIEW_WITH_CONTENT_ITEM_SCORES_FOR_JOB_APPLICATION = gql(`
    query getInterviewWithContentItemScoresForJobApplication(
        $interviewId: Int!
        $jobApplicationId: Int!
    ) {
        interview(id: $interviewId) {
            id
            contentItems {
                ...InterviewContentItemFields
                publishedScores(jobApplicationId: $jobApplicationId) {
                    id
                    score
                    comment
                    isSkipped
                    interviewRequest {
                        id
                        employment {
                            id
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                        }
                    }
                }
                aggregatedScore(jobApplicationId: $jobApplicationId) {
                    competencyScoreAverage
                    interviews
                }
            }
        }
    }
`);

export const GET_INTERVIEW_REQUEST = gql(`
    query getInterviewRequest(
        $id: Int!
    ) {
        interviewRequest(id: $id) {
            id
            jobApplicationId
            publishedAt
            interviewId
            interview {
                id
                title
                description
                contentItems {
                    ...InterviewContentItemFields
                }
            }
            competencyScores {
                id
                created
                modified
                interviewContentItemId
                score
                comment
                isSkipped
            }
            publishedCompetencyScores {
                id
                created
                modified
                interviewContentItemId
                score
                comment
                isSkipped
                publishedAt
            }
            candidateUserId
            candidateFirstName
            candidateLastName
            candidateEmail
            jobPositionId
            jobPositionName
            jobPositionRoleId
            jobPositionRoleName
            jobPositionTeamId
            jobPositionTeamName
            interviewTitle
            interviewDescription
            hiringManagerId
            hiringManagerFirstName
            hiringManagerLastName
        }
    }
`);

export const GET_ORGANIZATION_INTERVIEW_TEMPLATES = gql(`
    query getOrganizationInterviewTemplates {
        authenticatedOrganization {
            id
            interviewTemplates {
                id
                title
                created
                description
                createdByAlva
                contentLibraryItems {
                    ...ContentLibraryItemsFields
                }
            }
        }
    }
`);

export const GET_INTERVIEW_TEMPLATE_BY_ID_QUERY = gql(`
    query getInterviewTemplateById(
        $interviewTemplateId: String!
    ) {
        interviewTemplate(id: $interviewTemplateId) {
            id
            title
            description
            contentLibraryItems {
                ...ContentLibraryItemsFields
            }
        }
    }
`);

export const FETCH_INTERVIEW_TEMPLATES_BY_IDS_QUERY = gql(`
    query fetchInterviewTemplatesByIds($interviewTemplateIds: [String]!) {
        interviewTemplates(interviewTemplateIds: $interviewTemplateIds) {
            id
            title
            description
        }
    }
`);

export const FETCH_INTERVIEW_REQUESTS_FOR_EMPLOYMENT = gql(`
    query fetchInterviewRequests($fetchOptions: InterviewRequestsFetchOptionsInput) {
        currentEmployment {
            id
            interviewRequestsConnection(fetchOptions: $fetchOptions) {
                totalResults
                cursor {
                    hasMore
                    next
                }
                items {
                    id
                    publishedAt
                    competencyScores {
                        id
                    }
                    publishedCompetencyScores {
                        id
                    }
                    candidateUserId
                    candidateFirstName
                    candidateLastName
                    candidateEmail
                    jobPositionName
                    jobPositionId
                    interviewTitle
                }
            }
        }
    }
`);

export const FETCH_INTERVIEWS_BY_IDS_QUERY = gql(`
    query fetchInterviewsByIds($interviewIds: [Int]!) {
        interviews(interviewIds: $interviewIds) {
            id
            title
            description
        }
    }
`);

export const GET_ORGANIZATION_INTERVIEW_TAGS = gql(`
    query getOrganizationInterviewTags {
        authenticatedOrganization {
            id
            organizationId
            interviewTags {
                id
                name
            }
        }
    }
`);

export const GET_ORGANIZATION_INTERVIEW_CONTENT_LIBRARY_ITEMS = gql(`
    query getOrganizationInterviewContentLibraryItems {
        authenticatedOrganization {
            id
            organizationId
            interviewContentLibraryItems {
                ...ContentLibraryItemsFields
            }
        }
    }
`);

export const GET_ORGANIZATION_INTERVIEW_CONTENT_LIBRARY_ITEM = gql(`
    query getOrganizationInterviewContentLibraryItem($itemId: String!)  {
        authenticatedOrganization {
            id
            organizationId
            interviewContentLibraryItem(itemId: $itemId) {
                ...ContentLibraryItemsFields
            }
        }
    }
`);
