import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    ColorBaseGrey200,
    SpacingLarge,
    SpacingMedium,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

const ContentItemListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingLarge};
`;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

const GridBase = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 6fr 6fr;
    grid-row-gap: ${SpacingXxlarge};
`;

const ScorecardsHeader = styled(GridBase)`
    height: ${SpacingXlarge};
    align-items: center;
    border-bottom: 1px solid ${ColorBaseGrey200};
`;

const ContentItemsGrid = styled(GridBase)`
    margin: ${SpacingMedium} 0;
    position: relative;

    @media only screen and (max-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        display: flex;
        flex-direction: column;
        grid-row-gap: ${SpacingXsmall};
    }
`;

const EmptyStateContentItemsGrid = styled(ContentItemsGrid)`
    align-items: center;
`;

export {
    ContentItemListWrapper,
    Wrapper,
    ScorecardsHeader,
    ContentItemsGrid,
    EmptyStateContentItemsGrid
};
