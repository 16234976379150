import {StandardTenChartRangeSvgProps} from '@/componentLibrary/components/charts/StandardTenChartRange/components/StandardTenChartRangeSvg/types';
import {DEFAULT_BLOCK_COLOR} from '@/componentLibrary/components/charts/StandardTenChartRange/constants';

export const StandardTenChartRangeSvg = ({
    currentWidth,
    height,
    maskId,
    formattedBlocks
}: StandardTenChartRangeSvgProps) => {
    return (
        <svg
            width={currentWidth()}
            height={height}
            viewBox="0 0 414 68"
            preserveAspectRatio="none"
            fill="none"
        >
            <mask id={maskId} maskUnits="userSpaceOnUse" x={0} y={0} width={414} height={68}>
                <path
                    d="M114.79 30.91C74.332 51.44 20.7 61.817 0 61.817V68h414v-6.182c-18.818 0-71.509-12.363-109.146-30.909C267.217 12.364 238.991 0 207 0c-27.286 0-51.751 10.379-92.21 30.91z"
                    fill={DEFAULT_BLOCK_COLOR}
                />
            </mask>
            <g mask={`url(#${maskId})`}>{formattedBlocks}</g>
        </svg>
    );
};
