export const WEIGHTS = {
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH'
};

export const convertWeightToValue = (weight?: string | null) => {
    switch (weight) {
        case WEIGHTS.LOW:
            return 1;
        case WEIGHTS.MEDIUM:
            return 2;
        case WEIGHTS.HIGH:
            return 3;
        default:
            return 0;
    }
};
