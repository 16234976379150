import {ApolloError} from '@apollo/client';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {SmallSecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {H3} from '@/componentLibrary/components/typography/Headings';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import messages from '@/pages/User/components/JobRecommendations/messages';

type Props = {
    loading: boolean;
    error?: ApolloError;
    hasRecommendations: boolean;
    onEdit: () => void;
};

export function JobRecommendationsHeader(props: Props) {
    const {loading, error, hasRecommendations, onEdit} = props;
    const intl = useIntl();

    const descriptionKey = hasRecommendations
        ? messages.description
        : messages.emptyDescription;
    const description = intl.formatMessage(descriptionKey);
    const title = intl.formatMessage(messages.title);
    const editProfile = intl.formatMessage(messages.editProfile);

    return (
        <Wrapper>
            <div>
                <H3>{title}</H3>
                {!loading && !error && <P1>{description}</P1>}
            </div>
            <ActionWrapper>
                <SmallSecondaryButton onClick={onEdit}>{editProfile}</SmallSecondaryButton>
            </ActionWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    & ${H3} {
        margin-bottom: 8px;
    }
`;

const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
`;
