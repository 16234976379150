import {ReactNode} from 'react-markdown';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {ColorVariables} from '@/componentLibrary/tokens/types';
import {
    ColorFgAttention,
    ColorFgDanger,
    ColorFgInfo,
    ColorFgSuccess
} from '@/componentLibrary/tokens/variables';
import {PhraseConstants, useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {Toast, ToastType} from '../../types';
import messages from './messages';
import {LoadingIcon} from './styled';

const getToastComposition = (
    phrases: PhraseConstants
): {
    [key in ToastType]: {
        color: ColorVariables;
        icon?: Icons;
        IconComponent?: typeof Icon;
        content?: string | ReactNode;
    };
} => {
    return {
        info: {
            color: ColorFgInfo,
            icon: Icons.INFO
        },
        loading: {
            color: ColorFgInfo,
            icon: Icons.AUTORENEW,
            IconComponent: LoadingIcon,
            content: phrases.loading
        },
        error: {
            color: ColorFgDanger,
            icon: Icons.ERROR_CIRCLE_ROUNDED,
            content: phrases.error
        },
        warning: {
            color: ColorFgAttention,
            icon: Icons.WARNING,
            content: phrases.warning
        },
        success: {
            color: ColorFgSuccess,
            icon: Icons.CHECK_CIRCLE,
            content: phrases.success
        }
    };
};

export const useToastComposition = ({message, type}: Toast) => {
    const phrases = useExtractPhraseConstants(messages);
    const {
        color,
        icon,
        IconComponent = Icon,
        content
    } = getToastComposition(phrases)[type || 'info'];
    return {
        icon: icon ? <IconComponent icon={icon} size={50} fill={color} /> : undefined,
        color,
        content: message ?? content
    };
};
