import {defineMessages} from 'react-intl';

export default defineMessages({
    congratulations: {
        id: 'app.pages.logicTestIrt.pages.createNewValidationLogicTest.congratulations'
    },
    description: {
        id: 'app.pages.logicTestIrt.pages.createNewValidationLogicTest.description'
    },
    refreshWarning: {
        id: 'app.pages.logicTestIrt.pages.createNewValidationLogicTest.refreshWarning'
    },
    additionalWarning: {
        id: 'app.pages.logicTestIrt.pages.createNewValidationLogicTest.additionalWarning'
    },
    startTest: {
        id: 'app.pages.logicTestIrt.pages.createNewValidationLogicTest.startTest'
    }
});
