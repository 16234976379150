import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_MY_USER_ORGANIZATIONS_WITH_SHARED_TESTS} from '@/api/users/queries';

export function useMyUserOrganizationsWithSharedTests() {
    const {error, loading, data, refetch} = useQuery(
        GET_MY_USER_ORGANIZATIONS_WITH_SHARED_TESTS,
        {fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first'}
    );

    const userId = useMemo(() => data?.me?.id ?? null, [data]);

    const userOrganizations = useMemo(() => data?.me?.userOrganizations ?? [], [data]);

    const sharedLogicTests = useMemo(() => data?.me?.sharedLogicTests ?? [], [data]);

    const sharedPersonalityTests = useMemo(
        () => data?.me?.sharedPersonalityTests ?? [],
        [data]
    );

    return {
        error,
        loading,
        refetch,
        userId,
        userOrganizations,
        sharedLogicTests,
        sharedPersonalityTests
    };
}
