import {Link} from 'react-router-dom';

import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {usePersonalityTestResult} from '@/api/personalityTests/usePersonalityTestResult';
import {InPageHeader} from '@/componentLibrary/components/InPageHeader';
import {SmallPrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {PERSONALITY_IRT_LINKS} from '@/pages/PersonalityTestIrt/links';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {sharedResultsLinks} from '@/pages/SharedResults/components/SharedResultsPage/links';
import {SharedResultsContext} from '@/pages/SharedResults/components/UserResults/hooks/SharedResultsContext';
import {useContext} from 'react';
import {RevokeSharedPersonalityTest} from './components/RevokeSharedPersonalityTest';
import messages from './messages';

interface OverviewHeaderProps {
    redirectLink?: string;
    personalityTestId?: string;
    disabled?: boolean;
    jobPositionId?: number;
}

export function OverviewHeader({
    redirectLink,
    personalityTestId,
    jobPositionId,
    disabled
}: OverviewHeaderProps) {
    const phrases = useExtractPhraseConstants(messages);
    const isSharedResultsView = useContext(SharedResultsContext);

    const {jobPosition} = useJobPosition(jobPositionId);
    const {personalityTestResult} = usePersonalityTestResult({personalityTestId});

    const baseUrl = !isSharedResultsView
        ? PERSONALITY_IRT_LINKS.getTestResultLink({
              testId: personalityTestId as string,
              jobPositionId: jobPositionId?.toString()
          })
        : sharedResultsLinks.personalityTestReport(
              isSharedResultsView,
              personalityTestId as string
          );
    const link = `${baseUrl}?redirect=${redirectLink}`;

    const shouldShowFullTestResult = !isDefined(disabled);
    const shouldShowRevokeButton =
        isDefined(personalityTestId) &&
        isDefined(jobPositionId) &&
        isDefined(personalityTestResult) &&
        !isSharedResultsView;

    const action = shouldShowFullTestResult ? (
        <Link to={link}>
            <SmallPrimaryButton>{phrases.seeFullTestResult}</SmallPrimaryButton>
        </Link>
    ) : null;

    const secondaryAction = shouldShowRevokeButton ? (
        <RevokeSharedPersonalityTest
            disabled={disabled}
            user={personalityTestResult?.user}
            organizationId={jobPosition?.organizationId}
            personalityTestId={personalityTestId}
        />
    ) : null;

    return (
        <InPageHeader
            title={{content: phrases.title}}
            primaryAction={action}
            secondaryAction={secondaryAction}
        />
    );
}
