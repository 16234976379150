import {useCallback, useState} from 'react';

import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import messages from '../components/TechStackSelector/messages';
import {MANUAL_TECH_STACK_OPTION} from '../constants';
import {SelectOption, UseTechStackSelectorProps} from '../types';
import {useImportBoilerplate} from './useImportBoilerplate';
import {useInitChallengeAssignment} from './useInitChallengeAssignment';

export const useTechStackSelector = ({challengeAssignment}: UseTechStackSelectorProps) => {
    const phrases = useExtractPhraseConstants(messages);
    const {modalIsOpen, openModal, closeModal} = useSimpleModalLogic();

    const [selectedFrameworkError, setSelectedFrameworkError] = useState<string | undefined>();

    const [selectedFramework, setSelectedFramework] = useState<SelectOption | null>(null);

    const handleSelectFramework = useCallback((framework: SelectOption) => {
        setSelectedFramework(framework);
        setSelectedFrameworkError(undefined);
    }, []);

    const autoPickedFramework =
        challengeAssignment?.challengeSpecifications?.frameworks.length === 1;

    const getSelectedFrameworkName = useCallback(() => {
        if (autoPickedFramework) {
            return challengeAssignment?.challengeSpecifications?.frameworks[0];
        }

        return selectedFramework?.name;
    }, [challengeAssignment?.challengeSpecifications, selectedFramework, autoPickedFramework]);

    const {importBoilerplate, loading: importingBoilerplate} = useImportBoilerplate({
        challengeAssignmentId: challengeAssignment?.id || ''
    });

    const {initChallengeAssignment, loading: initializingChallengeAssignment} =
        useInitChallengeAssignment({
            assignmentId: challengeAssignment?.id || ''
        });

    const validateSelectedFramework = useCallback(() => {
        if (!selectedFramework && !autoPickedFramework) {
            setSelectedFrameworkError(phrases.techstackSelectError);
            return false;
        }
        return true;
    }, [autoPickedFramework, phrases.techstackSelectError, selectedFramework]);

    const startImport = useCallback(async () => {
        if (!validateSelectedFramework()) {
            return;
        }

        if (challengeAssignment?.status === ChallengeAssignmentStatus.NOT_STARTED) {
            const initResult = await initChallengeAssignment({enableManualSubmission: true});
            if (initResult?.isError) {
                return;
            }
        }

        const selectedFrameworkName = getSelectedFrameworkName();

        if (selectedFramework?.id === MANUAL_TECH_STACK_OPTION.id || !selectedFrameworkName) {
            return;
        }

        const importResult = await importBoilerplate({framework: selectedFrameworkName});
        if (importResult?.isError) {
            return;
        }

        openModal();
    }, [
        initChallengeAssignment,
        openModal,
        selectedFramework,
        importBoilerplate,
        getSelectedFrameworkName,
        challengeAssignment?.status,
        validateSelectedFramework
    ]);

    return {
        modalIsOpen,
        closeModal,
        openModal,
        handleSelectFramework,
        selectedFrameworkError,
        selectedFramework,
        startImport,
        validateSelectedFramework,
        loading: initializingChallengeAssignment || importingBoilerplate,
        autoPickedFramework
    };
};
