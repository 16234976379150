import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';
import {GET_USER_WITH_RECOMMENDATIONS_SETTINGS} from '@/api/users/queries';
import {logger} from '@/services/logrocket';

import {
    GetLoggedInUserWithRecommendationSettingsQuery,
    UpdateUserRecommendationSettingsInput,
    UpdateUserRecommendationSettingsMutation
} from '../types/__generated__/graphql';
import {UPDATE_USER_RECOMMENDATION_SETTINGS} from './mutations';

export function useUpdateUserRecommendationSettings(
    skipUpdateCache?: boolean
): MutationHookReturnType<
    UpdateUserRecommendationSettingsMutation,
    UpdateUserRecommendationSettingsInput
> {
    const [mutate, {error, loading}] = useMutation(UPDATE_USER_RECOMMENDATION_SETTINGS, {
        update(cache, {data}) {
            if (skipUpdateCache) {
                return;
            }
            if (!data?.updateUserRecommendationSettings?.ok) {
                return;
            }
            try {
                const user: GetLoggedInUserWithRecommendationSettingsQuery | null =
                    cache.readQuery({
                        query: GET_USER_WITH_RECOMMENDATIONS_SETTINGS
                    });

                const me = user?.me;
                if (!me) {
                    return;
                }

                const updateRecommendationSettings = Object.assign({}, me, {
                    __typename: 'User',
                    recommendationsSettings: {
                        __typename: 'UserRecommendationsSettings',
                        recommendationsEnabled:
                            !me.recommendationsSettings.recommendationsEnabled
                    }
                });

                cache.writeQuery({
                    query: GET_USER_WITH_RECOMMENDATIONS_SETTINGS,
                    data: {
                        me: updateRecommendationSettings
                    } as GetLoggedInUserWithRecommendationSettingsQuery
                });
            } catch (cacheError) {
                logger.error(cacheError, ' when updating cache.');
            }
        }
    });

    const doUpdateUserRecommendationSettings = useCallback(
        data => mutate({variables: {data}}),
        [mutate]
    );

    return [doUpdateUserRecommendationSettings, {error, loading}];
}
