import {useChallengeAssignmentStatus} from '@/api/assessment/codingTests/useChallengeAssignmentStatus';
import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';
import {useCallback, useEffect, useState} from 'react';
import {GetMyJobApplications_JobApplication_ChallengeAssignment} from '../../../types';
import {updateChallengeAssignmentCache} from '../utils';

export function useChallengeAssignmentStatusLogic({
    jobPositionId,
    userId,
    challengeAssignment
}: {
    challengeAssignment: GetMyJobApplications_JobApplication_ChallengeAssignment;
    jobPositionId?: number;
    userId?: number;
}) {
    const [testStarted, setTestStarted] = useState<boolean>(
        challengeAssignment?.status === ChallengeAssignmentStatus.IN_PROGRESS ||
            (challengeAssignment?.isOpenForLateSubmission ?? false)
    );

    const [isPolling, setIsPolling] = useState<boolean>(false);

    const {startPolling, stopPolling, assignmentStatus, client} = useChallengeAssignmentStatus(
        {
            jobPositionId: jobPositionId || 0,
            userId: userId || 0
        }
    );

    const handleStartPolling = (duration: number) => {
        startPolling(duration);
        setIsPolling(true);
    };

    const handleStopPolling = useCallback(() => {
        stopPolling();
        setIsPolling(false);
    }, [stopPolling]);

    useEffect(() => {
        if (
            !testStarted &&
            assignmentStatus?.status === ChallengeAssignmentStatus.IN_PROGRESS &&
            (!challengeAssignment?.timeLimitMinutes ||
                (challengeAssignment?.timeLimitMinutes && assignmentStatus.submissionDeadline))
        ) {
            setTestStarted(true);
            handleStopPolling();
        } else if (isPolling && testStarted) {
            handleStopPolling();
        }
    }, [
        assignmentStatus,
        testStarted,
        handleStopPolling,
        isPolling,
        challengeAssignment?.timeLimitMinutes
    ]);

    useEffect(() => {
        if (
            testStarted ||
            assignmentStatus?.status !== ChallengeAssignmentStatus.IN_PROGRESS
        ) {
            return;
        }

        if (assignmentStatus && challengeAssignment?.id) {
            updateChallengeAssignmentCache({
                client,
                challengeAssignmentId: challengeAssignment.id,
                updatedPayload: assignmentStatus
            });
        }
    }, [client, assignmentStatus, challengeAssignment, testStarted]);

    useEffect(() => {
        return () => {
            handleStopPolling();
        };
    }, [handleStopPolling]);

    return {
        testStarted,
        handleStartPolling
    };
}
