import PropTypes from 'prop-types';
import {Component} from 'react';
import styled, {css} from 'styled-components';

import {coral, grey} from '@/componentLibrary/tokens/legacyColors';

const MAX_LOGIC_RESULT = 10;
const BOX_WIDTH = 100 / (MAX_LOGIC_RESULT - 1);

export class RangeBar extends Component {
    static propTypes = {
        lower: PropTypes.number,
        upper: PropTypes.number
    };

    isOutsideRange = result => {
        const {lower, upper} = this.props;

        return result < lower || result > upper - 1;
    };

    getColor = result => {
        return this.isOutsideRange(result) ? grey.grey800() : coral.lighter();
    };

    renderResultBoxes = () => {
        const resultArray = Array.from(Array(MAX_LOGIC_RESULT - 1).keys());

        return resultArray.map(result => (
            <ResultBox key={result} isFirst={result === 0}>
                <Bar
                    color={this.getColor(result + 1)}
                    isFirst={result === 0}
                    isLast={result === MAX_LOGIC_RESULT - 2}
                    isLower={result + 1 === this.props.lower}
                    isUpper={result + 1 === this.props.upper - 1}
                />
            </ResultBox>
        ));
    };

    render() {
        return (
            <Wrapper>
                <ResultBoxWrapper>{this.renderResultBoxes()}</ResultBoxWrapper>
            </Wrapper>
        );
    }
}

const ResultBoxWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
`;

const Wrapper = styled.div`
    padding-right: 24px;
    display: flex;
    height: 100%;
`;

const ResultBox = styled.div`
    height: 100%;
    width: ${BOX_WIDTH}%;
    display: flex;
    align-items: center;
    border-right: 1px dashed ${grey.grey700()};
    ${({isFirst}) =>
        isFirst
            ? css`
        border-left: 1px dashed ${grey.grey700()};
    `
            : ''}
`;

ResultBox.propTypes = {
    isFirst: PropTypes.bool
};

const Bar = styled.div`
    height: 8px;
    width: calc(100% + 1px);
    margin-left: -1px;
    background: ${({color}) => color};
    ${({isFirst}) =>
        isFirst
            ? css`
        border-radius: 2px 0 0 2px;
        padding-left: 1px;
        margin-left: 0;
    `
            : ''}
    ${({isLast}) =>
        isLast
            ? css`
        border-radius: 0 2px 2px 0;
        padding-right: 1px;
    `
            : ''}
  ${({isLower}) => (isLower ? 'border-radius: 2px 0 0 2px;' : '')}
  ${({isUpper}) => (isUpper ? 'border-radius: 0 2px 2px 0;' : '')}
  ${({isLower, isUpper}) => (isLower && isUpper ? 'border-radius: 2px;' : '')}
`;

Bar.propTypes = {
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    isLower: PropTypes.bool,
    isUpper: PropTypes.bool,
    color: PropTypes.string
};
