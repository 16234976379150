import {defineMessages} from 'react-intl';

export default defineMessages({
    totalTimeSpent: {
        id: 'app.logicTestReport.resultCharts.totalTimeSpent'
    },
    totalTimeForTest: {
        id: 'app.logicTestReport.resultCharts.totalTimeForTest'
    }
});
