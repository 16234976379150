import {defineMessages} from 'react-intl';

export default defineMessages({
    loading: {
        id: 'app.components.toast.loading'
    },
    error: {
        id: 'app.components.toast.error'
    },
    warning: {
        id: 'app.components.toast.warning'
    },
    success: {
        id: 'app.components.toast.success'
    }
});
