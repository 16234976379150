import {defineMessages} from 'react-intl';

export default defineMessages({
    personalityScore: {
        id: 'app.assessment.candidatePage.personalityTest.personalityScore'
    },
    facetTitle: {
        id: 'app.assessment.candidatePage.personalityTest.facetTitle'
    },
    facetLink: {
        id: 'app.assessment.candidatePage.personalityTest.facetLink'
    }
});
