import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {ModalEmpty} from '@/componentLibrary/components/modals/ModalEmpty';
import {DisplayMedium} from '@/componentLibrary/components/typography/Display';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    ContentWrapper,
    DescriptionWrapper,
    TextWrapper
} from '@/pages/LogicTestValidation/pages/LogicTestValidation/components/StartValidationTestPage/components/StartValidationLogicTest/styled';
import {StartValidationLogicTestProps} from '@/pages/LogicTestValidation/pages/LogicTestValidation/components/StartValidationTestPage/components/StartValidationLogicTest/types';
import messages from './messages';

export function StartValidationLogicTest({
    onStartTest,
    loading
}: StartValidationLogicTestProps) {
    const phrases = useExtractPhraseConstants(messages);

    return (
        <ModalEmpty noCenterWrapper>
            <ContentWrapper>
                <TextWrapper>
                    <DisplayMedium>{phrases.congratulations}</DisplayMedium>
                    <DescriptionWrapper>{phrases.description}</DescriptionWrapper>
                    <DescriptionWrapper>{phrases.refreshWarning}</DescriptionWrapper>
                    <DescriptionWrapper>{phrases.additionalWarning}</DescriptionWrapper>
                </TextWrapper>
                <PrimaryButton onClick={onStartTest} isLoading={loading}>
                    {phrases.startTest}
                </PrimaryButton>
            </ContentWrapper>
        </ModalEmpty>
    );
}
