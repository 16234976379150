import {S2} from '@/componentLibrary/components/typography';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import styled from 'styled-components';

export const InfoWrapper = styled.div`
    padding-right: 120px;
`;

export const ResultsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    
    @media only screen and (max-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        flex-direction: column;
        justify-content: initial;
    }
`;

export const Score = styled(S2)`
    margin-right: 60px;
    flex: 1;
    max-width: 22px;
`;
