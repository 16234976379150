import {H3} from '@/componentLibrary/components/typography/Headings';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {ValidationWrapper} from '../AdminContent/styled';
import {ScoreOverview} from '../ScoreOverview';
import {TimePerQuestionChart} from '../TimePerQuestionChart';
import {TotalTimeSpentGraph} from '../TotalTimeSpentGraph';
import {ValidationBanner} from '../ValidationBanner';
import messages from './messages';
import {ChartWrapper, ColumnWrapper, ContentWrapper, Wrapper} from './styled';
import {LogicTestValidationReportProps} from './types';

export function LogicTestValidationReport({
    originalTestScore,
    originalTestQuestions,
    validationIsCompleted,
    validationTestScore,
    validationTestQuestions,
    validationResultType,
    isValidationRequested
}: LogicTestValidationReportProps) {
    const phrases = useExtractPhraseConstants(messages);

    if (!isValidationRequested) {
        return null;
    }

    return (
        <ValidationWrapper>
            <Wrapper>
                <ValidationBanner
                    isCompleted={Boolean(validationIsCompleted)}
                    validationResultType={validationResultType}
                />
                {validationIsCompleted && validationTestScore && validationTestQuestions ? (
                    <ContentWrapper>
                        <ColumnWrapper>
                            <H3>{phrases.originalTest}</H3>
                            <ScoreOverview standardScore={originalTestScore} shortReport />
                            <ChartWrapper>
                                <TimePerQuestionChart
                                    questions={originalTestQuestions}
                                    graphHeight={260}
                                />
                            </ChartWrapper>
                            <ChartWrapper>
                                <TotalTimeSpentGraph
                                    questions={originalTestQuestions}
                                    graphHeight={260}
                                />
                            </ChartWrapper>
                        </ColumnWrapper>
                        <ColumnWrapper>
                            <H3>{phrases.validationTest}</H3>
                            <ScoreOverview standardScore={validationTestScore} shortReport />
                            <ChartWrapper>
                                <TimePerQuestionChart
                                    questions={validationTestQuestions}
                                    graphHeight={260}
                                />
                            </ChartWrapper>
                            <ChartWrapper>
                                <TotalTimeSpentGraph
                                    questions={validationTestQuestions}
                                    graphHeight={260}
                                />
                            </ChartWrapper>
                        </ColumnWrapper>
                    </ContentWrapper>
                ) : null}
            </Wrapper>
        </ValidationWrapper>
    );
}
