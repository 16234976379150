import {A_MESSAGES, C_MESSAGES, ES_MESSAGES, E_MESSAGES, O_MESSAGES} from './messages';

type MessageStructure = {
    description: {id: string};
    descriptionLower: {id: string};
    descriptionUpper: {id: string};
    labelLower: {id: string};
    labelUpper: {id: string};
    name: {id: string};
    resultAverage: {id: string};
    resultHigh: {id: string};
    resultLow: {id: string};
    resultVeryHigh: {id: string};
    resultVeryLow: {id: string};
};

// Defining the type for personality factor keys
export type PersonalityFactorKey = 'A' | 'C' | 'E' | 'ES' | 'O';

// Messages for each personality factor key should be typed as well
type PersonalityFactorMessages = {
    [key in PersonalityFactorKey]: MessageStructure; // assuming each factor has an array of strings as messages, modify as needed
};

export const PERSONALITY_FACTORS_KEYS: Record<PersonalityFactorKey, PersonalityFactorKey> = {
    A: 'A',
    C: 'C',
    E: 'E',
    ES: 'ES',
    O: 'O'
};

export const PERSONALITY_FACTOR_MESSAGES: PersonalityFactorMessages = {
    A: A_MESSAGES,
    C: C_MESSAGES,
    E: E_MESSAGES,
    ES: ES_MESSAGES,
    O: O_MESSAGES
};

export const PERSONALITY_FACTORS: PersonalityFactorKey[] = ['A', 'C', 'E', 'ES', 'O'];

export const PERSONALITY_IRT_FACETS = [
    'AI',
    'AII',
    'AIII',
    'CI',
    'CII',
    'CIII',
    'OI',
    'OII',
    'OIII',
    'EI',
    'EII',
    'EIII',
    'ESI',
    'ESII',
    'ESIII'
] as const;

export const TRANSFORM_VALUES = [
    'a',
    'ai',
    'aii',
    'aiii',
    'c',
    'ci',
    'cii',
    'ciii',
    'e',
    'ei',
    'eii',
    'eiii',
    'es',
    'esi',
    'esii',
    'esiii',
    'o',
    'oi',
    'oii',
    'oiii'
] as const;

// This is from old report
export const PERSONALITY_IRT_FACET_COLORS = {
    AI: '#d14725',
    AII: '#d15634',
    AIII: '#e57252',
    CI: '#F8A001',
    CII: '#FFB400',
    CIII: '#FFC501',
    EI: '#007AC7',
    EII: '#0091E0',
    EIII: '#40B0EF',
    ESI: '#3F2588',
    ESII: '#502C9A',
    ESIII: '#653CB6',
    OI: '#389D01',
    OII: '#45B302',
    OIII: '#60CD18'
};
