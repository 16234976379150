import {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {ChallengeAssignmentAutoScoreResultState} from '@/api/types/__generated__/graphql';
import {
    ColorBaseGreen800,
    ColorBaseRed800,
    ColorBaseYellow800
} from '@/componentLibrary/tokens/variables';

import {REVIEW_SCORES} from '../constants';
import messages from '../messages';

export function useStatus() {
    const intl = useIntl();
    return useMemo(
        () => ({
            [ChallengeAssignmentAutoScoreResultState.FAILED]: {
                text: intl.formatMessage(messages.failed),
                color: ColorBaseRed800
            },
            [ChallengeAssignmentAutoScoreResultState.PASSED]: {
                text: intl.formatMessage(messages.passed),
                color: ColorBaseGreen800
            }
        }),
        [intl]
    );
}

export function useScore() {
    const intl = useIntl();
    return useMemo(
        () => ({
            [REVIEW_SCORES.LOW]: {
                text: intl.formatMessage(messages.scoreLow),
                color: ColorBaseRed800
            },
            [REVIEW_SCORES.MID]: {
                text: intl.formatMessage(messages.scoreMid),
                color: ColorBaseYellow800
            },
            [REVIEW_SCORES.HIGH]: {
                text: intl.formatMessage(messages.scoreHigh),
                color: ColorBaseGreen800
            }
        }),
        [intl]
    );
}
