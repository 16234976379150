import {useIntl} from 'react-intl';

import {UTM_MEDIUM, UTM_SOURCE} from './constants';
import messages from './messages';

export function useOriginalJobAdLogic(url?: string | null) {
    const phrases = useJobAdPhrases();
    const urlWithTrackingParameters = `${url}?utm_source=${UTM_SOURCE}&utm_medium=${UTM_MEDIUM}`;
    return {urlWithTrackingParameters, phrases};
}

function useJobAdPhrases() {
    const intl = useIntl();
    const title = intl.formatMessage(messages.title);
    const description = intl.formatMessage(messages.description);
    const link = intl.formatMessage(messages.link);
    const applicationInfo = intl.formatMessage(messages.applicationInfo);
    return {title, description, link, applicationInfo};
}
