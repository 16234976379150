import {useQuery} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {FetchCursor} from '../types/__generated__/graphql';
import {FETCH_EMPLOYMENTS_FOR_ORGANIZATION} from './queries';
import {UseEmploymentLoaderOptions} from './types';

export function useEmploymentLoader(options: UseEmploymentLoaderOptions) {
    const {searchString = '', orderBy, isDescending, limit = 10, withTeams = true} = options;
    const getVariables = useCallback(
        (cursor: FetchCursor | null) => ({
            withTeams: withTeams,
            fetchOptions: {
                searchString: searchString,
                orderBy: orderBy,
                isDescending: isDescending,
                limit: limit,
                offset: cursor && cursor.next ? cursor.next : 0
            }
        }),
        [isDescending, limit, orderBy, searchString, withTeams]
    );
    const {data, error, networkStatus, fetchMore} = useQuery(
        FETCH_EMPLOYMENTS_FOR_ORGANIZATION,
        {
            variables: getVariables(null),
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first'
        }
    );
    const loading = networkStatus <= 4 && networkStatus !== 3;
    const loadingMore = networkStatus === 3;
    const loadMore = useCallback(() => {
        let currentCursor: FetchCursor = {
            hasMore: false,
            next: null,
            __typename: 'FetchCursor'
        };
        if (data?.authenticatedOrganization) {
            currentCursor = data.authenticatedOrganization.employmentsConnection.cursor;
        }

        if (!currentCursor.next) {
            return;
        }

        fetchMore({
            variables: getVariables(currentCursor),
            // @TODO Replace with field policies (https://www.apollographql.com/blog/announcing-the-release-of-apollo-client-3-0#pagination-helpers)
            updateQuery: (prev, {fetchMoreResult}) => {
                if (!fetchMoreResult) {
                    return prev;
                }

                const previousItems =
                    prev.authenticatedOrganization?.employmentsConnection.items ?? [];
                const prevCursor = prev
                    ? prev.authenticatedOrganization?.employmentsConnection?.cursor
                    : null;
                const {totalResults, cursor, items} =
                    fetchMoreResult.authenticatedOrganization?.employmentsConnection ?? {};

                const isNewEmployments =
                    !prevCursor?.next ||
                    prevCursor.next < (cursor?.next ?? 0) ||
                    (cursor?.next === null && prevCursor.next !== null);
                if (isNewEmployments) {
                    if (!prev?.authenticatedOrganization) {
                        return prev;
                    }
                    const updatedEmploymentsConnection = Object.assign(
                        {},
                        prev.authenticatedOrganization.employmentsConnection,
                        {
                            totalResults: totalResults,
                            cursor: cursor,
                            items: [...previousItems, ...(items ?? [])]
                        }
                    );
                    return Object.assign({}, prev, {
                        authenticatedOrganization: Object.assign(
                            {},
                            prev.authenticatedOrganization,
                            {
                                employmentsConnection: updatedEmploymentsConnection
                            }
                        )
                    });
                }
                return prev;
            }
        });
    }, [data, fetchMore, getVariables]);

    const employments = useMemo(() => {
        if (loading || !data?.authenticatedOrganization) {
            return [];
        }

        return data.authenticatedOrganization.employmentsConnection.items;
    }, [loading, data]);

    const totalResults = useMemo(() => {
        if (loading || !data?.authenticatedOrganization) {
            return null;
        }

        return data.authenticatedOrganization.employmentsConnection.totalResults;
    }, [data, loading]);

    const hasMore = useMemo(() => {
        if (loading || !data?.authenticatedOrganization) {
            return false;
        }
        return data.authenticatedOrganization.employmentsConnection.cursor.hasMore;
    }, [data, loading]);

    return {
        error,
        loading,
        hasMore,
        loadingMore,
        loadMore,
        employments,
        totalResults
    };
}
