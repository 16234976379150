import qs from 'qs';

import {getConfig} from '@/config';

import {SsoState} from './types';

const SSO_STATE_KEY = 'SSO_STATE';
const SSO_NONCE_KEY = 'SSO_NONCE';

function setSsoStateInSessionStorage(state: SsoState): void {
    window.sessionStorage.setItem(SSO_STATE_KEY, JSON.stringify(state));
}

function getSsoStateFromSessionStorage(): SsoState {
    const state = JSON.parse(sessionStorage.getItem(SSO_STATE_KEY) || '{}');
    if (state.organizationId) {
        state.organizationId = window.parseInt(state.organizationId, 10);
    }

    return state;
}

function removeSsoStateFromSessionStorage(): void {
    sessionStorage.removeItem(SSO_STATE_KEY);
}

function setSsoNonceInSessionStorage(nonce: string): void {
    window.sessionStorage.setItem(SSO_NONCE_KEY, nonce);
}

function getSsoNonceFromSessionStorage(): string | null {
    return sessionStorage.getItem(SSO_NONCE_KEY) || null;
}

function removeSsoNonceFromSessionStorage(): void {
    sessionStorage.removeItem(SSO_NONCE_KEY);
}

function getSsoRedirectUrl() {
    const appConfig = getConfig();

    return `${appConfig.APP}/verify-oidc-authentication`;
}

function encodeSsoStateParam(state: SsoState) {
    return qs.stringify(state, {encode: true});
}

export {
    setSsoStateInSessionStorage,
    getSsoStateFromSessionStorage,
    removeSsoStateFromSessionStorage,
    setSsoNonceInSessionStorage,
    getSsoNonceFromSessionStorage,
    removeSsoNonceFromSessionStorage,
    getSsoRedirectUrl,
    encodeSsoStateParam
};
