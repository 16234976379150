import {FormattedMessage} from 'react-intl';

import {DisplaySmallest} from '@/componentLibrary/components/typography/Display';
import {personalityTestTakingMessages} from '@/pages/PersonalityTestIrt/messages';
import {Wrapper} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/ErrorLoadingTest/styled';

export function ErrorLoadingTest() {
    return (
        <Wrapper>
            <DisplaySmallest>
                <FormattedMessage {...personalityTestTakingMessages.errorLoadingTest} />
            </DisplaySmallest>
        </Wrapper>
    );
}
