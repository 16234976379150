import styled from 'styled-components';

export const ToastContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ToastTitle = styled.div`
    font-weight: 500;
`;
