import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {
    AuthenticationMethod,
    LoginUserWithSsoFromAppMutation,
    OrganizationOktaConfiguration
} from '../types/__generated__/graphql';
import {LOGIN_USER_WITH_SSO} from './mutations';

export function useLoginUserWithSso(
    idToken: string | null,
    ssoProvider?: AuthenticationMethod,
    expectedOktaConfig?: OrganizationOktaConfiguration | null
): MutationHookReturnType<LoginUserWithSsoFromAppMutation> {
    const [mutate, {error, loading}] = useMutation(LOGIN_USER_WITH_SSO);

    const doLogin = useCallback(() => {
        if (!idToken || !ssoProvider) {
            return Promise.reject(new Error('Missing idToken or ssoProvider'));
        }

        const variables = {
            idToken,
            ssoProvider,
            expectedOktaDomain: expectedOktaConfig?.domain,
            expectedOktaClientId: expectedOktaConfig?.clientId
        };
        return mutate({variables});
    }, [
        idToken,
        ssoProvider,
        expectedOktaConfig?.domain,
        expectedOktaConfig?.clientId,
        mutate
    ]);

    return [doLogin, {error, loading}];
}
