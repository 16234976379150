import {useCallback} from 'react';

import {useOrganization} from '@/api/organizations/useOrganization';

import {ALVA_SELECTED_ORGANIZATION_KEY} from '../../services/auth/constants';

export function useSelectEmploymentOrganization() {
    const {organization, client} = useOrganization();
    const organizationId = organization ? organization.id : null;
    const selectOrganization = useCallback(
        newOrganizationId => {
            window.localStorage.setItem(ALVA_SELECTED_ORGANIZATION_KEY, newOrganizationId);
            return client.cache.reset();
        },
        [client]
    );
    const resetOrganizationSelection = useCallback(() => {
        if (organizationId) {
            window.localStorage.removeItem(ALVA_SELECTED_ORGANIZATION_KEY);
            return client.cache.reset();
        }
        return Promise.resolve();
    }, [client, organizationId]);
    return {
        resetOrganizationSelection,
        selectOrganization,
        selectedOrganizationId: organizationId
    };
}
