import {Icon} from '@/componentLibrary/components/icons/Icon';
import {ColorFgDanger} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    pointer-events: none;
`;

export const Indicator = styled.div<{score: number}>`
    position: relative;
    left: ${({score}) => score}%;
    display: grid;
    grid-template-rows: auto;
    width: 15px;
    margin-left: -8.5px;
    overflow: visible;
    height: 100%;
`;

export const HorizontalLine = styled.div`
    width: 1.5px;
    background: ${ColorFgDanger};
    margin: 0 auto;
    grid-area: 1 / 1;
`;

export const CustomIcon = styled(Icon)`
    grid-area: 1 / 1;
    width: 15px;
    height: 4px;

    svg {
        width: 40px; /* Not using tokens because this can never change */
        height: 40px; /* Not using tokens because this can never change */
    }
`;
