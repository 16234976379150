import {useIntl} from 'react-intl';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {DestructiveButton} from '@/componentLibrary/components/buttons/DestructiveButton';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {
    Markdown,
    MarkdownSupportedBodyTypes
} from '@/componentLibrary/components/typography/Markdown';

import {useCompareOrganizationName} from '../../../../hooks';
import {OrganizationInputField} from '../../../../styled';
import {ModalProps} from '../../../../types';
import {useWithdrawJobApplicationsFromOrganizationModalLogic} from './hooks';
import messages from './messages';

export function WithdrawApplicationsFromOrganizationModal({
    userId,
    organizationId,
    organizationName,
    closeModal
}: ModalProps) {
    const {loading, withdrawApplications} =
        useWithdrawJobApplicationsFromOrganizationModalLogic(
            userId,
            organizationId,
            closeModal
        );
    const {organizationNameInput, handleOrganizationInputChange, hasEnteredOrganizationName} =
        useCompareOrganizationName(organizationName);
    const intl = useIntl();
    const title = intl.formatMessage(messages.modalTitle);
    const description = intl.formatMessage(messages.description);
    const buttonText = intl.formatMessage(messages.withdrawApplications);
    const confirmationInputLabel = intl.formatMessage(messages.confirmationInputLabel, {
        organizationName
    });

    return (
        <Modal
            close={closeModal}
            headerTitle={title}
            footer={{
                primaryAction: (
                    <DestructiveButton
                        isLoading={loading}
                        onClick={withdrawApplications}
                        disabled={!hasEnteredOrganizationName}
                        isFullWidth
                    >
                        {buttonText}
                    </DestructiveButton>
                )
            }}
        >
            <Markdown body={MarkdownSupportedBodyTypes.P2}>{description}</Markdown>
            <OrganizationInputField>
                <TextField
                    name="organizationName"
                    type="text"
                    fullWidth
                    value={organizationNameInput}
                    onChange={handleOrganizationInputChange}
                    label={confirmationInputLabel}
                />
            </OrganizationInputField>
        </Modal>
    );
}
