import {useCallback, useEffect, useMemo, useState} from 'react';

import {CheckoutLinkParams, PlanKey} from '@/api/organizations/types';
import {useGenerateOrganizationCheckoutSessionLink} from '@/api/organizations/useGenerateOrganizationCheckoutSessionLink';
import {useOrganizationSubscription} from '@/api/organizations/useOrganizationSubscription';
import {useRequestSubscriptionUpgradeForOrganization} from '@/api/organizations/useRequestSubscriptionUpgradeForOrganization';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {PlanType} from '@/pages/UpgradePlan/components/UpgradePlan/types';

import {planKeyMapping} from './constants';
import {useParsePlan} from './hooks';

export const useUpgradePlan = () => {
    const plan: PlanType = useParsePlan();
    const planKey: PlanKey = useMemo(() => planKeyMapping[plan], [plan]);
    const [returnUrl, setReturnUrl] = useState<string>('/o/home');
    const [checkoutUrl, setCheckoutUrl] = useState('');
    const {error: errorUser, loading: loadingUser, user, refetch} = useLoggedInUser();
    const {loading: loadingOrg, error: errorOrg, organization} = useOrganizationSubscription();
    const [upgradeRequested, setUpgradeRequested] = useState(false);
    const [doRequestSubscriptionUpgrade, {error: errorRequesting, loading: loadingRequest}] =
        useRequestSubscriptionUpgradeForOrganization({
            organizationId: organization?.organizationId ?? '',
            newPlan: planKey
        });
    const [unauthorized, setUnauthorized] = useState(false);
    const doRequestUpgrade = useCallback(
        (numPositions: number) => {
            doRequestSubscriptionUpgrade(numPositions)
                .then(({data}) => {
                    const {requestSubscriptionUpgradeForOrganization} = data ?? {};
                    if (requestSubscriptionUpgradeForOrganization?.ok) {
                        setUpgradeRequested(true);
                        return;
                    }
                    throw new Error(
                        requestSubscriptionUpgradeForOrganization?.errorMessage ?? ''
                    );
                })
                .catch(e => {
                    if (e.message === 'ACCESS_CONTROL_EXCEPTION') {
                        setUnauthorized(true);
                    }
                    setError(true);
                });
        },
        [doRequestSubscriptionUpgrade]
    );

    const checkoutLinkParams = useMemo(() => {
        if (!organization) {
            return {
                organizationId: '',
                name: '',
                successUrl: '',
                cancelUrl: '',
                subscriptionExternalId: ''
            };
        }
        const baseUrl = `${window.location.origin}${window.location.pathname}`;
        const p: CheckoutLinkParams = {
            organizationId: organization.organizationId ?? '',
            name: organization.name ?? '',
            successUrl: `${baseUrl}?result=success`,
            cancelUrl: `${baseUrl}?result=canceled`,
            subscriptionExternalId: organization?.subscription?.externalId ?? '',
            upgradePlan: planKey
        };
        return p;
    }, [organization, planKey]);
    const [
        doGenerateOrganizationCheckoutSessionLink,
        {loading: loadingLink, error: errorLink}
    ] = useGenerateOrganizationCheckoutSessionLink(checkoutLinkParams);

    const [errorGeneratingLink, setErrorGeneratingLink] = useState(!!errorLink);

    const fetchCheckoutSession = useCallback(() => {
        doGenerateOrganizationCheckoutSessionLink()
            .then(({data}) => {
                const {generateCheckoutSessionLinkForOrganization} = data ?? {};
                if (
                    generateCheckoutSessionLinkForOrganization?.ok &&
                    generateCheckoutSessionLinkForOrganization?.stripeSessionUrl
                ) {
                    //OK
                    setCheckoutUrl(
                        generateCheckoutSessionLinkForOrganization.stripeSessionUrl
                    );
                    return;
                }
                throw new Error(
                    generateCheckoutSessionLinkForOrganization?.errorMessage ?? ''
                );
            })
            .catch(e => {
                console.error('Error generating link %o', e);
                setErrorGeneratingLink(true);
            });
    }, [doGenerateOrganizationCheckoutSessionLink]);

    const [error, setError] = useState(!!errorUser || !!errorOrg);

    useEffect(() => {
        if (!user) {
            refetch()
                .then(() => setError(false))
                .catch(() => setError(true));
        }
    }, [user, refetch]);

    useEffect(() => {
        const url = sessionStorage.getItem('PLAN_UPGRADE_ORIGIN');
        if (url) {
            setReturnUrl(url);
        }
    }, []);

    return {
        user,
        planKey,
        organization,
        checkoutUrl,
        returnUrl,
        fetchCheckoutSession,
        orgNotSelected: organization === null,
        error,
        unauthorized,
        loading: loadingUser || loadingOrg,
        loadingLink,
        errorGeneratingLink,
        doRequestUpgrade,
        errorRequesting,
        loadingRequest,
        upgradeRequested
    };
};
