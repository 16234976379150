import {parse} from 'qs';
import {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {parseProlificPid} from '@/services/prolific/prolificPidParser';

export function useProlificFormLogic(createUser: (contributorId: string | null) => void) {
    const location = useLocation();
    const [triedToSubmit, setTriedToSubmit] = useState(false);
    const [isConsentAccepted, setIsConsentAccepted] = useState(false);

    const acceptConsent = useCallback(({accepted}) => {
        setIsConsentAccepted(accepted);
    }, []);
    const prolificId = useMemo(() => {
        return parseProlificPid(
            parse(location.search, {ignoreQueryPrefix: true}).PROLIFIC_PID as string
        );
    }, [location]);

    const handleSubmit = useCallback(
        e => {
            e.preventDefault();
            setTriedToSubmit(true);
            if (isConsentAccepted) {
                createUser(prolificId);
            }
        },
        [prolificId, isConsentAccepted, createUser]
    );

    return {triedToSubmit, acceptConsent, handleSubmit, isConsentAccepted};
}
