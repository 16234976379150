import styled from 'styled-components';

import {
    BorderRadiusBaseRounded,
    ColorBgDefault,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div<{$itemNumber: number}>`
    display: grid;
    grid-template-columns: repeat(${({$itemNumber}) => $itemNumber}, 1fr);
    column-gap: ${SpacingXxsmall};
    background: ${ColorBgDefault};
`;
export const StepperItem = styled.div<{$bg: string}>`
    border-radius: ${BorderRadiusBaseRounded};
    height: ${SpacingXxxsmall};
    background-color: ${({$bg}) => $bg};
`;
