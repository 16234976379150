import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    SpacingLarge,
    SpacingMedium,
    SpacingXsmall,
    SpacingXxlarge,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

import {StyledBaseButton} from '../buttons/ButtonBase';
import {StyledTextField} from '../inputs/TextField/styled';

export const HeaderWrapper = styled.div`
    padding-block: ${SpacingXsmall};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${SpacingXxlarge};
    padding-inline: ${SpacingLarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: initial;
        gap: ${SpacingMedium};
    }

    @media print {
        padding: 0 20px 20px;
    }
`;

export const HeaderTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    height: auto;
    gap: ${SpacingXxsmall};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
    }
`;

export const HeaderTitle = styled(HeaderTitleWrapper)`
    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: row;
        align-items: center;
    }
`;

export const PrimaryActionWrapper = styled.div`
    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        ${StyledBaseButton}, ${StyledTextField} {
            width: 100%;
        }

        width: 100%;
    }
`;
