import {useCallback, useEffect, useState} from 'react';

import {toast} from '@/componentLibrary/components/Toast/toast';
import {P2} from '@/componentLibrary/components/typography';
import {DEFAULT_LOCALE} from '@/config';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {ajax, serializeQueryString} from '@/utils/ajax';

import {UseTestReportDownloadLogicProps} from '../types';
import messages from './messages';
import {ToastContent, ToastTitle} from './styled';

const REPORT_CONTENT_TYPE = 'application/pdf';
const DOWNLOAD_PDF_URL = '/download/generate-pdf';

export function useTestReportDownloadLogic({
    testId,
    userId,
    type,
    reportTitle,
    jobPositionId,
    reportLocale,
    organizationId
}: UseTestReportDownloadLogicProps) {
    const phrases = useExtractPhraseConstants(messages);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const onCompleted = useCallback(() => {
        setDone(true);
        setLoading(false);
    }, []);

    const onError = useCallback(() => {
        setError(true);
        setDone(true);
        setLoading(false);
    }, []);

    const renderToastContent = useCallback(
        (title: string, description: string) => (
            <ToastContent>
                <ToastTitle>{title}</ToastTitle>
                <P2>{description}</P2>
            </ToastContent>
        ),
        []
    );

    useEffect(() => {
        if (loading) {
            toast({
                type: 'info',
                message: renderToastContent(
                    phrases.generatingPdfInfoTitle,
                    phrases.generatingPdfInfoDescription
                )
            });
        }
    }, [loading, phrases, renderToastContent]);

    useEffect(() => {
        if (done && !error) {
            toast({type: 'success', message: phrases.downloadCompleted});
            setDone(false);
        }
    }, [done, error, phrases]);

    useEffect(() => {
        if (error) {
            toast({
                type: 'error',
                persist: true,
                hideCloseButton: false,
                message: renderToastContent(phrases.errorTitle, phrases.errorSubTitle)
            });
        }
    }, [error, phrases, renderToastContent]);

    useEffect(() => {
        const prevTitle = document.title;
        document.title = reportTitle;
        return () => {
            document.title = prevTitle;
        };
    });

    const download = useCallback(() => {
        const variables = {
            testId,
            type,
            userId: userId,
            reportLocale: reportLocale ?? DEFAULT_LOCALE,
            ...(jobPositionId && {jobPositionId}),
            ...(organizationId && {organizationId})
        };
        setLoading(true);
        setDone(false);
        ajax.downloadFile(
            `${DOWNLOAD_PDF_URL}${serializeQueryString(variables)}`,
            reportTitle,
            REPORT_CONTENT_TYPE,
            onCompleted,
            onError
        );
    }, [
        testId,
        type,
        userId,
        reportTitle,
        reportLocale,
        jobPositionId,
        organizationId,
        onCompleted,
        onError
    ]);

    return {error, done, download, loading};
}
