import styled from 'styled-components';

import {
    BorderRadiusBaseXsmall,
    ColorFgSubtle,
    SpacingMedium,
    SpacingSmall,
    SpacingXsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import arrow from '@/pages/LogicTestIrt/components/FormHeader/assets/arrow.svg';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const LeaveTestWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${ColorFgSubtle};
    padding: ${BorderRadiusBaseXsmall} ${SpacingXxxsmall};
    margin: 0 -${SpacingXxxsmall};
    border-radius: ${BorderRadiusBaseXsmall};
    cursor: pointer;

    &:hover {
        background-color: rgb(0 0 0 / 5%);
    }
`;

export const Arrow = styled.span`
    background: transparent url("${arrow}") center center no-repeat;
    background-size: contain;
    margin-inline-end: ${SpacingXsmall};
    width: ${SpacingSmall};
    height: ${SpacingSmall};

    [dir='rtl'] & {
        transform: scaleX(-1);
    }
`;

export const Questions = styled.span`
    margin-inline-end: ${SpacingXsmall};
    line-height: 30px;
`;

export const NumberDisplay = styled.span`
    line-height: 30px;
    font-size: ${SpacingMedium};
    color: ${props => props.color};
`;
