import {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import {useReplaceSharedLogicTest} from '@/api/logicTestIrt/useReplaceSharedLogicTest';
import {useShareLogicTestWithOrganization} from '@/api/logicTestIrt/useShareLogicTestWithOrganization';
import {useReplaceSharedPersonalityTest} from '@/api/personalityTests/useReplaceSharedPersonalityTest';
import {useSharePersonalityTestWithOrganization} from '@/api/personalityTests/useSharePersonalityTestWithOrganization';
import {useMyTestProgress} from '@/api/users/useMyTestProgress';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {logger} from '@/services/logrocket';

import {
    ReplaceSharedLogicTestMutation,
    ReplaceSharedPersonalityTestMutation
} from '@/api/types/__generated__/graphql';
import {FetchResult} from '@apollo/client';
import messages from './messages';

export function useSharePreviousResultsLogic(
    userId: number | null,
    logicTestId: string | null,
    personalityTestId: string | null,
    organizationId: number,
    setShowSeePreviousResult: (value: boolean) => void
) {
    const {formatMessage} = useIntl();
    const {user} = useMyTestProgress(organizationId);

    const isPreviouslySharedPersonalityTestExpired = useMemo(() => {
        return user?.personalityTestProgress.isSharedPersonalityTestExpired;
    }, [user]);

    const isPreviouslySharedLogicTestExpired = useMemo(() => {
        return user?.logicTestProgress.isSharedLogicTestExpired;
    }, [user]);

    const [doShareLogicTest, {loading: sharingLogicTest}] = useShareLogicTestWithOrganization(
        organizationId,
        userId,
        logicTestId
    );
    const [doSharePersonalityTest, {loading: sharingPersonalityTest}] =
        useSharePersonalityTestWithOrganization(organizationId, userId, personalityTestId);

    const [doReplaceSharedPersonalityTest, {loading: replacingSharedPersonalityTest}] =
        useReplaceSharedPersonalityTest(organizationId, personalityTestId);

    const [doReplaceSharedLogicTest, {loading: replacingSharedLogicTest}] =
        useReplaceSharedLogicTest(organizationId, logicTestId);

    const successToast = useCallback(() => {
        toast({
            type: 'success',
            message: formatMessage(messages.testSuccessfullyShared)
        });
    }, [formatMessage]);

    const replaceSharedPersonalityTest = useCallback(() => {
        const onResponse: (
            value: FetchResult<ReplaceSharedPersonalityTestMutation>
        ) => void | PromiseLike<void> = response => {
            const responseData = response.data;
            const mutationResponse = responseData?.replaceSharedPersonalityTest;
            const {ok, errorMessage} = mutationResponse || {};
            if (!ok) {
                logger.error('Error replacing shared personality test', errorMessage);
                throw new Error(
                    errorMessage ?? 'Unknown error when replacing shared personality test'
                );
            }
            successToast();
            setShowSeePreviousResult(false);
        };
        doReplaceSharedPersonalityTest()
            .then(onResponse)
            .catch(() => toast({type: 'error'}));
    }, [doReplaceSharedPersonalityTest, setShowSeePreviousResult, successToast]);

    const onlySharePersonalityTest = useCallback(() => {
        doSharePersonalityTest()
            .then(({data}) => {
                const {ok, errorMessage} = data?.sharePersonalityTestWithOrganization ?? {};
                if (ok) {
                    successToast();
                    setShowSeePreviousResult(false);
                } else {
                    throw new Error(
                        errorMessage ?? 'Unknown error when sharing personality test'
                    );
                }
            })
            .catch(() => toast({type: 'error'}));
    }, [doSharePersonalityTest, setShowSeePreviousResult, successToast]);

    const replaceSharedLogicTest = useCallback(() => {
        const onResponse: (
            value: FetchResult<ReplaceSharedLogicTestMutation>
        ) => void | PromiseLike<void> = response => {
            const responseData = response.data;
            const mutationResponse = responseData?.replaceSharedLogicTest;
            const {ok, errorMessage} = mutationResponse || {};
            if (!ok) {
                logger.error('Error replacing shared personality test', errorMessage);
                throw new Error(
                    errorMessage ?? 'Unknown error when replacing shared logic test'
                );
            }
            successToast();
            setShowSeePreviousResult(false);
        };
        doReplaceSharedLogicTest()
            .then(onResponse)
            .catch(() => toast({type: 'error'}));
    }, [doReplaceSharedLogicTest, setShowSeePreviousResult, successToast]);

    const onlyShareLogicTest = useCallback(() => {
        doShareLogicTest()
            .then(({data}) => {
                const {ok, errorMessage} = data?.shareLogicTestWithOrganization ?? {};
                if (ok) {
                    successToast();
                    setShowSeePreviousResult(false);
                } else {
                    throw new Error(errorMessage ?? 'Unknown error when sharing logic test');
                }
            })
            .catch(() => toast({type: 'error'}));
    }, [doShareLogicTest, setShowSeePreviousResult, successToast]);

    const sharePersonalityTest = useMemo(
        () =>
            isPreviouslySharedPersonalityTestExpired
                ? replaceSharedPersonalityTest
                : onlySharePersonalityTest,
        [
            isPreviouslySharedPersonalityTestExpired,
            onlySharePersonalityTest,
            replaceSharedPersonalityTest
        ]
    );

    const shareLogicTest = useMemo(
        () =>
            isPreviouslySharedLogicTestExpired ? replaceSharedLogicTest : onlyShareLogicTest,
        [isPreviouslySharedLogicTestExpired, replaceSharedLogicTest, onlyShareLogicTest]
    );

    return {
        sharingLogicTest: sharingLogicTest || replacingSharedLogicTest,
        shareLogicTest,
        sharingPersonalityTest: sharingPersonalityTest || replacingSharedPersonalityTest,
        sharePersonalityTest
    };
}
