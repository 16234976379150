import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {Header} from '@/componentLibrary/components/layout/Header';
import {DisplaySmall} from '@/componentLibrary/components/typography/Display';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {Wrapper} from '@/pages/LogicTestDataCollection/pages/DataCollectionForm/components/StartDataCollectionTest/styled';
import {StartDataCollectionTestProps} from '@/pages/LogicTestDataCollection/pages/DataCollectionForm/components/StartDataCollectionTest/types';
import {logicTestDataCollectionMessages} from '../../../../messages';
import {useStartDataCollectionTestLogic} from './logic';

export function StartDataCollectionTest({
    externalId,
    source,
    studyId,
    showErrorToast
}: StartDataCollectionTestProps) {
    const {creatingTest, startLogicTest} = useStartDataCollectionTestLogic({
        showErrorToast,
        externalId,
        source,
        studyId
    });
    const phrases = useExtractPhraseConstants(logicTestDataCollectionMessages);

    return (
        <Wrapper>
            <Header>
                <DisplaySmall data-testid={'start-data-collection-test-title'}>
                    {phrases.testInstructionsTitle}
                </DisplaySmall>
            </Header>
            <FormField>{phrases.testInstructionsContent}</FormField>

            <FormField>
                <PrimaryButton
                    isLoading={creatingTest}
                    onClick={startLogicTest}
                    data-testid={'start-data-collection-test-button'}
                >
                    {phrases.startTestButton}
                </PrimaryButton>
            </FormField>
        </Wrapper>
    );
}
