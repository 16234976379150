import styled from 'styled-components';

import {S2} from '@/componentLibrary/components/typography';
import {SpacingXxsmall} from '@/componentLibrary/tokens/variables';

const Wrapper = styled.div`
    display: flex;
    gap: ${SpacingXxsmall};
    align-items: center;
`;

const Label = styled(S2)`
    white-space: nowrap;
`;

export {Wrapper, Label};
