type FormatBytesProps = {
    bytes: number;
    decimals?: number;
    properNaming?: boolean;
};

export function formatBytes({bytes, decimals = 2, properNaming = false}: FormatBytesProps) {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const k = 1024,
        sizes = properNaming
            ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
            : ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}

export function roundScore(score: number, decimals: number = 1) {
    if (Number.isInteger(score)) {
        return score;
    }
    return score.toFixed(decimals);
}
