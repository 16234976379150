import {FormattedMessage} from 'react-intl';

import {H3, P2} from '@/componentLibrary/components/typography';
import {WelcomeSection} from '@/pages/SignupV2/components/WelcomeMessage/styled';

import messages from '../WelcomeMessage/messages';

export function WelcomeMessage() {
    return (
        <WelcomeSection>
            <H3>
                <FormattedMessage {...messages.welcome} />
            </H3>
            <P2>
                <FormattedMessage {...messages.introMessage} />
            </P2>
            <P2>
                <FormattedMessage {...messages.tagLine} />
            </P2>
        </WelcomeSection>
    );
}
