import styled from 'styled-components';

import {SpacingSmall, SpacingXxxsmall} from '@/componentLibrary/tokens/variables';

import {Skeleton} from './Skeleton';
import {SkeletonProps} from './types';

export const TextSkeleton = styled(Skeleton).attrs<SkeletonProps>(({height, width}) => ({
    height: height || SpacingSmall,
    width: width || '95px',
    borderRadius: SpacingXxxsmall
}))`
    margin-top: ${SpacingXxxsmall};
`;

export const TextSkeletonLarge = styled(TextSkeleton).attrs({
    height: '18px'
})`
    margin-bottom: 2px;
`;

export const TextSkeletonCaption = styled(TextSkeleton)`
    margin-top: 6px;
    margin-bottom: 2px;
`;
