import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {GetJobApplicationQuery} from '@/api/types/__generated__/graphql';
import {ScoreBar} from '@/componentLibrary/components/charts/ScoreBar';
import {secondaryColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {isDefined} from '@/utils/typeGuards/isDefined';

import messages from './messages';

type ScoreBarsProps = {
    roleFit?: NonNullable<GetJobApplicationQuery['jobApplication']>['roleFit'];
    jobPosition: {
        requirePersonalityTest: boolean;
        requireLogicIrtTest: boolean;
        requireInterviews: boolean;
    };
    width?: number;
    verticalMargin: number;
    isLargeTitle?: boolean;
};

export function ScoreBars({
    roleFit,
    jobPosition,
    width,
    verticalMargin,
    isLargeTitle
}: ScoreBarsProps) {
    const intl = useIntl();
    let testProfilePersonalityScore = null;
    let testProfileLogicScore = null;

    if (isDefined(roleFit)) {
        ({testProfilePersonalityScore, testProfileLogicScore} = roleFit);
    }

    return (
        <Wrapper width={width}>
            {jobPosition.requirePersonalityTest && (
                <ScoreBarWrapper verticalMargin={verticalMargin}>
                    <ScoreBar
                        barColor={secondaryColorTokens.blue000()}
                        title={intl.formatMessage(messages.personalityScore)}
                        score={testProfilePersonalityScore}
                        isLargeTitle={isLargeTitle}
                    />
                </ScoreBarWrapper>
            )}
            {jobPosition.requireLogicIrtTest && (
                <ScoreBarWrapper verticalMargin={verticalMargin}>
                    <ScoreBar
                        barColor={secondaryColorTokens.brown000()}
                        title={intl.formatMessage(messages.logicScore)}
                        score={testProfileLogicScore}
                        isLargeTitle={isLargeTitle}
                    />
                </ScoreBarWrapper>
            )}
        </Wrapper>
    );
}

const Wrapper = styled.div<{width?: number}>`
    width: ${({width}) => (width ? `${width}px` : 'auto')};
`;

const ScoreBarWrapper = styled.div<{verticalMargin: number}>`
    margin-bottom: ${({verticalMargin}) => verticalMargin}px;

    &:last-child {
        margin-bottom: 0;
    }
`;
