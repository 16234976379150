import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {DestructiveButton} from '@/componentLibrary/components/buttons/DestructiveButton';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {Props} from '@/componentLibrary/components/inputs/TextEditor/components/LinkModal/types';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {useLinkModalLogic} from './logic';
import messages from './messages';
import {Wrapper} from './styled';

export function LinkModal({initialLink, initialLinkText, close, remove, save}: Props) {
    const phrases = useExtractPhraseConstants(messages);
    const {
        link,
        linkText,
        linkRef,
        linkTextRef,
        linkValid,
        linkTextValid,
        validateLink,
        validateLinkText,
        isEditing,
        handleLinkChange,
        handleLinkTextChange,
        onClearLinkText,
        onClearLink,
        onSave,
        onRemove
    } = useLinkModalLogic({initialLink, initialLinkText, close, remove, save});

    const footer = {
        rightSecondaryAction: <PrimaryButton onClick={onSave}>{phrases.save}</PrimaryButton>,
        leftSecondaryAction: isEditing ? (
            <DestructiveButton onClick={onRemove}>{phrases.removeLink}</DestructiveButton>
        ) : undefined
    };

    const title = isEditing ? phrases.editLink : phrases.addLink;

    return (
        <Modal headerTitle={title} close={close} footer={footer}>
            <Wrapper>
                <TextField
                    ref={linkTextRef}
                    required
                    fullWidth
                    isClearable
                    placeholder={''}
                    value={linkText}
                    hasError={!linkTextValid}
                    errorMessage={phrases.invalidText}
                    onChange={handleLinkTextChange}
                    onClear={onClearLinkText}
                    label={phrases.text}
                    onBlur={validateLinkText}
                />
                <TextField
                    ref={linkRef}
                    required
                    fullWidth
                    isClearable
                    placeholder={''}
                    value={link}
                    hasError={!linkValid}
                    errorMessage={phrases.invalidLink}
                    onChange={handleLinkChange}
                    onClear={onClearLink}
                    label={phrases.link}
                    onBlur={validateLink}
                />
            </Wrapper>
        </Modal>
    );
}
