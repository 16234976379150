import {defineMessages} from 'react-intl';

export default defineMessages({
    disclaimer: {
        id: 'app.signup.createFreeTrialAccount.recaptchaTerms.disclaimer'
    },
    termsOfServiceTitle: {
        id: 'app.signup.createFreeTrialAccount.recaptchaTerms.termsOfServiceTitle'
    },
    privacyPolicyTitle: {
        id: 'app.signup.createFreeTrialAccount.recaptchaTerms.privacyPolicyTitle'
    }
});
