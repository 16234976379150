import {InPageHeader} from '@/componentLibrary/components/InPageHeader';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import logicMessages from '../../LogicTest/components/OverviewHeader/messages';
import personalityMessages from '../../PersonalityTest/components/OverviewHeader/messages';
import {CenteredSpinner, SpinnerWrapper, Wrapper} from './styled';

export enum TestReportType {
    LOGIC_TEST = 'LOGIC_TEST',
    PERSONALITY_TEST = 'PERSONALITY_TEST'
}

export function TestReportOverviewLoading({type}: {type: TestReportType}) {
    const logicPhrases = useExtractPhraseConstants(logicMessages);
    const personalityPhrases = useExtractPhraseConstants(personalityMessages);
    const phrases = type === TestReportType.LOGIC_TEST ? logicPhrases : personalityPhrases;

    return (
        <Wrapper>
            <InPageHeader
                title={{content: phrases.title}}
                primaryAction={
                    <PrimaryButton disabled>{phrases.seeFullTestResult}</PrimaryButton>
                }
            />
            <SpinnerWrapper>
                <CenteredSpinner />
            </SpinnerWrapper>
        </Wrapper>
    );
}
