import {StandardTenChartRangeSvg} from '@/componentLibrary/components/charts/StandardTenChartRange/components/StandardTenChartRangeSvg';
import {useStandardTenChartRangeLogic} from '@/componentLibrary/components/charts/StandardTenChartRange/logic';
import {
    BadgeWrapper,
    LabelLine,
    LabelWrapper,
    LabelsWrapper,
    PercentageText,
    ScoresWrapper,
    SvgWrapper,
    Wrapper
} from '@/componentLibrary/components/charts/StandardTenChartRange/styled';
import {StandardTenChartRangeProps} from '@/componentLibrary/components/charts/StandardTenChartRange/types';
import {Badge} from '@/componentLibrary/components/misc/Badge';
import range from '@/utils/range';

import {DEFAULT_HEIGHT, DEFAULT_WIDTH} from './constants';

export function StandardTenChartRange({
    height = DEFAULT_HEIGHT,
    width = DEFAULT_WIDTH,
    lowerBound,
    upperBound,
    selectedValue,
    rangeColor
}: StandardTenChartRangeProps) {
    const {currentWidth, maskId, formattedBlocks, groupedRanges, blockWidth, blockPadding} =
        useStandardTenChartRangeLogic({width, lowerBound, upperBound, rangeColor});

    return (
        <Wrapper>
            <ScoresWrapper>
                {selectedValue &&
                    range(1, 11).map(i => {
                        return (
                            <BadgeWrapper key={i}>
                                {i === selectedValue ? <Badge value={selectedValue} /> : null}
                            </BadgeWrapper>
                        );
                    })}
            </ScoresWrapper>
            <SvgWrapper width={currentWidth()} height={height}>
                <StandardTenChartRangeSvg
                    currentWidth={currentWidth}
                    height={height}
                    maskId={maskId}
                    formattedBlocks={formattedBlocks}
                />
            </SvgWrapper>
            <LabelsWrapper width={currentWidth()} alignItems={'center'}>
                {groupedRanges.map((groupedRange, index) => {
                    if (groupedRange.count === 0) {
                        return null;
                    }

                    return (
                        <LabelWrapper
                            key={index}
                            blockCount={groupedRange.count}
                            blockWidth={blockWidth}
                            blockPadding={blockPadding}
                        >
                            <LabelLine show={groupedRange.count > 1} />
                            <PercentageText>{groupedRange.score * 100}%</PercentageText>
                            <LabelLine show={groupedRange.count > 1} />
                        </LabelWrapper>
                    );
                })}
            </LabelsWrapper>
        </Wrapper>
    );
}
