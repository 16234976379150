import {defineMessages} from 'react-intl';

export default defineMessages({
    showMore: {
        id: 'app.general.showMore'
    },
    showLess: {
        id: 'app.general.showLess'
    }
});
