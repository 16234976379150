import {ApolloError} from '@apollo/client';
import {MouseEvent, useCallback, useEffect} from 'react';

import {logger} from '@/services/logrocket';

export function useErrorStateLogic(refetch?: () => void, error?: ApolloError | Error) {
    const reloadData = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            if (!refetch) {
                return;
            }
            if (e) {
                e.preventDefault();
            }
            refetch();
        },
        [refetch]
    );

    const isQueryInFlightError = useCallback(() => {
        const queryInFlightErrorMessage = 'Store reset while query was in flight';
        return error && error.message && error.message.includes(queryInFlightErrorMessage);
    }, [error]);

    const logErrorMessage = useCallback(() => {
        if (!error || isQueryInFlightError()) {
            return;
        }

        if (error instanceof ApolloError) {
            const {graphQLErrors, networkError: _networkError} = error;

            if (Array.isArray(graphQLErrors) && graphQLErrors.length > 0) {
                const {graphQLErrors, networkError: _networkError} = error;
                graphQLErrors.forEach(({message, locations, path}) => {
                    logger.error(
                        `[GraphQL error]: Message: ${
                            hasSensitiveData(message) ? '**redacted**' : message
                        }, Location: ${locations}, Path: ${path}`
                    );
                });
            } else if (_networkError) {
                logger.error(`[Network error]: ${_networkError}`);
            }
        } else {
            logger.error(error);
        }
    }, [error, isQueryInFlightError]);

    useEffect(() => logErrorMessage(), [logErrorMessage]);

    return {reloadData};
}

export function hasSensitiveData(errorMessage: string): boolean {
    const sensitiveProperties = ['firstName', 'lastName', 'email', 'password'];

    return sensitiveProperties.some(property => errorMessage.includes(property));
}
