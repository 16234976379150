import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingSmall, SpacingXxsmall} from '@/componentLibrary/tokens/variables';

const Wrapper = styled.div`
    width: 100%;
`;

const BannerWrapper = styled.div`
    margin: ${SpacingSmall} 0;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.small}) {
        align-items: flex-start;
        flex-flow: column wrap;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.min}) {
        margin: ${SpacingXxsmall} 0;
    }
`;

const OrganizationNameWrapper = styled.div`
    margin-inline-start: ${SpacingSmall};
`;

export {Wrapper, BannerWrapper, ContentWrapper, TitleWrapper, OrganizationNameWrapper};
