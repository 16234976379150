import {useCallback, useEffect} from 'react';

import {logger} from '@/services/logrocket';
import {usePusherClient} from '@/services/pusher';

import {CustomChannel} from './types';

type Props<T> = {
    organizationId?: number;
    userId?: number;
    eventName: string;
    callback: ((data: T) => void) | ((data?: unknown) => void);
};

let userChannel: CustomChannel | undefined;
let organizationChannel: CustomChannel | undefined;

export function useRealTimeUpdates<T>(props: Props<T>) {
    const {callback, eventName} = props;

    const {
        subscribeToUserChannel,
        unsubscribeFromUserChannel,
        subscribeToOrganizationChannel,
        unsubscribeFromOrganizationChannel,
        pusherClient
    } = usePusherClient();

    const handleEvent = useCallback(
        data => {
            try {
                logger.debug('New event: ', data);
                callback(data);
            } catch (err) {
                logger.warn(err);
            }
        },
        [callback]
    );

    const bindUserChannel = useCallback(() => {
        if (!props.userId) {
            return;
        }
        let channel = subscribeToUserChannel(props.userId);
        if (channel) {
            channel = channel.bind(eventName, handleEvent);
            userChannel = {
                id: props.userId,
                channel: channel
            };
            logger.debug(
                'Subscribed to user channel %s and bound event handler for %s',
                props.userId,
                eventName
            );
        }
    }, [eventName, handleEvent, props.userId, subscribeToUserChannel]);

    const bindOrganizationChannel = useCallback(() => {
        if (!props.organizationId) {
            return;
        }
        let channel = subscribeToOrganizationChannel(props.organizationId);
        if (channel) {
            channel = channel.bind(eventName, handleEvent);
            organizationChannel = {
                id: props.organizationId,
                channel: channel
            };
            logger.debug(
                'Subscribed to organization channel %s and bound event handler for %s',
                props.organizationId,
                eventName
            );
        }
    }, [eventName, handleEvent, props.organizationId, subscribeToOrganizationChannel]);

    const unBindChannels = useCallback(() => {
        if (userChannel) {
            userChannel.channel.unbind(eventName, handleEvent);
            unsubscribeFromUserChannel(userChannel.id);
            logger.debug(
                'Unbound event handler for %s and unsubscribed from user channel %s',
                eventName,
                userChannel.id
            );
            userChannel = undefined;
        }
        if (organizationChannel) {
            organizationChannel.channel.unbind(eventName, handleEvent);
            unsubscribeFromOrganizationChannel(organizationChannel.id);
            logger.debug(
                'Unbound event handler for %s and unsubscribed from organization channel %s',
                eventName,
                organizationChannel.id
            );
            organizationChannel = undefined;
        }
    }, [
        eventName,
        handleEvent,
        unsubscribeFromOrganizationChannel,
        unsubscribeFromUserChannel
    ]);

    useEffect(() => {
        if (pusherClient) {
            bindUserChannel();
            bindOrganizationChannel();
        }
        return unBindChannels;
    }, [pusherClient, bindUserChannel, bindOrganizationChannel, unBindChannels]);
}
