import {
    ColorBaseGrey900,
    FontSizeDefault,
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
    margin: 0 auto;
    width: 35%;
    max-width: 640px;
    min-width: 420px;
    height: 100vh;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: flex-start;
    flex-direction: column;

    @media only screen and (max-width: 760px) {
        width: 100%;
        padding: 36px ${SpacingSmall};
        min-width: 0;
        height: auto;
    }

    @media only screen and (min-width: 761px) and (max-width: 800px) {
        width: 100%;
        padding: 36px 80px;
        min-width: 0;
        height: auto;
    }
`;

export const DescriptionWrapper = styled.div`
    font-weight: normal;
    margin-bottom: ${SpacingXxlarge};

    @media only screen and (max-width: 760px) {
        margin-bottom: ${SpacingMedium};
    }

    font-size: ${FontSizeDefault};
    color: ${ColorBaseGrey900};
`;

export const TextWrapper = styled.div`
    & h1 {
        margin-bottom: 48px;

        @media only screen and (max-width: 760px) {
            margin-bottom: ${SpacingLarge};
        }
    }
`;
