import {useCallback, useState} from 'react';

import {CreateUser} from '@/pages/PersonalityDataCollection/pages/DataCollectionForm/components/FigureEightContent/types';

export function useGeneralContentLogic(createUser: CreateUser) {
    const [triedToSubmit, setTriedToSubmit] = useState(false);
    const [isConsentAccepted, setIsConsentAccepted] = useState(false);

    const acceptConsent = useCallback(({accepted}) => {
        setIsConsentAccepted(accepted);
    }, []);

    const handleSubmit = useCallback(
        e => {
            e.preventDefault();
            setTriedToSubmit(true);
            if (isConsentAccepted) {
                createUser();
            }
        },
        [isConsentAccepted, createUser]
    );

    return {
        triedToSubmit,
        isConsentAccepted,
        acceptConsent,
        handleSubmit
    };
}
