import qs from 'qs';
import {useCallback, useEffect} from 'react';
import {Redirect} from 'react-router-dom';

import {useLogin} from '@/api/auth/useLogin';
import {getRedirectUrl} from '@/pages/Login/loginUtils';

import {LoginForm} from './components/LoginForm';
import {LoginStatusHandlerProps} from './types';

export function LoginStatusHandler(props: LoginStatusHandlerProps) {
    const [login, loginResult] = useLogin();

    const changeLocaleIfDifferent = useCallback(() => {
        if (
            props.isLoggedIn &&
            props.user?.language &&
            props.user?.language !== props.locale
        ) {
            props.changeLocale(props.user.language);
        }
    }, [props]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        changeLocaleIfDifferent();
    }, [changeLocaleIfDifferent, props.isLoggedIn]);

    function getPreFilledEmail() {
        const params = qs.parse(props.searchString, {ignoreQueryPrefix: true});
        return params.email as string;
    }

    function getMessage() {
        const params = qs.parse(props.searchString, {ignoreQueryPrefix: true});
        return params.message as string;
    }

    if (props.isLoggedIn) {
        return <Redirect to={getRedirectUrl(props.searchString)} />;
    }

    return (
        <LoginForm
            login={login}
            loginResult={loginResult}
            preFilledEmail={getPreFilledEmail()}
            message={getMessage()}
        />
    );
}
