import styled from 'styled-components';

import {Caption, S2} from '@/componentLibrary/components/typography';
import {
    BorderRadiusBaseRounded,
    ColorFgSubtle,
    SpacingSmall,
    SpacingXsmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    flex-grow: 1;
    flex-basis: 0;
`;

export const BulletContentCaption = styled(Caption)`
    color: ${ColorFgSubtle};
`;

export const BulletContent = styled.div`
    display: grid;
    grid-template-columns: auto 2fr ${SpacingSmall} 1fr;
    align-items: center;
    gap: ${SpacingXxsmall};
`;

export const BulletContentWeight = styled(S2)`
    text-align: left;
    white-space: nowrap;
    text-transform: capitalize;
`;

export const BulletListWrapper = styled.div<{$compact?: boolean}>`
    display: flex;
    flex-direction: column;
    gap: ${p => (p.$compact ? SpacingXxxsmall : SpacingXxsmall)};
`;

export const Marker = styled.div<{$markerColor?: string}>`
    background-color: ${p => p.$markerColor};
    border-radius: ${BorderRadiusBaseRounded};
    width: ${SpacingXsmall};
    height: ${SpacingXsmall};
    align-self: center;
`;
