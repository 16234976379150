import {CheckBox} from '@/componentLibrary/components/controls/CheckBox';
import {Question} from '@/pages/common/RelevanceForm/components/Question';
import {ErrorDisplay} from '@/pages/common/RelevanceForm/styled';

import {Option} from '../../types';
import {CheckBoxGroupWrapper, CheckBoxWrapper} from './styled';
import {Props} from './types';

export function CheckBoxGroupQuestion(props: Props) {
    const {
        title,
        options,
        subTitle,
        selectedOptionIds,
        onChange,
        errorType,
        formValidationErrors,
        getErrorText
    } = props;

    return (
        <Question
            id={errorType}
            title={title}
            subtitle={subTitle}
            content={
                <div>
                    <CheckBoxGroupWrapper count={options.length}>
                        {options.map((option: Option, index: number) => (
                            <CheckBoxWrapper key={index}>
                                <CheckBox
                                    checkboxId={option.id}
                                    value={selectedOptionIds.includes(option.id)}
                                    onChange={onChange.bind(null, option.id)}
                                    label={option.name}
                                    alignItems={'center'}
                                    withMargin={false}
                                />
                            </CheckBoxWrapper>
                        ))}
                    </CheckBoxGroupWrapper>
                    {formValidationErrors.includes(errorType) ? (
                        <ErrorDisplay>{getErrorText(errorType)}</ErrorDisplay>
                    ) : null}
                </div>
            }
        />
    );
}
