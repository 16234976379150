import styled from 'styled-components';

import {SpacingSmall, SpacingXxsmall} from '@/componentLibrary/tokens/variables';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const BarWrapper = styled.div`
    margin: ${SpacingXxsmall} 0 ${SpacingSmall};
`;

export {Wrapper, BarWrapper};
