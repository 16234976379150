import {JobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {MessagesWithIndexSignature} from '@/types/generic.types';
import {useIntl} from 'react-intl';

import messages from './messages';
import {
    AssessmentMethodDescription,
    PercentageValue,
    TextWrapper,
    WeightBarItem,
    WeightBarWrapper,
    Wrapper
} from './styled';
import {useWeightsBreakdownLogic} from './useWeightsBreakdownLogic';

type Props = {
    jobPosition: JobPosition;
};

const typedMessages = messages as MessagesWithIndexSignature;

export function AssessmentProfileWeights({jobPosition}: Props) {
    const {weightBarItems} = useWeightsBreakdownLogic(jobPosition);
    const intl = useIntl();

    return (
        <Wrapper>
            <WeightBarWrapper>
                {weightBarItems.map(item =>
                    item.weight > 0 ? (
                        <WeightBarItem
                            key={`assessment-profile-weights-${item.descriptionMessageId}`}
                            width={item.weight}
                            backgroundColor={item.color}
                        >
                            <TextWrapper>
                                <PercentageValue>{item.weight}%</PercentageValue>
                                <AssessmentMethodDescription>
                                    {intl.formatMessage(
                                        typedMessages[item.descriptionMessageId]
                                    )}
                                </AssessmentMethodDescription>
                            </TextWrapper>
                        </WeightBarItem>
                    ) : null
                )}
            </WeightBarWrapper>
        </Wrapper>
    );
}
