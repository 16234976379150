import {useCallback, useEffect, useState} from 'react';

import {getConfig} from '@/config';
import {logger} from '@/services/logrocket';

declare global {
    interface Window {
        dataLayer: [Record<string, number | string>];
    }
}

export const GoogleTagManager = () => {
    const isInitialized = useGoogleTagManager();

    useEffect(() => {
        if (!isInitialized) {
            return;
        }
        logger.log('Loaded google tag manager for %s', getConfig().GOOGLE_TAG_MANAGER_ID);
    }, [isInitialized]);

    return null;
};

function useGoogleTagManager() {
    const [initialized, setInitialized] = useState(false);

    const activateGTM = useCallback(() => {
        if (window.TestingMode) {
            console.log('Playwright detected, skipping GTM');
            return;
        }
        const gtmId = getConfig().GOOGLE_TAG_MANAGER_ID;
        if (!gtmId) {
            logger.info('No google tag manager id defined.');
            return;
        }
        const l = 'dataLayer';
        window[l] = window[l] || [];
        window[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        const f = document.getElementsByTagName('script')[0];
        if (f === null || !f.parentNode) {
            return;
        }
        const script = document.createElement('script'),
            dl = l !== 'dataLayer' ? '&l=' + l : '';
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId + dl;
        f.parentNode.insertBefore(script, f);

        script.onload = () => {
            setInitialized(true);
        };
        script.onerror = () => {
            logger.error('Error loading google tag manager');
        };
    }, []);

    useEffect(() => {
        activateGTM();
    }, [activateGTM]);

    return initialized;
}
