import styled from 'styled-components';

import {S2} from '@/componentLibrary/components/typography';
import {tertiaryColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {
    SpacingLarge,
    SpacingSmall,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const UserRowWrapper = styled.div<{isDanger: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${({isDanger}) => isDanger && `background-color: ${tertiaryColorTokens.red100(0.3)};`}
    padding: 0 ${SpacingXxsmall};
`;

export const SelectedCandidatesWrapper = styled.div`
    margin-top: ${SpacingXsmall};
    overflow-y: hidden;
`;

export const UserItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 56px;
    gap: ${SpacingSmall};
`;

export const Description = styled(S2)`
    margin-top: ${SpacingLarge};
`;
