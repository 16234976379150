import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {useJobApplication} from '@/api/assessment/jobApplications/useJobApplication';
import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {useTestProfileForJobPosition} from '@/api/assessment/testProfiles/useTestProfileForJobPosition';
import {Section} from '@/componentLibrary/components/layout/Section';
import {H4} from '@/componentLibrary/components/typography/Headings';

import {TestResults} from './components/TestResults';
import messages from './messages';

export function AssessmentResults({jobPositionId, userId}) {
    const {testProfile, loading: loadingTestProfile} =
        useTestProfileForJobPosition(jobPositionId);
    const {jobApplication, loading: loadingJobApplication} = useJobApplication(
        jobPositionId,
        userId,
        {withCandidate: true, withRoleFit: true}
    );
    const {
        jobPosition,
        error: jobPositionError,
        loading: loadingJobPosition,
        refetchJobPosition
    } = useJobPosition(jobPositionId);

    if (jobPositionError) {
        return (
            <Section
                header={
                    <H4>
                        <FormattedMessage {...messages.title} />
                    </H4>
                }
                error={{reload: refetchJobPosition}}
            />
        );
    }

    return (
        <Section
            header={
                <H4>
                    <FormattedMessage {...messages.title} />
                </H4>
            }
        >
            <TestResults
                testProfile={testProfile}
                jobApplication={jobApplication}
                loading={loadingTestProfile || loadingJobApplication || loadingJobPosition}
                isPersonalityRequired={jobPosition && jobPosition.requirePersonalityTest}
                isLogicRequired={jobPosition && jobPosition.requireLogicIrtTest}
                isOldLogicRequired={jobPosition && jobPosition.requireLogicTest}
            />
        </Section>
    );
}

AssessmentResults.propTypes = {
    jobPositionId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired
};
