/**
 * Do not edit directly
 * Generated on Mon, 26 Feb 2024 19:59:37 GMT
 */
import {css} from 'styled-components';

export const ColorBaseBrown100 = '#8F5D4D';
export const BorderBaseDefault = '1px solid #eaeaea';
export const BorderBaseMuted = '1px solid #f9f9f9';
export const BorderBaseFocus = '1px solid #2256E4';
export const BorderBaseError = '1px solid #c10d30';
export const BorderBaseInfo = '1px solid #88A7FF';
export const BorderBaseSuccess = '1px solid #2ed196';
export const BorderBaseAttention = '1px solid #FFE6A3';
export const BorderBaseDanger = '1px solid #ffa073';
export const BorderActionSecondaryDefault = '1px solid #eaeaea';
export const BorderActionSecondaryHover = '1px solid #b4b4b4';
export const BorderActionSecondaryActive = '1px solid #b4b4b4';
export const BorderActionSecondaryDisabled = '1px solid #f9f9f9';
export const BorderActionDestructiveDefault = '1px solid #f9d6ce';
export const BorderActionDestructiveHover = '1px solid #c10d30';
export const BorderActionDestructiveActive = '1px solid #c10d30';
export const BorderActionDestructiveDisabled = '1px solid #f9f9f9';
export const BorderDropAreaDefault = '2px dashed #eaeaea';
export const BorderDropAreaHover = '2px dashed #b4b4b4';
export const BorderDropAreaDrag = '2px dashed #88A7FF';
export const BorderBaseDefaultRule = css`
    border: 1px solid #eaeaea;
`;
export const BorderBaseMutedRule = css`
    border: 1px solid #f9f9f9;
`;
export const BorderBaseFocusRule = css`
    border: 1px solid #2256e4;
`;
export const BorderBaseErrorRule = css`
    border: 1px solid #c10d30;
`;
export const BorderBaseInfoRule = css`
    border: 1px solid #88a7ff;
`;
export const BorderBaseSuccessRule = css`
    border: 1px solid #2ed196;
`;
export const BorderBaseAttentionRule = css`
    border: 1px solid #ffe6a3;
`;
export const BorderBaseDangerRule = css`
    border: 1px solid #ffa073;
`;
export const BorderActionSecondaryDefaultRule = css`
    border: 1px solid #eaeaea;
`;
export const BorderActionSecondaryHoverRule = css`
    border: 1px solid #b4b4b4;
`;
export const BorderActionSecondaryActiveRule = css`
    border: 1px solid #b4b4b4;
`;
export const BorderActionSecondaryDisabledRule = css`
    border: 1px solid #f9f9f9;
`;
export const BorderActionDestructiveDefaultRule = css`
    border: 1px solid #f9d6ce;
`;
export const BorderActionDestructiveHoverRule = css`
    border: 1px solid #c10d30;
`;
export const BorderActionDestructiveActiveRule = css`
    border: 1px solid #c10d30;
`;
export const BorderActionDestructiveDisabledRule = css`
    border: 1px solid #f9f9f9;
`;
export const BorderDropAreaDefaultRule = css`
    border: 2px dashed #eaeaea;
`;
export const BorderDropAreaHoverRule = css`
    border: 2px dashed #b4b4b4;
`;
export const BorderDropAreaDragRule = css`
    border: 2px dashed #88a7ff;
`;
