import {useMemo} from 'react';

import {ColorVariables} from '@/componentLibrary/tokens/types';
import {
    ColorBgAttention,
    ColorBgDanger,
    ColorBgInfo,
    ColorBgSubtle,
    ColorBgSuccess,
    ColorBorderAttention,
    ColorBorderDanger,
    ColorBorderDefault,
    ColorBorderInfo,
    ColorBorderSuccess,
    ColorFgAttention,
    ColorFgDanger,
    ColorFgInfo,
    ColorFgSubtle,
    ColorFgSuccess
} from '@/componentLibrary/tokens/variables';

import {Icons} from '../../icons/constants';
import {BannerType} from '../constants';

type IconAndColors = {
    icon: Icons;
    borderColor: ColorVariables;
    bgColor: ColorVariables;
    iconColor: ColorVariables;
};

export const useBannerIconAndStyle = ({type}: {type: BannerType}): IconAndColors => {
    const iconAndColors = useMemo((): IconAndColors => {
        switch (type) {
            case BannerType.SUCCESS:
                return {
                    icon: Icons.CHECK_CIRCLE,
                    borderColor: ColorBorderSuccess,
                    bgColor: ColorBgSuccess,
                    iconColor: ColorFgSuccess
                };
            case BannerType.ERROR:
                return {
                    icon: Icons.WARNING,
                    borderColor: ColorBorderDanger,
                    bgColor: ColorBgDanger,
                    iconColor: ColorFgDanger
                };
            case BannerType.ATTENTION:
                return {
                    icon: Icons.ERROR_CIRCLE_ROUNDED,
                    borderColor: ColorBorderAttention,
                    bgColor: ColorBgAttention,
                    iconColor: ColorFgAttention
                };
            case BannerType.PLAIN:
                return {
                    icon: Icons.INFO,
                    borderColor: ColorBorderDefault,
                    bgColor: ColorBgSubtle,
                    iconColor: ColorFgSubtle
                };
            case BannerType.INFO:
            default:
                return {
                    icon: Icons.INFO,
                    borderColor: ColorBorderInfo,
                    bgColor: ColorBgInfo,
                    iconColor: ColorFgInfo
                };
        }
    }, [type]);

    return iconAndColors;
};
