import {matchSorter} from 'match-sorter';
import {useCallback, useMemo} from 'react';

import {useChangeLocaleForUser} from '@/api/users/useChangeLocaleForUser';
import {Dropdown} from '@/componentLibrary/components/Dropdowns/Dropdown';
import {useChangeLocale} from '@/containers/LanguageProvider/useChangeLocale';
import {
    AVAILABLE_LOCALES,
    AVAILABLE_LOCALE_NAMES,
    BASIC_LOCALES
} from '@/services/locale/constants';
import {logger} from '@/services/logrocket';

import {DropdownProps} from '../Dropdown/types';
import {SEARCHABLE_ITEMS} from './constants';
import {LanguageToggleProps} from './types';

export const LanguageToggle: React.FC<LanguageToggleProps> = ({
    userId,
    fullWidth = false,
    inputCss,
    onLocalSelect,
    selectedLocale,
    recruiterLocalesOnly = false
}) => {
    const {locale: userLocale, changeLocale} = useChangeLocale();
    const [doChangeLocaleForUser] = useChangeLocaleForUser();

    const LOCALES = recruiterLocalesOnly ? BASIC_LOCALES : AVAILABLE_LOCALES;

    const handleSelect = useCallback(
        (locale: string | null) => {
            if (locale === null) {
                return;
            }
            if (onLocalSelect) {
                onLocalSelect(locale);
            } else if (userId) {
                doChangeLocaleForUser(userId, locale)
                    .then(() => changeLocale(locale))
                    .catch(logger.error);
            } else {
                changeLocale(locale);
            }
        },
        [onLocalSelect, userId, doChangeLocaleForUser, changeLocale]
    );

    const itemToString = useCallback(
        (localeName: string) => AVAILABLE_LOCALE_NAMES[localeName],
        []
    );
    const itemToId = useCallback((localeName: string) => localeName, []);

    const languageFilter = useCallback(
        (searchString: string) => {
            if (!searchString) {
                return LOCALES;
            }
            const matches = matchSorter(SEARCHABLE_ITEMS, searchString, {
                keys: ['id', 'name']
            });
            return matches.map(item => item.id);
        },
        [LOCALES]
    );

    const locale = useMemo(() => {
        return selectedLocale ? selectedLocale : userLocale;
    }, [selectedLocale, userLocale]);

    return (
        <Dropdown<string>
            width={'255px'}
            fullWidth={fullWidth}
            selectedItem={locale}
            items={LOCALES.sort()}
            itemToId={itemToId as DropdownProps<string>['itemToId']}
            itemToString={itemToString}
            onSelect={handleSelect}
            customFilter={languageFilter}
            noFocusTrap
            inputCss={inputCss}
        />
    );
};
