import React, {useCallback, useState} from 'react';

import {CHECKBOX_VALUES} from '@/componentLibrary/components/controls/CheckBox/constants';

type ValidatePropsArguments = {
    defaultValue?: boolean | string;
    value?: boolean | string;
    isValueControlled: boolean;
};

function validateProps({defaultValue, value, isValueControlled}: ValidatePropsArguments) {
    if (defaultValue !== undefined && value !== undefined) {
        throw new Error('Value must be either controlled or uncontrolled in CheckBox');
    }

    if (isValueControlled && value === undefined) {
        throw new Error('CheckBox does not support changing from controlled to uncontrolled');
    }

    if (!isValueControlled && value !== undefined) {
        throw new Error('Uncontrolled CheckBox does not support setting controlled value');
    }
}

type HookArguments = {
    defaultValue?: boolean | string;
    value?: boolean | string;
    onChange?: (value: boolean) => void;
};

export function useCheckBoxValueLogic({defaultValue, value, onChange}: HookArguments) {
    const [isValueControlled] = useState(value !== undefined);
    const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue);

    validateProps({defaultValue, value, isValueControlled});

    function stopEventPropagation(e: React.MouseEvent<HTMLLabelElement | HTMLDivElement>) {
        e.stopPropagation();
    }

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!isValueControlled) {
                setUncontrolledValue(e.target.checked);
            }
            onChange?.(e.target.checked);
        },
        [isValueControlled, onChange]
    );

    const derivedValue = isValueControlled ? value : uncontrolledValue;
    const htmlCheckboxIsChecked =
        derivedValue === true || derivedValue === CHECKBOX_VALUES.CHECKED;

    return {derivedValue, htmlCheckboxIsChecked, stopEventPropagation, handleChange};
}
