import {defineMessages} from 'react-intl';

export default defineMessages({
    errorTitle: {
        id: 'app.errorState.title'
    },
    errorSubTitle: {
        id: 'app.errorState.descriptionThree'
    },
    generatingPdfInfoTitle: {
        id: 'app.testReport.generatingPDF.title'
    },
    generatingPdfInfoDescription: {
        id: 'app.testReport.generatingPDF.description'
    },
    downloadCompleted: {
        id: 'app.general.download.downloaded'
    }
});
