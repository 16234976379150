import {useCallback, useContext, useMemo, useRef} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {Alert} from '@/componentLibrary/blocks/Modals/Alert/Alert';
import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {PlainAnchorButton} from '@/componentLibrary/components/buttons/PlainButton';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {H1, H2, H3, P1, P2, S2} from '@/componentLibrary/components/typography';
import {
    ColorBaseGrey900,
    ColorFgDanger,
    ColorFgInfo
} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useCheckAssignmentAccountAccess} from '@/pages/Organization/pages/Assessment/components/CodingTests/hooks/useCheckAccess';
import {convertToHoursAndMinutes} from '@/pages/Organization/pages/Assessment/pages/CodingTests/CodingTestsList/utils';
import {UserDemographics} from '@/pages/UserDemographics';

import {toast} from '@/componentLibrary/components/Toast/toast';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {CenteredSpinner} from '@/componentLibrary/components/spinners';
import {HELP_CENTER} from '@/constants/helpCenter';
import {useStatus} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateCodingTestPage/components/AutomatedScoreV2Results/components/DetailedResultsModal/hooks/useLogic';
import {StatusDot} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateCodingTestPage/components/CandidateProgressTable/styled';
import {PrivateExperimentalFeatureKeys} from '@/services/experimentalFeatures/features';
import {useUserFeatureFlag} from '@/services/experimentalFeatures/useUserFeatureFlag';
import {getRepositoryUrlWithBranch} from '@/utils/misc';
import JobPositionContext from '../../context';
import {getChallengeDurations} from '../../functions/getChallengeDurations';
import {ImportBoilerplateProgressModal} from './components/ImportBoilerplateProgressModal';
import {StartTestAlert} from './components/StartTestAlert';
import {TechStackSelector} from './components/TechStackSelector';
import {TechStackSelectorManual} from './components/TechStackSelectorManual';
import {IMPORT_BOILERPLATE_STATUS, MANUAL_TECH_STACK_OPTION} from './constants';
import {useCountdown, useStartCodingTestAlert, useTestResultsModal} from './hooks';
import {useChallengeAssignmentStatusLogic} from './hooks/useChallengeAssignmentStatusLogic';
import {useInitChallengeAssignment} from './hooks/useInitChallengeAssignment';
import {useSubmitResultLogic} from './hooks/useSubmitResultLogic';
import {useTechStackSelector} from './hooks/useTechStackSelector';
import messages from './messages';
import {
    CenteredContentWrapper,
    Column,
    ContentSection,
    ContentWrapper,
    DemographicsModalBody,
    DetailedResultTable,
    FetchingText,
    FooterContent,
    Header,
    ModalBody,
    ModalFooter,
    ModalHeader,
    P2Wrapper,
    RepositoryLink,
    ResultRepositoryLink,
    Results,
    Row,
    TestResultsContent,
    TestResultsContentModalBody,
    TimeHeaderWrapper
} from './styled';
import {CodingTestModalPropType} from './types';

export function CodingTestModal({showModal, onHide}: CodingTestModalPropType) {
    const intl = useIntl();
    const visitedGithubLink = useRef<boolean>(false);

    const {challengeAssignment, organizationId, jobPosition} = useContext(JobPositionContext);

    const statusType = useStatus();

    const {user} = useLoggedInUser();

    const {testStarted, handleStartPolling: startPollingStatus} =
        useChallengeAssignmentStatusLogic({
            jobPositionId: jobPosition?.id,
            userId: user?.id,
            challengeAssignment
        });

    const {initChallengeAssignment, loading: loadingInitChallengeAssignment} =
        useInitChallengeAssignment({
            assignmentId: challengeAssignment?.id || ''
        });

    const {checkAssignmentAccountAccess} = useCheckAssignmentAccountAccess();

    const isCodingStackSelectorEnabled = useUserFeatureFlag(
        PrivateExperimentalFeatureKeys.CODING_TEST_STACK_SELECTOR
    );

    const {
        modalIsOpen: importingBoilerplateModalIsOpen,
        closeModal: closeImportingBoilerplateModal,
        loading: initializingImportBoilerplate,
        startImport,
        validateSelectedFramework,
        selectedFramework,
        selectedFrameworkError,
        handleSelectFramework,
        autoPickedFramework
    } = useTechStackSelector({
        challengeAssignment
    });

    const {
        alertText: startCodingTestTexts,
        showAlert: showStartTestAlert,
        onShowAlert: onShowStartTestAlert,
        onHideAlert: onHideStartTestAlert
    } = useStartCodingTestAlert({
        withTechStack: !!(
            isCodingStackSelectorEnabled &&
            (autoPickedFramework ||
                (selectedFramework && selectedFramework.id !== MANUAL_TECH_STACK_OPTION.id))
        )
    });

    const {
        handleSubmit,
        showDemographicsModal,
        setShowDemographicsModal,
        loading: isSubmitting,
        alertText: submitCodingTestTexts,
        showAlert: showSubmitTestAlert,
        onShowAlert: onShowSubmitTestAlert,
        onHideAlert: onHideSubmitTestAlert
    } = useSubmitResultLogic(challengeAssignment?.id ?? '');

    const {
        textTestResults,
        showTestResults,
        onShowTestResults,
        onHideTestResults,
        countResult,
        updatedChallengeAssignment,
        isEmptyDetailsTestResults
    } = useTestResultsModal(organizationId);

    const startTest = useCallback(async () => {
        const data = await initChallengeAssignment({enableManualSubmission: true});
        if (data?.isError) {
            return;
        }
        const repositoryUrl = await checkAssignmentAccountAccess(
            challengeAssignment?.id || ''
        );
        window.open(
            getRepositoryUrlWithBranch(repositoryUrl ?? '', ''),
            '_blank',
            'noopener, noreferrer'
        );
        handleSelectFramework(null);
        startPollingStatus(3000);
        onHideStartTestAlert();
    }, [
        challengeAssignment?.id,
        checkAssignmentAccountAccess,
        initChallengeAssignment,
        startPollingStatus,
        handleSelectFramework,
        onHideStartTestAlert
    ]);

    const {countdown, remainingTimeSec} = useCountdown({
        deadline: challengeAssignment?.submissionDeadline,
        timeLimitMinutes: challengeAssignment?.timeLimitMinutes ?? 0,
        started: testStarted
    });
    const phrases = useExtractPhraseConstants(messages);

    const displayEstimates = useMemo(() => {
        const duration = getChallengeDurations(challengeAssignment?.challenge ?? null, intl);

        if (!duration) {
            return phrases.codingTestWithoutTimeText;
        }

        if (!duration.to) {
            return duration.from;
        }

        return (
            <FormattedMessage
                values={{from: duration.from, to: duration.to}}
                {...messages.codingTestTimeText}
            />
        );
    }, [challengeAssignment?.challenge, intl, phrases.codingTestWithoutTimeText]);

    const navigateToGithub = useCallback(() => {
        (async () => {
            if (!challengeAssignment?.id) {
                return;
            }
            const repositoryUrl = await checkAssignmentAccountAccess(challengeAssignment.id);
            if (repositoryUrl) {
                window.open(
                    getRepositoryUrlWithBranch(
                        repositoryUrl ?? '',
                        challengeAssignment?.boilerplateImportDetails?.branch
                    ),
                    '_blank',
                    'noopener, noreferrer'
                );
                visitedGithubLink.current = true;
            }
        })();
    }, [
        challengeAssignment?.id,
        checkAssignmentAccountAccess,
        challengeAssignment?.boilerplateImportDetails?.branch
    ]);

    const handleModalClose = useCallback(() => {
        if (
            !user?.hasSubmittedDemographicsForm &&
            !challengeAssignment?.isOpenForLateSubmission &&
            challengeAssignment?.status === ChallengeAssignmentStatus.SUBMITTED
        ) {
            setShowDemographicsModal(true);
            return;
        }
        onHide();
    }, [
        challengeAssignment?.isOpenForLateSubmission,
        challengeAssignment?.status,
        onHide,
        setShowDemographicsModal,
        user?.hasSubmittedDemographicsForm
    ]);

    const handleStartTestClick = useCallback(
        async (isAlertModal?: boolean) => {
            if (hasTimeLimit && !isAlertModal) {
                if (isCodingStackSelectorEnabled && !validateSelectedFramework()) {
                    return;
                }
                onShowStartTestAlert();
                return;
            }

            if (
                !isCodingStackSelectorEnabled ||
                !challengeAssignment?.challengeSpecifications ||
                (isCodingStackSelectorEnabled &&
                    selectedFramework?.id === MANUAL_TECH_STACK_OPTION.id) ||
                !challengeAssignment?.challenge?.availableBoilerplates.length
            ) {
                startTest();
                return;
            }
            await startImport();
            onHideStartTestAlert();
        },
        [
            onShowStartTestAlert,
            startTest,
            startImport,
            onHideStartTestAlert,
            selectedFramework,
            validateSelectedFramework,
            challengeAssignment?.challengeSpecifications,
            isCodingStackSelectorEnabled,
            challengeAssignment?.challenge?.availableBoilerplates.length
        ]
    );

    const handleImportComplete = useCallback(
        importDetails => {
            if (importDetails?.status === IMPORT_BOILERPLATE_STATUS.FAILURE) {
                toast({
                    type: 'error',
                    message: phrases.importBoilerplateFailedErrorMessage
                });
            }
            closeImportingBoilerplateModal();
            startPollingStatus(3000);
            toast({
                type: 'success',
                message: phrases.importBoilerplatesSuccessMessage
            });
        },
        [
            closeImportingBoilerplateModal,
            phrases.importBoilerplateFailedErrorMessage,
            phrases.importBoilerplatesSuccessMessage,
            startPollingStatus
        ]
    );

    if (!showModal) {
        return null;
    }

    const hasTimeLimit = !!challengeAssignment?.timeLimitMinutes;
    const pastDeadline = remainingTimeSec === 0;
    const isOpenForLateSubmission = challengeAssignment?.isOpenForLateSubmission ?? false;

    if (showDemographicsModal) {
        return (
            <Modal withoutBody close={handleModalClose} hideClose size={MODAL_SIZE.FULL_PAGE}>
                <DemographicsModalBody>
                    <UserDemographics onSubmitSuccess={onHide} />
                </DemographicsModalBody>
            </Modal>
        );
    }

    const isSubmitted =
        challengeAssignment?.status === ChallengeAssignmentStatus.SUBMITTED &&
        !isOpenForLateSubmission;

    return (
        <Modal withoutBody close={handleModalClose} hideClose size={MODAL_SIZE.FULL_PAGE}>
            <ModalHeader>
                <PlainAnchorButton onClick={handleModalClose} data-testid="back-btn">
                    <Icon size={16} icon={Icons.ARROW_BACK} fill={ColorFgInfo} />{' '}
                    {phrases.backButtonText}
                </PlainAnchorButton>
            </ModalHeader>
            {isSubmitted ? (
                <ModalBody>
                    <CenteredContentWrapper>
                        <ContentSection>
                            <H3>{phrases.codingTestResultsHeader}</H3>
                            <P2>
                                {!hasTimeLimit || (hasTimeLimit && !pastDeadline)
                                    ? phrases.codingTestResultsText
                                    : phrases.codingTestResultsTextLate}
                            </P2>
                            <ResultRepositoryLink
                                onClick={navigateToGithub}
                                visited={visitedGithubLink.current}
                            >
                                {phrases.codingTestResultsGithub}
                                <Icon size={16} icon={Icons.OPEN_IN_NEW} fill={ColorFgInfo} />
                            </ResultRepositoryLink>
                        </ContentSection>
                    </CenteredContentWrapper>
                </ModalBody>
            ) : (
                <>
                    <ModalBody>
                        <ContentWrapper>
                            <Header>
                                {testStarted ? (
                                    <H2>{phrases.progressPageHeader}</H2>
                                ) : (
                                    <>
                                        <H2>{phrases.landingPageHeader}</H2>
                                        <P1>{phrases.landingPageSubheader}</P1>
                                    </>
                                )}
                            </Header>
                            {testStarted && (
                                <ContentSection>
                                    <RepositoryLink
                                        onClick={navigateToGithub}
                                        visited={visitedGithubLink.current}
                                    >
                                        {phrases.githubRepository}
                                        <Icon icon={Icons.OPEN_IN_NEW} fill={ColorFgInfo} />
                                    </RepositoryLink>
                                    <P2>{phrases.githubSubtitle}</P2>
                                </ContentSection>
                            )}
                            <ContentSection>
                                <TimeHeaderWrapper>
                                    <H3>
                                        {pastDeadline
                                            ? phrases.expiredTimeHeader
                                            : phrases.timeHeader}
                                    </H3>
                                    {pastDeadline && (
                                        <Icon
                                            icon={Icons.ALARM_OFF}
                                            size={24}
                                            fill={ColorFgDanger}
                                        />
                                    )}
                                </TimeHeaderWrapper>
                                {!testStarted && !!challengeAssignment?.timeLimitMinutes && (
                                    <P2>
                                        <FormattedMessage
                                            values={{
                                                limit: convertToHoursAndMinutes(
                                                    challengeAssignment?.timeLimitMinutes,
                                                    intl
                                                )
                                            }}
                                            {...messages.timeSubheader}
                                        />
                                    </P2>
                                )}
                                {hasTimeLimit ? (
                                    <>
                                        <H1 {...(pastDeadline && {color: ColorFgDanger})}>
                                            {countdown}
                                        </H1>
                                        <P2Wrapper>
                                            <P2>
                                                {pastDeadline ? (
                                                    phrases.timeLimitDeadlinePassedDesc
                                                ) : (
                                                    <FormattedMessage
                                                        values={{linebreak: <br />}}
                                                        {...messages.timeLimitDesc}
                                                    />
                                                )}
                                            </P2>
                                            {!testStarted && (
                                                <P2>{phrases.landingPageTimerInfo}</P2>
                                            )}
                                        </P2Wrapper>
                                    </>
                                ) : (
                                    <P2>
                                        <FormattedMessage
                                            values={{
                                                estimate: displayEstimates
                                            }}
                                            {...messages.timeEstimateDesc}
                                        />
                                    </P2>
                                )}
                            </ContentSection>
                            <ContentSection>
                                <H3>{phrases.technologiesHeader}</H3>
                                <P2>
                                    <FormattedMessage
                                        values={{
                                            a: chunks => (
                                                <a
                                                    href={HELP_CENTER.codingTestViaGithub}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {chunks}
                                                </a>
                                            )
                                        }}
                                        {...messages.technologiesText}
                                    />
                                </P2>
                                {!testStarted &&
                                    isCodingStackSelectorEnabled &&
                                    !!challengeAssignment?.challenge?.availableBoilerplates
                                        .length && (
                                        <TechStackSelector
                                            challengeAssignment={challengeAssignment}
                                            selectedFramework={selectedFramework}
                                            handleSelectFramework={handleSelectFramework}
                                            error={selectedFrameworkError}
                                        />
                                    )}
                                {testStarted &&
                                    isCodingStackSelectorEnabled &&
                                    !challengeAssignment?.boilerplateImportDetails &&
                                    !!challengeAssignment?.challenge?.availableBoilerplates
                                        .length && (
                                        <TechStackSelectorManual
                                            challengeAssignment={challengeAssignment}
                                            startImport={startImport}
                                            selectedFramework={selectedFramework}
                                            selectedFrameworkError={selectedFrameworkError}
                                            handleSelectFramework={handleSelectFramework}
                                        />
                                    )}
                            </ContentSection>
                            <ContentSection>
                                <H3>{phrases.completionHeader}</H3>
                                <P2>{phrases.inProgressCompletionText}</P2>
                            </ContentSection>
                            {!testStarted && (
                                <ContentSection>
                                    <H3>{phrases.githubSectionHeader}</H3>
                                    <P2>{phrases.githubSectionContent}</P2>
                                </ContentSection>
                            )}
                        </ContentWrapper>
                    </ModalBody>
                    <ModalFooter>
                        <FooterContent>
                            {testStarted ? (
                                <PrimaryButton
                                    isFullWidth={false}
                                    onClick={
                                        challengeAssignment?.testScoreEnabled
                                            ? onShowTestResults
                                            : onShowSubmitTestAlert
                                    }
                                    data-testid="submit-btn"
                                >
                                    {phrases.submitBtnTitle}
                                </PrimaryButton>
                            ) : (
                                <PrimaryButton
                                    isFullWidth={false}
                                    onClick={() => handleStartTestClick()}
                                    isLoading={
                                        !hasTimeLimit &&
                                        (loadingInitChallengeAssignment ||
                                            initializingImportBoilerplate)
                                    }
                                    data-testid="start-btn"
                                >
                                    {phrases.startTestBtnTitle}
                                </PrimaryButton>
                            )}
                        </FooterContent>
                    </ModalFooter>
                </>
            )}

            {importingBoilerplateModalIsOpen && jobPosition?.id && user?.id && (
                <ImportBoilerplateProgressModal
                    onComplete={handleImportComplete}
                    jobPositionId={jobPosition?.id}
                    userId={user?.id}
                />
            )}

            {showStartTestAlert && (
                <StartTestAlert
                    startCodingTestTexts={startCodingTestTexts}
                    onRightAction={() => handleStartTestClick(true)}
                    onLeftAction={onHideStartTestAlert}
                    isLoading={
                        hasTimeLimit &&
                        (loadingInitChallengeAssignment || initializingImportBoilerplate)
                    }
                />
            )}
            {showSubmitTestAlert && (
                <Alert
                    onRightAction={() => handleSubmit(onHideSubmitTestAlert)}
                    onLeftAction={onHideSubmitTestAlert}
                    isLoading={isSubmitting}
                    confirm
                    {...submitCodingTestTexts}
                />
            )}
            {showTestResults && (
                <Modal
                    headerTitle={textTestResults.title}
                    close={onHideTestResults}
                    hideClose
                    withoutBody
                    footer={{
                        leftSecondaryAction: (
                            <SecondaryButton
                                onClick={onHideTestResults}
                                disabled={isSubmitting}
                            >
                                {textTestResults.leftButton}
                            </SecondaryButton>
                        ),
                        primaryAction: (
                            <PrimaryButton
                                onClick={() => handleSubmit(onHideTestResults)}
                                isLoading={isSubmitting}
                            >
                                {textTestResults.rightButton}
                            </PrimaryButton>
                        )
                    }}
                >
                    <TestResultsContentModalBody>
                        <TestResultsContent>
                            <S2>
                                {countResult(
                                    updatedChallengeAssignment?.automatedScore?.testScore
                                        ?.results ?? null
                                )}
                            </S2>
                            <ResultRepositoryLink
                                onClick={navigateToGithub}
                                visited={visitedGithubLink.current}
                            >
                                {textTestResults.resultsGithub}
                                <Icon size={16} icon={Icons.OPEN_IN_NEW} fill={ColorFgInfo} />
                            </ResultRepositoryLink>
                            <P2>{textTestResults.subtitle}</P2>
                            {isEmptyDetailsTestResults ? (
                                <DetailedResultTable>
                                    <CenteredSpinner />
                                    <FetchingText>
                                        {textTestResults.fetchingResults}
                                    </FetchingText>
                                </DetailedResultTable>
                            ) : (
                                !!updatedChallengeAssignment?.automatedScore?.testScore
                                    ?.results.length && (
                                    <DetailedResultTable>
                                        <Row header>
                                            <Column>
                                                <P1 color={ColorBaseGrey900}>
                                                    {textTestResults.testScenario}
                                                </P1>
                                            </Column>
                                            <Column>
                                                <P1 color={ColorBaseGrey900}>
                                                    {textTestResults.results}
                                                </P1>
                                            </Column>
                                        </Row>
                                        {updatedChallengeAssignment?.automatedScore?.testScore?.results?.map(
                                            (result, k) => (
                                                <Row key={k} data-testid="test-score-row">
                                                    <Column>
                                                        <P2>{result.test}</P2>
                                                    </Column>
                                                    <Column>
                                                        <Results>
                                                            <StatusDot
                                                                color={
                                                                    statusType[result.result]
                                                                        .color
                                                                }
                                                            />
                                                            <P2>
                                                                {
                                                                    statusType[result.result]
                                                                        .text
                                                                }
                                                            </P2>
                                                        </Results>
                                                    </Column>
                                                </Row>
                                            )
                                        )}
                                    </DetailedResultTable>
                                )
                            )}
                        </TestResultsContent>
                    </TestResultsContentModalBody>
                </Modal>
            )}
        </Modal>
    );
}
