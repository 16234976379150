import {defineMessages} from 'react-intl';

export default defineMessages({
    weightInRoleFit: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitContribution.assessmentCriterionTooltip.weightInRoleFit'
    },
    contributionToRoleFit: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitContribution.assessmentCriterionTooltip.contributionToRoleFit'
    }
});
