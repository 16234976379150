import ReactFocusLock from 'react-focus-lock';

const intercomWhiteList = (activeElement: HTMLElement) => {
    return !(
        activeElement instanceof HTMLIFrameElement &&
        (activeElement.name === 'intercom-messenger-frame' ||
            activeElement.id === 'intersection-frame')
    );
};
type FocusLockProps = {
    disableWhiteList?: boolean;
    children: React.ReactNode;
};

export const FocusLock = ({disableWhiteList, ...rest}: FocusLockProps) => (
    <ReactFocusLock whiteList={disableWhiteList ? undefined : intercomWhiteList}>
        {rest.children}
    </ReactFocusLock>
);
