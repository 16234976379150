import {defineMessages} from 'react-intl';

export default defineMessages({
    unknownPlanError: {
        id: 'app.upgradePlan.unknownPlanError'
    },
    upgradePlanHeader: {
        id: 'app.upgradePlan.title'
    },
    cancelButton: {
        id: 'app.upgradePlan.cancelButton'
    },
    sendRequestButton: {
        id: 'app.upgradePlan.sendRequestButton'
    },
    selectedPlan: {
        id: 'app.upgradePlan.selectedPlan'
    },
    numPositionsTitle: {
        id: 'app.upgradePlan.numPositionsTitle'
    },
    numPositionsDesc: {
        id: 'app.upgradePlan.numPositionsDesc'
    },
    numPositionsError: {
        id: 'app.upgradePlan.numPositionsError'
    },
    paymentMethodTitle: {
        id: 'app.upgradePlan.paymentMethodTitle'
    },
    creditCard: {
        id: 'app.upgradePlan.paymentMethod.creditCard'
    },
    yearlyInvoice: {
        id: 'app.upgradePlan.paymentMethod.yearlyInvoice'
    },
    salesReachOut: {
        id: 'app.upgradePlan.salesReachOut'
    },
    genericError: {
        id: 'app.upgradePlan.genericError'
    },
    unauthorizedError: {
        id: 'app.upgradePlan.unauthorizedError'
    },
    orgNotSelectedError: {
        id: 'app.upgradePlan.orgNotSelectedError'
    },
    selectOrg: {
        id: 'app.upgradePlan.selectOrganization'
    },
    GROW_V2: {
        id: 'app.upgradePlan.plan.grow'
    },
    SCALE_V2: {
        id: 'app.upgradePlan.plan.scale'
    },
    ENTERPRISE1_V2: {
        id: 'app.upgradePlan.plan.enterprise'
    },
    PAY_AS_YOU_GO_V2: {
        id: 'app.upgradePlan.plan.paygo'
    },
    changePlan: {
        id: 'app.upgradePlan.changePlan'
    }
});
