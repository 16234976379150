import {FormattedMessage, useIntl} from 'react-intl';

import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {capitalizeFirstLetter} from '@/utils/misc';

import messages from '../messages';

export function AutoscoreDisabledBanner({profile}: {profile: string | undefined}) {
    const intl = useIntl();

    const getBannerText = () => {
        if (profile === 'machine learning') {
            return {
                title: messages.autoScoringUnavailableBannerTitle,
                subtitle: messages.autoScoringUnavailableBannerSubTitle
            };
        } else if (profile === 'data') {
            return {
                title: messages.qualityScoringUnavailableBannerTitle,
                subtitle: messages.qualityScoringUnavailableBannerSubTitle
            };
        }
    };

    const bannerText = getBannerText();

    if (!bannerText || !profile) {
        return null;
    }

    return (
        <Banner
            title={
                <FormattedMessage
                    {...bannerText.title}
                    values={{
                        profile: capitalizeFirstLetter(profile)
                    }}
                />
            }
            type={BannerType.ATTENTION}
        >
            {intl.formatMessage(bannerText.subtitle)}
        </Banner>
    );
}
