import {FooterWrapper, TestDivider} from './styled';
import {TestFooterProps} from './types';

export function TestFooter({children}: TestFooterProps) {
    return (
        <FooterWrapper>
            <TestDivider />
            {children}
        </FooterWrapper>
    );
}
