import {useEffect, useState} from 'react';

export const useFetch = (url: string, fetcher = fetch) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | undefined>(undefined);
    // biome-ignore lint/suspicious/noExplicitAny: generic data, perform type casting in the hooks
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(undefined);
            try {
                const response = await fetcher(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result);
            } catch (error) {
                setError(error as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [url, fetcher]);

    return {loading, error, data};
};
