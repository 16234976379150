import {useCallback, useMemo} from 'react';

import {
    ChallengeAssignmentStatus,
    GetUserOrganizationsQuery
} from '@/api/types/__generated__/graphql';
import {useMyJobApplications} from '@/api/users/useMyJobApplications';
import {useMyTestProgress} from '@/api/users/useMyTestProgress';
import {useMyUserOrganizationsWithSharedTests} from '@/api/users/useMyUserOrganizationsWithSharedTests';
import {TagVariant} from '@/componentLibrary/components/Tag/constants';

import {TEST_STATUSES} from './constants';
import {codingTestAllStatus, useMyApplicationLogicReturn} from './types';

export function useMyApplicationLogic(
    userOrganization: NonNullable<GetUserOrganizationsQuery['me']>['userOrganizations'][0],
    goToJobApplication: (id: number) => void
): useMyApplicationLogicReturn {
    const {sharedLogicTests, sharedPersonalityTests} = useMyUserOrganizationsWithSharedTests();
    const {jobApplications, loading} = useMyJobApplications(userOrganization?.organizationId);
    const {user} = useMyTestProgress(userOrganization.organizationId);

    const jobApplicationsForOrganization = useMemo(() => {
        return jobApplications.filter(
            application =>
                application?.jobPosition?.organization?.id === userOrganization.organizationId
        );
    }, [jobApplications, userOrganization.organizationId]);

    const isLogicTestIrtRequired = useMemo(() => {
        if (loading) {
            return null;
        }
        return jobApplicationsForOrganization.some(
            jobApplication => jobApplication.jobPosition?.requireLogicIrtTest
        );
    }, [jobApplicationsForOrganization, loading]);

    const isPersonalityTestRequired = useMemo(() => {
        if (loading) {
            return null;
        }
        return jobApplicationsForOrganization.some(
            jobApplication => jobApplication.jobPosition?.requirePersonalityTest
        );
    }, [jobApplicationsForOrganization, loading]);

    const isCodingTestRequired = useMemo(() => {
        if (loading) {
            return null;
        }
        return !!jobApplicationsForOrganization.find(
            jobApplication => jobApplication?.challengeAssignment?.id
        );
    }, [jobApplicationsForOrganization, loading]);

    const sharedLogicTest = useMemo(() => {
        return sharedLogicTests.find(
            sharedTest => sharedTest.organizationId === userOrganization.organizationId
        );
    }, [sharedLogicTests, userOrganization]);

    const sharedPersonalityTest = useMemo(() => {
        return sharedPersonalityTests.find(
            sharedTest => sharedTest.organizationId === userOrganization.organizationId
        );
    }, [sharedPersonalityTests, userOrganization]);

    const sharedLogicTestExpired = useMemo(
        () => user?.logicTestProgress.isSharedLogicTestExpired,
        [user]
    );

    const logicTestStatus = useMemo(() => {
        if (isLogicTestIrtRequired) {
            if (!sharedLogicTest || sharedLogicTestExpired) {
                return TEST_STATUSES.NOT_STARTED;
            }
            if (sharedLogicTest.logicalAbilityEstimate?.id) {
                return TEST_STATUSES.COMPLETED;
            }
            return TEST_STATUSES.IN_PROGRESS;
        }
    }, [isLogicTestIrtRequired, sharedLogicTest, sharedLogicTestExpired]);

    const sharedPersonalityTestExpired = useMemo(
        () => user?.personalityTestProgress.isSharedPersonalityTestExpired,
        [user]
    );

    const personalityTestStatus = useMemo(() => {
        if (isPersonalityTestRequired) {
            if (!sharedPersonalityTest || sharedPersonalityTestExpired) {
                return TEST_STATUSES.NOT_STARTED;
            }
            if (sharedPersonalityTest.personalityIrtResult?.id) {
                return TEST_STATUSES.COMPLETED;
            }
            return TEST_STATUSES.IN_PROGRESS;
        }
    }, [isPersonalityTestRequired, sharedPersonalityTest, sharedPersonalityTestExpired]);

    const codingTestAllStatuses: codingTestAllStatus[] = useMemo(() => {
        if (isCodingTestRequired) {
            return jobApplicationsForOrganization
                .filter(jobApplication => jobApplication?.challengeAssignment)
                .reverse()
                .map(jobApplication => ({
                    id: jobApplication?.challengeAssignment?.id,
                    status: jobApplication?.challengeAssignment?.status,
                    isOpenForLateSubmission: Boolean(
                        jobApplication?.challengeAssignment?.isOpenForLateSubmission
                    ),
                    jobPositionName: jobApplication?.jobPosition?.name,
                    name: jobApplication?.challengeAssignment?.challenge.name
                }));
        }
        return [];
    }, [jobApplicationsForOrganization, isCodingTestRequired]);

    const codingTestsAllCompleted = useMemo(() => {
        if (isCodingTestRequired) {
            return jobApplicationsForOrganization
                .filter(jobApplication => jobApplication?.challengeAssignment)
                .every(
                    jobApplication =>
                        !jobApplication?.challengeAssignment?.isOpenForLateSubmission &&
                        jobApplication?.challengeAssignment?.status ===
                            ChallengeAssignmentStatus.SUBMITTED
                );
        }
        return true;
    }, [isCodingTestRequired, jobApplicationsForOrganization]);

    const codingTestsNotStarted = useMemo(() => {
        if (isCodingTestRequired) {
            return jobApplicationsForOrganization.every(
                jobApplication =>
                    jobApplication?.challengeAssignment?.status ===
                    ChallengeAssignmentStatus.NOT_STARTED
            );
        }
        return true;
    }, [isCodingTestRequired, jobApplicationsForOrganization]);

    const areTestsCompleted = useMemo(
        () =>
            (isPersonalityTestRequired === false ||
                (isPersonalityTestRequired === true &&
                    personalityTestStatus === TEST_STATUSES.COMPLETED)) &&
            (isLogicTestIrtRequired === false ||
                (isLogicTestIrtRequired === true &&
                    logicTestStatus === TEST_STATUSES.COMPLETED)) &&
            codingTestsAllCompleted,
        [
            isPersonalityTestRequired,
            isLogicTestIrtRequired,
            personalityTestStatus,
            logicTestStatus,
            codingTestsAllCompleted
        ]
    );

    const areTestsNotStarted = useMemo(
        () =>
            logicTestStatus === TEST_STATUSES.NOT_STARTED &&
            personalityTestStatus === TEST_STATUSES.NOT_STARTED &&
            codingTestsNotStarted,
        [logicTestStatus, personalityTestStatus, codingTestsNotStarted]
    );

    const assessmentStatus = useMemo(() => {
        if (areTestsCompleted) {
            return TEST_STATUSES.COMPLETED;
        }
        if (areTestsNotStarted) {
            return TEST_STATUSES.NOT_STARTED;
        }
        return TEST_STATUSES.IN_PROGRESS;
    }, [areTestsCompleted, areTestsNotStarted]);

    const tagVariant = useMemo(
        () => (areTestsCompleted ? TagVariant.success : TagVariant.warning),
        [areTestsCompleted]
    );

    const redirectToApplication = useCallback(() => {
        goToJobApplication(userOrganization.organizationId);
    }, [userOrganization.organizationId, goToJobApplication]);

    return {
        jobApplicationsForOrganization,
        logicTestStatus,
        personalityTestStatus,
        assessmentStatus,
        codingTestAllStatuses,
        tagVariant,
        redirectToApplication
    };
}
