import styled from 'styled-components';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {ModalFooterProps} from '@/componentLibrary/components/Modal/ModalFooter/types';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {
    SecondaryButton,
    SmallSecondaryButton
} from '@/componentLibrary/components/buttons/SecondaryButton';
import {S2} from '@/componentLibrary/components/typography/SubTitles';
import {SpacingSmall, SpacingXxsmall} from '@/componentLibrary/tokens/variables';
import {FEEDBACK_TYPES} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation/components/LinkedinFeedback/constants';

import {useLinkedinFeedbackLogic} from './logic';

type Props = {
    onLeftAction: () => void;
    close: () => void;
};

export function LinkedinFeedback({onLeftAction, close}: Props) {
    const {
        titleText,
        bodyText,
        labelText,
        noProfileText,
        dontWantToShareText,
        previousText,
        okText,
        successTitleText,
        successBodyText,
        onFeedback,
        feedbackSubmitted
    } = useLinkedinFeedbackLogic();

    const FeedbackButtons = (
        <>
            <FeedbackLabel>
                <S2>{labelText}</S2>
            </FeedbackLabel>
            <SmallSecondaryButton onClick={onFeedback(FEEDBACK_TYPES.DONT_HAVE_LINKEDIN)}>
                {noProfileText}
            </SmallSecondaryButton>
            <SmallSecondaryButton
                onClick={onFeedback(FEEDBACK_TYPES.DONT_WANT_TO_SHARE_LINKEDIN)}
            >
                {dontWantToShareText}
            </SmallSecondaryButton>
        </>
    );

    const FeedbackSubmittedBanner = (
        <SuccessWrapper>
            <Banner title={successTitleText} type={BannerType.SUCCESS} noIcon>
                {successBodyText}
            </Banner>
        </SuccessWrapper>
    );

    const footer: ModalFooterProps = {};
    feedbackSubmitted
        ? (footer.primaryAction = <PrimaryButton onClick={close}>{okText}</PrimaryButton>)
        : (footer.leftSecondaryAction = (
              <SecondaryButton onClick={() => onLeftAction()}>{previousText}</SecondaryButton>
          ));

    return (
        <Modal close={close} headerTitle={titleText} footer={footer}>
            <Wrapper>
                {bodyText}
                {feedbackSubmitted ? FeedbackSubmittedBanner : FeedbackButtons}
            </Wrapper>
        </Modal>
    );
}

const FeedbackLabel = styled.div`
    margin: ${SpacingSmall} 0;
`;

const SuccessWrapper = styled.div`
    margin-top: ${SpacingSmall};
`;

const Wrapper = styled.div`
    button {
        margin-bottom: ${SpacingXxsmall};
    }

    button:first-of-type {
        margin-right: ${SpacingXxsmall};
    }
`;
