import {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';

import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {CenteredSpinner} from '@/componentLibrary/components/spinners';
import {personalityTestTakingMessages} from '@/pages/PersonalityTestIrt/messages';

import {WaitingForResult, Wrapper} from './styled';
import {Props} from './types';

export function PersonalityResultPlaceholder({
    goToTestResult,
    onResultFound,
    personalityTestResult,
    organizationId
}: Props) {
    useEffect(() => {
        if (personalityTestResult) {
            onResultFound();
        }
    }, [personalityTestResult, onResultFound]);

    return (
        <Wrapper>
            {personalityTestResult ? (
                <PrimaryButton
                    onClick={() =>
                        goToTestResult(personalityTestResult.personalityTestId, organizationId)
                    }
                >
                    <FormattedMessage {...personalityTestTakingMessages.seeResult} />
                </PrimaryButton>
            ) : (
                <div>
                    <WaitingForResult>
                        <FormattedMessage
                            {...personalityTestTakingMessages.waitingForResult}
                        />
                    </WaitingForResult>

                    <CenteredSpinner />
                </div>
            )}
        </Wrapper>
    );
}
