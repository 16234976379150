import {
    BorderRadiusBaseLarge,
    ColorBaseGrey200,
    SpacingSmall,
    SpacingXlarge,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';
import styled, {css} from 'styled-components';

export const Overlay = styled.div<{isVisible: boolean}>`
    ${({isVisible}) =>
        isVisible &&
        css`
            background-color: ${ColorBaseGrey200};
            border-radius: ${BorderRadiusBaseLarge};
            opacity: 0.5;
        `}
    margin-bottom: ${SpacingSmall};
`;
export const OrganizationWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 414px) {
        flex-direction: column;
    }
`;
export const OrganizationInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 414px) {
        margin-bottom: ${SpacingXlarge};
    }
`;
export const OrganizationTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${SpacingXsmall};
`;
export const OrganizationNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-inline-start: ${SpacingSmall};
`;
