import {useCallback} from 'react';

import {OrganizationOktaConfiguration} from '@/api/types/__generated__/graphql';
import {getOktaClient} from '@/services/auth/sso/okta/utils';
import {SsoState} from '@/services/auth/sso/types';
import {encodeSsoStateParam} from '@/services/auth/sso/utils';

export function useRedirectToOkta() {
    return useCallback((state: SsoState, config: OrganizationOktaConfiguration | null) => {
        const client = getOktaClient(config);
        const stateWithOktaConfig = {
            ...state,
            oktaConfig: config
        };
        client.token
            .getWithRedirect({
                state: encodeSsoStateParam(stateWithOktaConfig)
            })
            .then()
            .catch();
    }, []);
}
