import {PersonalityTestResultKey} from './components/FactorsOverview/types';
import {TRANSFORM_VALUES} from './constants';
import {TransformedValues} from './types';

const factorsToTransform: PersonalityTestResultKey[] = ['a', 'c', 'e', 'es', 'o'];

const capValue = (value: number, minValue: number, maxValue: number) =>
    Math.max(Math.min(value, maxValue), minValue);

export function roundTestResult(result: TransformedValues, onlyFactors = false) {
    const transformedResult: Partial<TransformedValues> = Object.assign({}, result);
    const minValue = 1;
    const maxValue = 10;

    if (onlyFactors) {
        factorsToTransform.forEach(key => {
            transformedResult[key] = capValue(Math.round(result[key]), minValue, maxValue);
        });

        return transformedResult;
    }

    TRANSFORM_VALUES.forEach(key => {
        transformedResult[key] = capValue(Math.round(result[key]), minValue, maxValue);
    });

    return transformedResult;
}
