import {defineMessages} from 'react-intl';

export default defineMessages({
    description: {
        id: 'app.pages.logicTestIrt.pages.createNewLogicTest.description'
    },
    refreshWarning: {
        id: 'app.pages.logicTestIrt.pages.createNewLogicTest.refreshWarning'
    },
    additionalWarning: {
        id: 'app.pages.logicTestIrt.pages.createNewLogicTest.additionalWarning'
    },
    startTest: {
        id: 'app.pages.logicTestIrt.pages.createNewLogicTest.startTest'
    },
    titleWarning: {
        id: 'app.pages.logicTestIrt.pages.createNewLogicTest.titleWarning'
    },
    bulletsWarning: {
        id: 'app.pages.logicTestIrt.pages.createNewLogicTest.bulletsWarning'
    },
    goBack: {
        id: 'app.backLink.goBack'
    }
});
