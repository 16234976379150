import {useApolloClient} from '@apollo/client';
import {useCallback} from 'react';

import {useCheckLoggedIn} from '@/api/auth/useCheckLoggedIn';
import {useLoginUserWithSso} from '@/api/auth/useLoginUserWithSso';
import {useRegisterUserWithSso} from '@/api/auth/useRegisterUserWithSso';
import {RegisterUserWithSsoMutationVariables} from '@/api/types/__generated__/graphql';
import {useSsoRedirectionLogic} from '@/pages/Login/hooks/useSsoRedirectionLogic';
import {SsoState} from '@/services/auth/sso/types';
import {setUserAuthToken} from '@/services/auth/utils';

export function useSsoAuthenticationLogic(idToken: string | null, ssoState: SsoState) {
    const client = useApolloClient();
    const {redirectToLogin, redirectToSignup, redirectToUrl} =
        useSsoRedirectionLogic(ssoState);

    const {refetchLoginStatus} = useCheckLoggedIn();
    const [loginUserWithSso] = useLoginUserWithSso(
        idToken,
        ssoState.ssoProvider,
        ssoState.oktaConfig
    );
    const [registerUserWithSso] = useRegisterUserWithSso();

    const loginWithSso = useCallback(() => {
        loginUserWithSso()
            .then(({data}) => {
                const {ok, errorMessage, token} = data?.loginUserWithSso || {};
                if (ok && token) {
                    setUserAuthToken(token);
                    refetchLoginStatus()
                        .then(() => {
                            client.resetStore().then(() => redirectToUrl());
                        })
                        .catch(e => {
                            throw new Error(e.message);
                        });
                } else {
                    throw new Error(errorMessage ?? 'Unknown error');
                }
            })
            .catch(e => {
                if (ssoState.redirectToLoginOnError) {
                    redirectToLogin(e.message);
                    return;
                }

                redirectToUrl(e.message);
            });
    }, [
        loginUserWithSso,
        refetchLoginStatus,
        client,
        redirectToUrl,
        ssoState.redirectToLoginOnError,
        redirectToLogin
    ]);

    const registerWithSso = useCallback(() => {
        if (!idToken) {
            throw new Error('Missing idToken');
        }
        const variables: RegisterUserWithSsoMutationVariables = {
            data: {
                signupToken: ssoState.token,
                idToken,
                locale: ssoState.locale,
                termsOfServiceVersion: ssoState.tosRevision,
                ssoProvider: ssoState.ssoProvider,
                candidateServicesConsentVersion: ssoState.cscRevision,
                expectedOktaDomain: ssoState.oktaConfig?.domain,
                expectedOktaClientId: ssoState.oktaConfig?.clientId
            }
        };

        registerUserWithSso(variables)
            .then(({data}) => {
                const {ok, errorMessage, token} = data?.registerUserWithSso || {};
                if (ok && token) {
                    setUserAuthToken(token);
                    client.resetStore().then(() => redirectToUrl());
                } else {
                    throw new Error(errorMessage ?? 'Unknown error');
                }
            })
            .catch(e => redirectToSignup(e.message));
    }, [ssoState, idToken, registerUserWithSso, client, redirectToUrl, redirectToSignup]);

    return {
        loginWithSso,
        registerWithSso
    };
}
