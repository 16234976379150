import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {JobRelevanceInformation} from '@/api/types/__generated__/graphql';
import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {ModalProps} from '@/componentLibrary/blocks/Modals/Modal/types';
import {OrganizationAvatar} from '@/componentLibrary/components/avatars/OrganizationAvatar';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {hasNonNullKeys} from '@/utils/typeGuards/hasNonNullKeys';
import {isDefined} from '@/utils/typeGuards/isDefined';

import messages from '../JobRecommendationItem/messages';
import {Description} from './components/Description';
import {Languages} from './components/Languages';
import {Leadership} from './components/Leadership';
import {Location} from './components/Location';
import {OriginalJobAd} from './components/OriginalJobAd';
import {PriorExperience} from './components/PriorExperience';
import {Specialities} from './components/Specialities';

type Props = {
    jobPositionName: string;
    organizationName: string;
    organizationLogo?: string | null;
    externalJobAdUrl?: string | null;
    jobRelevanceInformation: Partial<JobRelevanceInformation>;
    onLeftAction?: () => void;
    onRightAction?: () => void;
    close: () => void;
    banner?: ModalProps['banner'];
};

export function JobInformationModal({
    jobRelevanceInformation,
    jobPositionName,
    organizationName,
    organizationLogo,
    externalJobAdUrl,
    onLeftAction,
    onRightAction,
    close,
    banner
}: Props) {
    const intl = useIntl();

    if (!isDefined(jobRelevanceInformation)) {
        return null;
    }

    const {
        levelOfExperience,
        roleDescription,
        familiarityDescription,
        autoGenerated,
        workLocations,
        citiesWithMetaData,
        jobFamily,
        jobFamilySpecialties,
        requiredLanguages,
        hasLeadershipResponsibilities
    } = jobRelevanceInformation;

    const jobTitle = intl.formatMessage(messages.title, {
        jobName: jobPositionName,
        companyName: organizationName
    });

    const rightSecondaryAction = isDefined(onLeftAction) ? (
        <SecondaryButton onClick={onLeftAction} disabled={!!banner}>
            <FormattedMessage {...messages.dismiss} />
        </SecondaryButton>
    ) : undefined;
    const primaryAction = isDefined(onRightAction) ? (
        <PrimaryButton onClick={onRightAction} disabled={!!banner}>
            <FormattedMessage {...messages.apply} />
        </PrimaryButton>
    ) : undefined;

    const footer = {
        rightSecondaryAction,
        primaryAction
    };

    const hasFooter = hasNonNullKeys(footer);

    return (
        <Modal
            isVerticallyCentered
            close={close}
            headerTitle={jobTitle}
            banner={banner}
            hideClose={!!banner}
            headerIcon={
                <OrganizationAvatar
                    size={24}
                    fontSize={12}
                    name={organizationName}
                    image={organizationLogo || undefined}
                />
            }
            footer={hasFooter ? footer : undefined}
        >
            <Wrapper>
                {isDefined(workLocations) && isDefined(citiesWithMetaData) && (
                    <Location
                        preferredWorkLocations={workLocations}
                        preferredCities={citiesWithMetaData}
                    />
                )}
                {isDefined(jobFamily) && isDefined(jobFamilySpecialties) && (
                    <Specialities jobFamily={jobFamily} specialities={jobFamilySpecialties} />
                )}
                {isDefined(requiredLanguages) && (
                    <Languages spokenLanguages={requiredLanguages} />
                )}
                {isDefined(hasLeadershipResponsibilities) && (
                    <Leadership
                        hasLeadershipResponsibilities={hasLeadershipResponsibilities}
                    />
                )}
                {isDefined(levelOfExperience) && (
                    <PriorExperience levelOfExperience={levelOfExperience} />
                )}
                {isDefined(roleDescription) && (
                    <Description title="roleDescriptionTitle" content={roleDescription} />
                )}
                {isDefined(familiarityDescription) && (
                    <Description
                        title="familiarityDescriptionTitle"
                        content={familiarityDescription}
                    />
                )}
                {autoGenerated && <OriginalJobAd url={externalJobAdUrl} />}
            </Wrapper>
        </Modal>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 24px;
    margin-bottom: 6px;
`;
