import {Route, Switch, withRouter} from 'react-router-dom';

import {ErrorBoundary} from '@/componentLibrary/components/ErrorBoundary';
import {decodeNodeId} from '@/utils/encoders';

import {NotFound} from '../NotFound';
import {PUBLIC_JOB_POSITION_LINKS} from './links';
import {ApplyForJobPosition} from './pages/ApplyForJobPosition';

function PublicJobPositionUnconnected() {
    function parseJobPositionId(jobPositionIdInput: string) {
        const jobPositionIdAsInt = window.parseInt(jobPositionIdInput, 10);

        if (window.isNaN(jobPositionIdAsInt)) {
            return decodeNodeId(jobPositionIdInput);
        }
        return jobPositionIdAsInt;
    }

    return (
        <Switch>
            <Route
                exact
                path={PUBLIC_JOB_POSITION_LINKS.apply(':jobPositionId')}
                render={p => (
                    <ErrorBoundary>
                        <ApplyForJobPosition
                            jobPositionId={
                                parseJobPositionId(
                                    p.match.params.jobPositionId as string
                                ) as number
                            }
                        />
                    </ErrorBoundary>
                )}
            />
            <Route path={'*'} render={() => <NotFound />} />
        </Switch>
    );
}

export const PublicJobPosition = withRouter(PublicJobPositionUnconnected);
