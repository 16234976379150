import {
    greyColorTokensHex,
    secondaryColorTokensHex
} from '@/componentLibrary/tokens/legacyColors';
import {ColorBaseBlue600} from '@/componentLibrary/tokens/variables';

export const generalChartAttributes = {
    theme: 'fusion',
    baseFont: 'Euclid Circular A',
    baseFontSize: '14',
    baseFontColor: greyColorTokensHex.grey100,
    chartLeftMargin: 0,
    chartRightMargin: 0,
    showHoverEffect: true,
    showToolTipShadow: true,
    toolTipBorderColor: '#FFFFFF',
    toolTipBorderAlpha: 0,
    toolTipBorderRadius: 2,
    toolTipPadding: 8,
    labelPadding: 20,
    decimals: '0',
    divLineColor: greyColorTokensHex.grey600,
    columnHoverAlpha: 50,
    legendIconScale: 1
};

export const lineChartAnchorAttributes = {
    drawAnchors: true,
    anchorAlpha: 0,
    anchorRadius: 6,
    anchorBgColor: '#9DA0AB',
    anchorHoverEffect: true,
    anchorHoverAlpha: 100,
    anchorHoverRadius: 6,
    anchorBorderThickness: 1,
    anchorBorderColor: '#FFFFFF',
    anchorBgHoverColor: '#9DA0AB',
    anchorBgHoverAlpha: 100,
    anchorBorderHoverColor: '#FFFFFF',
    anchorBorderHoverAlpha: 100,
    anchorBorderHoverThickness: 1
};

export const comboChartAttributes = {
    plotSpacePercent: 80,
    plotPaddingPercent: 40,
    chartLeftMargin: 20,
    chartRightMargin: 20,
    drawCrossLine: false,
    legendPosition: 'bottom-left',
    legendItemFontColor: greyColorTokensHex.grey100,
    drawCustomLegendIcon: true,
    legendIconSides: 1,
    legendIconScale: 0.8,
    legendIconBorderThickness: 0,
    labelFontColor: greyColorTokensHex.grey400,
    yAxisValueFontColor: greyColorTokensHex.grey100
};

export const completionRateChartAttributes = {
    yAxisMinValue: 0,
    yAxisMaxValue: 100,
    numberSuffix: '%',
    decimals: '1',
    areaOverColumns: false,
    drawCrossLine: false
};

export const DOUGHNUT_CHART_COLOR_PALETTE = [
    secondaryColorTokensHex.warmGrey000,
    secondaryColorTokensHex.blue000,
    secondaryColorTokensHex.brown000,
    secondaryColorTokensHex.purple000,
    secondaryColorTokensHex.green000,
    ColorBaseBlue600,
    greyColorTokensHex.grey700
];

const doughnutChartPaletteColors = DOUGHNUT_CHART_COLOR_PALETTE.join(', ');

export const doughnutChartAttributes = {
    paletteColors: doughnutChartPaletteColors,
    showPercentValues: true,
    decimals: 1,
    showZeroPies: false,
    showLegend: false,
    enablesmartLabels: false,
    enableSlicing: false,
    valuePosition: 'inside',
    labelFontColor: '#FFFFFF',
    labelFontSize: 16,
    labelFont: 'Euclid Circular A, sans-serif',
    labelFontBold: true,
    plotBorderHoverColor: '#FFFFFF',
    pieRadius: 160,
    doughnutradius: 80
};

export const barChartAttributes = {
    useEllipsesWhenOverflow: true,
    maxLabelWidthPercent: 30,
    maxBarHeight: 12,
    paletteColors: `${secondaryColorTokensHex.brown000}`,
    decimals: 0,
    forceDecimals: true
};

export const darkTooltipAttributes = {
    toolTipBgColor: greyColorTokensHex.grey000,
    toolTipColor: greyColorTokensHex.white000,
    toolTipBorderColor: greyColorTokensHex.grey000
};
