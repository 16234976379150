import {ButtonHTMLAttributes, forwardRef} from 'react';

import {IconButton} from '@/componentLibrary/components/buttons/IconButton';

import {useActionOverlayContext} from '../hooks';
import {Wrapper} from './styled';

export const ActionOverlayClose = forwardRef<
    HTMLDivElement,
    ButtonHTMLAttributes<HTMLDivElement>
>(function ActionOverlayClose(props, ref) {
    const {setOpen} = useActionOverlayContext();
    return (
        <Wrapper>
            <IconButton ref={ref} {...props} onClick={() => setOpen(false)} />
        </Wrapper>
    );
});
