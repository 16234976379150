import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {SendResetPasswordEmailMutation} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';
import {SEND_RESET_PASSWORD_EMAIL} from './mutations';

export function useSendResetPasswordEmail(): MutationHookReturnType<
    SendResetPasswordEmailMutation,
    string
> {
    const [mutate, {error, loading}] = useMutation(SEND_RESET_PASSWORD_EMAIL);

    const doSendResetPasswordEmail = useCallback(
        email => mutate({variables: {data: {email}}}),
        [mutate]
    );

    return [doSendResetPasswordEmail, {error, loading}];
}
