import {useMergeRefs} from '@floating-ui/react';
import {HTMLProps, cloneElement, forwardRef, isValidElement} from 'react';
import styled from 'styled-components';

import {useActionOverlayContext} from '../hooks';
import {ActionOverlayTriggerProps} from '../types';

export const ActionOverlayTrigger = forwardRef<
    HTMLElement,
    HTMLProps<HTMLElement> & ActionOverlayTriggerProps
>(function ActionOverlayTrigger(
    {children, asChild = false, asDiv = false, shouldTrigger, ...props},
    propRef
) {
    const context = useActionOverlayContext();
    const ref = useMergeRefs<HTMLElement | null>([
        context.refs.setReference,
        propRef,
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (children as any).ref ?? null
    ]);

    if (!shouldTrigger) {
        return <>{children}</>;
    }

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
        const enhancedProps = context.getReferenceProps({
            ref,
            ...children.props,
            onClick: e => {
                e.stopPropagation();
                children.props.onClick?.(e);
            },
            'data-state': context.open ? 'open' : 'closed',
            ...props
        });

        return cloneElement(children, enhancedProps);
    }

    // `asDiv` uses a simple div instead of a button
    if (asDiv) {
        return (
            <StyledDiv
                openOnHover={context.openOnHover}
                ref={ref}
                // The user can style the trigger based on the state
                data-state={context.open ? 'open' : 'closed'}
                {...context.getReferenceProps({...props, onClick: e => e.stopPropagation()})}
            >
                {children}
            </StyledDiv>
        );
    }

    return (
        <StyledButton
            openOnHover={context.openOnHover}
            ref={ref}
            type="button"
            // The user can style the trigger based on the state
            data-state={context.open ? 'open' : 'closed'}
            {...context.getReferenceProps({...props, onClick: e => e.stopPropagation()})}
        >
            {children}
        </StyledButton>
    );
});

const getCursorStyle = (openOnHover: boolean): string => `
    cursor: ${openOnHover ? 'help' : 'pointer'};
`;

const StyledButton = styled.button<{openOnHover: boolean}>`
    ${({openOnHover}) => getCursorStyle(openOnHover)}
    display: inline-flex;
    align-items: center;
`;

const StyledDiv = styled.div<{openOnHover: boolean}>`
    ${({openOnHover}) => getCursorStyle(openOnHover)}
`;
