import styled from 'styled-components';

import {
    ColorBaseGrey900,
    FontSizeDefault,
    SpacingSmall,
    SpacingXsmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';

export const ContentWrapper = styled.div`
    margin: 0 auto;
    width: 35%;
    max-width: 640px;
    min-width: 420px;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: flex-start;
    flex-direction: column;

    @media only screen and (max-width: 760px) {
        width: 100%;
        padding: 36px ${SpacingSmall};
        min-width: 0;
    }

    @media only screen and (min-width: 761px) and (max-width: 800px) {
        width: 100%;
        padding: 36px 80px;
        min-width: 0;
    }
`;

export const TextWrapper = styled.div`
    h3 {
        margin-bottom: ${SpacingXxlarge};
    }
`;

export const DescriptionWrapper = styled.div`
    font-weight: normal;
    margin-bottom: ${SpacingXxlarge};
    font-size: ${FontSizeDefault};
    color: ${ColorBaseGrey900};
`;

export const ButtonsWrapper = styled.div`
    display: flex;

    & button {
        margin-inline-end: ${SpacingXsmall};
    }
`;
