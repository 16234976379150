import {defineMessages} from 'react-intl';

export default defineMessages({
    personalityNotCompletedTitle: {
        id: 'app.assessment.candidatePage.candidateResults.noPersonalityResults.title'
    },
    personalityNotCompletedDescription: {
        id: 'app.assessment.candidatePage.candidateResults.noPersonalityResults.description'
    }
});
