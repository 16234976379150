import styled, {css} from 'styled-components';

import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';
import {
    BorderRadiusBaseSmall,
    ColorBgSubtle,
    ColorFgDefault
} from '@/componentLibrary/tokens/variables';

export const AvatarWrapper = styled.div<{size: number; image?: string | null}>`
    background-color: ${ColorBgSubtle};
    display: inline-block;
    height: ${({size}) => size}px;
    width: ${({size}) => size}px;
    position: relative;
    border-radius: ${BorderRadiusBaseSmall};
    border: ${BorderBaseDefault};

    ${({image}) =>
        image &&
        css`
            background: transparent url("${image}") center center no-repeat;
            background-size: contain;
            border: none;
        `}
`;

export const AvatarContent = styled.span<{size: number; fontSize: number}>`
    color: ${ColorFgDefault};
    display: block;
    letter-spacing: 1px;
    line-height: ${({size}) => size - 2}px;
    text-align: center;
    font-size: ${({fontSize}) => fontSize}px;
`;
