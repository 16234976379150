import {H2} from '@/componentLibrary/components/typography/Headings';
import {Wrapper} from './styled';
import {SectionProps} from './types';

export function Section({title, children}: SectionProps) {
    return (
        <Wrapper>
            <H2>{title}</H2>
            {children}
        </Wrapper>
    );
}
