import {useQuery} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {GET_MY_JOB_RECOMMENDATIONS} from './queries';

export function useMyJobRecommendations() {
    const {data, loading, error, refetch, startPolling, stopPolling} = useQuery(
        GET_MY_JOB_RECOMMENDATIONS,
        {
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
            notifyOnNetworkStatusChange: true
        }
    );

    const jobRecommendations = useMemo(() => {
        return data?.me?.jobRecommendations ?? [];
    }, [data]);

    const pendingRecommendations = useMemo(() => {
        return jobRecommendations
            .filter(({publicJobPosition}) => !!publicJobPosition)
            .filter(({status}) => status === 'PENDING');
    }, [jobRecommendations]);

    const refetchCallback = useCallback(() => refetch(), [refetch]);

    return {
        pendingRecommendations,
        loading,
        error,
        refetch: refetchCallback,
        startPolling,
        stopPolling
    };
}
