import {
    ColorFgAttention,
    ColorFgDanger,
    ColorFgSuccess
} from '@/componentLibrary/tokens/variables';

export const getProgressColor = (value: number) => {
    if (value < 1) {
        return ColorFgDanger;
    } else if (value < 3) {
        return ColorFgAttention;
    }
    return ColorFgSuccess;
};
