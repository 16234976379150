import {randomString} from '@/utils/misc';

import {P2} from '../../typography/Paragraphs';
import {useCheckBoxValueLogic} from '../CheckBox/hooks/useCheckBoxValueLogic';
import {useToggleStyleLogic} from './logic';
import {Input, Marker, ToggleWrapper} from './styled';
import {ToggleProps} from './types';

export function Toggle({
    name,
    isToggled,
    isDefaultToggled,
    isDisabled,
    onChange,
    label,
    showLabelToTheLeft = false,
    'data-testid': dataTestId
}: ToggleProps) {
    const {
        derivedValue: derivedIsToggled,
        htmlCheckboxIsChecked,
        handleChange,
        stopEventPropagation
    } = useCheckBoxValueLogic({
        defaultValue: isDefaultToggled,
        value: isToggled,
        onChange
    });

    const {getColors} = useToggleStyleLogic({
        isToggled: !!derivedIsToggled,
        isDisabled
    });

    const {markerColor} = getColors();
    const labelToTheLeft = showLabelToTheLeft && label;
    const labelToTheRight = !showLabelToTheLeft && label;

    const Label = () => <P2>{label}</P2>;

    const randomId = `checkbox-${randomString(50)}`;

    return (
        <ToggleWrapper
            $labelLeft={showLabelToTheLeft}
            $disabled={isDisabled}
            onClick={stopEventPropagation}
        >
            <Input
                id={randomId}
                type="checkbox"
                name={name}
                onChange={handleChange}
                checked={htmlCheckboxIsChecked}
                disabled={isDisabled}
                data-testid={dataTestId}
            />
            {labelToTheLeft && <Label />}
            <Marker $color={markerColor} htmlFor={randomId} />
            {labelToTheRight && <Label />}
        </ToggleWrapper>
    );
}
