import {defineMessages} from 'react-intl';

export default defineMessages({
    firstCardTitle: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.firstCardTitle'
    },
    firstCardDescription: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.firstCardDescription'
    },
    secondCardTitle: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.secondCardTitle'
    },
    secondCardDescription: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.secondCardDescription'
    },
    thirdCardTitle: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.thirdCardTitle'
    },
    thirdCardDescription: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.thirdCardDescription'
    },
    trySampleTest: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.trySampleTest'
    },
    startSampleCardTitle: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.startSampleCardTitle'
    },
    startSampleCardDescription: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.startSampleCardDescription'
    },
    goBackToTutorial: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.goBackToTutorial'
    },
    startLogicTest: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.startLogicTest'
    },
    correctAnswer: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.correctAnswer'
    },
    wrongAnswer: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.wrongAnswer'
    },
    nextQuestion: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.nextQuestion'
    },
    submit: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.submit'
    }
});
