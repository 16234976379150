import {HTMLAttributes, ReactNode} from 'react';

export enum TITLE_SIZE {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}

type InPageHeaderTitle = {
    content: string;
    size?: TITLE_SIZE;
};

export type InPageHeaderProps = Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
    title: InPageHeaderTitle;
    tag?: ReactNode;
    primaryAction?: ReactNode;
    secondaryAction?: ReactNode;
};
