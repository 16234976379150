import styled from 'styled-components';

import {Caption} from '@/componentLibrary/components/typography';
import {SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const InnerContainer = styled.div<{width: number}>`
    position: absolute;
    height: 100%;
    width: ${({width}) => width}%;
    top: 0;
    padding: 0 ${SpacingXxsmall} 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
`;

export const CriterionName = styled(Caption)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
`;
