import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import {rootReducer} from '@/rootReducer';

import {getLocaleState, saveLocaleState} from './containers/LanguageProvider/helpers';
import {getUiState, saveUiState} from './services/ui/helpers';

const middlewares = [thunk];
const enhancers = [];
const devToolsExtension = window.devToolsExtension;

if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
}

const composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers);

const configureStore = initialState => {
    return createStore(rootReducer, initialState, composedEnhancers);
};

const store = configureStore({
    ui: getUiState(),
    languageProvider: getLocaleState()
});

store.subscribe(() => {
    const ui = store.getState().ui;
    saveUiState(ui);

    const languageProvider = store.getState().languageProvider;
    saveLocaleState(languageProvider);
});

export default store;
