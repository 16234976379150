import styled from 'styled-components';

import {
    BorderRadiusBaseRounded,
    ColorBgInset,
    ColorFgMuted
} from '@/componentLibrary/tokens/variables';

interface OneDimensionalBarProps {
    scoreInPercentage: number;
    color?: string;
    className?: string;
    isLarge?: boolean;
}

export function OneDimensionalBar({
    scoreInPercentage,
    color = ColorFgMuted,
    className,
    isLarge = false
}: OneDimensionalBarProps): JSX.Element {
    return (
        <OuterBar className={className} $isLarge={isLarge}>
            <InnerBar color={color} score={scoreInPercentage} />
        </OuterBar>
    );
}

const OuterBar = styled.div<{$isLarge?: boolean}>`
    background: ${ColorBgInset};
    border-radius: ${BorderRadiusBaseRounded};
    width: 100%;
    height: ${({$isLarge}) => ($isLarge ? 10 : 6)}px;
`;

const InnerBar = styled.div<{score: number; color: string}>`
    background: ${props => props.color};
    border-radius: ${BorderRadiusBaseRounded};
    width: ${props => props.score}%;
    height: 100%;
`;
