import {parse} from 'qs';
import {useLocation} from 'react-router-dom';

import {PersonalityTestReportProps} from '../PersonalityTestReport/types';
import {hasGrowthFactors} from '../PersonalityTestReport/utils';
import {FactorsOverview} from '../components/FactorsOverview';
import {FactorsOverviewProps} from '../components/FactorsOverview/types';
import {GrowthFactors} from '../components/GrowthFactors';
import {OverviewHeader} from '../components/OverviewHeader';
import {StrengthsAndChallenges} from '../components/StrengthsAndChallenges';
import {roundTestResult} from '../utils';
import {
    FactorsOverviewWrapper,
    GrowthFactorsWrapper,
    StrengthsAndChallengesWrapper,
    Wrapper
} from './styled';

export function PersonalityTestReportOverview({
    personalityTestResult,
    jobPositionId
}: PersonalityTestReportProps) {
    const location = useLocation();
    // @TODO Clean this up
    const fillInDemographicsFormLater = parse(location.search, {
        ignoreQueryPrefix: true
    }).fillInLater;
    const fillInLaterUrl = location.search.includes('?')
        ? '&fillInLater=true'
        : '?fillInLater=true';
    const path = location.pathname + location.search;
    const redirectLink = fillInDemographicsFormLater ? path + fillInLaterUrl : path;
    const roundedResult = roundTestResult(
        personalityTestResult,
        true
    ) as FactorsOverviewProps['personalityTestResult'];

    return (
        <Wrapper>
            <OverviewHeader
                redirectLink={redirectLink}
                personalityTestId={personalityTestResult.personalityTestId}
                jobPositionId={jobPositionId}
            />
            <FactorsOverviewWrapper>
                <FactorsOverview personalityTestResult={roundedResult} />
            </FactorsOverviewWrapper>
            <StrengthsAndChallengesWrapper>
                <StrengthsAndChallenges personalityTestResult={roundedResult} />
            </StrengthsAndChallengesWrapper>
            {hasGrowthFactors(personalityTestResult) && (
                <GrowthFactorsWrapper>
                    <GrowthFactors
                        topDriversDimensions={personalityTestResult.topDriversDimensions}
                        topCultureDimensions={personalityTestResult.topCultureDimensions}
                    />
                </GrowthFactorsWrapper>
            )}
        </Wrapper>
    );
}
