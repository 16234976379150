import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useMemo,
    useState
} from 'react';

type Props = {
    children: ReactNode;
};

type StateType = {
    linkedinUrl?: string;
    setLinkedinUrl: Dispatch<SetStateAction<string | undefined>>;
};

const initialState: StateType = {linkedinUrl: undefined, setLinkedinUrl: () => null};

const StateContext = createContext(initialState);

/**
 * Active Job Recommendation State provider & hooks
 */
export const ActiveJobRecommendationStateProvider = ({children}: Props) => {
    const [linkedinUrl, setLinkedinUrl] = useState(initialState.linkedinUrl);
    const contextValue = useMemo(() => ({linkedinUrl, setLinkedinUrl}), [linkedinUrl]);

    return <StateContext.Provider value={contextValue}>{children}</StateContext.Provider>;
};

export const useActiveJobRecommendationState = () => useContext(StateContext);
