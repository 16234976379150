import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {
    GetMyTestResultsQuery,
    GetMyTestResultsQueryVariables,
    GetUserTestResultsQuery,
    GetUserTestResultsQueryVariables
} from '../types/__generated__/graphql';
import {GET_MY_RESULTS, GET_USER_RESULTS} from './queries';

type Variables = GetMyTestResultsQueryVariables | GetUserTestResultsQueryVariables;
type Query = GetMyTestResultsQuery | GetUserTestResultsQuery;

export function useUserResults(userId: number | null = null, withLeadershipProfile = false) {
    const query = useMemo(() => {
        return userId ? GET_USER_RESULTS : GET_MY_RESULTS;
    }, [userId]);

    const variables: Variables = useMemo(() => {
        return userId ? {userId, withLeadershipProfile} : {withLeadershipProfile};
    }, [userId, withLeadershipProfile]);

    const {loading, error, data, refetch} = useQuery<Query, Variables>(query, {
        variables,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    const user = useMemo(() => {
        if (!data) {
            return null;
        }
        if ('user' in data) {
            return data.user;
        }
        return data.me;
    }, [data]);

    return {loading, user, error, refetch};
}
