import {SpacingXsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${SpacingXsmall} 0;
`;

export const TabWrapper = styled.div`    
    width: 80%;
`;
