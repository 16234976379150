import {ChangeEvent, FormEvent, useCallback, useState} from 'react';

import {errorMessages} from './messages';

const REQUIRED_PASSWORD_STRENGTH = 2;

export function useEmailPasswordFormLogic(
    defaultFirstName: string,
    defaultLastName: string,
    onSubmit: (firstName: string, lastName: string, password: string) => void
) {
    const [firstName, setFirstName] = useState<string>(defaultFirstName);
    const [lastName, setLastName] = useState<string>(defaultLastName);
    const [password, setPassword] = useState<string>('');
    const [passwordStrength, setPasswordStrength] = useState<number>(-1);
    const [passwordError, setPasswordError] = useState(false);
    const [firstNameError, setFirstNameError] = useState<{id: string} | null>(null);
    const [lastNameError, setLastNameError] = useState<{id: string} | null>(null);

    const handlePasswordStrengthChanged = useCallback((strength: number) => {
        setPasswordStrength(strength);
    }, []);

    const handleChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    }, []);

    const handleChangeFirstName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    }, []);

    const handleChangeLastName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
    }, []);

    const validateFormInputs = useCallback(() => {
        const validPassword = passwordStrength > REQUIRED_PASSWORD_STRENGTH;
        const validFirstName = !!firstName;
        const validLastName = !!lastName;
        const validInputs = validPassword && validFirstName && validLastName;

        setPasswordError(!validPassword);
        setFirstNameError(!validFirstName ? errorMessages.firstNameError : null);
        setLastNameError(!validLastName ? errorMessages.lastNameError : null);

        return validInputs;
    }, [firstName, lastName, passwordStrength]);

    const submit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            if (e) {
                e.preventDefault();
            }

            const validInputs = validateFormInputs();
            if (!validInputs) {
                return;
            }

            onSubmit(firstName, lastName, password);
        },
        [validateFormInputs, onSubmit, password, firstName, lastName]
    );

    return {
        firstName,
        lastName,
        password,
        firstNameError,
        lastNameError,
        passwordError,
        handleChangeFirstName,
        handleChangeLastName,
        handleChangePassword,
        handlePasswordStrengthChanged,
        submit
    };
}
