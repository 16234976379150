export enum PlanType {
    paygo = 'paygo',
    grow = 'grow',
    scale = 'scale',
    enterprise = 'enterprise'
}

export type PlanParam = {
    plan: string;
};
