import {Boilerplate} from '@/api/types/__generated__/graphql';
import {useMemo} from 'react';
import {MANUAL_TECH_STACK_OPTION} from '../../constants';
import {UseTechStackOptionsProps} from './types';

export function getAvailableFrameworks(boilerplates: Boilerplate[], languages: string[]) {
    let filteredBoilerplates = boilerplates;

    if (languages.length) {
        filteredBoilerplates = boilerplates?.filter(item =>
            item.languages.some(language => language && languages.includes(language))
        );
    }

    return (
        filteredBoilerplates?.map(framework => ({
            id: framework.framework,
            name: framework.framework
        })) || []
    );
}

export function useTechStackOptions({
    challengeAssignment,
    includeManual = true
}: UseTechStackOptionsProps) {
    const techStackOptions = useMemo(() => {
        const defaultOption = MANUAL_TECH_STACK_OPTION;

        if (challengeAssignment?.challengeSpecifications?.frameworks.length === 0) {
            return getAvailableFrameworks(
                challengeAssignment?.challenge.availableBoilerplates,
                challengeAssignment?.challengeSpecifications.languages
            ).concat(includeManual ? [defaultOption] : []);
        }

        return (
            challengeAssignment?.challengeSpecifications?.frameworks.map((item: string) => ({
                name: item,
                id: item
            })) || []
        ).concat(includeManual ? [defaultOption] : []);
    }, [challengeAssignment, includeManual]);

    return {techStackOptions};
}
