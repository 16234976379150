import {ReactElement} from 'react';

import {
    PatternItem,
    PatternItemWrapper,
    PatternsRow
} from '@/pages/LogicTestIrt/components/Question/styled';
import {Pattern} from '@/pages/LogicTestIrt/components/Question/types';

export const renderPatternsRow = (patterns: Pattern[], isSvg: boolean): ReactElement => {
    return (
        <PatternsRow>
            {patterns.map((pattern: Pattern) => (
                <PatternItemWrapper key={pattern.key}>
                    {isSvg || pattern.key === 'questionMark' ? (
                        <PatternItem src={pattern.data} />
                    ) : (
                        <PatternItem src={`data:image/svg+xml;base64, ${pattern.data}`} />
                    )}
                </PatternItemWrapper>
            ))}
        </PatternsRow>
    );
};
