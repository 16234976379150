import {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';

import {LandingPageLayout} from '@/componentLibrary/blocks/LandingPageLayout';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import {Column, OuterWrapper, Row, TitleText} from '@/pages/ResetPassword/styled';

import {ResetPasswordForm} from './components/ResetPasswordForm';
import {useResetPasswordLogic, useValidateResetPasswordTokenLogic} from './logic';
import messages from './messages';

const RECOVER_PASSWORD_LINK = '/login/recover';

export function ResetPasswordPage() {
    const {token, tokenValidated, onValidToken, onError, error, errorMessage} =
        useResetPasswordLogic();
    const {validateToken, loading} = useValidateResetPasswordTokenLogic(onValidToken, onError);

    useEffect(() => {
        if (loading || !token) {
            return;
        }

        if (!error && !tokenValidated) {
            validateToken(token);
        }
    }, [error, loading, token, tokenValidated, validateToken]);

    return (
        <LandingPageLayout>
            <OuterWrapper>
                <Row>
                    <Column>
                        <TitleText>
                            <FormattedMessage {...messages.title} />
                        </TitleText>
                        {error ? (
                            <P1>
                                <FormattedMessage
                                    {...errorMessage}
                                    values={{
                                        link: (
                                            <a
                                                href={RECOVER_PASSWORD_LINK}
                                                rel="noreferrer noopener"
                                                target="_blank"
                                            >
                                                <FormattedMessage
                                                    {...messages.recoverPageText}
                                                />
                                            </a>
                                        )
                                    }}
                                />
                            </P1>
                        ) : null}
                    </Column>
                    <Column>{tokenValidated ? <ResetPasswordForm /> : null}</Column>
                </Row>
            </OuterWrapper>
        </LandingPageLayout>
    );
}
