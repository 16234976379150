import {useCandidateRoleFitContext} from '../../context';
import {CodingTestList} from './components/CodingTestList';
import {InterviewList} from './components/InterviewList';
import {NoDataList} from './components/InterviewList/components/NoDataList';
import {LogicTestList} from './components/LogicTestList';
import {PersonalityTestList} from './components/PersonalityTestList';
import {SCORE_TYPE} from './constants';
import {useAssessmentScoresResultsLogic} from './logic';
import {Wrapper} from './styled';

export function AssessmentScoresResults({
    type,
    interviewId
}: {
    type: SCORE_TYPE;
    interviewId?: string;
}) {
    const {jobApplication} = useCandidateRoleFitContext();
    const {hasInterviewScore, hasCodingScore, hasLogicScore, hasPersonalityScore} =
        useAssessmentScoresResultsLogic(jobApplication, interviewId);

    const interviewsList = hasInterviewScore ? (
        <InterviewList interviewId={interviewId} />
    ) : (
        <NoDataList />
    );
    const personalityTest = hasPersonalityScore ? <PersonalityTestList /> : <NoDataList />;
    const logicTest = hasLogicScore ? <LogicTestList /> : <NoDataList />;
    const codingTest = hasCodingScore ? <CodingTestList /> : <NoDataList />;

    const scoreTypeComponents = {
        [SCORE_TYPE.PERSONALITY_TEST]: personalityTest,
        [SCORE_TYPE.LOGIC_TEST]: logicTest,
        [SCORE_TYPE.CODING_TEST]: codingTest,
        [SCORE_TYPE.INTERVIEW]: interviewsList
    };

    return <Wrapper>{scoreTypeComponents[type]}</Wrapper>;
}
