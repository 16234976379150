import styled from 'styled-components';

import {BorderBaseDefaultRule} from '@/componentLibrary/tokens/customVariables';
import {
    BorderRadiusBaseSmall,
    ColorBgSubtle,
    ColorFgSubtle,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

import {getColors} from './logic';
import {RoleFitTagWrapperProps} from './types';

export const RoleFitTagWrapper = styled.span<RoleFitTagWrapperProps>`
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    background-color: ${ColorBgSubtle};
    color: ${ColorFgSubtle};
    ${BorderBaseDefaultRule}
    gap: ${SpacingXxsmall};
    padding-block: ${({$small}) => ($small ? 0 : SpacingXxxsmall)};
    padding-inline: ${SpacingXxsmall};
    border-radius: ${BorderRadiusBaseSmall};
    ${({$label}) => getColors($label)}
`;
