import qs from 'qs';
import {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {usePersonalityTestResult} from '@/api/personalityTests/usePersonalityTestResult';
import {PersonalityIrtResult} from '@/api/types/__generated__/graphql';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {useMyJobApplications} from '@/api/users/useMyJobApplications';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {anyWithLeadershipReport} from '@/pages/PersonalityTestIrt/utils';
import {Status} from '@/utils/misc';
import {isDefined} from '@/utils/typeGuards/isDefined';

import messages from './messages';

type Props = {
    personalityTestId: string;
    organizationId?: number;
    jobPositionId?: number;
    closeLink: string;
    displayDemographicsForm: boolean;
};

export function usePersonalityTestReportPageLogic({
    personalityTestId,
    organizationId,
    jobPositionId,
    closeLink,
    displayDemographicsForm
}: Props) {
    const history = useHistory();
    const {user, loading: loadingUser} = useLoggedInUser();
    const {jobApplications, loading: loadingJobApplications} =
        useMyJobApplications(organizationId);
    const {jobPosition, loading: loadingJobPosition} = useJobPosition(
        jobPositionId as number,
        {skip: !isDefined(jobPositionId)}
    );

    const leadershipProfileStatus: Status = useMemo(() => {
        if (!isDefined(organizationId) && !isDefined(jobPositionId)) {
            return Status.Disabled;
        }

        if (isDefined(organizationId) && loadingJobApplications) {
            return Status.Unknown;
        }

        if (isDefined(jobPositionId) && loadingJobPosition) {
            return Status.Unknown;
        }

        if (isDefined(organizationId) && anyWithLeadershipReport(jobApplications)) {
            return Status.Enabled;
        }

        if (isDefined(jobPositionId) && jobPosition?.leadershipReportEnabled) {
            return Status.Enabled;
        }

        return Status.Disabled;
    }, [
        organizationId,
        loadingJobApplications,
        jobApplications,
        jobPositionId,
        loadingJobPosition,
        jobPosition
    ]);

    const {
        loading: loadingPersonalityTestResult,
        error,
        refetch,
        personalityTestResult: NullablePersonalityTestResult
    } = usePersonalityTestResult({
        personalityTestId,
        withLeadershipProfile: leadershipProfileStatus === Status.Enabled,
        skip: leadershipProfileStatus === Status.Unknown
    });

    const personalityTestResult = NullablePersonalityTestResult as PersonalityIrtResult;

    const fillInDemographicsFormLater = qs.parse(location.search, {
        ignoreQueryPrefix: true
    }).fillInLater;

    const redirectUrl = useMemo(() => {
        return closeLink ? closeLink : '/';
    }, [closeLink]);

    const goBack = useCallback(() => {
        history.push(redirectUrl);
    }, [history, redirectUrl]);

    const loading =
        loadingPersonalityTestResult ||
        loadingUser ||
        loadingJobApplications ||
        loadingJobPosition ||
        !user;

    const shouldDisplayDemographicsForm = useMemo(() => {
        const isSameUser =
            user && personalityTestResult && user.id === personalityTestResult.userId;
        return (
            displayDemographicsForm &&
            !user?.hasSubmittedDemographicsForm &&
            !fillInDemographicsFormLater &&
            isSameUser
        );
    }, [displayDemographicsForm, user, fillInDemographicsFormLater, personalityTestResult]);

    const demographicsFormRedirectUrl = useMemo(() => {
        const redirect = window.encodeURIComponent(`${location.pathname}${location.search}`);
        return `/demographics-form?redirect=${redirect}`;
    }, []);

    const phrases = useExtractPhraseConstants(messages);

    return {
        loading,
        error,
        refetch,
        personalityTestResult,
        shouldDisplayDemographicsForm,
        demographicsFormRedirectUrl,
        goBack,
        phrases
    };
}
