import {useCurrentEmployment} from '@/api/organizations/useCurrentEmployment';
import {useAuthenticatedUser} from '@/api/users/useAuthenticatedUser';
import {Config} from '@/config';
import {getUserName} from '@/utils/misc';
import {useEffect, useMemo} from 'react';
import {IntercomSettings} from './types';

export const useIntercomSettings = (config: Config): IntercomSettings | null => {
    const {user, loading: loadingUser} = useAuthenticatedUser();
    const {employment, loading: loadingEmployemnt} = useCurrentEmployment();

    return useMemo(() => {
        if (loadingUser || loadingEmployemnt) {
            return null;
        }

        const settings: IntercomSettings = {
            app_id: config.INTERCOM_APP_ID
        };

        if (user) {
            settings['user_id'] = user.userId.toString();
            settings['user_hash'] = user.intercomVerificationToken;
            settings['name'] = getUserName(user);
            settings['email'] = user.email?.toString();
        }

        if (employment) {
            // TODO: Remove key 'access_groups', verify not used in Intercom!
            const accessGroupName =
                employment && employment.accessGroup ? employment.accessGroup.name : '';
            settings['access_groups'] = accessGroupName;
            settings['access_group'] = accessGroupName;
            settings['key_member'] = !!employment.isKeyMember;
        }

        // If the user is impersonating another user, DON'T add organization info.
        if (employment && user && employment.user && employment.user.id === user.userId) {
            // User is NOT impersonating
            const {organization} = employment;
            // Add organization info
            if (organization) {
                settings['organization_id'] = organization.id;
                settings['organization'] = organization.name;
                settings['organization_current_plan'] = organization.currentPlan;
                settings['company'] = {
                    id: organization.id?.toString(),
                    name: organization.name
                };
            }
        }
        return settings;
    }, [user, employment, config, loadingUser, loadingEmployemnt]);
};

export function useWindowIntercom(config: Config) {
    useEffect(() => {
        if (!window.Intercom) {
            const intercomScript = document.querySelector(
                `script[src="https://widget.intercom.io/widget/${config.INTERCOM_APP_ID}"]`
            );
            if (!intercomScript) {
                (function (w, d, id) {
                    function i() {
                        i.c(arguments);
                    }
                    // @ts-expect-error: TS7008
                    i.q = [];
                    i.c = function (args: unknown) {
                        i.q.push(args);
                    };
                    w.Intercom = i;
                    const s = d.createElement('script');
                    s.async = true;
                    s.type = 'text/plain';
                    s.setAttribute('data-cookieconsent', 'preferences');
                    s.src = 'https://widget.intercom.io/widget/' + id;
                    const x = d.getElementsByTagName('script')[0];
                    x.parentNode?.append(s);
                })(window, window.document, config.INTERCOM_APP_ID);
            }
        }
    }, [config.INTERCOM_APP_ID]);
}
