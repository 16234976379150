import {useCallback, useEffect, useMemo, useState} from 'react';

export function useTableDataLogic({loading, data, filterFunction, onDeselectAll, isSelected}) {
    const [searchString, setSearchString] = useState('');

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        onDeselectAll();
    }, [searchString]);

    const onFilterChange = useCallback(value => {
        setSearchString(value);
    }, []);

    const tableData = useMemo(() => {
        if (loading || !data) {
            return [];
        }
        if (!searchString) {
            return data;
        }
        return data.filter(filterFunction.bind(null, searchString));
    }, [data, loading, filterFunction, searchString]);

    const isAllRowsSelected = useCallback(() => {
        return tableData.length > 0 && tableData.every(isSelected);
    }, [tableData, isSelected]);

    const isSomeRowsSelected = useCallback(() => {
        return tableData.length > 0 && tableData.some(isSelected);
    }, [tableData, isSelected]);

    return {
        onFilterChange,
        tableData,
        isAllRowsSelected,
        isSomeRowsSelected
    };
}
