import {useIntl} from 'react-intl';

import {roundScore} from '@/utils/math';
import {isDefined} from '@/utils/typeGuards/isDefined';

import messages from '../../messages';
import {List, ListWrapper} from '../../styled';
import {useCombineInterviewData} from './logic';

export function InterviewList({interviewId}: {interviewId?: string}) {
    const {formatMessage} = useIntl();
    const combinedData = useCombineInterviewData(interviewId);

    if (!isDefined(combinedData)) {
        return null;
    }

    const listItems = combinedData.contentItems.map(({id, name, score}) => (
        <li key={id}>
            {name}: &nbsp;
            {isDefined(score) ? `${roundScore(score, 1)}/5` : formatMessage(messages.noData)}
        </li>
    ));

    return (
        <ListWrapper>
            <List>{listItems}</List>
        </ListWrapper>
    );
}
