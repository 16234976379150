import styled from 'styled-components';

import {Spinner} from '@/componentLibrary/components/spinners/index';

export const CenteredSpinner = styled(Spinner)`
    width: 68px;
    height: 68px;
    margin: auto;
`;

export const Wrapper = styled.div`
    height: 100vh;
    display: flex;
`;
