import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {
    BorderRadiusBaseLarge,
    ColorBgDefault,
    ColorBgSubtle,
    SpacingLarge,
    SpacingSmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';

import {SizeNarrow, SizeWide} from './constants';

export const WhiteBox = styled.div`
    background-color: ${greyColorTokens.white000()};
    max-width: ${SizeWide};
    width: ${SizeWide};
    padding: ${SpacingLarge} ${SpacingLarge} 0 ${SpacingLarge};
    border-radius: ${BorderRadiusBaseLarge};
    height: 100%;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        max-width: ${SizeNarrow};
        width: ${SizeNarrow};
    }
`;

export const Wrapper = styled.div`
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    background: ${ColorBgSubtle};
`;

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const HeaderWrapper = styled.div`
    padding: ${SpacingSmall} ${SpacingLarge};
    background: ${ColorBgDefault};
    height: 76px;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        padding: ${SpacingSmall};
    }
`;

export const ContentWrapper = styled.div`
    flex: 1;
    align-items: center;
    display: flex;
`;

type RowProps = {
    minHeight: string;
};

export const Row = styled.div<RowProps>`
    width: 100%;
    padding: ${SpacingXxlarge};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    min-height: ${({minHeight}) => minHeight};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column-reverse;
        padding: 0;
        height: auto;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 600px;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        padding: ${SpacingSmall};
        width: 100%;
        align-items: center;
    }
`;

export const SingleColumn = styled(Column)`
    width: 100%;
    display: flex;
    align-items: center;
`;
