/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
 /**
  * DO NOT EDIT, THIS FILE IS GENERATED FROM A TEMPLATE
  * See i18n.js.jinja and run "yarn sync-locales"
 */
import {DEFAULT_LOCALE} from './config';
import arTranslationMessages from './translations/ar.json';
import csTranslationMessages from './translations/cs.json';
import daTranslationMessages from './translations/da.json';
import deTranslationMessages from './translations/de.json';
import enGbTranslationMessages from './translations/en-gb.json';
import enTranslationMessages from './translations/en.json';
import esTranslationMessages from './translations/es.json';
import etTranslationMessages from './translations/et.json';
import fiTranslationMessages from './translations/fi.json';
import frFrTranslationMessages from './translations/fr-fr.json';
import itTranslationMessages from './translations/it.json';
import jaTranslationMessages from './translations/ja.json';
import ltTranslationMessages from './translations/lt.json';
import lvTranslationMessages from './translations/lv.json';
import nbTranslationMessages from './translations/nb.json';
import nlTranslationMessages from './translations/nl.json';
import plTranslationMessages from './translations/pl.json';
import ptBrTranslationMessages from './translations/pt-br.json';
import skTranslationMessages from './translations/sk.json';
import svTranslationMessages from './translations/sv.json';
import zhCnTranslationMessages from './translations/zh-cn.json';

type TranslationMessages = Record<string, string>;

export type LocaleMessages = Record<string, TranslationMessages>;

const formatTranslationMessages = (
    locale: string,
    messages: TranslationMessages
): TranslationMessages => {
    const defaultMessages: TranslationMessages = enTranslationMessages;
    return Object.keys(defaultMessages).reduce((formattedMessages, key) => {
        const formattedMessage =
            !messages[key] && locale !== DEFAULT_LOCALE ? defaultMessages[key] : messages[key];

        return Object.assign(formattedMessages, {[key]: formattedMessage});
    }, {});
};

export const translationMessages: LocaleMessages = { 
    'en': formatTranslationMessages('en', enTranslationMessages),
    'sv': formatTranslationMessages('sv', svTranslationMessages),
    'fi': formatTranslationMessages('fi', fiTranslationMessages),
    'nb': formatTranslationMessages('nb', nbTranslationMessages),
    'da': formatTranslationMessages('da', daTranslationMessages),
    'de': formatTranslationMessages('de', deTranslationMessages),
    'lv': formatTranslationMessages('lv', lvTranslationMessages),
    'lt': formatTranslationMessages('lt', ltTranslationMessages),
    'et': formatTranslationMessages('et', etTranslationMessages),
    'nl': formatTranslationMessages('nl', nlTranslationMessages),
    'es': formatTranslationMessages('es', esTranslationMessages),
    'en-gb': formatTranslationMessages('en-gb', enGbTranslationMessages),
    'fr-fr': formatTranslationMessages('fr-fr', frFrTranslationMessages),
    'pt-br': formatTranslationMessages('pt-br', ptBrTranslationMessages),
    'zh-cn': formatTranslationMessages('zh-cn', zhCnTranslationMessages),
    'ar': formatTranslationMessages('ar', arTranslationMessages),
    'pl': formatTranslationMessages('pl', plTranslationMessages),
    'it': formatTranslationMessages('it', itTranslationMessages),
    'cs': formatTranslationMessages('cs', csTranslationMessages),
    'sk': formatTranslationMessages('sk', skTranslationMessages),
    'ja': formatTranslationMessages('ja', jaTranslationMessages),
};