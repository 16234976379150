import {AssessmentScore} from '@/api/types/__generated__/graphql';
import {isArrayWithItems} from '@/utils/typeGuards/isArrayWithItems';

export function roundPercentageValue(value: number) {
    return Math.round(value * 100);
}

export function sortAssessmentScores(
    assessmentConfigurations: AssessmentScore[],
    interviewIds?: number[]
) {
    const customOrder = ['PERSONALITY_TEST', 'LOGIC_TEST', 'CODING_TEST', 'INTERVIEW'];
    const sorter1 = (a: AssessmentScore, b: AssessmentScore) =>
        customOrder.indexOf(a.assessmentMethodType) -
        customOrder.indexOf(b.assessmentMethodType);

    const initialSorted = [...assessmentConfigurations].sort(sorter1);
    if (!isArrayWithItems(interviewIds)) {
        return initialSorted;
    }

    const sorter2 = (a: AssessmentScore, b: AssessmentScore) => {
        if (a.assessmentMethodType !== b.assessmentMethodType) {
            return 0;
        }
        if (a.assessmentMethodType !== 'INTERVIEW') {
            return 0;
        }

        const aIndex = interviewIds.indexOf(parseInt(a.assessmentMethodId, 10));
        const bIndex = interviewIds.indexOf(parseInt(b.assessmentMethodId, 10));

        if (aIndex !== undefined && bIndex !== undefined) {
            return aIndex - bIndex;
        }

        return 0;
    };

    return initialSorted.sort(sorter2);
}
