import {H3} from '@/componentLibrary/components/typography/Headings';
import {ColorFgMuted} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {getUserName} from '@/utils/misc';

import messages from './messages';
import {ExtendedP2, Wrapper} from './styled';
import {ReportIntroProps} from './types';

export function ReportIntro({user, showStandardScoreInfo}: ReportIntroProps) {
    const phrases = useExtractPhraseConstants(messages);

    return (
        <Wrapper>
            <H3>
                {getUserName(user)} - {phrases.title}
            </H3>

            <ExtendedP2 color={ColorFgMuted}>{phrases.intro}</ExtendedP2>
            {showStandardScoreInfo && (
                <ExtendedP2 color={ColorFgMuted}>{phrases.scoreDescription}</ExtendedP2>
            )}
        </Wrapper>
    );
}
