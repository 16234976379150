import {gql} from '@/api/types/__generated__';

export const GET_JOB_POSITION = gql(`
    query getJobPosition(
        $id: Int!
        $withTeam: Boolean = false
        $withOrganizationRole: Boolean = false
        $withHiringManager: Boolean = false
        $withHiringTeam: Boolean = false
        $withMetrics: Boolean = false
        $withEmailTemplate: Boolean = false
        $withTestProfile: Boolean = false
        $withInterview: Boolean = false
        $withChallenge: Boolean = false
        $withAssessmentsRecommendation: Boolean = false
        $withPersonalityProfile: Boolean = false
        $withChallengeSpecifications: Boolean = false
    ) {
        jobPosition(id: $id) {
            id
            organizationId
            created
            name
            currentStatus
            activationState
            expiresAt
            createdFrom
            team @include(if: $withTeam) {
                id
                name
            }
            organizationRole @include(if: $withOrganizationRole) {
                id
                name
            }
            hiringManager @include(if: $withHiringManager) {
                id
                user {
                    id
                    firstName
                    lastName
                    email
                }
            }
            hiringTeam @include(if: $withHiringTeam) {
                id
                user {
                    id
                    firstName
                    lastName
                    email
                }
            }
            requirePersonalityTest
            requireLogicTest
            requireLogicIrtTest
            requireInterviews
            testsWeight
            interviewsWeight
            selectedProfileId
            selectedTestProfile @include(if: $withTestProfile) {
                ...TestProfileFields
            }
            allowsPublicApplications
            publicPageTitle
            publicPageInstructions
            metrics @include(if: $withMetrics) {
                candidatesInvited
                candidatesAccepted
                candidatesWithTestScore
            }
            inviteCandidateEmailTemplate @include(if: $withEmailTemplate) {
                id
                name
                subject
                title
                body
                linkText
                useLogo
                organizationName
                showOrganizationName
            }
            usedInviteCandidateEmailTemplate @include(if: $withEmailTemplate) {
                id
                name
                subject
                title
                body
                linkText
                useLogo
                organizationName
                showOrganizationName
            }
            candidateReminderEmailTemplate @include(if: $withEmailTemplate) {
                id
                name
                subject
                title
                body
                linkText
                useLogo
                organizationName
                showOrganizationName
            }
            usedCandidateReminderEmailTemplate @include(if: $withEmailTemplate) {
                id
                name
                subject
                title
                body
                linkText
                useLogo
                organizationName
                showOrganizationName
            }
            automatedRemindersEnabled
            automatedRemindersIntervalFrequency
            recommendationsEnabled
            interviews @include(if: $withInterview) {
                id
                title
                created
                modified
                description
                order
                jobPositionId
                contentItems {
                    ...InterviewContentItemFields
                }
            }
            challenge @include(if: $withChallenge) {
                id
                name
                profile
                isCustom
                juniorLevelEstimateDurationMinutes
                midLevelEstimateDurationMinutes
                seniorLevelEstimateDurationMinutes
                scorecard {
                    requirements {
                        criteria
                        requirement
                    }
                    version
                }
            }
            challengeTimeLimitMinutes
            assessmentsRecommendation @include(if: $withAssessmentsRecommendation) {
                aggregatedStatus
                assessmentsRecommendationId
                createdAt
                jobTitle {
                    id
                    name
                }
                results {
                    status
                    type
                    recommendedAssessmentId
                }
                skills {
                    id
                    name
                }
                yearsOfExperience
            }
            isInternal
            isConfidential
            leadershipReportEnabled
            enabledBetaFeatures
            countryCode
            personalityProfile @include(if: $withPersonalityProfile) {
                id
                name
                description
                jobPositionId
                externalJobPositionId
                organizationId
                isModified
                facets {
                    name
                    weight
                    order
                    positiveWeight
                }
            }
            assessmentConfigurations {
                assessmentMethodKey
                assessmentMethodType
                assessmentMethodId
                weight
                weightPercentage
            }
            challengeSpecifications @include(if: $withChallengeSpecifications) {
                frameworks
                languages
            }
            publicPageOrganizationNameEnabled
            publicPagePrivacyNoticeEnabled
            publicPageColorHeaderEnabled
            publicPageLogoEnabled
            publicPageColorHeader
            publicPageOrganizationName
        }
    }
`);

export const GET_ASSESSMENT_METHODS_FOR_JOB_POSITION = gql(`
    query getAssessmentMethodsForJobPosition(
        $id: Int!
        $withChallengeSpecifications: Boolean = false
    ) {
        jobPosition(id: $id) {
            ...JobPositionAssessmentMethodsFields
            challengeSpecifications @include(if: $withChallengeSpecifications) {
                frameworks
                languages
            }
        }
    }
`);

export const GET_EMPLOYMENTS_FOR_TEAM_IN_JOB_POSITION = gql(`
    query getJobPositionWithTeam($id: Int!) {
        jobPosition(id: $id) {
            id
            team {
                id
                teamMembers {
                    id
                    showInTeamInsights
                    employment {
                        id
                    }
                }
            }
        }
    }
`);

export const GET_JOB_POSITIONS = gql(`
    query getJobPositionsForOrganization(
        $id: Int!
        $fetchOptions: JobPositionsFetchOptionsInput
        $withOrganizationRole: Boolean = false
        $withTeam: Boolean = false
        $withHiringManager: Boolean = false
        $withMetrics: Boolean = false
    ) {
        organization(id: $id) {
            id
            jobPositionsConnection(fetchOptions: $fetchOptions) {
                totalResults
                cursor {
                    hasMore
                    next
                }
                items {
                    id
                    created
                    name
                    activationState
                    expiresAt
                    currentStatus
                    createdFrom
                    team @include(if: $withTeam) {
                        id
                        name
                    }
                    organizationRole @include(if: $withOrganizationRole) {
                        id
                        name
                    }
                    hiringManager @include(if: $withHiringManager) {
                        id
                        user {
                            id
                            firstName
                            lastName
                            email
                        }
                    }
                    requirePersonalityTest
                    requireLogicTest
                    requireLogicIrtTest
                    requireInterviews
                    testsWeight
                    interviewsWeight
                    selectedProfileId
                    allowsPublicApplications
                    publicPageTitle
                    publicPageInstructions
                    metrics @include(if: $withMetrics) {
                        candidatesInvited
                        candidatesAccepted
                        candidatesWithTestScore
                    }
                    recommendationsEnabled
                }
            }
        }
    }
`);

export const GET_ATS_JOB_POSITION = gql(`
    query ATSJobPosition($jobPositionId: Int!) {
        ATSJobPosition(jobPositionId: $jobPositionId) {
            stages
            integrationId
            assessmentStages {
                codingTest
                psychometricTest
                interviews {
                    interviewId
                    stage
                }
            }
        }
    }
`);

export const GET_JOB_POSITION_TEAMTAILOR_URL = gql(`
    query JobPositionTeamtailorUrl($jobPositionId: Int!, $teamtailorJobId: String!) {
        jobPosition(id: $jobPositionId) {
            id
            teamtailorJobUrl(teamtailorJobId: $teamtailorJobId)
        }
    }
`);

export const GET_MY_JOB_POSITIONS = gql(`
    query getMyJobPositions {
        myJobPositions {
            id
            created
            name
            activationState
            metrics {
                candidatesAccepted
                candidatesInvited
                candidatesWithTestScore
            }
            recommendationsEnabled
        }
    }
`);
