import styled from 'styled-components';

import {Wrapper as SeparatorWrapper} from '@/componentLibrary/components/Separator/styled';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {SpacingLarge, SpacingXlarge} from '@/componentLibrary/tokens/variables';

import {Wrapper} from '../PersonalityTest/PersonalityTestReport/styled';

export const ExtendedWrapper = styled(Wrapper)`
    @media print {
        ${SeparatorWrapper} {
            display: none;
        }
    }
`;

export const TranslationsMissingBanner = styled(Banner)`
    margin-bottom: ${SpacingXlarge};
`;

export const OverviewWrapper = styled.div`
    margin-block-start: ${SpacingLarge};

    @media print {
        page-break-after: always;
        break-after: page;
        page-break-inside: avoid;
        break-inside: avoid;
    }
`;
