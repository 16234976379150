import {SpacingXlarge, SpacingXsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXsmall};
`;
export const ChartWrapper = styled.div`
    margin: ${SpacingXlarge} auto;
    width: 100%;
`;
export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 99%;
`;
