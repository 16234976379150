export const FORM_STATES = {
    COMPLETED: 'COMPLETED',
    TRIED_TO_SUBMIT: 'TRIED_TO_SUBMIT',
    ACCEPTED_CONSENT: 'ACCEPTED_CONSENT'
} as const;

export const ERROR_TYPES = {
    EMAIL_ERROR: 'EMAIL_ERROR',
    COUNTRY_BLOCKED_ERROR: 'COUNTRY_BLOCKED_ERROR',
    DISPLAY_WARNING: 'DISPLAY_WARNING'
} as const;

export const CREATED_FROM = 'PUBLIC_PAGE';
