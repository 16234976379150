import {gql} from '@/api/types/__generated__';

export const GET_APPLICATIONS_FOR_JOB_POSITION_AND_CANDIDATES = gql(`
    query getApplicationsForJobPosition(
        $jobPositionId: Int!
        $userIds: [Int]!
        $withRoleFitV1: Boolean!
        $withRoleFitV2: Boolean!
    ) {
        jobApplications(jobPositionId: $jobPositionId, userIds: $userIds) {
            ...JobApplicationFields
            roleFit @include(if: $withRoleFitV1) {
                id
                score
                label
                interviewScore
                testProfileScore
            }
            roleFitV2 @include(if: $withRoleFitV2) {
                jobApplicationId
                totalScore
                label
                missingResultsForAnyMethod
                assessmentScores {
                    missingResults
                }
            }
            interviewRequestsStatus {
                publishedRequests
                totalRequests
            }
        }
    }
`);

export const GET_JOB_APPLICATIONS_FOR_USER = gql(`
    query getJobApplicationsForUser($userId: Int!, $organizationId: Int) {
        user(id: $userId) {
            id
            jobApplications(organizationId: $organizationId) {
                id
                created
                modified
                acceptedByCandidateAt
                currentStage
                createdFrom
                jobPosition {
                    id
                    name
                    currentStatus
                }
            }
        }
    }
`);

export const LOAD_JOB_APPLICATIONS_FOR_ORGANIZATION = gql(`
    query loadJobApplicationsForOrganization(
        $fetchOptions: JobApplicationsFetchOptionsInput
        $withJobPosition: Boolean!
        $withRoleFit: Boolean!
        $withRoleFitV2: Boolean!
        $withInterviewScore: Boolean!
        $withPersonalityResult: Boolean!
        $withLogicTestResult: Boolean!
        $withLogicalAbilityEstimate: Boolean!
        $withScoreCounts: Boolean!
        $withSentEmails: Boolean!
        $withInvitationStatus: Boolean!
        $withCandidateReminderStatus: Boolean!
        $withChallengeAssignment: Boolean!
    ) {
        authenticatedOrganization {
            id
            jobApplicationsConnection(fetchOptions: $fetchOptions) {
                totalResults
                overallCount @include(if: $withScoreCounts)
                withLogicScoreCount @include(if: $withScoreCounts)
                withPersonalityScoreCount @include(if: $withScoreCounts)
                withTestProfileScoreCount @include(if: $withScoreCounts)
                withInterviewScoreCount @include(if: $withScoreCounts)
                cursor {
                    hasMore
                    next
                }
                items {
                    id
                    interviewsOverallScore
                    acceptedByCandidateAt
                    withdrawnAt
                    fromJobRecommendationId
                    created
                    currentStage
                    jobPosition @include(if: $withJobPosition) {
                        id
                        name
                    }
                    invitationStatus @include(if: $withInvitationStatus) {
                        jobApplicationId
                        acceptedAt
                        latestEmailSentAt
                        latestEmailOpenedAt
                        latestEmailBouncedAt
                    }
                    user {
                        id
                        firstName
                        lastName
                        lastSeenAt
                        email
                        emailConfirmed
                        isActive
                        registeredAt
                        sentEmails @include(if: $withSentEmails) {
                            id
                            emailType
                            bouncedAt
                            deliveredAt
                            openedAt
                        }
                        personalityIrtResult @include(if: $withPersonalityResult) {
                            id
                            a
                            ai
                            aii
                            aiii
                            c
                            ci
                            cii
                            ciii
                            e
                            ei
                            eii
                            eiii
                            es
                            esi
                            esii
                            esiii
                            o
                            oi
                            oii
                            oiii
                        }
                        latestLogicTest @include(if: $withLogicTestResult) {
                            id
                            standardScore
                        }
                        logicalAbilityEstimate @include(if: $withLogicalAbilityEstimate) {
                            id
                            mu
                            validationTestResult {
                                id
                                result
                            }
                        }
                    }
                    roleFit @include(if: $withRoleFit) {
                        id
                        score
                        label
                        interviewScore
                        testProfileScore
                        testProfilePersonalityScore
                        testProfileLogicScore
                        logicDetails {
                            id
                            score
                            label
                        }
                        personalityDetails {
                            id
                            facetName
                            score
                            label
                        }
                    }
                    roleFitV2 @include(if: $withRoleFitV2) {
                        assessmentScores {
                            assessmentMethodKey
                            assessmentMethodId
                            assessmentMethodType
                            instanceIds
                            score
                            missingResults
                        }
                        jobApplicationId
                        totalScore
                        label
                        missingResultsForAnyMethod
                    }
                    interviewAggregatedScore @include(if: $withInterviewScore) {
                        id
                        created
                        score
                        competencyScores {
                            interviewContentItemId
                            competencyScoreAverage
                            competencyScoreSigma
                        }
                    }
                    interviewRequestsStatus @include(if: $withInterviewScore) {
                        publishedRequests
                        totalRequests
                    }
                    interviewRequests @include(if: $withInterviewScore) {
                        id
                        interviewId
                        publishedAt
                        employment {
                            id
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                        }
                        competencyScores {
                            id
                        }
                        publishedCompetencyScores {
                            id
                            interviewContentItemId
                            score
                            comment
                            isSkipped
                        }
                    }
                    candidateReminderStatus @include(if: $withCandidateReminderStatus) {
                        userId
                        jobPositionId
                        numberOfRemindersSent
                        firstReminderSentAt
                        latestReminderSentAt
                        latestDeliveredInvitationTimestamp
                        latestBouncedInvitationTimestamp
                        latestDeliveredReminderTimestamp
                        latestBouncedReminderTimestamp
                    }
                    challengeAssignment @include(if: $withChallengeAssignment) {
                        id
                        repositoryUrl
                        publishedScorecards {
                            requirementId
                            criteria
                            requirement
                            version
                            scores {
                                comments
                                score
                                isSkipped
                            }
                        }
                        automatedScore {
                            totalScore
                            createdAt
                            testScore {
                                score
                            }
                            qualityScore {
                                score
                            }
                            reviewScore {
                                score
                                scorecardVersion
                            }
                        }
                        overdueAutomatedScore {
                            totalScore
                            createdAt
                            testScore {
                                score
                            }
                            qualityScore {
                                score
                            }
                            reviewScore {
                                score
                                scorecardVersion
                            }
                        }
                        useOverdueScore
                        publishedDecisions {
                            comments
                            state
                        }
                        challengeId
                        status
                        publishedReviewCount
                        noDecisionCount
                        yesDecisionCount
                        timeLimitMinutes
                        submissionDeadline
                        submittedAt
                        challenge {
                            scorecard {
                                requirements {
                                    criteria
                                }
                                version
                            }
                            profile
                        }
                        scorecardVersion
                    }
                }
            }
        }
    }
`);

export const GET_JOB_APPLICATION = gql(`
    query getJobApplication(
        $jobPositionId: Int!
        $userId: Int!
        $withRoleFit: Boolean = false
        $withRoleFitV2: Boolean = false
        $withRoleFitDistribution: Boolean = false
        $withRoleFitHistogram: Boolean = false
        $withInterviewScore: Boolean = false
        $withInterviewScoresAndInterview: Boolean = false
        $withJobPosition: Boolean = false
        $withInterviews: Boolean = false
        $withCandidate: Boolean = false
        $withCandidateReminderStatus: Boolean = false
        $withCompletedAt: Boolean = false
        $withRoleFitContribution: Boolean = false
        $withChallengeAssignment: Boolean = false
        $withAutomatedScore: Boolean = false
        $withTeam: Boolean = false
        $withHiringManager: Boolean = false
    ) {
        jobApplication(jobPositionId: $jobPositionId, userId: $userId) {
            id
            created
            currentStage
            withdrawnAt
            fromJobRecommendationId
            user @include(if: $withCandidate) {
                id
                firstName
                lastName
                email
                personalityIrtResult {
                    ...PersonalityIrtFields
                }
                latestLogicTest {
                    id
                    standardScore
                }
                logicalAbilityEstimate {
                    id
                    mu
                    validationTestResult {
                        id
                        result
                    }
                }
            }
            roleFit @include(if: $withRoleFit) {
                id
                score
                label
                interviewScore
                testProfileScore
                testProfilePersonalityScore
                testProfileLogicScore
                logicDetails {
                    id
                    score
                    label
                }
                personalityDetails {
                    id
                    facetName
                    score
                    label
                }
            }
            roleFitV2 @include(if: $withRoleFitV2) {
                jobApplicationId
                updatedAt
                totalScore
                label
                missingResultsForAnyMethod
                assessmentConfigurations {
                    assessmentMethodKey
                    assessmentMethodType
                    assessmentMethodId
                    weight
                    weightPercentage
                }
                assessmentScores {
                    assessmentMethodKey
                    assessmentMethodType
                    assessmentMethodId
                    score
                    instanceIds
                    missingResults
                }
            }
            challengeAssignment @include(if: $withChallengeAssignment) {
                id
                repositoryUrl
                challengeId
                challenge {
                    id
                    profile
                    name
                    url
                    description
                    isCustom
                    juniorLevelEstimateDurationMinutes
                    midLevelEstimateDurationMinutes
                    seniorLevelEstimateDurationMinutes
                    scorecard {
                        requirements {
                            requirement
                            criteria
                        }
                        version
                    }
                }
                draftScorecards {
                    requirementId
                    criteria
                    requirement
                    version
                    score {
                        comments
                        score
                        isSkipped
                        author {
                            id
                        }
                    }
                }
                publishedScorecards {
                    requirementId
                    criteria
                    requirement
                    version
                    scores {
                        comments
                        score
                        isSkipped
                        author {
                            id
                        }
                    }
                }
                hasUnpublishedDraftScorecards
                publishedDecisions {
                    comments
                    state
                    author {
                        id
                    }
                }
                draftDecision {
                    comments
                    state
                    author {
                        id
                    }
                }
                hasUnpublishedDraftDecision
                status
                publishedReviewCount
                noDecisionCount
                yesDecisionCount
                automatedScore @include(if: $withAutomatedScore) {
                    createdAt
                    totalScore
                    testScore {
                        score
                        results {
                            result
                            test
                        }
                    }
                    qualityScore {
                        score
                        requirementScores {
                            motivation
                            requirement
                            score
                        }
                        motivation
                    }
                    reviewScore {
                        score
                        requirementScores {
                            criterion
                            motivation
                            requirement
                            score
                        }
                        motivation
                        scorecardVersion
                    }
                }
                overdueAutomatedScore @include(if: $withAutomatedScore) {
                    createdAt
                    totalScore
                    testScore {
                        score
                        results {
                            result
                            test
                        }
                    }
                    qualityScore {
                        score
                        requirementScores {
                            motivation
                            requirement
                            score
                        }
                        motivation
                    }
                    reviewScore {
                        score
                        requirementScores {
                            criterion
                            motivation
                            requirement
                            score
                        }
                        motivation
                        scorecardVersion
                    }
                }
                useOverdueScore
                timeLimitMinutes
                submissionDeadline
                submittedAt
                scorecardVersion
            }
            roleFitDistribution @include(if: $withRoleFitDistribution) {
                userId
                jobPositionId
                score
                candidatesWithScore
                rank
                alpha
                location
                scale
                percentileBoundaries {
                    boundary
                    value
                }
            }
            roleFitHistogram @include(if: $withRoleFitHistogram) {
                userId
                jobPositionId
                score
                buckets {
                    lower
                    upper
                    userCount
                }
            }
            roleFitContribution @include(if: $withRoleFitContribution) {
                logicTestContribution {
                    contribution
                    maxContribution
                }
                personalityFacetsContribution {
                    facet
                    contribution
                    maxContribution
                }
            }
            interviewAggregatedScore @include(if: $withInterviewScore) {
                id
                created
                score
                competencyScores {
                    competencyScoreAverage
                    competencyScoreSigma
                }
            }
            interviewAggregatedScores @include(if: $withInterviewScoresAndInterview) {
                id
                created
                score
                interview {
                    id
                    title
                    order
                }
                competencyScores {
                    competencyScoreAverage
                    competencyScoreSigma
                }
            }
            interviewRequests @include(if: $withInterviewScoresAndInterview) {
                interviewId
                publishedCompetencyScores {
                    interviewContentItemId
                    score
                    isSkipped
                }
            }
            jobPosition @include(if: $withJobPosition) {
                id
                name
                selectedProfileId
                requirePersonalityTest
                requireLogicTest
                requireLogicIrtTest
                requireInterviews
                enabledBetaFeatures @include(if: $withRoleFitV2)
                team @include(if: $withTeam) {
                    name
                    id
                }
                challenge {
                    id
                }
                hiringManager @include(if: $withHiringManager) {
                    id
                    user {
                        firstName
                        lastName
                        id
                    }
                }
                interviews @include(if: $withInterviews) {
                    id
                    title
                    order
                }
            }
            candidateReminderStatus @include(if: $withCandidateReminderStatus) {
                userId
                jobPositionId
                numberOfRemindersSent
                latestReminderSentAt
                latestDeliveredInvitationTimestamp
                latestBouncedInvitationTimestamp
                latestDeliveredReminderTimestamp
                latestBouncedReminderTimestamp
            }
            completedAt @include(if: $withCompletedAt)
        }
    }
`);

export const HAS_JOB_APPLICATIONS_FOR_JOB_POSITION = gql(`
    query hasJobApplicationsForJobPosition($fetchOptions: JobApplicationsFetchOptionsInput) {
        authenticatedOrganization {
            id
            jobApplicationsConnection(fetchOptions: $fetchOptions) {
                items {
                    id
                }
            }
        }
    }
`);
