import {P1} from '@/componentLibrary/components/typography';
import {
    BorderBaseDefaultStyle,
    BorderBaseDefaultWidth,
    BorderRadiusBaseRounded,
    ColorBaseGrey100,
    ColorBaseGrey200,
    ColorBaseRed800,
    ColorBgInset,
    ColorFgMuted,
    SpacingMedium,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const ProgressBar = styled.div`
    width: 100%;
    height: 6px;
    border-radius: ${BorderRadiusBaseRounded};
    background-color: ${ColorBgInset};
    overflow: hidden;
`;

export const Bar = styled(ProgressBar)<{percentage: number; intervalDuration: number}>`
    transform: translateX(${({percentage}) => percentage - 100}%);
    transition: transform ${({intervalDuration}) => intervalDuration / 1000}s linear;
    background-color: ${ColorFgMuted};
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingMedium};
`;

export const Header = styled(P1)`
    text-align: center;
`;

export const FrameworkName = styled.span`
    font-weight: 500;
`;

export const BranchName = styled.span`
    background-color: ${ColorBaseGrey100};
    color: ${ColorBaseRed800};
    padding: 0 6px;
    border: ${BorderBaseDefaultWidth} ${BorderBaseDefaultStyle} ${ColorBaseGrey200};
    border-radius: 5px;
    box-shadow: inset 0 0 ${SpacingXxxsmall} ${ColorBaseGrey100};
    white-space: nowrap;
`;
