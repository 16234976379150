import {createContext} from 'react';

export const initialState = null;
export const SET_HOVERED_ITEM_ID = 'SET_HOVERED_ITEM_ID';
export const CLEAR_HOVERED_ITEM_ID = 'CLEAR_HOVERED_ITEM_ID';

export function reducer(_state, action) {
    switch (action.type) {
        case SET_HOVERED_ITEM_ID:
            return action.hoveredItemId;
        case CLEAR_HOVERED_ITEM_ID:
            return null;
        default:
            return initialState;
    }
}

export const TooltipHoverStateContext = createContext(initialState);
