import {defineMessages} from 'react-intl';

export default defineMessages({
    searchText: {
        id: 'app.general.search'
    },
    noMatchesText: {
        id: 'app.pages.user.candidateRelevanceModal.noMatchesText'
    },
    done: {
        id: 'app.pages.user.candidateRelevanceModal.done'
    }
});
