import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.signup.expiredToken.title'
    },
    description: {
        id: 'app.signup.expiredToken.description'
    },
    resendEmail: {
        id: 'app.signup.expiredToken.resendEmail'
    },
    emailSent: {
        id: 'app.signup.expiredToken.emailSent'
    },
    sendEmailSuccess: {
        id: 'app.signup.expiredToken.sendEmailSuccess'
    },
    sendEmailError: {
        id: 'app.signup.expiredToken.sendEmailError'
    },
    resendEmailCaption: {
        id: 'app.signup.expiredToken.resendEmailCaption'
    }
});
