import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {
    CreateInterviewRequestsForJobApplicationsMutation,
    InterviewRequestsForJobApplicationsInput
} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {CREATE_INTERVIEW_REQUESTS_FOR_JOB_APPLICATIONS} from './mutations';

export function useCreateInterviewRequestsForJobApplications(): MutationHookReturnType<
    CreateInterviewRequestsForJobApplicationsMutation,
    [InterviewRequestsForJobApplicationsInput, boolean]
> {
    const [mutate, {error, loading}] = useMutation(
        CREATE_INTERVIEW_REQUESTS_FOR_JOB_APPLICATIONS
    );

    const createInterviewRequestsForJobApplications = useCallback(
        (data: InterviewRequestsForJobApplicationsInput, notifyInterviewers: boolean) => {
            const variables = {data, notifyInterviewers};
            return mutate({variables});
        },
        [mutate]
    );

    return [createInterviewRequestsForJobApplications, {error, loading}];
}
