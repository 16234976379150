import {ReactNode} from 'react';
import styled from 'styled-components';

import {grey} from '@/componentLibrary/tokens/legacyColors';
import {SpacingMedium, SpacingXxlarge} from '@/componentLibrary/tokens/variables';

type PageTemplateWithSectionsProps = {
    primarySection?: ReactNode;
    secondarySection?: ReactNode;
};

export const PageTemplateWithSections = ({
    primarySection,
    secondarySection
}: PageTemplateWithSectionsProps) => (
    <>
        {primarySection && <PrimarySection>{primarySection}</PrimarySection>}
        {secondarySection && <SecondarySection>{secondarySection}</SecondarySection>}
    </>
);

export const Wrapper = styled.div`
    background-color: ${grey.grey800()};
    width: 100%;
    min-height: 100vh;
    overflow-y: hidden;
`;

export const Body = styled.div`
    padding: ${SpacingXxlarge} ${SpacingMedium} ${SpacingMedium} ${SpacingMedium};

    @media only screen and (min-width: 1440px) {
        margin: 0 auto;
    }

    overflow-y: auto;

    :empty {
        display: none;
    }
`;

const PrimarySection = styled(Body)`
    padding-bottom: 0;
    min-height: 60vh;
`;

const SecondarySection = styled(Body)`
    background-color: ${grey.white()};
    min-height: 40vh;
`;
