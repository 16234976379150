import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {id: 'app.pages.user.jobRecommendations.jobRecommendationItem.originalJobAdTitle'},
    description: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.originalJobAdDescription'
    },
    link: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.originalJobAdLink'
    },
    applicationInfo: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.originalJobAdApplicationInfo'
    }
});
