import {DonutChart} from './DonutChart';
import {DonutLegend} from './DonutLegend';
import {ChartWrapper} from './styled';
import {DonutGraphProps} from './types';

export const DonutGraph = ({
    data,
    tracesOptions,
    layoutOptions,
    legendOptions,
    displayLegend = false
}: DonutGraphProps) => {
    return (
        <ChartWrapper>
            <DonutChart
                data={data}
                tracesOptions={tracesOptions}
                layoutOptions={layoutOptions}
            />
            {displayLegend && <DonutLegend data={data} legendOptions={legendOptions} />}
        </ChartWrapper>
    );
};
