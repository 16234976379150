import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';

import {useWithdrawJobApplicationsFromOrganization} from '@/api/users/useWithdrawJobApplicationsFromOrganization';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {userLinks} from '@/pages/User/links';
import {ALVA_SELECTED_ORGANIZATION_KEY} from '@/services/auth/constants';
import {logger} from '@/services/logrocket';

import messages from './messages';

export function useWithdrawJobApplicationsFromOrganizationModalLogic(
    userId: number,
    organizationId: number | null,
    closeModal: {(): void; (): void}
) {
    const {formatMessage} = useIntl();
    const [doWithdraw, {loading}] = useWithdrawJobApplicationsFromOrganization(
        userId,
        organizationId
    );
    const history = useHistory();
    const selectedOrganizationId = window.localStorage.getItem(ALVA_SELECTED_ORGANIZATION_KEY);

    const withdrawJobApplications = useCallback(() => {
        if (!userId || !organizationId) {
            return;
        }
        doWithdraw()
            .then(({data}) => {
                const {ok, errorMessage} = data?.withdrawJobApplicationsFromOrganization ?? {};
                if (ok) {
                    toast({
                        type: 'success',
                        message: formatMessage(messages.applicationsWithdrawn)
                    });
                    closeModal();
                    if (
                        selectedOrganizationId &&
                        parseInt(selectedOrganizationId, 10) === organizationId
                    ) {
                        history.push(userLinks.accountSettings());
                    }
                } else {
                    throw new Error(
                        errorMessage ?? 'Unknown error when withdrawing applications'
                    );
                }
            })
            .catch(e => {
                logger.error(e);
                toast({type: 'error'});
            });
    }, [
        userId,
        organizationId,
        doWithdraw,
        closeModal,
        selectedOrganizationId,
        history,
        formatMessage
    ]);

    return {
        loading,
        withdrawApplications: withdrawJobApplications
    };
}
