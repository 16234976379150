import {defineMessages} from 'react-intl';

export default defineMessages({
    invalidTokenTitle: {
        id: 'app.pages.authenticateCandidate.errorPage.invalidTokenTitle'
    },
    invalidTokenInformation: {
        id: 'app.pages.authenticateCandidate.errorPage.invalidTokenInformation'
    },
    tokenExpiredTitle: {
        id: 'app.pages.authenticateCandidate.errorPage.tokenExpiredTitle'
    },
    tokenExpiredInformation: {
        id: 'app.pages.authenticateCandidate.errorPage.tokenExpiredInformation'
    }
});
