import {Button} from './components/Button';
import {useSegmentedControlLogic} from './logic';
import {Wrapper} from './styled';
import {SegmentedControlProps, SegmentedControlSize} from './types';

export function SegmentedControl<T>({
    options,
    onSelect,
    selectedOptionValue,
    size = SegmentedControlSize.DEFAULT
}: SegmentedControlProps<T>) {
    const getHeight = useSegmentedControlLogic(size);

    return (
        <Wrapper height={getHeight()}>
            {options.map(option => (
                <Button<T>
                    key={`segmented-control-option-${option.value}`}
                    option={option}
                    onSelect={onSelect}
                    isSelected={option.value === selectedOptionValue || option.isSelected}
                />
            ))}
        </Wrapper>
    );
}
