import {useCallback, useState} from 'react';

import {useSendSignupEmail} from '@/api/email/useSendSignupEmail';
import {logger} from '@/services/logrocket';

export function useExpiredTokenLogic(userId?: number) {
    const [emailIsSent, setEmailIsSent] = useState(false);
    const [errorWhenSendingEmail, setErrorWhenSendingEmail] = useState(false);
    const [doSendSignupEmail, {loading}] = useSendSignupEmail();

    const sendSignupEmail = useCallback(() => {
        doSendSignupEmail(userId)
            .then(({data}) => {
                const {ok, errorMessage} = data?.sendSignupEmail ?? {};
                if (ok) {
                    setEmailIsSent(true);
                } else {
                    throw new Error(errorMessage ?? '');
                }
            })
            .catch(error => {
                logger.error(error);
                setErrorWhenSendingEmail(true);
            });
    }, [userId, doSendSignupEmail]);

    return {
        emailIsSent,
        errorWhenSendingEmail,
        loading,
        sendSignupEmail
    };
}
