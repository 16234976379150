import {FC} from 'react';
import {useIntl} from 'react-intl';

import {Weight} from '@/api/types/__generated__/graphql';
import {DonutChart} from '@/componentLibrary/components/charts/DonutGraph/DonutChart';
import {OneDimensionalBar} from '@/componentLibrary/components/charts/OneDimensionalBar';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {Caption, H2, H4, P2, S2} from '@/componentLibrary/components/typography';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {getFacetWeightMessage} from '@/pages/Organization/pages/Assessment/components/AssessmentSelector/components/PersonalityProfileDetailsModal/utils';

import {isDefined} from '@/utils/typeGuards/isDefined';
import {AssessmentScoresResults} from '../../../AssessmentScoresResults/AssessmentScoresResults';
import {SCORE_TYPE} from '../../../AssessmentScoresResults/constants';
import messages from '../../messages';
import {
    Divider,
    Importance,
    InnerWrapper,
    ResultsTitle,
    ScoreResults,
    TextDisplay,
    Wrapper
} from './styled';
import {ScoreCardDisplayProps} from './types';

export const ScoreCardDisplay: FC<ScoreCardDisplayProps> = ({
    title,
    scoreInPercentage,
    type,
    amount,
    chartData,
    miniChartColors,
    weight,
    interviewId
}) => {
    const intl = useIntl();
    const phrases = useExtractPhraseConstants(messages);

    return (
        <Wrapper>
            <H4>{title}</H4>
            {type === SCORE_TYPE.INTERVIEW && phrases.interviewTitle}
            <InnerWrapper>
                <ScoreResults>
                    <TextDisplay>
                        <P2 color={ColorFgSubtle}>{phrases.score}</P2>
                        <FlexLayout alignItems="baseline">
                            <H2>{isDefined(scoreInPercentage) ? scoreInPercentage : '--'}</H2>%
                        </FlexLayout>
                    </TextDisplay>
                    <OneDimensionalBar isLarge scoreInPercentage={scoreInPercentage || 0} />
                    <ResultsTitle color={ColorFgSubtle}>{phrases.results}</ResultsTitle>
                    <AssessmentScoresResults type={type} interviewId={interviewId} />
                </ScoreResults>
                {amount > 1 && (
                    <>
                        <Divider />
                        <Importance>
                            <Caption color={ColorFgSubtle}>
                                {phrases.roleFitDescription}
                            </Caption>
                            <DonutChart
                                data={chartData}
                                tracesOptions={{
                                    hoverinfo: 'none',
                                    marker: {colors: miniChartColors}
                                }}
                                layoutOptions={{height: 25, width: 25}}
                            />
                            <S2>{getFacetWeightMessage(intl, weight as unknown as Weight)}</S2>
                        </Importance>
                    </>
                )}
            </InnerWrapper>
        </Wrapper>
    );
};
