import {useMemo} from 'react';

import {useJobApplication} from '@/api/assessment/jobApplications/useJobApplication';

export function useRoleFitContributionLogic({userId, jobPositionId}) {
    const {loading, error, refetch, jobApplication} = useJobApplication(
        jobPositionId,
        userId,
        {withRoleFit: true, withRoleFitContribution: true}
    );

    const data = useMemo(() => {
        if (loading || error) {
            return null;
        }

        if (!jobApplication || !jobApplication.roleFitContribution) {
            return null;
        }

        const {logicTestContribution, personalityFacetsContribution} =
            jobApplication.roleFitContribution;

        return {
            logicTestContribution: logicTestContribution ? [logicTestContribution] : null,
            personalityFacetsContribution
        };
    }, [loading, error, jobApplication]);

    const noData = useMemo(() => {
        if (!data) {
            return true;
        }

        const {logicTestContribution, personalityFacetsContribution} = data;

        return !logicTestContribution && !personalityFacetsContribution;
    }, [data]);

    const roleFitScore = useMemo(() => {
        if (!jobApplication || !jobApplication.roleFit) {
            return null;
        }

        return jobApplication.roleFit.score;
    }, [jobApplication]);

    return {
        loading,
        error: error ? {reload: refetch} : null,
        noData,
        ...data,
        roleFitScore
    };
}
