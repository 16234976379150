import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {H2} from '@/componentLibrary/components/typography/Headings';
import {S1} from '@/componentLibrary/components/typography/SubTitles';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {ColorBaseYellow900, SpacingSmall} from '@/componentLibrary/tokens/variables';
import messages from '@/pages/SignupV2/components/InfoPanel/messages';

import {SizeNarrow} from '../../constants';

export function InfoPanel() {
    const intl = useIntl();
    return (
        <Panel data-testid="new-signup-info-panel">
            <H2>
                <FormattedMessage {...messages.mainSubjectHeader} />
            </H2>
            <FeatureList>
                <FeatureItem text={intl.formatMessage(messages.feature1)} />
                <FeatureItem text={intl.formatMessage(messages.feature2)} />
                <FeatureItem text={intl.formatMessage(messages.feature3)} />
                <FeatureItem text={intl.formatMessage(messages.feature4)} />
                <FeatureItem text={intl.formatMessage(messages.feature5)} />
                <FeatureItem text={intl.formatMessage(messages.feature6)} />
                <FeatureItem text={intl.formatMessage(messages.feature7)} />
            </FeatureList>
        </Panel>
    );
}

type FeatureItemProps = {
    text: string;
};
function FeatureItem(props: FeatureItemProps) {
    return (
        <ListItem>
            <Icon size={20} icon={Icons.CHECK_CIRCLE} fill={ColorBaseYellow900} />
            <ItemText>{props.text}</ItemText>
        </ListItem>
    );
}

const Panel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        width: ${SizeNarrow};
    }
`;

const ListItem = styled.li`
    display: flex;
    height: 24px;
    align-items: center;
    padding: 0 2.5px;
    margin-bottom: 8px;
`;

const ItemText = styled(S1)`
    margin-left: 8px;
`;

const FeatureList = styled.ul`
    list-style: none;
    margin-top: ${SpacingSmall};
`;
