import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {ExternalResultsPermissionInput} from '@/api/types/__generated__/graphql';
import {useUser} from '@/api/users/useUser';
import {useEnabledAssessmentConfigurations} from '@/hooks/useEnabledAssessmentConfigurations';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useHasCombinedRoleFitBetaTest} from '@/hooks/useHasCombinedRoleFitBetaTest';
import {
    CandidateCodingTestPage,
    CandidateInterviewPage,
    CandidateLogicTestPage,
    CandidatePersonalityTestPage,
    CandidateResults,
    CandidateRoleFitPage
} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages';
import {CandidateRoleFitV2Page} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateRoleFitV2Page';
import {
    CandidateTabFields,
    CandidateTabsMap
} from '@/pages/Organization/pages/Settings/pages/AccessGroups/types';
import {TrackPageView} from '@/services/segment';
import {PAGE_CATEGORIES} from '@/services/segment/constants';
import {getUserName} from '@/utils/misc';
import {useCallback, useMemo} from 'react';
import {sharedResultsLinks} from '../../SharedResultsPage/links';
import messages from '../messages';
import {TabWrapper} from '../styled';

export const useSharedUserResultsLogic = (
    token: string,
    permissions: string[],
    userId: number,
    jobPositionId: number
) => {
    const phrases = useExtractPhraseConstants(messages);

    const {jobPosition, loading: loadingJobPosition} = useJobPosition(jobPositionId);
    const hasCombinedRoleFitBetaTest = useHasCombinedRoleFitBetaTest(jobPosition);
    const RoleFitPageComponent = hasCombinedRoleFitBetaTest
        ? CandidateRoleFitV2Page
        : CandidateRoleFitPage;

    const assessmentConfigurations = useEnabledAssessmentConfigurations(jobPosition);

    const {user} = useUser(userId);
    const candidateName = getUserName(user);

    const candidateTabs: CandidateTabsMap = {
        roleFit: {
            id: 'roleFitSharedPage',
            path: sharedResultsLinks.roleFitResults(token),
            isExact: true,
            title: phrases.roleFit,
            render: () => (
                <TrackPageView
                    name="Results shared page"
                    category={PAGE_CATEGORIES.SHARED_RESULTS}
                >
                    <TabWrapper>
                        <RoleFitPageComponent jobPositionId={jobPositionId} userId={userId} />
                    </TabWrapper>
                </TrackPageView>
            )
        },
        personalityTest: {
            id: 'personalityTestSharedPage',
            path: sharedResultsLinks.personalityTestResults(token),
            isExact: true,
            title: phrases.personalityTest,
            render: () => (
                <TrackPageView
                    name="Results shared page"
                    category={PAGE_CATEGORIES.SHARED_RESULTS}
                >
                    <TabWrapper>
                        <CandidatePersonalityTestPage
                            jobPositionId={jobPositionId}
                            userId={userId}
                        />
                    </TabWrapper>
                </TrackPageView>
            )
        },
        logicTest: {
            id: 'logicTestSharedPage',
            path: sharedResultsLinks.logicTestResults(token),
            isExact: true,
            title: phrases.logicTest,
            render: () => (
                <TrackPageView
                    name="Results shared page"
                    category={PAGE_CATEGORIES.SHARED_RESULTS}
                >
                    <TabWrapper>
                        <CandidateLogicTestPage
                            jobPositionId={jobPositionId}
                            userId={userId}
                        />
                    </TabWrapper>
                </TrackPageView>
            )
        },
        psychometricTest: {
            id: 'psychometricTestSharedPage',
            path: sharedResultsLinks.psychometricTestResults(token),
            isExact: true,
            title: phrases.psychometric,
            render: () => (
                <TrackPageView
                    name="Results shared page"
                    category={PAGE_CATEGORIES.SHARED_RESULTS}
                >
                    <TabWrapper>
                        <CandidateResults userId={userId} jobPositionId={jobPositionId} />
                    </TabWrapper>
                </TrackPageView>
            )
        },
        interview: {
            id: 'interviewSharedPage',
            path: sharedResultsLinks.interviewResults(token),
            isExact: true,
            title: phrases.interview,
            render: () => (
                <TrackPageView
                    name="Results shared page"
                    category={PAGE_CATEGORIES.SHARED_RESULTS}
                >
                    <TabWrapper>
                        <CandidateInterviewPage
                            jobPositionId={jobPositionId}
                            userId={userId}
                        />
                    </TabWrapper>
                </TrackPageView>
            )
        },
        codingTest: {
            id: 'codingTestSharedPage',
            path: sharedResultsLinks.codingTestResults(token),
            isExact: true,
            title: phrases.codingTest,
            render: () => (
                <TrackPageView
                    name="Results shared page"
                    category={PAGE_CATEGORIES.SHARED_RESULTS}
                >
                    <TabWrapper>
                        <CandidateCodingTestPage
                            jobPositionId={jobPositionId}
                            userId={userId}
                        />
                    </TabWrapper>
                </TrackPageView>
            )
        }
    };

    const removeTabs = useCallback(
        (tabs: CandidateTabFields[]) => {
            if (!(assessmentConfigurations.PERSONALITY_TEST && hasCombinedRoleFitBetaTest)) {
                tabs = tabs.filter(tab => tab.id !== candidateTabs.personalityTest.id);
            }
            if (!(assessmentConfigurations.LOGIC_TEST && hasCombinedRoleFitBetaTest)) {
                tabs = tabs.filter(tab => tab.id !== candidateTabs.logicTest.id);
            }
            if (!assessmentConfigurations.INTERVIEW) {
                tabs = tabs.filter(tab => tab.id !== candidateTabs.interview.id);
            }
            if (!assessmentConfigurations.CODING_TEST) {
                tabs = tabs.filter(tab => tab.id !== candidateTabs.codingTest.id);
            }
            return tabs;
        },
        [
            hasCombinedRoleFitBetaTest,
            assessmentConfigurations,
            candidateTabs.codingTest.id,
            candidateTabs.interview.id,
            candidateTabs.logicTest.id,
            candidateTabs.personalityTest.id
        ]
    );

    const navigationTabs = useMemo(() => {
        const tabs: CandidateTabFields[] = [];
        if (permissions.includes(ExternalResultsPermissionInput.EXTERNAL_RESULTS_ROLE_FIT)) {
            tabs.push(candidateTabs.roleFit);
        }
        if (
            permissions.includes(
                ExternalResultsPermissionInput.EXTERNAL_RESULTS_PERSONALITY_TEST
            )
        ) {
            tabs.push(candidateTabs.personalityTest);
        }
        if (permissions.includes(ExternalResultsPermissionInput.EXTERNAL_RESULTS_LOGIC_TEST)) {
            tabs.push(candidateTabs.logicTest);
        }
        if (
            [
                ExternalResultsPermissionInput.EXTERNAL_RESULTS_LOGIC_TEST,
                ExternalResultsPermissionInput.EXTERNAL_RESULTS_PERSONALITY_TEST
            ].every(permission => permissions.includes(permission)) &&
            !hasCombinedRoleFitBetaTest
        ) {
            tabs.push(candidateTabs.psychometricTest);
        }
        if (permissions.includes(ExternalResultsPermissionInput.EXTERNAL_RESULTS_INTERVIEWS)) {
            tabs.push(candidateTabs.interview);
        }
        if (
            permissions.includes(ExternalResultsPermissionInput.EXTERNAL_RESULTS_CODING_TEST)
        ) {
            tabs.push(candidateTabs.codingTest);
        }
        const filteredTabs = removeTabs(tabs);

        if (!loadingJobPosition) {
            return filteredTabs;
        }
        return tabs;
    }, [
        removeTabs,
        permissions,
        hasCombinedRoleFitBetaTest,
        loadingJobPosition,
        candidateTabs.codingTest,
        candidateTabs.interview,
        candidateTabs.logicTest,
        candidateTabs.personalityTest,
        candidateTabs.psychometricTest,
        candidateTabs.roleFit
    ]);

    const redirectPath = useMemo(() => {
        if (navigationTabs.length > 0) {
            return navigationTabs[0].path.toString();
        }
        return '';
    }, [navigationTabs]);

    return {
        navigationTabs,
        candidateName,
        redirectPath
    };
};
