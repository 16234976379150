import styled from 'styled-components';

import {BorderBaseDefaultRule} from '@/componentLibrary/tokens/customVariables';
import {
    BorderRadiusBaseMedium,
    BorderRadiusBaseRounded,
    ColorBaseGrey600,
    ColorBgActionSecondaryDisabled,
    ColorBgInset,
    ColorBorderActionSecondaryDefault,
    ColorBorderActionSecondaryHover,
    ColorBorderDefault,
    ColorBorderFocus,
    SpacingLarge,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

import {MenuItemWrapper} from './components/styled';

export const Wrapper = styled.div<{fullWidth: boolean; width: string}>`
    width: ${({fullWidth, width}) => (fullWidth ? '100%' : width)};
`;

const DisabledDropdownTriggerCSS = `
    cursor: not-allowed;
    background-color: ${ColorBgActionSecondaryDisabled};
    border-color: ${ColorBorderDefault};
    color: ${ColorBaseGrey600};

    &::placeholder {
        color: ${ColorBgInset};
    }

    &:focus-within {
        border-color: ${ColorBorderDefault};
    }

    *:hover {
        cursor: disabled;
    }

    span[role='status']:hover {
        cursor: inherit
    }

    &:hover {
        border-color: ${ColorBorderDefault};
    }
`;

const ReadOnlyTriggerCss = `
    *:hover {
        border-color: ${ColorBorderActionSecondaryDefault};
    }

    &:focus-within {
        border-color: ${ColorBorderActionSecondaryDefault};
    }
`;

export const DropdownTriggerWrapper = styled.div<{
    disabled?: boolean;
    readOnly?: boolean;
    loading?: boolean;
    multi?: boolean;
}>`
    display: flex;
    ${BorderBaseDefaultRule};
    align-items: center;
    flex-wrap: wrap;
    ${({multi}) =>
        multi
            ? `
            padding: ${SpacingXxsmall} 72px ${SpacingXxsmall} ${SpacingXxsmall};
            border-radius: ${BorderRadiusBaseMedium};
            gap: ${SpacingXxxsmall};
            min-height: 44px;
            `
            : `
            padding: 0 ${SpacingXlarge} 0 ${SpacingXsmall};
            border-radius: ${BorderRadiusBaseRounded};
            `}
    position: relative;

    span[role='status'] {
        height: ${SpacingLarge};

        &:hover {
            cursor: disabled !important;
        }
    }

    &:hover {
        border-color: ${ColorBorderActionSecondaryHover};
        cursor: pointer;
    }

    &:focus-within {
        border-color: ${ColorBorderFocus};
        outline: none;
    }

    ${({disabled}) => (disabled ? DisabledDropdownTriggerCSS : '')}
    ${({readOnly}) => (readOnly ? ReadOnlyTriggerCss : '')}
    ${({loading}) => (loading ? 'pointer-events: none' : '')}
`;

export const FluidTextAction = styled.div`
    position: absolute;
    right: ${SpacingXsmall};
`;

export const CreateCustomItemWrapper = styled(MenuItemWrapper)`
    justify-content: flex-start;
`;
