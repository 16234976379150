import {InfoBell} from '@/componentLibrary/components/Bells/InfoBell';
import {useLocale} from '@/containers/LanguageProvider/useLocale';

import {UserInfoBellProps} from './types';
import {getBellInfo} from './utils';

export function UserInfoBell({candidateReminderStatus}: UserInfoBellProps) {
    const locale: string = useLocale();

    const latestBouncedEmailInfo = getBellInfo({
        candidateReminderStatus,
        locale
    });

    if (!latestBouncedEmailInfo) {
        return null;
    }

    return (
        <InfoBell
            data-testid="user-info-bell"
            tooltip={latestBouncedEmailInfo.tooltipText}
            isWarning={latestBouncedEmailInfo.isInfoBellWarning}
            isDanger={!!latestBouncedEmailInfo.latestEmailBouncedAt}
        />
    );
}
