import {gql} from '@/api/types/__generated__';

export const GET_LOGIC_TEST_STATUS = gql(`
    query getLogicTestStatus($id: String!) {
        logicTestStatus(logicTestId: $id) {
            ...LogicTestStatusFields
        }
    }
`);

export const GET_LOGICAL_ABILITY_ESTIMATE_WITH_REPORT = gql(`
    query getLogicalAbilityEstimateWithReport($id: String!) {
        logicalAbilityEstimate(logicTestId: $id) {
            ...LogicalAbilityEstimateFields
        }
    }
`);

export const GET_LOGIC_TEST_DATA_COLLECTION_RESULT = gql(`
    query getLogicTestDataCollectionResult($logicTestId: String!) {
        logicalAbilityEstimate(logicTestId: $logicTestId) {
            id
            isUsedInDataCollection
            completionCode
            logicTestId
            mu
            sigma
        }
    }
`);

export const GET_VALIDATION_TEST_RESULT = gql(`
    query getValidationTestResult($id: String!, $withReport: Boolean!) {
        logicalAbilityEstimate(logicTestId: $id) {
            id
            validationTestResult {
                id
                mu
                sigma
                result
                testRequestedAt
                testCompletedAt
                report @include(if: $withReport) {
                    ...LogicTestReportFields
                }
            }
        }
    }
`);
