import {SsoButton} from '@/componentLibrary/components/buttons/SsoButton';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {SsoProvider} from '@/services/auth/sso/constants';

import {Props} from './types';

export function SsoSection(props: Props) {
    const {
        showGoogleRegistration,
        showMicrosoftRegistration,
        showOktaRegistration,
        signupMethod,
        loading,
        handleRegisterWithGoogle,
        handleRegisterWithMicrosoft,
        handleRegisterWithOkta
    } = props;

    return (
        <>
            {showGoogleRegistration && (
                <FormField>
                    <SsoButton
                        isSignup
                        isLoading={signupMethod === SsoProvider.GOOGLE && loading}
                        ssoProvider={SsoProvider.GOOGLE}
                        onClick={handleRegisterWithGoogle}
                    />
                </FormField>
            )}

            {showMicrosoftRegistration && (
                <FormField>
                    <SsoButton
                        isSignup
                        isLoading={signupMethod === SsoProvider.MICROSOFT && loading}
                        ssoProvider={SsoProvider.MICROSOFT}
                        onClick={handleRegisterWithMicrosoft}
                    />
                </FormField>
            )}

            {showOktaRegistration && (
                <FormField>
                    <SsoButton
                        isSignup
                        isLoading={signupMethod === SsoProvider.OKTA && loading}
                        ssoProvider={SsoProvider.OKTA}
                        onClick={handleRegisterWithOkta}
                    />
                </FormField>
            )}
        </>
    );
}
