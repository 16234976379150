import {useHistory, useLocation} from 'react-router-dom';

import {useLogicTestStatus} from '@/api/logicTestIrt/useLogicTestStatus';
import {useSaveAnswerForLogicTest} from '@/api/logicTestIrt/useSaveAnswerForLogicTest';
import {LogicTestStatus} from '@/api/types/__generated__/graphql';
import {LogicTestManager} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTestManager';
import {LogicTestProps} from '@/pages/LogicTestIrt/pages/LogicTest/types';

export function LogicTest({logicTestId, isValidationTest, goBack}: LogicTestProps) {
    const {
        logicTestStatus: latestFetchedLogicTestStatus,
        loading: loadingStatus,
        called,
        refetch
    } = useLogicTestStatus(logicTestId);
    const [doSaveAnswer, {loading: savingAnswer}] = useSaveAnswerForLogicTest(logicTestId);
    const history = useHistory();
    const location = useLocation();

    return latestFetchedLogicTestStatus ? (
        <LogicTestManager
            history={history}
            location={location}
            loadingStatus={loadingStatus || !called}
            doSaveAnswer={doSaveAnswer}
            savingAnswer={savingAnswer}
            latestFetchedLogicTestStatus={latestFetchedLogicTestStatus as LogicTestStatus}
            goBack={goBack}
            doFetchNewStatus={refetch}
            isValidationTest={isValidationTest}
        />
    ) : null;
}
