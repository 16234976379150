import styled from 'styled-components';

import {H3} from '@/componentLibrary/components/typography';
import {SpacingMedium, SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    margin: 0 auto ${SpacingMedium};
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    gap: ${SpacingMedium};

    @media only screen and (min-width: 1040px) {
        width: 1000px;
    }
`;

export const NoOrganizationsTitle = styled(H3)`
    text-align: center;
`;

export const InnerWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: ${SpacingXsmall};
`;

export const DescriptionWrapper = styled.div`
    padding-top: ${SpacingMedium};
    padding-bottom: 80px;
    text-align: center;

    @media (min-width: 768px) {
        width: 368px;
        margin: 0 auto;
    }
`;
