import styled from 'styled-components';

import {H4} from '@/componentLibrary/components/typography';
import {BorderBaseDefaultRule} from '@/componentLibrary/tokens/customVariables';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderRadiusBaseLarge,
    BorderRadiusBaseSmall,
    ColorBgDefault,
    ColorBgSubtle,
    SpacingLarge,
    SpacingSmall,
    SpacingXlarge
} from '@/componentLibrary/tokens/variables';

export const PageWrap = styled.div`
    display: grid;
    justify-items: center;
    align-items: baseline;
    height: 100vh;
    background-color: ${ColorBgSubtle};
`;

export const CenteredPageWrap = styled(PageWrap)`
    align-items: center;
    height: calc(100vh - 76px);
`;

export const Main = styled.div`
    padding-top: ${SpacingLarge};
    width: 560px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.small}) {
        width: 370px;
    }
`;

export const WhiteBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${SpacingLarge};
    border-radius: ${BorderRadiusBaseLarge};
    background-color: ${ColorBgDefault};
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${SpacingLarge} 0;
`;

export const PlanBox = styled(H4)`
    ${BorderBaseDefaultRule}
    border-radius: ${BorderRadiusBaseSmall};
    padding: ${SpacingSmall};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PositionsWrap = styled.div`
    margin-top: ${SpacingXlarge};
`;
