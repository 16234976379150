import styled from 'styled-components';

import {
    BorderRadiusBaseMedium,
    ColorBgDefault,
    SpacingSmall,
    SpacingXlarge,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

import {P2} from '../../typography';
import {MARKER_SIZE, MARKER_TRANSFORM} from './constants';
import {ToggleWrapperProps} from './types';

export const ToggleWrapper = styled.label<ToggleWrapperProps>`
    display: flex;
    align-items: center;
    width: fit-content;

    &,
    & label,
    & ${P2} {
        cursor: ${({$disabled}) => ($disabled ? 'not-allowed' : 'pointer')};
    }

    & ${P2} {
        user-select: none;
        ${({$labelLeft}) =>
            `margin: 0 ${$labelLeft ? SpacingXsmall : 0} 0 ${$labelLeft ? 0 : SpacingXsmall};`}
    }
`;

export const Marker = styled.label<{$color: string}>`
    position: relative;
    width: ${SpacingXlarge};
    min-width: ${SpacingXlarge};
    height: ${SpacingSmall};
    border-radius: ${BorderRadiusBaseMedium};
    background: ${({$color}) => $color};
    transition: all 0.3s;

    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        left: 1px;
        top: 1px;
        width: ${MARKER_SIZE}px;
        height: ${MARKER_SIZE}px;
        background: ${ColorBgDefault};
        border-radius: 50%;
        transition: transform 0.3s;
    }
`;

export const Input = styled.input`
    opacity: 0;
    width: 0;

    &:checked ~ ${Marker} {
        &::before {
            transform: translateX(${MARKER_TRANSFORM}px);
        }
    }
`;
