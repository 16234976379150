import {gql} from '@/api/types/__generated__';

export const GET_PUBLIC_JOB_POSITION = gql(`
    query getPublicJobPosition($jobPositionId: Int!) {
        publicJobPosition(jobPositionId: $jobPositionId) {
            jobPositionId
            name
            publicPageTitle
            publicPageInstructions
            organizationId
            organizationName
            organizationLogo
            useUnregisteredCandidateFlow
            publicPageOrganizationNameEnabled
            publicPagePrivacyNoticeEnabled
            publicPageColorHeaderEnabled
            publicPageLogoEnabled
            publicPageColorHeader
            publicPageOrganizationName
            organizationExternalPrivacyPolicyUrl
        }
    }
`);
