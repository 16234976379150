import {Spinner} from '@/componentLibrary/components/spinners';
import {LoadingWrapper} from './styled';

export function LoadingSpinner() {
    return (
        <LoadingWrapper>
            <Spinner />
        </LoadingWrapper>
    );
}
