import {LeftActionGroupWrapper, RightActionGroupWrapper, Wrapper} from './styled';
import {ModalFooterProps} from './types';

export const ModalFooter = ({
    primaryAction,
    leftSecondaryAction,
    rightSecondaryAction
}: ModalFooterProps) => {
    if (!primaryAction && !leftSecondaryAction && !rightSecondaryAction) {
        return null;
    }

    if (primaryAction && !leftSecondaryAction && !rightSecondaryAction) {
        return (
            <Wrapper>
                <RightActionGroupWrapper>{primaryAction}</RightActionGroupWrapper>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <LeftActionGroupWrapper>
                {Boolean(leftSecondaryAction) && leftSecondaryAction}
            </LeftActionGroupWrapper>
            <RightActionGroupWrapper>
                {Boolean(rightSecondaryAction) && rightSecondaryAction}
                {Boolean(primaryAction) && primaryAction}
            </RightActionGroupWrapper>
        </Wrapper>
    );
};
