import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingMedium, SpacingXxlarge} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    max-width: 425px;
    margin-bottom: ${SpacingXxlarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        width: 100%;
        max-width: none;
    }
`;
export const ExtendedP2 = styled(P2)`
    width: 100%;
    margin-bottom: ${SpacingMedium};
`;
