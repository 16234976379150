import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_AUTHENTICATED_ORGANIZATION_QUERY} from '@/api/organizations/queries';

export function useOrganization() {
    const {data, error, loading, refetch, client} = useQuery(
        GET_AUTHENTICATED_ORGANIZATION_QUERY
    );

    const organization = useMemo(() => data?.authenticatedOrganization ?? null, [data]);

    return {loading, error, organization, refetch, client};
}
