import {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';

import {EVENTS} from '@/services/segment/constants';
import {useTrackEvent} from '@/services/segment/trackEvent';

import messages from './messages';

export function useLinkedinFeedbackLogic() {
    const phrases = useLinkedinFeedbackPhrases();
    const trackEvent = useTrackEvent();
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

    const onFeedback = useCallback(
        (type: string) => {
            return () => {
                trackEvent(EVENTS.RECOMMENDATION_APPLICATION_FEEDBACK, {
                    type
                });
                setFeedbackSubmitted(true);
            };
        },
        [trackEvent]
    );

    return {...phrases, onFeedback, feedbackSubmitted};
}

function useLinkedinFeedbackPhrases() {
    const intl = useIntl();
    const titleText = intl.formatMessage(messages.title);
    const bodyText = intl.formatMessage(messages.body);
    const labelText = intl.formatMessage(messages.label);
    const noProfileText = intl.formatMessage(messages.noProfile);
    const dontWantToShareText = intl.formatMessage(messages.dontWantToShare);
    const previousText = intl.formatMessage(messages.previous);
    const okText = intl.formatMessage(messages.ok);
    const successTitleText = intl.formatMessage(messages.successTitle);
    const successBodyText = intl.formatMessage(messages.successBody);

    return {
        titleText,
        bodyText,
        labelText,
        noProfileText,
        dontWantToShareText,
        previousText,
        okText,
        successTitleText,
        successBodyText
    };
}
