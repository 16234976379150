import {FunctionComponent, ReactNode} from 'react';
import styled from 'styled-components';

import {Base} from '@/componentLibrary/components/typography/Base';
import {HeadingH2} from '@/componentLibrary/tokens/typography';
import {SpacingLarge, SpacingXxsmall} from '@/componentLibrary/tokens/variables';
import {roundValue} from '@/utils/misc';
import {isDefined} from '@/utils/typeGuards/isDefined';

type ScorePercentProps = {
    score?: number;
    missingResults: boolean;
    inline?: boolean;
    children?: ReactNode;
    withMargin?: boolean;
    isPercentage?: boolean;
    withGap?: boolean;
};

export const ScorePercent = ({
    score,
    missingResults,
    isPercentage = false,
    inline = false,
    children,
    withMargin = true,
    withGap = false
}: ScorePercentProps) => {
    const ScoreComponent: FunctionComponent = inline ? Base : Score;
    const scoreToShow =
        missingResults || !isDefined(score)
            ? '--'
            : roundValue(isPercentage ? score : score * 100);

    return (
        <Wrapper $inline={inline} $withMargin={withMargin} $withGap={withGap}>
            <ScoreComponent>{scoreToShow}</ScoreComponent>
            {inline ? <span>%</span> : <PercentSign>%</PercentSign>}
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.div<{$inline: boolean; $withMargin: boolean; $withGap: boolean}>`
    display: flex;
    align-items: ${({$inline}) => ($inline ? 'center' : 'end')};
    ${({$withMargin}) => ($withMargin ? `margin-block: ${SpacingLarge}` : '')};
    ${({$withGap}) => ($withGap ? `gap: ${SpacingXxsmall}` : '')};

    & *:first-child {
        white-space: nowrap;
    }
`;

const Score = styled.div`
    font-size: 110px;
    line-height: 86px;
    font-weight: 400;
`;

const PercentSign = styled.div`
    ${HeadingH2}
    line-height: 1;
`;
