import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.authenticateCandidate.title'
    },
    tos: {
        id: 'app.pages.authenticateCandidate.tos_short'
    },
    disclaimer: {
        id: 'app.pages.authenticateCandidate.disclaimer'
    },
    continue: {
        id: 'app.pages.authenticateCandidate.continue'
    },
    mandatoryConsentCheckboxCustomLabel: {
        id: 'consent.PRIVACY_POLICY_RECRUITMENT_SERVICES.newShortDescription'
    },
    candidateServicesLabelDescription: {
        id: 'consent.PRIVACY_POLICY_CANDIDATE_SERVICES.newShortDescription.description'
    },
    alvaTermsOfServiceTitle: {
        id: 'consent.ALVA_LABS_TERMS_OF_SERVICE.title'
    },
    privacyPolicyTitle: {
        id: 'consent.PRIVACY_POLICY.title'
    }
});
