import {LanguageToggle} from '@/componentLibrary/components/Dropdowns/LanguageToggle';
import {ColorBaseGrey000} from '@/componentLibrary/tokens/variables';
import {useIsMobileDevice} from '@/utils/useIsMobileDevice';

import {
    ColorHeader,
    ContentWrapper,
    DescriptionWrapper,
    FormWrapper,
    InstructionWrapper,
    LanguageToggleWrapper,
    LeftHalfWrapper,
    Logo,
    LogoWrapper,
    RightHalfWrapper
} from './styles';
import {InstructionLayoutProps} from './types';

export const InstructionLayout: React.FC<InstructionLayoutProps> = ({
    children,
    instruction,
    loading = false,
    colorHeader,
    colorHeaderEnabled
}) => {
    const isMobileDevice = useIsMobileDevice();

    if (loading) {
        return null;
    }
    return (
        <ContentWrapper>
            <LeftHalfWrapper>
                {isMobileDevice ? (
                    <LanguageToggleWrapper>
                        <LanguageToggle />
                    </LanguageToggleWrapper>
                ) : null}
                {colorHeaderEnabled ? (
                    <ColorHeader color={colorHeader ? colorHeader : ColorBaseGrey000} />
                ) : null}
                <FormWrapper>{children}</FormWrapper>
            </LeftHalfWrapper>
            <RightHalfWrapper>
                <DescriptionWrapper>
                    {!isMobileDevice ? (
                        <LanguageToggleWrapper>
                            <LanguageToggle />
                        </LanguageToggleWrapper>
                    ) : null}
                    <InstructionWrapper>{instruction}</InstructionWrapper>
                    <LogoWrapper>
                        <Logo />
                    </LogoWrapper>
                </DescriptionWrapper>
            </RightHalfWrapper>
        </ContentWrapper>
    );
};
