import {defineMessages} from 'react-intl';

export const personalityResultStrengthMessages = defineMessages({
    A_AVERAGE_O_AVERAGE: {
        id: 'personalityResult.A-AVERAGE:O-AVERAGE.strength'
    },
    A_AVERAGE_O_HIGH: {
        id: 'personalityResult.A-AVERAGE:O-HIGH.strength'
    },
    A_AVERAGE_O_LOW: {
        id: 'personalityResult.A-AVERAGE:O-LOW.strength'
    },
    A_HIGH_O_AVERAGE: {
        id: 'personalityResult.A-HIGH:O-AVERAGE.strength'
    },
    A_HIGH_O_HIGH: {
        id: 'personalityResult.A-HIGH:O-HIGH.strength'
    },
    A_HIGH_O_LOW: {
        id: 'personalityResult.A-HIGH:O-LOW.strength'
    },
    A_LOW_O_AVERAGE: {
        id: 'personalityResult.A-LOW:O-AVERAGE.strength'
    },
    A_LOW_O_HIGH: {
        id: 'personalityResult.A-LOW:O-HIGH.strength'
    },
    A_LOW_O_LOW: {
        id: 'personalityResult.A-LOW:O-LOW.strength'
    },
    C_AVERAGE_A_AVERAGE: {
        id: 'personalityResult.C-AVERAGE:A-AVERAGE.strength'
    },
    C_AVERAGE_A_HIGH: {
        id: 'personalityResult.C-AVERAGE:A-HIGH.strength'
    },
    C_AVERAGE_A_LOW: {
        id: 'personalityResult.C-AVERAGE:A-LOW.strength'
    },
    C_AVERAGE_E_AVERAGE: {
        id: 'personalityResult.C-AVERAGE:E-AVERAGE.strength'
    },
    C_AVERAGE_E_HIGH: {
        id: 'personalityResult.C-AVERAGE:E-HIGH.strength'
    },
    C_AVERAGE_E_LOW: {
        id: 'personalityResult.C-AVERAGE:E-LOW.strength'
    },
    C_AVERAGE_ES_AVERAGE: {
        id: 'personalityResult.C-AVERAGE:ES-AVERAGE.strength'
    },
    C_AVERAGE_ES_HIGH: {
        id: 'personalityResult.C-AVERAGE:ES-HIGH.strength'
    },
    C_AVERAGE_ES_LOW: {
        id: 'personalityResult.C-AVERAGE:ES-LOW.strength'
    },
    C_AVERAGE_O_AVERAGE: {
        id: 'personalityResult.C-AVERAGE:O-AVERAGE.strength'
    },
    C_AVERAGE_O_HIGH: {
        id: 'personalityResult.C-AVERAGE:O-HIGH.strength'
    },
    C_AVERAGE_O_LOW: {
        id: 'personalityResult.C-AVERAGE:O-LOW.strength'
    },
    C_HIGH_A_AVERAGE: {
        id: 'personalityResult.C-HIGH:A-AVERAGE.strength'
    },
    C_HIGH_A_HIGH: {
        id: 'personalityResult.C-HIGH:A-HIGH.strength'
    },
    C_HIGH_A_LOW: {
        id: 'personalityResult.C-HIGH:A-LOW.strength'
    },
    C_HIGH_E_AVERAGE: {
        id: 'personalityResult.C-HIGH:E-AVERAGE.strength'
    },
    C_HIGH_E_HIGH: {
        id: 'personalityResult.C-HIGH:E-HIGH.strength'
    },
    C_HIGH_E_LOW: {
        id: 'personalityResult.C-HIGH:E-LOW.strength'
    },
    C_HIGH_ES_AVERAGE: {
        id: 'personalityResult.C-HIGH:ES-AVERAGE.strength'
    },
    C_HIGH_ES_HIGH: {
        id: 'personalityResult.C-HIGH:ES-HIGH.strength'
    },
    C_HIGH_ES_LOW: {
        id: 'personalityResult.C-HIGH:ES-LOW.strength'
    },
    C_HIGH_O_AVERAGE: {
        id: 'personalityResult.C-HIGH:O-AVERAGE.strength'
    },
    C_HIGH_O_HIGH: {
        id: 'personalityResult.C-HIGH:O-HIGH.strength'
    },
    C_HIGH_O_LOW: {
        id: 'personalityResult.C-HIGH:O-LOW.strength'
    },
    C_LOW_A_AVERAGE: {
        id: 'personalityResult.C-LOW:A-AVERAGE.strength'
    },
    C_LOW_A_HIGH: {
        id: 'personalityResult.C-LOW:A-HIGH.strength'
    },
    C_LOW_A_LOW: {
        id: 'personalityResult.C-LOW:A-LOW.strength'
    },
    C_LOW_E_AVERAGE: {
        id: 'personalityResult.C-LOW:E-AVERAGE.strength'
    },
    C_LOW_E_HIGH: {
        id: 'personalityResult.C-LOW:E-HIGH.strength'
    },
    C_LOW_E_LOW: {
        id: 'personalityResult.C-LOW:E-LOW.strength'
    },
    C_LOW_ES_AVERAGE: {
        id: 'personalityResult.C-LOW:ES-AVERAGE.strength'
    },
    C_LOW_ES_HIGH: {
        id: 'personalityResult.C-LOW:ES-HIGH.strength'
    },
    C_LOW_ES_LOW: {
        id: 'personalityResult.C-LOW:ES-LOW.strength'
    },
    C_LOW_O_AVERAGE: {
        id: 'personalityResult.C-LOW:O-AVERAGE.strength'
    },
    C_LOW_O_HIGH: {
        id: 'personalityResult.C-LOW:O-HIGH.strength'
    },
    C_LOW_O_LOW: {
        id: 'personalityResult.C-LOW:O-LOW.strength'
    },
    E_AVERAGE_A_AVERAGE: {
        id: 'personalityResult.E-AVERAGE:A-AVERAGE.strength'
    },
    E_AVERAGE_A_HIGH: {
        id: 'personalityResult.E-AVERAGE:A-HIGH.strength'
    },
    E_AVERAGE_A_LOW: {
        id: 'personalityResult.E-AVERAGE:A-LOW.strength'
    },
    E_AVERAGE_O_AVERAGE: {
        id: 'personalityResult.E-AVERAGE:O-AVERAGE.strength'
    },
    E_AVERAGE_O_HIGH: {
        id: 'personalityResult.E-AVERAGE:O-HIGH.strength'
    },
    E_AVERAGE_O_LOW: {
        id: 'personalityResult.E-AVERAGE:O-LOW.strength'
    },
    E_HIGH_A_AVERAGE: {
        id: 'personalityResult.E-HIGH:A-AVERAGE.strength'
    },
    E_HIGH_A_HIGH: {
        id: 'personalityResult.E-HIGH:A-HIGH.strength'
    },
    E_HIGH_A_LOW: {
        id: 'personalityResult.E-HIGH:A-LOW.strength'
    },
    E_HIGH_O_AVERAGE: {
        id: 'personalityResult.E-HIGH:O-AVERAGE.strength'
    },
    E_HIGH_O_HIGH: {
        id: 'personalityResult.E-HIGH:O-HIGH.strength'
    },
    E_HIGH_O_LOW: {
        id: 'personalityResult.E-HIGH:O-LOW.strength'
    },
    E_LOW_A_AVERAGE: {
        id: 'personalityResult.E-LOW:A-AVERAGE.strength'
    },
    E_LOW_A_HIGH: {
        id: 'personalityResult.E-LOW:A-HIGH.strength'
    },
    E_LOW_A_LOW: {
        id: 'personalityResult.E-LOW:A-LOW.strength'
    },
    E_LOW_O_AVERAGE: {
        id: 'personalityResult.E-LOW:O-AVERAGE.strength'
    },
    E_LOW_O_HIGH: {
        id: 'personalityResult.E-LOW:O-HIGH.strength'
    },
    E_LOW_O_LOW: {
        id: 'personalityResult.E-LOW:O-LOW.strength'
    },
    ES_AVERAGE_A_AVERAGE: {
        id: 'personalityResult.ES-AVERAGE:A-AVERAGE.strength'
    },
    ES_AVERAGE_A_HIGH: {
        id: 'personalityResult.ES-AVERAGE:A-HIGH.strength'
    },
    ES_AVERAGE_A_LOW: {
        id: 'personalityResult.ES-AVERAGE:A-LOW.strength'
    },
    ES_AVERAGE_E_AVERAGE: {
        id: 'personalityResult.ES-AVERAGE:E-AVERAGE.strength'
    },
    ES_AVERAGE_E_HIGH: {
        id: 'personalityResult.ES-AVERAGE:E-HIGH.strength'
    },
    ES_AVERAGE_E_LOW: {
        id: 'personalityResult.ES-AVERAGE:E-LOW.strength'
    },
    ES_AVERAGE_O_AVERAGE: {
        id: 'personalityResult.ES-AVERAGE:O-AVERAGE.strength'
    },
    ES_AVERAGE_O_HIGH: {
        id: 'personalityResult.ES-AVERAGE:O-HIGH.strength'
    },
    ES_AVERAGE_O_LOW: {
        id: 'personalityResult.ES-AVERAGE:O-LOW.strength'
    },
    ES_HIGH_A_AVERAGE: {
        id: 'personalityResult.ES-HIGH:A-AVERAGE.strength'
    },
    ES_HIGH_A_HIGH: {
        id: 'personalityResult.ES-HIGH:A-HIGH.strength'
    },
    ES_HIGH_A_LOW: {
        id: 'personalityResult.ES-HIGH:A-LOW.strength'
    },
    ES_HIGH_E_AVERAGE: {
        id: 'personalityResult.ES-HIGH:E-AVERAGE.strength'
    },
    ES_HIGH_E_HIGH: {
        id: 'personalityResult.ES-HIGH:E-HIGH.strength'
    },
    ES_HIGH_E_LOW: {
        id: 'personalityResult.ES-HIGH:E-LOW.strength'
    },
    ES_HIGH_O_AVERAGE: {
        id: 'personalityResult.ES-HIGH:O-AVERAGE.strength'
    },
    ES_HIGH_O_HIGH: {
        id: 'personalityResult.ES-HIGH:O-HIGH.strength'
    },
    ES_HIGH_O_LOW: {
        id: 'personalityResult.ES-HIGH:O-LOW.strength'
    },
    ES_LOW_A_AVERAGE: {
        id: 'personalityResult.ES-LOW:A-AVERAGE.strength'
    },
    ES_LOW_A_HIGH: {
        id: 'personalityResult.ES-LOW:A-HIGH.strength'
    },
    ES_LOW_A_LOW: {
        id: 'personalityResult.ES-LOW:A-LOW.strength'
    },
    ES_LOW_E_AVERAGE: {
        id: 'personalityResult.ES-LOW:E-AVERAGE.strength'
    },
    ES_LOW_E_HIGH: {
        id: 'personalityResult.ES-LOW:E-HIGH.strength'
    },
    ES_LOW_E_LOW: {
        id: 'personalityResult.ES-LOW:E-LOW.strength'
    },
    ES_LOW_O_AVERAGE: {
        id: 'personalityResult.ES-LOW:O-AVERAGE.strength'
    },
    ES_LOW_O_HIGH: {
        id: 'personalityResult.ES-LOW:O-HIGH.strength'
    },
    ES_LOW_O_LOW: {
        id: 'personalityResult.ES-LOW:O-LOW.strength'
    }
});

export const personalityResultChallengeMessages = defineMessages({
    A_AVERAGE_O_AVERAGE: {
        id: 'personalityResult.A-AVERAGE:O-AVERAGE.challenge'
    },
    A_AVERAGE_O_HIGH: {
        id: 'personalityResult.A-AVERAGE:O-HIGH.challenge'
    },
    A_AVERAGE_O_LOW: {
        id: 'personalityResult.A-AVERAGE:O-LOW.challenge'
    },
    A_HIGH_O_AVERAGE: {
        id: 'personalityResult.A-HIGH:O-AVERAGE.challenge'
    },
    A_HIGH_O_HIGH: {
        id: 'personalityResult.A-HIGH:O-HIGH.challenge'
    },
    A_HIGH_O_LOW: {
        id: 'personalityResult.A-HIGH:O-LOW.challenge'
    },
    A_LOW_O_AVERAGE: {
        id: 'personalityResult.A-LOW:O-AVERAGE.challenge'
    },
    A_LOW_O_HIGH: {
        id: 'personalityResult.A-LOW:O-HIGH.challenge'
    },
    A_LOW_O_LOW: {
        id: 'personalityResult.A-LOW:O-LOW.challenge'
    },
    C_AVERAGE_A_AVERAGE: {
        id: 'personalityResult.C-AVERAGE:A-AVERAGE.challenge'
    },
    C_AVERAGE_A_HIGH: {
        id: 'personalityResult.C-AVERAGE:A-HIGH.challenge'
    },
    C_AVERAGE_A_LOW: {
        id: 'personalityResult.C-AVERAGE:A-LOW.challenge'
    },
    C_AVERAGE_E_AVERAGE: {
        id: 'personalityResult.C-AVERAGE:E-AVERAGE.challenge'
    },
    C_AVERAGE_E_HIGH: {
        id: 'personalityResult.C-AVERAGE:E-HIGH.challenge'
    },
    C_AVERAGE_E_LOW: {
        id: 'personalityResult.C-AVERAGE:E-LOW.challenge'
    },
    C_AVERAGE_ES_AVERAGE: {
        id: 'personalityResult.C-AVERAGE:ES-AVERAGE.challenge'
    },
    C_AVERAGE_ES_HIGH: {
        id: 'personalityResult.C-AVERAGE:ES-HIGH.challenge'
    },
    C_AVERAGE_ES_LOW: {
        id: 'personalityResult.C-AVERAGE:ES-LOW.challenge'
    },
    C_AVERAGE_O_AVERAGE: {
        id: 'personalityResult.C-AVERAGE:O-AVERAGE.challenge'
    },
    C_AVERAGE_O_HIGH: {
        id: 'personalityResult.C-AVERAGE:O-HIGH.challenge'
    },
    C_AVERAGE_O_LOW: {
        id: 'personalityResult.C-AVERAGE:O-LOW.challenge'
    },
    C_HIGH_A_AVERAGE: {
        id: 'personalityResult.C-HIGH:A-AVERAGE.challenge'
    },
    C_HIGH_A_HIGH: {
        id: 'personalityResult.C-HIGH:A-HIGH.challenge'
    },
    C_HIGH_A_LOW: {
        id: 'personalityResult.C-HIGH:A-LOW.challenge'
    },
    C_HIGH_E_AVERAGE: {
        id: 'personalityResult.C-HIGH:E-AVERAGE.challenge'
    },
    C_HIGH_E_HIGH: {
        id: 'personalityResult.C-HIGH:E-HIGH.challenge'
    },
    C_HIGH_E_LOW: {
        id: 'personalityResult.C-HIGH:E-LOW.challenge'
    },
    C_HIGH_ES_AVERAGE: {
        id: 'personalityResult.C-HIGH:ES-AVERAGE.challenge'
    },
    C_HIGH_ES_HIGH: {
        id: 'personalityResult.C-HIGH:ES-HIGH.challenge'
    },
    C_HIGH_ES_LOW: {
        id: 'personalityResult.C-HIGH:ES-LOW.challenge'
    },
    C_HIGH_O_AVERAGE: {
        id: 'personalityResult.C-HIGH:O-AVERAGE.challenge'
    },
    C_HIGH_O_HIGH: {
        id: 'personalityResult.C-HIGH:O-HIGH.challenge'
    },
    C_HIGH_O_LOW: {
        id: 'personalityResult.C-HIGH:O-LOW.challenge'
    },
    C_LOW_A_AVERAGE: {
        id: 'personalityResult.C-LOW:A-AVERAGE.challenge'
    },
    C_LOW_A_HIGH: {
        id: 'personalityResult.C-LOW:A-HIGH.challenge'
    },
    C_LOW_A_LOW: {
        id: 'personalityResult.C-LOW:A-LOW.challenge'
    },
    C_LOW_E_AVERAGE: {
        id: 'personalityResult.C-LOW:E-AVERAGE.challenge'
    },
    C_LOW_E_HIGH: {
        id: 'personalityResult.C-LOW:E-HIGH.challenge'
    },
    C_LOW_E_LOW: {
        id: 'personalityResult.C-LOW:E-LOW.challenge'
    },
    C_LOW_ES_AVERAGE: {
        id: 'personalityResult.C-LOW:ES-AVERAGE.challenge'
    },
    C_LOW_ES_HIGH: {
        id: 'personalityResult.C-LOW:ES-HIGH.challenge'
    },
    C_LOW_ES_LOW: {
        id: 'personalityResult.C-LOW:ES-LOW.challenge'
    },
    C_LOW_O_AVERAGE: {
        id: 'personalityResult.C-LOW:O-AVERAGE.challenge'
    },
    C_LOW_O_HIGH: {
        id: 'personalityResult.C-LOW:O-HIGH.challenge'
    },
    C_LOW_O_LOW: {
        id: 'personalityResult.C-LOW:O-LOW.challenge'
    },
    E_AVERAGE_A_AVERAGE: {
        id: 'personalityResult.E-AVERAGE:A-AVERAGE.challenge'
    },
    E_AVERAGE_A_HIGH: {
        id: 'personalityResult.E-AVERAGE:A-HIGH.challenge'
    },
    E_AVERAGE_A_LOW: {
        id: 'personalityResult.E-AVERAGE:A-LOW.challenge'
    },
    E_AVERAGE_O_AVERAGE: {
        id: 'personalityResult.E-AVERAGE:O-AVERAGE.challenge'
    },
    E_AVERAGE_O_HIGH: {
        id: 'personalityResult.E-AVERAGE:O-HIGH.challenge'
    },
    E_AVERAGE_O_LOW: {
        id: 'personalityResult.E-AVERAGE:O-LOW.challenge'
    },
    E_HIGH_A_AVERAGE: {
        id: 'personalityResult.E-HIGH:A-AVERAGE.challenge'
    },
    E_HIGH_A_HIGH: {
        id: 'personalityResult.E-HIGH:A-HIGH.challenge'
    },
    E_HIGH_A_LOW: {
        id: 'personalityResult.E-HIGH:A-LOW.challenge'
    },
    E_HIGH_O_AVERAGE: {
        id: 'personalityResult.E-HIGH:O-AVERAGE.challenge'
    },
    E_HIGH_O_HIGH: {
        id: 'personalityResult.E-HIGH:O-HIGH.challenge'
    },
    E_HIGH_O_LOW: {
        id: 'personalityResult.E-HIGH:O-LOW.challenge'
    },
    E_LOW_A_AVERAGE: {
        id: 'personalityResult.E-LOW:A-AVERAGE.challenge'
    },
    E_LOW_A_HIGH: {
        id: 'personalityResult.E-LOW:A-HIGH.challenge'
    },
    E_LOW_A_LOW: {
        id: 'personalityResult.E-LOW:A-LOW.challenge'
    },
    E_LOW_O_AVERAGE: {
        id: 'personalityResult.E-LOW:O-AVERAGE.challenge'
    },
    E_LOW_O_HIGH: {
        id: 'personalityResult.E-LOW:O-HIGH.challenge'
    },
    E_LOW_O_LOW: {
        id: 'personalityResult.E-LOW:O-LOW.challenge'
    },
    ES_AVERAGE_A_AVERAGE: {
        id: 'personalityResult.ES-AVERAGE:A-AVERAGE.challenge'
    },
    ES_AVERAGE_A_HIGH: {
        id: 'personalityResult.ES-AVERAGE:A-HIGH.challenge'
    },
    ES_AVERAGE_A_LOW: {
        id: 'personalityResult.ES-AVERAGE:A-LOW.challenge'
    },
    ES_AVERAGE_E_AVERAGE: {
        id: 'personalityResult.ES-AVERAGE:E-AVERAGE.challenge'
    },
    ES_AVERAGE_E_HIGH: {
        id: 'personalityResult.ES-AVERAGE:E-HIGH.challenge'
    },
    ES_AVERAGE_E_LOW: {
        id: 'personalityResult.ES-AVERAGE:E-LOW.challenge'
    },
    ES_AVERAGE_O_AVERAGE: {
        id: 'personalityResult.ES-AVERAGE:O-AVERAGE.challenge'
    },
    ES_AVERAGE_O_HIGH: {
        id: 'personalityResult.ES-AVERAGE:O-HIGH.challenge'
    },
    ES_AVERAGE_O_LOW: {
        id: 'personalityResult.ES-AVERAGE:O-LOW.challenge'
    },
    ES_HIGH_A_AVERAGE: {
        id: 'personalityResult.ES-HIGH:A-AVERAGE.challenge'
    },
    ES_HIGH_A_HIGH: {
        id: 'personalityResult.ES-HIGH:A-HIGH.challenge'
    },
    ES_HIGH_A_LOW: {
        id: 'personalityResult.ES-HIGH:A-LOW.challenge'
    },
    ES_HIGH_E_AVERAGE: {
        id: 'personalityResult.ES-HIGH:E-AVERAGE.challenge'
    },
    ES_HIGH_E_HIGH: {
        id: 'personalityResult.ES-HIGH:E-HIGH.challenge'
    },
    ES_HIGH_E_LOW: {
        id: 'personalityResult.ES-HIGH:E-LOW.challenge'
    },
    ES_HIGH_O_AVERAGE: {
        id: 'personalityResult.ES-HIGH:O-AVERAGE.challenge'
    },
    ES_HIGH_O_HIGH: {
        id: 'personalityResult.ES-HIGH:O-HIGH.challenge'
    },
    ES_HIGH_O_LOW: {
        id: 'personalityResult.ES-HIGH:O-LOW.challenge'
    },
    ES_LOW_A_AVERAGE: {
        id: 'personalityResult.ES-LOW:A-AVERAGE.challenge'
    },
    ES_LOW_A_HIGH: {
        id: 'personalityResult.ES-LOW:A-HIGH.challenge'
    },
    ES_LOW_A_LOW: {
        id: 'personalityResult.ES-LOW:A-LOW.challenge'
    },
    ES_LOW_E_AVERAGE: {
        id: 'personalityResult.ES-LOW:E-AVERAGE.challenge'
    },
    ES_LOW_E_HIGH: {
        id: 'personalityResult.ES-LOW:E-HIGH.challenge'
    },
    ES_LOW_E_LOW: {
        id: 'personalityResult.ES-LOW:E-LOW.challenge'
    },
    ES_LOW_O_AVERAGE: {
        id: 'personalityResult.ES-LOW:O-AVERAGE.challenge'
    },
    ES_LOW_O_HIGH: {
        id: 'personalityResult.ES-LOW:O-HIGH.challenge'
    },
    ES_LOW_O_LOW: {
        id: 'personalityResult.ES-LOW:O-LOW.challenge'
    }
});
