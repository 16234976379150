import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Banner} from '@/componentLibrary/components/banners/Banner';
import {SmallSecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {ExternalLink} from '@/componentLibrary/components/links';
import {P2} from '@/componentLibrary/components/typography';
import {SpacingXlarge} from '@/componentLibrary/tokens/variables';
import {LINKS} from '@/links';

import {useCandidateServicesConsentBannerLogic} from './logic';
import messages from './messages';

type Props = {
    organizationName?: string;
};

export function CandidateServicesConsentBanner({organizationName}: Props) {
    const intl = useIntl();
    const title = intl.formatMessage(messages.title, {companyName: organizationName});
    const description = intl.formatMessage(messages.description, {
        privacyPolicyUrl: LINKS.privacyPolicy
    });
    const {acceptButtonIsDisabled, accepting, registerUser} =
        useCandidateServicesConsentBannerLogic();

    return (
        <Wrapper data-testid="candidate-services-consent-banner">
            <Banner
                title={title}
                customAction={
                    <SmallSecondaryButton
                        disabled={acceptButtonIsDisabled}
                        isLoading={accepting}
                        onClick={registerUser}
                        data-testid="accept-candidate-services-consent-button"
                    >
                        {intl.formatMessage(messages.accept)}
                    </SmallSecondaryButton>
                }
            >
                <P2>
                    {description}
                    <ExternalLink url={LINKS.candidateServices}>
                        {intl.formatMessage(messages.privacyPolicyTitle)}
                    </ExternalLink>
                </P2>
            </Banner>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin: ${SpacingXlarge} 0;
`;
