import {defineMessages} from 'react-intl';

export default defineMessages({
    whatMeasureTitle: {
        id: 'app.logicTestReport.whatMeasure'
    },
    technicalManualTitle: {
        id: 'app.logicTestReport.technicalManual'
    },
    validityTitle: {
        id: 'app.logicTestReport.validity'
    },
    howToInterpretTitle: {
        id: 'app.logicTestReport.howToInterpret'
    },
    logicalAbilityTitle: {
        id: 'app.logicTestReport.logicalAbility'
    }
});
