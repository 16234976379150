import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.assessment.jobPositions.title'
    },
    all: {
        id: 'app.assessment.jobPositions.tabs.all'
    },
    archive: {
        id: 'app.assessment.jobPositions.tabs.archive'
    }
});

export const jobPositionStatusMessages = defineMessages({
    ALL: {
        id: 'app.assessment.jobPositions.ALL'
    },
    OPEN: {
        id: 'app.assessment.jobPositions.OPEN'
    },
    UNLISTED: {
        id: 'app.assessment.jobPositions.UNLISTED'
    },
    ARCHIVED: {
        id: 'app.assessment.jobPositions.ARCHIVED'
    }
});

export const jobPositionStateMessages = defineMessages({
    ALL: {
        id: 'app.assessment.jobPositions.ALL'
    },
    ACTIVE: {
        id: 'app.assessment.jobPositions.state.ACTIVE'
    },
    INACTIVE: {
        id: 'app.assessment.jobPositions.state.INACTIVE'
    },
    EXPIRED: {
        id: 'app.assessment.jobPositions.state.EXPIRED'
    }
});
