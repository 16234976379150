import debounce from 'lodash.debounce';
import {useCallback, useEffect, useRef, useState} from 'react';

import {Item} from './types';

const EMPTY_STRING = '';
const SPACE = ' ';

export function useSearchMultiSelectModalLogic(
    selectedItems: Item[],
    items: Item[],
    onChange: (items: Item[], done?: boolean) => void,
    queryResults: Item[],
    onSearchKeyChange?: (searchKey: string) => void,
    withQuery?: boolean,
    queryLoading?: boolean
) {
    const [showSelectorModal, setShowSelectorModal] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<Item[]>([]);
    const [finalSelectedOptions, setFinalSelectedOptions] = useState<Item[]>([]);
    const [filterValue, setFilterValue] = useState(EMPTY_STRING);
    const isEmptyFilterValue = filterValue === EMPTY_STRING;
    const [showLoadingUntilDebounceIsTriggered, setShowLoadingUntilDebounceIsTriggered] =
        useState(false);

    useEffect(() => {
        setFinalSelectedOptions(selectedItems);
        setSelectedOptions(selectedItems);
    }, [selectedItems]);

    const onSelectorModalClose = () => {
        setShowSelectorModal(false);
        clearFilter();
        setSelectedOptions(selectedItems);
    };

    const onSelectorModalPrimaryAction = () => {
        setShowSelectorModal(false);
        clearFilter();
        setFinalSelectedOptions(selectedOptions);
        onChange(selectedOptions, true);
    };

    const clearFilter = useCallback(() => {
        setFilterValue(EMPTY_STRING);
    }, []);

    const removeItem = (index: number) => {
        const data = [...selectedOptions];
        data.splice(index, 1);
        setSelectedOptions(data);
        onChange(data);
    };

    const handleChange = (option: Item) => {
        const isSelected = selectedOptions.findIndex(item => option.id === item.id);
        if (isSelected !== -1) {
            removeItem(isSelected);
            return;
        }
        const data = [...selectedOptions, option];
        setSelectedOptions(data);
        onChange(data);
    };

    const handleFilterChange = useCallback(e => {
        setFilterValue(e.target.value);
    }, []);

    /*
    names can have multiple words such as "Mandarin Chinese", performing .startsWith check
    for each word will allow us to show more relevant results compared to plain .includes check
    */

    const hasAnyMatchingWord = (item: Item) => {
        const words = item.name.split(SPACE);
        return words.some(word => word.toLowerCase().startsWith(filterValue?.toLowerCase()));
    };

    const filteredItems = items.filter(item => hasAnyMatchingWord(item));

    /*
    additional to default functions above,
    below functions include debounced search specific behaviour,
    they are exclusive for using the component with querying
    */

    const clearFilterWithQuery = useCallback(() => {
        clearFilter();
        setShowLoadingUntilDebounceIsTriggered(false);
        if (onSearchKeyChange) {
            onSearchKeyChange(EMPTY_STRING);
        }
    }, [clearFilter, onSearchKeyChange]);

    const debouncedSearch = useRef(
        debounce(value => {
            if (onSearchKeyChange) {
                onSearchKeyChange(value);
            }
            setShowLoadingUntilDebounceIsTriggered(false);
        }, 350)
    ).current;

    const handleFilterChangeWithQuery = useCallback(
        e => {
            handleFilterChange(e);
            const value = e.target.value;
            if (value !== EMPTY_STRING) {
                setShowLoadingUntilDebounceIsTriggered(true);
                debouncedSearch(value);
            }
        },
        [handleFilterChange, debouncedSearch]
    );

    const loadingWithQuery = showLoadingUntilDebounceIsTriggered || queryLoading;
    const filteredItemsWithQuery = isEmptyFilterValue ? items : queryResults;

    return {
        selectedOptions,
        finalSelectedOptions,
        showSelectorModal,
        setShowSelectorModal,
        onSelectorModalClose,
        onSelectorModalPrimaryAction,
        removeItem,
        handleChange,
        filterValue,
        isEmptyFilterValue,
        handleFilterChange: withQuery ? handleFilterChangeWithQuery : handleFilterChange,
        clearFilter: withQuery ? clearFilterWithQuery : clearFilter,
        filteredItems: withQuery ? filteredItemsWithQuery : filteredItems,
        loading: loadingWithQuery
    };
}
