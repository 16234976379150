import {isNumber} from 'mathjs';
import styled from 'styled-components';

import {BorderBaseMutedRule} from '@/componentLibrary/tokens/customVariables';
import {BodyP2} from '@/componentLibrary/tokens/typography';
import {
    BorderRadiusBaseSmall,
    BoxShadowBaseRegular,
    ColorBaseGrey700,
    ColorBaseGrey900,
    ColorBgDefault,
    ColorBgSubtle,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

import {OptionProps} from './types';

export const Content = styled.div<{readonly $width?: number | string}>`
    background: ${ColorBgDefault};
    box-shadow: ${BoxShadowBaseRegular};
    border-radius: ${BorderRadiusBaseSmall};
    ${BorderBaseMutedRule};
    width: ${({$width}) => (isNumber($width) ? `${$width}px` : $width)};
`;

export const OptionWrapper = styled.div<OptionProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${ColorBaseGrey900};
    padding: ${SpacingXxsmall};
    ${BodyP2}

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: ${ColorBgSubtle};
    }

    &[disabled] {
        color: ${ColorBaseGrey700};
        font-style: italic;
        cursor: not-allowed;

        &:hover {
            background-color: transparent;
        }
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    padding-right: ${SpacingXxsmall};
`;
