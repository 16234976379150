import {defineMessages} from 'react-intl';

export default defineMessages({
    personalityTest: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentProfileOverview.assessmentProfileDetails.personalityTest'
    },
    logicTest: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentProfileOverview.assessmentProfileDetails.logicTest'
    },
    logicalAbility: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentProfileOverview.assessmentProfileDetails.logicalAbility'
    },
    codingTest: {
        id: 'app.assessment.candidatePage.codingTest'
    },
    noResultsYet: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentResults.testResults.noResultsYet'
    },
    interview: {
        id: 'app.assessment.jobPosition.assessmentMethods.interview.title'
    },
    noData: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResults.roleFitBadge.noData'
    },
    logicScore: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.logicScore'
    },
    personalityScore: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.personalityScore'
    },
    interviewScore: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.interviewScore'
    },
    codingScore: {
        id: 'app.assessment.jobPosition.assessmentMethods.codingTest.title'
    }
});
