import {useCallback, useEffect, useMemo, useState} from 'react';

import {GetJobPosition_JobPosition} from '@/api/assessment/types';

import {TEST} from './constant';

type useTestCompositionProps = {
    jobPosition: GetJobPosition_JobPosition | null;
    predefinedOption?: TEST;
};

export const useTestComposition = ({
    jobPosition,
    predefinedOption
}: useTestCompositionProps) => {
    const enabledTests = useMemo(() => {
        if (predefinedOption) {
            return predefinedOption;
        }
        if (!jobPosition) {
            return null;
        }
        const psychometric =
            jobPosition?.requireLogicIrtTest || jobPosition?.requirePersonalityTest;
        if (psychometric && !jobPosition?.challenge) {
            return TEST.PSYCHOMETRIC;
        }
        if (!psychometric && jobPosition?.challenge) {
            return TEST.CODING;
        }
        if (psychometric && jobPosition?.challenge) {
            return TEST.ALL;
        }
        return null;
    }, [jobPosition, predefinedOption]);

    const [currentOption, setCurrentOption] = useState(predefinedOption || null);

    const onCurrentOptionChange = useCallback(value => setCurrentOption(value), []);

    useEffect(() => {
        if (predefinedOption) {
            return onCurrentOptionChange(predefinedOption);
        }
        onCurrentOptionChange(enabledTests === TEST.ALL ? TEST.PSYCHOMETRIC : enabledTests);
    }, [enabledTests, onCurrentOptionChange, predefinedOption]);

    const invitationOptions = useMemo(() => {
        let options = {
            includePsychometricTests: false,
            includeCodingTests: false
        };
        if (currentOption === TEST.PSYCHOMETRIC) {
            options = {
                includeCodingTests: false,
                includePsychometricTests: true
            };
        }
        if (currentOption === TEST.CODING) {
            options = {
                includeCodingTests: true,
                includePsychometricTests: false
            };
        }
        if (currentOption === TEST.ALL) {
            options = {
                includeCodingTests: true,
                includePsychometricTests: true
            };
        }
        return options;
    }, [currentOption]);

    return {currentOption, onCurrentOptionChange, enabledTests, invitationOptions};
};
