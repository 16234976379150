import {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';
import {
    ColorBaseBlue800,
    ColorBaseGreen800,
    ColorBaseRed800,
    ColorFgSubtle
} from '@/componentLibrary/tokens/variables';

import messages from '../messages';

export function useStatus() {
    const intl = useIntl();
    return useMemo(
        () => ({
            [ChallengeAssignmentStatus.NOT_STARTED]: {
                text: intl.formatMessage(messages.notStarted),
                color: ColorFgSubtle
            },
            [ChallengeAssignmentStatus.IN_PROGRESS]: {
                text: intl.formatMessage(messages.inProgress),
                color: ColorBaseBlue800
            },
            [ChallengeAssignmentStatus.SUPPORT_NEEDED]: {
                text: intl.formatMessage(messages.needsSupport),
                color: ColorBaseRed800
            },
            [ChallengeAssignmentStatus.SUBMITTED]: {
                text: intl.formatMessage(messages.submitted),
                color: ColorBaseGreen800
            }
        }),
        [intl]
    );
}
