import {useCallback, useEffect, useRef, useState} from 'react';

import {useDetailedModalContext} from '../Context';
import {MODAL_HEADER_HEIGHT} from '../constants';

const updateRefStyle = (
    ref: React.RefObject<HTMLElement>,
    styles: {[key: string]: string | number}
) => {
    if (!ref.current) {
        return;
    }
    Object.assign(ref.current.style, styles);
};

export function useStickyScrollLogic() {
    const {tabRef, parentRef} = useDetailedModalContext();
    const sectionWrapperRef = useRef<HTMLDivElement>(null);
    const sectionFooterRef = useRef<HTMLDivElement>(null);
    const scoreRef = useRef<HTMLDivElement>(null);
    const headerRowRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLDivElement>(null);

    const [isSticky, setIsSticky] = useState(false);

    const handleScroll = useCallback(() => {
        if (
            !sectionWrapperRef.current ||
            !scoreRef.current ||
            !sectionFooterRef.current ||
            !headerRowRef.current ||
            !tableRef.current
        ) {
            return;
        }

        const divRect = sectionWrapperRef.current.getBoundingClientRect();
        const scoreRect = scoreRef.current.getBoundingClientRect();
        const endRect = sectionFooterRef.current.getBoundingClientRect();
        const headerRowRect = headerRowRef.current.getBoundingClientRect();

        const topBuffer = MODAL_HEADER_HEIGHT + (tabRef?.current?.clientHeight || 0);

        if (isSticky) {
            if (endRect.top <= scoreRect.height + topBuffer) {
                updateRefStyle(scoreRef, {top: `${endRect.top - scoreRect.height}px`});
            } else if (endRect.top !== topBuffer) {
                updateRefStyle(scoreRef, {top: `${topBuffer}px`});
            }

            if (endRect.top <= headerRowRect.height + topBuffer) {
                updateRefStyle(headerRowRef, {top: `${endRect.top - headerRowRect.height}px`});
            } else if (endRect.top !== topBuffer) {
                updateRefStyle(headerRowRef, {top: `${topBuffer}px`});
            }

            if (divRect.top > topBuffer) {
                setIsSticky(false);
                updateRefStyle(headerRowRef, {width: 'inherit'});
                updateRefStyle(tableRef, {paddingTop: 'inherit'});
            }
        } else {
            if (divRect.top <= topBuffer) {
                setIsSticky(true);
                updateRefStyle(headerRowRef, {width: `${headerRowRect.width}px`});
                updateRefStyle(tableRef, {paddingTop: `${headerRowRect.height}px`});
            }

            updateRefStyle(scoreRef, {top: `${topBuffer}px`});
            updateRefStyle(headerRowRef, {top: `${topBuffer}px`});
        }
    }, [isSticky, tabRef]);

    useEffect(() => {
        const parentEl = parentRef.current;
        if (!parentEl) {
            return;
        }

        parentEl.addEventListener('scroll', handleScroll);

        return () => {
            if (parentEl) {
                parentEl.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll, parentRef]);

    return {
        sectionWrapperRef,
        sectionFooterRef,
        scoreRef,
        headerRowRef,
        tableRef,
        isSticky
    };
}
