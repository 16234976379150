export const CONTENT_URL_TYPES = {
    GENERAL: 'general',
    PROLIFIC: 'prolific',
    FIGURE_EIGHT: 'figure8'
};

export const CONTENT_TYPES = {
    GENERAL: 'GENERAL',
    PROLIFIC: 'PROLIFIC',
    FIGURE_EIGHT: 'FIGURE8'
};
