import styled from 'styled-components';

import {SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXxsmall};
`;

export const Note = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SpacingXxsmall};
    font-style: italic;
`;
