import {useIntl} from 'react-intl';

import {EmptyState} from '@/componentLibrary/blocks/EmptyState';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {ExternalLink} from '@/componentLibrary/components/links';
import {LINKS} from '@/links';

import {useAcceptMarketplaceConsentLogic} from './logic';
import messages from './messages';

type Props = {
    organizationName?: string;
    onAccept?: () => void;
};

export function AcceptMarketplaceConsent({organizationName, onAccept}: Props) {
    const intl = useIntl();
    const {loadingConsent, acceptingPolicy, acceptPolicy} =
        useAcceptMarketplaceConsentLogic(onAccept);

    return (
        <EmptyState
            isLarge
            titleText={intl.formatMessage(messages.acceptPolicyTitle)}
            contentText={
                <>
                    {intl.formatMessage(messages.acceptPolicyDescription, {
                        companyName:
                            organizationName ?? intl.formatMessage(messages.genericCompanyText)
                    })}
                    &nbsp;
                    <ExternalLink url={LINKS.candidateServices}>
                        {intl.formatMessage(messages.privacyPolicyTitle)}
                    </ExternalLink>
                </>
            }
            actionZone={
                <PrimaryButton
                    onClick={acceptPolicy}
                    isLoading={acceptingPolicy}
                    disabled={loadingConsent}
                >
                    {intl.formatMessage(messages.acceptPolicyButton)}
                </PrimaryButton>
            }
        ></EmptyState>
    );
}
