import {AuthenticationFormWrapper} from '@/pages/common/authentication/styled';
import {AuthenticationMethod} from '@/services/auth/constants';

import {CreateAccountFormSeparator} from './components/CreateAccountFormSeparator';
import {EmailPasswordForm} from './components/EmailPasswordForm';
import {EnforcedSsoInfo} from './components/EnforcedSsoInfo';
import {ErrorDisplay} from './components/Error';
import {FormHeader} from './components/FormHeader';
import {Links} from './components/Links';
import {SsoSection} from './components/SsoSection';
import {useCommonLogic} from './hooks/useCommonLogic';
import {useConsentLogic} from './hooks/useConsentLogic';
import {useFormLogic} from './hooks/useFormLogic';
import {useSsoLogic} from './hooks/useSsoLogic';
import {CreateAccountFormProps} from './types';

export function CreateAccountForm(props: CreateAccountFormProps) {
    const {
        email,
        emailPasswordLoading,
        save,
        language,
        token,
        enforcedAuthenticationMethod,
        defaultFirstName,
        defaultLastName,
        registrationType,
        organizationOktaConfiguration
    } = props;

    const {signupMethod, setSignupMethod, displayedAuthenticationMethods} = useCommonLogic(
        enforcedAuthenticationMethod,
        organizationOktaConfiguration
    );

    const {termsOfServiceVersion, candidateServicesConsentVersion, loadingConsents} =
        useConsentLogic(registrationType);

    const {onSubmit} = useFormLogic(
        loadingConsents,
        termsOfServiceVersion,
        candidateServicesConsentVersion,
        setSignupMethod,
        save
    );

    const {
        ssoError,
        loadingSso,
        handleRegisterWithGoogle,
        handleRegisterWithMicrosoft,
        handleRegisterWithOkta,
        getSsoErrorMessage
    } = useSsoLogic(
        signupMethod,
        setSignupMethod,
        token,
        loadingConsents,
        termsOfServiceVersion,
        candidateServicesConsentVersion,
        language,
        organizationOktaConfiguration
    );

    return (
        <>
            <AuthenticationFormWrapper>
                <FormHeader />

                {!ssoError && <ErrorDisplay message={null} />}

                {displayedAuthenticationMethods[AuthenticationMethod.EMAIL_PASSWORD] && (
                    <EmailPasswordForm
                        onSubmit={onSubmit}
                        email={email}
                        defaultFirstName={defaultFirstName}
                        defaultLastName={defaultLastName}
                        loading={emailPasswordLoading}
                    />
                )}

                {displayedAuthenticationMethods[AuthenticationMethod.EMAIL_PASSWORD] && (
                    <CreateAccountFormSeparator />
                )}

                {!!enforcedAuthenticationMethod && (
                    <EnforcedSsoInfo
                        enforcedAuthenticationMethod={enforcedAuthenticationMethod}
                    />
                )}

                {ssoError && <ErrorDisplay message={getSsoErrorMessage()} />}

                <SsoSection
                    showGoogleRegistration={
                        displayedAuthenticationMethods[AuthenticationMethod.GOOGLE]
                    }
                    showMicrosoftRegistration={
                        displayedAuthenticationMethods[AuthenticationMethod.MICROSOFT]
                    }
                    showOktaRegistration={
                        displayedAuthenticationMethods[AuthenticationMethod.OKTA]
                    }
                    signupMethod={signupMethod}
                    loading={loadingSso}
                    handleRegisterWithGoogle={handleRegisterWithGoogle}
                    handleRegisterWithMicrosoft={handleRegisterWithMicrosoft}
                    handleRegisterWithOkta={handleRegisterWithOkta}
                />

                <Links />
            </AuthenticationFormWrapper>
        </>
    );
}
