import {InterviewContentRatingType} from '@/api/types/__generated__/graphql';
import {OneDimensionalBar} from '@/componentLibrary/components/charts/OneDimensionalBar';
import {ColorFgMuted} from '@/componentLibrary/tokens/variables';
import {ScorecardCriterionRating} from '@/pages/Organization/pages/Assessment/components/ScorecardCriterionRating';

import {useScorecardResultsBreakdownLogic} from './logic';
import {BarWrapper, Wrapper} from './styled';
import {PublishedCompetencyScore, ScorecardResultsBreakdownProps} from './types';

export function ScorecardResultsBreakdown({
    interviewScores,
    averageScore,
    allInterviewRequests,
    ratingType
}: ScorecardResultsBreakdownProps) {
    const {getScoreInPercentage, notStartedInterviews} = useScorecardResultsBreakdownLogic(
        averageScore,
        allInterviewRequests
    );

    return (
        <Wrapper>
            {ratingType !== InterviewContentRatingType.NO_RATING && (
                <BarWrapper>
                    <OneDimensionalBar
                        color={ColorFgMuted}
                        scoreInPercentage={getScoreInPercentage()}
                    />
                </BarWrapper>
            )}
            {interviewScores.map((interviewScore: PublishedCompetencyScore) => (
                <ScorecardCriterionRating
                    key={interviewScore.id}
                    score={interviewScore.score}
                    comment={interviewScore.comment}
                    isSkipped={interviewScore.isSkipped}
                    user={interviewScore.interviewRequest.employment?.user}
                    ratingType={ratingType}
                />
            ))}
            {notStartedInterviews.map(interviewRequest => (
                <ScorecardCriterionRating
                    key={interviewRequest.id}
                    isSkipped={false}
                    user={interviewRequest.employment?.user}
                    ratingType={ratingType}
                />
            ))}
        </Wrapper>
    );
}
