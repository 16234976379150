import gaussian from 'gaussian';

const gaussianDistribution = gaussian(5.5, Math.pow(2, 2));

export function getUpperPercentile(value: number) {
    const roundedValue = roundAndCapStenScore(value);
    if (roundedValue >= 10) {
        return null;
    }
    return (100 * gaussianDistribution.cdf(roundedValue + 0.5)).toFixed(0);
}

export function getLowerPercentile(value: number) {
    const roundedValue = roundAndCapStenScore(value);
    if (roundedValue <= 1) {
        return null;
    }
    return (100 * gaussianDistribution.cdf(roundedValue - 0.5)).toFixed(0);
}

function roundAndCapStenScore(value: number) {
    const cappedValue = Math.max(Math.min(value, 10), 1);
    return Math.round(cappedValue);
}
