import {useState} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {StepperProps} from '@/componentLibrary/components/Stepper/types';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {Caption} from '@/componentLibrary/components/typography';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {ColorFgSubtle, SpacingLarge, SpacingSmall} from '@/componentLibrary/tokens/variables';

import {useShareConfirmationLogic} from './logic';
import messages from './messages';

type Props = {
    jobRecommendationId: string;
    stepper?: StepperProps;
    jobName: string;
    organizationExternalPrivacyPolicyUrl: string | null;
    companyName: string;
    onLeftAction: () => void;
    onRightAction: () => void;
    close: () => void;
};

export function ShareConfirmation({
    jobRecommendationId,
    stepper,
    jobName,
    companyName,
    organizationExternalPrivacyPolicyUrl,
    onLeftAction,
    onRightAction,
    close
}: Props) {
    const intl = useIntl();
    const [hasError, setHasError] = useState(false);
    const onError = (isError: boolean) => setHasError(isError);
    const {
        titleText,
        backText,
        shareText,
        informationText,
        shareItemsTexts,
        atsPrivacyNotice,
        sendApplication,
        loading
    } = useShareConfirmationLogic({
        jobRecommendationId,
        jobName,
        companyName,
        organizationExternalPrivacyPolicyUrl,
        onRightAction,
        close,
        onError
    });

    const toListItem = (itemText: string) => (
        <li key={itemText}>
            <P2>{itemText}</P2>
        </li>
    );
    const SharedItems = shareItemsTexts.map(toListItem);
    const leftSecondaryAction = (
        <SecondaryButton onClick={onLeftAction}>{backText}</SecondaryButton>
    );
    const primaryAction = (
        <PrimaryButton onClick={sendApplication} isLoading={loading}>
            {shareText}
        </PrimaryButton>
    );

    return (
        <Modal
            close={close}
            headerTitle={titleText}
            stepper={stepper}
            footer={{leftSecondaryAction, primaryAction}}
        >
            <Wrapper>
                <P2>{informationText}</P2>
                <List>{SharedItems}</List>
                <Caption color={ColorFgSubtle}>{atsPrivacyNotice}</Caption>
                {hasError && (
                    <BannerWrapper>
                        <Banner type={BannerType.ERROR}>
                            {intl.formatMessage(messages.backendError)}
                        </Banner>
                    </BannerWrapper>
                )}
            </Wrapper>
        </Modal>
    );
}

const Wrapper = styled.div`
    & ul {
        list-style-type: disc;
        margin-left: 12px;
    }

    & ul li {
        margin-bottom: 4px;
    }
`;

const List = styled.ul`
    padding-left: 25px;
    margin-bottom: ${SpacingSmall};
`;

const BannerWrapper = styled.div`
    margin-top: ${SpacingLarge};
`;
