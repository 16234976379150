import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GetAuthenticatedUserQueryVariables} from '../types/__generated__/graphql';
import {GET_AUTHENTICATED_USER} from './queries';

export function useAuthenticatedUser(
    {withConsents}: GetAuthenticatedUserQueryVariables = {withConsents: false}
) {
    const variables = {withConsents};
    const {error, loading, data, refetch} = useQuery(GET_AUTHENTICATED_USER, {variables});
    const user = useMemo(() => data?.authenticatedUser ?? null, [data]);
    return {error, loading, user, refetch};
}
