import {useCallback, useState} from 'react';

import {FORM_STATES} from './constants';

export function useApplyFormStatesLogic() {
    const [triedToSubmit, setTriedToSubmit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [consentIsAccepted, setConsentIsAccepted] = useState(false);

    const setFormState = useCallback((stateType, value = true) => {
        setTriedToSubmit(false);

        if (stateType === FORM_STATES.COMPLETED) {
            setCompleted(value);
        }

        if (stateType === FORM_STATES.TRIED_TO_SUBMIT) {
            setTriedToSubmit(value);
        }

        if (stateType === FORM_STATES.ACCEPTED_CONSENT) {
            setConsentIsAccepted(value.accepted);
        }
    }, []);

    return {triedToSubmit, completed, consentIsAccepted, setFormState};
}
