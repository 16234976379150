import styled from 'styled-components';

import {BorderBaseDefaultRule} from '@/componentLibrary/tokens/customVariables';
import {
    BorderRadiusBaseLarge,
    BoxShadowBaseRegular,
    ColorBgDefault,
    SpacingLarge
} from '@/componentLibrary/tokens/variables';

export const Container = styled.div`
    max-width: 1100px;
    padding: ${SpacingLarge};
    background-color: ${ColorBgDefault};
    ${BorderBaseDefaultRule}
    border-radius: ${BorderRadiusBaseLarge};
    box-shadow: ${BoxShadowBaseRegular};
`;
