import {useCallback, useEffect, useState} from 'react';

export function useContainerDimensions(
    element: HTMLElement | null,
    elementIsVisible: boolean
) {
    const [dimensions, setDimensions] = useState<{width: number; height: number}>({
        width: 0,
        height: 0
    });

    const getDimensions = useCallback(() => {
        return {
            width: element?.offsetWidth || 0,
            height: element?.offsetHeight || 0
        };
    }, [element]);

    const handleResize = useCallback(() => {
        setDimensions(getDimensions());
    }, [getDimensions]);

    useEffect(() => {
        if (elementIsVisible) {
            setDimensions(getDimensions());
        }
    }, [elementIsVisible, getDimensions]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return dimensions;
}
