import {useMemo} from 'react';

import {
    ColorBgEmphasis,
    ColorBgInset,
    ColorBorderSuccess
} from '@/componentLibrary/tokens/variables';

import {StepperItem, Wrapper} from './styled';
import {StepperProps} from './types';

export function Stepper({noOfSteps, activeStep}: StepperProps) {
    const renderItems = useMemo(() => {
        const items: JSX.Element[] = [];
        for (let i = 1; i < noOfSteps + 1; i++) {
            if (i < activeStep) {
                items.push(<StepperItem key={i} $bg={ColorBorderSuccess} />);
            }
            if (i === activeStep) {
                items.push(<StepperItem key={i} $bg={ColorBgEmphasis} />);
            }
            if (i > activeStep) {
                items.push(<StepperItem key={i} $bg={ColorBgInset} />);
            }
        }
        return items;
    }, [noOfSteps, activeStep]);

    return <Wrapper $itemNumber={renderItems.length}>{renderItems}</Wrapper>;
}
