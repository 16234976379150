import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {ValidateResetPasswordTokenMutation} from '../types/__generated__/graphql';
import {VALIDATE_RESET_PASSWORD_TOKEN} from './mutations';

export function useValidateResetPasswordToken(): MutationHookReturnType<
    ValidateResetPasswordTokenMutation,
    string
> {
    const [mutate, {error, loading}] = useMutation(VALIDATE_RESET_PASSWORD_TOKEN);

    const doValidateToken = useCallback(
        (token: string) => mutate({variables: {token}}),
        [mutate]
    );

    return [doValidateToken, {error, loading}];
}
