import {Component} from 'react';
import styled from 'styled-components';

import {Caption} from '@/componentLibrary/components/typography/Captions';

const MAX_VALUE = 10;

export class RangeBarHeader extends Component {
    renderResults = () => {
        const results = Array.from(Array(MAX_VALUE).keys());
        return results.map((result, index) => (
            <ResultLabel key={index}>{result + 1}</ResultLabel>
        ));
    };

    render() {
        return <Wrapper>{this.renderResults()}</Wrapper>;
    }
}

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: space-between;
    overflow: visible;
    padding-right: 21px;
    margin-right: -8px;
    margin-left: -8px;
    padding-top: 2px;
    padding-left: 2px;
`;

const ResultLabel = styled(Caption)`
    display: block;
    width: 16px;
    color: #666;
    text-align: center;
    position: relative;
`;
