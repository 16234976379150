import {useLocation} from 'react-router-dom';

import {LogicTestValidationIndicator} from '@/componentLibrary/blocks/testReports/LogicTest/components/LogicTestValidationIndicator';
import {OverviewHeader} from '@/componentLibrary/blocks/testReports/LogicTest/components/OverviewHeader';
import {ScoreOverview} from '@/componentLibrary/blocks/testReports/LogicTest/components/ScoreOverview';
import {TestReportOverviewLoading} from '@/componentLibrary/blocks/testReports/components/TestReportOverviewLoading';
import {TestReportType} from '@/componentLibrary/blocks/testReports/components/TestReportOverviewLoading/TestReportOverviewLoading';
import {InPageHeader} from '@/componentLibrary/components/InPageHeader';
import {TITLE_SIZE} from '@/componentLibrary/components/InPageHeader/types';
import {ScorePercent} from '@/componentLibrary/components/typography';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {LogicTestResultEmptyState} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/components/LogicTestResultEmptyState';
import {capValue, roundValue} from '@/utils/misc';

import {useCandidateLogicTestResults} from './hooks';
import {messages} from './messages';
import {HeaderWrapper, IndicatorWrapper, ScoreWrapper, Wrapper} from './styled';
import {CandidateLogicTestPageProps} from './types';

function CandidateLogicTestPage({userId, jobPositionId}: CandidateLogicTestPageProps) {
    const phrases = useExtractPhraseConstants(messages);
    const {
        logicTestResults,
        jobApplication,
        validationTestResult,
        loadingUserResults,
        loadingValidationTestResult
    } = useCandidateLogicTestResults(userId, jobPositionId);
    const location = useLocation();

    if (loadingUserResults || loadingValidationTestResult) {
        return <TestReportOverviewLoading type={TestReportType.LOGIC_TEST} />;
    }

    if (!logicTestResults?.logicalAbilityEstimate) {
        return <LogicTestResultEmptyState />;
    }

    const redirectLink = location.pathname + location.search;
    const standardScore = roundValue(
        capValue(logicTestResults.logicalAbilityEstimate.mu, 1, 10),
        0
    );
    const logicTestScore = jobApplication?.roleFitV2?.assessmentScores?.find(
        ({assessmentMethodType}) => assessmentMethodType === 'LOGIC_TEST'
    );

    return (
        <Wrapper>
            <HeaderWrapper>
                <InPageHeader title={{content: phrases.logicScore, size: TITLE_SIZE.LARGE}} />
                <ScorePercent
                    score={logicTestScore?.score}
                    missingResults={logicTestScore?.missingResults ?? true}
                />
            </HeaderWrapper>
            <OverviewHeader
                redirectLink={redirectLink}
                logicTestId={logicTestResults.logicalAbilityEstimate.logicTestId}
                jobPositionId={jobPositionId}
            />
            <ScoreWrapper>
                <ScoreOverview standardScore={standardScore} showAdminContent />
            </ScoreWrapper>
            <IndicatorWrapper>
                <LogicTestValidationIndicator
                    isValidationRequested={Boolean(validationTestResult)}
                    isValidationCompleted={Boolean(validationTestResult?.testCompletedAt)}
                    validationResultType={validationTestResult?.result ?? null}
                />
            </IndicatorWrapper>
        </Wrapper>
    );
}

export {CandidateLogicTestPage};
