import styled from 'styled-components';

import {SpacingXlarge} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: fade-in;

    @media only screen and (max-width: 370px) {
        height: 340px;
    }

    @media only screen and (min-width: 371px) and (max-width: 760px) {
        height: 406px;
    }

    @media only screen and (min-width: 761px) and (max-width: 800px) {
        height: 434px;
    }
`;

export const TextWrapper = styled.div`
    margin: 0 ${SpacingXlarge};
`;
