import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {Caption} from '@/componentLibrary/components/typography/Captions';
import {H4} from '@/componentLibrary/components/typography/Headings';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';

import {useExpiredTokenLogic} from './logic';
import messages from './messages';
import {ActionWrapper, Wrapper} from './styled';
import {Props} from './types';

export function ExpiredToken({userId, email}: Props) {
    const intl = useIntl();
    const {emailIsSent, errorWhenSendingEmail, loading, sendSignupEmail} =
        useExpiredTokenLogic(userId);

    const renderContent = useCallback(() => {
        if (emailIsSent || errorWhenSendingEmail) {
            const bannerType = emailIsSent ? BannerType.SUCCESS : BannerType.ERROR;
            const bannerText = emailIsSent
                ? intl.formatMessage(messages.sendEmailSuccess, {email})
                : intl.formatMessage(messages.sendEmailError);

            return <Banner type={bannerType}>{bannerText}</Banner>;
        }

        const resendEmailText = intl.formatMessage(messages.resendEmail);
        const resendEmailCaption = intl.formatMessage(messages.resendEmailCaption, {email});

        return (
            <ActionWrapper>
                <PrimaryButton isLoading={loading} onClick={sendSignupEmail}>
                    {resendEmailText}
                </PrimaryButton>
                <Caption>{resendEmailCaption}</Caption>
            </ActionWrapper>
        );
    }, [email, emailIsSent, errorWhenSendingEmail, intl, loading, sendSignupEmail]);

    return (
        <Wrapper>
            <H4>{intl.formatMessage(messages.title)}</H4>
            <P1>{intl.formatMessage(messages.description)}</P1>
            {renderContent()}
        </Wrapper>
    );
}
