import {Fragment, useCallback} from 'react';

import {LanguageToggle} from '@/componentLibrary/components/Dropdowns/LanguageToggle';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {TextFieldLabel} from '@/componentLibrary/components/inputs/styled';

import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useHistory} from 'react-router-dom';
import {personalityTestIntroMessages} from '../../messages';
import {useStartTestFormLogic} from './logic';
import {ButtonWrapper, Wrapper} from './styled';
import {Props} from './types';

export function StartTestForm({onTestCreated, shareWithOrganizationId, user}: Props) {
    const {loading, startTest} = useStartTestFormLogic(
        onTestCreated,
        shareWithOrganizationId,
        user
    );
    const phrases = useExtractPhraseConstants(personalityTestIntroMessages);

    const history = useHistory();
    const goBack = useCallback(() => {
        history.goBack();
    }, [history]);

    return (
        <Fragment>
            <Wrapper>
                <FormField>
                    <TextFieldLabel>{phrases.selectLanguage}</TextFieldLabel>
                    <LanguageToggle userId={user?.id ?? null} fullWidth />
                </FormField>

                <FormField>
                    <ButtonWrapper>
                        <PrimaryButton
                            onClick={startTest}
                            isLoading={loading}
                            data-testid="start-personality-test-button"
                        >
                            {phrases.startTest}
                        </PrimaryButton>

                        <SecondaryButton onClick={goBack}>{phrases.goBack}</SecondaryButton>
                    </ButtonWrapper>
                </FormField>
            </Wrapper>
        </Fragment>
    );
}
