import {WatchQueryFetchPolicy, useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GetJobPositionQuery} from '@/api/types/__generated__/graphql';
import {DeepPick} from '@/types/generic.types';

import {GET_JOB_POSITION} from './queries';
import {Options} from './types';

export type JobPosition = DeepPick<GetJobPositionQuery, 'jobPosition'>;
export type Interview = DeepPick<JobPosition, 'interviews'>[0];
export type InterviewContentItem = DeepPick<Interview, 'contentItems'>[0];
export type TestProfile = DeepPick<JobPosition, 'selectedTestProfile'>;

export function useJobPosition(
    jobPositionId?: number,
    options: Options = {},
    fetchPolicy: WatchQueryFetchPolicy | undefined = 'cache-and-network'
) {
    const {
        withMetrics = false,
        withHiringManager = false,
        withTeam = false,
        withEmailTemplate = false,
        withHiringTeam = false,
        withOrganizationRole = false,
        withTestProfile = false,
        withInterview = false,
        withChallenge = false,
        withAssessmentsRecommendation = false,
        withPersonalityProfile = false,
        withChallengeSpecifications = false,
        skip = false
    } = options;

    const variables = {
        id: jobPositionId as number,
        withMetrics,
        withHiringManager,
        withTeam,
        withEmailTemplate,
        withOrganizationRole,
        withHiringTeam,
        withTestProfile,
        withInterview,
        withChallenge,
        withAssessmentsRecommendation,
        withPersonalityProfile,
        withChallengeSpecifications,
        skip
    };

    const {data, loading, error, refetch, startPolling, stopPolling} = useQuery(
        GET_JOB_POSITION,
        {
            variables,
            fetchPolicy: fetchPolicy,
            nextFetchPolicy: 'cache-first',
            skip: skip || !jobPositionId
        }
    );
    const jobPosition = useMemo(() => data?.jobPosition ?? null, [data]);

    return {jobPosition, error, loading, refetch, startPolling, stopPolling};
}
