import {AssessmentConfiguration} from '@/api/types/__generated__/graphql';
import {ChartData} from '@/componentLibrary/components/charts/DonutGraph/DonutChart/types';

enum AssessmentMethodType {
    LOGIC_TEST = 'LOGIC_TEST',
    PERSONALITY_TEST = 'PERSONALITY_TEST',
    INTERVIEW = 'INTERVIEW',
    CODING_TEST = 'CODING_TEST'
}

type AssessmentWeightContext = {
    assessmentConfiguration: AssessmentConfiguration;
    chartData: ChartData[];
    enabledIndexInChart: number;
};

export {AssessmentMethodType, AssessmentWeightContext};
