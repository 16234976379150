import {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import {useSendValidateLogicTestEmail} from '@/api/logicTestIrt/useSendValidateLogicTestEmail';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {logger} from '@/services/logrocket';

import messages from '../messages';

export function useSendReminderLogic(organizationId: number, userId?: number) {
    const {formatMessage} = useIntl();
    const [doSendReminder, {loading: sendingReminder, data}] = useSendValidateLogicTestEmail(
        organizationId,
        userId
    );

    const sendReminder = useCallback(() => {
        doSendReminder()
            .then(({data: doData}) => {
                const {ok, errorMessage: error} =
                    doData?.sendValidateLogicTestInvitation ?? {};
                if (ok) {
                    toast({type: 'success', message: formatMessage(messages.reminderSent)});
                } else {
                    const message = formatMessage(messages.failedToSendReminder, {error});
                    toast({type: 'error', message});
                }
            })
            .catch(error => {
                logger.error(error);
                toast({type: 'error'});
            });
    }, [doSendReminder, formatMessage]);

    const sendReminderIsDisabled = useMemo(
        () => data?.sendValidateLogicTestInvitation?.ok ?? false,
        [data]
    );

    return {sendReminder, sendingReminder, sendReminderIsDisabled};
}
