import {useQuery} from '@apollo/client';

import {GET_RELEVANCE_PARAMETER_OPTIONS} from './queries';

export function useRelevanceParametersOptions() {
    const {data, loading, error, refetch} = useQuery(GET_RELEVANCE_PARAMETER_OPTIONS, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });

    return {
        relevanceParametersOptions: data?.relevanceParametersOptions ?? null,
        loading,
        error,
        refetch
    };
}
