import {ReactElement, ReactNode, useCallback} from 'react';
import styled from 'styled-components';

import {SpacingXxsmall} from '@/componentLibrary/tokens/variables';

import {TableWrapper} from '../TableWrapper';
import {SelectionCell} from '../tableCells/SelectionCell';
import {SelectionHeader} from '../tableCells/SelectionHeader';
import {CustomTableHeader} from './components/CustomTableHeader';
import {SearchFilter} from './components/SearchFilter/index';
import {defaultFilter} from './filters';
import {useColumnsLogic} from './logic/useColumnsLogic';
import {usePageSizeForLoadingLogic} from './logic/usePageSizeForLoadingLogic';
import {useTableDataLogic} from './logic/useTableDataLogic';
import {useTablePropsLogic} from './logic/useTablePropsLogic';
import './style.css';

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type TableMainProps<T = any> = {
    data: T[];
    columns: unknown[];
    loading?: boolean;
    tableProps?: unknown;
    loadingTableProps?: unknown;
    rowHeight?: number;
    headerAndFooterPadding?: number;
    enableFilter?: boolean;
    enableSearchFilter?: boolean;
    filterFunction?: (searchString: string, data: T) => unknown[];
    renderFilterRowActions?: (data?: T) => ReactNode;
    rowSelectionEnabled?: boolean;
    isSelectionDisabledForItem?: (item: T) => boolean;
    disabledSelectionTooltip?: ReactElement | string;
    renderActions?: () => ReactNode;
    renderSelectionText?: (data?: unknown) => JSX.Element | null;
    selectAllInTableHeader?: boolean;
    searchPlaceholderMessage?: string;
    onSelect?: (item: T) => void;
    onDeselect?: (item: T) => void;
    onChangeSelection?: (items: T[]) => void;
    onDeselectAll?: () => void;
    isSelected?: (item: T) => boolean;
    onRowClick?: (item: T, ctrlOrMetaKeyPressed: boolean) => void;
};

export const TableMain = ({
    data,
    columns,
    loading = false,
    tableProps,
    loadingTableProps,
    rowHeight = 40,
    headerAndFooterPadding = 139,
    enableFilter = false,
    enableSearchFilter = true,
    filterFunction = defaultFilter,
    renderFilterRowActions = () => null,
    rowSelectionEnabled = false,
    isSelectionDisabledForItem = () => false,
    disabledSelectionTooltip,
    renderActions = () => null,
    renderSelectionText = () => null,
    selectAllInTableHeader = false,
    onSelect = () => undefined,
    onDeselect = () => undefined,
    onChangeSelection = () => undefined,
    onDeselectAll = () => undefined,
    isSelected = () => false,
    onRowClick,
    searchPlaceholderMessage
}: TableMainProps) => {
    const {onFilterChange, tableData, isAllRowsSelected, isSomeRowsSelected} =
        useTableDataLogic({
            loading: loading,
            data: data,
            filterFunction: filterFunction,
            onDeselectAll: onDeselectAll,
            isSelected: isSelected
        });

    const renderSelectionCell = useCallback(
        (original: unknown) => (
            <SelectionCell
                handleChecking={() => onSelect(original)}
                handleUnChecking={() => onDeselect(original)}
                isChecked={isSelected(original)}
                isDisabled={isSelectionDisabledForItem(original)}
                tooltipMessage={
                    isSelectionDisabledForItem(original) ? disabledSelectionTooltip : undefined
                }
            />
        ),
        [
            disabledSelectionTooltip,
            isSelected,
            isSelectionDisabledForItem,
            onDeselect,
            onSelect
        ]
    );

    const renderSelectionHeader = useCallback(
        () => (
            <SelectionHeader
                isChecked={isAllRowsSelected()}
                isIndeterminate={!isAllRowsSelected() && isSomeRowsSelected()}
                handleChecking={onChangeSelection.bind(null, tableData)}
                handleUnchecking={onDeselectAll}
            />
        ),
        [isAllRowsSelected, isSomeRowsSelected, onChangeSelection, onDeselectAll, tableData]
    );

    const {getColumns} = useColumnsLogic({
        selectAllInTableHeader: selectAllInTableHeader,
        renderSelectionCell,
        renderSelectionHeader,
        columns: columns,
        rowSelectionEnabled: rowSelectionEnabled,
        loading: loading
    });

    const {loadingProps, normalTableProps} = useTablePropsLogic({
        onRowClick: onRowClick,
        tableProps: tableProps,
        loadingTableProps: loadingTableProps,
        onDeselectAll: onDeselectAll
    });

    const pageSizeForLoading = usePageSizeForLoadingLogic(
        headerAndFooterPadding,
        enableFilter,
        rowHeight
    );

    const renderHeader = useCallback(
        () => (
            <CustomTableHeader
                isAllRowsSelected={isAllRowsSelected()}
                isSomeRowsSelected={isSomeRowsSelected()}
                rowSelectionEnabled={rowSelectionEnabled}
                selectAllInTableHeader={selectAllInTableHeader}
                onSelectAllRows={onChangeSelection.bind(null, tableData)}
                onDeselectAllRows={onDeselectAll}
                selectionText={renderSelectionText({visibleRows: tableData.length})}
                renderActions={renderActions}
            />
        ),
        [
            isAllRowsSelected,
            isSomeRowsSelected,
            onChangeSelection,
            onDeselectAll,
            renderActions,
            renderSelectionText,
            rowSelectionEnabled,
            selectAllInTableHeader,
            tableData
        ]
    );

    const renderFilterRow = useCallback(
        (renderedInPortal?: boolean): JSX.Element | null => {
            if (!enableFilter) {
                // Nothing activated
                return null;
            }

            return (
                <FilterRow noMargin={renderedInPortal}>
                    <div>
                        {enableSearchFilter && (
                            <SearchFilter
                                placeholderMessage={searchPlaceholderMessage}
                                onFilterChange={onFilterChange}
                            />
                        )}
                    </div>
                    {renderFilterRowActions ? <div>{renderFilterRowActions()}</div> : null}
                </FilterRow>
            );
        },
        [
            enableFilter,
            enableSearchFilter,
            onFilterChange,
            renderFilterRowActions,
            searchPlaceholderMessage
        ]
    );

    const renderTable = useCallback(
        (_, makeTable: () => JSX.Element) => (
            <div>
                {renderFilterRow()}
                {renderHeader()}
                {makeTable()}
            </div>
        ),
        [renderFilterRow, renderHeader]
    );

    if (loading) {
        return (
            <TableWrapper
                data={Array.from(Array(pageSizeForLoading), () => ({}))}
                columns={getColumns()}
                tableProps={loadingProps}
            >
                {renderTable}
            </TableWrapper>
        );
    }

    return (
        <TableWrapper data={tableData} columns={getColumns()} tableProps={normalTableProps}>
            {renderTable}
        </TableWrapper>
    );
};

type FilterRowProps = {
    noMargin?: boolean;
};

const FilterRow = styled.div<FilterRowProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${SpacingXxsmall};
    ${({noMargin}) => noMargin && 'margin: 0;'};
`;
