import {PersonAvatarUserShape} from '../PersonAvatar/types';

export function useAvatarsLogic() {
    function getUserInitials(user: PersonAvatarUserShape) {
        let initials = '';

        if (!user || user.isAnonymized) {
            return initials;
        }

        if (user.firstName && user.lastName) {
            initials = `${user.firstName[0]}${user.lastName[0]}`;
        } else if (user.email && user.email !== '') {
            let emailName = user.email.split('@')[0];

            initials = emailName[0];
        }

        return initials.toUpperCase();
    }

    function getOrganizationInitials(name?: string | null) {
        let initials = '';

        if (!name) {
            return initials;
        }

        const text = name.replace(/[&._-]/g, '').trim();
        const nameParts = text.split(/\s+/);

        if (nameParts.length === 1) {
            initials = nameParts[0][0];
        } else if (nameParts.length > 1) {
            initials = `${nameParts[0][0]}${nameParts[1][0]}`;
        }

        return initials.toUpperCase();
    }

    return {getUserInitials, getOrganizationInitials};
}
