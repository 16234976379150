import {defineMessages} from 'react-intl';

export const errorMessages = defineMessages({
    emailMismatch: {
        id: 'app.signup.createAccountForm.emailMismatch'
    },
    somethingWentWrong: {
        id: 'app.signup.createAccountForm.somethingWentWrong'
    }
});
