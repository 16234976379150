import {AuthenticationMethod} from '@/api/types/__generated__/graphql';

export enum FieldState {
    UNTOUCHED,
    VALID,
    INVALID
}

export enum RegistrationChannel {
    SSO = 'sso',
    EMAIL = 'email'
}

export type SsoToken = {
    ssoProvider: AuthenticationMethod;
    ssoToken: string;
};
