import PropTypes from 'prop-types';
import {Component} from 'react';

import {TableMain} from '@/LEGACY_COMPONENT_LIBRARY/TableMain';

import {RangeBar} from './components/RangeBar';
import {RangeBarHeader} from './components/RangeBarHeader';

export class TestProfileRangeTable extends Component {
    static propTypes = {
        items: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.node,
                lower: PropTypes.number,
                upper: PropTypes.number
            })
        ).isRequired
    };

    getColumns = () => {
        return [
            {
                accessor: 'label',
                filterable: false,
                sortable: false,
                Cell: ({value}) => <span>{value}</span>,
                minWidth: 200
            },
            {
                id: 'range',
                accessor: row => row.lower,
                filterable: false,
                sortable: false,
                Cell: ({original}) => (
                    <RangeBar lower={original.lower} upper={original.upper} />
                ),
                Header: () => <RangeBarHeader />,
                getHeaderProps: () => ({
                    style: {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'pre-line',
                        overflow: 'hidden',
                        display: 'flex'
                    }
                }),
                style: {
                    padding: '0 1px 0 0',
                    height: '100%',
                    overflow: 'hidden'
                },
                headerStyle: {
                    paddingLeft: 0
                },
                minWidth: 200,
                maxWidth: 400
            }
        ];
    };

    tableProps = {
        resizable: false,
        sortable: false,
        manual: true
    };

    render() {
        return (
            <TableMain
                data={this.props.items}
                columns={this.getColumns()}
                tableProps={this.tableProps}
                rowSelectionEnabled={false}
                enableFilter={false}
                paginationEnabled={false}
            />
        );
    }
}
