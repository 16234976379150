import styled, {css} from 'styled-components';

import {LOWER_HEIGHT} from '@/componentLibrary/components/charts/StandardTenChart/constants';
import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    ColorFgMuted,
    SpacingLarge,
    SpacingSmall,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxlarge,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    & > div:not(:last-child) {
        border-bottom: ${BorderBaseDefault};
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        & > div:not(:last-child) {
            border-bottom: none;
        }
    }
`;

const flexBaseStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const HeaderRow = styled.div`
    width: 100%;
    height: ${SpacingXxlarge};
    ${flexBaseStyle}
    flex-direction: row;
    border-bottom: ${BorderBaseDefault};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-bottom: ${SpacingLarge};
    }
`;

export const HeadersGroup = styled.div`
    width: 580px;
    ${flexBaseStyle}

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        display: none;
    }
`;

export const StyledFactorRow = styled.div`
    width: 100%;
    height: 68px;
    ${flexBaseStyle}
    flex-direction: row;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-bottom: ${SpacingXlarge};
        height: auto;
        flex-direction: column;
        align-items: start;
    }
`;

export const FactorResult = styled.div`
    width: 100%;
    max-width: 580px;
    ${flexBaseStyle}

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-top: ${SpacingXsmall};
        flex-wrap: wrap;
        align-items: start;
    }
`;

const bandWrapperStyle = css`
    width: 124px;
    margin-inline-end: ${SpacingSmall};
    color: ${ColorFgMuted};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: auto;
        margin-top: ${SpacingXxsmall};
    }
`;

export const LowerBandWrapper = styled.div`
    ${bandWrapperStyle}
    text-align: right;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-inline-end: 0;
        order: 2;
    }
`;

export const UpperBandWrapper = styled.div`
    ${bandWrapperStyle}
    margin-left: ${SpacingSmall};

    @media only screen and (max-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        width: 80px;
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-inline-start: 0;
        order: 3;
    }
`;

export const ChartWrapper = styled.div`
    max-width: 320px;
    height: ${LOWER_HEIGHT}px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
        max-width: none;
    }
`;
