import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.signup.createOrganization.formTitle'
    },
    orgNameLabel: {
        id: 'app.signup.createOrganization.organizationName'
    },
    phoneLabel: {
        id: 'app.signup.createOrganization.phoneNumber'
    },
    referralSourceLabel: {
        id: 'app.signup.createOrganization.referralSource'
    },
    referralSourceError: {
        id: 'app.signup.createOrganization.referralSource.error'
    },
    orgNameDisclaimer: {
        id: 'app.signup.createOrganization.organizationName.description'
    },
    phoneDisclaimer: {
        id: 'app.signup.createOrganization.phoneNumber.description'
    },
    createOrg: {
        id: 'app.signup.createOrganization.button'
    },
    orgNameError: {
        id: 'app.signup.createOrganization.organizationName.error'
    },
    phoneError: {
        id: 'app.signup.createOrganization.phoneNumber.error'
    },
    error: {
        id: 'app.signup.unknownError.title'
    },
    errorRecaptcha: {
        id: 'app.signup.createOrganization.recaptcha.error'
    },
    loading: {
        id: 'app.signup.loading'
    }
});
