import {useCallback, useMemo, useState} from 'react';

export function useReadMoreLogic(text?: string, limit?: number) {
    const [isExpanded, setIsExpanded] = useState(false);

    const onClick = useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);

    const textLimit = useMemo(() => {
        if (!isExpanded && text && limit && text?.length > limit) {
            return `${text.substring(0, limit)}...`;
        }
        return text;
    }, [text, limit, isExpanded]);

    return {isExpanded, onClick, textLimit};
}
