import DOMPurify from 'dompurify';
import styled from 'styled-components';

import {SpacingMedium, SpacingXsmall} from '@/componentLibrary/tokens/variables';
import {ContentItemType} from '@/pages/Organization/pages/Assessment/components/CodingTests/types';

export const getContentItem = (contentItem: ContentItemType) => {
    return typeof contentItem === 'string' ? (
        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
        <InnerHTML dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(contentItem)}} />
    ) : (
        contentItem
    );
};

export const InnerHTML = styled.span`
    & > ol,
    ul {
        list-style: revert;
        margin: ${SpacingXsmall};
        padding-left: ${SpacingXsmall};
    }

    & > ol:not(:last-child) {
        margin-bottom: ${SpacingMedium};
    }

    & > br {
        content: ' ';
        display: block;
        margin-bottom: ${SpacingMedium};
    }

    & > p:not(:last-child) {
        margin-bottom: ${SpacingMedium};
    }
`;
