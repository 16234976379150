import {useMemo} from 'react';

import {Article} from './components/Article';
import {Wrapper} from './styled';
import {ArticlesProps} from './types';

export function Articles({items}: ArticlesProps) {
    const renderedArticles = useMemo(
        () => items.map((item, index) => <Article key={index} {...item} />),
        [items]
    );

    return <Wrapper>{renderedArticles}</Wrapper>;
}
