import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {
    InitializeChallengeAssignmentInput,
    InitializeChallengeAssignmentMutation
} from '../../types/__generated__/graphql';
import {MutationHookReturnType} from '../../types/genericApi/types';
import {INITIALIZE_CHALLENGE_ASSIGNMENT} from './mutations';

export function useInitializeChallengeAssignment(
    assignmentId: string
): MutationHookReturnType<
    InitializeChallengeAssignmentMutation,
    [InitializeChallengeAssignmentInput]
> {
    const [mutate, {error, loading}] = useMutation(INITIALIZE_CHALLENGE_ASSIGNMENT);

    const doInitializeChallengeAssignment = useCallback(
        (input: InitializeChallengeAssignmentInput) => {
            const variables = {assignmentId, input};
            return mutate({variables});
        },
        [assignmentId, mutate]
    );

    return [doInitializeChallengeAssignment, {error, loading}];
}
