import {ReactElement, ReactNode, useMemo} from 'react';

import {SelectionHeader} from '@/LEGACY_COMPONENT_LIBRARY/tableCells/SelectionHeader';

import {
    LeftComponent,
    RightComponent,
    SelectionHeaderWrapper,
    SelectionTextWrapper,
    Wrapper
} from './styled';

type CustomTableHeaderProps = {
    isAllRowsSelected: boolean;
    isSomeRowsSelected: boolean;
    rowSelectionEnabled: boolean;
    selectAllInTableHeader: boolean;
    onSelectAllRows: () => void;
    onDeselectAllRows: () => void;
    renderActions: () => ReactNode;
    selectionText?: string | ReactElement | null;
};

export const CustomTableHeader = ({
    isAllRowsSelected,
    isSomeRowsSelected,
    rowSelectionEnabled,
    selectAllInTableHeader,
    onSelectAllRows,
    onDeselectAllRows,
    selectionText,
    renderActions
}: CustomTableHeaderProps) => {
    const actions = useMemo(() => renderActions(), [renderActions]);
    const isAllSelected = useMemo(() => isAllRowsSelected, [isAllRowsSelected]);
    const isIntermediate = useMemo(
        () => !isAllSelected && isSomeRowsSelected,
        [isAllSelected, isSomeRowsSelected]
    );

    const shouldRenderCustomHeader = useMemo(
        () =>
            actions !== null ||
            (rowSelectionEnabled && !selectAllInTableHeader) ||
            selectionText,
        [actions, rowSelectionEnabled, selectAllInTableHeader, selectionText]
    );

    if (!shouldRenderCustomHeader) {
        return null;
    }

    return (
        <Wrapper>
            <LeftComponent>
                {rowSelectionEnabled && !selectAllInTableHeader ? (
                    <>
                        <SelectionHeaderWrapper>
                            <SelectionHeader
                                isChecked={isAllSelected}
                                isIndeterminate={isIntermediate}
                                handleChecking={onSelectAllRows}
                                handleUnchecking={onDeselectAllRows}
                            />
                        </SelectionHeaderWrapper>
                        <div>{selectionText}</div>
                    </>
                ) : (
                    selectionText && (
                        <SelectionTextWrapper>{selectionText}</SelectionTextWrapper>
                    )
                )}
            </LeftComponent>
            <RightComponent>{actions}</RightComponent>
        </Wrapper>
    );
};
