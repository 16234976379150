import {useCallback} from 'react';

import {ConsentType} from '@/api/types/__generated__/graphql';
import {useGiveConsent} from '@/api/users/useGiveConsent';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {useStaticLegalContent} from '@/services/staticLegalContent/useStaticLegalContent';
import {isDefined} from '@/utils/typeGuards/isDefined';

export function useCandidateServicesConsentBannerLogic() {
    const [doGiveConsent, {loading: loadingGiveConsent}] = useGiveConsent();
    const {loading: loadingConsent, data: consent} = useStaticLegalContent({
        type: ConsentType.PRIVACY_POLICY_CANDIDATE_SERVICES
    });

    const registerUser = useCallback(() => {
        if (!isDefined(consent)) {
            return;
        }
        doGiveConsent({
            consentType: ConsentType.PRIVACY_POLICY_CANDIDATE_SERVICES,
            version: consent.version
        })
            .then(({data}) => {
                const {ok, errorMessage} = data?.giveConsent ?? {};
                if (!ok) {
                    throw new Error(errorMessage ?? '');
                }
            })
            .catch(() => {
                toast({
                    type: 'error'
                });
            });
    }, [consent, doGiveConsent]);

    return {
        acceptButtonIsDisabled: loadingConsent,
        accepting: loadingGiveConsent,
        registerUser
    };
}
