import {useApolloClient} from '@apollo/client';
import {useCallback} from 'react';

import {throttledSetUserLastSeen} from './utils';

export function useSetUserLastSeen() {
    const client = useApolloClient();

    const setUserLastSeen = useCallback(() => {
        throttledSetUserLastSeen(client);
    }, [client]);

    return {
        setUserLastSeen: setUserLastSeen,
        cancel: throttledSetUserLastSeen.cancel
    };
}
