import {defineMessages} from 'react-intl';

export default defineMessages({
    personalityTest: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentProfileOverview.assessmentProfileWeights.personalityTest'
    },
    logicTest: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentProfileOverview.assessmentProfileWeights.logicTest'
    },
    scorecard: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentProfileOverview.assessmentProfileWeights.scorecard'
    }
});
