import styled from 'styled-components';

import {H3} from '@/componentLibrary/components/typography/Headings';
import {BorderBaseDefaultRule} from '@/componentLibrary/tokens/customVariables';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderRadiusBaseLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXlarge,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

export const Title = styled(H3)`
    margin-bottom: 16px;
`;
export const CardWrapper = styled.div`
    ${BorderBaseDefaultRule}
    margin: ${SpacingMedium} 0;
    border-radius: ${BorderRadiusBaseLarge};
    padding: ${SpacingSmall};
`;
export const ScoreBarsWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    &:last-child {
        margin-bottom: 0;
    }

    margin-left: ${SpacingXlarge};
    width: 260px;

    @media only screen and (max-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        margin-left: 0;
        margin-top: ${SpacingXsmall};
        width: 100%;
    }

    gap: ${SpacingXsmall};
`;
export const ScoreGroupWrapper = styled.div`
    height: 100%;

    @media only screen and (min-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        display: flex;
        align-items: center;
    }
`;
