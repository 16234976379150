import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {RadioButton} from '@/componentLibrary/components/controls/RadioButton';
import {S2} from '@/componentLibrary/components/typography';
import {
    BorderBaseDefaultColor,
    BorderBaseFocusColor,
    BorderRadiusBaseSmall,
    BorderWidthBase,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';
import messages from '@/pages/UpgradePlan/components/UpgradePlan/messages';
import {
    InputField,
    InputText
} from '@/pages/UserDemographics/components/UserDemographicsForm/components/styled';

enum PaymentType {
    CREDIT_CARD = 'CREDIT_CARD',
    YEARLY_INVOICE = 'YEARLY_INVOICE'
}

export function PaymentMethodSelector() {
    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.YEARLY_INVOICE);

    const onPaymentTypeChange = (value: PaymentType) => {
        setPaymentType(value);
    };

    return (
        <Wrap>
            <InputField>
                <InputText>
                    <S2>
                        <FormattedMessage {...messages.paymentMethodTitle} />
                    </S2>
                </InputText>
                <Border selected={paymentType === PaymentType.CREDIT_CARD}>
                    <RadioButton<PaymentType>
                        value={PaymentType.CREDIT_CARD}
                        isDisabled
                        isSelected={paymentType === PaymentType.CREDIT_CARD}
                        onSelect={onPaymentTypeChange}
                        label={<FormattedMessage {...messages.creditCard} />}
                    />
                </Border>

                <Border selected={paymentType === PaymentType.YEARLY_INVOICE}>
                    <RadioButton<PaymentType>
                        value={PaymentType.YEARLY_INVOICE}
                        isSelected={paymentType === PaymentType.YEARLY_INVOICE}
                        onSelect={onPaymentTypeChange}
                        label={<FormattedMessage {...messages.yearlyInvoice} />}
                    />
                </Border>
            </InputField>
        </Wrap>
    );
}

const Wrap = styled.div`
    padding-top: ${SpacingXxsmall};
    margin-bottom: 0;
`;

interface BorderProps {
    selected: boolean;
}

const Border = styled.div<BorderProps>`
    border: ${BorderWidthBase} solid
        ${props => (props.selected ? BorderBaseFocusColor : BorderBaseDefaultColor)};
    border-radius: ${BorderRadiusBaseSmall};
    padding: 10px;
    margin-bottom: ${SpacingXxsmall};
`;
