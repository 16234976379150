export const SHARED_INDEX_URL = '/shared-results';

export const sharedResultsLinks = {
    main: (token: string) => `${SHARED_INDEX_URL}/${token}`,
    test: (token: string) => `${SHARED_INDEX_URL}/${token}/test`,
    roleFitResults: (token: string) => `/shared-results/${token}/role-fit-shared`,
    logicTestResults: (token: string) => `${SHARED_INDEX_URL}/${token}/logic-test-shared`,
    personalityTestResults: (token: string) =>
        `${SHARED_INDEX_URL}/${token}/personality-test-shared`,
    psychometricTestResults: (token: string) =>
        `${SHARED_INDEX_URL}/${token}/psychometric-test-shared`,
    interviewResults: (token: string) => `${SHARED_INDEX_URL}/${token}/interview-shared`,
    codingTestResults: (token: string) => `${SHARED_INDEX_URL}/${token}/coding-test-shared`,
    logicTestReport: (token?: string, testId?: string) =>
        `${SHARED_INDEX_URL}/${token}/logic-report/${testId}`,
    personalityTestReport: (token?: string, testId?: string) =>
        `${SHARED_INDEX_URL}/${token}/personality-report/${testId}`
};
