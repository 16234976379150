import {VALIDATION_LOGIC_TEST_VALIDATION_TOKEN} from '@/api/logicTestIrt/mutations';
import {ValidateLogicTestValidationTokenMutation} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';
import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

export function useValidateLogicTestValidationToken(
    token: string
): MutationHookReturnType<ValidateLogicTestValidationTokenMutation> {
    const [mutate, {error, loading}] = useMutation(VALIDATION_LOGIC_TEST_VALIDATION_TOKEN);

    const validateToken = useCallback(() => {
        return mutate({variables: {token}});
    }, [mutate, token]);

    return [validateToken, {loading, error}];
}
