import {FloatingFocusManager, FloatingPortal, useMergeRefs} from '@floating-ui/react';
import {HTMLProps, forwardRef, useCallback} from 'react';

import {isDefined} from '@/utils/typeGuards/isDefined';
import {useActionMenuContext} from '../hooks';
import {Content, IconWrapper} from '../styled';
import {ActionItem, Components} from '../types';

type ActionMenuContentProps = {
    menuWidth: number | string;
    components: Components;
    items: ActionItem[];
} & HTMLProps<HTMLDivElement>;

export const ActionMenuContent = forwardRef<HTMLDivElement, ActionMenuContentProps>(
    function ActionMenuContent({style, menuWidth, components, items, ...props}, propRef) {
        const {context: floatingContext, setOpen, ...context} = useActionMenuContext();
        const ref = useMergeRefs([context.refs.setFloating, propRef]);

        const handleClick = useCallback(
            (item: ActionItem, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                event.stopPropagation();
                if (item.disabled) {
                    return;
                }
                setOpen(false);
                item.action?.();
            },
            [setOpen]
        );

        if (!floatingContext.open) {
            return null;
        }

        return (
            <FloatingPortal>
                <FloatingFocusManager context={floatingContext} modal={context.modal}>
                    <Content
                        ref={ref}
                        style={{...context.floatingStyles, ...style}}
                        aria-labelledby={context.labelId}
                        aria-describedby={context.descriptionId}
                        $width={menuWidth}
                        {...context.getFloatingProps(props)}
                    >
                        {items.map((item, index) => (
                            <components.Option
                                key={index}
                                className={`action-menu-option-${item.title}`}
                                onClick={event => handleClick(item, event)}
                                disabled={item.disabled}
                            >
                                {isDefined(item.icon) && (
                                    <IconWrapper>{item.icon}</IconWrapper>
                                )}
                                {item.title}
                            </components.Option>
                        ))}
                    </Content>
                </FloatingFocusManager>
            </FloatingPortal>
        );
    }
);
