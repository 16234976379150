import {JobPosition} from '@/api/assessment/jobPositions/useJobPosition';

import {secondaryColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useMemo} from 'react';

import {roundPercentageValue} from '../../../../../CandidateRoleFitV2Page/components/ScoreCards/utils';

function getTestWeight(
    isRequired: boolean,
    isOnlyTestRequired: boolean,
    individualWeight: number
) {
    if (!isRequired) {
        return 0;
    }

    return isOnlyTestRequired ? 1 : individualWeight;
}

export function useWeightsBreakdownLogic(jobPosition: JobPosition) {
    const weights = useMemo(() => {
        if (!isDefined(jobPosition?.selectedTestProfile)) {
            return null;
        }

        const {
            requireLogicTest,
            requireLogicIrtTest,
            requirePersonalityTest,
            selectedTestProfile: {personalityWeight, logicWeight}
        } = jobPosition;

        const logicTestIsRequired = requireLogicTest || requireLogicIrtTest;
        const onlyLogicTestIsRequired = logicTestIsRequired && !requirePersonalityTest;
        const onlyPersonalityTestIsRequired = requirePersonalityTest && !logicTestIsRequired;

        const logicTestWeight = getTestWeight(
            logicTestIsRequired,
            onlyLogicTestIsRequired,
            logicWeight
        );

        const personalityTestWeight = getTestWeight(
            requirePersonalityTest,
            onlyPersonalityTestIsRequired,
            personalityWeight
        );

        return {
            personalityTestWeight: roundPercentageValue(personalityTestWeight),
            logicTestWeight: roundPercentageValue(logicTestWeight)
        };
    }, [jobPosition]);

    const weightBarItems = useMemo(() => {
        if (!weights) {
            return [];
        }

        return [
            {
                weight: weights.personalityTestWeight,
                descriptionMessageId: 'personalityTest',
                color: secondaryColorTokens.blue000()
            },
            {
                weight: weights.logicTestWeight,
                descriptionMessageId: 'logicTest',
                color: secondaryColorTokens.brown000()
            }
        ];
    }, [weights]);

    const showWeightsIndicator = useMemo(() => {
        if (!isDefined(jobPosition)) {
            return false;
        }

        const {requireLogicTest, requireLogicIrtTest, requirePersonalityTest} = jobPosition;

        return requireLogicTest || requireLogicIrtTest || requirePersonalityTest;
    }, [jobPosition]);

    return {weightBarItems, showWeightsIndicator};
}
