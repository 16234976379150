import {
    ColorBaseGrey600,
    ColorFgMuted,
    ColorFgSubtle
} from '@/componentLibrary/tokens/variables';

export const chartProps = {
    theme: 'fusion',
    baseFont: 'Euclid Circular A',
    baseFontSize: '14',
    baseFontColor: ColorFgMuted,
    chartLeftMargin: 4,
    chartRightMargin: 4,
    labelPadding: 14,
    decimals: '0',
    divLineColor: ColorBaseGrey600,
    plotSpacePercent: 40,
    showToolTip: 0,
    showHoverEffect: 0,
    plotHoverEffect: 0,
    legendPosition: 'bottom-left',
    legendItemFontColor: ColorFgMuted,
    drawCustomLegendIcon: true,
    legendIconSides: 1,
    legendIconScale: 1,
    labelFontColor: ColorFgSubtle,
    legendItemFontSize: 12,
    yAxisValueFontColor: ColorFgMuted,
    yAxisMinValue: 0,
    yAxisMaxValue: 120,
    numDivLines: 3,
    numberSuffix: 's',
    animation: 0,
    defaultAnimation: 0
};
