import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {
    ColorBaseGrey000,
    ColorBaseGrey900,
    SpacingSmall,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';
import styled, {css} from 'styled-components';
import {DropdownMenuItemWrapperProps} from './types';

export const DropdownMenuItemWrapper = styled.div<DropdownMenuItemWrapperProps>`
    cursor: pointer;
    padding: ${SpacingXsmall} ${SpacingSmall};
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${ColorBaseGrey000};

    &:hover {
        background-color: ${greyColorTokens.grey900(0.7)};
    }

    color: ${ColorBaseGrey900};

    ${({isDisabled}) =>
        isDisabled &&
        css`
            opacity: 0.7;
            pointer-events: none;
        `}
`;
