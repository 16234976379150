import {useQuery} from '@apollo/client';

import {GET_MY_TESTS_PROGRESS} from '@/api/users/queries';

export function useMyTestProgress(organizationId: number) {
    const {data, loading, error, refetch} = useQuery(GET_MY_TESTS_PROGRESS, {
        variables: {organizationId: organizationId},
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });

    return {user: data?.me ?? null, loading, error, refetch};
}
