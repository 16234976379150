import styled from 'styled-components';

import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {S2} from '@/componentLibrary/components/typography';
import {
    BorderBaseDefaultColor,
    SpacingMedium,
    SpacingSmall,
    SpacingXlarge,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

const FlexColumn = `
    display: flex;
    flex-direction: column;
`;

const FlexRow = `
    display: flex;
    flex-direction: row;
`;

export const CandidateProgressWrapper = styled.div`
    ${FlexColumn}
    gap: ${SpacingMedium};
    padding: ${SpacingXlarge} 0;
`;

export const ProgressTable = styled.div`
    ${FlexColumn}
    gap: ${SpacingXxsmall};
`;

export const ProgressTableRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SpacingXlarge};
    padding: ${SpacingSmall} 0;
`;

export const HorizontalBar = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${BorderBaseDefaultColor};
`;

export const RowTitle = styled(S2)`
    ${FlexColumn}
    width: 288px;
    min-width: 288px;
`;

export const RowDescription = styled.div`
    ${FlexColumn}
    gap: ${SpacingXxsmall}
`;

export const StatusDescription = styled(RowDescription)`
    flex-direction: row;
    align-items: center;
    gap: ${SpacingXxsmall};
`;

export const CodingTestDescription = styled.div`
    ${FlexRow}
    align-items: center;
    justify-content: space-between;
    flex: 1;
`;

export const StatusDot = styled.div<{color: string}>`
    height: ${SpacingXxsmall};
    width: ${SpacingXxsmall};
    border-radius: 100%;
    ${({color}) => color && `background: ${color}`}
`;

export const CodingTestLink = styled(PlainButton)`
    padding: 0;
`;
