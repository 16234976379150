import styled from 'styled-components';

import {P2, S2} from '@/componentLibrary/components/typography';
import {
    SpacingMedium,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${SpacingXxxsmall};
`;

export const LabelsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-block-start: ${SpacingXxxsmall};
`;

export const FacetWrapper = styled.div`
    font-size: 0;
    display: flex;
    flex-direction: column;
`;

export const DescriptionText = styled(P2)`
    margin-block-start: ${SpacingXxsmall};
`;

export const SubHeader = styled(S2)`
    margin-block-start: ${SpacingMedium};
`;
