import styled from 'styled-components';

import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';
import {SpacingXlarge} from '@/componentLibrary/tokens/variables';

export const Divider = styled.div`
    padding-top: ${SpacingXlarge};
    margin-bottom: ${SpacingXlarge};
    border-bottom: ${BorderBaseDefault};
`;
