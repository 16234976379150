import {Alert} from '@/componentLibrary/blocks/Modals/Alert/Alert';
import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';

import {StartTestAlertWrapper, StartTestBannerWrapper} from '../styled';
import {StartTestAlertProps} from '../types';

export function StartTestAlert({
    onRightAction,
    onLeftAction,
    isLoading,
    startCodingTestTexts
}: StartTestAlertProps) {
    return (
        <Alert
            onRightAction={onRightAction}
            onLeftAction={onLeftAction}
            isLoading={isLoading}
            size={MODAL_SIZE.MEDIUM}
            asChild
            confirm
            {...startCodingTestTexts}
        >
            <StartTestAlertWrapper>
                <div>{startCodingTestTexts.subtitle}</div>
                <StartTestBannerWrapper>
                    <Banner type={BannerType.ATTENTION} noFullwidth>
                        {startCodingTestTexts.disclaimer}
                    </Banner>
                </StartTestBannerWrapper>
            </StartTestAlertWrapper>
        </Alert>
    );
}
