import {ActionOverlay} from '../../ActionOverlay';
import {ActionOverlayProps} from '../../ActionOverlay/types';

export function Popover({
    content,
    trigger,
    showClose = false,
    asChild = false,
    asDiv = false,
    outsidePress = true,
    ...restOptions
}: ActionOverlayProps) {
    const actionOverlayOptions: ActionOverlayProps = {
        content,
        trigger,
        showClose,
        asChild,
        asDiv,
        outsidePress,
        ...restOptions
    };

    return <ActionOverlay {...actionOverlayOptions} />;
}
