import {defineMessages} from 'react-intl';

export default defineMessages({
    information: {
        id: 'app.pages.user.jobRecommendations.RejectJobRecommendation.information'
    },
    reasonsForDismissal: {
        id: 'app.pages.user.jobRecommendations.RejectJobRecommendation.reasonsForDismissal'
    },
    reasonsForDismissalFreeText: {
        id: 'app.pages.user.jobRecommendations.RejectJobRecommendation.reasonsForDismissalFreeText'
    },
    rejectReasonInputNotValid: {
        id: 'app.pages.user.jobRecommendations.RejectJobRecommendation.rejectReasonInputNotValid'
    }
});

export const rejectReasonMessages = defineMessages({
    DO_NOT_WANT_TO_WORK_AT_THIS_COMPANY: {
        id: 'app.pages.user.jobRecommendations.RejectJobRecommendation.DO_NOT_WANT_TO_WORK_AT_THIS_COMPANY'
    },
    DO_NOT_HAVE_THE_EXPERIENCE_NEEDED_FOR_THIS_ROLE: {
        id: 'app.pages.user.jobRecommendations.RejectJobRecommendation.DO_NOT_HAVE_THE_EXPERIENCE_NEEDED_FOR_THIS_ROLE'
    },
    DO_NOT_HAVE_THE_EDUCATION_NEEDED_FOR_THIS_ROLE: {
        id: 'app.pages.user.jobRecommendations.RejectJobRecommendation.DO_NOT_HAVE_THE_EDUCATION_NEEDED_FOR_THIS_ROLE'
    },
    DO_NOT_WANT_TO_WORK_IN_THIS_ROLE: {
        id: 'app.pages.user.jobRecommendations.RejectJobRecommendation.DO_NOT_WANT_TO_WORK_IN_THIS_ROLE'
    },
    OTHER_REASON: {
        id: 'app.pages.user.jobRecommendations.RejectJobRecommendation.OTHER_REASON'
    }
});
