import {AuthenticationMethod} from '@/api/types/__generated__/graphql';

const SsoProvider = {
    [AuthenticationMethod.GOOGLE]: AuthenticationMethod.GOOGLE,
    [AuthenticationMethod.MICROSOFT]: AuthenticationMethod.MICROSOFT,
    [AuthenticationMethod.OKTA]: AuthenticationMethod.OKTA
} as const;

type SsoProviderType = keyof typeof SsoProvider;

enum SsoOperationType {
    LOGIN = 'LOGIN',
    REGISTER = 'REGISTER',
    AUTHENTICATE_AND_VALIDATE = 'AUTHENTICATE_AND_VALIDATE'
}

enum SsoError {
    INVALID_NONCE = 'INVALID_NONCE',
    NO_TOKEN = 'NO_TOKEN',
    INVALID_ID_TOKEN = 'INVALID_ID_TOKEN',
    NO_SUCH_USER = 'NO_SUCH_USER',
    USER_IS_NOT_ACTIVATED = 'USER_IS_NOT_ACTIVATED',
    EMAIL_MISMATCH = 'EMAIL_MISMATCH'
}

export {SsoProvider, SsoProviderType, SsoOperationType, SsoError};
