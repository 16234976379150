import {gql} from '../types/__generated__';

export const GET_MY_JOB_APPLICATIONS = gql(`
    query getEmploymentsAndApplications(
        $withRelevanceInformation: Boolean = false
        $withAutomatedScore: Boolean = false
    ) {
        me {
            id
            acceptedCandidateOrganizations
            jobApplications {
                id
                acceptedByCandidateAt
                withdrawnAt
                completedAt
                jobPosition {
                    id
                    name
                    requireLogicTest
                    requirePersonalityTest
                    requireLogicIrtTest
                    leadershipReportEnabled
                    organization {
                        id
                        name
                        logo
                    }
                    externalJobAdUrl
                    jobRelevanceInformation @include(if: $withRelevanceInformation) {
                        jobPositionId
                        citiesWithMetaData {
                            id
                            name
                            countryName
                        }
                        workLocations
                        jobFamily
                        jobFamilySpecialties
                        levelOfExperience
                        hasLeadershipResponsibilities
                        requiredLanguages
                        roleDescription
                        familiarityDescription
                        autoGenerated
                    }
                }
                fromJobRecommendationId
                challengeAssignment {
                    id
                    status
                    repositoryUrl
                    timeLimitMinutes
                    submissionDeadline
                    isOpenForLateSubmission
                    challengeSpecifications {
                        languages
                        frameworks
                    }
                    testScoreEnabled
                    automatedScore @include(if: $withAutomatedScore) {
                        testScore {
                            score
                            results {
                                result
                                test
                            }
                        }
                    }
                    boilerplateImportDetails {
                        branch
                        framework
                        status
                    }
                    challenge {
                        name
                        description
                        availableBoilerplates {
                            name
                            framework
                            languages
                        }
                        juniorLevelEstimateDurationMinutes
                        seniorLevelEstimateDurationMinutes
                        midLevelEstimateDurationMinutes
                    }
                }
            }
        }
    }
`);

export const GET_USER_ORGANIZATIONS = gql(`
    query getUserOrganizations {
        me {
            id
            email
            firstName
            lastName
            userOrganizations {
                id
                created
                organizationId
                organizationName
                organizationLogo
                organizationExternalPrivacyPolicyUrl
                requiresAcceptance
                relation
                acceptedAt
                isCandidate
                isEmployment
                jobApplicationsWithdrawnAt
                enforcedAuthenticationMethod
                organizationOktaConfiguration {
                    domain
                    clientId
                }
            }
        }
    }
`);

export const GET_USER = gql(`
    query getUser($id: Int!, $withEmployment: Boolean = false) {
        user(id: $id) {
            id
            firstName
            lastName
            email
            emailConfirmed
            isActive
            hasSubmittedDemographicsForm
            employment @include(if: $withEmployment) {
                id
                accessGroupId
            }
        }
    }
`);

export const GET_USER_SETTINGS = gql(`
    query getUserSettings {
        me {
            id
            created
            firstName
            lastName
            language
            email
            emailConfirmed
            born
            gender
            isActive
        }
    }
`);

export const GET_LOGGED_IN_USER = gql(`
    query getLoggedInUser($withConsents: Boolean = false) {
        me {
            id
            firstName
            lastName
            email
            language
            gender
            isSuperUser
            hasSubmittedDemographicsForm
            hasRegisteredToCandidateServices
            registeredAt
            isActive
            consents @include(if: $withConsents) {
                consentType
                version
                isApproved
                decisionAt
            }
            featureFlags
        }
    }
`);

export const GET_USER_FEATURE_FLAGS = gql(`
    query getUserFeatureFlags {
        me {
            id
            featureFlags
        }
    }
`);

export const GET_USER_WITH_RECOMMENDATIONS_SETTINGS = gql(`
    query getLoggedInUserWithRecommendationSettings {
        me {
            id
            isActive
            hasRegisteredToCandidateServices
            recommendationsSettings {
                recommendationsEnabled
                hasOnlyAppliedToInternalPositions
            }
        }
    }
`);

export const GET_MY_TESTS_PROGRESS = gql(`
    query getMyTestsProgress($organizationId: Int) {
        me {
            id
            registeredAt
            logicTestProgress(organizationId: $organizationId) {
                latestCompletedTestId
                latestLogicTestCompletedAt
                latestInProgressTestId
                sharedLogicTestId
                isSharedLogicTestCompleted
                isSharedLogicTestExpired
                notAllowedToTakeNewTest
            }
            personalityTestProgress(organizationId: $organizationId) {
                latestCompletedTestId
                latestPersonalityTestCompletedAt
                latestInProgressTestId
                sharedPersonalityTestId
                isSharedPersonalityTestCompleted
                isSharedPersonalityTestExpired
                notAllowedToTakeNewTest
            }
        }
    }
`);

export const GET_USER_RELEVANCE_INFORMATION = gql(`
    query getUserRelevanceInformation($userId: Int!) {
        user(id: $userId) {
            id
            userRelevanceInformation {
                userId
                preferredJobFamily
                interestedInLeadershipPositions
                preferredWorkLocations
                linkedinProfileUrl
                preferredCompanyTypes
                preferredSpecialties
                preferredIndustries
                preferredCitiesWithMetaData {
                    id
                    name
                    countryName
                }
                spokenLanguages
                linkedinProfileUrl
            }
        }
    }
`);

export const GET_MY_RESULTS = gql(`
    query getMyTestResults($withLeadershipProfile: Boolean = false) {
        me {
            ...UserResultFields
        }
    }
`);

export const GET_USER_RESULTS = gql(`
    query getUserTestResults($userId: Int!, $withLeadershipProfile: Boolean = false) {
        user(id: $userId) {
            ...UserResultFields
        }
    }
`);

export const GET_USER_VALIDATION_TEST = gql(`
    query getUserValidationTest($id: Int!) {
        user(id: $id) {
            id
            firstName
            lastName
            email
            logicalAbilityEstimate {
                id
                logicTestId
                validationTestResult {
                    id
                    testCompletedAt
                }
            }
        }
    }
`);

export const GET_MY_USER_ORGANIZATIONS_WITH_SHARED_TESTS = gql(`
    query getUserOrganizationsWithSharedTests {
        me {
            id
            latestLogicTest {
                id
            }
            userOrganizations {
                id
                created
                acceptedAt
                organizationId
                organizationName
                organizationLogo
                organizationUnregisteredCandidateFlowEnabled
                isCandidate
                isEmployment
                jobApplicationsWithdrawnAt
            }
            sharedLogicTests {
                userId
                organizationId
                sharedAt
                logicalAbilityEstimate {
                    id
                    latestAnswerAt
                }
            }
            sharedPersonalityTests {
                userId
                organizationId
                sharedAt
                personalityIrtResult {
                    id
                    latestAnswerAt
                }
            }
        }
    }
`);

export const GET_AUTHENTICATED_USER = gql(`
    query getAuthenticatedUser($withConsents: Boolean = false) {
        authenticatedUser {
            userId
            firstName
            lastName
            email
            intercomVerificationToken
            lastSeenAt
            signedUpAt
            registeredAt
            consents @include(if: $withConsents) {
                consentType
                version
                isApproved
                decisionAt
            }
        }
    }
`);

export const CHECK_SHAREABLE_RESULTS_TOKEN = gql(`
    query checkShareableResultsToken($token: String!) {
      checkShareableResultsToken(token: $token) {
        expiresAt
        isValid
        permissions
        jobPositionId
        userId
        interviewIds
        jobApplicationId
      }
    }
`);
