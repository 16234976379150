import styled from 'styled-components';

import {
    ColorBaseGrey000,
    ColorBaseGrey900,
    FontSizeSmall,
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    font-size: ${FontSizeSmall};
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 760px) {
        justify-content: space-between;
    }

    background-color: ${ColorBaseGrey900};
    color: ${ColorBaseGrey000};
    height: 60px;
    width: 100%;
    line-height: 27px;
    padding: ${SpacingSmall} 0;
`;
export const TextWrapper = styled.div`
    @media only screen and (max-width: 760px) {
        margin-inline-start: ${SpacingSmall};
        width: 60%;
        line-height: 16px;
    }
`;
export const SkipSampleTestButton = styled.button`
    margin-inline-start: ${SpacingMedium};
    font-size: ${FontSizeSmall};
    background-color: rgb(60 76 91 / 57%);
    color: ${ColorBaseGrey000};
    cursor: pointer;

    &:hover {
        background-color: rgb(64 83 101 / 57%);
    }

    @media only screen and (max-width: 760px) {
        margin: 0 ${SpacingSmall} 0 ${SpacingXxxsmall};
        height: ${SpacingLarge};
    }
`;
