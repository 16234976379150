import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {CREATE_LOGIC_TEST_MUTATION} from '@/api/logicTestIrt/mutations';

import {CreateLogicTestMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';

export function useCreateLogicTest(
    shareWithOrganizationId: number
): MutationHookReturnType<CreateLogicTestMutation> {
    const [mutate, {error, loading}] = useMutation(CREATE_LOGIC_TEST_MUTATION);

    const createLogicTest = useCallback(() => {
        return mutate({variables: {shareWithOrganizationId}});
    }, [shareWithOrganizationId, mutate]);

    return [createLogicTest, {error, loading}];
}
