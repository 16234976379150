import styled from 'styled-components';
import animatedLogo from './assets/alva_animation.gif';

export const MainWrapper = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
`;

export const SubWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const MessageWrapper = styled.div<{$width?: number; $height?: number}>`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: ${({$width}) => $width || 379}px;
    height: ${({$height}) => $height || 48}px;
`;

export const CenterLogo = styled.div`
    width: 80px;
    height: 80px;
    background: url("${animatedLogo}") center center no-repeat;
    background-size: contain;
`;

export const AlvaLogoWrapper = styled.div`
    margin-left: 27px;
    margin-bottom: 23px;
`;
