import {useMutation} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {GET_MY_USER_ORGANIZATIONS_WITH_SHARED_TESTS} from '@/api/users/queries';

import {WithdrawJobApplicationsFromOrganizationMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {WITHDRAW_JOB_APPLICATIONS_FROM_ORGANIZATION} from './mutations';

export function useWithdrawJobApplicationsFromOrganization(
    userId: number,
    organizationId: number | null
): MutationHookReturnType<WithdrawJobApplicationsFromOrganizationMutation> {
    const options = useMemo(() => {
        return {refetchQueries: [{query: GET_MY_USER_ORGANIZATIONS_WITH_SHARED_TESTS}]};
    }, []);
    const [mutate, {error, loading}] = useMutation(
        WITHDRAW_JOB_APPLICATIONS_FROM_ORGANIZATION,
        options
    );
    const doWithdraw = useCallback(() => {
        if (!organizationId) {
            return Promise.reject(new Error('Organization id is required'));
        }
        return mutate({variables: {userId, organizationId}});
    }, [userId, organizationId, mutate]);

    return [doWithdraw, {error, loading}];
}
