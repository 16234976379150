import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {
    UpdateUserRelevanceDataInput,
    UpdateUserRelevanceDataMutation
} from '../types/__generated__/graphql';
import {UPDATE_USER_RELEVANCE_INFORMATION} from './mutations';

export function useUpdateUserRelevanceData(): MutationHookReturnType<
    UpdateUserRelevanceDataMutation,
    UpdateUserRelevanceDataInput
> {
    const [mutate, {error, loading}] = useMutation(UPDATE_USER_RELEVANCE_INFORMATION);

    const updateUserRelevanceData = useCallback(data => mutate({variables: {data}}), [mutate]);

    return [updateUserRelevanceData, {error, loading}];
}
