import {toast} from '@/componentLibrary/components/Toast/toast';

import {useImportChallengeAssignmentBoilerplate} from '@/api/assessment/codingTests/useImportChallengeAssignmentBoilerplate';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {ApolloError} from '@apollo/client';
import {useCallback} from 'react';
import {BoilerplateImportError} from '../constants';
import messages from '../messages';
import {UseImportBoilerplateProps} from '../types';
import {formatImportBoilerplateError, renderErrorToastContent} from '../utils';

export const useImportBoilerplate = ({challengeAssignmentId}: UseImportBoilerplateProps) => {
    const phrases = useExtractPhraseConstants(messages);
    const [doImportBoilerplate, {loading}] = useImportChallengeAssignmentBoilerplate({
        challengeAssignmentId
    });

    const importBoilerplate = useCallback(
        async ({framework}: {framework: string}) => {
            try {
                const {data} = await doImportBoilerplate(framework);
                if (!data?.importChallengeAssignmentBoilerplate?.ok) {
                    throw new Error(
                        data?.importChallengeAssignmentBoilerplate?.errorMessage || 'Error'
                    );
                }
            } catch (e) {
                const errorMessage = String((e as ApolloError).message);
                toast({
                    type: 'error',
                    message: renderErrorToastContent(
                        phrases.importBoilerplateFailedErrorMessage,
                        formatImportBoilerplateError(
                            errorMessage as keyof typeof BoilerplateImportError,
                            phrases
                        )
                    )
                });
                return {isError: true};
            }
        },
        [doImportBoilerplate, phrases]
    );
    return {importBoilerplate, loading};
};
