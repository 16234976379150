import {useCallback, useEffect, useState} from 'react';

import {CandidateReminderStatus} from '@/componentLibrary/components/Bells/UserInfoBell/types';
import {JobApplication} from '@/pages/Organization/pages/Assessment/pages/JobPosition/pages/EvaluationPage/types';

import {TEST} from '../../../../components/TestComposition/constant';

export function useInvitationsModalLogic(
    jobApplications: JobApplication[],
    currentOption: TEST | null,
    challengeId?: string
) {
    const didLastUserEmailBounce = useCallback(
        (candidateReminderStatus: CandidateReminderStatus | null) => {
            if (!candidateReminderStatus) {
                return false;
            }

            const {
                latestDeliveredInvitationTimestamp,
                latestBouncedInvitationTimestamp,
                latestDeliveredReminderTimestamp,
                latestBouncedReminderTimestamp
            } = candidateReminderStatus || {};

            if (!latestBouncedInvitationTimestamp && !latestBouncedReminderTimestamp) {
                return false;
            }

            const invitationDeliveredAt = new Date(
                latestDeliveredInvitationTimestamp
            ).getTime();
            const invitationBouncedAt = new Date(latestBouncedInvitationTimestamp).getTime();
            const reminderDeliveredAt = new Date(latestDeliveredReminderTimestamp).getTime();
            const reminderBouncedAt = new Date(latestBouncedReminderTimestamp).getTime();

            const invitationDidBounce = invitationBouncedAt > invitationDeliveredAt;
            const reminderDidBounce = reminderBouncedAt > reminderDeliveredAt;

            if (reminderDeliveredAt) {
                return reminderDidBounce;
            }

            return invitationDidBounce || reminderDidBounce;
        },
        []
    );

    const didEmailExclude = useCallback(
        (challengeAssignmentChallengeId?: string, jobPositionChallengeId?: string) => {
            if (!jobPositionChallengeId || !challengeAssignmentChallengeId) {
                return false;
            }
            return jobPositionChallengeId !== challengeAssignmentChallengeId;
        },
        []
    );

    const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

    useEffect(() => {
        setSelectedUserIds(
            jobApplications.reduce((acc, application) => {
                if (
                    (currentOption === TEST.PSYCHOMETRIC ||
                        !didEmailExclude(
                            application?.challengeAssignment?.challengeId,
                            challengeId
                        )) &&
                    !didLastUserEmailBounce(application.candidateReminderStatus ?? null) &&
                    application.user
                ) {
                    acc.push(application.user.id);
                }
                return acc;
            }, [] as number[])
        );
    }, [jobApplications, challengeId, didEmailExclude, didLastUserEmailBounce, currentOption]);

    const [isPreviewEmailModalOpen, setIsPreviewEmailModalOpen] = useState(false);

    const selectUser = useCallback(
        userId => {
            setSelectedUserIds([...selectedUserIds, userId]);
        },
        [selectedUserIds]
    );

    const deselectUser = useCallback(
        userId => {
            setSelectedUserIds(selectedUserIds.filter(item => item !== userId));
        },
        [selectedUserIds]
    );

    const isUserSelected = useCallback(
        userId => {
            return selectedUserIds.includes(userId);
        },
        [selectedUserIds]
    );

    const toggleUserSelection = useCallback(
        userId => {
            if (isUserSelected(userId)) {
                deselectUser(userId);
            } else {
                selectUser(userId);
            }
        },
        [selectUser, deselectUser, isUserSelected]
    );

    const openEmailPreview = useCallback(() => {
        setIsPreviewEmailModalOpen(true);
    }, []);

    const closeEmailPreview = useCallback(() => {
        setIsPreviewEmailModalOpen(false);
    }, []);

    return {
        selectedUserIds,
        isPreviewEmailModalOpen,
        toggleUserSelection,
        isUserSelected,
        openEmailPreview,
        closeEmailPreview,
        didLastUserEmailBounce,
        didEmailExclude
    };
}
