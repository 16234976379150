import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.logicTestIrtValidation.logicTestValidationError.title'
    },
    invalidToken: {
        id: 'app.pages.logicTestIrtValidation.logicTestValidationError.invalidToken'
    },
    expiredToken: {
        id: 'app.pages.logicTestIrtValidation.logicTestValidationError.expiredToken'
    }
});
