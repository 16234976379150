import styled from 'styled-components';

import {OtherCaption} from '@/componentLibrary/tokens/typography';
import {
    BorderRadiusBaseRounded,
    BorderWidthBase,
    ColorBgDefault,
    ColorFgDefault,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

import {TagVariant} from './constants';
import {getColors} from './logic';
import {TagWrapperProps} from './types';

export const TagWrapper = styled.span<TagWrapperProps>`
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    cursor: ${({$clickable}) => ($clickable ? 'pointer' : 'default')};
    ${OtherCaption};
    background-color: ${ColorBgDefault};
    color: ${ColorFgDefault};
    border: ${BorderWidthBase} solid ${ColorFgDefault};
    gap: ${SpacingXxsmall};
    padding-block: ${SpacingXxxsmall};
    padding-inline: ${SpacingXxsmall};
    border-radius: ${BorderRadiusBaseRounded};
    ${({$variant}) => getColors($variant || TagVariant.default)}
`;
