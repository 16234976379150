import qs from 'qs';
import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';

import {OrganizationOktaConfiguration} from '@/api/types/__generated__/graphql';
import {redirectToGoogle, redirectToMicrosoft} from '@/pages/Login/loginUtils';
import {
    SsoError,
    SsoOperationType,
    SsoProvider,
    SsoProviderType
} from '@/services/auth/sso/constants';
import {useRedirectToOkta} from '@/services/auth/sso/okta/useRedirectToOkta';
import {SsoState} from '@/services/auth/sso/types';
import {
    getSsoStateFromSessionStorage,
    removeSsoStateFromSessionStorage,
    setSsoStateInSessionStorage
} from '@/services/auth/sso/utils';
import {capitalizeFirstLetter, isEmptyObject} from '@/utils/misc';
import {parseRedirectPathFromQueryParams} from '@/utils/urlParamsParsers';

import {errorMessages} from '../messages';

export function useSsoLogic(
    signupMethod: string | null,
    setSignupMethod: (signupMethod: string) => void,
    token: string,
    loadingConsents: boolean,
    termsOfServiceVersion: string | null | undefined,
    candidateServicesConsentVersion: string | null | undefined,
    language: string | null,
    organizationOktaConfiguration: OrganizationOktaConfiguration | null
) {
    const location = useLocation();
    const intl = useIntl();

    const [ssoError, setSsoError] = useState<boolean>(false);
    const [loadingSso, setLoadingSso] = useState(false);

    const redirectToOkta = useRedirectToOkta();

    const getErrorMessageFromUrl = useCallback(() => {
        const params = qs.parse(location.search, {ignoreQueryPrefix: true});
        return params.message as string;
    }, [location.search]);

    useEffect(() => {
        const urlIncludesSsoError = Object.keys(SsoError).includes(getErrorMessageFromUrl());
        setSsoError(urlIncludesSsoError);
    }, [getErrorMessageFromUrl]);

    useEffect(() => {
        const ssoState = getSsoStateFromSessionStorage();
        if (isEmptyObject(ssoState)) {
            return;
        }
        if (ssoState.ssoProvider) {
            setSignupMethod(ssoState.ssoProvider);
        }

        if (ssoState.completed) {
            setLoadingSso(false);
            removeSsoStateFromSessionStorage();
        }
    }, [setSignupMethod]);

    const getSsoState = useCallback(
        (ssoProvider: SsoProviderType): SsoState => {
            return {
                token,
                ssoProvider,
                operationType: SsoOperationType.REGISTER,
                locale: language,
                tosRevision: termsOfServiceVersion || '',
                cscRevision: candidateServicesConsentVersion,
                redirectPath: parseRedirectPathFromQueryParams(location.search),
                oktaConfig: organizationOktaConfiguration
            };
        },
        [
            candidateServicesConsentVersion,
            language,
            location.search,
            organizationOktaConfiguration,
            termsOfServiceVersion,
            token
        ]
    );

    const initiateSsoRegistration = useCallback(
        (ssoProvider: SsoProviderType) => {
            setLoadingSso(true);
            setSignupMethod(ssoProvider);
        },
        [setSignupMethod]
    );

    const handleRegisterWithGoogle = useCallback(() => {
        if (loadingConsents) {
            return;
        }
        initiateSsoRegistration(SsoProvider.GOOGLE);
        setSsoStateInSessionStorage(getSsoState(SsoProvider.GOOGLE));
        redirectToGoogle(location.search);
    }, [getSsoState, initiateSsoRegistration, loadingConsents, location.search]);

    const handleRegisterWithMicrosoft = useCallback(() => {
        if (loadingConsents) {
            return;
        }
        initiateSsoRegistration(SsoProvider.MICROSOFT);
        setSsoStateInSessionStorage(getSsoState(SsoProvider.MICROSOFT));
        redirectToMicrosoft(location.search);
    }, [getSsoState, initiateSsoRegistration, loadingConsents, location.search]);

    const handleRegisterWithOkta = useCallback(() => {
        if (loadingConsents) {
            return;
        }
        initiateSsoRegistration(SsoProvider.OKTA);
        redirectToOkta(getSsoState(SsoProvider.OKTA), organizationOktaConfiguration);
    }, [
        getSsoState,
        initiateSsoRegistration,
        loadingConsents,
        organizationOktaConfiguration,
        redirectToOkta
    ]);

    const getSsoErrorMessage = useCallback(() => {
        const errorMessage = getErrorMessageFromUrl();
        if (!errorMessage || !signupMethod || loadingSso) {
            return null;
        }

        if (errorMessage === SsoError.EMAIL_MISMATCH) {
            return intl.formatMessage(errorMessages.emailMismatch, {
                ssoProvider: signupMethod && capitalizeFirstLetter(signupMethod)
            });
        }

        return intl.formatMessage(errorMessages.somethingWentWrong);
    }, [getErrorMessageFromUrl, signupMethod, loadingSso, intl]);

    return {
        ssoError,
        loadingSso,
        handleRegisterWithGoogle,
        handleRegisterWithMicrosoft,
        handleRegisterWithOkta,
        getSsoErrorMessage
    };
}
