import {useEffect, useState} from 'react';

import {
    OptionItem,
    OptionItemWrapper,
    OptionsRow,
    Wrapper
} from '@/pages/LogicTestIrt/components/Options/styled';
import {Option, OptionsProps} from '@/pages/LogicTestIrt/components/Options/types';
import {shuffle} from '@/pages/LogicTestIrt/components/Options/utils';
import {logger} from '@/services/logrocket';

import {LOGIC_TEST_OPTION_VALUES} from '../../constants';

export const Options = ({
    question,
    selectOption,
    selectedOption,
    isSvg = false
}: OptionsProps) => {
    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {
        const {optionA, optionB, optionC, optionD, optionE, optionF} = question;
        const shuffledOptions = shuffle([
            {image: optionA, value: LOGIC_TEST_OPTION_VALUES.A},
            {image: optionB, value: LOGIC_TEST_OPTION_VALUES.B},
            {image: optionC, value: LOGIC_TEST_OPTION_VALUES.C},
            {image: optionD, value: LOGIC_TEST_OPTION_VALUES.D},
            {image: optionE, value: LOGIC_TEST_OPTION_VALUES.E},
            {image: optionF, value: LOGIC_TEST_OPTION_VALUES.F}
        ]);
        setOptions(shuffledOptions);
    }, [question]);

    const handleSelectOption = (optionValue: string) => {
        if (selectOption) {
            selectOption(optionValue);
        } else {
            logger.log(optionValue);
        }
    };

    const renderOptionsRow = (rows: Option[]): JSX.Element => (
        <OptionsRow>
            {rows.map(option => (
                <OptionItemWrapper
                    key={option.value}
                    onClick={() => handleSelectOption(option.value)}
                    isSelected={selectedOption === option.value}
                >
                    {isSvg ? (
                        <OptionItem
                            src={option.image}
                            isSelected={selectedOption === option.value}
                        />
                    ) : (
                        <OptionItem
                            src={`data:image/svg+xml;base64, ${option.image}`}
                            isSelected={selectedOption === option.value}
                        />
                    )}
                </OptionItemWrapper>
            ))}
        </OptionsRow>
    );

    const firstRow = options.slice(0, 3);
    const secondRow = options.slice(3);

    return (
        <Wrapper>
            {renderOptionsRow(firstRow)}
            {renderOptionsRow(secondRow)}
        </Wrapper>
    );
};
