export const LOGIC_SCORE_BOUNDARIES = {
    VERY_LOW_LEVEL_UPPER: 2.5,
    LOW_LEVEL_UPPER: 4.5,
    AVERAGE_LEVEL_UPPER: 6.5,
    HIGH_LEVEL_UPPER: 8.5
};

export const VALIDATION_RESULT_TYPE = {
    LOWER: 'LOWER',
    VALIDATED: 'VALIDATED',
    HIGHER: 'HIGHER'
};
