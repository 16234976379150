import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {SmallSecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {JobStatusBadge} from '@/pages/Organization/pages/Assessment/components/JobPositionStatusBadge/components/JobStatusBadge';

import messages from './messages';

export function JobPositionsList({
    jobPositions,
    hasApplicationInPosition,
    addCandidateToJobPosition
}) {
    return (
        <Wrapper>
            {jobPositions.map(jobPosition => (
                <Item key={jobPosition.id}>
                    <JobNameWrapper>
                        <JobPositionName>{jobPosition.name}</JobPositionName>
                        <JobStatusBadge status={jobPosition.currentStatus} />
                    </JobNameWrapper>

                    <ButtonWrapper>
                        {hasApplicationInPosition(jobPosition.id) ? (
                            <SmallSecondaryButton disabled isFullWidth>
                                <FormattedMessage {...messages.added} />
                            </SmallSecondaryButton>
                        ) : (
                            <SmallSecondaryButton
                                isFullWidth
                                onClick={addCandidateToJobPosition.bind(null, jobPosition.id)}
                            >
                                <FormattedMessage {...messages.addToJob} />
                            </SmallSecondaryButton>
                        )}
                    </ButtonWrapper>
                </Item>
            ))}
        </Wrapper>
    );
}

JobPositionsList.propTypes = {
    jobPositions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            currentStatus: PropTypes.string
        })
    ),
    addCandidateToJobPosition: PropTypes.func,
    hasApplicationInPosition: PropTypes.func
};

const Wrapper = styled.div`
    margin: 20px 0;
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 10px;
`;

const JobNameWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const JobPositionName = styled.div`
    margin-right: 12px;
`;

const ButtonWrapper = styled.div`
    width: 120px;
`;
