import {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import {useAcceptJobRecommendation} from '@/api/jobRecommendations/useAcceptJobRecommendation';
import {useApplyToJobRecommendation} from '@/api/jobRecommendations/useApplyToJobRecommendation';
import {useGetRelevanceInformation} from '@/api/users/useGetRelevanceInformation';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {ExternalLink} from '@/componentLibrary/components/links';
import {useActiveJobRecommendationState} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation/context';
import {isDefined} from '@/utils/typeGuards/isDefined';

import messages from '../../messages';

type Props = {
    jobRecommendationId: string;
    jobName: string;
    companyName: string;
    organizationExternalPrivacyPolicyUrl: string | null;
    onRightAction: () => void;
    onError: (hasError: boolean) => void;
    close: () => void;
};

const CONFIRMATION_TIMEOUT = 2000;

export function useShareConfirmationLogic(props: Props) {
    const {
        jobRecommendationId,
        jobName,
        companyName,
        organizationExternalPrivacyPolicyUrl,
        onRightAction,
        onError,
        close
    } = props;

    const intl = useIntl();
    const titleText = intl.formatMessage(messages.title, {companyName});
    const backText = intl.formatMessage(messages.back);
    const shareText = intl.formatMessage(messages.share);
    const informationText = intl.formatMessage(messages.information, {jobName, companyName});
    const atsPrivacyNoticePart1Text = intl.formatMessage(messages.atsPrivacyNoticePart1, {
        companyName
    });
    const atsPrivacyNoticePart2Text = intl.formatMessage(messages.atsPrivacyNoticePart2);
    const logicTestResults = intl.formatMessage(messages.logicTestResults);
    const personalityTestResults = intl.formatMessage(messages.personalityTestResults);
    const name = intl.formatMessage(messages.name);
    const email = intl.formatMessage(messages.email);
    const linkedin = intl.formatMessage(messages.linkedInProfileURL);
    const shareItemsTexts = [logicTestResults, personalityTestResults, name, email, linkedin];

    const {user: loggedInUser} = useLoggedInUser();
    const {userRelevanceInformation} = useGetRelevanceInformation(loggedInUser?.id);
    const hasSubmittedLinkedinProfile = userRelevanceInformation?.linkedinProfileUrl;

    const {linkedinUrl} = useActiveJobRecommendationState();

    const [acceptJobRecommendation, {loading: loadingAcceptRecommendation}] =
        useAcceptJobRecommendation(jobRecommendationId, loggedInUser?.id);

    const [applyToJobRecommendation, {loading: loadingApplyToJobRecommendation}] =
        useApplyToJobRecommendation(jobRecommendationId, linkedinUrl, loggedInUser?.id);

    const onApplicationSuccessful = useCallback(() => {
        onRightAction();
        setTimeout(() => close(), CONFIRMATION_TIMEOUT);
    }, [onRightAction, close]);

    const doAcceptRecommendation = useCallback(() => {
        acceptJobRecommendation()
            .then(({data}) => {
                if (data?.acceptJobRecommendation?.ok) {
                    onApplicationSuccessful();
                } else {
                    onError(true);
                }
            })
            .catch(console.error);
    }, [acceptJobRecommendation, onApplicationSuccessful, onError]);

    const doApplyToJobRecommendation = useCallback(() => {
        applyToJobRecommendation()
            .then(({data}) => {
                if (data?.applyToJobRecommendation?.ok) {
                    onApplicationSuccessful();
                } else {
                    onError(true);
                }
            })
            .catch(console.error);
    }, [applyToJobRecommendation, onApplicationSuccessful, onError]);

    const sendApplication = useCallback(() => {
        return !hasSubmittedLinkedinProfile
            ? doApplyToJobRecommendation()
            : doAcceptRecommendation();
    }, [hasSubmittedLinkedinProfile, doAcceptRecommendation, doApplyToJobRecommendation]);

    const loading = useMemo(() => {
        return !hasSubmittedLinkedinProfile
            ? loadingApplyToJobRecommendation
            : loadingAcceptRecommendation;
    }, [
        hasSubmittedLinkedinProfile,
        loadingAcceptRecommendation,
        loadingApplyToJobRecommendation
    ]);

    const atsPrivacyNotice = useMemo(() => {
        if (isDefined(organizationExternalPrivacyPolicyUrl)) {
            return (
                <>
                    {atsPrivacyNoticePart1Text}
                    &nbsp;
                    <ExternalLink url={organizationExternalPrivacyPolicyUrl}>
                        {atsPrivacyNoticePart2Text}
                    </ExternalLink>
                </>
            );
        }
        return (
            <>
                {atsPrivacyNoticePart1Text}
                &nbsp;
                {atsPrivacyNoticePart2Text}
            </>
        );
    }, [
        organizationExternalPrivacyPolicyUrl,
        atsPrivacyNoticePart1Text,
        atsPrivacyNoticePart2Text
    ]);

    return {
        titleText,
        backText,
        shareText,
        informationText,
        shareItemsTexts,
        atsPrivacyNotice,
        sendApplication,
        loading
    };
}
