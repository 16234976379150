import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.title'
    },
    cancel: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.cancel'
    },
    share: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.share'
    },
    information: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.information'
    },
    testResults: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.testResults'
    },
    relevanceInformation: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.relevanceInformation'
    },
    personalInformation: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.personalInformation'
    },
    logicTestResults: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.logicTestResults'
    },
    personalityTestResults: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.personalityTestResults'
    },
    backgroundAndJobPreferences: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.backgroundAndJobPreferences'
    },
    name: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.name'
    },
    email: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.email'
    },
    linkedInProfileURL: {
        id: 'app.jobRecommendations.yourLinkedInProfileURL'
    },
    back: {
        id: 'app.modalEmpty.goBack'
    },
    success: {
        id: 'app.jobRecommendations.appliedSuccess'
    },
    atsPrivacyNoticePart1: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.atsPrivacyNoticePart1'
    },
    atsPrivacyNoticePart2: {
        id: 'app.pages.user.jobRecommendations.confirmAcceptJobRecommendation.atsPrivacyNoticePart2'
    }
});
