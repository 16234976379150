export enum TEST_STATUSES {
    NOT_STARTED = 'NOT_STARTED',
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN_PROGRESS'
}

export enum TEST_TYPES {
    PERSONALITY_TEST = 'PERSONALITY_TEST',
    LOGIC_TEST = 'LOGIC_TEST'
}
