import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.title'
    },
    roleFitWeights: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.roleFitWeights'
    },
    assessmentScoresResults: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.roleFitOverview.assessmentScoresResults'
    },
    noRolefitDescription: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.scoresAndResults.missingData.description'
    },
    noData: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResults.roleFitBadge.noData'
    }
});
