import {FormattedMessage} from 'react-intl';

import {Separator} from '@/componentLibrary/components/Separator';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {SsoButton} from '@/componentLibrary/components/buttons/SsoButton';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {H4} from '@/componentLibrary/components/typography/Headings';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {LINKS} from '@/links';
import {SsoProvider} from '@/services/auth/sso/constants';

import {useCreateAccountLogic} from './logic';
import messages from './messages';
import {ConsentSection, CreateAccountWrapper, SignUpWithEmail, Stack} from './styled';

type CreateAccountSelectorProps = {
    doSignupWithEmail: () => void;
    showPersonalEmailBanner: boolean;
};

export function CreateAccountSelector(props: CreateAccountSelectorProps) {
    const {
        handleRegisterWithGoogle,
        handleRegisterWithMicrosoft,
        loadingGoogle,
        loadingMicrosoft
    } = useCreateAccountLogic();

    const banner = props.showPersonalEmailBanner ? (
        <Banner type={BannerType.ERROR} noIcon>
            <FormattedMessage {...messages.personalEmailNotAllowed} />
        </Banner>
    ) : null;

    return (
        <CreateAccountWrapper>
            <Stack>
                <H4>
                    <FormattedMessage {...messages.createAccount} />
                </H4>

                {banner}

                <SsoButton
                    isSignup
                    isLoading={loadingGoogle}
                    ssoProvider={SsoProvider.GOOGLE}
                    onClick={handleRegisterWithGoogle}
                />

                <SsoButton
                    isSignup
                    isLoading={loadingMicrosoft}
                    ssoProvider={SsoProvider.MICROSOFT}
                    onClick={handleRegisterWithMicrosoft}
                />

                <P2>
                    <FormattedMessage {...messages.pleaseUseWorkAccount} />
                </P2>

                <Separator>
                    <P2>
                        <FormattedMessage {...messages.or} />
                    </P2>
                </Separator>

                <SignUpWithEmail>
                    <PlainButton
                        data-testid={'signup-with-email'}
                        icon={Icons.MAIL}
                        onClick={props.doSignupWithEmail}
                    >
                        <FormattedMessage {...messages.signUpWithEmail} />
                    </PlainButton>
                </SignUpWithEmail>

                <ConsentSection>
                    <FormattedMessage
                        {...messages.acceptTermsAndPolicy}
                        values={{
                            termsOfServiceLink: (
                                <ExternalLink url={LINKS.termsOfService}>
                                    <FormattedMessage {...messages.termsOfServiceTitle} />
                                </ExternalLink>
                            ),
                            privacyPolicyLink: (
                                <ExternalLink url={LINKS.privacyPolicy}>
                                    <FormattedMessage {...messages.privacyPolicyTitle} />
                                </ExternalLink>
                            )
                        }}
                    />
                </ConsentSection>
            </Stack>
        </CreateAccountWrapper>
    );
}
