import styled from 'styled-components';

import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';

import {Overlay} from '../../Overlay';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    border-bottom: ${BorderBaseDefault};

    &:hover ${Overlay} {
        display: none;
    }
`;
