import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SubtitleS1} from '@/componentLibrary/tokens/typography';
import {
    ColorBgDefault,
    ColorFgDefault,
    SpacingLarge,
    SpacingSmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import {
    CENTER_COLUMN_GAP,
    CENTER_COLUMN_WIDTH,
    LANGUAGE_TOGGLE_INLINE_MARGIN,
    LANGUAGE_TOGGLE_WIDTH,
    LOGO_SIZE,
    WRAPPER_HORIZONTAL_PADDING,
    WRAP_WIDTH
} from '@/pages/User/components/PageTemplate/components/StartHeader/constants';

import alvaLogo from './assets/blackAlvaLogo.svg';

export const MainNavLink = styled(NavLink)`
    ${SubtitleS1}
    color: ${ColorFgDefault};
    text-decoration: none;
`;

export const Wrapper = styled.div`
    display: flex;
    padding: ${SpacingSmall} ${WRAPPER_HORIZONTAL_PADDING};
    background-color: ${ColorBgDefault};
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;

    @media only screen and (max-width: ${WRAP_WIDTH}px) {
        gap: ${SpacingLarge};
        padding: ${SpacingSmall};
    }
`;

export const Logo = styled.div`
    height: ${LOGO_SIZE};
    width: ${SpacingXxlarge};
    background: url("${alvaLogo}") center center no-repeat;
`;

export const CenterColumn = styled.div`
    margin: 0 auto;
    display: flex;
    position: absolute;
    justify-content: center;
    gap: ${CENTER_COLUMN_GAP};
    width: ${CENTER_COLUMN_WIDTH};
    left: 0;
    right: 0;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        position: relative;
    }

    @media only screen and (max-width: ${WRAP_WIDTH}px) {
        order: 1;
    }
`;

export const MenuColumn = styled.div`
    display: flex;
    align-items: center;
`;

export const LanguageToggleWrapper = styled.div`
    margin-inline-end: ${LANGUAGE_TOGGLE_INLINE_MARGIN};
    width: ${LANGUAGE_TOGGLE_WIDTH};
`;
