import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {
    RegisterUserAndGiveConsentsInput,
    RegisterUserAndGiveConsentsMutation
} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {GET_LOGGED_IN_USER} from '../queries';
import {REGISTER_USER_AND_GIVE_CONSENTS} from './mutations';

export function useRegisterUserAndGiveConsents(token: string): MutationHookReturnType<
    RegisterUserAndGiveConsentsMutation,
    Omit<RegisterUserAndGiveConsentsInput, 'organizationId'> & {
        organizationId: number | null | undefined;
    }
> {
    const [mutate, {error, loading}] = useMutation(REGISTER_USER_AND_GIVE_CONSENTS, {
        refetchQueries: [{query: GET_LOGGED_IN_USER, variables: {withConsents: true}}]
    });

    const doRegisterUserAndGiveConsents = useCallback(
        data => {
            if (!data.organizationId) {
                return Promise.reject(new Error('Organization id is required'));
            }
            return mutate({variables: {token, data}});
        },
        [token, mutate]
    );

    return [doRegisterUserAndGiveConsents, {error, loading}];
}
