import {urlSanitizer} from '@/services/sanitizers';

// biome-ignore lint/correctness/noUndeclaredVariables: <explanation>
export const sanitizeEvent = async (context: SegmentAnalytics.CoreContext) => {
    context.event.properties = sanitizeUrls(context.event.properties);
    context.event.context.page = sanitizeUrls(context.event.context.page);
    return context;
};

export const sanitizeUrlPlugin = {
    name: 'Sanitize urls in events',
    type: 'enrichment',
    load: () => Promise.resolve(),
    isLoaded: () => true,
    page: sanitizeEvent,
    track: sanitizeEvent,
    identify: sanitizeEvent
};

// biome-ignore lint/correctness/noUndeclaredVariables: <explanation>
const sanitizeUrls = (object?: SegmentAnalytics.Properties) => {
    if (!object) {
        return undefined;
    }

    const properties = Object.entries(object);
    const sanitizeUrlPropertiesReducer = (
        // biome-ignore lint/correctness/noUndeclaredVariables: <explanation>
        accumulator: SegmentAnalytics.Properties,
        [key, value]: [string, unknown]
    ) => {
        if (isUrlOrPath(value)) {
            const url = value as string;
            return {...accumulator, [key]: urlSanitizer(url)};
        }
        return {...accumulator, [key]: value};
    };

    return properties.reduce(sanitizeUrlPropertiesReducer, {});
};

const isUrlOrPath = (value: unknown): boolean => {
    if (typeof value !== 'string') {
        return false;
    }

    const urlOrPathRegex = /^(https?:\/\/\S+|\/[a-zA-Z0-9-_/]+\/?)$/;
    return urlOrPathRegex.test(value);
};
