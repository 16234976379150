import {P2} from '@/componentLibrary/components/typography';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {donutChartPaletteColors} from '../DonutChart/constants';
import {LegendCaptions} from './components/LegendCaptions';
import {
    BulletContent,
    BulletContentWeight,
    BulletListWrapper,
    Marker,
    Wrapper
} from './styled';
import {DonutLegendProps} from './types';

export const DonutLegend = ({data, legendOptions}: DonutLegendProps) => {
    const paletteColors =
        legendOptions?.paletteColors ?? (donutChartPaletteColors as string[]);
    return (
        <Wrapper>
            <BulletListWrapper $compact={legendOptions?.compact}>
                <LegendCaptions legendOptions={legendOptions} />
                {data.map(({value, label}, index) => (
                    <BulletContent key={label}>
                        <Marker $markerColor={paletteColors[index] as string}> </Marker>
                        <P2>{label}</P2>
                        {isDefined(legendOptions?.showValue) && (
                            <BulletContentWeight>
                                {legendOptions?.displayValues?.[index] ?? value}
                            </BulletContentWeight>
                        )}
                    </BulletContent>
                ))}
            </BulletListWrapper>
        </Wrapper>
    );
};
