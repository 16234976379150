import {ApolloClient} from '@apollo/client';
import throttle from 'lodash.throttle';

import {logger} from '@/services/logrocket';

import {THROTTLE_MAX_WAIT, THROTTLE_OPTIONS} from './constants';
import {SET_USER_LAST_SEEN} from './mutations';

const setUserLastSeen = (apolloClient: ApolloClient<object>) => {
    apolloClient.mutate({mutation: SET_USER_LAST_SEEN}).catch(error => {
        logger.warn(error);
    });
};

export const throttledSetUserLastSeen = throttle(
    setUserLastSeen,
    THROTTLE_MAX_WAIT,
    THROTTLE_OPTIONS
);
