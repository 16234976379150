import {useMemo} from 'react';

import {InterviewContentItem} from '@/api/assessment/jobPositions/useJobPosition';
import {
    InterviewContentItem as TranslatedInterviewContentItem,
    useTranslateInterviewContentItem
} from '@/pages/Organization/hooks/useTranslatedInterviewContent';

export function useScorecardCriterionInfoLogic(contentItem: InterviewContentItem) {
    const translateContentItem =
        useTranslateInterviewContentItem<TranslatedInterviewContentItem>();
    const translatedContentItem = useMemo(() => {
        return translateContentItem(contentItem);
    }, [contentItem, translateContentItem]);

    return {translatedContentItem};
}
