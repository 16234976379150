import styled from 'styled-components';

import {SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const InfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${SpacingXxsmall};
`;

export const ScoreWrapper = styled.div`
    display: flex;
    align-items: center;
`;
