import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.title'
    },
    viewJobDescription: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.viewJobDescription'
    },
    dismiss: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.dismiss'
    },
    apply: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.apply'
    },
    share: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.share'
    },
    readMore: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.readMore'
    },
    moreTags: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.moreTags'
    },
    experience: {
        id: 'app.backgroundInformation.backgroundInformationReport.experience'
    },
    jobTitle: {
        id: 'app.backgroundInformation.backgroundInformationReport.jobTitle'
    },
    jobFamily: {
        id: 'app.backgroundInformation.backgroundInformationReport.jobFamily'
    },
    companyType: {
        id: 'app.backgroundInformation.backgroundInformationReport.companyType'
    },
    yearsOfExperience: {
        id: 'app.backgroundInformation.backgroundInformationReport.yearsOfExperience'
    },
    languageRequirementsTitle: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.languageRequirementsTitle'
    },
    jobSpecialitiesTitle: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.jobSpecialitiesTitle'
    },
    leadershipRoleTitle: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.leadershipRoleTitle'
    },
    priorExperienceTitle: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.priorExperienceTitle'
    },
    minimumExperience: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.minimumExperience'
    },
    noMinimumExperience: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.noMinimumExperience'
    },
    roleDescriptionTitle: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.roleDescriptionTitle'
    },
    familiarityDescriptionTitle: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.familiarityDescriptionTitle'
    },
    leadershipTitle: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.leadershipTitle'
    },
    leadershipYes: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.leadershipYes'
    },
    leadershipNo: {
        id: 'app.pages.user.jobRecommendations.jobRecommendationItem.leadershipNo'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING'
    },
    jobFamilies_DESIGN_AND_CREATIVE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE'
    },
    jobFamilies_FINANCE_AND_INSURANCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE'
    },
    jobFamilies_HR_AND_RECRUITMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT'
    },
    jobFamilies_MARKETING_AND_PR: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS'
    },
    jobFamilies_SOFTWARE_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING'
    },
    jobFamilies_IT_SERVICES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES'
    },
    jobFamilies_EDUCATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT'
    },
    jobFamilies_HEALTHCARE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE'
    },
    jobFamilies_LEGAL: {id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL'},
    jobFamilies_LIFE_SCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE'
    },
    jobFamilies_SOCIAL_SCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT'
    },
    jobFamilies_CUSTOMER_SERVICE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE'
    },
    jobFamilies_SALES: {id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES'},
    jobFamilies_TRANSPORTATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.jobFamilies_INSTALLATION_MAINTENANCE_REPAIR'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_AEROSPACE_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.AEROSPACE_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_ARCHITECTURE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.ARCHITECTURE'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_AUTOMOTIVE_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.AUTOMOTIVE_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_CHEMICAL_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.CHEMICAL_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_COMPUTER_HARDWARE_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.COMPUTER_HARDWARE_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_ELECTRICAL_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.ELECTRICAL_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_ENERGY_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.ENERGY_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_ENVIRONMENTAL_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.ENVIRONMENTAL_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_INDUSTRIAL_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.INDUSTRIAL_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_LANDSCAPE_ARCHITECTURE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.LANDSCAPE_ARCHITECTURE'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_MECHANICAL_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.MECHANICAL_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_ROBOTICS_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.ROBOTICS_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_TRANSPORTATION_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.TRANSPORTATION_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_VALIDATION_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.VALIDATION_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_WATER_AND_WIND_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.WATER_AND_WIND_ENGINEERING'
    },
    jobFamilies_DESIGN_AND_CREATIVE_ARTWORK: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.ARTWORK'
    },
    jobFamilies_DESIGN_AND_CREATIVE_COPYWRITING_AND_CREATIVE_WRITING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.COPYWRITING_AND_CREATIVE_WRITING'
    },
    jobFamilies_DESIGN_AND_CREATIVE_INDUSTRIAL_DESIGN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.INDUSTRIAL_DESIGN'
    },
    jobFamilies_DESIGN_AND_CREATIVE_EDITING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.EDITING'
    },
    jobFamilies_DESIGN_AND_CREATIVE_FASHION_DESIGN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.FASHION_DESIGN'
    },
    jobFamilies_DESIGN_AND_CREATIVE_FILM_AND_VIDEO_DIRECTING_AND_EDITING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.FILM_AND_VIDEO_DIRECTING_AND_EDITING'
    },
    jobFamilies_DESIGN_AND_CREATIVE_GRAPHIC_DESIGN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.GRAPHIC_DESIGN'
    },
    jobFamilies_DESIGN_AND_CREATIVE_ILLUSTRATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.ILLUSTRATION'
    },
    jobFamilies_DESIGN_AND_CREATIVE_MEDIA_PRODUCTION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.MEDIA_PRODUCTION'
    },
    jobFamilies_DESIGN_AND_CREATIVE_PHOTOGRAPHY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.PHOTOGRAPHY'
    },
    jobFamilies_DESIGN_AND_CREATIVE_PRODUCT_DESIGN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.PRODUCT_DESIGN'
    },
    jobFamilies_DESIGN_AND_CREATIVE_UX_DESIGN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.UX_DESIGN'
    },
    jobFamilies_DESIGN_AND_CREATIVE_VISUAL_DESIGN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.VISUAL_DESIGN'
    },
    jobFamilies_FINANCE_AND_INSURANCE_ACCOUNTING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.ACCOUNTING'
    },
    jobFamilies_FINANCE_AND_INSURANCE_AUDITING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.AUDITING'
    },
    jobFamilies_FINANCE_AND_INSURANCE_APPRAISAL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.APPRAISAL'
    },
    jobFamilies_FINANCE_AND_INSURANCE_BUDGET_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.BUDGET_ANALYSIS'
    },
    jobFamilies_FINANCE_AND_INSURANCE_COMPLIANCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.COMPLIANCE'
    },
    jobFamilies_FINANCE_AND_INSURANCE_CONTROLLING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.CONTROLLING'
    },
    jobFamilies_FINANCE_AND_INSURANCE_CREDIT_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.CREDIT_ANALYSIS'
    },
    jobFamilies_FINANCE_AND_INSURANCE_FINANCIAL_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.FINANCIAL_ANALYSIS'
    },
    jobFamilies_FINANCE_AND_INSURANCE_FINANCIAL_ADVISING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.FINANCIAL_ADVISING'
    },
    jobFamilies_FINANCE_AND_INSURANCE_FINANCIAL_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.FINANCIAL_MANAGEMENT'
    },
    jobFamilies_FINANCE_AND_INSURANCE_INVESTMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.INVESTMENT'
    },
    jobFamilies_FINANCE_AND_INSURANCE_TAXATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.TAXATION'
    },
    jobFamilies_HR_AND_RECRUITMENT_COMPENSATION_AND_BENEFITS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.COMPENSATION_AND_BENEFITS'
    },
    jobFamilies_HR_AND_RECRUITMENT_EMPLOYER_BRANDING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.EMPLOYER_BRANDING'
    },
    jobFamilies_HR_AND_RECRUITMENT_HR_BUSINESS_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.HR_BUSINESS_SUPPORT'
    },
    jobFamilies_HR_AND_RECRUITMENT_HR_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.HR_MANAGEMENT'
    },
    jobFamilies_HR_AND_RECRUITMENT_LABOR_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.LABOR_LAW'
    },
    jobFamilies_HR_AND_RECRUITMENT_LEADERSHIP_DEVELOPMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.LEADERSHIP_DEVELOPMENT'
    },
    jobFamilies_HR_AND_RECRUITMENT_LEARNING_AND_DEVELOPMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.LEARNING_AND_DEVELOPMENT'
    },
    jobFamilies_HR_AND_RECRUITMENT_ONBOARDING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.ONBOARDING'
    },
    jobFamilies_HR_AND_RECRUITMENT_ORGANIZATIONAL_DEVELOPMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.ORGANIZATIONAL_DEVELOPMENT'
    },
    jobFamilies_HR_AND_RECRUITMENT_PERFORMANCE_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.PERFORMANCE_MANAGEMENT'
    },
    jobFamilies_HR_AND_RECRUITMENT_TALENT_ACQUISITION_AND_RECRUITMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.TALENT_ACQUISITION_AND_RECRUITMENT'
    },
    jobFamilies_HR_AND_RECRUITMENT_TALENT_AND_SUCCESSION_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.TALENT_AND_SUCCESSION_MANAGEMENT'
    },
    jobFamilies_MARKETING_AND_PR_CONTENT_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.CONTENT_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_DIRECT_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.DIRECT_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_EVENT_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.EVENT_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_INBOUND_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.INBOUND_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_INFLUENCER_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.INFLUENCER_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_MARKET_RESEARCH: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.MARKET_RESEARCH'
    },
    jobFamilies_MARKETING_AND_PR_MARKETING_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.MARKETING_MANAGEMENT'
    },
    jobFamilies_MARKETING_AND_PR_MEDIA_RELATIONS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.MEDIA_RELATIONS'
    },
    jobFamilies_MARKETING_AND_PR_OUTBOUND_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.OUTBOUND_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_PRODUCT_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.PRODUCT_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_PUBLIC_RELATIONS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.PUBLIC_RELATIONS'
    },
    jobFamilies_MARKETING_AND_PR_SEM_AND_SEO: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.SEM_AND_SEO'
    },
    jobFamilies_MARKETING_AND_PR_SOCIAL_MEDIA_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.SOCIAL_MEDIA_MARKETING'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_BUYING_AND_PURCHASING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.BUYING_AND_PURCHASING'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_DEMAND_PLANNING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.DEMAND_PLANNING'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_DISTRIBUTION_SPECIALIST: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.DISTRIBUTION_SPECIALIST'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_EXPORT_AND_IMPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.EXPORT_AND_IMPORT'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_INVENTORY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.INVENTORY'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_LOGISTICS_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.LOGISTICS_ANALYSIS'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_LOGISTICS_COORDINATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.LOGISTICS_COORDINATION'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_PROCUREMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.PROCUREMENT'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_QUALITY_ASSURANCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.QUALITY_ASSURANCE'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_ROUTING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.ROUTING'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_SOURCING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.SOURCING'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_SUPPLY_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.SUPPLY_MANAGEMENT'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_TRANSPORTATION_PLANNING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.TRANSPORTATION_PLANNING'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_WAREHOUSING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.WAREHOUSING'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_BUSINESS_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.BUSINESS_ANALYSIS'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_BUSINESS_INTELLIGENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.BUSINESS_INTELLIGENCE'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_DATA_SCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.DATA_SCIENCE'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_MARKET_DATA_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.MARKET_DATA_ANALYSIS'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_OPERATIONS_DATA_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.OPERATIONS_DATA_ANALYSIS'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_PEOPLE_ANALYTICS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.PEOPLE_ANALYTICS'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_STATISTICAL_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.STATISTICAL_ANALYSIS'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_SYSTEM_ARCHITECTURE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.SYSTEM_ARCHITECTURE'
    },
    jobFamilies_SOFTWARE_ENGINEERING_APP_DEVELOPMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.APP_DEVELOPMENT'
    },
    jobFamilies_SOFTWARE_ENGINEERING_BACKEND_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.BACKEND_ENGINEERING'
    },
    jobFamilies_SOFTWARE_ENGINEERING_CLOUD_SOLUTIONS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.CLOUD_SOLUTIONS'
    },
    jobFamilies_SOFTWARE_ENGINEERING_COMPUTER_NETWORKS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.COMPUTER_NETWORKS'
    },
    jobFamilies_SOFTWARE_ENGINEERING_DATA_WAREHOUSING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.DATA_WAREHOUSING'
    },
    jobFamilies_SOFTWARE_ENGINEERING_DATABASE_ARCHITECTURE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.DATABASE_ARCHITECTURE'
    },
    jobFamilies_SOFTWARE_ENGINEERING_DEV_OPS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.DEV_OPS'
    },
    jobFamilies_SOFTWARE_ENGINEERING_DISTRIBUTED_SYSTEMS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.DISTRIBUTED_SYSTEMS'
    },
    jobFamilies_SOFTWARE_ENGINEERING_ENGINEERING_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.ENGINEERING_MANAGEMENT'
    },
    jobFamilies_SOFTWARE_ENGINEERING_FRONTEND_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.FRONTEND_ENGINEERING'
    },
    jobFamilies_SOFTWARE_ENGINEERING_FULL_STACK_DEVELOPMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.FULL_STACK_DEVELOPMENT'
    },
    jobFamilies_SOFTWARE_ENGINEERING_MACHINE_LEARNING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.MACHINE_LEARNING'
    },
    jobFamilies_SOFTWARE_ENGINEERING_MOBILE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.MOBILE'
    },
    jobFamilies_SOFTWARE_ENGINEERING_SCRUM_AND_AGILE_METHODS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.SCRUM_AND_AGILE_METHODS'
    },
    jobFamilies_SOFTWARE_ENGINEERING_SYSTEM_ARCHITECTURE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.SYSTEM_ARCHITECTURE'
    },
    jobFamilies_SOFTWARE_ENGINEERING_TESTING_AND_QUALITY_ASSURANCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.TESTING_AND_QUALITY_ASSURANCE'
    },
    jobFamilies_IT_SERVICES_COMPUTER_SYSTEMS_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.COMPUTER_SYSTEMS_MANAGEMENT'
    },
    jobFamilies_IT_SERVICES_DATA_PRIVACY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.DATA_PRIVACY'
    },
    jobFamilies_IT_SERVICES_INFORMATION_SECURITY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.INFORMATION_SECURITY'
    },
    jobFamilies_IT_SERVICES_IT_COORDINATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.IT_COORDINATION'
    },
    jobFamilies_IT_SERVICES_IT_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.IT_MANAGEMENT'
    },
    jobFamilies_IT_SERVICES_IT_PROJECT_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.IT_PROJECT_MANAGEMENT'
    },
    jobFamilies_IT_SERVICES_IT_SECURITY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.IT_SECURITY'
    },
    jobFamilies_IT_SERVICES_IT_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.IT_SUPPORT'
    },
    jobFamilies_IT_SERVICES_NETWORK_ADMINISTRATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.NETWORK_ADMINISTRATION'
    },
    jobFamilies_IT_SERVICES_SYSTEM_ADMINISTRATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.SYSTEM_ADMINISTRATION'
    },
    jobFamilies_IT_SERVICES_SYSTEM_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.SYSTEM_ANALYSIS'
    },
    jobFamilies_IT_SERVICES_SERVICE_DESK_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.SERVICE_DESK_ANALYSIS'
    },
    jobFamilies_EDUCATION_CAREER_COUNSELING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.CAREER_COUNSELING'
    },
    jobFamilies_EDUCATION_ED_TECH: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.ED_TECH'
    },
    jobFamilies_EDUCATION_EDUCATION_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.EDUCATION_MANAGEMENT'
    },
    jobFamilies_EDUCATION_LIBRARY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.LIBRARY'
    },
    jobFamilies_EDUCATION_TEACHING_PRIMARY_SCHOOL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.TEACHING_PRIMARY_SCHOOL'
    },
    jobFamilies_EDUCATION_TEACHING_SECONDARY_SCHOOL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.TEACHING_SECONDARY_SCHOOL'
    },
    jobFamilies_EDUCATION_TEACHING_UPPER_SECONDARY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.TEACHING_UPPER_SECONDARY'
    },
    jobFamilies_EDUCATION_TEACHING_UNIVERSITY_LEVEL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.TEACHING_UNIVERSITY_LEVEL'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_BAR: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.BAR'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_COOKING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.COOKING'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_FOOD_PREPARATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.FOOD_PREPARATION'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_HOSTING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.HOSTING'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_HOTEL_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.HOTEL_MANAGEMENT'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_RESTAURANT_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.RESTAURANT_MANAGEMENT'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_TRAVEL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.TRAVEL'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_WAITING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.WAITING'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_WINE_AND_BEVERAGES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.WINE_AND_BEVERAGES'
    },
    jobFamilies_HEALTHCARE_DENTISTRY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.DENTISTRY'
    },
    jobFamilies_HEALTHCARE_E_HEALTH: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.E_HEALTH'
    },
    jobFamilies_HEALTHCARE_HEALTHCARE_ADMIN_AND_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.HEALTHCARE_ADMIN_AND_SUPPORT'
    },
    jobFamilies_HEALTHCARE_HEALTHCARE_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.HEALTHCARE_MANAGEMENT'
    },
    jobFamilies_HEALTHCARE_MEDICINE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.MEDICINE'
    },
    jobFamilies_HEALTHCARE_MIDWIFERY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.MIDWIFERY'
    },
    jobFamilies_HEALTHCARE_NURSING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.NURSING'
    },
    jobFamilies_HEALTHCARE_OCCUPATIONAL_THERAPY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.OCCUPATIONAL_THERAPY'
    },
    jobFamilies_HEALTHCARE_OPTICS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.OPTICS'
    },
    jobFamilies_HEALTHCARE_PHARMACOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.PHARMACOLOGY'
    },
    jobFamilies_HEALTHCARE_PSYCHOLOGY_AND_PSYCHOTHERAPY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.PSYCHOLOGY_AND_PSYCHOTHERAPY'
    },
    jobFamilies_HEALTHCARE_PSYCHIATRY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.PSYCHIATRY'
    },
    jobFamilies_LEGAL_ARBITRATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.ARBITRATION'
    },
    jobFamilies_LEGAL_BUSINESS_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.BUSINESS_LAW'
    },
    jobFamilies_LEGAL_ENVIRONMENTAL_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.ENVIRONMENTAL_LAW'
    },
    jobFamilies_LEGAL_FAMILY_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.FAMILY_LAW'
    },
    jobFamilies_LEGAL_INTELLECTUAL_PROPERTY_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.INTELLECTUAL_PROPERTY_LAW'
    },
    jobFamilies_LEGAL_IT_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.IT_LAW'
    },
    jobFamilies_LEGAL_JUDICIARY_SYSTEM: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.JUDICIARY_SYSTEM'
    },
    jobFamilies_LEGAL_LABOR_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.LABOR_LAW'
    },
    jobFamilies_LEGAL_LAW_ADMINISTRATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.LAW_ADMINISTRATION'
    },
    jobFamilies_LEGAL_PATENT_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.PATENT_LAW'
    },
    jobFamilies_LEGAL_PUBLIC_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.PUBLIC_LAW'
    },
    jobFamilies_LEGAL_TAXATION_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.TAXATION_LAW'
    },
    jobFamilies_LIFE_SCIENCE_BIOCHEMISTRY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.BIOCHEMISTRY'
    },
    jobFamilies_LIFE_SCIENCE_BIOTECHNOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.BIOTECHNOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_BIOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.BIOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_CHEMICAL_BIOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.CHEMICAL_BIOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_CHEMICAL_TECHNOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.CHEMICAL_TECHNOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_CHEMISTRY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.CHEMISTRY'
    },
    jobFamilies_LIFE_SCIENCE_ENVIRONMENTAL_SCIENCES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.ENVIRONMENTAL_SCIENCES'
    },
    jobFamilies_LIFE_SCIENCE_GENETICS_AND_MOLECULAR_BIOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.GENETICS_AND_MOLECULAR_BIOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_MEDICAL_SCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.MEDICAL_SCIENCE'
    },
    jobFamilies_LIFE_SCIENCE_MEDICAL_TECHNOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.MEDICAL_TECHNOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_MICROBIOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.MICROBIOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_NEUROSCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.NEUROSCIENCE'
    },
    jobFamilies_LIFE_SCIENCE_PATHOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.PATHOLOGY'
    },
    jobFamilies_SOCIAL_SCIENCE_ANTHROPOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.ANTHROPOLOGY'
    },
    jobFamilies_SOCIAL_SCIENCE_ARCHAEOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.ARCHAEOLOGY'
    },
    jobFamilies_SOCIAL_SCIENCE_ECONOMICS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.ECONOMICS'
    },
    jobFamilies_SOCIAL_SCIENCE_FORENSIC_SCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.FORENSIC_SCIENCE'
    },
    jobFamilies_SOCIAL_SCIENCE_GEOSCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.GEOSCIENCE'
    },
    jobFamilies_SOCIAL_SCIENCE_HISTORY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.HISTORY'
    },
    jobFamilies_SOCIAL_SCIENCE_INDUSTRIAL_ORGANIZATIONAL_PSYCHOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.INDUSTRIAL_ORGANIZATIONAL_PSYCHOLOGY'
    },
    jobFamilies_SOCIAL_SCIENCE_OCCUPATIONAL_HEALTH: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.OCCUPATIONAL_HEALTH'
    },
    jobFamilies_SOCIAL_SCIENCE_POLITICAL_SCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.POLITICAL_SCIENCE'
    },
    jobFamilies_SOCIAL_SCIENCE_SOCIOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.SOCIOLOGY'
    },
    jobFamilies_SOCIAL_SCIENCE_URBAN_PLANNING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.URBAN_PLANNING'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_ENGINEERING_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.ENGINEERING_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_EXECUTIVE_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.EXECUTIVE_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_FINANCIAL_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.FINANCIAL_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_GROWTH_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.GROWTH_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_HUMAN_RESOURCE_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.HUMAN_RESOURCE_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_LEGAL_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.LEGAL_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_MARKETING_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.MARKETING_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_OPERATIONS_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.OPERATIONS_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_RISK_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.RISK_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_SALES_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.SALES_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_SUSTAINABILITY_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.SUSTAINABILITY_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_TECHNICAL_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.TECHNICAL_MANAGEMENT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_ACCOUNTING_AND_BOOKKEPING_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.ACCOUNTING_AND_BOOKKEPING_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_BILLING_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.BILLING_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_EQUIPMENT_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.EQUIPMENT_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_FINANCIAL_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.FINANCIAL_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_HR_ASSISTANCE_AND_PAYROLL_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.HR_ASSISTANCE_AND_PAYROLL_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_OFFICE_HOSTING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.OFFICE_HOSTING'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_OFFICE_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.OFFICE_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_RECEPTION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.RECEPTION'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_SECRETARY_SERVICES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.SECRETARY_SERVICES'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_SHIPPING_AND_INVENTORY_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.SHIPPING_AND_INVENTORY_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_SUPPLY_MAINTENANCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.SUPPLY_MAINTENANCE'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_TELEPHONE_OPERATIONS_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.TELEPHONE_OPERATIONS_SUPPORT'
    },
    jobFamilies_CUSTOMER_SERVICE_CUSTOMER_EXPERIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE.CUSTOMER_EXPERIENCE'
    },
    jobFamilies_CUSTOMER_SERVICE_CUSTOMER_SERVICE_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE.CUSTOMER_SERVICE_MANAGEMENT'
    },
    jobFamilies_CUSTOMER_SERVICE_CUSTOMER_SUCCESS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE.CUSTOMER_SUCCESS'
    },
    jobFamilies_CUSTOMER_SERVICE_CUSTOMER_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE.CUSTOMER_SUPPORT'
    },
    jobFamilies_CUSTOMER_SERVICE_IMPLEMENTATION_AND_ONBOARDING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE.IMPLEMENTATION_AND_ONBOARDING'
    },
    jobFamilies_CUSTOMER_SERVICE_TECHNICAL_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE.TECHNICAL_SUPPORT'
    },
    jobFamilies_SALES_BUSINESS_TO_BUSINESS_: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.BUSINESS_TO_BUSINESS_'
    },
    jobFamilies_SALES_BUSINESS_TO_CONSUMER: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.BUSINESS_TO_CONSUMER'
    },
    jobFamilies_SALES_FIELD_SALES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.FIELD_SALES'
    },
    jobFamilies_SALES_LONG_SALES_CYCLES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.LONG_SALES_CYCLES'
    },
    jobFamilies_SALES_NEW_BUSINESS_DEVELOPMENT_: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.NEW_BUSINESS_DEVELOPMENT_'
    },
    jobFamilies_SALES_PROCUREMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.PROCUREMENT'
    },
    jobFamilies_SALES_RETAIL_SALES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.RETAIL_SALES'
    },
    jobFamilies_SALES_SAAS_SOLUTIONS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.SAAS_SOLUTIONS'
    },
    jobFamilies_SALES_SALES_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.SALES_MANAGEMENT'
    },
    jobFamilies_SALES_SHORT_SALES_CYCLES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.SHORT_SALES_CYCLES'
    },
    jobFamilies_SALES_TECHNICAL_SALES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.TECHNICAL_SALES'
    },
    jobFamilies_SALES_TELEMARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.TELEMARKETING'
    },
    jobFamilies_SALES_TRANSACTIONAL_SALES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.TRANSACTIONAL_SALES'
    },
    jobFamilies_SALES_UPSELL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.UPSELL'
    },
    jobFamilies_TRANSPORTATION_AIRLINE_PILOTING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.AIRLINE_PILOTING'
    },
    jobFamilies_TRANSPORTATION_BUS_DRIVING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.BUS_DRIVING'
    },
    jobFamilies_TRANSPORTATION_FLIGHT_ATTENDANCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.FLIGHT_ATTENDANCE'
    },
    jobFamilies_TRANSPORTATION_INDUSTRIAL_TRUCK_AND_TRAILER_DRIVING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.INDUSTRIAL_TRUCK_AND_TRAILER_DRIVING'
    },
    jobFamilies_TRANSPORTATION_SHIPPING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.SHIPPING'
    },
    jobFamilies_TRANSPORTATION_TAXI_DRIVING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.TAXI_DRIVING'
    },
    jobFamilies_TRANSPORTATION_TRAILER_TRUCK_DRIVING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.TRAILER_TRUCK_DRIVING'
    },
    jobFamilies_TRANSPORTATION_TRUCK_DRIVING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.TRUCK_DRIVING'
    },

    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_ELECTRIC_AND_ELECTRONICS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.ELECTRIC_AND_ELECTRONICS'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_AUTOMOTIVE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.AUTOMOTIVE'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_WIND_OR_WATER: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.WIND_OR_WATER'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_ENERGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.ENERGY'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_MEDICAL_EQUIPMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.MEDICAL_EQUIPMENT'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_AEROSPACE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.AEROSPACE'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_ROBOTICS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.ROBOTICS'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_MECHANICAL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.MECHANICAL'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_HARDWARE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.HARDWARE'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_ENVIRONMENTAL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.ENVIRONMENTAL'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_LANDSCAPE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.LANDSCAPE'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_INDUSTRIAL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.INDUSTRIAL'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_CHEMICAL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.CHEMICAL'
    },
    companyTypes_START_UP_EARLY_STAGE: {
        id: 'app.pages.user.candidateRelevanceModal.companyTypes.START_UP_EARLY_STAGE'
    },
    companyTypes_SCALE_UP_IN_GROWTH_PHASE: {
        id: 'app.pages.user.candidateRelevanceModal.companyTypes.SCALE_UP_IN_GROWTH_PHASE'
    },
    companyTypes_ENTERPRISE: {
        id: 'app.pages.user.candidateRelevanceModal.companyTypes.ENTERPRISE'
    },
    companyTypes_AGENCY: {id: 'app.pages.user.candidateRelevanceModal.companyTypes.AGENCY'},
    companyTypes_CONSULTANCY: {
        id: 'app.pages.user.candidateRelevanceModal.companyTypes.CONSULTANCY'
    },
    languages_AA: {id: 'app.pages.user.candidateRelevanceModal.languages.AA'},
    languages_AB: {id: 'app.pages.user.candidateRelevanceModal.languages.AB'},
    languages_AE: {id: 'app.pages.user.candidateRelevanceModal.languages.AE'},
    languages_AF: {id: 'app.pages.user.candidateRelevanceModal.languages.AF'},
    languages_AK: {id: 'app.pages.user.candidateRelevanceModal.languages.AK'},
    languages_AM: {id: 'app.pages.user.candidateRelevanceModal.languages.AM'},
    languages_AN: {id: 'app.pages.user.candidateRelevanceModal.languages.AN'},
    languages_AR: {id: 'app.pages.user.candidateRelevanceModal.languages.AR'},
    languages_AS: {id: 'app.pages.user.candidateRelevanceModal.languages.AS'},
    languages_AV: {id: 'app.pages.user.candidateRelevanceModal.languages.AV'},
    languages_AY: {id: 'app.pages.user.candidateRelevanceModal.languages.AY'},
    languages_AZ: {id: 'app.pages.user.candidateRelevanceModal.languages.AZ'},
    languages_BA: {id: 'app.pages.user.candidateRelevanceModal.languages.BA'},
    languages_BE: {id: 'app.pages.user.candidateRelevanceModal.languages.BE'},
    languages_BG: {id: 'app.pages.user.candidateRelevanceModal.languages.BG'},
    languages_BI: {id: 'app.pages.user.candidateRelevanceModal.languages.BI'},
    languages_BM: {id: 'app.pages.user.candidateRelevanceModal.languages.BM'},
    languages_BN: {id: 'app.pages.user.candidateRelevanceModal.languages.BN'},
    languages_BO: {id: 'app.pages.user.candidateRelevanceModal.languages.BO'},
    languages_BR: {id: 'app.pages.user.candidateRelevanceModal.languages.BR'},
    languages_BS: {id: 'app.pages.user.candidateRelevanceModal.languages.BS'},
    languages_CA: {id: 'app.pages.user.candidateRelevanceModal.languages.CA'},
    languages_CE: {id: 'app.pages.user.candidateRelevanceModal.languages.CE'},
    languages_CH: {id: 'app.pages.user.candidateRelevanceModal.languages.CH'},
    languages_CO: {id: 'app.pages.user.candidateRelevanceModal.languages.CO'},
    languages_CR: {id: 'app.pages.user.candidateRelevanceModal.languages.CR'},
    languages_CS: {id: 'app.pages.user.candidateRelevanceModal.languages.CS'},
    languages_CU: {id: 'app.pages.user.candidateRelevanceModal.languages.CU'},
    languages_CV: {id: 'app.pages.user.candidateRelevanceModal.languages.CV'},
    languages_CY: {id: 'app.pages.user.candidateRelevanceModal.languages.CY'},
    languages_DA: {id: 'app.pages.user.candidateRelevanceModal.languages.DA'},
    languages_DE: {id: 'app.pages.user.candidateRelevanceModal.languages.DE'},
    languages_DV: {id: 'app.pages.user.candidateRelevanceModal.languages.DV'},
    languages_DZ: {id: 'app.pages.user.candidateRelevanceModal.languages.DZ'},
    languages_EE: {id: 'app.pages.user.candidateRelevanceModal.languages.EE'},
    languages_EL: {id: 'app.pages.user.candidateRelevanceModal.languages.EL'},
    languages_EN: {id: 'app.pages.user.candidateRelevanceModal.languages.EN'},
    languages_EO: {id: 'app.pages.user.candidateRelevanceModal.languages.EO'},
    languages_ES: {id: 'app.pages.user.candidateRelevanceModal.languages.ES'},
    languages_ET: {id: 'app.pages.user.candidateRelevanceModal.languages.ET'},
    languages_EU: {id: 'app.pages.user.candidateRelevanceModal.languages.EU'},
    languages_FA: {id: 'app.pages.user.candidateRelevanceModal.languages.FA'},
    languages_FF: {id: 'app.pages.user.candidateRelevanceModal.languages.FF'},
    languages_FI: {id: 'app.pages.user.candidateRelevanceModal.languages.FI'},
    languages_FJ: {id: 'app.pages.user.candidateRelevanceModal.languages.FJ'},
    languages_FO: {id: 'app.pages.user.candidateRelevanceModal.languages.FO'},
    languages_FR: {id: 'app.pages.user.candidateRelevanceModal.languages.FR'},
    languages_FY: {id: 'app.pages.user.candidateRelevanceModal.languages.FY'},
    languages_GA: {id: 'app.pages.user.candidateRelevanceModal.languages.GA'},
    languages_GD: {id: 'app.pages.user.candidateRelevanceModal.languages.GD'},
    languages_GL: {id: 'app.pages.user.candidateRelevanceModal.languages.GL'},
    languages_GN: {id: 'app.pages.user.candidateRelevanceModal.languages.GN'},
    languages_GU: {id: 'app.pages.user.candidateRelevanceModal.languages.GU'},
    languages_GV: {id: 'app.pages.user.candidateRelevanceModal.languages.GV'},
    languages_HA: {id: 'app.pages.user.candidateRelevanceModal.languages.HA'},
    languages_HE: {id: 'app.pages.user.candidateRelevanceModal.languages.HE'},
    languages_HI: {id: 'app.pages.user.candidateRelevanceModal.languages.HI'},
    languages_HO: {id: 'app.pages.user.candidateRelevanceModal.languages.HO'},
    languages_HR: {id: 'app.pages.user.candidateRelevanceModal.languages.HR'},
    languages_HT: {id: 'app.pages.user.candidateRelevanceModal.languages.HT'},
    languages_HU: {id: 'app.pages.user.candidateRelevanceModal.languages.HU'},
    languages_HY: {id: 'app.pages.user.candidateRelevanceModal.languages.HY'},
    languages_HZ: {id: 'app.pages.user.candidateRelevanceModal.languages.HZ'},
    languages_IA: {id: 'app.pages.user.candidateRelevanceModal.languages.IA'},
    languages_ID: {id: 'app.pages.user.candidateRelevanceModal.languages.ID'},
    languages_IE: {id: 'app.pages.user.candidateRelevanceModal.languages.IE'},
    languages_IG: {id: 'app.pages.user.candidateRelevanceModal.languages.IG'},
    languages_II: {id: 'app.pages.user.candidateRelevanceModal.languages.II'},
    languages_IK: {id: 'app.pages.user.candidateRelevanceModal.languages.IK'},
    languages_IO: {id: 'app.pages.user.candidateRelevanceModal.languages.IO'},
    languages_IS: {id: 'app.pages.user.candidateRelevanceModal.languages.IS'},
    languages_IT: {id: 'app.pages.user.candidateRelevanceModal.languages.IT'},
    languages_IU: {id: 'app.pages.user.candidateRelevanceModal.languages.IU'},
    languages_JA: {id: 'app.pages.user.candidateRelevanceModal.languages.JA'},
    languages_JV: {id: 'app.pages.user.candidateRelevanceModal.languages.JV'},
    languages_KA: {id: 'app.pages.user.candidateRelevanceModal.languages.KA'},
    languages_KG: {id: 'app.pages.user.candidateRelevanceModal.languages.KG'},
    languages_KI: {id: 'app.pages.user.candidateRelevanceModal.languages.KI'},
    languages_KJ: {id: 'app.pages.user.candidateRelevanceModal.languages.KJ'},
    languages_KK: {id: 'app.pages.user.candidateRelevanceModal.languages.KK'},
    languages_KL: {id: 'app.pages.user.candidateRelevanceModal.languages.KL'},
    languages_KM: {id: 'app.pages.user.candidateRelevanceModal.languages.KM'},
    languages_KN: {id: 'app.pages.user.candidateRelevanceModal.languages.KN'},
    languages_KO: {id: 'app.pages.user.candidateRelevanceModal.languages.KO'},
    languages_KR: {id: 'app.pages.user.candidateRelevanceModal.languages.KR'},
    languages_KS: {id: 'app.pages.user.candidateRelevanceModal.languages.KS'},
    languages_KU: {id: 'app.pages.user.candidateRelevanceModal.languages.KU'},
    languages_KV: {id: 'app.pages.user.candidateRelevanceModal.languages.KV'},
    languages_KW: {id: 'app.pages.user.candidateRelevanceModal.languages.KW'},
    languages_KY: {id: 'app.pages.user.candidateRelevanceModal.languages.KY'},
    languages_LA: {id: 'app.pages.user.candidateRelevanceModal.languages.LA'},
    languages_LB: {id: 'app.pages.user.candidateRelevanceModal.languages.LB'},
    languages_LG: {id: 'app.pages.user.candidateRelevanceModal.languages.LG'},
    languages_LI: {id: 'app.pages.user.candidateRelevanceModal.languages.LI'},
    languages_LN: {id: 'app.pages.user.candidateRelevanceModal.languages.LN'},
    languages_LO: {id: 'app.pages.user.candidateRelevanceModal.languages.LO'},
    languages_LT: {id: 'app.pages.user.candidateRelevanceModal.languages.LT'},
    languages_LU: {id: 'app.pages.user.candidateRelevanceModal.languages.LU'},
    languages_LV: {id: 'app.pages.user.candidateRelevanceModal.languages.LV'},
    languages_MG: {id: 'app.pages.user.candidateRelevanceModal.languages.MG'},
    languages_MH: {id: 'app.pages.user.candidateRelevanceModal.languages.MH'},
    languages_MI: {id: 'app.pages.user.candidateRelevanceModal.languages.MI'},
    languages_MK: {id: 'app.pages.user.candidateRelevanceModal.languages.MK'},
    languages_ML: {id: 'app.pages.user.candidateRelevanceModal.languages.ML'},
    languages_MN: {id: 'app.pages.user.candidateRelevanceModal.languages.MN'},
    languages_MR: {id: 'app.pages.user.candidateRelevanceModal.languages.MR'},
    languages_MS: {id: 'app.pages.user.candidateRelevanceModal.languages.MS'},
    languages_MT: {id: 'app.pages.user.candidateRelevanceModal.languages.MT'},
    languages_MY: {id: 'app.pages.user.candidateRelevanceModal.languages.MY'},
    languages_NA: {id: 'app.pages.user.candidateRelevanceModal.languages.NA'},
    languages_NB: {id: 'app.pages.user.candidateRelevanceModal.languages.NB'},
    languages_ND: {id: 'app.pages.user.candidateRelevanceModal.languages.ND'},
    languages_NE: {id: 'app.pages.user.candidateRelevanceModal.languages.NE'},
    languages_NG: {id: 'app.pages.user.candidateRelevanceModal.languages.NG'},
    languages_NL: {id: 'app.pages.user.candidateRelevanceModal.languages.NL'},
    languages_NN: {id: 'app.pages.user.candidateRelevanceModal.languages.NN'},
    languages_NO: {id: 'app.pages.user.candidateRelevanceModal.languages.NO'},
    languages_NR: {id: 'app.pages.user.candidateRelevanceModal.languages.NR'},
    languages_NV: {id: 'app.pages.user.candidateRelevanceModal.languages.NV'},
    languages_NY: {id: 'app.pages.user.candidateRelevanceModal.languages.NY'},
    languages_OC: {id: 'app.pages.user.candidateRelevanceModal.languages.OC'},
    languages_OJ: {id: 'app.pages.user.candidateRelevanceModal.languages.OJ'},
    languages_OM: {id: 'app.pages.user.candidateRelevanceModal.languages.OM'},
    languages_OR: {id: 'app.pages.user.candidateRelevanceModal.languages.OR'},
    languages_OS: {id: 'app.pages.user.candidateRelevanceModal.languages.OS'},
    languages_PA: {id: 'app.pages.user.candidateRelevanceModal.languages.PA'},
    languages_PI: {id: 'app.pages.user.candidateRelevanceModal.languages.PI'},
    languages_PL: {id: 'app.pages.user.candidateRelevanceModal.languages.PL'},
    languages_PS: {id: 'app.pages.user.candidateRelevanceModal.languages.PS'},
    languages_PT: {id: 'app.pages.user.candidateRelevanceModal.languages.PT'},
    languages_QU: {id: 'app.pages.user.candidateRelevanceModal.languages.QU'},
    languages_RM: {id: 'app.pages.user.candidateRelevanceModal.languages.RM'},
    languages_RN: {id: 'app.pages.user.candidateRelevanceModal.languages.RN'},
    languages_RO: {id: 'app.pages.user.candidateRelevanceModal.languages.RO'},
    languages_RU: {id: 'app.pages.user.candidateRelevanceModal.languages.RU'},
    languages_RW: {id: 'app.pages.user.candidateRelevanceModal.languages.RW'},
    languages_SA: {id: 'app.pages.user.candidateRelevanceModal.languages.SA'},
    languages_SC: {id: 'app.pages.user.candidateRelevanceModal.languages.SC'},
    languages_SD: {id: 'app.pages.user.candidateRelevanceModal.languages.SD'},
    languages_SE: {id: 'app.pages.user.candidateRelevanceModal.languages.SE'},
    languages_SG: {id: 'app.pages.user.candidateRelevanceModal.languages.SG'},
    languages_SI: {id: 'app.pages.user.candidateRelevanceModal.languages.SI'},
    languages_SK: {id: 'app.pages.user.candidateRelevanceModal.languages.SK'},
    languages_SL: {id: 'app.pages.user.candidateRelevanceModal.languages.SL'},
    languages_SM: {id: 'app.pages.user.candidateRelevanceModal.languages.SM'},
    languages_SN: {id: 'app.pages.user.candidateRelevanceModal.languages.SN'},
    languages_SO: {id: 'app.pages.user.candidateRelevanceModal.languages.SO'},
    languages_SQ: {id: 'app.pages.user.candidateRelevanceModal.languages.SQ'},
    languages_SR: {id: 'app.pages.user.candidateRelevanceModal.languages.SR'},
    languages_SS: {id: 'app.pages.user.candidateRelevanceModal.languages.SS'},
    languages_ST: {id: 'app.pages.user.candidateRelevanceModal.languages.ST'},
    languages_SU: {id: 'app.pages.user.candidateRelevanceModal.languages.SU'},
    languages_SV: {id: 'app.pages.user.candidateRelevanceModal.languages.SV'},
    languages_SW: {id: 'app.pages.user.candidateRelevanceModal.languages.SW'},
    languages_TA: {id: 'app.pages.user.candidateRelevanceModal.languages.TA'},
    languages_TE: {id: 'app.pages.user.candidateRelevanceModal.languages.TE'},
    languages_TG: {id: 'app.pages.user.candidateRelevanceModal.languages.TG'},
    languages_TH: {id: 'app.pages.user.candidateRelevanceModal.languages.TH'},
    languages_TI: {id: 'app.pages.user.candidateRelevanceModal.languages.TI'},
    languages_TK: {id: 'app.pages.user.candidateRelevanceModal.languages.TK'},
    languages_TL: {id: 'app.pages.user.candidateRelevanceModal.languages.TL'},
    languages_TN: {id: 'app.pages.user.candidateRelevanceModal.languages.TN'},
    languages_TO: {id: 'app.pages.user.candidateRelevanceModal.languages.TO'},
    languages_TR: {id: 'app.pages.user.candidateRelevanceModal.languages.TR'},
    languages_TS: {id: 'app.pages.user.candidateRelevanceModal.languages.TS'},
    languages_TT: {id: 'app.pages.user.candidateRelevanceModal.languages.TT'},
    languages_TW: {id: 'app.pages.user.candidateRelevanceModal.languages.TW'},
    languages_TY: {id: 'app.pages.user.candidateRelevanceModal.languages.TY'},
    languages_UG: {id: 'app.pages.user.candidateRelevanceModal.languages.UG'},
    languages_UK: {id: 'app.pages.user.candidateRelevanceModal.languages.UK'},
    languages_UR: {id: 'app.pages.user.candidateRelevanceModal.languages.UR'},
    languages_UZ: {id: 'app.pages.user.candidateRelevanceModal.languages.UZ'},
    languages_VE: {id: 'app.pages.user.candidateRelevanceModal.languages.VE'},
    languages_VI: {id: 'app.pages.user.candidateRelevanceModal.languages.VI'},
    languages_VO: {id: 'app.pages.user.candidateRelevanceModal.languages.VO'},
    languages_WA: {id: 'app.pages.user.candidateRelevanceModal.languages.WA'},
    languages_WO: {id: 'app.pages.user.candidateRelevanceModal.languages.WO'},
    languages_XH: {id: 'app.pages.user.candidateRelevanceModal.languages.XH'},
    languages_YI: {id: 'app.pages.user.candidateRelevanceModal.languages.YI'},
    languages_YO: {id: 'app.pages.user.candidateRelevanceModal.languages.YO'},
    languages_ZA: {id: 'app.pages.user.candidateRelevanceModal.languages.ZA'},
    languages_ZH: {id: 'app.pages.user.candidateRelevanceModal.languages.ZH'},
    languages_ZU: {id: 'app.pages.user.candidateRelevanceModal.languages.ZU'},
    languages_ENG: {id: 'app.pages.user.candidateRelevanceModal.languages.ENG'},
    languages_CMN: {id: 'app.pages.user.candidateRelevanceModal.languages.CMN'},
    languages_HIN: {id: 'app.pages.user.candidateRelevanceModal.languages.HIN'},
    languages_SPA: {id: 'app.pages.user.candidateRelevanceModal.languages.SPA'},
    languages_FRA: {id: 'app.pages.user.candidateRelevanceModal.languages.FRA'},
    languages_ARB: {id: 'app.pages.user.candidateRelevanceModal.languages.ARB'},
    languages_BEN: {id: 'app.pages.user.candidateRelevanceModal.languages.BEN'},
    languages_RUS: {id: 'app.pages.user.candidateRelevanceModal.languages.RUS'},
    languages_POR: {id: 'app.pages.user.candidateRelevanceModal.languages.POR'},
    languages_URD: {id: 'app.pages.user.candidateRelevanceModal.languages.URD'},
    languages_IND: {id: 'app.pages.user.candidateRelevanceModal.languages.IND'},
    languages_DEU: {id: 'app.pages.user.candidateRelevanceModal.languages.DEU'},
    languages_JPN: {id: 'app.pages.user.candidateRelevanceModal.languages.JPN'},
    languages_PCM: {id: 'app.pages.user.candidateRelevanceModal.languages.PCM'},
    languages_MAR: {id: 'app.pages.user.candidateRelevanceModal.languages.MAR'},
    languages_TEL: {id: 'app.pages.user.candidateRelevanceModal.languages.TEL'},
    languages_TUR: {id: 'app.pages.user.candidateRelevanceModal.languages.TUR'},
    languages_TAM: {id: 'app.pages.user.candidateRelevanceModal.languages.TAM'},
    languages_YUE: {id: 'app.pages.user.candidateRelevanceModal.languages.YUE'},
    languages_VIE: {id: 'app.pages.user.candidateRelevanceModal.languages.VIE'},
    languages_TGL: {id: 'app.pages.user.candidateRelevanceModal.languages.TGL'},
    languages_WUU: {id: 'app.pages.user.candidateRelevanceModal.languages.WUU'},
    languages_KOR: {id: 'app.pages.user.candidateRelevanceModal.languages.KOR'},
    languages_PES: {id: 'app.pages.user.candidateRelevanceModal.languages.PES'},
    languages_HAU: {id: 'app.pages.user.candidateRelevanceModal.languages.HAU'},
    languages_ARZ: {id: 'app.pages.user.candidateRelevanceModal.languages.ARZ'},
    languages_SWH: {id: 'app.pages.user.candidateRelevanceModal.languages.SWH'},
    languages_JAV: {id: 'app.pages.user.candidateRelevanceModal.languages.JAV'},
    languages_ITA: {id: 'app.pages.user.candidateRelevanceModal.languages.ITA'},
    languages_PNB: {id: 'app.pages.user.candidateRelevanceModal.languages.PNB'},
    languages_GUJ: {id: 'app.pages.user.candidateRelevanceModal.languages.GUJ'},
    languages_THA: {id: 'app.pages.user.candidateRelevanceModal.languages.THA'},
    languages_KAN: {id: 'app.pages.user.candidateRelevanceModal.languages.KAN'},
    languages_AMH: {id: 'app.pages.user.candidateRelevanceModal.languages.AMH'},
    languages_BHO: {id: 'app.pages.user.candidateRelevanceModal.languages.BHO'},
    languages_PAN: {id: 'app.pages.user.candidateRelevanceModal.languages.PAN'},
    languages_NAN: {id: 'app.pages.user.candidateRelevanceModal.languages.NAN'},
    languages_CJY: {id: 'app.pages.user.candidateRelevanceModal.languages.CJY'},
    languages_YOR: {id: 'app.pages.user.candidateRelevanceModal.languages.YOR'},
    languages_HAK: {id: 'app.pages.user.candidateRelevanceModal.languages.HAK'},
    languages_MYA: {id: 'app.pages.user.candidateRelevanceModal.languages.MYA'},
    languages_APD: {id: 'app.pages.user.candidateRelevanceModal.languages.APD'},
    languages_POL: {id: 'app.pages.user.candidateRelevanceModal.languages.POL'},
    languages_ARQ: {id: 'app.pages.user.candidateRelevanceModal.languages.ARQ'},
    languages_LIN: {id: 'app.pages.user.candidateRelevanceModal.languages.LIN'},
    languages_ORY: {id: 'app.pages.user.candidateRelevanceModal.languages.ORY'},
    languages_HSN: {id: 'app.pages.user.candidateRelevanceModal.languages.HSN'},
    languages_MAL: {id: 'app.pages.user.candidateRelevanceModal.languages.MAL'},
    languages_MAI: {id: 'app.pages.user.candidateRelevanceModal.languages.MAI'},
    languages_ARY: {id: 'app.pages.user.candidateRelevanceModal.languages.ARY'},
    languages_UKR: {id: 'app.pages.user.candidateRelevanceModal.languages.UKR'},
    languages_SND: {id: 'app.pages.user.candidateRelevanceModal.languages.SND'},
    languages_SUN: {id: 'app.pages.user.candidateRelevanceModal.languages.SUN'},
    languages_APC: {id: 'app.pages.user.candidateRelevanceModal.languages.APC'},
    languages_IBO: {id: 'app.pages.user.candidateRelevanceModal.languages.IBO'},
    languages_PBU: {id: 'app.pages.user.candidateRelevanceModal.languages.PBU'},
    languages_PRS: {id: 'app.pages.user.candidateRelevanceModal.languages.PRS'},
    languages_ZUL: {id: 'app.pages.user.candidateRelevanceModal.languages.ZUL'},
    languages_UZN: {id: 'app.pages.user.candidateRelevanceModal.languages.UZN'},
    languages_SKR: {id: 'app.pages.user.candidateRelevanceModal.languages.SKR'},
    languages_NPI: {id: 'app.pages.user.candidateRelevanceModal.languages.NPI'},
    languages_NLD: {id: 'app.pages.user.candidateRelevanceModal.languages.NLD'},
    languages_AEC: {id: 'app.pages.user.candidateRelevanceModal.languages.AEC'},
    languages_RON: {id: 'app.pages.user.candidateRelevanceModal.languages.RON'},
    languages_GAN: {id: 'app.pages.user.candidateRelevanceModal.languages.GAN'},
    languages_SOM: {id: 'app.pages.user.candidateRelevanceModal.languages.SOM'},
    languages_MAG: {id: 'app.pages.user.candidateRelevanceModal.languages.MAG'},
    languages_PBT: {id: 'app.pages.user.candidateRelevanceModal.languages.PBT'},
    languages_XHO: {id: 'app.pages.user.candidateRelevanceModal.languages.XHO'},
    languages_GAZ: {id: 'app.pages.user.candidateRelevanceModal.languages.GAZ'},
    languages_ZLM: {id: 'app.pages.user.candidateRelevanceModal.languages.ZLM'},
    languages_ACM: {id: 'app.pages.user.candidateRelevanceModal.languages.ACM'},
    languages_ARS: {id: 'app.pages.user.candidateRelevanceModal.languages.ARS'},
    languages_KHM: {id: 'app.pages.user.candidateRelevanceModal.languages.KHM'},
    languages_AFR: {id: 'app.pages.user.candidateRelevanceModal.languages.AFR'},
    languages_SIN: {id: 'app.pages.user.candidateRelevanceModal.languages.SIN'},
    languages_FUV: {id: 'app.pages.user.candidateRelevanceModal.languages.FUV'},
    languages_HNE: {id: 'app.pages.user.candidateRelevanceModal.languages.HNE'},
    languages_CEB: {id: 'app.pages.user.candidateRelevanceModal.languages.CEB'},
    languages_KMR: {id: 'app.pages.user.candidateRelevanceModal.languages.KMR'},
    languages_ASM: {id: 'app.pages.user.candidateRelevanceModal.languages.ASM'},
    languages_TTS: {id: 'app.pages.user.candidateRelevanceModal.languages.TTS'},
    languages_AZB: {id: 'app.pages.user.candidateRelevanceModal.languages.AZB'},
    languages_BAR: {id: 'app.pages.user.candidateRelevanceModal.languages.BAR'},
    languages_NYA: {id: 'app.pages.user.candidateRelevanceModal.languages.NYA'},
    languages_BAM: {id: 'app.pages.user.candidateRelevanceModal.languages.BAM'},
    languages_TSN: {id: 'app.pages.user.candidateRelevanceModal.languages.TSN'},
    languages_NSO: {id: 'app.pages.user.candidateRelevanceModal.languages.NSO'},
    languages_SOT: {id: 'app.pages.user.candidateRelevanceModal.languages.SOT'},
    languages_CES: {id: 'app.pages.user.candidateRelevanceModal.languages.CES'},
    languages_ELL: {id: 'app.pages.user.candidateRelevanceModal.languages.ELL'},
    languages_KIN: {id: 'app.pages.user.candidateRelevanceModal.languages.KIN'},
    languages_SWE: {id: 'app.pages.user.candidateRelevanceModal.languages.SWE'},
    languages_CTG: {id: 'app.pages.user.candidateRelevanceModal.languages.CTG'},
    languages_DCC: {id: 'app.pages.user.candidateRelevanceModal.languages.DCC'},
    languages_AJP: {id: 'app.pages.user.candidateRelevanceModal.languages.AJP'},
    languages_KAZ: {id: 'app.pages.user.candidateRelevanceModal.languages.KAZ'},
    languages_AYN: {id: 'app.pages.user.candidateRelevanceModal.languages.AYN'},
    languages_HUN: {id: 'app.pages.user.candidateRelevanceModal.languages.HUN'},
    languages_DYU: {id: 'app.pages.user.candidateRelevanceModal.languages.DYU'},
    languages_WOL: {id: 'app.pages.user.candidateRelevanceModal.languages.WOL'},
    languages_SCK: {id: 'app.pages.user.candidateRelevanceModal.languages.SCK'},
    languages_WES: {id: 'app.pages.user.candidateRelevanceModal.languages.WES'},
    languages_ACQ: {id: 'app.pages.user.candidateRelevanceModal.languages.ACQ'},
    languages_AEB: {id: 'app.pages.user.candidateRelevanceModal.languages.AEB'},
    languages_MNP: {id: 'app.pages.user.candidateRelevanceModal.languages.MNP'},
    languages_SYL: {id: 'app.pages.user.candidateRelevanceModal.languages.SYL'},
    languages_RUN: {id: 'app.pages.user.candidateRelevanceModal.languages.RUN'},
    languages_KNG: {id: 'app.pages.user.candidateRelevanceModal.languages.KNG'},
    languages_SWC: {id: 'app.pages.user.candidateRelevanceModal.languages.SWC'},
    languages_LUG: {id: 'app.pages.user.candidateRelevanceModal.languages.LUG'},
    languages_SNA: {id: 'app.pages.user.candidateRelevanceModal.languages.SNA'},
    languages_CDO: {id: 'app.pages.user.candidateRelevanceModal.languages.CDO'},
    languages_RKT: {id: 'app.pages.user.candidateRelevanceModal.languages.RKT'},
    languages_ACW: {id: 'app.pages.user.candidateRelevanceModal.languages.ACW'},
    languages_IBB: {id: 'app.pages.user.candidateRelevanceModal.languages.IBB'},
    languages_AFB: {id: 'app.pages.user.candidateRelevanceModal.languages.AFB'},
    languages_UIG: {id: 'app.pages.user.candidateRelevanceModal.languages.UIG'},
    languages_SRP: {id: 'app.pages.user.candidateRelevanceModal.languages.SRP'},
    languages_AYP: {id: 'app.pages.user.candidateRelevanceModal.languages.AYP'},
    languages_TIR: {id: 'app.pages.user.candidateRelevanceModal.languages.TIR'},
    languages_TSO: {id: 'app.pages.user.candidateRelevanceModal.languages.TSO'},
    languages_BGC: {id: 'app.pages.user.candidateRelevanceModal.languages.BGC'},
    languages_HAE: {id: 'app.pages.user.candidateRelevanceModal.languages.HAE'},
    languages_HEB: {id: 'app.pages.user.candidateRelevanceModal.languages.HEB'},
    languages_AKA: {id: 'app.pages.user.candidateRelevanceModal.languages.AKA'},
    languages_CAT: {id: 'app.pages.user.candidateRelevanceModal.languages.CAT'},
    languages_AZJ: {id: 'app.pages.user.candidateRelevanceModal.languages.AZJ'},
    languages_KNC: {id: 'app.pages.user.candidateRelevanceModal.languages.KNC'},
    languages_PST: {id: 'app.pages.user.candidateRelevanceModal.languages.PST'},
    languages_GAX: {id: 'app.pages.user.candidateRelevanceModal.languages.GAX'},
    languages_BUL: {id: 'app.pages.user.candidateRelevanceModal.languages.BUL'},
    languages_KRI: {id: 'app.pages.user.candidateRelevanceModal.languages.KRI'},
    languages_TGK: {id: 'app.pages.user.candidateRelevanceModal.languages.TGK'},
    languages_HAT: {id: 'app.pages.user.candidateRelevanceModal.languages.HAT'},
    languages_KIK: {id: 'app.pages.user.candidateRelevanceModal.languages.KIK'},
    languages_SUK: {id: 'app.pages.user.candidateRelevanceModal.languages.SUK'},
    languages_MOS: {id: 'app.pages.user.candidateRelevanceModal.languages.MOS'},
    languages_RWR: {id: 'app.pages.user.candidateRelevanceModal.languages.RWR'},
    languages_MAD: {id: 'app.pages.user.candidateRelevanceModal.languages.MAD'},
    languages_SAT: {id: 'app.pages.user.candidateRelevanceModal.languages.SAT'},
    languages_PLT: {id: 'app.pages.user.candidateRelevanceModal.languages.PLT'},
    languages_SLK: {id: 'app.pages.user.candidateRelevanceModal.languages.SLK'},
    languages_KAS: {id: 'app.pages.user.candidateRelevanceModal.languages.KAS'},
    languages_LUA: {id: 'app.pages.user.candidateRelevanceModal.languages.LUA'},
    languages_UMB: {id: 'app.pages.user.candidateRelevanceModal.languages.UMB'},
    languages_VAH: {id: 'app.pages.user.candidateRelevanceModal.languages.VAH'},
    languages_KAB: {id: 'app.pages.user.candidateRelevanceModal.languages.KAB'},
    languages_INS: {id: 'app.pages.user.candidateRelevanceModal.languages.INS'},
    languages_HRV: {id: 'app.pages.user.candidateRelevanceModal.languages.HRV'},
    languages_TUK: {id: 'app.pages.user.candidateRelevanceModal.languages.TUK'},
    languages_GUG: {id: 'app.pages.user.candidateRelevanceModal.languages.GUG'},
    languages_ILO: {id: 'app.pages.user.candidateRelevanceModal.languages.ILO'},
    languages_GSW: {id: 'app.pages.user.candidateRelevanceModal.languages.GSW'},
    languages_HIL: {id: 'app.pages.user.candidateRelevanceModal.languages.HIL'},
    languages_NOD: {id: 'app.pages.user.candidateRelevanceModal.languages.NOD'},
    languages_BJJ: {id: 'app.pages.user.candidateRelevanceModal.languages.BJJ'},
    languages_NAP: {id: 'app.pages.user.candidateRelevanceModal.languages.NAP'},
    languages_FUB: {id: 'app.pages.user.candidateRelevanceModal.languages.FUB'},
    languages_FIN: {id: 'app.pages.user.candidateRelevanceModal.languages.FIN'},
    languages_BNS: {id: 'app.pages.user.candidateRelevanceModal.languages.BNS'},
    languages_DAN: {id: 'app.pages.user.candidateRelevanceModal.languages.DAN'},
    languages_EWE: {id: 'app.pages.user.candidateRelevanceModal.languages.EWE'},
    languages_MUP: {id: 'app.pages.user.candidateRelevanceModal.languages.MUP'},
    languages_FUC: {id: 'app.pages.user.candidateRelevanceModal.languages.FUC'},
    languages_CZH: {id: 'app.pages.user.candidateRelevanceModal.languages.CZH'},
    languages_AYL: {id: 'app.pages.user.candidateRelevanceModal.languages.AYL'},
    languages_TAT: {id: 'app.pages.user.candidateRelevanceModal.languages.TAT'},
    languages_NOR: {id: 'app.pages.user.candidateRelevanceModal.languages.NOR'},
    languages_UZS: {id: 'app.pages.user.candidateRelevanceModal.languages.UZS'},
    languages_CKB: {id: 'app.pages.user.candidateRelevanceModal.languages.CKB'},
    languages_KAM: {id: 'app.pages.user.candidateRelevanceModal.languages.KAM'},
    languages_LUO: {id: 'app.pages.user.candidateRelevanceModal.languages.LUO'},
    languages_SAG: {id: 'app.pages.user.candidateRelevanceModal.languages.SAG'},
    languages_KIR: {id: 'app.pages.user.candidateRelevanceModal.languages.KIR'},
    languages_SHI: {id: 'app.pages.user.candidateRelevanceModal.languages.SHI'},
    languages_AYH: {id: 'app.pages.user.candidateRelevanceModal.languages.AYH'},
    languages_LIR: {id: 'app.pages.user.candidateRelevanceModal.languages.LIR'},
    languages_LMN: {id: 'app.pages.user.candidateRelevanceModal.languages.LMN'},
    languages_SAN: {id: 'app.pages.user.candidateRelevanceModal.languages.SAN'},
    languages_GPE: {id: 'app.pages.user.candidateRelevanceModal.languages.GPE'},
    languages_BEW: {id: 'app.pages.user.candidateRelevanceModal.languages.BEW'},
    languages_KTU: {id: 'app.pages.user.candidateRelevanceModal.languages.KTU'},
    languages_MEY: {id: 'app.pages.user.candidateRelevanceModal.languages.MEY'},
    languages_MIN: {id: 'app.pages.user.candidateRelevanceModal.languages.MIN'},
    languages_FUF: {id: 'app.pages.user.candidateRelevanceModal.languages.FUF'},
    languages_TZM: {id: 'app.pages.user.candidateRelevanceModal.languages.TZM'},
    languages_SSW: {id: 'app.pages.user.candidateRelevanceModal.languages.SSW'},
    languages_SCN: {id: 'app.pages.user.candidateRelevanceModal.languages.SCN'},
    languages_SHN: {id: 'app.pages.user.candidateRelevanceModal.languages.SHN'},
    languages_BCI: {id: 'app.pages.user.candidateRelevanceModal.languages.BCI'},
    languages_TIV: {id: 'app.pages.user.candidateRelevanceModal.languages.TIV'},
    languages_SOU: {id: 'app.pages.user.candidateRelevanceModal.languages.SOU'},
    languages_LAO: {id: 'app.pages.user.candidateRelevanceModal.languages.LAO'},
    languages_SID: {id: 'app.pages.user.candidateRelevanceModal.languages.SID'},
    languages_RIF: {id: 'app.pages.user.candidateRelevanceModal.languages.RIF'},
    languages_BUG: {id: 'app.pages.user.candidateRelevanceModal.languages.BUG'},
    languages_AWA: {id: 'app.pages.user.candidateRelevanceModal.languages.AWA'},
    languages_DJE: {id: 'app.pages.user.candidateRelevanceModal.languages.DJE'},
    languages_MTR: {id: 'app.pages.user.candidateRelevanceModal.languages.MTR'}
});
