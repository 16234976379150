import {SpacingSmall, SpacingXlarge} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 360px;
    display: flex;
    flex-direction: column;
    gap: ${SpacingXlarge};
    margin: 0 auto;
    justify-content: center;
    height: 100%;
`;

export const SmallGapWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingSmall};
`;
