import {Plot} from '@/componentLibrary/components/charts/plotlyBuild';

import {useDonutChartLogic} from './logic';
import {DonutChartProps} from './types';

export const DonutChart = ({data, layoutOptions, tracesOptions}: DonutChartProps) => {
    const {traces, layout} = useDonutChartLogic(data, layoutOptions, tracesOptions);

    return (
        <Plot
            data={traces}
            layout={layout}
            config={{displayModeBar: false}}
            useResizeHandler
        />
    );
};
