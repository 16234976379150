import qs from 'qs';
import {useCallback, useEffect, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {LINKS} from '@/links';
import {clearSelectedOrganization} from '@/services/auth/utils';

export function useSignupPageLogic() {
    const location = useLocation();
    const history = useHistory();

    const token = useMemo(() => {
        const params = qs.parse(location.search, {ignoreQueryPrefix: true});
        return params.t && typeof params.t === 'string'
            ? window.decodeURIComponent(params.t)
            : '';
    }, [location.search]);

    const redirectToLogin = useCallback(() => {
        history.push(LINKS.login);
    }, [history]);

    useEffect(() => {
        clearSelectedOrganization();
    }, []);

    return {token, redirectToLogin};
}
