import {useMutation} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {GET_MY_JOB_APPLICATIONS, GET_MY_TESTS_PROGRESS} from '@/api/users/queries';

import {ShareLogicTestWithOrganizationMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {SHARE_LOGIC_TEST_WITH_ORGANIZATION} from './mutations';

export function useShareLogicTestWithOrganization(
    organizationId: number,
    userId: number | null,
    logicTestId: string | null
): MutationHookReturnType<ShareLogicTestWithOrganizationMutation> {
    const options = useMemo(
        () => ({
            refetchQueries: [
                {
                    query: GET_MY_TESTS_PROGRESS,
                    variables: {
                        organizationId
                    }
                },
                {
                    query: GET_MY_JOB_APPLICATIONS
                }
            ]
        }),
        [organizationId]
    );

    const [mutate, {error, loading}] = useMutation(
        SHARE_LOGIC_TEST_WITH_ORGANIZATION,
        options
    );
    const doShareLogicTest = useCallback(() => {
        if (!userId) {
            return Promise.reject(new Error('User id is required'));
        }
        if (!logicTestId) {
            return Promise.reject(new Error('Logic test id is required'));
        }
        const variables = {
            data: {organizationId, userId, logicTestId}
        };
        return mutate({variables});
    }, [organizationId, userId, logicTestId, mutate]);

    return [doShareLogicTest, {error, loading}];
}
