import styled from 'styled-components';

import {ColorBaseGrey000} from '@/componentLibrary/tokens/variables';

export const Overlay = styled.div<{isVisible?: boolean}>`
    background-color: ${ColorBaseGrey000};
    opacity: 0.6;
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    ${({isVisible}) => !isVisible && 'display: none;'}
`;
