import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {SaveAnswerForLogicTestQuestionMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {SAVE_ANSWER_FOR_LOGIC_TEST_QUESTION} from './mutations';

export function useSaveAnswerForLogicTest(
    logicTestId: string
): MutationHookReturnType<
    SaveAnswerForLogicTestQuestionMutation,
    [string, (string | null)?, boolean?]
> {
    const [mutate, {error, loading}] = useMutation(SAVE_ANSWER_FOR_LOGIC_TEST_QUESTION);

    const saveAnswerForLogicTest = useCallback(
        (questionId: string, answer?: string | null, requestNewQuestion?: boolean) => {
            const data = {
                logicTestId: logicTestId,
                questionId: questionId,
                answer: answer,
                requestNewQuestion: !!requestNewQuestion
            };

            return mutate({variables: {data}});
        },
        [logicTestId, mutate]
    );

    return [saveAnswerForLogicTest, {loading, error}];
}
