import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {LogicProps as Props} from '@/componentLibrary/components/inputs/TextEditor/components/LinkModal/types';
import {
    EMPTY_STRING,
    addProtocol,
    isValidLink,
    isValidLinkText,
    isValidLinkWithoutProtocol
} from '@/componentLibrary/components/inputs/TextEditor/components/LinkModal/utils';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {isValidUrl} from '@/utils/validators';

export const useLinkModalLogic = ({
    initialLink,
    initialLinkText,
    close,
    remove,
    save
}: Props) => {
    const linkRef = useRef<HTMLInputElement>(null);
    const linkTextRef = useRef<HTMLInputElement>(null);
    const [link, setLink] = useState<string>(initialLink || EMPTY_STRING);
    const [linkText, setLinkText] = useState<string>(initialLinkText || EMPTY_STRING);
    const [linkValid, setLinkValid] = useState<boolean>(true);
    const [linkTextValid, setLinkTextValid] = useState<boolean>(true);

    const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setLink(event.target.value);

    const handleLinkTextChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setLinkText(event.target.value);

    const onClearLinkText = () => {
        setLinkTextValid(true);
        setLinkText(EMPTY_STRING);
    };

    const onClearLink = () => {
        setLinkValid(true);
        setLink(EMPTY_STRING);
    };

    useFocusOnUnsetField(initialLinkText, linkRef, linkTextRef);

    const validateLink = useCallback(() => {
        if (!isDefined(link)) {
            return true;
        }

        const isValid = isValidLink(link);
        if (isValid) {
            setLinkValid(isValid);
            return;
        }

        const isValidWithoutProtocol = isValidLinkWithoutProtocol(link);
        if (isValidWithoutProtocol) {
            setLink(addProtocol(link));
            setLinkValid(true);
        }
    }, [link]);

    const validateLinkText = useCallback(() => {
        if (!isDefined(linkText)) {
            return true;
        }
        const isValid = isValidLinkText(linkText);
        setLinkTextValid(isValid);
    }, [linkText]);

    const onSave = useCallback(() => {
        if (!isValidUrl(link) || !isValidLinkText(linkText)) {
            validateLink();
            validateLinkText();
            return;
        }

        save(link, linkText);
        close();
    }, [validateLink, validateLinkText, save, link, linkText, close]);

    const isEditing = useMemo(
        () => initialLink && initialLinkText,
        [initialLink, initialLinkText]
    );

    const onRemove = useCallback(() => {
        remove();
        close();
    }, [remove, close]);

    return {
        link,
        linkText,
        linkRef,
        linkTextRef,
        linkValid,
        linkTextValid,
        validateLink,
        validateLinkText,
        isEditing,
        handleLinkChange,
        handleLinkTextChange,
        onClearLinkText,
        onClearLink,
        onSave,
        onRemove
    };
};

const useFocusOnUnsetField = (
    initialLinkText: string | undefined,
    linkRef: React.RefObject<HTMLInputElement>,
    linkTextRef: React.RefObject<HTMLInputElement>
) => {
    useEffect(() => {
        if (initialLinkText) {
            linkRef?.current?.focus();
            return;
        }

        linkTextRef?.current?.focus();
    }, [initialLinkText, linkRef, linkTextRef]);
};
