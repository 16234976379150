import {ActionOverlay} from '@/componentLibrary/components/ActionOverlay';
import {PersonAvatarLarge} from '@/componentLibrary/components/avatars/PersonAvatar';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {LINKS} from '@/links';
import {userLinks} from '@/pages/User/links';

import messages from './messages';
import {MenuItem} from './styled';
import {StartMenuProps} from './types';

export function StartMenu({user, showOrganizations}: StartMenuProps) {
    const phrases = useExtractPhraseConstants(messages);

    return (
        <ActionOverlay
            hasArrow
            outsidePress
            noPadding
            placement="bottom"
            trigger={<PersonAvatarLarge isClickable user={user} />}
            content={closeOverlay => (
                <>
                    {showOrganizations && (
                        <MenuItem
                            exact
                            to={userLinks.myOrganizations()}
                            onClick={() => closeOverlay()}
                        >
                            {phrases.organizations}
                        </MenuItem>
                    )}
                    <MenuItem
                        exact
                        to={userLinks.accountSettings()}
                        onClick={() => closeOverlay()}
                    >
                        {phrases.accountSettings}
                    </MenuItem>
                    <MenuItem to={LINKS.logout}>{phrases.signOut}</MenuItem>
                </>
            )}
        />
    );
}
