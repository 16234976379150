import {useCallback} from 'react';

import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {P2} from '@/componentLibrary/components/typography';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {LEADERSHIP_REPORT_SECTION_VALUES} from '../../constants';
import messages from '../../messages';
import {LeadershipReportSectionValue} from '../../types';
import messagesAnchors from './messages';
import {AnchorsWrapper} from './styled';

type AnchorsProps = {
    onClick: (factor: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export function Anchors({onClick}: AnchorsProps) {
    const phrasesAnchor = useExtractPhraseConstants(messagesAnchors);
    const phrases = useExtractPhraseConstants(messages);

    const getSectionName = useCallback(
        (section: LeadershipReportSectionValue) => phrases[section],
        [phrases]
    );

    return (
        <AnchorsWrapper>
            <P2 color={ColorFgSubtle}>{phrasesAnchor.helperText}</P2>
            {LEADERSHIP_REPORT_SECTION_VALUES.map(
                (section: LeadershipReportSectionValue, index: number) => (
                    <a
                        href={`#${section}`}
                        key={index}
                        onClick={event => onClick(section, event)}
                    >
                        <SecondaryButton small>{getSectionName(section)}</SecondaryButton>
                    </a>
                )
            )}
        </AnchorsWrapper>
    );
}
