import {P2} from '@/componentLibrary/components/typography';
import {
    BorderRadiusBaseSmall,
    ColorBgDanger,
    ColorFgDanger,
    SpacingLarge,
    SpacingSmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

import {WarningWrapperProps} from '@/pages/PublicJobPosition/pages/ApplyForJobPosition/components/ApplyForm/components/FormWarning/components/types';

export const WarningWrapper = styled.div<WarningWrapperProps>`
    min-height: ${SpacingXxlarge};
    background: ${props => (props.visible ? ColorBgDanger : 'transparent')};
    border-radius: ${BorderRadiusBaseSmall};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${SpacingLarge} 0;
`;

export const WarningTextWrapper = styled(P2)`
    height: 100%;
    padding: 10px ${SpacingSmall};
    color: ${ColorFgDanger};
`;

export const IconWrapper = styled.div`
    padding: 10px ${SpacingSmall};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
