import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {RECREATE_SHARED_PERSONALITY_TEST} from '@/api/personalityTests/mutations';
import {useLocale} from '@/containers/LanguageProvider/useLocale';

import {RecreateSharedPersonalityTestMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';

export function useRecreateSharedPersonalityTest(
    organizationId: number
): MutationHookReturnType<RecreateSharedPersonalityTestMutation, [string]> {
    const [mutate, {error, loading}] = useMutation(RECREATE_SHARED_PERSONALITY_TEST);
    const locale = useLocale();
    const recreateSharedPersonalityTest = useCallback(
        testLanguage => {
            const data = {locale: testLanguage ?? locale, organizationId};
            return mutate({variables: {data}});
        },
        [locale, organizationId, mutate]
    );

    return [recreateSharedPersonalityTest, {error, loading}];
}
