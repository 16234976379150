import {FormattedMessage} from 'react-intl';

import {Alert} from '@/componentLibrary/blocks/Modals/Alert/Alert';
import {ModalEmpty} from '@/componentLibrary/components/modals/ModalEmpty';
import {personalityTestTakingMessages} from '@/pages/PersonalityTestIrt/messages';
import {LeavingTestWarningProps} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/components/FormHeader/components/LeavingTestWarning/types';

export const LeavingTestWarning = ({leaveTest, cancel}: LeavingTestWarningProps) => {
    return (
        <ModalEmpty>
            <Alert
                onLeftAction={cancel}
                onRightAction={leaveTest}
                title={<FormattedMessage {...personalityTestTakingMessages.leaveTestTitle} />}
                subtitle={
                    <FormattedMessage
                        {...personalityTestTakingMessages.leaveTestDescription}
                    />
                }
                leftButton={<FormattedMessage {...personalityTestTakingMessages.cancel} />}
                rightButton={<FormattedMessage {...personalityTestTakingMessages.confirm} />}
                confirm
            />
        </ModalEmpty>
    );
};
