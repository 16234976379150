import {GetJobApplication_JobApplication_ChallengeAssignment} from '@/api/assessment/types';
import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {Body} from './Body';
import messages from './messages';

type ScorecardViewProps = {
    close: () => void;
    challenge: GetJobApplication_JobApplication_ChallengeAssignment['challenge'] | null;
};

export const ScorecardView = ({close, challenge}: ScorecardViewProps) => {
    const phrases = useExtractPhraseConstants(messages);

    return (
        <Modal headerTitle={phrases.title} close={close} size={MODAL_SIZE.LARGE} withoutBody>
            <Body challenge={challenge} />
        </Modal>
    );
};
