import {useMemo} from 'react';
import {IntlShape} from 'react-intl';
import styled from 'styled-components';

import {P2, S2} from '@/componentLibrary/components/typography';
import {
    ColorFgDefault,
    SpacingLarge,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

import messages from '../messages';

type EmptyRequirementsProps = {
    intl: IntlShape;
    isCustom: boolean;
    isEdit?: boolean;
};

export const EmptyRequirements = ({intl, isCustom, isEdit}: EmptyRequirementsProps) => {
    const textAction = useMemo(() => {
        if (!isCustom) {
            return '';
        }
        if (isEdit) {
            return intl.formatMessage(messages.emptyRequirementAdd);
        }
        return intl.formatMessage(messages.emptyRequirementClick);
    }, [intl, isCustom, isEdit]);

    const textTitle = useMemo(() => {
        if (isCustom) {
            return <S2>{intl.formatMessage(messages.emptyRequirementDescriptionCustom)}</S2>;
        }
        return <P2>{intl.formatMessage(messages.emptyRequirementDescriptionDefault)}</P2>;
    }, [intl, isCustom]);

    return (
        <EmptyRequirementsWrapper>
            {!!textTitle && textTitle}
            {!!textAction && <P2>{textAction}</P2>}
        </EmptyRequirementsWrapper>
    );
};

const EmptyRequirementsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: ${SpacingLarge};
    row-gap: ${SpacingXxsmall};
    width: 280px;
    margin: ${SpacingLarge} auto 0;
    text-align: center;
    color: ${ColorFgDefault};
`;
