import {arrayOf, func, number, object} from 'prop-types';
import {useContext, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {
    FeaturesLockType,
    FeaturesLockedContext
} from '@/pages/Organization/FeaturesLockedContext';

import lockIcon from './assets/lockIcon.svg';
import {AddCandidateToJobForm} from './components/AddCandidateToJobForm';
import messages from './messages';

export function AddCandidateToJobPosition({
    userId,
    organizationId,
    existingJobApplications,
    refetchExistingJobApplications
}) {
    const featuresLockType = useContext(FeaturesLockedContext);
    const areFeaturesLocked = featuresLockType !== FeaturesLockType.NONE;
    const [showModal, setShowModal] = useState(false);

    if (showModal) {
        return (
            <Modal
                close={setShowModal.bind(null, false)}
                headerTitle={<FormattedMessage {...messages.addToJob} />}
            >
                <AddCandidateToJobForm
                    userId={userId}
                    organizationId={organizationId}
                    existingJobApplications={existingJobApplications}
                    refetchExistingJobApplications={refetchExistingJobApplications}
                />
            </Modal>
        );
    }

    return (
        <div>
            <PrimaryButton
                disabled={areFeaturesLocked}
                onClick={setShowModal.bind(null, true)}
            >
                {areFeaturesLocked ? (
                    <LockWrapper>
                        <LockIcon />
                        <FormattedMessage {...messages.addToJob} />
                    </LockWrapper>
                ) : (
                    <FormattedMessage {...messages.addToJob} />
                )}
            </PrimaryButton>
        </div>
    );
}

AddCandidateToJobPosition.propTypes = {
    userId: number.isRequired,
    organizationId: number.isRequired,
    existingJobApplications: arrayOf(object),
    refetchExistingJobApplications: func
};

const LockWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const LockIcon = styled.div`
    width: 15px;
    height: 20px;
    background-image: url("${lockIcon}");
    background-size: contain;
    background-repeat: no-repeat;
`;
