import {useMemo} from 'react';

import {useMyUserOrganizationsWithSharedTests} from '@/api/users/useMyUserOrganizationsWithSharedTests';
import {useUserWithRecommendations} from '@/api/users/useRecommendationsUser';

export function useOtherJobsSectionPrerequisities() {
    const {
        userOrganizations,
        sharedLogicTests,
        sharedPersonalityTests,
        loading: loadingUserOrganizationsWithSharedTests
    } = useMyUserOrganizationsWithSharedTests();
    const {user: userWithRecommendationSettings, loading: loadingUserRecommendationsSettings} =
        useUserWithRecommendations();

    const disabledRecommendations =
        userWithRecommendationSettings?.hasRegisteredToCandidateServices === true &&
        userWithRecommendationSettings?.recommendationsSettings?.recommendationsEnabled ===
            false;

    const userId = userWithRecommendationSettings?.id;

    const hasCompletedAPersonalityTest = sharedPersonalityTests?.some(
        test => test.personalityIrtResult
    );

    const hasCompletedALogicTest = sharedLogicTests?.some(
        test => test.logicalAbilityEstimate?.id
    );

    const isEmployee = userOrganizations?.some(organization => organization.isEmployment);

    const hasPrerequisites = useMemo(() => {
        return (
            !disabledRecommendations &&
            hasCompletedAPersonalityTest &&
            hasCompletedALogicTest &&
            !isEmployee
        );
    }, [
        disabledRecommendations,
        hasCompletedALogicTest,
        hasCompletedAPersonalityTest,
        isEmployee
    ]);

    const loadingPrerequisites = useMemo(
        () => loadingUserOrganizationsWithSharedTests || loadingUserRecommendationsSettings,
        [loadingUserRecommendationsSettings, loadingUserOrganizationsWithSharedTests]
    );

    return {
        userId,
        hasPrerequisites,
        loadingPrerequisites
    };
}
