import styled from 'styled-components';

import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import {SpacingMedium, SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const Container = styled.div`
    margin-bottom: ${SpacingMedium};
`;
export const Wrapper = styled.div<{noPointerEvents?: boolean}>`
    position: relative;
    ${({noPointerEvents}) => noPointerEvents && 'pointer-events: none;'}
`;
export const Title = styled(P1)`
    margin-bottom: ${SpacingXsmall};
`;
