import {defineMessages} from 'react-intl';

export default defineMessages({
    descriptionInterviewers: {
        id: 'app.assessment.jobPosition.settings.assignInterview.descriptionInterviewers'
    },
    remove: {
        id: 'app.assessment.jobPosition.settings.assignInterview.remove'
    },
    involvedTeamMembers: {
        id: 'app.assessment.jobPosition.settings.assignInterview.involvedTeamMembers'
    },
    sendEmails: {
        id: 'app.assessment.jobPosition.settings.assignInterview.sendEmails'
    }
});
