import {defineMessages} from 'react-intl';

export default defineMessages({
    skip: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.tutorialCard.skip'
    },
    previous: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.tutorialCard.previous'
    },
    next: {
        id: 'app.pages.logicTestIrt.pages.logicTestTutorial.tutorialCard.next'
    },
    goBack: {
        id: 'app.backLink.goBack'
    }
});
