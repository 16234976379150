import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_PERSONALITY_TEST_RESULT} from '@/api/personalityTests/queries';

import {UsePersonalityTestResultProps} from './types';

export function usePersonalityTestResult({
    personalityTestId,
    withLeadershipProfile = false,
    skip = false,
    pollInterval = undefined
}: UsePersonalityTestResultProps) {
    const {data, error, loading, refetch, stopPolling} = useQuery(
        GET_PERSONALITY_TEST_RESULT,
        {
            variables: {id: personalityTestId as string, withLeadershipProfile},
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
            skip: skip || !personalityTestId,
            pollInterval
        }
    );

    const personalityTestResult = useMemo(() => data?.personalityTestResult ?? null, [data]);
    return {error, loading, personalityTestResult, refetch, stopPolling};
}
