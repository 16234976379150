import {getConfig} from '@/config';
import {useFetch} from '@/services/common/useFetch/useFetch';
import {ConsentType} from '@/services/consent/types';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {Props, StaticLegalContent} from './types';

export const useStaticLegalContent = ({type}: Props) => {
    const url = toUrl(type);
    const {loading, error, data: rawData} = useFetch(url);
    const data = isDefined(rawData)
        ? ({
              type: rawData.type,
              version: `${rawData.version}`,
              content: rawData.content
          } as StaticLegalContent)
        : null;

    return {
        loading,
        error,
        data
    };
};

const toUrl = (type: ConsentType) => {
    const config = getConfig();
    const baseUrl = config.STATIC_BUCKET_URL;
    return `${baseUrl}/content/${type}/${type}_latest.json`;
};
