import {PieData} from 'plotly.js';

import {ColorBaseBlue900, ColorBaseYellow900} from '@/componentLibrary/tokens/variables';

export const donutChartPaletteColors: PieData['marker']['colors'] = [
    ColorBaseYellow900,
    '#ff9723',
    '#ff6b43',
    '#ff3f62',
    '#ec167f',
    '#c21499',
    '#842baa',
    ColorBaseBlue900
];

export const PieSize = 144;

const PieHoleSize = 60;

export const PieHoleRelativeSize = parseFloat((PieHoleSize / PieSize).toFixed(2));
