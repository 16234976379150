import {v4 as uuid} from 'uuid';

import {ToastEventType} from './constants';
import {useToasts as useToastsInternal} from './hooks';
import {ToastFunctions, ToastInput, UseToastsOptsInternal} from './types';

const initToasts = (): ToastFunctions => {
    const channelId = uuid();
    const channel = `${ToastEventType}-${channelId}`;

    return {
        toast: (args: ToastInput) => {
            const id = args.id || uuid();
            document.dispatchEvent(new CustomEvent(channel, {detail: {...args, id}}));
            return id;
        },
        useToasts: (opts: UseToastsOptsInternal = {} as UseToastsOptsInternal) =>
            useToastsInternal({...(opts || {}), channel})
    };
};

export const {toast, useToasts} = initToasts();
