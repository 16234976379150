import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXsmall, SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const LabelTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${SpacingXxsmall};
    max-width: 728px;
    margin-bottom: ${SpacingXsmall};
`;

export const ComponentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${SpacingXxsmall};
    margin-right: ${SpacingXxsmall};
`;

export const Wrapper = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
`;

export const PageWrapper = styled.div`
    flex: 1 1;
    height: 100%;
    overflow: auto;
`;

export const CenteredContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

export const WrapperButtons = styled.div`
    display: flex;
    gap: ${SpacingXsmall};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column;
    }
`;

export const ScorecardSubtitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXxsmall};
`;
