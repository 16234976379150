import {useIntl} from 'react-intl';

import {GetAssessmentMethodsForJobPosition_JobPosition_PersonalityProfile} from '@/api/assessment/types';
import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {PersonalityProfileGraph} from '@/pages/Organization/pages/Assessment/components/AssessmentSelector/components/PersonalityProfileDetailsModal/components/PersonalityProfileGraph';
import {messages} from '@/pages/Organization/pages/Assessment/components/AssessmentSelector/components/PersonalityProfileDetailsModal/messages';
import {
    Description,
    Header,
    HeaderWrapper,
    ModalBody,
    ModifiedLabel,
    TitleWrapper
} from '@/pages/Organization/pages/Assessment/components/AssessmentSelector/components/PersonalityProfileDetailsModal/styled';
import {PersonalityProfileDetailsModalProps} from '@/pages/Organization/pages/Assessment/components/AssessmentSelector/components/PersonalityProfileDetailsModal/types';

function isNotTemplate(
    obj: PersonalityProfileDetailsModalProps['personalityProfile']
): obj is GetAssessmentMethodsForJobPosition_JobPosition_PersonalityProfile {
    return (obj && 'isModified' in obj) ?? false;
}

export const PersonalityProfileDetailsModal = ({
    closeModal,
    personalityProfile,
    headerTitle
}: PersonalityProfileDetailsModalProps) => {
    const intl = useIntl();

    return (
        <Modal
            close={closeModal}
            size={MODAL_SIZE.LARGE}
            headerTitle={headerTitle ?? intl.formatMessage(messages.detailsPersonalityProfile)}
        >
            <ModalBody>
                <HeaderWrapper>
                    <TitleWrapper>
                        <Header>{personalityProfile?.name}</Header>
                        {isNotTemplate(personalityProfile) &&
                            personalityProfile?.isModified && (
                                <ModifiedLabel>
                                    ({intl.formatMessage(messages.edited)})
                                </ModifiedLabel>
                            )}
                    </TitleWrapper>
                    <Description>{personalityProfile?.description}</Description>
                </HeaderWrapper>
                <PersonalityProfileGraph facets={personalityProfile?.facets} />
            </ModalBody>
        </Modal>
    );
};
