import {useCallback, useState} from 'react';

import {StepperProps} from '@/componentLibrary/components/Stepper/types';

type ModalState = {
    [key: string]: boolean;
};

type ModalSteppers = {[key: string]: StepperProps};

type ModalKeys = {
    [key: string]: string;
};

const getDefaultState = (_keys: ModalKeys) => {
    const reducer = (accumulator: ModalState, modalKey: string, index: number) => {
        const isFirstModal = index === 0;
        return Object.assign({}, accumulator, {[modalKey]: isFirstModal});
    };

    return Object.values(_keys).reduce(reducer, {});
};

export function useModalLogic(keys: ModalKeys, defaultState?: ModalState) {
    const _defaultState = defaultState ?? getDefaultState(keys);
    const [state, setState] = useState<ModalState>(_defaultState);
    const [currentKey, setCurrentKey] = useState(
        Object.keys(_defaultState)[Object.values(_defaultState).findIndex(Boolean)]
    );
    const {steppers} = useSteppers(keys);

    const getState = useCallback(
        (openModal: keyof ModalKeys) => {
            const reducer = (acc: ModalState, modalKey: string) => {
                const shouldOpen = modalKey === openModal;
                return Object.assign({}, acc, {[modalKey]: shouldOpen});
            };

            return Object.values(keys).reduce(reducer, {});
        },
        [keys]
    );

    const open = useCallback(
        modalKey => {
            const newState = getState(modalKey);
            setState(newState);
            setCurrentKey(modalKey);
        },
        [getState]
    );

    return {state, steppers, open, currentKey};
}

function useSteppers(keys: ModalKeys) {
    const numberOfSteps = Object.keys(keys).length;
    const initialSteppers: ModalSteppers = {};

    const toSteppers = (accumulator: ModalSteppers, key: string, index: number) => {
        const stepper = {
            [key]: {
                noOfSteps: numberOfSteps,
                activeStep: index + 1
            }
        };

        return Object.assign({}, accumulator, stepper);
    };
    const steppers = Object.keys(keys).reduce(toSteppers, initialSteppers);

    return {steppers};
}
