import {
    SpacingLarge,
    SpacingSmall,
    SpacingXlarge,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const OrganizationsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingLarge};
`;
export const OrganizationWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 414px) {
        flex-direction: column;
    }
`;
export const OrganizationInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 414px) {
        margin-bottom: ${SpacingXlarge};
    }
`;
export const OrganizationTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${SpacingXsmall};
`;
export const OrganizationNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-inline-start: ${SpacingSmall};
`;
export const TestsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SpacingXsmall};
    margin-inline-start: 56px;
`;
export const TestWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
