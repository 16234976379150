import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {useLocale} from '@/containers/LanguageProvider/useLocale';

import {CreatePersonalityTestMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {CREATE_PERSONALITY_TEST} from './mutations';

export function useCreatePersonalityTest(
    shareWithOrganizationId: number
): MutationHookReturnType<CreatePersonalityTestMutation, string> {
    const [mutate, {error, loading}] = useMutation(CREATE_PERSONALITY_TEST);
    const locale = useLocale();
    const createPersonalityTest = useCallback(
        (selectedTestLanguage: string) => {
            const variables = {
                data: {
                    locale: selectedTestLanguage ? selectedTestLanguage : locale,
                    shareWithOrganizationId: shareWithOrganizationId
                }
            };
            return mutate({variables});
        },
        [locale, shareWithOrganizationId, mutate]
    );

    return [createPersonalityTest, {error, loading}];
}
