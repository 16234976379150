import {
    JobPositionActivationState,
    JobPositionStatus
} from '@/api/types/__generated__/graphql';
import {TagVariant} from '@/componentLibrary/components/Tag/constants';
import {
    ColorBaseGreen500,
    ColorBaseGrey100,
    ColorBaseGrey200
} from '@/componentLibrary/tokens/variables';

const JOB_POSITION_STATUS_VARIANT = {
    [JobPositionStatus.OPEN]: TagVariant.success,
    [JobPositionStatus.UNLISTED]: TagVariant.info,
    [JobPositionStatus.ARCHIVED]: TagVariant.default
};

const JOB_POSITION_ACTIVATION_STATE_VARIANT = {
    [JobPositionActivationState.ACTIVE]: TagVariant.success,
    [JobPositionActivationState.EXPIRED]: TagVariant.warning,
    [JobPositionActivationState.INACTIVE]: TagVariant.default
};

export const getJobPositionStatusVariant = (status: JobPositionStatus | string) =>
    JOB_POSITION_STATUS_VARIANT[(status as JobPositionStatus) || JobPositionStatus.UNLISTED];

export const getJobPositionActivationStateVariant = (status: JobPositionActivationState) =>
    JOB_POSITION_ACTIVATION_STATE_VARIANT[status || JobPositionActivationState.INACTIVE];

const JOB_POSITION_STATUS_COLORS = {
    [JobPositionStatus.OPEN]: ColorBaseGreen500,
    [JobPositionStatus.UNLISTED]: ColorBaseGrey200,
    [JobPositionStatus.ARCHIVED]: ColorBaseGrey100
};

export const getJobPositionStatusColor = (status: JobPositionStatus) =>
    JOB_POSITION_STATUS_COLORS[status || JobPositionStatus.UNLISTED];
