import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    ColorBgDefault,
    SpacingMedium,
    SpacingXlarge
} from '@/componentLibrary/tokens/variables';
import {QuestionInnerWrapperProps} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/types';

export const Wrapper = styled.div`
    background-color: ${ColorBgDefault};
    padding: 0 ${SpacingMedium} ${SpacingMedium};
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Header = styled.div`
    padding-top: ${SpacingMedium};
    flex: 0;
    width: 100%;
    z-index: 100;
    background-color: ${ColorBgDefault};
`;

export const QuestionWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${SpacingXlarge} 0;
`;

export const QuestionInnerWrapper = styled.div<QuestionInnerWrapperProps>`
    width: 600px;

    @media (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-top: 0;
        width: 100%;
    }

    animation-duration: 1s;
    animation-name: ${({animationName}) => animationName};
`;

export const Footer = styled.div`
    flex: 0;
    width: 100%;
    z-index: 100;
    background-color: ${ColorBgDefault};
`;
