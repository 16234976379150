export const getOverdueByMinutes = ({
    submissionDeadline,
    submittedAt
}: {
    submissionDeadline: string;
    submittedAt: string;
}) => {
    const submissionDeadlineDt: Date = new Date(submissionDeadline);
    const submittedAtDt: Date = new Date(submittedAt);

    const submissionDeadlineMs = submissionDeadlineDt.getTime();
    const submittedAtMs = submittedAtDt.getTime();

    if (isNaN(submissionDeadlineMs) || isNaN(submittedAtMs)) {
        return 0;
    }

    const differenceInMilliseconds = submittedAtMs - submissionDeadlineMs;

    if (differenceInMilliseconds < 0) {
        return 0;
    }

    return Math.ceil(differenceInMilliseconds / (1000 * 60));
};
