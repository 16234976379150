import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Tag} from '@/componentLibrary/components/Tag';
import {SpacingXxxsmall} from '@/componentLibrary/tokens/variables';
import {MessagesWithIndexSignature} from '@/types/generic.types';
import {randomString} from '@/utils/misc';

import messages from '../../../JobRecommendationItem/messages';
import {Section} from '../Section';

type Props = {
    jobFamily: string | null;
    specialities: (string | null)[];
};

const typedMessages = messages as MessagesWithIndexSignature;

export function Specialities({jobFamily, specialities}: Props) {
    const intl = useIntl();
    const jobSpecialitiesTitle = intl.formatMessage(messages.jobSpecialitiesTitle);
    return (
        <Section title={jobSpecialitiesTitle}>
            <JobSpecialityTags>
                {specialities.map(speciality => {
                    const id = `jobFamilies_${jobFamily}_${speciality}`;
                    const message = intl.formatMessage(typedMessages[id]);
                    return (
                        <JobSpecialityTag key={randomString()}>
                            <Tag>{message}</Tag>
                        </JobSpecialityTag>
                    );
                })}
            </JobSpecialityTags>
        </Section>
    );
}

const JobSpecialityTags = styled.div`
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    gap: ${SpacingXxxsmall};
`;

const JobSpecialityTag = styled.div`
    padding: 0 8px 8px 0;
`;
