import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {forwardRef} from 'react';
import {Icons} from '../icons/constants';

import {Icon} from '../icons/Icon';
import {IconButtonProps} from './types';

export const IconButton = forwardRef<HTMLSpanElement, IconButtonProps>(
    ({isSmall = false, color = ColorFgSubtle, icon = Icons.CLOSE, ...otherProps}, ref) => {
        return (
            <Icon
                ref={ref}
                isHoverable
                fill={color}
                icon={icon}
                size={isSmall ? 16 : 24}
                isAction
                {...otherProps}
            />
        );
    }
);
