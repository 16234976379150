import {useContext, useEffect, useMemo} from 'react';

import {useCurrentEmployment} from '@/api/organizations/useCurrentEmployment';
import {EmptyState} from '@/componentLibrary/blocks/EmptyState';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useRateCandidate} from '@/pages/common/interviews/hooks/useRateCandidate';

import {SharedResultsContext} from '@/pages/SharedResults/components/UserResults/hooks/SharedResultsContext';
import messages from '../messages';

type ScorecardResultsEmptyStateProps = {
    jobApplicationId: number;
    jobApplicationIsWithdrawn: boolean;
    interviewId: number | null;
};

export function ScorecardResultsEmptyState({
    jobApplicationId,
    jobApplicationIsWithdrawn,
    interviewId
}: ScorecardResultsEmptyStateProps) {
    const phrases = useExtractPhraseConstants(messages);
    const {employment} = useCurrentEmployment();
    const {rateCandidate, rateCandidateError, rateCandidateLoading} = useRateCandidate(
        jobApplicationId,
        interviewId ? [interviewId] : [],
        employment?.id
    );
    const isSharedResultsView = useContext(SharedResultsContext);

    useEffect(() => {
        if (rateCandidateError) {
            toast({
                type: 'error',
                message: phrases.toastRateCandidateError
            });
        }
    }, [phrases.toastRateCandidateError, rateCandidateError]);

    const rateButton = useMemo(() => {
        return !isSharedResultsView ? (
            <SecondaryButton
                isLoading={rateCandidateLoading}
                disabled={!interviewId || jobApplicationIsWithdrawn}
                onClick={rateCandidate}
            >
                {phrases.rateThisCandidate}
            </SecondaryButton>
        ) : null;
    }, [
        rateCandidateLoading,
        interviewId,
        jobApplicationIsWithdrawn,
        isSharedResultsView,
        rateCandidate,
        phrases.rateThisCandidate
    ]);

    return <EmptyState titleText={phrases.noCompletedTitle} actionZone={rateButton} />;
}
