import {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {PERSONALITY_FACTOR_MESSAGES, PersonalityFactorKey} from '../../constants';
import {FacetResult} from './components/FacetResult';
import {PERSONALITY_FACET_MESSAGES, PersonalityFacetKey} from './constants';
import messages from './messages';
import {FacetsWrapper, TitleWrapper, Wrapper} from './styled';

type FactorFacetsProps = {
    personalityTestResult: {[key: string]: number};
    factor: PersonalityFactorKey;
};

export function FactorFacets({personalityTestResult, factor}: FactorFacetsProps) {
    const intl = useIntl();

    const factorName = useMemo(() => {
        return intl.formatMessage(PERSONALITY_FACTOR_MESSAGES[factor].name);
    }, [intl, factor]);

    const getFacetMessages = useCallback((facet: PersonalityFacetKey) => {
        return PERSONALITY_FACET_MESSAGES[facet];
    }, []);

    const getValueForFacet = useCallback(
        facet => {
            return personalityTestResult[facet.toLowerCase()];
        },
        [personalityTestResult]
    );

    const facetsForFactor = useMemo(() => {
        return ['I', 'II', 'III'].map(facetIndex => {
            const facetName = `${factor}${facetIndex}` as PersonalityFacetKey;

            return {
                facetName,
                value: getValueForFacet(facetName),
                messages: getFacetMessages(facetName)
            };
        });
    }, [factor, getValueForFacet, getFacetMessages]);

    return (
        <Wrapper>
            <TitleWrapper color={ColorFgSubtle}>
                {intl.formatMessage(messages.title, {factorName: factorName.toLowerCase()})}
            </TitleWrapper>
            <FacetsWrapper>
                {facetsForFactor.map(facet => (
                    <FacetResult
                        key={facet.facetName}
                        scoreValue={facet.value}
                        facetMessages={facet.messages}
                    />
                ))}
            </FacetsWrapper>
        </Wrapper>
    );
}
