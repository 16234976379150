import styled from 'styled-components';

import {Overline} from '@/componentLibrary/components/typography/Overline';
import {
    SpacingLarge,
    SpacingSmall,
    SpacingXlarge,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    padding-top: ${SpacingLarge};
`;
export const Header = styled(Overline)`
    margin-bottom: ${SpacingLarge};
`;
export const FooterRow = styled.div`
    margin-top: ${SpacingXlarge};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: ${SpacingXsmall};

    & div {
        padding: 0 ${SpacingSmall};
        margin-inline-end: ${SpacingXsmall};
    }
`;
