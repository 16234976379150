import {
    Dispatch,
    MutableRefObject,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

import {ToastEventType} from './constants';
import {Toast, UnsubscribeFunction, UseToastsOptsInternal} from './types';
import {onToastAdded} from './utils';

const DEFAULT_TIMEOUT = 3000;
const getRemoveChannel = (channel: string) => `${channel}-remove`;

const subscribeToToasts = (
    setToasts: Dispatch<SetStateAction<Toast[]>>,
    options: UseToastsOptsInternal,
    timeouts: MutableRefObject<{[key: string]: NodeJS.Timeout}>,
    removeToast: (id: string) => void
): UnsubscribeFunction => {
    const listener = (event: Event & {detail?: Toast}) => {
        if (event.detail?.id) {
            onToastAdded({toast: event.detail, setToasts, options, timeouts, removeToast});
        }
    };
    document.addEventListener(options.channel, listener);

    return () => {
        document.removeEventListener(options.channel, listener);
    };
};

/** Listens to all toasts and stores them in a list */
export function useToasts(
    opts: UseToastsOptsInternal = {
        channel: ToastEventType,
        removeToastsAfterMs: DEFAULT_TIMEOUT
    }
) {
    const timeouts = useRef<Record<string, ReturnType<typeof setTimeout>>>({});
    const mounted = useRef(true);
    const [toasts, setToasts] = useState<Toast[]>([]);
    const options = useMemo(() => {
        return {
            ...opts,
            removeToastsAfterMs: opts.removeToastsAfterMs ?? DEFAULT_TIMEOUT
        };
    }, [opts]);

    useListenToRemoveToast({
        onRemove: id => {
            if (mounted.current) {
                setToasts(prev => {
                    if (!prev.find(item => item.id === id)) {
                        return prev; // keep old reference to prevent re-render
                    }
                    return prev.filter(item => item.id !== id);
                });
            }
        },
        channel: options.channel
    });

    const removeToast = useCallback(
        (id: string) => {
            if (mounted.current) {
                setToasts(prev => prev.filter(item => item.id !== id));
            }
            document.dispatchEvent(
                new CustomEvent(getRemoveChannel(options.channel), {
                    detail: id
                })
            );
        },
        [options.channel]
    );

    useEffect(() => {
        mounted.current = true;
        const unsubscribe = subscribeToToasts(setToasts, options, timeouts, removeToast);

        return () => {
            unsubscribe();
            mounted.current = false;
        };
    }, [options.removeToastsAfterMs, options.onToastAdded, options, removeToast]);

    return {
        toasts,
        onRemoveToast: removeToast,
        cancelToastTimeout: (id: string) => {
            timeouts.current[id] && clearTimeout(timeouts.current[id]);
        },
        restartToastTimeout: (id: string) => {
            const toast = toasts.find(t => t.id === id);

            if (!toast) {
                return;
            }

            timeouts.current[id] && clearTimeout(timeouts.current[id]);

            timeouts.current[id] = setTimeout(
                () => removeToast(id),
                toast.removeAfterMs ?? options.removeToastsAfterMs
            );
        }
    };
}

function useListenToRemoveToast(opts: {onRemove: (toastId: string) => void; channel: string}) {
    const removeChannel = getRemoveChannel(opts.channel);
    useEffect(() => {
        const listener = (event: Event & {detail?: string}) => {
            if (event.detail) {
                opts.onRemove(event.detail);
            }
        };
        document.addEventListener(removeChannel, listener);

        return () => document.removeEventListener(removeChannel, listener);
    }, [opts, removeChannel]);
}
