import {defineMessages} from 'react-intl';

export default defineMessages({
    info: {
        id: 'app.logicTestIrt.logicTestTutorial.sampleTestTopBanner.info'
    },
    skipSample: {
        id: 'app.logicTestIrt.logicTestTutorial.sampleTestTopBanner.skipSample'
    }
});
