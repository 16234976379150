import {useMemo} from 'react';

import range from '@/utils/range';

import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {roundValue} from '@/utils/misc';
import {Border, XAxisScore, YAxisScore, YAxisScoreWrapper} from './styled';
import {UseChartLogicProps} from './types';

export function useChartLogic({
    data,
    height,
    width,
    spacingTop,
    spacingBottom
}: UseChartLogicProps) {
    const maxYAxisValue = useMemo(() => {
        const defaultValue = 10;
        const reducedValue = data.reduce(
            (prev, current) => (prev.value > current.value ? prev : current),
            {value: 0}
        ).value;
        if (data.length === 0 || reducedValue === 0) {
            return defaultValue;
        }
        return reducedValue;
    }, [data]);

    const yAxisTickCount = useMemo(
        () => Math.floor((height - spacingTop - spacingBottom) / 30),
        [height, spacingTop, spacingBottom]
    );

    const xAxisTickCount = useMemo(() => (width > 360 ? 10 : 5), [width]);

    const generateYAxisLabels = useMemo(() => {
        const itemCount = yAxisTickCount;
        const step = Math.max(maxYAxisValue / (itemCount - 1), 1);

        return range(itemCount).map(i => {
            const score = maxYAxisValue - step * i;
            if (score < 0) {
                return;
            }
            return (
                <YAxisScoreWrapper
                    height={roundValue((score / maxYAxisValue) * 100)}
                    isFirst={i === 0}
                    key={i}
                >
                    <YAxisScore color={ColorFgSubtle}>{roundValue(score)}</YAxisScore>
                    <Border />
                </YAxisScoreWrapper>
            );
        });
    }, [maxYAxisValue, yAxisTickCount]);

    const generateXAxisLabels = (count: number) => {
        const ticks = range(count * 2 + 3).map(i => {
            if (i % 2 === 0) {
                return <div key={`1-${i}`} />;
            }
            return <Border key={`1-${i}`} />;
        });
        const scores = range(count * 2 + 2).map(i => {
            const score = roundValue((100 * (i - 1)) / (count * 2), 1);
            if (i % 2 !== 0) {
                return (
                    <XAxisScore key={`2-${i}`} color={ColorFgSubtle}>
                        {score}
                    </XAxisScore>
                );
            }
            return <div key={`2-${i}`} />;
        });
        return [...ticks, ...scores];
    };

    return {
        generateXAxisLabels,
        generateYAxisLabels,
        maxYAxisValue,
        xAxisTickCount,
        yAxisTickCount
    };
}
