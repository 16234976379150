import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.user.candidateSettings.candidateProfileForm.title'
    },
    firstName: {
        id: 'app.user.candidateSettings.candidateProfileForm.firstName'
    },
    lastName: {
        id: 'app.user.candidateSettings.candidateProfileForm.lastName'
    },
    email: {
        id: 'app.user.candidateSettings.candidateProfileForm.email'
    },
    language: {
        id: 'app.user.candidateSettings.candidateProfileForm.language'
    },
    saveChanges: {
        id: 'app.user.candidateSettings.candidateProfileForm.saveChanges'
    }
});
