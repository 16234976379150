import {DocumentNode, OperationDefinitionNode} from 'graphql';

import {
    CREATE_DATA_COLLECTION_PERSONALITY_TEST_V2,
    SAVE_ANSWER_FOR_PERSONALITY_TEST_V2,
    SKIP_QUESTION_FOR_PERSONALITY_TEST_V2
} from '@/api/personalityTests/mutations';

const BACKEND_STAGING_URL = 'https://graphql-gateway-staging.alvalabs.io';

const LOCAL_MICROSOFT_CLIENT_ID = '65e8151a-0e86-426e-a1d2-365f4665c303';
const STAGING_MICROSOFT_CLIENT_ID = 'b3591e89-31b8-418c-8a11-a0527f5b9fb6';

const FUSION_CHARTS_LICENSE_KEY =
    'uuA5exvF3F3I2E3A11D7A4D4A5A4G1D2G1rmoE6E1C4gI4B3C8zyhD3D2E3vraA4B2C2zbnD1H4B3A5B3F4F3H3G4A19A32aikC6D3RH4moA-31A7C8A3wfA2IC1C5rA-16mD2D5F1A2B2B7A3D6A3A1E4F2H2C3u==';

const commonDevConfig = {
    INTERCOM_APP_ID: 'fel1xywj',
    SEGMENT_WRITE_KEY: 'M9F7zzfqUjsjk20eq1Z7W1ALqt5XdCrn', // TODO: Disable segment for local
    SEGMENT_CLIENT_SIDE_WRITE_KEY: 'WExTC17gVUK2MbiZgHLZqtzf5QbljXBy',
    GOOGLE_CLIENT_ID:
        '320084368669-3k3s65t0cr4vf4oca8r2e9bgliqp2lbk.apps.googleusercontent.com',
    SATISMETER_WRITE_KEY: 'VDxJFRdywSxQG3bs',
    RECAPTCHA_SITE_KEY: '6LcUwQclAAAAACQ6Wao7q1aL25CAaymh_UbYWqN0',
    HUBSPOT_PORTAL_ID: '21528739',
    COOKIEBOT_ID: '3c1d2b2c-9a21-43e4-a1cb-168a308e62b4',
    GOOGLE_TAG_MANAGER_ID: '',
    FUSION_CHARTS_LICENSE_KEY
};

const LocalConfig = {
    BACKEND: shouldUseStagingBackendForDev() ? BACKEND_STAGING_URL : 'http://localhost:4000',
    BACKEND_ENVIRONMENT: shouldUseStagingBackendForDev() ? 'dev' : 'local',
    APP: 'http://localhost:3000',
    ENVIRONMENT: 'local',
    PUSHER_APP_ID: shouldUseStagingBackendForDev() ? '744909' : '744914',
    PUSHER_KEY: shouldUseStagingBackendForDev()
        ? 'ff64b529ed84a1d5080f'
        : '7c76ad8586e8cfd4e978',
    SEGMENT_CONFIG: {
        flushAt: 1,
        flushInterval: 1
    },
    LOG_ROCKET_KEY: 'xy0xbz/application-local',
    MICROSOFT_CLIENT_ID: shouldUseStagingBackendForDev()
        ? STAGING_MICROSOFT_CLIENT_ID
        : LOCAL_MICROSOFT_CLIENT_ID,
    LEVER_ADAPTER_BASE_URL: shouldUseStagingBackendForDev()
        ? 'https://lever-staging.alvalabs.io'
        : 'http://localhost:1371',
    STATIC_BUCKET_URL: 'https://staging.static-app.alvalabs.io',

    ...commonDevConfig
};

const StagingConfig = {
    BACKEND: BACKEND_STAGING_URL,
    BACKEND_ENVIRONMENT: 'dev',
    APP: 'https://staging.app.alvalabs.io',
    ENVIRONMENT: 'dev',
    PUSHER_APP_ID: '744909',
    PUSHER_KEY: 'ff64b529ed84a1d5080f',
    SEGMENT_CONFIG: {
        flushAt: 10,
        flushInterval: 5000
    },
    LOG_ROCKET_KEY: 'xy0xbz/application-staging',
    MICROSOFT_CLIENT_ID: STAGING_MICROSOFT_CLIENT_ID,
    LEVER_ADAPTER_BASE_URL: 'https://lever-staging.alvalabs.io',
    STATIC_BUCKET_URL: 'https://staging.static-app.alvalabs.io',
    ...commonDevConfig
};

const ProductionConfig = {
    BACKEND: 'https://graphql-gateway.alvalabs.io',
    BACKEND_ENVIRONMENT: 'production',
    APP: 'https://app.alvalabs.io',
    ENVIRONMENT: 'production',
    INTERCOM_APP_ID: 'xjr8vtw1',
    PUSHER_APP_ID: '402008',
    PUSHER_KEY: '954905b3af271c87549c',
    SEGMENT_WRITE_KEY: 'Mgal7mht1pERcT2wpXPluTxYhLSZnMUB',
    SEGMENT_CONFIG: {
        flushAt: 10,
        flushInterval: 5000
    },
    SEGMENT_CLIENT_SIDE_WRITE_KEY: 'dZI1ADAtPiVOjYiW2O0R2TjY8g0wfAVr',
    LOG_ROCKET_KEY: 'xy0xbz/application-production',
    GOOGLE_CLIENT_ID:
        '37625479344-4rmhf5r1pathpdinsamo93f7754io387.apps.googleusercontent.com',
    MICROSOFT_CLIENT_ID: '09080ab6-fab5-4e12-8016-52d9c5dc9741',
    LEVER_ADAPTER_BASE_URL: 'https://lever.alvalabs.io',
    SATISMETER_WRITE_KEY: 'd83DFYRQD1z4ypkM',
    RECAPTCHA_SITE_KEY: '6LcUwQclAAAAACQ6Wao7q1aL25CAaymh_UbYWqN0',
    HUBSPOT_PORTAL_ID: '6249733',
    COOKIEBOT_ID: '3c1d2b2c-9a21-43e4-a1cb-168a308e62b4',
    GOOGLE_TAG_MANAGER_ID: 'GTM-W72SWJ8',
    STATIC_BUCKET_URL: 'https://production.static-app.alvalabs.io',
    FUSION_CHARTS_LICENSE_KEY
};

export type Config = typeof LocalConfig | typeof StagingConfig | typeof ProductionConfig;

function isStaging() {
    return window.location.hostname.includes('staging.app.alvalabs.io');
}

function isLocal() {
    return (
        window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    );
}

function isProduction() {
    return window.location.hostname.includes('app.alvalabs.io');
}

export function isGHPages() {
    return window.location.hostname.includes('alvalabs.github.io');
}

function shouldUseStagingBackendForDev(): boolean {
    if (!isLocal() || typeof process === 'undefined') {
        return false;
    }
    return process?.env?.VITE_USE_STAGING_BACKEND === 'true';
}

export const getConfig = (): Config => {
    if (isStaging()) {
        return StagingConfig;
    }
    if (isProduction()) {
        return ProductionConfig;
    }
    if (isLocal()) {
        return LocalConfig;
    }
    return StagingConfig;
};

export function getBaseUrl() {
    return getConfig().BACKEND;
}

export const graphqlEndpoint = getBaseUrl() + '/graphql';
// Language config
export const DEFAULT_LOCALE = 'en';

function getOperationName(gql: DocumentNode): string | null {
    const operationDefinition = gql.definitions.find(
        def => def.kind === 'OperationDefinition'
    ) as OperationDefinitionNode;

    return operationDefinition && operationDefinition.name
        ? operationDefinition.name.value
        : null;
}

export const IDEMPOTENT_MUTATIONS = [
    getOperationName(SAVE_ANSWER_FOR_PERSONALITY_TEST_V2),
    getOperationName(SKIP_QUESTION_FOR_PERSONALITY_TEST_V2),
    getOperationName(CREATE_DATA_COLLECTION_PERSONALITY_TEST_V2)
];
