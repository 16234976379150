import {ColorBaseBrown100} from '@/componentLibrary/tokens/customVariables';
import {
    ColorBaseGrey600,
    ColorFgMuted,
    ColorFgSubtle
} from '@/componentLibrary/tokens/variables';

export const chartProps = {
    theme: 'fusion',
    baseFont: 'Euclid Circular A',
    baseFontSize: '14',
    baseFontColor: ColorFgMuted,
    chartLeftMargin: 4,
    chartRightMargin: 4,
    labelPadding: 12,
    decimals: '0',
    divLineColor: ColorBaseGrey600,
    showToolTip: 0,
    showHoverEffect: 0,
    plotHoverEffect: 0,
    drawCrossLine: false,
    drawAnchors: false,
    legendPosition: 'bottom-left',
    legendItemFontColor: ColorFgMuted,
    legendIconBgColor: ColorBaseBrown100,
    drawCustomLegendIcon: true,
    legendIconSides: 1,
    legendIconScale: 1,
    labelFontColor: ColorFgSubtle,
    legendItemFontSize: 12,
    yAxisValueFontColor: ColorFgMuted,
    yAxisMinValue: 0,
    yAxisMaxValue: 40,
    numDivLines: 3,
    numberSuffix: 'm',
    animation: 0,
    defaultAnimation: 0
};
