import {getUserName} from '../../../utils/misc';

export function filterTeamMembership(searchString, teamMembership) {
    const {employment} = teamMembership;
    const searchStringLowerCase = searchString ? searchString.toLowerCase() : '';
    if (
        getUserName(employment ? employment.user : null, {guessFromEmail: true})
            .toLowerCase()
            .includes(searchStringLowerCase)
    ) {
        return true;
    }

    if (
        employment &&
        employment.jobTitle &&
        employment.jobTitle.toLowerCase().includes(searchStringLowerCase)
    ) {
        return true;
    }

    return teamMembership.team.name.toLowerCase().includes(searchStringLowerCase);
}

export function filterJobPositions(searchString, jobPosition) {
    const searchStringLowerCase = searchString ? searchString.toLowerCase() : '';

    if (jobPosition.name && jobPosition.name.toLowerCase().includes(searchStringLowerCase)) {
        return true;
    }

    if (
        jobPosition.hiringManager &&
        getUserName(jobPosition.hiringManager.user, {guessFromEmail: true})
            .toLowerCase()
            .includes(searchStringLowerCase)
    ) {
        return true;
    }

    return (
        jobPosition.team && jobPosition.team.name.toLowerCase().includes(searchStringLowerCase)
    );
}

export function defaultFilter(searchString, entity) {
    if (!searchString) {
        return true;
    }
    return Object.keys(entity).find(key => {
        const value = entity[key];
        if (value instanceof Object && !Array.isArray(value)) {
            return defaultFilter(searchString, value);
        }
        return (
            typeof value === 'string' &&
            value.toLowerCase().includes(searchString.toLowerCase())
        );
    });
}
