import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';

import {getConfig} from '@/config';

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
(FusionCharts.options as any).license({
    key: getConfig().FUSION_CHARTS_LICENSE_KEY,
    creditLabel: false
});

ReactFC.fcRoot(FusionCharts, Charts, PowerCharts, FusionTheme);

export const FusionChart = ReactFC;
