import {gql} from '../types/__generated__';

export const GET_RELEVANCE_PARAMETER_OPTIONS = gql(`
    query getRelevanceParametersOptions {
        relevanceParametersOptions {
            jobFamilies {
                id
                specialities
            }
            newCities {
                id
                name
                countryName
            }
            industries
            companyTypes
            languages
            workLocations
        }
    }
`);

export const GET_CITY_SEARCH_RESULTS = gql(`
    query getCitySearchResults($search_key: String!) {
        citySearchResults(searchKey: $search_key) {
            id
            name
            countryName
        }
    }
`);

export const GET_JOB_RELEVANCE_INFORMATION = gql(`
    query getJobRelevanceInformation($jobPositionId: Int!) {
        jobPosition(id: $jobPositionId) {
            id
            jobRelevanceInformation {
                jobPositionId
                citiesWithMetaData {
                    id
                    name
                    countryName
                }
                workLocations
                jobFamily
                jobFamilySpecialties
                levelOfExperience
                hasLeadershipResponsibilities
                requiredLanguages
                roleDescription
                familiarityDescription
            }
        }
    }
`);

export const GET_MY_JOB_RECOMMENDATIONS = gql(`
    query getMyJobRecommendations {
        me {
            id
            jobRecommendations {
                id
                createdAt
                userId
                status
                publicJobPosition {
                    id
                    name
                    organizationId
                    organizationName
                    organizationLogo
                    organizationExternalPrivacyPolicyUrl
                    externalJobAdUrl
                }
                jobRelevanceInformation {
                    jobPositionId
                    citiesWithMetaData {
                        id
                        name
                        countryName
                    }
                    workLocations
                    jobFamily
                    jobFamilySpecialties
                    levelOfExperience
                    hasLeadershipResponsibilities
                    requiredLanguages
                    roleDescription
                    familiarityDescription
                    autoGenerated
                }
            }
        }
    }
`);
