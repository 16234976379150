import {useIntl} from 'react-intl';

import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';

import {VALIDATION_RESULT_TYPE} from '../../constants';
import messages from './messages';
import {LogicTestValidationIndicatorProps} from './types';

export function LogicTestValidationIndicator({
    isValidationRequested,
    isValidationCompleted,
    validationResultType
}: LogicTestValidationIndicatorProps) {
    const intl = useIntl();

    if (!isValidationCompleted && isValidationRequested) {
        const titleText = intl.formatMessage(messages.validationRequestSent);
        return <Banner noFullwidth>{titleText}</Banner>;
    }

    if (validationResultType === VALIDATION_RESULT_TYPE.VALIDATED) {
        const titleText = intl.formatMessage(messages.testResultValidated);
        return (
            <Banner type={BannerType.SUCCESS} noFullwidth>
                {titleText}
            </Banner>
        );
    }

    if (
        validationResultType === VALIDATION_RESULT_TYPE.LOWER ||
        validationResultType === VALIDATION_RESULT_TYPE.HIGHER
    ) {
        const titleText = intl.formatMessage(messages.testResultIsDifferent);
        return (
            <Banner type={BannerType.ERROR} noFullwidth>
                {titleText}
            </Banner>
        );
    }

    return null;
}
