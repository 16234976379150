import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {Alert} from './Alert';
import messages from './messages';
import {AlertVariationProps} from './types';

export const ConfirmAlert = ({
    title,
    leftButton,
    rightButton,
    ...rest
}: AlertVariationProps) => {
    const phrases = useExtractPhraseConstants(messages);
    return (
        <Alert
            title={title || phrases.areYouSure}
            leftButton={leftButton || phrases.cancel}
            rightButton={rightButton || phrases.confirm}
            confirm
            {...rest}
        />
    );
};
