const MINIMUM_SCORE = 0;
const MAXIMUM_SCORE = 1;
const INTERPOLATION_SLOPE = 0.25;

export function calculateScoreFromTestProfileBounds({result, lowerBound, upperBound}) {
    let distanceFromRange = 0;
    if (result < lowerBound) {
        distanceFromRange = Math.abs(result - lowerBound);
    }

    if (result > upperBound) {
        distanceFromRange = Math.abs(result - upperBound);
    }

    const score = MAXIMUM_SCORE - INTERPOLATION_SLOPE * distanceFromRange;
    let formattedScore = score;
    if (score < MINIMUM_SCORE) {
        formattedScore = MINIMUM_SCORE;
    }

    return formattedScore;
}
