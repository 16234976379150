import {FetchResult, useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {
    AuthorizationResultInput,
    RequestChallengeAccountAccessMutation
} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {REQUEST_CHALLENGE_ACCOUNT_ACCESS} from './mutations';

export function useRequestChallengeAccountAccess(): MutationHookReturnType<
    RequestChallengeAccountAccessMutation,
    [string, AuthorizationResultInput?]
> {
    const [mutate, {error, loading}] = useMutation(REQUEST_CHALLENGE_ACCOUNT_ACCESS);

    const doChallengeAccountAccess = useCallback(
        (
            challengeId: string,
            input?: AuthorizationResultInput
        ): Promise<FetchResult<RequestChallengeAccountAccessMutation>> => {
            return mutate({variables: {challengeId, input}});
        },
        [mutate]
    );

    return [doChallengeAccountAccess, {error, loading}];
}
