import styled from 'styled-components';

import {ModalBody as BaseModalBody} from '@/componentLibrary/blocks/Modals/Modal/styled';
import {
    ColorBaseGrey200,
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const ModalBody = styled(BaseModalBody)`
    padding: ${SpacingLarge} ${SpacingXlarge} ${SpacingXlarge} ${SpacingXlarge};
`;

export const TestDetailSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingMedium};
    padding-bottom: ${SpacingXlarge};
`;

export const TestDetailTable = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXxsmall};
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: ${SpacingSmall} 0;
    gap: ${SpacingXlarge};
`;

export const RowTitle = styled.div`
    width: 278px;
    min-width: 278px;
`;

export const RowDescription = styled.div<{color?: string}>`
    display: flex;
    flex-direction: column;
    ${({color}) => (color ? `color: ${color}` : '')}
`;

export const GithubRowDescription = styled(RowDescription)`
    gap: ${SpacingXxsmall};
`;

export const RowDivider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${ColorBaseGrey200};
`;

export const TimeEffortSection = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${SpacingXlarge};
    gap: ${SpacingMedium};
`;

export const TimeEffortSectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXsmall};
`;

export const TimeEffortTable = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXxsmall};
`;
