import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {
    GetAssessmentMethodsForJobPositionQuery,
    GetJobApplicationQuery,
    GetJobPositionQuery
} from '@/api/types/__generated__/graphql';

export const useHasCombinedRoleFitBetaTest = (
    jobPosition:
        | GetJobPositionQuery['jobPosition']
        | NonNullable<GetJobApplicationQuery['jobApplication']>['jobPosition']
        | GetAssessmentMethodsForJobPositionQuery['jobPosition']
        | null
) => jobPosition?.enabledBetaFeatures?.includes('COMBINED_ROLE_FIT') ?? false;

export function useHasCombinedRoleFitBetaTestByJobPositionId(jobPositionId: number) {
    const {jobPosition} = useJobPosition(jobPositionId);
    return useHasCombinedRoleFitBetaTest(jobPosition);
}
