import styled from 'styled-components';

import {SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const UserWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const AvatarWrapper = styled.div`
    display: flex;
    margin-right: ${SpacingXsmall};
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
