import {useEffect, useState} from 'react';

import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {LoadingScreen} from '@/componentLibrary/components/LoadingScreen';
import {H3, P2} from '@/componentLibrary/components/typography';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {Wrapper} from '../MyOrganizations/styled';
import {MyApplication} from './components/MyApplication';
import {PendingOrganization} from './components/PendingOrganization';
import {useMyApplicationsLogic} from './hooks';
import messages from './messages';
import {InnerCardWrapper, InnerWrapper} from './styled';

export function MyApplications() {
    const phrases = useExtractPhraseConstants(messages);
    const {
        loading,
        error,
        refetchUserOrganizations,
        userOrganizations,
        myApplications,
        setCandidateOrganizationSelectedByUser,
        setPollingEnabledAfterAcceptingRecommendation,
        pollingEnabledAfterAcceptingRecommendation,
        isRightAfterSignup,
        pendingInvitations
    } = useMyApplicationsLogic();

    const [reloadingOnSignup, setReloadingOnSignup] = useState(false);

    useEffect(() => {
        if (userOrganizations.length === 0 && isRightAfterSignup) {
            setReloadingOnSignup(true);
            setPollingEnabledAfterAcceptingRecommendation(true);
        }
    }, [
        isRightAfterSignup,
        userOrganizations.length,
        setPollingEnabledAfterAcceptingRecommendation
    ]);

    if (error) {
        return <ErrorState error={error} refetch={refetchUserOrganizations} />;
    }

    if (loading || (userOrganizations.length === 0 && reloadingOnSignup)) {
        return <LoadingScreen />;
    }

    return (
        <Wrapper>
            {pendingInvitations.length > 0 && !pollingEnabledAfterAcceptingRecommendation && (
                <InnerWrapper>
                    <H3>{phrases.pendingInvitationsTitle}</H3>
                    <InnerCardWrapper>
                        {pendingInvitations.map(userOrganization => (
                            <PendingOrganization
                                key={userOrganization.id}
                                userOrganization={userOrganization}
                            />
                        ))}
                    </InnerCardWrapper>
                </InnerWrapper>
            )}
            <InnerWrapper>
                <H3>{phrases.myApplicationsTitle}</H3>
                <InnerCardWrapper>
                    {myApplications.length === 0 && <P2>{phrases.noApplications}</P2>}
                    {myApplications.map(userOrganization => (
                        <MyApplication
                            key={userOrganization.id}
                            userOrganization={userOrganization}
                            goToJobApplication={setCandidateOrganizationSelectedByUser}
                        />
                    ))}
                </InnerCardWrapper>
            </InnerWrapper>
        </Wrapper>
    );
}
