import {useMemo, useState} from 'react';

import {useRelevanceParametersOptions} from '@/api/jobRecommendations/useRelevanceParametersOptions';
import {useGetRelevanceInformation} from '@/api/users/useGetRelevanceInformation';
import {usePollUntilChange} from '@/hooks/usePollUntilChange';

type Props = {
    userId?: number;
};

export function useRelevanceDataLogic({userId}: Props) {
    const {relevanceParametersOptions, loading: loadingRelevanceParametersOptions} =
        useRelevanceParametersOptions();
    const {
        userRelevanceInformation,
        startPolling: startPollingUserRelevanceInformation,
        stopPolling: stopPollingUserRelevanceInformation,
        loading: loadingUserRelevance
    } = useGetRelevanceInformation(userId);

    const {startPollingUntilChange: pollForUserRelevanceInformation, isPolling} =
        usePollUntilChange({
            start: startPollingUserRelevanceInformation,
            stop: stopPollingUserRelevanceInformation,
            timeout: 10000,
            dataToChange: userRelevanceInformation
        });

    const [showRelevanceForm, setShowRelevanceForm] = useState(false);

    const onSubmitForm = () => {
        pollForUserRelevanceInformation();
        setShowRelevanceForm(false);
    };

    const isMissingBasicRelevance = useMemo(() => {
        if (!userRelevanceInformation && loadingUserRelevance) {
            return undefined;
        }

        if (!userRelevanceInformation) {
            return true;
        }

        return (
            userRelevanceInformation.preferredJobFamily === null ||
            userRelevanceInformation.spokenLanguages?.length === 0
        );
    }, [userRelevanceInformation, loadingUserRelevance]);

    const loading = loadingRelevanceParametersOptions || loadingUserRelevance || isPolling;

    return {
        relevanceParametersOptions,
        userRelevanceInformation,
        pollForUserRelevanceInformation,
        showRelevanceForm,
        setShowRelevanceForm,
        onSubmitForm,
        loading,
        isMissingBasicRelevance
    };
}
