import {ApolloError} from '@apollo/client';
import {ReactNode} from 'react';

import {DisplaySmall} from '@/componentLibrary/components/typography/Display';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {logger} from '@/services/logrocket';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {ColorBaseGrey600} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {ERROR_CODES} from './constants';
import messages from './messages';
import {InnerWrapper, Wrapper} from './styled';
import {Props} from './types';

export function ErrorPage({errorCode}: Props) {
    logger.warn('Failed to authenticate candidate', errorCode);
    const phrases = useExtractPhraseConstants(messages);

    const errorTitle = (errorCode: string | ApolloError): ReactNode => {
        return errorCode === ERROR_CODES.TOKEN_EXPIRED
            ? phrases.tokenExpiredTitle
            : phrases.invalidTokenTitle;
    };
    const errorInformation = (errorCode: string | ApolloError): ReactNode => {
        return errorCode === ERROR_CODES.TOKEN_EXPIRED
            ? phrases.tokenExpiredInformation
            : phrases.invalidTokenInformation;
    };

    return (
        <Wrapper>
            <Icon icon={Icons.WARNING} size={72} fill={ColorBaseGrey600} />

            <InnerWrapper>
                <DisplaySmall withMargin>{errorTitle(errorCode)}</DisplaySmall>
                <P2>{errorInformation(errorCode)}</P2>
            </InnerWrapper>
        </Wrapper>
    );
}
