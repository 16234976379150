export enum InitialActiveRecommendationState {
    REJECT,
    READ_MORE
}

export const MODAL_KEYS = {
    REJECT: 'REJECT',
    READ_MORE: 'READ_MORE',
    SUBMIT_RESUME: 'SUBMIT_RESUME',
    LINKEDIN_FEEDBACK: 'LINKEDIN_FEEDBACK',
    SHARE_CONFIRMATION: 'SHARE_CONFIRMATION',
    SENT_CONFIRMATION: 'SENT_CONFIRMATION'
};

export const showRejectState = {
    REJECT: true,
    READ_MORE: false,
    SUBMIT_RESUME: false,
    LINKEDIN_FEEDBACK: false,
    SHARE_CONFIRMATION: false,
    SENT_CONFIRMATION: false
};

export const showReadMoreState = {
    REJECT: false,
    READ_MORE: true,
    SUBMIT_RESUME: false,
    LINKEDIN_FEEDBACK: false,
    SHARE_CONFIRMATION: false,
    SENT_CONFIRMATION: false
};
