import {SpacingSmall, SpacingXsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
    display: flex;
    gap: ${SpacingSmall};

    @media (max-width: 320px) {
        flex-direction: column;
        gap: ${SpacingXsmall};
    }
`;
