import {Icons} from '@/componentLibrary/components/icons/constants';

import {Dictionary} from './types';

export enum Block {
    BASIC = 'BASIC',
    TIMES = 'TIMES',
    LABELS = 'LABELS',
    SCORECARDS = 'SCORECARDS'
}

export enum BlockMode {
    EDIT = 'EDIT',
    VIEW = 'VIEW'
}

export const DUPLICATE_PREFIX_DIVIDER = ' — ';

export const BLOCKS = [Block.BASIC, Block.TIMES, Block.LABELS, Block.SCORECARDS];

export const initialBlockChallengeData = {
    [Block.BASIC]: null,
    [Block.LABELS]: null,
    [Block.SCORECARDS]: null,
    [Block.TIMES]: null
};

export const iconMapping: Dictionary = {
    CheckCircleIcon: Icons.CHECK_CIRCLE,
    ClipboardListIcon: Icons.LIST_ALT,
    CodeIcon: Icons.CODE
};

export const defaultBlockState = {
    BASIC: BlockMode.VIEW,
    TIMES: BlockMode.VIEW,
    LABELS: BlockMode.VIEW,
    SCORECARDS: BlockMode.VIEW
};

export const ViewWidth = '568px';
export const CardWidth = '1024px';
export const ModalWidth = '656px';

export const NAME_FIELD_REGEXP = /[A-Za-z]+/;

export enum LocationName {
    CODING_TESTS = 'coding_tests',
    ARCHIVE_CODING_TESTS = 'archive_coding_tests',
    DUPLICATE_TEST = 'duplicate_test',
    CODING_TEST = 'coding_test',
    CREATE_TEST = 'create_test'
}
