import PropTypes from 'prop-types';
import {Fragment, useReducer} from 'react';
import styled from 'styled-components';

import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {S2} from '@/componentLibrary/components/typography/SubTitles';
import {SpacingXsmall} from '@/componentLibrary/tokens/variables';

import {AssessmentMethodContribution} from './components/AssessmentMethodContribution';
import {RoleFitSummary} from './components/RoleFitSummary';
import {useRoleFitContributionChartLogic} from './logic';
import {
    TooltipHoverStateContext,
    initialState,
    reducer
} from './utils/TooltipHoverStateContext';
import {ROLE_FIT_CONTRIBUTION_TYPES} from './utils/constants';

export function RoleFitContributionChart(props) {
    const {logicTestContribution, personalityFacetsContribution, roleFitScore} = props;

    const {testScoresAreMissing, missingScoresText} = useRoleFitContributionChartLogic({
        logicTestContribution,
        personalityFacetsContribution,
        roleFitScore
    });

    const [hoveredItemId, dispatch] = useReducer(reducer, initialState);

    return (
        <TooltipHoverStateContext.Provider value={{hoveredItemId, dispatch}}>
            <Fragment>
                <MissingScores text={missingScoresText} />
                <FlexLayout direction={'column'}>
                    {personalityFacetsContribution ? (
                        <AssessmentMethodContribution
                            contributionTypeId={
                                ROLE_FIT_CONTRIBUTION_TYPES.PERSONALITY_TEST.id
                            }
                            contributionItems={personalityFacetsContribution}
                            isBlurred={testScoresAreMissing}
                        />
                    ) : null}
                    {logicTestContribution ? (
                        <AssessmentMethodContribution
                            contributionTypeId={ROLE_FIT_CONTRIBUTION_TYPES.LOGIC_TEST.id}
                            contributionItems={logicTestContribution}
                            isBlurred={testScoresAreMissing}
                        />
                    ) : null}
                    <RoleFitSummary score={roleFitScore} />
                </FlexLayout>
            </Fragment>
        </TooltipHoverStateContext.Provider>
    );
}

RoleFitContributionChart.propTypes = {
    logicTestContribution: PropTypes.arrayOf(
        PropTypes.shape({
            contribution: PropTypes.number,
            maxContribution: PropTypes.number
        })
    ),
    personalityFacetsContribution: PropTypes.arrayOf(
        PropTypes.shape({
            facet: PropTypes.string,
            contribution: PropTypes.number,
            maxContribution: PropTypes.number
        })
    ),
    roleFitScore: PropTypes.number
};

function MissingScores({text}) {
    return (
        <MissingScoresWrapper isHidden={!text}>
            <S2>{text}</S2>
        </MissingScoresWrapper>
    );
}

MissingScores.propTypes = {
    text: PropTypes.string
};

const MissingScoresWrapper = styled.div`
    ${({isHidden}) => isHidden && 'display: none'}
    margin-bottom: ${SpacingXsmall};
`;

MissingScoresWrapper.propTypes = {
    isHidden: PropTypes.bool
};
