import {Fragment} from 'react';
import {useIntl} from 'react-intl';

import {EmptyState} from '@/componentLibrary/blocks/EmptyState';
import {OverviewHeader} from '@/componentLibrary/blocks/testReports/PersonalityTest/components/OverviewHeader';

import messages from './messages';

export function PersonalityEmptyState() {
    const intl = useIntl();

    return (
        <Fragment>
            <OverviewHeader disabled />
            <EmptyState
                titleText={intl.formatMessage(messages.personalityNotCompletedTitle)}
                contentText={intl.formatMessage(messages.personalityNotCompletedDescription)}
            />
        </Fragment>
    );
}
