import {isGHPages} from '@/config';

import {Icons} from './constants';

export function buildIconSrc(icon: Icons): string {
    return isGHPages() ? `./icons/${icon}.svg` : `/icons/${icon}.svg`;
}

export function isBrandLogo(icon: Icons): boolean {
    return [Icons.GOOGLE_LOGO, Icons.MICROSOFT_LOGO].includes(icon);
}
