import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';

import {useRevokeAccessFromOrganization} from '@/api/users/useRevokeAccessFromOrganization';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {userLinks} from '@/pages/User/links';
import {ALVA_SELECTED_ORGANIZATION_KEY} from '@/services/auth/constants';

import messages from './messages';

export function useRevokeAccessFromOrganizationModalLogic(
    userId: number,
    organizationId: number | null,
    closeModal: () => void
) {
    const {formatMessage} = useIntl();
    const [doRevokeAccess, {loading}] = useRevokeAccessFromOrganization(
        userId,
        organizationId
    );
    const history = useHistory();
    const selectedOrganizationId = window.localStorage.getItem(ALVA_SELECTED_ORGANIZATION_KEY);

    const revokeAccess = useCallback(() => {
        if (!userId || !organizationId) {
            return;
        }
        doRevokeAccess()
            .then(({data}) => {
                const {ok, errorMessage} = data?.revokeAccessFromOrganization ?? {};
                if (ok) {
                    toast({
                        type: 'success',
                        message: formatMessage(messages.revokedAccess)
                    });
                    closeModal();
                    if (
                        selectedOrganizationId &&
                        parseInt(selectedOrganizationId, 10) === organizationId
                    ) {
                        history.push(userLinks.accountSettings());
                    }
                } else {
                    throw new Error(
                        errorMessage ?? 'Unknown error in revokeAccessFromOrganization'
                    );
                }
            })
            .catch(() => toast({type: 'error'}));
    }, [
        userId,
        organizationId,
        doRevokeAccess,
        formatMessage,
        closeModal,
        selectedOrganizationId,
        history
    ]);

    return {
        loading,
        revokeAccess
    };
}
