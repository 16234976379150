import {useIntl} from 'react-intl';

import {EmptyState} from '@/componentLibrary/blocks/EmptyState';
import {OverviewHeader} from '@/componentLibrary/blocks/testReports/LogicTest/components/OverviewHeader';

import messages from './messages';

export function LogicTestResultEmptyState() {
    const intl = useIntl();

    return (
        <>
            <OverviewHeader disabled />
            <EmptyState
                titleText={intl.formatMessage(messages.logicNotCompletedTitle)}
                contentText={intl.formatMessage(messages.logicNotCompletedDescription)}
            />
        </>
    );
}
