import {useCallback} from 'react';

import {useSendResetPasswordEmail} from '@/api/users/useSendResetPasswordEmail';

import {useIntl} from 'react-intl';
import messages, {errorMessages} from './messages';

export function useRecoverPasswordLogic(
    showToast: (show: boolean, message: {id: string}) => void
) {
    const [doSendEmail, {loading}] = useSendResetPasswordEmail();
    const intl = useIntl();

    const sendResetPasswordEmail = useCallback(
        (email: string) => {
            doSendEmail(email)
                .then(response => {
                    const {ok, errorMessage} = response.data?.sendResetPasswordEmail || {};
                    if (ok) {
                        showToast(false, messages.emailMaybeSent);
                    } else {
                        let messageKey = messages.somethingWentWrong;
                        if (errorMessage && errorMessage in errorMessages) {
                            messageKey =
                                errorMessages[errorMessage as keyof typeof errorMessages];
                        }
                        throw new Error(intl.formatMessage(messageKey));
                    }
                })
                .catch((error: Error) => {
                    const knownErrorMessage =
                        errorMessages[error.message as keyof typeof errorMessages];
                    const errorMessage = knownErrorMessage ?? messages.somethingWentWrong;
                    showToast(true, errorMessage);
                });
        },
        [showToast, doSendEmail, intl]
    );

    return {sendResetPasswordEmail, loading};
}
