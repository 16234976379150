import {GetJobApplication_JobApplication_ChallengeAssignment_PublishedScorecards as Scorecards} from '@/api/assessment/types';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';
import {SCORECARD_ACTION_TYPE} from '../../../JobPosition/constants';
import {AUTOSCORING_DISABLED_PROFILES} from './constants';
import {ChallengeAssignment} from './types';

interface MinimalScoreCard {
    requirement: string;
    medianScore?: number;
}

function calculateMedian(scores: number[]) {
    if (scores.length === 0) {
        return undefined;
    }
    const sortedScores = [...scores].sort((a, b) => a - b);
    const mid = Math.floor(sortedScores.length / 2);

    return sortedScores.length % 2 !== 0
        ? sortedScores[mid]
        : (sortedScores[mid - 1] + sortedScores[mid]) / 2;
}

export const getMinimalScoreCards = (
    scoreCards?: Scorecards | null
): MinimalScoreCard[] | null => {
    if (!isDefined(scoreCards)) {
        return null;
    }
    return Object.values(
        scoreCards.reduce(
            (acc, scoreCard) => {
                if (!isDefined(scoreCard)) {
                    return acc;
                }
                const {requirement, scores} = scoreCard;
                if (!acc[requirement]) {
                    acc[requirement] = {requirement, scores: []};
                }
                acc[requirement].scores.push(
                    ...scores.filter(s => isDefined(s.score)).map(s => s.score ?? 0)
                );
                return acc;
            },
            {} as Record<string, {requirement: string; scores: number[]}>
        )
    ).map(({requirement, scores}) => ({
        requirement,
        medianScore: calculateMedian(scores)
    }));
};

export const getAssignmentScore = (challengeAssignment: ChallengeAssignment | null) => {
    if (!challengeAssignment) {
        return null;
    }

    const {useOverdueScore, overdueAutomatedScore, automatedScore} = challengeAssignment;

    return useOverdueScore ? overdueAutomatedScore : automatedScore;
};

const getReviewScoreScorecard = (challengeAssignment: ChallengeAssignment | null) => {
    if (!challengeAssignment) {
        return null;
    }

    const {timeLimitMinutes, overdueAutomatedScore, automatedScore} = challengeAssignment;

    return timeLimitMinutes && overdueAutomatedScore?.reviewScore
        ? overdueAutomatedScore.reviewScore.scorecardVersion
        : automatedScore?.reviewScore?.scorecardVersion;
};

export const getScorecardActionType = (challengeAssignment: ChallengeAssignment | null) => {
    if (
        !challengeAssignment ||
        AUTOSCORING_DISABLED_PROFILES.includes(challengeAssignment.challenge.profile)
    ) {
        return null;
    }

    const assignmentScore = getAssignmentScore(challengeAssignment);

    if (
        (!challengeAssignment.scorecardVersion ||
            !assignmentScore?.reviewScore?.scorecardVersion) &&
        challengeAssignment.challenge.scorecard.version
    ) {
        return SCORECARD_ACTION_TYPE.ADDED;
    }

    if (
        challengeAssignment.scorecardVersion !==
        challengeAssignment.challenge.scorecard.version
    ) {
        if (!challengeAssignment.challenge.scorecard.requirements.length) {
            return SCORECARD_ACTION_TYPE.DELETED;
        }
        return SCORECARD_ACTION_TYPE.UPDATED;
    }

    return null;
};

export const isSyncInProgress = (challengeAssignment: ChallengeAssignment | null) => {
    if (challengeAssignment?.status !== ChallengeAssignmentStatus.SUBMITTED) {
        return false;
    }

    if (
        !challengeAssignment ||
        AUTOSCORING_DISABLED_PROFILES.includes(challengeAssignment.challenge.profile)
    ) {
        return false;
    }

    const {scorecardVersion} = challengeAssignment;

    const usedScorecardVersion = getReviewScoreScorecard(challengeAssignment);

    if (
        challengeAssignment.challenge.scorecard.requirements.length > 0 &&
        !usedScorecardVersion &&
        challengeAssignment.challenge.scorecard.version ===
            challengeAssignment.scorecardVersion
    ) {
        return true;
    }

    return !!(
        scorecardVersion &&
        usedScorecardVersion &&
        usedScorecardVersion !== scorecardVersion
    );
};
