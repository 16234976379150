import {defineMessages} from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'app.components.confirmDialog.cancel'
    },
    back: {
        id: 'app.modalEmpty.goBack'
    }
});
