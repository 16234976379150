import {useMergeRefs} from '@floating-ui/react';
import {HTMLProps, cloneElement, forwardRef, isValidElement} from 'react';

import {useActionMenuContext} from '../hooks';
import {ActionMenuTriggerProps} from '../types';
import {Button} from './styled';

export const ActionMenuTrigger = forwardRef<
    HTMLElement,
    HTMLProps<HTMLElement> & ActionMenuTriggerProps
>(function ActionMenuTrigger({children, asChild = false, asDiv = false, ...props}, propRef) {
    const context = useActionMenuContext();
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs<HTMLElement | null>([
        context.refs.setReference,
        propRef,
        childrenRef
    ]);

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
        return cloneElement(
            children,
            context.getReferenceProps({
                ref,
                ...props,
                ...children.props,
                'data-state': context.open ? 'open' : 'closed',
                onClick: e => e.stopPropagation()
            })
        );
    }

    // `asDiv` uses a simple div instead of a button
    if (asDiv) {
        return (
            <div
                ref={ref}
                // The user can style the trigger based on the state
                data-state={context.open ? 'open' : 'closed'}
                {...context.getReferenceProps(props)}
            >
                {children}
            </div>
        );
    }

    return (
        <Button
            ref={ref}
            type="button"
            // The user can style the trigger based on the state
            data-state={context.open ? 'open' : 'closed'}
            {...context.getReferenceProps({...props, onClick: e => e.stopPropagation()})}
        >
            {children}
        </Button>
    );
});
