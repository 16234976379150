import alvaLogo from './assets/alvaLogoWithText.svg';

import styled from 'styled-components';

export function LogoWithText() {
    return <AlvaLogoWithText />;
}

const AlvaLogoWithText = styled.div`
    width: 104px;
    height: 32px;
    background: url("${alvaLogo}") center center no-repeat;
    background-size: contain;
`;
