import {ReactNode} from 'react';

import {Tooltip} from '@/componentLibrary/components/Tooltips/Tooltip';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {ColorBaseYellow900} from '@/componentLibrary/tokens/variables';

type WarningTriangleProps = {
    text?: ReactNode;
    isLarge?: boolean;
    withTooltip?: boolean;
};

export function WarningTriangle({text, isLarge, withTooltip = true}: WarningTriangleProps) {
    return (
        <Tooltip
            content={withTooltip && text}
            trigger={
                <Icon
                    icon={Icons.WARNING}
                    size={isLarge ? 24 : 16}
                    fill={ColorBaseYellow900}
                />
            }
        />
    );
}
