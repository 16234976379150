import {defineMessages} from 'react-intl';

export default defineMessages({
    goHome: {
        id: 'app.organizationSettings.noAccessFallback.goHome'
    },
    switchOrganization: {
        id: 'app.organizationSettings.noAccessFallback.switchOrganization'
    },
    accessDenied: {
        id: 'app.organizationSettings.noAccessFallback.accessDenied'
    },
    body: {
        id: 'app.organizationSettings.noAccessFallback.body'
    }
});
