import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {Caption, H4} from '@/componentLibrary/components/typography';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useEmailPromptLogic} from './hooks/useEmailPromptLogic';
import messages from './messages';
import {SmallGapWrapper, Wrapper} from './styles';
import {SharedResultsEmailPromptProps} from './types';

export const SharedResultsEmailPrompt = ({
    token,
    loggedInEmail,
    onShowResults
}: SharedResultsEmailPromptProps) => {
    const phrases = useExtractPhraseConstants(messages);
    const {email, updateEmail, saving, doViewResults, validEmail} = useEmailPromptLogic(
        token,
        loggedInEmail,
        onShowResults
    );

    return (
        <Wrapper>
            <H4>{phrases.title}</H4>
            <SmallGapWrapper>
                <TextField
                    data-testid={'shared-results-email-input'}
                    fullWidth
                    label={phrases.yourEmail}
                    placeholder={'you@mailbox.com'}
                    value={email}
                    disabled={saving}
                    onChange={e => updateEmail(e.target.value)}
                />
                <PrimaryButton
                    data-testid={'view-results-button'}
                    isFullWidth
                    onClick={doViewResults}
                    disabled={!validEmail || saving}
                    isLoading={saving}
                >
                    {phrases.viewResults}
                </PrimaryButton>
            </SmallGapWrapper>
            <Caption color={ColorFgSubtle}>{phrases.disclaimer}</Caption>
        </Wrapper>
    );
};
