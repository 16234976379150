import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {toast} from '@/componentLibrary/components/Toast/toast';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {PendingOrganizationProps} from '../../../MyApplications/components/PendingOrganization/types';
import {RejectInvitationModal} from './components/RejectInvitationModal';
import {useAcceptInvitationLogic} from './hooks';
import messages from './messages';
import {ActionsWrapper, FooterRow, Header, Wrapper} from './styled';

export function AcceptInvitationInfo({userOrganization}: PendingOrganizationProps) {
    const {formatMessage} = useIntl();
    const phrases = useExtractPhraseConstants(messages);

    const displaySuccessToast = useCallback(
        () => toast({type: 'success', message: phrases.invitationAccepted}),
        [phrases.invitationAccepted]
    );

    const displayErrorToast = useCallback(
        () => toast({type: 'error', message: phrases.errorAcceptingInvitation}),
        [phrases.errorAcceptingInvitation]
    );

    const {
        loading,
        isRejectModalOpen,
        isRejected,
        showRejectInvitationModal,
        closeRejectInvitationModal,
        onInvitationRejected,
        handleAccept
    } = useAcceptInvitationLogic(userOrganization, displaySuccessToast, displayErrorToast);

    if (isRejected) {
        return (
            <Wrapper>
                <Header>{phrases.invitationRejected}</Header>
            </Wrapper>
        );
    }

    return (
        <>
            <Wrapper>
                <P2>
                    {formatMessage(messages.shareDataText, {
                        name: userOrganization.organizationName
                    })}

                    {userOrganization.organizationExternalPrivacyPolicyUrl ? (
                        <>
                            &nbsp;
                            {formatMessage(messages.externalPrivacyPolicy, {
                                organization: userOrganization.organizationName,
                                link: (
                                    <a
                                        href={
                                            userOrganization.organizationExternalPrivacyPolicyUrl
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {phrases.privacyPolicy}
                                    </a>
                                )
                            })}
                        </>
                    ) : null}
                </P2>
                <FooterRow>
                    <ActionsWrapper>
                        <SecondaryButton onClick={showRejectInvitationModal}>
                            {phrases.deny}
                        </SecondaryButton>
                        <PrimaryButton onClick={handleAccept} isLoading={loading}>
                            {phrases.accept}
                        </PrimaryButton>
                    </ActionsWrapper>
                    <P2 color={ColorFgSubtle}>
                        {userOrganization.isEmployment ? phrases.employee : phrases.candidate}
                    </P2>
                </FooterRow>
            </Wrapper>
            {isRejectModalOpen ? (
                <RejectInvitationModal
                    userOrganization={userOrganization}
                    close={closeRejectInvitationModal}
                    onRejected={onInvitationRejected}
                />
            ) : null}
        </>
    );
}
