import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    FontSizeLargest,
    SpacingLarge,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    margin-top: 30vh;
    min-height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.min}) {
        margin-top: ${FontSizeLargest};
    }
`;
export const DescriptionTitle = styled.div`
    padding-bottom: ${SpacingXsmall};
    font-size: ${SpacingLarge};
`;
