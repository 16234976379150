import {defineMessages} from 'react-intl';

export default defineMessages({
    goToOrganization: {
        id: 'app.pages.user.chooseOrganization.myOrganization.goToOrganization'
    },
    loginWithSso: {
        id: 'app.pages.user.chooseOrganization.myOrganization.loginWithSso'
    }
});
