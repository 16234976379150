import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_ORGANIZATION_QUERY} from '@/api/organizations/queries';
import {GetOrganizationInfo_Organization} from '@/api/organizations/types';

export function useOrganizationInformation(organizationId: number) {
    const {data, error, loading, refetch} = useQuery(GET_ORGANIZATION_QUERY, {
        variables: {
            id: organizationId
        }
    });

    const organization: GetOrganizationInfo_Organization | null = useMemo(
        () => data?.organization ?? null,
        [data]
    );

    return {organization, loading, error, refetch};
}
