import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {tertiaryColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {HELP_CENTER} from '@/constants/helpCenter';

import messages from '../../messages';

export const Link = () => (
    <a href={HELP_CENTER.validateLogicTest} target="_blank" rel="noreferrer noopener">
        <LinkText>
            <FormattedMessage {...messages.readMore} />
        </LinkText>
    </a>
);

const LinkText = styled.span`
    color: ${tertiaryColorTokens.blue300()};
`;
