import {defineMessages} from 'react-intl';

export default defineMessages({
    leadershipReportTitle: {id: 'leadershipReport.printTitle'},
    logicTestReportTitle: {
        id: 'app.assessment.candidatePage.candidateResults.logicTestPrintTitle'
    },
    personalityTestReportTitle: {
        id: 'app.assessment.candidatePage.candidateResults.personalityTestPrintTitle'
    }
});
