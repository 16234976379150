import {defineMessages} from 'react-intl';

export const FACET_DESCRIPTION_MESSAGES = defineMessages({
    AI: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.AI'
    },
    AII: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.AII'
    },
    AIII: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.AIII'
    },
    CI: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.CI'
    },
    CII: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.CII'
    },
    CIII: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.CIII'
    },
    EI: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.EI'
    },
    EII: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.EII'
    },
    EIII: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.EIII'
    },
    ESI: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.ESI'
    },
    ESII: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.ESII'
    },
    ESIII: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.ESIII'
    },
    OI: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.OI'
    },
    OII: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.OII'
    },
    OIII: {
        id: 'app.assessment.candidatePage.roleFit.facetDescription.OIII'
    }
});
