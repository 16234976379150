import {parse} from 'qs';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useLocation} from 'react-router-dom';

import {useOrganizationSubscription} from '@/api/organizations/useOrganizationSubscription';

import {LogoWithText} from '@/componentLibrary/components/Logo/LogoWithText/LogoWithText';
import {StripeCheckoutError} from './components/StripeCancel';
import {StripeSuccess} from './components/StripeSuccess';
import messages from './messages';
import {AlvaLogoWrapper, CenterLogo, MainWrapper, MessageWrapper, SubWrapper} from './styled';

export function StripeLoadingPage() {
    const location = useLocation();
    const {organization, loading} = useOrganizationSubscription(4000);
    const [isCheckoutError, setIsCheckoutError] = useState(false);

    const getRedirectUrl = useMemo(() => {
        const params = parse(location.search, {ignoreQueryPrefix: true});
        if (typeof params['redirectUri'] === 'string') {
            return params['redirectUri'];
        }
        return '/';
    }, [location]);

    useEffect(() => {
        const timer = setTimeout(() => setIsCheckoutError(true), 15000);
        return () => clearTimeout(timer);
    }, []);

    if (!loading) {
        if (organization?.subscription?.isActive === true) {
            return <StripeSuccess redirectUrl={getRedirectUrl} />;
        } else if (isCheckoutError) {
            return <StripeCheckoutError redirectUrl={getRedirectUrl} />;
        }
    }

    return (
        <Fragment>
            <MainWrapper>
                <div />
                <SubWrapper>
                    <CenterLogo />
                    <MessageWrapper>
                        <FormattedMessage
                            {...messages.stripeCheckoutLoadingMessage}
                            values={{value: <br />}}
                        />
                    </MessageWrapper>
                </SubWrapper>
                <AlvaLogoWrapper>
                    <LogoWithText />
                </AlvaLogoWrapper>
            </MainWrapper>
        </Fragment>
    );
}
