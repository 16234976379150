import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {
    BorderRadiusBaseRounded,
    BorderRadiusBaseSmall,
    SpacingXsmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    padding: ${SpacingXxsmall};
    background: linear-gradient(to right, #D2DDFF 0%, #E8EEFF 21%, #EEF3FF 67%, #EAF0FF 100%);
    gap: ${SpacingXxsmall};
    border-radius: ${BorderRadiusBaseSmall};
`;

export const Main = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const PlainButtonEdit = styled(PlainButton)<{$isOneLine?: boolean}>`
    padding: 0;
`;

export const RecommendedLeadershipReportWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: ${SpacingXxsmall} ${SpacingXsmall};
    background: linear-gradient(to right, #D2DDFF 0%, #E8EEFF 21%, #EEF3FF 67%, #EAF0FF 100%);
    border-radius: ${BorderRadiusBaseRounded};
    gap: ${SpacingXxxsmall};
`;
