import PropTypes from 'prop-types';
import {useCallback, useMemo} from 'react';

import {useDefaultEmailTemplate} from '@/api/organizations/emailTemplates/useDefaultEmailTemplate';
import {useOrganization} from '@/api/organizations/useOrganization';
import {useAuthenticatedUser} from '@/api/users/useAuthenticatedUser';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {EMAIL_TYPES} from '@/pages/Organization/pages/Settings/pages/EmailTemplates/constants';
import {getUserName} from '@/utils/misc';

export function WithDefaultEmailContent({emailType, children}) {
    const {
        organization,
        loading: loadingOrganization,
        error: errorOrganization
    } = useOrganization();
    const {user, loading: loadingUser, error: errorUser} = useAuthenticatedUser();
    const {
        defaultEmailTemplate,
        loading: loadingDefaultTemplate,
        error: errorDefaultTemplate
    } = useDefaultEmailTemplate(emailType);
    const replaceContentVariables = useCallback(
        (contentString, organizationName, senderName) => {
            return contentString
                .replace('{organization_name}', organizationName)
                .replace('{sender_name}', senderName)
                .replace('{recipient_name}', '[first name]');
        },
        []
    );

    const emailData = useMemo(() => {
        if (!defaultEmailTemplate || !organization || !user) {
            return null;
        }

        const organizationName = organization.name;
        const senderName = getUserName(user, {fallbackOnEmail: true, firstNameOnly: true});

        return {
            subject: replaceContentVariables(
                defaultEmailTemplate.subject,
                organizationName,
                senderName
            ),
            title: replaceContentVariables(
                defaultEmailTemplate.title,
                organizationName,
                senderName
            ),
            body: replaceContentVariables(
                defaultEmailTemplate.body,
                organizationName,
                senderName
            ),
            linkText: replaceContentVariables(
                defaultEmailTemplate.linkText,
                organizationName,
                senderName
            )
        };
    }, [organization, defaultEmailTemplate, user, replaceContentVariables]);

    if (!emailType) {
        return null;
    }

    if (errorUser) {
        return <ErrorState error={errorUser} />;
    }

    if (errorOrganization) {
        return <ErrorState error={errorOrganization} />;
    }

    if (errorDefaultTemplate) {
        return <ErrorState error={errorDefaultTemplate} />;
    }

    const loading = loadingOrganization || loadingUser || loadingDefaultTemplate;
    return children({
        loading,
        emailData
    });
}

WithDefaultEmailContent.propTypes = {
    emailType: PropTypes.oneOf(Object.values(EMAIL_TYPES)).isRequired,
    children: PropTypes.func.isRequired
};
