import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {logout} from '@/services/auth/actions';

export function useLogout() {
    const dispatch = useDispatch();
    const doLogout = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);
    return {logout: doLogout};
}
