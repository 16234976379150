import {PieData} from 'plotly.js';
import {useIntl} from 'react-intl';

import {FacetName, Weight} from '@/api/types/__generated__/graphql';
import {DonutGraph} from '@/componentLibrary/components/charts/DonutGraph';
import {PersonalityProfileGraphProps} from '@/pages/Organization/pages/Assessment/components/AssessmentSelector/components/PersonalityProfileDetailsModal/components/PersonalityProfileGraph/types';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {
    convertFacetWeightToValue,
    getFacetColor,
    getFacetFullName,
    getFacetWeightMessage
} from '../../utils';
import {messages} from './messages';
import {ChartWrapper} from './styled';

export const PersonalityProfileGraph = ({facets}: PersonalityProfileGraphProps) => {
    const intl = useIntl();
    const colors: PieData['marker']['colors'] = [];
    const legendDisplayValues: string[] = [];
    const data = facets?.map(({name, weight}) => {
        colors.push(getFacetColor(name));
        legendDisplayValues.push(getFacetWeightMessage(intl, weight));
        return {
            label: getFacetFullName(intl, name),
            value: convertFacetWeightToValue(weight)
        };
    });
    const toHoverLabel = ({name, weight}: {name: FacetName; weight: Weight}) => {
        const facetName = getFacetFullName(intl, name);
        const facetWeight = getFacetWeightMessage(intl, weight);
        return `${facetName}<br>${facetWeight}`;
    };
    const hoverLabels = facets?.map(toHoverLabel);
    const facetCaption = intl.formatMessage(messages.facetCaption);
    const weightCaption = intl.formatMessage(messages.weightCaption);

    return isDefined(data) ? (
        <ChartWrapper>
            <DonutGraph
                data={data}
                displayLegend
                tracesOptions={{
                    marker: {colors},
                    hoverinfo: 'text',
                    hovertext: hoverLabels
                }}
                layoutOptions={{height: 320, width: 320}}
                legendOptions={{
                    showValue: true,
                    displayValues: legendDisplayValues,
                    labelCaption: facetCaption,
                    valueCaption: weightCaption,
                    paletteColors: colors
                }}
            />
        </ChartWrapper>
    ) : null;
};
