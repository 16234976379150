import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {GET_PREVIOUS_QUESTION_FOR_PERSONALITY_TEST_V2} from '@/api/personalityTests/mutations';
import {GetPreviousQuestionForPersonalityTestV2Mutation} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';

export function useGetPreviousQuestionForPersonalityTestV2(
    personalityTestId: string
): MutationHookReturnType<GetPreviousQuestionForPersonalityTestV2Mutation, string> {
    const [mutate, {error, loading}] = useMutation(
        GET_PREVIOUS_QUESTION_FOR_PERSONALITY_TEST_V2
    );

    const getPreviousQuestionV2 = useCallback(
        questionId => {
            const getPreviousQuestionForPersonalityTestInput = {
                personalityTestId,
                questionId: questionId
            };
            return mutate({variables: {getPreviousQuestionForPersonalityTestInput}});
        },
        [mutate, personalityTestId]
    );

    return [getPreviousQuestionV2, {error, loading}];
}
