import styled from 'styled-components';

import {H3, S2} from '@/componentLibrary/components/typography';
import {BodyP2} from '@/componentLibrary/tokens/typography';
import {
    BorderBaseDefaultColor,
    ColorBaseBlue800,
    ColorBaseBlue900,
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

import {ModalBody as BaseModalBody} from '@/componentLibrary/blocks/Modals/Modal/styled';

const flexColumn = `
    display: flex;
    flex-direction: column;
`;

export const TimeHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${SpacingXsmall};
`;

export const ModalHeader = styled.div`
    height: 76px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: ${SpacingLarge} ${SpacingXxsmall} ${SpacingLarge} ${SpacingXxsmall};
`;

export const ModalBody = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    margin: 0 auto;
    padding: ${SpacingXlarge} 0;
`;

export const DemographicsModalBody = styled(ModalBody)`
    padding: 0;
`;

export const ContentWrapper = styled.div`
    width: 656px;
    margin: 0 auto;
`;

export const CenteredContentWrapper = styled(ContentWrapper)`
    display: flex;
    height: 100%;
    align-items: center;
`;

export const Header = styled.div`
    ${flexColumn}
    gap: ${SpacingSmall};
    padding-bottom: ${SpacingXlarge};
`;

export const ContentSection = styled.div`
    ${flexColumn}
    padding: ${SpacingXlarge} 0;
    gap: ${SpacingMedium};
`;

export const P2Wrapper = styled.div`
    ${flexColumn}
    gap: ${SpacingXsmall};
`;

export const ModalFooter = styled.div`
    height: 92px;
    width: 100%;
    padding: ${SpacingLarge} 0;
    display: flex;
    margin: 0 auto;
    align-items: center;
    border-top: solid thin #eaeaea;
`;

export const FooterContent = styled.div`
    margin: 0 auto;
    width: 656px;
`;

export const RepositoryLink = styled(H3).attrs(() => ({as: 'a'}))<{visited: boolean}>`
    display: flex;
    align-items: center;
    gap: ${SpacingXxsmall};
    cursor: pointer;
    color: ${ColorBaseBlue800};

    &:hover {
        text-decoration: underline;
    }

    ${({visited}) =>
        visited &&
        `color: ${ColorBaseBlue900};
        text-decoration: underline;`}
`;

export const ResultRepositoryLink = styled(RepositoryLink)`
    ${BodyP2};
    gap: ${SpacingXxxsmall};
`;

export const StartTestAlertWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingLarge};
    padding-bottom: ${SpacingLarge};
`;

export const StartTestBannerWrapper = styled.div`
    display: flex;
    align-self: center;
`;

export const DetailedResultTable = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingSmall};
`;

export const Row = styled.div<{header?: boolean}>`
    display: flex;
    flex-direction: row;
    ${({header}) =>
        header &&
        `
        border-bottom: 1px solid ${BorderBaseDefaultColor};
        padding-bottom: ${SpacingSmall};
    `}
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    min-width: 80%;
    gap: ${SpacingXsmall};
    justify-content: center;
`;

export const Results = styled(Row)`
    align-items: center;
    gap: ${SpacingXxsmall};
`;

export const TestResultsContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingSmall};
`;

export const TestResultsContentModalBody = styled(BaseModalBody)`
    padding: ${SpacingLarge} ${SpacingXlarge} ${SpacingXlarge} ${SpacingXlarge};
`;

export const FetchingText = styled(S2)`
    margin: 0 auto;
`;
