import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
import {useCallback, useEffect, useRef} from 'react';

import {displayIntercom, hideIntercom} from '@/services/intercom/utils';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useIsMobileDevice, useIsTabletDevice} from '@/utils/useIsMobileDevice';

type Props = {
    close: () => void;
    closeOnClickOutside: boolean;
    backdropId: string;
};

export function useModalLogic({close, closeOnClickOutside, backdropId}: Props) {
    const isMobileDevice = useIsMobileDevice();
    const isTabletDevice = useIsTabletDevice();

    const modalRef = useRef<HTMLElement | null>(null);

    const setRef = useCallback((element: HTMLElement | null) => {
        modalRef.current = element;
    }, []);

    const closeModal = useCallback(() => {
        if (close && typeof close === 'function') {
            clearAllBodyScrollLocks();
            close();
        }
    }, [close]);

    const handleClickOutside = useCallback(
        event => {
            if (!closeOnClickOutside) {
                return;
            }
            if (event?.target?.id === backdropId) {
                closeModal();
            }
        },
        [backdropId, closeOnClickOutside, closeModal]
    );

    const handleKeyPress = useCallback(
        event => {
            if (event.keyCode === 27) {
                closeModal();
            }
            event.stopImmediatePropagation();
        },
        [closeModal]
    );

    useEffect(() => {
        if (!(isMobileDevice || isTabletDevice)) {
            isDefined(modalRef.current) && disableBodyScroll(modalRef.current);
        }
        window.addEventListener('keyup', handleKeyPress);
        window.addEventListener('mouseup', handleClickOutside);
        if (isMobileDevice) {
            hideIntercom();
        }

        return function cleanup() {
            clearAllBodyScrollLocks();
            window.removeEventListener('keyup', handleKeyPress);
            window.removeEventListener('mouseup', handleClickOutside);
            if (isMobileDevice) {
                displayIntercom();
            }
        };
    }, [handleClickOutside, handleKeyPress, isMobileDevice, isTabletDevice]);

    return {setRef};
}
