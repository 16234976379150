import {useQuery} from '@apollo/client';

import {GET_LOGGED_IN_USER} from '@/api/users/queries';

export function useLoggedInUser(withConsents = false) {
    const variables = {withConsents};
    const {data, error, loading, refetch} = useQuery(GET_LOGGED_IN_USER, {variables});
    const user = data?.me ?? null;

    return {error, loading, user, refetch};
}
