import {SpacingMedium, SpacingXxlarge} from '@/componentLibrary/tokens/variables';
import {toInt} from '@/utils/misc';

export const CENTER_COLUMN_WIDTH = '280px';
export const LANGUAGE_TOGGLE_WIDTH = '220px';
export const LOGO_SIZE = SpacingXxlarge;
export const CENTER_COLUMN_GAP = SpacingMedium;
export const WRAPPER_HORIZONTAL_PADDING = SpacingXxlarge;
export const LANGUAGE_TOGGLE_INLINE_MARGIN = SpacingMedium;

export const WRAP_WIDTH =
    toInt(LOGO_SIZE) +
    toInt(CENTER_COLUMN_WIDTH) +
    toInt(LANGUAGE_TOGGLE_WIDTH) +
    toInt(WRAPPER_HORIZONTAL_PADDING) * 2 +
    toInt(CENTER_COLUMN_GAP) * 2 +
    toInt(LANGUAGE_TOGGLE_INLINE_MARGIN);
