import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.logicTestReport.testIntro.title'
    },
    intro: {
        id: 'app.logicTestReport.testIntro.intro'
    },
    scoreDescription: {
        id: 'app.logicTestReport.testIntro.scoreDescription'
    }
});
