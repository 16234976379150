import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.resetPassword.title'
    },
    invalidTokenDescription: {
        id: 'app.resetPassword.invalidToken.description'
    },
    unknownErrorDescription: {
        id: 'app.resetPassword.unknownError.description'
    },
    recoverPageText: {
        id: 'app.resetPassword.recoverPageText'
    }
});
