import {useMutation} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {GET_MY_USER_ORGANIZATIONS_WITH_SHARED_TESTS} from '@/api/users/queries';

import {RevokeAccessFromOrganizationMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {REVOKE_ACCESS_FROM_ORGANIZATION} from './mutations';

export function useRevokeAccessFromOrganization(
    userId: number,
    organizationId: number | null
): MutationHookReturnType<RevokeAccessFromOrganizationMutation> {
    const options = useMemo(() => {
        return {refetchQueries: [{query: GET_MY_USER_ORGANIZATIONS_WITH_SHARED_TESTS}]};
    }, []);
    const [mutate, {error, loading}] = useMutation(REVOKE_ACCESS_FROM_ORGANIZATION, options);
    const doRevokeAccess = useCallback(() => {
        if (!organizationId) {
            return Promise.reject(new Error('Organization id is required'));
        }
        return mutate({variables: {userId, organizationId}});
    }, [userId, organizationId, mutate]);

    return [doRevokeAccess, {error, loading}];
}
