import {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useUpdateUserRelevanceData} from '@/api/jobRecommendations/useUpdateUserRelevanceData';
import {ConsentType} from '@/api/types/__generated__/graphql';
import {
    ERROR_TYPES,
    FormType
} from '@/pages/User/components/RecommendationRelevanceForm/constants';
import {useInformationLogic} from '@/pages/User/components/RecommendationRelevanceForm/hooks/useInformationLogic';
import {useFormOptions} from '@/pages/common/RelevanceForm/hooks/useFormOptions';
import {useFormValidationErrors} from '@/pages/common/RelevanceForm/hooks/useFormValidationErrors';
import {useInterestedInLeadershipPositionsLogic} from '@/pages/common/RelevanceForm/hooks/useInterestedInLeadershipPositionsLogic';
import {useJobFamilyLogic} from '@/pages/common/RelevanceForm/hooks/useJobFamilyLogic';
import {useLinkedInProfileLogic} from '@/pages/common/RelevanceForm/hooks/useLinkedInProfileLogic';
import {useLocationLogic} from '@/pages/common/RelevanceForm/hooks/useLocationLogic';
import {useScrollToFormError} from '@/pages/common/RelevanceForm/hooks/useScrollToFormError';
import {logger} from '@/services/logrocket';

import {useStaticLegalContent} from '@/services/staticLegalContent/useStaticLegalContent';
import {RecommendationRelevanceFormProps} from './types';
import {getClientSelectedUserRelevanceInformation} from './utils';

export function useCandidateRelevanceFormLogic({
    close,
    userId,
    relevanceParametersOptions,
    userRelevanceInformation,
    onSubmit
}: RecommendationRelevanceFormProps) {
    const {
        formValidationErrors,
        setFormValidationErrors,
        removeValidationError,
        getErrorText
    } = useFormValidationErrors(FormType.CANDIDATE);
    const [isFormSubmittedSuccessfully, setIsFormSubmittedSuccessfully] = useState(false);
    const [updateUserRelevanceData, {loading: loadingUpdateRelevanceData}] =
        useUpdateUserRelevanceData();
    const history = useHistory();
    const closeInitialProfileSubmitSucessModal = () => {
        close();
        history.push('/start');
    };
    const {scrollToError} = useScrollToFormError();
    const [showConfirmLeaveRelevanceModal, setShowConfirmLeaveRelevanceModal] =
        useState(false);
    const {
        companyTypes,
        languages,
        cities,
        jobFamilies,
        getSelectableJobFamilySpecialities,
        workLocations
    } = useFormOptions(relevanceParametersOptions);

    const {selectedLanguages, setSelectedLanguages, handleLanguagesSelection} =
        useInformationLogic(removeValidationError);

    const {
        selectedWorkLocations,
        setSelectedWorkLocations,
        handleWorkLocationSelection,
        showCitiesSelection,
        selectedCities,
        setSelectedCities,
        handleCitiesSelection,
        isCitySelectionsValid,
        setCitiesSearchKey,
        citiesQueryResults,
        citiesQueryLoading
    } = useLocationLogic(removeValidationError);

    const {
        jobFamily,
        setJobFamily,
        handleJobFamilySelection,
        jobFamilySpecialties,
        selectableJobFamilySpecialities,
        setSelectableJobFamilySpecialities,
        setJobFamilySpecialities,
        handleJobFamilySpecialitySelection
    } = useJobFamilyLogic(getSelectableJobFamilySpecialities, removeValidationError);

    const {interestedInLeadershipPositions, setInterestedInLeadershipPositions} =
        useInterestedInLeadershipPositionsLogic();
    const {
        setLinkedinProfileUrl,
        isLinkedinProfileUrlValid,
        linkedinProfileUrl,
        handleLinkedinProfileUrlChange
    } = useLinkedInProfileLogic(removeValidationError);
    const {loading: loadingCandidateServices, data: candidateServices} = useStaticLegalContent(
        {type: ConsentType.PRIVACY_POLICY_CANDIDATE_SERVICES}
    );

    const loading = loadingUpdateRelevanceData || loadingCandidateServices;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!userRelevanceInformation) {
            return;
        }

        const clientSelectedUserRelevanceInformation =
            getClientSelectedUserRelevanceInformation(userRelevanceInformation);

        setSelectedWorkLocations(
            clientSelectedUserRelevanceInformation.preferredWorkLocations
        );
        setInterestedInLeadershipPositions(
            clientSelectedUserRelevanceInformation.interestedInLeadershipPositions
        );
        setJobFamily(clientSelectedUserRelevanceInformation.preferredJobFamily);
        setSelectableJobFamilySpecialities(
            getSelectableJobFamilySpecialities(
                clientSelectedUserRelevanceInformation.preferredJobFamily
            )
        );
        setJobFamilySpecialities(clientSelectedUserRelevanceInformation.preferredSpecialties);
        setSelectedLanguages(clientSelectedUserRelevanceInformation.spokenLanguages);
        if (clientSelectedUserRelevanceInformation?.preferredCitiesWithMetaData) {
            setSelectedCities(
                clientSelectedUserRelevanceInformation.preferredCitiesWithMetaData
            );
        }
        setLinkedinProfileUrl(clientSelectedUserRelevanceInformation.linkedinProfileUrl);
    }, [userRelevanceInformation, getSelectableJobFamilySpecialities]);

    const editedExistingValues = useMemo(() => {
        if (!userRelevanceInformation) {
            return false;
        }
        const areEqual = (valueOne: unknown, valueTwo: unknown) =>
            JSON.stringify(valueOne) === JSON.stringify(valueTwo);

        return !(
            areEqual(userRelevanceInformation.linkedinProfileUrl, linkedinProfileUrl) &&
            areEqual(userRelevanceInformation.preferredJobFamily, jobFamily) &&
            areEqual(userRelevanceInformation.preferredSpecialties, jobFamilySpecialties) &&
            areEqual(
                Boolean(userRelevanceInformation.interestedInLeadershipPositions),
                interestedInLeadershipPositions
            ) &&
            areEqual(userRelevanceInformation.spokenLanguages, selectedLanguages) &&
            areEqual(userRelevanceInformation.preferredWorkLocations, selectedWorkLocations) &&
            areEqual(
                userRelevanceInformation.preferredCitiesWithMetaData.map(city => city.id),
                selectedCities?.map(city => city.id)
            )
        );
    }, [
        interestedInLeadershipPositions,
        jobFamily,
        jobFamilySpecialties,
        linkedinProfileUrl,
        selectedCities,
        selectedLanguages,
        selectedWorkLocations,
        userRelevanceInformation
    ]);

    const handleSubmit = useCallback(() => {
        // todo: verify if the data is correct before submitting
        const validationErrors = [];
        if (!isLinkedinProfileUrlValid) {
            validationErrors.push(ERROR_TYPES.LINKEDIN_URL);
        }
        if (!jobFamily || jobFamilySpecialties.length === 0) {
            validationErrors.push(ERROR_TYPES.JOB_FAMILY);
        }
        if (!languages || selectedLanguages.length === 0) {
            validationErrors.push(ERROR_TYPES.LANGUAGES);
        }
        if (!workLocations || selectedWorkLocations.length === 0) {
            validationErrors.push(ERROR_TYPES.WORK_LOCATIONS);
        }
        if (!isCitySelectionsValid) {
            validationErrors.push(ERROR_TYPES.CITIES);
        }
        setFormValidationErrors(validationErrors);
        if (validationErrors.length > 0) {
            scrollToError(validationErrors[0]);
            return;
        }

        // don't submit a request if nothing is changed
        if (userRelevanceInformation && !editedExistingValues) {
            close();
            return;
        }

        const relevanceInformation = {
            userId,
            interestedInLeadershipPositions,
            preferredWorkLocations: selectedWorkLocations,
            linkedinProfileUrl: linkedinProfileUrl,
            preferredJobFamily: jobFamily,
            preferredSpecialties: jobFamilySpecialties,
            preferredCities: selectedCities?.map(city => city.id),
            spokenLanguages: selectedLanguages,
            userExperiences: []
        };
        updateUserRelevanceData({
            ...relevanceInformation,
            candidateServicesConsentVersion: candidateServices?.version
        })
            .then(({data}) => {
                if (data?.updateUserRelevanceData?.ok) {
                    setIsFormSubmittedSuccessfully(true);
                    onSubmit();
                } else {
                    throw new Error(data?.updateUserRelevanceData?.errorMessage ?? '');
                }
            })
            .catch(err => {
                logger.error(err);
            });
    }, [
        editedExistingValues,
        isLinkedinProfileUrlValid,
        jobFamily,
        jobFamilySpecialties,
        languages,
        selectedLanguages,
        workLocations,
        selectedWorkLocations,
        isCitySelectionsValid,
        setFormValidationErrors,
        userId,
        userRelevanceInformation,
        interestedInLeadershipPositions,
        linkedinProfileUrl,
        selectedCities,
        updateUserRelevanceData,
        candidateServices,
        close,
        scrollToError,
        onSubmit
    ]);

    const handleCandidateRelevanceModalLeave = useCallback(
        closeParent => {
            const someFieldIsFilled =
                selectedWorkLocations.length !== 0 ||
                interestedInLeadershipPositions !== false ||
                jobFamilySpecialties.length !== 0 ||
                selectedLanguages.length !== 0 ||
                selectedCities?.length !== 0;

            const firstTimeAndEditedValues = !userRelevanceInformation && someFieldIsFilled;

            if (firstTimeAndEditedValues || editedExistingValues) {
                setShowConfirmLeaveRelevanceModal(true);
            } else {
                closeParent();
            }
        },
        [
            selectedWorkLocations.length,
            interestedInLeadershipPositions,
            jobFamilySpecialties.length,
            selectedLanguages.length,
            selectedCities?.length,
            userRelevanceInformation,
            editedExistingValues
        ]
    );

    return {
        closeInitialProfileSubmitSucessModal,
        workLocations,
        selectedWorkLocations,
        handleWorkLocationSelection,
        showCitiesSelection,
        interestedInLeadershipPositions,
        setInterestedInLeadershipPositions,
        jobFamily,
        jobFamilySpecialties,
        handleJobFamilySelection,
        handleJobFamilySpecialitySelection,
        handleSubmit,
        jobFamilies,
        selectableJobFamilySpecialities,
        companyTypes,
        languages,
        selectedLanguages,
        cities,
        selectedCities,
        handleLanguagesSelection,
        handleCitiesSelection,
        loading,
        formValidationErrors,
        getErrorText,
        isFormSubmittedSuccessfully,
        showConfirmLeaveRelevanceModal,
        setShowConfirmLeaveRelevanceModal,
        handleCandidateRelevanceModalLeave,
        citiesQueryResults,
        citiesQueryLoading,
        setCitiesSearchKey,
        linkedinProfileUrl,
        handleLinkedinProfileUrlChange
    };
}
