import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.user.jobApplication.consentToShareResults.title'
    },
    description: {
        id: 'app.pages.user.jobApplication.consentToShareResults.description'
    },
    privacyPolicyTitle: {
        id: 'consent.PRIVACY_POLICY.title'
    },
    accept: {
        id: 'app.general.accept'
    }
});
