import styled from 'styled-components';

import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    SpacingMedium,
    SpacingXlarge,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

export const OrganizationInputField = styled.div`
    margin-top: ${SpacingXsmall};
`;

export const Wrapper = styled(FlexLayout)`
    max-width: 1000px;
    margin: 0 auto;
    gap: ${SpacingMedium};

    @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
        gap: ${SpacingXlarge};
    }
`;

export const SettingsCardWrapper = styled.div`
    max-width: 1000px;

    @media only screen and (min-width: 1440px) {
        width: 1000px;
    }
`;
