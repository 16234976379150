import {Container} from './styled';
import {CardBaseProps} from './types';

export function CardBase({
    children,
    isActive = false,
    isDisabled = false,
    isClickable = false,
    height = 'auto',
    className,
    ...props
}: CardBaseProps) {
    const $isClickable = !isDisabled && (!!props.onClick || isClickable);

    return (
        <Container
            $isActive={isActive}
            $isDisabled={isDisabled}
            $isClickable={$isClickable}
            $height={height}
            className={className}
            {...props}
        >
            {children}
        </Container>
    );
}
