import {logger} from '@/services/logrocket';
import {useMutation} from '@apollo/client';
import {REJECT_SHARING_CANDIDATE_DATA_WITH_ORGANIZATION} from './mutations';

export const useRejectSharingCandidateDataWithOrganization = (
    onSuccess: () => void,
    onError: () => void
) => {
    const [mutate, {error, loading}] = useMutation(
        REJECT_SHARING_CANDIDATE_DATA_WITH_ORGANIZATION
    );

    const rejectInvitation = async (organizationId: number) => {
        try {
            const {data} = await mutate({variables: {organizationId}});
            const {ok, errorMessage} = data?.rejectSharingCandidateDataWithOrganization || {};
            if (ok) {
                onSuccess?.();
            } else {
                throw errorMessage;
            }
        } catch (error) {
            logger.error(error);
            onError?.();
        }
    };

    return {rejectInvitation, error, loading} as const;
};
