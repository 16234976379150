import {defineMessages} from 'react-intl';

export default defineMessages({
    info: {
        id: 'app.impersonationBanner.info'
    },
    endImpersonation: {
        id: 'app.impersonationBanner.endImpersonation'
    }
});
