import {Fragment} from 'react';
import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingMedium} from '@/componentLibrary/tokens/variables';
import {TestProfileGraphProps} from '@/pages/Organization/pages/Assessment/components/TestProfileGraph/types';

import {TestProfileLegend} from './components/TestProfileLegend';
import {TestProfilePieChart} from './components/TestProfilePieChart';
import {TestProfileRanges} from './components/TestProfileRanges';

export function TestProfileGraph({
    displayRanges,
    includePersonalityTest,
    includeLogicTest,
    testProfile
}: TestProfileGraphProps) {
    return (
        <P2>
            <Fragment>
                <ChartWrapper>
                    <TestProfilePieChart
                        testProfile={testProfile}
                        includePersonalityTest={includePersonalityTest}
                        includeLogicTest={includeLogicTest}
                    />
                    <TestProfileLegend
                        testProfile={testProfile}
                        includePersonalityTest={includePersonalityTest}
                        includeLogicTest={includeLogicTest}
                    />
                </ChartWrapper>

                {displayRanges ? (
                    <ProfileRangesWrapper>
                        <TestProfileRanges
                            testProfile={testProfile}
                            includePersonalityTest={includePersonalityTest}
                            includeLogicTest={includeLogicTest}
                        />
                    </ProfileRangesWrapper>
                ) : null}
            </Fragment>
        </P2>
    );
}

const ChartWrapper = styled.div`
    margin: ${SpacingMedium} 0;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 ${SpacingMedium};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
        flex-direction: column;
    }
`;

const ProfileRangesWrapper = styled.div`
    margin: ${SpacingMedium} auto;
    width: 640px;
    max-width: 100%;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
    }
`;
