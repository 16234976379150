import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingLarge, SpacingSmall} from '@/componentLibrary/tokens/variables';

import {SizeNarrow, SizeWide} from '../../constants';

export const WelcomeSection = styled.div`
    width: ${SizeWide};
    display: flex;
    flex-direction: column;
    grid-gap: ${SpacingSmall};
    padding: 0 ${SpacingLarge} ${SpacingLarge} ${SpacingLarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        max-width: ${SizeNarrow};
        width: ${SizeNarrow};
    }
`;
