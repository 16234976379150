import {RefObject, useCallback, useRef, useState} from 'react';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import messages from '../messages';

type Refs = {
    current: RefObject<HTMLElement>[];
};

export const useCopyToClipboard = (refs: Refs) => {
    const phrases = useExtractPhraseConstants(messages);
    const [text, setText] = useState(phrases.copy);
    const timeout = useRef<NodeJS.Timeout | null>(null);

    const resetTextWithDelay = useCallback(() => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
            setText(phrases.copy);
        }, 3000);
    }, [phrases.copy]);

    const resetText = useCallback(() => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        setText(phrases.copy);
    }, [phrases.copy]);

    const copyToClipboard = useCallback(
        async (index: number): Promise<void> => {
            const element = refs.current[index]?.current;
            if (!element) {
                setText(phrases.copiedToClipboardError);
                return resetTextWithDelay();
            }

            const textToCopy = element.innerText || '';
            try {
                await navigator.clipboard.writeText(textToCopy);
                setText(phrases.copied);
                resetTextWithDelay();
            } catch {
                setText(phrases.copiedToClipboardError);
                resetTextWithDelay();
            }
        },
        [phrases.copied, phrases.copiedToClipboardError, refs, resetTextWithDelay]
    );

    return {copyToClipboard, statusText: text, resetText};
};
