import styled, {css} from 'styled-components';

import {
    ColorFgMuted,
    FontSizeDefault,
    FontSizeSmaller,
    SpacingMedium,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const AvatarWrapper = styled.div`
    flex-shrink: 0;
`;

export const UserWrapper = styled.div<{fixedWidth?: string}>`
    display: flex;
    flex-direction: column;
    margin-left: ${SpacingXsmall};
    justify-content: center;
    ${({fixedWidth}) => (fixedWidth ? `width: ${fixedWidth}};` : '')}
`;

const fixedWidthStyling = css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const Name = styled.div<{isBigName?: boolean; fixedWidth?: string}>`
    line-height: ${SpacingMedium};
    ${p => (p.isBigName ? `font-size: ${FontSizeDefault};` : '')}
    ${p => (p.fixedWidth ? fixedWidthStyling : '')}
`;

export const Title = styled.div<{fixedWidth?: string}>`
    display: flex;
    align-items: center;
    font-size: ${FontSizeSmaller};
    height: 15px;
    color: ${ColorFgMuted};
    ${p => (p.fixedWidth ? fixedWidthStyling : '')}
`;
