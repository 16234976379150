import {SpacingLarge} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const TitleWrapper = styled.div`
    margin-bottom: ${SpacingLarge};
`;

export const DescriptionWrapper = styled.div`
    max-width: 740px;
    margin-bottom: ${SpacingLarge};
`;

export const CodeWrapper = styled.div`
    margin-bottom: ${SpacingLarge};
`;
