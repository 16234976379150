import {Fragment, useMemo} from 'react';

import {DisplayMedium, DisplaySmall} from '@/componentLibrary/components/typography/Display';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {personalityDataCollectionMessages} from '@/pages/PersonalityDataCollection/messages';
import {
    CodeWrapper,
    DescriptionWrapper,
    TitleWrapper
} from '@/pages/PersonalityDataCollection/pages/CompletedDataCollectionTest/components/styled';

const PROLIFIC_COMPLETION_BASE_URL = 'https://app.prolific.co/submissions/complete';

export function ProlificCompletedContent({completionCode}: {completionCode?: string | null}) {
    const completionUrl = useMemo(() => {
        return PROLIFIC_COMPLETION_BASE_URL + `?cc=${completionCode}`;
    }, [completionCode]);
    const phrases = useExtractPhraseConstants(personalityDataCollectionMessages);

    return (
        <Fragment>
            <TitleWrapper data-testid={'prolific-completed-title'}>
                <DisplayMedium style={{marginBottom: 20}}>
                    {phrases.completionPageTitle}
                </DisplayMedium>
            </TitleWrapper>
            <DescriptionWrapper data-testid={'prolific-completed-description'}>
                <DisplaySmall style={{marginBottom: 20}}>
                    {phrases.completionPageProlificContent}
                </DisplaySmall>
            </DescriptionWrapper>
            <CodeWrapper>
                <DisplaySmall style={{marginBottom: 20}}>
                    <a href={completionUrl} target="_blank" rel="noopener noreferrer">
                        Link for completion
                    </a>
                </DisplaySmall>
            </CodeWrapper>
        </Fragment>
    );
}
