import {FC} from 'react';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {Tooltip} from '../Tooltips/Tooltip';
import {S2} from '../typography/SubTitles';
import {RoleFitTagLabel} from './constants';
import messages from './messages';
import {RoleFitTagWrapper} from './styled';
import {RoleFitTagLabelType, RoleFitTagProps} from './types';

export const RoleFitTag: FC<RoleFitTagProps> = ({
    children,
    missingResults = false,
    roleFit,
    small,
    ...rest
}) => {
    const phrases = useExtractPhraseConstants(messages);

    const getMessage = () => {
        if (!roleFit) {
            return phrases.noData;
        }
        if (!roleFit.label) {
            return phrases.inProgress;
        }
        return phrases[roleFit.label as keyof typeof phrases];
    };

    const getLabel = () => (roleFit?.label as RoleFitTagLabelType) ?? RoleFitTagLabel.NO_DATA;

    return (
        <RoleFitTagWrapper role="status" $label={getLabel()} $small={small} {...rest}>
            <S2>{getMessage()}</S2>
            {missingResults && (
                <Tooltip
                    trigger={<Icon fill="currentColor" size={20} icon={Icons.INFO} />}
                    content={phrases.missingResults}
                />
            )}
        </RoleFitTagWrapper>
    );
};
