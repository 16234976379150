export const WHITE_LISTED_PATHS = [
    '/login',
    '/signup',
    '/reset-password',
    '/terms',
    '/logic-test-validation',
    '/apply',
    '/improve-alva',
    '/share-data',
    '/personal-data'
];
