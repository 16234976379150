import styled, {css} from 'styled-components';

import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';
import {
    BorderRadiusBaseRounded,
    ColorBgSubtle,
    ColorFgDefault,
    ShadowElevationHigh,
    ShadowElevationLow
} from '@/componentLibrary/tokens/variables';

export const AvatarWrapper = styled.div<{
    size: number;
    onClick?: () => void;
    isClickable?: boolean;
    $backgroundColor?: string;
    $borderColor?: string;
    $fontColor?: string;
}>`
    background-color: ${({$backgroundColor}) => $backgroundColor ?? ColorBgSubtle};
    color: ${({$fontColor}) => $fontColor ?? ColorFgDefault};
    height: ${({size}) => size}px;
    width: ${({size}) => size}px;
    position: relative;
    border-radius: ${BorderRadiusBaseRounded};
    border: ${BorderBaseDefault};
    ${({$borderColor}) => ($borderColor ? `border-color: ${$borderColor}` : '')};
    ${({onClick, isClickable}) =>
        (onClick || isClickable) &&
        css`
            cursor: pointer;

            &:hover {
                box-shadow: ${ShadowElevationLow};
            }
        `};

    &[data-state='open'] {
        box-shadow: ${ShadowElevationHigh};
    }
`;

export const AvatarContent = styled.span<{fontSize: number; size: number}>`
    display: block;
    letter-spacing: ${({fontSize}) => (fontSize > 16 ? '1px' : '0')};
    line-height: ${({size}) => size - 2}px;
    text-align: center;
    font-size: ${({fontSize}) => fontSize}px;
`;
