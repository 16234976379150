import styled from 'styled-components';

import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';
import {SpacingLarge} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    border-top: ${BorderBaseDefault};
    width: 100%;
`;

export const TitleWrapper = styled(P1)`
    margin-top: ${SpacingLarge};
`;

export const FacetsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
