import styled from 'styled-components';

import {SpacingXlarge} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    gap: ${SpacingXlarge};
    max-width: 928px;
    margin: 0 auto;
`;

export const WrapperSendInvitation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    margin: 0 auto;
`;
