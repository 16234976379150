import {matchSorter} from 'match-sorter';
import {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {isDefined} from '@/utils/typeGuards/isDefined';
import {CreateCustomItemComponent} from './components/types';
import {getSelectedValueDisplay, isCreateCustomItemComponent} from './components/utils';
import {OnChangeValue, SearchStringParams} from './types';
import {isObject, isObjectOrNull} from './utils';

export function useSearchStringLogic({
    controlledSearchString,
    onSearchStringChange
}: SearchStringParams | Required<SearchStringParams>) {
    const [uncontrolledSearchString, setUncontrolledSearchString] = useState<string>('');
    const isSearchStringControlled = useMemo(
        () => isDefined(controlledSearchString),
        [controlledSearchString]
    );
    const searchString = useMemo(() => {
        return isSearchStringControlled
            ? (controlledSearchString as string)
            : uncontrolledSearchString;
    }, [isSearchStringControlled, controlledSearchString, uncontrolledSearchString]);

    useEffect(() => {
        if (!isSearchStringControlled) {
            onSearchStringChange?.(searchString);
        }
    }, [isSearchStringControlled, onSearchStringChange, searchString]);

    const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            if (isSearchStringControlled && onSearchStringChange) {
                onSearchStringChange(value);
            } else {
                setUncontrolledSearchString(value);
            }
        },
        [isSearchStringControlled, onSearchStringChange]
    );

    const clearSearchString = useCallback(() => {
        if (isSearchStringControlled) {
            onSearchStringChange?.('');
        } else {
            setUncontrolledSearchString('');
        }
    }, [isSearchStringControlled, onSearchStringChange]);

    return {
        searchString,
        clearSearchString,
        handleInputChange
    };
}

export function useFilteringLogic<T>(
    items: T[],
    disableDefaultFiltering: boolean,
    searchString: string,
    isMulti: boolean,
    removeOnSelect: boolean,
    selectedItem: T[] | null,
    itemToId: (item: T | null) => string | number,
    itemToString: (item: T) => string,
    customFilter?: ((searchString: string) => T[]) | null
) {
    const itemsToShow = useMemo(() => {
        let resultItems = items;
        if (isMulti && Array.isArray(selectedItem) && removeOnSelect) {
            resultItems = items.filter(
                (resultItem: T) =>
                    !selectedItem.some((item: T) => itemToId(resultItem) === itemToId(item))
            );
        }
        if (disableDefaultFiltering) {
            return resultItems;
        }
        if (customFilter) {
            return customFilter(searchString);
        }
        return searchString
            ? matchSorter(resultItems, searchString, {keys: ['name']})
            : resultItems;
    }, [
        disableDefaultFiltering,
        items,
        itemToId,
        isMulti,
        selectedItem,
        removeOnSelect,
        searchString,
        customFilter
    ]);

    const isItemAdded = useMemo(
        () =>
            isMulti &&
            Array.isArray(selectedItem) &&
            selectedItem.some(
                item => itemToString(item).toLowerCase() === searchString.toLowerCase()
            ),
        [itemToString, searchString, selectedItem, isMulti]
    );

    const isExactMatch = useMemo(() => {
        const lowercaseSearchString = searchString.trim().toLowerCase();

        return (
            isMulti &&
            lowercaseSearchString &&
            itemsToShow.some(
                item => itemToString(item).toLowerCase() === lowercaseSearchString
            )
        );
    }, [searchString, itemToString, itemsToShow, isMulti]);

    return {itemsToShow, isExactMatch, isItemAdded};
}

export function useMenuLogic(
    clearSearchString: () => void,
    onBlur?: () => void,
    onFocus?: () => void,
    preventClearSearchString?: boolean
) {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const triggerWrapperRef = useRef<HTMLDivElement | null>(null);

    const blur = useCallback(() => {
        setTimeout(() => {
            inputRef.current?.blur();
            triggerWrapperRef.current?.blur();
        });
    }, []);

    const handleOnFocus = useCallback(() => {
        setIsFocused(true);
        onFocus?.();
    }, [onFocus]);

    const handleOnBlur = useCallback(() => {
        if (!preventClearSearchString) {
            clearSearchString();
        }
        setIsFocused(false);
        onBlur?.();
    }, [preventClearSearchString, clearSearchString, onBlur]);

    return {
        isFocused,
        handleOnFocus,
        handleOnBlur,
        inputRef,
        triggerWrapperRef,
        blur
    };
}

function addToMultiSelectValue<T>(
    currentValue: T[] | T | null,
    selectedItem: T | null,
    itemToId: (item: T | null) => string | number
): T[] {
    if (isObject(currentValue)) {
        return [];
    }

    if (!selectedItem && Array.isArray(currentValue)) {
        return currentValue;
    }

    if (!currentValue && selectedItem) {
        return [selectedItem];
    }

    if (Array.isArray(currentValue)) {
        if (currentValue.some(item => itemToId(item) === itemToId(selectedItem))) {
            return currentValue.filter(item => itemToId(item) !== itemToId(selectedItem));
        }
        return currentValue.concat(selectedItem);
    }

    return [];
}

function removeFromMultiSelectValue<T>(
    currentValue: T[] | T | null,
    selectedItem: T,
    itemToId: (item: T | null) => string | number
): T[] {
    if (isObjectOrNull(currentValue)) {
        return [];
    }
    return currentValue.filter(
        (currentValueRow: T) => itemToId(currentValueRow) !== itemToId(selectedItem)
    );
}

export function useSelectLogic<T, IsMulti extends boolean = false>(
    clearSearchString: () => void,
    blur: () => void,
    defaultValue: T | T[] | null,
    itemToId: (item: T | null) => string | number,
    closeMenuOnSelect: boolean,
    controlledSelectedItem?: T | T[] | null,
    onSelect?: OnChangeValue<T, IsMulti>,
    onChange?: OnChangeValue<T, IsMulti>,
    isMulti?: IsMulti
) {
    const [uncontrolledSelectedItem, setUncontrolledSelectedItem] = useState<T | T[] | null>(
        defaultValue
    );
    useEffect(() => {
        setUncontrolledSelectedItem(defaultValue);
    }, [defaultValue]);

    const isSelectedItemControlled = typeof controlledSelectedItem !== 'undefined';

    const handleSelect = useCallback(
        (item: T | CreateCustomItemComponent | null) => {
            if (isCreateCustomItemComponent(item)) {
                return;
            }
            const currentItem = typeof item !== 'undefined' ? item : null;
            if (!isSelectedItemControlled) {
                if (isMulti) {
                    setUncontrolledSelectedItem(prevItem => {
                        const updatedValue = addToMultiSelectValue<T>(
                            prevItem,
                            currentItem,
                            itemToId
                        );
                        (onSelect as OnChangeValue<T, true>)?.(updatedValue);
                        (onChange as OnChangeValue<T, true>)?.(updatedValue);
                        return updatedValue;
                    });
                } else {
                    (onSelect as OnChangeValue<T>)?.(currentItem);
                    (onChange as OnChangeValue<T>)?.(currentItem);
                    setUncontrolledSelectedItem(currentItem);
                }
            } else if (isMulti && Array.isArray(controlledSelectedItem)) {
                const multiSelectValue = addToMultiSelectValue<T>(
                    controlledSelectedItem,
                    currentItem,
                    itemToId
                );
                (onSelect as OnChangeValue<T, true>)?.(multiSelectValue);
                (onChange as OnChangeValue<T, true>)?.(multiSelectValue);
            } else {
                (onSelect as OnChangeValue<T>)?.(currentItem);
                (onChange as OnChangeValue<T>)?.(currentItem);
            }
            if (closeMenuOnSelect && !isMulti) {
                blur();
            }
            clearSearchString();
        },
        [
            isSelectedItemControlled,
            isMulti,
            controlledSelectedItem,
            closeMenuOnSelect,
            clearSearchString,
            itemToId,
            onSelect,
            onChange,
            blur
        ]
    );

    const handleTagRemove = useCallback(
        (e, item: T) => {
            e.stopPropagation();
            if (!isMulti) {
                return;
            }
            let updatedValue: T[] = [];
            if (!isSelectedItemControlled) {
                setUncontrolledSelectedItem(prevItem => {
                    updatedValue = removeFromMultiSelectValue<T>(prevItem, item, itemToId);
                    return updatedValue;
                });
            }
            if (Array.isArray(controlledSelectedItem)) {
                updatedValue = removeFromMultiSelectValue<T>(
                    controlledSelectedItem,
                    item,
                    itemToId
                );
            }
            (onSelect as OnChangeValue<T, true>)?.(updatedValue);
            (onChange as OnChangeValue<T, true>)?.(updatedValue);
        },
        [
            isMulti,
            controlledSelectedItem,
            isSelectedItemControlled,
            onSelect,
            itemToId,
            onChange
        ]
    );

    const handleTagRemoveAll = useCallback(() => {
        if (!isMulti) {
            return;
        }
        const updatedValue: T[] = [];
        if (!isSelectedItemControlled) {
            setUncontrolledSelectedItem(updatedValue);
        }
        (onSelect as OnChangeValue<T, true>)?.(updatedValue);
        (onChange as OnChangeValue<T, true>)?.(updatedValue);
    }, [isMulti, isSelectedItemControlled, onSelect, onChange]);

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!isMulti || e.key !== 'Backspace' || e.currentTarget.value.length) {
                return;
            }

            if (!isSelectedItemControlled) {
                setUncontrolledSelectedItem(prevItem => {
                    let updatedValue = prevItem;
                    if (Array.isArray(prevItem) && prevItem.length > 0) {
                        updatedValue = prevItem.slice(0, -1);
                        (onSelect as OnChangeValue<T, true>)?.(updatedValue);
                        (onChange as OnChangeValue<T, true>)?.(updatedValue);
                    }
                    return updatedValue;
                });
            } else if (
                Array.isArray(controlledSelectedItem) &&
                controlledSelectedItem.length > 0
            ) {
                const multiSelectValue = controlledSelectedItem.slice(0, -1);
                (onSelect as OnChangeValue<T, true>)?.(multiSelectValue);
                (onChange as OnChangeValue<T, true>)?.(multiSelectValue);
            }
        },
        [isMulti, controlledSelectedItem, isSelectedItemControlled, onSelect, onChange]
    );

    const selectedItem = isSelectedItemControlled
        ? controlledSelectedItem
        : uncontrolledSelectedItem;
    return {handleSelect, handleTagRemove, handleKeyDown, handleTagRemoveAll, selectedItem};
}

export function useDisplayValueLogic<T>(
    isFocused: boolean,
    showCheckboxes: boolean,
    selectedItem?: T | T[] | null,
    searchString?: string,
    itemToString?: (item: T) => string,
    preventClearSearchString?: boolean,
    customDisplayValue?: string
) {
    const usedItemToString = itemToString ? itemToString : defaultItemToString;

    const result = {itemToString: usedItemToString};

    if (showCheckboxes) {
        if (isFocused) {
            return {...result, displayValue: searchString};
        }
        return {
            ...result,
            displayValue: getSelectedValueDisplay(
                selectedItem,
                usedItemToString,
                customDisplayValue
            )
        };
    }

    const displayValue =
        !Array.isArray(selectedItem) && !preventClearSearchString && !isFocused && selectedItem
            ? usedItemToString(selectedItem)
            : searchString;
    return {...result, displayValue};
}

export function useEqualityLogic<T>(itemToId?: (item: T | null) => string | number) {
    return {itemToId: itemToId ? itemToId : defaultItemToId};
}

function defaultItemToId<T>(item: T | null) {
    if (item && typeof item === 'object' && 'id' in item) {
        return String(item['id' as keyof T]);
    }
    return '';
}

function defaultItemToString<T>(item: T | null) {
    if (item && typeof item === 'object' && 'name' in item) {
        return String(item['name' as keyof T]);
    }
    return '';
}
