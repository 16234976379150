import {isDefined} from '@/utils/typeGuards/isDefined';

import {Icon} from '../icons/Icon';
import {Icons} from '../icons/constants';
import {ActionMenuContent} from './components/ActionMenuContent';
import {ActionMenuTrigger} from './components/ActionMenuTrigger';
import {ActionMenuIconSize} from './constants';
import {ActionMenuContext, useActionMenu} from './hooks';
import {OptionWrapper} from './styled';
import {ActionMenuOptions, ActionMenuProps} from './types';
import {isTriggerFunction} from './utils';

export function ActionMenu({
    items,
    trigger,
    modal = true,
    menuWidth = 240,
    asChild = false,
    asDiv = false,
    disabled = false,
    components = {Option: OptionWrapper},
    iconSize = ActionMenuIconSize.DEFAULT,
    menuOffset,
    style,
    ...restOptions
}: ActionMenuProps) {
    // This can accept any props as options, e.g. `placement`,
    // or other positioning options.
    const defaultOptions: ActionMenuOptions = {placement: 'bottom-end'};
    const actionMenu = useActionMenu({
        ...defaultOptions,
        ...restOptions,
        modal,
        offsetInPx: menuOffset
    });
    const getTrigger = () => {
        if (!isDefined(trigger)) {
            return null;
        }
        return isTriggerFunction(trigger) ? trigger(actionMenu.context.open) : trigger;
    };
    return (
        <ActionMenuContext.Provider value={actionMenu}>
            <ActionMenuTrigger asChild={asChild} asDiv={asDiv} disabled={disabled}>
                {getTrigger() || (
                    <Icon
                        isDisabled={disabled}
                        isActive={actionMenu.open}
                        isAction
                        isHoverable
                        icon={Icons.MORE_HORIZ}
                        size={iconSize}
                    />
                )}
            </ActionMenuTrigger>
            <ActionMenuContent
                menuWidth={menuWidth}
                components={components}
                items={items}
                style={style}
            />
        </ActionMenuContext.Provider>
    );
}
