import qs from 'qs';
import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {LINKS} from '@/links';

type Params = {
    message?: string;
};

export function useRedirectToLogin() {
    const history = useHistory();
    return useCallback(
        (params: Params) => {
            const baseUrl = LINKS.login;
            const queryString = qs.stringify(params, {encode: true});

            return history.push(`${baseUrl}?${queryString}`);
        },
        [history]
    );
}
