import {useCallback} from 'react';

const SIGNUP_METHOD = 'EMAIL_PASSWORD';

export function useFormLogic(
    loadingConsents: boolean,
    termsOfServiceVersion: string | null | undefined,
    candidateServicesConsentVersion: string | null | undefined,
    setSignupMethod: (signupMethod: string) => void,
    save: (data: {
        firstName: string;
        lastName: string;
        password: string;
        termsOfServiceVersion: string | null | undefined;
        candidateServicesConsentVersion: string | null | undefined;
    }) => void
) {
    const onSubmit = useCallback(
        (firstName: string, lastName: string, password: string) => {
            if (loadingConsents) {
                return;
            }

            setSignupMethod(SIGNUP_METHOD);
            save({
                firstName,
                lastName,
                password,
                termsOfServiceVersion,
                candidateServicesConsentVersion
            });
        },
        [
            loadingConsents,
            setSignupMethod,
            save,
            termsOfServiceVersion,
            candidateServicesConsentVersion
        ]
    );

    return {
        onSubmit
    };
}
