import {Fragment} from 'react';

import {DisplayMedium} from '@/componentLibrary/components/typography/Display';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {personalityDataCollectionMessages} from '@/pages/PersonalityDataCollection/messages';
import {TitleWrapper} from '@/pages/PersonalityDataCollection/pages/CompletedDataCollectionTest/components/styled';

export function GeneralCompletedContent() {
    const phrases = useExtractPhraseConstants(personalityDataCollectionMessages);
    return (
        <Fragment>
            <TitleWrapper>
                <DisplayMedium style={{marginBottom: 20}}>
                    {phrases.completionPageTitle}
                </DisplayMedium>
            </TitleWrapper>
        </Fragment>
    );
}
