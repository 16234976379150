import {P2} from '@/componentLibrary/components/typography';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingMedium, SpacingXxsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        flex-direction: column;
    }

    margin-bottom: ${SpacingMedium};
`;

export const TestProfileName = styled(P2)`
    margin-right: ${SpacingXxsmall};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-bottom: ${SpacingXxsmall};
        margin-right: 0;
    }
`;
