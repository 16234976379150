import {useMemo} from 'react';

import {useStaticLegalContent} from '../staticLegalContent/useStaticLegalContent';
import {CONSENT_TYPES} from './consentTypes';

export function useTermsOfServiceVersion() {
    const {data: termsOfService, loading: loadingConsent} = useStaticLegalContent({
        type: CONSENT_TYPES.OLD_TERMS_OF_SERVICE
    });

    const termsOfServiceVersion = useMemo(() => termsOfService?.version, [termsOfService]);

    return {
        loadingTermsOfServiceVersion: loadingConsent,
        termsOfServiceVersion
    };
}
