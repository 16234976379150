import {defineMessages} from 'react-intl';

export default defineMessages({
    placeholder: {
        id: 'app.inputs.dropdown.placeholder'
    },
    alvaProfile: {
        id: 'app.alvaProfile'
    }
});
