import {useMutation} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {GET_USER_VALIDATION_TEST} from '@/api/users/queries';

import {RequestValidationTestMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {REQUEST_VALIDATION_TEST} from './mutations';

export function useRequestValidationTest(
    organizationId: number,
    userId?: number
): MutationHookReturnType<RequestValidationTestMutation> {
    const refetchQueries = useMemo(() => {
        return [{query: GET_USER_VALIDATION_TEST, variables: {id: userId}}];
    }, [userId]);

    const [mutate, {error, loading}] = useMutation(REQUEST_VALIDATION_TEST, {refetchQueries});

    const requestValidationTest = useCallback(() => {
        if (!userId) {
            throw new Error('userId is required');
        }
        const variables = {userId, organizationId};
        return mutate({variables});
    }, [mutate, userId, organizationId]);

    return [requestValidationTest, {error, loading}];
}
