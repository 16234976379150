const OperatingSystems: {[key: string]: string} = {
    Win: 'windows',
    Mac: 'mac',
    X11: 'unix',
    Linux: 'linux',
    Unknown: 'unknown'
};

function getOperatingSystem() {
    const userAgent = window.navigator.userAgent;
    const key =
        Object.keys(OperatingSystems).find(os => userAgent.indexOf(os) !== -1) ||
        OperatingSystems.Unknown;
    return OperatingSystems[key];
}

export function isMac() {
    return getOperatingSystem() === OperatingSystems.Mac;
}
