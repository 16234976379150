import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {REVOKE_LOGIC_TEST_FROM_ORGANIZATION} from '@/api/logicTestIrt/mutations';

import {
    RevokeLogicTestFromOrganizationInput,
    RevokeLogicTestFromOrganizationMutation
} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';

export function useRevokeLogicTestFromOrganization(
    userId?: number
): MutationHookReturnType<
    RevokeLogicTestFromOrganizationMutation,
    RevokeLogicTestFromOrganizationInput
> {
    const [mutate, {error, loading}] = useMutation(REVOKE_LOGIC_TEST_FROM_ORGANIZATION, {
        update: (cache, result) => {
            if (result.data?.revokeLogicTestFromOrganization?.ok && userId) {
                cache.modify({
                    id: cache.identify({id: userId, __typename: 'User'}),
                    fields: {
                        logicalAbilityEstimate() {
                            return null;
                        }
                    }
                });
            }
        }
    });

    const doRevokeLogicTestFromOrganization = useCallback(
        (data: RevokeLogicTestFromOrganizationInput) => {
            return mutate({variables: {data}});
        },
        [mutate]
    );

    return [doRevokeLogicTestFromOrganization, {error, loading}];
}
