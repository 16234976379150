import styled from 'styled-components';

import {H4} from '@/componentLibrary/components/typography';
import {SpacingLarge} from '@/componentLibrary/tokens/variables';

const Header = styled(H4)`
    margin-bottom: ${SpacingLarge};
    display: flex;
    justify-content: flex-start;
`;

export {Header};
