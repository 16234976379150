export function encodeNodeId(type: string, id: string | number): string {
    // Example: Organizational:1
    const idInt = parseInt(id.toString(), 10);
    const encodedString = `${type}:${idInt}`;

    return btoa(encodedString);
}

export function decodeNodeId(encodedString: string): number | null {
    if (!encodedString) {
        return null;
    }
    const data = atob(encodedString).split(':');
    return parseInt(data[1], 10);
}
