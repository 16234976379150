import {ActiveJobRecommendation} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation';
import {JobRecommendationsHeader} from '@/pages/User/components/JobRecommendations/components/JobRecommendationsHeader';
import {LoadingSpinner} from '@/pages/User/components/JobRecommendations/components/LoadingSpinner';
import {useJobRecommendationsLogic} from '@/pages/User/components/JobRecommendations/hooks';
import {RecommendationRelevanceForm} from '@/pages/User/components/RecommendationRelevanceForm';

import {JobRecommendationList} from './components/JobRecommendationList';
import {JobRecommendationsProps} from './types';

export function JobRecommendations({
    setPollingEnabledAfterAcceptingRecommendation
}: JobRecommendationsProps) {
    const {
        recommendationsSettingsIsDisabled,
        hasRecommendations,
        relevanceParametersOptions,
        showRelevanceForm,
        setShowRelevanceForm,
        user,
        userRelevanceInformation,
        pendingRecommendations,
        loading,
        error,
        refetch,
        onSubmissionOfRelevanceForm,
        activeJobRecommendationState,
        setActiveJobRecommendationState,
        resetActiveJobRecommendation
    } = useJobRecommendationsLogic();

    if (recommendationsSettingsIsDisabled) {
        return null;
    }

    const onSubmitForm = async () => {
        await onSubmissionOfRelevanceForm();
        setShowRelevanceForm(false);
    };

    if (showRelevanceForm && user) {
        return (
            <RecommendationRelevanceForm
                close={setShowRelevanceForm.bind(null, false)}
                userId={user.id}
                relevanceParametersOptions={relevanceParametersOptions}
                userRelevanceInformation={userRelevanceInformation}
                onSubmit={onSubmitForm}
            />
        );
    }

    return (
        <>
            <JobRecommendationsHeader
                loading={loading}
                error={error}
                hasRecommendations={hasRecommendations}
                onEdit={setShowRelevanceForm.bind(null, true)}
            />

            <LoadingSpinner loading={loading} error={error} refetch={refetch} />

            {activeJobRecommendationState && (
                <ActiveJobRecommendation
                    initialState={activeJobRecommendationState.initialState}
                    jobRecommendation={activeJobRecommendationState.jobRecommendation}
                    onApply={setPollingEnabledAfterAcceptingRecommendation.bind(null, true)}
                    close={resetActiveJobRecommendation}
                />
            )}

            <JobRecommendationList
                loading={loading}
                error={error}
                pendingRecommendations={pendingRecommendations}
                setActive={setActiveJobRecommendationState}
            />
        </>
    );
}
