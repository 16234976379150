import styled from 'styled-components';

import {TestReportOverviewLoading} from '@/componentLibrary/blocks/testReports/components/TestReportOverviewLoading';
import {TestReportType} from '@/componentLibrary/blocks/testReports/components/TestReportOverviewLoading/TestReportOverviewLoading';
import {SpacingXxlarge} from '@/componentLibrary/tokens/variables';

export function NewCandidateResultsLoadingState() {
    return (
        <Wrapper>
            <TestReportOverviewLoading type={TestReportType.LOGIC_TEST} />
            <TestReportOverviewLoading type={TestReportType.PERSONALITY_TEST} />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXxlarge};
`;
