import {
    autoUpdate,
    flip,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole
} from '@floating-ui/react';
import {createContext, useContext, useMemo, useState} from 'react';

import {ActionMenuOptions, ContextType} from './types';

export const ActionMenuContext = createContext<ContextType>(null);

export const useActionMenuContext = () => {
    const context = useContext(ActionMenuContext);

    if (context == null) {
        throw new Error('ActionMenu components must be wrapped in <ActionMenu />');
    }

    return context;
};

export function useActionMenu({
    initialOpen = false,
    placement = 'bottom',
    open: controlledOpen,
    onOpenChange: setControlledOpen,
    modal,
    offsetInPx = 5
}: ActionMenuOptions = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
    const [labelId, setLabelId] = useState<string | undefined>();
    const [descriptionId, setDescriptionId] = useState<string | undefined>();

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(offsetInPx),
            flip({
                crossAxis: placement.includes('-'),
                fallbackAxisSideDirection: 'end',
                padding: offsetInPx
            }),
            shift({padding: offsetInPx})
        ]
    });

    const context = data.context;

    const click = useClick(context);
    const dismiss = useDismiss(context, {capture: {escapeKey: true}});
    const role = useRole(context);

    const interactions = useInteractions([click, dismiss, role]);

    return useMemo(
        () => ({
            open,
            setOpen,
            ...interactions,
            ...data,
            modal,
            labelId,
            descriptionId,
            setLabelId,
            setDescriptionId
        }),
        [open, setOpen, interactions, data, modal, labelId, descriptionId]
    );
}
