import {Dropdown} from '@/componentLibrary/components/Dropdowns/Dropdown';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {TextField} from '@/componentLibrary/components/inputs/TextField';

import {GetLoggedInUserQuery} from '@/api/types/__generated__/graphql';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useCandidateProfileFormLogic} from './hooks';
import messages from './messages';
import {
    FormWrapper,
    InputField,
    NameInputField,
    NameWrapper,
    SelectLanguageWrapper,
    Title,
    Wrapper
} from './styled';

type CandidateProfileFormProps = {
    user: GetLoggedInUserQuery['me'];
};

export function CandidateProfileForm({user}: CandidateProfileFormProps) {
    const phrases = useExtractPhraseConstants(messages);
    const {
        formInputs,
        handleFormInputChange,
        handleSelectLanguage,
        formInputsAreValid,
        languageItems,
        updateUser,
        updatingUser
    } = useCandidateProfileFormLogic(user);

    return (
        <Wrapper>
            <Title>{phrases.title}</Title>
            <form onSubmit={updateUser}>
                <FormWrapper>
                    <NameWrapper>
                        <NameInputField>
                            <TextField
                                name="firstName"
                                type="text"
                                fullWidth
                                value={formInputs.firstName}
                                onChange={handleFormInputChange}
                                label={phrases.firstName}
                            />
                        </NameInputField>
                        <NameInputField>
                            <TextField
                                name="lastName"
                                type="text"
                                fullWidth
                                value={formInputs.lastName}
                                onChange={handleFormInputChange}
                                label={phrases.lastName}
                            />
                        </NameInputField>
                    </NameWrapper>
                    <InputField>
                        <TextField
                            name="email"
                            type="text"
                            fullWidth
                            value={formInputs.email}
                            disabled
                            label={phrases.email}
                        />
                    </InputField>
                    <SelectLanguageWrapper>
                        <Dropdown
                            label={phrases.language}
                            fullWidth
                            selectedItem={formInputs.language}
                            items={languageItems}
                            isLoading={!user}
                            onSelect={handleSelectLanguage}
                        />
                    </SelectLanguageWrapper>
                </FormWrapper>
                <PrimaryButton
                    type="submit"
                    disabled={!formInputsAreValid}
                    isLoading={updatingUser}
                >
                    {phrases.saveChanges}
                </PrimaryButton>
            </form>
        </Wrapper>
    );
}
