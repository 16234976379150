import {useLoggedInUser} from '@/api/users/useLoggedInUser';

export function useShowAcceptPolicyLogic() {
    const {loading: loadingLoggedInUser, user: loggedInUser} = useLoggedInUser(true);
    const hasCandidateServicesConsent = loggedInUser?.hasRegisteredToCandidateServices;

    return {
        loading: loadingLoggedInUser,
        hasCandidateServicesConsent
    };
}
