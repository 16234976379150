import {useMemo} from 'react';

import {CONSENT_TYPES} from './consentTypes';
import {
    PRIVACY_POLICY_CANDIDATE_SERVICES,
    PRIVACY_POLICY_RECRUITMENT_SERVICES,
    SUB_PROCESSORS,
    TERMS_OF_SERVICE
} from './messages';

export function useConsentMessages() {
    return useMemo(
        () =>
            ({
                [CONSENT_TYPES.PRIVACY_POLICY_RECRUITMENT_SERVICES]:
                    PRIVACY_POLICY_RECRUITMENT_SERVICES,
                [CONSENT_TYPES.PRIVACY_POLICY_CANDIDATE_SERVICES]:
                    PRIVACY_POLICY_CANDIDATE_SERVICES,
                [CONSENT_TYPES.OLD_TERMS_OF_SERVICE]: TERMS_OF_SERVICE,
                [CONSENT_TYPES.TERMS_OF_SERVICE]: TERMS_OF_SERVICE,
                [CONSENT_TYPES.SUB_PROCESSORS]: SUB_PROCESSORS,
                [CONSENT_TYPES.SECURITY]: SUB_PROCESSORS
            }) as Record<string, Record<string, {id: string}>>,
        []
    );
}
