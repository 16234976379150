import {ColorFgOnEmphasis, SpacingMedium} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const EndImpersonationButton = styled.button`
    margin-left: ${SpacingMedium};
    background-color: rgb(60 76 91 / 57%);
    color: ${ColorFgOnEmphasis};
    cursor: pointer;

    &:hover {
        background-color: rgb(64 83 101 / 57%);
    }
`;
