import {ColorFgActionTertriaryDefault} from '@/componentLibrary/tokens/variables';

import {Icon} from '../icons/Icon';
import {Icons} from '../icons/constants';
import {HelpCenterLinkBase} from './styled';
import {HelpCenterLinkProps} from './types';

export function HelpCenterLink({
    onClick,
    url,
    children,
    inline = false,
    hideWhenPrinting = false,
    ...rest
}: HelpCenterLinkProps): JSX.Element {
    return (
        <HelpCenterLinkBase
            onClick={onClick}
            href={url}
            target="_blank"
            $inline={inline}
            rel="noreferrer noopener"
            $hideWhenPrinting={hideWhenPrinting}
            {...rest}
        >
            <Icon
                icon={Icons.AUTO_STORIES}
                size={inline ? 16 : 24}
                fill={ColorFgActionTertriaryDefault}
            />
            {children}
        </HelpCenterLinkBase>
    );
}
