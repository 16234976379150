import {MouseEventHandler, ReactNode, useCallback} from 'react';

import {ColorBaseBlue900, ColorBaseGrey900} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    Arrow,
    LeaveTestWrapper,
    NumberDisplay,
    Questions,
    Wrapper
} from '@/pages/LogicTestIrt/components/FormHeader/styled';
import {FormHeaderProps} from '@/pages/LogicTestIrt/components/FormHeader/types';

import messages from './messages';

export const FormHeader = ({logicTestStatus, goBack}: FormHeaderProps) => {
    const phrases = useExtractPhraseConstants(messages);
    const renderQuestions = useCallback((): ReactNode => {
        if (!logicTestStatus) {
            return null;
        }

        const {answeredQuestions, totalQuestions} = logicTestStatus;
        const currentQuestionNumber = answeredQuestions + 1;

        return (
            <div>
                <Questions>{phrases.task}</Questions>
                <NumberDisplay color={ColorBaseBlue900}>{currentQuestionNumber}</NumberDisplay>
                <NumberDisplay color={ColorBaseGrey900}>/{totalQuestions}</NumberDisplay>
            </div>
        );
    }, [logicTestStatus, phrases]);

    return (
        <Wrapper>
            <LeaveTestWrapper onClick={goBack as MouseEventHandler<HTMLDivElement>}>
                {goBack ? (
                    <>
                        <Arrow />
                        {phrases.leaveTest}
                    </>
                ) : null}
            </LeaveTestWrapper>

            {renderQuestions()}
        </Wrapper>
    );
};
