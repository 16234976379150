import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_MY_JOB_APPLICATIONS} from '@/api/users/queries';

type Options = {
    withRelevanceInformation?: boolean;
    withAutomatedScore?: boolean;
};

export function useMyJobApplications(organizationId?: number, options?: Options) {
    const {
        data,
        loading,
        error,
        refetch,
        startPolling: startPollingMyJobApplications,
        stopPolling: stopPollingMyJobApplications
    } = useQuery(GET_MY_JOB_APPLICATIONS, {
        notifyOnNetworkStatusChange: true,
        skip: !organizationId,
        variables: {
            withRelevanceInformation: options?.withRelevanceInformation ?? false,
            withAutomatedScore: options?.withAutomatedScore ?? false
        }
    });

    const jobApplications = useMemo(() => {
        if (!data?.me?.jobApplications || !Array.isArray(data?.me?.jobApplications)) {
            return [];
        }

        const applications = data.me.jobApplications;
        if (organizationId) {
            return applications.filter(jobApplication => {
                return jobApplication.jobPosition?.organization.id === organizationId;
            });
        }
        return applications;
    }, [data, organizationId]);

    const acceptedCandidateOrganizations = useMemo(
        () => data?.me?.acceptedCandidateOrganizations ?? [],
        [data]
    );

    return {
        jobApplications,
        acceptedCandidateOrganizations,
        loading,
        error,
        refetch,
        startPolling: startPollingMyJobApplications,
        stopPolling: stopPollingMyJobApplications
    };
}
