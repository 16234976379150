import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {RECORD_EXTERNAL_RESULTS_VISIT} from './mutations';

export function useRecordExternalResultsVisit(token: string) {
    const [mutate, {error, loading}] = useMutation(RECORD_EXTERNAL_RESULTS_VISIT);

    const saveVisit = useCallback(
        (email: string) => {
            const variables = {
                token,
                email
            };

            return mutate({variables});
        },
        [token, mutate]
    );

    return [saveVisit, {error, loading}] as const;
}
