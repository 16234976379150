import {defineMessages} from 'react-intl';

export default defineMessages({
    recommendation: {
        id: 'app.components.assessmentCard.recommendation'
    },
    recommendationMore: {
        id: 'app.components.assessmentCard.recommendation.more'
    },
    viewDetails: {
        id: 'app.components.assessmentCard.recommendation.viewDetails'
    },
    recommendedByAI: {
        id: 'app.components.assessmentCard.recommendedByAI'
    },
    basedOn: {
        id: 'app.components.assessmentCard.basedOn'
    },
    basedOnResearch: {
        id: 'app.components.assessmentCard.basedOnResearch'
    },
    role: {
        id: 'app.components.assessmentCard.basedOn.role'
    },
    expected: {
        id: 'app.components.assessmentCard.basedOn.expected'
    },
    applyTo: {
        id: 'app.components.assessmentCard.apply.to'
    },
    applyRecommendation: {
        id: 'app.components.assessmentCard.apply.recommendation'
    },
    applyInsteadOf: {
        id: 'app.components.assessmentCard.apply.insteadOf'
    },
    buttonEditRole: {
        id: 'app.components.assessmentCard.button.editRole'
    },
    buttonEditRoleExperienceSkills: {
        id: 'app.components.assessmentCard.button.editRoleExperienceSkills'
    },
    leadershipBannerAlva: {
        id: 'app.components.assessmentCard.recommendation.leadership.banner.alva'
    },
    leadershipBannerRole: {
        id: 'app.components.assessmentCard.recommendation.leadership.banner.role'
    }
});
