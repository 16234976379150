import {useMemo} from 'react';

import {
    BLOCKS,
    DEFAULT_BLOCK_COLOR
} from '@/componentLibrary/components/charts/StandardTenChart/constants';
import {StandardTenChartLogicProps} from '@/componentLibrary/components/charts/StandardTenChart/types';
import {getColorPerResultValue} from '@/componentLibrary/components/charts/StandardTenChart/utils';

export const useStandardTenChartLogic = ({
    lowerBound,
    upperBound,
    rangeColor,
    selectedValue,
    valueColor
}: StandardTenChartLogicProps) => {
    const colors = getColorPerResultValue({
        lowerBound,
        upperBound,
        rangeColor,
        valueColor,
        selectedValue
    });
    const formattedBlocks = useMemo(() => {
        return BLOCKS.map((block, index) => {
            return <rect key={index} {...block} fill={colors[index] || DEFAULT_BLOCK_COLOR} />;
        });
    }, [colors]);

    // the randomness here is needed for safari
    const maskId = 'prefix__a__' + Math.random().toString(36).substring(7);

    return {maskId, formattedBlocks};
};
