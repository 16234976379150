import {FetchResult} from '@apollo/client';
import {Fragment} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {DoCandidateForJobPositionProps} from '@/api/assessment/jobPositions/useAddCandidateForJobPosition';
import {
    AddCandidateForJobPositionMutation,
    GetPublicJobPositionQuery
} from '@/api/types/__generated__/graphql';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {TextEditor} from '@/componentLibrary/components/inputs/TextEditor';
import {Link} from '@/componentLibrary/components/links';
import {S1} from '@/componentLibrary/components/typography';
import {H3} from '@/componentLibrary/components/typography/Headings';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {
    ButtonWrapper,
    DescriptionWrapper,
    FormWrapper,
    Image,
    OrganizationTitleWrapper,
    PrivacyPolicyNoticeWrapper,
    TitleWrapper
} from '@/pages/PublicJobPosition/pages/ApplyForJobPosition/components/ApplyForm/styles';

import {FormFields} from './components/FormFields';
import {FormWarning} from './components/FormWarning';
import {ThankYouPage} from './components/ThankYouPage';
import {useApplyFormErrorStatesLogic} from './hooks/useApplyFormErrorStatesLogic';
import {isInvalidFormData, useApplyFormLogic} from './hooks/useApplyFormLogic';
import {useApplyFormStatesLogic} from './hooks/useApplyFormStatesLogic';
import messages from './messages';

type ApplyFormProps = {
    loading: boolean;
    jobPosition: GetPublicJobPositionQuery['publicJobPosition'];
    doAddCandidate: ({
        candidateData,
        createdFrom,
        jobPositionId,
        invitationOptions
    }: DoCandidateForJobPositionProps) => Promise<
        FetchResult<AddCandidateForJobPositionMutation>
    >;
};

export function ApplyForm({loading, jobPosition, doAddCandidate}: ApplyFormProps) {
    const {hasEmailError, hasCountryBlockedError, warningIsVisible, setError, clearErrors} =
        useApplyFormErrorStatesLogic();
    const intl = useIntl();
    const {triedToSubmit, completed, setFormState} = useApplyFormStatesLogic();

    const {formData, handleTextInputChange, fieldHasError, handleSubmit} = useApplyFormLogic({
        doAddCandidate,
        setError,
        clearErrors,
        triedToSubmit,
        setFormState,
        jobPosition
    });

    const organizationLogo = jobPosition?.publicPageLogoEnabled
        ? jobPosition?.organizationLogo
        : null;

    const organizationExternalPrivacyPolicyUrl =
        jobPosition?.organizationExternalPrivacyPolicyUrl
            ? jobPosition?.organizationExternalPrivacyPolicyUrl
            : undefined;

    const {publicPageTitle, publicPageInstructions, name: jobPositionName} = jobPosition ?? {};

    return (
        <FormWrapper>
            <Image imageUrl={organizationLogo} />
            {jobPosition?.publicPageOrganizationNameEnabled ? (
                <OrganizationTitleWrapper>
                    <S1>{jobPosition?.publicPageOrganizationName}</S1>
                </OrganizationTitleWrapper>
            ) : null}
            {completed ? (
                <ThankYouPage />
            ) : (
                <Fragment>
                    <TitleWrapper>
                        <H3>
                            {publicPageTitle ? (
                                publicPageTitle
                            ) : (
                                <FormattedMessage
                                    {...messages.title}
                                    values={{jobName: jobPositionName}}
                                />
                            )}
                        </H3>
                    </TitleWrapper>
                    <DescriptionWrapper color={ColorFgSubtle}>
                        {publicPageInstructions ? (
                            <TextEditor
                                fullWidth
                                showContentOnly
                                content={publicPageInstructions}
                            />
                        ) : (
                            <FormattedMessage {...messages.description} />
                        )}
                    </DescriptionWrapper>

                    <FormFields
                        formData={formData}
                        fieldHasError={fieldHasError}
                        handleTextInputChange={handleTextInputChange}
                        hasEmailError={hasEmailError}
                    />
                    <ButtonWrapper>
                        <PrimaryButton
                            onClick={handleSubmit}
                            isLoading={loading}
                            disabled={isInvalidFormData(formData)}
                        >
                            <FormattedMessage {...messages.getStarted} />
                        </PrimaryButton>
                    </ButtonWrapper>
                    {jobPosition?.publicPagePrivacyNoticeEnabled ? (
                        <PrivacyPolicyNoticeWrapper>
                            <FormattedMessage
                                {...messages.privacyPolicyNotice}
                                values={{
                                    privacyPolicyLink: (
                                        <Link
                                            noWrap
                                            notReactRouter
                                            url={organizationExternalPrivacyPolicyUrl}
                                        >
                                            {intl.formatMessage(messages.privacyPolicy)}
                                        </Link>
                                    )
                                }}
                            />
                        </PrivacyPolicyNoticeWrapper>
                    ) : null}
                    <FormWarning
                        triedToSubmit={triedToSubmit}
                        hasEmailError={hasEmailError}
                        warningIsVisible={warningIsVisible}
                        hasCountryBlockedError={hasCountryBlockedError}
                        onClose={clearErrors}
                    />
                </Fragment>
            )}
        </FormWrapper>
    );
}
