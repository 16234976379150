import {defineMessages} from 'react-intl';

export default defineMessages({
    validationRequestSent: {
        id: 'app.logicTestReport.validationIndicator.requestSentTitle'
    },
    testResultValidated: {
        id: 'app.logicTestReport.validationIndicator.testResultValidated'
    },
    testResultIsDifferent: {
        id: 'app.logicTestReport.validationIndicator.testResultIsDifferent'
    }
});
