import {ColorBgInset, ColorFgMuted} from '@/componentLibrary/tokens/variables';
import range from '@/utils/range';
import {useMemo} from 'react';
import {MAXIMUM_SCORE} from './constants';
import {ScoreCircle, Wrapper} from './styled';
import {Props} from './types';

export function InterviewScoreFigure({score}: Props) {
    const formattedScore = useMemo(() => (score ? score : 0), [score]);

    return (
        <Wrapper>
            {range(0, formattedScore).map((i: number) => (
                <ScoreCircle key={i} color={ColorFgMuted} />
            ))}
            {range(0, MAXIMUM_SCORE - formattedScore).map(i => (
                <ScoreCircle key={i} color={ColorBgInset} />
            ))}
        </Wrapper>
    );
}
