import {defineMessages} from 'react-intl';

export default defineMessages({
    NOT_STARTED: {
        id: 'app.pages.user.chooseOrganization.myApplication.testProgressBox.NOT_STARTED'
    },
    COMPLETED: {
        id: 'app.pages.user.chooseOrganization.myApplication.testProgressBox.COMPLETED'
    },
    IN_PROGRESS: {
        id: 'app.pages.user.chooseOrganization.myApplication.testProgressBox.IN_PROGRESS'
    },
    PERSONALITY_TEST: {
        id: 'app.pages.user.chooseOrganization.myApplication.testProgressBox.personalityAssessment'
    },
    LOGIC_TEST: {
        id: 'app.pages.user.chooseOrganization.myApplication.testProgressBox.logicAssessment'
    },
    SUPPORT_NEEDED: {
        id: 'app.pages.user.chooseOrganization.myApplication.testProgressBox.SUPPORT_NEEDED'
    },
    SUBMITTED: {
        id: 'app.pages.user.chooseOrganization.myApplication.testProgressBox.COMPLETED'
    }
});
