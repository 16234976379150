import {defineMessages} from 'react-intl';

export const personalityDataCollectionMessages = defineMessages({
    completionPageFigureEightContent: {
        id: 'personalityTestDataCollection.completionPageFigureEightContent'
    },
    completionPageProlificContent: {
        id: 'personalityTestDataCollection.completionPageProlificContent'
    },
    completionPageTitle: {
        id: 'personalityTestDataCollection.completionPageTitle'
    },
    figureEightContent: {
        id: 'personalityTestDataCollection.figureEightContent'
    },
    formTitle: {
        id: 'personalityTestDataCollection.formTitle'
    },
    generalContent: {
        id: 'personalityTestDataCollection.generalContent'
    },
    goToInstructions: {
        id: 'personalityTestDataCollection.goToInstructions'
    },
    instructionsTitle: {
        id: 'personalityTestDataCollection.instructionsTitle'
    },
    instructionDescription: {
        id: 'personalityTestDataCollection.instructionDescription'
    },
    prolificContent: {
        id: 'personalityTestDataCollection.prolificContent'
    },
    startTestButton: {
        id: 'personalityTestDataCollection.startTestButton'
    },
    testInstructionsContent: {
        id: 'personalityTestDataCollection.testInstructionsContent'
    },
    testInstructionsTitle: {
        id: 'personalityTestDataCollection.testInstructionsTitle'
    }
});
