import {Dropdown} from '@/componentLibrary/components/Dropdowns/Dropdown';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {TextFieldLabel} from '@/componentLibrary/components/inputs/styled';
import {ExternalLink} from '@/componentLibrary/components/links';
import {P2} from '@/componentLibrary/components/typography';
import {HELP_CENTER} from '@/constants/helpCenter';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {FormattedMessage} from 'react-intl';
import {MANUAL_TECH_STACK_OPTION} from '../../constants';
import {useTechStackOptions} from './hooks';
import messages from './messages';
import {TechStackSelectorPropType} from './types';

export const TechStackSelector = ({
    challengeAssignment,
    selectedFramework,
    handleSelectFramework,
    error
}: TechStackSelectorPropType) => {
    const phrases = useExtractPhraseConstants(messages);

    const {techStackOptions} = useTechStackOptions({challengeAssignment});

    if (!challengeAssignment?.challengeSpecifications) {
        return null;
    }

    if (challengeAssignment?.challengeSpecifications?.frameworks.length === 1) {
        return (
            <>
                <P2>
                    <FormattedMessage
                        values={{
                            languages:
                                challengeAssignment?.challengeSpecifications?.languages.join(
                                    ', '
                                ),
                            framework:
                                challengeAssignment?.challengeSpecifications?.frameworks[0]
                        }}
                        {...messages.techStackSelectedInfo}
                    />
                </P2>
                <P2>
                    <FormattedMessage
                        values={{
                            a: chunks => (
                                <ExternalLink url={HELP_CENTER.setupCodebaseForCodingTest}>
                                    {chunks}
                                </ExternalLink>
                            )
                        }}
                        {...messages.codespaceInfo}
                    />
                </P2>
            </>
        );
    }

    return (
        <>
            {!!(
                challengeAssignment?.challengeSpecifications?.languages.length ||
                challengeAssignment?.challengeSpecifications?.frameworks.length
            ) && (
                <P2 forwardedAs="div">
                    <div>{phrases.techStackLanguageHeader}</div>
                    {challengeAssignment?.challengeSpecifications?.languages.map(
                        (language: string, k: number) => (
                            <div key={k}>- {language}</div>
                        )
                    )}
                    {!challengeAssignment?.challengeSpecifications?.languages.length && (
                        <div>- {phrases.anyLanguageLabel}</div>
                    )}
                    <br />
                    <div>{phrases.techStackFrameworkHeader}</div>
                    {challengeAssignment?.challengeSpecifications?.frameworks.map(
                        (framework: string, k: number) => (
                            <div key={k}>- {framework}</div>
                        )
                    )}
                    {!challengeAssignment?.challengeSpecifications?.frameworks.length && (
                        <div>- {phrases.anyFrameworkLabel}</div>
                    )}
                </P2>
            )}
            <P2 forwardedAs="div">
                <TextFieldLabel isRequired>{phrases.techStackSelectLabel}</TextFieldLabel>
                <Dropdown
                    data-testid="tech-stack-selector"
                    selectedItem={selectedFramework}
                    onChange={handleSelectFramework}
                    items={techStackOptions}
                    hasError={!!error}
                    errorMessage={error}
                />
            </P2>
            {selectedFramework?.id === MANUAL_TECH_STACK_OPTION.id && (
                <Banner type={BannerType.ATTENTION} data-testid="manual-banner">
                    <div>
                        <FormattedMessage
                            values={{
                                a: chunks => (
                                    <ExternalLink url={HELP_CENTER.setupCodebaseForCodingTest}>
                                        {chunks}
                                    </ExternalLink>
                                )
                            }}
                            {...messages.manualBanner}
                        />
                    </div>
                </Banner>
            )}
        </>
    );
};
