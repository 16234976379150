import styled, {css} from 'styled-components';

import {
    BorderRadiusBaseRounded,
    ColorBaseGrey700,
    ColorBaseGrey800,
    ColorBgInset
} from '@/componentLibrary/tokens/variables';

import {StyledIconProps} from './types';

export const IconShell = styled.span<StyledIconProps>`
    width: ${({$isAction, $isDisabled, $size}) =>
        !$isDisabled && $isAction ? $size + 8 : $size}px;
    height: ${({$isAction, $isDisabled, $size}) =>
        !$isDisabled && $isAction ? $size + 8 : $size}px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: ${BorderRadiusBaseRounded};

    svg {
        width: ${({$size}) => $size}px;
        height: ${({$size}) => $size}px;
        display: flex;
    }

    transform: rotate(${({$rotate}) => $rotate ?? 0}deg);
    transition: transform 0.2s ease-in-out;

    ${({$fill}) =>
        $fill &&
        css`
            svg > path {
                fill: ${$fill};
            }
        `}

    ${({$isHoverable, $isAction, $isDisabled, $hoverFill}) =>
        !$isDisabled &&
        ($isAction || $isHoverable) &&
        css`
            cursor: pointer;

            &:hover {
                svg > path {
                    fill: ${$hoverFill};
                }
            }
        `}

    ${({$isActive}) =>
        $isActive &&
        css`
            background-color: ${ColorBgInset};

            &:hover {
                background-color: ${ColorBgInset};
            }
        `}

    ${({$isAction, $isDisabled, $isActive}) =>
        $isAction &&
        !$isActive &&
        !$isDisabled &&
        css`
            &:hover {
                background-color: ${ColorBaseGrey700}25;
            }

            &:active {
                background-color: ${ColorBaseGrey800}33;
            }
        `}

    ${({$isDisabled}) =>
        $isDisabled &&
        css`
            cursor: not-allowed;
            opacity: 0.5;
        `}
`;
