import {defineMessages} from 'react-intl';

export default defineMessages({
    whatIsAlva: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.components.applyInstructions.whatIsAlva'
    },
    alvaDescription: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.components.applyInstructions.alvaDescription'
    },
    whatNext: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.components.applyInstructions.whatNext'
    },
    createAccount: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.components.applyInstructions.createAccount'
    },
    createAccountDescription: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.components.applyInstructions.createAccountDescription'
    },
    completeAssessments: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.components.applyInstructions.completeAssessments'
    },
    completeAssessmentsDescription: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.components.applyInstructions.completeAssessmentsDescription'
    },
    checkResults: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.components.applyInstructions.checkResults'
    },
    checkResultsDescription: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.components.applyInstructions.checkResultsDescription'
    }
});
