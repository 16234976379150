import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {OtherCaption} from '@/componentLibrary/tokens/typography';
import {ColorBaseBlue800, SpacingXxsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${SpacingXxsmall};
`;

export const TertiaryButton = styled(PlainButton)`
    ${OtherCaption};
    color: ${ColorBaseBlue800};
    padding: 0;
`;
