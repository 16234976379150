import {SpacingMedium} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation-duration: 0.25s;
    animation-fill-mode: both;
    animation-name: fade-in;
    gap: ${SpacingMedium};
`;
export const InnerWrapper = styled.div`
    width: 520px;
    max-width: 100%;
    text-align: center;
`;
