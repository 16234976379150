import {FC} from 'react';
import {useHistory} from 'react-router-dom';

import {isDefined} from '@/utils/typeGuards/isDefined';

import {Icon} from '../icons/Icon';
import {Icons} from '../icons/constants';
import {H3} from '../typography';
import {HeaderTitle, HeaderTitleWrapper, HeaderWrapper, PrimaryActionWrapper} from './styled';
import {PageHeaderProps} from './types';

export const PageHeader: FC<PageHeaderProps> = ({
    title,
    primaryAction,
    secondaryAction,
    backLink
}) => {
    const history = useHistory();

    return (
        <HeaderWrapper>
            <HeaderTitleWrapper>
                <HeaderTitle>
                    {isDefined(backLink) && (
                        <Icon
                            icon={Icons.KEYBOARD_ARROW_LEFT}
                            onClick={() => history.push(backLink)}
                            size={16}
                            isAction
                        />
                    )}
                    <H3>{title}</H3>
                </HeaderTitle>
                {secondaryAction}
            </HeaderTitleWrapper>

            <PrimaryActionWrapper>{primaryAction}</PrimaryActionWrapper>
        </HeaderWrapper>
    );
};
