import {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import {useIntl} from 'react-intl';

import {TopBanner} from '@/componentLibrary/components/banners/TopBanner';
import {clearImpersonationToken, isImpersonating} from '@/utils/ajax';
import {getUserName} from '@/utils/misc';

import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {RequireAuthentication} from '../auth/RequireAuthentication';
import messages from './messages';
import {EndImpersonationButton} from './styled';

export const ImpersonationBanner = () => {
    const {formatMessage} = useIntl();
    const [isImpersonatingState, setIsImpersonatingState] = useState(isImpersonating);

    useEffect(() => {
        const handleTokenUpdate = () => setIsImpersonatingState(isImpersonating);

        window.addEventListener('impersonationTokenUpdatedEvent', handleTokenUpdate);

        return () => {
            window.removeEventListener('impersonationTokenUpdatedEvent', handleTokenUpdate);
        };
    }, []);

    const endImpersonation = () => {
        clearImpersonationToken();
        window.location.replace('/login');
    };

    const {user} = useLoggedInUser();

    const renderBanner = () => (
        <RequireAuthentication logoutAndRedirectIfNotLoggedIn={false}>
            {() => (
                <TopBanner visible={!isDefined(user)}>
                    <>
                        {formatMessage(messages.info, {userName: <b>{getUserName(user)}</b>})}
                        <EndImpersonationButton onClick={endImpersonation}>
                            {formatMessage(messages.endImpersonation)}
                        </EndImpersonationButton>
                    </>
                </TopBanner>
            )}
        </RequireAuthentication>
    );

    if (isImpersonatingState) {
        const domNode = window.document.getElementById('impersonation');
        if (domNode) {
            return createPortal(renderBanner(), domNode);
        }
    }
    return null;
};
