import {P2} from '@/componentLibrary/components/typography/Paragraphs';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    DescriptionTitle,
    DetailsWrapper,
    List,
    ParagraphWrapper,
    Wrapper
} from '@/pages/PublicJobPosition/pages/ApplyForJobPosition/components/PublicJobPositionNotFound/styled';
import messages from './messages';

export function PublicJobPositionNotFound() {
    const phrases = useExtractPhraseConstants(messages);
    return (
        <Wrapper>
            <ParagraphWrapper>
                <DescriptionTitle>{phrases.notFound}</DescriptionTitle>
                <DetailsWrapper>{phrases.description}</DetailsWrapper>
            </ParagraphWrapper>
            <ParagraphWrapper>
                <DetailsWrapper>
                    <List>
                        <P2 forwardedAs="li">{phrases.bullet1}</P2>
                        <P2 forwardedAs="li">{phrases.bullet2}</P2>
                        <P2 forwardedAs="li">{phrases.bullet3}</P2>
                    </List>
                </DetailsWrapper>
            </ParagraphWrapper>
        </Wrapper>
    );
}
