import {RefObject, createContext, useContext} from 'react';

import {isDefined} from '@/utils/typeGuards/isDefined';

export const DetailedModalContext = createContext<{
    tabRef: RefObject<HTMLDivElement>;
    parentRef: RefObject<HTMLDivElement>;
} | null>(null);

export function useDetailedModalContext() {
    const context = useContext(DetailedModalContext);
    if (!isDefined(context)) {
        throw new Error('useDetailedModalContext must be within DetailedModalContextProvider');
    }

    return context;
}
