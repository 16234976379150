import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {H4, P1, S1, ScorePercent} from '@/componentLibrary/components/typography';
import {ColorBaseGrey200, ColorBaseGrey900} from '@/componentLibrary/tokens/variables';
import {useHasCombinedRoleFitBetaTestByJobPositionId} from '@/hooks/useHasCombinedRoleFitBetaTest';

import {useInterviewScoreLogic} from './logic';
import messages from './messages';
import {ScoreWrapper, Wrapper} from './styled';
import {InterviewScoreProps} from './types';

export function InterviewScore({
    interview,
    jobApplication,
    jobPositionId
}: InterviewScoreProps) {
    const intl = useIntl();
    const {roleFitV1InterviewScore, roleFitV2InterviewScore} = useInterviewScoreLogic(
        jobApplication,
        interview.id
    );
    const hasCombinedRoleFitBetaTest =
        useHasCombinedRoleFitBetaTestByJobPositionId(jobPositionId);

    const RoleFitV1Score = useCallback(() => {
        return (
            <>
                <ScoreWrapper>
                    <S1>{intl.formatMessage(messages.score)}</S1>
                    :&nbsp;
                    <S1 color={roleFitV1InterviewScore ? ColorBaseGrey900 : ColorBaseGrey200}>
                        {roleFitV1InterviewScore ? roleFitV1InterviewScore + '/5' : 'N/A'}
                    </S1>
                </ScoreWrapper>
                {interview.description && <P1>{interview.description}</P1>}
            </>
        );
    }, [interview.description, intl, roleFitV1InterviewScore]);

    const RoleFitV2Score = useCallback(() => {
        return (
            <>
                <H4>{intl.formatMessage(messages.score)}</H4>
                <ScorePercent
                    score={roleFitV2InterviewScore?.score}
                    missingResults={roleFitV2InterviewScore?.missingResults ?? true}
                />
            </>
        );
    }, [intl, roleFitV2InterviewScore]);

    return (
        <Wrapper>
            {hasCombinedRoleFitBetaTest ? <RoleFitV2Score /> : <RoleFitV1Score />}
        </Wrapper>
    );
}
