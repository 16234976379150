import {useIntl} from 'react-intl';

import {StandardTenChart} from '@/componentLibrary/components/charts/StandardTenChart';
import {capValue, roundValue} from '@/utils/misc';

import {LogicPercentile} from '../components/LogicPercentile';
import messages from './messages';
import {ScoreWrapper, Wrapper} from './styled';
import {LogicTestReportChartProps} from './types';

export function LogicTestReportChart({logicalAbilityEstimate}: LogicTestReportChartProps) {
    const intl = useIntl();
    const standardScore = roundValue(capValue(logicalAbilityEstimate.mu, 1, 10), 0);
    const scoreText = intl.formatMessage(messages.score, {score: standardScore});

    return (
        <Wrapper>
            <StandardTenChart selectedValue={standardScore} showBadge />
            <ScoreWrapper>{scoreText}</ScoreWrapper>
            <LogicPercentile standardScore={standardScore} />
        </Wrapper>
    );
}
