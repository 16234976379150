import {useCallback, useEffect, useState} from 'react';

import {PERSONALITY_FACTORS_KEYS} from '@/componentLibrary/blocks/testReports/PersonalityTest/constants';

import {PersonalityTestModalType} from './constants';

type Props = {
    activeModalType: PersonalityTestModalType;
};

export function usePersonalityTestReportAnchorsLogic({activeModalType}: Props) {
    const [anchorTargetA, setAnchorTargetA] = useState<null | HTMLElement>(null);
    const [anchorTargetC, setAnchorTargetC] = useState<null | HTMLElement>(null);
    const [anchorTargetE, setAnchorTargetE] = useState<null | HTMLElement>(null);
    const [anchorTargetES, setAnchorTargetES] = useState<null | HTMLElement>(null);
    const [anchorTargetO, setAnchorTargetO] = useState<null | HTMLElement>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setAnchorTargetA(document.getElementById(PERSONALITY_FACTORS_KEYS.A));
        setAnchorTargetC(document.getElementById(PERSONALITY_FACTORS_KEYS.C));
        setAnchorTargetE(document.getElementById(PERSONALITY_FACTORS_KEYS.E));
        setAnchorTargetES(document.getElementById(PERSONALITY_FACTORS_KEYS.ES));
        setAnchorTargetO(document.getElementById(PERSONALITY_FACTORS_KEYS.O));
    }, [activeModalType]);

    const handleAnchorClick = useCallback(
        (factor, event) => {
            event.preventDefault();
            if (factor === PERSONALITY_FACTORS_KEYS.A) {
                anchorTargetA?.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
            if (factor === PERSONALITY_FACTORS_KEYS.C) {
                anchorTargetC?.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
            if (factor === PERSONALITY_FACTORS_KEYS.E) {
                anchorTargetE?.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
            if (factor === PERSONALITY_FACTORS_KEYS.ES) {
                anchorTargetES?.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
            if (factor === PERSONALITY_FACTORS_KEYS.O) {
                anchorTargetO?.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        },
        [anchorTargetA, anchorTargetC, anchorTargetE, anchorTargetES, anchorTargetO]
    );

    return {handleAnchorClick};
}
