import {FormattedMessage} from 'react-intl';

import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';
import {Link} from '@/componentLibrary/components/links/Link';
import {H3, P2} from '@/componentLibrary/components/typography';
import {ColorBaseGrey700} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import {useStatus} from '@/pages/Organization/pages/Assessment/components/CodingTests/hooks/useStatus';

import {ScorecardView} from '../ScorecardView';
import {CodingTestPreviewModal} from './components/CodingTestPreviewModal/CodingTestPreviewModal';
import messages from './messages';
import {
    CandidateProgressWrapper,
    CodingTestDescription,
    CodingTestLink,
    HorizontalBar,
    ProgressTable,
    ProgressTableRow,
    RowDescription,
    RowTitle,
    StatusDescription,
    StatusDot
} from './styled';
import {CandidateProgressTableProps} from './types';

export function CandidateProgressTable({
    challengeAssignment,
    candidatesRepo
}: CandidateProgressTableProps) {
    const phrases = useExtractPhraseConstants(messages);
    const testStatus = useStatus();

    const {
        modalIsOpen: scorecardsPreviewModalIsOpen,
        openModal: openScorecardsPreviewModal,
        closeModal: closeScorecardsPreviewModal
    } = useSimpleModalLogic();

    const {
        modalIsOpen: codingTestPreviewModalIsOpen,
        openModal: openCodingTestPreviewModal,
        closeModal: closeCodingTestPreviewModal
    } = useSimpleModalLogic();

    if (!challengeAssignment) {
        return null;
    }

    return (
        <>
            <CandidateProgressWrapper>
                <H3>{phrases.candidateProgressHeader}</H3>
                <ProgressTable>
                    <ProgressTableRow>
                        <RowTitle>{phrases.candidateProgressStatus}</RowTitle>
                        <StatusDescription>
                            <StatusDot color={testStatus[challengeAssignment?.status].color} />
                            <div>{testStatus[challengeAssignment?.status].text}</div>
                        </StatusDescription>
                    </ProgressTableRow>
                    <HorizontalBar />
                    <ProgressTableRow>
                        <RowTitle>{phrases.candidateProgressRepository}</RowTitle>
                        <RowDescription>
                            <div>{candidatesRepo}</div>
                            {challengeAssignment?.status !==
                                ChallengeAssignmentStatus.NOT_STARTED && (
                                <P2 color={ColorBaseGrey700}>
                                    {challengeAssignment?.challenge?.scorecard?.requirements
                                        ?.length ? (
                                        <FormattedMessage
                                            values={{
                                                a: chunks => (
                                                    <Link onClick={openScorecardsPreviewModal}>
                                                        {chunks}
                                                    </Link>
                                                )
                                            }}
                                            {...messages.manualReviewText}
                                        />
                                    ) : (
                                        phrases.manualReviewTextNoScorecards
                                    )}
                                </P2>
                            )}
                        </RowDescription>
                    </ProgressTableRow>
                    <HorizontalBar />
                    <ProgressTableRow>
                        <RowTitle>{phrases.candidateProgressCodingTest}</RowTitle>
                        <CodingTestDescription>
                            <div>{challengeAssignment?.challenge?.name}</div>
                            <CodingTestLink onClick={openCodingTestPreviewModal}>
                                {phrases.codingTestLink}
                            </CodingTestLink>
                        </CodingTestDescription>
                    </ProgressTableRow>
                </ProgressTable>
                {codingTestPreviewModalIsOpen && (
                    <CodingTestPreviewModal
                        onClose={closeCodingTestPreviewModal}
                        challenge={challengeAssignment.challenge}
                    />
                )}
            </CandidateProgressWrapper>
            {scorecardsPreviewModalIsOpen && (
                <ScorecardView
                    challenge={challengeAssignment?.challenge}
                    close={closeScorecardsPreviewModal}
                />
            )}
        </>
    );
}
