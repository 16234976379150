import parse from 'html-react-parser';
import {ReactNode, createElement} from 'react';

export function parseAndWrap(
    str: string,
    rootTag: keyof JSX.IntrinsicElements = 'div'
): ReactNode {
    const parsed: HTMLElement[] | ReactNode = parse(str);
    if (Array.isArray(parsed) && parsed.length === 1) {
        return parsed[0];
    }
    if (Array.isArray(parsed) && parsed.length > 1) {
        return createElement(rootTag, {}, parsed);
    }
    return parsed;
}
