import {useCallback, useMemo} from 'react';

import {roundValue} from '@/utils/misc';

import {useRoleFitContributionBarsLogicProps} from './types';

export function useRoleFitContributionBarsLogic({
    contributionItems
}: useRoleFitContributionBarsLogicProps) {
    const formatContributionValues = useCallback(item => {
        const percentageContribution = item.contribution;
        const missingContribution = percentageContribution !== 0 && !percentageContribution;

        const percentageMatch = missingContribution
            ? 0
            : roundValue(percentageContribution / item.maxContribution, 2);

        const absoluteContribution = missingContribution
            ? null
            : roundValue(percentageContribution * 100, 1);

        const maxAbsoluteContribution = roundValue(item.maxContribution * 100, 1);
        const barHeight = maxAbsoluteContribution * 8;

        return {
            facet: item.facet,
            percentageMatch,
            absoluteContribution,
            maxAbsoluteContribution,
            barHeight
        };
    }, []);

    const formattedContributionItems = useMemo(() => {
        return contributionItems.map(formatContributionValues);
    }, [contributionItems, formatContributionValues]);

    return {formattedContributionItems};
}
