import {OktaAuth} from '@okta/okta-auth-js';

import {OrganizationOktaConfiguration} from '@/api/types/__generated__/graphql';
import {getSsoRedirectUrl} from '@/services/auth/sso/utils';

function getOktaClient(config: OrganizationOktaConfiguration | null) {
    const options = {
        issuer: `https://${config?.domain}`,
        clientId: config?.clientId,
        redirectUri: getSsoRedirectUrl(),
        scopes: ['openid', 'email', 'profile']
    };

    return new OktaAuth(options);
}

export {getOktaClient};
