import {LeadershipDimension} from '@/api/types/__generated__/graphql';
import {P2} from '@/componentLibrary/components/typography';

import {TooltipTitle} from './components/TooltipTitle';
import {useLeadershipDimensionsLogic} from './logic';
import {Dimension, Wrapper} from './styled';

type Props = {
    dimensions: LeadershipDimension[];
};

export function LeadershipDimensions({dimensions}: Props) {
    const {getTitle, getTooltipTitle, getTooltipDescription, getLabel} =
        useLeadershipDimensionsLogic();

    const Dimensions = dimensions.map(dimension => {
        return (
            <Dimension key={dimension.name}>
                <TooltipTitle
                    title={getTitle(dimension)}
                    tooltipTitle={getTooltipTitle(dimension)}
                    tooltipDescription={getTooltipDescription(dimension)}
                />
                <P2>{getLabel(dimension)}</P2>
            </Dimension>
        );
    });

    return <Wrapper>{Dimensions}</Wrapper>;
}
