import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.pages.logicTestIrtValidation.continueValidationTest.title'
    },
    description: {
        id: 'app.pages.logicTestIrtValidation.continueValidationTest.description'
    },
    warning: {
        id: 'app.pages.logicTestIrtValidation.continueValidationTest.warning'
    },
    goodLuck: {
        id: 'app.pages.logicTestIrtValidation.continueValidationTest.goodLuck'
    },
    goToTest: {
        id: 'app.pages.logicTestIrtValidation.continueValidationTest.goToTest'
    }
});
