import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {GiveConsentInput, GiveConsentMutation} from '../types/__generated__/graphql';
import {GIVE_CONSENT} from './mutations';
import {GET_AUTHENTICATED_USER} from './queries';

export function useGiveConsent(): MutationHookReturnType<
    GiveConsentMutation,
    GiveConsentInput
> {
    const [mutate, {error, loading}] = useMutation(GIVE_CONSENT);
    const doGiveConsent = useCallback(
        ({consentType, version}) => {
            const queriesToRefetch = [
                {query: GET_AUTHENTICATED_USER, variables: {withConsents: true}}
            ];

            return mutate({
                variables: {data: {consentType, version}},
                refetchQueries: queriesToRefetch
            });
        },
        [mutate]
    );

    return [doGiveConsent, {error, loading}];
}
