export const isValidEmail = (email: string): boolean => {
    const validFormatRe =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const invalidCharRe = /[^\w+.\-@]/; // prevents non english characters being in emails, such as åäö

    return validFormatRe.test(email) && !invalidCharRe.test(email);
};

export function isValidUrl(urlString: string, requireHttps = false): boolean {
    let url: URL;

    try {
        url = new URL(urlString);
    } catch (_) {
        return false;
    }
    if (requireHttps) {
        return url.protocol.startsWith('https');
    }
    return true;
}
