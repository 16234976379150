import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';
import {Tag} from '@/componentLibrary/components/Tag';
import {TagVariant} from '@/componentLibrary/components/Tag/constants';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {DisplaySkeletonSmaller} from '@/componentLibrary/components/skeleton/DisplaySkeleton';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {ReactNode, useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';

import {TEST_PROGRESS} from '../../constants';
import {CodingTestModal} from '../CodingTestModal';
import {useModal} from '../CodingTestModal/hooks/useModal';
import {LoadingState} from './components/LoadingState';
import messages, {
    CODING_TEST_PROGRESS_MESSAGES,
    TEST_ACTION_MESSAGES,
    TEST_PROGRESS_MESSAGES
} from './messages';
import {
    ActionWrapper,
    ButtonsWrapper,
    Description,
    HeaderWrapper,
    OldTestBanner,
    PrimaryAction,
    SubTitle,
    TestTitle,
    TimeEstimate,
    Wrapper
} from './styled';

type Props = {
    title: ReactNode;
    subTitle?: ReactNode;
    description: ReactNode;
    timeText: ReactNode;
    testProgress?: TEST_PROGRESS;
    codingTestProgress?: ChallengeAssignmentStatus;
    link?: string | null;
    setShowStartNewTest?: (show: boolean) => void;
    setShowSeePreviousResult?: (show: boolean) => void;
    shareablePersonalityTestId?: string;
    shareableLogicTestId?: string;
    loading?: boolean;
    allowedToTakeNewTest?: boolean;
    codingTestAssignmentId?: string;
    numberOfSharedTests?: number;
    userIsRegistered?: boolean;
};

export function TestItem(props: Props) {
    const phrases = useExtractPhraseConstants(messages);

    const intl = useIntl();
    const history = useHistory();
    const {
        title,
        subTitle,
        description,
        timeText,
        testProgress,
        codingTestProgress,
        link,
        setShowStartNewTest,
        setShowSeePreviousResult,
        loading = false,
        allowedToTakeNewTest = false,
        codingTestAssignmentId = ''
    } = props;

    const {
        modalIsOpen: isStartCodingTestModal,
        openModal: openStartCodingTestModal,
        closeModal: closeStartCodingTestModal
    } = useModal({assignmentId: codingTestAssignmentId});

    const getMessageForTestProgress = useMemo(() => {
        if (testProgress && TEST_PROGRESS_MESSAGES[testProgress]) {
            return intl.formatMessage(TEST_PROGRESS_MESSAGES[testProgress]);
        }
    }, [intl, testProgress]);

    const getMessageForCodingTestProgress = useMemo(() => {
        if (codingTestProgress && CODING_TEST_PROGRESS_MESSAGES[codingTestProgress]) {
            return intl.formatMessage(CODING_TEST_PROGRESS_MESSAGES[codingTestProgress]);
        }
    }, [codingTestProgress, intl]);

    const getMessageForTestAction = useMemo(() => {
        if (testProgress && TEST_ACTION_MESSAGES[testProgress]) {
            return intl.formatMessage(TEST_ACTION_MESSAGES[testProgress]);
        }
    }, [intl, testProgress]);

    const handleClick = useCallback(() => {
        if (link) {
            history.push(link);
        }
    }, [history, link]);

    const handleClickSupportNeeded = useCallback(() => {
        if (link) {
            window.location.href = `${link}/issues`;
        }
    }, [link]);

    const seePreviousResult = useCallback(() => {
        setShowSeePreviousResult && setShowSeePreviousResult(true);
    }, [setShowSeePreviousResult]);

    const startNewTest = useCallback(() => {
        setShowStartNewTest && setShowStartNewTest(true);
    }, [setShowStartNewTest]);

    const tagVariant = useMemo(() => {
        if (
            testProgress === TEST_PROGRESS.COMPLETED ||
            codingTestProgress === ChallengeAssignmentStatus.SUBMITTED
        ) {
            return TagVariant.success;
        }
        if (
            testProgress === TEST_PROGRESS.IN_PROGRESS ||
            codingTestProgress === ChallengeAssignmentStatus.IN_PROGRESS
        ) {
            return TagVariant.info;
        }
        if (codingTestProgress === ChallengeAssignmentStatus.SUPPORT_NEEDED) {
            return TagVariant.danger;
        }
        return TagVariant.default;
    }, [testProgress, codingTestProgress]);

    const button = useMemo(() => {
        if (!testProgress && !codingTestProgress) {
            return null;
        }
        if (testProgress === TEST_PROGRESS.SHAREABLE) {
            return (
                <>
                    <PrimaryAction onClick={seePreviousResult}>
                        {phrases.seePreviousResult}
                    </PrimaryAction>
                    {allowedToTakeNewTest && (
                        <SecondaryButton onClick={startNewTest}>
                            {phrases.startNewTest}
                        </SecondaryButton>
                    )}
                </>
            );
        }
        if (
            testProgress === TEST_PROGRESS.IN_PROGRESS ||
            testProgress === TEST_PROGRESS.NOT_STARTED ||
            testProgress === TEST_PROGRESS.EXPIRED
        ) {
            return (
                <PrimaryAction onClick={handleClick}>{getMessageForTestAction}</PrimaryAction>
            );
        }
        if (testProgress === TEST_PROGRESS.COMPLETED) {
            return (
                <SecondaryButton onClick={handleClick}>
                    {getMessageForTestAction}
                </SecondaryButton>
            );
        }
        if (codingTestProgress === ChallengeAssignmentStatus.SUPPORT_NEEDED) {
            return (
                <PrimaryAction onClick={handleClickSupportNeeded}>
                    {phrases.codingTestContinue}
                </PrimaryAction>
            );
        }

        if (
            codingTestProgress === ChallengeAssignmentStatus.IN_PROGRESS ||
            codingTestProgress === ChallengeAssignmentStatus.NOT_STARTED
        ) {
            return (
                <PrimaryAction onClick={openStartCodingTestModal}>
                    {codingTestProgress === ChallengeAssignmentStatus.NOT_STARTED
                        ? phrases.codingTestStart
                        : phrases.codingTestContinue}
                </PrimaryAction>
            );
        }
        if (codingTestProgress === ChallengeAssignmentStatus.SUBMITTED) {
            return (
                <SecondaryButton onClick={openStartCodingTestModal}>
                    {phrases.codingTestCompleted}
                </SecondaryButton>
            );
        }
    }, [
        testProgress,
        codingTestProgress,
        seePreviousResult,
        phrases.seePreviousResult,
        phrases.startNewTest,
        phrases.codingTestContinue,
        phrases.codingTestStart,
        phrases.codingTestCompleted,
        allowedToTakeNewTest,
        startNewTest,
        handleClick,
        getMessageForTestAction,
        handleClickSupportNeeded,
        openStartCodingTestModal
    ]);

    const displayOldTestBanner = useMemo(() => {
        return props.userIsRegistered && testProgress === TEST_PROGRESS.EXPIRED;
    }, [testProgress, props.userIsRegistered]);

    if (loading) {
        return <LoadingState />;
    }

    return (
        <>
            <Wrapper>
                <HeaderWrapper>
                    {loading ? (
                        <DisplaySkeletonSmaller />
                    ) : (
                        <>
                            <TestTitle>{title}</TestTitle>

                            <span>
                                {testProgress !== TEST_PROGRESS.SHAREABLE && (
                                    <Tag $variant={tagVariant}>
                                        {getMessageForTestProgress ||
                                            getMessageForCodingTestProgress}
                                    </Tag>
                                )}
                            </span>
                        </>
                    )}
                </HeaderWrapper>

                {subTitle && <SubTitle>{subTitle}</SubTitle>}
                <TimeEstimate>{timeText}</TimeEstimate>
                <Description>{description}</Description>
                <ActionWrapper>
                    {displayOldTestBanner && (
                        <OldTestBanner noIcon title={phrases.oldTestBannerTitle}>
                            {phrases.oldTestBannerDescription}
                        </OldTestBanner>
                    )}
                    <ButtonsWrapper>{button}</ButtonsWrapper>
                </ActionWrapper>
            </Wrapper>
            {isStartCodingTestModal && (
                <CodingTestModal
                    showModal={isStartCodingTestModal}
                    onHide={closeStartCodingTestModal}
                />
            )}
        </>
    );
}
