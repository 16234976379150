import {grey} from '@/componentLibrary/tokens/legacyColors';
import {SpacingMedium, SpacingSmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const OuterWrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: ${grey.grey800(0.5)};
`;
export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${SpacingSmall};
    padding: ${SpacingSmall} ${SpacingMedium};
`;
