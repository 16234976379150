import {useMemo} from 'react';

import {ColorBgInset, ColorFgDefault} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {isArrayWithItems} from '@/utils/typeGuards/isArrayWithItems';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {getAssignmentScore} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateCodingTestPage/utils';
import {useCandidateRoleFitContext} from '../../context';
import {SCORE_TYPE} from '../AssessmentScoresResults/constants';
import messages from './messages';
import {UseScoreCardsLogicResult} from './types';
import {roundPercentageValue, sortAssessmentScores} from './utils';

export const useScoreCardsLogic = (): UseScoreCardsLogicResult[] => {
    const phrases = useExtractPhraseConstants(messages);

    const {jobApplication, jobPosition} = useCandidateRoleFitContext();
    const roleFit = jobApplication.roleFitV2;

    const interviewIds = useMemo(() => {
        return jobPosition?.interviews?.map(interview => interview.id);
    }, [jobPosition?.interviews]);

    const sortedAssessmentScores = useMemo(() => {
        return isDefined(roleFit) && isArrayWithItems(roleFit?.assessmentScores)
            ? sortAssessmentScores(roleFit.assessmentScores, interviewIds)
            : [];
    }, [interviewIds, roleFit]);

    const scoreTitles = {
        PERSONALITY_TEST: phrases.personalityTest,
        CODING_TEST: phrases.codingTest,
        LOGIC_TEST: phrases.logicTest
    };

    const chartData =
        roleFit?.assessmentScores.map(({assessmentMethodType}) => {
            const value =
                roleFit?.assessmentConfigurations.find(
                    ac => ac.assessmentMethodType === assessmentMethodType
                )?.weightPercentage ?? 0;
            return {label: assessmentMethodType, value};
        }) ?? [];

    const getCodingTestScoreCard = () => {
        const miniChartColors = chartData.map(({label}) =>
            label === SCORE_TYPE.CODING_TEST ? ColorFgDefault : ColorBgInset
        );
        return {
            type: SCORE_TYPE.CODING_TEST,
            title: phrases.codingTest,
            scoreInPercentage:
                getAssignmentScore(jobApplication.challengeAssignment ?? null)?.totalScore ??
                undefined,
            amount: roleFit?.assessmentConfigurations.length || 0,
            chartData,
            miniChartColors,
            weight: roleFit?.assessmentConfigurations.find(
                ac => ac.assessmentMethodType === SCORE_TYPE.CODING_TEST
            )?.weight
        };
    };

    return (
        sortedAssessmentScores.map(value => {
            if (value.assessmentMethodType === SCORE_TYPE.CODING_TEST) {
                return getCodingTestScoreCard();
            }
            const isInterview = value.assessmentMethodType === SCORE_TYPE.INTERVIEW;
            const interview = isInterview
                ? jobPosition.interviews?.find(
                      ({id}) => id === parseInt(value.assessmentMethodId, 10)
                  )
                : null;
            const title = isDefined(interview)
                ? interview?.title
                : scoreTitles[value.assessmentMethodType as keyof typeof scoreTitles];
            const missingResults = value.missingResults;
            const scoreInPercentage = missingResults
                ? undefined
                : roundPercentageValue(value.score);
            const amount = roleFit?.assessmentConfigurations.length || 0;
            const miniChartColors = chartData.map(({label}) =>
                label === value.assessmentMethodType ? ColorFgDefault : ColorBgInset
            );

            return {
                type: value.assessmentMethodType as SCORE_TYPE,
                title,
                scoreInPercentage,
                amount,
                chartData,
                miniChartColors,
                interviewId: isInterview ? value.assessmentMethodId : undefined,
                weight: roleFit?.assessmentConfigurations.find(
                    ac => ac.assessmentMethodType === value.assessmentMethodType
                )?.weight
            };
        }) ?? []
    );
};
