import {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {LandingPageLayout} from '@/componentLibrary/blocks/LandingPageLayout';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {BodyP2} from '@/componentLibrary/tokens/typography';

import {
    ColorBaseGrey900,
    FontSizeLargest,
    SpacingMedium
} from '@/componentLibrary/tokens/variables';
import {RecoverPasswordForm} from './components/RecoverForm/index';
import {useRecoverPasswordLogic} from './logic';
import messages from './messages';

export function RecoverPasswordPage() {
    const showToast = useCallback((isError, message) => {
        toast({
            type: isError ? 'error' : 'success',
            message: <FormattedMessage {...message} />
        });
    }, []);

    const {sendResetPasswordEmail, loading} = useRecoverPasswordLogic(showToast);

    return (
        <LandingPageLayout>
            <OuterWrapper>
                <Row>
                    <Column>
                        <TitleText>
                            <FormattedMessage {...messages.title} />
                        </TitleText>
                        <P1>
                            <FormattedMessage {...messages.description} />
                        </P1>
                    </Column>
                    <Column>
                        <RecoverPasswordForm
                            submit={sendResetPasswordEmail}
                            loading={loading}
                        />
                    </Column>
                </Row>
            </OuterWrapper>
        </LandingPageLayout>
    );
}

const OuterWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 114px;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        padding: 0;
    }
`;

const Row = styled.div`
    height: 100%;
    width: 100%;

    @media only screen and (min-width: 1680px) {
        width: 1000px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column;
        justify-content: flex-start;
        padding: 0;
        width: 100%;
    }
`;

const Column = styled.div`
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 290px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        &:first-child {
            margin-top: 48px;
            height: auto;
            margin-bottom: 20px;
        }
    }
`;

const TitleText = styled.div`
    ${BodyP2};
    font-size: 60px;
    line-height: 58px;
    letter-spacing: -3px;
    color: ${ColorBaseGrey900};
    margin-bottom: ${SpacingMedium};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        font-size: ${FontSizeLargest};
        line-height: ${FontSizeLargest};
    }
`;
