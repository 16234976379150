import {useCallback} from 'react';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {ModalFooterProps} from '@/componentLibrary/components/Modal/ModalFooter/types';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {SelectInterviewers} from './SelectInterviewers';
import {SelectInterviews} from './SelectInterviews';
import {INTERVIEWER_SELECTION_STEP, INTERVIEW_SELECTION_STEP} from './constants';
import {
    useRequestInterviewModalLogic,
    useSelectInterviewersLogic,
    useSelectInterviewsLogic
} from './logic';
import messages from './messages';
import {Body} from './styled';
import {RequestInterviewModalProps, SelectInterviewType} from './types';

export const RequestInterviewModal = ({
    jobApplicationIds,
    preselectedEmploymentIds,
    jobPositionId,
    onComplete,
    closeModal,
    skipSelectInterviews,
    skipSelectInterviewers,
    preselectedInterviewIds,
    existingInterviewers
}: RequestInterviewModalProps) => {
    const {jobPositionInterviews, selectInterview, selectedInterviewIds, isInterviewSelected} =
        useSelectInterviewsLogic(jobPositionId);

    const {
        handleSelectInterviewer,
        isEmployeeAlreadySelectedAsInterviewer,
        sortedSelectedInterviewers,
        newInterviewerIds,
        removeEmployment,
        notifyInterviewers,
        toggleNotifyInterviewers,
        removedInterviewerIds
    } = useSelectInterviewersLogic(existingInterviewers);

    const {
        loading,
        createOrRemoveInterviewRequests,
        startInterview,
        modalStep,
        goToNextStep,
        goToPreviousStep
    } = useRequestInterviewModalLogic({
        jobApplicationIds,
        interviewIds:
            preselectedInterviewIds && preselectedInterviewIds.length > 0
                ? preselectedInterviewIds
                : selectedInterviewIds,
        employmentIds:
            preselectedEmploymentIds && preselectedEmploymentIds.length > 0
                ? preselectedEmploymentIds
                : newInterviewerIds,
        removedEmploymentIds: removedInterviewerIds,
        notifyInterviewers,
        skipSelectInterviews,
        onComplete,
        closeModal
    });

    const phrases = useExtractPhraseConstants(messages);

    const primarySelectInterviewsAction = useCallback(
        () => (
            <PrimaryButton onClick={goToNextStep} disabled={selectedInterviewIds.length === 0}>
                {phrases.next}
            </PrimaryButton>
        ),
        [goToNextStep, phrases.next, selectedInterviewIds.length]
    );

    const primarySelectInterviewersAction = useCallback(
        () => (
            <PrimaryButton
                onClick={createOrRemoveInterviewRequests}
                disabled={newInterviewerIds.length === 0 && removedInterviewerIds.length === 0}
                isLoading={loading}
            >
                {existingInterviewers && existingInterviewers.length > 0
                    ? phrases.updateInterviewersButton
                    : phrases.assignInterviewersButton}
            </PrimaryButton>
        ),
        [
            createOrRemoveInterviewRequests,
            newInterviewerIds.length,
            removedInterviewerIds.length,
            loading,
            existingInterviewers,
            phrases.updateInterviewersButton,
            phrases.assignInterviewersButton
        ]
    );

    const primaryStartInterviewAction = useCallback(
        () => (
            <PrimaryButton
                onClick={createOrRemoveInterviewRequests}
                disabled={selectedInterviewIds.length === 0}
            >
                {phrases.startInterviewButton}
            </PrimaryButton>
        ),
        [
            createOrRemoveInterviewRequests,
            phrases.startInterviewButton,
            selectedInterviewIds.length
        ]
    );

    const secondaryCancelAction = useCallback(
        () => <SecondaryButton onClick={closeModal}>{phrases.cancel}</SecondaryButton>,
        [closeModal, phrases.cancel]
    );

    const secondaryGoBackAction = useCallback(
        () => <SecondaryButton onClick={goToPreviousStep}>{phrases.previous}</SecondaryButton>,
        [goToPreviousStep, phrases.previous]
    );

    const getSecondaryAction = useCallback(() => {
        if (
            modalStep === INTERVIEW_SELECTION_STEP ||
            skipSelectInterviews ||
            skipSelectInterviewers
        ) {
            return secondaryCancelAction();
        }
        return secondaryGoBackAction();
    }, [
        modalStep,
        skipSelectInterviews,
        skipSelectInterviewers,
        secondaryCancelAction,
        secondaryGoBackAction
    ]);

    const getPrimaryAction = useCallback(() => {
        if (skipSelectInterviewers) {
            return primaryStartInterviewAction();
        }
        if (modalStep === INTERVIEWER_SELECTION_STEP) {
            return primarySelectInterviewersAction();
        }
        return primarySelectInterviewsAction();
    }, [
        modalStep,
        skipSelectInterviewers,
        primarySelectInterviewsAction,
        primarySelectInterviewersAction,
        primaryStartInterviewAction
    ]);

    const getStepper = useCallback(() => {
        if (!skipSelectInterviews && !skipSelectInterviewers) {
            return {noOfSteps: 2, activeStep: modalStep};
        }
    }, [modalStep, skipSelectInterviews, skipSelectInterviewers]);

    const getModalFooter = useCallback(
        (): ModalFooterProps | undefined =>
            !skipSelectInterviewers
                ? {
                      leftSecondaryAction: getSecondaryAction(),
                      primaryAction: getPrimaryAction()
                  }
                : undefined,
        [getSecondaryAction, getPrimaryAction, skipSelectInterviewers]
    );

    const getModalTitle = useCallback(() => {
        if (skipSelectInterviews || modalStep === INTERVIEWER_SELECTION_STEP) {
            return phrases.assignInterviewerTitle;
        }
        if (skipSelectInterviewers) {
            return phrases.startInterviewTitle;
        }

        return phrases.assignInterviewTitle;
    }, [
        skipSelectInterviews,
        modalStep,
        phrases.assignInterviewerTitle,
        phrases.startInterviewTitle,
        phrases.assignInterviewTitle,
        skipSelectInterviewers
    ]);

    return (
        <Modal
            close={closeModal}
            headerTitle={getModalTitle()}
            footer={getModalFooter()}
            stepper={getStepper()}
        >
            <Body>
                {modalStep === INTERVIEW_SELECTION_STEP && !skipSelectInterviews ? (
                    <SelectInterviews
                        interviews={jobPositionInterviews}
                        isInterviewSelected={isInterviewSelected}
                        onSelect={skipSelectInterviewers ? startInterview : selectInterview}
                        selectionType={
                            skipSelectInterviewers
                                ? SelectInterviewType.START_INTERVIEW
                                : SelectInterviewType.ASSIGN_INTERVIEWERS
                        }
                    />
                ) : (
                    <SelectInterviewers
                        handleSelectInterviewer={handleSelectInterviewer}
                        isEmployeeAlreadySelectedAsInterviewer={
                            isEmployeeAlreadySelectedAsInterviewer
                        }
                        sortedSelectedInterviewers={sortedSelectedInterviewers}
                        removeEmployment={removeEmployment}
                        notifyInterviewers={notifyInterviewers}
                        toggleNotifyInterviewers={toggleNotifyInterviewers}
                    />
                )}
            </Body>
        </Modal>
    );
};
