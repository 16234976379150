import {defineMessages} from 'react-intl';

export default defineMessages({
    checkYourInbox: {
        id: 'app.emailConfirmationRequired.title'
    },
    confirmationNeeded: {
        id: 'app.emailConfirmationRequired.description'
    },
    resendQuestion: {
        id: 'app.emailConfirmationRequired.resendEmail.title'
    },
    resendLinkText: {
        id: 'app.emailConfirmationRequired.resendEmail.link'
    },
    resendSuccess: {
        id: 'app.emailConfirmationRequired.resendEmail.success'
    },
    resendError: {
        id: 'app.networkError.description'
    }
});
