import styled from 'styled-components';

import {ColorVariables} from '@/componentLibrary/tokens/types';

export const StyledList = styled.ul<{gap?: string; color: ColorVariables}>`
    display: flex;
    flex-direction: column;
    gap: ${props => props.gap};

    li {
        display: flex;
        align-items: center;

        &::before {
            content: '•';
            color: ${props => props.color};
            display: inline-block;
            width: 28px;
            font-size: 28px;
            line-height: 1;
            margin-top: -2px;
        }
    }

    p {
        display: inline;
    }
`;
