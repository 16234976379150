import {createPortal} from 'react-dom';

import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {ModalFooter} from '@/componentLibrary/components/Modal/ModalFooter';
import {ModalHeader} from '@/componentLibrary/components/Modal/ModalHeader';
import {PromoBanner} from '@/componentLibrary/components/banners/PromoBanner';
import {generateId} from '@/utils/misc';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {FocusLock} from '@/componentLibrary/misc/FocusLock';
import {useModalLogic} from './logic';
import {ModalBackground, ModalBody, ModalGlobalStyle} from './styled';
import {ModalProps} from './types';
import {getModalSize, validateProps} from './utils';

export function Modal({
    close,
    back,
    hideClose = false,
    size = MODAL_SIZE.SMALL,
    innerSize,
    height,
    closeOnClickOutside = true,
    isVerticallyCentered = size === MODAL_SIZE.LARGE,
    headerIcon,
    headerTitle,
    closeButtonText,
    headerCustomRightContent,
    children,
    footer,
    stepper,
    banner,
    bodyRef,
    withoutBody = false,
    withHeader = false
}: ModalProps) {
    validateProps({close, back, size});
    const backdropId = `modal_background${generateId()}`;
    const modalId = `modal_element${generateId()}`;
    const {setRef} = useModalLogic({
        close: (close || back) as () => void,
        closeOnClickOutside,
        backdropId
    });
    const ModalComponent = getModalSize({size: size});
    const displayHeader = withHeader || headerTitle || headerIcon || !hideClose;
    const modalProps = {
        id: modalId,
        ref: setRef,
        'aria-modal': true,
        role: 'dialog',
        $isVerticallyCentered: isVerticallyCentered,
        'data-testid': 'modal',
        $height: height
    };
    const headerProps = {
        title: headerTitle,
        icon: headerIcon,
        hideClose,
        closeButtonText,
        close,
        back,
        stepper,
        customRightContent: headerCustomRightContent
    };

    return createPortal(
        <FocusLock>
            <ModalBackground id={backdropId} data-testid="modal-backdrop">
                <ModalGlobalStyle />
                <ModalComponent {...modalProps}>
                    {isDefined(banner) && <PromoBanner {...banner} />}
                    {displayHeader && <ModalHeader {...headerProps} />}
                    {withoutBody ? (
                        children
                    ) : (
                        <ModalBody ref={bodyRef} $innerSize={innerSize}>
                            {children}
                        </ModalBody>
                    )}
                    {isDefined(footer) && <ModalFooter {...footer} />}
                </ModalComponent>
            </ModalBackground>
        </FocusLock>,
        document.body
    );
}
