import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXlarge, SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const AnchorsWrapper = styled.div`
    margin-top: ${SpacingXlarge};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${SpacingXxsmall};

    @media only screen and (max-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        display: none;
    }

    @media print {
        display: none;
    }
`;
