import styled, {css} from 'styled-components';

import {
    BorderRadiusBaseLarge,
    ColorBaseGrey200,
    SpacingSmall,
    SpacingXlarge,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    width: 100%;

    @media (min-width: 545px) {
        width: 490px;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TitleWrapper = styled.div`
    display: flex;
`;

export const OrganizationNameWrapper = styled.div`
    margin-inline-start: ${SpacingSmall};
    display: flex;
    flex-direction: column;
`;

export const ActionColumn = styled.div`
    margin-top: ${SpacingXlarge};
`;

export const TestsProgressWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    & > *:not(:first-child) {
        margin-top: ${SpacingXsmall};
    }
`;

export const Overlay = styled.div<{isVisible: boolean}>`
    ${({isVisible}) =>
        isVisible &&
        css`
            background-color: ${ColorBaseGrey200};
            border-radius: ${BorderRadiusBaseLarge};
            opacity: 0.5;
        `}
    margin-bottom: ${SpacingSmall};
`;
