import {OktaAuth} from '@okta/okta-auth-js';
import {useMemo} from 'react';

import {OrganizationOktaConfiguration} from '@/api/types/__generated__/graphql';
import {getSsoRedirectUrl} from '@/services/auth/sso/utils';

export function useOktaClient(oktaConfig?: OrganizationOktaConfiguration | null) {
    const config = useMemo(() => {
        return {
            issuer: `https://${oktaConfig?.domain}`,
            clientId: oktaConfig?.clientId,
            redirectUri: getSsoRedirectUrl(),
            scopes: ['openid', 'email', 'profile']
        };
    }, [oktaConfig]);

    return useMemo(() => new OktaAuth(config), [config]);
}
