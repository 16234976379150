import {PersonAvatarProps} from './types';

export function getInitials(innerUser: PersonAvatarProps['user']) {
    let initials = '';

    if (!innerUser || innerUser.isAnonymized) {
        return initials;
    }

    if (innerUser.firstName && innerUser.lastName) {
        initials = `${innerUser.firstName[0]}${innerUser.lastName[0]}`;
    } else if (innerUser.email && innerUser.email !== '') {
        const emailName = innerUser.email.split('@')[0];

        initials = emailName[0];
    }

    return initials.toUpperCase();
}
