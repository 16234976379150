import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.errorState.title'
    },
    descriptionOne: {
        id: 'app.errorState.descriptionOne'
    },
    descriptionTwo: {
        id: 'app.errorState.descriptionTwo'
    },
    refresh: {
        id: 'app.errorState.refresh'
    }
});
