import {useCallback, useEffect, useRef} from 'react';
import {withRouter} from 'react-router-dom';

import {getConfig} from '@/config';
import {shouldHideBadges} from '@/utils/shouldHideBadges';

import {useIntercomSettings, useWindowIntercom} from './hooks';

export const Intercom = withRouter(({location}) => {
    const config = getConfig();
    useWindowIntercom(config);
    const settings = useIntercomSettings(config);
    const booted = useRef(false);
    const inSharedResultsPage = /shared-results/.test(location.pathname);
    const shouldIntercomBeHidden = shouldHideBadges(location.pathname) || inSharedResultsPage;

    const bootIntercom = useCallback(() => {
        if (window.Intercom && settings) {
            window.Intercom('boot', settings);
            booted.current = true;
        }
    }, [settings]);

    const updateIntercom = useCallback(() => {
        if (window.Intercom && settings) {
            window.Intercom('update', settings);
        }
    }, [settings]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const shutdownIntercom = useCallback(() => {
        if (window.Intercom) {
            window.Intercom('shutdown');
            booted.current = false;
        }
    }, [booted]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (window.TestingMode) {
            console.log('Playwright detected, skipping Intercom');
            return;
        }
        if (!settings) {
            return;
        }
        if (booted.current && shouldIntercomBeHidden) {
            shutdownIntercom();
        } else if (!booted.current && !shouldIntercomBeHidden) {
            bootIntercom();
        } else if (booted.current) {
            updateIntercom();
        }
    }, [
        bootIntercom,
        updateIntercom,
        shutdownIntercom,
        settings,
        shouldIntercomBeHidden,
        booted
    ]);

    return null;
});
