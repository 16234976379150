import styled from 'styled-components';

import {SubtitleS1} from '@/componentLibrary/tokens/typography';
import {
    BorderWidthBase,
    BorderWidthEmphasis,
    ColorBaseGrey900,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const Legend = styled.div`
    ${SubtitleS1}
    color: ${ColorBaseGrey900};
    display: inline-block;
    min-width: 180px;
    padding-top: ${SpacingXsmall};
    border-top: ${BorderWidthEmphasis} solid ${ColorBaseGrey900};
    padding-bottom: ${SpacingXxsmall};
    border-bottom: ${BorderWidthBase} solid ${ColorBaseGrey900};
`;
