import styled, {css} from 'styled-components';

import {S2} from '@/componentLibrary/components/typography';
import {HeadingH1, HeadingH3, SubtitleS1} from '@/componentLibrary/tokens/typography';
import {
    BoxShadowSeparatorTable,
    SpacingLarge,
    SpacingMedium,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxlarge,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

import {MODAL_HEADER_HEIGHT} from './constants';

export const ModalHeader = styled.div`
    height: ${MODAL_HEADER_HEIGHT}px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: ${SpacingLarge} ${SpacingXxsmall} ${SpacingLarge} ${SpacingXxsmall};
    z-index: 2;
    background: white;
`;

export const CandidateNameHeader = styled(S2)<{show: boolean}>`
    position: absolute;
    width: 100%;
    text-align: center;
    transition: all 0.2s ease;
    z-index: -1;

    ${({show}) =>
        show
            ? 'opacity: 1; tranform: translateY(0px)'
            : `opacity: 0; transform: translateY(${SpacingXxsmall})`}
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Wrapper = styled(FlexColumn)`
    width: 928px;
    gap: ${SpacingXxlarge};
    margin: 0 auto;
`;

export const ResultsWrapper = styled(FlexRow)`
    gap: ${SpacingXlarge};
`;

const fixedHeaderCss = css`
    position: fixed;
    top: ${MODAL_HEADER_HEIGHT}px;
`;

export const ScoreWrapper = styled(FlexColumn)<{isSticky?: boolean}>`
    gap: ${SpacingXxsmall};
    width: 208px;
    ${({isSticky}) => isSticky && fixedHeaderCss}
`;

export const Score = styled(FlexColumn)<{isSticky?: boolean}>`
    width: 208px;
    padding-top: ${SpacingMedium};
    ${({isSticky}) => isSticky && fixedHeaderCss}
`;

export const ScorePercent = styled.div`
    ${HeadingH1}
    display: flex;
    align-items: baseline;
    gap: 6px;
    font-size: 64px;
    line-height: 120%;
`;

export const ScorePercentSymbol = styled.div`
    ${HeadingH3};
    line-height: 1;
`;

export const ScoreDisplay = styled(ScorePercent)`
    gap: 0;
`;

export const ScoreDisplayBase = ScorePercentSymbol;

export const ScoreName = styled(FlexRow)`
    ${SubtitleS1};
    gap: 6px;
`;

export const DetailedResultTable = styled(FlexColumn)<{
    isSticky?: boolean;
}>`
    flex: 1;
    ${({isSticky}) => isSticky && `padding-left: 240px;`}
`;

export const Row = styled(FlexRow)<{isSticky?: boolean; width?: number | null}>`
    box-shadow: ${BoxShadowSeparatorTable};
    background: white;

    &:last-child {
        box-shadow: none;
    }

    ${({isSticky}) => isSticky && fixedHeaderCss}

    ${({width}) => width && `width: ${width}px;`}
`;

export const Column = styled(FlexColumn)<{vcentered?: boolean}>`
    width: 50%;
    padding: ${SpacingLarge} ${SpacingMedium} ${SpacingLarge} ${SpacingXxsmall};
    gap: ${SpacingXsmall};
    ${({vcentered}) => (vcentered ? 'justify-content: center' : '')}
`;

export const Results = styled(FlexRow)`
    align-items: center;
    gap: ${SpacingXxsmall};
`;

export const TabWrapper = styled(Wrapper)`
    height: 41px;
    padding-bottom: 60px;
`;

export const OverduescoreTab = styled.div<{isSticky?: boolean}>`
    background-color: white;
    z-index: 1;
    width: 928px;
    ${({isSticky}) =>
        isSticky &&
        css`
            ${fixedHeaderCss}
            height: 41px; 
            padding-bottom: 60px;
        `}
`;
