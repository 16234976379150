import {
    FIRST_FALLBACK_LOCALE,
    SECOND_FALLBACK_LOCALE
} from '@/containers/LanguageProvider/helpers';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {Translation} from './types';

export const getTranslationsInPriorityOrder = (
    translations: Translation[],
    locale: string
) => {
    const priorityOrder = [locale, FIRST_FALLBACK_LOCALE, SECOND_FALLBACK_LOCALE];

    const onPriorityOrder = (a: Translation, b: Translation) => {
        const indexA = priorityOrder.indexOf(a.locale);
        const indexB = priorityOrder.indexOf(b.locale);
        const foundInPriorityOrder = (index: number) => index !== -1;

        if (foundInPriorityOrder(indexA) && foundInPriorityOrder(indexB)) {
            return indexA - indexB;
        }

        if (foundInPriorityOrder(indexA)) {
            return -1;
        }
        if (foundInPriorityOrder(indexB)) {
            return 1;
        }

        return a.locale.localeCompare(b.locale);
    };

    return [...translations].sort(onPriorityOrder);
};

export const hasValue = (value?: string | object) => {
    if (!isDefined(value)) {
        return false;
    }

    if (typeof value === 'string') {
        return value !== '';
    }

    return true;
};

export const getOrFallback = (translations: Translation[], key: string) => {
    const translationProperty = key as keyof Translation;

    const hasValueForProperty = (translation: Translation) => {
        const value = translation[translationProperty];
        return hasValue(value);
    };

    const translation = translations.filter(isDefined).find(hasValueForProperty);

    if (!isDefined(translation)) {
        return {
            value: undefined,
            locale: undefined
        };
    }

    return {
        value: translation[translationProperty],
        locale: translation.locale
    };
};
