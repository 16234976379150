import {DEFAULT_LOCALE} from '@/config';
import {AVAILABLE_LOCALES} from '@/services/locale/constants';

const LOCALE_KEY = 'alva:locale';

export const getLocaleState = (): {locale: string} => {
    if (!window.localStorage || !window.localStorage.getItem(LOCALE_KEY)) {
        return getDefaultState();
    }
    let savedState;

    try {
        savedState = JSON.parse(window.localStorage.getItem(LOCALE_KEY) as string);
    } catch (_e) {
        return getDefaultState();
    }

    // Make sure that the returned object have the same format as 'initialUi'
    // This returns a new object where initialUi is overridden with settings in saved state
    return Object.assign({}, getDefaultState(), savedState);
};

function getDefaultState(): {locale: string} {
    const languages = navigator.languages,
        language = navigator.language;
    return {
        locale: getUserPreferredLanguage([...languages], language)
    };
}

export function getUserPreferredLanguage(
    languages: string[] | undefined,
    language: string | undefined
) {
    if (languages && languages.length) {
        const matchedLocale = languages.find(locale => {
            return matchLocaleAgainstAvailableLocales(locale);
        });
        if (matchedLocale) {
            return matchedLocale.slice(0, AVAILABLE_LOCALES[0].length);
        }
    }

    if (language) {
        const matchedLocale = matchLocaleAgainstAvailableLocales(language);

        if (matchedLocale) {
            return matchedLocale;
        }
    }

    return DEFAULT_LOCALE;
}

function matchLocaleAgainstAvailableLocales(locale: string) {
    return AVAILABLE_LOCALES.find(availableLocale => {
        if (locale.startsWith(availableLocale)) {
            return availableLocale;
        }
    });
}

export const saveLocaleState = (data: {locale: string}) => {
    if (!window.localStorage) {
        return;
    }
    window.localStorage.setItem(LOCALE_KEY, JSON.stringify(data));
};

/** DO NOT EDIT, THIS FILE IS GENERATED FROM A TEMPLATE
    See helpers.ts.jinja and run "yarn sync-locales" */
//START_JINJA_TEMPLATE
const ACCEPTED_CONTENT_ITEM_LOCALES = ['en', 'sv', 'fi', 'nb'];
//END_JINJA_TEMPLATE

export function getLocaleForInterviewContentItem(locale: string) {
    if (!ACCEPTED_CONTENT_ITEM_LOCALES.includes(locale)) {
        return DEFAULT_LOCALE;
    }

    return locale;
}

export const FIRST_FALLBACK_LOCALE = DEFAULT_LOCALE;
export const SECOND_FALLBACK_LOCALE = 'sv';
