import {useCallback, useState} from 'react';

import {GetAccessTokenDetailsQuery} from '@/api/types/__generated__/graphql';
import {redirectToGoogle, redirectToMicrosoft} from '@/pages/Login/loginUtils';
import {SsoOperationType, SsoProvider, SsoProviderType} from '@/services/auth/sso/constants';
import {useRedirectToOkta} from '@/services/auth/sso/okta/useRedirectToOkta';
import {SsoState} from '@/services/auth/sso/types';
import {setSsoStateInSessionStorage} from '@/services/auth/sso/utils';

import {UserOrganization} from './types';

export function useMyEmploymentLogic(
    {
        organizationId,
        organizationOktaConfiguration,
        enforcedAuthenticationMethod
    }: UserOrganization,
    accessTokenDetails: GetAccessTokenDetailsQuery['accessTokenDetails'] | null
) {
    const [loginMethod, setLoginMethod] = useState<SsoProviderType | null>(null);
    const redirectToOkta = useRedirectToOkta();

    const organizationIsUnlockedByOktaLogin = useCallback(
        (org: UserOrganization) => {
            return (
                org.organizationOktaConfiguration?.domain ===
                accessTokenDetails?.organizationOktaConfiguration?.domain
            );
        },
        [accessTokenDetails?.organizationOktaConfiguration?.domain]
    );

    const handleLoginWithSso = useCallback(
        (ssoProvider: SsoProviderType) => {
            setLoginMethod(ssoProvider);
            const state: SsoState = {
                ssoProvider: ssoProvider,
                operationType: SsoOperationType.LOGIN,
                redirectPath: location.pathname,
                organizationId,
                oktaConfig: organizationOktaConfiguration,
                token: '',
                tosRevision: ''
            };
            setSsoStateInSessionStorage(state);

            const ssoActions = {
                [SsoProvider.OKTA]: () => redirectToOkta(state, organizationOktaConfiguration),
                [SsoProvider.GOOGLE]: () => redirectToGoogle(location.search),
                [SsoProvider.MICROSOFT]: () => redirectToMicrosoft(location.search)
            };

            ssoActions[ssoProvider]?.();
        },
        [organizationId, organizationOktaConfiguration, redirectToOkta]
    );

    return {
        organizationIsUnlockedByOktaLogin,
        handleLoginWithSso,
        loading: (loginMethod && loginMethod === enforcedAuthenticationMethod) || false
    };
}
