import DOMPurify from 'dompurify';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {convertMarkdownListItemsToHTMLString} from '@/pages/common/RelevanceForm/converters';
import {MessagesWithIndexSignature} from '@/types/generic.types';

import messages from '../../../JobRecommendationItem/messages';
import {Section} from '../Section';

type Props = {
    title: string;
    content: string | null;
};

const typedMessages = messages as MessagesWithIndexSignature;

export function Description({title, content}: Props) {
    const intl = useIntl();
    const descriptionTitle = intl.formatMessage(typedMessages[title]);

    const htmlString = convertMarkdownListItemsToHTMLString(content);

    if (htmlString === null) {
        return null;
    }

    return (
        <Section title={descriptionTitle}>
            <ListWrapper>
                <P2
                    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(htmlString)
                    }}
                />
            </ListWrapper>
        </Section>
    );
}

const ListWrapper = styled.ul`
    list-style: initial;
    margin-left: 20px;
`;
