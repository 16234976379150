import {useCallback} from 'react';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {SpacingXsmall} from '@/componentLibrary/tokens/variables';

import {ClearSelectionProps} from './types';

export function ClearSelection({onClick}: ClearSelectionProps) {
    const handleClick = useCallback(
        e => {
            e.stopPropagation();
            onClick?.();
        },
        [onClick]
    );
    return (
        <Icon
            icon={Icons.CLOSE}
            data-testid="clear-selection"
            onClick={handleClick}
            isHoverable
            isAction
            size={14}
            style={{cursor: 'pointer', pointerEvents: 'initial', marginRight: SpacingXsmall}}
        />
    );
}
