import styled from 'styled-components';

import {Overline} from '@/componentLibrary/components/typography/Overline';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';

export const HeaderText = styled(Overline)`
    color: ${greyColorTokens.grey400()};
`;

export const StenChartWrapper = styled.div`
    display: block;
    margin-bottom: -10px;
`;

export const ResultWrapper = styled.span`
    display: flex;

    & > * {
        vertical-align: middle;
        line-height: 20px;
        min-width: 15px;
    }

    & > *:nth-child(2) {
        margin-left: 45px;
    }
`;
