import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'personalityTestReport.personalityProfile'
    },
    seeFullTestResult: {
        id: 'personalityTestReport.seeFullTestResult'
    }
});
