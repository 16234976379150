import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXxlarge, SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const ChartWrapper = styled.div`
    margin: ${SpacingXxsmall} 0;
    display: flex;
    align-items: center;
    width: 100%;
    gap: ${SpacingXxlarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
        flex-direction: column;
    }
`;
