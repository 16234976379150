import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_PERSONALITY_DATA_COLLECTION_RESULT} from './queries';

export function usePersonalityDataCollectionResult(personalityTestId: string) {
    const variables = {personalityTestId};
    const {data, error, loading, refetch, stopPolling} = useQuery(
        GET_PERSONALITY_DATA_COLLECTION_RESULT,
        {
            variables,
            notifyOnNetworkStatusChange: true,
            pollInterval: 1000
        }
    );

    const personalityDataCollectionResult = useMemo(
        () => data?.personalityDataCollectionResult ?? null,
        [data]
    );
    return {error, loading, personalityDataCollectionResult, refetch, stopPolling};
}
