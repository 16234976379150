import Sample1Option1 from './assets/Sample1/Sample1Option1.svg';
import Sample1Option2 from './assets/Sample1/Sample1Option2.svg';
import Sample1Option3 from './assets/Sample1/Sample1Option3.svg';
import Sample1Option4 from './assets/Sample1/Sample1Option4.svg';
import Sample1Option5 from './assets/Sample1/Sample1Option5.svg';
import Sample1Option6 from './assets/Sample1/Sample1Option6.svg';
import Sample1Pattern1 from './assets/Sample1/Sample1Pattern1.svg';
import Sample1Pattern2 from './assets/Sample1/Sample1Pattern2.svg';
import Sample1Pattern3 from './assets/Sample1/Sample1Pattern3.svg';
import Sample1Pattern4 from './assets/Sample1/Sample1Pattern4.svg';
import Sample1Pattern5 from './assets/Sample1/Sample1Pattern5.svg';
import Sample1Pattern6 from './assets/Sample1/Sample1Pattern6.svg';
import Sample1Pattern7 from './assets/Sample1/Sample1Pattern7.svg';
import Sample1Pattern8 from './assets/Sample1/Sample1Pattern8.svg';
import Sample2Option1 from './assets/Sample2/Sample2Option1.svg';
import Sample2Option2 from './assets/Sample2/Sample2Option2.svg';
import Sample2Option3 from './assets/Sample2/Sample2Option3.svg';
import Sample2Option4 from './assets/Sample2/Sample2Option4.svg';
import Sample2Option5 from './assets/Sample2/Sample2Option5.svg';
import Sample2Option6 from './assets/Sample2/Sample2Option6.svg';
import Sample2Pattern1 from './assets/Sample2/Sample2Pattern1.svg';
import Sample2Pattern2 from './assets/Sample2/Sample2Pattern2.svg';
import Sample2Pattern3 from './assets/Sample2/Sample2Pattern3.svg';
import Sample2Pattern4 from './assets/Sample2/Sample2Pattern4.svg';
import Sample2Pattern5 from './assets/Sample2/Sample2Pattern5.svg';
import Sample2Pattern6 from './assets/Sample2/Sample2Pattern6.svg';
import Sample2Pattern7 from './assets/Sample2/Sample2Pattern7.svg';
import Sample2Pattern8 from './assets/Sample2/Sample2Pattern8.svg';

const getGeneratedTimeStamp = () => {
    return new Date().toString();
};

export type SampleQuestion = {
    id: string;
    pattern1: string | null;
    pattern2: string | null;
    pattern3: string | null;
    pattern4: string | null;
    pattern5: string | null;
    pattern6: string | null;
    pattern7: string | null;
    pattern8: string | null;
    optionA: string | null;
    optionB: string | null;
    optionC: string | null;
    optionD: string | null;
    optionE: string | null;
    optionF: string | null;
    correctAnswer: string;
    answeredQuestions: number;
    totalQuestions: number;
    generated: string;
};

export const firstExampleQuestion: SampleQuestion = {
    id: 'sample-1',
    pattern1: Sample1Pattern1,
    pattern2: Sample1Pattern2,
    pattern3: Sample1Pattern3,
    pattern4: Sample1Pattern4,
    pattern5: Sample1Pattern5,
    pattern6: Sample1Pattern6,
    pattern7: Sample1Pattern7,
    pattern8: Sample1Pattern8,
    optionA: Sample1Option1,
    optionB: Sample1Option2,
    optionC: Sample1Option3,
    optionD: Sample1Option4,
    optionE: Sample1Option5,
    optionF: Sample1Option6,
    correctAnswer: 'D',
    answeredQuestions: 0,
    totalQuestions: 2,
    generated: getGeneratedTimeStamp()
};

export const secondExampleQuestion: SampleQuestion = {
    id: 'sample-2',
    pattern1: Sample2Pattern1,
    pattern2: Sample2Pattern2,
    pattern3: Sample2Pattern3,
    pattern4: Sample2Pattern4,
    pattern5: Sample2Pattern5,
    pattern6: Sample2Pattern6,
    pattern7: Sample2Pattern7,
    pattern8: Sample2Pattern8,
    optionA: Sample2Option1,
    optionB: Sample2Option2,
    optionC: Sample2Option3,
    optionD: Sample2Option4,
    optionE: Sample2Option5,
    optionF: Sample2Option6,
    correctAnswer: 'D',
    answeredQuestions: 1,
    totalQuestions: 2,
    generated: getGeneratedTimeStamp()
};
