import {zxcvbn, zxcvbnOptions} from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import {dictionary, translations} from '@zxcvbn-ts/language-en';
import {ChangeEvent, useCallback, useState} from 'react';

import {PasswordInfo} from './types';

const options = {
    translations,
    dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...dictionary
    },
    graphs: zxcvbnCommonPackage.adjacencyGraphs
};

export function usePasswordInputLogic(
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
    onPasswordStrengthChanged?: (strength: number) => void
) {
    const [passwordInfo, setPasswordInfo] = useState<PasswordInfo>({
        strength: -1,
        feedback: null
    });

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const password = e.target.value;
            zxcvbnOptions.setOptions(options);
            const z = zxcvbn(password);
            const strength = z.score;
            const feedback = z.feedback.suggestions?.[0] ?? '';

            onChange(e);

            if (onPasswordStrengthChanged && strength !== passwordInfo.strength) {
                onPasswordStrengthChanged(strength);
                setPasswordInfo({strength, feedback});
            }
        },
        [onChange, onPasswordStrengthChanged, passwordInfo.strength]
    );

    return {handleChange, passwordInfo};
}
