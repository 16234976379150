import {HELP_CENTER} from '@/constants/helpCenter';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import messages from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTestCompleted/components/LogicTestResultPlaceholder/messages';
import {LinkWrapper} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTestCompleted/components/LogicTestResultPlaceholder/styled';

export function LearnMoreLink() {
    const phrases = useExtractPhraseConstants(messages);
    return (
        <LinkWrapper
            href={HELP_CENTER.alvasLogicTest}
            target="_blank"
            rel="noopener noreferrer"
        >
            {phrases.linkText}
        </LinkWrapper>
    );
}
