const PROLIFIC_PID_REGEX = /{{0,2}%?(\w+)%?}{0,2}/;

export const parseProlificPid = (queryParam?: string | null) => {
    if (queryParam) {
        const match = queryParam.match(PROLIFIC_PID_REGEX);
        if (match?.length === 2) {
            return match[1];
        }
    }
    return null;
};
