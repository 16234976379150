import {Redirect} from 'react-router-dom';

import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {PersonalityTestReport} from '@/componentLibrary/blocks/testReports/PersonalityTest/PersonalityTestReport';
import {PageCenteredSpinner} from '@/componentLibrary/components/spinners/PageCenteredSpinner';
import {usePersonalityTestReportPageLogic} from '@/pages/PersonalityTestIrt/pages/PersonalityTestReportPage/logic';

type Props = {
    closeLink: string;
    personalityTestId: string;
    displayDemographicsForm: boolean;
    organizationId?: number;
    jobPositionId?: number;
};

export function PersonalityTestReportPage({
    closeLink,
    personalityTestId,
    organizationId,
    jobPositionId,
    displayDemographicsForm = false
}: Props) {
    const {
        loading,
        error,
        refetch,
        personalityTestResult,
        shouldDisplayDemographicsForm,
        demographicsFormRedirectUrl,
        goBack,
        phrases
    } = usePersonalityTestReportPageLogic({
        personalityTestId,
        organizationId,
        jobPositionId,
        closeLink,
        displayDemographicsForm
    });

    if (loading) {
        return <PageCenteredSpinner />;
    }

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    if (!loading && !personalityTestResult) {
        return <>{phrases.testResultNotFound}</>;
    }

    if (shouldDisplayDemographicsForm) {
        return <Redirect to={demographicsFormRedirectUrl} />;
    }

    return (
        <PersonalityTestReport
            personalityTestResult={personalityTestResult}
            close={goBack}
            jobPositionId={jobPositionId}
            organizationId={organizationId}
        />
    );
}
