import {
    DataSeries,
    ScatterplotChartProps
} from '@/componentLibrary/components/charts/ScatterPlotChart/types';
import {Plot} from '@/componentLibrary/components/charts/plotlyBuild';
import {
    ColorBaseGrey200,
    ColorBaseGrey600,
    ColorBgDanger,
    ColorBgSuccess,
    ColorBorderDanger,
    ColorBorderSuccess
} from '@/componentLibrary/tokens/variables';

export const ScatterPlotChart = ({
    xCorrectData,
    xIncorrectData,
    yCorrectData,
    yIncorrectData,
    standardScore,
    title,
    xAxisTitle,
    correctDataLabel,
    incorrectDataLabel,
    standardScoreLabel,
    valueLabel
}: ScatterplotChartProps) => {
    const data: DataSeries[] = [
        {
            x: xCorrectData,
            y: yCorrectData,
            mode: 'markers',
            type: 'scatter',
            marker: {
                color: ColorBgSuccess,
                size: 30,
                line: {
                    color: ColorBorderSuccess,
                    width: 2
                }
            },
            name: correctDataLabel,
            legendgroup: 'group',
            opacity: 0.7,
            hovertemplate: `${correctDataLabel}<extra>${valueLabel}: %{x}</extra>`
        },
        {
            x: xIncorrectData,
            y: yIncorrectData,
            mode: 'markers',
            type: 'scatter',
            marker: {
                color: ColorBgDanger,
                size: 30,
                line: {
                    color: ColorBorderDanger,
                    width: 2
                }
            },
            name: incorrectDataLabel,
            legendgroup: 'group',
            opacity: 0.7,
            hovertemplate: `${incorrectDataLabel}<extra>${valueLabel}: %{x}</extra>`
        }
    ];

    if (standardScore) {
        data.push({
            x: [standardScore],
            y: [11],
            type: 'bar',
            marker: {
                color: ColorBaseGrey200,
                size: 1,
                line: {
                    color: ColorBaseGrey600,
                    width: 1
                }
            },
            name: standardScoreLabel,
            opacity: 0.7,
            hovertemplate: `${standardScoreLabel}: ${standardScore}<extra></extra>`
        });
    }

    const layout = {
        title: title,
        xaxis: {
            title: xAxisTitle || '',
            range: [0, 11],
            fixedrange: true,
            showgrid: true,
            step: 1,
            zeroline: false,
            showline: true
        },
        yaxis: {
            title: '',
            range: [0, 11],
            fixedrange: true,
            showgrid: false,
            zeroline: false,
            showticklabels: false
        }
    };

    return (
        <Plot
            className="scatter-plot-chart"
            data-testid="scatter-plot-chart"
            style={{width: '100%'}}
            data={data}
            layout={layout}
            config={{displayModeBar: false}}
            useResizeHandler={false}
        />
    );
};
