import {defineMessages} from 'react-intl';

export default defineMessages({
    missingResults: {
        id: 'app.roleFit.information.resultsmissing'
    },
    VERY_HIGH: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResults.roleFitBadge.VERY_HIGH'
    },
    HIGH: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResults.roleFitBadge.HIGH'
    },
    MEDIUM: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResults.roleFitBadge.MEDIUM'
    },
    LOW: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResults.roleFitBadge.LOW'
    },
    VERY_LOW: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResults.roleFitBadge.VERY_LOW'
    },
    noData: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResults.roleFitBadge.noData'
    },
    inProgress: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResults.roleFitBadge.inProgress'
    }
});
