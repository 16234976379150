import {sum} from 'mathjs';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {ColorBaseBrown100} from '@/componentLibrary/tokens/customVariables';
import {roundValue} from '@/utils/misc';

import {FusionChart} from '../../../../../components/charts/FusionChart';
import {ChartHeader} from '../ChartHeader';
import {useCategory} from '../hooks';
import {Wrapper} from '../styled';
import {LogicTestReportChartProps} from '../types';
import {copyAndSortQuestions} from '../utils';
import {chartProps} from './constants';
import messages from './messages';

export function TotalTimeSpentGraph({
    questions,
    graphHeight = 340
}: LogicTestReportChartProps) {
    const intl = useIntl();
    const legend = intl.formatMessage(messages.totalTimeSpent);
    const totalTimeSpentInSeconds = sum(questions.map(item => item.secondsSpentOnQuestion));
    const totalTimeMinutesPart = Math.floor(totalTimeSpentInSeconds / 60);
    const totalTimeSecondsPart = roundValue(
        totalTimeSpentInSeconds - totalTimeMinutesPart * 60,
        0
    );
    const totalTimeText = intl.formatMessage(messages.totalTimeForTest, {
        totalTimeMinutesPart,
        totalTimeSecondsPart
    });

    const copyOfQuestions = copyAndSortQuestions(questions);

    const category = useCategory(copyOfQuestions);

    const totalTimeSpentData = useMemo(
        () =>
            copyOfQuestions.map((_question, index) => {
                const accumulatedSeconds = copyOfQuestions
                    .slice(0, index + 1)
                    .reduce((acc, item) => acc + item.secondsSpentOnQuestion, 0);
                return {
                    value: accumulatedSeconds / 60
                };
            }),
        [copyOfQuestions]
    );

    const dataSource = {
        chart: chartProps,
        categories: [{category: category}],
        dataset: [
            {
                seriesname: legend,
                color: ColorBaseBrown100,
                data: totalTimeSpentData
            }
        ]
    };

    return (
        <Wrapper>
            <ChartHeader title={legend} valueText={totalTimeText} />
            <FusionChart
                type="msspline"
                width="100%"
                height={graphHeight}
                dataFormat="JSON"
                dataSource={dataSource}
            />
        </Wrapper>
    );
}
