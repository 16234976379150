import {defineMessages} from 'react-intl';

export default defineMessages({
    allowRetakingTest: {
        id: 'app.assessment.candidatePage.candidateResults.allowRetakingTest'
    },
    title: {
        id: 'app.assessment.candidatePage.candidateResults.allowRetakingTest.confirmationModal.title.personalityTest'
    },
    descriptionPart1: {
        id: 'app.assessment.candidatePage.candidateResults.allowRetakingTest.confirmationModal.description.part1.personalityTest'
    },
    descriptionPart2: {
        id: 'app.assessment.candidatePage.candidateResults.allowRetakingTest.confirmationModal.description.part2.personalityTest'
    },
    inputLabel: {
        id: 'app.assessment.candidatePage.candidateResults.allowRetakingTest.confirmationModal.inputLabel'
    },
    actionLabel: {
        id: 'app.assessment.candidatePage.candidateResults.allowRetakingTest.confirmationModal.action'
    }
});
