import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import styled, {css} from 'styled-components';

export const transparentLanguageDropdownCss = css`
    @media only screen and (min-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        background-color: transparent;
        color: ${greyColorTokens.white000()};
        border-color: ${greyColorTokens.white000()};

        &:hover {
            color: ${greyColorTokens.grey600()};
            border-color: ${greyColorTokens.grey600()};

            &::placeholder {
                color: ${greyColorTokens.grey600()};
            }
        }

        &:focus {
            color: ${greyColorTokens.white000()};
            border-color: ${greyColorTokens.white000()};
            outline: none;

            &::placeholder {
                color: ${greyColorTokens.white000()};
            }
        }

        &::placeholder {
            color: ${greyColorTokens.white000()};
        }
    }
`;

type WrapperProps = {
    withLogo: boolean;
    marginBottom?: string | number;
};
export const Wrapper = styled.div<WrapperProps>`
    height: 48px;
    width: 100%;
    flex: 0 0;
    display: flex;
    align-items: center;
    ${({withLogo}) =>
        withLogo ? 'justify-content: space-between;' : 'justify-content: flex-end;'}
    background-color: transparent;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-bottom: ${({marginBottom}) => marginBottom || '20px'};
    }
`;
type AlvaLogoProps = {
    backgroundImage: string;
    width: string;
    clickable?: boolean;
};
export const AlvaLogo = styled.div<AlvaLogoProps>`
    height: 40px;
    width: ${({width}) => width};
    background: url("${({backgroundImage}) => backgroundImage}") center center no-repeat;
    cursor: ${({clickable}) => (clickable ? 'pointer' : 'default')};
`;
