import {FormattedMessage} from 'react-intl';

import {usePersonalityTestResult} from '@/api/personalityTests/usePersonalityTestResult';
import {usePersonalityTestV2} from '@/api/personalityTests/usePersonalityTestV2';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {CenteredContentWrapper} from '@/componentLibrary/components/modals/ModalEmpty';
import {Spinner} from '@/componentLibrary/components/spinners';
import {DisplaySmall} from '@/componentLibrary/components/typography/Display';
import {personalityTestTakingMessages} from '@/pages/PersonalityTestIrt/messages';

import {PersonalityResultPlaceholder} from './components/PersonalityResultPlaceholder';
import {PlaceholderWrapper, Wrapper} from './styled';
import {PersonalityTestCompletedProps} from './types';

export function PersonalityTestCompletedV2({
    goToTestResult,
    personalityTestId,
    organizationId
}: PersonalityTestCompletedProps) {
    const {loading, error, personalityTestV2, refetch} = usePersonalityTestV2(
        personalityTestId,
        false
    );
    const {
        loading: loadingTestResult,
        personalityTestResult,
        stopPolling
    } = usePersonalityTestResult({personalityTestId, pollInterval: 1000});

    if (loading) {
        return (
            <CenteredContentWrapper>
                <Spinner />
            </CenteredContentWrapper>
        );
    }

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    if (!personalityTestV2) {
        return (
            <Wrapper className="notranslate">
                <FormattedMessage {...personalityTestTakingMessages.testNotFoundTitle} />
            </Wrapper>
        );
    }

    return (
        <CenteredContentWrapper>
            <Wrapper className="notranslate">
                <DisplaySmall>
                    <FormattedMessage {...personalityTestTakingMessages.testIsCompleted} />
                </DisplaySmall>

                <PlaceholderWrapper>
                    <PersonalityResultPlaceholder
                        goToTestResult={goToTestResult}
                        onResultFound={stopPolling}
                        loading={loadingTestResult}
                        personalityTestResult={personalityTestResult}
                        organizationId={organizationId}
                    />
                </PlaceholderWrapper>
            </Wrapper>
        </CenteredContentWrapper>
    );
}
