import {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import {CreateUserDemographicsInput} from '@/api/types/__generated__/graphql';
import {useCreateUserDemographics} from '@/api/userDemographics/useCreateUserDemographics';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {userDemographicsMessages} from '@/pages/UserDemographics/messages';

export function useUserDemographicsFormLogic(
    redirect: () => void,
    onSubmitSuccess: () => void
) {
    const {user} = useLoggedInUser();
    const [language, setLanguage] = useState<string | null>(null);
    const [birthYear, setBirthYear] = useState<number | null>(null);
    const [gender, setGender] = useState<string | null>(null);
    const [employmentStatus, setEmploymentStatus] = useState<string | null>(null);
    const [levelOfEducation, setLevelOfEducation] = useState<string | null>(null);

    const inputsAreValid = useMemo(() => {
        return !!language && !!gender && !!employmentStatus && !!levelOfEducation;
    }, [language, gender, employmentStatus, levelOfEducation]);

    const inputData = useMemo(
        () => ({
            userId: user ? user.id : null,
            nativeLanguage: language,
            birthYear,
            gender,
            employmentStatus,
            levelOfEducation
        }),
        [user, language, birthYear, gender, employmentStatus, levelOfEducation]
    );

    const [submitForm, {loading}] = useSubmitDemographicsFormLogic(
        inputData as CreateUserDemographicsInput,
        redirect,
        onSubmitSuccess
    );

    return {
        language,
        setLanguage,
        birthYear,
        setBirthYear,
        gender,
        setGender,
        employmentStatus,
        setEmploymentStatus,
        levelOfEducation,
        setLevelOfEducation,
        inputsAreValid,
        submitForm,
        loading
    };
}

type UseSubmitDemographicsFormLogicReturn = [(e: React.FormEvent) => void, {loading: boolean}];

function useSubmitDemographicsFormLogic(
    data: CreateUserDemographicsInput,
    redirect: () => void,
    onSubmitSuccess: () => void
): UseSubmitDemographicsFormLogicReturn {
    const {formatMessage} = useIntl();
    const [doCreate, {loading}] = useCreateUserDemographics(data);
    const submit = useCallback(
        e => {
            e.preventDefault();
            doCreate()
                .then(() => {
                    toast({
                        type: 'success',
                        message: formatMessage(
                            userDemographicsMessages.submittedDemographicsForm
                        )
                    });
                    onSubmitSuccess ? onSubmitSuccess() : redirect();
                })
                .catch(error => {
                    console.error(error);
                    toast({
                        type: 'error',
                        message: formatMessage(
                            userDemographicsMessages.failedToSubmitDemographicsForm
                        )
                    });
                });
        },
        [doCreate, formatMessage, onSubmitSuccess, redirect]
    );
    return [submit, {loading}];
}
