import styled from 'styled-components';

import {
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    margin-bottom: ${SpacingSmall};
    max-width: 920px;

    @media only screen and (max-width: 1032px) {
        padding: ${SpacingMedium};
    }
`;

export const HeaderWrapper = styled.div`
    padding-bottom: ${SpacingMedium};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const ScoreWrapper = styled.div`
    margin-top: ${SpacingXsmall};
`;

export const IndicatorWrapper = styled.div`
    margin-top: ${SpacingLarge};
`;
