import {useEffect} from 'react';

import {useSsoAuthenticationLogic} from '@/pages/Login/hooks/useSsoAuthenticationLogic';
import {useSsoStateLogic} from '@/pages/Login/hooks/useSsoStateLogic';
import {useSsoAuthenticateLogic} from '@/pages/SignupV2/hooks/useSsoAuthenticateLogic';
import {SsoOperationType} from '@/services/auth/sso/constants';

export function useSsoHandler() {
    const {
        ssoError,
        ssoState,
        completeSsoState,
        idToken,
        stateString,
        loading,
        redirectOnSsoError
    } = useSsoStateLogic();
    const {loginWithSso, registerWithSso} = useSsoAuthenticationLogic(idToken, ssoState);
    const {authenticateWithSso} = useSsoAuthenticateLogic(ssoState, idToken);

    useEffect(() => {
        completeSsoState();
        if (loading) {
            return;
        }
        if (ssoError) {
            redirectOnSsoError();
        } else if (ssoState.operationType === SsoOperationType.LOGIN) {
            loginWithSso();
        } else if (ssoState.operationType === SsoOperationType.REGISTER) {
            registerWithSso();
        } else if (ssoState.operationType === SsoOperationType.AUTHENTICATE_AND_VALIDATE) {
            authenticateWithSso(stateString);
        }
    }, [
        completeSsoState,
        registerWithSso,
        authenticateWithSso,
        loginWithSso,
        ssoError,
        ssoState.operationType,
        stateString,
        loading,
        redirectOnSsoError
    ]);
}
