import {defineMessages} from 'react-intl';

export default defineMessages({
    pendingInvitationsTitle: {
        id: 'app.pages.user.chooseOrganization.pendingInvitationsTitle'
    },
    myApplicationsTitle: {
        id: 'app.pages.user.chooseOrganization.myApplicationsTitle'
    },
    noApplications: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResults.roleFitBadge.noData'
    }
});
