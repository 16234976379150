import {Link} from 'react-router-dom';

import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {useLogicalAbilityEstimate} from '@/api/logicTestIrt/useLogicalAbilityEstimate';
import {useUser} from '@/api/users/useUser';
import {InPageHeader} from '@/componentLibrary/components/InPageHeader';
import {SmallPrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {LOGIC_TEST_IRT_LINKS} from '@/pages/LogicTestIrt/links';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {sharedResultsLinks} from '@/pages/SharedResults/components/SharedResultsPage/links';
import {SharedResultsContext} from '@/pages/SharedResults/components/UserResults/hooks/SharedResultsContext';
import {useContext} from 'react';
import {RevokeSharedLogicTest} from './RevokeSharedLogicTest';
import messages from './messages';
import {OverviewHeaderProps} from './types';

export function OverviewHeader({
    redirectLink,
    logicTestId,
    disabled,
    jobPositionId
}: OverviewHeaderProps) {
    const phrases = useExtractPhraseConstants(messages);
    const isSharedResultsView = useContext(SharedResultsContext);

    const {jobPosition} = useJobPosition(jobPositionId);
    const {logicalAbilityEstimate} = useLogicalAbilityEstimate(logicTestId);
    const {user} = useUser(logicalAbilityEstimate?.userId);

    const baseUrl = !isSharedResultsView
        ? LOGIC_TEST_IRT_LINKS.report(logicTestId)
        : sharedResultsLinks.logicTestReport(isSharedResultsView, logicTestId);
    const link = `${baseUrl}?redirect=${redirectLink}`;

    const shouldShowFullTestResult = !isDefined(disabled);
    const shouldShowRevokeButton =
        isDefined(logicTestId) &&
        isDefined(jobPositionId) &&
        isDefined(user) &&
        !isSharedResultsView;

    const action = shouldShowFullTestResult ? (
        <Link to={link}>
            <SmallPrimaryButton>{phrases.seeFullTestResult}</SmallPrimaryButton>
        </Link>
    ) : null;

    const secondaryAction = shouldShowRevokeButton ? (
        <RevokeSharedLogicTest
            disabled={disabled}
            user={user}
            organizationId={jobPosition?.organizationId}
            logicTestId={logicTestId}
        />
    ) : null;

    return (
        <InPageHeader
            title={{content: phrases.title}}
            primaryAction={action}
            secondaryAction={secondaryAction}
        />
    );
}
