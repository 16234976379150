import {useCallback} from 'react';

import {
    GetInterviewRequestsForJobApplication_JobApplication_InterviewRequests_Employment as Employment,
    GetInterviewRequestsForJobApplication_JobApplication_InterviewRequest as InterviewRequest,
    GetInterviewRequestsForJobApplication_JobApplication_InterviewRequests_Employment_User as User
} from '@/api/assessment/types';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import {isDefined} from '@/utils/typeGuards/isDefined';

export function useInterviewDetailsLogic(
    interviewRequests: InterviewRequest[],
    onChange: () => void
) {
    const {modalIsOpen, openModal, closeModal} = useSimpleModalLogic();

    const onInterviewRequestsCreated = useCallback(() => {
        onChange();
        closeModal();
    }, [onChange, closeModal]);

    const getInterviewers = useCallback((): User[] => {
        return interviewRequests
            .map((interviewRequest: InterviewRequest) => interviewRequest.employment?.user)
            .filter(isDefined);
    }, [interviewRequests]);

    const getSelectedInterviewers = useCallback((): Employment[] => {
        return interviewRequests
            .map((interviewRequest: InterviewRequest) => interviewRequest.employment)
            .filter(isDefined);
    }, [interviewRequests]);

    return {
        modalIsOpen,
        openModal,
        closeModal,
        onInterviewRequestsCreated,
        getInterviewers,
        getSelectedInterviewers
    };
}
