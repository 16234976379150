import {gql} from '@/api/types/__generated__';

export const CREATE_CHALLENGE = gql(`
    mutation CreateChallenge($input: CreateChallengeInput!) {
        createChallenge(input: $input) {
            ok
            errorMessage
            challenge {
                ...ChallengeFields
            }
        }
    }
`);

export const UPDATE_CHALLENGE = gql(`
    mutation UpdateChallenge($challengeId: ID!, $input: UpdateChallengeInput!) {
        updateChallenge(challengeId: $challengeId, input: $input) {
            ok
            errorMessage
            challenge {
                ...ChallengeFields
                parentId
            }
        }
    }
`);

export const PUBLISH_CHALLENGE_ASSIGNMENT_DRAFT_SCORECARD_SCORES = gql(`
    mutation PublishChallengeAssignmentScorecardScores($assignmentId: ID!) {
        publishChallengeAssignmentScorecardScores(assignmentId: $assignmentId) {
            ok
            errorMessage
        }
    }
`);

export const PUBLISH_CHALLENGE_ASSIGNMENT_DRAFT_DECISION = gql(`
    mutation PublishChallengeAssignmentDecision($assignmentId: ID!) {
        publishChallengeAssignmentDecision(assignmentId: $assignmentId) {
            ok
            errorMessage
        }
    }
`);

export const REQUEST_CHALLENGE_ACCOUNT_ACCESS = gql(`
    mutation RequestChallengeAccountAccess(
        $challengeId: ID!
        $input: AuthorizationResultInput
    ) {
        requestChallengeAccountAccess(challengeId: $challengeId, input: $input) {
            ok
            errorMessage
            accountAccess {
                hasAccess
                url
                authenticationRedirectUrl
            }
        }
    }
`);

export const REQUEST_CHALLENGE_ASSIGNMENT_ACCOUNT_ACCESS = gql(`
    mutation RequestChallengeAssignmentAccountAccess(
        $assignmentId: ID!
        $input: AuthorizationResultInput
    ) {
        requestChallengeAssignmentAccountAccess(assignmentId: $assignmentId, input: $input) {
            ok
            errorMessage
            accountAccess {
                hasAccess
                url
                authenticationRedirectUrl
            }
        }
    }
`);

export const INITIALIZE_CHALLENGE_ASSIGNMENT = gql(`
    mutation InitializeChallengeAssignment(
        $assignmentId: ID!
        $input: InitializeChallengeAssignmentInput
    ) {
    initializeChallengeAssignment(assignmentId: $assignmentId, input: $input) {
            ok
            errorMessage
            repositoryUrl
        }
    }
`);

export const CHANGE_CHALLENGE_STATUS = gql(`
    mutation changeChallengeStatus($challengeId: ID!, $status: ChallengeStatus!) {
        changeChallengeStatus(challengeId: $challengeId, status: $status) {
            ok
            errorMessage
        }
    }
`);

export const CHANGE_CHALLENGE_STATUSES = gql(`
    mutation changeChallengeStatuses($challengeIds: [ID!]!, $status: ChallengeStatus!) {
        changeChallengeStatuses(challengeIds: $challengeIds, status: $status) {
            ok
            errorMessage
        }
    }
`);

export const SUBMIT_CHALLENGE_ASSIGNMENT = gql(`
    mutation SubmitChallengeAssignment($assignmentId: ID!) {
        submitChallengeAssignment(assignmentId: $assignmentId) {
         ok
            errorMessage
        }
    }
`);

export const SET_USE_OVERDUE_SCORE_FOR_CHALLENGE_ASSIGNMENT = gql(`
    mutation setUseOverdueScoreForChallengeAssignment(
        $assignmentId: ID!
        $useOverdueScore: Boolean!
    ) {
        setUseOverdueScoreForChallengeAssignment(
            assignmentId: $assignmentId
            useOverdueScore: $useOverdueScore
        ) {
            ok
            errorMessage
        }
    }
`);

export const SET_CHALLENGE_TIME_LIMIT = gql(`
    mutation setChallengeTimeLimit($input: ChallengeTimeLimitInput!) {
        setChallengeTimeLimit(input: $input) {
            ok
            errorMessage
        }
    }
`);

export const SYNC_CHALLENGE_SCORECARD = gql(`
    mutation syncChallengeScorecard($assignmentId: ID!) {
        syncChallengeScorecard(assignmentId: $assignmentId) {
            ok
            errorMessage
        }
    }
`);

export const SET_CHALLENGE_SPECIFICATIONS = gql(`
    mutation setChallengeSpecifications($input: ChallengeSpecificationsInput!) {
        setChallengeSpecifications(input: $input) {
            ok
            errorMessage
        }
    }
`);

export const IMPORT_CHALLENGE_ASSIGNMENT_BOILERPLATE = gql(`
    mutation importChallengeAssignmentBoilerplate($input: ImportChallengeAssignmentBoilerplateInput!) {
        importChallengeAssignmentBoilerplate(input: $input) {
            ok
            errorMessage
        }
    }
`);
