import {useBoilerplateImportDetails} from '@/api/assessment/codingTests/useBoilerplateImportDetails';
import {useCheckAssignmentAccountAccess} from '@/pages/Organization/pages/Assessment/components/CodingTests/hooks/useCheckAccess';
import {useEffect, useRef, useState} from 'react';
import {IMPORT_BOILERPLATE_STATUS} from '../../constants';
import {updateChallengeAssignmentCache} from '../../utils';
import {LoaderTypeProps, UseLogicProps} from './types';

export function useLogic({
    jobPositionId,
    userId,
    onComplete,
    pollingDuration = 3000
}: UseLogicProps) {
    const [inProgress, setInProgress] = useState<boolean>(false);
    const {checkAssignmentAccountAccess} = useCheckAssignmentAccountAccess();
    const [repositoryUrl, setRepositoryUrl] = useState<string | null>(null);

    const {importDetails, startPolling, stopPolling, challengeAssignmentId, client} =
        useBoilerplateImportDetails({
            jobPositionId,
            userId
        });

    useEffect(() => {
        startPolling(pollingDuration);
        setInProgress(true);
    }, [startPolling, pollingDuration]);

    useEffect(() => {
        (async () => {
            if (
                importDetails &&
                importDetails.status !== IMPORT_BOILERPLATE_STATUS.IN_PROGRESS
            ) {
                stopPolling();
                setInProgress(false);
                onComplete(importDetails);
                updateChallengeAssignmentCache({
                    client,
                    challengeAssignmentId,
                    updatedPayload: {
                        boilerplateImportDetails: importDetails
                    }
                });
            }

            if (
                importDetails?.status === IMPORT_BOILERPLATE_STATUS.SUCCESS &&
                challengeAssignmentId
            ) {
                const repositoryUrl =
                    await checkAssignmentAccountAccess(challengeAssignmentId);
                if (repositoryUrl) {
                    setRepositoryUrl(repositoryUrl);
                }
            }
        })();
    }, [
        importDetails,
        stopPolling,
        checkAssignmentAccountAccess,
        challengeAssignmentId,
        onComplete,
        client
    ]);

    useEffect(() => {
        return () => {
            stopPolling();
        };
    }, [stopPolling]);

    return {importDetails, inProgress, repositoryUrl};
}

export const useLoader = ({
    intervalDuration,
    incrementFactor,
    maxPercentage = 100
}: LoaderTypeProps) => {
    const [percentage, setPercentage] = useState(0);
    const intervalRef = useRef<ReturnType<typeof setInterval>>();

    useEffect(() => {
        const addJitter = (increment: number) => increment + (Math.random() - 0.5) * 0.4;

        const updatePercentage = () => {
            setPercentage(prev => {
                if (prev >= maxPercentage) {
                    intervalRef.current && clearInterval(intervalRef.current);
                    return maxPercentage;
                }

                const progress = prev / maxPercentage;
                const increment = Math.max(
                    (Math.sin(progress * Math.PI) * incrementFactor) / 10 + addJitter(0),
                    0.1
                );
                return Math.min(prev + increment, maxPercentage);
            });

            if (Math.random() < 0.1) {
                intervalRef.current && clearInterval(intervalRef.current);
                setTimeout(() => {
                    intervalRef.current = setInterval(updatePercentage, intervalDuration);
                }, Math.random() * 2000);
            }
        };

        intervalRef.current = setInterval(updatePercentage, intervalDuration);

        return () => intervalRef.current && clearInterval(intervalRef.current);
    }, [intervalDuration, incrementFactor, maxPercentage]);

    return {percentage};
};
