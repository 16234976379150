import {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {User} from '@/api/users/useUserValidationTest';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {Card} from '@/componentLibrary/components/cards/Card';

import {Link} from './components/Link';
import {SendInvitationMessage} from './components/SendInvitationMessage';
import {SendReminderMessage} from './components/SendReminderMessage';
import {TestAdministrationHeader} from './components/TestAdministrationHeader';
import {TestValidationModal} from './components/TestValidationModal';
import {useRequestValidationTestLogic} from './logic/useRequestValidationTestLogic';
import {useSendReminderLogic} from './logic/useSendReminderLogic';
import {useValidationTestModalLogic} from './logic/useValidationTestModalLogic';
import messages from './messages';

type Props = {
    user: User;
    organizationId: number;
    isLogicTestCompleted: boolean;
    isValidationTestRequested: boolean;
    isValidationTestCompleted: boolean;
};

export function TestAdministrationCard({
    user,
    organizationId,
    isLogicTestCompleted,
    isValidationTestCompleted,
    isValidationTestRequested
}: Props) {
    const {modalIsOpen, showValidationTestModal, hideValidationTestModal} =
        useValidationTestModalLogic();
    const {requestValidationTest, requestingValidation} = useRequestValidationTestLogic(
        organizationId,
        hideValidationTestModal,
        user?.id
    );
    const {sendReminder, sendingReminder, sendReminderIsDisabled} = useSendReminderLogic(
        organizationId,
        user?.id
    );

    function renderLink() {
        return <Link />;
    }

    const renderTestAdministrationHeader = useCallback(() => {
        return (
            <TestAdministrationHeader
                isValidationTestRequested={isValidationTestRequested}
                isValidationTestCompleted={isValidationTestCompleted}
            />
        );
    }, [isValidationTestCompleted, isValidationTestRequested]);

    const renderTestValidationModal = useCallback(() => {
        if (!modalIsOpen) {
            return null;
        }

        return (
            <TestValidationModal
                close={hideValidationTestModal}
                user={user}
                requestValidationTest={requestValidationTest}
                requestingValidation={requestingValidation}
            />
        );
    }, [
        hideValidationTestModal,
        modalIsOpen,
        user,
        requestValidationTest,
        requestingValidation
    ]);

    const renderValidationTestCompletedMessage = useCallback(
        () => (
            <Banner type={BannerType.SUCCESS}>
                <FormattedMessage {...messages.validationTestCompleted} />
            </Banner>
        ),
        []
    );

    const renderSendReminderMessage = useCallback(
        () => (
            <SendReminderMessage
                sendReminder={sendReminder}
                sendingReminder={sendingReminder}
                sendReminderIsDisabled={sendReminderIsDisabled}
            />
        ),
        [sendReminder, sendReminderIsDisabled, sendingReminder]
    );

    const renderSendInvitationMessage = useCallback(
        () => (
            <SendInvitationMessage
                open={showValidationTestModal}
                isLogicTestCompleted={isLogicTestCompleted}
                requestingValidation={requestingValidation}
            />
        ),
        [isLogicTestCompleted, requestingValidation, showValidationTestModal]
    );

    const renderCardContent = useCallback(() => {
        if (isValidationTestCompleted) {
            return renderValidationTestCompletedMessage();
        }

        if (isValidationTestRequested) {
            return renderSendReminderMessage();
        }

        return renderSendInvitationMessage();
    }, [
        isValidationTestCompleted,
        isValidationTestRequested,
        renderValidationTestCompletedMessage,
        renderSendInvitationMessage,
        renderSendReminderMessage
    ]);

    return (
        <Card expanded header={renderTestAdministrationHeader()}>
            <Description>
                <FormattedMessage
                    {...messages.validationTestDescription}
                    values={{link: renderLink()}}
                />
            </Description>
            {renderCardContent()}
            {renderTestValidationModal()}
        </Card>
    );
}

const Description = styled.div`
    max-width: 520px;
    margin-bottom: 20px;
`;
