import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {AuthenticateCandidateMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {AUTHENTICATE_CANDIDATE_MUTATION} from './mutations';

export function useAuthenticateCandidate(): MutationHookReturnType<
    AuthenticateCandidateMutation,
    [string | null]
> {
    const [mutate, {error, loading}] = useMutation(AUTHENTICATE_CANDIDATE_MUTATION);
    const validateToken = useCallback(
        token => {
            if (!token) {
                return Promise.reject(new Error('Token is required'));
            }
            return mutate({variables: {token}});
        },
        [mutate]
    );

    return [validateToken, {error, loading}];
}
