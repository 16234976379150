import {Route, Switch} from 'react-router-dom';

import {NotFound} from '../NotFound';
import {personalityDataCollectionLinks} from './links';
import {CompletedDataCollectionTest} from './pages/CompletedDataCollectionTest/index';
import {DataCollectionForm} from './pages/DataCollectionForm';

export function PersonalityDataCollection() {
    return (
        <Switch>
            <Route
                exact
                path={personalityDataCollectionLinks.form(':localeName')}
                render={() => <DataCollectionForm />}
            />
            <Route
                exact
                path={personalityDataCollectionLinks.completed(':personalityTestId')}
                render={({match}) =>
                    match.params.personalityTestId ? (
                        <CompletedDataCollectionTest
                            personalityTestId={match.params.personalityTestId}
                        />
                    ) : (
                        <NotFound />
                    )
                }
            />
            <Route path={'*'} render={() => <NotFound />} />
        </Switch>
    );
}
