export const DEBOUNCE_WAIT = 250;

export const itemsYearsOfExperience = [
    {id: '0', name: '0 years'},
    {id: '1', name: '1+ year'},
    {id: '2', name: '2+ years'},
    {id: '3', name: '3+ years'},
    {id: '4', name: '4+ years'},
    {id: '5', name: '5+ years'},
    {id: '6', name: '6+ years'},
    {id: '7', name: '7+ years'},
    {id: '8', name: '8+ years'},
    {id: '9', name: '9+ years'},
    {id: '10', name: '10+ years'}
];

export const INITIAL_ERRORS = {
    role: false
};

export const KEYWORD_TO_YEARS_OF_EXPERIENCE_MAP: {[key: string]: number} = {
    junior: 0,
    intern: 0,
    'entry-level': 0,
    'entry level': 0,
    senior: 6,
    lead: 6,
    professional: 6
} as const;

export const ARCHITECT_MODE = {
    EDIT_ROLE: 'EDIT_ROLE',
    CODING_CHALLENGE: 'CODING_CHALLENGE',
    ASSESSMENT: 'ASSESSMENT'
} as const;
