import {GetEmploymentsAndApplicationsQuery} from '@/api/types/__generated__/graphql';
import {DeepPick} from '@/types/generic.types';

type JobApplication = DeepPick<GetEmploymentsAndApplicationsQuery, 'me.jobApplications'>[0];

export const anyWithLeadershipReport = (jobApplications: JobApplication[]) => {
    const hasLeadershipProfileEnabled = (jobApplication: JobApplication) =>
        jobApplication.jobPosition?.leadershipReportEnabled;
    return jobApplications.some(hasLeadershipProfileEnabled);
};
