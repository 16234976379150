import styled from 'styled-components';

import {SpacingMedium} from '@/componentLibrary/tokens/variables';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: ${SpacingMedium};
`;

const ScoreWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export {Wrapper, ScoreWrapper};
