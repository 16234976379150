import moment from 'moment';
import {useCallback, useEffect, useRef, useState} from 'react';

import {ColorBaseBlue900, ColorBaseRed900} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    MessageContainer,
    MessageWrapper,
    TimeLeft,
    Wrapper
} from '@/pages/LogicTestIrt/components/Timer/styled';
import {TimerProps} from '@/pages/LogicTestIrt/components/Timer/types';

import {ProgressCircle} from './components/ProgressCircle';
import messages from './messages';

const SHOW_MESSAGE_THRESHOLD = 30;

export const Timer = ({
    limit,
    countdownLimit = 0,
    onTimeIsUp,
    onExtraTimeIsUp = () => null,
    disableExtraTime = false
}: TimerProps) => {
    const timeLimit = moment.duration(limit).asSeconds();
    const [questionStarted] = useState<number | null>(new Date().getTime());
    const [timeLeft, setTimeLeft] = useState<number>(timeLimit);
    const [extraTimeLeft, setExtraTimeLeft] = useState<number>(countdownLimit);
    const [timeIsUp, setTimeIsUp] = useState<boolean>(false);
    const requestIdRef = useRef<number>();
    const phrases = useExtractPhraseConstants(messages);

    const updateExtraTimeLeft = useCallback(() => {
        const now = new Date();
        const elapsedTime =
            (now.getTime() - ((questionStarted || 0) + timeLimit * 1000)) / 1000;
        const newExtraTimeLeft = countdownLimit - elapsedTime;

        if (newExtraTimeLeft >= 0) {
            setExtraTimeLeft(newExtraTimeLeft);
            requestIdRef.current = window.requestAnimationFrame(updateExtraTimeLeft);
        } else {
            onExtraTimeIsUp();
        }
    }, [countdownLimit, questionStarted, timeLimit, onExtraTimeIsUp]);

    const updateTimeLeft = useCallback(() => {
        const now = new Date();
        const elapsedTime = (now.getTime() - (questionStarted || 0)) / 1000;
        const newTimeLeft = timeLimit - elapsedTime;

        if (newTimeLeft >= 0) {
            setTimeLeft(newTimeLeft);
            requestIdRef.current = window.requestAnimationFrame(updateTimeLeft);
        } else {
            setTimeIsUp(true);
            onTimeIsUp();
            if (!disableExtraTime) {
                updateExtraTimeLeft();
            }
        }
    }, [questionStarted, disableExtraTime, onTimeIsUp, timeLimit, updateExtraTimeLeft]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        requestIdRef.current = window.requestAnimationFrame(updateTimeLeft);
        return () => {
            if (requestIdRef.current) {
                window.cancelAnimationFrame(requestIdRef.current);
            }
        };
    }, []);

    const showWarning = () => {
        return timeLeft <= SHOW_MESSAGE_THRESHOLD;
    };

    const formatTimeLeft = () => {
        if (extraTimeLeft <= 0) {
            return '0';
        }
        return Math.round(extraTimeLeft).toString();
    };

    const getColor = () => {
        return showWarning() ? ColorBaseRed900 : ColorBaseBlue900;
    };

    const renderMessage = () => {
        if (timeIsUp && disableExtraTime) {
            return <MessageWrapper>{phrases.timeIsUp}</MessageWrapper>;
        }

        if (timeIsUp) {
            return (
                <MessageWrapper>
                    {phrases.nextQuestionIn}
                    <TimeLeft>{formatTimeLeft()}</TimeLeft>
                </MessageWrapper>
            );
        }

        if (showWarning()) {
            return <MessageWrapper>{phrases.timeToSelectAnswer}</MessageWrapper>;
        }

        return null;
    };

    const percentageTimeLeft = 100 * (timeLeft / timeLimit);

    return (
        <Wrapper>
            <ProgressCircle percentage={100 - percentageTimeLeft} color={getColor()} />
            <MessageContainer>{renderMessage()}</MessageContainer>
        </Wrapper>
    );
};
