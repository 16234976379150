import {useQuery} from '@apollo/client';
import {useCallback, useMemo} from 'react';

import {GET_JOB_POSITIONS} from '@/api/assessment/jobPositions/queries';
import {
    FetchCursor,
    GetJobPositionsForOrganizationQueryVariables
} from '@/api/types/__generated__/graphql';

import {GetJobPositionsForOrganization_Organization_JobPositionsConnection} from '../types';

type UseJobPositionsOptions = {
    searchString?: string;
    status?: string;
    statuses?: string[];
    activationState?: string;
    hiringManagerId?: number;
    testProfileId?: string;
    orderBy?: string;
    isDescending?: boolean;
    limit?: number;
    withOrganizationRole?: boolean;
    withHiringManager?: boolean;
    withTeam?: boolean;
    withMetrics?: boolean;
};

export function getVariablesForGetJobPositions(
    organizationId: number,
    options: UseJobPositionsOptions = {},
    cursor?: FetchCursor
): GetJobPositionsForOrganizationQueryVariables {
    const {
        searchString = '',
        status = null,
        statuses = ['OPEN', 'UNLISTED'],
        activationState = null,
        hiringManagerId = null,
        testProfileId = null,
        orderBy = null,
        isDescending = null,
        limit = 20,
        withOrganizationRole = false,
        withHiringManager = false,
        withTeam = false,
        withMetrics = false
    } = options;

    return {
        id: organizationId,
        fetchOptions: {
            searchString,
            status,
            statuses,
            activationState,
            hiringManagerId,
            testProfileId,
            orderBy,
            isDescending,
            limit: limit,
            offset: cursor && cursor.next ? cursor.next : 0
        },
        withOrganizationRole,
        withHiringManager,
        withTeam,
        withMetrics
    };
}

export function useJobPositions(organizationId: number, options: UseJobPositionsOptions = {}) {
    const {error, networkStatus, data, refetch, fetchMore} = useQuery(GET_JOB_POSITIONS, {
        variables: getVariablesForGetJobPositions(organizationId, options),
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    const loadMore = useCallback(() => {
        if (!data?.organization) {
            return;
        }
        const {jobPositionsConnection} = data.organization;
        if (!jobPositionsConnection?.cursor?.hasMore) {
            return;
        }
        fetchMore({
            variables: getVariablesForGetJobPositions(
                organizationId,
                options,
                jobPositionsConnection.cursor
            )
        });
    }, [data, fetchMore, organizationId, options]);

    const jobPositionsConnection = useMemo(() => {
        if (!data?.organization?.jobPositionsConnection) {
            // Useful default values
            return {
                items: [] as GetJobPositionsForOrganization_Organization_JobPositionsConnection['items'],
                cursor: {},
                totalResults: null
            };
        }
        return {
            items: data.organization.jobPositionsConnection.items,
            cursor: data.organization.jobPositionsConnection.cursor,
            totalResults: data.organization.jobPositionsConnection.totalResults
        };
    }, [data]);

    const loading = networkStatus <= 4 && networkStatus !== 3;
    const loadingMore = networkStatus === 3;

    return {
        error,
        loading,
        jobPositions: jobPositionsConnection.items,
        cursor: jobPositionsConnection.cursor,
        totalResults: jobPositionsConnection.totalResults,
        refetch,
        loadMore,
        loadingMore
    };
}
