import styled from 'styled-components';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {StepperProps} from '@/componentLibrary/components/Stepper/types';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {TextFieldErrorMessage} from '@/componentLibrary/components/inputs/styled';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {Link} from '@/componentLibrary/components/links/Link';
import {BodyP2} from '@/componentLibrary/tokens/typography';
import {SpacingLarge, SpacingXsmall} from '@/componentLibrary/tokens/variables';

import {LINKEDIN_PLACEHOLDER_URL, LINKEDIN_URL} from './constants';
import {useSubmitResumeLogic} from './logic';

type Props = {
    onRightAction: () => void;
    onGiveFeedback: () => void;
    close: () => void;
    stepper?: StepperProps;
};

export function SubmitResume({stepper, onRightAction, onGiveFeedback, close}: Props) {
    const {
        titleText,
        continueText,
        label,
        linkLabel,
        giveFeedback,
        errorNoUrl,
        errorInvalidUrl,
        hasError,
        linkedinUrl,
        handleChange,
        handleContinue
    } = useSubmitResumeLogic(onRightAction);

    const primaryAction = (
        <PrimaryButton onClick={handleContinue}>{continueText}</PrimaryButton>
    );

    const ErrorMessage = hasError && (
        <TextFieldErrorMessage>
            {linkedinUrl?.length ? errorInvalidUrl : errorNoUrl}
        </TextFieldErrorMessage>
    );

    return (
        <Modal
            close={close}
            headerTitle={titleText}
            stepper={stepper}
            footer={{primaryAction}}
        >
            <Wrapper>
                <TextField
                    label={label}
                    type="url"
                    placeholder={LINKEDIN_PLACEHOLDER_URL}
                    isRequired
                    fullWidth
                    value={linkedinUrl}
                    onChange={handleChange}
                    hasError={hasError}
                />
                {ErrorMessage}
                <Links>
                    <ExternalLink url={LINKEDIN_URL}>{linkLabel}</ExternalLink>
                    <Link onClick={onGiveFeedback} notReactRouter>
                        {giveFeedback}
                    </Link>
                </Links>
            </Wrapper>
        </Modal>
    );
}

const Wrapper = styled.div`
    input {
        margin-bottom: ${SpacingXsmall};
    }

    a {
        ${BodyP2}
    }
`;

const Links = styled.div`
    display: flex;
    flex-direction: column;

    & > :first-child {
        margin-bottom: ${SpacingLarge};
    }
`;
