import {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';

import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {DisplayMedium, DisplaySmall} from '@/componentLibrary/components/typography/Display';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    BodyWrapper,
    ButtonsWrapper,
    DescriptionWrapper,
    Spinner,
    SpinnerWrapper,
    Wrapper
} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTestCompleted/components/LogicTestResultPlaceholder/styled';
import {LogicTestResultPlaceholderProps} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTestCompleted/components/LogicTestResultPlaceholder/types';
import {LearnMoreLink} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTestCompleted/components/LogicTestResultPlaceholder/utils';

import messages from './messages';

export function LogicTestResultPlaceholder({
    logicalAbilityEstimate,
    onLogicalAbilityEstimateFound,
    goBack,
    goToTestResult
}: LogicTestResultPlaceholderProps) {
    const phrases = useExtractPhraseConstants(messages);

    useEffect(() => {
        if (logicalAbilityEstimate) {
            onLogicalAbilityEstimateFound();
        }
    }, [logicalAbilityEstimate, onLogicalAbilityEstimateFound]);

    return (
        <Wrapper>
            {logicalAbilityEstimate ? (
                <>
                    <DisplayMedium>{phrases.completed}</DisplayMedium>
                    <BodyWrapper>
                        {phrases.completedDescription}
                        <br />
                        <FormattedMessage
                            {...messages.learnMore}
                            values={{link: <LearnMoreLink />}}
                        />
                    </BodyWrapper>
                    <ButtonsWrapper>
                        {goBack ? (
                            <SecondaryButton onClick={() => goBack()}>
                                {phrases.goBack}
                            </SecondaryButton>
                        ) : null}
                        <PrimaryButton onClick={() => goToTestResult()}>
                            {phrases.seeResults}
                        </PrimaryButton>
                    </ButtonsWrapper>
                </>
            ) : (
                <>
                    <SpinnerWrapper>
                        <Spinner />
                    </SpinnerWrapper>
                    <DisplaySmall>{phrases.processing}</DisplaySmall>
                    <DescriptionWrapper>{phrases.processingDescription}</DescriptionWrapper>
                </>
            )}
        </Wrapper>
    );
}
