import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {TextField} from '@/componentLibrary/components/inputs/TextField';

import messages from './messages';

export function SearchFilter({onFilterChange, placeholderMessage}) {
    const intl = useIntl();

    const onSearchStringChange = useCallback(
        e => {
            const {value} = e.target;
            onFilterChange(value.trim());
        },
        [onFilterChange]
    );

    const placeholder = intl.formatMessage(
        placeholderMessage ? placeholderMessage : messages.defaultPlaceholder
    );

    return (
        <Wrapper>
            <TextField type="text" placeholder={placeholder} onChange={onSearchStringChange} />
        </Wrapper>
    );
}

SearchFilter.propTypes = {
    onFilterChange: PropTypes.func,
    placeholderMessage: PropTypes.shape({
        id: PropTypes.string
    })
};

SearchFilter.defaultProps = {
    onFilterChange: () => undefined
};

const Wrapper = styled.div`
    padding: 8px 0;
`;
