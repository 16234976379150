import React from 'react';

import {GetMyTestResults_Me_PersonalityIrtResult} from '@/api/assessment/types';
import {Facet} from '@/api/types/__generated__/graphql';
import {
    FACET_MESSAGES,
    FACET_MESSAGES_TYPE
} from '@/componentLibrary/blocks/testReports/LeadershipReport/messages';
import {StandardTenChart} from '@/componentLibrary/components/charts/StandardTenChart';
import {Caption} from '@/componentLibrary/components/typography';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {capAndRoundStenValue} from '@/utils/misc';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {FacetTitle, FacetWrapper, Result, StenChartWrapper, Wrapper} from './styled';

type FacetChartsProps = {
    facets?: Facet[];
    personalityTestResults?: {
        personalityIrtResult?: GetMyTestResults_Me_PersonalityIrtResult;
    };
};

export const FacetCharts: React.FC<FacetChartsProps> = ({facets, personalityTestResults}) => {
    const phrases = useExtractPhraseConstants(FACET_MESSAGES);
    if (!personalityTestResults?.personalityIrtResult) {
        return null;
    }

    const facetsJsx = facets?.map((facet, index) => {
        if (!isDefined(facet)) {
            return null;
        }
        const selectedValue: number | undefined =
            personalityTestResults?.personalityIrtResult?.[
                facet.name.toLowerCase() as keyof (typeof personalityTestResults)['personalityIrtResult']
            ];
        if (!selectedValue) {
            return null;
        }
        return (
            <FacetWrapper key={index}>
                <FacetTitle>
                    {phrases[`${facet.name}.title` as FACET_MESSAGES_TYPE]}
                </FacetTitle>
                <Result>
                    <Caption color={ColorFgSubtle}>
                        {phrases[`${facet.name}.factor` as FACET_MESSAGES_TYPE]}
                    </Caption>
                    <StenChartWrapper>
                        <StandardTenChart
                            showBadge
                            selectedValue={capAndRoundStenValue(selectedValue as number)}
                        />
                    </StenChartWrapper>
                </Result>
            </FacetWrapper>
        );
    });

    return <Wrapper>{facetsJsx}</Wrapper>;
};
