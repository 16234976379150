import {BannerType} from '@/componentLibrary/components/banners/constants';
import {AuthenticationFormBanner} from '@/pages/common/authentication/AuthenticationFormBanner';
import {AuthenticationErrorPlaceholder} from '@/pages/common/authentication/styled';

import {Props} from './types';

export function ErrorDisplay({message}: Props) {
    if (!message) {
        return <AuthenticationErrorPlaceholder />;
    }

    return <AuthenticationFormBanner text={message} type={BannerType.ERROR} />;
}
