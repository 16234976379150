import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const TextFieldWrapper = styled.div`
    width: 500px;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        width: 100%;
    }
`;

export const LinkWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: ${SpacingXsmall};
`;
