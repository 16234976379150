import {P2} from '@/componentLibrary/components/typography';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {usePercentileText} from './hooks';

type PercentileTextProps = {
    standardScore: number;
};

export function PercentileText({standardScore}: PercentileTextProps): JSX.Element {
    const percentileText = usePercentileText(standardScore);
    return <P2 color={ColorFgSubtle}>{percentileText}</P2>;
}
