import {defineMessages} from 'react-intl';

export default defineMessages({
    aboutYouTitle: {
        id: 'app.pages.user.candidateRelevanceModal.resume.title'
    },
    linkedinProfileUrl: {
        id: 'app.jobRecommendations.handleResume.input.linkedIn.label'
    },
    getLinkedInURL: {
        id: 'app.jobRecommendations.handleResume.input.link.getLinkedInURL'
    },
    confirmRemoveExperienceTitle: {
        id: 'app.pages.user.confirmRemoveExperience.title'
    },
    confirmRemoveExperienceTitleWithJobTitle: {
        id: 'app.pages.user.confirmRemoveExperience.titleWithJobTitle'
    },
    sectionPreviousWork: {
        id: 'app.pages.user.candidateRelevanceModal.sectionPreviousWork'
    },
    previousWorkTitle: {
        id: 'app.pages.user.candidateRelevanceModal.previousWorkTitle'
    },
    jobTitle: {
        id: 'app.pages.user.candidateRelevanceModal.jobTitle'
    },
    jobFamily: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamily'
    },
    companyType: {
        id: 'app.pages.user.candidateRelevanceModal.companyType'
    },
    yearsOfExperience: {
        id: 'app.pages.user.candidateRelevanceModal.yearsOfExperience'
    },
    remove: {
        id: 'app.pages.user.candidateRelevanceModal.remove'
    },
    addAnotherPosition: {
        id: 'app.pages.user.candidateRelevanceModal.addAnotherPosition'
    },
    sectionNextWork: {
        id: 'app.pages.user.candidateRelevanceModal.sectionNextWork'
    },
    sectionJobPreferences: {
        id: 'app.pages.user.candidateRelevanceModal.sectionJobPreferences'
    },
    sectionLanguages: {
        id: 'app.pages.user.candidateRelevanceModal.sectionLanguages'
    },
    editButtonText: {
        id: 'app.pages.user.candidateRelevanceModal.editButtonText'
    },
    addButtonText: {
        id: 'app.pages.user.candidateRelevanceModal.addButtonText'
    },
    editCitiesButtonText: {
        id: 'app.pages.user.candidateRelevanceModal.editCitiesButtonText'
    },
    addCitiesButtonText: {
        id: 'app.pages.user.candidateRelevanceModal.addCitiesButtonText'
    },
    citiesModalTitle: {
        id: 'app.pages.user.candidateRelevanceModal.citiesModalTitle'
    },
    modalTitle: {
        id: 'app.pages.user.candidateRelevanceModal.modalTitle'
    },
    searchText: {
        id: 'app.general.search'
    },
    searchCitiesInfoText: {
        id: 'app.pages.user.candidateRelevanceModal.searchCitiesInfoText'
    },
    noMatchesText: {
        id: 'app.pages.user.candidateRelevanceModal.noMatchesText'
    },
    sectionLocation: {
        id: 'app.pages.user.candidateRelevanceModal.sectionLocation'
    },
    industriesTitle: {
        id: 'app.pages.user.candidateRelevanceModal.industriesTitle'
    },
    industriesSubtitle: {
        id: 'app.pages.user.candidateRelevanceModal.industriesSubtitle'
    },
    companyTypesTitle: {
        id: 'app.pages.user.candidateRelevanceModal.companyTypesTitle'
    },
    companyTypesSubtitle: {
        id: 'app.pages.user.candidateRelevanceModal.companyTypesSubtitle'
    },
    languagesTitle: {
        id: 'app.pages.user.candidateRelevanceModal.languagesTitle'
    },
    languagesSubtitle: {
        id: 'app.pages.user.candidateRelevanceModal.languagesSubtitle'
    },
    citiesTitle: {
        id: 'app.pages.user.candidateRelevanceModal.citiesTitle'
    },
    citiesSubtitle: {
        id: 'app.pages.user.candidateRelevanceModal.citiesSubtitle'
    },
    leadershipPositionsTitle: {
        id: 'app.pages.user.candidateRelevanceModal.leadershipPositionsTitle'
    },
    leadershipPositionsSubtitle: {
        id: 'app.pages.user.candidateRelevanceModal.leadershipPositionsSubtitle'
    },
    leadershipPositionsInterested: {
        id: 'app.pages.user.candidateRelevanceModal.leadershipPositionsInterested'
    },
    jobFamilyTitle: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilyTitle'
    },
    jobFamilySubtitle: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilySubtitle'
    },
    jobFamilyLabel: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilyLabel'
    },
    jobFamilyPlaceholder: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilyPlaceholder'
    },
    jobFamilyEmptyStateTitle: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilyEmptyStateTitle'
    },
    remoteWorkTitle: {
        id: 'app.pages.user.candidateRelevanceModal.remoteWorkTitle'
    },
    remoteWorkOpen: {
        id: 'app.pages.user.candidateRelevanceModal.remoteWorkOpen'
    },
    workLocationsTitle: {
        id: 'app.pages.user.candidateRelevanceModal.workLocationsTitle'
    },
    workLocationsSubTitle: {
        id: 'app.general.multipleOptionsDescription'
    },
    errors_JOB_FAMILY: {id: 'app.pages.user.candidateRelevanceModal.errors.JOB_FAMILY'},
    errors_INDUSTRIES: {id: 'app.pages.user.candidateRelevanceModal.errors.INDUSTRIES'},
    errors_COMPANY_TYPES: {id: 'app.pages.user.candidateRelevanceModal.errors.COMPANY_TYPES'},
    errors_LANGUAGES: {id: 'app.pages.user.candidateRelevanceModal.errors.LANGUAGES'},
    errors_CITIES: {id: 'app.pages.user.candidateRelevanceModal.errors.CITIES'},
    errors_WORK_LOCATIONS: {
        id: 'app.pages.user.candidateRelevanceModal.errors.WORK_LOCATIONS'
    },
    errors_EXPERIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.errors.EXPERIENCE'
    },
    companyTypes_START_UP_EARLY_STAGE: {
        id: 'app.pages.user.candidateRelevanceModal.companyTypes.START_UP_EARLY_STAGE'
    },
    companyTypes_SCALE_UP_IN_GROWTH_PHASE: {
        id: 'app.pages.user.candidateRelevanceModal.companyTypes.SCALE_UP_IN_GROWTH_PHASE'
    },
    companyTypes_ENTERPRISE: {
        id: 'app.pages.user.candidateRelevanceModal.companyTypes.ENTERPRISE'
    },
    companyTypes_AGENCY: {id: 'app.pages.user.candidateRelevanceModal.companyTypes.AGENCY'},
    companyTypes_CONSULTANCY: {
        id: 'app.pages.user.candidateRelevanceModal.companyTypes.CONSULTANCY'
    },
    industries_BANKING_AND_INSURANCE: {
        id: 'app.pages.user.candidateRelevanceModal.industries.BANKING_AND_INSURANCE'
    },
    industries_CONSTRUCTION_AND_REAL_ESTATE: {
        id: 'app.pages.user.candidateRelevanceModal.industries.CONSTRUCTION_AND_REAL_ESTATE'
    },
    industries_CONSULTING_AND_PROFESSIONAL_SERVICES: {
        id: 'app.pages.user.candidateRelevanceModal.industries.CONSULTING_AND_PROFESSIONAL_SERVICES'
    },
    industries_CONSUMER_PRODUCTS_AND_RETAIL: {
        id: 'app.pages.user.candidateRelevanceModal.industries.CONSUMER_PRODUCTS_AND_RETAIL'
    },
    industries_E_COMMERCE: {
        id: 'app.pages.user.candidateRelevanceModal.industries.E_COMMERCE'
    },
    industries_EDUCATION: {id: 'app.pages.user.candidateRelevanceModal.industries.EDUCATION'},
    industries_ENERGY: {id: 'app.pages.user.candidateRelevanceModal.industries.ENERGY'},
    industries_HEALTHCARE_AND_LIFE_SCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.industries.HEALTHCARE_AND_LIFE_SCIENCE'
    },
    industries_HOSPITALITY_FOOD_AND_TRAVEL: {
        id: 'app.pages.user.candidateRelevanceModal.industries.HOSPITALITY_FOOD_AND_TRAVEL'
    },
    industries_INDUSTRY_AND_MANUFACTURING: {
        id: 'app.pages.user.candidateRelevanceModal.industries.INDUSTRY_AND_MANUFACTURING'
    },
    industries_MEDIA_PR_AND_ADVERTISING: {
        id: 'app.pages.user.candidateRelevanceModal.industries.MEDIA_PR_AND_ADVERTISING'
    },
    industries_NON_PROFIT_SECTOR: {
        id: 'app.pages.user.candidateRelevanceModal.industries.NON_PROFIT_SECTOR'
    },
    industries_PUBLIC_SECTOR: {
        id: 'app.pages.user.candidateRelevanceModal.industries.PUBLIC_SECTOR'
    },
    industries_SERVICE_SECTOR: {
        id: 'app.pages.user.candidateRelevanceModal.industries.SERVICE_SECTOR'
    },
    industries_TECH_SECTOR: {
        id: 'app.pages.user.candidateRelevanceModal.industries.TECH_SECTOR'
    },
    industries_TELECOM: {id: 'app.pages.user.candidateRelevanceModal.industries.TELECOM'},
    industries_TRANSPORTATION_AND_LOGISTICS: {
        id: 'app.pages.user.candidateRelevanceModal.industries.TRANSPORTATION_AND_LOGISTICS'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING'
    },
    jobFamilies_DESIGN_AND_CREATIVE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE'
    },
    jobFamilies_FINANCE_AND_INSURANCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE'
    },
    jobFamilies_HR_AND_RECRUITMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT'
    },
    jobFamilies_MARKETING_AND_PR: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS'
    },
    jobFamilies_SOFTWARE_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING'
    },
    jobFamilies_IT_SERVICES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES'
    },
    jobFamilies_EDUCATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT'
    },
    jobFamilies_HEALTHCARE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE'
    },
    jobFamilies_LEGAL: {id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL'},
    jobFamilies_LIFE_SCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE'
    },
    jobFamilies_SOCIAL_SCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT'
    },
    jobFamilies_CUSTOMER_SERVICE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE'
    },
    jobFamilies_SALES: {id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES'},
    jobFamilies_TRANSPORTATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.jobFamilies_INSTALLATION_MAINTENANCE_REPAIR'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_AEROSPACE_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.AEROSPACE_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_ARCHITECTURE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.ARCHITECTURE'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_AUTOMOTIVE_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.AUTOMOTIVE_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_CHEMICAL_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.CHEMICAL_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_COMPUTER_HARDWARE_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.COMPUTER_HARDWARE_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_ELECTRICAL_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.ELECTRICAL_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_ENERGY_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.ENERGY_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_ENVIRONMENTAL_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.ENVIRONMENTAL_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_INDUSTRIAL_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.INDUSTRIAL_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_LANDSCAPE_ARCHITECTURE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.LANDSCAPE_ARCHITECTURE'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_MECHANICAL_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.MECHANICAL_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_ROBOTICS_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.ROBOTICS_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_TRANSPORTATION_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.TRANSPORTATION_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_VALIDATION_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.VALIDATION_ENGINEERING'
    },
    jobFamilies_ARCHITECTURE_AND_ENGINEERING_WATER_AND_WIND_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.ARCHITECTURE_AND_ENGINEERING.WATER_AND_WIND_ENGINEERING'
    },
    jobFamilies_DESIGN_AND_CREATIVE_ARTWORK: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.ARTWORK'
    },
    jobFamilies_DESIGN_AND_CREATIVE_COPYWRITING_AND_CREATIVE_WRITING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.COPYWRITING_AND_CREATIVE_WRITING'
    },
    jobFamilies_DESIGN_AND_CREATIVE_INDUSTRIAL_DESIGN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.INDUSTRIAL_DESIGN'
    },
    jobFamilies_DESIGN_AND_CREATIVE_EDITING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.EDITING'
    },
    jobFamilies_DESIGN_AND_CREATIVE_FASHION_DESIGN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.FASHION_DESIGN'
    },
    jobFamilies_DESIGN_AND_CREATIVE_FILM_AND_VIDEO_DIRECTING_AND_EDITING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.FILM_AND_VIDEO_DIRECTING_AND_EDITING'
    },
    jobFamilies_DESIGN_AND_CREATIVE_GRAPHIC_DESIGN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.GRAPHIC_DESIGN'
    },
    jobFamilies_DESIGN_AND_CREATIVE_ILLUSTRATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.ILLUSTRATION'
    },
    jobFamilies_DESIGN_AND_CREATIVE_MEDIA_PRODUCTION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.MEDIA_PRODUCTION'
    },
    jobFamilies_DESIGN_AND_CREATIVE_PHOTOGRAPHY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.PHOTOGRAPHY'
    },
    jobFamilies_DESIGN_AND_CREATIVE_PRODUCT_DESIGN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.PRODUCT_DESIGN'
    },
    jobFamilies_DESIGN_AND_CREATIVE_UX_DESIGN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.UX_DESIGN'
    },
    jobFamilies_DESIGN_AND_CREATIVE_VISUAL_DESIGN: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.DESIGN_AND_CREATIVE.VISUAL_DESIGN'
    },
    jobFamilies_FINANCE_AND_INSURANCE_ACCOUNTING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.ACCOUNTING'
    },
    jobFamilies_FINANCE_AND_INSURANCE_AUDITING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.AUDITING'
    },
    jobFamilies_FINANCE_AND_INSURANCE_APPRAISAL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.APPRAISAL'
    },
    jobFamilies_FINANCE_AND_INSURANCE_BUDGET_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.BUDGET_ANALYSIS'
    },
    jobFamilies_FINANCE_AND_INSURANCE_COMPLIANCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.COMPLIANCE'
    },
    jobFamilies_FINANCE_AND_INSURANCE_CONTROLLING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.CONTROLLING'
    },
    jobFamilies_FINANCE_AND_INSURANCE_CREDIT_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.CREDIT_ANALYSIS'
    },
    jobFamilies_FINANCE_AND_INSURANCE_FINANCIAL_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.FINANCIAL_ANALYSIS'
    },
    jobFamilies_FINANCE_AND_INSURANCE_FINANCIAL_ADVISING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.FINANCIAL_ADVISING'
    },
    jobFamilies_FINANCE_AND_INSURANCE_FINANCIAL_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.FINANCIAL_MANAGEMENT'
    },
    jobFamilies_FINANCE_AND_INSURANCE_INVESTMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.INVESTMENT'
    },
    jobFamilies_FINANCE_AND_INSURANCE_TAXATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.FINANCE_AND_INSURANCE.TAXATION'
    },
    jobFamilies_HR_AND_RECRUITMENT_COMPENSATION_AND_BENEFITS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.COMPENSATION_AND_BENEFITS'
    },
    jobFamilies_HR_AND_RECRUITMENT_EMPLOYER_BRANDING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.EMPLOYER_BRANDING'
    },
    jobFamilies_HR_AND_RECRUITMENT_HR_BUSINESS_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.HR_BUSINESS_SUPPORT'
    },
    jobFamilies_HR_AND_RECRUITMENT_HR_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.HR_MANAGEMENT'
    },
    jobFamilies_HR_AND_RECRUITMENT_LABOR_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.LABOR_LAW'
    },
    jobFamilies_HR_AND_RECRUITMENT_LEADERSHIP_DEVELOPMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.LEADERSHIP_DEVELOPMENT'
    },
    jobFamilies_HR_AND_RECRUITMENT_LEARNING_AND_DEVELOPMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.LEARNING_AND_DEVELOPMENT'
    },
    jobFamilies_HR_AND_RECRUITMENT_ONBOARDING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.ONBOARDING'
    },
    jobFamilies_HR_AND_RECRUITMENT_ORGANIZATIONAL_DEVELOPMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.ORGANIZATIONAL_DEVELOPMENT'
    },
    jobFamilies_HR_AND_RECRUITMENT_PERFORMANCE_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.PERFORMANCE_MANAGEMENT'
    },
    jobFamilies_HR_AND_RECRUITMENT_TALENT_ACQUISITION_AND_RECRUITMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.TALENT_ACQUISITION_AND_RECRUITMENT'
    },
    jobFamilies_HR_AND_RECRUITMENT_TALENT_AND_SUCCESSION_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HR_AND_RECRUITMENT.TALENT_AND_SUCCESSION_MANAGEMENT'
    },
    jobFamilies_MARKETING_AND_PR_CONTENT_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.CONTENT_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_DIRECT_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.DIRECT_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_EVENT_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.EVENT_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_INBOUND_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.INBOUND_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_INFLUENCER_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.INFLUENCER_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_MARKET_RESEARCH: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.MARKET_RESEARCH'
    },
    jobFamilies_MARKETING_AND_PR_MARKETING_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.MARKETING_MANAGEMENT'
    },
    jobFamilies_MARKETING_AND_PR_MEDIA_RELATIONS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.MEDIA_RELATIONS'
    },
    jobFamilies_MARKETING_AND_PR_OUTBOUND_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.OUTBOUND_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_PRODUCT_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.PRODUCT_MARKETING'
    },
    jobFamilies_MARKETING_AND_PR_PUBLIC_RELATIONS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.PUBLIC_RELATIONS'
    },
    jobFamilies_MARKETING_AND_PR_SEM_AND_SEO: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.SEM_AND_SEO'
    },
    jobFamilies_MARKETING_AND_PR_SOCIAL_MEDIA_MARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.MARKETING_AND_PR.SOCIAL_MEDIA_MARKETING'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_BUYING_AND_PURCHASING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.BUYING_AND_PURCHASING'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_DEMAND_PLANNING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.DEMAND_PLANNING'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_DISTRIBUTION_SPECIALIST: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.DISTRIBUTION_SPECIALIST'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_EXPORT_AND_IMPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.EXPORT_AND_IMPORT'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_INVENTORY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.INVENTORY'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_LOGISTICS_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.LOGISTICS_ANALYSIS'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_LOGISTICS_COORDINATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.LOGISTICS_COORDINATION'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_PROCUREMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.PROCUREMENT'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_QUALITY_ASSURANCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.QUALITY_ASSURANCE'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_ROUTING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.ROUTING'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_SOURCING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.SOURCING'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_SUPPLY_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.SUPPLY_MANAGEMENT'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_TRANSPORTATION_PLANNING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.TRANSPORTATION_PLANNING'
    },
    jobFamilies_LOGISTICS_AND_SUPPLY_CHAIN_WAREHOUSING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LOGISTICS_AND_SUPPLY_CHAIN.WAREHOUSING'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_BUSINESS_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.BUSINESS_ANALYSIS'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_BUSINESS_INTELLIGENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.BUSINESS_INTELLIGENCE'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_DATA_SCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.DATA_SCIENCE'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_MARKET_DATA_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.MARKET_DATA_ANALYSIS'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_OPERATIONS_DATA_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.OPERATIONS_DATA_ANALYSIS'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_PEOPLE_ANALYTICS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.PEOPLE_ANALYTICS'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_STATISTICAL_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.STATISTICAL_ANALYSIS'
    },
    jobFamilies_BUSINESS_INTELLIGENCE_AND_ANALYTICS_SYSTEM_ARCHITECTURE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.BUSINESS_INTELLIGENCE_AND_ANALYTICS.SYSTEM_ARCHITECTURE'
    },
    jobFamilies_SOFTWARE_ENGINEERING_APP_DEVELOPMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.APP_DEVELOPMENT'
    },
    jobFamilies_SOFTWARE_ENGINEERING_BACKEND_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.BACKEND_ENGINEERING'
    },
    jobFamilies_SOFTWARE_ENGINEERING_CLOUD_SOLUTIONS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.CLOUD_SOLUTIONS'
    },
    jobFamilies_SOFTWARE_ENGINEERING_COMPUTER_NETWORKS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.COMPUTER_NETWORKS'
    },
    jobFamilies_SOFTWARE_ENGINEERING_DATA_WAREHOUSING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.DATA_WAREHOUSING'
    },
    jobFamilies_SOFTWARE_ENGINEERING_DATABASE_ARCHITECTURE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.DATABASE_ARCHITECTURE'
    },
    jobFamilies_SOFTWARE_ENGINEERING_DEV_OPS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.DEV_OPS'
    },
    jobFamilies_SOFTWARE_ENGINEERING_DISTRIBUTED_SYSTEMS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.DISTRIBUTED_SYSTEMS'
    },
    jobFamilies_SOFTWARE_ENGINEERING_ENGINEERING_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.ENGINEERING_MANAGEMENT'
    },
    jobFamilies_SOFTWARE_ENGINEERING_FRONTEND_ENGINEERING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.FRONTEND_ENGINEERING'
    },
    jobFamilies_SOFTWARE_ENGINEERING_FULL_STACK_DEVELOPMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.FULL_STACK_DEVELOPMENT'
    },
    jobFamilies_SOFTWARE_ENGINEERING_MACHINE_LEARNING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.MACHINE_LEARNING'
    },
    jobFamilies_SOFTWARE_ENGINEERING_MOBILE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.MOBILE'
    },
    jobFamilies_SOFTWARE_ENGINEERING_SCRUM_AND_AGILE_METHODS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.SCRUM_AND_AGILE_METHODS'
    },
    jobFamilies_SOFTWARE_ENGINEERING_SYSTEM_ARCHITECTURE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.SYSTEM_ARCHITECTURE'
    },
    jobFamilies_SOFTWARE_ENGINEERING_TESTING_AND_QUALITY_ASSURANCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOFTWARE_ENGINEERING.TESTING_AND_QUALITY_ASSURANCE'
    },
    jobFamilies_IT_SERVICES_COMPUTER_SYSTEMS_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.COMPUTER_SYSTEMS_MANAGEMENT'
    },
    jobFamilies_IT_SERVICES_DATA_PRIVACY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.DATA_PRIVACY'
    },
    jobFamilies_IT_SERVICES_INFORMATION_SECURITY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.INFORMATION_SECURITY'
    },
    jobFamilies_IT_SERVICES_IT_COORDINATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.IT_COORDINATION'
    },
    jobFamilies_IT_SERVICES_IT_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.IT_MANAGEMENT'
    },
    jobFamilies_IT_SERVICES_IT_PROJECT_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.IT_PROJECT_MANAGEMENT'
    },
    jobFamilies_IT_SERVICES_IT_SECURITY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.IT_SECURITY'
    },
    jobFamilies_IT_SERVICES_IT_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.IT_SUPPORT'
    },
    jobFamilies_IT_SERVICES_NETWORK_ADMINISTRATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.NETWORK_ADMINISTRATION'
    },
    jobFamilies_IT_SERVICES_SYSTEM_ADMINISTRATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.SYSTEM_ADMINISTRATION'
    },
    jobFamilies_IT_SERVICES_SYSTEM_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.SYSTEM_ANALYSIS'
    },
    jobFamilies_IT_SERVICES_SERVICE_DESK_ANALYSIS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.IT_SERVICES.SERVICE_DESK_ANALYSIS'
    },
    jobFamilies_EDUCATION_CAREER_COUNSELING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.CAREER_COUNSELING'
    },
    jobFamilies_EDUCATION_ED_TECH: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.ED_TECH'
    },
    jobFamilies_EDUCATION_EDUCATION_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.EDUCATION_MANAGEMENT'
    },
    jobFamilies_EDUCATION_LIBRARY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.LIBRARY'
    },
    jobFamilies_EDUCATION_TEACHING_PRIMARY_SCHOOL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.TEACHING_PRIMARY_SCHOOL'
    },
    jobFamilies_EDUCATION_TEACHING_SECONDARY_SCHOOL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.TEACHING_SECONDARY_SCHOOL'
    },
    jobFamilies_EDUCATION_TEACHING_UPPER_SECONDARY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.TEACHING_UPPER_SECONDARY'
    },
    jobFamilies_EDUCATION_TEACHING_UNIVERSITY_LEVEL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.EDUCATION.TEACHING_UNIVERSITY_LEVEL'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_BAR: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.BAR'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_COOKING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.COOKING'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_FOOD_PREPARATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.FOOD_PREPARATION'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_HOSTING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.HOSTING'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_HOTEL_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.HOTEL_MANAGEMENT'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_RESTAURANT_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.RESTAURANT_MANAGEMENT'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_TRAVEL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.TRAVEL'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_WAITING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.WAITING'
    },
    jobFamilies_HOSPITALITY_AND_RESTAURANT_WINE_AND_BEVERAGES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HOSPITALITY_AND_RESTAURANT.WINE_AND_BEVERAGES'
    },
    jobFamilies_HEALTHCARE_DENTISTRY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.DENTISTRY'
    },
    jobFamilies_HEALTHCARE_E_HEALTH: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.E_HEALTH'
    },
    jobFamilies_HEALTHCARE_HEALTHCARE_ADMIN_AND_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.HEALTHCARE_ADMIN_AND_SUPPORT'
    },
    jobFamilies_HEALTHCARE_HEALTHCARE_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.HEALTHCARE_MANAGEMENT'
    },
    jobFamilies_HEALTHCARE_MEDICINE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.MEDICINE'
    },
    jobFamilies_HEALTHCARE_MIDWIFERY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.MIDWIFERY'
    },
    jobFamilies_HEALTHCARE_NURSING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.NURSING'
    },
    jobFamilies_HEALTHCARE_OCCUPATIONAL_THERAPY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.OCCUPATIONAL_THERAPY'
    },
    jobFamilies_HEALTHCARE_OPTICS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.OPTICS'
    },
    jobFamilies_HEALTHCARE_PHARMACOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.PHARMACOLOGY'
    },
    jobFamilies_HEALTHCARE_PSYCHOLOGY_AND_PSYCHOTHERAPY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.PSYCHOLOGY_AND_PSYCHOTHERAPY'
    },
    jobFamilies_HEALTHCARE_PSYCHIATRY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.HEALTHCARE.PSYCHIATRY'
    },
    jobFamilies_LEGAL_ARBITRATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.ARBITRATION'
    },
    jobFamilies_LEGAL_BUSINESS_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.BUSINESS_LAW'
    },
    jobFamilies_LEGAL_ENVIRONMENTAL_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.ENVIRONMENTAL_LAW'
    },
    jobFamilies_LEGAL_FAMILY_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.FAMILY_LAW'
    },
    jobFamilies_LEGAL_INTELLECTUAL_PROPERTY_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.INTELLECTUAL_PROPERTY_LAW'
    },
    jobFamilies_LEGAL_IT_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.IT_LAW'
    },
    jobFamilies_LEGAL_JUDICIARY_SYSTEM: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.JUDICIARY_SYSTEM'
    },
    jobFamilies_LEGAL_LABOR_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.LABOR_LAW'
    },
    jobFamilies_LEGAL_LAW_ADMINISTRATION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.LAW_ADMINISTRATION'
    },
    jobFamilies_LEGAL_PATENT_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.PATENT_LAW'
    },
    jobFamilies_LEGAL_PUBLIC_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.PUBLIC_LAW'
    },
    jobFamilies_LEGAL_TAXATION_LAW: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LEGAL.TAXATION_LAW'
    },
    jobFamilies_LIFE_SCIENCE_BIOCHEMISTRY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.BIOCHEMISTRY'
    },
    jobFamilies_LIFE_SCIENCE_BIOTECHNOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.BIOTECHNOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_BIOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.BIOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_CHEMICAL_BIOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.CHEMICAL_BIOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_CHEMICAL_TECHNOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.CHEMICAL_TECHNOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_CHEMISTRY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.CHEMISTRY'
    },
    jobFamilies_LIFE_SCIENCE_ENVIRONMENTAL_SCIENCES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.ENVIRONMENTAL_SCIENCES'
    },
    jobFamilies_LIFE_SCIENCE_GENETICS_AND_MOLECULAR_BIOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.GENETICS_AND_MOLECULAR_BIOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_MEDICAL_SCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.MEDICAL_SCIENCE'
    },
    jobFamilies_LIFE_SCIENCE_MEDICAL_TECHNOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.MEDICAL_TECHNOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_MICROBIOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.MICROBIOLOGY'
    },
    jobFamilies_LIFE_SCIENCE_NEUROSCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.NEUROSCIENCE'
    },
    jobFamilies_LIFE_SCIENCE_PATHOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.LIFE_SCIENCE.PATHOLOGY'
    },
    jobFamilies_SOCIAL_SCIENCE_ANTHROPOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.ANTHROPOLOGY'
    },
    jobFamilies_SOCIAL_SCIENCE_ARCHAEOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.ARCHAEOLOGY'
    },
    jobFamilies_SOCIAL_SCIENCE_ECONOMICS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.ECONOMICS'
    },
    jobFamilies_SOCIAL_SCIENCE_FORENSIC_SCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.FORENSIC_SCIENCE'
    },
    jobFamilies_SOCIAL_SCIENCE_GEOSCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.GEOSCIENCE'
    },
    jobFamilies_SOCIAL_SCIENCE_HISTORY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.HISTORY'
    },
    jobFamilies_SOCIAL_SCIENCE_INDUSTRIAL_ORGANIZATIONAL_PSYCHOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.INDUSTRIAL_ORGANIZATIONAL_PSYCHOLOGY'
    },
    jobFamilies_SOCIAL_SCIENCE_OCCUPATIONAL_HEALTH: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.OCCUPATIONAL_HEALTH'
    },
    jobFamilies_SOCIAL_SCIENCE_POLITICAL_SCIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.POLITICAL_SCIENCE'
    },
    jobFamilies_SOCIAL_SCIENCE_SOCIOLOGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.SOCIOLOGY'
    },
    jobFamilies_SOCIAL_SCIENCE_URBAN_PLANNING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SOCIAL_SCIENCE.URBAN_PLANNING'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_ENGINEERING_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.ENGINEERING_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_EXECUTIVE_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.EXECUTIVE_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_FINANCIAL_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.FINANCIAL_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_GROWTH_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.GROWTH_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_HUMAN_RESOURCE_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.HUMAN_RESOURCE_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_LEGAL_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.LEGAL_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_MARKETING_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.MARKETING_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_OPERATIONS_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.OPERATIONS_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_RISK_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.RISK_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_SALES_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.SALES_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_SUSTAINABILITY_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.SUSTAINABILITY_MANAGEMENT'
    },
    jobFamilies_TOP_LEVEL_MANAGEMENT_TECHNICAL_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TOP_LEVEL_MANAGEMENT.TECHNICAL_MANAGEMENT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_ACCOUNTING_AND_BOOKKEPING_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.ACCOUNTING_AND_BOOKKEPING_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_BILLING_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.BILLING_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_EQUIPMENT_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.EQUIPMENT_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_FINANCIAL_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.FINANCIAL_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_HR_ASSISTANCE_AND_PAYROLL_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.HR_ASSISTANCE_AND_PAYROLL_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_OFFICE_HOSTING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.OFFICE_HOSTING'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_OFFICE_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.OFFICE_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_RECEPTION: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.RECEPTION'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_SECRETARY_SERVICES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.SECRETARY_SERVICES'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_SHIPPING_AND_INVENTORY_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.SHIPPING_AND_INVENTORY_SUPPORT'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_SUPPLY_MAINTENANCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.SUPPLY_MAINTENANCE'
    },
    jobFamilies_OFFICE_AND_ADMINISTRATIVE_SUPPORT_TELEPHONE_OPERATIONS_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.OFFICE_AND_ADMINISTRATIVE_SUPPORT.TELEPHONE_OPERATIONS_SUPPORT'
    },
    jobFamilies_CUSTOMER_SERVICE_CUSTOMER_EXPERIENCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE.CUSTOMER_EXPERIENCE'
    },
    jobFamilies_CUSTOMER_SERVICE_CUSTOMER_SERVICE_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE.CUSTOMER_SERVICE_MANAGEMENT'
    },
    jobFamilies_CUSTOMER_SERVICE_CUSTOMER_SUCCESS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE.CUSTOMER_SUCCESS'
    },
    jobFamilies_CUSTOMER_SERVICE_CUSTOMER_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE.CUSTOMER_SUPPORT'
    },
    jobFamilies_CUSTOMER_SERVICE_IMPLEMENTATION_AND_ONBOARDING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE.IMPLEMENTATION_AND_ONBOARDING'
    },
    jobFamilies_CUSTOMER_SERVICE_TECHNICAL_SUPPORT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.CUSTOMER_SERVICE.TECHNICAL_SUPPORT'
    },
    jobFamilies_SALES_BUSINESS_TO_BUSINESS_: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.BUSINESS_TO_BUSINESS_'
    },
    jobFamilies_SALES_BUSINESS_TO_CONSUMER: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.BUSINESS_TO_CONSUMER'
    },
    jobFamilies_SALES_FIELD_SALES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.FIELD_SALES'
    },
    jobFamilies_SALES_LONG_SALES_CYCLES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.LONG_SALES_CYCLES'
    },
    jobFamilies_SALES_NEW_BUSINESS_DEVELOPMENT_: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.NEW_BUSINESS_DEVELOPMENT_'
    },
    jobFamilies_SALES_PROCUREMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.PROCUREMENT'
    },
    jobFamilies_SALES_RETAIL_SALES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.RETAIL_SALES'
    },
    jobFamilies_SALES_SAAS_SOLUTIONS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.SAAS_SOLUTIONS'
    },
    jobFamilies_SALES_SALES_MANAGEMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.SALES_MANAGEMENT'
    },
    jobFamilies_SALES_SHORT_SALES_CYCLES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.SHORT_SALES_CYCLES'
    },
    jobFamilies_SALES_TECHNICAL_SALES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.TECHNICAL_SALES'
    },
    jobFamilies_SALES_TELEMARKETING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.TELEMARKETING'
    },
    jobFamilies_SALES_TRANSACTIONAL_SALES: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.TRANSACTIONAL_SALES'
    },
    jobFamilies_SALES_UPSELL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.SALES.UPSELL'
    },
    jobFamilies_TRANSPORTATION_AIRLINE_PILOTING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.AIRLINE_PILOTING'
    },
    jobFamilies_TRANSPORTATION_BUS_DRIVING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.BUS_DRIVING'
    },
    jobFamilies_TRANSPORTATION_FLIGHT_ATTENDANCE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.FLIGHT_ATTENDANCE'
    },
    jobFamilies_TRANSPORTATION_INDUSTRIAL_TRUCK_AND_TRAILER_DRIVING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.INDUSTRIAL_TRUCK_AND_TRAILER_DRIVING'
    },
    jobFamilies_TRANSPORTATION_SHIPPING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.SHIPPING'
    },
    jobFamilies_TRANSPORTATION_TAXI_DRIVING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.TAXI_DRIVING'
    },
    jobFamilies_TRANSPORTATION_TRAILER_TRUCK_DRIVING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.TRAILER_TRUCK_DRIVING'
    },
    jobFamilies_TRANSPORTATION_TRUCK_DRIVING: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.TRANSPORTATION.TRUCK_DRIVING'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_ELECTRIC_AND_ELECTRONICS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.ELECTRIC_AND_ELECTRONICS'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_AUTOMOTIVE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.AUTOMOTIVE'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_WIND_OR_WATER: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.WIND_OR_WATER'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_ENERGY: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.ENERGY'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_MEDICAL_EQUIPMENT: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.MEDICAL_EQUIPMENT'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_AEROSPACE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.AEROSPACE'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_ROBOTICS: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.ROBOTICS'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_MECHANICAL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.MECHANICAL'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_HARDWARE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.HARDWARE'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_ENVIRONMENTAL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.ENVIRONMENTAL'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_LANDSCAPE: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.LANDSCAPE'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_INDUSTRIAL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.INDUSTRIAL'
    },
    jobFamilies_INSTALLATION_MAINTENANCE_REPAIR_CHEMICAL: {
        id: 'app.pages.user.candidateRelevanceModal.jobFamilies.INSTALLATION_MAINTENANCE_REPAIR.CHEMICAL'
    },
    workLocations_REMOTE: {
        id: 'app.pages.user.candidateRelevanceModal.workLocations.REMOTE'
    },
    workLocations_OFFICE: {
        id: 'app.pages.user.candidateRelevanceModal.workLocations.OFFICE'
    },
    workLocations_HYBRID: {
        id: 'app.pages.user.candidateRelevanceModal.workLocations.HYBRID'
    }
});
