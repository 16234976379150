import {TableMain} from '@/LEGACY_COMPONENT_LIBRARY/TableMain';
import {PERSONALITY_FACET_MESSAGES} from '@/componentLibrary/blocks/testReports/PersonalityTest/components/FactorFacets/constants';
import {Popover} from '@/componentLibrary/components/Tooltips/Popover';
import {StandardTenChartRange} from '@/componentLibrary/components/charts/StandardTenChartRange';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {Caption} from '@/componentLibrary/components/typography/Captions';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {S2} from '@/componentLibrary/components/typography/SubTitles';
import {greyColorTokens, secondaryColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {FACET_DESCRIPTION_MESSAGES} from '@/pages/Organization/pages/Assessment/messages';
import {capAndRoundStenValue} from '@/utils/misc';
import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import messages from '../../messages';
import {HeaderText, ResultWrapper, StenChartWrapper} from '../commonUiComponents';
import {FacetFactorDescription} from './styled.ts';

export function PersonalityTestResults({
    testProfile,
    jobApplication,
    calculateProfileLabel,
    loading
}) {
    const intl = useIntl();

    const personalityTestData = useMemo(() => {
        function getCriterionForFacet(facetName) {
            if (!testProfile || !testProfile.facetCriteria) {
                return null;
            }

            return testProfile.facetCriteria.find(
                facetCriterion => facetCriterion.facet === facetName
            );
        }

        function getFacetLabel(facetName) {
            const facetMessages = PERSONALITY_FACET_MESSAGES[facetName];

            return {
                name: intl.formatMessage(facetMessages.title),
                factor: intl.formatMessage(facetMessages.factor)
            };
        }

        function getFacetFactorDescription(facetName) {
            return intl.formatMessage(FACET_DESCRIPTION_MESSAGES[facetName]);
        }

        if (loading) {
            return [];
        }
        const {
            user: {personalityIrtResult}
        } = jobApplication;

        function getResultForFacet(facetName) {
            const result = personalityIrtResult
                ? personalityIrtResult[facetName.toLowerCase()]
                : null;

            if (result === null) {
                return null;
            }
            return capAndRoundStenValue(result);
        }

        const personalityDetails =
            testProfile && testProfile.facetCriteria ? testProfile.facetCriteria.slice(0) : [];
        personalityDetails.sort((a, b) => a.facet.localeCompare(b.facet));

        return personalityDetails.map(facetResult => {
            const criterion = getCriterionForFacet(facetResult.facet);
            return {
                ...getFacetLabel(facetResult.facet),
                facetFactorDescription: getFacetFactorDescription(facetResult.facet),
                facet: facetResult.facet,
                rangeStart: criterion ? capAndRoundStenValue(criterion.lower) : null,
                rangeEnd: criterion ? capAndRoundStenValue(criterion.upper) : null,
                result: getResultForFacet(facetResult.facet)
            };
        });
    }, [jobApplication, testProfile, loading, intl]);

    const tableColumns = useMemo(() => {
        return [
            {
                accessor: 'name',
                filterable: false,
                sortable: true,
                headerClassName: 'sortable',
                Cell: ({original}) => (
                    <PersonalityAssessmentWrapper>
                        <span>
                            <P2>{original.name}</P2>
                            <FacetText>{original.factor}</FacetText>
                        </span>
                        <FacetCriteriaInfoIconWrapper>
                            <Popover
                                content={
                                    <>
                                        <S2>
                                            {original.factor}: {original.name}
                                        </S2>
                                        <FacetFactorDescription>
                                            {original.facetFactorDescription}
                                        </FacetFactorDescription>
                                    </>
                                }
                                trigger={
                                    <Icon icon={Icons.INFO} size={20} fill={ColorFgSubtle} />
                                }
                                openOnHover
                            />
                        </FacetCriteriaInfoIconWrapper>
                    </PersonalityAssessmentWrapper>
                ),
                Header: (
                    <HeaderText>
                        <FormattedMessage {...messages.personalityAssessment} />
                    </HeaderText>
                )
            },
            {
                accessor: 'name',
                filterable: false,
                sortable: false,
                Cell: ({original}) => (
                    <StenChartWrapper>
                        <StandardTenChartRange
                            height={28}
                            width={220}
                            lowerBound={original.rangeStart}
                            upperBound={original.rangeEnd}
                            selectedValue={original.result}
                            rangeColor={opacity => secondaryColorTokens.blue000(opacity)}
                        />
                    </StenChartWrapper>
                ),
                Header: (
                    <HeaderWrapper>
                        <HeaderText>
                            <FormattedMessage {...messages.assessmentProfileRange} />
                        </HeaderText>
                        <Popover
                            openOnHover
                            content={
                                <FormattedMessage
                                    {...messages.assessmentProfileRangeTooltip}
                                    values={{
                                        testProfileName: testProfile ? testProfile.name : ''
                                    }}
                                />
                            }
                            trigger={<Icon icon={Icons.INFO} size={16} fill={ColorFgSubtle} />}
                        />
                    </HeaderWrapper>
                )
            },
            {
                accessor: 'result',
                filterable: false,
                sortable: true,
                headerClassName: 'sortable',
                Cell: ({original}) => (
                    <ResultWrapper>
                        {original.result ? (
                            <>
                                <P2 color={greyColorTokens.grey000()}>{original.result}</P2>
                                <Caption color={greyColorTokens.grey100()}>
                                    <FormattedMessage
                                        {...calculateProfileLabel(
                                            original.rangeStart,
                                            original.rangeEnd,
                                            original.result
                                        )}
                                    />
                                </Caption>
                            </>
                        ) : (
                            <P2 color={greyColorTokens.grey500()}>
                                <FormattedMessage {...messages.noResultsYet} />
                            </P2>
                        )}
                    </ResultWrapper>
                ),
                Header: (
                    <HeaderText>
                        <FormattedMessage {...messages.result} />
                    </HeaderText>
                )
            }
        ];
    }, [calculateProfileLabel, testProfile]);

    const tableProps = {
        resizable: false
    };

    return (
        <TableMain
            loading={loading}
            data={personalityTestData}
            columns={tableColumns}
            tableProps={tableProps}
            rowHeight={56}
        />
    );
}

PersonalityTestResults.propTypes = {
    loading: PropTypes.bool,
    testProfile: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        facetCriteria: PropTypes.array,
        logicCriterion: PropTypes.object,
        personalityWeight: PropTypes.number,
        logicWeight: PropTypes.number
    }),
    calculateProfileLabel: PropTypes.func.isRequired,
    jobApplication: PropTypes.shape({
        user: PropTypes.shape({
            id: PropTypes.number,
            personalityIrtResult: PropTypes.object,
            latestLogicTest: PropTypes.object,
            logicalAbilityEstimate: PropTypes.object
        }),
        roleFit: PropTypes.shape({
            score: PropTypes.number,
            interviewScore: PropTypes.number,
            testProfileScore: PropTypes.number,
            testProfilePersonalityScore: PropTypes.number,
            testProfileLogicScore: PropTypes.number,
            personalityDetails: PropTypes.arrayOf(
                PropTypes.shape({
                    facetName: PropTypes.string,
                    score: PropTypes.number,
                    label: PropTypes.string
                })
            ),
            logicDetails: PropTypes.object
        })
    })
};

const PersonalityAssessmentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
`;

const FacetCriteriaInfoIconWrapper = styled.div`
  margin: auto 60px auto 0;
  display: flex;
`;

const HeaderWrapper = styled.span`
  display: flex;

  .icon {
    fill: ${greyColorTokens.grey400()};
  }

  & > *:last-child {
    margin: auto 7px;
  }

  align-items: center;
`;

const FacetText = styled(Caption)`
  color: ${greyColorTokens.grey400()};
`;
