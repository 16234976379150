import {Tooltip} from '@/componentLibrary/components/Tooltips/Tooltip';

import {Item, ItemWrapper, Wrapper} from './styled';
import {HistogramGridProps} from './types';

export function HistogramGrid({data = [], maxYAxisValue}: HistogramGridProps) {
    return (
        <Wrapper size={data.length}>
            {data.map((item, i) => (
                <ItemWrapper
                    key={i}
                    height={maxYAxisValue === 0 ? 0 : (item.value / maxYAxisValue) * 100}
                >
                    <div />
                    <Tooltip content={item.tooltip} trigger={<Item />} />
                </ItemWrapper>
            ))}
        </Wrapper>
    );
}
