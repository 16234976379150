import {Popover} from '@/componentLibrary/components/Tooltips/Popover';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {Overline, P2} from '@/componentLibrary/components/typography';
import {ColorBaseGrey700} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {StatusDot} from '../../../../CandidateProgressTable/styled';
import scorePageMessages from '../../../messages';
import {toDisplayAutomatedScore} from '../../../utils';
import {useScore} from '../hooks/useLogic';
import {useStickyScrollLogic} from '../hooks/useStickyScrollLogic';
import messages from '../messages';
import {
    Column,
    DetailedResultTable,
    Results,
    ResultsWrapper,
    Row,
    Score,
    ScoreDisplay,
    ScoreDisplayBase,
    ScoreName
} from '../styled';
import {QualityScoreSectionProps} from '../types';

export function QualityScoreSection({score}: QualityScoreSectionProps) {
    const phrases = useExtractPhraseConstants(messages);
    const scorePagePhrases = useExtractPhraseConstants(scorePageMessages);

    const scoreType = useScore();

    const {sectionWrapperRef, sectionFooterRef, scoreRef, headerRowRef, tableRef, isSticky} =
        useStickyScrollLogic();

    if (!isDefined(score?.score) && score?.requirementScores.length === 0) {
        return null;
    }

    return (
        <div ref={sectionWrapperRef}>
            <ResultsWrapper>
                <Score isSticky={isSticky} ref={scoreRef}>
                    <ScoreName>
                        <div>{scorePagePhrases.qualityScore}</div>
                        <Popover
                            openOnHover
                            content={scorePagePhrases.qualityScoreTooltip}
                            trigger={<Icon icon={Icons.INFO} size={20} />}
                        />
                    </ScoreName>
                    <ScoreDisplay data-testid="quality-score">
                        {toDisplayAutomatedScore(score?.score)}
                        <ScoreDisplayBase>/3</ScoreDisplayBase>
                    </ScoreDisplay>
                </Score>
                <DetailedResultTable ref={tableRef} isSticky={isSticky}>
                    <Row isSticky={isSticky} ref={headerRowRef}>
                        <Column>
                            <Overline color={ColorBaseGrey700}>
                                {phrases.requirements}
                            </Overline>
                        </Column>
                        <Column>
                            <Overline color={ColorBaseGrey700}>{phrases.results}</Overline>
                        </Column>
                    </Row>
                    {score?.requirementScores.map((requirement, k) => (
                        <Row key={k} data-testid="quality-score-row">
                            <Column>
                                <P2>{requirement.requirement}</P2>
                            </Column>
                            <Column>
                                <Results>
                                    <StatusDot color={scoreType[requirement.score].color} />
                                    <P2>{scoreType[requirement.score].text}</P2>
                                </Results>
                                <P2 color={ColorBaseGrey700}>{requirement.motivation}</P2>
                            </Column>
                        </Row>
                    ))}
                </DetailedResultTable>
            </ResultsWrapper>
            <div ref={sectionFooterRef} />
        </div>
    );
}
