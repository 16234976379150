import {useJobApplicationInterviewRequests} from '@/api/assessment/interviews/useJobApplicationInterviewRequests';
import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {isArrayWithItems} from '@/utils/typeGuards/isArrayWithItems';
import {useCallback, useMemo} from 'react';

export function useInterviewRatingsLogic(jobPositionId: number, userId: number) {
    const {
        jobPosition,
        refetch: refetchJobPosition,
        error: jobPositionError
    } = useJobPosition(jobPositionId, {
        withInterview: true
    });

    const interviews = useMemo(() => {
        return jobPosition?.interviews ?? [];
    }, [jobPosition?.interviews]);

    const {
        interviewRequests,
        refetch: refetchInterviewRequests,
        error: interviewRequestsError,
        jobApplicationIsWithdrawn
    } = useJobApplicationInterviewRequests(jobPositionId, userId);

    const findInterviewRequestsByInterviewId = useCallback(
        (interviewId: number) => {
            return interviewRequests.filter(
                interviewRequest => interviewRequest.interviewId === interviewId
            );
        },
        [interviewRequests]
    );

    const display = useMemo(() => {
        return isArrayWithItems(interviews);
    }, [interviews]);

    return {
        jobPositionError,
        refetchJobPosition,
        interviews,
        interviewRequestsError,
        refetchInterviewRequests,
        findInterviewRequestsByInterviewId,
        display,
        jobApplicationIsWithdrawn
    };
}
