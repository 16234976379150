import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.logicTestIrt.logicTest.logicTestCompleted.validationTestCompleted.title'
    },
    descriptionResult: {
        id: 'app.logicTestIrt.logicTest.logicTestCompleted.validationTestCompleted.descriptionResult'
    },
    descriptionQuestions: {
        id: 'app.logicTestIrt.logicTest.logicTestCompleted.validationTestCompleted.descriptionQuestions'
    }
});
