import {gql} from '../types/__generated__';

export const SET_USER_LAST_SEEN = gql(`
    mutation setUserLastSeen {
        setUserLastSeen {
            ok
            errorMessage
        }
    }
`);

export const LOGIN = gql(`
    mutation login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            ok
            errorMessage
            token
        }
    }
`);

export const LOGIN_USER_WITH_SSO = gql(`
    mutation loginUserWithSsoFromApp(
        $idToken: String!
        $ssoProvider: AuthenticationMethod!
        $expectedOktaDomain: String
        $expectedOktaClientId: String
    ) {
        loginUserWithSso(
            idToken: $idToken
            ssoProvider: $ssoProvider
            expectedOktaDomain: $expectedOktaDomain
            expectedOktaClientId: $expectedOktaClientId
        ) {
            ok
            errorMessage
            token
        }
    }
`);

export const REGISTER_USER_WITH_SSO = gql(`
    mutation registerUserWithSso($data: RegisterUserWithSsoInput!) {
        registerUserWithSso(data: $data) {
            ok
            errorMessage
            token
        }
    }
`);

export const VALIDATE_SSO_TOKEN = gql(`
    mutation ValidateSsoToken($idToken: String!, $ssoProvider: AuthenticationMethod!) {
        validateSsoToken(idToken: $idToken, ssoProvider: $ssoProvider) {
            ok
            errorMessage
            tokenDetails {
                email
                givenName
                familyName
            }
        }
    }
`);
export const REQUEST_CREATE_FREE_TRIAL = gql(`
    mutation RequestFreeTrial($data: RequestFreeTrialInput!, $token: String!) {
        requestFreeTrial(data: $data, token: $token) {
            errorMessage
            ok
            userId
        }
    }
`);
