import {defineMessages} from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'app.assessment.jobPosition.settings.assignInterview.cancel'
    },
    previous: {
        id: 'app.assessment.jobPosition.settings.assignInterview.previous'
    },
    next: {
        id: 'app.assessment.jobPosition.settings.assignInterview.next'
    },
    assignInterviewersButton: {
        id: 'app.assessment.jobPosition.settings.assignInterview.assignInterviewersButton'
    },
    updateInterviewersButton: {
        id: 'app.assessment.jobPosition.settings.assignInterview.updateInterviewersButton'
    },
    startInterviewButton: {
        id: 'app.assessment.jobPosition.settings.assignInterview.startInterviewButton'
    },
    assignInterviewTitle: {
        id: 'app.assessment.jobPosition.settings.assignInterview.assignInterviewTitle'
    },
    assignInterviewerTitle: {
        id: 'app.assessment.jobPosition.settings.assignInterview.assignInterviewersButton'
    },
    startInterviewTitle: {
        id: 'app.assessment.jobPosition.settings.assignInterview.startInterviewButton'
    },
    successToastMessage: {
        id: 'app.assessment.jobPosition.settings.assignInterview.successToastMessage'
    }
});
