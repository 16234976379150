import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {Link} from '@/componentLibrary/components/links/Link';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {S2} from '@/componentLibrary/components/typography/SubTitles';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {
    BorderRadiusBaseLarge,
    SpacingLarge,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import {HELP_CENTER} from '@/constants/helpCenter';
import {LINKS} from '@/links';
import messages from '@/pages/SignupV2/components/InvitationNotice/messages';

import {SizeNarrow, SizeWide} from '../../constants';

export function InvitationNotice() {
    return (
        <InvitationWrapper>
            <S2>
                <FormattedMessage {...messages.title} />
            </S2>
            <P2>
                <FormattedMessage {...messages.notice} />
            </P2>
            <InviteNoticeLink>
                <Link url={LINKS.login}>
                    <P2>
                        <FormattedMessage {...messages.signIn} />
                    </P2>
                </Link>
            </InviteNoticeLink>
            <InviteNoticeLink>
                <Link notReactRouter url={HELP_CENTER.alvaForCandidates}>
                    <P2>
                        <FormattedMessage {...messages.readMore} />
                    </P2>
                </Link>
            </InviteNoticeLink>
        </InvitationWrapper>
    );
}

const InvitationWrapper = styled.div`
    margin-top: ${SpacingLarge};
    background-color: ${greyColorTokens.white000()};
    max-width: ${SizeWide};
    width: ${SizeWide};
    padding: ${SpacingLarge};
    border-radius: ${BorderRadiusBaseLarge};
    height: 100%;
    margin-left: ${SpacingXxlarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        max-width: ${SizeNarrow};
        width: ${SizeNarrow};
        margin-left: 0;
    }
`;

const InviteNoticeLink = styled.div`
    margin-top: 8px;
`;
