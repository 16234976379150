import {useCallback, useState} from 'react';

export function useValidationTestModalLogic() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const showValidationTestModal = useCallback(() => {
        setModalIsOpen(true);
    }, []);
    const hideValidationTestModal = useCallback(() => {
        setModalIsOpen(false);
    }, []);

    return {modalIsOpen, showValidationTestModal, hideValidationTestModal};
}
