import {AssessmentConfiguration} from '@/api/types/__generated__/graphql';
import {SCORE_TYPE} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateRoleFitV2Page/components/AssessmentScoresResults/constants';
import {useCandidateRoleFitContext} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateRoleFitV2Page/context';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {Overline} from '@/componentLibrary/components/typography';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {toDisplayAutomatedScore} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateCodingTestPage/components/AutomatedScoreV2Results/utils';
import {List, ListWrapper} from '../../styled';
import {useCodingTestListLogic} from './hooks';
import messages from './messages';

export function CodingTestList() {
    const phrases = useExtractPhraseConstants(messages);
    const {automatedScore} = useCodingTestListLogic();
    const {jobPosition} = useCandidateRoleFitContext();

    const isCodingTest = (configuration: AssessmentConfiguration) =>
        configuration.assessmentMethodType === SCORE_TYPE.CODING_TEST;
    const configuration = jobPosition.assessmentConfigurations.find(isCodingTest);

    if (!isDefined(configuration)) {
        return null;
    }

    const hasData =
        isDefined(automatedScore?.qualityScore?.score) ||
        isDefined(automatedScore?.reviewScore?.score) ||
        isDefined(automatedScore?.testScore?.score);

    return (
        <ListWrapper>
            <List>
                {isDefined(automatedScore?.testScore?.score) && (
                    <li>
                        <span>{phrases.automatedTestScoreTitle}</span>:{' '}
                        {automatedScore?.testScore?.score}%
                    </li>
                )}
                {isDefined(automatedScore?.qualityScore?.score) && (
                    <li>
                        <span>{phrases.qualityScoreTitle}</span>:{' '}
                        <span>
                            {toDisplayAutomatedScore(automatedScore.qualityScore.score)}
                            /3
                        </span>
                    </li>
                )}
                {isDefined(automatedScore?.reviewScore?.score) && (
                    <li>
                        <span>{phrases.reviewScoreTitle}</span>:{' '}
                        <span>
                            {toDisplayAutomatedScore(automatedScore?.reviewScore?.score)}
                            /3
                        </span>
                    </li>
                )}
            </List>
            {!hasData && <Overline color={ColorFgSubtle}>{phrases.noData}</Overline>}
        </ListWrapper>
    );
}
