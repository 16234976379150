import {useMediaQuery} from 'react-responsive';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';

export function useIsMobileDevice() {
    return useMediaQuery({query: `(max-width: ${deviceBreakPointTokens.MOBILE.max})`});
}

export function useIsTabletDevice() {
    return useMediaQuery({query: `(max-width: ${deviceBreakPointTokens.TABLET.max})`});
}
