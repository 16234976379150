import {defineMessages} from 'react-intl';

export const userDemographicsMessages = defineMessages({
    birthDate: {
        id: 'userDemographics.birthDate'
    },
    danish: {
        id: 'userDemographics.danish'
    },
    description: {
        id: 'userDemographics.description'
    },
    employed: {
        id: 'userDemographics.employed'
    },
    employmentStatus: {
        id: 'userDemographics.employmentStatus'
    },
    english: {
        id: 'userDemographics.english'
    },
    failedToSubmitDemographicsForm: {
        id: 'userDemographics.failedToSubmitDemographicsForm'
    },
    female: {
        id: 'userDemographics.female'
    },
    fillInLater: {
        id: 'userDemographics.fillInLater'
    },
    finnish: {
        id: 'userDemographics.finnish'
    },
    french: {
        id: 'userDemographics.french'
    },
    gender: {
        id: 'userDemographics.gender'
    },
    german: {
        id: 'userDemographics.german'
    },
    latvian: {
        id: 'userDemographics.latvian'
    },
    levelOfEducation: {
        id: 'userDemographics.levelOfEducation'
    },
    lithuanian: {
        id: 'userDemographics.lithuanian'
    },
    estonian: {
        id: 'userDemographics.estonian'
    },
    dutch: {
        id: 'userDemographics.dutch'
    },
    spanish: {
        id: 'userDemographics.spanish'
    },
    englishGB: {
        id: 'userDemographics.englishGB'
    },
    male: {
        id: 'userDemographics.male'
    },
    nativeLanguage: {
        id: 'userDemographics.nativeLanguage'
    },
    norwegian: {
        id: 'userDemographics.norwegian'
    },
    otherEducation: {
        id: 'userDemographics.otherEducation'
    },
    otherEmploymentStatus: {
        id: 'userDemographics.otherEmploymentStatus'
    },
    otherGender: {
        id: 'userDemographics.otherGender'
    },
    otherLanguage: {
        id: 'userDemographics.otherLanguage'
    },
    portuguese: {
        id: 'userDemographics.portuguese'
    },
    postSecondaryEducationLessThanThreeYears: {
        id: 'userDemographics.postSecondaryEducationLessThanThreeYears'
    },
    postSecondaryEducationThreeYearsAndMore: {
        id: 'userDemographics.postSecondaryEducationThreeYearsAndMore'
    },
    primaryAndLowerSecondaryEducation: {
        id: 'userDemographics.primaryAndLowerSecondaryEducation'
    },
    ratherNotSay: {
        id: 'userDemographics.ratherNotSay'
    },
    save: {
        id: 'userDemographics.save'
    },
    selectNativeLanguage: {
        id: 'userDemographics.selectNativeLanguage'
    },
    selfEmployed: {
        id: 'userDemographics.selfEmployed'
    },
    student: {
        id: 'userDemographics.student'
    },
    submittedDemographicsForm: {
        id: 'userDemographics.submittedDemographicsForm'
    },
    swedish: {
        id: 'userDemographics.swedish'
    },
    title: {
        id: 'userDemographics.title'
    },
    unEmployed: {
        id: 'userDemographics.unEmployed'
    },
    upperSecondaryEducationAndHighSchool: {
        id: 'userDemographics.upperSecondaryEducationAndHighSchool'
    }
});
