import {useCallback} from 'react';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';

import {IndicatorProps} from './types';

export function Indicator({isDisabled, menuIsOpen, onClick}: IndicatorProps) {
    const handleClick = useCallback(
        e => {
            e.stopPropagation();
            onClick?.();
        },
        [onClick]
    );

    return (
        <Icon
            icon={Icons.KEYBOARD_ARROW_DOWN}
            onClick={handleClick}
            rotate={menuIsOpen ? 180 : 0}
            size={14}
            isHoverable
            isAction
            isDisabled={isDisabled}
            style={{pointerEvents: menuIsOpen ? 'initial' : 'none'}}
        />
    );
}
