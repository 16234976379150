import {defineMessages} from 'react-intl';

export default defineMessages({
    hasNoPersonalityResult: {
        id: 'app.components.personAvatarGroup.hasNoPersonalityResult'
    },
    showAll: {
        id: 'app.assessment.myScorecards.selectScorecardStatus.showAll'
    }
});
