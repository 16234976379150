import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';

import messages from '../../messages';

type Props = {
    sendReminder: () => void;
    sendingReminder: boolean;
    sendReminderIsDisabled: boolean;
};

export const SendReminderMessage = ({
    sendReminder,
    sendingReminder,
    sendReminderIsDisabled
}: Props) => (
    <ButtonWrapper>
        <SecondaryButton
            isLoading={sendingReminder}
            onClick={sendReminder}
            disabled={sendReminderIsDisabled}
        >
            <FormattedMessage {...messages.sendReminder} />
        </SecondaryButton>

        <DisabledMessageWrapper>
            <FormattedMessage {...messages.disabledMessage} />
        </DisabledMessageWrapper>
    </ButtonWrapper>
);

const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 20px;
    margin-bottom: 4px;
    line-height: 34px;
`;

const DescriptionWrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 24px;
    white-space: pre-line;
    color: ${greyColorTokens.grey000()};
`;

const DisabledMessageWrapper = styled(DescriptionWrapper)`
    text-align: center;
    margin: 0 0 0 20px;
    color: ${greyColorTokens.grey400()};
`;
