import {UpgradePlan} from 'pages/UpgradePlan/components/UpgradePlan';
import styled from 'styled-components';

import {LandingPageLayoutHeader} from '@/componentLibrary/blocks/LandingPageLayout/components/LandingPageLayoutHeader';
import {ColorBgDefault, SpacingLarge, SpacingSmall} from '@/componentLibrary/tokens/variables';

export function UpgradePlanPage() {
    return (
        <>
            <HeaderWrapper>
                <LandingPageLayoutHeader
                    isWhite
                    withNameAndLogo
                    marginBottom={0}
                    showLanguageDropdown={false}
                    destination={undefined}
                />
            </HeaderWrapper>
            <UpgradePlan />
        </>
    );
}

const HeaderWrapper = styled.div`
    padding: ${SpacingSmall} ${SpacingLarge};
    background: ${ColorBgDefault};
    height: 78px;
`;
