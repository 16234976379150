import {defineMessages} from 'react-intl';

export default defineMessages({
    selectedTestProfile: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentProfileOverview.testProfileOverview.selectedTestProfile'
    },
    viewTestProfileDetails: {
        id: 'app.assessment.candidatePage.candidateRoleFitPage.assessmentProfileOverview.testProfileOverview.viewTestProfileDetails'
    }
});
