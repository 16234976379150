import {useCallback, useMemo} from 'react';

import {usePersonalityTestResult} from '@/api/personalityTests/usePersonalityTestResult';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {TransformedValues} from '@/componentLibrary/blocks/testReports/PersonalityTest/types';
import {roundTestResult} from '@/componentLibrary/blocks/testReports/PersonalityTest/utils';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {PERSONALITY_IRT_LINKS} from '@/pages/PersonalityTestIrt/links';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useSharePreviousResultsLogic} from '../../hooks';
import messages from './messages';
import {UseShowPreviousPersonalityTestResultLogicProps} from './types';

export function useShowPreviousPersonalityTestResultLogic({
    personalityTestId,
    organizationId,
    isLeadershipReportEnabled,
    setShowPreviousPersonalityTestResult
}: UseShowPreviousPersonalityTestResultLogicProps) {
    const phrases = useExtractPhraseConstants(messages);
    const {personalityTestResult, loading, error, refetch} = usePersonalityTestResult({
        personalityTestId,
        withLeadershipProfile: isLeadershipReportEnabled
    });
    const {user} = useLoggedInUser();
    const {sharingPersonalityTest, sharePersonalityTest} = useSharePreviousResultsLogic(
        user ? user.id : null,
        null,
        personalityTestId,
        organizationId,
        setShowPreviousPersonalityTestResult
    );

    const closeModal = useCallback(() => {
        setShowPreviousPersonalityTestResult(false);
    }, [setShowPreviousPersonalityTestResult]);

    const link = useMemo(
        () =>
            PERSONALITY_IRT_LINKS.getTestResultLink({
                testId: personalityTestId,
                organizationId: organizationId?.toString()
            }),
        [organizationId, personalityTestId]
    );
    const roundedResult = useMemo(() => {
        if (!isDefined(personalityTestResult)) {
            return null;
        }
        return roundTestResult(personalityTestResult as TransformedValues, true);
    }, [personalityTestResult]);

    const showLeadershipReportOverview = useMemo(
        () => isLeadershipReportEnabled && isDefined(personalityTestResult?.leadershipProfile),
        [isLeadershipReportEnabled, personalityTestResult]
    );

    return {
        user,
        personalityTestResult,
        loading,
        error,
        refetch,
        sharingPersonalityTest,
        sharePersonalityTest,
        closeModal,
        link,
        roundedResult,
        showLeadershipReportOverview,
        phrases
    };
}
