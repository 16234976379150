import {defineMessages} from 'react-intl';

export default defineMessages({
    task: {
        id: 'app.pages.logicTestIrt.components.formHeader.task'
    },
    leaveTest: {
        id: 'app.pages.logicTestIrt.components.formHeader.leaveTest'
    }
});
