import {PhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {GetEmploymentsAndApplicationsQuery} from '@/api/types/__generated__/graphql';
import {GET_MY_JOB_APPLICATIONS} from '@/api/users/queries';
import {ToastContent, ToastTitle} from '@/componentLibrary/blocks/testReports/hooks/styled';
import {P2} from '@/componentLibrary/components/typography';
import {ApolloClient} from '@apollo/client';
import {GetMyJobApplications_JobApplication_ChallengeAssignment} from '../../types';
import {BoilerplateImportError, SubmitChallengeAssignmentError} from './constants';

export function formatSubmitAssignmentChallengeError(
    errorCode: keyof typeof SubmitChallengeAssignmentError,
    phrases: PhraseConstants
) {
    switch (errorCode) {
        case SubmitChallengeAssignmentError.ASSIGNMENT_SUBMISSION_MISSING_PULL_REQUEST:
            return phrases.missingPRErrorMessageDesc;
        case SubmitChallengeAssignmentError.PULL_REQUEST_HAS_TOO_MANY_FILES_ERROR:
            return phrases.tooManyPRFilesErrorMessageDesc;
        default:
            return phrases.submissionFailedErrorMessageDesc;
    }
}

export function formatImportBoilerplateError(
    errorCode: keyof typeof BoilerplateImportError,
    phrases: PhraseConstants
) {
    switch (errorCode) {
        case BoilerplateImportError.BOILERPLATE_IMPORT_NOT_SUPPORTED:
            return phrases.boilerplateNotSupportedErrorMessageDesc;
        default:
            return phrases.importFailedErrorMessageDesc;
    }
}

export function updateQueryParams(paramsObj: {[key: string]: string | null}) {
    const newParams = new URLSearchParams(location.search);
    Object.entries(paramsObj).forEach(([key, value]) => {
        if (value === null) {
            newParams.delete(key);
        } else {
            newParams.set(key, value);
        }
    });
    const newUrl = `${location.pathname}?${newParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
}

export const renderErrorToastContent = (title: string, description: string) => (
    <ToastContent>
        <ToastTitle>{title}</ToastTitle>
        {description && <P2>{description}</P2>}
    </ToastContent>
);

export const updateChallengeAssignmentCache = ({
    client,
    challengeAssignmentId,
    updatedPayload
}: {
    client: ApolloClient<object>;
    challengeAssignmentId?: string;
    updatedPayload: Partial<GetMyJobApplications_JobApplication_ChallengeAssignment>;
}) => {
    const existingData = client.cache.readQuery({
        query: GET_MY_JOB_APPLICATIONS
    }) as GetEmploymentsAndApplicationsQuery;

    if (!existingData?.me) {
        return;
    }

    const challengeAssignmentExists = existingData.me.jobApplications.find(
        application => application.challengeAssignment?.id === challengeAssignmentId
    )?.challengeAssignment;

    if (!challengeAssignmentExists) {
        return;
    }

    const updatedJobApplications = existingData.me.jobApplications.map(application =>
        application.challengeAssignment?.id === challengeAssignmentId
            ? {
                  ...application,
                  challengeAssignment: {
                      ...application.challengeAssignment,
                      ...updatedPayload
                  } as GetMyJobApplications_JobApplication_ChallengeAssignment
              }
            : application
    );

    client.cache.writeQuery({
        query: GET_MY_JOB_APPLICATIONS,
        data: {
            ...existingData,
            me: {
                ...existingData.me,
                jobApplications: updatedJobApplications
            }
        }
    });
};
