import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {
    RegisterUserWithSsoMutation,
    RegisterUserWithSsoMutationVariables
} from '../types/__generated__/graphql';
import {REGISTER_USER_WITH_SSO} from './mutations';

export function useRegisterUserWithSso(): MutationHookReturnType<
    RegisterUserWithSsoMutation,
    RegisterUserWithSsoMutationVariables
> {
    const [mutate, {error, loading}] = useMutation(REGISTER_USER_WITH_SSO);
    const doRegisterUserWithSso = useCallback(variables => mutate({variables}), [mutate]);

    return [doRegisterUserWithSso, {error, loading}];
}
