import {defineMessages} from 'react-intl';

export default defineMessages({
    techStackLanguageHeader: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstack.languageHeader'
    },
    techStackFrameworkHeader: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstack.frameworkHeader'
    },
    techStackSelectDisclaimer: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstack.selectDisclaimer'
    },
    techStackSelectLabel: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstack.selectLabel'
    },
    techStackSelectedInfo: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstack.selectedInfo'
    },
    codespaceInfo: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstack.codespaceInfo'
    },
    manualBanner: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstack.manualBanner'
    },
    anyLanguageLabel: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstack.anyLanguageLabel'
    },
    anyFrameworkLabel: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstack.anyFrameworkLabel'
    },
    techstackSelectError: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.codingTestModal.techstack.selectError'
    }
});
