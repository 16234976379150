import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';

import {BannerWrapper} from '../styled';

export function LocalBanner({
    isSuccess,
    title,
    description
}: {
    isSuccess: boolean;
    title: JSX.Element | null;
    description: JSX.Element;
}) {
    return (
        <BannerWrapper>
            <Banner type={isSuccess ? BannerType.SUCCESS : BannerType.INFO} title={title}>
                {description}
            </Banner>
        </BannerWrapper>
    );
}
