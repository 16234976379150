import {State} from '@/services/ui/types';

const UI_KEY = 'alva:ui';

export const initialUi = {};

export const getUiState = () => {
    if (!window.localStorage?.getItem(UI_KEY)) {
        return initialUi;
    }

    // Parse data from local storage
    let savedState;

    try {
        const state = window.localStorage.getItem(UI_KEY) ?? '{}';
        savedState = JSON.parse(state);
    } catch (_e) {
        return initialUi;
    }

    // Make sure that the returned object have the same format as 'initialUi'
    // This returns a new object where initialUi is overridden with settings in saved state
    return Object.assign({}, initialUi, savedState);
};

export const saveUiState = (ui: State) => {
    if (!window.localStorage) {
        return;
    }
    window.localStorage.setItem(UI_KEY, JSON.stringify(ui));
};

export const setDocumentTitle = (title: string) => {
    window.document.title = title + ' | Alva Labs';
};
