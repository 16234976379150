import {P2} from '@/componentLibrary/components/typography';
import {
    ColorBaseGrey800,
    SpacingLarge,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: ${SpacingXxlarge};
`;

export const TitleWrapper = styled.div`
    margin-bottom: 30px;
`;

export const DescriptionWrapper = styled(P2)`
    color: ${ColorBaseGrey800};
    margin-bottom: ${SpacingLarge};
`;
