import {POSSIBLE_RESULT_VALUES} from './constants';
import {ColorPerResultValueParams} from './types';

export function getColorPerResultValue({
    lowerBound,
    upperBound,
    rangeColor,
    valueColor,
    selectedValue
}: ColorPerResultValueParams) {
    return POSSIBLE_RESULT_VALUES.map(result => {
        if (result === selectedValue) {
            return valueColor;
        }
        if (lowerBound && upperBound && result >= lowerBound && result <= upperBound) {
            return rangeColor;
        }
    });
}
