import {FormattedMessage, IntlShape} from 'react-intl';
import styled from 'styled-components';

import {GetChallenge_Challenge} from '@/api/assessment/types';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {Caption} from '@/componentLibrary/components/typography';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {ColorFgSubtle, SpacingXxxsmall} from '@/componentLibrary/tokens/variables';
import {capitalizeFirstLetter} from '@/utils/misc';

import {Empty} from '../components/Empty';
import messages from '../messages';
import {getVisibleText} from './getVisibleText';

export const getBasicProps = (
    challenge: GetChallenge_Challenge | null,
    onClickGitHubLink: (e: React.MouseEvent<HTMLAnchorElement>) => void,
    intl: IntlShape
) => {
    const content = [
        {
            contentTitle: intl.formatMessage(messages.basicInfoProfileLabel),
            contentItem: challenge?.profile ? (
                capitalizeFirstLetter(challenge.profile)
            ) : (
                <Empty />
            )
        },
        {
            contentTitle: intl.formatMessage(messages.gitHubTemplateRepoTitle),
            contentItem: (
                <ExternalLinkWrapper>
                    <ExternalLink onClick={onClickGitHubLink}>
                        <FormattedMessage {...messages.gitHubLink} />
                    </ExternalLink>
                    {challenge?.isCustom && (
                        <Caption color={ColorFgSubtle}>
                            {intl.formatMessage(messages.gitHubTemplateRepoSubtitle)}
                        </Caption>
                    )}
                </ExternalLinkWrapper>
            )
        },
        {
            contentTitle: intl.formatMessage(messages.gitHubTextAreaTitle),
            contentItem: getVisibleText(challenge?.description)
        }
    ];

    return {
        title: intl.formatMessage(messages.basicInfoTitle),
        subtitle: '',
        isCustom: challenge?.isCustom ?? false,
        content
    };
};

const ExternalLinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXxxsmall};
    width: 400px;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        width: 100%;
    }
`;
