import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
    max-width: 60%;
`;

export const GoToOrganizationButton = styled(SecondaryButton)`
    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.small}) {
        width: 100%;
    }
`;
