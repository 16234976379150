import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';

import {ChangeLanguageMutation} from '../types/__generated__/graphql';
import {CHANGE_LOCALE_FOR_USER} from './mutations';

export function useChangeLocaleForUser(
    {onCompleted} = {onCompleted: undefined}
): MutationHookReturnType<ChangeLanguageMutation, [number, string]> {
    const [mutate, {loading, error}] = useMutation(CHANGE_LOCALE_FOR_USER, {onCompleted});

    const changeLocale = useCallback(
        (userId, language) => mutate({variables: {userId, language}}),
        [mutate]
    );

    return [changeLocale, {error, loading}];
}
