import {useMemo} from 'react';

import {LogicTestReportChartProps} from './types';

export function useCategory(questions: LogicTestReportChartProps['questions']) {
    return useMemo(
        () =>
            questions.map(({questionOrder: order}) => ({
                label: order.toString(),
                showLabel: order === 1 || order % 5 === 0 ? '1' : '0',
                fontSize: 11
            })),
        [questions]
    );
}
