import {useMemo} from 'react';

import {ConsentType} from '@/api/types/__generated__/graphql';
import {useTermsOfServiceVersion} from '@/services/consent/useTermsOfServiceVersion';
import {useStaticLegalContent} from '@/services/staticLegalContent/useStaticLegalContent';

export function useConsentLogic(registrationType: string | null) {
    const {loadingTermsOfServiceVersion, termsOfServiceVersion} = useTermsOfServiceVersion();
    const {loading: loadingCandidateServices, data: candidateServices} = useStaticLegalContent(
        {type: ConsentType.PRIVACY_POLICY_CANDIDATE_SERVICES}
    );

    const candidateServicesConsentVersion = useMemo(() => {
        if (registrationType === 'EMPLOYEE') {
            return null;
        }

        return candidateServices?.version;
    }, [candidateServices, registrationType]);

    const loadingConsents = useMemo(
        () => loadingTermsOfServiceVersion || loadingCandidateServices,
        [loadingTermsOfServiceVersion, loadingCandidateServices]
    );

    return {
        termsOfServiceVersion,
        candidateServicesConsentVersion,
        loadingConsents
    };
}
