import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {HistogramChart} from '@/componentLibrary/components/charts/HistogramChart';
import {H4} from '@/componentLibrary/components/typography/Headings';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {RoleFitDistributionChart} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/pages/CandidateRoleFitPage/components/RoleFitOverview/components/RoleFitCharts/components/RoleFitDistributionChart';
import {useUserFeatureFlags} from '@/services/experimentalFeatures/useUserFeatureFlags';

import {SegmentedControl} from '@/componentLibrary/components/SegmentedControl';
import {SegmentedControlSize} from '@/componentLibrary/components/SegmentedControl/types';
import messages from '../../messages';
import {useRoleFitChartsLogic} from './logic';

export function RoleFitCharts({roleFitDistribution, roleFitHistogram}) {
    const {
        distribution: {
            titleText,
            yAxisTitleText,
            percentileText,
            labelTexts,
            percentileBoundaries,
            tooltipTexts,
            distributionParameters,
            isPercentileBoundariesVisible,
            disabledText
        },
        histogram: {
            segmentedControls,
            isHistogramChartEnabled,
            toggleSegmentedControls,
            roleFitHistogramData,
            histogramXAxisTitle,
            histogramYAxisTitle
        }
    } = useRoleFitChartsLogic(roleFitDistribution, roleFitHistogram);

    const {privateUserFeatureFlags} = useUserFeatureFlags();
    const isChartHeaderEnabled = privateUserFeatureFlags.ROLE_FIT_PAGE_CHART_HEADER;

    const getScorePercentage = score => (score === null ? null : Math.round(score * 100));

    const chart = isHistogramChartEnabled ? (
        <HistogramChart
            xAxisTitle={histogramXAxisTitle}
            yAxisTitle={histogramYAxisTitle}
            data={roleFitHistogramData}
            score={getScorePercentage(roleFitHistogram.score)}
        />
    ) : (
        <RoleFitDistributionChart
            roleFitScore={isPercentileBoundariesVisible ? roleFitDistribution.score : null}
            distributionParameters={
                isPercentileBoundariesVisible ? distributionParameters : null
            }
            xAxisTitle={titleText}
            yAxisTitle={yAxisTitleText}
            percentileBoundaries={percentileBoundaries}
            percentileBoundaryLabelTexts={labelTexts}
            percentileBoundaryTooltipTexts={tooltipTexts}
            disabledText={disabledText}
        />
    );

    return (
        <Wrapper>
            {isChartHeaderEnabled && (
                <>
                    {roleFitDistribution.score === null ? (
                        <>
                            <Header />
                            <Description />
                        </>
                    ) : (
                        <>
                            <Header color={greyColorTokens.grey000()}>{percentileText}</Header>
                            <Description color={greyColorTokens.grey100()}>
                                <FormattedMessage
                                    {...messages.rankedOutOf}
                                    values={{
                                        rank: roleFitDistribution.rank,
                                        total: roleFitDistribution.candidatesWithScore
                                    }}
                                />
                            </Description>
                        </>
                    )}
                </>
            )}

            <ChartWrapper>
                <SegmentedControlWrapper>
                    <SegmentedControl
                        onSelect={toggleSegmentedControls}
                        options={segmentedControls}
                        size={SegmentedControlSize.SMALL}
                        isFullwidth={false}
                    />
                </SegmentedControlWrapper>
                {chart}
            </ChartWrapper>
        </Wrapper>
    );
}

RoleFitCharts.propTypes = {
    roleFitDistribution: PropTypes.shape({
        userId: PropTypes.number,
        jobPositionId: PropTypes.number,
        score: PropTypes.number,
        candidatesWithScore: PropTypes.number,
        rank: PropTypes.number,
        alpha: PropTypes.number,
        location: PropTypes.number,
        scale: PropTypes.number,
        percentileBoundaries: PropTypes.arrayOf(
            PropTypes.shape({
                boundary: PropTypes.number,
                value: PropTypes.number
            })
        )
    }),
    roleFitHistogram: PropTypes.shape({
        userId: PropTypes.number,
        jobPositionId: PropTypes.number,
        score: PropTypes.number,
        buckets: PropTypes.arrayOf(
            PropTypes.shape({
                lower: PropTypes.number,
                upper: PropTypes.number,
                userCount: PropTypes.number
            })
        )
    })
};

const Wrapper = styled.div`
    margin-bottom: 12px;
    width: auto;
    padding-top: 20px;
`;

const SegmentedControlWrapper = styled.div`
    width: 250px;
`;

const ChartWrapper = styled.div`
    > :last-child {
        margin-top: 20px;
        min-height: 320px;
    }
`;

const Header = styled(H4)`
    margin-bottom: 8px;
`;

const Description = styled(P2)`
    margin-bottom: 40px;
`;
