import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {BorderRadiusBaseXsmall} from '@/componentLibrary/tokens/variables';

const OuterWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InnerWrapper = styled.div`
    @media only screen and (min-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        max-width: 964px;
    }

    display: flex;
    justify-content: center;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
    }
`;

const ChildrenWrapper = styled.div`
    background-color: ${greyColorTokens.white000()};
    max-width: 440px;
    height: 100%;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
    }

    @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
        width: 440px;
    }

    border-radius: ${BorderRadiusBaseXsmall};
`;

export {OuterWrapper, InnerWrapper, ChildrenWrapper};
