import styled from 'styled-components';

import {P2} from '@/componentLibrary/components/typography/Paragraphs';

export const Text = styled(P2)`
    padding: 4px 6px;
    border: 1px solid rgb(0 0 0 / 20%);
    cursor: pointer;
    user-select: all;
    line-height: 20px;
    font-size: 16px;
`;
