import {JobApplicationStage} from '@/api/types/__generated__/graphql';
import {TagVariant} from '@/componentLibrary/components/Tag/constants';
import {
    ColorBgDefault,
    ColorBorderSuccess,
    ColorFgAttention,
    ColorFgDefault,
    ColorFgError,
    ColorFgInfo
} from '@/componentLibrary/tokens/variables';

export const ORDERED_JOB_APPLICATION_STAGES = [
    JobApplicationStage.IN_PROCESS,
    JobApplicationStage.SHORTLISTED,
    JobApplicationStage.HIRED,
    JobApplicationStage.REJECTED,
    JobApplicationStage.WITHDRAWN
];

export const JOB_APPLICATION_STAGE_COLORS = {
    [JobApplicationStage.IN_PROCESS]: ColorBgDefault,
    [JobApplicationStage.SHORTLISTED]: ColorFgInfo,
    [JobApplicationStage.HIRED]: ColorBorderSuccess,
    [JobApplicationStage.REJECTED]: ColorFgError,
    [JobApplicationStage.WITHDRAWN]: ColorFgAttention
};

export const JOB_APPLICATION_STAGE_BORDER_COLORS = {
    [JobApplicationStage.IN_PROCESS]: ColorFgDefault,
    [JobApplicationStage.SHORTLISTED]: ColorFgInfo,
    [JobApplicationStage.HIRED]: ColorBorderSuccess,
    [JobApplicationStage.REJECTED]: ColorFgError,
    [JobApplicationStage.WITHDRAWN]: ColorFgAttention
};

export const JOB_APPLICATION_STAGE_VARIANTS = {
    [JobApplicationStage.IN_PROCESS]: TagVariant.default,
    [JobApplicationStage.SHORTLISTED]: TagVariant.info,
    [JobApplicationStage.HIRED]: TagVariant.success,
    [JobApplicationStage.REJECTED]: TagVariant.danger,
    [JobApplicationStage.WITHDRAWN]: TagVariant.warning
};

export const SCORECARD_ACTION_TYPE = {
    UPDATED: 'updated',
    DELETED: 'deleted',
    ADDED: 'added'
};
