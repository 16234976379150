import {DisplayMedium} from '@/componentLibrary/components/typography/Display';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {TextWrapper, Wrapper} from '@/pages/LogicTestIrt/components/SelectAnswer/styled';
import {SelectAnswerProps} from '@/pages/LogicTestIrt/components/SelectAnswer/types';

import messages from './messages';

export const SelectAnswer = ({isOptionSelected = false}: SelectAnswerProps) => {
    const phrases = useExtractPhraseConstants(messages);

    return (
        <Wrapper>
            <TextWrapper>
                <DisplayMedium>
                    {isOptionSelected ? phrases.submitAnswer : phrases.chooseAnswer}
                </DisplayMedium>
            </TextWrapper>
        </Wrapper>
    );
};
