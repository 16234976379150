import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDetailedModalContext} from '../../Context';
import {DETAILED_RESULTS_MODAL_TABS, MODAL_HEADER_HEIGHT} from '../../constants';
import messages from '../../messages';
import {UseLogicProps, UseTabsProps} from './types';

export function useLogic({tabRef}: UseLogicProps) {
    const [isSticky, setIsSticky] = useState(false);
    const {parentRef} = useDetailedModalContext();

    const handleScroll = useCallback(() => {
        if (!tabRef.current) {
            return;
        }

        const tabRect = tabRef.current.getBoundingClientRect();

        if (isSticky) {
            if (tabRect.top > MODAL_HEADER_HEIGHT) {
                setIsSticky(false);
            }
        } else if (tabRect.top <= MODAL_HEADER_HEIGHT) {
            setIsSticky(true);
        }
    }, [isSticky, tabRef]);

    useEffect(() => {
        if (!parentRef.current) {
            return;
        }

        parentRef.current.addEventListener('scroll', handleScroll);

        return () => {
            parentRef.current?.removeEventListener('scroll', handleScroll);
        };
    }, [parentRef, handleScroll]);
    return {isSticky};
}

export function useTabs({onClick}: UseTabsProps) {
    const {parentRef} = useDetailedModalContext();

    const [activeTabId, setActiveTabId] = useState(
        DETAILED_RESULTS_MODAL_TABS.WithinTimeLimit
    );
    const phrases = useExtractPhraseConstants(messages);

    const handleTabClick = useCallback(
        (tabId: string) => {
            setActiveTabId(tabId);
            onClick(tabId === DETAILED_RESULTS_MODAL_TABS.BeyondTimeLimit);
            parentRef.current?.scrollTo({
                top: 0
            });
        },
        [onClick, parentRef]
    );

    const tabs = useMemo(
        () => [
            {
                id: DETAILED_RESULTS_MODAL_TABS.WithinTimeLimit,
                title: phrases.testScoreWithinTime,
                onClick: handleTabClick.bind(
                    null,
                    DETAILED_RESULTS_MODAL_TABS.WithinTimeLimit
                ),
                render: () => null,
                active: activeTabId === DETAILED_RESULTS_MODAL_TABS.WithinTimeLimit
            },
            {
                id: DETAILED_RESULTS_MODAL_TABS.BeyondTimeLimit,
                title: phrases.testScoreBeyondTime,
                onClick: handleTabClick.bind(
                    null,
                    DETAILED_RESULTS_MODAL_TABS.BeyondTimeLimit
                ),
                render: () => null,
                active: activeTabId === DETAILED_RESULTS_MODAL_TABS.BeyondTimeLimit
            }
        ],
        [phrases.testScoreBeyondTime, phrases.testScoreWithinTime, activeTabId, handleTabClick]
    );
    return {tabs};
}
