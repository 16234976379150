import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    commonScore: {
        id: 'app.logicTestReport.testIntro.commonScore'
    },
    disclaimer: {
        id: 'app.logicTestReport.disclaimer'
    }
});

export const VERY_LOW_MESSAGES = defineMessages({
    title: {
        id: 'logicReportScore.title.VERY_LOW'
    },
    adminContent: {
        id: 'logicReportScore.VERY_LOW.adminContent'
    },
    userContent: {
        id: 'logicReportScore.VERY_LOW.userContent'
    },
    scoreDescription: {
        id: 'app.logicTestReport.VERY_LOW.scoreDescription'
    }
});

export const LOW_MESSAGES = defineMessages({
    title: {
        id: 'logicReportScore.title.LOW'
    },
    adminContent: {
        id: 'logicReportScore.LOW.adminContent'
    },
    userContent: {
        id: 'logicReportScore.LOW.userContent'
    },
    scoreDescription: {
        id: 'app.logicTestReport.LOW.scoreDescription'
    }
});

export const AVERAGE_MESSAGES = defineMessages({
    title: {
        id: 'logicReportScore.title.AVERAGE'
    },
    adminContent: {
        id: 'logicReportScore.AVERAGE.adminContent'
    },
    userContent: {
        id: 'logicReportScore.AVERAGE.userContent'
    },
    scoreDescription: {
        id: 'app.logicTestReport.AVERAGE.scoreDescription'
    }
});

export const HIGH_MESSAGES = defineMessages({
    title: {
        id: 'logicReportScore.title.HIGH'
    },
    adminContent: {
        id: 'logicReportScore.HIGH.adminContent'
    },
    userContent: {
        id: 'logicReportScore.HIGH.userContent'
    },
    scoreDescription: {
        id: 'app.logicTestReport.HIGH.scoreDescription'
    }
});

export const VERY_HIGH_MESSAGES = defineMessages({
    title: {
        id: 'logicReportScore.title.VERY_HIGH'
    },
    adminContent: {
        id: 'logicReportScore.VERY_HIGH.adminContent'
    },
    userContent: {
        id: 'logicReportScore.VERY_HIGH.userContent'
    },
    scoreDescription: {
        id: 'app.logicTestReport.VERY_HIGH.scoreDescription'
    }
});
