import {defineMessages} from 'react-intl';

export default defineMessages({
    reminderSentLastDay: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResultsTable.reminderSentLastDay'
    },
    tooManyRemindersSent: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResultsTable.tooManyRemindersSent'
    },
    latestInvitationEmailBounced: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResultsTable.latestInvitationEmailBounced'
    },
    latestReminderEmailBounced: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResultsTable.latestReminderEmailBounced'
    }
});
