import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {Overlay} from '../Overlay';
import {RoleFitContributionBars} from '../RoleFitContributionBars';
import messages from './messages';
import {Container, Title, Wrapper} from './styled';
import {AssessmentMethodContributionProps} from './types';

export function AssessmentMethodContribution({
    contributionTypeId,
    contributionItems,
    isBlurred
}: AssessmentMethodContributionProps) {
    const intl = useIntl();
    const getAndFormatTitleMessage = useCallback(() => {
        return intl.formatMessage(messages[contributionTypeId]);
    }, [intl, contributionTypeId]);

    return (
        <Container>
            <Title>{getAndFormatTitleMessage()}</Title>
            <Wrapper noPointerEvents={isBlurred}>
                <Overlay isVisible={isBlurred} />
                <RoleFitContributionBars
                    contributionTypeId={contributionTypeId}
                    contributionItems={contributionItems}
                    isBlurred={isBlurred}
                />
            </Wrapper>
        </Container>
    );
}
