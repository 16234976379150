import {defineMessages} from 'react-intl';

export default defineMessages({
    password: {
        id: 'app.passwordForm.password'
    },
    repeatPassword: {
        id: 'app.passwordForm.repeatPassword'
    },
    submit: {
        id: 'app.passwordForm.submit'
    },
    success: {
        id: 'app.resetPassword.success'
    },
    error: {
        id: 'app.resetPassword.error'
    },
    passwordMismatch: {
        id: 'app.pages.user.registerScreen.registerForm.passwordMismatch'
    }
});
