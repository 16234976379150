import PropTypes from 'prop-types';
import {ReactElement} from 'react';

import {LanguageProvider} from '@/containers/LanguageProvider';
import {translationMessages} from '@/i18n';

export function WithTestReportLocale({
    locale,
    children
}: {
    locale: string;
    children: ReactElement;
}): JSX.Element {
    return (
        <LanguageProvider customLocale={locale} messages={translationMessages}>
            {children}
        </LanguageProvider>
    );
}

WithTestReportLocale.propTypes = {
    locale: PropTypes.string,
    children: PropTypes.node
};
