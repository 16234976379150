import {ComponentType, LazyExoticComponent, lazy} from 'react';

type ComponentPromise<T = unknown> = Promise<{default: ComponentType<T>}>;

export function lazyRetry(
    component: () => ComponentPromise
): LazyExoticComponent<ComponentType<unknown>> {
    return lazy(() => retry(component) as ComponentPromise);
}

async function retry(componentImport: () => ComponentPromise) {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
        window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    );

    try {
        const component = await componentImport();
        window.localStorage.removeItem('page-has-been-force-refreshed');
        return component;
    } catch (error) {
        if (!pageHasAlreadyBeenForceRefreshed) {
            // Assuming that the user is not on the latest version of the application.
            // Let's refresh the page immediately.
            window.localStorage.setItem('page-has-been-force-refreshed', 'true');
            window.location.reload();
        } else {
            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Clear localStorage until next time
            window.localStorage.removeItem('page-has-been-force-refreshed');
            // Let the application crash and raise the error.
            throw error;
        }
    }
}
