import {ROLE_FIT_CONTRIBUTION_TYPES} from '../../utils/constants';
import {ItemWithTooltip} from './components/ItemWithTooltip';
import {useRoleFitContributionBarsLogic} from './logic';
import {OuterWrapper} from './styled';
import {RoleFitContributionBarsProps} from './types';

export function RoleFitContributionBars({
    contributionTypeId,
    contributionItems,
    isBlurred
}: RoleFitContributionBarsProps) {
    const {formattedContributionItems} = useRoleFitContributionBarsLogic({contributionItems});
    const contributionType = ROLE_FIT_CONTRIBUTION_TYPES[contributionTypeId];

    return (
        <OuterWrapper>
            {formattedContributionItems.map((item, index) => {
                return (
                    <ItemWithTooltip
                        key={index}
                        contributionType={contributionType}
                        item={item}
                        barIsBlurred={isBlurred}
                    />
                );
            })}
        </OuterWrapper>
    );
}
