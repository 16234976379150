import {GetJobApplication_JobApplication} from '@/api/assessment/types';

export function useAssessmentScoresResultsLogic(
    jobApplication: GetJobApplication_JobApplication,
    interviewId?: string
) {
    const assessmentScores = jobApplication?.roleFitV2?.assessmentScores || [];
    const hasInterviewScore = assessmentScores.some(
        ({assessmentMethodType, missingResults, assessmentMethodId}) =>
            assessmentMethodType === 'INTERVIEW' &&
            assessmentMethodId === interviewId &&
            !missingResults
    );

    const hasAssessmentScore = (assessmentMethodType: string): boolean => {
        return assessmentScores.some(
            ({assessmentMethodType: type, missingResults}) =>
                type === assessmentMethodType && !missingResults
        );
    };

    return {
        hasInterviewScore,
        hasPersonalityScore: hasAssessmentScore('PERSONALITY_TEST'),
        hasCodingScore: hasAssessmentScore('CODING_TEST'),
        hasLogicScore: hasAssessmentScore('LOGIC_TEST')
    };
}
