import {defineMessages} from 'react-intl';

export default defineMessages({
    chooseAnswer: {
        id: 'app.pages.logicTestIrt.components.selectAnswer.chooseAnswer'
    },
    submitAnswer: {
        id: 'app.pages.logicTestIrt.components.selectAnswer.submitAnswer'
    }
});
