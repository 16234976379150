import {useEffect, useState} from 'react';

import {calculateCountdown} from '../../../functions/calculateCountdown';
import {CalculateCountdown} from '../../../types';

export const useCountdown = ({deadline, timeLimitMinutes, started}: CalculateCountdown) => {
    const [timeString, setTimeString] = useState<string>(() =>
        calculateCountdown({deadline, timeLimitMinutes})
    );

    useEffect(() => {
        if (!started) {
            return;
        }
        const intervalId = setInterval(() => {
            const newTimeString = calculateCountdown({deadline, timeLimitMinutes});
            setTimeString(newTimeString);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [deadline, timeLimitMinutes, started]);

    const deadlineDt = deadline && new Date(deadline);

    const remainingTimeSec = deadlineDt
        ? Math.max(Math.ceil((new Date(deadline).getTime() - Date.now()) / 1000), 0)
        : null;

    return {countdown: timeString, remainingTimeSec};
};
