import {useMemo} from 'react';

export function usePageSizeForLoadingLogic(headerAndFooterPadding, enableFilter, rowHeight) {
    return useMemo(() => {
        const padding = headerAndFooterPadding + (enableFilter ? 52 : 0);
        const viewPortHeight = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight || 0
        );
        const tableBodyHeight = viewPortHeight * 0.7 - padding;
        return Math.max(1, Math.floor(tableBodyHeight / rowHeight));
    }, [headerAndFooterPadding, enableFilter, rowHeight]);
}
