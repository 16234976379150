import qs from 'qs';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';

import {ErrorBoundary} from '@/componentLibrary/components/ErrorBoundary';
import {LogicTest} from '@/pages/LogicTestIrt/pages/LogicTest';

import {LogicTestValidationPageUnconnectedProps} from '@/pages/LogicTestValidation/types';
import {LOGIC_TEST_VALIDATION_LINKS} from './links';
import {LogicTestValidation} from './pages/LogicTestValidation';

export const LogicTestValidationPageUnconnected = ({
    location,
    history
}: LogicTestValidationPageUnconnectedProps) => {
    const goToValidationTest = (testId: string) => {
        const url = LOGIC_TEST_VALIDATION_LINKS.test(testId);

        history.push(url);
    };

    const params = qs.parse(location.search, {ignoreQueryPrefix: true});
    const token = (params['t'] as string) ?? null;

    return (
        <Switch>
            <Route
                exact
                path={LOGIC_TEST_VALIDATION_LINKS.start()}
                render={() => (
                    <ErrorBoundary>
                        {token ? (
                            <LogicTestValidation
                                token={token}
                                goToValidationTest={goToValidationTest}
                            />
                        ) : null}
                    </ErrorBoundary>
                )}
            />
            <Route
                exact
                path={LOGIC_TEST_VALIDATION_LINKS.test(':logicTestId')}
                render={p => (
                    <ErrorBoundary>
                        <LogicTest
                            logicTestId={p.match.params.logicTestId as string}
                            isValidationTest
                        />
                    </ErrorBoundary>
                )}
            />
            <Route
                path="*"
                render={() => <Redirect to={LOGIC_TEST_VALIDATION_LINKS.start()} />}
            />
        </Switch>
    );
};

export const LogicTestValidationPage = withRouter(LogicTestValidationPageUnconnected);
