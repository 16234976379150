import {
    arrow,
    autoUpdate,
    flip,
    offset,
    safePolygon,
    shift,
    size,
    useClick,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole
} from '@floating-ui/react';
import {createContext, useContext, useEffect, useMemo, useRef, useState} from 'react';

import {isDefined} from '@/utils/typeGuards/isDefined';

import {ARROW_HEIGHT, GAP} from '../Tooltips/Tooltip/constants';
import {ActionOverlayOptions, ContextType} from './types';

export const ActionOverlayContext = createContext<ContextType>(null);

export const useActionOverlayContext = () => {
    const context = useContext(ActionOverlayContext);

    if (context == null) {
        throw new Error('ActionOverlay components must be wrapped in <ActionOverlay />');
    }

    return context;
};

export function useActionOverlay({
    initialOpen = false,
    placement = 'bottom',
    open: controlledOpen,
    onOpenChange: setControlledOpen,
    hasArrow,
    shouldFocus = false,
    matchTriggerWidth = false,
    outsidePress = false,
    focusTarget,
    gap = GAP,
    onOverlayClosed,
    openOnHover = false
}: ActionOverlayOptions = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
    const [labelId, setLabelId] = useState<string | undefined>();
    const [descriptionId, setDescriptionId] = useState<string | undefined>();
    const arrowRef = useRef(null);

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const data = useFloating({
        placement,
        open,
        onOpenChange(isOpen) {
            setOpen(isOpen);
        },
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(ARROW_HEIGHT + gap),
            flip({
                crossAxis: placement.includes('-'),
                fallbackAxisSideDirection: 'end',
                padding: 5
            }),
            shift({padding: 5}),
            arrow({
                element: arrowRef
            }),
            matchTriggerWidth
                ? size({
                      apply({rects, elements}) {
                          Object.assign(elements.floating.style, {
                              width: `${rects.reference.width}px`
                          });
                      }
                  })
                : null
        ]
    });

    useEffect(() => {
        if (open && isDefined(focusTarget) && shouldFocus) {
            focusTarget?.current?.focus();
        }
        if (!open && isDefined(onOverlayClosed)) {
            onOverlayClosed();
        }
    }, [open, focusTarget, onOverlayClosed, shouldFocus]);

    const context = data.context;

    const click = useClick(context, {
        enabled: !openOnHover && controlledOpen == null,
        keyboardHandlers: false
    });
    const dismiss = useDismiss(context, {outsidePress});
    const role = useRole(context);
    const focus = useFocus(context, {
        enabled: shouldFocus
    });
    const hover = useHover(context, {
        move: false,
        handleClose: safePolygon(),
        enabled: controlledOpen == null && openOnHover
    });

    const interactions = useInteractions([click, dismiss, role, focus, hover]);
    return useMemo(
        () => ({
            open,
            setOpen,
            hasArrow,
            openOnHover,
            matchTriggerWidth,
            arrowRef,
            ...interactions,
            ...data,
            labelId,
            descriptionId,
            setLabelId,
            setDescriptionId
        }),
        [
            open,
            setOpen,
            hasArrow,
            openOnHover,
            matchTriggerWidth,
            interactions,
            data,
            labelId,
            descriptionId
        ]
    );
}
