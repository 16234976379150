import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {BodyP2} from '@/componentLibrary/tokens/typography';

export const OuterWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 114px;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        padding: 0;
    }
`;

export const Row = styled.div`
    height: 100%;
    width: 100%;

    @media only screen and (min-width: 1680px) {
        width: 1000px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column;
        justify-content: flex-start;
        padding: 0;
        width: 100%;
    }
`;

export const Column = styled.div`
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 290px;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 100%;
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        &:first-child {
            margin-top: 48px;
            height: auto;
            margin-bottom: 20px;
        }
    }
`;

export const TitleText = styled.div`
    ${BodyP2};
    font-size: 60px;
    line-height: 58px;
    letter-spacing: -3px;
    color: ${greyColorTokens.grey000()};
    margin-bottom: 20px;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        font-size: 48px;
        line-height: 48px;
    }
`;
