import {useCallback, useMemo} from 'react';

import {languageItems} from './languages';
import {SelectLanguageProps} from './types';

export function useSelectLanguageLogic(
    setLanguage: SelectLanguageProps['setLanguage'],
    language: string
) {
    const onLanguageChange = useCallback(item => setLanguage(item?.id ?? null), [setLanguage]);

    const onRatherNotSay = useCallback(value => setLanguage(value), [setLanguage]);

    const selectedLanguage = useMemo(() => {
        return languageItems.find(lang => lang.id === language);
    }, [language]);

    return {onLanguageChange, onRatherNotSay, languageItems, selectedLanguage};
}
