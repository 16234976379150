import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {
    PERSONALITY_FACET_MESSAGES,
    PersonalityFacetKey
} from '@/componentLibrary/blocks/testReports/PersonalityTest/components/FactorFacets/constants';
import {Legend} from '@/componentLibrary/components/typography/Legend';
import {SpacingXlarge} from '@/componentLibrary/tokens/variables';
import {TestProfileRangesProps} from '@/pages/Organization/pages/Assessment/components/TestProfileGraph/types';

import messages from '../../messages';
import {TestProfileRangeTable} from './components/TestProfileRangeTable';

export function TestProfileRanges({
    testProfile,
    includeLogicTest,
    includePersonalityTest
}: TestProfileRangesProps) {
    const intl = useIntl();

    function getFacetName(facet: PersonalityFacetKey | null | undefined) {
        const facetMessages = facet ? PERSONALITY_FACET_MESSAGES[facet] : null;
        if (!facetMessages) {
            console.warn(`No messages found for facet: ${facet}`);
            return '';
        }
        return `${intl.formatMessage(facetMessages?.factor)}: ${intl.formatMessage(
            facetMessages?.title
        )}`;
    }

    function renderLogicProfile() {
        if (!includeLogicTest) {
            return null;
        }

        const items = [
            {
                label: <FormattedMessage {...messages.logicalAbility} />,
                lower: testProfile?.logicCriterion?.lower,
                upper: testProfile?.logicCriterion?.upper
            }
        ];

        return (
            <Wrapper>
                <Legend>
                    <FormattedMessage {...messages.logicTest} />
                </Legend>
                <TestProfileRangeTable items={items} />
            </Wrapper>
        );
    }

    function renderPersonalityProfile() {
        if (!includePersonalityTest) {
            return null;
        }
        const items = testProfile?.facetCriteria?.map(facetCriterion => {
            const label = getFacetName(facetCriterion?.facet as PersonalityFacetKey);

            return {
                label,
                lower: Math.floor(facetCriterion?.lower),
                upper: Math.ceil(facetCriterion?.upper)
            };
        });

        return (
            <Wrapper>
                <Legend>
                    <FormattedMessage {...messages.personalityTest} />
                </Legend>

                <TestProfileRangeTable items={items} />
            </Wrapper>
        );
    }

    return (
        <div>
            {renderLogicProfile()}
            {renderPersonalityProfile()}
        </div>
    );
}

const Wrapper = styled.div`
    margin: ${SpacingXlarge} 0;
`;
