import {JobPosition} from '@/api/assessment/jobPositions/useJobPosition';

import {
    PERSONALITY_FACET_MESSAGES,
    PersonalityFacetKey
} from '@/componentLibrary/blocks/testReports/PersonalityTest/components/FactorFacets/constants';
import {secondaryColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useCallback, useMemo} from 'react';

import messages from './messages';
import {DetailsItem} from './types';

export function useAssessmentProfileDetailsLogic(jobPosition: JobPosition) {
    const getFacetMessage = useCallback((facet: PersonalityFacetKey) => {
        return PERSONALITY_FACET_MESSAGES[facet].title;
    }, []);

    const detailItems = useMemo(() => {
        const items: DetailsItem[] = [];

        if (!isDefined(jobPosition)) {
            return items;
        }

        const {
            requireLogicTest,
            requireLogicIrtTest,
            requirePersonalityTest,
            selectedTestProfile
        } = jobPosition;

        const facetCriteria =
            selectedTestProfile && selectedTestProfile.facetCriteria
                ? selectedTestProfile.facetCriteria
                : [];

        if (requirePersonalityTest) {
            items.push({
                titleMessage: messages.personalityTest,
                listItems: facetCriteria.map(criterion =>
                    getFacetMessage(criterion.facet as PersonalityFacetKey)
                ),
                bulletColor: secondaryColorTokens.blue000(),
                formatMessage: true
            });
        }

        if (requireLogicTest || requireLogicIrtTest) {
            items.push({
                titleMessage: messages.logicTest,
                listItems: [messages.logicalAbility],
                bulletColor: secondaryColorTokens.brown000(),
                formatMessage: true
            });
        }

        return items;
    }, [jobPosition, getFacetMessage]);

    return {detailItems};
}
