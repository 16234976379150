import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {userDemographicsMessages} from '@/pages/UserDemographics/messages';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {ButtonWrapper} from '@/pages/UserDemographics/components/UserDemographicsForm/styled';
import {UserDemographicsFormProps} from '@/pages/UserDemographics/components/UserDemographicsForm/types';
import {SelectBirthYear} from './components/SelectBirthYear';
import {SelectEmploymentStatus} from './components/SelectEmploymentStatus';
import {SelectGender} from './components/SelectGender';
import {SelectLanguage} from './components/SelectLanguage';
import {SelectLevelOfEducation} from './components/SelectLevelOfEducation';
import {useUserDemographicsFormLogic} from './logic';

export function UserDemographicsForm({
    redirect,
    isMandatory,
    onSubmitSuccess
}: UserDemographicsFormProps) {
    const {
        language,
        setLanguage,
        birthYear,
        setBirthYear,
        gender,
        setGender,
        employmentStatus,
        setEmploymentStatus,
        levelOfEducation,
        setLevelOfEducation,
        inputsAreValid,
        submitForm,
        loading
    } = useUserDemographicsFormLogic(redirect, onSubmitSuccess);
    const phrases = useExtractPhraseConstants(userDemographicsMessages);

    return (
        <form onSubmit={submitForm}>
            <SelectLanguage language={language as string} setLanguage={setLanguage} />
            <SelectBirthYear birthYear={birthYear} setBirthYear={setBirthYear} />
            <SelectGender gender={gender as string} setGender={setGender} />
            <SelectEmploymentStatus
                employmentStatus={employmentStatus as string}
                setEmploymentStatus={setEmploymentStatus}
            />
            <SelectLevelOfEducation
                levelOfEducation={levelOfEducation as string}
                setLevelOfEducation={setLevelOfEducation}
            />

            <ButtonWrapper>
                <PrimaryButton
                    type="submit"
                    isLoading={loading}
                    disabled={!inputsAreValid}
                    data-testid="demographics-save-button"
                >
                    {phrases.save}
                </PrimaryButton>
                {!isMandatory ? (
                    <SecondaryButton
                        onClick={redirect}
                        data-testid="demographics-fill-in-later"
                    >
                        {phrases.fillInLater}
                    </SecondaryButton>
                ) : null}
            </ButtonWrapper>
        </form>
    );
}
