import PropTypes from 'prop-types';
import {useCallback} from 'react';
import styled from 'styled-components';

import {LogicTestResults} from './components/LogicTestResults';
import {PersonalityTestResults} from './components/PersonalityTestResults';
import messages from './messages';
import {calculateScoreFromTestProfileBounds} from './utils';

export function TestResults({
    testProfile,
    jobApplication,
    loading,
    isPersonalityRequired,
    isLogicRequired,
    isOldLogicRequired
}) {
    const calculateProfileLabel = useCallback((lowerBound, upperBound, estimate) => {
        const score = calculateScoreFromTestProfileBounds({
            result: estimate,
            lowerBound,
            upperBound
        });
        if (score === 1) {
            return messages.matchesProfile;
        }

        if (score === 0.75 && estimate < lowerBound) {
            return messages.slightlyBelowProfile;
        }

        if (score === 0.75 && estimate > upperBound) {
            return messages.slightlyAboveProfile;
        }

        if (estimate < lowerBound) {
            return messages.belowProfile;
        }

        return messages.aboveProfile;
    }, []);

    return (
        <Wrapper>
            {isPersonalityRequired || loading ? (
                <PersonalityTestResults
                    calculateProfileLabel={calculateProfileLabel}
                    loading={loading}
                    jobApplication={jobApplication}
                    testProfile={testProfile}
                />
            ) : null}

            {isLogicRequired || isOldLogicRequired || loading ? (
                <LogicTestResults
                    calculateProfileLabel={calculateProfileLabel}
                    loading={loading}
                    testProfile={testProfile}
                    oldLogicTest={isOldLogicRequired}
                    jobApplication={jobApplication}
                />
            ) : null}
        </Wrapper>
    );
}

TestResults.propTypes = {
    isLogicRequired: PropTypes.bool,
    isOldLogicRequired: PropTypes.bool,
    isPersonalityRequired: PropTypes.bool,
    testProfile: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        facetCriteria: PropTypes.array,
        logicCriterion: PropTypes.object,
        personalityWeight: PropTypes.number,
        logicWeight: PropTypes.number
    }),
    jobApplication: PropTypes.shape({
        user: PropTypes.shape({
            id: PropTypes.number,
            personalityIrtResult: PropTypes.object,
            latestLogicTest: PropTypes.object,
            logicalAbilityEstimate: PropTypes.object
        }),
        roleFit: PropTypes.shape({
            score: PropTypes.number,
            interviewScore: PropTypes.number,
            testProfileScore: PropTypes.number,
            testProfilePersonalityScore: PropTypes.number,
            testProfileLogicScore: PropTypes.number,
            personalityDetails: PropTypes.arrayOf(
                PropTypes.shape({
                    facetName: PropTypes.string,
                    score: PropTypes.number,
                    label: PropTypes.string
                })
            ),
            logicDetails: PropTypes.object
        })
    }),
    loading: PropTypes.bool
};

const Wrapper = styled.div`
    margin-bottom: 52px;

    & > :first-child {
        margin-bottom: 39px;
    }
`;
