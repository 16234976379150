import {defineMessages} from 'react-intl';

export default defineMessages({
    failedToRegisterUser: {
        id: 'app.signup.registerUser.failedToRegisterUser'
    },
    passwordTooWeak: {
        id: 'app.signup.registerUser.passwordTooWeak'
    },
    userAlreadyActivated: {
        id: 'app.signup.registerUser.userAlreadyActivated'
    }
});
