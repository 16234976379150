import {ExpandableInterviewInstructionDescription} from '@/pages/Organization/components/ExpandableInterviewInstructionDescription';
import {
    InterviewContent,
    useTranslateInterviewContentItem
} from '@/pages/Organization/hooks/useTranslatedInterviewContent';
import {Name, Wrapper} from './styled';

type Props = {
    item: InterviewContent;
};

export function ScorecardInstruction({item}: Props) {
    const translate = useTranslateInterviewContentItem();
    const translatedItem = translate(item);

    return (
        <>
            <Wrapper>
                <Name>{translatedItem.name}</Name>
                <ExpandableInterviewInstructionDescription item={translatedItem} />
            </Wrapper>
            <div />
        </>
    );
}
