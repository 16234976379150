import {Redirect, Route, RouteComponentProps, Switch} from 'react-router-dom';

import {useUserOrganizations} from '@/api/users/useUserOrganizations';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {ChallengeAccessPage} from '@/pages/Organization/pages/Assessment/pages/CodingTest/components/ChallengeAccessPage';
import {VerifyAcceptedTerms} from '@/services/verifyAcceptedTerms';
import {parseGlobalId} from '@/utils/misc';

import {Body, Wrapper} from './components/PageTemplate';
import {StartHeader} from './components/PageTemplate/components/StartHeader';
import {START_INDEX_URL, userLinks} from './links';
import {CandidateSettings} from './pages/CandidateSettings';
import {JobApplication} from './pages/JobApplication';
import {MyApplications} from './pages/MyApplications';
import {MyJobs} from './pages/MyJobs/MyJobs';
import {MyOrganizations} from './pages/MyOrganizations';

type RouteParams = {
    organizationId: string;
};

export const StartPage = () => {
    const {userOrganizations, user, loading, error, refetch} = useUserOrganizations();

    if (loading) {
        return null;
    }

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    const showOrganizations = userOrganizations.some(({isEmployment}) => isEmployment);

    return (
        <Wrapper>
            <StartHeader user={user} userOrganizations={userOrganizations} />
            <Body>
                <Switch>
                    <Redirect
                        exact
                        from={START_INDEX_URL}
                        to={
                            showOrganizations
                                ? userLinks.myOrganizations()
                                : userLinks.myApplications()
                        }
                    />
                    <Route
                        exact
                        path={userLinks.myOrganizations()}
                        component={MyOrganizations}
                    />
                    <Route
                        exact
                        path={userLinks.myApplications()}
                        component={MyApplications}
                    />
                    <Route exact path={userLinks.myJobs()} component={MyJobs} />
                    <Route
                        exact
                        path={userLinks.accountSettings()}
                        component={CandidateSettings}
                    />
                    <Route
                        path={userLinks.jobApplication(':organizationId')}
                        component={({match}: RouteComponentProps<RouteParams>) => (
                            <JobApplication
                                organizationId={
                                    parseGlobalId(match.params.organizationId) as number
                                }
                            />
                        )}
                    />
                    <Route
                        exact
                        path={userLinks.codingTestAssignmentAccess(':id')}
                        component={ChallengeAccessPage}
                    />
                    <Route path="*">
                        <Redirect to={START_INDEX_URL} />
                    </Route>
                </Switch>
                <VerifyAcceptedTerms />
            </Body>
        </Wrapper>
    );
};
