import {Tooltip} from '@/componentLibrary/components/Tooltips/Tooltip';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {
    ColorFgAttention,
    ColorFgDanger,
    ColorFgSubtle
} from '@/componentLibrary/tokens/variables';

import {InfoBellProps} from './types';

export function InfoBell({tooltip, isWarning, isDanger, ...rest}: InfoBellProps) {
    let fill = ColorFgSubtle;
    if (isWarning) {
        fill = ColorFgAttention;
    } else if (isDanger) {
        fill = ColorFgDanger;
    }
    let dataTestIdType = 'base';
    if (isWarning) {
        dataTestIdType = 'warning';
    }
    if (isDanger) {
        dataTestIdType = 'danger';
    }

    const dataTestId = `${rest['data-testid'] || 'info-bell'}-${dataTestIdType}`;

    return (
        <Tooltip
            hasArrow
            content={tooltip}
            trigger={<Icon icon={Icons.NOTIFICATIONS} fill={fill} data-testid={dataTestId} />}
        />
    );
}
