import {defineMessages} from 'react-intl';

export default defineMessages({
    errorNoUrl: {
        id: 'app.jobRecommendations.handleResume.input.linkedIn.error.noURL'
    },
    errorInvalidUrl: {
        id: 'app.jobRecommendations.handleResume.input.linkedIn.error.invalidURL'
    },
    continue: {
        id: 'app.general.continue'
    },
    label: {
        id: 'app.jobRecommendations.handleResume.input.linkedIn.label'
    },
    linkLabel: {
        id: 'app.jobRecommendations.handleResume.input.link.getLinkedInURL'
    },
    giveFeedback: {id: 'app.jobRecommendations.handleResume.input.giveFeedback'},
    submitResumeTitle: {
        id: 'app.jobRecommendations.handleResume.title.apply'
    }
});
