import {defineMessages} from 'react-intl';

export default defineMessages({
    leadershipReportTitle: {id: 'leadershipReport.printTitle'},
    ACHIEVING_RESULTS: {id: 'leadershipReport.factor.achievingResults.title'},
    INFLUENCING_OTHERS: {id: 'leadershipReport.factor.influencingOthers.title'},
    NAVIGATING: {id: 'leadershipReport.factor.navigating.title'},
    helperText: {id: 'personalityTestReport.anchors.helperText'},
    perceivedByOthers: {id: 'leadershipReport.percievedByOthers'},
    CHALLENGE: {
        id: 'leadershipReport.result.challenge'
    },
    STRENGTH: {
        id: 'leadershipReport.result.strength'
    },
    SUFFICIENT: {
        id: 'leadershipReport.result.sufficient'
    },
    basedOnFacets: {
        id: 'leadershipReport.result.basedOnFacets'
    },
    translationsMissingTitle: {
        id: 'leadershipReport.translationsMissing.title'
    },
    translationsMissingDescription: {
        id: 'leadershipReport.translationsMissing.description'
    },
    developmentPlanTitle: {
        id: 'leadershipReport.developmentPlan.download.title'
    },
    developmentPlanDescription: {
        id: 'leadershipReport.developmentPlan.download.description'
    },
    developmentPlanAction: {
        id: 'leadershipReport.developmentPlan.download.action'
    }
});

export const FACET_MESSAGES = defineMessages({
    'AI.labelLower': {id: 'personalityIrtFacet.AI.labelLower'},
    'AI.labelUpper': {id: 'personalityIrtFacet.AI.labelUpper'},
    'AI.title': {id: 'personalityIrtFacet.AI.title'},
    'AI.factor': {id: 'personalityIrtFacet.AI.factor'},
    'AII.labelLower': {id: 'personalityIrtFacet.AII.labelLower'},
    'AII.labelUpper': {id: 'personalityIrtFacet.AII.labelUpper'},
    'AII.title': {id: 'personalityIrtFacet.AII.title'},
    'AII.factor': {id: 'personalityIrtFacet.AII.factor'},
    'AIII.labelLower': {id: 'personalityIrtFacet.AIII.labelLower'},
    'AIII.labelUpper': {id: 'personalityIrtFacet.AIII.labelUpper'},
    'AIII.title': {id: 'personalityIrtFacet.AIII.title'},
    'AIII.factor': {id: 'personalityIrtFacet.AIII.factor'},
    'CI.labelLower': {id: 'personalityIrtFacet.CI.labelLower'},
    'CI.labelUpper': {id: 'personalityIrtFacet.CI.labelUpper'},
    'CI.title': {id: 'personalityIrtFacet.CI.title'},
    'CI.factor': {id: 'personalityIrtFacet.CI.factor'},
    'CII.labelLower': {id: 'personalityIrtFacet.CII.labelLower'},
    'CII.labelUpper': {id: 'personalityIrtFacet.CII.labelUpper'},
    'CII.title': {id: 'personalityIrtFacet.CII.title'},
    'CII.factor': {id: 'personalityIrtFacet.CII.factor'},
    'CIII.labelLower': {id: 'personalityIrtFacet.CIII.labelLower'},
    'CIII.labelUpper': {id: 'personalityIrtFacet.CIII.labelUpper'},
    'CIII.title': {id: 'personalityIrtFacet.CIII.title'},
    'CIII.factor': {id: 'personalityIrtFacet.CIII.factor'},
    'EI.labelLower': {id: 'personalityIrtFacet.EI.labelLower'},
    'EI.labelUpper': {id: 'personalityIrtFacet.EI.labelUpper'},
    'EI.title': {id: 'personalityIrtFacet.EI.title'},
    'EI.factor': {id: 'personalityIrtFacet.EI.factor'},
    'EII.labelLower': {id: 'personalityIrtFacet.EII.labelLower'},
    'EII.labelUpper': {id: 'personalityIrtFacet.EII.labelUpper'},
    'EII.title': {id: 'personalityIrtFacet.EII.title'},
    'EII.factor': {id: 'personalityIrtFacet.EII.factor'},
    'EIII.labelLower': {id: 'personalityIrtFacet.EIII.labelLower'},
    'EIII.labelUpper': {id: 'personalityIrtFacet.EIII.labelUpper'},
    'EIII.title': {id: 'personalityIrtFacet.EIII.title'},
    'EIII.factor': {id: 'personalityIrtFacet.EIII.factor'},
    'ESI.labelLower': {id: 'personalityIrtFacet.ESI.labelLower'},
    'ESI.labelUpper': {id: 'personalityIrtFacet.ESI.labelUpper'},
    'ESI.title': {id: 'personalityIrtFacet.ESI.title'},
    'ESI.factor': {id: 'personalityIrtFacet.ESI.factor'},
    'ESII.labelLower': {id: 'personalityIrtFacet.ESII.labelLower'},
    'ESII.labelUpper': {id: 'personalityIrtFacet.ESII.labelUpper'},
    'ESII.title': {id: 'personalityIrtFacet.ESII.title'},
    'ESII.factor': {id: 'personalityIrtFacet.ESII.factor'},
    'ESIII.labelLower': {id: 'personalityIrtFacet.ESIII.labelLower'},
    'ESIII.labelUpper': {id: 'personalityIrtFacet.ESIII.labelUpper'},
    'ESIII.title': {id: 'personalityIrtFacet.ESIII.title'},
    'ESIII.factor': {id: 'personalityIrtFacet.ESIII.factor'},
    'OI.labelLower': {id: 'personalityIrtFacet.OI.labelLower'},
    'OI.labelUpper': {id: 'personalityIrtFacet.OI.labelUpper'},
    'OI.title': {id: 'personalityIrtFacet.OI.title'},
    'OI.factor': {id: 'personalityIrtFacet.OI.factor'},
    'OII.labelLower': {id: 'personalityIrtFacet.OII.labelLower'},
    'OII.labelUpper': {id: 'personalityIrtFacet.OII.labelUpper'},
    'OII.title': {id: 'personalityIrtFacet.OII.title'},
    'OII.factor': {id: 'personalityIrtFacet.OII.factor'},
    'OIII.labelLower': {id: 'personalityIrtFacet.OIII.labelLower'},
    'OIII.labelUpper': {id: 'personalityIrtFacet.OIII.labelUpper'},
    'OIII.title': {id: 'personalityIrtFacet.OIII.title'},
    'OIII.factor': {id: 'personalityIrtFacet.OIII.factor'}
});

export type FACET_MESSAGES_TYPE = keyof typeof FACET_MESSAGES;

export const DECISION_MAKING_MESSAGES = defineMessages({
    definitionDescription: {
        id: 'leadershipReport.facet.decisionMaking.definition.description'
    },
    definitionTitle: {id: 'leadershipReport.facet.decisionMaking.definition.title'},
    title: {id: 'leadershipReport.facet.decisionMaking.title'},
    'EI-1_OI-1.description': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI-1_OI-1.description'
    },
    'EI-1_OI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI-1_OI-1.perceivedByReports'
    },
    'EI-1_OI0.description': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI-1_OI0.description'
    },
    'EI-1_OI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI-1_OI0.perceivedByReports'
    },
    'EI-1_OI1.description': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI-1_OI1.description'
    },
    'EI-1_OI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI-1_OI1.perceivedByReports'
    },
    'EI0_OI-1.description': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI0_OI-1.description'
    },
    'EI0_OI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI0_OI-1.perceivedByReports'
    },
    'EI0_OI0.description': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI0_OI0.description'
    },
    'EI0_OI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI0_OI0.perceivedByReports'
    },
    'EI0_OI1.description': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI0_OI1.description'
    },
    'EI0_OI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI0_OI1.perceivedByReports'
    },
    'EI1_OI-1.description': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI1_OI-1.description'
    },
    'EI1_OI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI1_OI-1.perceivedByReports'
    },
    'EI1_OI0.description': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI1_OI0.description'
    },
    'EI1_OI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI1_OI0.perceivedByReports'
    },
    'EI1_OI1.description': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI1_OI1.description'
    },
    'EI1_OI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DECISION_MAKING.EI1_OI1.perceivedByReports'
    }
});
export const DEFINING_THE_DIRECTION_MESSAGES = defineMessages({
    definitionDescription: {
        id: 'leadershipReport.facet.definingTheDirection.definition.description'
    },
    definitionTitle: {id: 'leadershipReport.facet.definingTheDirection.definition.title'},
    title: {id: 'leadershipReport.facet.definingTheDirection.title'},
    'CII-1_CIII-1_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII-1_ESIII-1.description'
    },
    'CII-1_CIII-1_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII-1_ESIII-1.perceivedByReports'
    },
    'CII-1_CIII-1_ESIII0.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII-1_ESIII0.description'
    },
    'CII-1_CIII-1_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII-1_ESIII0.perceivedByReports'
    },
    'CII-1_CIII-1_ESIII1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII-1_ESIII1.description'
    },
    'CII-1_CIII-1_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII-1_ESIII1.perceivedByReports'
    },
    'CII-1_CIII0_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII0_ESIII-1.description'
    },
    'CII-1_CIII0_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII0_ESIII-1.perceivedByReports'
    },
    'CII-1_CIII0_ESIII0.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII0_ESIII0.description'
    },
    'CII-1_CIII0_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII0_ESIII0.perceivedByReports'
    },
    'CII-1_CIII0_ESIII1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII0_ESIII1.description'
    },
    'CII-1_CIII0_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII0_ESIII1.perceivedByReports'
    },
    'CII-1_CIII1_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII1_ESIII-1.description'
    },
    'CII-1_CIII1_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII1_ESIII-1.perceivedByReports'
    },
    'CII-1_CIII1_ESIII0.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII1_ESIII0.description'
    },
    'CII-1_CIII1_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII1_ESIII0.perceivedByReports'
    },
    'CII-1_CIII1_ESIII1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII1_ESIII1.description'
    },
    'CII-1_CIII1_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII-1_CIII1_ESIII1.perceivedByReports'
    },
    'CII0_CIII-1_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII-1_ESIII-1.description'
    },
    'CII0_CIII-1_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII-1_ESIII-1.perceivedByReports'
    },
    'CII0_CIII-1_ESIII0.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII-1_ESIII0.description'
    },
    'CII0_CIII-1_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII-1_ESIII0.perceivedByReports'
    },
    'CII0_CIII-1_ESIII1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII-1_ESIII1.description'
    },
    'CII0_CIII-1_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII-1_ESIII1.perceivedByReports'
    },
    'CII0_CIII0_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII0_ESIII-1.description'
    },
    'CII0_CIII0_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII0_ESIII-1.perceivedByReports'
    },
    'CII0_CIII0_ESIII0.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII0_ESIII0.description'
    },
    'CII0_CIII0_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII0_ESIII0.perceivedByReports'
    },
    'CII0_CIII0_ESIII1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII0_ESIII1.description'
    },
    'CII0_CIII0_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII0_ESIII1.perceivedByReports'
    },
    'CII0_CIII1_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII1_ESIII-1.description'
    },
    'CII0_CIII1_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII1_ESIII-1.perceivedByReports'
    },
    'CII0_CIII1_ESIII0.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII1_ESIII0.description'
    },
    'CII0_CIII1_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII1_ESIII0.perceivedByReports'
    },
    'CII0_CIII1_ESIII1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII1_ESIII1.description'
    },
    'CII0_CIII1_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII0_CIII1_ESIII1.perceivedByReports'
    },
    'CII1_CIII-1_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII-1_ESIII-1.description'
    },
    'CII1_CIII-1_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII-1_ESIII-1.perceivedByReports'
    },
    'CII1_CIII-1_ESIII0.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII-1_ESIII0.description'
    },
    'CII1_CIII-1_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII-1_ESIII0.perceivedByReports'
    },
    'CII1_CIII-1_ESIII1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII-1_ESIII1.description'
    },
    'CII1_CIII-1_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII-1_ESIII1.perceivedByReports'
    },
    'CII1_CIII0_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII0_ESIII-1.description'
    },
    'CII1_CIII0_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII0_ESIII-1.perceivedByReports'
    },
    'CII1_CIII0_ESIII0.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII0_ESIII0.description'
    },
    'CII1_CIII0_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII0_ESIII0.perceivedByReports'
    },
    'CII1_CIII0_ESIII1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII0_ESIII1.description'
    },
    'CII1_CIII0_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII0_ESIII1.perceivedByReports'
    },
    'CII1_CIII1_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII1_ESIII-1.description'
    },
    'CII1_CIII1_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII1_ESIII-1.perceivedByReports'
    },
    'CII1_CIII1_ESIII0.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII1_ESIII0.description'
    },
    'CII1_CIII1_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII1_ESIII0.perceivedByReports'
    },
    'CII1_CIII1_ESIII1.description': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII1_ESIII1.description'
    },
    'CII1_CIII1_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DEFINING_THE_DIRECTION.CII1_CIII1_ESIII1.perceivedByReports'
    }
});
export const DRIVING_TOWARDS_RESULTS_MESSAGES = defineMessages({
    definitionDescription: {
        id: 'leadershipReport.facet.drivingTowardsResults.definition.description'
    },
    definitionTitle: {id: 'leadershipReport.facet.drivingTowardsResults.definition.title'},
    title: {id: 'leadershipReport.facet.drivingTowardsResults.title'},
    'AII-1_CI-1_EI-1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI-1_EI-1.description'
    },
    'AII-1_CI-1_EI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI-1_EI-1.perceivedByReports'
    },
    'AII-1_CI-1_EI0.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI-1_EI0.description'
    },
    'AII-1_CI-1_EI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI-1_EI0.perceivedByReports'
    },
    'AII-1_CI-1_EI1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI-1_EI1.description'
    },
    'AII-1_CI-1_EI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI-1_EI1.perceivedByReports'
    },
    'AII-1_CI0_EI-1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI0_EI-1.description'
    },
    'AII-1_CI0_EI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI0_EI-1.perceivedByReports'
    },
    'AII-1_CI0_EI0.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI0_EI0.description'
    },
    'AII-1_CI0_EI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI0_EI0.perceivedByReports'
    },
    'AII-1_CI0_EI1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI0_EI1.description'
    },
    'AII-1_CI0_EI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI0_EI1.perceivedByReports'
    },
    'AII-1_CI1_EI-1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI1_EI-1.description'
    },
    'AII-1_CI1_EI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI1_EI-1.perceivedByReports'
    },
    'AII-1_CI1_EI0.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI1_EI0.description'
    },
    'AII-1_CI1_EI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI1_EI0.perceivedByReports'
    },
    'AII-1_CI1_EI1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI1_EI1.description'
    },
    'AII-1_CI1_EI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII-1_CI1_EI1.perceivedByReports'
    },
    'AII0_CI-1_EI-1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI-1_EI-1.description'
    },
    'AII0_CI-1_EI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI-1_EI-1.perceivedByReports'
    },
    'AII0_CI-1_EI0.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI-1_EI0.description'
    },
    'AII0_CI-1_EI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI-1_EI0.perceivedByReports'
    },
    'AII0_CI-1_EI1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI-1_EI1.description'
    },
    'AII0_CI-1_EI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI-1_EI1.perceivedByReports'
    },
    'AII0_CI0_EI-1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI0_EI-1.description'
    },
    'AII0_CI0_EI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI0_EI-1.perceivedByReports'
    },
    'AII0_CI0_EI0.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI0_EI0.description'
    },
    'AII0_CI0_EI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI0_EI0.perceivedByReports'
    },
    'AII0_CI0_EI1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI0_EI1.description'
    },
    'AII0_CI0_EI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI0_EI1.perceivedByReports'
    },
    'AII0_CI1_EI-1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI1_EI-1.description'
    },
    'AII0_CI1_EI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI1_EI-1.perceivedByReports'
    },
    'AII0_CI1_EI0.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI1_EI0.description'
    },
    'AII0_CI1_EI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI1_EI0.perceivedByReports'
    },
    'AII0_CI1_EI1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI1_EI1.description'
    },
    'AII0_CI1_EI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII0_CI1_EI1.perceivedByReports'
    },
    'AII1_CI-1_EI-1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI-1_EI-1.description'
    },
    'AII1_CI-1_EI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI-1_EI-1.perceivedByReports'
    },
    'AII1_CI-1_EI0.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI-1_EI0.description'
    },
    'AII1_CI-1_EI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI-1_EI0.perceivedByReports'
    },
    'AII1_CI-1_EI1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI-1_EI1.description'
    },
    'AII1_CI-1_EI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI-1_EI1.perceivedByReports'
    },
    'AII1_CI0_EI-1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI0_EI-1.description'
    },
    'AII1_CI0_EI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI0_EI-1.perceivedByReports'
    },
    'AII1_CI0_EI0.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI0_EI0.description'
    },
    'AII1_CI0_EI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI0_EI0.perceivedByReports'
    },
    'AII1_CI0_EI1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI0_EI1.description'
    },
    'AII1_CI0_EI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI0_EI1.perceivedByReports'
    },
    'AII1_CI1_EI-1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI1_EI-1.description'
    },
    'AII1_CI1_EI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI1_EI-1.perceivedByReports'
    },
    'AII1_CI1_EI0.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI1_EI0.description'
    },
    'AII1_CI1_EI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI1_EI0.perceivedByReports'
    },
    'AII1_CI1_EI1.description': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI1_EI1.description'
    },
    'AII1_CI1_EI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.DRIVING_TOWARDS_RESULTS.AII1_CI1_EI1.perceivedByReports'
    }
});
export const GETTING_OTHERS_ONBOARD_MESSAGES = defineMessages({
    definitionDescription: {
        id: 'leadershipReport.facet.gettingOthersOnboard.definition.description'
    },
    definitionTitle: {id: 'leadershipReport.facet.gettingOthersOnboard.definition.title'},
    title: {id: 'leadershipReport.facet.gettingOthersOnboard.title'},
    'EII-1_EIII-1_OIII-1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII-1_OIII-1.description'
    },
    'EII-1_EIII-1_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII-1_OIII-1.perceivedByReports'
    },
    'EII-1_EIII-1_OIII0.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII-1_OIII0.description'
    },
    'EII-1_EIII-1_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII-1_OIII0.perceivedByReports'
    },
    'EII-1_EIII-1_OIII1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII-1_OIII1.description'
    },
    'EII-1_EIII-1_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII-1_OIII1.perceivedByReports'
    },
    'EII-1_EIII0_OIII-1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII0_OIII-1.description'
    },
    'EII-1_EIII0_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII0_OIII-1.perceivedByReports'
    },
    'EII-1_EIII0_OIII0.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII0_OIII0.description'
    },
    'EII-1_EIII0_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII0_OIII0.perceivedByReports'
    },
    'EII-1_EIII0_OIII1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII0_OIII1.description'
    },
    'EII-1_EIII0_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII0_OIII1.perceivedByReports'
    },
    'EII-1_EIII1_OIII-1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII1_OIII-1.description'
    },
    'EII-1_EIII1_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII1_OIII-1.perceivedByReports'
    },
    'EII-1_EIII1_OIII0.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII1_OIII0.description'
    },
    'EII-1_EIII1_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII1_OIII0.perceivedByReports'
    },
    'EII-1_EIII1_OIII1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII1_OIII1.description'
    },
    'EII-1_EIII1_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII-1_EIII1_OIII1.perceivedByReports'
    },
    'EII0_EIII-1_OIII-1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII-1_OIII-1.description'
    },
    'EII0_EIII-1_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII-1_OIII-1.perceivedByReports'
    },
    'EII0_EIII-1_OIII0.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII-1_OIII0.description'
    },
    'EII0_EIII-1_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII-1_OIII0.perceivedByReports'
    },
    'EII0_EIII-1_OIII1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII-1_OIII1.description'
    },
    'EII0_EIII-1_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII-1_OIII1.perceivedByReports'
    },
    'EII0_EIII0_OIII-1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII0_OIII-1.description'
    },
    'EII0_EIII0_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII0_OIII-1.perceivedByReports'
    },
    'EII0_EIII0_OIII0.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII0_OIII0.description'
    },
    'EII0_EIII0_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII0_OIII0.perceivedByReports'
    },
    'EII0_EIII0_OIII1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII0_OIII1.description'
    },
    'EII0_EIII0_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII0_OIII1.perceivedByReports'
    },
    'EII0_EIII1_OIII-1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII1_OIII-1.description'
    },
    'EII0_EIII1_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII1_OIII-1.perceivedByReports'
    },
    'EII0_EIII1_OIII0.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII1_OIII0.description'
    },
    'EII0_EIII1_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII1_OIII0.perceivedByReports'
    },
    'EII0_EIII1_OIII1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII1_OIII1.description'
    },
    'EII0_EIII1_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII0_EIII1_OIII1.perceivedByReports'
    },
    'EII1_EIII-1_OIII-1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII-1_OIII-1.description'
    },
    'EII1_EIII-1_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII-1_OIII-1.perceivedByReports'
    },
    'EII1_EIII-1_OIII0.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII-1_OIII0.description'
    },
    'EII1_EIII-1_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII-1_OIII0.perceivedByReports'
    },
    'EII1_EIII-1_OIII1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII-1_OIII1.description'
    },
    'EII1_EIII-1_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII-1_OIII1.perceivedByReports'
    },
    'EII1_EIII0_OIII-1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII0_OIII-1.description'
    },
    'EII1_EIII0_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII0_OIII-1.perceivedByReports'
    },
    'EII1_EIII0_OIII0.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII0_OIII0.description'
    },
    'EII1_EIII0_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII0_OIII0.perceivedByReports'
    },
    'EII1_EIII0_OIII1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII0_OIII1.description'
    },
    'EII1_EIII0_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII0_OIII1.perceivedByReports'
    },
    'EII1_EIII1_OIII-1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII1_OIII-1.description'
    },
    'EII1_EIII1_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII1_OIII-1.perceivedByReports'
    },
    'EII1_EIII1_OIII0.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII1_OIII0.description'
    },
    'EII1_EIII1_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII1_OIII0.perceivedByReports'
    },
    'EII1_EIII1_OIII1.description': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII1_OIII1.description'
    },
    'EII1_EIII1_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.GETTING_OTHERS_ONBOARD.EII1_EIII1_OIII1.perceivedByReports'
    }
});
export const HELPING_OTHERS_GROW_MESSAGES = defineMessages({
    definitionDescription: {
        id: 'leadershipReport.facet.helpingOthersGrow.definition.description'
    },
    definitionTitle: {id: 'leadershipReport.facet.helpingOthersGrow.definition.title'},
    title: {id: 'leadershipReport.facet.helpingOthersGrow.title'},
    'AI-1_AII-1_CII-1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII-1_CII-1.description'
    },
    'AI-1_AII-1_CII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII-1_CII-1.perceivedByReports'
    },
    'AI-1_AII-1_CII0.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII-1_CII0.description'
    },
    'AI-1_AII-1_CII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII-1_CII0.perceivedByReports'
    },
    'AI-1_AII-1_CII1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII-1_CII1.description'
    },
    'AI-1_AII-1_CII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII-1_CII1.perceivedByReports'
    },
    'AI-1_AII0_CII-1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII0_CII-1.description'
    },
    'AI-1_AII0_CII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII0_CII-1.perceivedByReports'
    },
    'AI-1_AII0_CII0.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII0_CII0.description'
    },
    'AI-1_AII0_CII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII0_CII0.perceivedByReports'
    },
    'AI-1_AII0_CII1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII0_CII1.description'
    },
    'AI-1_AII0_CII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII0_CII1.perceivedByReports'
    },
    'AI-1_AII1_CII-1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII1_CII-1.description'
    },
    'AI-1_AII1_CII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII1_CII-1.perceivedByReports'
    },
    'AI-1_AII1_CII0.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII1_CII0.description'
    },
    'AI-1_AII1_CII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII1_CII0.perceivedByReports'
    },
    'AI-1_AII1_CII1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII1_CII1.description'
    },
    'AI-1_AII1_CII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI-1_AII1_CII1.perceivedByReports'
    },
    'AI0_AII-1_CII-1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII-1_CII-1.description'
    },
    'AI0_AII-1_CII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII-1_CII-1.perceivedByReports'
    },
    'AI0_AII-1_CII0.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII-1_CII0.description'
    },
    'AI0_AII-1_CII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII-1_CII0.perceivedByReports'
    },
    'AI0_AII-1_CII1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII-1_CII1.description'
    },
    'AI0_AII-1_CII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII-1_CII1.perceivedByReports'
    },
    'AI0_AII0_CII-1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII0_CII-1.description'
    },
    'AI0_AII0_CII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII0_CII-1.perceivedByReports'
    },
    'AI0_AII0_CII0.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII0_CII0.description'
    },
    'AI0_AII0_CII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII0_CII0.perceivedByReports'
    },
    'AI0_AII0_CII1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII0_CII1.description'
    },
    'AI0_AII0_CII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII0_CII1.perceivedByReports'
    },
    'AI0_AII1_CII-1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII1_CII-1.description'
    },
    'AI0_AII1_CII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII1_CII-1.perceivedByReports'
    },
    'AI0_AII1_CII0.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII1_CII0.description'
    },
    'AI0_AII1_CII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII1_CII0.perceivedByReports'
    },
    'AI0_AII1_CII1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII1_CII1.description'
    },
    'AI0_AII1_CII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI0_AII1_CII1.perceivedByReports'
    },
    'AI1_AII-1_CII-1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII-1_CII-1.description'
    },
    'AI1_AII-1_CII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII-1_CII-1.perceivedByReports'
    },
    'AI1_AII-1_CII0.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII-1_CII0.description'
    },
    'AI1_AII-1_CII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII-1_CII0.perceivedByReports'
    },
    'AI1_AII-1_CII1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII-1_CII1.description'
    },
    'AI1_AII-1_CII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII-1_CII1.perceivedByReports'
    },
    'AI1_AII0_CII-1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII0_CII-1.description'
    },
    'AI1_AII0_CII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII0_CII-1.perceivedByReports'
    },
    'AI1_AII0_CII0.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII0_CII0.description'
    },
    'AI1_AII0_CII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII0_CII0.perceivedByReports'
    },
    'AI1_AII0_CII1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII0_CII1.description'
    },
    'AI1_AII0_CII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII0_CII1.perceivedByReports'
    },
    'AI1_AII1_CII-1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII1_CII-1.description'
    },
    'AI1_AII1_CII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII1_CII-1.perceivedByReports'
    },
    'AI1_AII1_CII0.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII1_CII0.description'
    },
    'AI1_AII1_CII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII1_CII0.perceivedByReports'
    },
    'AI1_AII1_CII1.description': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII1_CII1.description'
    },
    'AI1_AII1_CII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.HELPING_OTHERS_GROW.AI1_AII1_CII1.perceivedByReports'
    }
});
export const INNER_COMPASS_MESSAGES = defineMessages({
    definitionDescription: {id: 'leadershipReport.facet.innerCompass.definition.description'},
    definitionTitle: {id: 'leadershipReport.facet.innerCompass.definition.title'},
    title: {id: 'leadershipReport.facet.innerCompass.title'},
    'AI-1_CII-1_OI-1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII-1_OI-1.description'
    },
    'AI-1_CII-1_OI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII-1_OI-1.perceivedByReports'
    },
    'AI-1_CII-1_OI0.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII-1_OI0.description'
    },
    'AI-1_CII-1_OI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII-1_OI0.perceivedByReports'
    },
    'AI-1_CII-1_OI1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII-1_OI1.description'
    },
    'AI-1_CII-1_OI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII-1_OI1.perceivedByReports'
    },
    'AI-1_CII0_OI-1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII0_OI-1.description'
    },
    'AI-1_CII0_OI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII0_OI-1.perceivedByReports'
    },
    'AI-1_CII0_OI0.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII0_OI0.description'
    },
    'AI-1_CII0_OI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII0_OI0.perceivedByReports'
    },
    'AI-1_CII0_OI1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII0_OI1.description'
    },
    'AI-1_CII0_OI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII0_OI1.perceivedByReports'
    },
    'AI-1_CII1_OI-1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII1_OI-1.description'
    },
    'AI-1_CII1_OI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII1_OI-1.perceivedByReports'
    },
    'AI-1_CII1_OI0.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII1_OI0.description'
    },
    'AI-1_CII1_OI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII1_OI0.perceivedByReports'
    },
    'AI-1_CII1_OI1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII1_OI1.description'
    },
    'AI-1_CII1_OI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI-1_CII1_OI1.perceivedByReports'
    },
    'AI0_CII-1_OI-1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII-1_OI-1.description'
    },
    'AI0_CII-1_OI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII-1_OI-1.perceivedByReports'
    },
    'AI0_CII-1_OI0.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII-1_OI0.description'
    },
    'AI0_CII-1_OI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII-1_OI0.perceivedByReports'
    },
    'AI0_CII-1_OI1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII-1_OI1.description'
    },
    'AI0_CII-1_OI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII-1_OI1.perceivedByReports'
    },
    'AI0_CII0_OI-1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII0_OI-1.description'
    },
    'AI0_CII0_OI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII0_OI-1.perceivedByReports'
    },
    'AI0_CII0_OI0.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII0_OI0.description'
    },
    'AI0_CII0_OI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII0_OI0.perceivedByReports'
    },
    'AI0_CII0_OI1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII0_OI1.description'
    },
    'AI0_CII0_OI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII0_OI1.perceivedByReports'
    },
    'AI0_CII1_OI-1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII1_OI-1.description'
    },
    'AI0_CII1_OI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII1_OI-1.perceivedByReports'
    },
    'AI0_CII1_OI0.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII1_OI0.description'
    },
    'AI0_CII1_OI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII1_OI0.perceivedByReports'
    },
    'AI0_CII1_OI1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII1_OI1.description'
    },
    'AI0_CII1_OI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI0_CII1_OI1.perceivedByReports'
    },
    'AI1_CII-1_OI-1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII-1_OI-1.description'
    },
    'AI1_CII-1_OI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII-1_OI-1.perceivedByReports'
    },
    'AI1_CII-1_OI0.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII-1_OI0.description'
    },
    'AI1_CII-1_OI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII-1_OI0.perceivedByReports'
    },
    'AI1_CII-1_OI1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII-1_OI1.description'
    },
    'AI1_CII-1_OI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII-1_OI1.perceivedByReports'
    },
    'AI1_CII0_OI-1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII0_OI-1.description'
    },
    'AI1_CII0_OI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII0_OI-1.perceivedByReports'
    },
    'AI1_CII0_OI0.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII0_OI0.description'
    },
    'AI1_CII0_OI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII0_OI0.perceivedByReports'
    },
    'AI1_CII0_OI1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII0_OI1.description'
    },
    'AI1_CII0_OI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII0_OI1.perceivedByReports'
    },
    'AI1_CII1_OI-1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII1_OI-1.description'
    },
    'AI1_CII1_OI-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII1_OI-1.perceivedByReports'
    },
    'AI1_CII1_OI0.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII1_OI0.description'
    },
    'AI1_CII1_OI0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII1_OI0.perceivedByReports'
    },
    'AI1_CII1_OI1.description': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII1_OI1.description'
    },
    'AI1_CII1_OI1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.INNER_COMPASS.AI1_CII1_OI1.perceivedByReports'
    }
});
export const MANAGING_CHANGE_MESSAGES = defineMessages({
    definitionDescription: {
        id: 'leadershipReport.facet.managingChange.definition.description'
    },
    definitionTitle: {id: 'leadershipReport.facet.managingChange.definition.title'},
    title: {id: 'leadershipReport.facet.managingChange.title'},
    'CI-1_OI-1_OIII-1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI-1_OIII-1.description'
    },
    'CI-1_OI-1_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI-1_OIII-1.perceivedByReports'
    },
    'CI-1_OI-1_OIII0.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI-1_OIII0.description'
    },
    'CI-1_OI-1_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI-1_OIII0.perceivedByReports'
    },
    'CI-1_OI-1_OIII1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI-1_OIII1.description'
    },
    'CI-1_OI-1_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI-1_OIII1.perceivedByReports'
    },
    'CI-1_OI0_OIII-1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI0_OIII-1.description'
    },
    'CI-1_OI0_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI0_OIII-1.perceivedByReports'
    },
    'CI-1_OI0_OIII0.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI0_OIII0.description'
    },
    'CI-1_OI0_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI0_OIII0.perceivedByReports'
    },
    'CI-1_OI0_OIII1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI0_OIII1.description'
    },
    'CI-1_OI0_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI0_OIII1.perceivedByReports'
    },
    'CI-1_OI1_OIII-1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI1_OIII-1.description'
    },
    'CI-1_OI1_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI1_OIII-1.perceivedByReports'
    },
    'CI-1_OI1_OIII0.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI1_OIII0.description'
    },
    'CI-1_OI1_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI1_OIII0.perceivedByReports'
    },
    'CI-1_OI1_OIII1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI1_OIII1.description'
    },
    'CI-1_OI1_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI-1_OI1_OIII1.perceivedByReports'
    },
    'CI0_OI-1_OIII-1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI-1_OIII-1.description'
    },
    'CI0_OI-1_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI-1_OIII-1.perceivedByReports'
    },
    'CI0_OI-1_OIII0.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI-1_OIII0.description'
    },
    'CI0_OI-1_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI-1_OIII0.perceivedByReports'
    },
    'CI0_OI-1_OIII1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI-1_OIII1.description'
    },
    'CI0_OI-1_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI-1_OIII1.perceivedByReports'
    },
    'CI0_OI0_OIII-1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI0_OIII-1.description'
    },
    'CI0_OI0_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI0_OIII-1.perceivedByReports'
    },
    'CI0_OI0_OIII0.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI0_OIII0.description'
    },
    'CI0_OI0_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI0_OIII0.perceivedByReports'
    },
    'CI0_OI0_OIII1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI0_OIII1.description'
    },
    'CI0_OI0_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI0_OIII1.perceivedByReports'
    },
    'CI0_OI1_OIII-1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI1_OIII-1.description'
    },
    'CI0_OI1_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI1_OIII-1.perceivedByReports'
    },
    'CI0_OI1_OIII0.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI1_OIII0.description'
    },
    'CI0_OI1_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI1_OIII0.perceivedByReports'
    },
    'CI0_OI1_OIII1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI1_OIII1.description'
    },
    'CI0_OI1_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI0_OI1_OIII1.perceivedByReports'
    },
    'CI1_OI-1_OIII-1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI-1_OIII-1.description'
    },
    'CI1_OI-1_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI-1_OIII-1.perceivedByReports'
    },
    'CI1_OI-1_OIII0.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI-1_OIII0.description'
    },
    'CI1_OI-1_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI-1_OIII0.perceivedByReports'
    },
    'CI1_OI-1_OIII1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI-1_OIII1.description'
    },
    'CI1_OI-1_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI-1_OIII1.perceivedByReports'
    },
    'CI1_OI0_OIII-1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI0_OIII-1.description'
    },
    'CI1_OI0_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI0_OIII-1.perceivedByReports'
    },
    'CI1_OI0_OIII0.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI0_OIII0.description'
    },
    'CI1_OI0_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI0_OIII0.perceivedByReports'
    },
    'CI1_OI0_OIII1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI0_OIII1.description'
    },
    'CI1_OI0_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI0_OIII1.perceivedByReports'
    },
    'CI1_OI1_OIII-1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI1_OIII-1.description'
    },
    'CI1_OI1_OIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI1_OIII-1.perceivedByReports'
    },
    'CI1_OI1_OIII0.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI1_OIII0.description'
    },
    'CI1_OI1_OIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI1_OIII0.perceivedByReports'
    },
    'CI1_OI1_OIII1.description': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI1_OIII1.description'
    },
    'CI1_OI1_OIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.MANAGING_CHANGE.CI1_OI1_OIII1.perceivedByReports'
    }
});
export const RELATIONSHIP_BUILDING_MESSAGES = defineMessages({
    definitionDescription: {
        id: 'leadershipReport.facet.relationshipBuilding.definition.description'
    },
    definitionTitle: {id: 'leadershipReport.facet.relationshipBuilding.definition.title'},
    title: {id: 'leadershipReport.facet.relationshipBuilding.title'},
    'AI-1_EII-1.description': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI-1_EII-1.description'
    },
    'AI-1_EII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI-1_EII-1.perceivedByReports'
    },
    'AI-1_EII0.description': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI-1_EII0.description'
    },
    'AI-1_EII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI-1_EII0.perceivedByReports'
    },
    'AI-1_EII1.description': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI-1_EII1.description'
    },
    'AI-1_EII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI-1_EII1.perceivedByReports'
    },
    'AI0_EII-1.description': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI0_EII-1.description'
    },
    'AI0_EII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI0_EII-1.perceivedByReports'
    },
    'AI0_EII0.description': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI0_EII0.description'
    },
    'AI0_EII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI0_EII0.perceivedByReports'
    },
    'AI0_EII1.description': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI0_EII1.description'
    },
    'AI0_EII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI0_EII1.perceivedByReports'
    },
    'AI1_EII-1.description': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI1_EII-1.description'
    },
    'AI1_EII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI1_EII-1.perceivedByReports'
    },
    'AI1_EII0.description': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI1_EII0.description'
    },
    'AI1_EII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI1_EII0.perceivedByReports'
    },
    'AI1_EII1.description': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI1_EII1.description'
    },
    'AI1_EII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RELATIONSHIP_BUILDING.AI1_EII1.perceivedByReports'
    }
});
export const RESILIENCE_MESSAGES = defineMessages({
    definitionDescription: {id: 'leadershipReport.facet.resilience.definition.description'},
    definitionTitle: {id: 'leadershipReport.facet.resilience.definition.title'},
    title: {id: 'leadershipReport.facet.resilience.title'},
    'CI-1_ESI-1_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI-1_ESIII-1.description'
    },
    'CI-1_ESI-1_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI-1_ESIII-1.perceivedByReports'
    },
    'CI-1_ESI-1_ESIII0.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI-1_ESIII0.description'
    },
    'CI-1_ESI-1_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI-1_ESIII0.perceivedByReports'
    },
    'CI-1_ESI-1_ESIII1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI-1_ESIII1.description'
    },
    'CI-1_ESI-1_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI-1_ESIII1.perceivedByReports'
    },
    'CI-1_ESI0_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI0_ESIII-1.description'
    },
    'CI-1_ESI0_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI0_ESIII-1.perceivedByReports'
    },
    'CI-1_ESI0_ESIII0.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI0_ESIII0.description'
    },
    'CI-1_ESI0_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI0_ESIII0.perceivedByReports'
    },
    'CI-1_ESI0_ESIII1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI0_ESIII1.description'
    },
    'CI-1_ESI0_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI0_ESIII1.perceivedByReports'
    },
    'CI-1_ESI1_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI1_ESIII-1.description'
    },
    'CI-1_ESI1_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI1_ESIII-1.perceivedByReports'
    },
    'CI-1_ESI1_ESIII0.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI1_ESIII0.description'
    },
    'CI-1_ESI1_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI1_ESIII0.perceivedByReports'
    },
    'CI-1_ESI1_ESIII1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI1_ESIII1.description'
    },
    'CI-1_ESI1_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI-1_ESI1_ESIII1.perceivedByReports'
    },
    'CI0_ESI-1_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI-1_ESIII-1.description'
    },
    'CI0_ESI-1_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI-1_ESIII-1.perceivedByReports'
    },
    'CI0_ESI-1_ESIII0.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI-1_ESIII0.description'
    },
    'CI0_ESI-1_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI-1_ESIII0.perceivedByReports'
    },
    'CI0_ESI-1_ESIII1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI-1_ESIII1.description'
    },
    'CI0_ESI-1_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI-1_ESIII1.perceivedByReports'
    },
    'CI0_ESI0_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI0_ESIII-1.description'
    },
    'CI0_ESI0_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI0_ESIII-1.perceivedByReports'
    },
    'CI0_ESI0_ESIII0.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI0_ESIII0.description'
    },
    'CI0_ESI0_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI0_ESIII0.perceivedByReports'
    },
    'CI0_ESI0_ESIII1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI0_ESIII1.description'
    },
    'CI0_ESI0_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI0_ESIII1.perceivedByReports'
    },
    'CI0_ESI1_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI1_ESIII-1.description'
    },
    'CI0_ESI1_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI1_ESIII-1.perceivedByReports'
    },
    'CI0_ESI1_ESIII0.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI1_ESIII0.description'
    },
    'CI0_ESI1_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI1_ESIII0.perceivedByReports'
    },
    'CI0_ESI1_ESIII1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI1_ESIII1.description'
    },
    'CI0_ESI1_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI0_ESI1_ESIII1.perceivedByReports'
    },
    'CI1_ESI-1_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI-1_ESIII-1.description'
    },
    'CI1_ESI-1_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI-1_ESIII-1.perceivedByReports'
    },
    'CI1_ESI-1_ESIII0.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI-1_ESIII0.description'
    },
    'CI1_ESI-1_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI-1_ESIII0.perceivedByReports'
    },
    'CI1_ESI-1_ESIII1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI-1_ESIII1.description'
    },
    'CI1_ESI-1_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI-1_ESIII1.perceivedByReports'
    },
    'CI1_ESI0_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI0_ESIII-1.description'
    },
    'CI1_ESI0_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI0_ESIII-1.perceivedByReports'
    },
    'CI1_ESI0_ESIII0.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI0_ESIII0.description'
    },
    'CI1_ESI0_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI0_ESIII0.perceivedByReports'
    },
    'CI1_ESI0_ESIII1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI0_ESIII1.description'
    },
    'CI1_ESI0_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI0_ESIII1.perceivedByReports'
    },
    'CI1_ESI1_ESIII-1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI1_ESIII-1.description'
    },
    'CI1_ESI1_ESIII-1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI1_ESIII-1.perceivedByReports'
    },
    'CI1_ESI1_ESIII0.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI1_ESIII0.description'
    },
    'CI1_ESI1_ESIII0.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI1_ESIII0.perceivedByReports'
    },
    'CI1_ESI1_ESIII1.description': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI1_ESIII1.description'
    },
    'CI1_ESI1_ESIII1.perceivedByReports': {
        id: 'app.leadershipReport.dimension.RESILIENCE.CI1_ESI1_ESIII1.perceivedByReports'
    }
});
