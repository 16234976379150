import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 1000px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const WaitingForResult = styled.p`
    font-style: italic;
`;
export const ModalContent = styled.div`
    margin: 60px auto;
    width: 900px;
    max-width: 100%;

    @media print {
        margin: 0;
    }
`;
