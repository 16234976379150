import {useEffect, useRef, useState} from 'react';

import {UseOutOfViewportProps, UseOutOfViewportResult} from './types';

export function useIsOutOfViewport({
    bufferTopPx = 0
}: UseOutOfViewportProps): UseOutOfViewportResult {
    const [isOutOfViewport, setIsOutOfViewport] = useState(false);
    const targetRef = useRef<HTMLDivElement>(null);
    const parentRef = useRef<HTMLDivElement>(null);
    const prevIsOutOfViewport = useRef<boolean>(false);

    useEffect(() => {
        const handleScroll = () => {
            if (targetRef.current && parentRef.current) {
                const rect = targetRef.current.getBoundingClientRect();
                const isInViewport =
                    rect.top >= bufferTopPx && rect.bottom <= parentRef.current.clientHeight;

                if (isInViewport !== prevIsOutOfViewport.current) {
                    setIsOutOfViewport(!isInViewport);
                    prevIsOutOfViewport.current = isInViewport;
                }
            }
        };

        const currentParentRef = parentRef.current;

        if (currentParentRef) {
            currentParentRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentParentRef) {
                currentParentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [bufferTopPx]);

    return {targetRef, parentRef, isOutOfViewport};
}
