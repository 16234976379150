import {parse} from 'qs';
import {useCallback} from 'react';
import {useLocation} from 'react-router-dom';

import {useCreateDataCollectionPersonalityTestV2} from '@/api/personalityTests/useCreateDataCollectionPersonalityTestV2';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {personalityDataCollectionMessages} from '@/pages/PersonalityDataCollection/messages';
import {UseStartDataCollectionTestLogicProps} from '@/pages/PersonalityDataCollection/pages/DataCollectionForm/components/StartDataCollectionTest/types';
import {useGoToPersonalityTest} from '@/pages/PersonalityTestIrt/hooks/useGoToPersonalityTest';

export function useStartDataCollectionTestLogic({
    showErrorToast,
    externalId,
    locale
}: UseStartDataCollectionTestLogicProps) {
    const location = useLocation();
    const dataCollectionSource = parse(location.search, {ignoreQueryPrefix: true})
        .source as string;
    const phrases = useExtractPhraseConstants(personalityDataCollectionMessages);

    const onTestCreated = useGoToPersonalityTest();
    const [doCreateDataCollectionPersonalityTestV2, {loading: creatingTest}] =
        useCreateDataCollectionPersonalityTestV2(locale, dataCollectionSource);

    const startPersonalityTest = useCallback(() => {
        doCreateDataCollectionPersonalityTestV2(externalId)
            .then(({data}) => {
                const createDataCollectionPersonalityTest =
                    data?.createDataCollectionPersonalityTestV2;
                if (createDataCollectionPersonalityTest?.ok) {
                    onTestCreated(
                        createDataCollectionPersonalityTest.dataCollectionPersonalityTestId,
                        ''
                    );
                } else {
                    throw new Error(
                        createDataCollectionPersonalityTest?.errorMessage ?? 'Unknown error'
                    );
                }
            })
            .catch(showErrorToast);
    }, [doCreateDataCollectionPersonalityTestV2, showErrorToast, externalId, onTestCreated]);

    return {creatingTest, startPersonalityTest, phrases};
}
