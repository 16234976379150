import styled from 'styled-components';

import {H2} from '@/componentLibrary/components/typography';
import {BodyP1} from '@/componentLibrary/tokens/typography';
import {
    ColorBaseGrey200,
    ColorBaseGrey900,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

export const ColoredHeader = styled(H2)`
    color: ${ColorBaseGrey900};
`;

export const ScoreWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const InnerScoreWrapper = styled.div`
    display: flex;
    align-items: baseline;
`;

export const EmptyScoreText = styled.span`
    ${BodyP1};
    font-size: 72px;
    line-height: 80px;
    color: ${ColorBaseGrey200};
`;

export const ScoreText = styled.span`
    ${BodyP1};
    font-size: 110px;
    line-height: 100px;
    color: ${ColorBaseGrey900};
`;

export const BadgeWrapper = styled.div`
    margin-bottom: 10px;
    margin-left: ${SpacingXsmall};
`;
