import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXxlarge} from '@/componentLibrary/tokens/variables';

export const ValidationWrapper = styled.div`
    margin-top: 120px;

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        margin: ${SpacingXxlarge} auto;
    }

    @media print {
        margin: 0;
    }
`;
