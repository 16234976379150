import {ModalBody as ModalBodyInternal} from '@/componentLibrary/blocks/Modals/Modal/styled';
import {SpacingLarge, SpacingMedium, SpacingXlarge} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const ModalBody = styled(ModalBodyInternal)`
    padding: ${SpacingLarge} ${SpacingXlarge} ${SpacingXlarge} ${SpacingXlarge};
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingMedium};
    
`;
