import {defineMessages} from 'react-intl';

export default defineMessages({
    logicTest: {
        id: 'app.assessment.testProfileGraph.logicTest'
    },
    logicalAbility: {
        id: 'app.assessment.testProfileGraph.logicalAbility'
    },
    personalityTest: {
        id: 'app.assessment.testProfileGraph.personalityTest'
    }
});
