import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {useRequestValidationTest} from '@/api/logicTestIrt/useRequestValidationTest';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {logger} from '@/services/logrocket';

import messages from '../messages';

export function useRequestValidationTestLogic(
    organizationId: number,
    hideValidationTestModal: () => void,
    userId?: number
) {
    const intl = useIntl();
    const [doRequestValidationTest, {loading: requestingValidation}] =
        useRequestValidationTest(organizationId, userId);

    const requestValidationTest = useCallback(() => {
        doRequestValidationTest()
            .then(({data}) => {
                const {ok, errorMessage} = data?.requestValidationTest || {};
                if (ok) {
                    toast({
                        type: 'success',
                        message: intl.formatMessage(messages.validationRequested)
                    });
                    hideValidationTestModal();
                } else {
                    throw new Error(errorMessage ?? '');
                }
            })
            .catch(error => {
                logger.error(error);
                toast({
                    type: 'error',
                    message: intl.formatMessage(messages.failedToRequestValidation)
                });
            });
    }, [doRequestValidationTest, hideValidationTestModal, intl]);

    return {requestValidationTest, requestingValidation};
}
