import {defineMessages} from 'react-intl';

export default defineMessages({
    qualityScoreTitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.qualityScore'
    },
    reviewScoreTitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.reviewScore'
    },
    automatedTestScoreTitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.automatedTestScore'
    },
    noData: {
        id: 'app.assessment.jobPosition.evaluationPage.overallResults.roleFitBadge.noData'
    }
});
