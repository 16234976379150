import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {RequestSubscriptionUpgradeForOrganizationMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {REQUEST_SUBSCRIPTION_UPGRADE_FOR_ORGANIZATION} from './mutations';

type RequestSubscriptionUpgradeParams = {
    organizationId: string;
    newPlan: string;
};
export function useRequestSubscriptionUpgradeForOrganization(
    props: RequestSubscriptionUpgradeParams
): MutationHookReturnType<RequestSubscriptionUpgradeForOrganizationMutation, number> {
    const {organizationId, newPlan} = props;
    const [requestSubscriptionUpgradeForOrganization, {error, loading}] = useMutation(
        REQUEST_SUBSCRIPTION_UPGRADE_FOR_ORGANIZATION
    );

    const doRequestSubscriptionUpgrade = useCallback(
        (numPositions: number) => {
            if (!organizationId) {
                throw new Error('Organization id is required');
            }
            const variables = {
                organizationId,
                newPlan,
                includedActiveJobPositions: numPositions
            };
            return requestSubscriptionUpgradeForOrganization({variables: variables});
        },
        [requestSubscriptionUpgradeForOrganization, newPlan, organizationId]
    );

    return [doRequestSubscriptionUpgrade, {error, loading}];
}
