import {useEffect, useRef, useState} from 'react';

import {useMyJobRecommendations} from '@/api/jobRecommendations/useMyJobRecommendations';
import {useWaitForJobRecommendationsCreatedForUser} from '@/api/realTimeUpdates/useWaitForJobRecommendationsCreatedForUser';

import {ActiveJobRecommendationState} from '../../../types';
import {useOtherJobsSectionPrerequisities} from './useOtherJobsSectionPrerequisities';
import {useRelevanceDataLogic} from './useRelevanceDataLogic';
import {useShowAcceptPolicyLogic} from './useShowAcceptPolicyLogic';

export function useOtherJobsSectionLogic() {
    const {userId, hasPrerequisites, loadingPrerequisites} =
        useOtherJobsSectionPrerequisities();
    const showOtherJobsSection = hasPrerequisites && !loadingPrerequisites;

    const {
        isMissingBasicRelevance,
        userRelevanceInformation,
        relevanceParametersOptions,
        pollForUserRelevanceInformation,
        showRelevanceForm,
        setShowRelevanceForm,
        onSubmitForm,
        loading: loadingRelevance
    } = useRelevanceDataLogic({userId});

    const showUpdateUserRelevanceData = isMissingBasicRelevance && !showRelevanceForm;

    const onAcceptPolicy = () => {
        pollForUserRelevanceInformation();
    };
    const {hasCandidateServicesConsent, loading: loadingAcceptedPolicy} =
        useShowAcceptPolicyLogic();

    const {
        isWaitingForJobRecommendations,
        showJobRecommendationsLoader: waitForRecommendations
    } = useWaitForJobRecommendationsCreatedForUser();

    const previousIsMissingBasicRelevance = useRef(isMissingBasicRelevance);
    useEffect(() => {
        if (
            previousIsMissingBasicRelevance.current === true &&
            isMissingBasicRelevance === false
        ) {
            waitForRecommendations();
        }
        previousIsMissingBasicRelevance.current = isMissingBasicRelevance;
    }, [isMissingBasicRelevance, waitForRecommendations]);

    const [activeJobRecommendationState, setActiveJobRecommendationState] =
        useState<ActiveJobRecommendationState>();

    const resetActiveJobRecommendation = () => setActiveJobRecommendationState(undefined);

    const {
        pendingRecommendations,
        loading: loadingRecommendations,
        error: recommendationsError
    } = useMyJobRecommendations();

    const loading =
        loadingRelevance ||
        loadingAcceptedPolicy ||
        loadingRecommendations ||
        isWaitingForJobRecommendations;

    const error = recommendationsError;
    const hasRecommendations = pendingRecommendations.length !== 0;

    return {
        userId,
        showOtherJobsSection,
        userRelevanceInformation,
        pendingRecommendations,
        hasRecommendations,
        loading,
        error,
        relevanceParametersOptions,
        showRelevanceForm,
        setShowRelevanceForm,
        showUpdateUserRelevanceData,
        onSubmitForm,
        showAcceptPolicy: !hasCandidateServicesConsent,
        activeJobRecommendationState,
        setActiveJobRecommendationState,
        resetActiveJobRecommendation,
        isWaitingForJobRecommendations,
        onAcceptPolicy
    };
}
