import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {changeLocale} from '@/containers/LanguageProvider/actions';
import {useLocale} from '@/containers/LanguageProvider/useLocale';

export function useChangeLocale() {
    const dispatch = useDispatch();
    const userLocale = useLocale();
    const _changeLocale = useCallback(
        locale => {
            dispatch(changeLocale(locale));
        },
        [dispatch]
    );
    return {locale: userLocale, changeLocale: _changeLocale};
}
