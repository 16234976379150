import {H2} from '@/componentLibrary/components/typography/Headings';
import {getUserName} from '@/utils/misc';

import {UserTestResults_PersonalityIrtResult_User} from '../../LeadershipReport/types';
import logo from './assets/blackAlvaLogo.svg';
import {Logo, Wrapper} from './styled';

type PrintHeaderProps = {
    user: UserTestResults_PersonalityIrtResult_User | null;
};

export const PrintHeader = ({user}: PrintHeaderProps) => {
    return (
        <Wrapper>
            <H2>{getUserName(user)}</H2>
            <Logo src={logo} data-testid={'print-header-logo'} />
        </Wrapper>
    );
};
