import {defineMessages} from 'react-intl';

export default defineMessages({
    createAccount: {
        id: 'app.signup.createFreeTrialAccount.title'
    },
    pleaseUseWorkAccount: {
        id: 'app.signup.createFreeTrialAccount.ssoEmailDescription'
    },
    or: {
        id: 'app.signup.createAccountForm.or'
    },
    signUpWithEmail: {
        id: 'app.signup.createFreeTrialAccount.emailSignup'
    },
    acceptTermsAndPolicy: {
        id: 'app.signup.createFreeTrialAccount.acceptTermsAndPolicy'
    },
    termsOfServiceTitle: {
        id: 'app.signup.createFreeTrialAccount.termsOfServiceLinkTitle'
    },
    privacyPolicyTitle: {
        id: 'app.signup.createFreeTrialAccount.privacyPolicyLinkTitle'
    },
    personalEmailNotAllowed: {
        id: 'app.signup.createFreeTrialAccount.sso.error.personalEmailAddressUsed'
    }
});
