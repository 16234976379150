import styled from 'styled-components';

import {BorderBaseMuted} from '@/componentLibrary/tokens/customVariables';
import {SpacingXlarge, SpacingXxlarge} from '@/componentLibrary/tokens/variables';

export const TestDivider = styled.div`
    height: 1px;
    width: 100%;
    padding-top: ${SpacingXlarge};
    margin-bottom: ${SpacingXxlarge};
    border-bottom: ${BorderBaseMuted};
`;

export const FooterWrapper = styled.div`
    @media print {
        display: none;
    }
`;
