import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {LOGIC_TEST_IRT_LINKS} from '../links';

export function useGoToLogicTest(goBackUrl?: string) {
    const history = useHistory();
    return useCallback(
        personalityTestId => {
            const testUrl = LOGIC_TEST_IRT_LINKS.test(personalityTestId);
            const url = goBackUrl ? `${testUrl}?redirect=${goBackUrl}` : testUrl;
            history.push(url);
        },
        [history, goBackUrl]
    );
}
