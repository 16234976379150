import {IntlShape} from 'react-intl';

import {Challenge} from '@/api/types/__generated__/graphql';
import {convertToHoursAndMinutes} from '@/pages/Organization/pages/Assessment/pages/CodingTests/CodingTestsList/utils';

export const getChallengeDurations = (
    challenge: Partial<Challenge> | null,
    intl: IntlShape
) => {
    const durations: number[] = [];

    if (challenge?.seniorLevelEstimateDurationMinutes) {
        durations.push(challenge.seniorLevelEstimateDurationMinutes);
    }

    if (challenge?.midLevelEstimateDurationMinutes) {
        durations.push(challenge.midLevelEstimateDurationMinutes);
    }

    if (challenge?.juniorLevelEstimateDurationMinutes) {
        durations.push(challenge.juniorLevelEstimateDurationMinutes);
    }
    if (!durations.length) {
        return null;
    }

    const {min, max} = {
        min: Math.min(...durations),
        max: Math.max(...durations)
    };

    if (min && max && min !== max) {
        return {
            from: convertToHoursAndMinutes(min, intl, true),
            to: convertToHoursAndMinutes(max, intl)
        };
    }

    return {
        from: convertToHoursAndMinutes(min || max || 0, intl)
    };
};
