import {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {Overline} from '@/componentLibrary/components/typography';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {PERSONALITY_FACTORS} from '../../constants';
import {personalityTestReportMessages} from '../../messages';
import {FactorRow} from './components/FactorRow';
import {HeaderRow, HeadersGroup, LowerBandWrapper, UpperBandWrapper, Wrapper} from './styled';
import {FactorsOverviewProps} from './types';

export function FactorsOverview({personalityTestResult}: FactorsOverviewProps) {
    const intl = useIntl();
    const factorRows = useMemo(() => {
        return PERSONALITY_FACTORS.map(factor => (
            <FactorRow
                key={factor}
                personalityTestResult={personalityTestResult}
                factor={factor}
            />
        ));
    }, [personalityTestResult]);

    return (
        <Wrapper>
            <HeaderRow>
                <Overline color={ColorFgSubtle}>
                    {intl.formatMessage(personalityTestReportMessages.factor)}
                </Overline>
                <HeadersGroup>
                    <LowerBandWrapper>
                        <Overline color={ColorFgSubtle}>
                            {intl.formatMessage(personalityTestReportMessages.lowerBand)}
                        </Overline>
                    </LowerBandWrapper>
                    <Overline color={ColorFgSubtle}>
                        {intl.formatMessage(personalityTestReportMessages.average)}
                    </Overline>
                    <UpperBandWrapper>
                        <Overline color={ColorFgSubtle}>
                            {intl.formatMessage(personalityTestReportMessages.upperBand)}
                        </Overline>
                    </UpperBandWrapper>
                </HeadersGroup>
            </HeaderRow>
            {factorRows}
        </Wrapper>
    );
}
