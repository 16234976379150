import camelCase from 'lodash.camelcase';
import {useMemo} from 'react';

import {Tooltip} from '@/componentLibrary/components/Tooltips/Tooltip';
import {DonutChart} from '@/componentLibrary/components/charts/DonutGraph/DonutChart';
import {ColorBgInset, ColorFgDefault} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import messages from './messages';
import {Label, Wrapper} from './styled';
import {AssessmentWeightProps} from './types';

export function AssessmentWeight({context}: AssessmentWeightProps) {
    const {assessmentConfiguration, chartData, enabledIndexInChart} = context;
    const phrases = useExtractPhraseConstants(messages);

    const colors = useMemo(() => {
        return chartData.map((_, index) => {
            if (index === enabledIndexInChart) {
                return ColorFgDefault;
            }

            return ColorBgInset;
        });
    }, [chartData, enabledIndexInChart]);

    const WeightComponent = (
        <Wrapper>
            <DonutChart
                data={chartData}
                tracesOptions={{
                    hoverinfo: 'none',
                    marker: {colors},
                    direction: 'clockwise'
                }}
                layoutOptions={{
                    height: 24,
                    width: 24
                }}
            />
            <Label>
                {
                    phrases[
                        camelCase(
                            assessmentConfiguration.weight
                        ) as unknown as keyof typeof phrases
                    ]
                }
            </Label>
        </Wrapper>
    );

    return (
        <Tooltip
            content={phrases.tooltip}
            trigger={WeightComponent}
            placement={'top'}
            hasArrow
        />
    );
}
