import {defineMessages} from 'react-intl';

export default defineMessages({
    organizationSettings: {
        id: 'app.user.candidateSettings.userOrganizationSettings.organizationSettings'
    },
    noOrganizations: {
        id: 'app.user.candidateSettings.userOrganizationSettings.noOrganizations'
    }
});
