import {useMemo} from 'react';

import {Articles} from '@/componentLibrary/blocks/testReports/components/Articles';

import {useContent} from './hooks';
import {ReadMoreContentProps} from './types';

export function ReadMoreContent({showAdminContent}: ReadMoreContentProps) {
    const {CANDIDATE_CONTENT, EMPLOYEE_CONTENT} = useContent();

    const items = useMemo(
        () => (showAdminContent ? EMPLOYEE_CONTENT : CANDIDATE_CONTENT),
        [CANDIDATE_CONTENT, EMPLOYEE_CONTENT, showAdminContent]
    );

    return <Articles items={items} />;
}
