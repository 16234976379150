import {Fragment, useCallback} from 'react';
import {useIntl} from 'react-intl';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {MODAL_HEIGHT, MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {ExternalLink} from '@/componentLibrary/components/links';
import {H3, P2, S2} from '@/componentLibrary/components/typography';
import {ColorFgMuted, ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useCheckAccountAccess} from '@/pages/Organization/pages/Assessment/components/CodingTests/hooks/useCheckAccess';
import {
    getContentItem,
    getTimeProps,
    getVisibleText
} from '@/pages/Organization/pages/Assessment/pages/CodingTest/functions';
import {capitalizeFirstLetter} from '@/utils/misc';

import messages from './messages';
import {
    GithubRowDescription,
    ModalBody,
    Row,
    RowDescription,
    RowDivider,
    RowTitle,
    TestDetailSection,
    TestDetailTable,
    TimeEffortSection,
    TimeEffortSectionHeader,
    TimeEffortTable
} from './styled';
import {CodingTestPreviewModalProps} from './types';

export function CodingTestPreviewModal({
    onClose,
    challenge,
    headerTitle
}: CodingTestPreviewModalProps) {
    const phrases = useExtractPhraseConstants(messages);
    const intl = useIntl();

    const checkAccountAccess = useCheckAccountAccess();

    const onClickGitHubLink = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            if (challenge) {
                const repositoryUrl = await checkAccountAccess(challenge.id);
                if (repositoryUrl) {
                    window.open(repositoryUrl, '_blank', 'noopener, noreferrer');
                }
            }
        },
        [checkAccountAccess, challenge]
    );

    const timeProps = getTimeProps({challenge, intl});
    const challengeDescriptionHTML = getVisibleText(challenge?.description ?? null);

    return (
        <Modal
            close={onClose}
            headerTitle={headerTitle ?? phrases.modalHeader}
            size={MODAL_SIZE.LARGE}
            height={MODAL_HEIGHT.XLARGE}
            withoutBody
        >
            <ModalBody>
                <TestDetailSection>
                    <H3>{challenge?.name}</H3>
                    <TestDetailTable>
                        <Row>
                            <RowTitle>
                                <S2>{phrases.category}</S2>
                            </RowTitle>
                            <RowDescription>
                                {capitalizeFirstLetter(challenge?.profile || '')}
                            </RowDescription>
                        </Row>
                        <RowDivider />
                        <Row>
                            <RowTitle>
                                <S2>{phrases.templateRepository}</S2>
                            </RowTitle>
                            <GithubRowDescription>
                                <ExternalLink onClick={onClickGitHubLink}>
                                    {phrases.githubLink}
                                </ExternalLink>
                                {challenge?.isCustom && (
                                    <P2 color={ColorFgSubtle}>
                                        {phrases.gitHubTemplateRepoSubtitle}
                                    </P2>
                                )}
                            </GithubRowDescription>
                        </Row>
                        <RowDivider />
                        <Row>
                            <RowTitle>
                                <S2>{phrases.hiringTeamDescription}</S2>
                            </RowTitle>
                            <RowDescription color={ColorFgMuted}>
                                {typeof challengeDescriptionHTML === 'string'
                                    ? getContentItem(challengeDescriptionHTML)
                                    : challengeDescriptionHTML}
                            </RowDescription>
                        </Row>
                    </TestDetailTable>
                </TestDetailSection>
                <TimeEffortSection>
                    <TimeEffortSectionHeader>
                        <H3>{phrases.timeEffortHeader}</H3>
                        <P2 color={ColorFgSubtle}>{phrases.timeEffortSubtitle}</P2>
                    </TimeEffortSectionHeader>
                    <TimeEffortTable>
                        {timeProps.content.map((time, k) => (
                            <Fragment key={k}>
                                <Row>
                                    <RowTitle>
                                        <S2>{time.contentTitle}</S2>
                                    </RowTitle>
                                    <RowDescription>{time.contentItem}</RowDescription>
                                </Row>
                                {k !== timeProps.content.length - 1 && <RowDivider />}
                            </Fragment>
                        ))}
                    </TimeEffortTable>
                </TimeEffortSection>
            </ModalBody>
        </Modal>
    );
}
