import DOMPurify from 'dompurify';

import {Tooltip} from '@/componentLibrary/components/Tooltips/Tooltip';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {H3} from '@/componentLibrary/components/typography';
import {ColorFgMuted, ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {useCopyToClipboard, useDynamicRefs} from './hooks';
import messages from './messages';
import {
    ContentItem,
    Divider,
    Label,
    ScorecardContent,
    ScorecardWrapper,
    StyledIcon,
    StyledInnerHTML,
    StyledP2,
    StyledScorecard
} from './styled';
import {ScorecardsProps} from './types';

export const Scorecards = ({challenge}: ScorecardsProps) => {
    const phrases = useExtractPhraseConstants(messages);

    const refs = useDynamicRefs((challenge?.scorecard?.requirements?.length || 0) * 2);
    const {copyToClipboard, statusText, resetText} = useCopyToClipboard(refs);

    return (
        <StyledScorecard>
            <H3>{phrases.scorecard}</H3>
            <ScorecardContent>
                {challenge?.scorecard?.requirements
                    .map(({requirement, criteria}, index) => (
                        <ScorecardWrapper key={index}>
                            <ContentItem>
                                <Label>{phrases.requirementsLabel}</Label>
                                <StyledP2>
                                    <span ref={refs.current[2 * index]}>{requirement}</span>
                                    <Tooltip
                                        asChild
                                        content={statusText}
                                        key={2 * index}
                                        placement="top"
                                        trigger={
                                            <StyledIcon>
                                                <Icon
                                                    icon={Icons.CONTENT_COPY}
                                                    isHoverable
                                                    isAction
                                                    fill={ColorFgMuted}
                                                    hoverFill={ColorFgSubtle}
                                                    onClick={() => copyToClipboard(2 * index)}
                                                    onMouseLeave={() => resetText()}
                                                />
                                            </StyledIcon>
                                        }
                                    />
                                </StyledP2>
                            </ContentItem>
                            <Divider />
                            <ContentItem>
                                <Label>{phrases.scoringCriteria}</Label>
                                <StyledP2>
                                    <StyledInnerHTML
                                        ref={refs.current[2 * index + 1]}
                                        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(criteria)
                                        }}
                                    />
                                    <Tooltip
                                        asChild
                                        content={statusText}
                                        key={2 * index + 1}
                                        placement="top"
                                        trigger={
                                            <StyledIcon>
                                                <Icon
                                                    icon={Icons.CONTENT_COPY}
                                                    isHoverable
                                                    isAction
                                                    fill={ColorFgMuted}
                                                    hoverFill={ColorFgSubtle}
                                                    onClick={() =>
                                                        copyToClipboard(2 * index + 1)
                                                    }
                                                    onMouseLeave={() => resetText()}
                                                />
                                            </StyledIcon>
                                        }
                                    />
                                </StyledP2>
                            </ContentItem>
                        </ScorecardWrapper>
                    ))
                    .flat() || []}
            </ScorecardContent>
        </StyledScorecard>
    );
};
