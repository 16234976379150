import {useCallback, useState} from 'react';

import {AssessmentsRecommendationAssessmentType} from '@/api/types/__generated__/graphql';
import {P2, S2} from '@/componentLibrary/components/typography';
import {BorderBaseFocusColor, ColorFgMuted} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {RecommendationCardProps} from '@/pages/Organization/pages/Assessment/pages/JobPosition/pages/Settings/pages/Architect/types';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import messages from '../messages';
import {getRecommendationCardInfo} from './getRecommendationCardInfo';
import {Body, Main, PlainButtonEdit, Title, Wrapper} from './styled';

export const RecommendedCard = ({type, openArchitect, role}: RecommendationCardProps) => {
    const phrases = useExtractPhraseConstants(messages);
    const {button, architectMode} = getRecommendationCardInfo(type, phrases);
    const [isExpanded, setIsExpanded] = useState(false);

    const onClick = useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);

    return (
        <Wrapper>
            <Icon icon={Icons.HOLDING_HAND} fill={BorderBaseFocusColor} />
            <Main>
                <Title>
                    <S2>{phrases.recommendedByAI}</S2>
                    <Icon
                        icon={Icons.KEYBOARD_ARROW_DOWN}
                        fill={ColorFgMuted}
                        onClick={onClick}
                        rotate={isExpanded ? 180 : 0}
                    />
                </Title>
                {isExpanded && (
                    <Body>
                        <div>
                            <P2 forwardedAs="span" color={ColorFgMuted}>
                                {phrases.basedOnResearch}
                            </P2>
                            <S2>{role?.name ?? ''}</S2>
                            <P2 forwardedAs="span" color={ColorFgMuted}>
                                {phrases.role}
                            </P2>
                            {type ===
                                AssessmentsRecommendationAssessmentType.CODING_CHALLENGE && (
                                <P2 forwardedAs="span">{phrases.expected}</P2>
                            )}
                        </div>
                        <PlainButtonEdit narrow onClick={() => openArchitect?.(architectMode)}>
                            {button}
                        </PlainButtonEdit>
                    </Body>
                )}
            </Main>
        </Wrapper>
    );
};
