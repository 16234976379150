import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'personalityReport.factorFacets.title'
    }
});

export const AI_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.AI.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.AI.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.AI.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.AI.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.AI.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.AI.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.AI.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.AI.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.AI.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.AI.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.AI.title'
    }
});

export const AII_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.AII.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.AII.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.AII.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.AII.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.AII.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.AII.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.AII.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.AII.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.AII.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.AII.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.AII.title'
    }
});

export const AIII_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.AIII.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.AIII.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.AIII.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.AIII.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.AIII.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.AIII.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.AIII.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.AIII.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.AIII.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.AIII.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.AIII.title'
    }
});

export const CI_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.CI.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.CI.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.CI.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.CI.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.CI.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.CI.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.CI.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.CI.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.CI.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.CI.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.CI.title'
    }
});

export const CII_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.CII.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.CII.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.CII.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.CII.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.CII.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.CII.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.CII.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.CII.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.CII.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.CII.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.CII.title'
    }
});

export const CIII_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.CIII.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.CIII.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.CIII.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.CIII.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.CIII.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.CIII.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.CIII.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.CIII.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.CIII.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.CIII.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.CIII.title'
    }
});

export const EI_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.EI.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.EI.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.EI.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.EI.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.EI.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.EI.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.EI.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.EI.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.EI.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.EI.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.EI.title'
    }
});

export const EII_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.EII.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.EII.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.EII.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.EII.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.EII.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.EII.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.EII.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.EII.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.EII.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.EII.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.EII.title'
    }
});

export const EIII_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.EIII.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.EIII.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.EIII.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.EIII.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.EIII.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.EIII.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.EIII.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.EIII.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.EIII.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.EIII.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.EIII.title'
    }
});

export const ESI_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.ESI.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.ESI.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.ESI.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.ESI.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.ESI.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.ESI.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.ESI.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.ESI.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.ESI.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.ESI.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.ESI.title'
    }
});

export const ESII_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.ESII.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.ESII.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.ESII.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.ESII.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.ESII.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.ESII.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.ESII.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.ESII.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.ESII.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.ESII.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.ESII.title'
    }
});

export const ESIII_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.ESIII.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.ESIII.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.ESIII.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.ESIII.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.ESIII.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.ESIII.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.ESIII.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.ESIII.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.ESIII.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.ESIII.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.ESIII.title'
    }
});

export const OI_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.OI.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.OI.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.OI.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.OI.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.OI.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.OI.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.OI.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.OI.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.OI.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.OI.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.OI.title'
    }
});

export const OII_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.OII.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.OII.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.OII.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.OII.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.OII.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.OII.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.OII.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.OII.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.OII.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.OII.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.OII.title'
    }
});

export const OIII_MESSAGES = defineMessages({
    bulletsAverage: {
        id: 'personalityIrtFacet.OIII.bulletsAverage'
    },
    bulletsHigh: {
        id: 'personalityIrtFacet.OIII.bulletsHigh'
    },
    bulletsLow: {
        id: 'personalityIrtFacet.OIII.bulletsLow'
    },
    bulletsVeryHigh: {
        id: 'personalityIrtFacet.OIII.bulletsVeryHigh'
    },
    bulletsVeryLow: {
        id: 'personalityIrtFacet.OIII.bulletsVeryLow'
    },
    descriptionLower: {
        id: 'personalityIrtFacet.OIII.descriptionLower'
    },
    descriptionUpper: {
        id: 'personalityIrtFacet.OIII.descriptionUpper'
    },
    factor: {
        id: 'personalityIrtFacet.OIII.factor'
    },
    labelLower: {
        id: 'personalityIrtFacet.OIII.labelLower'
    },
    labelUpper: {
        id: 'personalityIrtFacet.OIII.labelUpper'
    },
    title: {
        id: 'personalityIrtFacet.OIII.title'
    }
});
