import {ApolloCache, FetchResult, useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {MutationHookReturnType} from '@/api/types/genericApi/types';
import {JOB_RECOMMENDATION_STATUS} from '@/pages/User/components/JobRecommendations/constants';
import {logger} from '@/services/logrocket';

import {RejectJobRecommendationMutation} from '../types/__generated__/graphql';
import {useLoggedInUser} from '../users/useLoggedInUser';
import {REJECT_JOB_RECOMMENDATION} from './mutations';

export type RejectReason = {
    id: string;
    name: string;
};

function updateCache(
    cache: ApolloCache<RejectJobRecommendationMutation>,
    response: FetchResult<RejectJobRecommendationMutation>
) {
    const {data} = response;
    if (!data?.rejectJobRecommendation?.ok) {
        return;
    }

    const {jobRecommendationId} = data.rejectJobRecommendation;

    try {
        const newStatus = JOB_RECOMMENDATION_STATUS.REJECTED;

        cache.modify({
            id: cache.identify({
                id: jobRecommendationId,
                __typename: 'JobRecommendation'
            }),
            fields: {
                status() {
                    return newStatus;
                }
            }
        });
    } catch (error) {
        logger.error(error, ' when updating cache.');
    }
}

function getOptimisticResponse(jobRecommendationId: string): RejectJobRecommendationMutation {
    return {
        __typename: 'Mutation',
        rejectJobRecommendation: {
            __typename: 'RejectJobRecommendation',
            ok: true,
            errorMessage: null,
            jobRecommendationId
        }
    };
}

export function useRejectJobRecommendation(
    jobRecommendationId: string,
    rejectReason: RejectReason,
    freeText: string
): MutationHookReturnType<RejectJobRecommendationMutation> {
    const [mutate, {error, loading}] = useMutation(REJECT_JOB_RECOMMENDATION);
    const {user} = useLoggedInUser();
    const doMutate = useCallback(() => {
        if (!user) {
            return Promise.reject(new Error('User is not logged in'));
        }
        const options = {
            variables: {
                data: {
                    userId: user?.id,
                    jobRecommendationId,
                    rejectReasons: {
                        selectedOptions: [rejectReason.id],
                        text: freeText
                    }
                }
            },
            update: updateCache,
            optimisticResponse: getOptimisticResponse(jobRecommendationId)
        };
        return mutate(options);
    }, [mutate, jobRecommendationId, rejectReason, freeText, user]);

    return [doMutate, {error, loading}];
}
