import {FC, useEffect, useRef, useState} from 'react';

import {CardBase} from '@/componentLibrary/components/cards/CardBase';
import {DisplaySmaller} from '@/componentLibrary/components/typography/Display';

import {Content, Header} from './styled';
import {CardProps} from './types';

export const Card: FC<CardProps> = ({
    children,
    expanded,
    header,
    disabled = false,
    onSelect,
    expandedHeight = 'auto',
    useFixedHeight = false,
    closable = false
}) => {
    const [displayContent, setDisplayContent] = useState(expanded);
    const hideContentTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (expanded) {
            if (hideContentTimeout.current) {
                clearTimeout(hideContentTimeout.current);
            }
            setDisplayContent(true);
        } else {
            hideContentTimeout.current = setTimeout(() => {
                setDisplayContent(false);
            }, 500);
        }

        return () => {
            if (hideContentTimeout.current) {
                clearTimeout(hideContentTimeout.current);
            }
        };
    }, [expanded]);

    const isClickable = () => {
        if (closable) {
            return !disabled && typeof onSelect === 'function';
        }
        return !expanded && !disabled && typeof onSelect === 'function';
    };

    const getHeight = () => (!expanded ? '72px' : expandedHeight);

    return (
        <CardBase isDisabled={disabled} isClickable={isClickable()} height={getHeight()}>
            <Header
                onClick={isClickable() ? onSelect : undefined}
                isClickable={isClickable()}
                disabled={disabled}
                isExpanded={expanded}
            >
                <DisplaySmaller>{header}</DisplaySmaller>
            </Header>
            <Content useFixedHeight={useFixedHeight}>
                {displayContent ? children : null}
            </Content>
        </CardBase>
    );
};
