import {ElementType, HTMLProps} from 'react';
import {Link, LinkProps, NavLink, NavLinkProps} from 'react-router-dom';
import styled, {css} from 'styled-components';

import {ButtonShellStyling, getIcon} from '@/componentLibrary/components/buttons/ButtonBase';
import {
    ColorFgActionTertriaryActive,
    ColorFgActionTertriaryDefault,
    ColorFgActionTertriaryDisabled,
    ColorFgActionTertriaryHover,
    SpacingSmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

import {BUTTON_VARIANTS} from './constants';
import {PlainButtonProps} from './types';

const PlainButtonStyling = css<PlainButtonProps>`
    ${ButtonShellStyling}
    padding: ${({small: isSmall}) =>
        isSmall ? SpacingXxxsmall : SpacingXxsmall} ${SpacingSmall};
    ${({narrow}) => narrow && `padding-inline: 0;`}
    background: none;
    color: ${ColorFgActionTertriaryDefault};

    &:hover {
        text-decoration: underline;
        color: ${ColorFgActionTertriaryHover};
    }

    &:active {
        color: ${ColorFgActionTertriaryActive};
    }

    ${({isSelected}) =>
        isSelected &&
        css`
            color: ${ColorFgActionTertriaryActive};

            &:hover,
            &:active {
                color: ${ColorFgActionTertriaryActive};
            }
        `};

    &:disabled {
        color: ${ColorFgActionTertriaryDisabled};
        cursor: not-allowed;
        text-decoration: none;
    }

    :focus:not(:focus-visible) {
        /* Remove focus indication when a mouse is used */
        outline: none;
    }

    :focus-visible {
        outline: 2px solid ${ColorFgActionTertriaryHover};
        outline-offset: 2px;
    }
`;

export const StyledPlainButton = styled.button`
    ${PlainButtonStyling}
`;

export const StyledPlainNavLink = styled(NavLink)`
    ${PlainButtonStyling}
`;

export const StyledPlainLink = styled(Link)`
    ${PlainButtonStyling}
`;

export const StyledPlainAnchor = styled.a`
    ${PlainButtonStyling}
`;

const PlainButtonShell = ({variant, children, icon, ...rest}: PlainButtonProps) => {
    let Component: ElementType;
    switch (variant) {
        case BUTTON_VARIANTS.NAVLINK:
            Component = StyledPlainNavLink;
            break;
        case BUTTON_VARIANTS.LINK:
            Component = StyledPlainLink;
            break;
        case BUTTON_VARIANTS.ANCHOR:
            Component = StyledPlainAnchor;
            break;
        default:
            Component = StyledPlainButton;
    }

    return (
        <Component {...rest}>
            {getIcon(icon)}
            {children}
        </Component>
    );
};

export const PlainButton = (props: PlainButtonProps & HTMLProps<HTMLButtonElement>) => (
    <PlainButtonShell {...props} />
);
export const PlainNavLinkButton = (props: PlainButtonProps & NavLinkProps) => (
    <PlainButtonShell {...props} variant={BUTTON_VARIANTS.NAVLINK} />
);
export const PlainLinkButton = (props: PlainButtonProps & LinkProps) => (
    <PlainButtonShell {...props} variant={BUTTON_VARIANTS.LINK} />
);
export const PlainAnchorButton = (props: PlainButtonProps & HTMLProps<HTMLAnchorElement>) => (
    <PlainButtonShell {...props} variant={BUTTON_VARIANTS.ANCHOR} />
);
