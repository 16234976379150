import {useLogicalAbilityEstimate} from '@/api/logicTestIrt/useLogicalAbilityEstimate';
import {useValidationTestResult} from '@/api/logicTestIrt/useValidationTestResult';
import {LogicTestReport} from '@/componentLibrary/blocks/testReports/LogicTest/LogicTestReport';
import {LogicTestReportProps} from '@/componentLibrary/blocks/testReports/LogicTest/LogicTestReport/types';
import {FC, useEffect} from 'react';

export type SharedLogicTestReportPageProps = {
    logicTestId: string;
    goBack?: () => void;
};

export const SharedLogicTestReportPage: FC<SharedLogicTestReportPageProps> = ({
    logicTestId,
    goBack
}) => {
    const {logicalAbilityEstimate, loading} = useLogicalAbilityEstimate(logicTestId);
    const {validationTestResult, loading: loadingValidation} =
        useValidationTestResult(logicTestId);

    useEffect(() => {
        if (loading || loadingValidation) {
            return;
        }

        if (!loading && !logicalAbilityEstimate) {
            return;
        }
    }, [loading, loadingValidation, logicalAbilityEstimate]);

    return (
        <LogicTestReport
            logicalAbilityEstimate={
                logicalAbilityEstimate as LogicTestReportProps['logicalAbilityEstimate']
            }
            validationTestResult={validationTestResult}
            close={goBack}
            showAdminContent={true}
        />
    );
};
