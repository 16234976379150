import {useCallback} from 'react';

import {useLogicalAbilityEstimate} from '@/api/logicTestIrt/useLogicalAbilityEstimate';
import {LogicalAbilityEstimate} from '@/api/types/__generated__/graphql';
import {LogicTestCompletedProps} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTestCompleted/types';

import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {LogicTestResultPlaceholder} from './components/LogicTestResultPlaceholder';
import {ValidationTestCompleted} from './components/ValidationTestCompleted';

export function LogicTestCompleted({
    goToTestResult,
    logicTestId,
    goBack,
    isValidationTest
}: LogicTestCompletedProps) {
    const {logicalAbilityEstimate, stopPolling, error, refetch, loading} =
        useLogicalAbilityEstimate(logicTestId, 1000);

    const goToTestResultPage = useCallback(() => {
        goToTestResult(logicTestId);
    }, [logicTestId, goToTestResult]);

    if (isValidationTest) {
        return <ValidationTestCompleted />;
    }

    if (error) {
        stopPolling();
        return <ErrorState error={error} refetch={refetch} />;
    }

    if (!logicalAbilityEstimate || loading) {
        return null;
    }

    return (
        <LogicTestResultPlaceholder
            goToTestResult={goToTestResultPage}
            goBack={goBack}
            onLogicalAbilityEstimateFound={stopPolling}
            logicalAbilityEstimate={logicalAbilityEstimate as LogicalAbilityEstimate}
        />
    );
}
