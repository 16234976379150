import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {CreateDataCollectionLogicTestMutation} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {CREATE_DATA_COLLECTION_LOGIC_TEST} from './mutations';

export function useCreateDataCollectionLogicTest(): MutationHookReturnType<
    CreateDataCollectionLogicTestMutation,
    [string, string, string]
> {
    const [mutate, {error, loading}] = useMutation(CREATE_DATA_COLLECTION_LOGIC_TEST);
    const doCreateDataCollectionLogicTest = useCallback(
        (externalId = null, source = null, studyId = null) => {
            const variables = {data: {externalId, dataCollectionSource: source, studyId}};
            return mutate({variables});
        },
        [mutate]
    );

    return [doCreateDataCollectionLogicTest, {error, loading}];
}
