import styled from 'styled-components';

import {
    BorderRadiusBaseRounded,
    ColorBgInset,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const Progress = styled.progress<{$backgroundColor: string}>`
    display: block;
    height: 6px;
    margin: ${SpacingXxsmall} 0 !important;
    appearance: none;
    border: none;
    border-radius: ${BorderRadiusBaseRounded};
    overflow: hidden;
    width: 100%;

    &::-webkit-progress-bar {
        background-color: ${ColorBgInset};
    }

    &::-webkit-progress-value {
        background-color: ${({$backgroundColor}) => $backgroundColor};
    }

    &::-ms-fill {
        background-color: ${({$backgroundColor}) => $backgroundColor};
    }

    &::-moz-progress-bar {
        background-color: ${({$backgroundColor}) => $backgroundColor};
    }
`;

export const HelpText = styled.p`
    font-size: ${SpacingXsmall};
    height: ${SpacingXsmall};
`;
