import {createContext, useContext} from 'react';

import {
    GetJobApplication_JobApplication as JobApplication,
    GetJobPosition_JobPosition as JobPosition
} from '@/api/assessment/types';
import {isDefined} from '@/utils/typeGuards/isDefined';

export const CandidateRoleFitContext = createContext<
    | {
          jobPosition: JobPosition;
          jobApplication: JobApplication;
          userId: number;
      }
    | Record<string, never>
>({});

export function useCandidateRoleFitContext() {
    const context = useContext(CandidateRoleFitContext);
    if (!isDefined(context)) {
        throw new Error('useCandidateRoleFitContext must be within CandidateRoleFitProvider');
    }

    return context;
}
