import {defineMessages} from 'react-intl';

export default defineMessages({
    results: {
        id: 'app.assessment.candidatePage.results'
    },
    settings: {
        id: 'app.assessment.candidatePage.settings'
    },
    overview: {
        id: 'app.assessment.candidatePage.overview'
    },
    roleFit: {
        id: 'app.assessment.candidatePage.roleFit'
    },
    interview: {
        id: 'app.assessment.candidatePage.interview'
    },
    codingTest: {
        id: 'app.assessment.candidatePage.codingTest'
    },
    logicTest: {
        id: 'app.assessment.candidatePage.candidateResults.logicTest'
    },
    personalityTest: {
        id: 'app.assessment.candidatePage.candidateResults.personalityTest'
    },
    psychometric: {
        id: 'app.assessment.candidatePage.results'
    },
    helpCenter: {
        id: 'app.assessment.candidatePage.candidateResults.helpCenter'
    },
    helpCenterDescription: {
        id: 'app.assessment.candidatePage.candidateResults.helpCenterDescription'
    }
});
