const EMPTY_STRING = '';
const SPACE_STRING = ' ';
const NEW_LINE = '\n';
const MARKDOWN_LIST_ITEM_TAG = '- ';
const SPACE_TAG = '&nbsp;';
const LINE_BREAK_TAG = '<br>';
const LIST_ITEM_START_TAG = '<li>';
const LIST_ITEM_END_TAG = '</li>';
const EMPTY_LIST_ITEM_PATTERN = /<li>( |<br>|&nbsp;)*<\/li>/gm;

function convertMarkdownListItemsToHTMLString(markdownData: string | null) {
    if (markdownData == null) {
        return null;
    }
    const rawListItems = markdownData.split(NEW_LINE);
    const rawListItemsWithRemovedMarkDownTag = rawListItems.map(item =>
        item.replace(MARKDOWN_LIST_ITEM_TAG, EMPTY_STRING)
    );
    const stringHTMLData = rawListItemsWithRemovedMarkDownTag.reduce(
        (acc, item) =>
            item.length > 0 ? acc + (LIST_ITEM_START_TAG + item + LIST_ITEM_END_TAG) : acc,
        EMPTY_STRING
    );
    return stringHTMLData;
}

function convertHTMLStringListItemsToMarkdown(stringHTMLData: string) {
    return getItemsWithContent(stringHTMLData)
        .replaceAll(LIST_ITEM_START_TAG, MARKDOWN_LIST_ITEM_TAG)
        .replaceAll(LIST_ITEM_END_TAG, NEW_LINE);
}

function getItemsWithContent(stringHTMLData: string) {
    return stringHTMLData
        .replaceAll(SPACE_TAG, SPACE_STRING)
        .replaceAll(LINE_BREAK_TAG, SPACE_STRING)
        .replaceAll(EMPTY_LIST_ITEM_PATTERN, EMPTY_STRING);
}

export {convertMarkdownListItemsToHTMLString, convertHTMLStringListItemsToMarkdown};
