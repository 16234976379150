import {GetJobPosition_JobPosition_Interview} from '@/api/assessment/types';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {SelectableInterviewCard} from './SelectableInterviewCard';
import messages from './messages';
import {DescriptionWrapper} from './styled';
import {SelectInterviewProps} from './types';

export const SelectInterviews = ({
    interviews,
    isInterviewSelected,
    onSelect,
    selectionType
}: SelectInterviewProps) => {
    const phrases = useExtractPhraseConstants(messages);

    return (
        <>
            <DescriptionWrapper>{phrases.descriptionInterview}</DescriptionWrapper>
            {interviews.map((interview: GetJobPosition_JobPosition_Interview) => (
                <SelectableInterviewCard
                    key={`${interview.id}-interview`}
                    interview={interview}
                    isSelected={isInterviewSelected(interview)}
                    onSelect={onSelect}
                    selectionType={selectionType}
                />
            ))}
        </>
    );
};
