import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import messages from './messages';

const MORGAN = {
    author: 'Morgan Pihl',
    authorDetails: 'Lead Psychometrician'
};

const KAJSA = {
    author: 'Kajsa Asplund',
    authorDetails: 'Talent Management | Author | PhD | Psychologist'
};

export function useContent() {
    const phrases = useExtractPhraseConstants(messages);

    const CANDIDATE_CONTENT = [
        {
            url: 'https://help.alvalabs.io/alva-s-personality-test/the-five-factor-model-of-personality',
            title: phrases.fiveFactorModelTitle,
            ...KAJSA
        },
        {
            url: 'https://help.alvalabs.io/en/articles/2666169-stability-of-personality-traits',
            title: phrases.stabilityOfTraitsTitle,
            ...MORGAN
        },
        {
            url: 'https://help.alvalabs.io/en/articles/4522026-technical-manual',
            title: phrases.technicalManualTitle,
            ...MORGAN
        }
    ];

    const EMPLOYEE_CONTENT = [
        {
            url: 'https://help.alvalabs.io/alva-s-personality-test/the-five-factor-model-of-personality',
            title: phrases.fiveFactorModelTitle,
            ...KAJSA
        },
        {
            url: 'https://help.alvalabs.io/en/articles/4522026-technical-manual',
            title: phrases.technicalManualTitle,
            ...MORGAN
        },
        {
            url: 'https://help.alvalabs.io/en/articles/2643977-construct-validity-of-alva-s-personality-test',
            title: phrases.validityTitle,
            ...MORGAN
        }
    ];

    return {
        CANDIDATE_CONTENT,
        EMPLOYEE_CONTENT
    };
}
