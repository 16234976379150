import {useIntl} from 'react-intl';

import {P2} from '@/componentLibrary/components/typography';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import messages from '../messages';

export const Empty = () => {
    const intl = useIntl();
    return <P2 color={ColorFgSubtle}>{intl.formatMessage(messages.empty)}</P2>;
};
