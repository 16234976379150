import {SignupTokenData} from '@/pages/Signup/pages/ValidateSignupToken/types';

import {useSignupPageLogic} from './logic';
import {RegisterUser} from './pages/RegisterUser';
import {ValidateSignupToken} from './pages/ValidateSignupToken';

export function SignupPage() {
    const {token, redirectToLogin} = useSignupPageLogic();

    return (
        <ValidateSignupToken
            token={token}
            onSuccess={(data: SignupTokenData) => (
                <RegisterUser
                    token={token}
                    onSuccess={redirectToLogin}
                    signupTokenData={data}
                />
            )}
        />
    );
}
