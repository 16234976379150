import {useCallback} from 'react';

import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {InstructionLayout} from '@/componentLibrary/components/layout/InstructionLayout';
import {CenteredSpinner} from '@/componentLibrary/components/spinners';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {NotFound} from '@/pages/NotFound';
import {Wrapper} from '@/pages/PersonalityDataCollection/pages/DataCollectionForm/styled';

import {CONTENT_URL_TYPES} from '../constants';
import {DataCollectionInstructions} from './components/DataCollectionInstructions';
import {FigureEightContent} from './components/FigureEightContent/index';
import {GeneralContent} from './components/GeneralContent';
import {ProlificContent} from './components/ProlificContent';
import {StartDataCollectionTest} from './components/StartDataCollectionTest';
import messages from './messages';
import {useDataCollectionLogic} from './useDataCollectionLogic';

export function DataCollectionForm() {
    const phrases = useExtractPhraseConstants(messages);
    const showErrorToast = useCallback(
        () => toast({type: 'error', message: phrases.error}),
        [phrases.error]
    );

    const {
        loading,
        localeName,
        errorLoadingLocale,
        isDataCollectionAllowed,
        contentType,
        creatingUser,
        doCreateAnonymousUser,
        externalId,
        isUserCreated
    } = useDataCollectionLogic({showErrorToast});

    if (errorLoadingLocale) {
        return <ErrorState error={errorLoadingLocale} />;
    }

    if (loading) {
        return (
            <Wrapper>
                <CenteredSpinner />
            </Wrapper>
        );
    }

    if (!isDataCollectionAllowed) {
        return <NotFound />;
    }

    function getCorrectContent() {
        if (isUserCreated) {
            return (
                <StartDataCollectionTest
                    externalId={externalId}
                    showErrorToast={showErrorToast}
                    locale={localeName}
                />
            );
        }

        switch (contentType) {
            case CONTENT_URL_TYPES.PROLIFIC:
                return (
                    <ProlificContent
                        createUser={doCreateAnonymousUser}
                        creatingUser={creatingUser}
                    />
                );
            case CONTENT_URL_TYPES.FIGURE_EIGHT:
                return (
                    <FigureEightContent
                        createUser={doCreateAnonymousUser}
                        creatingUser={creatingUser}
                    />
                );
            case CONTENT_URL_TYPES.GENERAL:
                return (
                    <GeneralContent
                        createUser={doCreateAnonymousUser}
                        creatingUser={creatingUser}
                    />
                );
            default:
                return (
                    <GeneralContent
                        createUser={doCreateAnonymousUser}
                        creatingUser={creatingUser}
                    />
                );
        }
    }

    return (
        <InstructionLayout
            instruction={<DataCollectionInstructions />}
            loading={loading}
            withLanguageToggle={false}
        >
            <Wrapper>{getCorrectContent()}</Wrapper>
        </InstructionLayout>
    );
}
