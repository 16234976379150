import {defineMessages} from 'react-intl';

export default defineMessages({
    backButtonText: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.detailModal.backButtonText'
    },
    title: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.detailModal.title'
    },
    testScenario: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.detailModal.testScenario'
    },
    results: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.detailModal.results'
    },
    requirements: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.detailModal.requirements'
    },
    passed: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.detailModal.status.passed'
    },
    failed: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.detailModal.status.failed'
    },
    scoreLow: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.detailModal.score.low'
    },
    scoreMid: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.detailModal.score.mid'
    },
    scoreHigh: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.detailModal.score.high'
    },
    aiDisclaimer: {
        id: 'app.assessment.codingTest.candidateCodingTest.results.aiDisclaimer'
    },
    testScoreWithinTime: {
        id: 'app.assessment.codingTests.candidateCodingTest.results.detailModal.tab.withinTimeLimit'
    },
    testScoreBeyondTime: {
        id: 'app.assessment.codingTests.candidateCodingTest.results.detailModal.tab.beyondTimeLimit'
    },
    autoScoringRetry: {
        id: 'app.errorState.retry'
    },
    autoScoringUnavailableBannerTitle: {
        id: 'app.assessment.codingTests.autoScoringUnavailable.banner.title'
    }
});
