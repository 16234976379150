import {defineMessages} from 'react-intl';

export default defineMessages({
    withdrawApplications: {
        id: 'app.user.candidateSettings.userOrganizationSettings.withdrawnApplicationsFromOrganizationModal.withdrawApplications'
    },
    description: {
        id: 'app.user.candidateSettings.userOrganizationSettings.withdrawnApplicationsFromOrganizationModal.description'
    },
    modalTitle: {
        id: 'app.user.candidateSettings.userOrganizationSettings.withdrawnApplicationsFromOrganizationModal.modalTitle'
    },
    applicationsWithdrawn: {
        id: 'app.user.candidateSettings.userOrganizationSettings.withdrawnApplicationsFromOrganizationModal.applicationsWithdrawn'
    },
    confirmationInputLabel: {
        id: 'app.user.candidateSettings.userOrganizationSettings.withdrawAndRevokeDataFromOrganizationModal.confirmationInputLabel'
    }
});
