import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {H3, H4} from '@/componentLibrary/components/typography/Headings';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {ColorBaseGrey800} from '@/componentLibrary/tokens/variables';
import {roundValue} from '@/utils/misc';

export function RoleFitSummary({score}) {
    const scoreText = score >= 0 ? roundValue(score * 100) : null;

    return (
        <FlexLayout justifyContent={'space-between'}>
            <TitleWrapper>
                <P1>Total Role Fit</P1>
            </TitleWrapper>
            {score !== null ? (
                <ScoreWrapper>
                    <Score>{scoreText}</Score>
                    <ColoredH4>/100</ColoredH4>
                </ScoreWrapper>
            ) : (
                <ColoredP1>N/A</ColoredP1>
            )}
        </FlexLayout>
    );
}

RoleFitSummary.propTypes = {
    score: PropTypes.number
};

const ColoredH4 = styled(H4)`
    color: ${ColorBaseGrey800};
`;

const ColoredP1 = styled(P1)`
    color: ${ColorBaseGrey800};
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: flex-end;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-bottom: 4px;
    }
`;

const ScoreWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

const Score = styled(H3)`
    margin-right: 4px;
    margin-bottom: 0;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        margin-right: 0;
    }
`;
