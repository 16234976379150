import {defineMessages} from 'react-intl';

export default defineMessages({
    complete: {
        id: 'app.pages.user.chooseOrganization.myApplication.assessmentsCompleted.complete'
    },
    completedAndPublished: {
        id: 'app.pages.user.chooseOrganization.myApplication.assessmentsCompleted.completedAndPublished'
    }
});
