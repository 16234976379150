import {JobInformationModal} from 'pages/User/components/JobRecommendations/components/JobInformationModal';

import {LinkedinFeedback} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation/components/LinkedinFeedback';
import {RejectJobRecommendation} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation/components/RejectJobRecommendation';
import {SentApplicationConfirmation} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation/components/SentApplicationConfirmation';
import {ShareConfirmation} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation/components/ShareConfirmation';
import {SubmitResume} from '@/pages/User/components/JobRecommendations/components/ActiveJobRecommendation/components/SubmitResume';

import {JobRecommendationsOverviewProps} from '../JobRecommendationsOverview/types';
import {InitialActiveRecommendationState, MODAL_KEYS} from './constants';
import {ActiveJobRecommendationStateProvider} from './context';
import {useActiveRecommendationLogic} from './logic';

type Props = {
    initialState: InitialActiveRecommendationState;
    jobRecommendation: JobRecommendationsOverviewProps['pendingRecommendations'][0];
    onApply?: () => void;
    close: () => void;
};

export function ActiveJobRecommendation(props: Props) {
    const {jobRecommendation, initialState, onApply, close} = props;
    const publicJobPosition = jobRecommendation?.publicJobPosition;
    const jobName = publicJobPosition?.name ?? '';
    const companyName = publicJobPosition?.organizationName ?? '';
    const {modalCurrentKey, modalActions, modalSteppers} = useActiveRecommendationLogic({
        initialState,
        onApply,
        close
    });

    const Components = {
        [MODAL_KEYS.REJECT]: (
            <RejectJobRecommendation
                jobRecommendationId={jobRecommendation.id}
                companyName={companyName}
                jobName={jobName}
                close={close}
            />
        ),
        [MODAL_KEYS.READ_MORE]: (
            <JobInformationModal
                jobRelevanceInformation={jobRecommendation.jobRelevanceInformation}
                jobPositionName={jobName}
                externalJobAdUrl={publicJobPosition?.externalJobAdUrl}
                organizationName={companyName}
                organizationLogo={publicJobPosition?.organizationLogo}
                onLeftAction={modalActions[MODAL_KEYS.READ_MORE].leftAction}
                onRightAction={modalActions[MODAL_KEYS.READ_MORE].rightAction}
                close={close}
            />
        ),
        [MODAL_KEYS.SUBMIT_RESUME]: (
            <SubmitResume
                onRightAction={modalActions[MODAL_KEYS.SUBMIT_RESUME].rightAction}
                onGiveFeedback={modalActions[MODAL_KEYS.SUBMIT_RESUME].giveFeedback}
                stepper={modalSteppers[MODAL_KEYS.SUBMIT_RESUME]}
                close={close}
            />
        ),
        [MODAL_KEYS.LINKEDIN_FEEDBACK]: (
            <LinkedinFeedback
                onLeftAction={modalActions[MODAL_KEYS.LINKEDIN_FEEDBACK].leftAction}
                close={close}
            />
        ),
        [MODAL_KEYS.SHARE_CONFIRMATION]: (
            <ShareConfirmation
                jobRecommendationId={jobRecommendation.id}
                jobName={jobName}
                companyName={companyName}
                organizationExternalPrivacyPolicyUrl={
                    publicJobPosition?.organizationExternalPrivacyPolicyUrl || null
                }
                onLeftAction={modalActions[MODAL_KEYS.SHARE_CONFIRMATION].leftAction}
                onRightAction={modalActions[MODAL_KEYS.SHARE_CONFIRMATION].rightAction}
                stepper={modalSteppers[MODAL_KEYS.SHARE_CONFIRMATION]}
                close={close}
            />
        ),
        [MODAL_KEYS.SENT_CONFIRMATION]: (
            <SentApplicationConfirmation companyName={companyName} close={close} />
        )
    } as const;

    return (
        <ActiveJobRecommendationStateProvider>
            {Components[modalCurrentKey]}
        </ActiveJobRecommendationStateProvider>
    );
}
