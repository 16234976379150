import styled from 'styled-components';

import {BorderBaseDefault} from '@/componentLibrary/tokens/customVariables';
import {SpacingMedium} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Dimension = styled.div`
    padding: ${SpacingMedium};
    border-block-start: ${BorderBaseDefault};
    text-align: left;
`;
