import {useValidationTestResult} from '@/api/logicTestIrt/useValidationTestResult';
import {GetUserTestResultsQuery} from '@/api/types/__generated__/graphql';
import {LogicTestReportOverview} from '@/componentLibrary/blocks/testReports/LogicTest/LogicTestReportOverview';
import {TestReportOverviewLoading} from '@/componentLibrary/blocks/testReports/components/TestReportOverviewLoading';
import {TestReportType} from '@/componentLibrary/blocks/testReports/components/TestReportOverviewLoading/TestReportOverviewLoading';

type Props = {
    logicalAbilityEstimate: NonNullable<
        NonNullable<GetUserTestResultsQuery['user']>['logicalAbilityEstimate']
    >;
    jobPositionId?: number;
};

export function LogicTestResultManager({logicalAbilityEstimate, jobPositionId}: Props) {
    const {validationTestResult, loading} = useValidationTestResult(
        logicalAbilityEstimate.logicTestId
    );

    if (loading) {
        return <TestReportOverviewLoading type={TestReportType.LOGIC_TEST} />;
    }

    return (
        <LogicTestReportOverview
            logicalAbilityEstimate={logicalAbilityEstimate}
            isValidationRequested={!!validationTestResult}
            isValidationCompleted={
                validationTestResult ? !!validationTestResult.testCompletedAt : false
            }
            validationResultType={validationTestResult ? validationTestResult.result : null}
            showAdminContent
            jobPositionId={jobPositionId}
        />
    );
}
