import {useCallback, useEffect, useState} from 'react';

import {FieldState} from '@/pages/SignupV2/components/types';
import {CreateFreeTrialData} from '@/pages/SignupV2/hooks/useCreateFreeTrialLogic';
import {useEmailValidator} from '@/pages/SignupV2/hooks/useEmailValidator';
import {isValidEmail} from '@/utils/validators';

export function useFormValidator(data: CreateFreeTrialData, emailBlurred: boolean) {
    const [fieldStates, setFieldStates] = useState<Record<string, FieldState>>({
        firstName: FieldState.UNTOUCHED,
        lastName: FieldState.UNTOUCHED,
        organizationName: FieldState.UNTOUCHED,
        email: FieldState.UNTOUCHED,
        phoneNumber: FieldState.UNTOUCHED,
        referralSource: FieldState.UNTOUCHED
    });

    const {
        isBlacklistedDomain,
        getDomainFromEmail,
        loading: loadingValidation
    } = useEmailValidator(data.email);
    const [blacklistedDomain, setBlacklistedDomain] = useState<string | null>(null);
    const [disableSubmit, setDisableSubmit] = useState(true);

    const isValidValue = useCallback((value: string) => {
        return value !== '';
    }, []);

    const validateForm = useCallback(
        (forceTouched = false) => {
            const invalid: string[] = [];
            const valid: string[] = [];
            const untouched: string[] = [];

            let blacklistedDomainValue = null;
            Object.entries(data).forEach(([name, value]) => {
                switch (name) {
                    case 'email':
                        if (value === '' && fieldStates[name] === FieldState.UNTOUCHED) {
                            if (forceTouched) {
                                invalid.push(name);
                            } else {
                                untouched.push(name);
                            }
                        } else if (
                            !isValidEmail(value) &&
                            fieldStates[name] !== FieldState.UNTOUCHED
                        ) {
                            invalid.push(name);
                        } else if (
                            isBlacklistedDomain &&
                            !loadingValidation &&
                            (emailBlurred || forceTouched)
                        ) {
                            blacklistedDomainValue = getDomainFromEmail();
                            invalid.push(name);
                        } else {
                            valid.push(name);
                        }

                        break;

                    case 'firstName':
                    case 'lastName':
                    case 'phoneNumber':
                    case 'organizationName':
                    case 'referralSource':
                        if (value === '' && fieldStates[name] === FieldState.UNTOUCHED) {
                            if (forceTouched) {
                                invalid.push(name);
                            } else {
                                untouched.push(name);
                            }
                        } else if (isValidValue(value)) {
                            valid.push(name);
                        } else {
                            invalid.push(name);
                        }
                        break;
                }
            });
            setBlacklistedDomain(blacklistedDomainValue);

            setFieldStates(prevState => {
                const newState = {...prevState};
                valid.forEach(name => {
                    newState[name] = FieldState.VALID;
                });

                untouched.forEach(name => {
                    newState[name] = FieldState.UNTOUCHED;
                });
                invalid.forEach(name => {
                    newState[name] = FieldState.INVALID;
                });

                if (JSON.stringify(newState) === JSON.stringify(prevState)) {
                    return prevState;
                }
                return newState;
            });

            setDisableSubmit(
                isBlacklistedDomain ||
                    invalid.length > 0 ||
                    untouched.length > 0 ||
                    loadingValidation
            );
        },
        [
            data,
            emailBlurred,
            fieldStates,
            getDomainFromEmail,
            isBlacklistedDomain,
            loadingValidation,
            isValidValue
        ]
    );

    const handleInvalidSubmit = useCallback(() => {
        validateForm(true);
    }, [validateForm]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: run on data change
    useEffect(() => {
        validateForm();
    }, [data, validateForm]);

    return {disableSubmit, blacklistedDomain, fieldStates, handleInvalidSubmit};
}
