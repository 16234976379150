import styled from 'styled-components';

import {Spinner} from '@/componentLibrary/components/spinners';
import {SpacingMedium} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const InnerWrapper = styled.div`
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    & ${Spinner} {
        margin-right: ${SpacingMedium};
    }
`;
