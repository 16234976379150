import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {UpdateUserMutation, UpdateUserMutationVariables} from '../types/__generated__/graphql';
import {MutationHookReturnType} from '../types/genericApi/types';
import {UPDATE_USER} from './mutations';

export function useUpdateUser(
    userId: number | null
): MutationHookReturnType<UpdateUserMutation, UpdateUserMutationVariables['data']> {
    const [mutate, {error, loading}] = useMutation(UPDATE_USER);

    const doUpdateUser = useCallback(
        data => {
            if (!userId) {
                return Promise.reject(new Error('User id is required'));
            }
            return mutate({variables: {userId: userId, data}});
        },
        [userId, mutate]
    );

    return [doUpdateUser, {error, loading}];
}
