import styled from 'styled-components';

import {
    BorderRadiusBaseXsmall,
    ColorBaseRed600,
    ColorBaseRed900,
    SpacingLarge,
    SpacingMedium,
    SpacingXsmall,
    SpacingXxlarge,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    display: flex;
    width: 352px;
    margin: ${SpacingXsmall} 0 ${SpacingLarge};

    @media only screen and (max-width: 760px) {
        margin: ${SpacingMedium} 0 0;
        width: 100%;
    }

    @media only screen and (min-width: 760px) and (max-width: 800px) {
        margin: 48px 0 0;
        width: 100%;
    }
`;

export const MessageContainer = styled.div`
    height: ${SpacingXxlarge};
    width: 250px;
    display: flex;
`;

export const MessageWrapper = styled.div`
    background: ${ColorBaseRed600};
    border-radius: ${BorderRadiusBaseXsmall};
    font-size: ${SpacingXsmall};
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: center;
    text-justify: auto;
    color: ${ColorBaseRed900};
    padding: 2px ${SpacingXxsmall};
    margin: auto ${SpacingXxsmall};

    @media only screen and (max-width: 760px) {
        animation-duration: 1.7s;
        animation-name: breathe;
    }

    @keyframes breathe {
        0% {
            transform: scale(1, 1) translateY(0);
        }

        10% {
            transform: scale(1.1, 0.9) translateY(0);
        }

        30% {
            transform: scale(0.9, 1.1) translateY(-10px);
        }

        50% {
            transform: scale(1, 1) translateY(0);
        }

        100% {
            transform: scale(1, 1) translateY(0);
        }
    }
`;

export const TimeLeft = styled.span`
    padding-left: ${SpacingXxxsmall};
`;
