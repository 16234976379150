import {useMemo} from 'react';

import {useCurrentEmployment} from '@/api/organizations/useCurrentEmployment';
import {Articles} from '@/componentLibrary/blocks/testReports/components/Articles';

import {useContent} from './hooks';

export function ReadMoreContent() {
    const {employment, loading} = useCurrentEmployment();
    const {CANDIDATE_CONTENT, EMPLOYEE_CONTENT} = useContent();

    const items = useMemo(() => {
        if (loading) {
            return [];
        }

        return employment === null ? CANDIDATE_CONTENT : EMPLOYEE_CONTENT;
    }, [loading, employment, CANDIDATE_CONTENT, EMPLOYEE_CONTENT]);

    return <Articles items={items} />;
}
