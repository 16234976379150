import {FetchResult} from '@apollo/client';
import {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import {useCreatePersonalityTest} from '@/api/personalityTests/useCreatePersonalityTest';
import {useRecreateSharedPersonalityTest} from '@/api/personalityTests/useRecreateSharedPersonalityTest';
import {
    CreatePersonalityTestMutation,
    GetMyTestsProgressQuery,
    RecreateSharedPersonalityTestMutation
} from '@/api/types/__generated__/graphql';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {useLocale} from '@/containers/LanguageProvider/useLocale';

import {personalityTestIntroMessages as messages} from '../../messages';

export function useStartTestFormLogic(
    onTestCreated: (personalityTestId: string, organizationId: number) => void,
    shareWithOrganizationId: number,
    user: GetMyTestsProgressQuery['me']
) {
    const {formatMessage} = useIntl();
    const locale = useLocale();
    const [doCreateTest, {loading: creatingTest}] =
        useCreatePersonalityTest(shareWithOrganizationId);
    const [doRecreateSharedPersonalityTest, {loading: recreatingTest}] =
        useRecreateSharedPersonalityTest(shareWithOrganizationId);

    const showErrorToast = useCallback(() => {
        toast({type: 'error', message: formatMessage(messages.errorCreatingTest)});
    }, [formatMessage]);

    const sharedTestExpired = useMemo(
        () => user?.personalityTestProgress.isSharedPersonalityTestExpired,
        [user]
    );

    const startPersonalityTest = useCallback(() => {
        const onResponse = (response: FetchResult<CreatePersonalityTestMutation>) => {
            const responseData = response.data;
            const mutationResponse = responseData?.createPersonalityTest;
            const {ok, personalityTest, errorMessage} = mutationResponse || {};
            if (ok && personalityTest) {
                onTestCreated(personalityTest.id, shareWithOrganizationId);
            } else {
                throw new Error(errorMessage ?? 'Failed to create personality test');
            }
        };

        doCreateTest(locale).then(onResponse).catch(showErrorToast);
    }, [doCreateTest, locale, onTestCreated, shareWithOrganizationId, showErrorToast]);

    const recreatePersonalityTest = useCallback(() => {
        const onResponse = (response: FetchResult<RecreateSharedPersonalityTestMutation>) => {
            const responseData = response.data;
            const mutationResponse = responseData?.recreateSharedPersonalityTest;
            const {ok, personalityTest, errorMessage} = mutationResponse || {};
            if (ok && personalityTest) {
                onTestCreated(personalityTest.id, shareWithOrganizationId);
            } else {
                throw new Error(errorMessage ?? 'Failed to create personality test');
            }
        };
        doRecreateSharedPersonalityTest(locale).then(onResponse).catch(showErrorToast);
    }, [
        doRecreateSharedPersonalityTest,
        locale,
        onTestCreated,
        shareWithOrganizationId,
        showErrorToast
    ]);

    const startTest = useMemo(
        () => (sharedTestExpired === true ? recreatePersonalityTest : startPersonalityTest),
        [sharedTestExpired, recreatePersonalityTest, startPersonalityTest]
    );

    return {
        loading: creatingTest || recreatingTest,
        startTest
    };
}
