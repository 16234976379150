import {FC} from 'react';

import {SpacingXxsmall} from '@/componentLibrary/tokens/variables';

import {Bar, ContentWrapper, Wrapper} from './styled';
import {SeparatorProps} from './types';

export const Separator: FC<SeparatorProps> = ({
    children,
    isHorizontal = true,
    padded = false,
    padding = SpacingXxsmall,
    ...rest
}) => {
    return (
        <Wrapper $isHorizontal={isHorizontal} $padded={padded} $padding={padding} {...rest}>
            {children ? (
                <>
                    <Bar $isHorizontal={isHorizontal} />
                    <ContentWrapper $isHorizontal={isHorizontal}>{children}</ContentWrapper>
                </>
            ) : null}
            <Bar $isHorizontal={isHorizontal} />
        </Wrapper>
    );
};
