import {useMemo} from 'react';

import {GetLogicalAbilityEstimate_LogicalAbilityEstimate} from '../../LogicTest/LogicTestReport/types';

export function useResultsDistributionLogic(
    logicalAbilityEstimate: GetLogicalAbilityEstimate_LogicalAbilityEstimate
) {
    const xCorrectData = useMemo(() => {
        return logicalAbilityEstimate.report.questions
            .filter(({isCorrect}) => isCorrect)
            .map(({difficulty}) => difficulty ?? (5 as number))
            .sort((a, b) => a - b);
    }, [logicalAbilityEstimate.report.questions]);
    const xIncorrectData = useMemo(() => {
        return logicalAbilityEstimate.report.questions
            .filter(({isCorrect}) => !isCorrect)
            .map(({difficulty}) => difficulty ?? (5 as number))
            .sort((a, b) => a - b);
    }, [logicalAbilityEstimate.report.questions]);

    const [yCorrectData, yIncorrectData] = useMemo(() => {
        const correct: number[] = [];
        const incorrect: number[] = [];

        for (let i = 1; i <= 10; i++) {
            let sharedCounterForLevel = 0;
            const correctCountForLevel = xCorrectData.filter(value => value === i).length;
            const incorrectCountForLevel = xIncorrectData.filter(value => value === i).length;

            for (let j = 0; j < correctCountForLevel; j++) {
                correct.push(++sharedCounterForLevel);
            }

            for (let j = 0; j < incorrectCountForLevel; j++) {
                incorrect.push(++sharedCounterForLevel);
            }
        }

        return [correct, incorrect];
    }, [xCorrectData, xIncorrectData]);
    return {xCorrectData, xIncorrectData, yCorrectData, yIncorrectData};
}
