import {useEffect, useState} from 'react';

import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {LoadingScreen} from '@/componentLibrary/components/LoadingScreen';
import {H3, P1} from '@/componentLibrary/components/typography';
import {ColorFgMuted} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {MyEmployment} from './components/MyEmployment';
import {useMyOrganizationsLogic} from './hooks';
import messages from './messages';
import {DescriptionWrapper, InnerWrapper, NoOrganizationsTitle, Wrapper} from './styled';

export function MyOrganizations() {
    const phrases = useExtractPhraseConstants(messages);
    const {
        loading,
        error,
        refetch,
        userOrganizations,
        myEmployments,
        currentAuthenticationMethod,
        message,
        organizationId,
        setEmploymentOrganizationSelectedByUser,
        setPollingEnabledAfterAcceptingRecommendation,
        isRightAfterSignup,
        accessTokenDetails
    } = useMyOrganizationsLogic();

    const [reloadingOnSignup, setReloadingOnSignup] = useState(false);

    useEffect(() => {
        if (userOrganizations.length === 0 && isRightAfterSignup) {
            setReloadingOnSignup(true);
            setPollingEnabledAfterAcceptingRecommendation(true);
        }
    }, [
        isRightAfterSignup,
        userOrganizations.length,
        setPollingEnabledAfterAcceptingRecommendation
    ]);

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    if (loading || (userOrganizations.length === 0 && reloadingOnSignup)) {
        return <LoadingScreen />;
    }

    if (userOrganizations.length === 0) {
        return (
            <Wrapper>
                <NoOrganizationsTitle>{phrases.noOrganizationsTitle}</NoOrganizationsTitle>
                <DescriptionWrapper>
                    <P1 color={ColorFgMuted}>{phrases.noOrganizationsDescription}</P1>
                </DescriptionWrapper>
            </Wrapper>
        );
    }

    return myEmployments.length > 0 ? (
        <Wrapper>
            <H3>{phrases.myEmploymentsTitle}</H3>
            <InnerWrapper>
                {myEmployments.map(userOrganization => (
                    <MyEmployment
                        key={userOrganization.id}
                        userOrganization={userOrganization}
                        selectOrganization={setEmploymentOrganizationSelectedByUser}
                        currentAuthenticationMethod={currentAuthenticationMethod}
                        message={
                            organizationId === userOrganization.organizationId
                                ? (message as string)
                                : null
                        }
                        accessTokenDetails={accessTokenDetails}
                    />
                ))}
            </InnerWrapper>
        </Wrapper>
    ) : null;
}
