import {DisplayMedium} from '@/componentLibrary/components/typography/Display';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    BodyWrapper,
    Wrapper
} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTestCompleted/components/ValidationTestCompleted/styled';

import messages from './messages';

export function ValidationTestCompleted() {
    const phrases = useExtractPhraseConstants(messages);
    return (
        <Wrapper>
            <DisplayMedium>{phrases.title}</DisplayMedium>
            <BodyWrapper>
                <p>{phrases.descriptionResult}</p>
                <p>{phrases.descriptionQuestions}</p>
            </BodyWrapper>
        </Wrapper>
    );
}
