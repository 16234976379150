import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderRadiusBaseRounded,
    ColorBaseGrey600,
    ColorBgActionSecondaryDisabled,
    ColorBgDefault,
    ColorBgInset,
    ColorBorderActionSecondaryDefault,
    ColorBorderActionSecondaryHover,
    ColorBorderDefault,
    ColorBorderError,
    ColorBorderFocus,
    ColorFgDefault,
    ColorFgSubtle,
    FontSizeDefault,
    SpacingSmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';

import {P2} from '../../typography';
import {DEFAULT_HEIGHT} from './constants';
import type {TextFieldProps} from './types';

export const Wrapper = styled.div<{fullWidth: boolean}>`
    display: flex;
    flex-direction: column;
    ${({fullWidth}) => fullWidth && 'flex-grow: 1;'}
`;

export const InputWrapper = styled.div<{fullWidth: boolean; width: string}>`
    position: relative;
    width: ${({fullWidth, width}) => (fullWidth ? '100%' : width)};
`;

export const StyledTextField = styled(P2).attrs({as: 'input'})<TextFieldProps>`
    background-color: ${ColorBgDefault};
    color: ${ColorFgDefault};
    border: 1px solid ${ColorBorderActionSecondaryDefault};
    border-radius: ${BorderRadiusBaseRounded};
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: ${({fullWidth, width}) => (fullWidth ? '100%' : width)};
    height: ${({height = DEFAULT_HEIGHT}) => height};
    padding: ${({isClearable}) =>
        isClearable ? `0 ${SpacingXxlarge} 0 ${SpacingSmall}` : `0 ${SpacingSmall}`};

    &::placeholder {
        color: ${ColorFgSubtle};
    }

    &.error {
        border-color: ${ColorBorderError};
    }

    &:hover {
        border-color: ${ColorBorderActionSecondaryHover};
    }

    &:focus {
        border-color: ${ColorBorderFocus};
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: ${ColorBgActionSecondaryDisabled};
        border-color: ${ColorBorderDefault};
        color: ${ColorBaseGrey600};

        &::placeholder {
            color: ${ColorBgInset};
        }

        &:hover {
            border-color: ${ColorBorderDefault};
        }
    }

    &:read-only {
        &:hover {
            border-color: ${ColorBorderActionSecondaryDefault};
        }

        &:focus {
            border-color: ${ColorBorderActionSecondaryDefault};
        }
    }

    ${({additionalCss}) => additionalCss}

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        font-size: ${FontSizeDefault}; /* Needed to prevent weird zoom behaviour when focusing input */
    }
`;

export const RightSideWrapper = styled.div<{$disabled?: boolean}>`
    position: absolute;
    right: ${SpacingSmall};
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    ${({$disabled}) => $disabled && {'pointer-events': 'none'}};

    [dir='rtl'] & {
        left: ${SpacingSmall};
        right: auto;
    }
`;
