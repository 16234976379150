import {TextEditor} from '@/componentLibrary/components/inputs/TextEditor';
import {InterviewContent} from '@/pages/Organization/hooks/useTranslatedInterviewContent';
import {useExpandableInterviewInstructionLogic} from './logic';
import {TertiaryButton, Wrapper} from './styled';

export type Props = {
    item: InterviewContent;
};

export const ExpandableInterviewInstructionDescription = ({item}: Props) => {
    const {hasOverflowText, description, toggleShowMore, buttonText} =
        useExpandableInterviewInstructionLogic(item);

    return (
        <Wrapper>
            <TextEditor content={description} showContentOnly fullWidth />
            {hasOverflowText && (
                <TertiaryButton onClick={toggleShowMore}>{buttonText}</TertiaryButton>
            )}
        </Wrapper>
    );
};
