import {
    TabLinkActiveSeparator,
    TabsContainer,
    TabsItems
} from '@/componentLibrary/components/Tabs/TabBar/styled';
import camelCase from 'lodash.camelcase';
import {TabItem, TabLink} from './styled';
import {TabBarProps} from './types';

const TabBar = ({tabs}: TabBarProps) => {
    const handleClick = (e: React.MouseEvent, clickHandler: () => void) => {
        e.preventDefault();
        clickHandler();
    };
    const tabsJsx = tabs.map(({title, id, onClick, active}, idx) => (
        <TabItem key={idx} id={camelCase(id)}>
            <TabLink onClick={e => handleClick(e, onClick)} className={active ? 'active' : ''}>
                {title}
            </TabLink>
            {active && <TabLinkActiveSeparator />}
        </TabItem>
    ));

    return (
        <TabsContainer>
            <TabsItems>{tabsJsx}</TabsItems>
        </TabsContainer>
    );
};

export {TabBar};
