import {HTMLProps, Ref, forwardRef} from 'react';
import {ReactSVG} from 'react-svg';

import {ColorFgDefault, ColorFgMuted} from '@/componentLibrary/tokens/variables';

import {IconShell} from './styled';
import {IconProps} from './types';
import {buildIconSrc, isBrandLogo} from './utils';

export const Icon = forwardRef(function Icon(
    {
        icon,
        size = 24,
        fill = ColorFgMuted,
        hoverFill = ColorFgDefault,
        isHoverable = false,
        isAction = false,
        isDisabled = false,
        isActive = false,
        rotate,
        ...rest
    }: IconProps & Omit<HTMLProps<HTMLSpanElement>, 'onClick'>,
    ref: Ref<HTMLSpanElement>
) {
    const iconSrc = buildIconSrc(icon);

    return (
        <IconShell
            ref={ref}
            data-testid={`icon-${icon}`}
            $size={size}
            $fill={isBrandLogo(icon) ? null : fill}
            $hoverFill={hoverFill}
            $isHoverable={isHoverable}
            $isAction={isAction}
            $isDisabled={isDisabled}
            $isActive={isActive}
            $rotate={rotate}
            {...rest}
        >
            <ReactSVG src={iconSrc} wrapper="span" />
        </IconShell>
    );
});
