import {BROWSER_BANNER_LOCAL_STORAGE_KEY} from './constants';

export function isUnsupported() {
    const userAgent = window.navigator.userAgent;
    return isIeBeforeEdge(userAgent) || isUnsupportedSafariVersion(userAgent);
}

function isIeBeforeEdge(userAgent: string) {
    return isIe10OrOlder(userAgent) || isIe11(userAgent);
}

function isIe10OrOlder(userAgent: string) {
    const msie = userAgent.indexOf('MSIE ');
    return msie > 0;
}

function isIe11(userAgent: string) {
    const trident = userAgent.indexOf('Trident/');
    return trident > 0;
}

function isUnsupportedSafariVersion(userAgent: string) {
    const regex = /Mac OS X.*Version\/([0-9]+(\.[0-9]+){0,2}) Safari\//;

    const matches = userAgent.match(regex);

    if (matches) {
        const versionParts = matches[1].split('.').map(Number) ?? [];
        const [major, minor = 0] = versionParts;
        // Check if the version is below 15.6
        if (major < 15 || (major === 15 && minor < 6)) {
            return true;
        }
    }

    return false;
}

export function hasSeenPrompt() {
    return localStorage.getItem(BROWSER_BANNER_LOCAL_STORAGE_KEY) === 'true';
}

export function markPromptAsSeen(): void {
    localStorage.setItem(BROWSER_BANNER_LOCAL_STORAGE_KEY, 'true');
}
