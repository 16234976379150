import {Fragment} from 'react';

import {DisplayMedium, DisplaySmall} from '@/componentLibrary/components/typography/Display';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {personalityDataCollectionMessages} from '@/pages/PersonalityDataCollection/messages';
import {
    CodeWrapper,
    DescriptionWrapper,
    TitleWrapper
} from '@/pages/PersonalityDataCollection/pages/CompletedDataCollectionTest/components/styled';

export function FigureEightCompletedContent({
    completionCode
}: {
    completionCode?: string | null;
}) {
    const phrases = useExtractPhraseConstants(personalityDataCollectionMessages);

    return (
        <Fragment>
            <TitleWrapper>
                <DisplayMedium style={{marginBottom: 20}}>
                    {phrases.completionPageTitle}
                </DisplayMedium>
            </TitleWrapper>
            <DescriptionWrapper>
                <DisplaySmall style={{marginBottom: 20}}>
                    {phrases.completionPageFigureEightContent}
                </DisplaySmall>
            </DescriptionWrapper>
            <CodeWrapper>
                <DisplaySmall style={{marginBottom: 20}}>
                    Completion code: {completionCode}
                </DisplaySmall>
            </CodeWrapper>
        </Fragment>
    );
}
