import {Caption} from '@/componentLibrary/components/typography';
import {
    ColorBaseGrey000,
    ColorBaseGrey200,
    SpacingMedium,
    SpacingXsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-bottom: ${SpacingMedium};
`;

export const WeightBarWrapper = styled.div`
    width: 100%;
    height: 60px;
    margin-bottom: ${SpacingXxxsmall};
    display: flex;
`;

export const WeightBarItem = styled.div<{width: number; backgroundColor: string}>`
    height: 100%;
    width: ${({width}) => width}%;
    background-color: ${({backgroundColor}) => backgroundColor};
    padding: ${SpacingXsmall} ${SpacingMedium};
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PercentageValue = styled(Caption)`
    color: ${ColorBaseGrey000};
`;

export const AssessmentMethodDescription = styled(Caption)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    color: ${ColorBaseGrey200};
`;
