import {useCallback} from 'react';

import {RadioButton} from '@/componentLibrary/components/controls/RadioButton';
import {userDemographicsMessages} from '@/pages/UserDemographics/messages';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {GENDERS} from '@/pages/UserDemographics/components/UserDemographicsForm/components/SelectGender/constants';
import {SelectGenderProps} from '@/pages/UserDemographics/components/UserDemographicsForm/components/SelectGender/types';
import {InputField, InputText} from '../styled';

export function SelectGender({gender, setGender}: SelectGenderProps) {
    const phrases = useExtractPhraseConstants(userDemographicsMessages);
    const onGenderChange = useCallback(
        value => {
            setGender(value);
        },
        [setGender]
    );

    return (
        <InputField>
            <InputText>{phrases.gender}</InputText>
            <RadioButton
                value={GENDERS.MALE}
                isSelected={gender === GENDERS.MALE}
                onSelect={onGenderChange}
                label={phrases.male}
                withMargin
            />
            <RadioButton
                value={GENDERS.FEMALE}
                isSelected={gender === GENDERS.FEMALE}
                onSelect={onGenderChange}
                label={phrases.female}
                withMargin
            />
            <RadioButton
                value={GENDERS.OTHER}
                isSelected={gender === GENDERS.OTHER}
                onSelect={onGenderChange}
                label={phrases.otherGender}
                withMargin
            />
            <RadioButton
                value={GENDERS.RATHER_NOT_SAY}
                isSelected={gender === GENDERS.RATHER_NOT_SAY}
                onSelect={onGenderChange}
                label={phrases.ratherNotSay}
                withMargin
            />
        </InputField>
    );
}
