import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {DestructiveButton} from '@/componentLibrary/components/buttons/DestructiveButton';
import {IconButton} from '@/componentLibrary/components/buttons/IconButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {ModalEmpty} from '@/componentLibrary/components/modals/ModalEmpty';
import {DisplaySmaller} from '@/componentLibrary/components/typography/Display';
import {Markdown} from '@/componentLibrary/components/typography/Markdown';
import {grey} from '@/componentLibrary/tokens/legacyColors';

import {useAnonymizeJobApplicationsForUserInOrganizationModalLogic} from './logic';
import messages from './messages';

export function AnonymizeJobApplicationsForUserInOrganizationModal({
    closeModal,
    userId,
    organizationId,
    backUrl
}) {
    const {loading, anonymizeUser} =
        useAnonymizeJobApplicationsForUserInOrganizationModalLogic(
            userId,
            organizationId,
            closeModal,
            backUrl
        );
    const intl = useIntl();
    const title = intl.formatMessage(messages.modalTitle);
    const description = intl.formatMessage(messages.description);
    const buttonText = intl.formatMessage(messages.anonymizeUser);
    const cancel = intl.formatMessage(messages.cancel);

    return (
        <ModalEmpty close={closeModal}>
            <Wrapper>
                <HeaderWrapper>
                    <DisplaySmaller>{title}</DisplaySmaller>
                    <IconButton onClick={closeModal} />
                </HeaderWrapper>
                <DescriptionWrapper>
                    <Markdown>{description}</Markdown>
                </DescriptionWrapper>
                <ButtonsWrapper>
                    <div>
                        <SecondaryButton onClick={closeModal}>{cancel}</SecondaryButton>
                    </div>
                    <DestructiveButton isLoading={loading} onClick={anonymizeUser}>
                        {buttonText}
                    </DestructiveButton>
                </ButtonsWrapper>
            </Wrapper>
        </ModalEmpty>
    );
}

AnonymizeJobApplicationsForUserInOrganizationModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    organizationId: PropTypes.number.isRequired,
    backUrl: PropTypes.string.isRequired
};

const Wrapper = styled.div`
    width: 480px;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    background-color: #fff;
    border: solid 1px ${grey.grey600()};
`;

const HeaderWrapper = styled.div`
    flex: 0 0 40px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
`;

const DescriptionWrapper = styled.div`
    padding: 4px 20px 24px;
`;

const ButtonsWrapper = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;

    & button {
        margin-right: 10px;
    }
`;
