import {ValidatePropsProps} from './types';

export function validateProps<T>({
    defaultValue,
    value,
    isValueControlled
}: ValidatePropsProps<T>) {
    if (defaultValue !== undefined && value !== undefined) {
        throw new Error('Value must be either controlled or uncontrolled in RadioButton');
    }

    if (isValueControlled && value === undefined) {
        throw new Error(
            'RadioButton does not support changing from controlled to uncontrolled'
        );
    }

    if (!isValueControlled && value !== undefined) {
        throw new Error('Uncontrolled RadioButton does not support setting controlled value');
    }
}
