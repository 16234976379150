import styled from 'styled-components';

import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {P2} from '@/componentLibrary/components/typography';
import {
    ColorBaseGrey600,
    ColorBaseGrey900,
    SpacingSmall,
    SpacingXlarge,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

import {HeaderWrapper} from '../../../CandidateLogicTestPage/styled';

export const ScoreItemWrapper = styled(HeaderWrapper)<{color?: string}>`
    width: 260px;
    ${({color}) => (color ? `color: ${ColorBaseGrey600}` : '')};
    padding-bottom: ${SpacingXlarge};
`;

export const ScorePercentagesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SpacingSmall};
    align-items: flex-start;
`;

export const OverdueDesc = styled(P2)`
    color: ${ColorBaseGrey900};
`;

export const SetOverdueScoreBtn = styled(SecondaryButton)`
    place-self: flex-start;
`;

export const ScorePercentageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXsmall};
    height: 110px;
    justify-content: center;
`;

export const WithButton = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXsmall};
`;
