import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {FormattedDate, FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {assessmentLinks} from '@/pages/Organization/pages/Assessment/links';
import {CurrentStageBadge} from '@/pages/Organization/pages/Assessment/pages/CandidatePage/components/CandidateStageBadge/components/CurrentStageBadge';

import messages from './messages';

export function CandidateJobApplications({userId, jobApplications}) {
    const sortedJobApplications = useMemo(() => {
        const copy = [...jobApplications];
        return copy.sort((a, b) => new Date(b.created) - new Date(a.created));
    }, [jobApplications]);

    const getLinkToRoleFitPage = jobApplication => {
        const jobPositionId = jobApplication.jobPosition.id;

        return assessmentLinks.candidatePage(jobPositionId, userId);
    };

    return (
        <Wrapper>
            {sortedJobApplications.map(jobApplication => (
                <Item key={jobApplication.id}>
                    <Created>
                        <FormattedDate value={new Date(jobApplication.created)} />
                    </Created>
                    <JobPositionName>{jobApplication.jobPosition.name}</JobPositionName>
                    <CurrentStage>
                        <CurrentStageBadge
                            currentStage={jobApplication.currentStage}
                            isSmall
                        />
                    </CurrentStage>
                    <div>
                        <ExternalLink url={getLinkToRoleFitPage(jobApplication)}>
                            <FormattedMessage {...messages.viewRoleFit} />
                        </ExternalLink>
                    </div>
                </Item>
            ))}
        </Wrapper>
    );
}

CandidateJobApplications.propTypes = {
    userId: PropTypes.number.isRequired,
    jobApplications: PropTypes.arrayOf(PropTypes.object)
};

const Wrapper = styled.div`
    margin-bottom: 20px;
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 10px;

    & div {
        margin-right: 12px;
    }
`;

const Created = styled.div`
    flex: 0 0 80px;
    font-size: 12px;
`;

const JobPositionName = styled.div`
    flex: 0 0 240px;
`;

const CurrentStage = styled.div`
    flex: 0 1 120px;
`;
