import {RoleFitTag} from '@/componentLibrary/components/RoleFitTag';
import {onBaseFive} from '@/utils/misc';

import {
    BadgeWrapper,
    ColoredHeader,
    EmptyScoreText,
    InnerScoreWrapper,
    ScoreText,
    ScoreWrapper
} from './styled';
import {ScoreProps} from './types';

export function RoleFitScore({roleFit, isInterviewScore, customScore}: ScoreProps) {
    const getScorePercentage = (score: number | null) =>
        score === null ? null : Math.round(score * 100);

    const score = (isInterviewScore ? customScore : roleFit?.score) ?? null;

    const showScore = () => {
        const suffix = isInterviewScore ? '/5' : '%';
        const textScore = isInterviewScore
            ? onBaseFive(score as number)
            : getScorePercentage(score);
        return (
            <>
                <ScoreText>{textScore}</ScoreText>
                <ColoredHeader>{suffix}</ColoredHeader>
            </>
        );
    };

    return (
        <ScoreWrapper>
            <InnerScoreWrapper>
                {score === null ? <EmptyScoreText>N/A</EmptyScoreText> : showScore()}
            </InnerScoreWrapper>
            {!isInterviewScore && (
                <BadgeWrapper>
                    <RoleFitTag roleFit={roleFit} />
                </BadgeWrapper>
            )}
        </ScoreWrapper>
    );
}
