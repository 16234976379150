import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useAuthenticateCandidate} from '@/api/users/useAuthenticateCandidate';
import {TOKEN_ERRORS} from '@/pages/Signup/pages/ValidateSignupToken/errors';
import {setUserAuthToken} from '@/services/auth/utils';
import {OrganizationData, ValidateTokenProps} from './types';

export function ValidateToken({token, onError, onLoading, onSuccess}: ValidateTokenProps) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [organizationData, setOrganizationData] = useState<OrganizationData | null>(null);
    const [validateToken, {error}] = useAuthenticateCandidate();
    const history = useHistory();
    useEffect(() => {
        validateToken(token)
            .then(({data}) => {
                const {
                    ok,
                    errorMessage,
                    organizationId,
                    organizationName,
                    organizationLogo,
                    authToken,
                    acceptedByCandidate
                } = data?.authenticateCandidate ?? {};
                if (ok && authToken) {
                    setUserAuthToken(authToken);

                    // Needs some delay to make sure that token is set in localStorage
                    window.setTimeout(() => {
                        const organization = {
                            id: organizationId,
                            name: organizationName,
                            logo: organizationLogo,
                            acceptedByCandidate
                        };
                        setOrganizationData(organization);
                    }, 250);
                } else {
                    if (errorMessage === TOKEN_ERRORS.USER_ALREADY_ACTIVATED) {
                        let url = `/login?message=${errorMessage}`;
                        history.push(url);
                    }
                    throw new Error(errorMessage ?? 'Unknown error in ValidateToken');
                }
            })
            .catch(e => {
                setErrorMessage(e.message);
            });
    }, [history, token, validateToken]);

    if (error) {
        return onError(error);
    }

    if (errorMessage) {
        return onError(errorMessage);
    }

    if (organizationData) {
        return onSuccess(organizationData);
    }

    // This state is treated similar as loading
    return onLoading();
}
