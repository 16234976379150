import {useCallback} from 'react';

import {SegmentedControlOption} from '@/componentLibrary/components/SegmentedControl/types';

export const useOptionLogic = <T>(
    option: SegmentedControlOption<T>,
    onSelect?: (value: T) => void
) => {
    return useCallback(() => {
        if (!option.disabled) {
            onSelect?.(option.value);
        }
    }, [onSelect, option]);
};
