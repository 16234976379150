import {defineMessages} from 'react-intl';

export default defineMessages({
    completedTitle: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.completedTitle'
    },
    completedDescription: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.completedDescription'
    },
    title: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.title'
    },
    description: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.description'
    },
    firstName: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.firstName'
    },
    lastName: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.lastName'
    },
    namePlaceholder: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.namePlaceholder'
    },
    email: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.email'
    },
    emailPlaceholder: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.emailPlaceholder'
    },
    getStarted: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.getStarted'
    },
    consent: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.consent'
    },
    failedToApply: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.failedToApply'
    },
    warning: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.warning'
    },
    warningInvalidEmail: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.warningInvalidEmail'
    },
    warningBlockedCountry: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.warningBlockedCountry'
    },
    privacyPolicyNotice: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.privacyPolicyNotice'
    },
    privacyPolicy: {
        id: 'app.pages.publicJobPosition.pages.applyForJobPosition.applyForm.privacyPolicy'
    }
});
