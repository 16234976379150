import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const FactorWrapper = styled.div`
    margin-inline-end: ${SpacingXsmall};

    @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
        max-width: 260px;
    }
`;
