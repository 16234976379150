import {PERSONALITY_FACTORS, PersonalityFactorKey} from '../../constants';
import {TransformedValues} from '../../types';
import {TestResult, getLabelFromResultValue} from './types';

export function getPersonalityFactorsFromTestResult(testResult: Partial<TransformedValues>) {
    const getDeviationForValue = (value: number) => Math.abs(5.5 - value);

    return PERSONALITY_FACTORS.map(factorName => {
        const factorValue = testResult[factorName.toLowerCase() as keyof TestResult] as number;

        return {
            factorName: factorName,
            value: factorValue,
            deviation: getDeviationForValue(factorValue),
            label: getLabelFromResultValue(factorValue)
        };
    });
}

export function sortPersonalityFactorResultBasedOnDeviation(
    personalityFactorResult: {
        factorName: PersonalityFactorKey;
        value: number;
        deviation: number;
        label: string;
    }[]
) {
    return personalityFactorResult.sort((a, b) => b.deviation - a.deviation);
}
