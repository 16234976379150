import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {ColorFgOnEmphasis} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import messages from './messages';
import {
    AbstractWrapper,
    DescriptionTitle,
    DetailsWrapper,
    InfoWrapper,
    InstructionsWrapper,
    ParagraphWrapper,
    SubParagraphWrapper
} from './styles';

export function ApplyInstructions() {
    const phrases = useExtractPhraseConstants(messages);

    return (
        <InstructionsWrapper>
            <ParagraphWrapper>
                <DescriptionTitle>{phrases.whatIsAlva}</DescriptionTitle>
                <AbstractWrapper>{phrases.alvaDescription}</AbstractWrapper>
            </ParagraphWrapper>
            <ParagraphWrapper>
                <DescriptionTitle>{phrases.whatNext}</DescriptionTitle>
                <SubParagraphWrapper>
                    <Icon icon={Icons.MAIL} fill={ColorFgOnEmphasis} size={16} />
                    <InfoWrapper>
                        <AbstractWrapper>{phrases.createAccount}</AbstractWrapper>
                        <DetailsWrapper>{phrases.createAccountDescription}</DetailsWrapper>
                    </InfoWrapper>
                </SubParagraphWrapper>
            </ParagraphWrapper>
            <ParagraphWrapper>
                <SubParagraphWrapper>
                    <Icon icon={Icons.CATEGORY} fill={ColorFgOnEmphasis} size={16} />
                    <InfoWrapper>
                        <AbstractWrapper>{phrases.completeAssessments}</AbstractWrapper>
                        <DetailsWrapper>
                            {phrases.completeAssessmentsDescription}
                        </DetailsWrapper>
                    </InfoWrapper>
                </SubParagraphWrapper>
            </ParagraphWrapper>
            <ParagraphWrapper>
                <SubParagraphWrapper>
                    <Icon icon={Icons.CELEBRATION} fill={ColorFgOnEmphasis} size={16} />
                    <InfoWrapper>
                        <AbstractWrapper>{phrases.checkResults}</AbstractWrapper>
                        <DetailsWrapper>{phrases.checkResultsDescription}</DetailsWrapper>
                    </InfoWrapper>
                </SubParagraphWrapper>
            </ParagraphWrapper>
        </InstructionsWrapper>
    );
}
