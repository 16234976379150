import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {
    InvitationOptionsInput,
    SendBatchInviteCandidateEmailsMutation
} from '@/api/types/__generated__/graphql';

import {MutationHookReturnType} from '../types/genericApi/types';
import {SEND_INVITE_CANDIDATE_EMAILS} from './mutations';

export function useSendCandidateInvitations(
    jobPositionId: number
): MutationHookReturnType<
    SendBatchInviteCandidateEmailsMutation,
    [number[], InvitationOptionsInput]
> {
    const [mutate, {error, loading}] = useMutation(SEND_INVITE_CANDIDATE_EMAILS);

    const doSendCandidateInvitationEmails = useCallback(
        (userIds: number[], invitationOptions: InvitationOptionsInput) => {
            const variables = {data: {userIds, jobPositionId, invitationOptions}};
            return mutate({variables});
        },
        [mutate, jobPositionId]
    );

    return [doSendCandidateInvitationEmails, {error, loading}];
}
