import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {TextBlock} from '@/componentLibrary/components/textBlocks/TextBlock';
import {P2, S2} from '@/componentLibrary/components/typography';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {ButtonWrapper, ContentItem, Wrapper} from './styled';
import {EmptyStateProps} from './types';

export function EmptyState({
    titleText,
    contentText,
    actionZone,
    isSmall = false,
    isLarge = false,
    actions
}: EmptyStateProps) {
    const title = <S2>{titleText}</S2>;
    const content = isDefined(contentText) ? <P2>{contentText}</P2> : undefined;

    const getActionComponent = () => {
        if (actions) {
            return (
                <ButtonWrapper>
                    {!!actions.secondary && (
                        <SecondaryButton onClick={actions.secondary.onClick}>
                            {actions.secondary.text}
                        </SecondaryButton>
                    )}
                    {!!actions.primary && (
                        <PrimaryButton onClick={actions.primary.onClick}>
                            {actions.primary.text}
                        </PrimaryButton>
                    )}
                </ButtonWrapper>
            );
        }
        return <ContentItem>{actionZone}</ContentItem>;
    };

    return (
        <Wrapper>
            <ContentItem>
                <TextBlock
                    title={title}
                    content={content}
                    isSmall={isSmall}
                    isLarge={isLarge}
                    isCentered
                />
            </ContentItem>
            {getActionComponent()}
        </Wrapper>
    );
}
