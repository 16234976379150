import {defineMessages} from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'app.general.cancel'
    },
    confirm: {
        id: 'app.general.confirm'
    },
    delete: {
        id: 'app.general.delete'
    },
    areYouSure: {
        id: 'app.general.areYouSure'
    }
});

export const discardAlertMessages = defineMessages({
    title: {
        id: 'app.general.discardChangesAlert.title'
    },
    description: {
        id: 'app.general.discardChangesAlert.description'
    },
    safeAction: {
        id: 'app.general.discardChangesAlert.safeAction'
    },
    destructiveAction: {
        id: 'app.general.discardChangesAlert.destructiveAction'
    }
});
