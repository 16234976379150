import {defineMessages} from 'react-intl';

export default defineMessages({
    leadershipTitle: {
        id: 'app.general.leadership'
    },
    leadershipDescription: {
        id: 'leadershipReportSummary.description'
    },
    leadershipTitleForPrint: {
        id: 'app.assessment.candidatePage.overview'
    },
    seeFullReport: {
        id: 'app.pages.user.jobApplication.testsForJobPosition.testItem.showPreviousResults.seeFullReport'
    },
    ACHIEVING_RESULTS: {
        id: 'leadershipReport.factor.achievingResults.title'
    },
    INFLUENCING_OTHERS: {
        id: 'leadershipReport.factor.influencingOthers.title'
    },
    NAVIGATING: {
        id: 'leadershipReport.factor.navigating.title'
    }
});
