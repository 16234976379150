import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {id: 'app.jobRecommendations.linkedInApplicationFeedback.title'},
    body: {id: 'app.jobRecommendations.linkedInApplicationFeedback.body'},
    label: {id: 'app.jobRecommendations.linkedInApplicationFeedback.input.label'},
    noProfile: {id: 'app.jobRecommendations.linkedInApplicationFeedback.input.noProfile'},
    dontWantToShare: {
        id: 'app.jobRecommendations.linkedInApplicationFeedback.input.dontWantToShare'
    },
    previous: {id: 'app.general.previous'},
    ok: {id: 'app.general.ok'},
    successTitle: {
        id: 'app.jobRecommendations.linkedInApplicationFeedback.success.title'
    },
    successBody: {
        id: 'app.jobRecommendations.linkedInApplicationFeedback.success.body'
    }
});
