import {
    BorderRadiusBaseRounded,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';
import {ScoreCircleProps} from './types';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ScoreCircle = styled.div<ScoreCircleProps>`
    &:not(:first-child) {
        margin-left: ${SpacingXxxsmall};
    }

    width: ${SpacingXxsmall};
    height: ${SpacingXxsmall};
    border-radius: ${BorderRadiusBaseRounded};
    background: ${props => props.color};
`;
