import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {S2} from '@/componentLibrary/components/typography/SubTitles';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {FactorWrapper} from './styled';

type ColumnDescriptionProps = {
    title: string;
    description: string;
};

export function ColumnDescription({title, description}: ColumnDescriptionProps) {
    return (
        <FactorWrapper>
            <S2>{title}</S2>
            <P2 color={ColorFgSubtle}>{description}</P2>
        </FactorWrapper>
    );
}
