import {useCallback, useEffect, useMemo} from 'react';

import {useGetPreviousQuestionForPersonalityTestV2} from '@/api/personalityTests/useGetPreviousQuestionForPersonalityTestV2';
import {usePersonalityTestV2} from '@/api/personalityTests/usePersonalityTestV2';
import {useSaveAnswerForPersonalityTestV2} from '@/api/personalityTests/useSaveAnswerForPersonalityTestV2';
import {useSkipQuestionForPersonalityTestV2} from '@/api/personalityTests/useSkipQuestionForPersonalityTestV2';
import {PersonalityTestStatusV2} from '@/api/types/__generated__/graphql';
import {useChangeLocaleForUser} from '@/api/users/useChangeLocaleForUser';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {PageCenteredSpinner} from '@/componentLibrary/components/spinners/PageCenteredSpinner';
import {useChangeLocale} from '@/containers/LanguageProvider/useChangeLocale';
import {useGoToCompletedDataCollectionTest} from '@/pages/PersonalityDataCollection/hooks/useGoToCompletedDataCollectionTest';
import {PersonalityTestProps} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/types';
import {logger} from '@/services/logrocket';

import {ErrorLoadingTest} from './components/ErrorLoadingTest';
import {PersonalityTestFormV2} from './components/PersonalityTestForm';
import {TestNotFound} from './components/TestNotFound';

export function PersonalityTestV2({
    goBack,
    goToTestCompleted,
    personalityTestId,
    organizationId
}: PersonalityTestProps) {
    const {locale, changeLocale} = useChangeLocale();
    const [doChangeLocale] = useChangeLocaleForUser();
    const {user, loading: loadingUser} = useLoggedInUser();

    const {
        loading: loadingPersonalityTestStatus,
        error: personalityTestStatusError,
        personalityTestV2
    } = usePersonalityTestV2(personalityTestId, true);

    const personalityTestStatus = useMemo(() => {
        if (!personalityTestV2) {
            return null;
        }
        return personalityTestV2.personalityTestStatusV2;
    }, [personalityTestV2]);

    const personalityTest = useMemo(() => {
        if (!personalityTestV2) {
            return null;
        }
        return personalityTestV2;
    }, [personalityTestV2]);

    const personalityTestLocale = useMemo(() => {
        if (!personalityTestV2) {
            return null;
        }
        return personalityTestV2.locale.toLowerCase();
    }, [personalityTestV2]);

    const [saveAnswerV2, {loading: savingAnswerV2}] =
        useSaveAnswerForPersonalityTestV2(personalityTestId);
    const [skipQuestionV2, {loading: skippingQuestionV2}] =
        useSkipQuestionForPersonalityTestV2(personalityTestId);
    const [getPreviousQuestionV2, {loading: loadingPreviousQuestionV2}] =
        useGetPreviousQuestionForPersonalityTestV2(personalityTestId);

    useEffect(() => {
        if (personalityTestLocale && user && personalityTestLocale !== locale) {
            doChangeLocale(user.id, personalityTestLocale)
                .then(() => {
                    changeLocale(personalityTestLocale);
                })
                .catch(e => {
                    logger.info(e);
                });
        }
    }, [changeLocale, doChangeLocale, personalityTestLocale, locale, user]);

    const goToDataCollectionTestCompleted =
        useGoToCompletedDataCollectionTest(personalityTestId);

    const onError = useCallback((error: Error) => {
        logger.log(error);
        return <ErrorLoadingTest />;
    }, []);

    const onTestCompleted = useCallback(() => {
        if (!personalityTest?.id) {
            return;
        }

        if (personalityTest?.isUsedInDataCollection) {
            goToDataCollectionTestCompleted();
        } else {
            goToTestCompleted(personalityTest.id, organizationId);
        }
    }, [
        personalityTest?.isUsedInDataCollection,
        personalityTest?.id,
        goToDataCollectionTestCompleted,
        goToTestCompleted,
        organizationId
    ]);

    if (loadingUser || loadingPersonalityTestStatus) {
        return <PageCenteredSpinner />;
    }

    if (personalityTestStatusError) {
        return onError(personalityTestStatusError);
    }

    if (!personalityTestStatus) {
        return <TestNotFound />;
    }

    return (
        <PersonalityTestFormV2
            goBack={goBack}
            onTestCompleted={onTestCompleted}
            initialStatus={personalityTestStatus as PersonalityTestStatusV2}
            saveAnswer={saveAnswerV2}
            savingAnswer={savingAnswerV2}
            skipQuestion={skipQuestionV2}
            skippingQuestion={skippingQuestionV2}
            getPreviousQuestion={getPreviousQuestionV2}
            loadingPreviousQuestion={loadingPreviousQuestionV2}
            isUsedInDataCollection={personalityTest?.isUsedInDataCollection}
        />
    );
}
