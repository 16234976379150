import styled from 'styled-components';

import {SpacingMedium, SpacingSmall} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
`;

export const ContentItem = styled.div`
    margin: ${SpacingMedium} auto 0;
`;

export const ButtonWrapper = styled.div`
    margin: ${SpacingMedium} auto 0;
    display: flex;
    justify-content: center;
    gap: ${SpacingSmall};
`;
