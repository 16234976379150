import {
    FloatingArrow,
    FloatingPortal,
    useMergeRefs,
    useTransitionStyles
} from '@floating-ui/react';
import {HTMLProps, forwardRef} from 'react';

import {ColorBgDefault} from '@/componentLibrary/tokens/variables';

import {ARROW_HEIGHT, ARROW_WIDTH} from '../constants';
import {useTooltipContext} from '../hooks';
import {Content, ContentWrapper} from './styled';

export const TooltipContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
    function TooltipContent({style, ...props}, propRef) {
        const {
            context,
            getFloatingProps,
            floatingStyles,
            hasArrow,
            arrowRef,
            placement,
            refs,
            open
        } = useTooltipContext();
        const ref = useMergeRefs([refs.setFloating, propRef]);

        const {styles: transitionStyles} = useTransitionStyles(context, {
            duration: 225,
            initial: {
                opacity: 0
            }
        });

        if (!open) {
            return null;
        }

        const {children, ...propsWithoutChildren} = props;

        const arrowStyle = ['left', 'right'].includes(placement)
            ? {transform: 'translateY(-2px)'}
            : {};

        return (
            <FloatingPortal>
                <ContentWrapper
                    ref={ref}
                    style={{...floatingStyles}}
                    {...getFloatingProps(propsWithoutChildren)}
                >
                    <Content style={{...transitionStyles, ...style}}>
                        {children}
                        {hasArrow && (
                            <FloatingArrow
                                ref={arrowRef}
                                context={context}
                                fill={ColorBgDefault}
                                style={arrowStyle}
                                height={ARROW_HEIGHT}
                                width={ARROW_WIDTH}
                            />
                        )}
                    </Content>
                </ContentWrapper>
            </FloatingPortal>
        );
    }
);
