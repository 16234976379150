import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {Link} from '@/componentLibrary/components/links/Link';
import {TestProfileGraph} from '@/pages/Organization/pages/Assessment/components/TestProfileGraph';
import {Fragment} from 'react';
import {useIntl} from 'react-intl';

import {useTestProfileOverviewLogic} from './logic';
import messages from './messages';
import {TestProfileName, Wrapper} from './styled';
import {TestProfileOverviewProps} from './types';

export function TestProfileOverview({
    testProfile,
    includePersonalityTest,
    includeLogicTest
}: TestProfileOverviewProps) {
    const intl = useIntl();
    const {modalIsOpen, openModal, closeModal} = useTestProfileOverviewLogic(testProfile);

    if (!includeLogicTest && !includePersonalityTest) {
        return null;
    }

    return (
        <Fragment>
            <Wrapper>
                <TestProfileName>
                    {intl.formatMessage(messages.selectedTestProfile, {
                        name: testProfile ? testProfile.name : ''
                    })}
                    <span>
                        {' '}
                        <Link notReactRouter onClick={openModal}>
                            {intl.formatMessage(messages.viewTestProfileDetails)}
                        </Link>
                    </span>
                </TestProfileName>
            </Wrapper>
            {modalIsOpen ? (
                <Modal
                    close={closeModal}
                    headerTitle={intl.formatMessage(messages.selectedTestProfile, {
                        name: testProfile ? testProfile.name : ''
                    })}
                    size={MODAL_SIZE.LARGE}
                >
                    <TestProfileGraph
                        testProfile={testProfile}
                        displayRanges
                        includePersonalityTest={includePersonalityTest}
                        includeLogicTest={includeLogicTest}
                    />
                </Modal>
            ) : null}
        </Fragment>
    );
}
