import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';

import {BannerAction} from '../../types';

type Props = {
    action: BannerAction;
    isPromo?: boolean;
};

export function BannerButton({action, isPromo}: Props) {
    const {text, ...rest} = action || {};

    const BannerBtn = isPromo ? PrimaryButton : SecondaryButton;

    return <BannerBtn {...rest}>{text}</BannerBtn>;
}
