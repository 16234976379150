import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {Separator} from '@/componentLibrary/components/Separator';
import {Tag} from '@/componentLibrary/components/Tag';
import {TagVariant} from '@/componentLibrary/components/Tag/constants';
import {OrganizationAvatar} from '@/componentLibrary/components/avatars/OrganizationAvatar';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {CardBase} from '@/componentLibrary/components/cards/CardBase';
import {H4, P2} from '@/componentLibrary/components/typography';
import {Caption} from '@/componentLibrary/components/typography/Captions';
import {ColorBaseGrey700} from '@/componentLibrary/tokens/variables';
import {formatDate} from '@/utils/formatDates';

import {RenderAssessmentProgress} from './components/RenderAssessmentProgress';
import {TEST_STATUSES} from './constants';
import {useMyApplicationLogic} from './logic';
import messages from './messages';
import {
    ActionColumn,
    Header,
    OrganizationNameWrapper,
    Overlay,
    TitleWrapper,
    Wrapper
} from './styled';
import {MyApplicationProps} from './types';

export function MyApplication({userOrganization, goToJobApplication}: MyApplicationProps) {
    const {
        logicTestStatus,
        personalityTestStatus,
        assessmentStatus,
        codingTestAllStatuses,
        tagVariant,
        redirectToApplication,
        jobApplicationsForOrganization: jobApplications
    } = useMyApplicationLogic(userOrganization, goToJobApplication);
    const intl = useIntl();

    const JobPositionsText = useCallback(() => {
        if (!jobApplications.length) {
            return null;
        }
        const numberOfJobPositions = jobApplications.length;
        const firstJobPositionName = jobApplications[0].jobPosition?.name as string;
        let jobPositionsText = '';
        if (numberOfJobPositions === 1) {
            jobPositionsText = firstJobPositionName;
        } else if (numberOfJobPositions > 1) {
            jobPositionsText = intl.formatMessage(messages.jobPositionsText, {
                jobTitle: firstJobPositionName,
                number: numberOfJobPositions - 1
            });
        }
        return <P2>{jobPositionsText}</P2>;
    }, [intl, jobApplications]);

    const getHeaderSubText = useCallback(() => {
        if (userOrganization.jobApplicationsWithdrawnAt) {
            return (
                <Caption color={ColorBaseGrey700}>
                    {intl.formatMessage(messages.withdrawnAt)}{' '}
                    {formatDate(userOrganization.jobApplicationsWithdrawnAt)}
                </Caption>
            );
        }
        return <JobPositionsText />;
    }, [JobPositionsText, intl, userOrganization.jobApplicationsWithdrawnAt]);

    return (
        <Wrapper>
            <Overlay isVisible={!!userOrganization.jobApplicationsWithdrawnAt}>
                <CardBase isActive>
                    <Header>
                        <TitleWrapper>
                            <OrganizationAvatar
                                name={userOrganization.organizationName}
                                image={userOrganization.organizationLogo}
                            />
                            <OrganizationNameWrapper>
                                <H4>{userOrganization.organizationName}</H4>
                                {getHeaderSubText()}
                            </OrganizationNameWrapper>
                        </TitleWrapper>
                        {userOrganization.jobApplicationsWithdrawnAt && (
                            <Tag $variant={TagVariant.defaultInverted}>
                                {intl.formatMessage(messages.withdrawn)}
                            </Tag>
                        )}
                        {!userOrganization.jobApplicationsWithdrawnAt &&
                            !!assessmentStatus &&
                            assessmentStatus !== TEST_STATUSES.NOT_STARTED && (
                                <Tag $variant={tagVariant}>
                                    {intl.formatMessage(messages[assessmentStatus])}
                                </Tag>
                            )}
                    </Header>
                    <Separator padded />
                    <RenderAssessmentProgress
                        assessmentStatus={assessmentStatus}
                        personalityTestStatus={personalityTestStatus}
                        logicTestStatus={logicTestStatus}
                        codingTestAllStatuses={codingTestAllStatuses}
                    />
                    <ActionColumn>
                        <SecondaryButton onClick={redirectToApplication}>
                            {intl.formatMessage(messages.goToApplication)}
                        </SecondaryButton>
                    </ActionColumn>
                </CardBase>
            </Overlay>
        </Wrapper>
    );
}
