import {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import messages from '../../messages';

const PERCENTILE_VISIBILITY_CANDIDATE_BOUNDARY = 10;

export function useRoleFitChartsLogic(roleFitDistribution, roleFitHistogram) {
    const {formatMessage} = useIntl();

    const [segmentedControls, setSegmentedControls] = useState([
        {value: 1, text: formatMessage(messages.histogram), isSelected: true},
        {
            value: 2,
            text: formatMessage(messages.distribution),
            isSelected: false
        }
    ]);

    const toggleSegmentedControls = useCallback(
        () =>
            setSegmentedControls(
                segmentedControls.map(control => ({
                    ...control,
                    isSelected: !control.isSelected
                }))
            ),
        [segmentedControls]
    );

    const isHistogramChartEnabled = useMemo(
        () => segmentedControls[0].isSelected,
        [segmentedControls]
    );

    const distributionParameters = {
        alpha: roleFitDistribution.alpha,
        location: roleFitDistribution.location,
        scale: roleFitDistribution.scale
    };

    const defaultRoleFitPercentileBoundaries = [50, 75, 90, 95, 99].map(item => ({
        boundary: item,
        value: item / 100
    }));

    const isPercentileBoundariesVisible =
        roleFitDistribution.candidatesWithScore >= PERCENTILE_VISIBILITY_CANDIDATE_BOUNDARY;

    const percentileText = useMemo(() => {
        if (roleFitDistribution.score === null) {
            return '';
        }
        for (let i = roleFitDistribution.percentileBoundaries.length - 1; i >= 0; i--) {
            const percentileBoundary = roleFitDistribution.percentileBoundaries[i];
            if (roleFitDistribution.score >= percentileBoundary.value) {
                return formatMessage(messages.topPercentage, {
                    value: 100 - percentileBoundary.boundary
                });
            }
        }
        return formatMessage(messages.bottomPercentage, {value: 50});
    }, [formatMessage, roleFitDistribution.percentileBoundaries, roleFitDistribution.score]);

    const titleText = formatMessage(messages.chartTitle);
    const yAxisTitleText = formatMessage(messages.chartYAxisTitle);
    const disabledText = !isPercentileBoundariesVisible
        ? formatMessage(messages.distributionChartDisabledText, {
              value: PERCENTILE_VISIBILITY_CANDIDATE_BOUNDARY
          })
        : '';

    const tooltipTexts = useMemo(() => {
        if (!isPercentileBoundariesVisible) {
            return [];
        }
        return roleFitDistribution.percentileBoundaries.map(percentileBoundary =>
            formatMessage(messages.tooltip, {
                boundary: percentileBoundary.boundary,
                score: Math.round(percentileBoundary.value * 100)
            })
        );
    }, [
        formatMessage,
        isPercentileBoundariesVisible,
        roleFitDistribution.percentileBoundaries
    ]);

    const labelTexts = useMemo(() => {
        if (!isPercentileBoundariesVisible) {
            return defaultRoleFitPercentileBoundaries.map(percentileBoundary =>
                formatMessage(messages.label, {
                    boundary: 100 - percentileBoundary.boundary
                })
            );
        }
        return roleFitDistribution.percentileBoundaries.map(percentileBoundary =>
            formatMessage(messages.label, {boundary: 100 - percentileBoundary.boundary})
        );
    }, [
        formatMessage,
        isPercentileBoundariesVisible,
        defaultRoleFitPercentileBoundaries,
        roleFitDistribution.percentileBoundaries
    ]);

    const percentileBoundaries = useMemo(() => {
        if (!isPercentileBoundariesVisible) {
            return defaultRoleFitPercentileBoundaries;
        }
        return roleFitDistribution.percentileBoundaries;
    }, [
        isPercentileBoundariesVisible,
        defaultRoleFitPercentileBoundaries,
        roleFitDistribution.percentileBoundaries
    ]);

    const roleFitHistogramData = useMemo(
        () =>
            roleFitHistogram &&
            roleFitHistogram.buckets.map(item => {
                const {lower, upper, userCount: value} = item;
                return {
                    lower,
                    upper,
                    value,
                    tooltip: formatMessage(messages.barChartTooltip, {value, lower, upper})
                };
            }),
        [roleFitHistogram, formatMessage]
    );

    const histogramXAxisTitle = formatMessage(messages.roleFit);
    const histogramYAxisTitle = formatMessage(messages.candidates);

    return {
        distribution: {
            titleText,
            yAxisTitleText,
            defaultRoleFitPercentileBoundaries,
            percentileText,
            labelTexts,
            percentileBoundaries,
            tooltipTexts,
            distributionParameters,
            isPercentileBoundariesVisible,
            disabledText
        },
        histogram: {
            segmentedControls,
            isHistogramChartEnabled,
            toggleSegmentedControls,
            roleFitHistogramData,
            histogramXAxisTitle,
            histogramYAxisTitle
        }
    };
}
