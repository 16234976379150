import {useCallback} from 'react';

import {useLogicalAbilityEstimate} from '@/api/logicTestIrt/useLogicalAbilityEstimate';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {LogicTestReportChart} from '@/componentLibrary/blocks/testReports/LogicTest/LogicTestReportChart';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {S1} from '@/componentLibrary/components/typography';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {LOGIC_TEST_IRT_LINKS} from '@/pages/LogicTestIrt/links';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useSharePreviousResultsLogic} from '../../hooks';
import messages from './messages';
import {ChartWrapper, HeaderWrapper, Wrapper} from './styled';
import {Props} from './types';

export function ShowPreviousLogicTestResult({
    setShowPreviousLogicTestResult,
    organizationId,
    logicTestId
}: Props) {
    const {title, description, seeFullReport, cancel, shareLogic, logicTestResults} =
        useExtractPhraseConstants(messages);

    const {logicalAbilityEstimate, loading, error, refetch} =
        useLogicalAbilityEstimate(logicTestId);
    const {user} = useLoggedInUser();
    const {sharingLogicTest, shareLogicTest} = useSharePreviousResultsLogic(
        user ? user.id : null,
        logicTestId,
        null,
        organizationId,
        setShowPreviousLogicTestResult
    );

    const closeModal = useCallback(() => {
        setShowPreviousLogicTestResult(false);
    }, [setShowPreviousLogicTestResult]);

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }

    if (!user || !logicalAbilityEstimate || loading) {
        return null;
    }

    const link = LOGIC_TEST_IRT_LINKS.report(logicTestId);

    return (
        <Modal
            close={closeModal}
            headerTitle={title}
            isVerticallyCentered
            footer={{
                leftSecondaryAction: (
                    <SecondaryButton onClick={closeModal}>{cancel}</SecondaryButton>
                ),
                primaryAction: (
                    <PrimaryButton isLoading={sharingLogicTest} onClick={shareLogicTest}>
                        {shareLogic}
                    </PrimaryButton>
                )
            }}
        >
            <Wrapper>
                <P2>{description}</P2>
                <HeaderWrapper>
                    <S1>{logicTestResults}</S1>
                    <ExternalLink url={link}>{seeFullReport}</ExternalLink>
                </HeaderWrapper>
                <ChartWrapper>
                    <LogicTestReportChart logicalAbilityEstimate={logicalAbilityEstimate} />
                </ChartWrapper>
            </Wrapper>
        </Modal>
    );
}
