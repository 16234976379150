import {useCallback} from 'react';

import {useRequestChallengeAccountAccess} from '@/api/assessment/codingTests/useRequestChallengeAccountAccess';
import {useRequestChallengeAssignmentAccountAccess} from '@/api/assessment/codingTests/useRequestChallengeAssignmentAccountAccess';
import {AccountAccess, AuthorizationResultInput} from '@/api/types/__generated__/graphql';
import {toast} from '@/componentLibrary/components/Toast/toast';

function handleAccountAccess({authenticationRedirectUrl, hasAccess, url}: AccountAccess) {
    if (!authenticationRedirectUrl) {
        return hasAccess ? url : null;
    }

    window.open(authenticationRedirectUrl ?? '', '_blank', 'noopener, noreferrer');
}

export const useCheckAccountAccess = () => {
    const [doChallengeAccountAccess] = useRequestChallengeAccountAccess();

    const checkAccountAccess = useCallback(
        (challengeId: string, input?: AuthorizationResultInput) => {
            const getAccountAccess = async () => {
                try {
                    const {data} = await doChallengeAccountAccess(challengeId, input);
                    const {ok, accountAccess, errorMessage} =
                        data?.requestChallengeAccountAccess ?? {};
                    if (!ok || !accountAccess) {
                        throw new Error(errorMessage ?? '');
                    }

                    return handleAccountAccess(accountAccess);
                } catch (_error) {
                    toast({type: 'error'});
                }
            };
            return getAccountAccess();
        },
        [doChallengeAccountAccess]
    );
    return checkAccountAccess;
};

export const useCheckAssignmentAccountAccess = () => {
    const [doChallengeAssignmentAccountAccess, {loading}] =
        useRequestChallengeAssignmentAccountAccess();

    const checkAssignmentAccountAccess = useCallback(
        (assignmentId: string, input?: AuthorizationResultInput) => {
            const getAssignmentAccountAccess = async () => {
                try {
                    const {data} = await doChallengeAssignmentAccountAccess(
                        assignmentId,
                        input
                    );
                    const {ok, accountAccess, errorMessage} =
                        data?.requestChallengeAssignmentAccountAccess ?? {};
                    if (!ok || !accountAccess) {
                        throw new Error(errorMessage ?? '');
                    }
                    return handleAccountAccess(accountAccess);
                } catch (_error) {
                    toast({type: 'error'});
                }
            };
            return getAssignmentAccountAccess();
        },
        [doChallengeAssignmentAccountAccess]
    );
    return {checkAssignmentAccountAccess, loading};
};
