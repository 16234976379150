import {defineMessages} from 'react-intl';

export default defineMessages({
    welcome: {
        id: 'app.signup.createOrganization.title'
    },
    introMessage: {
        id: 'app.signup.createOrganization.description'
    },
    tagLine: {
        id: 'app.signup.createOrganization.tagline'
    }
});
