import {defineMessages} from 'react-intl';

export default defineMessages({
    modalHeader: {
        id: 'app.assessment.codingTest.candidateCodingTest.candidateProgress.codingTestPreview.header'
    },
    category: {
        id: 'app.assessment.codingTest.basicInfo.basicInfoProfileLabel'
    },
    templateRepository: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTemplateRepoTitle'
    },
    hiringTeamDescription: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTextAreaTitle'
    },
    timeEffortHeader: {
        id: 'app.assessment.codingTest.effort.title'
    },
    timeEffortSubtitle: {
        id: 'app.assessment.codingTest.effort.subtitle'
    },
    githubLink: {
        id: 'app.assessment.codingTest.candidateCodingTest.gitHubLink'
    },
    gitHubTemplateRepoSubtitle: {
        id: 'app.assessment.codingTest.basicInfo.gitHubTemplateRepoSubtitle'
    }
});
