import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.assessment.codingTest.candidateCodingTest.title'
    },
    mainTitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.main.title'
    },
    gitHubLink: {
        id: 'app.assessment.codingTest.candidateCodingTest.main.gitHubLink'
    },
    name: {
        id: 'app.assessment.codingTest.candidateCodingTest.main.name'
    },
    profile: {
        id: 'app.assessment.codingTest.candidateCodingTest.main.profile'
    },
    candidatesRepository: {
        id: 'app.assessment.codingTest.candidateCodingTest.main.candidatesRepository'
    },
    candidatesRepositoryPlaceholder: {
        id: 'app.assessment.codingTest.candidateCodingTest.main.candidatesRepository.placeholder'
    },
    draftTextTitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.main.draftTextTitle'
    },
    draftTextClick: {
        id: 'app.assessment.codingTest.candidateCodingTest.main.draftTextClick'
    },
    score: {
        id: 'app.assessment.codingTest.candidateCodingTest.table.score'
    },
    testsPassed: {
        id: 'app.assessment.codingTest.candidateCodingTest.table.testsPassed'
    },
    scorecard: {
        id: 'app.assessment.codingTest.candidateCodingTest.table.scorecard'
    },
    reviewer: {
        id: 'app.assessment.codingTest.candidateCodingTest.table.reviewer'
    },
    autoScoringTitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.autoScoring.title'
    },
    automatedScoringTitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.automatedScoring.title'
    },
    totalScore: {
        id: 'app.assessment.codingTest.candidateCodingTest.automatedScoring.table.totalScore'
    },
    scoreType: {
        id: 'app.assessment.codingTest.candidateCodingTest.automatedScoring.table.scoreType'
    },
    scoreDetails: {
        id: 'app.assessment.codingTest.candidateCodingTest.automatedScoring.table.scoreDetails'
    },
    codeReviewTitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.codeReview.title'
    },
    manualScoringTitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.manualScoring.title'
    },
    nextRoundTitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.nextRound.title'
    },
    nextRoundTitleSubtitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.nextRound.reviewOverlay.subtitle'
    },
    requirementDecision: {
        id: 'app.assessment.codingTest.candidateCodingTest.nextRound.requirementDecision'
    },
    rate: {
        id: 'app.assessment.codingTest.candidateCodingTest.rate'
    },
    codingTestNotReceivedTitle: {
        id: 'app.assessment.candidatePage.candidateCodingTest.noCodingTestResults.title'
    },
    codingTestNotReceivedDescription: {
        id: 'app.assessment.candidatePage.candidateCodingTest.noCodingTestResults.description'
    },
    codingTestNotReceivedSendInvitation: {
        id: 'app.assessment.candidatePage.candidateCodingTest.noCodingTestResults.sendInvitation'
    },
    setMainScoreConfirmTitle: {
        id: 'app.assessment.codingTest.candidateCodingTest.setMainScore.confirm.title'
    },
    setMainScoreConfirmStayButtonText: {
        id: 'app.assessment.codingTest.candidateCodingTest.setMainScore.confirm.submitBtnTitle'
    },
    setMainScoreConfirmCancelButtonText: {
        id: 'app.assessment.codingTest.candidateCodingTest.setMainScore.confirm.cancelBtnTitle'
    },
    setMainScoreSuccessMessage: {
        id: 'app.assessment.codingTest.candidateCodingTest.setMainScore.success'
    },
    setMainScoreErrorMessage: {
        id: 'app.assessment.codingTest.candidateCodingTest.setMainScore.error'
    },
    autoScoringRetry: {
        id: 'app.errorState.retry'
    },
    autoScoringUnavailableBannerTitle: {
        id: 'app.assessment.codingTests.autoScoringUnavailable.banner.title'
    },
    autoScoringUnavailableBannerSubTitle: {
        id: 'app.assessment.codingTests.autoScoringUnavailable.banner.subtitle'
    },
    qualityScoringUnavailableBannerTitle: {
        id: 'app.assessment.codingTests.qualityScoringUnavailable.banner.title'
    },
    qualityScoringUnavailableBannerSubTitle: {
        id: 'app.assessment.codingTests.qualityScoringUnavailable.banner.subtitle'
    }
});
