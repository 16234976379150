import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import messages from '@/pages/SignupV2/components/RecaptchaTerms/messages';

import {SizeNarrow, SizeWide} from '../../constants';

const RECAPTCHA_LINKS = {
    termsOfService: 'https://policies.google.com/terms',
    privacyPolicy: 'https://policies.google.com/privacy'
};

export function RecaptchaTerms() {
    return (
        <Wrap>
            <FormattedMessage
                {...messages.disclaimer}
                values={{
                    termsOfServiceLink: (
                        <ExternalLink url={RECAPTCHA_LINKS.termsOfService}>
                            <FormattedMessage {...messages.termsOfServiceTitle} />
                        </ExternalLink>
                    ),
                    privacyPolicyLink: (
                        <ExternalLink url={RECAPTCHA_LINKS.privacyPolicy}>
                            <FormattedMessage {...messages.privacyPolicyTitle} />
                        </ExternalLink>
                    )
                }}
            />
        </Wrap>
    );
}

const Wrap = styled.div`
    padding: 24px;
    max-width: ${SizeWide};
    width: ${SizeWide};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        max-width: ${SizeNarrow};
        width: ${SizeNarrow};
    }
`;
