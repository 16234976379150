import {gql} from '@/api/types/__generated__';

export const CREATE_LOGIC_TEST_MUTATION = gql(`
    mutation createLogicTest($shareWithOrganizationId: Int!) {
        createLogicTest(shareWithOrganizationId: $shareWithOrganizationId) {
            ok
            errorMessage
            logicTestStatus {
                ...LogicTestStatusFields
            }
        }
    }
`);

export const SAVE_ANSWER_FOR_LOGIC_TEST_QUESTION = gql(`
    mutation saveAnswerForLogicTestQuestion($data: LogicTestQuestionAnswerInput!) {
        saveAnswerForLogicTestQuestion(data: $data) {
            ok
            errorMessage
            logicTestStatus {
                ...LogicTestStatusFields
            }
        }
    }
`);

export const REQUEST_VALIDATION_TEST = gql(`
    mutation requestValidationTest($userId: Int!, $organizationId: Int!) {
        requestValidationTest(userId: $userId, organizationId: $organizationId) {
            ok
            errorMessage
            validationTestRequest {
                userId
                organizationId
            }
        }
    }
`);

export const SEND_VALIDATE_LOGIC_TEST_INVITATION = gql(`
    mutation sendValidateLogicTestInvitation($userId: Int!, $organizationId: Int!) {
        sendValidateLogicTestInvitation(userId: $userId, organizationId: $organizationId) {
            ok
            errorMessage
        }
    }
`);

export const CREATE_OR_CONTINUE_VALIDATION_LOGIC_TEST_MUTATION = gql(`
    mutation createOrContinueValidationLogicTest($token: String!) {
        createOrContinueValidationLogicTest(token: $token) {
            ok
            errorMessage
            logicTestStatus {
                ...LogicTestStatusFields
            }
        }
    }
`);

export const SHARE_LOGIC_TEST_WITH_ORGANIZATION = gql(`
    mutation shareLogicTestWithOrganization($data: ShareLogicTestWithOrganizationInput!) {
        shareLogicTestWithOrganization(data: $data) {
            ok
            errorMessage
        }
    }
`);

export const CREATE_DATA_COLLECTION_LOGIC_TEST = gql(`
    mutation createDataCollectionLogicTest($data: CreateDataCollectionLogicTestInput!) {
        createDataCollectionLogicTest(data: $data) {
            ok
            errorMessage
            dataCollectionLogicTestId
        }
    }
`);

export const REVOKE_LOGIC_TEST_FROM_ORGANIZATION = gql(`
    mutation RevokeLogicTestFromOrganization($data: RevokeLogicTestFromOrganizationInput!) {
        revokeLogicTestFromOrganization(data: $data) {
            ok
            errorMessage
        }
    }
`);

export const RECREATE_SHARED_LOGIC_TEST = gql(`
    mutation recreateSharedLogicTest($data: RecreateSharedLogicTestInput!) {
        recreateSharedLogicTest(data: $data) {
            ok
            errorMessage
            logicTestStatus {
                logicTest {
                    id
                    userId
                }
            }
        }
    }
`);

export const REPLACE_SHARED_LOGIC_TEST = gql(`
    mutation replaceSharedLogicTest($data: ReplaceSharedLogicTestInput!) {
        replaceSharedLogicTest(data: $data) {
            ok
            errorMessage
        }
    }
`);

export const VALIDATION_LOGIC_TEST_VALIDATION_TOKEN = gql(`
    mutation ValidateLogicTestValidationToken($token: String!) {
        validateLogicTestValidationToken(token: $token) {
            ok
            errorMessage
            authToken
            validationTestId
            validationTestCompletedAt
        }
    }
`);
