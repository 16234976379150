import {useCallback} from 'react';

import {assessmentLinks} from '@/pages/Organization/pages/Assessment/links';

export function usePreviewInterview(
    jobPositionId?: number | string,
    interviewId?: number | string
) {
    return useCallback(() => {
        const url = assessmentLinks.previewInterview(jobPositionId, interviewId);
        window.open(url);
    }, [jobPositionId, interviewId]);
}
