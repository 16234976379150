import {useCallback, useMemo} from 'react';

import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';

import {updateQueryParams} from '../utils';

export function useModal({assignmentId}: {assignmentId: string}) {
    const queryParams = useMemo(() => new URLSearchParams(location.search), []);

    const isOpenOnInit =
        queryParams.get('startTestModal') === 'true' &&
        queryParams.get('assignmentId') === assignmentId;

    const {
        modalIsOpen,
        openModal: _openModal,
        closeModal: _closeModal
    } = useSimpleModalLogic(isOpenOnInit);

    const openModal = useCallback(() => {
        _openModal();
        updateQueryParams({
            startTestModal: 'true',
            assignmentId
        });
    }, [_openModal, assignmentId]);

    const closeModal = useCallback(() => {
        _closeModal();
        updateQueryParams({
            startTestModal: null,
            assignmentId: null
        });
    }, [_closeModal]);

    return {modalIsOpen, openModal, closeModal};
}
