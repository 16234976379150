import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    SpacingMedium,
    SpacingSmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';

import {
    StyledPlainAnchor,
    StyledPlainButton,
    StyledPlainLink,
    StyledPlainNavLink
} from '../buttons/PlainButton';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${SpacingXxlarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: initial;
        gap: ${SpacingMedium};

        > div {
            width: 100%;
        }
    }
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${SpacingSmall};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${SpacingMedium};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: initial;

        > a,
        button {
            width: 100%;
        }

        ${StyledPlainAnchor}, ${StyledPlainButton}, ${StyledPlainNavLink}, ${StyledPlainLink} {
            width: auto;
        }
    }
`;
