import {BulletItem, BulletLabelColumn, BulletMarkerColumn, Marker} from './styled';
import {BulletListProps} from './types';

export function BulletList({markerColor, children}: BulletListProps) {
    return (
        <BulletItem>
            <BulletMarkerColumn>
                <Marker markerColor={markerColor} />
            </BulletMarkerColumn>
            <BulletLabelColumn>{children}</BulletLabelColumn>
        </BulletItem>
    );
}
