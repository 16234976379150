import styled, {createGlobalStyle} from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderRadiusBaseLarge,
    ColorBgBackdrop,
    ColorBgDefault,
    SpacingMedium
} from '@/componentLibrary/tokens/variables';

import {MODAL_SIZE, MODAL_WIDTH} from './constants';
import {ModalBaseProps, ModalProps} from './types';

const TABLET_MIN = deviceBreakPointTokens.TABLET.min;

const getInnerSize = (size: ModalProps['innerSize']) => {
    switch (size) {
        case MODAL_SIZE.XSMALL:
            return MODAL_WIDTH.XSMALL;
        case MODAL_SIZE.SMALL:
            return MODAL_WIDTH.SMALL;
        case MODAL_SIZE.MEDIUM:
            return MODAL_WIDTH.MEDIUM;
        case MODAL_SIZE.LARGE:
            return MODAL_WIDTH.LARGE;
        default:
            return '100%';
    }
};

export const ModalGlobalStyle = createGlobalStyle`
    @media print {
        body {
            overflow: visible !important;
        }

        #root {
            display: none !important;
        }
    }
`;

export const ModalBackground = styled.div`
    position: fixed;
    inset: 0;
    background-color: ${ColorBgBackdrop};
    z-index: 100000;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    @media print {
        position: relative;
        background-color: ${ColorBgDefault};
        z-index: 2147483632;
        overflow: visible;
    }
`;

export const ModalBase = styled.div<ModalBaseProps>`
    z-index: 999;
    position: absolute;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: initial;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    align-items: stretch;
    justify-content: center;
    background-color: ${ColorBgDefault};
    border-radius: 0;

    @media only screen and (min-width: ${TABLET_MIN}) {
        top: ${p => (p.$isVerticallyCentered ? '50%' : '20vh')};
        left: 50%;
        transform: translate(-50%, ${p => (p.$isVerticallyCentered ? '-50%' : '0')});
        height: ${p => p.$height || 'initial'};
        align-items: initial;
        justify-content: initial;
        border-radius: ${BorderRadiusBaseLarge};
    }
`;

export const XsmallModal = styled(ModalBase)`
    @media only screen and (min-width: ${TABLET_MIN}) {
        width: ${MODAL_WIDTH.XSMALL};
        max-height: calc(80vh - 40px);
    }
`;

export const SmallModal = styled(ModalBase)`
    @media only screen and (min-width: ${TABLET_MIN}) {
        width: ${MODAL_WIDTH.SMALL};
        max-height: calc(80vh - 40px);
    }
`;

export const MediumModal = styled(ModalBase)`
    @media only screen and (min-width: ${TABLET_MIN}) {
        width: ${MODAL_WIDTH.MEDIUM};
        max-height: calc(80vh - 40px);
    }
`;

export const LargeModal = styled(ModalBase)`
    @media only screen and (min-width: ${TABLET_MIN}) {
        width: ${MODAL_WIDTH.LARGE};
        max-height: 90vh;
    }

    @media only screen and (min-width: ${TABLET_MIN}) and (max-width: 1000px) {
        width: calc(100vw - 40px);
    }
`;

export const FullPageModal = styled(ModalBase)`
    top: 0;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;

    @media only screen and (min-width: ${TABLET_MIN}) {
        transform: translate(-50%, 0);
    }

    @media print {
        max-height: none;
        position: static;

        * {
            :focus:not(:focus-visible),
            :focus-visible {
                outline: none;
            }
        }
    }
`;

export const ModalBody = styled.div<{$innerSize?: ModalProps['innerSize']; $padding?: string}>`
    overflow-y: auto;
    min-height: 52px;
    flex: 1;
    padding: ${p => p.$padding ?? SpacingMedium};
    width: ${p => (p.$innerSize ? getInnerSize(p.$innerSize) : '100%')};
    margin: 0 auto;
    max-width: 100%;
`;
