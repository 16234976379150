import {Route, Switch} from 'react-router-dom';

import {NotFound} from '../NotFound';
import {logicTestDataCollectionLinks} from './links';
import {CompletedDataCollectionTest} from './pages/CompletedDataCollectionTest';
import {DataCollectionForm} from './pages/DataCollectionForm';

export function LogicTestDataCollection() {
    return (
        <Switch>
            <Route
                exact
                path={logicTestDataCollectionLinks.form(':studyId')}
                render={() => <DataCollectionForm />}
            />
            <Route
                exact
                path={logicTestDataCollectionLinks.completed(':logicTestId')}
                render={p => (
                    <CompletedDataCollectionTest
                        logicTestId={p.match.params.logicTestId as string}
                    />
                )}
            />
            <Route path={'*'} render={() => <NotFound />} />
        </Switch>
    );
}
