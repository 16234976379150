import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {ColorBgDefault} from '@/componentLibrary/tokens/variables';

import {MODAL_WIDTH} from '../Modals/Modal/constants';

const TABLET_MIN = deviceBreakPointTokens.TABLET.min;

export const ContentWrapperWithDefaultBg = styled.div`
    background-color: ${ColorBgDefault};
    margin: auto;

    @media only screen and (min-width: ${TABLET_MIN}) {
        width: ${MODAL_WIDTH.LARGE};
    }

    @media only screen and (min-width: ${TABLET_MIN}) and (max-width: 1000px) {
        width: calc(100vw - 40px);
    }
`;
