import {ColorVariables} from '@/componentLibrary/tokens/types';

import {AvatarBase} from '../AvatarBase';
import {useAvatarsLogic} from '../hooks/useAvatarsLogic';

type BaseOrganizationAvatarProps = {
    name: string;
    colorFill?: string;
    fontColor?: ColorVariables;
    strokeColor?: string;
    image?: string | null;
    size?: number;
    fontSize?: number;
};

function BaseOrganizationAvatar({
    colorFill,
    fontColor,
    strokeColor,
    size,
    fontSize,
    image,
    name
}: BaseOrganizationAvatarProps) {
    const {getOrganizationInitials} = useAvatarsLogic();
    return (
        <AvatarBase
            colorFill={colorFill}
            fontColor={fontColor}
            strokeColor={strokeColor}
            size={size}
            fontSize={fontSize}
            isCircle={false}
            text={getOrganizationInitials(name)}
            image={image}
        />
    );
}

export function OrganizationAvatar({
    size = 40,
    fontSize = 16,
    ...props
}: BaseOrganizationAvatarProps) {
    return <BaseOrganizationAvatar size={size} fontSize={fontSize} {...props} />;
}
