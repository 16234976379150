import LogRocket from 'logrocket';

import {getConfig} from '@/config';
import {urlSanitizer} from '@/services/sanitizers';

import {logger} from '.';
import {requestSanitizer, responseSanitizer} from './sanitizers';
import {IOptions} from './types';

const config = getConfig();

const options: IOptions = {
    dom: {
        textSanitizer: true,
        inputSanitizer: true
    },
    shouldCaptureIP: false,
    console: {
        isEnabled: true,
        shouldAggregateConsoleErrors: true
    },
    browser: {
        urlSanitizer
    },
    network: {
        responseSanitizer,
        requestSanitizer
    }
};

if (!window.TestingMode) {
    LogRocket.init(config.LOG_ROCKET_KEY, options);
    logger.log('LogRocket initialized');
} else {
    console.log('Playwright detected, skipping LogRocket');
}
