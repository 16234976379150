import {useCallback, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {useSubmitChallengeAssignment} from '@/api/assessment/codingTests/useSubmitChallengeAssignment';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {ApolloError} from '@apollo/client';
import {SubmitChallengeAssignmentError} from '../constants';
import messages from '../messages';
import {formatSubmitAssignmentChallengeError, renderErrorToastContent} from '../utils';
import {useSubmitCodingTestAlert} from './useSubmitCodingTestAlert';

export function useSubmitResultLogic(assignmentId: string) {
    const phrases = useExtractPhraseConstants(messages);
    const {alertText, showAlert, onShowAlert, onHideAlert} = useSubmitCodingTestAlert();
    const [showDemographicsModal, setShowDemographicsModal] = useState(false);

    const [submitChallengeAssignment, {loading}] = useSubmitChallengeAssignment(assignmentId);

    const handleSubmit = useCallback(
        async (onSuccess: () => void) => {
            try {
                const {data} = await submitChallengeAssignment();
                if (!data?.submitChallengeAssignment?.ok) {
                    throw new Error(data?.submitChallengeAssignment?.errorMessage || 'Error');
                }
                toast({
                    type: 'success',
                    removeAfterMs: 5000,
                    message: <FormattedMessage {...messages.submitResultSuccess} />
                });
            } catch (e) {
                const errorMessage = String((e as ApolloError).message);
                toast({
                    type: 'error',
                    removeAfterMs: 5000,
                    message: renderErrorToastContent(
                        phrases.submissionFailedErrorMessage,
                        formatSubmitAssignmentChallengeError(
                            errorMessage as keyof typeof SubmitChallengeAssignmentError,
                            phrases
                        )
                    )
                });
            } finally {
                onSuccess?.();
            }
        },
        [phrases, submitChallengeAssignment]
    );

    return {
        handleSubmit,
        loading,
        alertText,
        showAlert,
        onShowAlert,
        onHideAlert,
        showDemographicsModal,
        setShowDemographicsModal
    };
}
