import {useCallback} from 'react';

import {useJobPosition} from '@/api/assessment/jobPositions/useJobPosition';
import {useSendCandidateInvitations} from '@/api/email/useSendCandidateInvitations';
import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {UserCell} from '@/componentLibrary/components/Table/components/UserCell';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {CheckBox} from '@/componentLibrary/components/controls/CheckBox';
import {S2} from '@/componentLibrary/components/typography';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {PreviewEmailModal} from '@/pages/Organization/pages/Assessment/components/PreviewCandidateEmailModal';
import {TestComposition} from '@/pages/Organization/pages/Assessment/components/TestComposition';
import {TEST} from '@/pages/Organization/pages/Assessment/components/TestComposition/constant';
import {useTestComposition} from '@/pages/Organization/pages/Assessment/components/TestComposition/useTestComposition';
import {JobApplication} from '@/pages/Organization/pages/Assessment/pages/JobPosition/pages/EvaluationPage/types';
import {EMAIL_TYPES} from '@/pages/Organization/pages/Settings/pages/EmailTemplates/constants';
import {logger} from '@/services/logrocket';

import {EmailStatus} from './components/EmailStatus';
import {useInvitationsModalLogic} from './logic';
import messages from './messages';
import {Description, SelectedCandidatesWrapper, UserItem, UserRowWrapper} from './styled';

type SendInvitationsModal = {
    jobPositionId: number;
    close: () => void;
    jobApplications: JobApplication[];
    onSuccess: () => void;
    predefinedOption?: TEST;
};

export function SendInvitationsModal({
    close,
    onSuccess,
    jobApplications,
    jobPositionId,
    predefinedOption
}: SendInvitationsModal) {
    const phrases = useExtractPhraseConstants(messages);
    const {loading: loadingJobPosition, jobPosition} = useJobPosition(jobPositionId, {
        withEmailTemplate: true,
        withChallenge: true
    });

    const {invitationOptions, currentOption, onCurrentOptionChange, enabledTests} =
        useTestComposition({jobPosition, predefinedOption});

    const {
        selectedUserIds,
        isPreviewEmailModalOpen,
        toggleUserSelection,
        isUserSelected,
        openEmailPreview,
        closeEmailPreview,
        didLastUserEmailBounce,
        didEmailExclude
    } = useInvitationsModalLogic(jobApplications, currentOption, jobPosition?.challenge?.id);

    const [doSendCandidateInvitationEmails, {loading: loadingSendingInvitation}] =
        useSendCandidateInvitations(jobPositionId);
    const toastId = 'sendInvitationsModal';

    const sendInvitations = useCallback(() => {
        const send = async () => {
            toast({id: toastId, type: 'loading', message: phrases.sendingInvitations});

            try {
                const {data} = await doSendCandidateInvitationEmails(
                    selectedUserIds,
                    invitationOptions
                );
                if (!data?.sendBatchInviteCandidateEmails?.ok) {
                    throw new Error(data?.sendBatchInviteCandidateEmails?.errorMessage ?? '');
                }
                toast({id: toastId, type: 'success', message: phrases.invitationsSent});
                onSuccess?.();
            } catch (error) {
                logger.error(error);
                toast({id: toastId, type: 'error', message: phrases.failedToSendInvitations});
            }
        };
        send();
    }, [
        doSendCandidateInvitationEmails,
        invitationOptions,
        onSuccess,
        phrases.failedToSendInvitations,
        phrases.invitationsSent,
        phrases.sendingInvitations,
        selectedUserIds
    ]);

    return (
        <>
            <Modal
                headerTitle={phrases.titleInvitation}
                isVerticallyCentered
                close={close}
                footer={{
                    leftSecondaryAction: (
                        <SecondaryButton
                            onClick={openEmailPreview}
                            disabled={loadingJobPosition}
                        >
                            {phrases.previewEmail}
                        </SecondaryButton>
                    ),
                    primaryAction: (
                        <PrimaryButton
                            onClick={sendInvitations}
                            isLoading={loadingSendingInvitation}
                            disabled={!selectedUserIds.length}
                        >
                            {phrases.sendInvitation}
                        </PrimaryButton>
                    )
                }}
            >
                <>
                    <S2>{phrases.testComposition}</S2>
                    {jobPosition && (
                        <TestComposition
                            jobPosition={jobPosition}
                            currentOption={currentOption}
                            onCurrentOptionChange={onCurrentOptionChange}
                            enabledTests={enabledTests}
                        />
                    )}
                    <Description>{phrases.description}</Description>

                    <SelectedCandidatesWrapper>
                        {jobApplications.map(application => {
                            const emailDidBounce = didLastUserEmailBounce(
                                application.candidateReminderStatus ?? null
                            );
                            const emailDidExclude =
                                currentOption !== TEST.PSYCHOMETRIC &&
                                didEmailExclude(
                                    application?.challengeAssignment?.challengeId,
                                    jobPosition?.challenge?.id
                                );

                            return (
                                <UserRowWrapper
                                    key={application.id}
                                    isDanger={emailDidBounce || emailDidExclude}
                                >
                                    <UserItem
                                        key={application.id}
                                        onClick={() =>
                                            toggleUserSelection(application.user?.id)
                                        }
                                    >
                                        <CheckBox
                                            value={isUserSelected(application.user?.id)}
                                            isDisabled={emailDidExclude}
                                        />
                                        <UserCell
                                            user={application.user}
                                            subtext={application.user?.email}
                                        />
                                    </UserItem>
                                    <EmailStatus
                                        emailDidBounce={emailDidBounce}
                                        emailDidExclude={emailDidExclude}
                                        candidateReminderStatus={
                                            application.candidateReminderStatus ?? null
                                        }
                                    />
                                </UserRowWrapper>
                            );
                        })}
                    </SelectedCandidatesWrapper>
                </>
            </Modal>
            {isPreviewEmailModalOpen && jobPosition && (
                <PreviewEmailModal
                    closeModal={closeEmailPreview}
                    emailTemplate={jobPosition?.usedInviteCandidateEmailTemplate || null}
                    emailType={EMAIL_TYPES.INVITE_CANDIDATE}
                />
            )}
        </>
    );
}
