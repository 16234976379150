import {defineMessages} from 'react-intl';

export default defineMessages({
    logicNotCompletedTitle: {
        id: 'app.assessment.candidatePage.candidateResults.noLogicResults.title'
    },
    logicNotCompletedDescription: {
        id: 'app.assessment.candidatePage.candidateResults.noLogicResults.description'
    }
});
