import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {ChallengeAssignmentStatus} from '@/api/types/__generated__/graphql';
import {EmptyState} from '@/componentLibrary/blocks/EmptyState';
import {ConfirmAlert} from '@/componentLibrary/blocks/Modals/Alert/ConfirmAlert';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {Section} from '@/componentLibrary/components/layout/Section';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useCheckAssignmentAccountAccess} from '@/pages/Organization/pages/Assessment/components/CodingTests/hooks/useCheckAccess';
import {TEST} from '@/pages/Organization/pages/Assessment/components/TestComposition/constant';
import {SendInvitationsModal} from '@/pages/Organization/pages/Assessment/pages/JobPosition/components/SendInvitationsModal';

import {BannerButton} from '@/componentLibrary/components/banners/components/BannerButton';
import {SharedResultsContext} from '@/pages/SharedResults/components/UserResults/hooks/SharedResultsContext';
import {JobApplication} from '../../../JobPosition/pages/EvaluationPage/types';
import {AutomatedScoreV2Results} from './components/AutomatedScoreV2Results/AutomatedScoreV2Results';
import {getScores} from './components/AutomatedScoreV2Results/getScores';
import {BannerWrapper} from './components/AutomatedScoreV2Results/styled';
import {AutoscoreDisabledBanner} from './components/AutoscoreDisabledBanner';
import {CandidateProgressTable} from './components/CandidateProgressTable/CandidateProgressTable';
import {ScorePercentages} from './components/ScorePercentages';
import {useLogic} from './hooks/useLogic';
import {useSetMainScoreLogic} from './hooks/useSetMainScoreLogic';
import {useSyncScorecardLogic} from './hooks/useSyncScorecardLogic';
import messages from './messages';
import {Wrapper, WrapperSendInvitation} from './styled';
import {CandidateCodingTestPageProps} from './types';
import {getScorecardActionType} from './utils';

export function CandidateCodingTestPage({
    userId,
    jobPositionId
}: CandidateCodingTestPageProps) {
    const phrases = useExtractPhraseConstants(messages);
    const [isOpen, setIsOpen] = useState(false);
    const isSharedResultsView = useContext(SharedResultsContext);

    const [submitted, setSubmitted] = useState(false);

    const toggleModal = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const {checkAssignmentAccountAccess} = useCheckAssignmentAccountAccess();

    const {data, loading, error, refetch, startPolling, stopPolling} = useLogic({
        userId,
        jobPositionId
    });
    const {challengeAssignment, jobApplication} = data;

    const {
        alertIsOpen: syncCodeReviewAlert,
        closeAlert: closeSyncCodeReviewAlert,
        alertTexts: syncCodeReviewAlertTexts,
        handleClick: showSyncCodeReviewAlert,
        onConfirm: syncCodeReview,
        bannerTexts: syncCodeReviewBannerTexts,
        isSyncInProgress
    } = useSyncScorecardLogic({
        refetchJobApplication: refetch,
        challengeAssignment: jobApplication?.challengeAssignment ?? null
    });

    const isScorecardUpdated = useMemo(
        () =>
            jobApplication?.challengeAssignment?.status === ChallengeAssignmentStatus.SUBMITTED
                ? !!getScorecardActionType(jobApplication?.challengeAssignment ?? null)
                : false,
        [jobApplication?.challengeAssignment]
    );

    const {
        modalIsOpen: setMainScoreConfirm,
        openModal: showSetMainScoreConfirm,
        closeModal: closeSetMainScoreConfirm,
        handleSubmit: setMainScore,
        loading: isSubmitting
    } = useSetMainScoreLogic({assignmentId: jobApplication?.challengeAssignment?.id || ''});

    const setMainScoreConfirmTexts = {
        title: phrases.setMainScoreConfirmTitle,
        rightButton: phrases.setMainScoreConfirmStayButtonText,
        leftButton: phrases.setMainScoreConfirmCancelButtonText
    };

    useEffect(() => {
        if (submitted) {
            startPolling(1500);
        }
    }, [startPolling, submitted]);

    const onSuccess = useCallback(() => {
        setSubmitted(true);
        toggleModal();
    }, [toggleModal]);

    useEffect(() => {
        if (submitted && challengeAssignment) {
            stopPolling();
            setSubmitted(false);
        }
    }, [stopPolling, submitted, challengeAssignment]);

    const handleOpenTest = useCallback(async () => {
        if (challengeAssignment?.id) {
            const repositoryUrl = await checkAssignmentAccountAccess(challengeAssignment.id);
            if (repositoryUrl) {
                window.open(repositoryUrl, '_blank', 'noopener, noreferrer');
            }
        }
    }, [challengeAssignment?.id, checkAssignmentAccountAccess]);

    const handleSetMainScore = useCallback(async () => {
        await setMainScore();
        refetch();
    }, [refetch, setMainScore]);

    const candidatesRepo = useMemo(() => {
        if (challengeAssignment?.status === ChallengeAssignmentStatus.NOT_STARTED) {
            return <P2>{phrases.candidatesRepositoryPlaceholder}</P2>;
        }
        return <ExternalLink onClick={handleOpenTest}>{phrases.gitHubLink}</ExternalLink>;
    }, [
        challengeAssignment?.status,
        handleOpenTest,
        phrases.candidatesRepositoryPlaceholder,
        phrases.gitHubLink
    ]);

    if (loading || submitted) {
        return <Section loading />;
    }

    if (error) {
        return <Section error={{reload: refetch}} />;
    }

    if (!challengeAssignment) {
        return (
            <>
                <WrapperSendInvitation>
                    <EmptyState
                        titleText={phrases.codingTestNotReceivedTitle}
                        contentText={
                            !isSharedResultsView
                                ? phrases.codingTestNotReceivedDescription
                                : undefined
                        }
                    />
                    {!isSharedResultsView ? (
                        <PrimaryButton onClick={toggleModal}>
                            {phrases.codingTestNotReceivedSendInvitation}
                        </PrimaryButton>
                    ) : null}
                </WrapperSendInvitation>
                {isOpen && (
                    <SendInvitationsModal
                        jobPositionId={jobPositionId}
                        jobApplications={
                            jobApplication ? [jobApplication as JobApplication] : []
                        }
                        onSuccess={onSuccess}
                        close={toggleModal}
                        predefinedOption={TEST.CODING}
                    />
                )}
            </>
        );
    }

    const codingTestScore = jobApplication?.roleFitV2?.assessmentScores?.find(
        ({assessmentMethodType}) => assessmentMethodType === 'CODING_TEST'
    );

    const {isAutoScoreDisabled} = getScores({
        challengeAssignment: jobApplication?.challengeAssignment ?? null
    });

    return (
        <Wrapper>
            {(isScorecardUpdated || isSyncInProgress) && (
                <BannerWrapper>
                    <Banner
                        title={syncCodeReviewBannerTexts.title}
                        type={BannerType.ATTENTION}
                        customAction={
                            <BannerButton
                                action={{
                                    disabled: !!isSyncInProgress,
                                    onClick: showSyncCodeReviewAlert,
                                    text: syncCodeReviewBannerTexts.btnTitle
                                }}
                            />
                        }
                    >
                        {syncCodeReviewBannerTexts.subtitle}
                    </Banner>
                </BannerWrapper>
            )}
            <ScorePercentages
                challengeAssignment={jobApplication?.challengeAssignment ?? null}
                codingTestScore={codingTestScore}
                showSetMainScoreConfirm={showSetMainScoreConfirm}
            />
            <AutoscoreDisabledBanner
                profile={jobApplication?.challengeAssignment?.challenge?.profile}
            />
            <AutomatedScoreV2Results
                user={jobApplication?.user}
                refetch={refetch}
                isAutoScoreDisabled={isAutoScoreDisabled}
                challengeAssignment={jobApplication?.challengeAssignment ?? null}
            />
            <CandidateProgressTable
                challengeAssignment={jobApplication?.challengeAssignment ?? null}
                candidatesRepo={candidatesRepo}
            />
            {setMainScoreConfirm && (
                <ConfirmAlert
                    onLeftAction={closeSetMainScoreConfirm}
                    onRightAction={handleSetMainScore}
                    isLoading={isSubmitting}
                    {...setMainScoreConfirmTexts}
                />
            )}
            {syncCodeReviewAlert && (
                <ConfirmAlert
                    onLeftAction={closeSyncCodeReviewAlert}
                    onRightAction={syncCodeReview}
                    {...syncCodeReviewAlertTexts}
                />
            )}
        </Wrapper>
    );
}
