import {
    ColorBgInset,
    ColorBgSuccess,
    ColorFgDefault,
    ColorFgSubtle,
    ColorFgSuccess
} from '@/componentLibrary/tokens/variables';

export const MARKER_SIZE = 14 as const;
export const MARKER_TRANSFORM = 16 as const;

export const MARKER_GREEN = ColorFgSuccess;
export const MARKER_GREEN_DISABLED = ColorBgSuccess;

export const MARKER_GREY = ColorFgSubtle;
export const MARKER_GREY_DISABLED = ColorBgInset;

export const LABEL_COLOR = ColorFgDefault;
export const LABEL_COLOR_DISABLED = ColorFgSubtle;
