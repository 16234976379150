import {defineMessages} from 'react-intl';

export default defineMessages({
    signIn: {
        id: 'app.login.loginForm.login'
    },
    accountExists: {
        id: 'app.signup.createFreeTrialAccount.emailAccount.accountExists'
    },
    accountExistsAction: {
        id: 'app.signup.createFreeTrialAccount.emailAccount.accountExists.action'
    }
});
