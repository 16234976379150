import {LandingPageLayout} from '@/componentLibrary/blocks/LandingPageLayout';

import backgroundImage from './assets/background.jpg';
import {ChildrenWrapper, InnerWrapper, OuterWrapper} from './styled';
import {Props} from './types';

export const AuthenticationPageLayout = ({children}: Props) => (
    <LandingPageLayout backgroundImage={backgroundImage}>
        <OuterWrapper>
            <InnerWrapper>
                <ChildrenWrapper>{children}</ChildrenWrapper>
            </InnerWrapper>
        </OuterWrapper>
    </LandingPageLayout>
);
