import {isArrayWithItems} from '@/utils/typeGuards/isArrayWithItems';
import {useCallback, useMemo} from 'react';

import {InterviewRequest} from './types';

export function useScorecardResultsBreakdownLogic(
    averageScore: number,
    interviewRequests: InterviewRequest[]
) {
    const getScoreInPercentage = useCallback(() => {
        if (averageScore <= 1) {
            return 0;
        }

        return ((averageScore - 1) * 100) / 4;
    }, [averageScore]);

    const notStartedInterviews = useMemo(() => {
        return interviewRequests.filter(request => {
            if (request.publishedAt) {
                return false;
            }

            return !isArrayWithItems(request.competencyScores);
        });
    }, [interviewRequests]);

    return {getScoreInPercentage, notStartedInterviews};
}
