import {useMemo} from 'react';

import {LogicReportGraphs} from '@/componentLibrary/blocks/testReports/LogicTest/components/LogicReportGraphs';
import {LogicTestValidationReport} from '@/componentLibrary/blocks/testReports/LogicTest/components/LogicTestValidationReport';
import {getStandardScore} from '@/componentLibrary/blocks/testReports/LogicTest/utils';
import {PrintHeader} from '@/componentLibrary/blocks/testReports/components/PrintHeader';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {ReportHeaderProps} from '../../../components/ReportHeader/types';
import {AdminContentProps} from './types';

export function AdminContent({
    logicalAbilityEstimate,
    validationTestResult
}: AdminContentProps) {
    const isValidationTestRequested = !!validationTestResult;
    const validationTestIsCompleted =
        isValidationTestRequested && !!validationTestResult.testCompletedAt;
    const standardScore = getStandardScore(logicalAbilityEstimate.mu);
    const validationTestStandardScore = validationTestIsCompleted
        ? getStandardScore(validationTestResult.mu as number)
        : undefined;

    const questions = useMemo(() => {
        if (!logicalAbilityEstimate?.report) {
            return [];
        }

        return logicalAbilityEstimate.report.questions;
    }, [logicalAbilityEstimate]);

    const validationTestQuestions = useMemo(() => {
        if (!validationTestIsCompleted || !validationTestResult?.report) {
            return undefined;
        }

        return validationTestResult.report.questions;
    }, [validationTestResult, validationTestIsCompleted]);

    return (
        <>
            <LogicReportGraphs questions={questions} />
            {isDefined(validationTestResult) && (
                <PrintHeader user={logicalAbilityEstimate.user as ReportHeaderProps['user']} />
            )}
            <LogicTestValidationReport
                originalTestScore={standardScore}
                originalTestQuestions={questions}
                isValidationRequested={isValidationTestRequested}
                validationIsCompleted={validationTestIsCompleted}
                validationResultType={validationTestResult?.result ?? undefined}
                validationTestScore={validationTestStandardScore}
                validationTestQuestions={validationTestQuestions}
            />
        </>
    );
}
