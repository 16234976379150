import {useEffect, useState} from 'react';

import {Columns} from '@/componentLibrary/components/layout/Columns';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {personalityTestTakingMessages} from '@/pages/PersonalityTestIrt/messages';
import {
    AnswerDescription,
    DescriptionColumns,
    DescriptionLabel,
    DescriptionText,
    Option,
    OptionLabel,
    OptionWrapper,
    OptionsRow
} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/components/QuestionOptions/styled';
import {QuestionOptionsProps} from '@/pages/PersonalityTestIrt/pages/PersonalityTest/components/PersonalityTestForm/components/QuestionOptions/types';

export const QuestionOptions = ({saveAnswer, answerValue}: QuestionOptionsProps) => {
    const phrases = useExtractPhraseConstants(personalityTestTakingMessages);
    const [selectedValue, setSelectedValue] = useState<number | null>(answerValue || null);

    useEffect(() => {
        setSelectedValue(answerValue ?? null);
    }, [answerValue]);

    const handleSaveAnswer = (value: number) => {
        setSelectedValue(value);
        saveAnswer(value);
    };

    const options = [1, 2, 3, 4, 5];

    return (
        <div>
            <AnswerDescription>{phrases.questionOptionsDescription}</AnswerDescription>

            <OptionsRow>
                {options.map(optionValue => (
                    <OptionWrapper
                        key={optionValue}
                        onClick={() => handleSaveAnswer(optionValue)}
                    >
                        <Option isSelected={selectedValue === optionValue}>
                            <OptionLabel>{optionValue}</OptionLabel>
                        </Option>
                    </OptionWrapper>
                ))}
            </OptionsRow>

            <DescriptionColumns>
                <Columns
                    columns={[
                        {
                            size: 4,
                            grow: false,
                            shrink: false,
                            render: () => (
                                <DescriptionLabel textAlign="left" justify="flex-start">
                                    <DescriptionText>{phrases.answerLow}</DescriptionText>
                                </DescriptionLabel>
                            )
                        },
                        {
                            size: 4,
                            grow: false,
                            shrink: false,
                            render: () => (
                                <DescriptionLabel textAlign="center" justify="center">
                                    <DescriptionText>{phrases.answerCenter}</DescriptionText>
                                </DescriptionLabel>
                            )
                        },
                        {
                            size: 4,
                            grow: false,
                            shrink: false,
                            render: () => (
                                <DescriptionLabel textAlign="right" justify="flex-end">
                                    <DescriptionText>{phrases.answerHigh}</DescriptionText>
                                </DescriptionLabel>
                            )
                        }
                    ]}
                    keepGridOnMobile
                    style={{alignItems: 'unset'}}
                    noMargin
                />
            </DescriptionColumns>
        </div>
    );
};
